import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import mainReducer from './mainReducer';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import aggregatorReducer from './aggregatorReducer';

const rootReducer = combineReducers({
  main: mainReducer,
  aggregator: aggregatorReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
    }),
});

export type RootState = ReturnType<typeof rootReducer>;

// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;