import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AppSpinner from '../../components/AppSpinner/AppSpinner';
import PurchaseProductModal from '../../components/PurchaseProductModal/PurchaseProductModal';
import { GetProductsByIdsQuery, useGetProductsByIdsQuery } from '../../gql/graphqlWithHooks';
import { setConsultCallData, setSectionToScrollTo } from '../../redux/mainReducer';
import { RootState } from '../../redux/store';
import { Periodicity, SectionName } from '../../utils/enums';
import { cacheImages } from '../../utils/functions/cacheImages';
import { scrollToBlockRef } from '../../utils/helper';
import useComponentDidUpdate from '../../utils/hooks/useComponentDidUpdate';
import { useModalStateHooks } from '../../utils/hooks/useModalStateHooks';
import { useScrollToTop } from '../../utils/hooks/useScrollToTop';
import { ConsultCallData, GraphqlProductData } from '../../utils/types';
import BaseLayout from '../BaseLayout';
import { structureGraphqlProductData } from '../MainPage/components/1.Aggregator/aggregatorUtils';
import FAQ from '../MainPage/components/7.FAQ/FAQ';
import Consult from '../MainPage/components/8.Consult/Consult';
import ItemDescriptionPageDetailsSection from './components/ItemDescriptionPageDetailsSection';
import ItemDescriptionPageMainBannerNew from './components/ItemDescriptionPageMainBannerNew';
import './ItemDescriptionPage.scss';

const openPurchaseModalQueryParameter = 'showPurchaseModal';

const ItemDescriptionPage = () => {
  useScrollToTop({ smooth: false });

  // ###################################################################################################
  const faqRef = useRef<HTMLDivElement>(null);
  const consultRef2 = useRef<HTMLDivElement>(null);

  const scrollToSection = (sectionName: SectionName | null) => {
    switch (sectionName) {
      case SectionName.ConsultSection2: scrollToBlockRef(consultRef2); break;
      case SectionName.FAQ:            scrollToBlockRef(faqRef); break;
      default: return;
    }
  }

  const dispatch = useDispatch();
  const sectionNameToScrollTo = useSelector((state: RootState) => state.main.sectionNameToScrollTo);
  useEffect(() => {
    scrollToSection(sectionNameToScrollTo);
    if (sectionNameToScrollTo) dispatch(setSectionToScrollTo(null));
  }, [sectionNameToScrollTo]);

  // ###################################################################################################
  // modal state handling
  const { modalIsOpen, openModal: openPurchaseModal, closeModal: closePurchaseModal } = useModalStateHooks();

  useComponentDidUpdate(() => {
    if (modalIsOpen) {
      if (!searchParams.has(openPurchaseModalQueryParameter)) {
        searchParams.append(openPurchaseModalQueryParameter, 'true');
      }
    } else {
      searchParams.delete(openPurchaseModalQueryParameter);
    }

    setSearchParams(searchParams, { replace: true });
  }, [modalIsOpen]);

  // ###################################################################################################
  // get query parameters from url
  const [searchParams, setSearchParams] = useSearchParams();
  const productId = parseInt(searchParams.get('id')!) || 0;
  const tariffId = parseInt(searchParams.get('variant')!) || 0;

  // determine whether to open purchase modal right away
  useEffect(() => {
    if (searchParams.has(openPurchaseModalQueryParameter)) {
      openPurchaseModal();
    }
  }, []);

  // const storedProducts = useSelector((state: RootState) => state.main.products);
  // const storedProductData = storedProducts.find(item => item.productId.toString() === productId.toString());

  // ###################################################################################################
  // graphql

  // if we can use stored product data from redux, ignore graphql (skip)
  const { data: fetchedGraphqlData } = useGetProductsByIdsQuery({ variables: { ids: [productId] } });
    // useGetProductsByIdsQuery({ variables: { ids: [productId], }, skip: !!storedProductData });

  const [productData, setProductData] = useState<GraphqlProductData>();
  // const [productData, setProductData] = useState<GraphqlProductData>(storedProductData || undefined!);
  const tariffData = useMemo(
    () => productData?.tariffs.find((item) => item.tariffId === tariffId),
    [productData, tariffId]
  );

  const [loadingImages, setLoadingImages] = useState(false);
  const setUpProductData = async (fetchedData?: GetProductsByIdsQuery) => {
    const fetchedProducts = fetchedData?.mobile_services;
    if (!fetchedProducts?.length) return;
    
    const fetchedProductData = fetchedProducts[0];
    const graphqlProductData = structureGraphqlProductData(fetchedProductData);

    setLoadingImages(true);
    await cacheImages(graphqlProductData.productImages);
    setLoadingImages(false);

    setProductData(graphqlProductData);
  }
  
  useEffect(() => {
    setUpProductData(fetchedGraphqlData);
  }, [fetchedGraphqlData]);

  // ###################################################################################################
  // TODO waiting for fixed request (still no fixed request 22.07.24)
  useEffect(() => {
    if (!productData) return; 

    const consultCallData: ConsultCallData = {
      programDevName: productData.title,
      durationInMonths: productData.periodicityName === Periodicity.Months ? productData.quantity! : 0,
      tags: [],
    }
    dispatch(setConsultCallData(consultCallData));
  }, [productData])

  // ###################################################################################################
  const dataIsReady = !loadingImages && tariffData && productData;

  return (
    <BaseLayout
      hideSectionLinks={false}
      hideFooter={false}
      content={
        <div className="itemDescriptionContainer">
          {!dataIsReady && (
            <div className="itemDescriptionLoader">
              <AppSpinner/>
            </div>
          )}

          {dataIsReady && (
            <>
              <PurchaseProductModal
                tariffData={tariffData}
                selectedTags={[]}
                modalIsOpen={modalIsOpen}
                closeModal={closePurchaseModal}
              />

              <ItemDescriptionPageMainBannerNew
                tariffData={tariffData}
                openPurchaseModal={openPurchaseModal}
              />

              <ItemDescriptionPageDetailsSection
                productData={productData}
                selectedTariffId={tariffId}
                openPurchaseModal={openPurchaseModal}
              />

              <div className="itemDescriptionContainerNegativeMargins">
                <FAQ blockRef={faqRef} mobileGap={40} />
                <Consult blockRef={consultRef2} />
              </div>
            </>
          )}
        </div>
      }
    />
  );
}

export default ItemDescriptionPage;
