import { debounce, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ButtonWithSpinner from '../../components/ButtonWithSpinner/ButtonWithSpinner';
import ClearSelectedTagTargetsButton from '../../components/ClearSelectedTagTargetsButton/ClearSelectedTagTargetsButton';
import { GetProductsByTagsQueryVariables, useGetProductsByTagsIncompleteLazyQuery, useGetProductsByTagsLazyQuery } from '../../gql/graphqlWithHooks';
import { setSelectedAgeRangeId, setSelectedCategoryId, setSelectedTagTargetIds } from '../../redux/aggregatorReducer';
import { useAppSelector } from '../../redux/store';
import { PagePath } from '../../utils/enums';
import { scrollToTop, sleep } from '../../utils/helper';
import { useAppNavigate } from '../../utils/hooks/useAppNavigate';
import useComponentDidUpdate from '../../utils/hooks/useComponentDidUpdate';
import { useFullScreenFiltersModal } from '../../utils/hooks/useFullScreenFiltersModal';
import { useGetAndStoreTags } from '../../utils/hooks/useGetAndStoreTags';
import { useGetSearchParamsObjectFromStoreVariables } from '../../utils/hooks/useGetSearchParamsObjectFromStoreVariables';
import { useOnReturnToMainPageButtonClick } from '../../utils/hooks/useOnReturnToMainPageButtonClick';
import images from '../../utils/images';
import { FetchedGraphqlProductData, GraphqlProductData } from '../../utils/types';
import { aggregatorButtons } from '../MainPage/components/1.Aggregator/aggregatorConstants';
import { structureGraphqlProductData } from '../MainPage/components/1.Aggregator/aggregatorUtils';
import AggregatorQuickSearchButton from '../MainPage/components/1.Aggregator/components/AggregatorQuickSearchButton';
import AggregatorAgeRangesMenu from '../MainPage/components/1.Aggregator/components/menus/AggregatorAgeRangesMenu';
import AggregatorCategoryMenu from '../MainPage/components/1.Aggregator/components/menus/AggregatorCategoryMenu';
import AggregatorTagTargetsMenu from '../MainPage/components/1.Aggregator/components/menus/AggregatorTagTargetsMenu';
import AggregatorFullScreenFiltersModal from '../MainPage/components/1.Aggregator/components/modals/AggregatorFullScreenFiltersModal';
import SearchPageResults from './components/SearchPageResults';
import SearchPageSelectedTagTargetsBlock from './components/SearchPageSelectedTagTargetsBlock';
import SearchPageSortButton from './components/SearchPageSortButton';
import { cacheImagesForProducts, getTagsFromSearchQueryParams } from './searchPageUtils';
import '../MainPage/components/1.Aggregator/components/menus/AggregatorSearchBlockMenus.scss';
import './SearchPageContent.scss';

export interface SearchInput {
  categoryId: number;
  ageRangeId: number;
  tagTargetIds: number[]
}

const SearchPageContent = () => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  
  const returnToMainPage = useOnReturnToMainPageButtonClick();
  const { getAndStoreTags } = useGetAndStoreTags();
  const { openModal: openFullScreenFiltersModal } = useFullScreenFiltersModal();

  const [getProductsByTagsLazyQuery] = useGetProductsByTagsLazyQuery();
  const [getProductsByTagsIncompleteLazyQuery] = useGetProductsByTagsIncompleteLazyQuery();
  
  // ###################################################################################################
  const {
    structuredTagsData: {
      categoryIds,
      ageRangeIds,
      tagTargetIds,
    },
    selectedCategoryId,
    selectedAgeRangeId,
    selectedTagTargetIds,
    fetching,
  } = useAppSelector(state => state.aggregator);

  // ###################################################################################################
  // get query parameters and use them to fill store if it is empty
  // get tags
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setIsLoaded(false);

    const { categoryIdFromQuery, ageRangeIdFromQuery, tagTargetIdsFromQuery } =
      getTagsFromSearchQueryParams(searchParams);

    if (categoryIdFromQuery && !selectedCategoryId) dispatch(setSelectedCategoryId(categoryIdFromQuery));
    if (ageRangeIdFromQuery && !selectedAgeRangeId) dispatch(setSelectedAgeRangeId(ageRangeIdFromQuery));
    if (tagTargetIdsFromQuery?.length && !selectedTagTargetIds.length) {
      dispatch(setSelectedTagTargetIds(tagTargetIdsFromQuery));
    }

    getAndStoreTags();
  }, []);

  // update url when selected tags change
  const { appNavigate } = useAppNavigate();
  const { searchParamsObject } = useGetSearchParamsObjectFromStoreVariables();

  useEffect(() => {
    if (searchParams.toString() !== searchParamsObject.toString()) {
      const updatedUrl = `${PagePath.SearchPage}?${searchParamsObject.toString()}`;
      appNavigate(updatedUrl, { replace: true });
    }
  }, [searchParamsObject]);
  
  // ###################################################################################################
  // perform initial search (only after we received tags using getAndStoreTags)

  useEffect(() => {
    if (isEmpty(categoryIds) || isEmpty (ageRangeIds) || isEmpty(tagTargetIds)) {
      return;
    }

    searchProducts().then(() => setIsLoaded(true));
  }, [categoryIds, ageRangeIds, tagTargetIds]);

  // ###################################################################################################
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [foundProducts, setFoundProducts] = useState<GraphqlProductData[]>([]);

  const searchProducts = async (input?: SearchInput) => {
    console.log(`searchProducts; input = ${JSON.stringify(input) || 'undefined'}`);
    scrollToTop();
    setFetchingProducts(true);

    const queryVariables: GetProductsByTagsQueryVariables = {
      tag_target_groups: input?.categoryId || (selectedCategoryId ? [selectedCategoryId] : categoryIds),
      tag_ages: input?.ageRangeId || (selectedAgeRangeId ? [selectedAgeRangeId] : ageRangeIds),
      tag_targets: input?.tagTargetIds || (selectedTagTargetIds.length ? selectedTagTargetIds : tagTargetIds),
    };

    // console.log(`queryVariables = ${JSON.stringify(queryVariables)}`);

    const { data: fetchedData } = await getProductsByTagsLazyQuery({
      fetchPolicy: 'network-only',
      variables: queryVariables,
    });
    const fetchedProductsData = fetchedData?.mobile_services;

    // const { data: fetchedData } = await getProductsByTagsIncompleteLazyQuery({
    //   fetchPolicy: 'cache-and-network',
    //   variables: {
    //     tag_target_groups: selectedCategoryId ? [selectedCategoryId] : [],
    //     tag_ages: selectedAgeRangeId ? [selectedAgeRangeId] : [],
    //     tag_targets: selectedTagTargetIds.length ? selectedTagTargetIds : [],
    //   }
    // })
    // const fetchedProductsData = fetchedData?.mobileGetServiceTariffs?.service_tariffs;

    if (fetchedProductsData) {
      const structuredProducts = fetchedProductsData.map((item) =>
        structureGraphqlProductData(item as FetchedGraphqlProductData)
      );

      await cacheImagesForProducts({ structuredProducts });
      setFoundProducts(structuredProducts);
    }

    // (?) artificial delay before search results are shown to prevent flickering
    sleep(100).then(() => setFetchingProducts(false));
  };

  const debounceSearch = debounce(searchProducts, 50);

  const selectedTagsCount = + selectedTagTargetIds.length
    + (selectedCategoryId ? 1 : 0)
    + (selectedAgeRangeId ? 1 : 0);

  return (
    <div className="searchPageContainer">
      <AggregatorFullScreenFiltersModal onSearchButtonClick={debounceSearch} isSearchPageFilter />

      <button className="searchPageBackButton link opacity-on-hover" onClick={returnToMainPage}>
        <img className="searchPageBackButtonImage" src={images.backArrowLeft} />
        {'На главную'}
      </button>

      <div className="searchPageTitle">{'Поиск готовых программ здоровья'}</div>
      <div className="searchPageSlogan">{'Управлять здоровьем — просто!'}</div>
      <SearchPageSortButton />

      <div className="searchPageMainContainer">
        <button className="searchPageFiltersBlockMobile app-button" onClick={openFullScreenFiltersModal}>
          {`Настроить фильтры${selectedTagsCount ? ` (${selectedTagsCount})` : ''}`}
        </button>

        <div className="searchPageFiltersBlock">
          <div className="searchPageFiltersBlockSection">
            <div className="searchPageFiltersBlockSectionTitle">{'Поиск персональной программы'}</div>

            <AggregatorCategoryMenu flex={1} />
            <AggregatorAgeRangesMenu flex={1} />
            <AggregatorTagTargetsMenu flex={2} usingAltDesign />

            <SearchPageSelectedTagTargetsBlock />
            <ClearSelectedTagTargetsButton />

            <ButtonWithSpinner
              title={'Найти'}
              fetching={fetching || fetchingProducts}
              onClick={() => debounceSearch()}
              className="searchPageFiltersBlockSearchButton app-button"
              spinnerSize={30}
            />
          </div>

          <div className="searchPageFiltersBlockSection">
            <div className="searchPageFiltersBlockSectionTitle">{'Или ищите по тегам'}</div>

            <div className="searchPageFiltersBlockQuickTagsList">
              {aggregatorButtons.map((tagValues) => (
                <AggregatorQuickSearchButton
                  key={tagValues.tagId}
                  iconImage={tagValues.iconImage}
                  title={tagValues.title}
                  tagId={tagValues.tagId}
                  onSearch={debounceSearch}
                  usingMobileStyles
                  isSearchPageFilter
                />
              ))}
            </div>
          </div>
        </div>

        <SearchPageResults
          fetching={!isLoaded || fetching || fetchingProducts}
          products={foundProducts}
        />
      </div>
    </div>
  );
};

export default SearchPageContent;