import { useMemo } from 'react';
import { getDurationText, getImageUrlForCss, getServiceDurationText } from '../../../../../utils/stringHelper';
import images from '../../../../../utils/images';
import { GraphqlTariffData } from '../../../../../utils/types';
import './ProductItemModal.scss';

const ProductItemModal = ({
  tariffData,
}: {
  tariffData: GraphqlTariffData;
}) => {
  const servicesList = tariffData.tariffDataRaw.service_lists;

  const productDurationText = useMemo(() => getDurationText({
    periodicityName: tariffData.periodicityName,
    quantity: tariffData.quantity,
  }), [tariffData]);
  
  // TODO suggest different approaches, maybe client selects id for background or it depends on type
  const backgroundImage = images.servicesLine1;

  return (
    <div className="modalProductItemContainer">
      <div>
        <div className="modalProductItemTitle" style={{ backgroundImage: getImageUrlForCss(backgroundImage) }}>
          {tariffData.productTitle || tariffData.tariffName}
        </div>
        {/* {<div className="modalProductItemTextDescription">
          {productData.descriptionShort}
        </div>} */}
        <div className="modalProductItemIncludedBlock">
          {servicesList.filter(it => !it.as_gift).map((subService) => (
            <div className="modalProductItemIncludedItem" key={subService.id}>
              <div className="modalProductItemIncludedItemText">{subService.title}</div>
              {subService.service_list_periodicity && 
                <div className="modalProductItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                  {getServiceDurationText(subService)}
                </div>
              }
            </div>
          ))}
        </div>
        <div className="modalProductItemIncludedBonusesBlock">
          {servicesList.filter(it => it.as_gift).map((bonusItem) => (
            <div className="modalProductItemIncludedBonusItem" key={bonusItem.id}>
              <img className="modalProductItemIncludedBonusItemIcon" src={images.servicesGiftIcon} />
              <div className="modalProductItemIncludedItemText">{bonusItem.title}</div>
              <div className="modalProductItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                {getServiceDurationText(bonusItem)}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="modalProductItemPriceAndDuration">
        <div className="modalProductItemPrice">{tariffData.tariffPriceText}</div>
        <div
          className={'modalProductItemDuration'}
          style={{ ...(!productDurationText && { display: 'none' }) }}
        >
          {productDurationText}
        </div>
      </div>
    </div>
  );
};

export default ProductItemModal;
