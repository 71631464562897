import { useMemo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { PagePath } from '../../utils/enums';

const AppLink = (options: LinkProps) => {
  const location = useLocation();
  const currentPagePath = useMemo(() => location.pathname as PagePath, []);
  
  return (
    <Link
      {...options}
      style={{ WebkitTapHighlightColor: 'transparent', ...options.style }}
      state={{ from: currentPagePath, ...options.state }}
    />
  );
};

export default AppLink;