import { apolloClient } from "../..";
import {
  PurchaseRequestDocument,
  PurchaseRequestMutation,
  PurchaseRequestMutationVariables,
} from '../../gql/graphqlWithHooks';

// currently (02.07.24) backend does not accept tags (it should in the future)
export const handlePurchaseRequest = async ({
  userCredentials,
  userPhone,
  userEmail,
  userComment,
  tags,
  selectedTariffId,
  setBlockInterface,
  setRequestReturnedError,
}: {
  userCredentials: string;
  userPhone: string;
  userEmail: string;
  userComment: string;
  tags: string[];
  selectedTariffId: number;
  setBlockInterface: (b: boolean) => void;
  setRequestReturnedError: (b: boolean) => void;
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');
  const formattedTags: { tag: string }[] = tags.map(tag => ({ tag: tag }));

  const operationVariables: PurchaseRequestMutationVariables = {
    service_tariff_id: selectedTariffId, // 279
    phone: `+${userPhoneFormatted}`,     // "+7964935xxxx",
    email: userEmail,                    // "test@test.ru",
    fio: userCredentials,                // "Alexander",
    // tags: formattedTags,              // [{"tag": "Сложные случаи"},{"tag": "Мужчина"},{"tag": "25–35 лет"}]
    comment: userComment || undefined,   // 
  };
  try {
    const response = await apolloClient.mutate<PurchaseRequestMutation>({
      mutation: PurchaseRequestDocument,
      variables: operationVariables,
    });

    // request resolved
    const responseData = response?.data?.mobilePurchasingService;

    // process errors
    if (!responseData || responseData.status === false) {
      console.warn(`handlePurchaseRequest failed: ${JSON.stringify(responseData)}`);
      setRequestReturnedError(true);
      setBlockInterface(false);
      return;
    }

    if (responseData?.status === true) {
      const paymentUrl = responseData.payment_link!;
      window.location.href = paymentUrl;
      return;

      // after payment is complete user gets redirected back to:
      // https://dev.wellness.etna.global/order?id=...
    }

    setBlockInterface(false);
  } catch (error) {
    console.error('handlePurchaseRequest Error:', error);
  }
};