
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import PurchaseProductModal from '../../../components/PurchaseProductModal/PurchaseProductModal';
import { useAppSelector } from '../../../redux/store';
import { useModalStateHooks } from '../../../utils/hooks/useModalStateHooks';
import { GraphqlProductData, GraphqlTariffData, ProductsSortType } from '../../../utils/types';
import { cacheImagesForProducts } from '../searchPageUtils';
import SearchPageConsultBanner from './SearchPageConsultBanner';
import SearchPagePersonalProgramItem from './SearchPagePersonalProgramItem';
import SearchPageResultItem from './SearchPageResultItem';
import SearchPageResultTemplate from './SearchPageResultTemplate';
import './SearchPageResults.scss';
import SearchPageLoader from './SearchPageLoader';

const getProductTariffIdPairs = (products: GraphqlProductData[]) => {
  const productTariffIdPairs = products.reduce(
    (
      arr: { productData: GraphqlProductData; tariffData: GraphqlTariffData; tariffId: number }[],
      product
    ) => {
      const tariffs = product.tariffs;

      const newItems: { productData: GraphqlProductData; tariffData: GraphqlTariffData; tariffId: number }[] = [];
      for (let index = 0; index < tariffs.length; index++) {
        const tariffData = tariffs[index];
        const tariffId = tariffData.tariffId;

        newItems.push({ productData: product, tariffData, tariffId: tariffId });
      }

      return [...arr, ...newItems];
    },
    []
  );

  return productTariffIdPairs;
}

// ####################################################################################
// ####################################################################################
const SearchPageResults = ({
  fetching,
  products,
}: {
  fetching: boolean;
  products: GraphqlProductData[];
}) => {
  const productTariffIdPairs = useMemo(() => getProductTariffIdPairs(products), [products]);
  const sortType = useAppSelector((state) => state.aggregator.selectedSortType);

  const productTariffIdPairsSorted = useMemo(() => {
    switch (sortType) {
      case ProductsSortType.PopularFirst:
        return productTariffIdPairs; // TODO - to add sorting by popularity when it is added on backend

      case ProductsSortType.PriceAscending:
        return [...productTariffIdPairs].sort((a, b) => a.tariffData.tariffPrice - b.tariffData.tariffPrice);

      case ProductsSortType.PriceDescending:
        return [...productTariffIdPairs].sort((a, b) => b.tariffData.tariffPrice - a.tariffData.tariffPrice);
    }
  }, [productTariffIdPairs, sortType]);

  const loadingImages = useRef<boolean>(false);
  useEffect(() => {
    if (loadingImages.current || !productTariffIdPairsSorted.length) return;

    loadingImages.current = true;
    
    const structuredProducts = productTariffIdPairsSorted.map(item => item.productData);
    cacheImagesForProducts({ structuredProducts: structuredProducts })
      .then(() => loadingImages.current = false);

  }, [productTariffIdPairsSorted]);

  const [selectedTariffData, setSelectedTariffData] = useState<GraphqlTariffData>();
  const onPurchaseButtonClick = (tariffData: GraphqlTariffData) => {
    setSelectedTariffData(tariffData);
    openModal();
  }

  const { modalIsOpen, openModal, closeModal } = useModalStateHooks();

  const listItems = useMemo(() => {
    const renderItems: ReactNode[] = [];

    for (let index = 0; index < productTariffIdPairsSorted.length; index++) {
      const productTariffIdPair = productTariffIdPairsSorted[index];
      const { productData, tariffData, tariffId } = productTariffIdPair;

      renderItems.push(
        <SearchPageResultItem
          key={tariffId}
          productData={productData}
          tariffData={tariffData}
          tariffId={tariffId}
          onPurchaseButtonClick={() => onPurchaseButtonClick(tariffData)}
        />
      );

      // after every 8th, 16th, etc. item, add alt version banner
      if ((index + 1) % 8 === 0) {
        renderItems.push(<SearchPageConsultBanner key={`consult-banner-${index}`} usingAltStyle />);
        continue;
      }

      // after every 4th, 12th, etc. item, add normal version banner
      if ((index + 1) % 4 === 0) {
        renderItems.push(<SearchPageConsultBanner key={`consult-banner-${index}`}  />);
        continue;
      }
    }

    // if there is less than 4 products, add consult banner early
    if (productTariffIdPairsSorted.length < 4) {
      renderItems.push(<SearchPageConsultBanner key={`consult-banner-early`}  />);
    }

    return renderItems;
  }, [productTariffIdPairsSorted]);

  // ####################################################################################
  // ####################################################################################
  // ####################################################################################
  const showLoader          =  fetching;
  const showTemplates       = !fetching &&  loadingImages.current;
  const showProducts        = !fetching && !loadingImages.current &&!!products.length;
  const showPersonalProgram = !fetching && !products.length;

  return (
    <div className="searchPageResultsContainer">
      <PurchaseProductModal
        tariffData={selectedTariffData!}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
      {showLoader && <SearchPageLoader />}

      {showProducts && listItems}

      {showPersonalProgram && (
        <>
          <SearchPagePersonalProgramItem />
          <SearchPageConsultBanner usingAltStyle />
        </>
      )}

      {showTemplates && (
        <>
          <SearchPageResultTemplate />
          <SearchPageResultTemplate />
          <SearchPageResultTemplate />
        </>
      )}
    </div>
  );
};

export default SearchPageResults;