import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSearchParams } from 'react-router-dom';
import { useGetTariffsByIdsLazyQuery } from '../../../../../gql/graphqlWithHooks';
import { setMainPageTariffs } from '../../../../../redux/aggregatorReducer';
import { useAppSelector } from '../../../../../redux/store';
import { PagePath } from '../../../../../utils/enums';
import { cacheImages } from '../../../../../utils/functions/cacheImages';
import { useAppNavigate } from '../../../../../utils/hooks/useAppNavigate';
import { useGetAndStoreTags } from '../../../../../utils/hooks/useGetAndStoreTags';
import { useGetSearchParamsObjectFromStoreVariables } from '../../../../../utils/hooks/useGetSearchParamsObjectFromStoreVariables';
import { structureGraphqlTariffData } from '../aggregatorUtils';
import AggregatorSearchBlockItem from './AggregatorSearchBlockItem';
import AggregatorSearchBlockPreviewItems from './AggregatorSearchBlockTemplateItems';
import AggregatorMenus from './menus/AggregatorMenus';
import AggregatorFullScreenFiltersModal from './modals/AggregatorFullScreenFiltersModal';
import './AggregatorSearchBlock.scss';

const AggregatorSearchBlock = () => {
  const dispatch = useDispatch();
  const screenWidth = useAppSelector((state) => state.main.dimensionsData.screenSize.width);
  const mainPageTariffs = useAppSelector((state) => state.aggregator.mainPageTariffs);

  const [fetchingInitialProducts, setFetchingInitialProducts] = useState(false);

  const [getTariffsByIdsLazyQuery] = useGetTariffsByIdsLazyQuery();
  const { getAndStoreTags } = useGetAndStoreTags();

  const getInitialProducts = async () => {
    setFetchingInitialProducts(true);
    try {
      const { data: fetchedTariffsData } = await getTariffsByIdsLazyQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
          ids: [247, 248, 249, 450, 451, 452],
          // ids: [247, 248, 249, 250, 450, 451, 452, 453],
        },
      });

      const fetchedTariffs = fetchedTariffsData?.mobile_service_tariffs;
      if (!fetchedTariffs) return;

      const structuredTariffs = fetchedTariffs.map((item) =>
        structureGraphqlTariffData(item)
      );

      dispatch(setMainPageTariffs(structuredTariffs.slice(0, 6) || []));

      const imageSources = structuredTariffs.reduce(
        (arr: string[], item) => [...arr, item.productImages[0]],
        []
      );
      await cacheImages(imageSources);
    } catch (e: any) {
      console.log(`Error in getInitialProducts: ${e || e?.message}`);
    } finally {
      setFetchingInitialProducts(false);
    }
  };

  useEffect(() => {
    getAndStoreTags();
    if (!mainPageTariffs.length) getInitialProducts();
  }, []);

  const { appNavigate } = useAppNavigate();
  const { searchParamsObject } = useGetSearchParamsObjectFromStoreVariables();
  const onSearchButtonClick = () => {
    appNavigate({
      pathname: PagePath.SearchPage,
      search: createSearchParams(searchParamsObject).toString(),
    });
  };

  return (
    <div className="aggregatorSearchBlockContainer">
      <AggregatorFullScreenFiltersModal onSearchButtonClick={onSearchButtonClick} />

      <AggregatorMenus
        fetchingInitialProducts={fetchingInitialProducts}
        onSearchButtonClick={onSearchButtonClick}
      />

      <div
        className="aggregatorSearchBlockProductsListContainer"
        style={{ marginTop: screenWidth < 1080 ? -105 : undefined }}
      >
        <div className="aggregatorSearchBlockProductsList">
          {fetchingInitialProducts ? (
            <AggregatorSearchBlockPreviewItems />
          ) : (
            mainPageTariffs.map((tariffData) => (
              <AggregatorSearchBlockItem key={tariffData.tariffId} tariffData={tariffData} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AggregatorSearchBlock;
