import { useEffect } from 'react';
import { socialMediaButtons } from '../../utils/appConstants';
import images from '../../utils/images';
import { MenuItem } from '../../utils/types';
import LogoBlock from '../LogoBlock/LogoBlock';
import SocialMediaButton from '../SocialMediaButton/SocialMediaButton';
import './Header.scss';

const HeaderMobileMenuModal = ({
  closeModal,
  scrollToConsultSection,
  companyMail,
  companyMailHref,
  companyPhone,
  companyPhoneHref,
  menuItems,
}: {
  closeModal: () => void;
  scrollToConsultSection: () => void;
  companyMail: string;
  companyMailHref: string;
  companyPhone: string;
  companyPhoneHref: string;
  menuItems: MenuItem[];
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const onMenuItemClick = (menuItem: MenuItem) => {
    closeModal();
    menuItem.onClick();
  };

  return (
    <div className="headerMenuModalContainer">
      <div className="headerContainer" style={{ padding: 0, maxWidth: 'unset' }}>
        <LogoBlock size={40} isClickable={true} onClick={closeModal}/>
        <button className="headerMenuButton opacity-on-hover" onClick={closeModal}>
          <img src={images.headerMobileMenuIconClose} className="headerMenuButtonIconClose" />
        </button>
      </div>
      <div className="headerMenuModalMainBlock">
        <a className="headerMenuCompanyPhone link opacity-on-hover" href={companyPhoneHref}>
          {companyPhone}
        </a>
        <a className="headerMenuEmail link opacity-on-hover" href={companyMailHref}>
          {companyMail}
        </a>
        <button onClick={scrollToConsultSection} className="headerConsultButtonMobile">
          {'Перезвоните мне'}
        </button>
        <div className="headerMenuItemsBlock">
          {menuItems.map((item) => (
            <button
              key={item.text}
              className={`headerMenuItem opacity-on-hover ${item.disabled ? 'headerMenuItemDisabled' : ''}`}
              onClick={() => onMenuItemClick(item)}
              disabled={item.disabled}
            >
              {item.text}
            </button>
          ))}
          <div className="headerSocialMediaButtonsContainerMobile">
            {socialMediaButtons.map((item) => (
              <SocialMediaButton item={item} key={item.href} size={42} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMobileMenuModal;
