import { isNumber } from 'lodash';
import AppSpinner from '../../../../../../components/AppSpinner/AppSpinner';
import { useAppSelector } from '../../../../../../redux/store';
import { useAggregatorMenuModal } from '../../../../../../utils/hooks/useAggregatorMenuModal';
import AggregatorCategoriesModal from '../modals/AggregatorCategoriesModal';
import AggregatorMenuArrow from './AggregatorMenuArrow';
import './AggregatorSearchBlockMenus.scss';

const AggregatorCategoryMenu = ({
  flex = 1,
  usingFullScreenModal = false,
  withMobileStyles = false,
  menuAsModal = true,
}: {
  flex?: number;
  usingFullScreenModal?: boolean;
  withMobileStyles?: boolean;
  menuAsModal?: boolean;
}) => {
  const { modalIsOpen, closeModal, toggleModal } = useAggregatorMenuModal({ usingFullScreenModal });

  const { fetching, structuredTagsData, selectedCategoryId } = useAppSelector((state) => state.aggregator);
  const selectedCategory = structuredTagsData.categories.find((item) => item.id === selectedCategoryId);

  const onMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleModal();
  }

  return (
    <div className="aggregatorSearchBlockFieldContainerOuter" style={{ flex }}>
      <button
        className="aggregatorSearchBlockFieldMenuButton"
        onClick={onMenuButtonClick}
        disabled={fetching}
      >
        <div className="aggregatorSearchBlockFieldTitle">{'Категория'}</div>

        <div
          className="aggregatorSearchBlockFieldContainerInner"
          style={{ borderColor: modalIsOpen ? '#0EC1C9' : undefined }}
        >
          <div className="aggregatorSearchBlockFieldContent">
            {fetching ? (
              <AppSpinner/>
            ) : isNumber(selectedCategoryId) ? (
              <>
                <img
                  className="aggregatorSearchBlockFieldSelectedItemIcon"
                  src={selectedCategory?.iconSource}
                />
                <div>{selectedCategory?.name}</div>
              </>
            ) : (
              <div className="aggregatorSearchBlockFieldSelectedItemName">
                {usingFullScreenModal ? 'Выберите' : 'Выберите категорию'}
              </div>
            )}
          </div>

          <AggregatorMenuArrow modalIsOpen={modalIsOpen} />
        </div>
      </button>

      {!usingFullScreenModal && (
        <AggregatorCategoriesModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          asModal={menuAsModal}
          withMobileStyles={withMobileStyles}
        />
      )}
    </div>
  );
};

export default AggregatorCategoryMenu;
