import { useMemo } from "react";
import { useAppSelector } from "../../redux/store";
import { SearchQueryParams } from "../enums";

export const useGetSearchParamsObjectFromStoreVariables = () => {
  const {
    selectedCategoryId,
    selectedAgeRangeId,
    selectedTagTargetIds
  } = useAppSelector(state => state.aggregator);

  const searchParamsObject = useMemo(() => {
    const params = new URLSearchParams();

    if (selectedCategoryId) params.append(SearchQueryParams.categoryId, selectedCategoryId.toString());
    if (selectedAgeRangeId) params.append(SearchQueryParams.ageRangeId, selectedAgeRangeId.toString());
    if (selectedTagTargetIds.length)
      params.append(SearchQueryParams.tagTargetIds, selectedTagTargetIds.toString());

    return params;
  }, [selectedCategoryId, selectedAgeRangeId, selectedTagTargetIds]);

  return { searchParamsObject };
}