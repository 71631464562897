import Slider from 'react-slick';
import { aggregatorButtons } from '../aggregatorConstants';
import AggregatorQuickSearchButton from './AggregatorQuickSearchButton';
import './AggregatorQuickSearchButtonsBlock.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppSelector } from '../../../../../redux/store';

const AggregatorQuickSearchButtonsBlock = () => {
  const screenWidth = useAppSelector(state => state.main.dimensionsData.screenSize.width);

  if (screenWidth >= 720)
    return (
      <div className="aggregatorQaggregatorQuickSearchButtonsContainer">
        {aggregatorButtons.map((buttonValues) => (
          <AggregatorQuickSearchButton
            key={buttonValues.tagId}
            iconImage={buttonValues.iconImage}
            title={buttonValues.title}
            tagId={buttonValues.tagId}
          />
        ))}
      </div>
    );

  return (
    <div className="aggregatorQaggregatorQuickSearchButtonsSliderContainer">
      <Slider
        className="aggregatorQaggregatorQuickSearchButtonsSlider"
        // infinite={true}
        // centerMode={true}
        speed={500}
        slidesToShow={6}
        slidesToScroll={1}
        arrows={false}

        swipeToSlide={true}
        variableWidth={false}
        cssEase={'ease'}

        autoplay={true}
        autoplaySpeed={1700}
        // responsive={responsiveDesignValues}
      >
        {aggregatorButtons.map((buttonValues) => (
          <AggregatorQuickSearchButton
            key={buttonValues.tagId}
            iconImage={buttonValues.iconImage}
            title={buttonValues.title}
            tagId={buttonValues.tagId}
          />
        ))}
      </Slider>
    </div>
  );
};

export default AggregatorQuickSearchButtonsBlock;
