import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  invalidPhoneNumberText,
} from '../../utils/appConstants';
import { validatePhoneNumber, withOpacity } from '../../utils/helper';
import { handleCallRequest } from '../../utils/requests/callRequest';
import { baseColors } from '../../utils/themeConstants';
import { ConsultCallData } from '../../utils/types';
import { yandexMetrics } from '../../utils/yandexMetrics';
import AppMaskedPhoneInput from '../AppMaskedPhoneInput/AppMaskedPhoneInput';
import ButtonWithSpinner from '../ButtonWithSpinner/ButtonWithSpinner';
import CommonInlineStyles from '../CommonInlineStyles';
import PrivacyPolicyTextBlock from '../PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import RequestProcessedModal from '../RequestProcessedModal/RequestProcessedModal';
import { useAppSelector } from '../../redux/store';

const RequestCallForm = ({
  consultData,
  callMeButtonText = 'Перезвоните мне',
  containerStyle,

  containerClass,
  inputsContainerClass,
  inputFieldClass,
  phoneNumberBlockClass,
  phoneNumberErrorTextClass,
  inputErrorStyle,
  callMeButtonClass,
  callMeButtonStyle,
  personalDataNoteClass,

  closeOuterModal,
}: {
  consultData: ConsultCallData;
  callMeButtonText?: string;
  containerStyle?: React.CSSProperties;

  containerClass: string;
  inputsContainerClass: string;
  inputFieldClass: string;
  phoneNumberBlockClass: string;
  phoneNumberErrorTextClass: string;
  inputErrorStyle?: React.CSSProperties;
  callMeButtonClass: string;
  callMeButtonStyle: React.CSSProperties;
  personalDataNoteClass: string;

  closeOuterModal?: () => void;
}) => {
  // ###################################################################################################
  // get tag names for operator
  const {
    structuredTagsData: {
      categories,
      ageRanges,
      tagTargets,
    },
    selectedCategoryId,
    selectedAgeRangeId,
    selectedTagTargetIds,
  } = useAppSelector((state) => state.aggregator);

  const selectedTagsStrings = useMemo(() => {
    const tags: string[] = [];

    const selectedCategory = categories.find(tag => tag.id === selectedCategoryId)?.name;
    if (selectedCategory) tags.push(selectedCategory);

    const selectedAgeRange = ageRanges.find(tag => tag.id === selectedAgeRangeId)?.name;
    if (selectedAgeRange) tags.push(selectedAgeRange);

    const selectedTagTargets = tagTargets.filter(tag => selectedTagTargetIds.includes(tag.id));
    tags.push(...selectedTagTargets.map(item => item.name));

    return tags;
  }, [selectedCategoryId, selectedAgeRangeId, selectedTagTargetIds]);

  // ###################################################################################################

  const [userName, setUserName] = useState('');
  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const [fieldReceivedInput, setFieldReceivedInput] = useState(false);

  const [userPhone, setUserPhone] = useState('');
  const [userPhoneValid, setUserPhoneValid] = useState(true);
  const onUserPhoneCheck = () => {
    const isValid = validatePhoneNumber(userPhone);
    setUserPhoneValid(isValid);
    return isValid;
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [requestIsSuccessful, setRequestIsSuccessful] = useState<boolean>();
  const openSuccessModal = ({ requestSuccessful }: { requestSuccessful?: boolean }) => {
    setModalIsOpen(true);
    setRequestIsSuccessful(requestSuccessful);
  }

  const closeSuccessModal = () => {
    setModalIsOpen(false);
    closeOuterModal?.();
  };

  const [processing, setProcessing] = useState(false);
  const onCallMeButtonClick = async () => {
    const isValid = onUserPhoneCheck();
    if (!isValid) return;

    // start spinner
    setProcessing(true);

    if (callMeButtonText === 'Подобрать программу') yandexMetrics.helpChooseProduct();
    else                                            yandexMetrics.phoneCallRequest();

    const success = await handleCallRequest({
      userName: userName,
      userPhone: userPhone,
      programDevName: consultData.programDevName,
      durationInMonths: consultData.durationInMonths,
      tags: selectedTagsStrings.length ? selectedTagsStrings : consultData.tags,
    })

    // stop spinner, show success modal, reset phone/name fields
    openSuccessModal({ requestSuccessful: success });
    setProcessing(false);

    if (success) {
      setUserPhone('');
      setUserName('');
    }
  };

  // ###################################################################################################
  const CallMeButton = ({ className, style }: { className: string; style: React.CSSProperties }) => {
    const disabled = processing || !fieldReceivedInput || !userPhoneValid;

    return (
      <ButtonWithSpinner
        onClick={onCallMeButtonClick}
        title={callMeButtonText}
        fetchingTitle={'Обработка запроса'}
        fetching={processing}
        disabled={disabled}
        style={{
          ...style,
          ...(disabled
            ? {
                ...CommonInlineStyles.buttonDisabled,
                backgroundColor: withOpacity(style.backgroundColor as string, 0.85),
                color: style?.color,
              }
            : undefined),
        }}
        className={`app-button ${className}`}
        spinnerSize={18}
        spinnerColor={style.color || baseColors.white}
      />
    );
  }

  // ###################################################################################################
  return (
    <div className={containerClass}>
      <RequestProcessedModal
        modalIsOpen={modalIsOpen}
        closeModal={closeSuccessModal}
        requestIsSuccessful={requestIsSuccessful}
      />

      <div className={inputsContainerClass} style={containerStyle}>
        <input
          type={'text'}
          autoComplete={'name'}
          maxLength={100}
          placeholder={'Ваше имя'}
          value={userName}
          onChange={onUserNameChange}
          className={`input-field-dynamic-outline ${inputFieldClass}`}
        />
        <div className={phoneNumberBlockClass}>
          <AppMaskedPhoneInput
            userPhone={userPhone}
            setUserPhone={setUserPhone}
            userPhoneValid={userPhoneValid}
            setUserPhoneValid={setUserPhoneValid}
            setFieldReceivedInput={setFieldReceivedInput}
            className={`input-field-dynamic-outline ${inputFieldClass}`}
            errorStyle={inputErrorStyle}
          />
          {!userPhoneValid && <div className={phoneNumberErrorTextClass}>{invalidPhoneNumberText}</div>}
        </div>
        <CallMeButton className={callMeButtonClass} style={callMeButtonStyle} />
      </div>

      <PrivacyPolicyTextBlock
        className={personalDataNoteClass}
        mainText={'Нажимая на эту кнопку вы даёте свое '}
      />
    </div>
  );
};

export default RequestCallForm;
