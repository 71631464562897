export enum PagePath {
  MainPage = '/',
  SearchPage = '/search',
  OrderStatusPage = "/order", // this is where backend navigates us, and should remain the same
  SentFreeGuidePage = "/sentfreeguide", // this is where backend navigates us, and should remain the same
  ItemDescriptionPage = "/item",
  ValuesPage = "/values",
}

export enum SearchQueryParams {
  categoryId = 'categoryId',
  ageRangeId = 'ageRangeId',
  tagTargetIds = 'tagTargetIds',
}

export enum SectionName {
  Values = 'Values',
  Products = 'Products',
  // ServicesList = 'ServicesList',
  ConsultSection1 = 'ConsultSection1',
  JoinUs = 'JoinUs',
  ConsultSection2 = 'ConsultSection2',
  FAQ = 'FAQ',
}

export enum Periodicity {
  Units = 'шт.',
  Hours = 'час',
  Days = 'день',
  Months = 'месяц',
  Years = 'год',
}