import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConsultCallData, setSectionToReturnTo, setSectionToScrollTo } from '../../redux/mainReducer';
import { RootState } from '../../redux/store';
import { SectionName } from '../../utils/enums';
import { scrollToBlockRef } from '../../utils/helper';
import { defaultConsultCallData } from '../../utils/requestApi';
import BaseLayout from '../BaseLayout';
import AggregatorBlock from './components/1.Aggregator/AggregatorBlock';
import ValuesBlock from './components/2.ValuesBlock/ValuesBlock';
import MainBannerSummary from './components/3.MainBannerSummary/MainBannerSummary';
import GetFreeGuideBlock from './components/4.GetFreeGuideBlock/GetFreeGuideBlock';
import ExpertsAndMentors from './components/5.1.ExpertsAndMentors/ExpertsAndMentors';
import ReviewsBlock from './components/5.2.ReviewsBlock/ReviewsBlock';
import JoinUs from './components/6.JoinUs/JoinUs';
import FAQ from './components/7.FAQ/FAQ';
import Consult from './components/8.Consult/Consult';
import './MainPage.scss';

const MainPage = () => {
  const valuesBlockRef = useRef<HTMLDivElement>(null);
  // const productsRef = useRef<HTMLDivElement>(null);
  const consultRef1 = useRef<HTMLDivElement>(null);
  const joinUsRef = useRef<HTMLDivElement>(null);
  const consultRef2 = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (sectionName: SectionName | null) => {
    switch (sectionName) {
      case SectionName.Values:          scrollToBlockRef(valuesBlockRef);      break;
      // case SectionName.Products:        scrollToBlockRef(productsRef, 0);      break;
      case SectionName.ConsultSection1: scrollToBlockRef(consultRef1);         break;
      case SectionName.JoinUs:          scrollToBlockRef(joinUsRef);           break;
      case SectionName.ConsultSection2: scrollToBlockRef(consultRef2);         break;
      case SectionName.FAQ:             scrollToBlockRef(faqRef);              break;
      
      default: return;
    }
  }
  
  const dispatch = useDispatch();
  const sectionNameToReturnTo = useSelector((state: RootState) => state.main.sectionNameToReturnTo);
  const sectionNameToScrollTo = useSelector((state: RootState) => state.main.sectionNameToScrollTo);

  useEffect(() => {
    if (sectionNameToReturnTo) {
      // if we have values for both sectionNameToReturnTo AND sectionNameToScrollTo,
      // sectionNameToScrollTo should have priority and sectionNameToReturnTo should be ignored & reset
      if (!sectionNameToScrollTo) scrollToSection(sectionNameToReturnTo);

      dispatch(setSectionToReturnTo(null));
    }
  }, []);

  useEffect(() => {
    if (sectionNameToScrollTo) {
      scrollToSection(sectionNameToScrollTo);
      dispatch(setSectionToScrollTo(null));
    }
  }, [sectionNameToScrollTo]);

  // reset consult call data
  useEffect(() => {
    dispatch(setConsultCallData(defaultConsultCallData));
  }, []);

  return (
    <BaseLayout
      hideSectionLinks={false}
      content={
        <div className="mainPageContainerInner">
          <AggregatorBlock />
          <ValuesBlock blockRef={valuesBlockRef} />
          <MainBannerSummary />
          <GetFreeGuideBlock />

          <ExpertsAndMentors />
          <ReviewsBlock />

          <Consult isFirstSection blockRef={consultRef1} />
          <JoinUs blockRef={joinUsRef} />
          <FAQ blockRef={faqRef} />
          <Consult blockRef={consultRef2} />
        </div>
      }
    />
  );
}

export default MainPage;
