import './AggregatorSearchBlockTemplateItems.scss';
import './AggregatorSearchBlockItem.scss';

const AggregatorSearchBlockTemplateItem = () => {
  return (
    <div className="aggregatorSearchBlockProductItemContainer aggregatorSearchBlockProductTemplateContainer">
      <div className="aggregatorSearchBlockProductItemImage aggregatorSearchBlockProductTemplateImage animatedGradient" />
      <div className="aggregatorSearchBlockProductItemContainerInner aggregatorSearchBlockProductTemplateContainerInner">
        <div className="aggregatorSearchBlockProductItemTitle aggregatorSearchBlockProductTemplateTitle animatedGradient" />
        <div className="aggregatorSearchBlockProductItemPrice aggregatorSearchBlockProductTemplatePrice animatedGradient" />
      </div>
    </div>
  );
};

const templateItems = [
  <AggregatorSearchBlockTemplateItem key={0} />,
  <AggregatorSearchBlockTemplateItem key={1} />,
  <AggregatorSearchBlockTemplateItem key={2} />,
  <AggregatorSearchBlockTemplateItem key={3} />,
  <AggregatorSearchBlockTemplateItem key={4} />,
  <AggregatorSearchBlockTemplateItem key={5} />,
]

const AggregatorSearchBlockTemplateItems = () => {
  return (
    <>
      {templateItems.map(item => item)}
    </>
  );
};

export default AggregatorSearchBlockTemplateItems;
