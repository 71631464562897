import { useDispatch } from 'react-redux';
import { toggleTagTargetById } from '../../../redux/aggregatorReducer';
import images from '../../../utils/images';
import { AggregatorTagDataWithPicture } from '../../../utils/typesSearch';
import './SearchPageSelectedTagTargetItem.scss';

const SearchPageSelectedTagTargetItem = ({ tagTarget }: { tagTarget: AggregatorTagDataWithPicture }) => {
  const dispatch = useDispatch();
  const removeTagTarget = () => {
    dispatch(toggleTagTargetById(tagTarget.id));
  }
  
  return (
    <div className="searchPageSelectedTagTargetItem">
      <img className="searchPageSelectedTagTargetIcon" src={tagTarget.iconSource} />
      <div className="searchPageSelectedTagTargetItemTitle">{tagTarget.name}</div>
      <button className="searchPageSelectedTagTargetRemoveButton opacity-on-hover" onClick={removeTagTarget}>
        <img className="searchPageSelectedTagTargetRemoveIcon" src={images.aggregatorRemoveItem} />
      </button>
    </div>
  );
};

export default SearchPageSelectedTagTargetItem;