import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { GraphqlProductData, GraphqlTariffData, ProductsSortType } from '../utils/types';
import { AggregatorTagData, AggregatorTagDataWithPicture } from '../utils/typesSearch';

interface StructuredTagsData {
  categories: AggregatorTagDataWithPicture[],
  ageRanges: AggregatorTagData[],
  tagTargets: AggregatorTagDataWithPicture[],
  categoryIds: number[],
  ageRangeIds: number[],
  tagTargetIds: number[],
}

interface SearchState {
  fetching: boolean;

  structuredTagsData: StructuredTagsData;

  selectedCategoryId: number | null;
  selectedAgeRangeId: number | null;
  selectedTagTargetIds: number[];

  selectedSortType: ProductsSortType;
  fullScreenFiltersModalIsOpen: boolean;

  selectedProductData: GraphqlProductData | null;
  selectedTariffId: number;
  mainPageTariffs: GraphqlTariffData[];
  tagImageUrlsForCaching: string[];

  // products: GraphqlProductData[]; // TODO: ? store fetched products here and use them in other routes
  // tariffs: GraphqlTariffData[]; // TODO: ? store fetched products here and use them in other routes
};

const initialState: SearchState = {
  fetching: false,

  structuredTagsData: {
    categories: [],
    ageRanges: [],
    tagTargets: [],
    categoryIds: [],
    ageRangeIds: [],
    tagTargetIds: [],
  },

  selectedCategoryId: null,
  selectedAgeRangeId: null,
  selectedTagTargetIds: [],

  selectedSortType: ProductsSortType.PopularFirst,
  fullScreenFiltersModalIsOpen: false,

  selectedProductData: null,
  selectedTariffId: 0,
  mainPageTariffs: [],
  tagImageUrlsForCaching: [],
  
  // products: [],
  // tariffs: [],
};

const settingsSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // ####################################################################################
    setFetching: (state, { payload }: PayloadAction<boolean>) => {
      state.fetching = payload;
    },
    // ####################################################################################
    setStructuredTagsData: (state, { payload }: PayloadAction<StructuredTagsData>) => {
      state.structuredTagsData = payload;
    },
    // ####################################################################################
    setSelectedCategoryId: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedCategoryId = payload;
    },
    setSelectedAgeRangeId: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedAgeRangeId = payload;
    },
    setSelectedTagTargetIds: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedTagTargetIds = payload;
    },
    toggleTagTargetById: (state, { payload }: PayloadAction<number>) => {
      const tagTargetId = payload;
      let updatedSelectedTagTargetIds = [...state.selectedTagTargetIds];

      const exists = updatedSelectedTagTargetIds.includes(tagTargetId);
      if (exists) {
        updatedSelectedTagTargetIds = updatedSelectedTagTargetIds.filter(item => item !== tagTargetId);
      } else {
        updatedSelectedTagTargetIds.push(tagTargetId);
      }

      state.selectedTagTargetIds = updatedSelectedTagTargetIds;
    },
    // ####################################################################################
    setSelectedSortType: (state, { payload }: PayloadAction<ProductsSortType>) => {
      state.selectedSortType = payload;
    },
    setFullScreenFiltersModalIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.fullScreenFiltersModalIsOpen = payload;
    },
    // ####################################################################################
    setSelectedProductData: (state, { payload }: PayloadAction<GraphqlProductData | null>) => {
      state.selectedProductData = payload;
    },
    setSelectedTariffId: (state, { payload }: PayloadAction<number>) => {
      state.selectedTariffId = payload;
    },
    setMainPageTariffs: (state, { payload }: PayloadAction<GraphqlTariffData[]>) => {
      state.mainPageTariffs = payload;
    },
    setTagImageUrlsForCaching: (state, { payload }: PayloadAction<string[]>) => {
      state.tagImageUrlsForCaching = payload;
    },
    // ####################################################################################
  },
});

export const {
  setFetching,
  
  setStructuredTagsData,

  setSelectedCategoryId,
  setSelectedAgeRangeId,
  setSelectedTagTargetIds,
  toggleTagTargetById,

  setSelectedSortType,
  setFullScreenFiltersModalIsOpen,

  setSelectedProductData,
  setSelectedTariffId,
  setMainPageTariffs,
  setTagImageUrlsForCaching,
} = settingsSlice.actions;

export default settingsSlice.reducer;
