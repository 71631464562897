import RequestCallForm from '../../../components/RequestCallForm/RequestCallForm';
import { useGetSelectedTagsFromStore } from '../../../utils/hooks/useGetSelectedTagsFromStore';
import { defaultConsultCallData } from '../../../utils/requestApi';
import { ConsultCallData } from '../../../utils/types';
import './SearchPageConsultBanner.scss';

const contentDefault = {
  title: 'Не нашли нужную программу?',
  note: 'Мы готовы создать персональную программу под ваш запрос',
}

const contentAlt = {
  title: 'Нужна помощь с выбором?',
  note: 'Наши специалисты помогут подобрать подходящую программу на основе ваших целей и пожеланий',
}

const SearchPageConsultBanner = ({ usingAltStyle }: { usingAltStyle?: boolean }) => {
  const selectedTags = useGetSelectedTagsFromStore();

  const consultData: ConsultCallData = {
    ...defaultConsultCallData,
    tags: selectedTags,
  }
  const content = usingAltStyle ? contentAlt : contentDefault;

  return (
    <div
      className="searchPageConsultBannerContainer"
      style={usingAltStyle ? { backgroundColor: '#1D595B', color: 'white' } : {}}
    >
      <div
        className={
          usingAltStyle ? 'searchPageConsultBannerTitleBlockAlt' : 'searchPageConsultBannerTitleBlock'
        }
      >
        <div className="searchPageConsultBannerTitle">{content.title}</div>
        <div className="searchPageConsultBannerNote">{content.note}</div>
      </div>

      <RequestCallForm
        consultData={consultData}
        containerClass="searchPageConsultBannerCallForm"
        inputsContainerClass="searchPageConsultBannerCallFormInputsContainer"
        inputFieldClass="searchPageConsultBannerCallFormInput"
        phoneNumberBlockClass="searchPageConsultBannerCallFormPhoneNumberBlock"
        phoneNumberErrorTextClass={
          usingAltStyle
            ? 'searchPageConsultBannerCallFormPhoneNumberErrorTextAlt'
            : 'searchPageConsultBannerCallFormPhoneNumberErrorText'
        }
        callMeButtonClass="searchPageConsultBannerCallFormCallMeButton"
        callMeButtonStyle={{ backgroundColor: '#FFFFFF', color: '#1A3233' }}
        personalDataNoteClass="searchPageConsultBannerCallFormPersonalDataNote"
        inputErrorStyle={{ outline: `2px solid ${usingAltStyle ? '#FF5F5F' : '#9B2C2C'}` }}
      />
    </div>
  );
};

export default SearchPageConsultBanner;