import { baseColors } from "../utils/themeConstants";
import { StyleSheet } from "../utils/types";

export default StyleSheet.create({
  inputFieldErrorBorder: {
    outline: `2px solid ${baseColors.c9B2C2C}`,
    outlineOffset: 0,
  },
  buttonDisabled: {
    backgroundColor: baseColors.c85E0E4,
    color: baseColors.white,
    border: 0,
    fontWeight: 600,
    cursor: 'default',
  },
});
