// misc images
import logoIcon from '../images/logoIcon.png';
import checkboxEmpty from '../images/checkboxEmpty.png';
import checkboxChecked from '../images/checkboxChecked.png';
import appleIcon from '../images/appleIcon.png';
import googlePlayIcon from '../images/googlePlayIcon.png';
import telegramIcon from '../images/iconTelegram.png';
import telegramIconHover from '../images/iconTelegramHover.png';
import vkIcon from '../images/iconVk.png';
import vkIconHover from '../images/iconVkHover.png';

// modals
import modalSuccess from '../images/modalSuccess.png';
import modalError from '../images/modalError.png';
import modalCross from '../images/modalCross.png';
import purchaseModalCreditCards from '../images/purchaseModalCreditCards.png';

// aggregator
import aggregatorMenuArrowDown from '../images/aggregator/aggregatorMenuArrowDown.png';
import aggregatorMenuBackground from '../images/aggregator/aggregatorMenuBackground.png';
import allCategoriesIcon from '../images/aggregator/allCategoriesIcon.png';
import aggregatorModalCross from '../images/aggregator/aggregatorModalCross.png';
import aggregatorMenuChecked from '../images/aggregator/aggregatorMenuChecked.png';
import aggregatorMenuUnchecked from '../images/aggregator/aggregatorMenuUnchecked.png';
import aggregatorSortIcon from '../images/aggregator/aggregatorSortIcon.png';
import aggregatorRemoveItem from '../images/aggregator/aggregatorRemoveItem.png';
import aggregatorPersonalProgramImage from '../images/aggregator/aggregatorPersonalProgramImage.jpg';
// reviews
import reviewsCarouselArrow from '../images/reviewsCarouselArrow.png';
import reviewer1 from '../images/reviewers/reviewer1.png';
import reviewer2 from '../images/reviewers/reviewer2.png';
import reviewer3 from '../images/reviewers/reviewer3.png';
import reviewer4 from '../images/reviewers/reviewer4.png';
import reviewBackground from '../images/reviewers/reviewBackground.png';
import reviewBackgroundMobile from '../images/reviewers/reviewBackgroundMobile.png';

// experts and mentors
import expertsAndMentorsImage1 from '../images/expertsAndMentorsImage1.png';
import expertsAndMentorsImage2 from '../images/expertsAndMentorsImage2.png';
import expertsAndMentorsImage3 from '../images/expertsAndMentorsImage3.png';

// successful payment page
// directly imported in styles
import successfulPaymentSecondColumnBackground from '../images/successfulPaymentSecondColumnBackground.png';

// item description page
import backArrowLeft from '../images/backArrowLeft.png';

// learn more page
import learnMoreBannerDesktop from '../images/learnMoreBannerDesktop.webp';
import learnMoreBannerMobile from '../images/learnMoreBannerDesktop.webp';
// import learnMoreBannerMobile from '../images/learnMoreBannerMobile.png';

// main page blocks
import headerMobileMenuIcon from '../images/headerMobileMenuButtonIcon.png';
import headerMobileMenuIconClose from '../images/headerMobileMenuButtonClose.png';

import mainBannerBg1 from '../images/mainBannerBg1.png';
import mainBannerBg2 from '../images/mainBannerBg2.png';

// offer v2 main banner
import mainBannerSummaryBlockHeader from '../images/mainBannerSummaryBlockHeader.png';

import getFreeGuideBanner from '../images/getFreeGuideBanner.png';
import getFreeGuideBannerMobile from '../images/getFreeGuideBannerMobile.png';
import getFreeGuideModalBackground from '../images/getFreeGuideModalBackground.png';
import getFreeGuideModalBackgroundMobile from '../images/getFreeGuideModalBackgroundMobile.png';

import servicesLine1 from '../images/servicesLine1.png';
import servicesGiftIcon from '../images/servicesGiftIcon.png';

import consult1Background from '../images/consultEtnamedLogo.svg';
import consult2Background from '../images/consult2Background.png';
import consult2RightImageBlock from '../images/consult2RightImageBlock.png';
import consult23Cards from '../images/consult23Cards.png';

import joinUsPhoneImage from '../images/joinUsPhoneImage.png';
import joinUsPhoneImageMobile from '../images/joinUsPhoneImageMobile.png';
import joinUsAppEvents from '../images/alreadyInAppEvents.png';

import faqRectangleBg from '../images/faqRectangleBg.png';
import faqButtonIcon from '../images/faqButtonIcon.png';

// file extension icons
import documentIcon from '../images/fileExtensions/documentIcon.png';
import extensionIcon_zip from '../images/fileExtensions/zip.png';
import extensionIcon_3gp from '../images/fileExtensions/3gp.png';
import extensionIcon_acc from '../images/fileExtensions/acc.png';
import extensionIcon_avi from '../images/fileExtensions/avi.png';
import extensionIcon_bmp from '../images/fileExtensions/bmp.png';
import extensionIcon_doc from '../images/fileExtensions/doc.png';
import extensionIcon_docx from '../images/fileExtensions/docx.png';
import extensionIcon_flac from '../images/fileExtensions/flac.png';
import extensionIcon_gif from '../images/fileExtensions/gif.png';
import extensionIcon_jpeg from '../images/fileExtensions/jpeg.png';
import extensionIcon_jpg from '../images/fileExtensions/jpg.png';
import extensionIcon_mkv from '../images/fileExtensions/mkv.png';
import extensionIcon_mov from '../images/fileExtensions/mov.png';
import extensionIcon_mp3 from '../images/fileExtensions/mp3.png';
import extensionIcon_mp4 from '../images/fileExtensions/mp4.png';
import extensionIcon_ods from '../images/fileExtensions/ods.png';
import extensionIcon_odt from '../images/fileExtensions/odt.png';
import extensionIcon_pdf from '../images/fileExtensions/pdf.png';
import extensionIcon_png from '../images/fileExtensions/png.png';
import extensionIcon_ppt from '../images/fileExtensions/ppt.png';
import extensionIcon_pptx from '../images/fileExtensions/pptx.png';
import extensionIcon_rar from '../images/fileExtensions/rar.png';
import extensionIcon_rtf from '../images/fileExtensions/rtf.png';
import extensionIcon_svg from '../images/fileExtensions/svg.png';
import extensionIcon_tif from '../images/fileExtensions/tif.png';
import extensionIcon_txt from '../images/fileExtensions/txt.png';
import extensionIcon_wav from '../images/fileExtensions/wav.png';
import extensionIcon_xls from '../images/fileExtensions/xls.png';
import extensionIcon_xlsx from '../images/fileExtensions/xlsx.png';

// emojis:
import stop from '../images/emojis/stop.png';
import document from '../images/emojis/document.png';
import rocket from '../images/emojis/rocket.png';
import handshake from '../images/emojis/handshake.png';
import strength from '../images/emojis/strength.png';
import run from '../images/emojis/run.png';
import hospital from '../images/emojis/hospital.png';
import meditate from '../images/emojis/meditate.png';
import salad from '../images/emojis/salad.png';
import pregnant from '../images/emojis/pregnant.png';
import mail from '../images/emojis/mail.png';
import explosion from '../images/emojis/explosion.png';

export default {
  // misc images
  logoIcon,

  checkboxEmpty,
  checkboxChecked,
  appleIcon,
  googlePlayIcon,
  telegramIcon,
  telegramIconHover,
  vkIcon,
  vkIconHover,

  reviewsCarouselArrow,
  reviewer1,
  reviewer2,
  reviewer3,
  reviewer4,
  reviewBackground,
  reviewBackgroundMobile,

  // modals
  modalSuccess,
  modalError,
  modalCross,
  purchaseModalCreditCards,

  // aggregator
  aggregatorMenuArrowDown,
  aggregatorMenuBackground,
  allCategoriesIcon,
  aggregatorModalCross,
  aggregatorMenuChecked,
  aggregatorMenuUnchecked,
  aggregatorSortIcon,
  aggregatorRemoveItem,
  aggregatorPersonalProgramImage,

  // experts and mentors
  expertsAndMentorsImage1,
  expertsAndMentorsImage2,
  expertsAndMentorsImage3,


  // successful payment page
  successfulPaymentSecondColumnBackground,

  // item description page
  backArrowLeft,

  // learn more page
  learnMoreBannerDesktop,
  learnMoreBannerMobile,

  // main page blocks
  headerMobileMenuIcon,
  headerMobileMenuIconClose,
  mainBannerBg1,
  mainBannerBg2,
  mainBannerSummaryBlockHeader,
  
  getFreeGuideBanner,
  getFreeGuideBannerMobile,
  getFreeGuideModalBackground,
  getFreeGuideModalBackgroundMobile, // imported directly in scss
  
  servicesLine1,
  servicesGiftIcon,

  consult1Background,
  consult2Background,
  consult2RightImageBlock,
  consult23Cards,

  joinUsPhoneImage,
  joinUsPhoneImageMobile,
  joinUsAppEvents,

  faqRectangleBg,
  faqButtonIcon,

  // file extension icons
  documentIcon,
  extensionIcon_zip,
  extensionIcon_3gp,
  extensionIcon_acc,
  extensionIcon_avi,
  extensionIcon_bmp,
  extensionIcon_doc,
  extensionIcon_docx,
  extensionIcon_flac,
  extensionIcon_gif,
  extensionIcon_jpeg,
  extensionIcon_jpg,
  extensionIcon_mkv,
  extensionIcon_mov,
  extensionIcon_mp3,
  extensionIcon_mp4,
  extensionIcon_ods,
  extensionIcon_odt,
  extensionIcon_pdf,
  extensionIcon_png,
  extensionIcon_ppt,
  extensionIcon_pptx,
  extensionIcon_rar,
  extensionIcon_rtf,
  extensionIcon_svg,
  extensionIcon_tif,
  extensionIcon_txt,
  extensionIcon_wav,
  extensionIcon_xls,
  extensionIcon_xlsx,

  // emojis
  explosion,
  stop,
  document,
  rocket,
  handshake,
  strength,

  run,
  hospital,
  meditate,
  salad,
  pregnant,
  mail,
};