import { useSearchParams } from 'react-router-dom';
import { useOnReturnToMainPageButtonClick } from '../../utils/hooks/useOnReturnToMainPageButtonClick';
import { useScrollToTop } from '../../utils/hooks/useScrollToTop';
import images from '../../utils/images';
import BaseLayout from '../BaseLayout';
import { learnMoreDataArray } from './valuesPageData';
import './ValuesPage.scss';

const ValuesPage = () => {
  useScrollToTop({});

  // use location object to get query parameters (order id) from e.g. .../xxx?id=123
  const [searchParams] = useSearchParams();
  const itemId = searchParams.get('id');
  const learnMoreData = learnMoreDataArray[itemId ? parseInt(itemId) : 0];

  const onReturnButtonClick = useOnReturnToMainPageButtonClick();

  return (
    <BaseLayout
      hideSectionLinks={false}
      content={
        <div className="learnMorePageContainer">
          <div className="learnMorePageBackButton link opacity-on-hover" onClick={onReturnButtonClick}>
            <img className="learnMorePageBackButtonImage" src={images.backArrowLeft} />
            {'Назад'}
          </div>
          <div className="learnMorePageTitle">{learnMoreData.title}</div>

          <div className="learnMorePageMainBlock">
            <div className="learnMorePageMainColumn">
              <div>{learnMoreData.mainTexts[0]}</div>

              <img className="learnMorePageBannerMobile" src={images.learnMoreBannerMobile} />

              {learnMoreData.mainTexts.slice(1).map((text) => (
                <div key={text}>{text}</div>
              ))}

              <button className="app-button learnMorePageBottomReturnButton" onClick={onReturnButtonClick}>
                {'На главную'}
              </button>
            </div>

            <div className="learnMorePageBannerDesktopContainer">
              <img
                className="learnMorePageBannerDesktop"
                src={images.learnMoreBannerDesktop}
                width={522}
                height={264}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default ValuesPage;