import { useRef, useState } from 'react';
import { sleep } from '../../../../utils/helper';

import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import ReviewItem from './components/ReviewItem';
import images from '../../../../utils/images';
import reviewsArray from './reviewsArray';
import './ReviewsBlock.scss';
import { useAppSelector } from '../../../../redux/store';

const ReviewsBlock = () => {
  const useMobileDesign = useAppSelector(state => state.main.dimensionsData.isVerticallyOriented);

  const title = 'Отзывы';

  const getMaxItems = () => {
    // if (useMobileDesign) return Math.min(reviewsArray.length, 1.1);
    if (useMobileDesign) return 1;
    else return 2;
  }
  const maxItems = getMaxItems();

  const sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const [arrowsEnabled, setArrowsEnabled] = useState(true);

  const infiniteScroll = true;
  const previousArrowEnabled = infiniteScroll || (currentSlide > 0);
  const nextArrowEnabled = infiniteScroll || (currentSlide < reviewsArray.length - maxItems);

  const onPreviousArrowClick = () => {
    if (!previousArrowEnabled || !arrowsEnabled) return;
    sliderRef?.current!.slickPrev();
    setCurrentSlide(currentSlide - 1);
    tempDisableButtons();
  };

  const onNextArrowClick = () => {
    if (!nextArrowEnabled || !arrowsEnabled) return;
    sliderRef?.current!.slickNext();
    setCurrentSlide(currentSlide + 1);
    tempDisableButtons();
  };

  const transitionDuration = 300;
  const tempDisableButtons = () => {
    setArrowsEnabled(false);
    sleep(transitionDuration + 100).then(() => setArrowsEnabled(true));
  };

  return (
    <div className="reviewsBlockContainer">
      <div className="reviewsBlockTitleContainer">
        <div className="reviewsBlockTitle">{title}</div>

        {!useMobileDesign &&<div className='reviewsBlockArrows'>
          <div className='reviewsBlockArrowContainer' onClick={onPreviousArrowClick}>
            <img
              src={images.reviewsCarouselArrow}
              alt=""
              className='reviewsBlockArrowLeft'
              style={{ opacity: previousArrowEnabled ? 1 : 0.15 }}
            />
          </div>
          <div className='reviewsBlockArrowContainer' onClick={onNextArrowClick}>
            <img
              src={images.reviewsCarouselArrow}
              alt=""
              className='reviewsBlockArrow'
              style={{ opacity: previousArrowEnabled ? 1 : 0.15 }}
            />
          </div>
        </div>}
      </div>
      

      <div className='reviewsBlockSlidesContainer'> 
        <div className='reviewsBlockOpacityBlockLeft' style={{ opacity: useMobileDesign ? 0 : 1 }} />
        <Slider
          adaptiveHeight={false}
          ref={sliderRef}
          className={"reviewsBlockSlider"}
          // centerMode={!useMobileDesign}
          centerMode={useMobileDesign}
          infinite={infiniteScroll}
          centerPadding={useMobileDesign ? "0px" : "80px"}
          initialSlide={useMobileDesign ? 0 : 1}
          slidesToShow={maxItems}
          swipe={useMobileDesign}
          speed={transitionDuration}
          arrows={false}
          dots={true}
          dotsClass="reviewsBlockSliderDots"
          // autoplay={true}
          // autoplaySpeed={2700}
        >
          {reviewsArray.map((item, index) => (
            <ReviewItem
              reviewItemData={item}
              key={`${item.reviewText}${reviewsArray.length}${index}`}
              useMobileDesign={useMobileDesign}
            />
          ))}
        </Slider>
        <div className='reviewsBlockOpacityBlockRight' style={{ opacity: useMobileDesign ? 0 : 1 }}/>
      </div>
    </div>
  );
};

export default ReviewsBlock;