import AppLink from '../../../../../components/AppLink/AppLink';
import { getProductDescriptionPageNavLink } from '../../../../../utils/helper';
import { getImageUrlForCss } from '../../../../../utils/stringHelper';
import { GraphqlTariffData } from '../../../../../utils/types';
import { yandexMetrics } from '../../../../../utils/yandexMetrics';
import './AggregatorSearchBlockItem.scss';

const AggregatorSearchBlockItem = ({ tariffData }: { tariffData: GraphqlTariffData }) => {
  const itemDescriptionNavLink = getProductDescriptionPageNavLink(tariffData.productId, tariffData.tariffId);

  const onProductDetailsClick = () => {
    yandexMetrics.productDetailsClick();
  }

  return (
    <AppLink
      className="aggregatorSearchBlockProductItemContainer"
      to={itemDescriptionNavLink}
      onClick={onProductDetailsClick}
    >
      <div
        className="aggregatorSearchBlockProductItemImage"
        style={{ backgroundImage: getImageUrlForCss(tariffData.productImages[0]) }}
      />
      <div className="aggregatorSearchBlockProductItemContainerInner">
        <div className="aggregatorSearchBlockProductItemTitle">{tariffData.productTitle}</div>
        <div className="aggregatorSearchBlockProductItemPrice">{tariffData.tariffPriceText}</div>
      </div>
    </AppLink>
  );
};

export default AggregatorSearchBlockItem;
