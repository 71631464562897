import { CSSProperties, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CookiesConsentNotification from '../components/CookiesConsentNotification/CookiesConsentNotification';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { RootState } from '../redux/store';
import { useSetTitle } from '../utils/hooks/useSetTitle';
import { useTrackScreenSize } from '../utils/hooks/useTrackScreenSize';
import { isProd } from '../utils/requestApi';
import { getBuildDateString } from '../utils/stringHelper';
import './BaseLayout.scss';

const buildDateInfoString = `#upd. ${getBuildDateString()} (${process.env.REACT_APP_ENV})`;

const BaseLayout = ({
  content,
  hideSectionLinks,
  hideFooter,
  style,
}: {
  content: ReactNode;
  hideSectionLinks: boolean;
  hideFooter?: boolean;
  style?: CSSProperties;
}) => {
  useTrackScreenSize();
  useSetTitle();

  useEffect(() => {
    console.log(buildDateInfoString);
    window.ym(96548425, "init", {triggerEvent: true});
  }, []);
  
  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);

  return (
    <div className="appContainer" style={style}>
      {!isProd && <div className="buildDateInfoString">{buildDateInfoString}</div>}

      {blockInterface && <div className="disablePageOverlay" />}

      <CookiesConsentNotification />

      <Header hideSectionLinks={hideSectionLinks} />

      {content}

      {!hideFooter && <Footer hideSectionLinks={hideSectionLinks} />}
    </div>
  );
};

export default BaseLayout;
