import { useMemo } from 'react';
import { PagePath } from '../../../utils/enums';
import images from '../../../utils/images';
import { getDurationText } from '../../../utils/stringHelper';
import { GraphqlTariffData } from '../../../utils/types';
import ItemDescriptionPageMainBannerCards from './ItemDescriptionPageMainBannerCards';
import './ItemDescriptionPageMainBannerNew.scss';
import { useAppNavigate } from '../../../utils/hooks/useAppNavigate';

const ItemDescriptionPageMainBannerNew = ({
  tariffData,
  openPurchaseModal,
}: {
  tariffData: GraphqlTariffData;
  openPurchaseModal: () => void;
}) => {
  const { appNavigate, location } = useAppNavigate();

  const onBackButtonClick = () => {
    if (location.state?.from) {
      appNavigate(-1);
    } else {
      appNavigate(PagePath.MainPage);
    }
  }

  const {
    productTitle,
    productImages,
    periodicityName,
    quantity,
    descriptionShort,
    tariffName,
    tariffPriceText,
    tariffPurchaseButtonText,
  } = tariffData;

  const productDurationText = useMemo(
    () => getDurationText({ periodicityName, quantity }),
    [periodicityName, quantity]
  );

  const tariffText = tariffName;
  const durationText = `на ${productDurationText}`;

  return (
    <div className="productDescriptionMainBannerOuter">
      <button
        onClick={onBackButtonClick}
        className="productDescriptionMainBannerBackButton link opacity-on-hover"
      >
        <img className="productDescriptionMainBannerBackButtonImage" src={images.backArrowLeft} />
        {'Назад'}
      </button>
      <div className="productDescriptionMainBanner">
        <div className="productDescriptionMainBannerInfoBlockPurchaseSectionMobile">
          <div className="productDescriptionMainBannerPriceDurationContainer">
            <div className="productDescriptionMainBannerPrice">{tariffPriceText}</div>
            {productDurationText && (
              <div className="productDescriptionMainBannerDurationText">{durationText}</div>
            )}
          </div>
          <button className="app-button productDescriptionMainBannerButton" onClick={openPurchaseModal}>
            {tariffPurchaseButtonText}
          </button>
        </div>

        <ItemDescriptionPageMainBannerCards productImages={productImages} />

        <div className="productDescriptionMainBannerInfoBlock">
          <div className="productDescriptionMainBannerInfoBlockDescription">
            <div className="productDescriptionMainBannerTitle">{productTitle}</div>
            {tariffText && (
              <div className="productDescriptionMainBannerTariffText">
                <b>{'Тариф: '}</b>
                {tariffText}
              </div>
            )}
            <div className="productDescriptionMainBannerDescription">{descriptionShort}</div>
          </div>

          <div className="productDescriptionMainBannerInfoBlockPurchaseSection">
            <div className="productDescriptionMainBannerPriceDurationContainer">
              <div className="productDescriptionMainBannerPrice">{tariffPriceText}</div>
              {productDurationText && (
                <div className="productDescriptionMainBannerDurationText">{durationText}</div>
              )}
            </div>

            <button className="app-button productDescriptionMainBannerButton" onClick={openPurchaseModal}>
              {tariffPurchaseButtonText}
            </button>
          </div>
        </div>
      </div>

      <div className="productDescriptionMainBannerDescriptionTablet">{descriptionShort}</div>
    </div>
  );
};

export default ItemDescriptionPageMainBannerNew;
