import { useMemo } from 'react';
import { useDisableScroll } from '../../utils/hooks/useDisableScroll';
import images from '../../utils/images';
import './RequestProcessedModal.scss';

const RequestProcessedModal = ({
  modalIsOpen,
  closeModal,
  requestIsSuccessful,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  requestIsSuccessful?: boolean;
}) => {
  useDisableScroll(modalIsOpen);

  const modalData = useMemo(() => {
    if (requestIsSuccessful)
      return {
        statusImage: images.modalSuccess,
        statusImageClass: 'requestProcessedModalSuccessIcon',
        title: 'Ваша заявка принята!',
        message: 'Ваша заявка принята и в ближайшее время наши специалисты свяжутся с вами!',
      };

    return {
      statusImage: images.modalError,
      statusImageClass: 'requestProcessedModalErrorIcon',
      title: 'Что-то пошло не так!',
      message: `Произошла неизвестная ошибка. Попробуйте повторить попытку позже.`,
    };
  }, [requestIsSuccessful]);

  if (!modalIsOpen) return null;

  return (
    <div className="requestProcessedModalOverlay">
      <div className="requestProcessedModalContainer">
        <img src={images.modalCross} alt="Закрыть" className="requestProcessedModalCrossIcon" onClick={closeModal} />
        <img src={modalData.statusImage} alt="" className={modalData.statusImageClass} />
        <div className="requestProcessedModalTitle">{modalData.title}</div>
        <div className="requestProcessedModalText">{modalData.message}</div>
      </div>
    </div>
  );
};

export default RequestProcessedModal;
