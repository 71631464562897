import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { learnMoreDataArray } from '../../pages/ValuesPage/valuesPageData';
import { PagePath } from '../enums';
import { getProductByIdRequest } from '../requests/getProductByIdRequest';

export const useSetTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const companyName = 'EtnaApp';
    
    let targetTitle = companyName;
    const searchParams = new URLSearchParams(location.search);

    switch (location.pathname) {
      default:
      case PagePath.MainPage:
        break;

      case PagePath.SearchPage:
        targetTitle = `${companyName} — Поиск`;
        break;

      case PagePath.OrderStatusPage:
        targetTitle = `${companyName} — Статус заказа`;
        break;
  
      case PagePath.ItemDescriptionPage:
        targetTitle = `${companyName} — Описание тарифа`;
        const productId = parseInt(searchParams.get('id')!) || 0;

        getProductByIdRequest({ productId }).then(productData => {
          if (!productData) return;
          targetTitle = productData.title || targetTitle;
          document.title = targetTitle;
        });
        return;

      case PagePath.ValuesPage:
        targetTitle = `${companyName} — Ценности`;
        const valueId = parseInt(searchParams.get('id')!) || 0;
        const learnMoreData = learnMoreDataArray[valueId];
        if (!learnMoreData) break;
        targetTitle = learnMoreData.titleShort;
        break;
    }

    document.title = targetTitle;
  }, [location]);
};
