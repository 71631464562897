import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ClearSelectedTagTargetsButton from '../../../../../../components/ClearSelectedTagTargetsButton/ClearSelectedTagTargetsButton';
import { toggleTagTargetById } from '../../../../../../redux/aggregatorReducer';
import { useAppSelector } from '../../../../../../redux/store';
import { useCloseModalOnClickOutside } from '../../../../../../utils/hooks/useCloseModalOnClickOutside';
import { AggregatorTagDataWithPicture } from '../../../../../../utils/typesSearch';
import { nonModalMenuStyles } from './nonModalMenuStyles';
import './AggregatorTagTargetsModal.scss';

const highlightColour = '#3ACFD5';
const textHighlightColour = 'white';

const AggregatorTagTargetsModal = ({
  modalIsOpen,
  closeModal,
  asModal = true,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  asModal: boolean;
}) => {
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });
  const { structuredTagsData: { tagTargets }, selectedTagTargetIds } = useAppSelector(state => state.aggregator);

  const dispatch = useDispatch();
  const onSelectTagTargetInner = (id: number) => {
    dispatch(toggleTagTargetById(id));
  }

  const AggregatorTagTargetModalItemCallback = useCallback(
    ({
      tagTargetItem,
      isSelected,
    }: {
      tagTargetItem: AggregatorTagDataWithPicture;
      isSelected: boolean;
    }) => {
      return (
        <button
          key={tagTargetItem.id}
          className="aggregatorTagTargetsModalItem"
          style={{ backgroundColor: isSelected ? highlightColour : undefined }}
          onClick={() => onSelectTagTargetInner(tagTargetItem.id)}
        >
          <img className="aggregatorTagTargetsModalItemIcon" src={tagTargetItem.iconSource} />
          <div
            className="aggregatorTagTargetsModalItemText"
            style={{ color: isSelected ? textHighlightColour : undefined }}
          >
            {tagTargetItem.name}
          </div>
        </button>
      );
    },
    []
  );

  if (!modalIsOpen) return null;

  return (
    <div
      className="aggregatorTagTargetsModalContainerOuter"
      ref={modalRef}
      style={{ ...(!asModal && { ...nonModalMenuStyles, marginTop: 7 }) }}
    >
      <div className="aggregatorTagTargetsModalContainer">
        {tagTargets.map((tagTargetItem) => (
          <AggregatorTagTargetModalItemCallback
            key={tagTargetItem.id}
            tagTargetItem={tagTargetItem}
            isSelected={selectedTagTargetIds.includes(tagTargetItem.id)}
          />
        ))}
      </div>
      {!asModal && <ClearSelectedTagTargetsButton />}
    </div>
  );
};

export default AggregatorTagTargetsModal;
