import Markdown from 'react-markdown'; // https://github.com/remarkjs/react-markdown
import remarkGfm from 'remark-gfm';
import { getFileExtensionIcon } from '../../../utils/helper';
import { getServiceDurationText } from '../../../utils/stringHelper';
import { GraphqlProductData } from '../../../utils/types';
import images from '../../../utils/images';
import './ItemDescriptionPageDetailsSection.scss';
import './ItemDescriptionPageDetailsMarkdown.scss';

const ItemDescriptionPageDetailsSection = ({
  productData,
  selectedTariffId,
  openPurchaseModal,
}: {
  productData: GraphqlProductData;
  selectedTariffId: number;
  openPurchaseModal: () => void;
}) => {
  const selectedTariff = productData.tariffs.find(item => item.tariffId === selectedTariffId)!;
  const documents = productData.productDocuments;

  const serviceList = selectedTariff.tariffDataRaw.service_lists;

  return (
    <div className="itemDescriptionDetailsSection">
      <div className="itemDescriptionDetailsSectionFirstBlock">
        <div className="itemDescriptionDetailsSectionFirstBlockTitle">{productData.title}</div>
        
        <Markdown className="itemDescriptionPageMarkdownBlockText" remarkPlugins={[remarkGfm]}>
          {productData.descriptionLong}
        </Markdown>

        <div className="itemDescriptionDetailsSectionFirstBlockPriceSectionTitle">{'Стоимость'}</div>
        <div className="itemDescriptionDetailsSectionFirstBlockPriceText">
          {selectedTariff.tariffPriceText}
        </div>
        <button
          className="app-button itemDescriptionDetailsSectionFirstBlockPurchaseButton"
          onClick={openPurchaseModal}
        >
          {selectedTariff.tariffPurchaseButtonText}
        </button>
      </div>

      <div className="itemDescriptionDetailsSectionSecondBlock">
        <div className="itemDescriptionDetailsSectionSecondBlockTitle">{'Что включено?'}</div>
        <div className="itemDescriptionDetailsSectionSecondBlockServiceItemsContainer">
          {serviceList.filter((it) => !it.as_gift).map((subService) => (
            <div key={subService.id} className="itemDescriptionDetailsSectionSecondBlockServiceItem">
              <div className="itemDescriptionDetailsSectionSecondBlockServiceItemTitle">
                {subService.title}
              </div>
              <div className="itemDescriptionDetailsSectionSecondBlockServiceItemPointList">
                {subService.description}
              </div>
              {subService.service_list_periodicity &&
                !(subService.service_list_periodicity?.name === 'шт.' && subService.quantity === 1) && (
                  <div className="itemDescriptionDetailsSectionSecondBlockServiceItemDuration">
                    {getServiceDurationText(subService, true)}
                  </div>
                )}
            </div>
          ))}

          {serviceList.filter((it) => it.as_gift).map((subService) => (
            <div key={subService.id} className="itemDescriptionDetailsSectionSecondBlockServiceItem">
              <div className="itemDescriptionDetailsSectionSecondBlockServiceItemTitle">
                <img
                  src={images.servicesGiftIcon}
                  className="itemDescriptionDetailsSectionSecondBlockServiceItemGiftIcon"
                />
                {subService.title}
              </div>
              <div className="itemDescriptionDetailsSectionSecondBlockServiceItemPointList">
                {subService.description}
              </div>
              {subService.service_list_periodicity &&
                !(subService.service_list_periodicity?.name === 'шт.' && subService.quantity === 1) && (
                  <div className="itemDescriptionDetailsSectionSecondBlockServiceItemDuration">
                    {getServiceDurationText(subService, true)}
                  </div>
                )}
            </div>
          ))}
        </div>

        {!!documents.length && (
          <div className="itemDescriptionDetailsSectionSecondBlockDocuments">
            <div className="itemDescriptionDetailsSectionSecondBlockDocumentsBlockTitle">{'Документы'}</div>
            <div className="itemDescriptionDetailsSectionSecondBlockDocumentsList">
              {documents.map((documentData) => (
                <a
                  key={documentData.documentId}
                  className="itemDescriptionDetailsSectionSecondBlockDocument opacity-on-hover"
                  href={documentData.documentUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={getFileExtensionIcon(documentData.documentExtension)}
                    className="itemDescriptionDetailsSectionSecondBlockDocumentImage"
                  />

                  {/* <div
                    className="itemDescriptionDetailsSectionSecondBlockDocumentIcon"
                    style={{ backgroundImage: getImageUrlForCss(images.documentIcon) }}
                  >
                    <div className="itemDescriptionDetailsSectionSecondBlockDocumentIconText">
                      {documentData.documentExtension}
                    </div>
                  </div> */}

                  <div className="itemDescriptionDetailsSectionSecondBlockDocumentInfo">
                    <div className="itemDescriptionDetailsSectionSecondBlockDocumentTitle">
                      {documentData.documentDisplayedName}
                    </div>
                    <div className="itemDescriptionDetailsSectionSecondBlockDocumentSizeText">
                      {documentData.documentSizeText}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemDescriptionPageDetailsSection;
