import { useDispatch } from 'react-redux';
import {
  setSelectedAgeRangeId,
  setSelectedCategoryId,
  setSelectedTagTargetIds,
} from '../../../../../redux/aggregatorReducer';
import { PagePath, SearchQueryParams } from '../../../../../utils/enums';
import { useAppSelector } from '../../../../../redux/store';
import AggregatorQuickSearchButtonVisual from './AggregatorQuickSearchButtonVisual';
import { CSSProperties } from 'react';
import { SearchInput } from '../../../../SearchPage/SearchPageContent';
import AppLink from '../../../../../components/AppLink/AppLink';

const containerStyle: CSSProperties = {
  cursor: 'pointer',
  textDecoration: 'none',
  border: 'none',
  background: 'none',
  padding: 0,
  margin: 0,
}

const getSearchWithTagTargetNavLink = (tagTargetId: number) => {
  return `${PagePath.SearchPage}?${SearchQueryParams.tagTargetIds}=${tagTargetId}`;
}

const AggregatorQuickSearchButton = ({
  iconImage,
  title,
  tagId,
  onSearch,
  usingMobileStyles = false,
  isSearchPageFilter,
}: {
  iconImage: string;
  title: string;
  tagId: number;
  onSearch?: (input?: SearchInput) => void;
  usingMobileStyles?: boolean;
  isSearchPageFilter?: boolean,
}) => {
  const selectedTagTargetIds = useAppSelector(state => state.aggregator.selectedTagTargetIds);
  const isSelected =
    isSearchPageFilter && selectedTagTargetIds.length === 1 && selectedTagTargetIds.includes(tagId);
  
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setSelectedTagTargetIds([tagId]));

    if (isSearchPageFilter) {
      dispatch(setSelectedAgeRangeId(null));
      dispatch(setSelectedCategoryId(null));

      onSearch?.({ categoryId: 0, ageRangeId: 0, tagTargetIds: [tagId] })
    }
  }

  if (isSearchPageFilter) return (
    <button onClick={onClick} draggable={false}>
      <AggregatorQuickSearchButtonVisual
        iconImage={iconImage}
        title={title}
        usingMobileStyles={usingMobileStyles}
        isSelected={isSelected}
      />
    </button>
  );

  const navLink = getSearchWithTagTargetNavLink(tagId);

  return (
    <AppLink to={navLink} onClick={onClick} draggable={false} style={containerStyle} >
      <AggregatorQuickSearchButtonVisual
        iconImage={iconImage}
        title={title}
        usingMobileStyles={usingMobileStyles}
        isSelected={isSelected}
      />
    </AppLink>
  );
};

export default AggregatorQuickSearchButton;
