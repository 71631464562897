import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ConsultCallData, DimensionsData } from '../utils/types';
import { SectionName } from '../utils/enums';
import { defaultConsultCallData } from '../utils/requestApi';

interface MainState {
  fetching: boolean;
  blockInterface: boolean;
  dimensionsData: DimensionsData;

  sectionNameToScrollTo: SectionName | null;
  sectionNameToReturnTo: SectionName | null;
  consultCallData: ConsultCallData;
  freeGuideEmail: string;
};

const initialState: MainState = {
  fetching: false,
  blockInterface: false,
  dimensionsData: {
    screenSize: {
      width: 1920,
      height: 1080,
    },
    isVerticallyOriented: false,
  },

  sectionNameToScrollTo: null,
  sectionNameToReturnTo: null,
  consultCallData: defaultConsultCallData,
  freeGuideEmail: '',
};

const settingsSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    // ####################################################################################
    setBlockInterface: (state, { payload }: PayloadAction<boolean>) => {
      state.blockInterface = payload;
    },
    setDimensionsData: (state, { payload }: PayloadAction<DimensionsData>) => {
      state.dimensionsData = payload;
    },
    // ####################################################################################
    setSectionToScrollTo: (state, { payload }: PayloadAction<SectionName | null>) => {
      state.sectionNameToScrollTo = payload;
    },
    setSectionToReturnTo: (state, { payload }: PayloadAction<SectionName | null>) => {
      state.sectionNameToReturnTo = payload;
    },
    setConsultCallData: (state, { payload }: PayloadAction<ConsultCallData>) => {
      state.consultCallData = payload;
    },
    setFreeGuideEmail: (state, { payload }: PayloadAction<string>) => {
      state.freeGuideEmail = payload;
    },
    // ####################################################################################
  },
});

export const {
  setBlockInterface,
  setDimensionsData,

  setSectionToScrollTo,
  setSectionToReturnTo,
  setConsultCallData,
  setFreeGuideEmail,
} = settingsSlice.actions;

export default settingsSlice.reducer;
