import { apolloClient } from "../..";
import {
  GetProductsByIdsDocument,
  GetProductsByIdsQuery,
  GetProductsByIdsQueryVariables,
} from '../../gql/graphqlWithHooks';

export const getProductByIdRequest = async ({
  productId,
}: {
  productId: number;
}) => {
  const operationVariables: GetProductsByIdsQueryVariables = {
    ids: [productId],
  };

  try {
    const response = await apolloClient.query<GetProductsByIdsQuery>({
      query: GetProductsByIdsDocument,
      variables: operationVariables,
    });

    // request resolved
    const fetchedProduct = response?.data?.mobile_services?.[0] || undefined;
    return fetchedProduct;
  } catch (error) {
    console.error('getFreeGuideRequest Error:', error);
    return false;
  }
};
