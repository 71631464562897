import { useCloseModalOnClickOutside } from '../../../utils/hooks/useCloseModalOnClickOutside';
import images from '../../../utils/images';
import { ProductsSortType } from '../../../utils/types';
import { SortOption } from './SearchPageSortButton';
import './SearchPageSortSelectionModal.scss';

const SearchPageSortSelectionModal = ({
  modalIsOpen,
  closeModal,
  sortOptions,
  selectedSortType,
  setSelectedSortType,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  sortOptions: SortOption[];
  selectedSortType: ProductsSortType;
  setSelectedSortType: (sortType: ProductsSortType) => void;
}) => {
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });

  const onSelect = (sortType: ProductsSortType) => {
    setSelectedSortType(sortType);
    closeModal();
  }

  if (!modalIsOpen) return null;

  return (
    <div className="SearchPageSortSelectionModal" ref={modalRef}>
      {sortOptions.map((sortOption) => {
        const isSelected = sortOption.sortType === selectedSortType;

        return (
          <button
            key={sortOption.sortType}
            className="SearchPageSortSelectionModalItemContainer"
            onClick={() => onSelect(sortOption.sortType)}
          >
            <div className="SearchPageSortSelectionModalItemTitle">{sortOption.title}</div>
            <img
              className="SearchPageSortSelectionModalItemCheckmark"
              src={isSelected ? images.aggregatorMenuChecked : images.aggregatorMenuUnchecked}
            />
          </button>
        );
      })}
      
    </div>
  );
};

export default SearchPageSortSelectionModal;