import AggregatorQuickSearchButtonsBlock from './components/AggregatorQuickSearchButtonsBlock';
import AggregatorSearchBlock from './components/AggregatorSearchBlock';
import './AggregatorBlock.scss';

const AggregatorBlock = () => {
  const title = 'Поиск готовых программ здоровья';
  const slogan = 'Управлять здоровьем — просто!';

  return (
    <div className="aggregatorContainer">
      <div className="aggregatorTitle">{title}</div>
      <div className="aggregatorSlogan">{slogan}</div>
      <AggregatorQuickSearchButtonsBlock />
      <AggregatorSearchBlock />
    </div>
  );
};

export default AggregatorBlock;
