import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
};

export type AutoReplyDocumentOutput = {
  __typename?: 'AutoReplyDocumentOutput';
  auto_reply_id?: Maybe<Scalars['Int']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pro_document_status_id?: Maybe<Scalars['Int']['output']>;
  problem_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type AutoReplyMediafileOutput = {
  __typename?: 'AutoReplyMediafileOutput';
  auto_reply_id?: Maybe<Scalars['Int']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['Int']['output']>;
  pro_document_status_id?: Maybe<Scalars['Int']['output']>;
  problem_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type CreateDirectUploadInput = {
  byte_size: Scalars['Int']['input'];
  checksum: Scalars['String']['input'];
  content_type: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export type CreateDirectUploadOutput = {
  __typename?: 'CreateDirectUploadOutput';
  direct_upload?: Maybe<Scalars['jsonb']['output']>;
};

export type CreateInvoiceInput = {
  amount: Scalars['Int']['input'];
  currency: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  expires_at?: InputMaybe<Scalars['String']['input']>;
  from_client_user_id: Scalars['Int']['input'];
};

export type FintechDocument = {
  __typename?: 'FintechDocument';
  persondoc_issby?: Maybe<Scalars['String']['output']>;
  persondoc_issdate?: Maybe<Scalars['String']['output']>;
  persondoc_number?: Maybe<Scalars['String']['output']>;
};

export type FintechDocumentAttributes = {
  persondoc_issby?: InputMaybe<Scalars['String']['input']>;
  persondoc_issdate?: InputMaybe<Scalars['String']['input']>;
  persondoc_number?: InputMaybe<Scalars['String']['input']>;
};

export type InsertMobilePostsOneInput = {
  content_id: Scalars['Int']['input'];
  media_file: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  profile_id: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type InsertMobilePostsOneOutput = {
  __typename?: 'InsertMobilePostsOneOutput';
  content_id?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  media_file_url?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MeOutput = {
  __typename?: 'MeOutput';
  avatar?: Maybe<Scalars['jsonb']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type MobileGetNotificationsOutput = {
  __typename?: 'MobileGetNotificationsOutput';
  notifications?: Maybe<Array<Maybe<MobileGetNotificationsOutput_Get_Notification>>>;
};

export type MobileGetNotificationsOutput_Get_Notification = {
  __typename?: 'MobileGetNotificationsOutput_get_notification';
  accessToken: Scalars['String']['output'];
  actor_avatar_48x48?: Maybe<Scalars['String']['output']>;
  actor_avatar_64x64?: Maybe<Scalars['String']['output']>;
  actor_avatar_96x96?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  notifiable_id?: Maybe<Scalars['Int']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  post_media_file_48x48?: Maybe<Scalars['String']['output']>;
  post_media_file_64x64?: Maybe<Scalars['String']['output']>;
  post_media_file_96x96?: Maybe<Scalars['String']['output']>;
  read_at?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MobileGetProfileOutput = {
  __typename?: 'MobileGetProfileOutput';
  profile: Scalars['jsonb']['output'];
};

export type OrderClientInfo = {
  __typename?: 'OrderClientInfo';
  address?: Maybe<Scalars['String']['output']>;
  available_balance?: Maybe<Scalars['Int']['output']>;
  birth_date?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_checked?: Maybe<Scalars['Boolean']['output']>;
  reg_date?: Maybe<Scalars['String']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type PayInvoiceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  invoice_id: Scalars['Int']['input'];
};

export type ServciceTagTargets = {
  __typename?: 'ServciceTagTargets';
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  tag_target?: Maybe<TagTarget>;
  tag_target_id?: Maybe<Scalars['Int']['output']>;
};

export type ServiceDocumentOutput = {
  __typename?: 'ServiceDocumentOutput';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  pro_document_status_id?: Maybe<Scalars['Int']['output']>;
  problem_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type ServiceImageOutput = {
  __typename?: 'ServiceImageOutput';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  media_file_size?: Maybe<Scalars['Int']['output']>;
  pro_document_status_id?: Maybe<Scalars['Int']['output']>;
  problem_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type ServiceImages = {
  __typename?: 'ServiceImages';
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  media_file_size?: Maybe<Scalars['Int']['output']>;
};

export type ServiceListPeriodicityOutput = {
  __typename?: 'ServiceListPeriodicityOutput';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ServiceListsInput = {
  as_gift?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  service_list_periodicity_id?: InputMaybe<Scalars['Int']['input']>;
  tariff_name?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type ServiceListsOutput = {
  __typename?: 'ServiceListsOutput';
  as_gift?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Int']['output']>;
  service_tariff_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type ServiceOutput = {
  __typename?: 'ServiceOutput';
  category_profession_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  service_status_id?: Maybe<Scalars['Int']['output']>;
  service_type_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type ServicePeriodicity = {
  __typename?: 'ServicePeriodicity';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ServiceTagAges = {
  __typename?: 'ServiceTagAges';
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  tag_age?: Maybe<TagAge>;
  tag_age_id?: Maybe<Scalars['Int']['output']>;
};

export type ServiceTagTargetGroups = {
  __typename?: 'ServiceTagTargetGroups';
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  tag_target_group?: Maybe<TagTargetGroup>;
  tag_target_group_id?: Maybe<Scalars['Int']['output']>;
};

export type ServiceTariffOutput = {
  __typename?: 'ServiceTariffOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  service_lists?: Maybe<Array<Maybe<ServiceListsOutput>>>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type TagAge = {
  __typename?: 'TagAge';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TagTarget = {
  __typename?: 'TagTarget';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

export type TagTargetGroup = {
  __typename?: 'TagTargetGroup';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  avatar?: Maybe<Scalars['jsonb']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UpdateUserUsersPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

export type UpdateUserUsersSetInput = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AuthChangeEmailInput = {
  otp_code: Scalars['String']['input'];
};

export type AuthChangeEmailOutput = {
  __typename?: 'authChangeEmailOutput';
  error: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type AuthChangePasswordInput = {
  current_password: Scalars['String']['input'];
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
};

export type AuthChangePasswordOutput = {
  __typename?: 'authChangePasswordOutput';
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthChangePhoneInput = {
  otp_code: Scalars['String']['input'];
};

export type AuthChangePhoneOutput = {
  __typename?: 'authChangePhoneOutput';
  error: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type AuthCheckOtpCodeEmailInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type AuthCheckOtpCodeEmailOutput = {
  __typename?: 'authCheckOtpCodeEmailOutput';
  error: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type AuthCheckOtpCodePhoneInput = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type AuthCheckOtpCodePhoneOutput = {
  __typename?: 'authCheckOtpCodePhoneOutput';
  error: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type AuthCheckUsernameInput = {
  user_id: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type AuthCheckUsernameOutput = {
  __typename?: 'authCheckUsernameOutput';
  name_replace: Scalars['Boolean']['output'];
};

export type AuthCheckUsernameUniquenessInput = {
  username: Scalars['String']['input'];
};

export type AuthCheckUsernameUniquenessOutput = {
  __typename?: 'authCheckUsernameUniquenessOutput';
  is_unique: Scalars['Boolean']['output'];
};

export type AuthFindOrCreateMedconciergeBotOutput = {
  __typename?: 'authFindOrCreateMedconciergeBotOutput';
  status: Scalars['Boolean']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type AuthFindUserInput = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type AuthFindUserOutput = {
  __typename?: 'authFindUserOutput';
  id?: Maybe<Scalars['Int']['output']>;
};

export type AuthHardDeleteUserInput = {
  code: Scalars['String']['input'];
};

export type AuthHardDeleteUserOutput = {
  __typename?: 'authHardDeleteUserOutput';
  status: Scalars['String']['output'];
};

export type AuthSendAccountDeletionCodeOutput = {
  __typename?: 'authSendAccountDeletionCodeOutput';
  status: Scalars['String']['output'];
};

export type AuthSendOtpCodeCheckEmailInput = {
  email: Scalars['String']['input'];
};

export type AuthSendOtpCodeCheckEmailOutput = {
  __typename?: 'authSendOtpCodeCheckEmailOutput';
  error: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthSendOtpCodeCheckPhoneInput = {
  phone: Scalars['String']['input'];
};

export type AuthSendOtpCodeCheckPhoneOutput = {
  __typename?: 'authSendOtpCodeCheckPhoneOutput';
  error: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type AuthSendingMailConfirmNewEmailInput = {
  email: Scalars['String']['input'];
};

export type AuthSendingMailConfirmNewEmailOutput = {
  __typename?: 'authSendingMailConfirmNewEmailOutput';
  error: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  timeout?: Maybe<Scalars['Int']['output']>;
};

export type AuthSendingSmsConfirmNewPhoneInput = {
  phone: Scalars['String']['input'];
};

export type AuthSendingSmsConfirmNewPhoneOutput = {
  __typename?: 'authSendingSmsConfirmNewPhoneOutput';
  error: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  timeout?: Maybe<Scalars['Int']['output']>;
};

export type AuthSoftDeleteUserOutput = {
  __typename?: 'authSoftDeleteUserOutput';
  status: Scalars['String']['output'];
};

export type AuthVerifyTokenBeforeResettingPasswordInput = {
  token: Scalars['String']['input'];
};

export type AuthVerifyTokenBeforeResettingPasswordOutput = {
  __typename?: 'authVerifyTokenBeforeResettingPasswordOutput';
  error?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

/** columns and relationships of "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens = {
  __typename?: 'auth_oauth_access_tokens';
  application_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamp']['output'];
  expires_in?: Maybe<Scalars['Int']['output']>;
  id: Scalars['bigint']['output'];
  previous_refresh_token: Scalars['String']['output'];
  refresh_token?: Maybe<Scalars['String']['output']>;
  resource_owner_id?: Maybe<Scalars['bigint']['output']>;
  revoked_at?: Maybe<Scalars['timestamp']['output']>;
  scopes?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

/** aggregated selection of "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Aggregate = {
  __typename?: 'auth_oauth_access_tokens_aggregate';
  aggregate?: Maybe<Auth_Oauth_Access_Tokens_Aggregate_Fields>;
  nodes: Array<Auth_Oauth_Access_Tokens>;
};

export type Auth_Oauth_Access_Tokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Oauth_Access_Tokens_Aggregate_Bool_Exp_Count>;
};

export type Auth_Oauth_Access_Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Aggregate_Fields = {
  __typename?: 'auth_oauth_access_tokens_aggregate_fields';
  avg?: Maybe<Auth_Oauth_Access_Tokens_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Oauth_Access_Tokens_Max_Fields>;
  min?: Maybe<Auth_Oauth_Access_Tokens_Min_Fields>;
  stddev?: Maybe<Auth_Oauth_Access_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Oauth_Access_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Oauth_Access_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Oauth_Access_Tokens_Sum_Fields>;
  var_pop?: Maybe<Auth_Oauth_Access_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Oauth_Access_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Auth_Oauth_Access_Tokens_Variance_Fields>;
};


/** aggregate fields of "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Aggregate_Order_By = {
  avg?: InputMaybe<Auth_Oauth_Access_Tokens_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Oauth_Access_Tokens_Max_Order_By>;
  min?: InputMaybe<Auth_Oauth_Access_Tokens_Min_Order_By>;
  stddev?: InputMaybe<Auth_Oauth_Access_Tokens_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Auth_Oauth_Access_Tokens_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Auth_Oauth_Access_Tokens_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Auth_Oauth_Access_Tokens_Sum_Order_By>;
  var_pop?: InputMaybe<Auth_Oauth_Access_Tokens_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Auth_Oauth_Access_Tokens_Var_Samp_Order_By>;
  variance?: InputMaybe<Auth_Oauth_Access_Tokens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Auth_Oauth_Access_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Oauth_Access_Tokens_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Oauth_Access_Tokens_Avg_Fields = {
  __typename?: 'auth_oauth_access_tokens_avg_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  expires_in?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  resource_owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Avg_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "oauth_access_tokens". All fields are combined with a logical 'AND'. */
export type Auth_Oauth_Access_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Bool_Exp>>;
  application_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expires_in?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  previous_refresh_token?: InputMaybe<String_Comparison_Exp>;
  refresh_token?: InputMaybe<String_Comparison_Exp>;
  resource_owner_id?: InputMaybe<Bigint_Comparison_Exp>;
  revoked_at?: InputMaybe<Timestamp_Comparison_Exp>;
  scopes?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "oauth_access_tokens" */
export enum Auth_Oauth_Access_Tokens_Constraint {
  /** unique or primary key constraint on columns "refresh_token" */
  IndexOauthAccessTokensOnRefreshToken = 'index_oauth_access_tokens_on_refresh_token',
  /** unique or primary key constraint on columns "token" */
  IndexOauthAccessTokensOnToken = 'index_oauth_access_tokens_on_token',
  /** unique or primary key constraint on columns "id" */
  OauthAccessTokensPkey = 'oauth_access_tokens_pkey'
}

/** input type for incrementing numeric columns in table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Inc_Input = {
  application_id?: InputMaybe<Scalars['bigint']['input']>;
  expires_in?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  resource_owner_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Insert_Input = {
  application_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_in?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  previous_refresh_token?: InputMaybe<Scalars['String']['input']>;
  refresh_token?: InputMaybe<Scalars['String']['input']>;
  resource_owner_id?: InputMaybe<Scalars['bigint']['input']>;
  revoked_at?: InputMaybe<Scalars['timestamp']['input']>;
  scopes?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Oauth_Access_Tokens_Max_Fields = {
  __typename?: 'auth_oauth_access_tokens_max_fields';
  application_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_in?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  previous_refresh_token?: Maybe<Scalars['String']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
  resource_owner_id?: Maybe<Scalars['bigint']['output']>;
  revoked_at?: Maybe<Scalars['timestamp']['output']>;
  scopes?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Max_Order_By = {
  application_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previous_refresh_token?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
  revoked_at?: InputMaybe<Order_By>;
  scopes?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Oauth_Access_Tokens_Min_Fields = {
  __typename?: 'auth_oauth_access_tokens_min_fields';
  application_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_in?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  previous_refresh_token?: Maybe<Scalars['String']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
  resource_owner_id?: Maybe<Scalars['bigint']['output']>;
  revoked_at?: Maybe<Scalars['timestamp']['output']>;
  scopes?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Min_Order_By = {
  application_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previous_refresh_token?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
  revoked_at?: InputMaybe<Order_By>;
  scopes?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Mutation_Response = {
  __typename?: 'auth_oauth_access_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Oauth_Access_Tokens>;
};

/** on_conflict condition type for table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_On_Conflict = {
  constraint: Auth_Oauth_Access_Tokens_Constraint;
  update_columns?: Array<Auth_Oauth_Access_Tokens_Update_Column>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "oauth_access_tokens". */
export type Auth_Oauth_Access_Tokens_Order_By = {
  application_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previous_refresh_token?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
  revoked_at?: InputMaybe<Order_By>;
  scopes?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: oauth_access_tokens */
export type Auth_Oauth_Access_Tokens_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "oauth_access_tokens" */
export enum Auth_Oauth_Access_Tokens_Select_Column {
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresIn = 'expires_in',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousRefreshToken = 'previous_refresh_token',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  ResourceOwnerId = 'resource_owner_id',
  /** column name */
  RevokedAt = 'revoked_at',
  /** column name */
  Scopes = 'scopes',
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Set_Input = {
  application_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_in?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  previous_refresh_token?: InputMaybe<Scalars['String']['input']>;
  refresh_token?: InputMaybe<Scalars['String']['input']>;
  resource_owner_id?: InputMaybe<Scalars['bigint']['input']>;
  revoked_at?: InputMaybe<Scalars['timestamp']['input']>;
  scopes?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Oauth_Access_Tokens_Stddev_Fields = {
  __typename?: 'auth_oauth_access_tokens_stddev_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  expires_in?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  resource_owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Stddev_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Oauth_Access_Tokens_Stddev_Pop_Fields = {
  __typename?: 'auth_oauth_access_tokens_stddev_pop_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  expires_in?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  resource_owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Stddev_Pop_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Oauth_Access_Tokens_Stddev_Samp_Fields = {
  __typename?: 'auth_oauth_access_tokens_stddev_samp_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  expires_in?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  resource_owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Stddev_Samp_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "auth_oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Oauth_Access_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Oauth_Access_Tokens_Stream_Cursor_Value_Input = {
  application_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_in?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  previous_refresh_token?: InputMaybe<Scalars['String']['input']>;
  refresh_token?: InputMaybe<Scalars['String']['input']>;
  resource_owner_id?: InputMaybe<Scalars['bigint']['input']>;
  revoked_at?: InputMaybe<Scalars['timestamp']['input']>;
  scopes?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Auth_Oauth_Access_Tokens_Sum_Fields = {
  __typename?: 'auth_oauth_access_tokens_sum_fields';
  application_id?: Maybe<Scalars['bigint']['output']>;
  expires_in?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  resource_owner_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Sum_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** update columns of table "oauth_access_tokens" */
export enum Auth_Oauth_Access_Tokens_Update_Column {
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresIn = 'expires_in',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousRefreshToken = 'previous_refresh_token',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  ResourceOwnerId = 'resource_owner_id',
  /** column name */
  RevokedAt = 'revoked_at',
  /** column name */
  Scopes = 'scopes',
  /** column name */
  Token = 'token'
}

export type Auth_Oauth_Access_Tokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Oauth_Access_Tokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Oauth_Access_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Oauth_Access_Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Oauth_Access_Tokens_Var_Pop_Fields = {
  __typename?: 'auth_oauth_access_tokens_var_pop_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  expires_in?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  resource_owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Var_Pop_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Oauth_Access_Tokens_Var_Samp_Fields = {
  __typename?: 'auth_oauth_access_tokens_var_samp_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  expires_in?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  resource_owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Var_Samp_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Oauth_Access_Tokens_Variance_Fields = {
  __typename?: 'auth_oauth_access_tokens_variance_fields';
  application_id?: Maybe<Scalars['Float']['output']>;
  expires_in?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  resource_owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "oauth_access_tokens" */
export type Auth_Oauth_Access_Tokens_Variance_Order_By = {
  application_id?: InputMaybe<Order_By>;
  expires_in?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resource_owner_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "test_users" */
export type Auth_Test_Users = {
  __typename?: 'auth_test_users';
  created_at: Scalars['timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fix_token?: Maybe<Scalars['String']['output']>;
  hard_delete?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['bigint']['output'];
  not_register?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  test_code?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "test_users" */
export type Auth_Test_Users_Aggregate = {
  __typename?: 'auth_test_users_aggregate';
  aggregate?: Maybe<Auth_Test_Users_Aggregate_Fields>;
  nodes: Array<Auth_Test_Users>;
};

/** aggregate fields of "test_users" */
export type Auth_Test_Users_Aggregate_Fields = {
  __typename?: 'auth_test_users_aggregate_fields';
  avg?: Maybe<Auth_Test_Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Test_Users_Max_Fields>;
  min?: Maybe<Auth_Test_Users_Min_Fields>;
  stddev?: Maybe<Auth_Test_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Test_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Test_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Test_Users_Sum_Fields>;
  var_pop?: Maybe<Auth_Test_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Test_Users_Var_Samp_Fields>;
  variance?: Maybe<Auth_Test_Users_Variance_Fields>;
};


/** aggregate fields of "test_users" */
export type Auth_Test_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Test_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Auth_Test_Users_Avg_Fields = {
  __typename?: 'auth_test_users_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "test_users". All fields are combined with a logical 'AND'. */
export type Auth_Test_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Test_Users_Bool_Exp>>;
  _not?: InputMaybe<Auth_Test_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Test_Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fix_token?: InputMaybe<String_Comparison_Exp>;
  hard_delete?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  not_register?: InputMaybe<Boolean_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  test_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "test_users" */
export enum Auth_Test_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestUsersPkey = 'test_users_pkey'
}

/** input type for incrementing numeric columns in table "test_users" */
export type Auth_Test_Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "test_users" */
export type Auth_Test_Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fix_token?: InputMaybe<Scalars['String']['input']>;
  hard_delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  not_register?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  test_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Auth_Test_Users_Max_Fields = {
  __typename?: 'auth_test_users_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fix_token?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  test_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Auth_Test_Users_Min_Fields = {
  __typename?: 'auth_test_users_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fix_token?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  test_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "test_users" */
export type Auth_Test_Users_Mutation_Response = {
  __typename?: 'auth_test_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Test_Users>;
};

/** on_conflict condition type for table "test_users" */
export type Auth_Test_Users_On_Conflict = {
  constraint: Auth_Test_Users_Constraint;
  update_columns?: Array<Auth_Test_Users_Update_Column>;
  where?: InputMaybe<Auth_Test_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "test_users". */
export type Auth_Test_Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fix_token?: InputMaybe<Order_By>;
  hard_delete?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_register?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  test_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: test_users */
export type Auth_Test_Users_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "test_users" */
export enum Auth_Test_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FixToken = 'fix_token',
  /** column name */
  HardDelete = 'hard_delete',
  /** column name */
  Id = 'id',
  /** column name */
  NotRegister = 'not_register',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  TestCode = 'test_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "test_users" */
export type Auth_Test_Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fix_token?: InputMaybe<Scalars['String']['input']>;
  hard_delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  not_register?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  test_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Test_Users_Stddev_Fields = {
  __typename?: 'auth_test_users_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Test_Users_Stddev_Pop_Fields = {
  __typename?: 'auth_test_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Test_Users_Stddev_Samp_Fields = {
  __typename?: 'auth_test_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_test_users" */
export type Auth_Test_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Test_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Test_Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fix_token?: InputMaybe<Scalars['String']['input']>;
  hard_delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  not_register?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  test_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Auth_Test_Users_Sum_Fields = {
  __typename?: 'auth_test_users_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "test_users" */
export enum Auth_Test_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FixToken = 'fix_token',
  /** column name */
  HardDelete = 'hard_delete',
  /** column name */
  Id = 'id',
  /** column name */
  NotRegister = 'not_register',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  TestCode = 'test_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Test_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Test_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Test_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Test_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Test_Users_Var_Pop_Fields = {
  __typename?: 'auth_test_users_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Auth_Test_Users_Var_Samp_Fields = {
  __typename?: 'auth_test_users_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Auth_Test_Users_Variance_Fields = {
  __typename?: 'auth_test_users_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users" */
export type Auth_Users = {
  __typename?: 'auth_users';
  account_deletion_code?: Maybe<Scalars['String']['output']>;
  /** Адрес проживания */
  address_residence?: Maybe<Scalars['String']['output']>;
  allow_password_change?: Maybe<Scalars['Boolean']['output']>;
  /** Баланс */
  balance?: Maybe<Scalars['float8']['output']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']['output']>;
  /** Гражданство */
  citizenship?: Maybe<Scalars['String']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  /** Страна */
  country?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  date_account_deletion_code_sent_at?: Maybe<Scalars['timestamp']['output']>;
  date_send_code?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_confirmation_change_at?: Maybe<Scalars['timestamp']['output']>;
  email_confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  email_confirmation_token?: Maybe<Scalars['String']['output']>;
  encrypted_password: Scalars['String']['output'];
  /** Имя */
  first_name?: Maybe<Scalars['String']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  /** Иностранец */
  foreigner?: Maybe<Scalars['Boolean']['output']>;
  hard_delete?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['bigint']['output'];
  /** Загранпаспорт */
  international_passport?: Maybe<Scalars['String']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  is_delete: Scalars['Boolean']['output'];
  /** Фамилия */
  last_name?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  oauth_access_tokens: Array<Auth_Oauth_Access_Tokens>;
  /** An aggregate relationship */
  oauth_access_tokens_aggregate: Auth_Oauth_Access_Tokens_Aggregate;
  oms?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']['output']>;
  phone_confirmation_change_at?: Maybe<Scalars['timestamp']['output']>;
  phone_confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  phone_confirmation_token?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']['output']>;
  previous_email?: Maybe<Scalars['String']['output']>;
  previous_phone?: Maybe<Scalars['String']['output']>;
  profile_deletion_date?: Maybe<Scalars['timestamp']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']['output']>;
  /** Пол */
  sex?: Maybe<Scalars['Boolean']['output']>;
  telegram_confirmation_code?: Maybe<Scalars['String']['output']>;
  telegram_push?: Maybe<Scalars['Boolean']['output']>;
  test_code?: Maybe<Scalars['String']['output']>;
  test_user?: Maybe<Scalars['Boolean']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  unconfirmed_phone?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  username?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "users" */
export type Auth_UsersOauth_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};


/** columns and relationships of "users" */
export type Auth_UsersOauth_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Auth_Users_Aggregate = {
  __typename?: 'auth_users_aggregate';
  aggregate?: Maybe<Auth_Users_Aggregate_Fields>;
  nodes: Array<Auth_Users>;
};

/** aggregate fields of "users" */
export type Auth_Users_Aggregate_Fields = {
  __typename?: 'auth_users_aggregate_fields';
  avg?: Maybe<Auth_Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Users_Max_Fields>;
  min?: Maybe<Auth_Users_Min_Fields>;
  stddev?: Maybe<Auth_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Users_Sum_Fields>;
  var_pop?: Maybe<Auth_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Users_Var_Samp_Fields>;
  variance?: Maybe<Auth_Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Auth_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Auth_Users_Avg_Fields = {
  __typename?: 'auth_users_avg_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Auth_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Users_Bool_Exp>>;
  _not?: InputMaybe<Auth_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Users_Bool_Exp>>;
  account_deletion_code?: InputMaybe<String_Comparison_Exp>;
  address_residence?: InputMaybe<String_Comparison_Exp>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  balance?: InputMaybe<Float8_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  citizenship?: InputMaybe<String_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_account_deletion_code_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_send_code?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_confirmation_change_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email_confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email_confirmation_token?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  foreign_passport?: InputMaybe<String_Comparison_Exp>;
  foreigner?: InputMaybe<Boolean_Comparison_Exp>;
  hard_delete?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  international_passport?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_delete?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oauth_access_tokens?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
  oauth_access_tokens_aggregate?: InputMaybe<Auth_Oauth_Access_Tokens_Aggregate_Bool_Exp>;
  oms?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  phone_confirmation_change_at?: InputMaybe<Timestamp_Comparison_Exp>;
  phone_confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  phone_confirmation_token?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  place_birth?: InputMaybe<String_Comparison_Exp>;
  previous_email?: InputMaybe<String_Comparison_Exp>;
  previous_phone?: InputMaybe<String_Comparison_Exp>;
  profile_deletion_date?: InputMaybe<Timestamp_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  sex?: InputMaybe<Boolean_Comparison_Exp>;
  telegram_confirmation_code?: InputMaybe<String_Comparison_Exp>;
  telegram_push?: InputMaybe<Boolean_Comparison_Exp>;
  test_code?: InputMaybe<String_Comparison_Exp>;
  test_user?: InputMaybe<Boolean_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Auth_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Auth_Users_Inc_Input = {
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users" */
export type Auth_Users_Insert_Input = {
  account_deletion_code?: InputMaybe<Scalars['String']['input']>;
  /** Адрес проживания */
  address_residence?: InputMaybe<Scalars['String']['input']>;
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']['input']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']['input']>;
  /** Гражданство */
  citizenship?: InputMaybe<Scalars['String']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  /** Страна */
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_account_deletion_code_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_send_code?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_confirmation_change_at?: InputMaybe<Scalars['timestamp']['input']>;
  email_confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  email_confirmation_token?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  /** Иностранец */
  foreigner?: InputMaybe<Scalars['Boolean']['input']>;
  hard_delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  /** Загранпаспорт */
  international_passport?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oauth_access_tokens?: InputMaybe<Auth_Oauth_Access_Tokens_Arr_Rel_Insert_Input>;
  oms?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']['input']>;
  phone_confirmation_change_at?: InputMaybe<Scalars['timestamp']['input']>;
  phone_confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  phone_confirmation_token?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']['input']>;
  previous_email?: InputMaybe<Scalars['String']['input']>;
  previous_phone?: InputMaybe<Scalars['String']['input']>;
  profile_deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']['input']>;
  /** Пол */
  sex?: InputMaybe<Scalars['Boolean']['input']>;
  telegram_confirmation_code?: InputMaybe<Scalars['String']['input']>;
  telegram_push?: InputMaybe<Scalars['Boolean']['input']>;
  test_code?: InputMaybe<Scalars['String']['input']>;
  test_user?: InputMaybe<Scalars['Boolean']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Users_Max_Fields = {
  __typename?: 'auth_users_max_fields';
  account_deletion_code?: Maybe<Scalars['String']['output']>;
  /** Адрес проживания */
  address_residence?: Maybe<Scalars['String']['output']>;
  /** Баланс */
  balance?: Maybe<Scalars['float8']['output']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']['output']>;
  /** Гражданство */
  citizenship?: Maybe<Scalars['String']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  /** Страна */
  country?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_account_deletion_code_sent_at?: Maybe<Scalars['timestamp']['output']>;
  date_send_code?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_confirmation_change_at?: Maybe<Scalars['timestamp']['output']>;
  email_confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  email_confirmation_token?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  /** Загранпаспорт */
  international_passport?: Maybe<Scalars['String']['output']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oms?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']['output']>;
  phone_confirmation_change_at?: Maybe<Scalars['timestamp']['output']>;
  phone_confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  phone_confirmation_token?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']['output']>;
  previous_email?: Maybe<Scalars['String']['output']>;
  previous_phone?: Maybe<Scalars['String']['output']>;
  profile_deletion_date?: Maybe<Scalars['timestamp']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']['output']>;
  telegram_confirmation_code?: Maybe<Scalars['String']['output']>;
  test_code?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  unconfirmed_phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_Users_Min_Fields = {
  __typename?: 'auth_users_min_fields';
  account_deletion_code?: Maybe<Scalars['String']['output']>;
  /** Адрес проживания */
  address_residence?: Maybe<Scalars['String']['output']>;
  /** Баланс */
  balance?: Maybe<Scalars['float8']['output']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']['output']>;
  /** Гражданство */
  citizenship?: Maybe<Scalars['String']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  /** Страна */
  country?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_account_deletion_code_sent_at?: Maybe<Scalars['timestamp']['output']>;
  date_send_code?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_confirmation_change_at?: Maybe<Scalars['timestamp']['output']>;
  email_confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  email_confirmation_token?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  /** Загранпаспорт */
  international_passport?: Maybe<Scalars['String']['output']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  oms?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']['output']>;
  phone_confirmation_change_at?: Maybe<Scalars['timestamp']['output']>;
  phone_confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  phone_confirmation_token?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']['output']>;
  previous_email?: Maybe<Scalars['String']['output']>;
  previous_phone?: Maybe<Scalars['String']['output']>;
  profile_deletion_date?: Maybe<Scalars['timestamp']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']['output']>;
  telegram_confirmation_code?: Maybe<Scalars['String']['output']>;
  test_code?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  unconfirmed_phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users" */
export type Auth_Users_Mutation_Response = {
  __typename?: 'auth_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Users>;
};

/** on_conflict condition type for table "users" */
export type Auth_Users_On_Conflict = {
  constraint: Auth_Users_Constraint;
  update_columns?: Array<Auth_Users_Update_Column>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Auth_Users_Order_By = {
  account_deletion_code?: InputMaybe<Order_By>;
  address_residence?: InputMaybe<Order_By>;
  allow_password_change?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  citizenship?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_account_deletion_code_sent_at?: InputMaybe<Order_By>;
  date_send_code?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_confirmation_change_at?: InputMaybe<Order_By>;
  email_confirmation_sent_at?: InputMaybe<Order_By>;
  email_confirmation_token?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  foreigner?: InputMaybe<Order_By>;
  hard_delete?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  international_passport?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_delete?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oauth_access_tokens_aggregate?: InputMaybe<Auth_Oauth_Access_Tokens_Aggregate_Order_By>;
  oms?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone_confirmation_change_at?: InputMaybe<Order_By>;
  phone_confirmation_sent_at?: InputMaybe<Order_By>;
  phone_confirmation_token?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  place_birth?: InputMaybe<Order_By>;
  previous_email?: InputMaybe<Order_By>;
  previous_phone?: InputMaybe<Order_By>;
  profile_deletion_date?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  telegram_confirmation_code?: InputMaybe<Order_By>;
  telegram_push?: InputMaybe<Order_By>;
  test_code?: InputMaybe<Order_By>;
  test_user?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  unconfirmed_phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Auth_Users_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users" */
export enum Auth_Users_Select_Column {
  /** column name */
  AccountDeletionCode = 'account_deletion_code',
  /** column name */
  AddressResidence = 'address_residence',
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  Balance = 'balance',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Citizenship = 'citizenship',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateAccountDeletionCodeSentAt = 'date_account_deletion_code_sent_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EmailConfirmationChangeAt = 'email_confirmation_change_at',
  /** column name */
  EmailConfirmationSentAt = 'email_confirmation_sent_at',
  /** column name */
  EmailConfirmationToken = 'email_confirmation_token',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Foreigner = 'foreigner',
  /** column name */
  HardDelete = 'hard_delete',
  /** column name */
  Id = 'id',
  /** column name */
  InternationalPassport = 'international_passport',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDelete = 'is_delete',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Name = 'name',
  /** column name */
  Oms = 'oms',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PhoneConfirmationChangeAt = 'phone_confirmation_change_at',
  /** column name */
  PhoneConfirmationSentAt = 'phone_confirmation_sent_at',
  /** column name */
  PhoneConfirmationToken = 'phone_confirmation_token',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  PreviousEmail = 'previous_email',
  /** column name */
  PreviousPhone = 'previous_phone',
  /** column name */
  ProfileDeletionDate = 'profile_deletion_date',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  TelegramConfirmationCode = 'telegram_confirmation_code',
  /** column name */
  TelegramPush = 'telegram_push',
  /** column name */
  TestCode = 'test_code',
  /** column name */
  TestUser = 'test_user',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UnconfirmedPhone = 'unconfirmed_phone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Auth_Users_Set_Input = {
  account_deletion_code?: InputMaybe<Scalars['String']['input']>;
  /** Адрес проживания */
  address_residence?: InputMaybe<Scalars['String']['input']>;
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']['input']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']['input']>;
  /** Гражданство */
  citizenship?: InputMaybe<Scalars['String']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  /** Страна */
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_account_deletion_code_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_send_code?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_confirmation_change_at?: InputMaybe<Scalars['timestamp']['input']>;
  email_confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  email_confirmation_token?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  /** Иностранец */
  foreigner?: InputMaybe<Scalars['Boolean']['input']>;
  hard_delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  /** Загранпаспорт */
  international_passport?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oms?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']['input']>;
  phone_confirmation_change_at?: InputMaybe<Scalars['timestamp']['input']>;
  phone_confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  phone_confirmation_token?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']['input']>;
  previous_email?: InputMaybe<Scalars['String']['input']>;
  previous_phone?: InputMaybe<Scalars['String']['input']>;
  profile_deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']['input']>;
  /** Пол */
  sex?: InputMaybe<Scalars['Boolean']['input']>;
  telegram_confirmation_code?: InputMaybe<Scalars['String']['input']>;
  telegram_push?: InputMaybe<Scalars['Boolean']['input']>;
  test_code?: InputMaybe<Scalars['String']['input']>;
  test_user?: InputMaybe<Scalars['Boolean']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Users_Stddev_Fields = {
  __typename?: 'auth_users_stddev_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Users_Stddev_Pop_Fields = {
  __typename?: 'auth_users_stddev_pop_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Users_Stddev_Samp_Fields = {
  __typename?: 'auth_users_stddev_samp_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_users" */
export type Auth_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Users_Stream_Cursor_Value_Input = {
  account_deletion_code?: InputMaybe<Scalars['String']['input']>;
  /** Адрес проживания */
  address_residence?: InputMaybe<Scalars['String']['input']>;
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  /** Баланс */
  balance?: InputMaybe<Scalars['float8']['input']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']['input']>;
  /** Гражданство */
  citizenship?: InputMaybe<Scalars['String']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  /** Страна */
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_account_deletion_code_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_send_code?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_confirmation_change_at?: InputMaybe<Scalars['timestamp']['input']>;
  email_confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  email_confirmation_token?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  /** Иностранец */
  foreigner?: InputMaybe<Scalars['Boolean']['input']>;
  hard_delete?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  /** Загранпаспорт */
  international_passport?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oms?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']['input']>;
  phone_confirmation_change_at?: InputMaybe<Scalars['timestamp']['input']>;
  phone_confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  phone_confirmation_token?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']['input']>;
  previous_email?: InputMaybe<Scalars['String']['input']>;
  previous_phone?: InputMaybe<Scalars['String']['input']>;
  profile_deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']['input']>;
  /** Пол */
  sex?: InputMaybe<Scalars['Boolean']['input']>;
  telegram_confirmation_code?: InputMaybe<Scalars['String']['input']>;
  telegram_push?: InputMaybe<Scalars['Boolean']['input']>;
  test_code?: InputMaybe<Scalars['String']['input']>;
  test_user?: InputMaybe<Scalars['Boolean']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Auth_Users_Sum_Fields = {
  __typename?: 'auth_users_sum_fields';
  /** Баланс */
  balance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users" */
export enum Auth_Users_Update_Column {
  /** column name */
  AccountDeletionCode = 'account_deletion_code',
  /** column name */
  AddressResidence = 'address_residence',
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  Balance = 'balance',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Citizenship = 'citizenship',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateAccountDeletionCodeSentAt = 'date_account_deletion_code_sent_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EmailConfirmationChangeAt = 'email_confirmation_change_at',
  /** column name */
  EmailConfirmationSentAt = 'email_confirmation_sent_at',
  /** column name */
  EmailConfirmationToken = 'email_confirmation_token',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Foreigner = 'foreigner',
  /** column name */
  HardDelete = 'hard_delete',
  /** column name */
  Id = 'id',
  /** column name */
  InternationalPassport = 'international_passport',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDelete = 'is_delete',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Name = 'name',
  /** column name */
  Oms = 'oms',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PhoneConfirmationChangeAt = 'phone_confirmation_change_at',
  /** column name */
  PhoneConfirmationSentAt = 'phone_confirmation_sent_at',
  /** column name */
  PhoneConfirmationToken = 'phone_confirmation_token',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  PreviousEmail = 'previous_email',
  /** column name */
  PreviousPhone = 'previous_phone',
  /** column name */
  ProfileDeletionDate = 'profile_deletion_date',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  TelegramConfirmationCode = 'telegram_confirmation_code',
  /** column name */
  TelegramPush = 'telegram_push',
  /** column name */
  TestCode = 'test_code',
  /** column name */
  TestUser = 'test_user',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UnconfirmedPhone = 'unconfirmed_phone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Auth_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Users_Var_Pop_Fields = {
  __typename?: 'auth_users_var_pop_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Auth_Users_Var_Samp_Fields = {
  __typename?: 'auth_users_var_samp_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Auth_Users_Variance_Fields = {
  __typename?: 'auth_users_variance_fields';
  /** Баланс */
  balance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "verification_emails" */
export type Auth_Verification_Emails = {
  __typename?: 'auth_verification_emails';
  approve_email?: Maybe<Scalars['Boolean']['output']>;
  approve_time?: Maybe<Scalars['timestamp']['output']>;
  code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  expirate?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "verification_emails" */
export type Auth_Verification_Emails_Aggregate = {
  __typename?: 'auth_verification_emails_aggregate';
  aggregate?: Maybe<Auth_Verification_Emails_Aggregate_Fields>;
  nodes: Array<Auth_Verification_Emails>;
};

/** aggregate fields of "verification_emails" */
export type Auth_Verification_Emails_Aggregate_Fields = {
  __typename?: 'auth_verification_emails_aggregate_fields';
  avg?: Maybe<Auth_Verification_Emails_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Verification_Emails_Max_Fields>;
  min?: Maybe<Auth_Verification_Emails_Min_Fields>;
  stddev?: Maybe<Auth_Verification_Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Verification_Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Verification_Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Verification_Emails_Sum_Fields>;
  var_pop?: Maybe<Auth_Verification_Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Verification_Emails_Var_Samp_Fields>;
  variance?: Maybe<Auth_Verification_Emails_Variance_Fields>;
};


/** aggregate fields of "verification_emails" */
export type Auth_Verification_Emails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Verification_Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Auth_Verification_Emails_Avg_Fields = {
  __typename?: 'auth_verification_emails_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "verification_emails". All fields are combined with a logical 'AND'. */
export type Auth_Verification_Emails_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Verification_Emails_Bool_Exp>>;
  _not?: InputMaybe<Auth_Verification_Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Verification_Emails_Bool_Exp>>;
  approve_email?: InputMaybe<Boolean_Comparison_Exp>;
  approve_time?: InputMaybe<Timestamp_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expirate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "verification_emails" */
export enum Auth_Verification_Emails_Constraint {
  /** unique or primary key constraint on columns "id" */
  VerificationEmailsPkey = 'verification_emails_pkey'
}

/** input type for incrementing numeric columns in table "verification_emails" */
export type Auth_Verification_Emails_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "verification_emails" */
export type Auth_Verification_Emails_Insert_Input = {
  approve_email?: InputMaybe<Scalars['Boolean']['input']>;
  approve_time?: InputMaybe<Scalars['timestamp']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expirate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Auth_Verification_Emails_Max_Fields = {
  __typename?: 'auth_verification_emails_max_fields';
  approve_time?: Maybe<Scalars['timestamp']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expirate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Auth_Verification_Emails_Min_Fields = {
  __typename?: 'auth_verification_emails_min_fields';
  approve_time?: Maybe<Scalars['timestamp']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expirate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "verification_emails" */
export type Auth_Verification_Emails_Mutation_Response = {
  __typename?: 'auth_verification_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Verification_Emails>;
};

/** on_conflict condition type for table "verification_emails" */
export type Auth_Verification_Emails_On_Conflict = {
  constraint: Auth_Verification_Emails_Constraint;
  update_columns?: Array<Auth_Verification_Emails_Update_Column>;
  where?: InputMaybe<Auth_Verification_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "verification_emails". */
export type Auth_Verification_Emails_Order_By = {
  approve_email?: InputMaybe<Order_By>;
  approve_time?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expirate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: verification_emails */
export type Auth_Verification_Emails_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "verification_emails" */
export enum Auth_Verification_Emails_Select_Column {
  /** column name */
  ApproveEmail = 'approve_email',
  /** column name */
  ApproveTime = 'approve_time',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Expirate = 'expirate',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "verification_emails" */
export type Auth_Verification_Emails_Set_Input = {
  approve_email?: InputMaybe<Scalars['Boolean']['input']>;
  approve_time?: InputMaybe<Scalars['timestamp']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expirate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Verification_Emails_Stddev_Fields = {
  __typename?: 'auth_verification_emails_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Verification_Emails_Stddev_Pop_Fields = {
  __typename?: 'auth_verification_emails_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Verification_Emails_Stddev_Samp_Fields = {
  __typename?: 'auth_verification_emails_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_verification_emails" */
export type Auth_Verification_Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Verification_Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Verification_Emails_Stream_Cursor_Value_Input = {
  approve_email?: InputMaybe<Scalars['Boolean']['input']>;
  approve_time?: InputMaybe<Scalars['timestamp']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expirate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Auth_Verification_Emails_Sum_Fields = {
  __typename?: 'auth_verification_emails_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "verification_emails" */
export enum Auth_Verification_Emails_Update_Column {
  /** column name */
  ApproveEmail = 'approve_email',
  /** column name */
  ApproveTime = 'approve_time',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Expirate = 'expirate',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Verification_Emails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Verification_Emails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Verification_Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Verification_Emails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Verification_Emails_Var_Pop_Fields = {
  __typename?: 'auth_verification_emails_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Auth_Verification_Emails_Var_Samp_Fields = {
  __typename?: 'auth_verification_emails_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Auth_Verification_Emails_Variance_Fields = {
  __typename?: 'auth_verification_emails_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "verification_phones" */
export type Auth_Verification_Phones = {
  __typename?: 'auth_verification_phones';
  approve_phone?: Maybe<Scalars['Boolean']['output']>;
  approve_time?: Maybe<Scalars['timestamp']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  expirate?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  phone: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "verification_phones" */
export type Auth_Verification_Phones_Aggregate = {
  __typename?: 'auth_verification_phones_aggregate';
  aggregate?: Maybe<Auth_Verification_Phones_Aggregate_Fields>;
  nodes: Array<Auth_Verification_Phones>;
};

/** aggregate fields of "verification_phones" */
export type Auth_Verification_Phones_Aggregate_Fields = {
  __typename?: 'auth_verification_phones_aggregate_fields';
  avg?: Maybe<Auth_Verification_Phones_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Verification_Phones_Max_Fields>;
  min?: Maybe<Auth_Verification_Phones_Min_Fields>;
  stddev?: Maybe<Auth_Verification_Phones_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Verification_Phones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Verification_Phones_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Verification_Phones_Sum_Fields>;
  var_pop?: Maybe<Auth_Verification_Phones_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Verification_Phones_Var_Samp_Fields>;
  variance?: Maybe<Auth_Verification_Phones_Variance_Fields>;
};


/** aggregate fields of "verification_phones" */
export type Auth_Verification_Phones_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Verification_Phones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Auth_Verification_Phones_Avg_Fields = {
  __typename?: 'auth_verification_phones_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "verification_phones". All fields are combined with a logical 'AND'. */
export type Auth_Verification_Phones_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Verification_Phones_Bool_Exp>>;
  _not?: InputMaybe<Auth_Verification_Phones_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Verification_Phones_Bool_Exp>>;
  approve_phone?: InputMaybe<Boolean_Comparison_Exp>;
  approve_time?: InputMaybe<Timestamp_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expirate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "verification_phones" */
export enum Auth_Verification_Phones_Constraint {
  /** unique or primary key constraint on columns "id" */
  VerificationPhonesPkey = 'verification_phones_pkey'
}

/** input type for incrementing numeric columns in table "verification_phones" */
export type Auth_Verification_Phones_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "verification_phones" */
export type Auth_Verification_Phones_Insert_Input = {
  approve_phone?: InputMaybe<Scalars['Boolean']['input']>;
  approve_time?: InputMaybe<Scalars['timestamp']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expirate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Auth_Verification_Phones_Max_Fields = {
  __typename?: 'auth_verification_phones_max_fields';
  approve_time?: Maybe<Scalars['timestamp']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expirate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Auth_Verification_Phones_Min_Fields = {
  __typename?: 'auth_verification_phones_min_fields';
  approve_time?: Maybe<Scalars['timestamp']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expirate?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "verification_phones" */
export type Auth_Verification_Phones_Mutation_Response = {
  __typename?: 'auth_verification_phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Verification_Phones>;
};

/** on_conflict condition type for table "verification_phones" */
export type Auth_Verification_Phones_On_Conflict = {
  constraint: Auth_Verification_Phones_Constraint;
  update_columns?: Array<Auth_Verification_Phones_Update_Column>;
  where?: InputMaybe<Auth_Verification_Phones_Bool_Exp>;
};

/** Ordering options when selecting data from "verification_phones". */
export type Auth_Verification_Phones_Order_By = {
  approve_phone?: InputMaybe<Order_By>;
  approve_time?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expirate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: verification_phones */
export type Auth_Verification_Phones_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "verification_phones" */
export enum Auth_Verification_Phones_Select_Column {
  /** column name */
  ApprovePhone = 'approve_phone',
  /** column name */
  ApproveTime = 'approve_time',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expirate = 'expirate',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "verification_phones" */
export type Auth_Verification_Phones_Set_Input = {
  approve_phone?: InputMaybe<Scalars['Boolean']['input']>;
  approve_time?: InputMaybe<Scalars['timestamp']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expirate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Verification_Phones_Stddev_Fields = {
  __typename?: 'auth_verification_phones_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Verification_Phones_Stddev_Pop_Fields = {
  __typename?: 'auth_verification_phones_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Verification_Phones_Stddev_Samp_Fields = {
  __typename?: 'auth_verification_phones_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_verification_phones" */
export type Auth_Verification_Phones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Verification_Phones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Verification_Phones_Stream_Cursor_Value_Input = {
  approve_phone?: InputMaybe<Scalars['Boolean']['input']>;
  approve_time?: InputMaybe<Scalars['timestamp']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expirate?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Auth_Verification_Phones_Sum_Fields = {
  __typename?: 'auth_verification_phones_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "verification_phones" */
export enum Auth_Verification_Phones_Update_Column {
  /** column name */
  ApprovePhone = 'approve_phone',
  /** column name */
  ApproveTime = 'approve_time',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expirate = 'expirate',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Auth_Verification_Phones_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Verification_Phones_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Verification_Phones_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Verification_Phones_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Verification_Phones_Var_Pop_Fields = {
  __typename?: 'auth_verification_phones_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Auth_Verification_Phones_Var_Samp_Fields = {
  __typename?: 'auth_verification_phones_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Auth_Verification_Phones_Variance_Fields = {
  __typename?: 'auth_verification_phones_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

export type BillingCheckPromoCodeInput = {
  code: Scalars['String']['input'];
};

export type BillingCheckPromoCodeOutput = {
  __typename?: 'billingCheckPromoCodeOutput';
  discount: Scalars['Int']['output'];
  error: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
};

export type BillingDmsPaytureGetStatusInput = {
  order_id: Scalars['String']['input'];
};

export type BillingDmsPaytureGetStatusOutput = {
  __typename?: 'billingDmsPaytureGetStatusOutput';
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BillingGetPaytureOrderInput = {
  order_id: Scalars['String']['input'];
};

export type BillingGetPaytureOrderOutput = {
  __typename?: 'billingGetPaytureOrderOutput';
  order_police: Scalars['jsonb']['output'];
  pay_link: Scalars['jsonb']['output'];
};

export type BillingOrderPoliceCreateInput = {
  back_url: Scalars['String']['input'];
  bonuscard?: InputMaybe<Scalars['String']['input']>;
  form_guid?: InputMaybe<Scalars['String']['input']>;
  insurant: Scalars['String']['input'];
  insurants: Scalars['String']['input'];
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  payment_method?: InputMaybe<Scalars['String']['input']>;
  product: Scalars['String']['input'];
  promo_code?: InputMaybe<Scalars['String']['input']>;
  type_product: Scalars['String']['input'];
};

export type BillingOrderPoliceCreateOutput = {
  __typename?: 'billingOrderPoliceCreateOutput';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** columns and relationships of "order_polices" */
export type Billing_Order_Polices = {
  __typename?: 'billing_order_polices';
  bonuscard?: Maybe<Scalars['String']['output']>;
  check_data?: Maybe<Scalars['jsonb']['output']>;
  counter_denum?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamp']['output'];
  form_guid?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  insurant: Scalars['jsonb']['output'];
  insurants: Scalars['jsonb']['output'];
  offer_guid?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['Boolean']['output']>;
  payment_url?: Maybe<Scalars['String']['output']>;
  pnumber?: Maybe<Scalars['String']['output']>;
  policy_data?: Maybe<Scalars['jsonb']['output']>;
  product: Scalars['jsonb']['output'];
  promo_code_id?: Maybe<Scalars['bigint']['output']>;
  type_policy?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "order_polices" */
export type Billing_Order_PolicesCheck_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "order_polices" */
export type Billing_Order_PolicesInsurantArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "order_polices" */
export type Billing_Order_PolicesInsurantsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "order_polices" */
export type Billing_Order_PolicesPolicy_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "order_polices" */
export type Billing_Order_PolicesProductArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "order_polices" */
export type Billing_Order_Polices_Aggregate = {
  __typename?: 'billing_order_polices_aggregate';
  aggregate?: Maybe<Billing_Order_Polices_Aggregate_Fields>;
  nodes: Array<Billing_Order_Polices>;
};

/** aggregate fields of "order_polices" */
export type Billing_Order_Polices_Aggregate_Fields = {
  __typename?: 'billing_order_polices_aggregate_fields';
  avg?: Maybe<Billing_Order_Polices_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Billing_Order_Polices_Max_Fields>;
  min?: Maybe<Billing_Order_Polices_Min_Fields>;
  stddev?: Maybe<Billing_Order_Polices_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Order_Polices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Order_Polices_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Order_Polices_Sum_Fields>;
  var_pop?: Maybe<Billing_Order_Polices_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Order_Polices_Var_Samp_Fields>;
  variance?: Maybe<Billing_Order_Polices_Variance_Fields>;
};


/** aggregate fields of "order_polices" */
export type Billing_Order_Polices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billing_Order_Polices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Billing_Order_Polices_Append_Input = {
  check_data?: InputMaybe<Scalars['jsonb']['input']>;
  insurant?: InputMaybe<Scalars['jsonb']['input']>;
  insurants?: InputMaybe<Scalars['jsonb']['input']>;
  policy_data?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Billing_Order_Polices_Avg_Fields = {
  __typename?: 'billing_order_polices_avg_fields';
  counter_denum?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promo_code_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "order_polices". All fields are combined with a logical 'AND'. */
export type Billing_Order_Polices_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Order_Polices_Bool_Exp>>;
  _not?: InputMaybe<Billing_Order_Polices_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Order_Polices_Bool_Exp>>;
  bonuscard?: InputMaybe<String_Comparison_Exp>;
  check_data?: InputMaybe<Jsonb_Comparison_Exp>;
  counter_denum?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  form_guid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insurant?: InputMaybe<Jsonb_Comparison_Exp>;
  insurants?: InputMaybe<Jsonb_Comparison_Exp>;
  offer_guid?: InputMaybe<String_Comparison_Exp>;
  payment_method?: InputMaybe<String_Comparison_Exp>;
  payment_status?: InputMaybe<Boolean_Comparison_Exp>;
  payment_url?: InputMaybe<String_Comparison_Exp>;
  pnumber?: InputMaybe<String_Comparison_Exp>;
  policy_data?: InputMaybe<Jsonb_Comparison_Exp>;
  product?: InputMaybe<Jsonb_Comparison_Exp>;
  promo_code_id?: InputMaybe<Bigint_Comparison_Exp>;
  type_policy?: InputMaybe<String_Comparison_Exp>;
  type_product?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_polices" */
export enum Billing_Order_Polices_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderPolicesPkey = 'order_polices_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Billing_Order_Polices_Delete_At_Path_Input = {
  check_data?: InputMaybe<Array<Scalars['String']['input']>>;
  insurant?: InputMaybe<Array<Scalars['String']['input']>>;
  insurants?: InputMaybe<Array<Scalars['String']['input']>>;
  policy_data?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Billing_Order_Polices_Delete_Elem_Input = {
  check_data?: InputMaybe<Scalars['Int']['input']>;
  insurant?: InputMaybe<Scalars['Int']['input']>;
  insurants?: InputMaybe<Scalars['Int']['input']>;
  policy_data?: InputMaybe<Scalars['Int']['input']>;
  product?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Billing_Order_Polices_Delete_Key_Input = {
  check_data?: InputMaybe<Scalars['String']['input']>;
  insurant?: InputMaybe<Scalars['String']['input']>;
  insurants?: InputMaybe<Scalars['String']['input']>;
  policy_data?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "order_polices" */
export type Billing_Order_Polices_Inc_Input = {
  counter_denum?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  promo_code_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "order_polices" */
export type Billing_Order_Polices_Insert_Input = {
  bonuscard?: InputMaybe<Scalars['String']['input']>;
  check_data?: InputMaybe<Scalars['jsonb']['input']>;
  counter_denum?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  form_guid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant?: InputMaybe<Scalars['jsonb']['input']>;
  insurants?: InputMaybe<Scalars['jsonb']['input']>;
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['Boolean']['input']>;
  payment_url?: InputMaybe<Scalars['String']['input']>;
  pnumber?: InputMaybe<Scalars['String']['input']>;
  policy_data?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
  promo_code_id?: InputMaybe<Scalars['bigint']['input']>;
  type_policy?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Billing_Order_Polices_Max_Fields = {
  __typename?: 'billing_order_polices_max_fields';
  bonuscard?: Maybe<Scalars['String']['output']>;
  counter_denum?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  form_guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  offer_guid?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_url?: Maybe<Scalars['String']['output']>;
  pnumber?: Maybe<Scalars['String']['output']>;
  promo_code_id?: Maybe<Scalars['bigint']['output']>;
  type_policy?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Billing_Order_Polices_Min_Fields = {
  __typename?: 'billing_order_polices_min_fields';
  bonuscard?: Maybe<Scalars['String']['output']>;
  counter_denum?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  form_guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  offer_guid?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_url?: Maybe<Scalars['String']['output']>;
  pnumber?: Maybe<Scalars['String']['output']>;
  promo_code_id?: Maybe<Scalars['bigint']['output']>;
  type_policy?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "order_polices" */
export type Billing_Order_Polices_Mutation_Response = {
  __typename?: 'billing_order_polices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Order_Polices>;
};

/** on_conflict condition type for table "order_polices" */
export type Billing_Order_Polices_On_Conflict = {
  constraint: Billing_Order_Polices_Constraint;
  update_columns?: Array<Billing_Order_Polices_Update_Column>;
  where?: InputMaybe<Billing_Order_Polices_Bool_Exp>;
};

/** Ordering options when selecting data from "order_polices". */
export type Billing_Order_Polices_Order_By = {
  bonuscard?: InputMaybe<Order_By>;
  check_data?: InputMaybe<Order_By>;
  counter_denum?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  form_guid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant?: InputMaybe<Order_By>;
  insurants?: InputMaybe<Order_By>;
  offer_guid?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  payment_url?: InputMaybe<Order_By>;
  pnumber?: InputMaybe<Order_By>;
  policy_data?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  promo_code_id?: InputMaybe<Order_By>;
  type_policy?: InputMaybe<Order_By>;
  type_product?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_polices */
export type Billing_Order_Polices_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Billing_Order_Polices_Prepend_Input = {
  check_data?: InputMaybe<Scalars['jsonb']['input']>;
  insurant?: InputMaybe<Scalars['jsonb']['input']>;
  insurants?: InputMaybe<Scalars['jsonb']['input']>;
  policy_data?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "order_polices" */
export enum Billing_Order_Polices_Select_Column {
  /** column name */
  Bonuscard = 'bonuscard',
  /** column name */
  CheckData = 'check_data',
  /** column name */
  CounterDenum = 'counter_denum',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormGuid = 'form_guid',
  /** column name */
  Id = 'id',
  /** column name */
  Insurant = 'insurant',
  /** column name */
  Insurants = 'insurants',
  /** column name */
  OfferGuid = 'offer_guid',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  PaymentUrl = 'payment_url',
  /** column name */
  Pnumber = 'pnumber',
  /** column name */
  PolicyData = 'policy_data',
  /** column name */
  Product = 'product',
  /** column name */
  PromoCodeId = 'promo_code_id',
  /** column name */
  TypePolicy = 'type_policy',
  /** column name */
  TypeProduct = 'type_product',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "order_polices" */
export type Billing_Order_Polices_Set_Input = {
  bonuscard?: InputMaybe<Scalars['String']['input']>;
  check_data?: InputMaybe<Scalars['jsonb']['input']>;
  counter_denum?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  form_guid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant?: InputMaybe<Scalars['jsonb']['input']>;
  insurants?: InputMaybe<Scalars['jsonb']['input']>;
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['Boolean']['input']>;
  payment_url?: InputMaybe<Scalars['String']['input']>;
  pnumber?: InputMaybe<Scalars['String']['input']>;
  policy_data?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
  promo_code_id?: InputMaybe<Scalars['bigint']['input']>;
  type_policy?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Billing_Order_Polices_Stddev_Fields = {
  __typename?: 'billing_order_polices_stddev_fields';
  counter_denum?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promo_code_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Billing_Order_Polices_Stddev_Pop_Fields = {
  __typename?: 'billing_order_polices_stddev_pop_fields';
  counter_denum?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promo_code_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Billing_Order_Polices_Stddev_Samp_Fields = {
  __typename?: 'billing_order_polices_stddev_samp_fields';
  counter_denum?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promo_code_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "billing_order_polices" */
export type Billing_Order_Polices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Order_Polices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Order_Polices_Stream_Cursor_Value_Input = {
  bonuscard?: InputMaybe<Scalars['String']['input']>;
  check_data?: InputMaybe<Scalars['jsonb']['input']>;
  counter_denum?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  form_guid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant?: InputMaybe<Scalars['jsonb']['input']>;
  insurants?: InputMaybe<Scalars['jsonb']['input']>;
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['Boolean']['input']>;
  payment_url?: InputMaybe<Scalars['String']['input']>;
  pnumber?: InputMaybe<Scalars['String']['input']>;
  policy_data?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
  promo_code_id?: InputMaybe<Scalars['bigint']['input']>;
  type_policy?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Billing_Order_Polices_Sum_Fields = {
  __typename?: 'billing_order_polices_sum_fields';
  counter_denum?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  promo_code_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "order_polices" */
export enum Billing_Order_Polices_Update_Column {
  /** column name */
  Bonuscard = 'bonuscard',
  /** column name */
  CheckData = 'check_data',
  /** column name */
  CounterDenum = 'counter_denum',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormGuid = 'form_guid',
  /** column name */
  Id = 'id',
  /** column name */
  Insurant = 'insurant',
  /** column name */
  Insurants = 'insurants',
  /** column name */
  OfferGuid = 'offer_guid',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  PaymentUrl = 'payment_url',
  /** column name */
  Pnumber = 'pnumber',
  /** column name */
  PolicyData = 'policy_data',
  /** column name */
  Product = 'product',
  /** column name */
  PromoCodeId = 'promo_code_id',
  /** column name */
  TypePolicy = 'type_policy',
  /** column name */
  TypeProduct = 'type_product',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Billing_Order_Polices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Billing_Order_Polices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Billing_Order_Polices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Billing_Order_Polices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Billing_Order_Polices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Billing_Order_Polices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Billing_Order_Polices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Order_Polices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Order_Polices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Billing_Order_Polices_Var_Pop_Fields = {
  __typename?: 'billing_order_polices_var_pop_fields';
  counter_denum?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promo_code_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Billing_Order_Polices_Var_Samp_Fields = {
  __typename?: 'billing_order_polices_var_samp_fields';
  counter_denum?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promo_code_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Billing_Order_Polices_Variance_Fields = {
  __typename?: 'billing_order_polices_variance_fields';
  counter_denum?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promo_code_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "billing_order_statuses" */
export type Billing_Order_Statuses = {
  __typename?: 'billing_order_statuses';
  created_at: Scalars['timestamp']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  order_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "billing_order_statuses" */
export type Billing_Order_Statuses_Aggregate = {
  __typename?: 'billing_order_statuses_aggregate';
  aggregate?: Maybe<Billing_Order_Statuses_Aggregate_Fields>;
  nodes: Array<Billing_Order_Statuses>;
};

/** aggregate fields of "billing_order_statuses" */
export type Billing_Order_Statuses_Aggregate_Fields = {
  __typename?: 'billing_order_statuses_aggregate_fields';
  avg?: Maybe<Billing_Order_Statuses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Billing_Order_Statuses_Max_Fields>;
  min?: Maybe<Billing_Order_Statuses_Min_Fields>;
  stddev?: Maybe<Billing_Order_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Order_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Order_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Order_Statuses_Sum_Fields>;
  var_pop?: Maybe<Billing_Order_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Order_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Billing_Order_Statuses_Variance_Fields>;
};


/** aggregate fields of "billing_order_statuses" */
export type Billing_Order_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billing_Order_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Billing_Order_Statuses_Avg_Fields = {
  __typename?: 'billing_order_statuses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "billing_order_statuses". All fields are combined with a logical 'AND'. */
export type Billing_Order_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Order_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Billing_Order_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Order_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing_order_statuses" */
export enum Billing_Order_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingOrderStatusesPkey = 'billing_order_statuses_pkey'
}

/** input type for incrementing numeric columns in table "billing_order_statuses" */
export type Billing_Order_Statuses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "billing_order_statuses" */
export type Billing_Order_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Billing_Order_Statuses_Max_Fields = {
  __typename?: 'billing_order_statuses_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Billing_Order_Statuses_Min_Fields = {
  __typename?: 'billing_order_statuses_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "billing_order_statuses" */
export type Billing_Order_Statuses_Mutation_Response = {
  __typename?: 'billing_order_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Order_Statuses>;
};

/** on_conflict condition type for table "billing_order_statuses" */
export type Billing_Order_Statuses_On_Conflict = {
  constraint: Billing_Order_Statuses_Constraint;
  update_columns?: Array<Billing_Order_Statuses_Update_Column>;
  where?: InputMaybe<Billing_Order_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "billing_order_statuses". */
export type Billing_Order_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: billing_order_statuses */
export type Billing_Order_Statuses_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "billing_order_statuses" */
export enum Billing_Order_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "billing_order_statuses" */
export type Billing_Order_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Billing_Order_Statuses_Stddev_Fields = {
  __typename?: 'billing_order_statuses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Billing_Order_Statuses_Stddev_Pop_Fields = {
  __typename?: 'billing_order_statuses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Billing_Order_Statuses_Stddev_Samp_Fields = {
  __typename?: 'billing_order_statuses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "billing_order_statuses" */
export type Billing_Order_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Order_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Order_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Billing_Order_Statuses_Sum_Fields = {
  __typename?: 'billing_order_statuses_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "billing_order_statuses" */
export enum Billing_Order_Statuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Billing_Order_Statuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Billing_Order_Statuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Order_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Order_Statuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Billing_Order_Statuses_Var_Pop_Fields = {
  __typename?: 'billing_order_statuses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Billing_Order_Statuses_Var_Samp_Fields = {
  __typename?: 'billing_order_statuses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Billing_Order_Statuses_Variance_Fields = {
  __typename?: 'billing_order_statuses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "promo_codes" */
export type Billing_Promo_Codes = {
  __typename?: 'billing_promo_codes';
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  discount?: Maybe<Scalars['Int']['output']>;
  expiration?: Maybe<Scalars['date']['output']>;
  id: Scalars['bigint']['output'];
  type_code?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  use?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "promo_codes" */
export type Billing_Promo_Codes_Aggregate = {
  __typename?: 'billing_promo_codes_aggregate';
  aggregate?: Maybe<Billing_Promo_Codes_Aggregate_Fields>;
  nodes: Array<Billing_Promo_Codes>;
};

/** aggregate fields of "promo_codes" */
export type Billing_Promo_Codes_Aggregate_Fields = {
  __typename?: 'billing_promo_codes_aggregate_fields';
  avg?: Maybe<Billing_Promo_Codes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Billing_Promo_Codes_Max_Fields>;
  min?: Maybe<Billing_Promo_Codes_Min_Fields>;
  stddev?: Maybe<Billing_Promo_Codes_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Promo_Codes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Promo_Codes_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Promo_Codes_Sum_Fields>;
  var_pop?: Maybe<Billing_Promo_Codes_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Promo_Codes_Var_Samp_Fields>;
  variance?: Maybe<Billing_Promo_Codes_Variance_Fields>;
};


/** aggregate fields of "promo_codes" */
export type Billing_Promo_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billing_Promo_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Billing_Promo_Codes_Avg_Fields = {
  __typename?: 'billing_promo_codes_avg_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "promo_codes". All fields are combined with a logical 'AND'. */
export type Billing_Promo_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Promo_Codes_Bool_Exp>>;
  _not?: InputMaybe<Billing_Promo_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Promo_Codes_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  expiration?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type_code?: InputMaybe<String_Comparison_Exp>;
  type_product?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  use?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "promo_codes" */
export enum Billing_Promo_Codes_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromoCodesPkey = 'promo_codes_pkey'
}

/** input type for incrementing numeric columns in table "promo_codes" */
export type Billing_Promo_Codes_Inc_Input = {
  discount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "promo_codes" */
export type Billing_Promo_Codes_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  expiration?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_code?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  use?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Billing_Promo_Codes_Max_Fields = {
  __typename?: 'billing_promo_codes_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  expiration?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  type_code?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Billing_Promo_Codes_Min_Fields = {
  __typename?: 'billing_promo_codes_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  expiration?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  type_code?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "promo_codes" */
export type Billing_Promo_Codes_Mutation_Response = {
  __typename?: 'billing_promo_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Promo_Codes>;
};

/** on_conflict condition type for table "promo_codes" */
export type Billing_Promo_Codes_On_Conflict = {
  constraint: Billing_Promo_Codes_Constraint;
  update_columns?: Array<Billing_Promo_Codes_Update_Column>;
  where?: InputMaybe<Billing_Promo_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "promo_codes". */
export type Billing_Promo_Codes_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_code?: InputMaybe<Order_By>;
  type_product?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  use?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promo_codes */
export type Billing_Promo_Codes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "promo_codes" */
export enum Billing_Promo_Codes_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  TypeCode = 'type_code',
  /** column name */
  TypeProduct = 'type_product',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Use = 'use'
}

/** input type for updating data in table "promo_codes" */
export type Billing_Promo_Codes_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  expiration?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_code?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  use?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Billing_Promo_Codes_Stddev_Fields = {
  __typename?: 'billing_promo_codes_stddev_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Billing_Promo_Codes_Stddev_Pop_Fields = {
  __typename?: 'billing_promo_codes_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Billing_Promo_Codes_Stddev_Samp_Fields = {
  __typename?: 'billing_promo_codes_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "billing_promo_codes" */
export type Billing_Promo_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Promo_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Promo_Codes_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  expiration?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_code?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  use?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Billing_Promo_Codes_Sum_Fields = {
  __typename?: 'billing_promo_codes_sum_fields';
  discount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "promo_codes" */
export enum Billing_Promo_Codes_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  TypeCode = 'type_code',
  /** column name */
  TypeProduct = 'type_product',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Use = 'use'
}

export type Billing_Promo_Codes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Billing_Promo_Codes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Promo_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Promo_Codes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Billing_Promo_Codes_Var_Pop_Fields = {
  __typename?: 'billing_promo_codes_var_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Billing_Promo_Codes_Var_Samp_Fields = {
  __typename?: 'billing_promo_codes_var_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Billing_Promo_Codes_Variance_Fields = {
  __typename?: 'billing_promo_codes_variance_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type ChatMessages = {
  __typename?: 'chatMessages';
  changed_message?: Maybe<Scalars['Boolean']['output']>;
  chat_id?: Maybe<Scalars['Int']['output']>;
  chat_message_id?: Maybe<Scalars['Int']['output']>;
  chat_message_type_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_all?: Maybe<Scalars['Boolean']['output']>;
  deleted_my?: Maybe<Scalars['Boolean']['output']>;
  deletion_date?: Maybe<Scalars['timestamptz']['output']>;
  forward_message_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  link_snippet_id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status_message_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type Chat_Messages = {
  __typename?: 'chat_messages';
  chat_id?: Maybe<Scalars['Int']['output']>;
  chat_message_id?: Maybe<Scalars['Int']['output']>;
  chat_message_type_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  link_snippet_id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  status_message_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type Chats = {
  __typename?: 'chats';
  avatar_path?: Maybe<Scalars['String']['output']>;
  chat_type_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

export type DmsApiDenumCheckSmsCodeInput = {
  code: Scalars['String']['input'];
  form_guid: Scalars['String']['input'];
  offer_guid: Scalars['String']['input'];
};

export type DmsApiDenumCheckSmsCodeOutput = {
  __typename?: 'dmsApiDenumCheckSmsCodeOutput';
  error: Scalars['jsonb']['output'];
  ok: Scalars['Boolean']['output'];
};

export type DmsApiDenumCreateFormInput = {
  birth_date: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  insured_date_of_birth: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  middle_name?: InputMaybe<Scalars['String']['input']>;
  mobile_phone: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  passport_division_code: Scalars['String']['input'];
  passport_issue_date: Scalars['String']['input'];
  passport_number: Scalars['String']['input'];
  passport_series: Scalars['String']['input'];
  product_id: Scalars['Int']['input'];
  promo_code?: InputMaybe<Scalars['String']['input']>;
  registration_address_string: Scalars['String']['input'];
  residence_address_string: Scalars['String']['input'];
};

export type DmsApiDenumCreateFormOutput = {
  __typename?: 'dmsApiDenumCreateFormOutput';
  error: Scalars['jsonb']['output'];
  form_guid?: Maybe<Scalars['String']['output']>;
  offer_guid?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

export type DmsApiDenumGetContractInput = {
  form_guid: Scalars['String']['input'];
  offer_guid: Scalars['String']['input'];
};

export type DmsApiDenumGetContractOutput = {
  __typename?: 'dmsApiDenumGetContractOutput';
  error: Scalars['jsonb']['output'];
  ok: Scalars['Boolean']['output'];
  payment_url?: Maybe<Scalars['String']['output']>;
};

export type DmsApiDenumGetSigningStatusInput = {
  form_guid: Scalars['String']['input'];
  offer_guid: Scalars['String']['input'];
};

export type DmsApiDenumGetSigningStatusOutput = {
  __typename?: 'dmsApiDenumGetSigningStatusOutput';
  error: Scalars['jsonb']['output'];
  ok: Scalars['Boolean']['output'];
  payment_url: Scalars['String']['output'];
};

export type DmsApiDenumResendSmsCodeInput = {
  form_guid: Scalars['String']['input'];
  offer_guid: Scalars['String']['input'];
};

export type DmsApiDenumResendSmsCodeOutput = {
  __typename?: 'dmsApiDenumResendSmsCodeOutput';
  error: Scalars['jsonb']['output'];
  ok: Scalars['Boolean']['output'];
};

export type DmsCheckPromoCodeInput = {
  code: Scalars['String']['input'];
};

export type DmsCheckPromoCodeOutput = {
  __typename?: 'dmsCheckPromoCodeOutput';
  discount: Scalars['Int']['output'];
  error: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
};

export type DmsCreateInsurantInput = {
  insurant: Scalars['String']['input'];
  insured: Scalars['String']['input'];
  product_id: Scalars['Int']['input'];
  promo_code: Scalars['String']['input'];
};

export type DmsCreateInsurantOutput = {
  __typename?: 'dmsCreateInsurantOutput';
  error: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
  order_id: Scalars['String']['output'];
  payment_link: Scalars['String']['output'];
};

export type DmsCreateOrderInput = {
  back_url: Scalars['String']['input'];
  form_guid?: InputMaybe<Scalars['String']['input']>;
  insurant: Scalars['String']['input'];
  insured: Scalars['String']['input'];
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['String']['input'];
  payment_method?: InputMaybe<Scalars['String']['input']>;
  product_id: Scalars['Int']['input'];
  promo_code?: InputMaybe<Scalars['String']['input']>;
  start_date: Scalars['date']['input'];
};

export type DmsCreateOrderNumberOutput = {
  __typename?: 'dmsCreateOrderNumberOutput';
  order_id: Scalars['String']['output'];
};

export type DmsCreateOrderOutput = {
  __typename?: 'dmsCreateOrderOutput';
  error: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
  order_id: Scalars['String']['output'];
  payment_link: Scalars['String']['output'];
};

export type DmsGetOrderDiscountInput = {
  order_id: Scalars['String']['input'];
};

export type DmsGetOrderDiscountOutput = {
  __typename?: 'dmsGetOrderDiscountOutput';
  discount: Scalars['Int']['output'];
};

export type DmsGetPolicyCardInput = {
  group_id?: InputMaybe<Scalars['Int']['input']>;
};

export type DmsGetPolicyCardOutput = {
  __typename?: 'dmsGetPolicyCardOutput';
  policy_card: Scalars['jsonb']['output'];
};

export type DmsOrderLinksByUidInput = {
  order_id: Scalars['String']['input'];
};

export type DmsOrderLinksByUidOutput = {
  __typename?: 'dmsOrderLinksByUidOutput';
  order?: Maybe<Scalars['jsonb']['output']>;
};

export type DmsPrintOrderInput = {
  order_id: Scalars['String']['input'];
};

export type DmsPrintOrderOutput = {
  __typename?: 'dmsPrintOrderOutput';
  description: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

/** columns and relationships of "cities" */
export type Dms_Cities = {
  __typename?: 'dms_cities';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  products: Array<Dms_Products>;
  /** An aggregate relationship */
  products_aggregate: Dms_Products_Aggregate;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "cities" */
export type Dms_CitiesProductsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


/** columns and relationships of "cities" */
export type Dms_CitiesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};

/** aggregated selection of "cities" */
export type Dms_Cities_Aggregate = {
  __typename?: 'dms_cities_aggregate';
  aggregate?: Maybe<Dms_Cities_Aggregate_Fields>;
  nodes: Array<Dms_Cities>;
};

/** aggregate fields of "cities" */
export type Dms_Cities_Aggregate_Fields = {
  __typename?: 'dms_cities_aggregate_fields';
  avg?: Maybe<Dms_Cities_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Cities_Max_Fields>;
  min?: Maybe<Dms_Cities_Min_Fields>;
  stddev?: Maybe<Dms_Cities_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Cities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Cities_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Cities_Sum_Fields>;
  var_pop?: Maybe<Dms_Cities_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Cities_Var_Samp_Fields>;
  variance?: Maybe<Dms_Cities_Variance_Fields>;
};


/** aggregate fields of "cities" */
export type Dms_Cities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Cities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Cities_Avg_Fields = {
  __typename?: 'dms_cities_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "cities". All fields are combined with a logical 'AND'. */
export type Dms_Cities_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Cities_Bool_Exp>>;
  _not?: InputMaybe<Dms_Cities_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Cities_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  products?: InputMaybe<Dms_Products_Bool_Exp>;
  products_aggregate?: InputMaybe<Dms_Products_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "cities" */
export enum Dms_Cities_Constraint {
  /** unique or primary key constraint on columns "id" */
  CitiesPkey = 'cities_pkey'
}

/** input type for incrementing numeric columns in table "cities" */
export type Dms_Cities_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "cities" */
export type Dms_Cities_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  products?: InputMaybe<Dms_Products_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Dms_Cities_Max_Fields = {
  __typename?: 'dms_cities_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Dms_Cities_Min_Fields = {
  __typename?: 'dms_cities_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "cities" */
export type Dms_Cities_Mutation_Response = {
  __typename?: 'dms_cities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Cities>;
};

/** input type for inserting object relation for remote table "cities" */
export type Dms_Cities_Obj_Rel_Insert_Input = {
  data: Dms_Cities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Cities_On_Conflict>;
};

/** on_conflict condition type for table "cities" */
export type Dms_Cities_On_Conflict = {
  constraint: Dms_Cities_Constraint;
  update_columns?: Array<Dms_Cities_Update_Column>;
  where?: InputMaybe<Dms_Cities_Bool_Exp>;
};

/** Ordering options when selecting data from "cities". */
export type Dms_Cities_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Dms_Products_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cities */
export type Dms_Cities_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "cities" */
export enum Dms_Cities_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "cities" */
export type Dms_Cities_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Cities_Stddev_Fields = {
  __typename?: 'dms_cities_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Cities_Stddev_Pop_Fields = {
  __typename?: 'dms_cities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Cities_Stddev_Samp_Fields = {
  __typename?: 'dms_cities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_cities" */
export type Dms_Cities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Cities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Cities_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Dms_Cities_Sum_Fields = {
  __typename?: 'dms_cities_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "cities" */
export enum Dms_Cities_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Cities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Cities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Cities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Cities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Cities_Var_Pop_Fields = {
  __typename?: 'dms_cities_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Cities_Var_Samp_Fields = {
  __typename?: 'dms_cities_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Cities_Variance_Fields = {
  __typename?: 'dms_cities_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "group_insureds" */
export type Dms_Group_Insureds = {
  __typename?: 'dms_group_insureds';
  created_at: Scalars['timestamp']['output'];
  /** An object relationship */
  group?: Maybe<Dms_Groups>;
  group_id?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  insured?: Maybe<Dms_Insureds>;
  insured_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "group_insureds" */
export type Dms_Group_Insureds_Aggregate = {
  __typename?: 'dms_group_insureds_aggregate';
  aggregate?: Maybe<Dms_Group_Insureds_Aggregate_Fields>;
  nodes: Array<Dms_Group_Insureds>;
};

export type Dms_Group_Insureds_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dms_Group_Insureds_Aggregate_Bool_Exp_Count>;
};

export type Dms_Group_Insureds_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "group_insureds" */
export type Dms_Group_Insureds_Aggregate_Fields = {
  __typename?: 'dms_group_insureds_aggregate_fields';
  avg?: Maybe<Dms_Group_Insureds_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Group_Insureds_Max_Fields>;
  min?: Maybe<Dms_Group_Insureds_Min_Fields>;
  stddev?: Maybe<Dms_Group_Insureds_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Group_Insureds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Group_Insureds_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Group_Insureds_Sum_Fields>;
  var_pop?: Maybe<Dms_Group_Insureds_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Group_Insureds_Var_Samp_Fields>;
  variance?: Maybe<Dms_Group_Insureds_Variance_Fields>;
};


/** aggregate fields of "group_insureds" */
export type Dms_Group_Insureds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "group_insureds" */
export type Dms_Group_Insureds_Aggregate_Order_By = {
  avg?: InputMaybe<Dms_Group_Insureds_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dms_Group_Insureds_Max_Order_By>;
  min?: InputMaybe<Dms_Group_Insureds_Min_Order_By>;
  stddev?: InputMaybe<Dms_Group_Insureds_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dms_Group_Insureds_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dms_Group_Insureds_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dms_Group_Insureds_Sum_Order_By>;
  var_pop?: InputMaybe<Dms_Group_Insureds_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dms_Group_Insureds_Var_Samp_Order_By>;
  variance?: InputMaybe<Dms_Group_Insureds_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "group_insureds" */
export type Dms_Group_Insureds_Arr_Rel_Insert_Input = {
  data: Array<Dms_Group_Insureds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Group_Insureds_On_Conflict>;
};

/** aggregate avg on columns */
export type Dms_Group_Insureds_Avg_Fields = {
  __typename?: 'dms_group_insureds_avg_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insured_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "group_insureds". All fields are combined with a logical 'AND'. */
export type Dms_Group_Insureds_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Group_Insureds_Bool_Exp>>;
  _not?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Group_Insureds_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  group?: InputMaybe<Dms_Groups_Bool_Exp>;
  group_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insured?: InputMaybe<Dms_Insureds_Bool_Exp>;
  insured_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_insureds" */
export enum Dms_Group_Insureds_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupInsuredsPkey = 'group_insureds_pkey'
}

/** input type for incrementing numeric columns in table "group_insureds" */
export type Dms_Group_Insureds_Inc_Input = {
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insured_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "group_insureds" */
export type Dms_Group_Insureds_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  group?: InputMaybe<Dms_Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insured?: InputMaybe<Dms_Insureds_Obj_Rel_Insert_Input>;
  insured_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Dms_Group_Insureds_Max_Fields = {
  __typename?: 'dms_group_insureds_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  group_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insured_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dms_Group_Insureds_Min_Fields = {
  __typename?: 'dms_group_insureds_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  group_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insured_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "group_insureds" */
export type Dms_Group_Insureds_Mutation_Response = {
  __typename?: 'dms_group_insureds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Group_Insureds>;
};

/** on_conflict condition type for table "group_insureds" */
export type Dms_Group_Insureds_On_Conflict = {
  constraint: Dms_Group_Insureds_Constraint;
  update_columns?: Array<Dms_Group_Insureds_Update_Column>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};

/** Ordering options when selecting data from "group_insureds". */
export type Dms_Group_Insureds_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Dms_Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured?: InputMaybe<Dms_Insureds_Order_By>;
  insured_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_insureds */
export type Dms_Group_Insureds_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "group_insureds" */
export enum Dms_Group_Insureds_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuredId = 'insured_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "group_insureds" */
export type Dms_Group_Insureds_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insured_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Group_Insureds_Stddev_Fields = {
  __typename?: 'dms_group_insureds_stddev_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insured_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dms_Group_Insureds_Stddev_Pop_Fields = {
  __typename?: 'dms_group_insureds_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insured_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dms_Group_Insureds_Stddev_Samp_Fields = {
  __typename?: 'dms_group_insureds_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insured_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dms_group_insureds" */
export type Dms_Group_Insureds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Group_Insureds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Group_Insureds_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insured_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Dms_Group_Insureds_Sum_Fields = {
  __typename?: 'dms_group_insureds_sum_fields';
  group_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insured_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "group_insureds" */
export enum Dms_Group_Insureds_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuredId = 'insured_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Group_Insureds_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Group_Insureds_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Group_Insureds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Group_Insureds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Group_Insureds_Var_Pop_Fields = {
  __typename?: 'dms_group_insureds_var_pop_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insured_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dms_Group_Insureds_Var_Samp_Fields = {
  __typename?: 'dms_group_insureds_var_samp_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insured_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dms_Group_Insureds_Variance_Fields = {
  __typename?: 'dms_group_insureds_variance_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insured_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "group_insureds" */
export type Dms_Group_Insureds_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insured_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "groups" */
export type Dms_Groups = {
  __typename?: 'dms_groups';
  created_at: Scalars['timestamp']['output'];
  /** An array relationship */
  group_insureds: Array<Dms_Group_Insureds>;
  /** An aggregate relationship */
  group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  group_name: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "groups" */
export type Dms_GroupsGroup_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type Dms_GroupsGroup_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Dms_Groups_Aggregate = {
  __typename?: 'dms_groups_aggregate';
  aggregate?: Maybe<Dms_Groups_Aggregate_Fields>;
  nodes: Array<Dms_Groups>;
};

/** aggregate fields of "groups" */
export type Dms_Groups_Aggregate_Fields = {
  __typename?: 'dms_groups_aggregate_fields';
  avg?: Maybe<Dms_Groups_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Groups_Max_Fields>;
  min?: Maybe<Dms_Groups_Min_Fields>;
  stddev?: Maybe<Dms_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Groups_Sum_Fields>;
  var_pop?: Maybe<Dms_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Groups_Var_Samp_Fields>;
  variance?: Maybe<Dms_Groups_Variance_Fields>;
};


/** aggregate fields of "groups" */
export type Dms_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Groups_Avg_Fields = {
  __typename?: 'dms_groups_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Dms_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Groups_Bool_Exp>>;
  _not?: InputMaybe<Dms_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Groups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
  group_insureds_aggregate?: InputMaybe<Dms_Group_Insureds_Aggregate_Bool_Exp>;
  group_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Dms_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** input type for incrementing numeric columns in table "groups" */
export type Dms_Groups_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "groups" */
export type Dms_Groups_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Arr_Rel_Insert_Input>;
  group_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Dms_Groups_Max_Fields = {
  __typename?: 'dms_groups_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  group_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Dms_Groups_Min_Fields = {
  __typename?: 'dms_groups_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  group_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "groups" */
export type Dms_Groups_Mutation_Response = {
  __typename?: 'dms_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Dms_Groups_Obj_Rel_Insert_Input = {
  data: Dms_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Dms_Groups_On_Conflict = {
  constraint: Dms_Groups_Constraint;
  update_columns?: Array<Dms_Groups_Update_Column>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Dms_Groups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_insureds_aggregate?: InputMaybe<Dms_Group_Insureds_Aggregate_Order_By>;
  group_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups */
export type Dms_Groups_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "groups" */
export enum Dms_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "groups" */
export type Dms_Groups_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  group_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Groups_Stddev_Fields = {
  __typename?: 'dms_groups_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Groups_Stddev_Pop_Fields = {
  __typename?: 'dms_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Groups_Stddev_Samp_Fields = {
  __typename?: 'dms_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_groups" */
export type Dms_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  group_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Dms_Groups_Sum_Fields = {
  __typename?: 'dms_groups_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "groups" */
export enum Dms_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Groups_Var_Pop_Fields = {
  __typename?: 'dms_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Groups_Var_Samp_Fields = {
  __typename?: 'dms_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Groups_Variance_Fields = {
  __typename?: 'dms_groups_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "insurants" */
export type Dms_Insurants = {
  __typename?: 'dms_insurants';
  birth_place?: Maybe<Scalars['String']['output']>;
  bithday: Scalars['date']['output'];
  created_at: Scalars['timestamp']['output'];
  date_release: Scalars['date']['output'];
  division_code: Scalars['String']['output'];
  division_issuing: Scalars['String']['output'];
  dms_file_path?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  foreign_passport?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  insureds: Array<Dms_Insureds>;
  /** An aggregate relationship */
  insureds_aggregate: Dms_Insureds_Aggregate;
  last_name: Scalars['String']['output'];
  order_uid?: Maybe<Scalars['String']['output']>;
  passport: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  registration_address: Scalars['String']['output'];
  residence?: Maybe<Scalars['String']['output']>;
  second_name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "insurants" */
export type Dms_InsurantsInsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


/** columns and relationships of "insurants" */
export type Dms_InsurantsInsureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};

/** aggregated selection of "insurants" */
export type Dms_Insurants_Aggregate = {
  __typename?: 'dms_insurants_aggregate';
  aggregate?: Maybe<Dms_Insurants_Aggregate_Fields>;
  nodes: Array<Dms_Insurants>;
};

/** aggregate fields of "insurants" */
export type Dms_Insurants_Aggregate_Fields = {
  __typename?: 'dms_insurants_aggregate_fields';
  avg?: Maybe<Dms_Insurants_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Insurants_Max_Fields>;
  min?: Maybe<Dms_Insurants_Min_Fields>;
  stddev?: Maybe<Dms_Insurants_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Insurants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Insurants_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Insurants_Sum_Fields>;
  var_pop?: Maybe<Dms_Insurants_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Insurants_Var_Samp_Fields>;
  variance?: Maybe<Dms_Insurants_Variance_Fields>;
};


/** aggregate fields of "insurants" */
export type Dms_Insurants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Insurants_Avg_Fields = {
  __typename?: 'dms_insurants_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "insurants". All fields are combined with a logical 'AND'. */
export type Dms_Insurants_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Insurants_Bool_Exp>>;
  _not?: InputMaybe<Dms_Insurants_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Insurants_Bool_Exp>>;
  birth_place?: InputMaybe<String_Comparison_Exp>;
  bithday?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_release?: InputMaybe<Date_Comparison_Exp>;
  division_code?: InputMaybe<String_Comparison_Exp>;
  division_issuing?: InputMaybe<String_Comparison_Exp>;
  dms_file_path?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  foreign_passport?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insureds?: InputMaybe<Dms_Insureds_Bool_Exp>;
  insureds_aggregate?: InputMaybe<Dms_Insureds_Aggregate_Bool_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  order_uid?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  registration_address?: InputMaybe<String_Comparison_Exp>;
  residence?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurants" */
export enum Dms_Insurants_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsurantsPkey = 'insurants_pkey'
}

/** input type for incrementing numeric columns in table "insurants" */
export type Dms_Insurants_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "insurants" */
export type Dms_Insurants_Insert_Input = {
  birth_place?: InputMaybe<Scalars['String']['input']>;
  bithday?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_release?: InputMaybe<Scalars['date']['input']>;
  division_code?: InputMaybe<Scalars['String']['input']>;
  division_issuing?: InputMaybe<Scalars['String']['input']>;
  dms_file_path?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insureds?: InputMaybe<Dms_Insureds_Arr_Rel_Insert_Input>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  order_uid?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  second_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Dms_Insurants_Max_Fields = {
  __typename?: 'dms_insurants_max_fields';
  birth_place?: Maybe<Scalars['String']['output']>;
  bithday?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_release?: Maybe<Scalars['date']['output']>;
  division_code?: Maybe<Scalars['String']['output']>;
  division_issuing?: Maybe<Scalars['String']['output']>;
  dms_file_path?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  order_uid?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  second_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Dms_Insurants_Min_Fields = {
  __typename?: 'dms_insurants_min_fields';
  birth_place?: Maybe<Scalars['String']['output']>;
  bithday?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_release?: Maybe<Scalars['date']['output']>;
  division_code?: Maybe<Scalars['String']['output']>;
  division_issuing?: Maybe<Scalars['String']['output']>;
  dms_file_path?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  order_uid?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  second_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "insurants" */
export type Dms_Insurants_Mutation_Response = {
  __typename?: 'dms_insurants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Insurants>;
};

/** input type for inserting object relation for remote table "insurants" */
export type Dms_Insurants_Obj_Rel_Insert_Input = {
  data: Dms_Insurants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Insurants_On_Conflict>;
};

/** on_conflict condition type for table "insurants" */
export type Dms_Insurants_On_Conflict = {
  constraint: Dms_Insurants_Constraint;
  update_columns?: Array<Dms_Insurants_Update_Column>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};

/** Ordering options when selecting data from "insurants". */
export type Dms_Insurants_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  dms_file_path?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insureds_aggregate?: InputMaybe<Dms_Insureds_Aggregate_Order_By>;
  last_name?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insurants */
export type Dms_Insurants_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "insurants" */
export enum Dms_Insurants_Select_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  DmsFilePath = 'dms_file_path',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "insurants" */
export type Dms_Insurants_Set_Input = {
  birth_place?: InputMaybe<Scalars['String']['input']>;
  bithday?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_release?: InputMaybe<Scalars['date']['input']>;
  division_code?: InputMaybe<Scalars['String']['input']>;
  division_issuing?: InputMaybe<Scalars['String']['input']>;
  dms_file_path?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  order_uid?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  second_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Insurants_Stddev_Fields = {
  __typename?: 'dms_insurants_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Insurants_Stddev_Pop_Fields = {
  __typename?: 'dms_insurants_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Insurants_Stddev_Samp_Fields = {
  __typename?: 'dms_insurants_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_insurants" */
export type Dms_Insurants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Insurants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Insurants_Stream_Cursor_Value_Input = {
  birth_place?: InputMaybe<Scalars['String']['input']>;
  bithday?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_release?: InputMaybe<Scalars['date']['input']>;
  division_code?: InputMaybe<Scalars['String']['input']>;
  division_issuing?: InputMaybe<Scalars['String']['input']>;
  dms_file_path?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  order_uid?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  second_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Dms_Insurants_Sum_Fields = {
  __typename?: 'dms_insurants_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "insurants" */
export enum Dms_Insurants_Update_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  DmsFilePath = 'dms_file_path',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Insurants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Insurants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Insurants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Insurants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Insurants_Var_Pop_Fields = {
  __typename?: 'dms_insurants_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Insurants_Var_Samp_Fields = {
  __typename?: 'dms_insurants_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Insurants_Variance_Fields = {
  __typename?: 'dms_insurants_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "insureds" */
export type Dms_Insureds = {
  __typename?: 'dms_insureds';
  birth_place?: Maybe<Scalars['String']['output']>;
  bithday: Scalars['date']['output'];
  created_at: Scalars['timestamp']['output'];
  date_release: Scalars['date']['output'];
  division_code: Scalars['String']['output'];
  division_issuing: Scalars['String']['output'];
  dms_file_path?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  foreign?: Maybe<Scalars['Boolean']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Boolean']['output']>;
  group_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  group_insureds: Array<Dms_Group_Insureds>;
  /** An aggregate relationship */
  group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  insurant?: Maybe<Dms_Insurants>;
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  last_name: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  oms?: Maybe<Scalars['String']['output']>;
  order_uid?: Maybe<Scalars['String']['output']>;
  passport: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  product_info?: Maybe<Scalars['jsonb']['output']>;
  registration_address: Scalars['String']['output'];
  residence?: Maybe<Scalars['String']['output']>;
  second_name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "insureds" */
export type Dms_InsuredsGroup_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


/** columns and relationships of "insureds" */
export type Dms_InsuredsGroup_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


/** columns and relationships of "insureds" */
export type Dms_InsuredsProduct_InfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "insureds" */
export type Dms_Insureds_Aggregate = {
  __typename?: 'dms_insureds_aggregate';
  aggregate?: Maybe<Dms_Insureds_Aggregate_Fields>;
  nodes: Array<Dms_Insureds>;
};

export type Dms_Insureds_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Dms_Insureds_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Dms_Insureds_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Dms_Insureds_Aggregate_Bool_Exp_Count>;
};

export type Dms_Insureds_Aggregate_Bool_Exp_Bool_And = {
  arguments: Dms_Insureds_Select_Column_Dms_Insureds_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Dms_Insureds_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dms_Insureds_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Dms_Insureds_Select_Column_Dms_Insureds_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Dms_Insureds_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Dms_Insureds_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Dms_Insureds_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "insureds" */
export type Dms_Insureds_Aggregate_Fields = {
  __typename?: 'dms_insureds_aggregate_fields';
  avg?: Maybe<Dms_Insureds_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Insureds_Max_Fields>;
  min?: Maybe<Dms_Insureds_Min_Fields>;
  stddev?: Maybe<Dms_Insureds_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Insureds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Insureds_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Insureds_Sum_Fields>;
  var_pop?: Maybe<Dms_Insureds_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Insureds_Var_Samp_Fields>;
  variance?: Maybe<Dms_Insureds_Variance_Fields>;
};


/** aggregate fields of "insureds" */
export type Dms_Insureds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "insureds" */
export type Dms_Insureds_Aggregate_Order_By = {
  avg?: InputMaybe<Dms_Insureds_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dms_Insureds_Max_Order_By>;
  min?: InputMaybe<Dms_Insureds_Min_Order_By>;
  stddev?: InputMaybe<Dms_Insureds_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dms_Insureds_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dms_Insureds_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dms_Insureds_Sum_Order_By>;
  var_pop?: InputMaybe<Dms_Insureds_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dms_Insureds_Var_Samp_Order_By>;
  variance?: InputMaybe<Dms_Insureds_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dms_Insureds_Append_Input = {
  product_info?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "insureds" */
export type Dms_Insureds_Arr_Rel_Insert_Input = {
  data: Array<Dms_Insureds_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};

/** aggregate avg on columns */
export type Dms_Insureds_Avg_Fields = {
  __typename?: 'dms_insureds_avg_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "insureds" */
export type Dms_Insureds_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insureds". All fields are combined with a logical 'AND'. */
export type Dms_Insureds_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Insureds_Bool_Exp>>;
  _not?: InputMaybe<Dms_Insureds_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Insureds_Bool_Exp>>;
  birth_place?: InputMaybe<String_Comparison_Exp>;
  bithday?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_release?: InputMaybe<Date_Comparison_Exp>;
  division_code?: InputMaybe<String_Comparison_Exp>;
  division_issuing?: InputMaybe<String_Comparison_Exp>;
  dms_file_path?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  foreign?: InputMaybe<Boolean_Comparison_Exp>;
  foreign_passport?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<Boolean_Comparison_Exp>;
  group_id?: InputMaybe<Bigint_Comparison_Exp>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
  group_insureds_aggregate?: InputMaybe<Dms_Group_Insureds_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insurant?: InputMaybe<Dms_Insurants_Bool_Exp>;
  insurant_id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  oms?: InputMaybe<String_Comparison_Exp>;
  order_uid?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  product_info?: InputMaybe<Jsonb_Comparison_Exp>;
  registration_address?: InputMaybe<String_Comparison_Exp>;
  residence?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "insureds" */
export enum Dms_Insureds_Constraint {
  /** unique or primary key constraint on columns "id" */
  InsuredsPkey = 'insureds_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dms_Insureds_Delete_At_Path_Input = {
  product_info?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dms_Insureds_Delete_Elem_Input = {
  product_info?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dms_Insureds_Delete_Key_Input = {
  product_info?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "insureds" */
export type Dms_Insureds_Inc_Input = {
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "insureds" */
export type Dms_Insureds_Insert_Input = {
  birth_place?: InputMaybe<Scalars['String']['input']>;
  bithday?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_release?: InputMaybe<Scalars['date']['input']>;
  division_code?: InputMaybe<Scalars['String']['input']>;
  division_issuing?: InputMaybe<Scalars['String']['input']>;
  dms_file_path?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign?: InputMaybe<Scalars['Boolean']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Boolean']['input']>;
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  group_insureds?: InputMaybe<Dms_Group_Insureds_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant?: InputMaybe<Dms_Insurants_Obj_Rel_Insert_Input>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  oms?: InputMaybe<Scalars['String']['input']>;
  order_uid?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  product_info?: InputMaybe<Scalars['jsonb']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  second_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Dms_Insureds_Max_Fields = {
  __typename?: 'dms_insureds_max_fields';
  birth_place?: Maybe<Scalars['String']['output']>;
  bithday?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_release?: Maybe<Scalars['date']['output']>;
  division_code?: Maybe<Scalars['String']['output']>;
  division_issuing?: Maybe<Scalars['String']['output']>;
  dms_file_path?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  group_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  oms?: Maybe<Scalars['String']['output']>;
  order_uid?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  second_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "insureds" */
export type Dms_Insureds_Max_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  dms_file_path?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  oms?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dms_Insureds_Min_Fields = {
  __typename?: 'dms_insureds_min_fields';
  birth_place?: Maybe<Scalars['String']['output']>;
  bithday?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_release?: Maybe<Scalars['date']['output']>;
  division_code?: Maybe<Scalars['String']['output']>;
  division_issuing?: Maybe<Scalars['String']['output']>;
  dms_file_path?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  foreign_passport?: Maybe<Scalars['String']['output']>;
  group_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  oms?: Maybe<Scalars['String']['output']>;
  order_uid?: Maybe<Scalars['String']['output']>;
  passport?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  second_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "insureds" */
export type Dms_Insureds_Min_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  dms_file_path?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  oms?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "insureds" */
export type Dms_Insureds_Mutation_Response = {
  __typename?: 'dms_insureds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Insureds>;
};

/** input type for inserting object relation for remote table "insureds" */
export type Dms_Insureds_Obj_Rel_Insert_Input = {
  data: Dms_Insureds_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};

/** on_conflict condition type for table "insureds" */
export type Dms_Insureds_On_Conflict = {
  constraint: Dms_Insureds_Constraint;
  update_columns?: Array<Dms_Insureds_Update_Column>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};

/** Ordering options when selecting data from "insureds". */
export type Dms_Insureds_Order_By = {
  birth_place?: InputMaybe<Order_By>;
  bithday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_release?: InputMaybe<Order_By>;
  division_code?: InputMaybe<Order_By>;
  division_issuing?: InputMaybe<Order_By>;
  dms_file_path?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  foreign?: InputMaybe<Order_By>;
  foreign_passport?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  group_insureds_aggregate?: InputMaybe<Dms_Group_Insureds_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  insurant?: InputMaybe<Dms_Insurants_Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  oms?: InputMaybe<Order_By>;
  order_uid?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  product_info?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  residence?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insureds */
export type Dms_Insureds_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dms_Insureds_Prepend_Input = {
  product_info?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "insureds" */
export enum Dms_Insureds_Select_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  DmsFilePath = 'dms_file_path',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Foreign = 'foreign',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Number = 'number',
  /** column name */
  Oms = 'oms',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProductInfo = 'product_info',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "dms_insureds_aggregate_bool_exp_bool_and_arguments_columns" columns of table "insureds" */
export enum Dms_Insureds_Select_Column_Dms_Insureds_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Foreign = 'foreign',
  /** column name */
  Gender = 'gender'
}

/** select "dms_insureds_aggregate_bool_exp_bool_or_arguments_columns" columns of table "insureds" */
export enum Dms_Insureds_Select_Column_Dms_Insureds_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Foreign = 'foreign',
  /** column name */
  Gender = 'gender'
}

/** input type for updating data in table "insureds" */
export type Dms_Insureds_Set_Input = {
  birth_place?: InputMaybe<Scalars['String']['input']>;
  bithday?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_release?: InputMaybe<Scalars['date']['input']>;
  division_code?: InputMaybe<Scalars['String']['input']>;
  division_issuing?: InputMaybe<Scalars['String']['input']>;
  dms_file_path?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign?: InputMaybe<Scalars['Boolean']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Boolean']['input']>;
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  oms?: InputMaybe<Scalars['String']['input']>;
  order_uid?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  product_info?: InputMaybe<Scalars['jsonb']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  second_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Insureds_Stddev_Fields = {
  __typename?: 'dms_insureds_stddev_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "insureds" */
export type Dms_Insureds_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dms_Insureds_Stddev_Pop_Fields = {
  __typename?: 'dms_insureds_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "insureds" */
export type Dms_Insureds_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dms_Insureds_Stddev_Samp_Fields = {
  __typename?: 'dms_insureds_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "insureds" */
export type Dms_Insureds_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dms_insureds" */
export type Dms_Insureds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Insureds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Insureds_Stream_Cursor_Value_Input = {
  birth_place?: InputMaybe<Scalars['String']['input']>;
  bithday?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_release?: InputMaybe<Scalars['date']['input']>;
  division_code?: InputMaybe<Scalars['String']['input']>;
  division_issuing?: InputMaybe<Scalars['String']['input']>;
  dms_file_path?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  foreign?: InputMaybe<Scalars['Boolean']['input']>;
  foreign_passport?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Boolean']['input']>;
  group_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  oms?: InputMaybe<Scalars['String']['input']>;
  order_uid?: InputMaybe<Scalars['String']['input']>;
  passport?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  product_info?: InputMaybe<Scalars['jsonb']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  second_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Dms_Insureds_Sum_Fields = {
  __typename?: 'dms_insureds_sum_fields';
  group_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "insureds" */
export type Dms_Insureds_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "insureds" */
export enum Dms_Insureds_Update_Column {
  /** column name */
  BirthPlace = 'birth_place',
  /** column name */
  Bithday = 'bithday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateRelease = 'date_release',
  /** column name */
  DivisionCode = 'division_code',
  /** column name */
  DivisionIssuing = 'division_issuing',
  /** column name */
  DmsFilePath = 'dms_file_path',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Foreign = 'foreign',
  /** column name */
  ForeignPassport = 'foreign_passport',
  /** column name */
  Gender = 'gender',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Number = 'number',
  /** column name */
  Oms = 'oms',
  /** column name */
  OrderUid = 'order_uid',
  /** column name */
  Passport = 'passport',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProductInfo = 'product_info',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  Residence = 'residence',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Insureds_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dms_Insureds_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dms_Insureds_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dms_Insureds_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dms_Insureds_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Insureds_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dms_Insureds_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Insureds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Insureds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Insureds_Var_Pop_Fields = {
  __typename?: 'dms_insureds_var_pop_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "insureds" */
export type Dms_Insureds_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dms_Insureds_Var_Samp_Fields = {
  __typename?: 'dms_insureds_var_samp_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "insureds" */
export type Dms_Insureds_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dms_Insureds_Variance_Fields = {
  __typename?: 'dms_insureds_variance_fields';
  group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "insureds" */
export type Dms_Insureds_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "order_links" */
export type Dms_Order_Links = {
  __typename?: 'dms_order_links';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  link: Scalars['String']['output'];
  /** An object relationship */
  order?: Maybe<Dms_Orders>;
  order_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "order_links" */
export type Dms_Order_Links_Aggregate = {
  __typename?: 'dms_order_links_aggregate';
  aggregate?: Maybe<Dms_Order_Links_Aggregate_Fields>;
  nodes: Array<Dms_Order_Links>;
};

/** aggregate fields of "order_links" */
export type Dms_Order_Links_Aggregate_Fields = {
  __typename?: 'dms_order_links_aggregate_fields';
  avg?: Maybe<Dms_Order_Links_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Order_Links_Max_Fields>;
  min?: Maybe<Dms_Order_Links_Min_Fields>;
  stddev?: Maybe<Dms_Order_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Order_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Order_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Order_Links_Sum_Fields>;
  var_pop?: Maybe<Dms_Order_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Order_Links_Var_Samp_Fields>;
  variance?: Maybe<Dms_Order_Links_Variance_Fields>;
};


/** aggregate fields of "order_links" */
export type Dms_Order_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Order_Links_Avg_Fields = {
  __typename?: 'dms_order_links_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "order_links". All fields are combined with a logical 'AND'. */
export type Dms_Order_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Order_Links_Bool_Exp>>;
  _not?: InputMaybe<Dms_Order_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Order_Links_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Dms_Orders_Bool_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_links" */
export enum Dms_Order_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderLinksPkey = 'order_links_pkey'
}

/** input type for incrementing numeric columns in table "order_links" */
export type Dms_Order_Links_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "order_links" */
export type Dms_Order_Links_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Dms_Orders_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Dms_Order_Links_Max_Fields = {
  __typename?: 'dms_order_links_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Dms_Order_Links_Min_Fields = {
  __typename?: 'dms_order_links_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "order_links" */
export type Dms_Order_Links_Mutation_Response = {
  __typename?: 'dms_order_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Order_Links>;
};

/** on_conflict condition type for table "order_links" */
export type Dms_Order_Links_On_Conflict = {
  constraint: Dms_Order_Links_Constraint;
  update_columns?: Array<Dms_Order_Links_Update_Column>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "order_links". */
export type Dms_Order_Links_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  order?: InputMaybe<Dms_Orders_Order_By>;
  order_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_links */
export type Dms_Order_Links_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "order_links" */
export enum Dms_Order_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_links" */
export type Dms_Order_Links_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Order_Links_Stddev_Fields = {
  __typename?: 'dms_order_links_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Order_Links_Stddev_Pop_Fields = {
  __typename?: 'dms_order_links_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Order_Links_Stddev_Samp_Fields = {
  __typename?: 'dms_order_links_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_order_links" */
export type Dms_Order_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Order_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Order_Links_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Dms_Order_Links_Sum_Fields = {
  __typename?: 'dms_order_links_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "order_links" */
export enum Dms_Order_Links_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Status = 'status',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Order_Links_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Order_Links_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Order_Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Order_Links_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Order_Links_Var_Pop_Fields = {
  __typename?: 'dms_order_links_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Order_Links_Var_Samp_Fields = {
  __typename?: 'dms_order_links_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Order_Links_Variance_Fields = {
  __typename?: 'dms_order_links_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "order_numbers" */
export type Dms_Order_Numbers = {
  __typename?: 'dms_order_numbers';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  order_id: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "order_numbers" */
export type Dms_Order_Numbers_Aggregate = {
  __typename?: 'dms_order_numbers_aggregate';
  aggregate?: Maybe<Dms_Order_Numbers_Aggregate_Fields>;
  nodes: Array<Dms_Order_Numbers>;
};

/** aggregate fields of "order_numbers" */
export type Dms_Order_Numbers_Aggregate_Fields = {
  __typename?: 'dms_order_numbers_aggregate_fields';
  avg?: Maybe<Dms_Order_Numbers_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Order_Numbers_Max_Fields>;
  min?: Maybe<Dms_Order_Numbers_Min_Fields>;
  stddev?: Maybe<Dms_Order_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Order_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Order_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Order_Numbers_Sum_Fields>;
  var_pop?: Maybe<Dms_Order_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Order_Numbers_Var_Samp_Fields>;
  variance?: Maybe<Dms_Order_Numbers_Variance_Fields>;
};


/** aggregate fields of "order_numbers" */
export type Dms_Order_Numbers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Order_Numbers_Avg_Fields = {
  __typename?: 'dms_order_numbers_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "order_numbers". All fields are combined with a logical 'AND'. */
export type Dms_Order_Numbers_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Order_Numbers_Bool_Exp>>;
  _not?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Order_Numbers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_numbers" */
export enum Dms_Order_Numbers_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderNumbersPkey = 'order_numbers_pkey'
}

/** input type for incrementing numeric columns in table "order_numbers" */
export type Dms_Order_Numbers_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "order_numbers" */
export type Dms_Order_Numbers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Dms_Order_Numbers_Max_Fields = {
  __typename?: 'dms_order_numbers_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Dms_Order_Numbers_Min_Fields = {
  __typename?: 'dms_order_numbers_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "order_numbers" */
export type Dms_Order_Numbers_Mutation_Response = {
  __typename?: 'dms_order_numbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Order_Numbers>;
};

/** on_conflict condition type for table "order_numbers" */
export type Dms_Order_Numbers_On_Conflict = {
  constraint: Dms_Order_Numbers_Constraint;
  update_columns?: Array<Dms_Order_Numbers_Update_Column>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};

/** Ordering options when selecting data from "order_numbers". */
export type Dms_Order_Numbers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_numbers */
export type Dms_Order_Numbers_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "order_numbers" */
export enum Dms_Order_Numbers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_numbers" */
export type Dms_Order_Numbers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Order_Numbers_Stddev_Fields = {
  __typename?: 'dms_order_numbers_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Order_Numbers_Stddev_Pop_Fields = {
  __typename?: 'dms_order_numbers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Order_Numbers_Stddev_Samp_Fields = {
  __typename?: 'dms_order_numbers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_order_numbers" */
export type Dms_Order_Numbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Order_Numbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Order_Numbers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Dms_Order_Numbers_Sum_Fields = {
  __typename?: 'dms_order_numbers_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "order_numbers" */
export enum Dms_Order_Numbers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Order_Numbers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Order_Numbers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Order_Numbers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Order_Numbers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Order_Numbers_Var_Pop_Fields = {
  __typename?: 'dms_order_numbers_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Order_Numbers_Var_Samp_Fields = {
  __typename?: 'dms_order_numbers_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Order_Numbers_Variance_Fields = {
  __typename?: 'dms_order_numbers_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "orders" */
export type Dms_Orders = {
  __typename?: 'dms_orders';
  created_at: Scalars['timestamp']['output'];
  discount?: Maybe<Scalars['Int']['output']>;
  form_guid?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  insurant_json?: Maybe<Scalars['jsonb']['output']>;
  insured_json?: Maybe<Scalars['jsonb']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  offer_guid?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  order_number_id?: Maybe<Scalars['bigint']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['bigint']['output']>;
  promo_code_str?: Maybe<Scalars['String']['output']>;
  start_date: Scalars['date']['output'];
  /** An object relationship */
  status?: Maybe<Dms_Statuses>;
  status_id?: Maybe<Scalars['bigint']['output']>;
  uid: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "orders" */
export type Dms_OrdersInsurant_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "orders" */
export type Dms_OrdersInsured_JsonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "orders" */
export type Dms_Orders_Aggregate = {
  __typename?: 'dms_orders_aggregate';
  aggregate?: Maybe<Dms_Orders_Aggregate_Fields>;
  nodes: Array<Dms_Orders>;
};

/** aggregate fields of "orders" */
export type Dms_Orders_Aggregate_Fields = {
  __typename?: 'dms_orders_aggregate_fields';
  avg?: Maybe<Dms_Orders_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Orders_Max_Fields>;
  min?: Maybe<Dms_Orders_Min_Fields>;
  stddev?: Maybe<Dms_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Orders_Sum_Fields>;
  var_pop?: Maybe<Dms_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Orders_Var_Samp_Fields>;
  variance?: Maybe<Dms_Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Dms_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dms_Orders_Append_Input = {
  insurant_json?: InputMaybe<Scalars['jsonb']['input']>;
  insured_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Dms_Orders_Avg_Fields = {
  __typename?: 'dms_orders_avg_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  order_number_id?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Dms_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Orders_Bool_Exp>>;
  _not?: InputMaybe<Dms_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Orders_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  form_guid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  insurant_id?: InputMaybe<Bigint_Comparison_Exp>;
  insurant_json?: InputMaybe<Jsonb_Comparison_Exp>;
  insured_json?: InputMaybe<Jsonb_Comparison_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  offer_guid?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  order_number_id?: InputMaybe<Bigint_Comparison_Exp>;
  payment_method?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  promo_code_str?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<Dms_Statuses_Bool_Exp>;
  status_id?: InputMaybe<Bigint_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Dms_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dms_Orders_Delete_At_Path_Input = {
  insurant_json?: InputMaybe<Array<Scalars['String']['input']>>;
  insured_json?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dms_Orders_Delete_Elem_Input = {
  insurant_json?: InputMaybe<Scalars['Int']['input']>;
  insured_json?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dms_Orders_Delete_Key_Input = {
  insurant_json?: InputMaybe<Scalars['String']['input']>;
  insured_json?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "orders" */
export type Dms_Orders_Inc_Input = {
  discount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  order_number_id?: InputMaybe<Scalars['bigint']['input']>;
  product_id?: InputMaybe<Scalars['bigint']['input']>;
  status_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "orders" */
export type Dms_Orders_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  form_guid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_json?: InputMaybe<Scalars['jsonb']['input']>;
  insured_json?: InputMaybe<Scalars['jsonb']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  order_number_id?: InputMaybe<Scalars['bigint']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['bigint']['input']>;
  promo_code_str?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<Dms_Statuses_Obj_Rel_Insert_Input>;
  status_id?: InputMaybe<Scalars['bigint']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Dms_Orders_Max_Fields = {
  __typename?: 'dms_orders_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  form_guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  offer_guid?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  order_number_id?: Maybe<Scalars['bigint']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['bigint']['output']>;
  promo_code_str?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  status_id?: Maybe<Scalars['bigint']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Dms_Orders_Min_Fields = {
  __typename?: 'dms_orders_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  form_guid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  offer_guid?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  order_number_id?: Maybe<Scalars['bigint']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['bigint']['output']>;
  promo_code_str?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  status_id?: Maybe<Scalars['bigint']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "orders" */
export type Dms_Orders_Mutation_Response = {
  __typename?: 'dms_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Dms_Orders_Obj_Rel_Insert_Input = {
  data: Dms_Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Dms_Orders_On_Conflict = {
  constraint: Dms_Orders_Constraint;
  update_columns?: Array<Dms_Orders_Update_Column>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Dms_Orders_Order_By = {
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  form_guid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurant_id?: InputMaybe<Order_By>;
  insurant_json?: InputMaybe<Order_By>;
  insured_json?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  offer_guid?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_number_id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  promo_code_str?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Dms_Statuses_Order_By>;
  status_id?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Dms_Orders_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dms_Orders_Prepend_Input = {
  insurant_json?: InputMaybe<Scalars['jsonb']['input']>;
  insured_json?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "orders" */
export enum Dms_Orders_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  FormGuid = 'form_guid',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  InsurantJson = 'insurant_json',
  /** column name */
  InsuredJson = 'insured_json',
  /** column name */
  Number = 'number',
  /** column name */
  OfferGuid = 'offer_guid',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderNumberId = 'order_number_id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PromoCodeStr = 'promo_code_str',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "orders" */
export type Dms_Orders_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  form_guid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_json?: InputMaybe<Scalars['jsonb']['input']>;
  insured_json?: InputMaybe<Scalars['jsonb']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  order_number_id?: InputMaybe<Scalars['bigint']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['bigint']['input']>;
  promo_code_str?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  status_id?: InputMaybe<Scalars['bigint']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Orders_Stddev_Fields = {
  __typename?: 'dms_orders_stddev_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  order_number_id?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Orders_Stddev_Pop_Fields = {
  __typename?: 'dms_orders_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  order_number_id?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Orders_Stddev_Samp_Fields = {
  __typename?: 'dms_orders_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  order_number_id?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_orders" */
export type Dms_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Orders_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  form_guid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_id?: InputMaybe<Scalars['bigint']['input']>;
  insurant_json?: InputMaybe<Scalars['jsonb']['input']>;
  insured_json?: InputMaybe<Scalars['jsonb']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  offer_guid?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  order_number_id?: InputMaybe<Scalars['bigint']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['bigint']['input']>;
  promo_code_str?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  status_id?: InputMaybe<Scalars['bigint']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Dms_Orders_Sum_Fields = {
  __typename?: 'dms_orders_sum_fields';
  discount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  insurant_id?: Maybe<Scalars['bigint']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  order_number_id?: Maybe<Scalars['bigint']['output']>;
  product_id?: Maybe<Scalars['bigint']['output']>;
  status_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "orders" */
export enum Dms_Orders_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  FormGuid = 'form_guid',
  /** column name */
  Id = 'id',
  /** column name */
  InsurantId = 'insurant_id',
  /** column name */
  InsurantJson = 'insurant_json',
  /** column name */
  InsuredJson = 'insured_json',
  /** column name */
  Number = 'number',
  /** column name */
  OfferGuid = 'offer_guid',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderNumberId = 'order_number_id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PromoCodeStr = 'promo_code_str',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Dms_Orders_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dms_Orders_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dms_Orders_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dms_Orders_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dms_Orders_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Orders_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dms_Orders_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Orders_Var_Pop_Fields = {
  __typename?: 'dms_orders_var_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  order_number_id?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Orders_Var_Samp_Fields = {
  __typename?: 'dms_orders_var_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  order_number_id?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Orders_Variance_Fields = {
  __typename?: 'dms_orders_variance_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  insurant_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  order_number_id?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "products" */
export type Dms_Products = {
  __typename?: 'dms_products';
  /** An object relationship */
  city?: Maybe<Dms_Cities>;
  city_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamp']['output'];
  file_program_path?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  medical_sum: Scalars['jsonb']['output'];
  name: Scalars['String']['output'];
  price: Scalars['jsonb']['output'];
  program: Scalars['jsonb']['output'];
  uid: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "products" */
export type Dms_ProductsMedical_SumArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "products" */
export type Dms_ProductsPriceArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "products" */
export type Dms_ProductsProgramArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "products" */
export type Dms_Products_Aggregate = {
  __typename?: 'dms_products_aggregate';
  aggregate?: Maybe<Dms_Products_Aggregate_Fields>;
  nodes: Array<Dms_Products>;
};

export type Dms_Products_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dms_Products_Aggregate_Bool_Exp_Count>;
};

export type Dms_Products_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dms_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Dms_Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "products" */
export type Dms_Products_Aggregate_Fields = {
  __typename?: 'dms_products_aggregate_fields';
  avg?: Maybe<Dms_Products_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Products_Max_Fields>;
  min?: Maybe<Dms_Products_Min_Fields>;
  stddev?: Maybe<Dms_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Products_Sum_Fields>;
  var_pop?: Maybe<Dms_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Products_Var_Samp_Fields>;
  variance?: Maybe<Dms_Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Dms_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "products" */
export type Dms_Products_Aggregate_Order_By = {
  avg?: InputMaybe<Dms_Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dms_Products_Max_Order_By>;
  min?: InputMaybe<Dms_Products_Min_Order_By>;
  stddev?: InputMaybe<Dms_Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dms_Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dms_Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dms_Products_Sum_Order_By>;
  var_pop?: InputMaybe<Dms_Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dms_Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Dms_Products_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dms_Products_Append_Input = {
  medical_sum?: InputMaybe<Scalars['jsonb']['input']>;
  price?: InputMaybe<Scalars['jsonb']['input']>;
  program?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "products" */
export type Dms_Products_Arr_Rel_Insert_Input = {
  data: Array<Dms_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Dms_Products_Avg_Fields = {
  __typename?: 'dms_products_avg_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "products" */
export type Dms_Products_Avg_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Dms_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Products_Bool_Exp>>;
  _not?: InputMaybe<Dms_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Products_Bool_Exp>>;
  city?: InputMaybe<Dms_Cities_Bool_Exp>;
  city_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  file_program_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  medical_sum?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Jsonb_Comparison_Exp>;
  program?: InputMaybe<Jsonb_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Dms_Products_Constraint {
  /** unique or primary key constraint on columns "uid" */
  IndexProductsOnUid = 'index_products_on_uid',
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dms_Products_Delete_At_Path_Input = {
  medical_sum?: InputMaybe<Array<Scalars['String']['input']>>;
  price?: InputMaybe<Array<Scalars['String']['input']>>;
  program?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dms_Products_Delete_Elem_Input = {
  medical_sum?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  program?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dms_Products_Delete_Key_Input = {
  medical_sum?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  program?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "products" */
export type Dms_Products_Inc_Input = {
  city_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "products" */
export type Dms_Products_Insert_Input = {
  city?: InputMaybe<Dms_Cities_Obj_Rel_Insert_Input>;
  city_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  file_program_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_sum?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['jsonb']['input']>;
  program?: InputMaybe<Scalars['jsonb']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Dms_Products_Max_Fields = {
  __typename?: 'dms_products_max_fields';
  city_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  file_program_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "products" */
export type Dms_Products_Max_Order_By = {
  city_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_program_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dms_Products_Min_Fields = {
  __typename?: 'dms_products_min_fields';
  city_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  file_program_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "products" */
export type Dms_Products_Min_Order_By = {
  city_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_program_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "products" */
export type Dms_Products_Mutation_Response = {
  __typename?: 'dms_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Products>;
};

/** on_conflict condition type for table "products" */
export type Dms_Products_On_Conflict = {
  constraint: Dms_Products_Constraint;
  update_columns?: Array<Dms_Products_Update_Column>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Dms_Products_Order_By = {
  city?: InputMaybe<Dms_Cities_Order_By>;
  city_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_program_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_sum?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: products */
export type Dms_Products_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dms_Products_Prepend_Input = {
  medical_sum?: InputMaybe<Scalars['jsonb']['input']>;
  price?: InputMaybe<Scalars['jsonb']['input']>;
  program?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "products" */
export enum Dms_Products_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileProgramPath = 'file_program_path',
  /** column name */
  Id = 'id',
  /** column name */
  MedicalSum = 'medical_sum',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Program = 'program',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "products" */
export type Dms_Products_Set_Input = {
  city_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  file_program_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_sum?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['jsonb']['input']>;
  program?: InputMaybe<Scalars['jsonb']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Products_Stddev_Fields = {
  __typename?: 'dms_products_stddev_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "products" */
export type Dms_Products_Stddev_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dms_Products_Stddev_Pop_Fields = {
  __typename?: 'dms_products_stddev_pop_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "products" */
export type Dms_Products_Stddev_Pop_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dms_Products_Stddev_Samp_Fields = {
  __typename?: 'dms_products_stddev_samp_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "products" */
export type Dms_Products_Stddev_Samp_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dms_products" */
export type Dms_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Products_Stream_Cursor_Value_Input = {
  city_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  file_program_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_sum?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['jsonb']['input']>;
  program?: InputMaybe<Scalars['jsonb']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Dms_Products_Sum_Fields = {
  __typename?: 'dms_products_sum_fields';
  city_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "products" */
export type Dms_Products_Sum_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "products" */
export enum Dms_Products_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileProgramPath = 'file_program_path',
  /** column name */
  Id = 'id',
  /** column name */
  MedicalSum = 'medical_sum',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Program = 'program',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dms_Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dms_Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dms_Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dms_Products_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Products_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dms_Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Products_Var_Pop_Fields = {
  __typename?: 'dms_products_var_pop_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "products" */
export type Dms_Products_Var_Pop_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dms_Products_Var_Samp_Fields = {
  __typename?: 'dms_products_var_samp_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "products" */
export type Dms_Products_Var_Samp_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dms_Products_Variance_Fields = {
  __typename?: 'dms_products_variance_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "products" */
export type Dms_Products_Variance_Order_By = {
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "promo_codes" */
export type Dms_Promo_Codes = {
  __typename?: 'dms_promo_codes';
  code: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  discount: Scalars['Int']['output'];
  expiration: Scalars['date']['output'];
  id: Scalars['bigint']['output'];
  type_code: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  use?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "promo_codes" */
export type Dms_Promo_Codes_Aggregate = {
  __typename?: 'dms_promo_codes_aggregate';
  aggregate?: Maybe<Dms_Promo_Codes_Aggregate_Fields>;
  nodes: Array<Dms_Promo_Codes>;
};

/** aggregate fields of "promo_codes" */
export type Dms_Promo_Codes_Aggregate_Fields = {
  __typename?: 'dms_promo_codes_aggregate_fields';
  avg?: Maybe<Dms_Promo_Codes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Promo_Codes_Max_Fields>;
  min?: Maybe<Dms_Promo_Codes_Min_Fields>;
  stddev?: Maybe<Dms_Promo_Codes_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Promo_Codes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Promo_Codes_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Promo_Codes_Sum_Fields>;
  var_pop?: Maybe<Dms_Promo_Codes_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Promo_Codes_Var_Samp_Fields>;
  variance?: Maybe<Dms_Promo_Codes_Variance_Fields>;
};


/** aggregate fields of "promo_codes" */
export type Dms_Promo_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Promo_Codes_Avg_Fields = {
  __typename?: 'dms_promo_codes_avg_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "promo_codes". All fields are combined with a logical 'AND'. */
export type Dms_Promo_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Promo_Codes_Bool_Exp>>;
  _not?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Promo_Codes_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  expiration?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  use?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "promo_codes" */
export enum Dms_Promo_Codes_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromoCodesPkey = 'promo_codes_pkey'
}

/** input type for incrementing numeric columns in table "promo_codes" */
export type Dms_Promo_Codes_Inc_Input = {
  discount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "promo_codes" */
export type Dms_Promo_Codes_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  expiration?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  use?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Dms_Promo_Codes_Max_Fields = {
  __typename?: 'dms_promo_codes_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  expiration?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  type_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Dms_Promo_Codes_Min_Fields = {
  __typename?: 'dms_promo_codes_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  expiration?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  type_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "promo_codes" */
export type Dms_Promo_Codes_Mutation_Response = {
  __typename?: 'dms_promo_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Promo_Codes>;
};

/** on_conflict condition type for table "promo_codes" */
export type Dms_Promo_Codes_On_Conflict = {
  constraint: Dms_Promo_Codes_Constraint;
  update_columns?: Array<Dms_Promo_Codes_Update_Column>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "promo_codes". */
export type Dms_Promo_Codes_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  use?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promo_codes */
export type Dms_Promo_Codes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "promo_codes" */
export enum Dms_Promo_Codes_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  TypeCode = 'type_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Use = 'use'
}

/** input type for updating data in table "promo_codes" */
export type Dms_Promo_Codes_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  expiration?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  use?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Promo_Codes_Stddev_Fields = {
  __typename?: 'dms_promo_codes_stddev_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Promo_Codes_Stddev_Pop_Fields = {
  __typename?: 'dms_promo_codes_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Promo_Codes_Stddev_Samp_Fields = {
  __typename?: 'dms_promo_codes_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_promo_codes" */
export type Dms_Promo_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Promo_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Promo_Codes_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  expiration?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  use?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Dms_Promo_Codes_Sum_Fields = {
  __typename?: 'dms_promo_codes_sum_fields';
  discount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "promo_codes" */
export enum Dms_Promo_Codes_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  TypeCode = 'type_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Use = 'use'
}

export type Dms_Promo_Codes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Promo_Codes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Promo_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Promo_Codes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Promo_Codes_Var_Pop_Fields = {
  __typename?: 'dms_promo_codes_var_pop_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Promo_Codes_Var_Samp_Fields = {
  __typename?: 'dms_promo_codes_var_samp_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Promo_Codes_Variance_Fields = {
  __typename?: 'dms_promo_codes_variance_fields';
  discount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "statuses" */
export type Dms_Statuses = {
  __typename?: 'dms_statuses';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "statuses" */
export type Dms_Statuses_Aggregate = {
  __typename?: 'dms_statuses_aggregate';
  aggregate?: Maybe<Dms_Statuses_Aggregate_Fields>;
  nodes: Array<Dms_Statuses>;
};

/** aggregate fields of "statuses" */
export type Dms_Statuses_Aggregate_Fields = {
  __typename?: 'dms_statuses_aggregate_fields';
  avg?: Maybe<Dms_Statuses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Statuses_Max_Fields>;
  min?: Maybe<Dms_Statuses_Min_Fields>;
  stddev?: Maybe<Dms_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Statuses_Sum_Fields>;
  var_pop?: Maybe<Dms_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Dms_Statuses_Variance_Fields>;
};


/** aggregate fields of "statuses" */
export type Dms_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Statuses_Avg_Fields = {
  __typename?: 'dms_statuses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "statuses". All fields are combined with a logical 'AND'. */
export type Dms_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Dms_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "statuses" */
export enum Dms_Statuses_Constraint {
  /** unique or primary key constraint on columns "title" */
  IndexStatusesOnTitle = 'index_statuses_on_title',
  /** unique or primary key constraint on columns "id" */
  StatusesPkey = 'statuses_pkey'
}

/** input type for incrementing numeric columns in table "statuses" */
export type Dms_Statuses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "statuses" */
export type Dms_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Dms_Statuses_Max_Fields = {
  __typename?: 'dms_statuses_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Dms_Statuses_Min_Fields = {
  __typename?: 'dms_statuses_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "statuses" */
export type Dms_Statuses_Mutation_Response = {
  __typename?: 'dms_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Statuses>;
};

/** input type for inserting object relation for remote table "statuses" */
export type Dms_Statuses_Obj_Rel_Insert_Input = {
  data: Dms_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dms_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "statuses" */
export type Dms_Statuses_On_Conflict = {
  constraint: Dms_Statuses_Constraint;
  update_columns?: Array<Dms_Statuses_Update_Column>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "statuses". */
export type Dms_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statuses */
export type Dms_Statuses_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "statuses" */
export enum Dms_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "statuses" */
export type Dms_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Statuses_Stddev_Fields = {
  __typename?: 'dms_statuses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Statuses_Stddev_Pop_Fields = {
  __typename?: 'dms_statuses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Statuses_Stddev_Samp_Fields = {
  __typename?: 'dms_statuses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_statuses" */
export type Dms_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Dms_Statuses_Sum_Fields = {
  __typename?: 'dms_statuses_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "statuses" */
export enum Dms_Statuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Dms_Statuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Statuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Statuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Statuses_Var_Pop_Fields = {
  __typename?: 'dms_statuses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Statuses_Var_Samp_Fields = {
  __typename?: 'dms_statuses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Statuses_Variance_Fields = {
  __typename?: 'dms_statuses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users" */
export type Dms_Users = {
  __typename?: 'dms_users';
  allow_password_change?: Maybe<Scalars['Boolean']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "users" */
export type Dms_Users_Aggregate = {
  __typename?: 'dms_users_aggregate';
  aggregate?: Maybe<Dms_Users_Aggregate_Fields>;
  nodes: Array<Dms_Users>;
};

/** aggregate fields of "users" */
export type Dms_Users_Aggregate_Fields = {
  __typename?: 'dms_users_aggregate_fields';
  avg?: Maybe<Dms_Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Dms_Users_Max_Fields>;
  min?: Maybe<Dms_Users_Min_Fields>;
  stddev?: Maybe<Dms_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Dms_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dms_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Dms_Users_Sum_Fields>;
  var_pop?: Maybe<Dms_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Dms_Users_Var_Samp_Fields>;
  variance?: Maybe<Dms_Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Dms_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dms_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Dms_Users_Avg_Fields = {
  __typename?: 'dms_users_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Dms_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Dms_Users_Bool_Exp>>;
  _not?: InputMaybe<Dms_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Dms_Users_Bool_Exp>>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Dms_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Dms_Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users" */
export type Dms_Users_Insert_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Dms_Users_Max_Fields = {
  __typename?: 'dms_users_max_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Dms_Users_Min_Fields = {
  __typename?: 'dms_users_min_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users" */
export type Dms_Users_Mutation_Response = {
  __typename?: 'dms_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dms_Users>;
};

/** on_conflict condition type for table "users" */
export type Dms_Users_On_Conflict = {
  constraint: Dms_Users_Constraint;
  update_columns?: Array<Dms_Users_Update_Column>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Dms_Users_Order_By = {
  allow_password_change?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Dms_Users_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users" */
export enum Dms_Users_Select_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Dms_Users_Set_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Dms_Users_Stddev_Fields = {
  __typename?: 'dms_users_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Dms_Users_Stddev_Pop_Fields = {
  __typename?: 'dms_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Dms_Users_Stddev_Samp_Fields = {
  __typename?: 'dms_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "dms_users" */
export type Dms_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dms_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dms_Users_Stream_Cursor_Value_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Dms_Users_Sum_Fields = {
  __typename?: 'dms_users_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users" */
export enum Dms_Users_Update_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Dms_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dms_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dms_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dms_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dms_Users_Var_Pop_Fields = {
  __typename?: 'dms_users_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Dms_Users_Var_Samp_Fields = {
  __typename?: 'dms_users_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Dms_Users_Variance_Fields = {
  __typename?: 'dms_users_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type FintechCancelOrderInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  order_id: Scalars['Int']['input'];
};

export type FintechCancelOrderJobInput = {
  order_id: Scalars['Int']['input'];
};

export type FintechCancelOrderOutput = {
  __typename?: 'fintechCancelOrderOutput';
  acc_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Int']['output'];
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<OrderClientInfo>;
  client_id: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  currency: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  email2?: Maybe<Scalars['String']['output']>;
  external_id: Scalars['Int']['output'];
  fee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  inprogress?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  life_period?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  post_code?: Maybe<Scalars['String']['output']>;
  ps?: Maybe<Scalars['String']['output']>;
  receipt_type?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  to_client?: Maybe<OrderClientInfo>;
  to_client_id: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type FintechCardInfoInput = {
  failurl?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FintechCardInfoOutput = {
  __typename?: 'fintechCardInfoOutput';
  card_url: FintechCardUrl;
};

export type FintechCardLimit = {
  __typename?: 'fintechCardLimit';
  created_at?: Maybe<Scalars['String']['output']>;
  current?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  limit_id?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  period_in_days?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type FintechCardUrl = {
  __typename?: 'fintechCardUrl';
  method?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type FintechCheckSmsClientInfoOutput = {
  __typename?: 'fintechCheckSmsClientInfoOutput';
  address?: Maybe<Scalars['String']['output']>;
  birth_date?: Maybe<Scalars['String']['output']>;
  card_limits?: Maybe<Array<Maybe<FintechCardLimit>>>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_checked?: Maybe<Scalars['Boolean']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
};

export type FintechClientInfoOutput = {
  __typename?: 'fintechClientInfoOutput';
  active?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  available_balance?: Maybe<Scalars['Int']['output']>;
  birth_date?: Maybe<Scalars['String']['output']>;
  card_limits?: Maybe<Array<Maybe<FintechCardLimit>>>;
  code?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  document?: Maybe<FintechDocument>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  max_balance_limit?: Maybe<FintechMaxBalanceLimit>;
  message?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_checked?: Maybe<Scalars['Boolean']['output']>;
  reg_date?: Maybe<Scalars['String']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type FintechClientInput = {
  get_cardinfo?: InputMaybe<Scalars['Int']['input']>;
  send_cardinfo?: InputMaybe<Scalars['Int']['input']>;
};

export type FintechClientModifInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  birth_date?: InputMaybe<Scalars['String']['input']>;
  document_attributes?: InputMaybe<FintechDocumentAttributes>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
};

export type FintechClientParamsInput = {
  phone: Scalars['String']['input'];
};

export type FintechClientSmsInput = {
  smscode: Scalars['String']['input'];
};

export type FintechGetOrders = {
  __typename?: 'fintechGetOrders';
  acc_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Int']['output'];
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<OrderClientInfo>;
  client_id: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  currency: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  email2?: Maybe<Scalars['String']['output']>;
  external_id: Scalars['Int']['output'];
  fee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  inprogress?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  life_period?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  post_code?: Maybe<Scalars['String']['output']>;
  ps?: Maybe<Scalars['String']['output']>;
  receipt_type?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  to_client?: Maybe<OrderClientInfo>;
  to_client_id: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type FintechGetOrdersOutput = {
  __typename?: 'fintechGetOrdersOutput';
  orders?: Maybe<Array<Maybe<FintechGetOrders>>>;
};

export type FintechMaxBalanceLimit = {
  __typename?: 'fintechMaxBalanceLimit';
  description?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FintechOperationInput = {
  amount: Scalars['Int']['input'];
  approval_code?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['Int']['input'];
  external_id: Scalars['Int']['input'];
  fee?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  operation_date?: InputMaybe<Scalars['String']['input']>;
  operation_type?: InputMaybe<Scalars['String']['input']>;
  reason_code?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type FintechOperationOutput = {
  __typename?: 'fintechOperationOutput';
  amount: Scalars['Int']['output'];
  approval_code?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  currency: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  from_client_ref?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  operation_date?: Maybe<Scalars['String']['output']>;
  operation_type: Scalars['Int']['output'];
  order_id?: Maybe<Scalars['Int']['output']>;
  pan?: Maybe<Scalars['String']['output']>;
  reason_code?: Maybe<Scalars['Int']['output']>;
  state: Scalars['Int']['output'];
  to_client_ref?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type FintechOrderOutput = {
  __typename?: 'fintechOrderOutput';
  acc_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Int']['output'];
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<OrderClientInfo>;
  client_id: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  currency: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  email2?: Maybe<Scalars['String']['output']>;
  external_id: Scalars['Int']['output'];
  fee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  inprogress?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  life_period?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  post_code?: Maybe<Scalars['String']['output']>;
  ps?: Maybe<Scalars['String']['output']>;
  receipt_type?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  to_client?: Maybe<OrderClientInfo>;
  to_client_id: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type FintechPayInDebitParamsInput = {
  amount: Scalars['Int']['input'];
  currency: Scalars['Int']['input'];
  description: Scalars['String']['input'];
};

export type FintechPaymentUrl = {
  __typename?: 'fintechPaymentUrl';
  method?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type FintechPaymentUrlOutput = {
  __typename?: 'fintechPaymentUrlOutput';
  operation_id: Scalars['Int']['output'];
  payment_url: FintechPaymentUrl;
};

export type FintechResendSmsOutput = {
  __typename?: 'fintechResendSmsOutput';
  resent: Scalars['Boolean']['output'];
  timeout?: Maybe<Scalars['Int']['output']>;
};

export type FintechTransferParamsInput = {
  amount: Scalars['Int']['input'];
  currency: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  receiver_id: Scalars['Int']['input'];
};

/** columns and relationships of "cards" */
export type Fintech_Cards = {
  __typename?: 'fintech_cards';
  available_balance?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  client: Fintech_Clients;
  client_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamp']['output'];
  currency?: Maybe<Scalars['Int']['output']>;
  current?: Maybe<Scalars['Int']['output']>;
  id: Scalars['bigint']['output'];
  limit_id?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  pan?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "cards" */
export type Fintech_Cards_Aggregate = {
  __typename?: 'fintech_cards_aggregate';
  aggregate?: Maybe<Fintech_Cards_Aggregate_Fields>;
  nodes: Array<Fintech_Cards>;
};

export type Fintech_Cards_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fintech_Cards_Aggregate_Bool_Exp_Count>;
};

export type Fintech_Cards_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Fintech_Cards_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cards" */
export type Fintech_Cards_Aggregate_Fields = {
  __typename?: 'fintech_cards_aggregate_fields';
  avg?: Maybe<Fintech_Cards_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Fintech_Cards_Max_Fields>;
  min?: Maybe<Fintech_Cards_Min_Fields>;
  stddev?: Maybe<Fintech_Cards_Stddev_Fields>;
  stddev_pop?: Maybe<Fintech_Cards_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fintech_Cards_Stddev_Samp_Fields>;
  sum?: Maybe<Fintech_Cards_Sum_Fields>;
  var_pop?: Maybe<Fintech_Cards_Var_Pop_Fields>;
  var_samp?: Maybe<Fintech_Cards_Var_Samp_Fields>;
  variance?: Maybe<Fintech_Cards_Variance_Fields>;
};


/** aggregate fields of "cards" */
export type Fintech_Cards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "cards" */
export type Fintech_Cards_Aggregate_Order_By = {
  avg?: InputMaybe<Fintech_Cards_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fintech_Cards_Max_Order_By>;
  min?: InputMaybe<Fintech_Cards_Min_Order_By>;
  stddev?: InputMaybe<Fintech_Cards_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Fintech_Cards_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Fintech_Cards_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Fintech_Cards_Sum_Order_By>;
  var_pop?: InputMaybe<Fintech_Cards_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Fintech_Cards_Var_Samp_Order_By>;
  variance?: InputMaybe<Fintech_Cards_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cards" */
export type Fintech_Cards_Arr_Rel_Insert_Input = {
  data: Array<Fintech_Cards_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Fintech_Cards_On_Conflict>;
};

/** aggregate avg on columns */
export type Fintech_Cards_Avg_Fields = {
  __typename?: 'fintech_cards_avg_fields';
  available_balance?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit_id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "cards" */
export type Fintech_Cards_Avg_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cards". All fields are combined with a logical 'AND'. */
export type Fintech_Cards_Bool_Exp = {
  _and?: InputMaybe<Array<Fintech_Cards_Bool_Exp>>;
  _not?: InputMaybe<Fintech_Cards_Bool_Exp>;
  _or?: InputMaybe<Array<Fintech_Cards_Bool_Exp>>;
  available_balance?: InputMaybe<Numeric_Comparison_Exp>;
  client?: InputMaybe<Fintech_Clients_Bool_Exp>;
  client_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  currency?: InputMaybe<Int_Comparison_Exp>;
  current?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  limit_id?: InputMaybe<Int_Comparison_Exp>;
  max?: InputMaybe<Int_Comparison_Exp>;
  pan?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "cards" */
export enum Fintech_Cards_Constraint {
  /** unique or primary key constraint on columns "id" */
  CardsPkey = 'cards_pkey'
}

/** input type for incrementing numeric columns in table "cards" */
export type Fintech_Cards_Inc_Input = {
  available_balance?: InputMaybe<Scalars['numeric']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  current?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  limit_id?: InputMaybe<Scalars['Int']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "cards" */
export type Fintech_Cards_Insert_Input = {
  available_balance?: InputMaybe<Scalars['numeric']['input']>;
  client?: InputMaybe<Fintech_Clients_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  current?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  limit_id?: InputMaybe<Scalars['Int']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  pan?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Fintech_Cards_Max_Fields = {
  __typename?: 'fintech_cards_max_fields';
  available_balance?: Maybe<Scalars['numeric']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  current?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  limit_id?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  pan?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "cards" */
export type Fintech_Cards_Max_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fintech_Cards_Min_Fields = {
  __typename?: 'fintech_cards_min_fields';
  available_balance?: Maybe<Scalars['numeric']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  current?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  limit_id?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  pan?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "cards" */
export type Fintech_Cards_Min_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cards" */
export type Fintech_Cards_Mutation_Response = {
  __typename?: 'fintech_cards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Fintech_Cards>;
};

/** on_conflict condition type for table "cards" */
export type Fintech_Cards_On_Conflict = {
  constraint: Fintech_Cards_Constraint;
  update_columns?: Array<Fintech_Cards_Update_Column>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};

/** Ordering options when selecting data from "cards". */
export type Fintech_Cards_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client?: InputMaybe<Fintech_Clients_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cards */
export type Fintech_Cards_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "cards" */
export enum Fintech_Cards_Select_Column {
  /** column name */
  AvailableBalance = 'available_balance',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Current = 'current',
  /** column name */
  Id = 'id',
  /** column name */
  LimitId = 'limit_id',
  /** column name */
  Max = 'max',
  /** column name */
  Pan = 'pan',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "cards" */
export type Fintech_Cards_Set_Input = {
  available_balance?: InputMaybe<Scalars['numeric']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  current?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  limit_id?: InputMaybe<Scalars['Int']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  pan?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Fintech_Cards_Stddev_Fields = {
  __typename?: 'fintech_cards_stddev_fields';
  available_balance?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit_id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "cards" */
export type Fintech_Cards_Stddev_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fintech_Cards_Stddev_Pop_Fields = {
  __typename?: 'fintech_cards_stddev_pop_fields';
  available_balance?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit_id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "cards" */
export type Fintech_Cards_Stddev_Pop_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fintech_Cards_Stddev_Samp_Fields = {
  __typename?: 'fintech_cards_stddev_samp_fields';
  available_balance?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit_id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "cards" */
export type Fintech_Cards_Stddev_Samp_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "fintech_cards" */
export type Fintech_Cards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fintech_Cards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fintech_Cards_Stream_Cursor_Value_Input = {
  available_balance?: InputMaybe<Scalars['numeric']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  current?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  limit_id?: InputMaybe<Scalars['Int']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  pan?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Fintech_Cards_Sum_Fields = {
  __typename?: 'fintech_cards_sum_fields';
  available_balance?: Maybe<Scalars['numeric']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  current?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  limit_id?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "cards" */
export type Fintech_Cards_Sum_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** update columns of table "cards" */
export enum Fintech_Cards_Update_Column {
  /** column name */
  AvailableBalance = 'available_balance',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Current = 'current',
  /** column name */
  Id = 'id',
  /** column name */
  LimitId = 'limit_id',
  /** column name */
  Max = 'max',
  /** column name */
  Pan = 'pan',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Fintech_Cards_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fintech_Cards_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fintech_Cards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fintech_Cards_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fintech_Cards_Var_Pop_Fields = {
  __typename?: 'fintech_cards_var_pop_fields';
  available_balance?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit_id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "cards" */
export type Fintech_Cards_Var_Pop_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fintech_Cards_Var_Samp_Fields = {
  __typename?: 'fintech_cards_var_samp_fields';
  available_balance?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit_id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "cards" */
export type Fintech_Cards_Var_Samp_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Fintech_Cards_Variance_Fields = {
  __typename?: 'fintech_cards_variance_fields';
  available_balance?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit_id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "cards" */
export type Fintech_Cards_Variance_Order_By = {
  available_balance?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** columns and relationships of "clients" */
export type Fintech_Clients = {
  __typename?: 'fintech_clients';
  active?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  available_balance?: Maybe<Scalars['Int']['output']>;
  birth_date?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  cards: Array<Fintech_Cards>;
  /** An aggregate relationship */
  cards_aggregate: Fintech_Cards_Aggregate;
  client_ref?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  currency?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  orders: Array<Fintech_Orders>;
  /** An aggregate relationship */
  orders_aggregate: Fintech_Orders_Aggregate;
  passphrase?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_checked?: Maybe<Scalars['Boolean']['output']>;
  reg_date?: Maybe<Scalars['timestamptz']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "clients" */
export type Fintech_ClientsCardsArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Cards_Order_By>>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type Fintech_ClientsCards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Cards_Order_By>>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type Fintech_ClientsOrdersArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Orders_Order_By>>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type Fintech_ClientsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Orders_Order_By>>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};

/** aggregated selection of "clients" */
export type Fintech_Clients_Aggregate = {
  __typename?: 'fintech_clients_aggregate';
  aggregate?: Maybe<Fintech_Clients_Aggregate_Fields>;
  nodes: Array<Fintech_Clients>;
};

/** aggregate fields of "clients" */
export type Fintech_Clients_Aggregate_Fields = {
  __typename?: 'fintech_clients_aggregate_fields';
  avg?: Maybe<Fintech_Clients_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Fintech_Clients_Max_Fields>;
  min?: Maybe<Fintech_Clients_Min_Fields>;
  stddev?: Maybe<Fintech_Clients_Stddev_Fields>;
  stddev_pop?: Maybe<Fintech_Clients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fintech_Clients_Stddev_Samp_Fields>;
  sum?: Maybe<Fintech_Clients_Sum_Fields>;
  var_pop?: Maybe<Fintech_Clients_Var_Pop_Fields>;
  var_samp?: Maybe<Fintech_Clients_Var_Samp_Fields>;
  variance?: Maybe<Fintech_Clients_Variance_Fields>;
};


/** aggregate fields of "clients" */
export type Fintech_Clients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fintech_Clients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Fintech_Clients_Avg_Fields = {
  __typename?: 'fintech_clients_avg_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  available_balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'. */
export type Fintech_Clients_Bool_Exp = {
  _and?: InputMaybe<Array<Fintech_Clients_Bool_Exp>>;
  _not?: InputMaybe<Fintech_Clients_Bool_Exp>;
  _or?: InputMaybe<Array<Fintech_Clients_Bool_Exp>>;
  active?: InputMaybe<Int_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  auth_state?: InputMaybe<Int_Comparison_Exp>;
  available_balance?: InputMaybe<Int_Comparison_Exp>;
  birth_date?: InputMaybe<Date_Comparison_Exp>;
  cards?: InputMaybe<Fintech_Cards_Bool_Exp>;
  cards_aggregate?: InputMaybe<Fintech_Cards_Aggregate_Bool_Exp>;
  client_ref?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inn?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  limit?: InputMaybe<Int_Comparison_Exp>;
  orders?: InputMaybe<Fintech_Orders_Bool_Exp>;
  orders_aggregate?: InputMaybe<Fintech_Orders_Aggregate_Bool_Exp>;
  passphrase?: InputMaybe<String_Comparison_Exp>;
  patronymic?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  phone_checked?: InputMaybe<Boolean_Comparison_Exp>;
  reg_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  snils?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "clients" */
export enum Fintech_Clients_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientsPkey = 'clients_pkey',
  /** unique or primary key constraint on columns "user_id" */
  IndexClientsOnUserId = 'index_clients_on_user_id'
}

/** input type for incrementing numeric columns in table "clients" */
export type Fintech_Clients_Inc_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  available_balance?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "clients" */
export type Fintech_Clients_Insert_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  available_balance?: InputMaybe<Scalars['Int']['input']>;
  birth_date?: InputMaybe<Scalars['date']['input']>;
  cards?: InputMaybe<Fintech_Cards_Arr_Rel_Insert_Input>;
  client_ref?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orders?: InputMaybe<Fintech_Orders_Arr_Rel_Insert_Input>;
  passphrase?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_checked?: InputMaybe<Scalars['Boolean']['input']>;
  reg_date?: InputMaybe<Scalars['timestamptz']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Fintech_Clients_Max_Fields = {
  __typename?: 'fintech_clients_max_fields';
  active?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  available_balance?: Maybe<Scalars['Int']['output']>;
  birth_date?: Maybe<Scalars['date']['output']>;
  client_ref?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  passphrase?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reg_date?: Maybe<Scalars['timestamptz']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Fintech_Clients_Min_Fields = {
  __typename?: 'fintech_clients_min_fields';
  active?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  available_balance?: Maybe<Scalars['Int']['output']>;
  birth_date?: Maybe<Scalars['date']['output']>;
  client_ref?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  passphrase?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reg_date?: Maybe<Scalars['timestamptz']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "clients" */
export type Fintech_Clients_Mutation_Response = {
  __typename?: 'fintech_clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Fintech_Clients>;
};

/** input type for inserting object relation for remote table "clients" */
export type Fintech_Clients_Obj_Rel_Insert_Input = {
  data: Fintech_Clients_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Fintech_Clients_On_Conflict>;
};

/** on_conflict condition type for table "clients" */
export type Fintech_Clients_On_Conflict = {
  constraint: Fintech_Clients_Constraint;
  update_columns?: Array<Fintech_Clients_Update_Column>;
  where?: InputMaybe<Fintech_Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "clients". */
export type Fintech_Clients_Order_By = {
  active?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  auth_state?: InputMaybe<Order_By>;
  available_balance?: InputMaybe<Order_By>;
  birth_date?: InputMaybe<Order_By>;
  cards_aggregate?: InputMaybe<Fintech_Cards_Aggregate_Order_By>;
  client_ref?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inn?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Fintech_Orders_Aggregate_Order_By>;
  passphrase?: InputMaybe<Order_By>;
  patronymic?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  phone_checked?: InputMaybe<Order_By>;
  reg_date?: InputMaybe<Order_By>;
  snils?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clients */
export type Fintech_Clients_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "clients" */
export enum Fintech_Clients_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  AuthState = 'auth_state',
  /** column name */
  AvailableBalance = 'available_balance',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  ClientRef = 'client_ref',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Inn = 'inn',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Limit = 'limit',
  /** column name */
  Passphrase = 'passphrase',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneChecked = 'phone_checked',
  /** column name */
  RegDate = 'reg_date',
  /** column name */
  Snils = 'snils',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "clients" */
export type Fintech_Clients_Set_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  available_balance?: InputMaybe<Scalars['Int']['input']>;
  birth_date?: InputMaybe<Scalars['date']['input']>;
  client_ref?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  passphrase?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_checked?: InputMaybe<Scalars['Boolean']['input']>;
  reg_date?: InputMaybe<Scalars['timestamptz']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Fintech_Clients_Stddev_Fields = {
  __typename?: 'fintech_clients_stddev_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  available_balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Fintech_Clients_Stddev_Pop_Fields = {
  __typename?: 'fintech_clients_stddev_pop_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  available_balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Fintech_Clients_Stddev_Samp_Fields = {
  __typename?: 'fintech_clients_stddev_samp_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  available_balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "fintech_clients" */
export type Fintech_Clients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fintech_Clients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fintech_Clients_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  available_balance?: InputMaybe<Scalars['Int']['input']>;
  birth_date?: InputMaybe<Scalars['date']['input']>;
  client_ref?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  passphrase?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_checked?: InputMaybe<Scalars['Boolean']['input']>;
  reg_date?: InputMaybe<Scalars['timestamptz']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Fintech_Clients_Sum_Fields = {
  __typename?: 'fintech_clients_sum_fields';
  active?: Maybe<Scalars['Int']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  available_balance?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "clients" */
export enum Fintech_Clients_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  AuthState = 'auth_state',
  /** column name */
  AvailableBalance = 'available_balance',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  ClientRef = 'client_ref',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Inn = 'inn',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Limit = 'limit',
  /** column name */
  Passphrase = 'passphrase',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneChecked = 'phone_checked',
  /** column name */
  RegDate = 'reg_date',
  /** column name */
  Snils = 'snils',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Fintech_Clients_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fintech_Clients_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fintech_Clients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fintech_Clients_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fintech_Clients_Var_Pop_Fields = {
  __typename?: 'fintech_clients_var_pop_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  available_balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Fintech_Clients_Var_Samp_Fields = {
  __typename?: 'fintech_clients_var_samp_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  available_balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Fintech_Clients_Variance_Fields = {
  __typename?: 'fintech_clients_variance_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  available_balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "orders" */
export type Fintech_Orders = {
  __typename?: 'fintech_orders';
  acc_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  client: Fintech_Clients;
  client_id: Scalars['bigint']['output'];
  client_ref?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  currency?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email2?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  external_id?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  from_client_ref?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  inprogress?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['Int']['output']>;
  life_period?: Maybe<Scalars['Int']['output']>;
  paid_at?: Maybe<Scalars['timestamp']['output']>;
  payer?: Maybe<Scalars['jsonb']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  post_code?: Maybe<Scalars['String']['output']>;
  ps?: Maybe<Scalars['Int']['output']>;
  receipt_type?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  to_client?: Maybe<Fintech_Clients>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
  to_client_ref?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "orders" */
export type Fintech_OrdersPayerArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "orders" */
export type Fintech_Orders_Aggregate = {
  __typename?: 'fintech_orders_aggregate';
  aggregate?: Maybe<Fintech_Orders_Aggregate_Fields>;
  nodes: Array<Fintech_Orders>;
};

export type Fintech_Orders_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fintech_Orders_Aggregate_Bool_Exp_Count>;
};

export type Fintech_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Fintech_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orders" */
export type Fintech_Orders_Aggregate_Fields = {
  __typename?: 'fintech_orders_aggregate_fields';
  avg?: Maybe<Fintech_Orders_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Fintech_Orders_Max_Fields>;
  min?: Maybe<Fintech_Orders_Min_Fields>;
  stddev?: Maybe<Fintech_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Fintech_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fintech_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Fintech_Orders_Sum_Fields>;
  var_pop?: Maybe<Fintech_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Fintech_Orders_Var_Samp_Fields>;
  variance?: Maybe<Fintech_Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Fintech_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "orders" */
export type Fintech_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Fintech_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fintech_Orders_Max_Order_By>;
  min?: InputMaybe<Fintech_Orders_Min_Order_By>;
  stddev?: InputMaybe<Fintech_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Fintech_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Fintech_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Fintech_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Fintech_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Fintech_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Fintech_Orders_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fintech_Orders_Append_Input = {
  payer?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "orders" */
export type Fintech_Orders_Arr_Rel_Insert_Input = {
  data: Array<Fintech_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Fintech_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Fintech_Orders_Avg_Fields = {
  __typename?: 'fintech_orders_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  external_id?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inprogress?: Maybe<Scalars['Float']['output']>;
  lang?: Maybe<Scalars['Float']['output']>;
  life_period?: Maybe<Scalars['Float']['output']>;
  ps?: Maybe<Scalars['Float']['output']>;
  receipt_type?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "orders" */
export type Fintech_Orders_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Fintech_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Fintech_Orders_Bool_Exp>>;
  _not?: InputMaybe<Fintech_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Fintech_Orders_Bool_Exp>>;
  acc_number?: InputMaybe<String_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  client?: InputMaybe<Fintech_Clients_Bool_Exp>;
  client_id?: InputMaybe<Bigint_Comparison_Exp>;
  client_ref?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  contract?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email2?: InputMaybe<String_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<Int_Comparison_Exp>;
  fee?: InputMaybe<Int_Comparison_Exp>;
  from_client_ref?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inprogress?: InputMaybe<Int_Comparison_Exp>;
  lang?: InputMaybe<Int_Comparison_Exp>;
  life_period?: InputMaybe<Int_Comparison_Exp>;
  paid_at?: InputMaybe<Timestamp_Comparison_Exp>;
  payer?: InputMaybe<Jsonb_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  phone2?: InputMaybe<String_Comparison_Exp>;
  post_code?: InputMaybe<String_Comparison_Exp>;
  ps?: InputMaybe<Int_Comparison_Exp>;
  receipt_type?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  to_client?: InputMaybe<Fintech_Clients_Bool_Exp>;
  to_client_id?: InputMaybe<Bigint_Comparison_Exp>;
  to_client_ref?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Fintech_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fintech_Orders_Delete_At_Path_Input = {
  payer?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fintech_Orders_Delete_Elem_Input = {
  payer?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fintech_Orders_Delete_Key_Input = {
  payer?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "orders" */
export type Fintech_Orders_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  external_id?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inprogress?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['Int']['input']>;
  life_period?: InputMaybe<Scalars['Int']['input']>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  receipt_type?: InputMaybe<Scalars['Int']['input']>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "orders" */
export type Fintech_Orders_Insert_Input = {
  acc_number?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Fintech_Clients_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  client_ref?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email2?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  external_id?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  from_client_ref?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inprogress?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['Int']['input']>;
  life_period?: InputMaybe<Scalars['Int']['input']>;
  paid_at?: InputMaybe<Scalars['timestamp']['input']>;
  payer?: InputMaybe<Scalars['jsonb']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone2?: InputMaybe<Scalars['String']['input']>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  receipt_type?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  to_client?: InputMaybe<Fintech_Clients_Obj_Rel_Insert_Input>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
  to_client_ref?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Fintech_Orders_Max_Fields = {
  __typename?: 'fintech_orders_max_fields';
  acc_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  client_ref?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email2?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  external_id?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  from_client_ref?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  inprogress?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['Int']['output']>;
  life_period?: Maybe<Scalars['Int']['output']>;
  paid_at?: Maybe<Scalars['timestamp']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  post_code?: Maybe<Scalars['String']['output']>;
  ps?: Maybe<Scalars['Int']['output']>;
  receipt_type?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
  to_client_ref?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "orders" */
export type Fintech_Orders_Max_Order_By = {
  acc_number?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  client_ref?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email2?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  from_client_ref?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  phone2?: InputMaybe<Order_By>;
  post_code?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_client_ref?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fintech_Orders_Min_Fields = {
  __typename?: 'fintech_orders_min_fields';
  acc_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  client_ref?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email2?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  external_id?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  from_client_ref?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  inprogress?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['Int']['output']>;
  life_period?: Maybe<Scalars['Int']['output']>;
  paid_at?: Maybe<Scalars['timestamp']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  post_code?: Maybe<Scalars['String']['output']>;
  ps?: Maybe<Scalars['Int']['output']>;
  receipt_type?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
  to_client_ref?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "orders" */
export type Fintech_Orders_Min_Order_By = {
  acc_number?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  client_ref?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email2?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  from_client_ref?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  phone2?: InputMaybe<Order_By>;
  post_code?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_client_ref?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Fintech_Orders_Mutation_Response = {
  __typename?: 'fintech_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Fintech_Orders>;
};

/** on_conflict condition type for table "orders" */
export type Fintech_Orders_On_Conflict = {
  constraint: Fintech_Orders_Constraint;
  update_columns?: Array<Fintech_Orders_Update_Column>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Fintech_Orders_Order_By = {
  acc_number?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  client?: InputMaybe<Fintech_Clients_Order_By>;
  client_id?: InputMaybe<Order_By>;
  client_ref?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  contract?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email2?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  from_client_ref?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  payer?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  phone2?: InputMaybe<Order_By>;
  post_code?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  to_client?: InputMaybe<Fintech_Clients_Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_client_ref?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Fintech_Orders_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fintech_Orders_Prepend_Input = {
  payer?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "orders" */
export enum Fintech_Orders_Select_Column {
  /** column name */
  AccNumber = 'acc_number',
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  City = 'city',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ClientRef = 'client_ref',
  /** column name */
  Comment = 'comment',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Email2 = 'email2',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fee = 'fee',
  /** column name */
  FromClientRef = 'from_client_ref',
  /** column name */
  Id = 'id',
  /** column name */
  Inprogress = 'inprogress',
  /** column name */
  Lang = 'lang',
  /** column name */
  LifePeriod = 'life_period',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  Payer = 'payer',
  /** column name */
  Phone = 'phone',
  /** column name */
  Phone2 = 'phone2',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  Ps = 'ps',
  /** column name */
  ReceiptType = 'receipt_type',
  /** column name */
  State = 'state',
  /** column name */
  ToClientId = 'to_client_id',
  /** column name */
  ToClientRef = 'to_client_ref',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "orders" */
export type Fintech_Orders_Set_Input = {
  acc_number?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  client_ref?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email2?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  external_id?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  from_client_ref?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inprogress?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['Int']['input']>;
  life_period?: InputMaybe<Scalars['Int']['input']>;
  paid_at?: InputMaybe<Scalars['timestamp']['input']>;
  payer?: InputMaybe<Scalars['jsonb']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone2?: InputMaybe<Scalars['String']['input']>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  receipt_type?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
  to_client_ref?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Fintech_Orders_Stddev_Fields = {
  __typename?: 'fintech_orders_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  external_id?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inprogress?: Maybe<Scalars['Float']['output']>;
  lang?: Maybe<Scalars['Float']['output']>;
  life_period?: Maybe<Scalars['Float']['output']>;
  ps?: Maybe<Scalars['Float']['output']>;
  receipt_type?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "orders" */
export type Fintech_Orders_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fintech_Orders_Stddev_Pop_Fields = {
  __typename?: 'fintech_orders_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  external_id?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inprogress?: Maybe<Scalars['Float']['output']>;
  lang?: Maybe<Scalars['Float']['output']>;
  life_period?: Maybe<Scalars['Float']['output']>;
  ps?: Maybe<Scalars['Float']['output']>;
  receipt_type?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type Fintech_Orders_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fintech_Orders_Stddev_Samp_Fields = {
  __typename?: 'fintech_orders_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  external_id?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inprogress?: Maybe<Scalars['Float']['output']>;
  lang?: Maybe<Scalars['Float']['output']>;
  life_period?: Maybe<Scalars['Float']['output']>;
  ps?: Maybe<Scalars['Float']['output']>;
  receipt_type?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type Fintech_Orders_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "fintech_orders" */
export type Fintech_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fintech_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fintech_Orders_Stream_Cursor_Value_Input = {
  acc_number?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  client_ref?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email2?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  external_id?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  from_client_ref?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inprogress?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['Int']['input']>;
  life_period?: InputMaybe<Scalars['Int']['input']>;
  paid_at?: InputMaybe<Scalars['timestamp']['input']>;
  payer?: InputMaybe<Scalars['jsonb']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone2?: InputMaybe<Scalars['String']['input']>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  receipt_type?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
  to_client_ref?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Fintech_Orders_Sum_Fields = {
  __typename?: 'fintech_orders_sum_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  external_id?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  inprogress?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['Int']['output']>;
  life_period?: Maybe<Scalars['Int']['output']>;
  ps?: Maybe<Scalars['Int']['output']>;
  receipt_type?: Maybe<Scalars['Int']['output']>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "orders" */
export type Fintech_Orders_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** update columns of table "orders" */
export enum Fintech_Orders_Update_Column {
  /** column name */
  AccNumber = 'acc_number',
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  City = 'city',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ClientRef = 'client_ref',
  /** column name */
  Comment = 'comment',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Email2 = 'email2',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fee = 'fee',
  /** column name */
  FromClientRef = 'from_client_ref',
  /** column name */
  Id = 'id',
  /** column name */
  Inprogress = 'inprogress',
  /** column name */
  Lang = 'lang',
  /** column name */
  LifePeriod = 'life_period',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  Payer = 'payer',
  /** column name */
  Phone = 'phone',
  /** column name */
  Phone2 = 'phone2',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  Ps = 'ps',
  /** column name */
  ReceiptType = 'receipt_type',
  /** column name */
  State = 'state',
  /** column name */
  ToClientId = 'to_client_id',
  /** column name */
  ToClientRef = 'to_client_ref',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Fintech_Orders_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Fintech_Orders_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Fintech_Orders_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Fintech_Orders_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Fintech_Orders_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fintech_Orders_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Fintech_Orders_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fintech_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fintech_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fintech_Orders_Var_Pop_Fields = {
  __typename?: 'fintech_orders_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  external_id?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inprogress?: Maybe<Scalars['Float']['output']>;
  lang?: Maybe<Scalars['Float']['output']>;
  life_period?: Maybe<Scalars['Float']['output']>;
  ps?: Maybe<Scalars['Float']['output']>;
  receipt_type?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "orders" */
export type Fintech_Orders_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fintech_Orders_Var_Samp_Fields = {
  __typename?: 'fintech_orders_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  external_id?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inprogress?: Maybe<Scalars['Float']['output']>;
  lang?: Maybe<Scalars['Float']['output']>;
  life_period?: Maybe<Scalars['Float']['output']>;
  ps?: Maybe<Scalars['Float']['output']>;
  receipt_type?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "orders" */
export type Fintech_Orders_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Fintech_Orders_Variance_Fields = {
  __typename?: 'fintech_orders_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  external_id?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inprogress?: Maybe<Scalars['Float']['output']>;
  lang?: Maybe<Scalars['Float']['output']>;
  life_period?: Maybe<Scalars['Float']['output']>;
  ps?: Maybe<Scalars['Float']['output']>;
  receipt_type?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "orders" */
export type Fintech_Orders_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inprogress?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  life_period?: InputMaybe<Order_By>;
  ps?: InputMaybe<Order_By>;
  receipt_type?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

export type GetInfoClinicForMapInput = {
  city_name: Scalars['String']['input'];
};

export type GetInfoClinicForMapOutput = {
  __typename?: 'getInfoClinicForMapOutput';
  infoClinic: Array<Maybe<Scalars['String']['output']>>;
};

export type InsertMobileProfilesOneInput = {
  about_myself?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type InsertMobileProfilesOneOutput = {
  __typename?: 'insertMobileProfilesOneOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  last_name: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

export type MedconciergeSendGreetingToProfileConciergeMessageOutput = {
  __typename?: 'medconciergeSendGreetingToProfileConciergeMessageOutput';
  bot_user_id: Scalars['Int']['output'];
  chat_id: Scalars['Int']['output'];
  chat_message_type_id: Scalars['Int']['output'];
  profile_id: Scalars['Int']['output'];
  status_message_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

export type MedconciergeSendGreetingToProfileInput = {
  bot_user_id: Scalars['Int']['input'];
  chat_id: Scalars['Int']['input'];
  chat_message_type_button_id: Scalars['Int']['input'];
  chat_message_type_text_id: Scalars['Int']['input'];
  profile_id: Scalars['Int']['input'];
  status_message_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

export type MedconciergeSendGreetingToProfileOutput = {
  __typename?: 'medconciergeSendGreetingToProfileOutput';
  result?: Maybe<Array<Maybe<MedconciergeSendGreetingToProfileConciergeMessageOutput>>>;
};

export type MobileAmocrmCreateLeadInput = {
  email_or_phone?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
  program_name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<MobileAmocrmCreateLeadTagsInput>>>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};

export type MobileAmocrmCreateLeadOutput = {
  __typename?: 'mobileAmocrmCreateLeadOutput';
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type MobileAmocrmCreateLeadTagsInput = {
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type MobileBotGreetingMessageChatOutput = {
  __typename?: 'mobileBotGreetingMessageChatOutput';
  chat_id: Scalars['Int']['output'];
  medconcierge_id: Scalars['Int']['output'];
  profile_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

export type MobileBotGreetingMessageInput = {
  profile_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

export type MobileBotGreetingMessageOutput = {
  __typename?: 'mobileBotGreetingMessageOutput';
  result?: Maybe<Array<Maybe<MobileBotGreetingMessageChatOutput>>>;
};

export type MobileCheckUsersOnlineDeviceOutput = {
  __typename?: 'mobileCheckUsersOnlineDeviceOutput';
  id?: Maybe<Scalars['Int']['output']>;
  last_online?: Maybe<Scalars['timestamp']['output']>;
  online?: Maybe<Scalars['Boolean']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileCheckUsersOnlineInput = {
  user_ids: Array<Scalars['Int']['input']>;
};

export type MobileCheckUsersOnlineOutput = {
  __typename?: 'mobileCheckUsersOnlineOutput';
  device?: Maybe<Array<Maybe<MobileCheckUsersOnlineDeviceOutput>>>;
  error?: Maybe<Scalars['String']['output']>;
};

export type MobileConciergePurchaseMailerSendClientInput = {
  medical_concierge_order_id?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileConciergePurchaseMailerSendClientOutput = {
  __typename?: 'mobileConciergePurchaseMailerSendClientOutput';
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type MobileContacts = {
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  record_id?: InputMaybe<Scalars['String']['input']>;
  type_phone?: InputMaybe<Scalars['String']['input']>;
};

export type MobileCreateLinkSnippetInput = {
  chat_id: Scalars['Int']['input'];
  chat_message_id: Scalars['Int']['input'];
  link: Scalars['String']['input'];
};

export type MobileCreateLinkSnippetOutput = {
  __typename?: 'mobileCreateLinkSnippetOutput';
  chat_id: Scalars['Int']['output'];
  chat_message_id: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image_url: Scalars['String']['output'];
  title: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
};

export type MobileCustomInsertChatMessageCommentInput = {
  chat_message_id: Scalars['Int']['input'];
  comment: Scalars['String']['input'];
};

export type MobileCustomInsertChatMessageCommentOutput = {
  __typename?: 'mobileCustomInsertChatMessageCommentOutput';
  chat_message_id: Scalars['Int']['output'];
  comment: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

export type MobileCustomInsertCommentInput = {
  message: Scalars['String']['input'];
  post_id: Scalars['Int']['input'];
  profile_id: Scalars['Int']['input'];
};

export type MobileCustomInsertCommentOutput = {
  __typename?: 'mobileCustomInsertCommentOutput';
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  post_id: Scalars['Int']['output'];
  profile_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

export type MobileDeleteAutoreplyInput = {
  auto_reply_id: Scalars['Int']['input'];
};

export type MobileDeleteAutoreplyOutput = {
  __typename?: 'mobileDeleteAutoreplyOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileDeleteAvatarOutput = {
  __typename?: 'mobileDeleteAvatarOutput';
  profile?: Maybe<ProfileOutput>;
};

export type MobileDeleteCommentsByPkInput = {
  comment_id: Scalars['Int']['input'];
};

export type MobileDeleteCommentsByPkOutput = {
  __typename?: 'mobileDeleteCommentsByPkOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  ref_comment_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileDeleteDocumentToProInput = {
  id: Scalars['Int']['input'];
  pro_id: Scalars['Int']['input'];
};

export type MobileDeleteDocumentToProOutput = {
  __typename?: 'mobileDeleteDocumentToProOutput';
  result?: Maybe<Array<Maybe<MobileDeleteDocumentToProresultOutput>>>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type MobileDeleteDocumentToProresultOutput = {
  __typename?: 'mobileDeleteDocumentToProresultOutput';
  document_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileDeletePersonalUserChatsInput = {
  chat_id?: InputMaybe<Scalars['Int']['input']>;
  delete_all?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MobileDeletePersonalUserChatsOutput = {
  __typename?: 'mobileDeletePersonalUserChatsOutput';
  error?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type MobileDeleteServiceInput = {
  service_id: Scalars['Int']['input'];
};

export type MobileFindByChatsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text_find: Scalars['String']['input'];
};

export type MobileFindByChatsOutput = {
  __typename?: 'mobileFindByChatsOutput';
  chat_profiles?: Maybe<Array<Maybe<Profiles>>>;
  chats_name_description?: Maybe<Array<Maybe<Chats>>>;
  profiles_global?: Maybe<Array<Maybe<Profiles>>>;
};

export type MobileFindByMyChatsInput = {
  text_find: Scalars['String']['input'];
};

export type MobileFindByMyChatsOutput = {
  __typename?: 'mobileFindByMyChatsOutput';
  chat_messages?: Maybe<Array<Maybe<Chat_Messages>>>;
};

export type MobileGetChatByIdInput = {
  chat_id: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileGetChatByIdOutput = {
  __typename?: 'mobileGetChatByIdOutput';
  chat?: Maybe<Scalars['String']['output']>;
};

export type MobileGetChatIdFindByProfileUserIdInput = {
  user_id_other: Scalars['Int']['input'];
};

export type MobileGetChatIdFindByProfileUserIdOutput = {
  __typename?: 'mobileGetChatIdFindByProfileUserIdOutput';
  chat_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileGetChatsOutput = {
  __typename?: 'mobileGetChatsOutput';
  chats: Scalars['String']['output'];
};

export type MobileGetChatsV2Output = {
  __typename?: 'mobileGetChatsV2Output';
  chats: Scalars['String']['output'];
};

export type MobileGetContactsInput = {
  contacts?: InputMaybe<Array<MobileContacts>>;
};

export type MobileGetContactsOutput = {
  __typename?: 'mobileGetContactsOutput';
  profiles?: Maybe<Array<Scalars['String']['output']>>;
};

export type MobileGetCountNotReadMessageOutput = {
  __typename?: 'mobileGetCountNotReadMessageOutput';
  number_unread_messages: Scalars['Int']['output'];
};

export type MobileGetFreeGuideInput = {
  email: Scalars['String']['input'];
  full_name?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  program_name: Scalars['String']['input'];
};

export type MobileGetFreeGuideOutput = {
  __typename?: 'mobileGetFreeGuideOutput';
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileGetPostMediaFileInput = {
  post_id: Scalars['Int']['input'];
  size: Scalars['String']['input'];
};

export type MobileGetPostMediaFileOutput = {
  __typename?: 'mobileGetPostMediaFileOutput';
  media_file_path?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type MobileGetProfileAvatarInput = {
  profile_id: Scalars['Int']['input'];
  size: Scalars['String']['input'];
};

export type MobileGetProfileAvatarOutput = {
  __typename?: 'mobileGetProfileAvatarOutput';
  avatar_path?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type MobileGetProfileMediafilesOutput = {
  __typename?: 'mobileGetProfileMediafilesOutput';
  result?: Maybe<Array<Maybe<MobileGetProfileMediafiles_User_Message_Files>>>;
};

export type MobileGetProfileMediafiles_User_Message_Files = {
  __typename?: 'mobileGetProfileMediafiles_user_message_files';
  file_original_path?: Maybe<Scalars['String']['output']>;
  message_id?: Maybe<Scalars['Int']['output']>;
  thumbnail_path?: Maybe<Scalars['String']['output']>;
};

export type MobileGetServiceTariffsInput = {
  tag_ages: Array<InputMaybe<Scalars['Int']['input']>>;
  tag_target_groups: Array<InputMaybe<Scalars['Int']['input']>>;
  tag_targets: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type MobileGetServiceTariffsOutput = {
  __typename?: 'mobileGetServiceTariffsOutput';
  service_tariffs?: Maybe<Array<Maybe<MobileGetServiceTariffsTariffOutput>>>;
};

export type MobileGetServiceTariffsServiceListsOutput = {
  __typename?: 'mobileGetServiceTariffsServiceListsOutput';
  as_gift?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity?: Maybe<ServiceListPeriodicityOutput>;
  service_list_periodicity_id?: Maybe<Scalars['Int']['output']>;
  service_tariff_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileGetServiceTariffsTariffOutput = {
  __typename?: 'mobileGetServiceTariffsTariffOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service?: Maybe<Array<Maybe<MobileGetServiceTariffsTariffService>>>;
  service_id?: Maybe<Scalars['Int']['output']>;
  service_lists?: Maybe<Array<Maybe<MobileGetServiceTariffsServiceListsOutput>>>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileGetServiceTariffsTariffService = {
  __typename?: 'mobileGetServiceTariffsTariffService';
  category_profession_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  service_images?: Maybe<Array<Maybe<ServiceImages>>>;
  service_periodicity?: Maybe<ServicePeriodicity>;
  service_status_id?: Maybe<Scalars['Int']['output']>;
  service_tag_ages?: Maybe<Array<Maybe<ServiceTagAges>>>;
  service_tag_target_groups?: Maybe<Array<Maybe<ServiceTagTargetGroups>>>;
  service_tag_targets?: Maybe<Array<Maybe<ServciceTagTargets>>>;
  service_type_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileGetStatusMedicalConciergeBillingInput = {
  order_id: Scalars['String']['input'];
};

export type MobileGetStatusMedicalConciergeBillingOutput = {
  __typename?: 'mobileGetStatusMedicalConciergeBillingOutput';
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  program_name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileGetStatusServiceBillingInput = {
  order_id: Scalars['String']['input'];
};

export type MobileGetStatusServiceBillingOutput = {
  __typename?: 'mobileGetStatusServiceBillingOutput';
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  service_tariff_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileHardDeleteUserForTestInput = {
  user_id: Scalars['Int']['input'];
};

export type MobileHardDeleteUserForTestOutput = {
  __typename?: 'mobileHardDeleteUserForTestOutput';
  status: Scalars['String']['output'];
};

export type MobileHardDeleteUserInput = {
  user_id: Scalars['Int']['input'];
};

export type MobileHardDeleteUserOutput = {
  __typename?: 'mobileHardDeleteUserOutput';
  status: Scalars['String']['output'];
};

export type MobileInsertButtonCommandMedconciergeInput = {
  button_command?: InputMaybe<Scalars['String']['input']>;
  chat_id: Scalars['Int']['input'];
  chat_message_type_id: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  profile_id: Scalars['Int']['input'];
  status_message_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

export type MobileInsertButtonCommandMedconciergeOutput = {
  __typename?: 'mobileInsertButtonCommandMedconciergeOutput';
  button_command?: Maybe<Scalars['String']['output']>;
  chat_id?: Maybe<Scalars['Int']['output']>;
  chat_message_type_id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  status_message_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileInsertChatMessagesOneChatMessageFilesOutput = {
  __typename?: 'mobileInsertChatMessagesOneChatMessageFilesOutput';
  chat_file_type_id?: Maybe<Scalars['Int']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
  video_processed?: Maybe<Scalars['Boolean']['output']>;
  video_sound?: Maybe<Scalars['Boolean']['output']>;
};

export type MobileInsertChatMessagesOneChatMessageTypeOutput = {
  __typename?: 'mobileInsertChatMessagesOneChatMessageTypeOutput';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertChatMessagesOneEmojiOutput = {
  __typename?: 'mobileInsertChatMessagesOneEmojiOutput';
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertChatMessagesOneFilesInput = {
  chat_file_type_id: Scalars['Int']['input'];
  file?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileInsertChatMessagesOneInput = {
  chat_id: Scalars['Int']['input'];
  chat_message_type_id: Scalars['Int']['input'];
  files?: InputMaybe<Array<InputMaybe<MobileInsertChatMessagesOneFilesInput>>>;
  message?: InputMaybe<Scalars['String']['input']>;
  profile_id: Scalars['Int']['input'];
  status_message_id: Scalars['Int']['input'];
};

export type MobileInsertChatMessagesOneOutput = {
  __typename?: 'mobileInsertChatMessagesOneOutput';
  changed_message?: Maybe<Scalars['Boolean']['output']>;
  chat_id?: Maybe<Scalars['Int']['output']>;
  chat_message_files?: Maybe<Array<Maybe<MobileInsertChatMessagesOneChatMessageFilesOutput>>>;
  chat_message_id?: Maybe<Scalars['Int']['output']>;
  chat_message_type?: Maybe<MobileInsertChatMessagesOneChatMessageTypeOutput>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_all?: Maybe<Scalars['Boolean']['output']>;
  deleted_my?: Maybe<Scalars['Boolean']['output']>;
  deletion_date?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<MobileInsertChatMessagesOneProfileOutput>;
  reactions?: Maybe<MobileInsertChatMessagesOneReactionOutput>;
  status_message?: Maybe<MobileInsertChatMessagesOneStatusMessageOutput>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id: Scalars['Int']['output'];
};

export type MobileInsertChatMessagesOneProfileOutput = {
  __typename?: 'mobileInsertChatMessagesOneProfileOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertChatMessagesOneReactionOutput = {
  __typename?: 'mobileInsertChatMessagesOneReactionOutput';
  emoji?: Maybe<MobileInsertChatMessagesOneEmojiOutput>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileInsertChatMessagesOneStatusMessageOutput = {
  __typename?: 'mobileInsertChatMessagesOneStatusMessageOutput';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertChatsOneInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  chat_type_id: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MobileInsertChatsOneOutput = {
  __typename?: 'mobileInsertChatsOneOutput';
  avatar_path?: Maybe<Scalars['String']['output']>;
  chat_type_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertMobileCommentsOneInput = {
  message: Scalars['String']['input'];
  post_id: Scalars['Int']['input'];
  ref_comment_id?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileInsertMobileCommentsOneOutput = {
  __typename?: 'mobileInsertMobileCommentsOneOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  profile?: Maybe<ProfileOutput>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  ref_comment_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileInsertParamsServicesInput = {
  metric_id?: InputMaybe<Scalars['Int']['input']>;
  param_id?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertParamsServicesOutput = {
  __typename?: 'mobileInsertParamsServicesOutput';
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  metric_id?: Maybe<Scalars['Int']['output']>;
  param_id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertPostParamsInput = {
  metric_id: Scalars['Int']['input'];
  param_id: Scalars['Int']['input'];
  post_id: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type MobileInsertPostParamsOutput = {
  __typename?: 'mobileInsertPostParamsOutput';
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  metric_id?: Maybe<Scalars['Int']['output']>;
  param_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProBankAccountInput = {
  account?: InputMaybe<Scalars['String']['input']>;
  bank_code?: InputMaybe<Scalars['String']['input']>;
  bank_correspondent_account?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertProBankAccountType = {
  __typename?: 'mobileInsertProBankAccountType';
  account?: Maybe<Scalars['String']['output']>;
  bank_code?: Maybe<Scalars['String']['output']>;
  bank_correspondent_account?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileInsertProDocumentInput = {
  document_mediafiles_attributes?: InputMaybe<Array<InputMaybe<MobileInsertProDocumentMediafileInput>>>;
  document_type?: InputMaybe<Scalars['String']['input']>;
  issue_date?: InputMaybe<Scalars['String']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertProDocumentMediafileInput = {
  mediafile?: InputMaybe<Scalars['String']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertProDocumentMediafileType = {
  __typename?: 'mobileInsertProDocumentMediafileType';
  id?: Maybe<Scalars['Int']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status?: Maybe<MobileInsertProMediafileStatusType>;
  mediafile_status_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProDocumentType = {
  __typename?: 'mobileInsertProDocumentType';
  document_mediafiles?: Maybe<Array<Maybe<MobileInsertProDocumentMediafileType>>>;
  document_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  issue_date?: Maybe<Scalars['String']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['Int']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProEducationInput = {
  education_mediafiles_attributes?: InputMaybe<Array<InputMaybe<MobileInsertProEducationMediafileInput>>>;
  faculty?: InputMaybe<Scalars['String']['input']>;
  graduation_year?: InputMaybe<Scalars['Int']['input']>;
  institution_name?: InputMaybe<Scalars['String']['input']>;
  specialization?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertProEducationMediafileInput = {
  mediafile?: InputMaybe<Scalars['String']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertProEducationMediafileType = {
  __typename?: 'mobileInsertProEducationMediafileType';
  id?: Maybe<Scalars['Int']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status?: Maybe<MobileInsertProMediafileStatusType>;
  mediafile_status_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProEducationType = {
  __typename?: 'mobileInsertProEducationType';
  education_mediafiles?: Maybe<Array<Maybe<MobileInsertProEducationMediafileType>>>;
  faculty?: Maybe<Scalars['String']['output']>;
  graduation_year?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  institution_name?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['Int']['output']>;
  specialization?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProFounderInput = {
  birth_date?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  founder_mediafiles_attributes?: InputMaybe<Array<InputMaybe<MobileInsertProFounderMediafileInput>>>;
  issue_date?: InputMaybe<Scalars['String']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertProFounderMediafileInput = {
  mediafile?: InputMaybe<Scalars['String']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertProFounderMediafileType = {
  __typename?: 'mobileInsertProFounderMediafileType';
  founder_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status?: Maybe<MobileInsertProMediafileStatusType>;
  mediafile_status_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProFounderType = {
  __typename?: 'mobileInsertProFounderType';
  birth_date?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  founder_mediafiles?: Maybe<Array<Maybe<MobileInsertProFounderMediafileType>>>;
  id?: Maybe<Scalars['Int']['output']>;
  issue_date?: Maybe<Scalars['String']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['Int']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProInput = {
  bank_account_attributes?: InputMaybe<MobileInsertProBankAccountInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents_attributes?: InputMaybe<Array<InputMaybe<MobileInsertProDocumentInput>>>;
  educations_attributes?: InputMaybe<Array<InputMaybe<MobileInsertProEducationInput>>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  founders_attributes?: InputMaybe<Array<InputMaybe<MobileInsertProFounderInput>>>;
  inn?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  ogrnip?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  profession_id: Scalars['Int']['input'];
  profile_id: Scalars['Int']['input'];
  snils?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['Int']['input'];
};

export type MobileInsertProMediafileStatusNameType = {
  __typename?: 'mobileInsertProMediafileStatusNameType';
  id?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProMediafileStatusType = {
  __typename?: 'mobileInsertProMediafileStatusType';
  id?: Maybe<Scalars['Int']['output']>;
  mediafile_status_names?: Maybe<Array<Maybe<MobileInsertProMediafileStatusNameType>>>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProOutput = {
  __typename?: 'mobileInsertProOutput';
  bank_account?: Maybe<MobileInsertProBankAccountType>;
  created_at?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<MobileInsertProDocumentType>>>;
  educations?: Maybe<Array<Maybe<MobileInsertProEducationType>>>;
  first_name?: Maybe<Scalars['String']['output']>;
  founders?: Maybe<Array<Maybe<MobileInsertProFounderType>>>;
  id?: Maybe<Scalars['Int']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  ogrnip?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  profession?: Maybe<MobileInsertProProfessionType>;
  profession_id: Scalars['Int']['output'];
  profile_id: Scalars['Int']['output'];
  snils?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id: Scalars['Int']['output'];
};

export type MobileInsertProProfessionNameType = {
  __typename?: 'mobileInsertProProfessionNameType';
  id?: Maybe<Scalars['Int']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profession_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileInsertProProfessionType = {
  __typename?: 'mobileInsertProProfessionType';
  category_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  profession_names?: Maybe<MobileInsertProProfessionNameType>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertProfileLocksOneInput = {
  locked_user_id: Scalars['Int']['input'];
};

export type MobileInsertProfileLocksOneOutput = {
  __typename?: 'mobileInsertProfileLocksOneOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locked_user_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['timestamptz']['output']>;
};

export type MobileInsertReportsInput = {
  reportable_id?: InputMaybe<Scalars['Int']['input']>;
  reportable_type?: InputMaybe<Scalars['String']['input']>;
};

export type MobileInsertReportsOutput = {
  __typename?: 'mobileInsertReportsOutput';
  message?: Maybe<Scalars['String']['output']>;
};

export type MobileInsertServiceListsInput = {
  service_id: Scalars['Int']['input'];
  service_lists: Array<InputMaybe<ServiceListsInput>>;
};

export type MobileInsertServiceListsOutput = {
  __typename?: 'mobileInsertServiceListsOutput';
  service_tariff?: Maybe<ServiceTariffOutput>;
};

export type MobileInsertServiceRatesOneInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rate?: InputMaybe<Scalars['Int']['input']>;
  service_id: Scalars['Int']['input'];
};

export type MobileInsertServiceRatesOneOutput = {
  __typename?: 'mobileInsertServiceRatesOneOutput';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileInsertServicesOneInput = {
  detailed_message: Scalars['String']['input'];
  instruction?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Float']['input']>;
  service_periodicity_id?: InputMaybe<Scalars['Int']['input']>;
  tag_ages?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tag_target_groups?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tag_targets?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  title: Scalars['String']['input'];
};

export type MobileInsertServicesOneOutput = {
  __typename?: 'mobileInsertServicesOneOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  detailed_message?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  instruction?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Int']['output']>;
  service_status_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileInsertSubscribesOneInput = {
  user_id_subscribe: Scalars['Int']['input'];
};

export type MobileInsertSubscribesOneOutput = {
  __typename?: 'mobileInsertSubscribesOneOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  user_id_subscribe?: Maybe<Scalars['Int']['output']>;
};

export type MobileMedconciergeTimerButtonInput = {
  chat_id: Scalars['Int']['input'];
};

export type MobilePostFindByTitleMessageInput = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  metric_param?: InputMaybe<Array<InputMaybe<MobilePostFindByTitleMessageMpInput>>>;
  text_find?: InputMaybe<Scalars['String']['input']>;
};

export type MobilePostFindByTitleMessageMpInput = {
  metric_id?: InputMaybe<Scalars['Int']['input']>;
  param_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MobilePostFindByTitleMessageOutput = {
  __typename?: 'mobilePostFindByTitleMessageOutput';
  posts?: Maybe<Array<Scalars['String']['output']>>;
  profile?: Maybe<Array<Maybe<MobilePostFindByTitleMessageOutputProfile>>>;
};

export type MobilePostFindByTitleMessageOutputProfile = {
  __typename?: 'mobilePostFindByTitleMessageOutputProfile';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  pro?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type MobilePostsActionInput = {
  count: Scalars['Int']['input'];
};

export type MobilePostsActionOutput = {
  __typename?: 'mobilePostsActionOutput';
  posts: Scalars['jsonb']['output'];
};

export type MobileProfileDataRecoveryOutput = {
  __typename?: 'mobileProfileDataRecoveryOutput';
  error?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type MobileProfileFindByFioInput = {
  fio: Scalars['String']['input'];
};

export type MobileProfileFindByFioOutput = {
  __typename?: 'mobileProfileFindByFioOutput';
  profiles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text_find?: InputMaybe<Scalars['String']['input']>;
  user_id_profile: Scalars['Int']['input'];
};

export type MobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberOutput = {
  __typename?: 'mobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberOutput';
  profiles?: Maybe<Array<MobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberProfOutput>>;
};

export type MobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberProfOutput = {
  __typename?: 'mobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberProfOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  pro?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['Int']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutLikeInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  post_id: Scalars['Int']['input'];
  text_find?: InputMaybe<Scalars['String']['input']>;
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutLikeOutput = {
  __typename?: 'mobileProfileFindByUsernameFirstnameLastnameAboutLikeOutput';
  profiles?: Maybe<Array<MobileProfileFindByUsernameFirstnameLastnameAboutLikeProfOutput>>;
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutLikeProfOutput = {
  __typename?: 'mobileProfileFindByUsernameFirstnameLastnameAboutLikeProfOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  pro?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['Int']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutSubscriberInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text_find?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['Int']['input'];
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutSubscriberOutput = {
  __typename?: 'mobileProfileFindByUsernameFirstnameLastnameAboutSubscriberOutput';
  profiles?: Maybe<Array<MobileProfileFindByUsernameFirstnameLastnameAboutSubscriberProfOutput>>;
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutSubscriberProfOutput = {
  __typename?: 'mobileProfileFindByUsernameFirstnameLastnameAboutSubscriberProfOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  pro?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['Int']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text_find?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['Int']['input'];
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionOutput = {
  __typename?: 'mobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionOutput';
  profiles?: Maybe<Array<Maybe<MobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionProfOutput>>>;
};

export type MobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionProfOutput = {
  __typename?: 'mobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionProfOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  pro?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['Int']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

export type MobileProfileGeneralSubscriberInput = {
  profile_id: Scalars['Int']['input'];
};

export type MobileProfileGeneralSubscriberOutput = {
  __typename?: 'mobileProfileGeneralSubscriberOutput';
  profiles?: Maybe<Array<Scalars['String']['output']>>;
};

export type MobilePurchasingMedicalConciergeInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fio: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  phone: Scalars['String']['input'];
  program_name: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<MobilePurchasingMedicalConciergeInputTagsInput>>>;
};

export type MobilePurchasingMedicalConciergeInputTagsInput = {
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type MobilePurchasingMedicalConciergeOutput = {
  __typename?: 'mobilePurchasingMedicalConciergeOutput';
  error?: Maybe<Scalars['String']['output']>;
  payment_link?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type MobilePurchasingMedicalConciergeRetryInput = {
  order_id: Scalars['String']['input'];
};

export type MobilePurchasingMedicalConciergeRetryOutput = {
  __typename?: 'mobilePurchasingMedicalConciergeRetryOutput';
  error?: Maybe<Scalars['String']['output']>;
  payment_link?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type MobilePurchasingPersonalProgramInput = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  tag_age_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tag_target_group_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tag_target_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  user_name: Scalars['String']['input'];
};

export type MobilePurchasingPersonalProgramOutput = {
  __typename?: 'mobilePurchasingPersonalProgramOutput';
  error?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type MobilePurchasingServiceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fio: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  service_tariff_id?: InputMaybe<Scalars['Int']['input']>;
  source: Scalars['String']['input'];
};

export type MobilePurchasingServiceOutput = {
  __typename?: 'mobilePurchasingServiceOutput';
  error?: Maybe<Scalars['String']['output']>;
  payment_link?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type MobilePurchasingServiceRetryInput = {
  order_id: Scalars['String']['input'];
};

export type MobilePurchasingServiceRetryOutput = {
  __typename?: 'mobilePurchasingServiceRetryOutput';
  error?: Maybe<Scalars['String']['output']>;
  payment_link?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type MobileSearchByMessagesInFilesInput = {
  text_find: Scalars['String']['input'];
};

export type MobileSearchByMessagesInFilesOutput = {
  __typename?: 'mobileSearchByMessagesInFilesOutput';
  result?: Maybe<Array<Maybe<MobileSearchByMessagesInFilesOutput_Message_And_Files>>>;
};

export type MobileSearchByMessagesInFilesOutput_File = {
  __typename?: 'mobileSearchByMessagesInFilesOutput_file';
  chat_file_type_id: Scalars['Int']['output'];
  chat_id: Scalars['Int']['output'];
  chat_message_id: Scalars['Int']['output'];
  file_path: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type MobileSearchByMessagesInFilesOutput_Message = {
  __typename?: 'mobileSearchByMessagesInFilesOutput_message';
  chat_id: Scalars['Int']['output'];
  chat_message_id: Scalars['Int']['output'];
  chat_message_type_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  link_snippet_id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  profile_id: Scalars['Int']['output'];
  status_message_id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

export type MobileSearchByMessagesInFilesOutput_Message_And_Files = {
  __typename?: 'mobileSearchByMessagesInFilesOutput_message_and_files';
  file?: Maybe<Array<Maybe<MobileSearchByMessagesInFilesOutput_File>>>;
  message?: Maybe<MobileSearchByMessagesInFilesOutput_Message>;
};

export type MobileSearchByServiceLikesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  service_id: Scalars['Int']['input'];
  text_find: Scalars['String']['input'];
};

export type MobileSearchByServiceLikesOutput = {
  __typename?: 'mobileSearchByServiceLikesOutput';
  profiles?: Maybe<Array<Maybe<ProfilesOutput>>>;
};

export type MobileSearchProfilesFindFirstLastUsernameAboutmyselfInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  text_find: Scalars['String']['input'];
};

export type MobileSearchProfilesFindFirstLastUsernameAboutmyselfOutput = {
  __typename?: 'mobileSearchProfilesFindFirstLastUsernameAboutmyselfOutput';
  profiles?: Maybe<Array<Maybe<MobileSearchProfilesFindFirstLastUsernameAboutmyselfOutputProfiles>>>;
};

export type MobileSearchProfilesFindFirstLastUsernameAboutmyselfOutputProfiles = {
  __typename?: 'mobileSearchProfilesFindFirstLastUsernameAboutmyselfOutputProfiles';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['Int']['output']>;
  type_profile?: Maybe<MobileSearchProfilesFindFirstLastUsernameAboutmyselfOutputTypeProfile>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type MobileSearchProfilesFindFirstLastUsernameAboutmyselfOutputTypeProfile = {
  __typename?: 'mobileSearchProfilesFindFirstLastUsernameAboutmyselfOutputTypeProfile';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MobileSendInviteInput = {
  contact_id?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
};

export type MobileSendInviteOutput = {
  __typename?: 'mobileSendInviteOutput';
  status: Scalars['String']['output'];
};

export type MobileServiceCheckingInput = {
  service_id: Scalars['Int']['input'];
};

export type MobileServiceHiddenInput = {
  service_id: Scalars['Int']['input'];
};

export type MobileSetOperatorProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type MobileSetOperatorProfileOutput = {
  __typename?: 'mobileSetOperatorProfileOutput';
  profile?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileSetProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type MobileSetProfileMedBotInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
  username: Scalars['String']['input'];
};

export type MobileSetProfileMedBotOutput = {
  __typename?: 'mobileSetProfileMedBotOutput';
  profile?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileSetProfileOutput = {
  __typename?: 'mobileSetProfileOutput';
  profile?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileSoftDeleteCommentInput = {
  comment_id?: InputMaybe<Scalars['Int']['input']>;
};

export type MobileSoftDeleteCommentOutput = {
  __typename?: 'mobileSoftDeleteCommentOutput';
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  profile_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileSoftDeleteUserFotTestInput = {
  user_id: Scalars['Int']['input'];
};

export type MobileSoftDeleteUserFotTestOutput = {
  __typename?: 'mobileSoftDeleteUserFotTestOutput';
  status: Scalars['String']['output'];
};

export type MobileSoftDeleteUserInput = {
  user_id: Scalars['Int']['input'];
};

export type MobileSoftDeleteUserOutput = {
  __typename?: 'mobileSoftDeleteUserOutput';
  status: Scalars['String']['output'];
};

export type MobileUpdateChatMessagesStatusMessageInput = {
  chat_messages_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  status_message_id: Scalars['Int']['input'];
};

export type MobileUpdateChatMessagesStatusMessageOutput = {
  __typename?: 'mobileUpdateChatMessagesStatusMessageOutput';
  chat_messages?: Maybe<Array<Maybe<ChatMessages>>>;
  error?: Maybe<Scalars['String']['output']>;
};

export type MobileUpdateDocumentToAutoreplyInput = {
  auto_reply_document_id: Scalars['Int']['input'];
  document: Scalars['String']['input'];
};

export type MobileUpdateDocumentToServiceInput = {
  document: Scalars['String']['input'];
  service_document_id: Scalars['Int']['input'];
};

export type MobileUpdateMediaFileToServiceInput = {
  media_file: Scalars['String']['input'];
  service_image_id: Scalars['Int']['input'];
};

export type MobileUpdateMediafileToAutoreplyInput = {
  auto_reply_mediafile_id: Scalars['Int']['input'];
  image: Scalars['String']['input'];
};

export type MobileUpdateMobileProfileAvatarInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
};

export type MobileUpdateServiceInput = {
  category_profession_id: Scalars['Int']['input'];
  currency: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  title: Scalars['String']['input'];
};

export type MobileUploadAgreementToServiceInput = {
  document: Scalars['String']['input'];
  service_id: Scalars['Int']['input'];
};

export type MobileUploadAgreementToServiceOutput = {
  __typename?: 'mobileUploadAgreementToServiceOutput';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileUploadDocumentToAutoreplyInput = {
  auto_reply_id: Scalars['Int']['input'];
  document: Scalars['String']['input'];
};

export type MobileUploadDocumentToAutoreplyOutput = {
  __typename?: 'mobileUploadDocumentToAutoreplyOutput';
  auto_reply_id?: Maybe<Scalars['Int']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_status?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileUploadDocumentToProInput = {
  document: Scalars['String']['input'];
  pro_id: Scalars['Int']['input'];
};

export type MobileUploadDocumentToProOutput = {
  __typename?: 'mobileUploadDocumentToProOutput';
  document_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type MobileUploadDocumentToServiceInput = {
  documents: Array<InputMaybe<Scalars['String']['input']>>;
  service_id: Scalars['Int']['input'];
};

export type MobileUploadDocumentToServiceListDocOutput = {
  __typename?: 'mobileUploadDocumentToServiceListDocOutput';
  document_path?: Maybe<Scalars['String']['output']>;
  document_status?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  service_list_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileUploadDocumentToServiceListInput = {
  documents?: InputMaybe<Array<Scalars['String']['input']>>;
  service_list_id: Scalars['Int']['input'];
};

export type MobileUploadDocumentToServiceListOutput = {
  __typename?: 'mobileUploadDocumentToServiceListOutput';
  docs?: Maybe<Array<Maybe<MobileUploadDocumentToServiceListDocOutput>>>;
};

export type MobileUploadDocumentToServiceOutput = {
  __typename?: 'mobileUploadDocumentToServiceOutput';
  documents?: Maybe<Array<Maybe<MobileUploadDocumentToServicedocumentsOutput>>>;
};

export type MobileUploadDocumentToServicedocumentsOutput = {
  __typename?: 'mobileUploadDocumentToServicedocumentsOutput';
  document_path?: Maybe<Scalars['String']['output']>;
  document_status?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type MobileUploadMediaFileToServiceInput = {
  media_file: Scalars['String']['input'];
  service_id: Scalars['Int']['input'];
};

export type MobileUploadMediaFileToServiceOutput = {
  __typename?: 'mobileUploadMediaFileToServiceOutput';
  error?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  media_file_path?: Maybe<Scalars['String']['output']>;
  media_file_status?: Maybe<Scalars['String']['output']>;
};

export type MobileUploadMediafileToAutoreplyInput = {
  auto_reply_id: Scalars['Int']['input'];
  media_file: Scalars['String']['input'];
};

export type MobileUploadMediafileToAutoreplyOutput = {
  __typename?: 'mobileUploadMediafileToAutoreplyOutput';
  auto_reply_id?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_status?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "amocrm_contacts" */
export type Mobile_Amocrm_Contacts = {
  __typename?: 'mobile_amocrm_contacts';
  /** An array relationship */
  amocrm_leads: Array<Mobile_Amocrm_Leads>;
  /** An aggregate relationship */
  amocrm_leads_aggregate: Mobile_Amocrm_Leads_Aggregate;
  contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  username?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "amocrm_contacts" */
export type Mobile_Amocrm_ContactsAmocrm_LeadsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


/** columns and relationships of "amocrm_contacts" */
export type Mobile_Amocrm_ContactsAmocrm_Leads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};

/** aggregated selection of "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Aggregate = {
  __typename?: 'mobile_amocrm_contacts_aggregate';
  aggregate?: Maybe<Mobile_Amocrm_Contacts_Aggregate_Fields>;
  nodes: Array<Mobile_Amocrm_Contacts>;
};

/** aggregate fields of "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Aggregate_Fields = {
  __typename?: 'mobile_amocrm_contacts_aggregate_fields';
  avg?: Maybe<Mobile_Amocrm_Contacts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Amocrm_Contacts_Max_Fields>;
  min?: Maybe<Mobile_Amocrm_Contacts_Min_Fields>;
  stddev?: Maybe<Mobile_Amocrm_Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Amocrm_Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Amocrm_Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Amocrm_Contacts_Sum_Fields>;
  var_pop?: Maybe<Mobile_Amocrm_Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Amocrm_Contacts_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Amocrm_Contacts_Variance_Fields>;
};


/** aggregate fields of "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Amocrm_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Amocrm_Contacts_Avg_Fields = {
  __typename?: 'mobile_amocrm_contacts_avg_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "amocrm_contacts". All fields are combined with a logical 'AND'. */
export type Mobile_Amocrm_Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Amocrm_Contacts_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Amocrm_Contacts_Bool_Exp>>;
  amocrm_leads?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
  amocrm_leads_aggregate?: InputMaybe<Mobile_Amocrm_Leads_Aggregate_Bool_Exp>;
  contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "amocrm_contacts" */
export enum Mobile_Amocrm_Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AmocrmContactsPkey = 'amocrm_contacts_pkey'
}

/** input type for incrementing numeric columns in table "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Inc_Input = {
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Insert_Input = {
  amocrm_leads?: InputMaybe<Mobile_Amocrm_Leads_Arr_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Amocrm_Contacts_Max_Fields = {
  __typename?: 'mobile_amocrm_contacts_max_fields';
  contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Amocrm_Contacts_Min_Fields = {
  __typename?: 'mobile_amocrm_contacts_min_fields';
  contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Mutation_Response = {
  __typename?: 'mobile_amocrm_contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Amocrm_Contacts>;
};

/** input type for inserting object relation for remote table "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Obj_Rel_Insert_Input = {
  data: Mobile_Amocrm_Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Amocrm_Contacts_On_Conflict>;
};

/** on_conflict condition type for table "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_On_Conflict = {
  constraint: Mobile_Amocrm_Contacts_Constraint;
  update_columns?: Array<Mobile_Amocrm_Contacts_Update_Column>;
  where?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "amocrm_contacts". */
export type Mobile_Amocrm_Contacts_Order_By = {
  amocrm_leads_aggregate?: InputMaybe<Mobile_Amocrm_Leads_Aggregate_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: amocrm_contacts */
export type Mobile_Amocrm_Contacts_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "amocrm_contacts" */
export enum Mobile_Amocrm_Contacts_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Set_Input = {
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Amocrm_Contacts_Stddev_Fields = {
  __typename?: 'mobile_amocrm_contacts_stddev_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Amocrm_Contacts_Stddev_Pop_Fields = {
  __typename?: 'mobile_amocrm_contacts_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Amocrm_Contacts_Stddev_Samp_Fields = {
  __typename?: 'mobile_amocrm_contacts_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_amocrm_contacts" */
export type Mobile_Amocrm_Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Amocrm_Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Amocrm_Contacts_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Amocrm_Contacts_Sum_Fields = {
  __typename?: 'mobile_amocrm_contacts_sum_fields';
  contact_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "amocrm_contacts" */
export enum Mobile_Amocrm_Contacts_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Mobile_Amocrm_Contacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Amocrm_Contacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Amocrm_Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Amocrm_Contacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Amocrm_Contacts_Var_Pop_Fields = {
  __typename?: 'mobile_amocrm_contacts_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Amocrm_Contacts_Var_Samp_Fields = {
  __typename?: 'mobile_amocrm_contacts_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Amocrm_Contacts_Variance_Fields = {
  __typename?: 'mobile_amocrm_contacts_variance_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "amocrm_leads" */
export type Mobile_Amocrm_Leads = {
  __typename?: 'mobile_amocrm_leads';
  /** An object relationship */
  amocrm_contact?: Maybe<Mobile_Amocrm_Contacts>;
  amocrm_contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lead_id?: Maybe<Scalars['Int']['output']>;
  lead_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  medical_concierge_order?: Maybe<Mobile_Medical_Concierge_Orders>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  medical_concierge_product?: Maybe<Mobile_Medical_Concierge_Products>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updated_at: Scalars['timestamp']['output'];
  user_name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "amocrm_leads" */
export type Mobile_Amocrm_Leads_Aggregate = {
  __typename?: 'mobile_amocrm_leads_aggregate';
  aggregate?: Maybe<Mobile_Amocrm_Leads_Aggregate_Fields>;
  nodes: Array<Mobile_Amocrm_Leads>;
};

export type Mobile_Amocrm_Leads_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Amocrm_Leads_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Amocrm_Leads_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "amocrm_leads" */
export type Mobile_Amocrm_Leads_Aggregate_Fields = {
  __typename?: 'mobile_amocrm_leads_aggregate_fields';
  avg?: Maybe<Mobile_Amocrm_Leads_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Amocrm_Leads_Max_Fields>;
  min?: Maybe<Mobile_Amocrm_Leads_Min_Fields>;
  stddev?: Maybe<Mobile_Amocrm_Leads_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Amocrm_Leads_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Amocrm_Leads_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Amocrm_Leads_Sum_Fields>;
  var_pop?: Maybe<Mobile_Amocrm_Leads_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Amocrm_Leads_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Amocrm_Leads_Variance_Fields>;
};


/** aggregate fields of "amocrm_leads" */
export type Mobile_Amocrm_Leads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Amocrm_Leads_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Amocrm_Leads_Max_Order_By>;
  min?: InputMaybe<Mobile_Amocrm_Leads_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Amocrm_Leads_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Amocrm_Leads_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Amocrm_Leads_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Amocrm_Leads_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Amocrm_Leads_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Amocrm_Leads_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Amocrm_Leads_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Amocrm_Leads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Amocrm_Leads_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Amocrm_Leads_Avg_Fields = {
  __typename?: 'mobile_amocrm_leads_avg_fields';
  amocrm_contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lead_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Avg_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "amocrm_leads". All fields are combined with a logical 'AND'. */
export type Mobile_Amocrm_Leads_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Amocrm_Leads_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Amocrm_Leads_Bool_Exp>>;
  amocrm_contact?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
  amocrm_contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lead_id?: InputMaybe<Int_Comparison_Exp>;
  lead_type?: InputMaybe<String_Comparison_Exp>;
  medical_concierge_order?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
  medical_concierge_order_id?: InputMaybe<Bigint_Comparison_Exp>;
  medical_concierge_product?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
  medical_concierge_product_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_order_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
  tags?: InputMaybe<String_Array_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "amocrm_leads" */
export enum Mobile_Amocrm_Leads_Constraint {
  /** unique or primary key constraint on columns "id" */
  AmocrmLeadsPkey = 'amocrm_leads_pkey'
}

/** input type for incrementing numeric columns in table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Inc_Input = {
  amocrm_contact_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lead_id?: InputMaybe<Scalars['Int']['input']>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Insert_Input = {
  amocrm_contact?: InputMaybe<Mobile_Amocrm_Contacts_Obj_Rel_Insert_Input>;
  amocrm_contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lead_id?: InputMaybe<Scalars['Int']['input']>;
  lead_type?: InputMaybe<Scalars['String']['input']>;
  medical_concierge_order?: InputMaybe<Mobile_Medical_Concierge_Orders_Obj_Rel_Insert_Input>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product?: InputMaybe<Mobile_Medical_Concierge_Products_Obj_Rel_Insert_Input>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Amocrm_Leads_Max_Fields = {
  __typename?: 'mobile_amocrm_leads_max_fields';
  amocrm_contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lead_id?: Maybe<Scalars['Int']['output']>;
  lead_type?: Maybe<Scalars['String']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Max_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  lead_type?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Amocrm_Leads_Min_Fields = {
  __typename?: 'mobile_amocrm_leads_min_fields';
  amocrm_contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lead_id?: Maybe<Scalars['Int']['output']>;
  lead_type?: Maybe<Scalars['String']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Min_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  lead_type?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Mutation_Response = {
  __typename?: 'mobile_amocrm_leads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Amocrm_Leads>;
};

/** on_conflict condition type for table "amocrm_leads" */
export type Mobile_Amocrm_Leads_On_Conflict = {
  constraint: Mobile_Amocrm_Leads_Constraint;
  update_columns?: Array<Mobile_Amocrm_Leads_Update_Column>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};

/** Ordering options when selecting data from "amocrm_leads". */
export type Mobile_Amocrm_Leads_Order_By = {
  amocrm_contact?: InputMaybe<Mobile_Amocrm_Contacts_Order_By>;
  amocrm_contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  lead_type?: InputMaybe<Order_By>;
  medical_concierge_order?: InputMaybe<Mobile_Medical_Concierge_Orders_Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product?: InputMaybe<Mobile_Medical_Concierge_Products_Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: amocrm_leads */
export type Mobile_Amocrm_Leads_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "amocrm_leads" */
export enum Mobile_Amocrm_Leads_Select_Column {
  /** column name */
  AmocrmContactId = 'amocrm_contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeadId = 'lead_id',
  /** column name */
  LeadType = 'lead_type',
  /** column name */
  MedicalConciergeOrderId = 'medical_concierge_order_id',
  /** column name */
  MedicalConciergeProductId = 'medical_concierge_product_id',
  /** column name */
  ServiceOrderId = 'service_order_id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Set_Input = {
  amocrm_contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lead_id?: InputMaybe<Scalars['Int']['input']>;
  lead_type?: InputMaybe<Scalars['String']['input']>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Amocrm_Leads_Stddev_Fields = {
  __typename?: 'mobile_amocrm_leads_stddev_fields';
  amocrm_contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lead_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Stddev_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Amocrm_Leads_Stddev_Pop_Fields = {
  __typename?: 'mobile_amocrm_leads_stddev_pop_fields';
  amocrm_contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lead_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Stddev_Pop_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Amocrm_Leads_Stddev_Samp_Fields = {
  __typename?: 'mobile_amocrm_leads_stddev_samp_fields';
  amocrm_contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lead_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Stddev_Samp_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_amocrm_leads" */
export type Mobile_Amocrm_Leads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Amocrm_Leads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Amocrm_Leads_Stream_Cursor_Value_Input = {
  amocrm_contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lead_id?: InputMaybe<Scalars['Int']['input']>;
  lead_type?: InputMaybe<Scalars['String']['input']>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Amocrm_Leads_Sum_Fields = {
  __typename?: 'mobile_amocrm_leads_sum_fields';
  amocrm_contact_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lead_id?: Maybe<Scalars['Int']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Sum_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** update columns of table "amocrm_leads" */
export enum Mobile_Amocrm_Leads_Update_Column {
  /** column name */
  AmocrmContactId = 'amocrm_contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeadId = 'lead_id',
  /** column name */
  LeadType = 'lead_type',
  /** column name */
  MedicalConciergeOrderId = 'medical_concierge_order_id',
  /** column name */
  MedicalConciergeProductId = 'medical_concierge_product_id',
  /** column name */
  ServiceOrderId = 'service_order_id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserName = 'user_name'
}

export type Mobile_Amocrm_Leads_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Amocrm_Leads_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Amocrm_Leads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Amocrm_Leads_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Amocrm_Leads_Var_Pop_Fields = {
  __typename?: 'mobile_amocrm_leads_var_pop_fields';
  amocrm_contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lead_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Var_Pop_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Amocrm_Leads_Var_Samp_Fields = {
  __typename?: 'mobile_amocrm_leads_var_samp_fields';
  amocrm_contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lead_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Var_Samp_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Amocrm_Leads_Variance_Fields = {
  __typename?: 'mobile_amocrm_leads_variance_fields';
  amocrm_contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lead_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "amocrm_leads" */
export type Mobile_Amocrm_Leads_Variance_Order_By = {
  amocrm_contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead_id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "amocrm_tags" */
export type Mobile_Amocrm_Tags = {
  __typename?: 'mobile_amocrm_tags';
  color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  request_id?: Maybe<Scalars['bigint']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "amocrm_tags" */
export type Mobile_Amocrm_Tags_Aggregate = {
  __typename?: 'mobile_amocrm_tags_aggregate';
  aggregate?: Maybe<Mobile_Amocrm_Tags_Aggregate_Fields>;
  nodes: Array<Mobile_Amocrm_Tags>;
};

/** aggregate fields of "amocrm_tags" */
export type Mobile_Amocrm_Tags_Aggregate_Fields = {
  __typename?: 'mobile_amocrm_tags_aggregate_fields';
  avg?: Maybe<Mobile_Amocrm_Tags_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Amocrm_Tags_Max_Fields>;
  min?: Maybe<Mobile_Amocrm_Tags_Min_Fields>;
  stddev?: Maybe<Mobile_Amocrm_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Amocrm_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Amocrm_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Amocrm_Tags_Sum_Fields>;
  var_pop?: Maybe<Mobile_Amocrm_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Amocrm_Tags_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Amocrm_Tags_Variance_Fields>;
};


/** aggregate fields of "amocrm_tags" */
export type Mobile_Amocrm_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Amocrm_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Amocrm_Tags_Avg_Fields = {
  __typename?: 'mobile_amocrm_tags_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  request_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "amocrm_tags". All fields are combined with a logical 'AND'. */
export type Mobile_Amocrm_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Amocrm_Tags_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Amocrm_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Amocrm_Tags_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  request_id?: InputMaybe<Bigint_Comparison_Exp>;
  tag_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "amocrm_tags" */
export enum Mobile_Amocrm_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  AmocrmTagsPkey = 'amocrm_tags_pkey'
}

/** input type for incrementing numeric columns in table "amocrm_tags" */
export type Mobile_Amocrm_Tags_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  request_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "amocrm_tags" */
export type Mobile_Amocrm_Tags_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  request_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Amocrm_Tags_Max_Fields = {
  __typename?: 'mobile_amocrm_tags_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  request_id?: Maybe<Scalars['bigint']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Amocrm_Tags_Min_Fields = {
  __typename?: 'mobile_amocrm_tags_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  request_id?: Maybe<Scalars['bigint']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "amocrm_tags" */
export type Mobile_Amocrm_Tags_Mutation_Response = {
  __typename?: 'mobile_amocrm_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Amocrm_Tags>;
};

/** on_conflict condition type for table "amocrm_tags" */
export type Mobile_Amocrm_Tags_On_Conflict = {
  constraint: Mobile_Amocrm_Tags_Constraint;
  update_columns?: Array<Mobile_Amocrm_Tags_Update_Column>;
  where?: InputMaybe<Mobile_Amocrm_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "amocrm_tags". */
export type Mobile_Amocrm_Tags_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: amocrm_tags */
export type Mobile_Amocrm_Tags_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "amocrm_tags" */
export enum Mobile_Amocrm_Tags_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "amocrm_tags" */
export type Mobile_Amocrm_Tags_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  request_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Amocrm_Tags_Stddev_Fields = {
  __typename?: 'mobile_amocrm_tags_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  request_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Amocrm_Tags_Stddev_Pop_Fields = {
  __typename?: 'mobile_amocrm_tags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  request_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Amocrm_Tags_Stddev_Samp_Fields = {
  __typename?: 'mobile_amocrm_tags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  request_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_amocrm_tags" */
export type Mobile_Amocrm_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Amocrm_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Amocrm_Tags_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  request_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Amocrm_Tags_Sum_Fields = {
  __typename?: 'mobile_amocrm_tags_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  request_id?: Maybe<Scalars['bigint']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "amocrm_tags" */
export enum Mobile_Amocrm_Tags_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Amocrm_Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Amocrm_Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Amocrm_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Amocrm_Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Amocrm_Tags_Var_Pop_Fields = {
  __typename?: 'mobile_amocrm_tags_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  request_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Amocrm_Tags_Var_Samp_Fields = {
  __typename?: 'mobile_amocrm_tags_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  request_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Amocrm_Tags_Variance_Fields = {
  __typename?: 'mobile_amocrm_tags_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  request_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "appointments" */
export type Mobile_Appointments = {
  __typename?: 'mobile_appointments';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  slot?: Maybe<Mobile_Slots>;
  slot_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "appointments" */
export type Mobile_Appointments_Aggregate = {
  __typename?: 'mobile_appointments_aggregate';
  aggregate?: Maybe<Mobile_Appointments_Aggregate_Fields>;
  nodes: Array<Mobile_Appointments>;
};

export type Mobile_Appointments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Appointments_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Appointments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Appointments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointments" */
export type Mobile_Appointments_Aggregate_Fields = {
  __typename?: 'mobile_appointments_aggregate_fields';
  avg?: Maybe<Mobile_Appointments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Appointments_Max_Fields>;
  min?: Maybe<Mobile_Appointments_Min_Fields>;
  stddev?: Maybe<Mobile_Appointments_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Appointments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Appointments_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Appointments_Sum_Fields>;
  var_pop?: Maybe<Mobile_Appointments_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Appointments_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Appointments_Variance_Fields>;
};


/** aggregate fields of "appointments" */
export type Mobile_Appointments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "appointments" */
export type Mobile_Appointments_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Appointments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Appointments_Max_Order_By>;
  min?: InputMaybe<Mobile_Appointments_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Appointments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Appointments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Appointments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Appointments_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Appointments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Appointments_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Appointments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "appointments" */
export type Mobile_Appointments_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Appointments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Appointments_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Appointments_Avg_Fields = {
  __typename?: 'mobile_appointments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  slot_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "appointments" */
export type Mobile_Appointments_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "appointments". All fields are combined with a logical 'AND'. */
export type Mobile_Appointments_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Appointments_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Appointments_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Appointments_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  slot?: InputMaybe<Mobile_Slots_Bool_Exp>;
  slot_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointments" */
export enum Mobile_Appointments_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppointmentsPkey = 'appointments_pkey'
}

/** input type for incrementing numeric columns in table "appointments" */
export type Mobile_Appointments_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  slot_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "appointments" */
export type Mobile_Appointments_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  slot?: InputMaybe<Mobile_Slots_Obj_Rel_Insert_Input>;
  slot_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Appointments_Max_Fields = {
  __typename?: 'mobile_appointments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  slot_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "appointments" */
export type Mobile_Appointments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Appointments_Min_Fields = {
  __typename?: 'mobile_appointments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  slot_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "appointments" */
export type Mobile_Appointments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointments" */
export type Mobile_Appointments_Mutation_Response = {
  __typename?: 'mobile_appointments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Appointments>;
};

/** on_conflict condition type for table "appointments" */
export type Mobile_Appointments_On_Conflict = {
  constraint: Mobile_Appointments_Constraint;
  update_columns?: Array<Mobile_Appointments_Update_Column>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};

/** Ordering options when selecting data from "appointments". */
export type Mobile_Appointments_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot?: InputMaybe<Mobile_Slots_Order_By>;
  slot_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointments */
export type Mobile_Appointments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "appointments" */
export enum Mobile_Appointments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SlotId = 'slot_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "appointments" */
export type Mobile_Appointments_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  slot_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Appointments_Stddev_Fields = {
  __typename?: 'mobile_appointments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  slot_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "appointments" */
export type Mobile_Appointments_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Appointments_Stddev_Pop_Fields = {
  __typename?: 'mobile_appointments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  slot_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "appointments" */
export type Mobile_Appointments_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Appointments_Stddev_Samp_Fields = {
  __typename?: 'mobile_appointments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  slot_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "appointments" */
export type Mobile_Appointments_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_appointments" */
export type Mobile_Appointments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Appointments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Appointments_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  slot_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Appointments_Sum_Fields = {
  __typename?: 'mobile_appointments_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  slot_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "appointments" */
export type Mobile_Appointments_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "appointments" */
export enum Mobile_Appointments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SlotId = 'slot_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Appointments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Appointments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Appointments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Appointments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Appointments_Var_Pop_Fields = {
  __typename?: 'mobile_appointments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  slot_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "appointments" */
export type Mobile_Appointments_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Appointments_Var_Samp_Fields = {
  __typename?: 'mobile_appointments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  slot_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "appointments" */
export type Mobile_Appointments_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Appointments_Variance_Fields = {
  __typename?: 'mobile_appointments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  slot_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "appointments" */
export type Mobile_Appointments_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  slot_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "auto_replies" */
export type Mobile_Auto_Replies = {
  __typename?: 'mobile_auto_replies';
  /** An array relationship */
  auto_reply_documents: Array<Mobile_Auto_Reply_Documents>;
  /** An aggregate relationship */
  auto_reply_documents_aggregate: Mobile_Auto_Reply_Documents_Aggregate;
  /** An array relationship */
  auto_reply_mediafiles: Array<Mobile_Auto_Reply_Mediafiles>;
  /** An aggregate relationship */
  auto_reply_mediafiles_aggregate: Mobile_Auto_Reply_Mediafiles_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  message: Scalars['String']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  service: Mobile_Services;
  service_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "auto_replies" */
export type Mobile_Auto_RepliesAuto_Reply_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


/** columns and relationships of "auto_replies" */
export type Mobile_Auto_RepliesAuto_Reply_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


/** columns and relationships of "auto_replies" */
export type Mobile_Auto_RepliesAuto_Reply_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "auto_replies" */
export type Mobile_Auto_RepliesAuto_Reply_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};

/** aggregated selection of "auto_replies" */
export type Mobile_Auto_Replies_Aggregate = {
  __typename?: 'mobile_auto_replies_aggregate';
  aggregate?: Maybe<Mobile_Auto_Replies_Aggregate_Fields>;
  nodes: Array<Mobile_Auto_Replies>;
};

export type Mobile_Auto_Replies_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Auto_Replies_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Auto_Replies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auto_replies" */
export type Mobile_Auto_Replies_Aggregate_Fields = {
  __typename?: 'mobile_auto_replies_aggregate_fields';
  avg?: Maybe<Mobile_Auto_Replies_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Auto_Replies_Max_Fields>;
  min?: Maybe<Mobile_Auto_Replies_Min_Fields>;
  stddev?: Maybe<Mobile_Auto_Replies_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Auto_Replies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Auto_Replies_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Auto_Replies_Sum_Fields>;
  var_pop?: Maybe<Mobile_Auto_Replies_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Auto_Replies_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Auto_Replies_Variance_Fields>;
};


/** aggregate fields of "auto_replies" */
export type Mobile_Auto_Replies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auto_replies" */
export type Mobile_Auto_Replies_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Auto_Replies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Auto_Replies_Max_Order_By>;
  min?: InputMaybe<Mobile_Auto_Replies_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Auto_Replies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Auto_Replies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Auto_Replies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Auto_Replies_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Auto_Replies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Auto_Replies_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Auto_Replies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auto_replies" */
export type Mobile_Auto_Replies_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Auto_Replies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Auto_Replies_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Auto_Replies_Avg_Fields = {
  __typename?: 'mobile_auto_replies_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auto_replies". All fields are combined with a logical 'AND'. */
export type Mobile_Auto_Replies_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Auto_Replies_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Auto_Replies_Bool_Exp>>;
  auto_reply_documents?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
  auto_reply_documents_aggregate?: InputMaybe<Mobile_Auto_Reply_Documents_Aggregate_Bool_Exp>;
  auto_reply_mediafiles?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
  auto_reply_mediafiles_aggregate?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "auto_replies" */
export enum Mobile_Auto_Replies_Constraint {
  /** unique or primary key constraint on columns "id" */
  AutoRepliesPkey = 'auto_replies_pkey'
}

/** input type for incrementing numeric columns in table "auto_replies" */
export type Mobile_Auto_Replies_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auto_replies" */
export type Mobile_Auto_Replies_Insert_Input = {
  auto_reply_documents?: InputMaybe<Mobile_Auto_Reply_Documents_Arr_Rel_Insert_Input>;
  auto_reply_mediafiles?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Auto_Replies_Max_Fields = {
  __typename?: 'mobile_auto_replies_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Auto_Replies_Min_Fields = {
  __typename?: 'mobile_auto_replies_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auto_replies" */
export type Mobile_Auto_Replies_Mutation_Response = {
  __typename?: 'mobile_auto_replies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Auto_Replies>;
};

/** input type for inserting object relation for remote table "auto_replies" */
export type Mobile_Auto_Replies_Obj_Rel_Insert_Input = {
  data: Mobile_Auto_Replies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Auto_Replies_On_Conflict>;
};

/** on_conflict condition type for table "auto_replies" */
export type Mobile_Auto_Replies_On_Conflict = {
  constraint: Mobile_Auto_Replies_Constraint;
  update_columns?: Array<Mobile_Auto_Replies_Update_Column>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};

/** Ordering options when selecting data from "auto_replies". */
export type Mobile_Auto_Replies_Order_By = {
  auto_reply_documents_aggregate?: InputMaybe<Mobile_Auto_Reply_Documents_Aggregate_Order_By>;
  auto_reply_mediafiles_aggregate?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auto_replies */
export type Mobile_Auto_Replies_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "auto_replies" */
export enum Mobile_Auto_Replies_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auto_replies" */
export type Mobile_Auto_Replies_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Auto_Replies_Stddev_Fields = {
  __typename?: 'mobile_auto_replies_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Auto_Replies_Stddev_Pop_Fields = {
  __typename?: 'mobile_auto_replies_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Auto_Replies_Stddev_Samp_Fields = {
  __typename?: 'mobile_auto_replies_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_auto_replies" */
export type Mobile_Auto_Replies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Auto_Replies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Auto_Replies_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Auto_Replies_Sum_Fields = {
  __typename?: 'mobile_auto_replies_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "auto_replies" */
export enum Mobile_Auto_Replies_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Auto_Replies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Auto_Replies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Auto_Replies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Auto_Replies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Auto_Replies_Var_Pop_Fields = {
  __typename?: 'mobile_auto_replies_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Auto_Replies_Var_Samp_Fields = {
  __typename?: 'mobile_auto_replies_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Auto_Replies_Variance_Fields = {
  __typename?: 'mobile_auto_replies_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auto_replies" */
export type Mobile_Auto_Replies_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents = {
  __typename?: 'mobile_auto_reply_documents';
  /** An object relationship */
  auto_reply?: Maybe<Mobile_Auto_Replies>;
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  pro_document_status?: Maybe<Mobile_Pro_Document_Statuses>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  problem?: Maybe<Mobile_Problems>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Aggregate = {
  __typename?: 'mobile_auto_reply_documents_aggregate';
  aggregate?: Maybe<Mobile_Auto_Reply_Documents_Aggregate_Fields>;
  nodes: Array<Mobile_Auto_Reply_Documents>;
};

export type Mobile_Auto_Reply_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Auto_Reply_Documents_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Auto_Reply_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Aggregate_Fields = {
  __typename?: 'mobile_auto_reply_documents_aggregate_fields';
  avg?: Maybe<Mobile_Auto_Reply_Documents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Auto_Reply_Documents_Max_Fields>;
  min?: Maybe<Mobile_Auto_Reply_Documents_Min_Fields>;
  stddev?: Maybe<Mobile_Auto_Reply_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Auto_Reply_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Auto_Reply_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Auto_Reply_Documents_Sum_Fields>;
  var_pop?: Maybe<Mobile_Auto_Reply_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Auto_Reply_Documents_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Auto_Reply_Documents_Variance_Fields>;
};


/** aggregate fields of "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Auto_Reply_Documents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Auto_Reply_Documents_Max_Order_By>;
  min?: InputMaybe<Mobile_Auto_Reply_Documents_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Auto_Reply_Documents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Auto_Reply_Documents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Auto_Reply_Documents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Auto_Reply_Documents_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Auto_Reply_Documents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Auto_Reply_Documents_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Auto_Reply_Documents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Auto_Reply_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Auto_Reply_Documents_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Auto_Reply_Documents_Avg_Fields = {
  __typename?: 'mobile_auto_reply_documents_avg_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Avg_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auto_reply_documents". All fields are combined with a logical 'AND'. */
export type Mobile_Auto_Reply_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Bool_Exp>>;
  auto_reply?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
  auto_reply_id?: InputMaybe<Bigint_Comparison_Exp>;
  comment_on_problem?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_path?: InputMaybe<String_Comparison_Exp>;
  document_size?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
  pro_document_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  problem?: InputMaybe<Mobile_Problems_Bool_Exp>;
  problem_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "auto_reply_documents" */
export enum Mobile_Auto_Reply_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  AutoReplyDocumentsPkey = 'auto_reply_documents_pkey'
}

/** input type for incrementing numeric columns in table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Inc_Input = {
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Insert_Input = {
  auto_reply?: InputMaybe<Mobile_Auto_Replies_Obj_Rel_Insert_Input>;
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Obj_Rel_Insert_Input>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem?: InputMaybe<Mobile_Problems_Obj_Rel_Insert_Input>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Auto_Reply_Documents_Max_Fields = {
  __typename?: 'mobile_auto_reply_documents_max_fields';
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Max_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Auto_Reply_Documents_Min_Fields = {
  __typename?: 'mobile_auto_reply_documents_min_fields';
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Min_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Mutation_Response = {
  __typename?: 'mobile_auto_reply_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Auto_Reply_Documents>;
};

/** on_conflict condition type for table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_On_Conflict = {
  constraint: Mobile_Auto_Reply_Documents_Constraint;
  update_columns?: Array<Mobile_Auto_Reply_Documents_Update_Column>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "auto_reply_documents". */
export type Mobile_Auto_Reply_Documents_Order_By = {
  auto_reply?: InputMaybe<Mobile_Auto_Replies_Order_By>;
  auto_reply_id?: InputMaybe<Order_By>;
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem?: InputMaybe<Mobile_Problems_Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auto_reply_documents */
export type Mobile_Auto_Reply_Documents_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "auto_reply_documents" */
export enum Mobile_Auto_Reply_Documents_Select_Column {
  /** column name */
  AutoReplyId = 'auto_reply_id',
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Set_Input = {
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Auto_Reply_Documents_Stddev_Fields = {
  __typename?: 'mobile_auto_reply_documents_stddev_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Stddev_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Auto_Reply_Documents_Stddev_Pop_Fields = {
  __typename?: 'mobile_auto_reply_documents_stddev_pop_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Stddev_Pop_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Auto_Reply_Documents_Stddev_Samp_Fields = {
  __typename?: 'mobile_auto_reply_documents_stddev_samp_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Stddev_Samp_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Auto_Reply_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Auto_Reply_Documents_Stream_Cursor_Value_Input = {
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Auto_Reply_Documents_Sum_Fields = {
  __typename?: 'mobile_auto_reply_documents_sum_fields';
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Sum_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "auto_reply_documents" */
export enum Mobile_Auto_Reply_Documents_Update_Column {
  /** column name */
  AutoReplyId = 'auto_reply_id',
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Auto_Reply_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Auto_Reply_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Auto_Reply_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Auto_Reply_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Auto_Reply_Documents_Var_Pop_Fields = {
  __typename?: 'mobile_auto_reply_documents_var_pop_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Var_Pop_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Auto_Reply_Documents_Var_Samp_Fields = {
  __typename?: 'mobile_auto_reply_documents_var_samp_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Var_Samp_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Auto_Reply_Documents_Variance_Fields = {
  __typename?: 'mobile_auto_reply_documents_variance_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auto_reply_documents" */
export type Mobile_Auto_Reply_Documents_Variance_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles = {
  __typename?: 'mobile_auto_reply_mediafiles';
  /** An object relationship */
  auto_reply?: Maybe<Mobile_Auto_Replies>;
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  pro_document_status?: Maybe<Mobile_Pro_Document_Statuses>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  problem?: Maybe<Mobile_Problems>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Aggregate = {
  __typename?: 'mobile_auto_reply_mediafiles_aggregate';
  aggregate?: Maybe<Mobile_Auto_Reply_Mediafiles_Aggregate_Fields>;
  nodes: Array<Mobile_Auto_Reply_Mediafiles>;
};

export type Mobile_Auto_Reply_Mediafiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Auto_Reply_Mediafiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Aggregate_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_aggregate_fields';
  avg?: Maybe<Mobile_Auto_Reply_Mediafiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Auto_Reply_Mediafiles_Max_Fields>;
  min?: Maybe<Mobile_Auto_Reply_Mediafiles_Min_Fields>;
  stddev?: Maybe<Mobile_Auto_Reply_Mediafiles_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Auto_Reply_Mediafiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Auto_Reply_Mediafiles_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Auto_Reply_Mediafiles_Sum_Fields>;
  var_pop?: Maybe<Mobile_Auto_Reply_Mediafiles_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Auto_Reply_Mediafiles_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Auto_Reply_Mediafiles_Variance_Fields>;
};


/** aggregate fields of "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Max_Order_By>;
  min?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Auto_Reply_Mediafiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Auto_Reply_Mediafiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Auto_Reply_Mediafiles_Avg_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_avg_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Avg_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auto_reply_mediafiles". All fields are combined with a logical 'AND'. */
export type Mobile_Auto_Reply_Mediafiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Bool_Exp>>;
  auto_reply?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
  auto_reply_id?: InputMaybe<Bigint_Comparison_Exp>;
  comment_on_problem?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mediafile_path?: InputMaybe<String_Comparison_Exp>;
  mediafile_size?: InputMaybe<Bigint_Comparison_Exp>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
  pro_document_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  problem?: InputMaybe<Mobile_Problems_Bool_Exp>;
  problem_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "auto_reply_mediafiles" */
export enum Mobile_Auto_Reply_Mediafiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  AutoReplyMediafilesPkey = 'auto_reply_mediafiles_pkey'
}

/** input type for incrementing numeric columns in table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Inc_Input = {
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Insert_Input = {
  auto_reply?: InputMaybe<Mobile_Auto_Replies_Obj_Rel_Insert_Input>;
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Obj_Rel_Insert_Input>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem?: InputMaybe<Mobile_Problems_Obj_Rel_Insert_Input>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Auto_Reply_Mediafiles_Max_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_max_fields';
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Max_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Auto_Reply_Mediafiles_Min_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_min_fields';
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Min_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Mutation_Response = {
  __typename?: 'mobile_auto_reply_mediafiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Auto_Reply_Mediafiles>;
};

/** on_conflict condition type for table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_On_Conflict = {
  constraint: Mobile_Auto_Reply_Mediafiles_Constraint;
  update_columns?: Array<Mobile_Auto_Reply_Mediafiles_Update_Column>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};

/** Ordering options when selecting data from "auto_reply_mediafiles". */
export type Mobile_Auto_Reply_Mediafiles_Order_By = {
  auto_reply?: InputMaybe<Mobile_Auto_Replies_Order_By>;
  auto_reply_id?: InputMaybe<Order_By>;
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem?: InputMaybe<Mobile_Problems_Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auto_reply_mediafiles */
export type Mobile_Auto_Reply_Mediafiles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "auto_reply_mediafiles" */
export enum Mobile_Auto_Reply_Mediafiles_Select_Column {
  /** column name */
  AutoReplyId = 'auto_reply_id',
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Set_Input = {
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Auto_Reply_Mediafiles_Stddev_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_stddev_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Stddev_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Auto_Reply_Mediafiles_Stddev_Pop_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_stddev_pop_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Stddev_Pop_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Auto_Reply_Mediafiles_Stddev_Samp_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_stddev_samp_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Stddev_Samp_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Auto_Reply_Mediafiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Auto_Reply_Mediafiles_Stream_Cursor_Value_Input = {
  auto_reply_id?: InputMaybe<Scalars['bigint']['input']>;
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Auto_Reply_Mediafiles_Sum_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_sum_fields';
  auto_reply_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_size?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Sum_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "auto_reply_mediafiles" */
export enum Mobile_Auto_Reply_Mediafiles_Update_Column {
  /** column name */
  AutoReplyId = 'auto_reply_id',
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Auto_Reply_Mediafiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Auto_Reply_Mediafiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Auto_Reply_Mediafiles_Var_Pop_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_var_pop_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Var_Pop_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Auto_Reply_Mediafiles_Var_Samp_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_var_samp_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Var_Samp_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Auto_Reply_Mediafiles_Variance_Fields = {
  __typename?: 'mobile_auto_reply_mediafiles_variance_fields';
  auto_reply_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auto_reply_mediafiles" */
export type Mobile_Auto_Reply_Mediafiles_Variance_Order_By = {
  auto_reply_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "bank_accounts" */
export type Mobile_Bank_Accounts = {
  __typename?: 'mobile_bank_accounts';
  account?: Maybe<Scalars['String']['output']>;
  bank_code?: Maybe<Scalars['String']['output']>;
  bank_correspondent_account?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  bik?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  pro: Mobile_Pros;
  pro_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "bank_accounts" */
export type Mobile_Bank_Accounts_Aggregate = {
  __typename?: 'mobile_bank_accounts_aggregate';
  aggregate?: Maybe<Mobile_Bank_Accounts_Aggregate_Fields>;
  nodes: Array<Mobile_Bank_Accounts>;
};

export type Mobile_Bank_Accounts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Bank_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Bank_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bank_accounts" */
export type Mobile_Bank_Accounts_Aggregate_Fields = {
  __typename?: 'mobile_bank_accounts_aggregate_fields';
  avg?: Maybe<Mobile_Bank_Accounts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Bank_Accounts_Max_Fields>;
  min?: Maybe<Mobile_Bank_Accounts_Min_Fields>;
  stddev?: Maybe<Mobile_Bank_Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Bank_Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Bank_Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Bank_Accounts_Sum_Fields>;
  var_pop?: Maybe<Mobile_Bank_Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Bank_Accounts_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Bank_Accounts_Variance_Fields>;
};


/** aggregate fields of "bank_accounts" */
export type Mobile_Bank_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "bank_accounts" */
export type Mobile_Bank_Accounts_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Bank_Accounts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Bank_Accounts_Max_Order_By>;
  min?: InputMaybe<Mobile_Bank_Accounts_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Bank_Accounts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Bank_Accounts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Bank_Accounts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Bank_Accounts_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Bank_Accounts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Bank_Accounts_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Bank_Accounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bank_accounts" */
export type Mobile_Bank_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Bank_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Bank_Accounts_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Bank_Accounts_Avg_Fields = {
  __typename?: 'mobile_bank_accounts_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bank_accounts". All fields are combined with a logical 'AND'. */
export type Mobile_Bank_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Bank_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Bank_Accounts_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  bank_code?: InputMaybe<String_Comparison_Exp>;
  bank_correspondent_account?: InputMaybe<String_Comparison_Exp>;
  bank_name?: InputMaybe<String_Comparison_Exp>;
  bik?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pro?: InputMaybe<Mobile_Pros_Bool_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bank_accounts" */
export enum Mobile_Bank_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  BankAccountsPkey = 'bank_accounts_pkey'
}

/** input type for incrementing numeric columns in table "bank_accounts" */
export type Mobile_Bank_Accounts_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "bank_accounts" */
export type Mobile_Bank_Accounts_Insert_Input = {
  account?: InputMaybe<Scalars['String']['input']>;
  bank_code?: InputMaybe<Scalars['String']['input']>;
  bank_correspondent_account?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  bik?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pro?: InputMaybe<Mobile_Pros_Obj_Rel_Insert_Input>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Bank_Accounts_Max_Fields = {
  __typename?: 'mobile_bank_accounts_max_fields';
  account?: Maybe<Scalars['String']['output']>;
  bank_code?: Maybe<Scalars['String']['output']>;
  bank_correspondent_account?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  bik?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Max_Order_By = {
  account?: InputMaybe<Order_By>;
  bank_code?: InputMaybe<Order_By>;
  bank_correspondent_account?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bik?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Bank_Accounts_Min_Fields = {
  __typename?: 'mobile_bank_accounts_min_fields';
  account?: Maybe<Scalars['String']['output']>;
  bank_code?: Maybe<Scalars['String']['output']>;
  bank_correspondent_account?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  bik?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Min_Order_By = {
  account?: InputMaybe<Order_By>;
  bank_code?: InputMaybe<Order_By>;
  bank_correspondent_account?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bik?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bank_accounts" */
export type Mobile_Bank_Accounts_Mutation_Response = {
  __typename?: 'mobile_bank_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Bank_Accounts>;
};

/** on_conflict condition type for table "bank_accounts" */
export type Mobile_Bank_Accounts_On_Conflict = {
  constraint: Mobile_Bank_Accounts_Constraint;
  update_columns?: Array<Mobile_Bank_Accounts_Update_Column>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "bank_accounts". */
export type Mobile_Bank_Accounts_Order_By = {
  account?: InputMaybe<Order_By>;
  bank_code?: InputMaybe<Order_By>;
  bank_correspondent_account?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bik?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pro?: InputMaybe<Mobile_Pros_Order_By>;
  pro_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bank_accounts */
export type Mobile_Bank_Accounts_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "bank_accounts" */
export enum Mobile_Bank_Accounts_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  BankCode = 'bank_code',
  /** column name */
  BankCorrespondentAccount = 'bank_correspondent_account',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  Bik = 'bik',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bank_accounts" */
export type Mobile_Bank_Accounts_Set_Input = {
  account?: InputMaybe<Scalars['String']['input']>;
  bank_code?: InputMaybe<Scalars['String']['input']>;
  bank_correspondent_account?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  bik?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Bank_Accounts_Stddev_Fields = {
  __typename?: 'mobile_bank_accounts_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Bank_Accounts_Stddev_Pop_Fields = {
  __typename?: 'mobile_bank_accounts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Bank_Accounts_Stddev_Samp_Fields = {
  __typename?: 'mobile_bank_accounts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_bank_accounts" */
export type Mobile_Bank_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Bank_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Bank_Accounts_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']['input']>;
  bank_code?: InputMaybe<Scalars['String']['input']>;
  bank_correspondent_account?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  bik?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Bank_Accounts_Sum_Fields = {
  __typename?: 'mobile_bank_accounts_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** update columns of table "bank_accounts" */
export enum Mobile_Bank_Accounts_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  BankCode = 'bank_code',
  /** column name */
  BankCorrespondentAccount = 'bank_correspondent_account',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  Bik = 'bik',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Bank_Accounts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Bank_Accounts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Bank_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Bank_Accounts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Bank_Accounts_Var_Pop_Fields = {
  __typename?: 'mobile_bank_accounts_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Bank_Accounts_Var_Samp_Fields = {
  __typename?: 'mobile_bank_accounts_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Bank_Accounts_Variance_Fields = {
  __typename?: 'mobile_bank_accounts_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "bank_accounts" */
export type Mobile_Bank_Accounts_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "blocked_sites" */
export type Mobile_Blocked_Sites = {
  __typename?: 'mobile_blocked_sites';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  record_date?: Maybe<Scalars['date']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "blocked_sites" */
export type Mobile_Blocked_Sites_Aggregate = {
  __typename?: 'mobile_blocked_sites_aggregate';
  aggregate?: Maybe<Mobile_Blocked_Sites_Aggregate_Fields>;
  nodes: Array<Mobile_Blocked_Sites>;
};

/** aggregate fields of "blocked_sites" */
export type Mobile_Blocked_Sites_Aggregate_Fields = {
  __typename?: 'mobile_blocked_sites_aggregate_fields';
  avg?: Maybe<Mobile_Blocked_Sites_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Blocked_Sites_Max_Fields>;
  min?: Maybe<Mobile_Blocked_Sites_Min_Fields>;
  stddev?: Maybe<Mobile_Blocked_Sites_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Blocked_Sites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Blocked_Sites_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Blocked_Sites_Sum_Fields>;
  var_pop?: Maybe<Mobile_Blocked_Sites_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Blocked_Sites_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Blocked_Sites_Variance_Fields>;
};


/** aggregate fields of "blocked_sites" */
export type Mobile_Blocked_Sites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Blocked_Sites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Blocked_Sites_Avg_Fields = {
  __typename?: 'mobile_blocked_sites_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "blocked_sites". All fields are combined with a logical 'AND'. */
export type Mobile_Blocked_Sites_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Blocked_Sites_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Blocked_Sites_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Blocked_Sites_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  record_date?: InputMaybe<Date_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "blocked_sites" */
export enum Mobile_Blocked_Sites_Constraint {
  /** unique or primary key constraint on columns "id" */
  BlockedSitesPkey = 'blocked_sites_pkey'
}

/** input type for incrementing numeric columns in table "blocked_sites" */
export type Mobile_Blocked_Sites_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "blocked_sites" */
export type Mobile_Blocked_Sites_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  record_date?: InputMaybe<Scalars['date']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Blocked_Sites_Max_Fields = {
  __typename?: 'mobile_blocked_sites_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  record_date?: Maybe<Scalars['date']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Blocked_Sites_Min_Fields = {
  __typename?: 'mobile_blocked_sites_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  record_date?: Maybe<Scalars['date']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "blocked_sites" */
export type Mobile_Blocked_Sites_Mutation_Response = {
  __typename?: 'mobile_blocked_sites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Blocked_Sites>;
};

/** on_conflict condition type for table "blocked_sites" */
export type Mobile_Blocked_Sites_On_Conflict = {
  constraint: Mobile_Blocked_Sites_Constraint;
  update_columns?: Array<Mobile_Blocked_Sites_Update_Column>;
  where?: InputMaybe<Mobile_Blocked_Sites_Bool_Exp>;
};

/** Ordering options when selecting data from "blocked_sites". */
export type Mobile_Blocked_Sites_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  record_date?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blocked_sites */
export type Mobile_Blocked_Sites_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "blocked_sites" */
export enum Mobile_Blocked_Sites_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecordDate = 'record_date',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "blocked_sites" */
export type Mobile_Blocked_Sites_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  record_date?: InputMaybe<Scalars['date']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Blocked_Sites_Stddev_Fields = {
  __typename?: 'mobile_blocked_sites_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Blocked_Sites_Stddev_Pop_Fields = {
  __typename?: 'mobile_blocked_sites_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Blocked_Sites_Stddev_Samp_Fields = {
  __typename?: 'mobile_blocked_sites_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_blocked_sites" */
export type Mobile_Blocked_Sites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Blocked_Sites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Blocked_Sites_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  record_date?: InputMaybe<Scalars['date']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Blocked_Sites_Sum_Fields = {
  __typename?: 'mobile_blocked_sites_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "blocked_sites" */
export enum Mobile_Blocked_Sites_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecordDate = 'record_date',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Mobile_Blocked_Sites_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Blocked_Sites_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Blocked_Sites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Blocked_Sites_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Blocked_Sites_Var_Pop_Fields = {
  __typename?: 'mobile_blocked_sites_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Blocked_Sites_Var_Samp_Fields = {
  __typename?: 'mobile_blocked_sites_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Blocked_Sites_Variance_Fields = {
  __typename?: 'mobile_blocked_sites_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "blockeds" */
export type Mobile_Blockeds = {
  __typename?: 'mobile_blockeds';
  id: Scalars['Int']['output'];
  user_id: Scalars['bigint']['output'];
  user_id_blockeds?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "blockeds" */
export type Mobile_Blockeds_Aggregate = {
  __typename?: 'mobile_blockeds_aggregate';
  aggregate?: Maybe<Mobile_Blockeds_Aggregate_Fields>;
  nodes: Array<Mobile_Blockeds>;
};

/** aggregate fields of "blockeds" */
export type Mobile_Blockeds_Aggregate_Fields = {
  __typename?: 'mobile_blockeds_aggregate_fields';
  avg?: Maybe<Mobile_Blockeds_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Blockeds_Max_Fields>;
  min?: Maybe<Mobile_Blockeds_Min_Fields>;
  stddev?: Maybe<Mobile_Blockeds_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Blockeds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Blockeds_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Blockeds_Sum_Fields>;
  var_pop?: Maybe<Mobile_Blockeds_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Blockeds_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Blockeds_Variance_Fields>;
};


/** aggregate fields of "blockeds" */
export type Mobile_Blockeds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Blockeds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Blockeds_Avg_Fields = {
  __typename?: 'mobile_blockeds_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_blockeds?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "blockeds". All fields are combined with a logical 'AND'. */
export type Mobile_Blockeds_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Blockeds_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Blockeds_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Blockeds_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id_blockeds?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "blockeds" */
export enum Mobile_Blockeds_Constraint {
  /** unique or primary key constraint on columns "id" */
  BlockedsPkey = 'blockeds_pkey'
}

/** input type for incrementing numeric columns in table "blockeds" */
export type Mobile_Blockeds_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_blockeds?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "blockeds" */
export type Mobile_Blockeds_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_blockeds?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Blockeds_Max_Fields = {
  __typename?: 'mobile_blockeds_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  user_id_blockeds?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Blockeds_Min_Fields = {
  __typename?: 'mobile_blockeds_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  user_id_blockeds?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "blockeds" */
export type Mobile_Blockeds_Mutation_Response = {
  __typename?: 'mobile_blockeds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Blockeds>;
};

/** on_conflict condition type for table "blockeds" */
export type Mobile_Blockeds_On_Conflict = {
  constraint: Mobile_Blockeds_Constraint;
  update_columns?: Array<Mobile_Blockeds_Update_Column>;
  where?: InputMaybe<Mobile_Blockeds_Bool_Exp>;
};

/** Ordering options when selecting data from "blockeds". */
export type Mobile_Blockeds_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_blockeds?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blockeds */
export type Mobile_Blockeds_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "blockeds" */
export enum Mobile_Blockeds_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIdBlockeds = 'user_id_blockeds'
}

/** input type for updating data in table "blockeds" */
export type Mobile_Blockeds_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_blockeds?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Blockeds_Stddev_Fields = {
  __typename?: 'mobile_blockeds_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_blockeds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Blockeds_Stddev_Pop_Fields = {
  __typename?: 'mobile_blockeds_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_blockeds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Blockeds_Stddev_Samp_Fields = {
  __typename?: 'mobile_blockeds_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_blockeds?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_blockeds" */
export type Mobile_Blockeds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Blockeds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Blockeds_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_blockeds?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Blockeds_Sum_Fields = {
  __typename?: 'mobile_blockeds_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  user_id_blockeds?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "blockeds" */
export enum Mobile_Blockeds_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIdBlockeds = 'user_id_blockeds'
}

export type Mobile_Blockeds_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Blockeds_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Blockeds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Blockeds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Blockeds_Var_Pop_Fields = {
  __typename?: 'mobile_blockeds_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_blockeds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Blockeds_Var_Samp_Fields = {
  __typename?: 'mobile_blockeds_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_blockeds?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Blockeds_Variance_Fields = {
  __typename?: 'mobile_blockeds_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_blockeds?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "calorie_norms" */
export type Mobile_Calorie_Norms = {
  __typename?: 'mobile_calorie_norms';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm: Scalars['float8']['output'];
  /** An object relationship */
  profile: Mobile_Profiles;
  profile_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "calorie_norms" */
export type Mobile_Calorie_Norms_Aggregate = {
  __typename?: 'mobile_calorie_norms_aggregate';
  aggregate?: Maybe<Mobile_Calorie_Norms_Aggregate_Fields>;
  nodes: Array<Mobile_Calorie_Norms>;
};

/** aggregate fields of "calorie_norms" */
export type Mobile_Calorie_Norms_Aggregate_Fields = {
  __typename?: 'mobile_calorie_norms_aggregate_fields';
  avg?: Maybe<Mobile_Calorie_Norms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Calorie_Norms_Max_Fields>;
  min?: Maybe<Mobile_Calorie_Norms_Min_Fields>;
  stddev?: Maybe<Mobile_Calorie_Norms_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Calorie_Norms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Calorie_Norms_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Calorie_Norms_Sum_Fields>;
  var_pop?: Maybe<Mobile_Calorie_Norms_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Calorie_Norms_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Calorie_Norms_Variance_Fields>;
};


/** aggregate fields of "calorie_norms" */
export type Mobile_Calorie_Norms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Calorie_Norms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Calorie_Norms_Avg_Fields = {
  __typename?: 'mobile_calorie_norms_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "calorie_norms". All fields are combined with a logical 'AND'. */
export type Mobile_Calorie_Norms_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Calorie_Norms_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Calorie_Norms_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Calorie_Norms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mentor_norm?: InputMaybe<Float8_Comparison_Exp>;
  mentor_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  norm?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "calorie_norms" */
export enum Mobile_Calorie_Norms_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalorieNormsPkey = 'calorie_norms_pkey'
}

/** input type for incrementing numeric columns in table "calorie_norms" */
export type Mobile_Calorie_Norms_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "calorie_norms" */
export type Mobile_Calorie_Norms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Calorie_Norms_Max_Fields = {
  __typename?: 'mobile_calorie_norms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Calorie_Norms_Min_Fields = {
  __typename?: 'mobile_calorie_norms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "calorie_norms" */
export type Mobile_Calorie_Norms_Mutation_Response = {
  __typename?: 'mobile_calorie_norms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Calorie_Norms>;
};

/** on_conflict condition type for table "calorie_norms" */
export type Mobile_Calorie_Norms_On_Conflict = {
  constraint: Mobile_Calorie_Norms_Constraint;
  update_columns?: Array<Mobile_Calorie_Norms_Update_Column>;
  where?: InputMaybe<Mobile_Calorie_Norms_Bool_Exp>;
};

/** Ordering options when selecting data from "calorie_norms". */
export type Mobile_Calorie_Norms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentor_norm?: InputMaybe<Order_By>;
  mentor_user_id?: InputMaybe<Order_By>;
  norm?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calorie_norms */
export type Mobile_Calorie_Norms_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "calorie_norms" */
export enum Mobile_Calorie_Norms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "calorie_norms" */
export type Mobile_Calorie_Norms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Calorie_Norms_Stddev_Fields = {
  __typename?: 'mobile_calorie_norms_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Calorie_Norms_Stddev_Pop_Fields = {
  __typename?: 'mobile_calorie_norms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Calorie_Norms_Stddev_Samp_Fields = {
  __typename?: 'mobile_calorie_norms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_calorie_norms" */
export type Mobile_Calorie_Norms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Calorie_Norms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Calorie_Norms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Calorie_Norms_Sum_Fields = {
  __typename?: 'mobile_calorie_norms_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "calorie_norms" */
export enum Mobile_Calorie_Norms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Calorie_Norms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Calorie_Norms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Calorie_Norms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Calorie_Norms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Calorie_Norms_Var_Pop_Fields = {
  __typename?: 'mobile_calorie_norms_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Calorie_Norms_Var_Samp_Fields = {
  __typename?: 'mobile_calorie_norms_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Calorie_Norms_Variance_Fields = {
  __typename?: 'mobile_calorie_norms_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "calorie_nows" */
export type Mobile_Calorie_Nows = {
  __typename?: 'mobile_calorie_nows';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "calorie_nows" */
export type Mobile_Calorie_Nows_Aggregate = {
  __typename?: 'mobile_calorie_nows_aggregate';
  aggregate?: Maybe<Mobile_Calorie_Nows_Aggregate_Fields>;
  nodes: Array<Mobile_Calorie_Nows>;
};

/** aggregate fields of "calorie_nows" */
export type Mobile_Calorie_Nows_Aggregate_Fields = {
  __typename?: 'mobile_calorie_nows_aggregate_fields';
  avg?: Maybe<Mobile_Calorie_Nows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Calorie_Nows_Max_Fields>;
  min?: Maybe<Mobile_Calorie_Nows_Min_Fields>;
  stddev?: Maybe<Mobile_Calorie_Nows_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Calorie_Nows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Calorie_Nows_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Calorie_Nows_Sum_Fields>;
  var_pop?: Maybe<Mobile_Calorie_Nows_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Calorie_Nows_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Calorie_Nows_Variance_Fields>;
};


/** aggregate fields of "calorie_nows" */
export type Mobile_Calorie_Nows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Calorie_Nows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Calorie_Nows_Avg_Fields = {
  __typename?: 'mobile_calorie_nows_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "calorie_nows". All fields are combined with a logical 'AND'. */
export type Mobile_Calorie_Nows_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Calorie_Nows_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Calorie_Nows_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Calorie_Nows_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "calorie_nows" */
export enum Mobile_Calorie_Nows_Constraint {
  /** unique or primary key constraint on columns "id" */
  CalorieNowsPkey = 'calorie_nows_pkey'
}

/** input type for incrementing numeric columns in table "calorie_nows" */
export type Mobile_Calorie_Nows_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "calorie_nows" */
export type Mobile_Calorie_Nows_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Calorie_Nows_Max_Fields = {
  __typename?: 'mobile_calorie_nows_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mobile_Calorie_Nows_Min_Fields = {
  __typename?: 'mobile_calorie_nows_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "calorie_nows" */
export type Mobile_Calorie_Nows_Mutation_Response = {
  __typename?: 'mobile_calorie_nows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Calorie_Nows>;
};

/** on_conflict condition type for table "calorie_nows" */
export type Mobile_Calorie_Nows_On_Conflict = {
  constraint: Mobile_Calorie_Nows_Constraint;
  update_columns?: Array<Mobile_Calorie_Nows_Update_Column>;
  where?: InputMaybe<Mobile_Calorie_Nows_Bool_Exp>;
};

/** Ordering options when selecting data from "calorie_nows". */
export type Mobile_Calorie_Nows_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calorie_nows */
export type Mobile_Calorie_Nows_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "calorie_nows" */
export enum Mobile_Calorie_Nows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "calorie_nows" */
export type Mobile_Calorie_Nows_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Calorie_Nows_Stddev_Fields = {
  __typename?: 'mobile_calorie_nows_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Calorie_Nows_Stddev_Pop_Fields = {
  __typename?: 'mobile_calorie_nows_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Calorie_Nows_Stddev_Samp_Fields = {
  __typename?: 'mobile_calorie_nows_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_calorie_nows" */
export type Mobile_Calorie_Nows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Calorie_Nows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Calorie_Nows_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Calorie_Nows_Sum_Fields = {
  __typename?: 'mobile_calorie_nows_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "calorie_nows" */
export enum Mobile_Calorie_Nows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Calorie_Nows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Calorie_Nows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Calorie_Nows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Calorie_Nows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Calorie_Nows_Var_Pop_Fields = {
  __typename?: 'mobile_calorie_nows_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Calorie_Nows_Var_Samp_Fields = {
  __typename?: 'mobile_calorie_nows_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Calorie_Nows_Variance_Fields = {
  __typename?: 'mobile_calorie_nows_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "calories" */
export type Mobile_Calories = {
  __typename?: 'mobile_calories';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "calories" */
export type Mobile_Calories_Aggregate = {
  __typename?: 'mobile_calories_aggregate';
  aggregate?: Maybe<Mobile_Calories_Aggregate_Fields>;
  nodes: Array<Mobile_Calories>;
};

/** aggregate fields of "calories" */
export type Mobile_Calories_Aggregate_Fields = {
  __typename?: 'mobile_calories_aggregate_fields';
  avg?: Maybe<Mobile_Calories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Calories_Max_Fields>;
  min?: Maybe<Mobile_Calories_Min_Fields>;
  stddev?: Maybe<Mobile_Calories_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Calories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Calories_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Calories_Sum_Fields>;
  var_pop?: Maybe<Mobile_Calories_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Calories_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Calories_Variance_Fields>;
};


/** aggregate fields of "calories" */
export type Mobile_Calories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Calories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Calories_Avg_Fields = {
  __typename?: 'mobile_calories_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "calories". All fields are combined with a logical 'AND'. */
export type Mobile_Calories_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Calories_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Calories_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Calories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "calories" */
export enum Mobile_Calories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaloriesPkey = 'calories_pkey'
}

/** input type for incrementing numeric columns in table "calories" */
export type Mobile_Calories_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "calories" */
export type Mobile_Calories_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Calories_Max_Fields = {
  __typename?: 'mobile_calories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mobile_Calories_Min_Fields = {
  __typename?: 'mobile_calories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "calories" */
export type Mobile_Calories_Mutation_Response = {
  __typename?: 'mobile_calories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Calories>;
};

/** on_conflict condition type for table "calories" */
export type Mobile_Calories_On_Conflict = {
  constraint: Mobile_Calories_Constraint;
  update_columns?: Array<Mobile_Calories_Update_Column>;
  where?: InputMaybe<Mobile_Calories_Bool_Exp>;
};

/** Ordering options when selecting data from "calories". */
export type Mobile_Calories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: calories */
export type Mobile_Calories_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "calories" */
export enum Mobile_Calories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "calories" */
export type Mobile_Calories_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Calories_Stddev_Fields = {
  __typename?: 'mobile_calories_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Calories_Stddev_Pop_Fields = {
  __typename?: 'mobile_calories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Calories_Stddev_Samp_Fields = {
  __typename?: 'mobile_calories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_calories" */
export type Mobile_Calories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Calories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Calories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Calories_Sum_Fields = {
  __typename?: 'mobile_calories_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "calories" */
export enum Mobile_Calories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Calories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Calories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Calories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Calories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Calories_Var_Pop_Fields = {
  __typename?: 'mobile_calories_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Calories_Var_Samp_Fields = {
  __typename?: 'mobile_calories_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Calories_Variance_Fields = {
  __typename?: 'mobile_calories_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "categories" */
export type Mobile_Categories = {
  __typename?: 'mobile_categories';
  /** An array relationship */
  category_names: Array<Mobile_Category_Names>;
  /** An aggregate relationship */
  category_names_aggregate: Mobile_Category_Names_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  orgs: Array<Mobile_Orgs>;
  /** An aggregate relationship */
  orgs_aggregate: Mobile_Orgs_Aggregate;
  /** An array relationship */
  professions: Array<Mobile_Professions>;
  /** An aggregate relationship */
  professions_aggregate: Mobile_Professions_Aggregate;
  /** An array relationship */
  service_types: Array<Mobile_Service_Types>;
  /** An aggregate relationship */
  service_types_aggregate: Mobile_Service_Types_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesCategory_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesCategory_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesOrgsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesOrgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesProfessionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesProfessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesService_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Types_Order_By>>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type Mobile_CategoriesService_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Types_Order_By>>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Mobile_Categories_Aggregate = {
  __typename?: 'mobile_categories_aggregate';
  aggregate?: Maybe<Mobile_Categories_Aggregate_Fields>;
  nodes: Array<Mobile_Categories>;
};

/** aggregate fields of "categories" */
export type Mobile_Categories_Aggregate_Fields = {
  __typename?: 'mobile_categories_aggregate_fields';
  avg?: Maybe<Mobile_Categories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Categories_Max_Fields>;
  min?: Maybe<Mobile_Categories_Min_Fields>;
  stddev?: Maybe<Mobile_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Categories_Sum_Fields>;
  var_pop?: Maybe<Mobile_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Categories_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Categories_Variance_Fields>;
};


/** aggregate fields of "categories" */
export type Mobile_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Categories_Avg_Fields = {
  __typename?: 'mobile_categories_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Mobile_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Categories_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Categories_Bool_Exp>>;
  category_names?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
  category_names_aggregate?: InputMaybe<Mobile_Category_Names_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  orgs?: InputMaybe<Mobile_Orgs_Bool_Exp>;
  orgs_aggregate?: InputMaybe<Mobile_Orgs_Aggregate_Bool_Exp>;
  professions?: InputMaybe<Mobile_Professions_Bool_Exp>;
  professions_aggregate?: InputMaybe<Mobile_Professions_Aggregate_Bool_Exp>;
  service_types?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
  service_types_aggregate?: InputMaybe<Mobile_Service_Types_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Mobile_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey'
}

/** input type for incrementing numeric columns in table "categories" */
export type Mobile_Categories_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "categories" */
export type Mobile_Categories_Insert_Input = {
  category_names?: InputMaybe<Mobile_Category_Names_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  orgs?: InputMaybe<Mobile_Orgs_Arr_Rel_Insert_Input>;
  professions?: InputMaybe<Mobile_Professions_Arr_Rel_Insert_Input>;
  service_types?: InputMaybe<Mobile_Service_Types_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Categories_Max_Fields = {
  __typename?: 'mobile_categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Categories_Min_Fields = {
  __typename?: 'mobile_categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "categories" */
export type Mobile_Categories_Mutation_Response = {
  __typename?: 'mobile_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Mobile_Categories_Obj_Rel_Insert_Input = {
  data: Mobile_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Mobile_Categories_On_Conflict = {
  constraint: Mobile_Categories_Constraint;
  update_columns?: Array<Mobile_Categories_Update_Column>;
  where?: InputMaybe<Mobile_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Mobile_Categories_Order_By = {
  category_names_aggregate?: InputMaybe<Mobile_Category_Names_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orgs_aggregate?: InputMaybe<Mobile_Orgs_Aggregate_Order_By>;
  professions_aggregate?: InputMaybe<Mobile_Professions_Aggregate_Order_By>;
  service_types_aggregate?: InputMaybe<Mobile_Service_Types_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: categories */
export type Mobile_Categories_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "categories" */
export enum Mobile_Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "categories" */
export type Mobile_Categories_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Categories_Stddev_Fields = {
  __typename?: 'mobile_categories_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Categories_Stddev_Pop_Fields = {
  __typename?: 'mobile_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Categories_Stddev_Samp_Fields = {
  __typename?: 'mobile_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_categories" */
export type Mobile_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Categories_Sum_Fields = {
  __typename?: 'mobile_categories_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "categories" */
export enum Mobile_Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Categories_Var_Pop_Fields = {
  __typename?: 'mobile_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Categories_Var_Samp_Fields = {
  __typename?: 'mobile_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Categories_Variance_Fields = {
  __typename?: 'mobile_categories_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "category_names" */
export type Mobile_Category_Names = {
  __typename?: 'mobile_category_names';
  /** An object relationship */
  category: Mobile_Categories;
  category_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "category_names" */
export type Mobile_Category_Names_Aggregate = {
  __typename?: 'mobile_category_names_aggregate';
  aggregate?: Maybe<Mobile_Category_Names_Aggregate_Fields>;
  nodes: Array<Mobile_Category_Names>;
};

export type Mobile_Category_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Category_Names_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Category_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "category_names" */
export type Mobile_Category_Names_Aggregate_Fields = {
  __typename?: 'mobile_category_names_aggregate_fields';
  avg?: Maybe<Mobile_Category_Names_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Category_Names_Max_Fields>;
  min?: Maybe<Mobile_Category_Names_Min_Fields>;
  stddev?: Maybe<Mobile_Category_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Category_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Category_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Category_Names_Sum_Fields>;
  var_pop?: Maybe<Mobile_Category_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Category_Names_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Category_Names_Variance_Fields>;
};


/** aggregate fields of "category_names" */
export type Mobile_Category_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "category_names" */
export type Mobile_Category_Names_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Category_Names_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Category_Names_Max_Order_By>;
  min?: InputMaybe<Mobile_Category_Names_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Category_Names_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Category_Names_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Category_Names_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Category_Names_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Category_Names_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Category_Names_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Category_Names_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "category_names" */
export type Mobile_Category_Names_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Category_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Category_Names_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Category_Names_Avg_Fields = {
  __typename?: 'mobile_category_names_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "category_names" */
export type Mobile_Category_Names_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "category_names". All fields are combined with a logical 'AND'. */
export type Mobile_Category_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Category_Names_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Category_Names_Bool_Exp>>;
  category?: InputMaybe<Mobile_Categories_Bool_Exp>;
  category_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_names" */
export enum Mobile_Category_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  CategoryNamesPkey = 'category_names_pkey'
}

/** input type for incrementing numeric columns in table "category_names" */
export type Mobile_Category_Names_Inc_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "category_names" */
export type Mobile_Category_Names_Insert_Input = {
  category?: InputMaybe<Mobile_Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Category_Names_Max_Fields = {
  __typename?: 'mobile_category_names_max_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "category_names" */
export type Mobile_Category_Names_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Category_Names_Min_Fields = {
  __typename?: 'mobile_category_names_min_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "category_names" */
export type Mobile_Category_Names_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "category_names" */
export type Mobile_Category_Names_Mutation_Response = {
  __typename?: 'mobile_category_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Category_Names>;
};

/** on_conflict condition type for table "category_names" */
export type Mobile_Category_Names_On_Conflict = {
  constraint: Mobile_Category_Names_Constraint;
  update_columns?: Array<Mobile_Category_Names_Update_Column>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "category_names". */
export type Mobile_Category_Names_Order_By = {
  category?: InputMaybe<Mobile_Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: category_names */
export type Mobile_Category_Names_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "category_names" */
export enum Mobile_Category_Names_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "category_names" */
export type Mobile_Category_Names_Set_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Category_Names_Stddev_Fields = {
  __typename?: 'mobile_category_names_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "category_names" */
export type Mobile_Category_Names_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Category_Names_Stddev_Pop_Fields = {
  __typename?: 'mobile_category_names_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "category_names" */
export type Mobile_Category_Names_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Category_Names_Stddev_Samp_Fields = {
  __typename?: 'mobile_category_names_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "category_names" */
export type Mobile_Category_Names_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_category_names" */
export type Mobile_Category_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Category_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Category_Names_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Category_Names_Sum_Fields = {
  __typename?: 'mobile_category_names_sum_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "category_names" */
export type Mobile_Category_Names_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "category_names" */
export enum Mobile_Category_Names_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Category_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Category_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Category_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Category_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Category_Names_Var_Pop_Fields = {
  __typename?: 'mobile_category_names_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "category_names" */
export type Mobile_Category_Names_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Category_Names_Var_Samp_Fields = {
  __typename?: 'mobile_category_names_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "category_names" */
export type Mobile_Category_Names_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Category_Names_Variance_Fields = {
  __typename?: 'mobile_category_names_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "category_names" */
export type Mobile_Category_Names_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "chat_black_lists" */
export type Mobile_Chat_Black_Lists = {
  __typename?: 'mobile_chat_black_lists';
  block_profile_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "chat_black_lists" */
export type Mobile_Chat_Black_Lists_Aggregate = {
  __typename?: 'mobile_chat_black_lists_aggregate';
  aggregate?: Maybe<Mobile_Chat_Black_Lists_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Black_Lists>;
};

/** aggregate fields of "chat_black_lists" */
export type Mobile_Chat_Black_Lists_Aggregate_Fields = {
  __typename?: 'mobile_chat_black_lists_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Black_Lists_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Black_Lists_Max_Fields>;
  min?: Maybe<Mobile_Chat_Black_Lists_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Black_Lists_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Black_Lists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Black_Lists_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Black_Lists_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Black_Lists_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Black_Lists_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Black_Lists_Variance_Fields>;
};


/** aggregate fields of "chat_black_lists" */
export type Mobile_Chat_Black_Lists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Black_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Black_Lists_Avg_Fields = {
  __typename?: 'mobile_chat_black_lists_avg_fields';
  block_profile_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "chat_black_lists". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Black_Lists_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Black_Lists_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Black_Lists_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Black_Lists_Bool_Exp>>;
  block_profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_black_lists" */
export enum Mobile_Chat_Black_Lists_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatBlackListsPkey = 'chat_black_lists_pkey'
}

/** input type for incrementing numeric columns in table "chat_black_lists" */
export type Mobile_Chat_Black_Lists_Inc_Input = {
  block_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_black_lists" */
export type Mobile_Chat_Black_Lists_Insert_Input = {
  block_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Black_Lists_Max_Fields = {
  __typename?: 'mobile_chat_black_lists_max_fields';
  block_profile_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Chat_Black_Lists_Min_Fields = {
  __typename?: 'mobile_chat_black_lists_min_fields';
  block_profile_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "chat_black_lists" */
export type Mobile_Chat_Black_Lists_Mutation_Response = {
  __typename?: 'mobile_chat_black_lists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Black_Lists>;
};

/** on_conflict condition type for table "chat_black_lists" */
export type Mobile_Chat_Black_Lists_On_Conflict = {
  constraint: Mobile_Chat_Black_Lists_Constraint;
  update_columns?: Array<Mobile_Chat_Black_Lists_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Black_Lists_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_black_lists". */
export type Mobile_Chat_Black_Lists_Order_By = {
  block_profile_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_black_lists */
export type Mobile_Chat_Black_Lists_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_black_lists" */
export enum Mobile_Chat_Black_Lists_Select_Column {
  /** column name */
  BlockProfileId = 'block_profile_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "chat_black_lists" */
export type Mobile_Chat_Black_Lists_Set_Input = {
  block_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Black_Lists_Stddev_Fields = {
  __typename?: 'mobile_chat_black_lists_stddev_fields';
  block_profile_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Black_Lists_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_black_lists_stddev_pop_fields';
  block_profile_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Black_Lists_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_black_lists_stddev_samp_fields';
  block_profile_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_chat_black_lists" */
export type Mobile_Chat_Black_Lists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Black_Lists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Black_Lists_Stream_Cursor_Value_Input = {
  block_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Black_Lists_Sum_Fields = {
  __typename?: 'mobile_chat_black_lists_sum_fields';
  block_profile_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "chat_black_lists" */
export enum Mobile_Chat_Black_Lists_Update_Column {
  /** column name */
  BlockProfileId = 'block_profile_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Chat_Black_Lists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Black_Lists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Black_Lists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Black_Lists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Black_Lists_Var_Pop_Fields = {
  __typename?: 'mobile_chat_black_lists_var_pop_fields';
  block_profile_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Black_Lists_Var_Samp_Fields = {
  __typename?: 'mobile_chat_black_lists_var_samp_fields';
  block_profile_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Black_Lists_Variance_Fields = {
  __typename?: 'mobile_chat_black_lists_variance_fields';
  block_profile_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "chat_defaults" */
export type Mobile_Chat_Defaults = {
  __typename?: 'mobile_chat_defaults';
  /** An object relationship */
  chat?: Maybe<Mobile_Chats>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "chat_defaults" */
export type Mobile_Chat_Defaults_Aggregate = {
  __typename?: 'mobile_chat_defaults_aggregate';
  aggregate?: Maybe<Mobile_Chat_Defaults_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Defaults>;
};

export type Mobile_Chat_Defaults_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Chat_Defaults_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Chat_Defaults_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_defaults" */
export type Mobile_Chat_Defaults_Aggregate_Fields = {
  __typename?: 'mobile_chat_defaults_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Defaults_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Defaults_Max_Fields>;
  min?: Maybe<Mobile_Chat_Defaults_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Defaults_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Defaults_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Defaults_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Defaults_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Defaults_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Defaults_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Defaults_Variance_Fields>;
};


/** aggregate fields of "chat_defaults" */
export type Mobile_Chat_Defaults_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chat_defaults" */
export type Mobile_Chat_Defaults_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Chat_Defaults_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Chat_Defaults_Max_Order_By>;
  min?: InputMaybe<Mobile_Chat_Defaults_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Chat_Defaults_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Chat_Defaults_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Chat_Defaults_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Chat_Defaults_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Chat_Defaults_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Chat_Defaults_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Chat_Defaults_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chat_defaults" */
export type Mobile_Chat_Defaults_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Chat_Defaults_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Defaults_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Defaults_Avg_Fields = {
  __typename?: 'mobile_chat_defaults_avg_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Avg_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chat_defaults". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Defaults_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Defaults_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Defaults_Bool_Exp>>;
  chat?: InputMaybe<Mobile_Chats_Bool_Exp>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_defaults" */
export enum Mobile_Chat_Defaults_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatDefaultsPkey = 'chat_defaults_pkey'
}

/** input type for incrementing numeric columns in table "chat_defaults" */
export type Mobile_Chat_Defaults_Inc_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_defaults" */
export type Mobile_Chat_Defaults_Insert_Input = {
  chat?: InputMaybe<Mobile_Chats_Obj_Rel_Insert_Input>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Defaults_Max_Fields = {
  __typename?: 'mobile_chat_defaults_max_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Max_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Chat_Defaults_Min_Fields = {
  __typename?: 'mobile_chat_defaults_min_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Min_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_defaults" */
export type Mobile_Chat_Defaults_Mutation_Response = {
  __typename?: 'mobile_chat_defaults_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Defaults>;
};

/** on_conflict condition type for table "chat_defaults" */
export type Mobile_Chat_Defaults_On_Conflict = {
  constraint: Mobile_Chat_Defaults_Constraint;
  update_columns?: Array<Mobile_Chat_Defaults_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_defaults". */
export type Mobile_Chat_Defaults_Order_By = {
  chat?: InputMaybe<Mobile_Chats_Order_By>;
  chat_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_defaults */
export type Mobile_Chat_Defaults_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_defaults" */
export enum Mobile_Chat_Defaults_Select_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "chat_defaults" */
export type Mobile_Chat_Defaults_Set_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Defaults_Stddev_Fields = {
  __typename?: 'mobile_chat_defaults_stddev_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Stddev_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Defaults_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_defaults_stddev_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Stddev_Pop_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Defaults_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_defaults_stddev_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Stddev_Samp_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_chat_defaults" */
export type Mobile_Chat_Defaults_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Defaults_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Defaults_Stream_Cursor_Value_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Defaults_Sum_Fields = {
  __typename?: 'mobile_chat_defaults_sum_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Sum_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "chat_defaults" */
export enum Mobile_Chat_Defaults_Update_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Chat_Defaults_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Defaults_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Defaults_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Defaults_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Defaults_Var_Pop_Fields = {
  __typename?: 'mobile_chat_defaults_var_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Var_Pop_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Defaults_Var_Samp_Fields = {
  __typename?: 'mobile_chat_defaults_var_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Var_Samp_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Defaults_Variance_Fields = {
  __typename?: 'mobile_chat_defaults_variance_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "chat_defaults" */
export type Mobile_Chat_Defaults_Variance_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "chat_file_types" */
export type Mobile_Chat_File_Types = {
  __typename?: 'mobile_chat_file_types';
  /** An array relationship */
  chat_message_files: Array<Mobile_Chat_Message_Files>;
  /** An aggregate relationship */
  chat_message_files_aggregate: Mobile_Chat_Message_Files_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "chat_file_types" */
export type Mobile_Chat_File_TypesChat_Message_FilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


/** columns and relationships of "chat_file_types" */
export type Mobile_Chat_File_TypesChat_Message_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};

/** aggregated selection of "chat_file_types" */
export type Mobile_Chat_File_Types_Aggregate = {
  __typename?: 'mobile_chat_file_types_aggregate';
  aggregate?: Maybe<Mobile_Chat_File_Types_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_File_Types>;
};

/** aggregate fields of "chat_file_types" */
export type Mobile_Chat_File_Types_Aggregate_Fields = {
  __typename?: 'mobile_chat_file_types_aggregate_fields';
  avg?: Maybe<Mobile_Chat_File_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_File_Types_Max_Fields>;
  min?: Maybe<Mobile_Chat_File_Types_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_File_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_File_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_File_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_File_Types_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_File_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_File_Types_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_File_Types_Variance_Fields>;
};


/** aggregate fields of "chat_file_types" */
export type Mobile_Chat_File_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_File_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Chat_File_Types_Avg_Fields = {
  __typename?: 'mobile_chat_file_types_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "chat_file_types". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_File_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_File_Types_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_File_Types_Bool_Exp>>;
  chat_message_files?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
  chat_message_files_aggregate?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_file_types" */
export enum Mobile_Chat_File_Types_Constraint {
  /** unique or primary key constraint on columns "created_at" */
  ChatFileTypesCreatedAtKey = 'chat_file_types_created_at_key',
  /** unique or primary key constraint on columns "id" */
  ChatFileTypesPkey = 'chat_file_types_pkey',
  /** unique or primary key constraint on columns "value" */
  IndexChatFileTypesOnValue = 'index_chat_file_types_on_value'
}

/** input type for incrementing numeric columns in table "chat_file_types" */
export type Mobile_Chat_File_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_file_types" */
export type Mobile_Chat_File_Types_Insert_Input = {
  chat_message_files?: InputMaybe<Mobile_Chat_Message_Files_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_File_Types_Max_Fields = {
  __typename?: 'mobile_chat_file_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Chat_File_Types_Min_Fields = {
  __typename?: 'mobile_chat_file_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chat_file_types" */
export type Mobile_Chat_File_Types_Mutation_Response = {
  __typename?: 'mobile_chat_file_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_File_Types>;
};

/** input type for inserting object relation for remote table "chat_file_types" */
export type Mobile_Chat_File_Types_Obj_Rel_Insert_Input = {
  data: Mobile_Chat_File_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_File_Types_On_Conflict>;
};

/** on_conflict condition type for table "chat_file_types" */
export type Mobile_Chat_File_Types_On_Conflict = {
  constraint: Mobile_Chat_File_Types_Constraint;
  update_columns?: Array<Mobile_Chat_File_Types_Update_Column>;
  where?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_file_types". */
export type Mobile_Chat_File_Types_Order_By = {
  chat_message_files_aggregate?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_file_types */
export type Mobile_Chat_File_Types_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_file_types" */
export enum Mobile_Chat_File_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "chat_file_types" */
export type Mobile_Chat_File_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_File_Types_Stddev_Fields = {
  __typename?: 'mobile_chat_file_types_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_File_Types_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_file_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_File_Types_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_file_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_chat_file_types" */
export type Mobile_Chat_File_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_File_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_File_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_File_Types_Sum_Fields = {
  __typename?: 'mobile_chat_file_types_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "chat_file_types" */
export enum Mobile_Chat_File_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Mobile_Chat_File_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_File_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_File_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_File_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_File_Types_Var_Pop_Fields = {
  __typename?: 'mobile_chat_file_types_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_File_Types_Var_Samp_Fields = {
  __typename?: 'mobile_chat_file_types_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Chat_File_Types_Variance_Fields = {
  __typename?: 'mobile_chat_file_types_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "chat_message_comments" */
export type Mobile_Chat_Message_Comments = {
  __typename?: 'mobile_chat_message_comments';
  /** An object relationship */
  chat_message?: Maybe<Mobile_Chat_Messages>;
  /** An object relationship */
  chat_message_comment?: Maybe<Mobile_Chat_Message_Comments>;
  chat_message_comment_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  chat_message_comments: Array<Mobile_Chat_Message_Comments>;
  /** An aggregate relationship */
  chat_message_comments_aggregate: Mobile_Chat_Message_Comments_Aggregate;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "chat_message_comments" */
export type Mobile_Chat_Message_CommentsChat_Message_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


/** columns and relationships of "chat_message_comments" */
export type Mobile_Chat_Message_CommentsChat_Message_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};

/** aggregated selection of "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Aggregate = {
  __typename?: 'mobile_chat_message_comments_aggregate';
  aggregate?: Maybe<Mobile_Chat_Message_Comments_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Message_Comments>;
};

export type Mobile_Chat_Message_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Chat_Message_Comments_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Chat_Message_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Aggregate_Fields = {
  __typename?: 'mobile_chat_message_comments_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Message_Comments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Message_Comments_Max_Fields>;
  min?: Maybe<Mobile_Chat_Message_Comments_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Message_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Message_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Message_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Message_Comments_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Message_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Message_Comments_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Message_Comments_Variance_Fields>;
};


/** aggregate fields of "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Chat_Message_Comments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Chat_Message_Comments_Max_Order_By>;
  min?: InputMaybe<Mobile_Chat_Message_Comments_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Chat_Message_Comments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Chat_Message_Comments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Chat_Message_Comments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Chat_Message_Comments_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Chat_Message_Comments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Chat_Message_Comments_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Chat_Message_Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Chat_Message_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Message_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Message_Comments_Avg_Fields = {
  __typename?: 'mobile_chat_message_comments_avg_fields';
  chat_message_comment_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Avg_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chat_message_comments". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Message_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Message_Comments_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Message_Comments_Bool_Exp>>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_message_comment?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
  chat_message_comment_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_message_comments?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
  chat_message_comments_aggregate?: InputMaybe<Mobile_Chat_Message_Comments_Aggregate_Bool_Exp>;
  chat_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message_comments" */
export enum Mobile_Chat_Message_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessageCommentsPkey = 'chat_message_comments_pkey'
}

/** input type for incrementing numeric columns in table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Inc_Input = {
  chat_message_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Insert_Input = {
  chat_message?: InputMaybe<Mobile_Chat_Messages_Obj_Rel_Insert_Input>;
  chat_message_comment?: InputMaybe<Mobile_Chat_Message_Comments_Obj_Rel_Insert_Input>;
  chat_message_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_comments?: InputMaybe<Mobile_Chat_Message_Comments_Arr_Rel_Insert_Input>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Message_Comments_Max_Fields = {
  __typename?: 'mobile_chat_message_comments_max_fields';
  chat_message_comment_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Max_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Chat_Message_Comments_Min_Fields = {
  __typename?: 'mobile_chat_message_comments_min_fields';
  chat_message_comment_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Min_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Mutation_Response = {
  __typename?: 'mobile_chat_message_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Message_Comments>;
};

/** input type for inserting object relation for remote table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Obj_Rel_Insert_Input = {
  data: Mobile_Chat_Message_Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Message_Comments_On_Conflict>;
};

/** on_conflict condition type for table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_On_Conflict = {
  constraint: Mobile_Chat_Message_Comments_Constraint;
  update_columns?: Array<Mobile_Chat_Message_Comments_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message_comments". */
export type Mobile_Chat_Message_Comments_Order_By = {
  chat_message?: InputMaybe<Mobile_Chat_Messages_Order_By>;
  chat_message_comment?: InputMaybe<Mobile_Chat_Message_Comments_Order_By>;
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_comments_aggregate?: InputMaybe<Mobile_Chat_Message_Comments_Aggregate_Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_message_comments */
export type Mobile_Chat_Message_Comments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_message_comments" */
export enum Mobile_Chat_Message_Comments_Select_Column {
  /** column name */
  ChatMessageCommentId = 'chat_message_comment_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Set_Input = {
  chat_message_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Message_Comments_Stddev_Fields = {
  __typename?: 'mobile_chat_message_comments_stddev_fields';
  chat_message_comment_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Stddev_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Message_Comments_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_message_comments_stddev_pop_fields';
  chat_message_comment_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Stddev_Pop_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Message_Comments_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_message_comments_stddev_samp_fields';
  chat_message_comment_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Stddev_Samp_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_chat_message_comments" */
export type Mobile_Chat_Message_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Message_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Message_Comments_Stream_Cursor_Value_Input = {
  chat_message_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Message_Comments_Sum_Fields = {
  __typename?: 'mobile_chat_message_comments_sum_fields';
  chat_message_comment_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Sum_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "chat_message_comments" */
export enum Mobile_Chat_Message_Comments_Update_Column {
  /** column name */
  ChatMessageCommentId = 'chat_message_comment_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Chat_Message_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Message_Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Message_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Message_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Message_Comments_Var_Pop_Fields = {
  __typename?: 'mobile_chat_message_comments_var_pop_fields';
  chat_message_comment_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Var_Pop_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Message_Comments_Var_Samp_Fields = {
  __typename?: 'mobile_chat_message_comments_var_samp_fields';
  chat_message_comment_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Var_Samp_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Message_Comments_Variance_Fields = {
  __typename?: 'mobile_chat_message_comments_variance_fields';
  chat_message_comment_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "chat_message_comments" */
export type Mobile_Chat_Message_Comments_Variance_Order_By = {
  chat_message_comment_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "chat_message_files" */
export type Mobile_Chat_Message_Files = {
  __typename?: 'mobile_chat_message_files';
  /** An object relationship */
  chat?: Maybe<Mobile_Chats>;
  /** An object relationship */
  chat_file_type?: Maybe<Mobile_Chat_File_Types>;
  chat_file_type_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  chat_message?: Maybe<Mobile_Chat_Messages>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['Int']['output']>;
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  video_duration?: Maybe<Scalars['Int']['output']>;
  video_processed?: Maybe<Scalars['Boolean']['output']>;
  video_sound?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "chat_message_files" */
export type Mobile_Chat_Message_Files_Aggregate = {
  __typename?: 'mobile_chat_message_files_aggregate';
  aggregate?: Maybe<Mobile_Chat_Message_Files_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Message_Files>;
};

export type Mobile_Chat_Message_Files_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Chat_Message_Files_Select_Column_Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Chat_Message_Files_Select_Column_Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_message_files" */
export type Mobile_Chat_Message_Files_Aggregate_Fields = {
  __typename?: 'mobile_chat_message_files_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Message_Files_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Message_Files_Max_Fields>;
  min?: Maybe<Mobile_Chat_Message_Files_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Message_Files_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Message_Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Message_Files_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Message_Files_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Message_Files_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Message_Files_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Message_Files_Variance_Fields>;
};


/** aggregate fields of "chat_message_files" */
export type Mobile_Chat_Message_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Chat_Message_Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Chat_Message_Files_Max_Order_By>;
  min?: InputMaybe<Mobile_Chat_Message_Files_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Chat_Message_Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Chat_Message_Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Chat_Message_Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Chat_Message_Files_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Chat_Message_Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Chat_Message_Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Chat_Message_Files_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chat_message_files" */
export type Mobile_Chat_Message_Files_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Chat_Message_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Message_Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Message_Files_Avg_Fields = {
  __typename?: 'mobile_chat_message_files_avg_fields';
  chat_file_type_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Avg_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chat_message_files". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Message_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Message_Files_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Message_Files_Bool_Exp>>;
  chat?: InputMaybe<Mobile_Chats_Bool_Exp>;
  chat_file_type?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
  chat_file_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  file_preview_path?: InputMaybe<String_Comparison_Exp>;
  file_size?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_duration?: InputMaybe<Int_Comparison_Exp>;
  video_processed?: InputMaybe<Boolean_Comparison_Exp>;
  video_sound?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message_files" */
export enum Mobile_Chat_Message_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessageFilesPkey = 'chat_message_files_pkey'
}

/** input type for incrementing numeric columns in table "chat_message_files" */
export type Mobile_Chat_Message_Files_Inc_Input = {
  chat_file_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "chat_message_files" */
export type Mobile_Chat_Message_Files_Insert_Input = {
  chat?: InputMaybe<Mobile_Chats_Obj_Rel_Insert_Input>;
  chat_file_type?: InputMaybe<Mobile_Chat_File_Types_Obj_Rel_Insert_Input>;
  chat_file_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Obj_Rel_Insert_Input>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
  video_processed?: InputMaybe<Scalars['Boolean']['input']>;
  video_sound?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Message_Files_Max_Fields = {
  __typename?: 'mobile_chat_message_files_max_fields';
  chat_file_type_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Max_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Chat_Message_Files_Min_Fields = {
  __typename?: 'mobile_chat_message_files_min_fields';
  chat_file_type_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Min_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_message_files" */
export type Mobile_Chat_Message_Files_Mutation_Response = {
  __typename?: 'mobile_chat_message_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Message_Files>;
};

/** on_conflict condition type for table "chat_message_files" */
export type Mobile_Chat_Message_Files_On_Conflict = {
  constraint: Mobile_Chat_Message_Files_Constraint;
  update_columns?: Array<Mobile_Chat_Message_Files_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message_files". */
export type Mobile_Chat_Message_Files_Order_By = {
  chat?: InputMaybe<Mobile_Chats_Order_By>;
  chat_file_type?: InputMaybe<Mobile_Chat_File_Types_Order_By>;
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  video_processed?: InputMaybe<Order_By>;
  video_sound?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_message_files */
export type Mobile_Chat_Message_Files_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_message_files" */
export enum Mobile_Chat_Message_Files_Select_Column {
  /** column name */
  ChatFileTypeId = 'chat_file_type_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FilePreviewPath = 'file_preview_path',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoDuration = 'video_duration',
  /** column name */
  VideoProcessed = 'video_processed',
  /** column name */
  VideoSound = 'video_sound'
}

/** select "mobile_chat_message_files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_message_files" */
export enum Mobile_Chat_Message_Files_Select_Column_Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  VideoProcessed = 'video_processed',
  /** column name */
  VideoSound = 'video_sound'
}

/** select "mobile_chat_message_files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_message_files" */
export enum Mobile_Chat_Message_Files_Select_Column_Mobile_Chat_Message_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  VideoProcessed = 'video_processed',
  /** column name */
  VideoSound = 'video_sound'
}

/** input type for updating data in table "chat_message_files" */
export type Mobile_Chat_Message_Files_Set_Input = {
  chat_file_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
  video_processed?: InputMaybe<Scalars['Boolean']['input']>;
  video_sound?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Message_Files_Stddev_Fields = {
  __typename?: 'mobile_chat_message_files_stddev_fields';
  chat_file_type_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Stddev_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Message_Files_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_message_files_stddev_pop_fields';
  chat_file_type_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Stddev_Pop_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Message_Files_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_message_files_stddev_samp_fields';
  chat_file_type_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Stddev_Samp_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_chat_message_files" */
export type Mobile_Chat_Message_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Message_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Message_Files_Stream_Cursor_Value_Input = {
  chat_file_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
  video_processed?: InputMaybe<Scalars['Boolean']['input']>;
  video_sound?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Message_Files_Sum_Fields = {
  __typename?: 'mobile_chat_message_files_sum_fields';
  chat_file_type_id?: Maybe<Scalars['bigint']['output']>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Sum_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** update columns of table "chat_message_files" */
export enum Mobile_Chat_Message_Files_Update_Column {
  /** column name */
  ChatFileTypeId = 'chat_file_type_id',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FilePreviewPath = 'file_preview_path',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoDuration = 'video_duration',
  /** column name */
  VideoProcessed = 'video_processed',
  /** column name */
  VideoSound = 'video_sound'
}

export type Mobile_Chat_Message_Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Message_Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Message_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Message_Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Message_Files_Var_Pop_Fields = {
  __typename?: 'mobile_chat_message_files_var_pop_fields';
  chat_file_type_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Var_Pop_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Message_Files_Var_Samp_Fields = {
  __typename?: 'mobile_chat_message_files_var_samp_fields';
  chat_file_type_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Var_Samp_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Message_Files_Variance_Fields = {
  __typename?: 'mobile_chat_message_files_variance_fields';
  chat_file_type_id?: Maybe<Scalars['Float']['output']>;
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "chat_message_files" */
export type Mobile_Chat_Message_Files_Variance_Order_By = {
  chat_file_type_id?: InputMaybe<Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
};

/** columns and relationships of "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications = {
  __typename?: 'mobile_chat_message_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  chat_messages?: Maybe<Mobile_Chat_Messages>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notifications?: Maybe<Mobile_Notifications>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Aggregate = {
  __typename?: 'mobile_chat_message_notifications_aggregate';
  aggregate?: Maybe<Mobile_Chat_Message_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Message_Notifications>;
};

/** aggregate fields of "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_chat_message_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Message_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Message_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Chat_Message_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Message_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Message_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Message_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Message_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Message_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Message_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Message_Notifications_Variance_Fields>;
};


/** aggregate fields of "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Message_Notifications_Avg_Fields = {
  __typename?: 'mobile_chat_message_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "chat_message_notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Message_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Message_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Mobile_Notifications_Obj_Rel_Insert_Input>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Message_Notifications_Max_Fields = {
  __typename?: 'mobile_chat_message_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Chat_Message_Notifications_Min_Fields = {
  __typename?: 'mobile_chat_message_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Mutation_Response = {
  __typename?: 'mobile_chat_message_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Message_Notifications>;
};

/** input type for inserting object relation for remote table "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Chat_Message_Notifications_Insert_Input;
};

/** Ordering options when selecting data from "chat_message_notifications". */
export type Mobile_Chat_Message_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Mobile_Notifications_Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "chat_message_notifications" */
export enum Mobile_Chat_Message_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Message_Notifications_Stddev_Fields = {
  __typename?: 'mobile_chat_message_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Message_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_message_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Message_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_message_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_chat_message_notifications" */
export type Mobile_Chat_Message_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Message_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Message_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Message_Notifications_Sum_Fields = {
  __typename?: 'mobile_chat_message_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Chat_Message_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Message_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Message_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Message_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Message_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_chat_message_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Message_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_chat_message_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Message_Notifications_Variance_Fields = {
  __typename?: 'mobile_chat_message_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "chat_message_types" */
export type Mobile_Chat_Message_Types = {
  __typename?: 'mobile_chat_message_types';
  /** An array relationship */
  chat_messages: Array<Mobile_Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Mobile_Chat_Messages_Aggregate;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "chat_message_types" */
export type Mobile_Chat_Message_TypesChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "chat_message_types" */
export type Mobile_Chat_Message_TypesChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};

/** aggregated selection of "chat_message_types" */
export type Mobile_Chat_Message_Types_Aggregate = {
  __typename?: 'mobile_chat_message_types_aggregate';
  aggregate?: Maybe<Mobile_Chat_Message_Types_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Message_Types>;
};

/** aggregate fields of "chat_message_types" */
export type Mobile_Chat_Message_Types_Aggregate_Fields = {
  __typename?: 'mobile_chat_message_types_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Message_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Message_Types_Max_Fields>;
  min?: Maybe<Mobile_Chat_Message_Types_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Message_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Message_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Message_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Message_Types_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Message_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Message_Types_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Message_Types_Variance_Fields>;
};


/** aggregate fields of "chat_message_types" */
export type Mobile_Chat_Message_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Message_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Message_Types_Avg_Fields = {
  __typename?: 'mobile_chat_message_types_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "chat_message_types". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Message_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Message_Types_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Message_Types_Bool_Exp>>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message_types" */
export enum Mobile_Chat_Message_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessageTypesPkey = 'chat_message_types_pkey'
}

/** input type for incrementing numeric columns in table "chat_message_types" */
export type Mobile_Chat_Message_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_message_types" */
export type Mobile_Chat_Message_Types_Insert_Input = {
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Message_Types_Max_Fields = {
  __typename?: 'mobile_chat_message_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Chat_Message_Types_Min_Fields = {
  __typename?: 'mobile_chat_message_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "chat_message_types" */
export type Mobile_Chat_Message_Types_Mutation_Response = {
  __typename?: 'mobile_chat_message_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Message_Types>;
};

/** input type for inserting object relation for remote table "chat_message_types" */
export type Mobile_Chat_Message_Types_Obj_Rel_Insert_Input = {
  data: Mobile_Chat_Message_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Message_Types_On_Conflict>;
};

/** on_conflict condition type for table "chat_message_types" */
export type Mobile_Chat_Message_Types_On_Conflict = {
  constraint: Mobile_Chat_Message_Types_Constraint;
  update_columns?: Array<Mobile_Chat_Message_Types_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message_types". */
export type Mobile_Chat_Message_Types_Order_By = {
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_message_types */
export type Mobile_Chat_Message_Types_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_message_types" */
export enum Mobile_Chat_Message_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "chat_message_types" */
export type Mobile_Chat_Message_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Message_Types_Stddev_Fields = {
  __typename?: 'mobile_chat_message_types_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Message_Types_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_message_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Message_Types_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_message_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_chat_message_types" */
export type Mobile_Chat_Message_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Message_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Message_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Message_Types_Sum_Fields = {
  __typename?: 'mobile_chat_message_types_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "chat_message_types" */
export enum Mobile_Chat_Message_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Chat_Message_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Message_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Message_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Message_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Message_Types_Var_Pop_Fields = {
  __typename?: 'mobile_chat_message_types_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Message_Types_Var_Samp_Fields = {
  __typename?: 'mobile_chat_message_types_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Message_Types_Variance_Fields = {
  __typename?: 'mobile_chat_message_types_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "chat_messages" */
export type Mobile_Chat_Messages = {
  __typename?: 'mobile_chat_messages';
  changed_message?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  chat?: Maybe<Mobile_Chats>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  chat_message?: Maybe<Mobile_Chat_Messages>;
  /** An array relationship */
  chat_message_comments: Array<Mobile_Chat_Message_Comments>;
  /** An aggregate relationship */
  chat_message_comments_aggregate: Mobile_Chat_Message_Comments_Aggregate;
  /** An array relationship */
  chat_message_files: Array<Mobile_Chat_Message_Files>;
  /** An aggregate relationship */
  chat_message_files_aggregate: Mobile_Chat_Message_Files_Aggregate;
  /** An object relationship */
  chat_message_forward?: Maybe<Mobile_Chat_Messages>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  chat_message_type?: Maybe<Mobile_Chat_Message_Types>;
  chat_message_type_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  chat_messages: Array<Mobile_Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Mobile_Chat_Messages_Aggregate;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deleted_all?: Maybe<Scalars['Boolean']['output']>;
  deleted_my?: Maybe<Scalars['Boolean']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  forward_message_id?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  link_snippet_id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message: Scalars['String']['output'];
  /** An object relationship */
  post?: Maybe<Mobile_Posts>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "chat_messages_public_message" */
  public_message?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  reactions: Array<Mobile_Reactions>;
  /** An aggregate relationship */
  reactions_aggregate: Mobile_Reactions_Aggregate;
  /** An object relationship */
  status_message?: Maybe<Mobile_Status_Messages>;
  status_message_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesChat_Message_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesChat_Message_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesChat_Message_FilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesChat_Message_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};


/** columns and relationships of "chat_messages" */
export type Mobile_Chat_MessagesReactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};

/** aggregated selection of "chat_messages" */
export type Mobile_Chat_Messages_Aggregate = {
  __typename?: 'mobile_chat_messages_aggregate';
  aggregate?: Maybe<Mobile_Chat_Messages_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Messages>;
};

export type Mobile_Chat_Messages_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Chat_Messages_Select_Column_Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Chat_Messages_Select_Column_Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Chat_Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_messages" */
export type Mobile_Chat_Messages_Aggregate_Fields = {
  __typename?: 'mobile_chat_messages_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Messages_Max_Fields>;
  min?: Maybe<Mobile_Chat_Messages_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Messages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Messages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Messages_Variance_Fields>;
};


/** aggregate fields of "chat_messages" */
export type Mobile_Chat_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chat_messages" */
export type Mobile_Chat_Messages_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Chat_Messages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Chat_Messages_Max_Order_By>;
  min?: InputMaybe<Mobile_Chat_Messages_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Chat_Messages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Chat_Messages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Chat_Messages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Chat_Messages_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Chat_Messages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Chat_Messages_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Chat_Messages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chat_messages" */
export type Mobile_Chat_Messages_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Chat_Messages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Messages_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Messages_Avg_Fields = {
  __typename?: 'mobile_chat_messages_avg_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  chat_message_type_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  forward_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_snippet_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  status_message_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Avg_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chat_messages". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Messages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Messages_Bool_Exp>>;
  changed_message?: InputMaybe<Boolean_Comparison_Exp>;
  chat?: InputMaybe<Mobile_Chats_Bool_Exp>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_message_comments?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
  chat_message_comments_aggregate?: InputMaybe<Mobile_Chat_Message_Comments_Aggregate_Bool_Exp>;
  chat_message_files?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
  chat_message_files_aggregate?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Bool_Exp>;
  chat_message_forward?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_message_type?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
  chat_message_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp>;
  chat_messages_count?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_all?: InputMaybe<Boolean_Comparison_Exp>;
  deleted_my?: InputMaybe<Boolean_Comparison_Exp>;
  deletion_date?: InputMaybe<Timestamp_Comparison_Exp>;
  forward_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  link_snippet_id?: InputMaybe<Bigint_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Mobile_Posts_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  public_message?: InputMaybe<String_Comparison_Exp>;
  reactions?: InputMaybe<Mobile_Reactions_Bool_Exp>;
  reactions_aggregate?: InputMaybe<Mobile_Reactions_Aggregate_Bool_Exp>;
  status_message?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
  status_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_messages" */
export enum Mobile_Chat_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessagesPkey = 'chat_messages_pkey'
}

/** input type for incrementing numeric columns in table "chat_messages" */
export type Mobile_Chat_Messages_Inc_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  forward_message_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link_snippet_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  status_message_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_messages" */
export type Mobile_Chat_Messages_Insert_Input = {
  changed_message?: InputMaybe<Scalars['Boolean']['input']>;
  chat?: InputMaybe<Mobile_Chats_Obj_Rel_Insert_Input>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Obj_Rel_Insert_Input>;
  chat_message_comments?: InputMaybe<Mobile_Chat_Message_Comments_Arr_Rel_Insert_Input>;
  chat_message_files?: InputMaybe<Mobile_Chat_Message_Files_Arr_Rel_Insert_Input>;
  chat_message_forward?: InputMaybe<Mobile_Chat_Messages_Obj_Rel_Insert_Input>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_type?: InputMaybe<Mobile_Chat_Message_Types_Obj_Rel_Insert_Input>;
  chat_message_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Arr_Rel_Insert_Input>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_all?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_my?: InputMaybe<Scalars['Boolean']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  forward_message_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link_snippet_id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<Mobile_Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  reactions?: InputMaybe<Mobile_Reactions_Arr_Rel_Insert_Input>;
  status_message?: InputMaybe<Mobile_Status_Messages_Obj_Rel_Insert_Input>;
  status_message_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Messages_Max_Fields = {
  __typename?: 'mobile_chat_messages_max_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_type_id?: Maybe<Scalars['bigint']['output']>;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  forward_message_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  link_snippet_id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "chat_messages_public_message" */
  public_message?: Maybe<Scalars['String']['output']>;
  status_message_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Max_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Chat_Messages_Min_Fields = {
  __typename?: 'mobile_chat_messages_min_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_type_id?: Maybe<Scalars['bigint']['output']>;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  forward_message_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  link_snippet_id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "chat_messages_public_message" */
  public_message?: Maybe<Scalars['String']['output']>;
  status_message_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Min_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_messages" */
export type Mobile_Chat_Messages_Mutation_Response = {
  __typename?: 'mobile_chat_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Messages>;
};

/** input type for inserting object relation for remote table "chat_messages" */
export type Mobile_Chat_Messages_Obj_Rel_Insert_Input = {
  data: Mobile_Chat_Messages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Messages_On_Conflict>;
};

/** on_conflict condition type for table "chat_messages" */
export type Mobile_Chat_Messages_On_Conflict = {
  constraint: Mobile_Chat_Messages_Constraint;
  update_columns?: Array<Mobile_Chat_Messages_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_messages". */
export type Mobile_Chat_Messages_Order_By = {
  changed_message?: InputMaybe<Order_By>;
  chat?: InputMaybe<Mobile_Chats_Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Order_By>;
  chat_message_comments_aggregate?: InputMaybe<Mobile_Chat_Message_Comments_Aggregate_Order_By>;
  chat_message_files_aggregate?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Order_By>;
  chat_message_forward?: InputMaybe<Mobile_Chat_Messages_Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type?: InputMaybe<Mobile_Chat_Message_Types_Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_all?: InputMaybe<Order_By>;
  deleted_my?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post?: InputMaybe<Mobile_Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  public_message?: InputMaybe<Order_By>;
  reactions_aggregate?: InputMaybe<Mobile_Reactions_Aggregate_Order_By>;
  status_message?: InputMaybe<Mobile_Status_Messages_Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_messages */
export type Mobile_Chat_Messages_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_messages" */
export enum Mobile_Chat_Messages_Select_Column {
  /** column name */
  ChangedMessage = 'changed_message',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  ChatMessageTypeId = 'chat_message_type_id',
  /** column name */
  ChatMessagesCount = 'chat_messages_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAll = 'deleted_all',
  /** column name */
  DeletedMy = 'deleted_my',
  /** column name */
  DeletionDate = 'deletion_date',
  /** column name */
  ForwardMessageId = 'forward_message_id',
  /** column name */
  Id = 'id',
  /** column name */
  LinkSnippetId = 'link_snippet_id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  StatusMessageId = 'status_message_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_chat_messages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_messages" */
export enum Mobile_Chat_Messages_Select_Column_Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ChangedMessage = 'changed_message',
  /** column name */
  DeletedAll = 'deleted_all',
  /** column name */
  DeletedMy = 'deleted_my'
}

/** select "mobile_chat_messages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_messages" */
export enum Mobile_Chat_Messages_Select_Column_Mobile_Chat_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ChangedMessage = 'changed_message',
  /** column name */
  DeletedAll = 'deleted_all',
  /** column name */
  DeletedMy = 'deleted_my'
}

/** input type for updating data in table "chat_messages" */
export type Mobile_Chat_Messages_Set_Input = {
  changed_message?: InputMaybe<Scalars['Boolean']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_all?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_my?: InputMaybe<Scalars['Boolean']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  forward_message_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link_snippet_id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  status_message_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Messages_Stddev_Fields = {
  __typename?: 'mobile_chat_messages_stddev_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  chat_message_type_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  forward_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_snippet_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  status_message_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Stddev_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Messages_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_messages_stddev_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  chat_message_type_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  forward_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_snippet_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  status_message_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Stddev_Pop_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Messages_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_messages_stddev_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  chat_message_type_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  forward_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_snippet_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  status_message_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Stddev_Samp_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_chat_messages" */
export type Mobile_Chat_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Messages_Stream_Cursor_Value_Input = {
  changed_message?: InputMaybe<Scalars['Boolean']['input']>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_type_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_all?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_my?: InputMaybe<Scalars['Boolean']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  forward_message_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  link_snippet_id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  status_message_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Messages_Sum_Fields = {
  __typename?: 'mobile_chat_messages_sum_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_type_id?: Maybe<Scalars['bigint']['output']>;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  forward_message_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  link_snippet_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  status_message_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Sum_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "chat_messages" */
export enum Mobile_Chat_Messages_Update_Column {
  /** column name */
  ChangedMessage = 'changed_message',
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  ChatMessageTypeId = 'chat_message_type_id',
  /** column name */
  ChatMessagesCount = 'chat_messages_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAll = 'deleted_all',
  /** column name */
  DeletedMy = 'deleted_my',
  /** column name */
  DeletionDate = 'deletion_date',
  /** column name */
  ForwardMessageId = 'forward_message_id',
  /** column name */
  Id = 'id',
  /** column name */
  LinkSnippetId = 'link_snippet_id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  StatusMessageId = 'status_message_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Chat_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Messages_Var_Pop_Fields = {
  __typename?: 'mobile_chat_messages_var_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  chat_message_type_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  forward_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_snippet_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  status_message_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Var_Pop_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Messages_Var_Samp_Fields = {
  __typename?: 'mobile_chat_messages_var_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  chat_message_type_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  forward_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_snippet_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  status_message_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Var_Samp_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Messages_Variance_Fields = {
  __typename?: 'mobile_chat_messages_variance_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  chat_message_type_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  forward_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  link_snippet_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  status_message_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "chat_messages" */
export type Mobile_Chat_Messages_Variance_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  forward_message_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_snippet_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  status_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "chat_roles" */
export type Mobile_Chat_Roles = {
  __typename?: 'mobile_chat_roles';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_chats: Array<Mobile_User_Chats>;
  /** An aggregate relationship */
  user_chats_aggregate: Mobile_User_Chats_Aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "chat_roles" */
export type Mobile_Chat_RolesUser_ChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


/** columns and relationships of "chat_roles" */
export type Mobile_Chat_RolesUser_Chats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};

/** aggregated selection of "chat_roles" */
export type Mobile_Chat_Roles_Aggregate = {
  __typename?: 'mobile_chat_roles_aggregate';
  aggregate?: Maybe<Mobile_Chat_Roles_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Roles>;
};

/** aggregate fields of "chat_roles" */
export type Mobile_Chat_Roles_Aggregate_Fields = {
  __typename?: 'mobile_chat_roles_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Roles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Roles_Max_Fields>;
  min?: Maybe<Mobile_Chat_Roles_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Roles_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Roles_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Roles_Variance_Fields>;
};


/** aggregate fields of "chat_roles" */
export type Mobile_Chat_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Roles_Avg_Fields = {
  __typename?: 'mobile_chat_roles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "chat_roles". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Roles_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_chats?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
  user_chats_aggregate?: InputMaybe<Mobile_User_Chats_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_roles" */
export enum Mobile_Chat_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatRolesPkey = 'chat_roles_pkey'
}

/** input type for incrementing numeric columns in table "chat_roles" */
export type Mobile_Chat_Roles_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_roles" */
export type Mobile_Chat_Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_chats?: InputMaybe<Mobile_User_Chats_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Roles_Max_Fields = {
  __typename?: 'mobile_chat_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Chat_Roles_Min_Fields = {
  __typename?: 'mobile_chat_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "chat_roles" */
export type Mobile_Chat_Roles_Mutation_Response = {
  __typename?: 'mobile_chat_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Roles>;
};

/** input type for inserting object relation for remote table "chat_roles" */
export type Mobile_Chat_Roles_Obj_Rel_Insert_Input = {
  data: Mobile_Chat_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Roles_On_Conflict>;
};

/** on_conflict condition type for table "chat_roles" */
export type Mobile_Chat_Roles_On_Conflict = {
  constraint: Mobile_Chat_Roles_Constraint;
  update_columns?: Array<Mobile_Chat_Roles_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_roles". */
export type Mobile_Chat_Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_chats_aggregate?: InputMaybe<Mobile_User_Chats_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_roles */
export type Mobile_Chat_Roles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_roles" */
export enum Mobile_Chat_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "chat_roles" */
export type Mobile_Chat_Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Roles_Stddev_Fields = {
  __typename?: 'mobile_chat_roles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Roles_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Roles_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_chat_roles" */
export type Mobile_Chat_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Roles_Sum_Fields = {
  __typename?: 'mobile_chat_roles_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "chat_roles" */
export enum Mobile_Chat_Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Mobile_Chat_Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Roles_Var_Pop_Fields = {
  __typename?: 'mobile_chat_roles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Roles_Var_Samp_Fields = {
  __typename?: 'mobile_chat_roles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Roles_Variance_Fields = {
  __typename?: 'mobile_chat_roles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "chat_types" */
export type Mobile_Chat_Types = {
  __typename?: 'mobile_chat_types';
  /** An array relationship */
  chats: Array<Mobile_Chats>;
  /** An aggregate relationship */
  chats_aggregate: Mobile_Chats_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "chat_types" */
export type Mobile_Chat_TypesChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chats_Order_By>>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};


/** columns and relationships of "chat_types" */
export type Mobile_Chat_TypesChats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chats_Order_By>>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};

/** aggregated selection of "chat_types" */
export type Mobile_Chat_Types_Aggregate = {
  __typename?: 'mobile_chat_types_aggregate';
  aggregate?: Maybe<Mobile_Chat_Types_Aggregate_Fields>;
  nodes: Array<Mobile_Chat_Types>;
};

/** aggregate fields of "chat_types" */
export type Mobile_Chat_Types_Aggregate_Fields = {
  __typename?: 'mobile_chat_types_aggregate_fields';
  avg?: Maybe<Mobile_Chat_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chat_Types_Max_Fields>;
  min?: Maybe<Mobile_Chat_Types_Min_Fields>;
  stddev?: Maybe<Mobile_Chat_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chat_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chat_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chat_Types_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chat_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chat_Types_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chat_Types_Variance_Fields>;
};


/** aggregate fields of "chat_types" */
export type Mobile_Chat_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chat_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Chat_Types_Avg_Fields = {
  __typename?: 'mobile_chat_types_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "chat_types". All fields are combined with a logical 'AND'. */
export type Mobile_Chat_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chat_Types_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chat_Types_Bool_Exp>>;
  chats?: InputMaybe<Mobile_Chats_Bool_Exp>;
  chats_aggregate?: InputMaybe<Mobile_Chats_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_types" */
export enum Mobile_Chat_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatTypesPkey = 'chat_types_pkey'
}

/** input type for incrementing numeric columns in table "chat_types" */
export type Mobile_Chat_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chat_types" */
export type Mobile_Chat_Types_Insert_Input = {
  chats?: InputMaybe<Mobile_Chats_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Chat_Types_Max_Fields = {
  __typename?: 'mobile_chat_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Chat_Types_Min_Fields = {
  __typename?: 'mobile_chat_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "chat_types" */
export type Mobile_Chat_Types_Mutation_Response = {
  __typename?: 'mobile_chat_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chat_Types>;
};

/** input type for inserting object relation for remote table "chat_types" */
export type Mobile_Chat_Types_Obj_Rel_Insert_Input = {
  data: Mobile_Chat_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chat_Types_On_Conflict>;
};

/** on_conflict condition type for table "chat_types" */
export type Mobile_Chat_Types_On_Conflict = {
  constraint: Mobile_Chat_Types_Constraint;
  update_columns?: Array<Mobile_Chat_Types_Update_Column>;
  where?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_types". */
export type Mobile_Chat_Types_Order_By = {
  chats_aggregate?: InputMaybe<Mobile_Chats_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_types */
export type Mobile_Chat_Types_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chat_types" */
export enum Mobile_Chat_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "chat_types" */
export type Mobile_Chat_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chat_Types_Stddev_Fields = {
  __typename?: 'mobile_chat_types_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chat_Types_Stddev_Pop_Fields = {
  __typename?: 'mobile_chat_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chat_Types_Stddev_Samp_Fields = {
  __typename?: 'mobile_chat_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_chat_types" */
export type Mobile_Chat_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chat_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chat_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chat_Types_Sum_Fields = {
  __typename?: 'mobile_chat_types_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "chat_types" */
export enum Mobile_Chat_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Chat_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chat_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chat_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chat_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chat_Types_Var_Pop_Fields = {
  __typename?: 'mobile_chat_types_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Chat_Types_Var_Samp_Fields = {
  __typename?: 'mobile_chat_types_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Chat_Types_Variance_Fields = {
  __typename?: 'mobile_chat_types_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "chats" */
export type Mobile_Chats = {
  __typename?: 'mobile_chats';
  avatar_path?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  chat_defaults: Array<Mobile_Chat_Defaults>;
  /** An aggregate relationship */
  chat_defaults_aggregate: Mobile_Chat_Defaults_Aggregate;
  /** An array relationship */
  chat_message_files: Array<Mobile_Chat_Message_Files>;
  /** An aggregate relationship */
  chat_message_files_aggregate: Mobile_Chat_Message_Files_Aggregate;
  /** An array relationship */
  chat_messages: Array<Mobile_Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Mobile_Chat_Messages_Aggregate;
  /** An object relationship */
  chat_type?: Maybe<Mobile_Chat_Types>;
  chat_type_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['bigint']['output']>;
  reaction?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  user_chats: Array<Mobile_User_Chats>;
  /** An aggregate relationship */
  user_chats_aggregate: Mobile_User_Chats_Aggregate;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsChat_DefaultsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Defaults_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsChat_Defaults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Defaults_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsChat_Message_FilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsChat_Message_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsUser_ChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


/** columns and relationships of "chats" */
export type Mobile_ChatsUser_Chats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};

/** aggregated selection of "chats" */
export type Mobile_Chats_Aggregate = {
  __typename?: 'mobile_chats_aggregate';
  aggregate?: Maybe<Mobile_Chats_Aggregate_Fields>;
  nodes: Array<Mobile_Chats>;
};

export type Mobile_Chats_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Chats_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Chats_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Chats_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Chats_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Chats_Select_Column_Mobile_Chats_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chats_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Chats_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Chats_Select_Column_Mobile_Chats_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chats_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Chats_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Chats_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chats" */
export type Mobile_Chats_Aggregate_Fields = {
  __typename?: 'mobile_chats_aggregate_fields';
  avg?: Maybe<Mobile_Chats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Chats_Max_Fields>;
  min?: Maybe<Mobile_Chats_Min_Fields>;
  stddev?: Maybe<Mobile_Chats_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Chats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Chats_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Chats_Sum_Fields>;
  var_pop?: Maybe<Mobile_Chats_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Chats_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Chats_Variance_Fields>;
};


/** aggregate fields of "chats" */
export type Mobile_Chats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chats" */
export type Mobile_Chats_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Chats_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Chats_Max_Order_By>;
  min?: InputMaybe<Mobile_Chats_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Chats_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Chats_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Chats_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Chats_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Chats_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Chats_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Chats_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chats" */
export type Mobile_Chats_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Chats_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chats_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Chats_Avg_Fields = {
  __typename?: 'mobile_chats_avg_fields';
  chat_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "chats" */
export type Mobile_Chats_Avg_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chats". All fields are combined with a logical 'AND'. */
export type Mobile_Chats_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Chats_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Chats_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Chats_Bool_Exp>>;
  avatar_path?: InputMaybe<String_Comparison_Exp>;
  chat_defaults?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
  chat_defaults_aggregate?: InputMaybe<Mobile_Chat_Defaults_Aggregate_Bool_Exp>;
  chat_message_files?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
  chat_message_files_aggregate?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Bool_Exp>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp>;
  chat_type?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
  chat_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_id?: InputMaybe<Bigint_Comparison_Exp>;
  reaction?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user_chats?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
  user_chats_aggregate?: InputMaybe<Mobile_User_Chats_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "chats" */
export enum Mobile_Chats_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatsPkey = 'chats_pkey',
  /** unique or primary key constraint on columns "url" */
  IndexChatsOnUrl = 'index_chats_on_url'
}

/** input type for incrementing numeric columns in table "chats" */
export type Mobile_Chats_Inc_Input = {
  chat_type_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  owner_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "chats" */
export type Mobile_Chats_Insert_Input = {
  avatar_path?: InputMaybe<Scalars['String']['input']>;
  chat_defaults?: InputMaybe<Mobile_Chat_Defaults_Arr_Rel_Insert_Input>;
  chat_message_files?: InputMaybe<Mobile_Chat_Message_Files_Arr_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Arr_Rel_Insert_Input>;
  chat_type?: InputMaybe<Mobile_Chat_Types_Obj_Rel_Insert_Input>;
  chat_type_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['bigint']['input']>;
  reaction?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_chats?: InputMaybe<Mobile_User_Chats_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mobile_Chats_Max_Fields = {
  __typename?: 'mobile_chats_max_fields';
  avatar_path?: Maybe<Scalars['String']['output']>;
  chat_type_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "chats" */
export type Mobile_Chats_Max_Order_By = {
  avatar_path?: InputMaybe<Order_By>;
  chat_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Chats_Min_Fields = {
  __typename?: 'mobile_chats_min_fields';
  avatar_path?: Maybe<Scalars['String']['output']>;
  chat_type_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "chats" */
export type Mobile_Chats_Min_Order_By = {
  avatar_path?: InputMaybe<Order_By>;
  chat_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chats" */
export type Mobile_Chats_Mutation_Response = {
  __typename?: 'mobile_chats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Chats>;
};

/** input type for inserting object relation for remote table "chats" */
export type Mobile_Chats_Obj_Rel_Insert_Input = {
  data: Mobile_Chats_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Chats_On_Conflict>;
};

/** on_conflict condition type for table "chats" */
export type Mobile_Chats_On_Conflict = {
  constraint: Mobile_Chats_Constraint;
  update_columns?: Array<Mobile_Chats_Update_Column>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};

/** Ordering options when selecting data from "chats". */
export type Mobile_Chats_Order_By = {
  avatar_path?: InputMaybe<Order_By>;
  chat_defaults_aggregate?: InputMaybe<Mobile_Chat_Defaults_Aggregate_Order_By>;
  chat_message_files_aggregate?: InputMaybe<Mobile_Chat_Message_Files_Aggregate_Order_By>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Order_By>;
  chat_type?: InputMaybe<Mobile_Chat_Types_Order_By>;
  chat_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  reaction?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_chats_aggregate?: InputMaybe<Mobile_User_Chats_Aggregate_Order_By>;
};

/** primary key columns input for table: chats */
export type Mobile_Chats_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "chats" */
export enum Mobile_Chats_Select_Column {
  /** column name */
  AvatarPath = 'avatar_path',
  /** column name */
  ChatTypeId = 'chat_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** select "mobile_chats_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chats" */
export enum Mobile_Chats_Select_Column_Mobile_Chats_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Reaction = 'reaction'
}

/** select "mobile_chats_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chats" */
export enum Mobile_Chats_Select_Column_Mobile_Chats_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Reaction = 'reaction'
}

/** input type for updating data in table "chats" */
export type Mobile_Chats_Set_Input = {
  avatar_path?: InputMaybe<Scalars['String']['input']>;
  chat_type_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['bigint']['input']>;
  reaction?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Chats_Stddev_Fields = {
  __typename?: 'mobile_chats_stddev_fields';
  chat_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "chats" */
export type Mobile_Chats_Stddev_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Chats_Stddev_Pop_Fields = {
  __typename?: 'mobile_chats_stddev_pop_fields';
  chat_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "chats" */
export type Mobile_Chats_Stddev_Pop_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Chats_Stddev_Samp_Fields = {
  __typename?: 'mobile_chats_stddev_samp_fields';
  chat_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "chats" */
export type Mobile_Chats_Stddev_Samp_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_chats" */
export type Mobile_Chats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Chats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Chats_Stream_Cursor_Value_Input = {
  avatar_path?: InputMaybe<Scalars['String']['input']>;
  chat_type_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['bigint']['input']>;
  reaction?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Chats_Sum_Fields = {
  __typename?: 'mobile_chats_sum_fields';
  chat_type_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  owner_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "chats" */
export type Mobile_Chats_Sum_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** update columns of table "chats" */
export enum Mobile_Chats_Update_Column {
  /** column name */
  AvatarPath = 'avatar_path',
  /** column name */
  ChatTypeId = 'chat_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Mobile_Chats_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Chats_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Chats_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Chats_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Chats_Var_Pop_Fields = {
  __typename?: 'mobile_chats_var_pop_fields';
  chat_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "chats" */
export type Mobile_Chats_Var_Pop_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Chats_Var_Samp_Fields = {
  __typename?: 'mobile_chats_var_samp_fields';
  chat_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "chats" */
export type Mobile_Chats_Var_Samp_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Chats_Variance_Fields = {
  __typename?: 'mobile_chats_variance_fields';
  chat_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  owner_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "chats" */
export type Mobile_Chats_Variance_Order_By = {
  chat_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "clients" */
export type Mobile_Clients = {
  __typename?: 'mobile_clients';
  active?: Maybe<Scalars['Int']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamp']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  income_orders: Array<Mobile_Orders>;
  /** An aggregate relationship */
  income_orders_aggregate: Mobile_Orders_Aggregate;
  last_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  orders: Array<Mobile_Orders>;
  /** An aggregate relationship */
  orders_aggregate: Mobile_Orders_Aggregate;
  patronymic?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['bigint']['output'];
  vc_id: Scalars['bigint']['output'];
};


/** columns and relationships of "clients" */
export type Mobile_ClientsIncome_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type Mobile_ClientsIncome_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type Mobile_ClientsOrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type Mobile_ClientsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};

/** aggregated selection of "clients" */
export type Mobile_Clients_Aggregate = {
  __typename?: 'mobile_clients_aggregate';
  aggregate?: Maybe<Mobile_Clients_Aggregate_Fields>;
  nodes: Array<Mobile_Clients>;
};

/** aggregate fields of "clients" */
export type Mobile_Clients_Aggregate_Fields = {
  __typename?: 'mobile_clients_aggregate_fields';
  avg?: Maybe<Mobile_Clients_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Clients_Max_Fields>;
  min?: Maybe<Mobile_Clients_Min_Fields>;
  stddev?: Maybe<Mobile_Clients_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Clients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Clients_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Clients_Sum_Fields>;
  var_pop?: Maybe<Mobile_Clients_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Clients_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Clients_Variance_Fields>;
};


/** aggregate fields of "clients" */
export type Mobile_Clients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Clients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Clients_Avg_Fields = {
  __typename?: 'mobile_clients_avg_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'. */
export type Mobile_Clients_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Clients_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Clients_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Clients_Bool_Exp>>;
  active?: InputMaybe<Int_Comparison_Exp>;
  auth_state?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  income_orders?: InputMaybe<Mobile_Orders_Bool_Exp>;
  income_orders_aggregate?: InputMaybe<Mobile_Orders_Aggregate_Bool_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  orders?: InputMaybe<Mobile_Orders_Bool_Exp>;
  orders_aggregate?: InputMaybe<Mobile_Orders_Aggregate_Bool_Exp>;
  patronymic?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  vc_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "clients" */
export enum Mobile_Clients_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientsPkey = 'clients_pkey'
}

/** input type for incrementing numeric columns in table "clients" */
export type Mobile_Clients_Inc_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "clients" */
export type Mobile_Clients_Insert_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  income_orders?: InputMaybe<Mobile_Orders_Arr_Rel_Insert_Input>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  orders?: InputMaybe<Mobile_Orders_Arr_Rel_Insert_Input>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Clients_Max_Fields = {
  __typename?: 'mobile_clients_max_fields';
  active?: Maybe<Scalars['Int']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  vc_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Clients_Min_Fields = {
  __typename?: 'mobile_clients_min_fields';
  active?: Maybe<Scalars['Int']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  vc_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "clients" */
export type Mobile_Clients_Mutation_Response = {
  __typename?: 'mobile_clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Clients>;
};

/** input type for inserting object relation for remote table "clients" */
export type Mobile_Clients_Obj_Rel_Insert_Input = {
  data: Mobile_Clients_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Clients_On_Conflict>;
};

/** on_conflict condition type for table "clients" */
export type Mobile_Clients_On_Conflict = {
  constraint: Mobile_Clients_Constraint;
  update_columns?: Array<Mobile_Clients_Update_Column>;
  where?: InputMaybe<Mobile_Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "clients". */
export type Mobile_Clients_Order_By = {
  active?: InputMaybe<Order_By>;
  auth_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  income_orders_aggregate?: InputMaybe<Mobile_Orders_Aggregate_Order_By>;
  last_name?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Mobile_Orders_Aggregate_Order_By>;
  patronymic?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clients */
export type Mobile_Clients_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "clients" */
export enum Mobile_Clients_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthState = 'auth_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VcId = 'vc_id'
}

/** input type for updating data in table "clients" */
export type Mobile_Clients_Set_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Clients_Stddev_Fields = {
  __typename?: 'mobile_clients_stddev_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Clients_Stddev_Pop_Fields = {
  __typename?: 'mobile_clients_stddev_pop_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Clients_Stddev_Samp_Fields = {
  __typename?: 'mobile_clients_stddev_samp_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_clients" */
export type Mobile_Clients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Clients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Clients_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Int']['input']>;
  auth_state?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Clients_Sum_Fields = {
  __typename?: 'mobile_clients_sum_fields';
  active?: Maybe<Scalars['Int']['output']>;
  auth_state?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  vc_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "clients" */
export enum Mobile_Clients_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthState = 'auth_state',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VcId = 'vc_id'
}

export type Mobile_Clients_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Clients_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Clients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Clients_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Clients_Var_Pop_Fields = {
  __typename?: 'mobile_clients_var_pop_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Clients_Var_Samp_Fields = {
  __typename?: 'mobile_clients_var_samp_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Clients_Variance_Fields = {
  __typename?: 'mobile_clients_variance_fields';
  active?: Maybe<Scalars['Float']['output']>;
  auth_state?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications = {
  __typename?: 'mobile_comment_like_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  comment_likes?: Maybe<Mobile_Comment_Likes>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notifications?: Maybe<Mobile_Notifications>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Aggregate = {
  __typename?: 'mobile_comment_like_notifications_aggregate';
  aggregate?: Maybe<Mobile_Comment_Like_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Comment_Like_Notifications>;
};

/** aggregate fields of "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_comment_like_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Comment_Like_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Comment_Like_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Comment_Like_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Comment_Like_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Comment_Like_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Comment_Like_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Comment_Like_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Comment_Like_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Comment_Like_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Comment_Like_Notifications_Variance_Fields>;
};


/** aggregate fields of "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Comment_Like_Notifications_Avg_Fields = {
  __typename?: 'mobile_comment_like_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "comment_like_notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Comment_Like_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Comment_Like_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  comment_likes?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  comment_likes?: InputMaybe<Mobile_Comment_Likes_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Mobile_Notifications_Obj_Rel_Insert_Input>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Comment_Like_Notifications_Max_Fields = {
  __typename?: 'mobile_comment_like_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Comment_Like_Notifications_Min_Fields = {
  __typename?: 'mobile_comment_like_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Mutation_Response = {
  __typename?: 'mobile_comment_like_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Comment_Like_Notifications>;
};

/** input type for inserting object relation for remote table "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Comment_Like_Notifications_Insert_Input;
};

/** Ordering options when selecting data from "comment_like_notifications". */
export type Mobile_Comment_Like_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  comment_likes?: InputMaybe<Mobile_Comment_Likes_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Mobile_Notifications_Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "comment_like_notifications" */
export enum Mobile_Comment_Like_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Comment_Like_Notifications_Stddev_Fields = {
  __typename?: 'mobile_comment_like_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Comment_Like_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_comment_like_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Comment_Like_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_comment_like_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_comment_like_notifications" */
export type Mobile_Comment_Like_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Comment_Like_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Comment_Like_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Comment_Like_Notifications_Sum_Fields = {
  __typename?: 'mobile_comment_like_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Comment_Like_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Comment_Like_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Comment_Like_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Comment_Like_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Comment_Like_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_comment_like_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Comment_Like_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_comment_like_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Comment_Like_Notifications_Variance_Fields = {
  __typename?: 'mobile_comment_like_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "comment_likes" */
export type Mobile_Comment_Likes = {
  __typename?: 'mobile_comment_likes';
  /** An object relationship */
  comment?: Maybe<Mobile_Comments>;
  comment_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "comment_likes" */
export type Mobile_Comment_Likes_Aggregate = {
  __typename?: 'mobile_comment_likes_aggregate';
  aggregate?: Maybe<Mobile_Comment_Likes_Aggregate_Fields>;
  nodes: Array<Mobile_Comment_Likes>;
};

export type Mobile_Comment_Likes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Comment_Likes_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Comment_Likes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comment_likes" */
export type Mobile_Comment_Likes_Aggregate_Fields = {
  __typename?: 'mobile_comment_likes_aggregate_fields';
  avg?: Maybe<Mobile_Comment_Likes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Comment_Likes_Max_Fields>;
  min?: Maybe<Mobile_Comment_Likes_Min_Fields>;
  stddev?: Maybe<Mobile_Comment_Likes_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Comment_Likes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Comment_Likes_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Comment_Likes_Sum_Fields>;
  var_pop?: Maybe<Mobile_Comment_Likes_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Comment_Likes_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Comment_Likes_Variance_Fields>;
};


/** aggregate fields of "comment_likes" */
export type Mobile_Comment_Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment_likes" */
export type Mobile_Comment_Likes_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Comment_Likes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Comment_Likes_Max_Order_By>;
  min?: InputMaybe<Mobile_Comment_Likes_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Comment_Likes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Comment_Likes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Comment_Likes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Comment_Likes_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Comment_Likes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Comment_Likes_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Comment_Likes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comment_likes" */
export type Mobile_Comment_Likes_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Comment_Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Comment_Likes_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Comment_Likes_Avg_Fields = {
  __typename?: 'mobile_comment_likes_avg_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Avg_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comment_likes". All fields are combined with a logical 'AND'. */
export type Mobile_Comment_Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Comment_Likes_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Comment_Likes_Bool_Exp>>;
  comment?: InputMaybe<Mobile_Comments_Bool_Exp>;
  comment_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_likes" */
export enum Mobile_Comment_Likes_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentLikesPkey = 'comment_likes_pkey',
  /** unique or primary key constraint on columns "comment_id", "user_id" */
  UniqueUserIdCommentId = 'unique_user_id_comment_id'
}

/** input type for incrementing numeric columns in table "comment_likes" */
export type Mobile_Comment_Likes_Inc_Input = {
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "comment_likes" */
export type Mobile_Comment_Likes_Insert_Input = {
  comment?: InputMaybe<Mobile_Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Comment_Likes_Max_Fields = {
  __typename?: 'mobile_comment_likes_max_fields';
  comment_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Comment_Likes_Min_Fields = {
  __typename?: 'mobile_comment_likes_min_fields';
  comment_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_likes" */
export type Mobile_Comment_Likes_Mutation_Response = {
  __typename?: 'mobile_comment_likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Comment_Likes>;
};

/** input type for inserting object relation for remote table "comment_likes" */
export type Mobile_Comment_Likes_Obj_Rel_Insert_Input = {
  data: Mobile_Comment_Likes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Comment_Likes_On_Conflict>;
};

/** on_conflict condition type for table "comment_likes" */
export type Mobile_Comment_Likes_On_Conflict = {
  constraint: Mobile_Comment_Likes_Constraint;
  update_columns?: Array<Mobile_Comment_Likes_Update_Column>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_likes". */
export type Mobile_Comment_Likes_Order_By = {
  comment?: InputMaybe<Mobile_Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_likes */
export type Mobile_Comment_Likes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "comment_likes" */
export enum Mobile_Comment_Likes_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comment_likes" */
export type Mobile_Comment_Likes_Set_Input = {
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Comment_Likes_Stddev_Fields = {
  __typename?: 'mobile_comment_likes_stddev_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Stddev_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Comment_Likes_Stddev_Pop_Fields = {
  __typename?: 'mobile_comment_likes_stddev_pop_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Stddev_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Comment_Likes_Stddev_Samp_Fields = {
  __typename?: 'mobile_comment_likes_stddev_samp_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Stddev_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_comment_likes" */
export type Mobile_Comment_Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Comment_Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Comment_Likes_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Comment_Likes_Sum_Fields = {
  __typename?: 'mobile_comment_likes_sum_fields';
  comment_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Sum_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "comment_likes" */
export enum Mobile_Comment_Likes_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Comment_Likes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Comment_Likes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Comment_Likes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Comment_Likes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Comment_Likes_Var_Pop_Fields = {
  __typename?: 'mobile_comment_likes_var_pop_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Var_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Comment_Likes_Var_Samp_Fields = {
  __typename?: 'mobile_comment_likes_var_samp_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Var_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Comment_Likes_Variance_Fields = {
  __typename?: 'mobile_comment_likes_variance_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "comment_likes" */
export type Mobile_Comment_Likes_Variance_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "comment_notifications" */
export type Mobile_Comment_Notifications = {
  __typename?: 'mobile_comment_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  comments?: Maybe<Mobile_Comments>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notifications?: Maybe<Mobile_Notifications>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "comment_notifications" */
export type Mobile_Comment_Notifications_Aggregate = {
  __typename?: 'mobile_comment_notifications_aggregate';
  aggregate?: Maybe<Mobile_Comment_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Comment_Notifications>;
};

/** aggregate fields of "comment_notifications" */
export type Mobile_Comment_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_comment_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Comment_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Comment_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Comment_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Comment_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Comment_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Comment_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Comment_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Comment_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Comment_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Comment_Notifications_Variance_Fields>;
};


/** aggregate fields of "comment_notifications" */
export type Mobile_Comment_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Comment_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Comment_Notifications_Avg_Fields = {
  __typename?: 'mobile_comment_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "comment_notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Comment_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Comment_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Comment_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Comment_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  comments?: InputMaybe<Mobile_Comments_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "comment_notifications" */
export type Mobile_Comment_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "comment_notifications" */
export type Mobile_Comment_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  comments?: InputMaybe<Mobile_Comments_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Mobile_Notifications_Obj_Rel_Insert_Input>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Comment_Notifications_Max_Fields = {
  __typename?: 'mobile_comment_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Comment_Notifications_Min_Fields = {
  __typename?: 'mobile_comment_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "comment_notifications" */
export type Mobile_Comment_Notifications_Mutation_Response = {
  __typename?: 'mobile_comment_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Comment_Notifications>;
};

/** input type for inserting object relation for remote table "comment_notifications" */
export type Mobile_Comment_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Comment_Notifications_Insert_Input;
};

/** Ordering options when selecting data from "comment_notifications". */
export type Mobile_Comment_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Mobile_Comments_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Mobile_Notifications_Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "comment_notifications" */
export enum Mobile_Comment_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "comment_notifications" */
export type Mobile_Comment_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Comment_Notifications_Stddev_Fields = {
  __typename?: 'mobile_comment_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Comment_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_comment_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Comment_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_comment_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_comment_notifications" */
export type Mobile_Comment_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Comment_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Comment_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Comment_Notifications_Sum_Fields = {
  __typename?: 'mobile_comment_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Comment_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Comment_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Comment_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Comment_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Comment_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_comment_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Comment_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_comment_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Comment_Notifications_Variance_Fields = {
  __typename?: 'mobile_comment_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "comments" */
export type Mobile_Comments = {
  __typename?: 'mobile_comments';
  /** An object relationship */
  comment?: Maybe<Mobile_Comments>;
  /** An array relationship */
  comment_likes: Array<Mobile_Comment_Likes>;
  /** An aggregate relationship */
  comment_likes_aggregate: Mobile_Comment_Likes_Aggregate;
  /** An array relationship */
  comments: Array<Mobile_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Mobile_Comments_Aggregate;
  comments_count?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message: Scalars['String']['output'];
  /** An object relationship */
  post?: Maybe<Mobile_Posts>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "public_message" */
  public_message?: Maybe<Scalars['String']['output']>;
  ref_comment_id?: Maybe<Scalars['bigint']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "comments" */
export type Mobile_CommentsComment_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type Mobile_CommentsComment_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type Mobile_CommentsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type Mobile_CommentsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};

/** aggregated selection of "comments" */
export type Mobile_Comments_Aggregate = {
  __typename?: 'mobile_comments_aggregate';
  aggregate?: Maybe<Mobile_Comments_Aggregate_Fields>;
  nodes: Array<Mobile_Comments>;
};

export type Mobile_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Comments_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comments" */
export type Mobile_Comments_Aggregate_Fields = {
  __typename?: 'mobile_comments_aggregate_fields';
  avg?: Maybe<Mobile_Comments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Comments_Max_Fields>;
  min?: Maybe<Mobile_Comments_Min_Fields>;
  stddev?: Maybe<Mobile_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Comments_Sum_Fields>;
  var_pop?: Maybe<Mobile_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Comments_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Comments_Variance_Fields>;
};


/** aggregate fields of "comments" */
export type Mobile_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comments" */
export type Mobile_Comments_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Comments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Comments_Max_Order_By>;
  min?: InputMaybe<Mobile_Comments_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Comments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Comments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Comments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Comments_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Comments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Comments_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comments" */
export type Mobile_Comments_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Comments_Avg_Fields = {
  __typename?: 'mobile_comments_avg_fields';
  comments_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  ref_comment_id?: Maybe<Scalars['Float']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "comments" */
export type Mobile_Comments_Avg_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comments". All fields are combined with a logical 'AND'. */
export type Mobile_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Comments_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Comments_Bool_Exp>>;
  comment?: InputMaybe<Mobile_Comments_Bool_Exp>;
  comment_likes?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
  comment_likes_aggregate?: InputMaybe<Mobile_Comment_Likes_Aggregate_Bool_Exp>;
  comments?: InputMaybe<Mobile_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Bool_Exp>;
  comments_count?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  post?: InputMaybe<Mobile_Posts_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  public_message?: InputMaybe<String_Comparison_Exp>;
  ref_comment_id?: InputMaybe<Bigint_Comparison_Exp>;
  ref_parent_comment_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "comments" */
export enum Mobile_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentsPkey = 'comments_pkey'
}

/** input type for incrementing numeric columns in table "comments" */
export type Mobile_Comments_Inc_Input = {
  comments_count?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_parent_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "comments" */
export type Mobile_Comments_Insert_Input = {
  comment?: InputMaybe<Mobile_Comments_Obj_Rel_Insert_Input>;
  comment_likes?: InputMaybe<Mobile_Comment_Likes_Arr_Rel_Insert_Input>;
  comments?: InputMaybe<Mobile_Comments_Arr_Rel_Insert_Input>;
  comments_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<Mobile_Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_parent_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Comments_Max_Fields = {
  __typename?: 'mobile_comments_max_fields';
  comments_count?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "public_message" */
  public_message?: Maybe<Scalars['String']['output']>;
  ref_comment_id?: Maybe<Scalars['bigint']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "comments" */
export type Mobile_Comments_Max_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Comments_Min_Fields = {
  __typename?: 'mobile_comments_min_fields';
  comments_count?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "public_message" */
  public_message?: Maybe<Scalars['String']['output']>;
  ref_comment_id?: Maybe<Scalars['bigint']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "comments" */
export type Mobile_Comments_Min_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments" */
export type Mobile_Comments_Mutation_Response = {
  __typename?: 'mobile_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Comments>;
};

/** input type for inserting object relation for remote table "comments" */
export type Mobile_Comments_Obj_Rel_Insert_Input = {
  data: Mobile_Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Comments_On_Conflict>;
};

/** on_conflict condition type for table "comments" */
export type Mobile_Comments_On_Conflict = {
  constraint: Mobile_Comments_Constraint;
  update_columns?: Array<Mobile_Comments_Update_Column>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "comments". */
export type Mobile_Comments_Order_By = {
  comment?: InputMaybe<Mobile_Comments_Order_By>;
  comment_likes_aggregate?: InputMaybe<Mobile_Comment_Likes_Aggregate_Order_By>;
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Order_By>;
  comments_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post?: InputMaybe<Mobile_Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  public_message?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comments */
export type Mobile_Comments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "comments" */
export enum Mobile_Comments_Select_Column {
  /** column name */
  CommentsCount = 'comments_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RefCommentId = 'ref_comment_id',
  /** column name */
  RefParentCommentId = 'ref_parent_comment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comments" */
export type Mobile_Comments_Set_Input = {
  comments_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_parent_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Comments_Stddev_Fields = {
  __typename?: 'mobile_comments_stddev_fields';
  comments_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  ref_comment_id?: Maybe<Scalars['Float']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "comments" */
export type Mobile_Comments_Stddev_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Comments_Stddev_Pop_Fields = {
  __typename?: 'mobile_comments_stddev_pop_fields';
  comments_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  ref_comment_id?: Maybe<Scalars['Float']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "comments" */
export type Mobile_Comments_Stddev_Pop_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Comments_Stddev_Samp_Fields = {
  __typename?: 'mobile_comments_stddev_samp_fields';
  comments_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  ref_comment_id?: Maybe<Scalars['Float']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "comments" */
export type Mobile_Comments_Stddev_Samp_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_comments" */
export type Mobile_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Comments_Stream_Cursor_Value_Input = {
  comments_count?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  ref_parent_comment_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Comments_Sum_Fields = {
  __typename?: 'mobile_comments_sum_fields';
  comments_count?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  ref_comment_id?: Maybe<Scalars['bigint']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "comments" */
export type Mobile_Comments_Sum_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "comments" */
export enum Mobile_Comments_Update_Column {
  /** column name */
  CommentsCount = 'comments_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RefCommentId = 'ref_comment_id',
  /** column name */
  RefParentCommentId = 'ref_parent_comment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Comments_Var_Pop_Fields = {
  __typename?: 'mobile_comments_var_pop_fields';
  comments_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  ref_comment_id?: Maybe<Scalars['Float']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "comments" */
export type Mobile_Comments_Var_Pop_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Comments_Var_Samp_Fields = {
  __typename?: 'mobile_comments_var_samp_fields';
  comments_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  ref_comment_id?: Maybe<Scalars['Float']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "comments" */
export type Mobile_Comments_Var_Samp_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Comments_Variance_Fields = {
  __typename?: 'mobile_comments_variance_fields';
  comments_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  ref_comment_id?: Maybe<Scalars['Float']['output']>;
  ref_parent_comment_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "comments" */
export type Mobile_Comments_Variance_Order_By = {
  comments_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  ref_comment_id?: InputMaybe<Order_By>;
  ref_parent_comment_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "confirmation_codes" */
export type Mobile_Confirmation_Codes = {
  __typename?: 'mobile_confirmation_codes';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  setting?: Maybe<Mobile_Settings>;
  setting_id?: Maybe<Scalars['bigint']['output']>;
  telegram_code?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "confirmation_codes" */
export type Mobile_Confirmation_Codes_Aggregate = {
  __typename?: 'mobile_confirmation_codes_aggregate';
  aggregate?: Maybe<Mobile_Confirmation_Codes_Aggregate_Fields>;
  nodes: Array<Mobile_Confirmation_Codes>;
};

export type Mobile_Confirmation_Codes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Confirmation_Codes_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Confirmation_Codes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "confirmation_codes" */
export type Mobile_Confirmation_Codes_Aggregate_Fields = {
  __typename?: 'mobile_confirmation_codes_aggregate_fields';
  avg?: Maybe<Mobile_Confirmation_Codes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Confirmation_Codes_Max_Fields>;
  min?: Maybe<Mobile_Confirmation_Codes_Min_Fields>;
  stddev?: Maybe<Mobile_Confirmation_Codes_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Confirmation_Codes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Confirmation_Codes_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Confirmation_Codes_Sum_Fields>;
  var_pop?: Maybe<Mobile_Confirmation_Codes_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Confirmation_Codes_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Confirmation_Codes_Variance_Fields>;
};


/** aggregate fields of "confirmation_codes" */
export type Mobile_Confirmation_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Confirmation_Codes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Confirmation_Codes_Max_Order_By>;
  min?: InputMaybe<Mobile_Confirmation_Codes_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Confirmation_Codes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Confirmation_Codes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Confirmation_Codes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Confirmation_Codes_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Confirmation_Codes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Confirmation_Codes_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Confirmation_Codes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Confirmation_Codes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Confirmation_Codes_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Confirmation_Codes_Avg_Fields = {
  __typename?: 'mobile_confirmation_codes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  setting_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "confirmation_codes". All fields are combined with a logical 'AND'. */
export type Mobile_Confirmation_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Confirmation_Codes_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Confirmation_Codes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  setting?: InputMaybe<Mobile_Settings_Bool_Exp>;
  setting_id?: InputMaybe<Bigint_Comparison_Exp>;
  telegram_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "confirmation_codes" */
export enum Mobile_Confirmation_Codes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConfirmationCodesPkey = 'confirmation_codes_pkey',
  /** unique or primary key constraint on columns "profile_id" */
  IndexConfirmationCodesOnProfileId = 'index_confirmation_codes_on_profile_id'
}

/** input type for incrementing numeric columns in table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  setting_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  setting?: InputMaybe<Mobile_Settings_Obj_Rel_Insert_Input>;
  setting_id?: InputMaybe<Scalars['bigint']['input']>;
  telegram_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Confirmation_Codes_Max_Fields = {
  __typename?: 'mobile_confirmation_codes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  setting_id?: Maybe<Scalars['bigint']['output']>;
  telegram_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  telegram_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Confirmation_Codes_Min_Fields = {
  __typename?: 'mobile_confirmation_codes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  setting_id?: Maybe<Scalars['bigint']['output']>;
  telegram_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  telegram_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Mutation_Response = {
  __typename?: 'mobile_confirmation_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Confirmation_Codes>;
};

/** on_conflict condition type for table "confirmation_codes" */
export type Mobile_Confirmation_Codes_On_Conflict = {
  constraint: Mobile_Confirmation_Codes_Constraint;
  update_columns?: Array<Mobile_Confirmation_Codes_Update_Column>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "confirmation_codes". */
export type Mobile_Confirmation_Codes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting?: InputMaybe<Mobile_Settings_Order_By>;
  setting_id?: InputMaybe<Order_By>;
  telegram_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: confirmation_codes */
export type Mobile_Confirmation_Codes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "confirmation_codes" */
export enum Mobile_Confirmation_Codes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SettingId = 'setting_id',
  /** column name */
  TelegramCode = 'telegram_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  setting_id?: InputMaybe<Scalars['bigint']['input']>;
  telegram_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Confirmation_Codes_Stddev_Fields = {
  __typename?: 'mobile_confirmation_codes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  setting_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Confirmation_Codes_Stddev_Pop_Fields = {
  __typename?: 'mobile_confirmation_codes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  setting_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Confirmation_Codes_Stddev_Samp_Fields = {
  __typename?: 'mobile_confirmation_codes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  setting_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_confirmation_codes" */
export type Mobile_Confirmation_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Confirmation_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Confirmation_Codes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  setting_id?: InputMaybe<Scalars['bigint']['input']>;
  telegram_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Confirmation_Codes_Sum_Fields = {
  __typename?: 'mobile_confirmation_codes_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  setting_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "confirmation_codes" */
export enum Mobile_Confirmation_Codes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SettingId = 'setting_id',
  /** column name */
  TelegramCode = 'telegram_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Confirmation_Codes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Confirmation_Codes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Confirmation_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Confirmation_Codes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Confirmation_Codes_Var_Pop_Fields = {
  __typename?: 'mobile_confirmation_codes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  setting_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Confirmation_Codes_Var_Samp_Fields = {
  __typename?: 'mobile_confirmation_codes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  setting_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Confirmation_Codes_Variance_Fields = {
  __typename?: 'mobile_confirmation_codes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  setting_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "confirmation_codes" */
export type Mobile_Confirmation_Codes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  setting_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "contacts" */
export type Mobile_Contacts = {
  __typename?: 'mobile_contacts';
  created_at: Scalars['timestamptz']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  invite_email_id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  phones: Array<Mobile_Phones>;
  /** An aggregate relationship */
  phones_aggregate: Mobile_Phones_Aggregate;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};


/** columns and relationships of "contacts" */
export type Mobile_ContactsPhonesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};


/** columns and relationships of "contacts" */
export type Mobile_ContactsPhones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};

/** aggregated selection of "contacts" */
export type Mobile_Contacts_Aggregate = {
  __typename?: 'mobile_contacts_aggregate';
  aggregate?: Maybe<Mobile_Contacts_Aggregate_Fields>;
  nodes: Array<Mobile_Contacts>;
};

export type Mobile_Contacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Contacts_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Contacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Contacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contacts" */
export type Mobile_Contacts_Aggregate_Fields = {
  __typename?: 'mobile_contacts_aggregate_fields';
  avg?: Maybe<Mobile_Contacts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Contacts_Max_Fields>;
  min?: Maybe<Mobile_Contacts_Min_Fields>;
  stddev?: Maybe<Mobile_Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Contacts_Sum_Fields>;
  var_pop?: Maybe<Mobile_Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Contacts_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Contacts_Variance_Fields>;
};


/** aggregate fields of "contacts" */
export type Mobile_Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "contacts" */
export type Mobile_Contacts_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Contacts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Contacts_Max_Order_By>;
  min?: InputMaybe<Mobile_Contacts_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Contacts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Contacts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Contacts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Contacts_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Contacts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Contacts_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Contacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contacts" */
export type Mobile_Contacts_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Contacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Contacts_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Contacts_Avg_Fields = {
  __typename?: 'mobile_contacts_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  invite_email_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "contacts" */
export type Mobile_Contacts_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Mobile_Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Contacts_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Contacts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invite_email_id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phones?: InputMaybe<Mobile_Phones_Bool_Exp>;
  phones_aggregate?: InputMaybe<Mobile_Phones_Aggregate_Bool_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Mobile_Contacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactsPkey = 'contacts_pkey'
}

/** input type for incrementing numeric columns in table "contacts" */
export type Mobile_Contacts_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  invite_email_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "contacts" */
export type Mobile_Contacts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invite_email_id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Mobile_Phones_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Contacts_Max_Fields = {
  __typename?: 'mobile_contacts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  invite_email_id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "contacts" */
export type Mobile_Contacts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Contacts_Min_Fields = {
  __typename?: 'mobile_contacts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  invite_email_id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "contacts" */
export type Mobile_Contacts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contacts" */
export type Mobile_Contacts_Mutation_Response = {
  __typename?: 'mobile_contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Contacts>;
};

/** input type for inserting object relation for remote table "contacts" */
export type Mobile_Contacts_Obj_Rel_Insert_Input = {
  data: Mobile_Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Contacts_On_Conflict>;
};

/** on_conflict condition type for table "contacts" */
export type Mobile_Contacts_On_Conflict = {
  constraint: Mobile_Contacts_Constraint;
  update_columns?: Array<Mobile_Contacts_Update_Column>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "contacts". */
export type Mobile_Contacts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phones_aggregate?: InputMaybe<Mobile_Phones_Aggregate_Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contacts */
export type Mobile_Contacts_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "contacts" */
export enum Mobile_Contacts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InviteEmailId = 'invite_email_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "contacts" */
export type Mobile_Contacts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invite_email_id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Contacts_Stddev_Fields = {
  __typename?: 'mobile_contacts_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  invite_email_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "contacts" */
export type Mobile_Contacts_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Contacts_Stddev_Pop_Fields = {
  __typename?: 'mobile_contacts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  invite_email_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "contacts" */
export type Mobile_Contacts_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Contacts_Stddev_Samp_Fields = {
  __typename?: 'mobile_contacts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  invite_email_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "contacts" */
export type Mobile_Contacts_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_contacts" */
export type Mobile_Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Contacts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invite_email_id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Contacts_Sum_Fields = {
  __typename?: 'mobile_contacts_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  invite_email_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "contacts" */
export type Mobile_Contacts_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "contacts" */
export enum Mobile_Contacts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InviteEmailId = 'invite_email_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Contacts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Contacts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Contacts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Contacts_Var_Pop_Fields = {
  __typename?: 'mobile_contacts_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  invite_email_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "contacts" */
export type Mobile_Contacts_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Contacts_Var_Samp_Fields = {
  __typename?: 'mobile_contacts_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  invite_email_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "contacts" */
export type Mobile_Contacts_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Contacts_Variance_Fields = {
  __typename?: 'mobile_contacts_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  invite_email_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "contacts" */
export type Mobile_Contacts_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  invite_email_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "contents" */
export type Mobile_Contents = {
  __typename?: 'mobile_contents';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  posts: Array<Mobile_Posts>;
  /** An aggregate relationship */
  posts_aggregate: Mobile_Posts_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "contents" */
export type Mobile_ContentsPostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type Mobile_ContentsPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};

/** aggregated selection of "contents" */
export type Mobile_Contents_Aggregate = {
  __typename?: 'mobile_contents_aggregate';
  aggregate?: Maybe<Mobile_Contents_Aggregate_Fields>;
  nodes: Array<Mobile_Contents>;
};

/** aggregate fields of "contents" */
export type Mobile_Contents_Aggregate_Fields = {
  __typename?: 'mobile_contents_aggregate_fields';
  avg?: Maybe<Mobile_Contents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Contents_Max_Fields>;
  min?: Maybe<Mobile_Contents_Min_Fields>;
  stddev?: Maybe<Mobile_Contents_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Contents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Contents_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Contents_Sum_Fields>;
  var_pop?: Maybe<Mobile_Contents_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Contents_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Contents_Variance_Fields>;
};


/** aggregate fields of "contents" */
export type Mobile_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Contents_Avg_Fields = {
  __typename?: 'mobile_contents_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contents". All fields are combined with a logical 'AND'. */
export type Mobile_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Contents_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Contents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  posts?: InputMaybe<Mobile_Posts_Bool_Exp>;
  posts_aggregate?: InputMaybe<Mobile_Posts_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contents" */
export enum Mobile_Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentsPkey = 'contents_pkey'
}

/** input type for incrementing numeric columns in table "contents" */
export type Mobile_Contents_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "contents" */
export type Mobile_Contents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  posts?: InputMaybe<Mobile_Posts_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Contents_Max_Fields = {
  __typename?: 'mobile_contents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Contents_Min_Fields = {
  __typename?: 'mobile_contents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "contents" */
export type Mobile_Contents_Mutation_Response = {
  __typename?: 'mobile_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Contents>;
};

/** input type for inserting object relation for remote table "contents" */
export type Mobile_Contents_Obj_Rel_Insert_Input = {
  data: Mobile_Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Contents_On_Conflict>;
};

/** on_conflict condition type for table "contents" */
export type Mobile_Contents_On_Conflict = {
  constraint: Mobile_Contents_Constraint;
  update_columns?: Array<Mobile_Contents_Update_Column>;
  where?: InputMaybe<Mobile_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "contents". */
export type Mobile_Contents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  posts_aggregate?: InputMaybe<Mobile_Posts_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contents */
export type Mobile_Contents_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "contents" */
export enum Mobile_Contents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contents" */
export type Mobile_Contents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Contents_Stddev_Fields = {
  __typename?: 'mobile_contents_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Contents_Stddev_Pop_Fields = {
  __typename?: 'mobile_contents_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Contents_Stddev_Samp_Fields = {
  __typename?: 'mobile_contents_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_contents" */
export type Mobile_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Contents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Contents_Sum_Fields = {
  __typename?: 'mobile_contents_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "contents" */
export enum Mobile_Contents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Contents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Contents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Contents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Contents_Var_Pop_Fields = {
  __typename?: 'mobile_contents_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Contents_Var_Samp_Fields = {
  __typename?: 'mobile_contents_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Contents_Variance_Fields = {
  __typename?: 'mobile_contents_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "device_infos" */
export type Mobile_Device_Infos = {
  __typename?: 'mobile_device_infos';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  device?: Maybe<Mobile_Devices>;
  device_id?: Maybe<Scalars['bigint']['output']>;
  get_android_id?: Maybe<Scalars['String']['output']>;
  get_api_level?: Maybe<Scalars['bigint']['output']>;
  get_application_name?: Maybe<Scalars['String']['output']>;
  get_available_location_providers?: Maybe<Scalars['jsonb']['output']>;
  get_base_os?: Maybe<Scalars['String']['output']>;
  get_battery_level?: Maybe<Scalars['float8']['output']>;
  get_bootloader?: Maybe<Scalars['String']['output']>;
  get_brand?: Maybe<Scalars['String']['output']>;
  get_brightness?: Maybe<Scalars['float8']['output']>;
  get_build_id?: Maybe<Scalars['String']['output']>;
  get_build_number?: Maybe<Scalars['String']['output']>;
  get_bundle_id?: Maybe<Scalars['String']['output']>;
  get_carrier?: Maybe<Scalars['String']['output']>;
  get_codename?: Maybe<Scalars['String']['output']>;
  get_device?: Maybe<Scalars['String']['output']>;
  get_device_id?: Maybe<Scalars['String']['output']>;
  get_device_name?: Maybe<Scalars['String']['output']>;
  get_device_token?: Maybe<Scalars['String']['output']>;
  get_device_type?: Maybe<Scalars['String']['output']>;
  get_display?: Maybe<Scalars['String']['output']>;
  get_fingerprint?: Maybe<Scalars['String']['output']>;
  get_first_install_time?: Maybe<Scalars['bigint']['output']>;
  get_font_scale?: Maybe<Scalars['float8']['output']>;
  get_free_disk_storage?: Maybe<Scalars['bigint']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['bigint']['output']>;
  get_hardware?: Maybe<Scalars['String']['output']>;
  get_host?: Maybe<Scalars['String']['output']>;
  get_incremental?: Maybe<Scalars['String']['output']>;
  get_install_referrer?: Maybe<Scalars['String']['output']>;
  get_installer_package_name?: Maybe<Scalars['String']['output']>;
  get_instance_id?: Maybe<Scalars['String']['output']>;
  get_ip_address?: Maybe<Scalars['String']['output']>;
  get_last_update_time?: Maybe<Scalars['bigint']['output']>;
  get_mac_address?: Maybe<Scalars['String']['output']>;
  get_manufacturer?: Maybe<Scalars['String']['output']>;
  get_max_memory?: Maybe<Scalars['bigint']['output']>;
  get_model?: Maybe<Scalars['String']['output']>;
  get_phone_number?: Maybe<Scalars['String']['output']>;
  get_power_state?: Maybe<Scalars['jsonb']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['bigint']['output']>;
  get_product?: Maybe<Scalars['String']['output']>;
  get_readable_version?: Maybe<Scalars['String']['output']>;
  get_security_patch?: Maybe<Scalars['String']['output']>;
  get_serial_number?: Maybe<Scalars['String']['output']>;
  get_system_available_features?: Maybe<Array<Scalars['String']['output']>>;
  get_system_name?: Maybe<Scalars['String']['output']>;
  get_system_version?: Maybe<Scalars['String']['output']>;
  get_tags?: Maybe<Scalars['String']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['bigint']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['bigint']['output']>;
  get_total_memory?: Maybe<Scalars['bigint']['output']>;
  get_type?: Maybe<Scalars['String']['output']>;
  get_unique_id?: Maybe<Scalars['String']['output']>;
  get_used_memory?: Maybe<Scalars['bigint']['output']>;
  get_user_agent?: Maybe<Scalars['String']['output']>;
  get_user_agent_sync?: Maybe<Scalars['String']['output']>;
  get_version?: Maybe<Scalars['String']['output']>;
  has_dynamic_island?: Maybe<Scalars['Boolean']['output']>;
  has_gms?: Maybe<Scalars['Boolean']['output']>;
  has_hms?: Maybe<Scalars['Boolean']['output']>;
  has_notch?: Maybe<Scalars['Boolean']['output']>;
  has_system_feature?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['bigint']['output'];
  is_airplane_mode?: Maybe<Scalars['Boolean']['output']>;
  is_battery_charging?: Maybe<Scalars['Boolean']['output']>;
  is_camera_present?: Maybe<Scalars['Boolean']['output']>;
  is_display_zoomed?: Maybe<Scalars['Boolean']['output']>;
  is_emulator?: Maybe<Scalars['Boolean']['output']>;
  is_headphones_connected?: Maybe<Scalars['Boolean']['output']>;
  is_landscape?: Maybe<Scalars['Boolean']['output']>;
  is_location_enabled?: Maybe<Scalars['Boolean']['output']>;
  is_pin_or_fingerprint_set?: Maybe<Scalars['Boolean']['output']>;
  is_tablet?: Maybe<Scalars['Boolean']['output']>;
  supported_32_bit_abis?: Maybe<Array<Scalars['String']['output']>>;
  supported_64_bit_abis?: Maybe<Array<Scalars['String']['output']>>;
  supported_abis?: Maybe<Array<Scalars['String']['output']>>;
  sync_unique_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "device_infos" */
export type Mobile_Device_InfosGet_Available_Location_ProvidersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "device_infos" */
export type Mobile_Device_InfosGet_Power_StateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "device_infos" */
export type Mobile_Device_Infos_Aggregate = {
  __typename?: 'mobile_device_infos_aggregate';
  aggregate?: Maybe<Mobile_Device_Infos_Aggregate_Fields>;
  nodes: Array<Mobile_Device_Infos>;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp_Var_Samp>;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Avg = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Corr = {
  arguments: Mobile_Device_Infos_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Mobile_Device_Infos_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Max = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Min = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Sum = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Device_Infos_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "device_infos" */
export type Mobile_Device_Infos_Aggregate_Fields = {
  __typename?: 'mobile_device_infos_aggregate_fields';
  avg?: Maybe<Mobile_Device_Infos_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Device_Infos_Max_Fields>;
  min?: Maybe<Mobile_Device_Infos_Min_Fields>;
  stddev?: Maybe<Mobile_Device_Infos_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Device_Infos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Device_Infos_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Device_Infos_Sum_Fields>;
  var_pop?: Maybe<Mobile_Device_Infos_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Device_Infos_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Device_Infos_Variance_Fields>;
};


/** aggregate fields of "device_infos" */
export type Mobile_Device_Infos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "device_infos" */
export type Mobile_Device_Infos_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Device_Infos_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Device_Infos_Max_Order_By>;
  min?: InputMaybe<Mobile_Device_Infos_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Device_Infos_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Device_Infos_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Device_Infos_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Device_Infos_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Device_Infos_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Device_Infos_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Device_Infos_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Device_Infos_Append_Input = {
  get_available_location_providers?: InputMaybe<Scalars['jsonb']['input']>;
  get_power_state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "device_infos" */
export type Mobile_Device_Infos_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Device_Infos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Device_Infos_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Device_Infos_Avg_Fields = {
  __typename?: 'mobile_device_infos_avg_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  get_api_level?: Maybe<Scalars['Float']['output']>;
  get_battery_level?: Maybe<Scalars['Float']['output']>;
  get_brightness?: Maybe<Scalars['Float']['output']>;
  get_first_install_time?: Maybe<Scalars['Float']['output']>;
  get_font_scale?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['Float']['output']>;
  get_last_update_time?: Maybe<Scalars['Float']['output']>;
  get_max_memory?: Maybe<Scalars['Float']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['Float']['output']>;
  get_total_memory?: Maybe<Scalars['Float']['output']>;
  get_used_memory?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "device_infos" */
export type Mobile_Device_Infos_Avg_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "device_infos". All fields are combined with a logical 'AND'. */
export type Mobile_Device_Infos_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Device_Infos_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Device_Infos_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  device?: InputMaybe<Mobile_Devices_Bool_Exp>;
  device_id?: InputMaybe<Bigint_Comparison_Exp>;
  get_android_id?: InputMaybe<String_Comparison_Exp>;
  get_api_level?: InputMaybe<Bigint_Comparison_Exp>;
  get_application_name?: InputMaybe<String_Comparison_Exp>;
  get_available_location_providers?: InputMaybe<Jsonb_Comparison_Exp>;
  get_base_os?: InputMaybe<String_Comparison_Exp>;
  get_battery_level?: InputMaybe<Float8_Comparison_Exp>;
  get_bootloader?: InputMaybe<String_Comparison_Exp>;
  get_brand?: InputMaybe<String_Comparison_Exp>;
  get_brightness?: InputMaybe<Float8_Comparison_Exp>;
  get_build_id?: InputMaybe<String_Comparison_Exp>;
  get_build_number?: InputMaybe<String_Comparison_Exp>;
  get_bundle_id?: InputMaybe<String_Comparison_Exp>;
  get_carrier?: InputMaybe<String_Comparison_Exp>;
  get_codename?: InputMaybe<String_Comparison_Exp>;
  get_device?: InputMaybe<String_Comparison_Exp>;
  get_device_id?: InputMaybe<String_Comparison_Exp>;
  get_device_name?: InputMaybe<String_Comparison_Exp>;
  get_device_token?: InputMaybe<String_Comparison_Exp>;
  get_device_type?: InputMaybe<String_Comparison_Exp>;
  get_display?: InputMaybe<String_Comparison_Exp>;
  get_fingerprint?: InputMaybe<String_Comparison_Exp>;
  get_first_install_time?: InputMaybe<Bigint_Comparison_Exp>;
  get_font_scale?: InputMaybe<Float8_Comparison_Exp>;
  get_free_disk_storage?: InputMaybe<Bigint_Comparison_Exp>;
  get_free_disk_storage_old?: InputMaybe<Bigint_Comparison_Exp>;
  get_hardware?: InputMaybe<String_Comparison_Exp>;
  get_host?: InputMaybe<String_Comparison_Exp>;
  get_incremental?: InputMaybe<String_Comparison_Exp>;
  get_install_referrer?: InputMaybe<String_Comparison_Exp>;
  get_installer_package_name?: InputMaybe<String_Comparison_Exp>;
  get_instance_id?: InputMaybe<String_Comparison_Exp>;
  get_ip_address?: InputMaybe<String_Comparison_Exp>;
  get_last_update_time?: InputMaybe<Bigint_Comparison_Exp>;
  get_mac_address?: InputMaybe<String_Comparison_Exp>;
  get_manufacturer?: InputMaybe<String_Comparison_Exp>;
  get_max_memory?: InputMaybe<Bigint_Comparison_Exp>;
  get_model?: InputMaybe<String_Comparison_Exp>;
  get_phone_number?: InputMaybe<String_Comparison_Exp>;
  get_power_state?: InputMaybe<Jsonb_Comparison_Exp>;
  get_preview_sdk_int?: InputMaybe<Bigint_Comparison_Exp>;
  get_product?: InputMaybe<String_Comparison_Exp>;
  get_readable_version?: InputMaybe<String_Comparison_Exp>;
  get_security_patch?: InputMaybe<String_Comparison_Exp>;
  get_serial_number?: InputMaybe<String_Comparison_Exp>;
  get_system_available_features?: InputMaybe<String_Array_Comparison_Exp>;
  get_system_name?: InputMaybe<String_Comparison_Exp>;
  get_system_version?: InputMaybe<String_Comparison_Exp>;
  get_tags?: InputMaybe<String_Comparison_Exp>;
  get_total_disk_capacity?: InputMaybe<Bigint_Comparison_Exp>;
  get_total_disk_capacity_old?: InputMaybe<Bigint_Comparison_Exp>;
  get_total_memory?: InputMaybe<Bigint_Comparison_Exp>;
  get_type?: InputMaybe<String_Comparison_Exp>;
  get_unique_id?: InputMaybe<String_Comparison_Exp>;
  get_used_memory?: InputMaybe<Bigint_Comparison_Exp>;
  get_user_agent?: InputMaybe<String_Comparison_Exp>;
  get_user_agent_sync?: InputMaybe<String_Comparison_Exp>;
  get_version?: InputMaybe<String_Comparison_Exp>;
  has_dynamic_island?: InputMaybe<Boolean_Comparison_Exp>;
  has_gms?: InputMaybe<Boolean_Comparison_Exp>;
  has_hms?: InputMaybe<Boolean_Comparison_Exp>;
  has_notch?: InputMaybe<Boolean_Comparison_Exp>;
  has_system_feature?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_airplane_mode?: InputMaybe<Boolean_Comparison_Exp>;
  is_battery_charging?: InputMaybe<Boolean_Comparison_Exp>;
  is_camera_present?: InputMaybe<Boolean_Comparison_Exp>;
  is_display_zoomed?: InputMaybe<Boolean_Comparison_Exp>;
  is_emulator?: InputMaybe<Boolean_Comparison_Exp>;
  is_headphones_connected?: InputMaybe<Boolean_Comparison_Exp>;
  is_landscape?: InputMaybe<Boolean_Comparison_Exp>;
  is_location_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_pin_or_fingerprint_set?: InputMaybe<Boolean_Comparison_Exp>;
  is_tablet?: InputMaybe<Boolean_Comparison_Exp>;
  supported_32_bit_abis?: InputMaybe<String_Array_Comparison_Exp>;
  supported_64_bit_abis?: InputMaybe<String_Array_Comparison_Exp>;
  supported_abis?: InputMaybe<String_Array_Comparison_Exp>;
  sync_unique_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "device_infos" */
export enum Mobile_Device_Infos_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeviceInfosPkey = 'device_infos_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mobile_Device_Infos_Delete_At_Path_Input = {
  get_available_location_providers?: InputMaybe<Array<Scalars['String']['input']>>;
  get_power_state?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mobile_Device_Infos_Delete_Elem_Input = {
  get_available_location_providers?: InputMaybe<Scalars['Int']['input']>;
  get_power_state?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mobile_Device_Infos_Delete_Key_Input = {
  get_available_location_providers?: InputMaybe<Scalars['String']['input']>;
  get_power_state?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "device_infos" */
export type Mobile_Device_Infos_Inc_Input = {
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  get_api_level?: InputMaybe<Scalars['bigint']['input']>;
  get_battery_level?: InputMaybe<Scalars['float8']['input']>;
  get_brightness?: InputMaybe<Scalars['float8']['input']>;
  get_first_install_time?: InputMaybe<Scalars['bigint']['input']>;
  get_font_scale?: InputMaybe<Scalars['float8']['input']>;
  get_free_disk_storage?: InputMaybe<Scalars['bigint']['input']>;
  get_free_disk_storage_old?: InputMaybe<Scalars['bigint']['input']>;
  get_last_update_time?: InputMaybe<Scalars['bigint']['input']>;
  get_max_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_preview_sdk_int?: InputMaybe<Scalars['bigint']['input']>;
  get_total_disk_capacity?: InputMaybe<Scalars['bigint']['input']>;
  get_total_disk_capacity_old?: InputMaybe<Scalars['bigint']['input']>;
  get_total_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_used_memory?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "device_infos" */
export type Mobile_Device_Infos_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device?: InputMaybe<Mobile_Devices_Obj_Rel_Insert_Input>;
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  get_android_id?: InputMaybe<Scalars['String']['input']>;
  get_api_level?: InputMaybe<Scalars['bigint']['input']>;
  get_application_name?: InputMaybe<Scalars['String']['input']>;
  get_available_location_providers?: InputMaybe<Scalars['jsonb']['input']>;
  get_base_os?: InputMaybe<Scalars['String']['input']>;
  get_battery_level?: InputMaybe<Scalars['float8']['input']>;
  get_bootloader?: InputMaybe<Scalars['String']['input']>;
  get_brand?: InputMaybe<Scalars['String']['input']>;
  get_brightness?: InputMaybe<Scalars['float8']['input']>;
  get_build_id?: InputMaybe<Scalars['String']['input']>;
  get_build_number?: InputMaybe<Scalars['String']['input']>;
  get_bundle_id?: InputMaybe<Scalars['String']['input']>;
  get_carrier?: InputMaybe<Scalars['String']['input']>;
  get_codename?: InputMaybe<Scalars['String']['input']>;
  get_device?: InputMaybe<Scalars['String']['input']>;
  get_device_id?: InputMaybe<Scalars['String']['input']>;
  get_device_name?: InputMaybe<Scalars['String']['input']>;
  get_device_token?: InputMaybe<Scalars['String']['input']>;
  get_device_type?: InputMaybe<Scalars['String']['input']>;
  get_display?: InputMaybe<Scalars['String']['input']>;
  get_fingerprint?: InputMaybe<Scalars['String']['input']>;
  get_first_install_time?: InputMaybe<Scalars['bigint']['input']>;
  get_font_scale?: InputMaybe<Scalars['float8']['input']>;
  get_free_disk_storage?: InputMaybe<Scalars['bigint']['input']>;
  get_free_disk_storage_old?: InputMaybe<Scalars['bigint']['input']>;
  get_hardware?: InputMaybe<Scalars['String']['input']>;
  get_host?: InputMaybe<Scalars['String']['input']>;
  get_incremental?: InputMaybe<Scalars['String']['input']>;
  get_install_referrer?: InputMaybe<Scalars['String']['input']>;
  get_installer_package_name?: InputMaybe<Scalars['String']['input']>;
  get_instance_id?: InputMaybe<Scalars['String']['input']>;
  get_ip_address?: InputMaybe<Scalars['String']['input']>;
  get_last_update_time?: InputMaybe<Scalars['bigint']['input']>;
  get_mac_address?: InputMaybe<Scalars['String']['input']>;
  get_manufacturer?: InputMaybe<Scalars['String']['input']>;
  get_max_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_model?: InputMaybe<Scalars['String']['input']>;
  get_phone_number?: InputMaybe<Scalars['String']['input']>;
  get_power_state?: InputMaybe<Scalars['jsonb']['input']>;
  get_preview_sdk_int?: InputMaybe<Scalars['bigint']['input']>;
  get_product?: InputMaybe<Scalars['String']['input']>;
  get_readable_version?: InputMaybe<Scalars['String']['input']>;
  get_security_patch?: InputMaybe<Scalars['String']['input']>;
  get_serial_number?: InputMaybe<Scalars['String']['input']>;
  get_system_available_features?: InputMaybe<Array<Scalars['String']['input']>>;
  get_system_name?: InputMaybe<Scalars['String']['input']>;
  get_system_version?: InputMaybe<Scalars['String']['input']>;
  get_tags?: InputMaybe<Scalars['String']['input']>;
  get_total_disk_capacity?: InputMaybe<Scalars['bigint']['input']>;
  get_total_disk_capacity_old?: InputMaybe<Scalars['bigint']['input']>;
  get_total_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_type?: InputMaybe<Scalars['String']['input']>;
  get_unique_id?: InputMaybe<Scalars['String']['input']>;
  get_used_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_user_agent?: InputMaybe<Scalars['String']['input']>;
  get_user_agent_sync?: InputMaybe<Scalars['String']['input']>;
  get_version?: InputMaybe<Scalars['String']['input']>;
  has_dynamic_island?: InputMaybe<Scalars['Boolean']['input']>;
  has_gms?: InputMaybe<Scalars['Boolean']['input']>;
  has_hms?: InputMaybe<Scalars['Boolean']['input']>;
  has_notch?: InputMaybe<Scalars['Boolean']['input']>;
  has_system_feature?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_airplane_mode?: InputMaybe<Scalars['Boolean']['input']>;
  is_battery_charging?: InputMaybe<Scalars['Boolean']['input']>;
  is_camera_present?: InputMaybe<Scalars['Boolean']['input']>;
  is_display_zoomed?: InputMaybe<Scalars['Boolean']['input']>;
  is_emulator?: InputMaybe<Scalars['Boolean']['input']>;
  is_headphones_connected?: InputMaybe<Scalars['Boolean']['input']>;
  is_landscape?: InputMaybe<Scalars['Boolean']['input']>;
  is_location_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_pin_or_fingerprint_set?: InputMaybe<Scalars['Boolean']['input']>;
  is_tablet?: InputMaybe<Scalars['Boolean']['input']>;
  supported_32_bit_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  supported_64_bit_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  supported_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  sync_unique_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Device_Infos_Max_Fields = {
  __typename?: 'mobile_device_infos_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  device_id?: Maybe<Scalars['bigint']['output']>;
  get_android_id?: Maybe<Scalars['String']['output']>;
  get_api_level?: Maybe<Scalars['bigint']['output']>;
  get_application_name?: Maybe<Scalars['String']['output']>;
  get_base_os?: Maybe<Scalars['String']['output']>;
  get_battery_level?: Maybe<Scalars['float8']['output']>;
  get_bootloader?: Maybe<Scalars['String']['output']>;
  get_brand?: Maybe<Scalars['String']['output']>;
  get_brightness?: Maybe<Scalars['float8']['output']>;
  get_build_id?: Maybe<Scalars['String']['output']>;
  get_build_number?: Maybe<Scalars['String']['output']>;
  get_bundle_id?: Maybe<Scalars['String']['output']>;
  get_carrier?: Maybe<Scalars['String']['output']>;
  get_codename?: Maybe<Scalars['String']['output']>;
  get_device?: Maybe<Scalars['String']['output']>;
  get_device_id?: Maybe<Scalars['String']['output']>;
  get_device_name?: Maybe<Scalars['String']['output']>;
  get_device_token?: Maybe<Scalars['String']['output']>;
  get_device_type?: Maybe<Scalars['String']['output']>;
  get_display?: Maybe<Scalars['String']['output']>;
  get_fingerprint?: Maybe<Scalars['String']['output']>;
  get_first_install_time?: Maybe<Scalars['bigint']['output']>;
  get_font_scale?: Maybe<Scalars['float8']['output']>;
  get_free_disk_storage?: Maybe<Scalars['bigint']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['bigint']['output']>;
  get_hardware?: Maybe<Scalars['String']['output']>;
  get_host?: Maybe<Scalars['String']['output']>;
  get_incremental?: Maybe<Scalars['String']['output']>;
  get_install_referrer?: Maybe<Scalars['String']['output']>;
  get_installer_package_name?: Maybe<Scalars['String']['output']>;
  get_instance_id?: Maybe<Scalars['String']['output']>;
  get_ip_address?: Maybe<Scalars['String']['output']>;
  get_last_update_time?: Maybe<Scalars['bigint']['output']>;
  get_mac_address?: Maybe<Scalars['String']['output']>;
  get_manufacturer?: Maybe<Scalars['String']['output']>;
  get_max_memory?: Maybe<Scalars['bigint']['output']>;
  get_model?: Maybe<Scalars['String']['output']>;
  get_phone_number?: Maybe<Scalars['String']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['bigint']['output']>;
  get_product?: Maybe<Scalars['String']['output']>;
  get_readable_version?: Maybe<Scalars['String']['output']>;
  get_security_patch?: Maybe<Scalars['String']['output']>;
  get_serial_number?: Maybe<Scalars['String']['output']>;
  get_system_available_features?: Maybe<Array<Scalars['String']['output']>>;
  get_system_name?: Maybe<Scalars['String']['output']>;
  get_system_version?: Maybe<Scalars['String']['output']>;
  get_tags?: Maybe<Scalars['String']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['bigint']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['bigint']['output']>;
  get_total_memory?: Maybe<Scalars['bigint']['output']>;
  get_type?: Maybe<Scalars['String']['output']>;
  get_unique_id?: Maybe<Scalars['String']['output']>;
  get_used_memory?: Maybe<Scalars['bigint']['output']>;
  get_user_agent?: Maybe<Scalars['String']['output']>;
  get_user_agent_sync?: Maybe<Scalars['String']['output']>;
  get_version?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  supported_32_bit_abis?: Maybe<Array<Scalars['String']['output']>>;
  supported_64_bit_abis?: Maybe<Array<Scalars['String']['output']>>;
  supported_abis?: Maybe<Array<Scalars['String']['output']>>;
  sync_unique_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "device_infos" */
export type Mobile_Device_Infos_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  get_android_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_application_name?: InputMaybe<Order_By>;
  get_base_os?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_bootloader?: InputMaybe<Order_By>;
  get_brand?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_build_id?: InputMaybe<Order_By>;
  get_build_number?: InputMaybe<Order_By>;
  get_bundle_id?: InputMaybe<Order_By>;
  get_carrier?: InputMaybe<Order_By>;
  get_codename?: InputMaybe<Order_By>;
  get_device?: InputMaybe<Order_By>;
  get_device_id?: InputMaybe<Order_By>;
  get_device_name?: InputMaybe<Order_By>;
  get_device_token?: InputMaybe<Order_By>;
  get_device_type?: InputMaybe<Order_By>;
  get_display?: InputMaybe<Order_By>;
  get_fingerprint?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_hardware?: InputMaybe<Order_By>;
  get_host?: InputMaybe<Order_By>;
  get_incremental?: InputMaybe<Order_By>;
  get_install_referrer?: InputMaybe<Order_By>;
  get_installer_package_name?: InputMaybe<Order_By>;
  get_instance_id?: InputMaybe<Order_By>;
  get_ip_address?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_mac_address?: InputMaybe<Order_By>;
  get_manufacturer?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_model?: InputMaybe<Order_By>;
  get_phone_number?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_product?: InputMaybe<Order_By>;
  get_readable_version?: InputMaybe<Order_By>;
  get_security_patch?: InputMaybe<Order_By>;
  get_serial_number?: InputMaybe<Order_By>;
  get_system_available_features?: InputMaybe<Order_By>;
  get_system_name?: InputMaybe<Order_By>;
  get_system_version?: InputMaybe<Order_By>;
  get_tags?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_type?: InputMaybe<Order_By>;
  get_unique_id?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  get_user_agent?: InputMaybe<Order_By>;
  get_user_agent_sync?: InputMaybe<Order_By>;
  get_version?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  supported_32_bit_abis?: InputMaybe<Order_By>;
  supported_64_bit_abis?: InputMaybe<Order_By>;
  supported_abis?: InputMaybe<Order_By>;
  sync_unique_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Device_Infos_Min_Fields = {
  __typename?: 'mobile_device_infos_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  device_id?: Maybe<Scalars['bigint']['output']>;
  get_android_id?: Maybe<Scalars['String']['output']>;
  get_api_level?: Maybe<Scalars['bigint']['output']>;
  get_application_name?: Maybe<Scalars['String']['output']>;
  get_base_os?: Maybe<Scalars['String']['output']>;
  get_battery_level?: Maybe<Scalars['float8']['output']>;
  get_bootloader?: Maybe<Scalars['String']['output']>;
  get_brand?: Maybe<Scalars['String']['output']>;
  get_brightness?: Maybe<Scalars['float8']['output']>;
  get_build_id?: Maybe<Scalars['String']['output']>;
  get_build_number?: Maybe<Scalars['String']['output']>;
  get_bundle_id?: Maybe<Scalars['String']['output']>;
  get_carrier?: Maybe<Scalars['String']['output']>;
  get_codename?: Maybe<Scalars['String']['output']>;
  get_device?: Maybe<Scalars['String']['output']>;
  get_device_id?: Maybe<Scalars['String']['output']>;
  get_device_name?: Maybe<Scalars['String']['output']>;
  get_device_token?: Maybe<Scalars['String']['output']>;
  get_device_type?: Maybe<Scalars['String']['output']>;
  get_display?: Maybe<Scalars['String']['output']>;
  get_fingerprint?: Maybe<Scalars['String']['output']>;
  get_first_install_time?: Maybe<Scalars['bigint']['output']>;
  get_font_scale?: Maybe<Scalars['float8']['output']>;
  get_free_disk_storage?: Maybe<Scalars['bigint']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['bigint']['output']>;
  get_hardware?: Maybe<Scalars['String']['output']>;
  get_host?: Maybe<Scalars['String']['output']>;
  get_incremental?: Maybe<Scalars['String']['output']>;
  get_install_referrer?: Maybe<Scalars['String']['output']>;
  get_installer_package_name?: Maybe<Scalars['String']['output']>;
  get_instance_id?: Maybe<Scalars['String']['output']>;
  get_ip_address?: Maybe<Scalars['String']['output']>;
  get_last_update_time?: Maybe<Scalars['bigint']['output']>;
  get_mac_address?: Maybe<Scalars['String']['output']>;
  get_manufacturer?: Maybe<Scalars['String']['output']>;
  get_max_memory?: Maybe<Scalars['bigint']['output']>;
  get_model?: Maybe<Scalars['String']['output']>;
  get_phone_number?: Maybe<Scalars['String']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['bigint']['output']>;
  get_product?: Maybe<Scalars['String']['output']>;
  get_readable_version?: Maybe<Scalars['String']['output']>;
  get_security_patch?: Maybe<Scalars['String']['output']>;
  get_serial_number?: Maybe<Scalars['String']['output']>;
  get_system_available_features?: Maybe<Array<Scalars['String']['output']>>;
  get_system_name?: Maybe<Scalars['String']['output']>;
  get_system_version?: Maybe<Scalars['String']['output']>;
  get_tags?: Maybe<Scalars['String']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['bigint']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['bigint']['output']>;
  get_total_memory?: Maybe<Scalars['bigint']['output']>;
  get_type?: Maybe<Scalars['String']['output']>;
  get_unique_id?: Maybe<Scalars['String']['output']>;
  get_used_memory?: Maybe<Scalars['bigint']['output']>;
  get_user_agent?: Maybe<Scalars['String']['output']>;
  get_user_agent_sync?: Maybe<Scalars['String']['output']>;
  get_version?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  supported_32_bit_abis?: Maybe<Array<Scalars['String']['output']>>;
  supported_64_bit_abis?: Maybe<Array<Scalars['String']['output']>>;
  supported_abis?: Maybe<Array<Scalars['String']['output']>>;
  sync_unique_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "device_infos" */
export type Mobile_Device_Infos_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  get_android_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_application_name?: InputMaybe<Order_By>;
  get_base_os?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_bootloader?: InputMaybe<Order_By>;
  get_brand?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_build_id?: InputMaybe<Order_By>;
  get_build_number?: InputMaybe<Order_By>;
  get_bundle_id?: InputMaybe<Order_By>;
  get_carrier?: InputMaybe<Order_By>;
  get_codename?: InputMaybe<Order_By>;
  get_device?: InputMaybe<Order_By>;
  get_device_id?: InputMaybe<Order_By>;
  get_device_name?: InputMaybe<Order_By>;
  get_device_token?: InputMaybe<Order_By>;
  get_device_type?: InputMaybe<Order_By>;
  get_display?: InputMaybe<Order_By>;
  get_fingerprint?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_hardware?: InputMaybe<Order_By>;
  get_host?: InputMaybe<Order_By>;
  get_incremental?: InputMaybe<Order_By>;
  get_install_referrer?: InputMaybe<Order_By>;
  get_installer_package_name?: InputMaybe<Order_By>;
  get_instance_id?: InputMaybe<Order_By>;
  get_ip_address?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_mac_address?: InputMaybe<Order_By>;
  get_manufacturer?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_model?: InputMaybe<Order_By>;
  get_phone_number?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_product?: InputMaybe<Order_By>;
  get_readable_version?: InputMaybe<Order_By>;
  get_security_patch?: InputMaybe<Order_By>;
  get_serial_number?: InputMaybe<Order_By>;
  get_system_available_features?: InputMaybe<Order_By>;
  get_system_name?: InputMaybe<Order_By>;
  get_system_version?: InputMaybe<Order_By>;
  get_tags?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_type?: InputMaybe<Order_By>;
  get_unique_id?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  get_user_agent?: InputMaybe<Order_By>;
  get_user_agent_sync?: InputMaybe<Order_By>;
  get_version?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  supported_32_bit_abis?: InputMaybe<Order_By>;
  supported_64_bit_abis?: InputMaybe<Order_By>;
  supported_abis?: InputMaybe<Order_By>;
  sync_unique_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "device_infos" */
export type Mobile_Device_Infos_Mutation_Response = {
  __typename?: 'mobile_device_infos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Device_Infos>;
};

/** on_conflict condition type for table "device_infos" */
export type Mobile_Device_Infos_On_Conflict = {
  constraint: Mobile_Device_Infos_Constraint;
  update_columns?: Array<Mobile_Device_Infos_Update_Column>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};

/** Ordering options when selecting data from "device_infos". */
export type Mobile_Device_Infos_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device?: InputMaybe<Mobile_Devices_Order_By>;
  device_id?: InputMaybe<Order_By>;
  get_android_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_application_name?: InputMaybe<Order_By>;
  get_available_location_providers?: InputMaybe<Order_By>;
  get_base_os?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_bootloader?: InputMaybe<Order_By>;
  get_brand?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_build_id?: InputMaybe<Order_By>;
  get_build_number?: InputMaybe<Order_By>;
  get_bundle_id?: InputMaybe<Order_By>;
  get_carrier?: InputMaybe<Order_By>;
  get_codename?: InputMaybe<Order_By>;
  get_device?: InputMaybe<Order_By>;
  get_device_id?: InputMaybe<Order_By>;
  get_device_name?: InputMaybe<Order_By>;
  get_device_token?: InputMaybe<Order_By>;
  get_device_type?: InputMaybe<Order_By>;
  get_display?: InputMaybe<Order_By>;
  get_fingerprint?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_hardware?: InputMaybe<Order_By>;
  get_host?: InputMaybe<Order_By>;
  get_incremental?: InputMaybe<Order_By>;
  get_install_referrer?: InputMaybe<Order_By>;
  get_installer_package_name?: InputMaybe<Order_By>;
  get_instance_id?: InputMaybe<Order_By>;
  get_ip_address?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_mac_address?: InputMaybe<Order_By>;
  get_manufacturer?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_model?: InputMaybe<Order_By>;
  get_phone_number?: InputMaybe<Order_By>;
  get_power_state?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_product?: InputMaybe<Order_By>;
  get_readable_version?: InputMaybe<Order_By>;
  get_security_patch?: InputMaybe<Order_By>;
  get_serial_number?: InputMaybe<Order_By>;
  get_system_available_features?: InputMaybe<Order_By>;
  get_system_name?: InputMaybe<Order_By>;
  get_system_version?: InputMaybe<Order_By>;
  get_tags?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_type?: InputMaybe<Order_By>;
  get_unique_id?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  get_user_agent?: InputMaybe<Order_By>;
  get_user_agent_sync?: InputMaybe<Order_By>;
  get_version?: InputMaybe<Order_By>;
  has_dynamic_island?: InputMaybe<Order_By>;
  has_gms?: InputMaybe<Order_By>;
  has_hms?: InputMaybe<Order_By>;
  has_notch?: InputMaybe<Order_By>;
  has_system_feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_airplane_mode?: InputMaybe<Order_By>;
  is_battery_charging?: InputMaybe<Order_By>;
  is_camera_present?: InputMaybe<Order_By>;
  is_display_zoomed?: InputMaybe<Order_By>;
  is_emulator?: InputMaybe<Order_By>;
  is_headphones_connected?: InputMaybe<Order_By>;
  is_landscape?: InputMaybe<Order_By>;
  is_location_enabled?: InputMaybe<Order_By>;
  is_pin_or_fingerprint_set?: InputMaybe<Order_By>;
  is_tablet?: InputMaybe<Order_By>;
  supported_32_bit_abis?: InputMaybe<Order_By>;
  supported_64_bit_abis?: InputMaybe<Order_By>;
  supported_abis?: InputMaybe<Order_By>;
  sync_unique_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: device_infos */
export type Mobile_Device_Infos_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Device_Infos_Prepend_Input = {
  get_available_location_providers?: InputMaybe<Scalars['jsonb']['input']>;
  get_power_state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  GetAndroidId = 'get_android_id',
  /** column name */
  GetApiLevel = 'get_api_level',
  /** column name */
  GetApplicationName = 'get_application_name',
  /** column name */
  GetAvailableLocationProviders = 'get_available_location_providers',
  /** column name */
  GetBaseOs = 'get_base_os',
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBootloader = 'get_bootloader',
  /** column name */
  GetBrand = 'get_brand',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetBuildId = 'get_build_id',
  /** column name */
  GetBuildNumber = 'get_build_number',
  /** column name */
  GetBundleId = 'get_bundle_id',
  /** column name */
  GetCarrier = 'get_carrier',
  /** column name */
  GetCodename = 'get_codename',
  /** column name */
  GetDevice = 'get_device',
  /** column name */
  GetDeviceId = 'get_device_id',
  /** column name */
  GetDeviceName = 'get_device_name',
  /** column name */
  GetDeviceToken = 'get_device_token',
  /** column name */
  GetDeviceType = 'get_device_type',
  /** column name */
  GetDisplay = 'get_display',
  /** column name */
  GetFingerprint = 'get_fingerprint',
  /** column name */
  GetFirstInstallTime = 'get_first_install_time',
  /** column name */
  GetFontScale = 'get_font_scale',
  /** column name */
  GetFreeDiskStorage = 'get_free_disk_storage',
  /** column name */
  GetFreeDiskStorageOld = 'get_free_disk_storage_old',
  /** column name */
  GetHardware = 'get_hardware',
  /** column name */
  GetHost = 'get_host',
  /** column name */
  GetIncremental = 'get_incremental',
  /** column name */
  GetInstallReferrer = 'get_install_referrer',
  /** column name */
  GetInstallerPackageName = 'get_installer_package_name',
  /** column name */
  GetInstanceId = 'get_instance_id',
  /** column name */
  GetIpAddress = 'get_ip_address',
  /** column name */
  GetLastUpdateTime = 'get_last_update_time',
  /** column name */
  GetMacAddress = 'get_mac_address',
  /** column name */
  GetManufacturer = 'get_manufacturer',
  /** column name */
  GetMaxMemory = 'get_max_memory',
  /** column name */
  GetModel = 'get_model',
  /** column name */
  GetPhoneNumber = 'get_phone_number',
  /** column name */
  GetPowerState = 'get_power_state',
  /** column name */
  GetPreviewSdkInt = 'get_preview_sdk_int',
  /** column name */
  GetProduct = 'get_product',
  /** column name */
  GetReadableVersion = 'get_readable_version',
  /** column name */
  GetSecurityPatch = 'get_security_patch',
  /** column name */
  GetSerialNumber = 'get_serial_number',
  /** column name */
  GetSystemAvailableFeatures = 'get_system_available_features',
  /** column name */
  GetSystemName = 'get_system_name',
  /** column name */
  GetSystemVersion = 'get_system_version',
  /** column name */
  GetTags = 'get_tags',
  /** column name */
  GetTotalDiskCapacity = 'get_total_disk_capacity',
  /** column name */
  GetTotalDiskCapacityOld = 'get_total_disk_capacity_old',
  /** column name */
  GetTotalMemory = 'get_total_memory',
  /** column name */
  GetType = 'get_type',
  /** column name */
  GetUniqueId = 'get_unique_id',
  /** column name */
  GetUsedMemory = 'get_used_memory',
  /** column name */
  GetUserAgent = 'get_user_agent',
  /** column name */
  GetUserAgentSync = 'get_user_agent_sync',
  /** column name */
  GetVersion = 'get_version',
  /** column name */
  HasDynamicIsland = 'has_dynamic_island',
  /** column name */
  HasGms = 'has_gms',
  /** column name */
  HasHms = 'has_hms',
  /** column name */
  HasNotch = 'has_notch',
  /** column name */
  HasSystemFeature = 'has_system_feature',
  /** column name */
  Id = 'id',
  /** column name */
  IsAirplaneMode = 'is_airplane_mode',
  /** column name */
  IsBatteryCharging = 'is_battery_charging',
  /** column name */
  IsCameraPresent = 'is_camera_present',
  /** column name */
  IsDisplayZoomed = 'is_display_zoomed',
  /** column name */
  IsEmulator = 'is_emulator',
  /** column name */
  IsHeadphonesConnected = 'is_headphones_connected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsLocationEnabled = 'is_location_enabled',
  /** column name */
  IsPinOrFingerprintSet = 'is_pin_or_fingerprint_set',
  /** column name */
  IsTablet = 'is_tablet',
  /** column name */
  Supported_32BitAbis = 'supported_32_bit_abis',
  /** column name */
  Supported_64BitAbis = 'supported_64_bit_abis',
  /** column name */
  SupportedAbis = 'supported_abis',
  /** column name */
  SyncUniqueId = 'sync_unique_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "mobile_device_infos_aggregate_bool_exp_avg_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** select "mobile_device_infos_aggregate_bool_exp_bool_and_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasDynamicIsland = 'has_dynamic_island',
  /** column name */
  HasGms = 'has_gms',
  /** column name */
  HasHms = 'has_hms',
  /** column name */
  HasNotch = 'has_notch',
  /** column name */
  HasSystemFeature = 'has_system_feature',
  /** column name */
  IsAirplaneMode = 'is_airplane_mode',
  /** column name */
  IsBatteryCharging = 'is_battery_charging',
  /** column name */
  IsCameraPresent = 'is_camera_present',
  /** column name */
  IsDisplayZoomed = 'is_display_zoomed',
  /** column name */
  IsEmulator = 'is_emulator',
  /** column name */
  IsHeadphonesConnected = 'is_headphones_connected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsLocationEnabled = 'is_location_enabled',
  /** column name */
  IsPinOrFingerprintSet = 'is_pin_or_fingerprint_set',
  /** column name */
  IsTablet = 'is_tablet'
}

/** select "mobile_device_infos_aggregate_bool_exp_bool_or_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasDynamicIsland = 'has_dynamic_island',
  /** column name */
  HasGms = 'has_gms',
  /** column name */
  HasHms = 'has_hms',
  /** column name */
  HasNotch = 'has_notch',
  /** column name */
  HasSystemFeature = 'has_system_feature',
  /** column name */
  IsAirplaneMode = 'is_airplane_mode',
  /** column name */
  IsBatteryCharging = 'is_battery_charging',
  /** column name */
  IsCameraPresent = 'is_camera_present',
  /** column name */
  IsDisplayZoomed = 'is_display_zoomed',
  /** column name */
  IsEmulator = 'is_emulator',
  /** column name */
  IsHeadphonesConnected = 'is_headphones_connected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsLocationEnabled = 'is_location_enabled',
  /** column name */
  IsPinOrFingerprintSet = 'is_pin_or_fingerprint_set',
  /** column name */
  IsTablet = 'is_tablet'
}

/** select "mobile_device_infos_aggregate_bool_exp_corr_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** select "mobile_device_infos_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** select "mobile_device_infos_aggregate_bool_exp_max_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** select "mobile_device_infos_aggregate_bool_exp_min_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** select "mobile_device_infos_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** select "mobile_device_infos_aggregate_bool_exp_sum_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** select "mobile_device_infos_aggregate_bool_exp_var_samp_arguments_columns" columns of table "device_infos" */
export enum Mobile_Device_Infos_Select_Column_Mobile_Device_Infos_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetFontScale = 'get_font_scale'
}

/** input type for updating data in table "device_infos" */
export type Mobile_Device_Infos_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  get_android_id?: InputMaybe<Scalars['String']['input']>;
  get_api_level?: InputMaybe<Scalars['bigint']['input']>;
  get_application_name?: InputMaybe<Scalars['String']['input']>;
  get_available_location_providers?: InputMaybe<Scalars['jsonb']['input']>;
  get_base_os?: InputMaybe<Scalars['String']['input']>;
  get_battery_level?: InputMaybe<Scalars['float8']['input']>;
  get_bootloader?: InputMaybe<Scalars['String']['input']>;
  get_brand?: InputMaybe<Scalars['String']['input']>;
  get_brightness?: InputMaybe<Scalars['float8']['input']>;
  get_build_id?: InputMaybe<Scalars['String']['input']>;
  get_build_number?: InputMaybe<Scalars['String']['input']>;
  get_bundle_id?: InputMaybe<Scalars['String']['input']>;
  get_carrier?: InputMaybe<Scalars['String']['input']>;
  get_codename?: InputMaybe<Scalars['String']['input']>;
  get_device?: InputMaybe<Scalars['String']['input']>;
  get_device_id?: InputMaybe<Scalars['String']['input']>;
  get_device_name?: InputMaybe<Scalars['String']['input']>;
  get_device_token?: InputMaybe<Scalars['String']['input']>;
  get_device_type?: InputMaybe<Scalars['String']['input']>;
  get_display?: InputMaybe<Scalars['String']['input']>;
  get_fingerprint?: InputMaybe<Scalars['String']['input']>;
  get_first_install_time?: InputMaybe<Scalars['bigint']['input']>;
  get_font_scale?: InputMaybe<Scalars['float8']['input']>;
  get_free_disk_storage?: InputMaybe<Scalars['bigint']['input']>;
  get_free_disk_storage_old?: InputMaybe<Scalars['bigint']['input']>;
  get_hardware?: InputMaybe<Scalars['String']['input']>;
  get_host?: InputMaybe<Scalars['String']['input']>;
  get_incremental?: InputMaybe<Scalars['String']['input']>;
  get_install_referrer?: InputMaybe<Scalars['String']['input']>;
  get_installer_package_name?: InputMaybe<Scalars['String']['input']>;
  get_instance_id?: InputMaybe<Scalars['String']['input']>;
  get_ip_address?: InputMaybe<Scalars['String']['input']>;
  get_last_update_time?: InputMaybe<Scalars['bigint']['input']>;
  get_mac_address?: InputMaybe<Scalars['String']['input']>;
  get_manufacturer?: InputMaybe<Scalars['String']['input']>;
  get_max_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_model?: InputMaybe<Scalars['String']['input']>;
  get_phone_number?: InputMaybe<Scalars['String']['input']>;
  get_power_state?: InputMaybe<Scalars['jsonb']['input']>;
  get_preview_sdk_int?: InputMaybe<Scalars['bigint']['input']>;
  get_product?: InputMaybe<Scalars['String']['input']>;
  get_readable_version?: InputMaybe<Scalars['String']['input']>;
  get_security_patch?: InputMaybe<Scalars['String']['input']>;
  get_serial_number?: InputMaybe<Scalars['String']['input']>;
  get_system_available_features?: InputMaybe<Array<Scalars['String']['input']>>;
  get_system_name?: InputMaybe<Scalars['String']['input']>;
  get_system_version?: InputMaybe<Scalars['String']['input']>;
  get_tags?: InputMaybe<Scalars['String']['input']>;
  get_total_disk_capacity?: InputMaybe<Scalars['bigint']['input']>;
  get_total_disk_capacity_old?: InputMaybe<Scalars['bigint']['input']>;
  get_total_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_type?: InputMaybe<Scalars['String']['input']>;
  get_unique_id?: InputMaybe<Scalars['String']['input']>;
  get_used_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_user_agent?: InputMaybe<Scalars['String']['input']>;
  get_user_agent_sync?: InputMaybe<Scalars['String']['input']>;
  get_version?: InputMaybe<Scalars['String']['input']>;
  has_dynamic_island?: InputMaybe<Scalars['Boolean']['input']>;
  has_gms?: InputMaybe<Scalars['Boolean']['input']>;
  has_hms?: InputMaybe<Scalars['Boolean']['input']>;
  has_notch?: InputMaybe<Scalars['Boolean']['input']>;
  has_system_feature?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_airplane_mode?: InputMaybe<Scalars['Boolean']['input']>;
  is_battery_charging?: InputMaybe<Scalars['Boolean']['input']>;
  is_camera_present?: InputMaybe<Scalars['Boolean']['input']>;
  is_display_zoomed?: InputMaybe<Scalars['Boolean']['input']>;
  is_emulator?: InputMaybe<Scalars['Boolean']['input']>;
  is_headphones_connected?: InputMaybe<Scalars['Boolean']['input']>;
  is_landscape?: InputMaybe<Scalars['Boolean']['input']>;
  is_location_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_pin_or_fingerprint_set?: InputMaybe<Scalars['Boolean']['input']>;
  is_tablet?: InputMaybe<Scalars['Boolean']['input']>;
  supported_32_bit_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  supported_64_bit_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  supported_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  sync_unique_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Device_Infos_Stddev_Fields = {
  __typename?: 'mobile_device_infos_stddev_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  get_api_level?: Maybe<Scalars['Float']['output']>;
  get_battery_level?: Maybe<Scalars['Float']['output']>;
  get_brightness?: Maybe<Scalars['Float']['output']>;
  get_first_install_time?: Maybe<Scalars['Float']['output']>;
  get_font_scale?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['Float']['output']>;
  get_last_update_time?: Maybe<Scalars['Float']['output']>;
  get_max_memory?: Maybe<Scalars['Float']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['Float']['output']>;
  get_total_memory?: Maybe<Scalars['Float']['output']>;
  get_used_memory?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "device_infos" */
export type Mobile_Device_Infos_Stddev_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Device_Infos_Stddev_Pop_Fields = {
  __typename?: 'mobile_device_infos_stddev_pop_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  get_api_level?: Maybe<Scalars['Float']['output']>;
  get_battery_level?: Maybe<Scalars['Float']['output']>;
  get_brightness?: Maybe<Scalars['Float']['output']>;
  get_first_install_time?: Maybe<Scalars['Float']['output']>;
  get_font_scale?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['Float']['output']>;
  get_last_update_time?: Maybe<Scalars['Float']['output']>;
  get_max_memory?: Maybe<Scalars['Float']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['Float']['output']>;
  get_total_memory?: Maybe<Scalars['Float']['output']>;
  get_used_memory?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "device_infos" */
export type Mobile_Device_Infos_Stddev_Pop_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Device_Infos_Stddev_Samp_Fields = {
  __typename?: 'mobile_device_infos_stddev_samp_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  get_api_level?: Maybe<Scalars['Float']['output']>;
  get_battery_level?: Maybe<Scalars['Float']['output']>;
  get_brightness?: Maybe<Scalars['Float']['output']>;
  get_first_install_time?: Maybe<Scalars['Float']['output']>;
  get_font_scale?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['Float']['output']>;
  get_last_update_time?: Maybe<Scalars['Float']['output']>;
  get_max_memory?: Maybe<Scalars['Float']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['Float']['output']>;
  get_total_memory?: Maybe<Scalars['Float']['output']>;
  get_used_memory?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "device_infos" */
export type Mobile_Device_Infos_Stddev_Samp_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_device_infos" */
export type Mobile_Device_Infos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Device_Infos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Device_Infos_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  get_android_id?: InputMaybe<Scalars['String']['input']>;
  get_api_level?: InputMaybe<Scalars['bigint']['input']>;
  get_application_name?: InputMaybe<Scalars['String']['input']>;
  get_available_location_providers?: InputMaybe<Scalars['jsonb']['input']>;
  get_base_os?: InputMaybe<Scalars['String']['input']>;
  get_battery_level?: InputMaybe<Scalars['float8']['input']>;
  get_bootloader?: InputMaybe<Scalars['String']['input']>;
  get_brand?: InputMaybe<Scalars['String']['input']>;
  get_brightness?: InputMaybe<Scalars['float8']['input']>;
  get_build_id?: InputMaybe<Scalars['String']['input']>;
  get_build_number?: InputMaybe<Scalars['String']['input']>;
  get_bundle_id?: InputMaybe<Scalars['String']['input']>;
  get_carrier?: InputMaybe<Scalars['String']['input']>;
  get_codename?: InputMaybe<Scalars['String']['input']>;
  get_device?: InputMaybe<Scalars['String']['input']>;
  get_device_id?: InputMaybe<Scalars['String']['input']>;
  get_device_name?: InputMaybe<Scalars['String']['input']>;
  get_device_token?: InputMaybe<Scalars['String']['input']>;
  get_device_type?: InputMaybe<Scalars['String']['input']>;
  get_display?: InputMaybe<Scalars['String']['input']>;
  get_fingerprint?: InputMaybe<Scalars['String']['input']>;
  get_first_install_time?: InputMaybe<Scalars['bigint']['input']>;
  get_font_scale?: InputMaybe<Scalars['float8']['input']>;
  get_free_disk_storage?: InputMaybe<Scalars['bigint']['input']>;
  get_free_disk_storage_old?: InputMaybe<Scalars['bigint']['input']>;
  get_hardware?: InputMaybe<Scalars['String']['input']>;
  get_host?: InputMaybe<Scalars['String']['input']>;
  get_incremental?: InputMaybe<Scalars['String']['input']>;
  get_install_referrer?: InputMaybe<Scalars['String']['input']>;
  get_installer_package_name?: InputMaybe<Scalars['String']['input']>;
  get_instance_id?: InputMaybe<Scalars['String']['input']>;
  get_ip_address?: InputMaybe<Scalars['String']['input']>;
  get_last_update_time?: InputMaybe<Scalars['bigint']['input']>;
  get_mac_address?: InputMaybe<Scalars['String']['input']>;
  get_manufacturer?: InputMaybe<Scalars['String']['input']>;
  get_max_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_model?: InputMaybe<Scalars['String']['input']>;
  get_phone_number?: InputMaybe<Scalars['String']['input']>;
  get_power_state?: InputMaybe<Scalars['jsonb']['input']>;
  get_preview_sdk_int?: InputMaybe<Scalars['bigint']['input']>;
  get_product?: InputMaybe<Scalars['String']['input']>;
  get_readable_version?: InputMaybe<Scalars['String']['input']>;
  get_security_patch?: InputMaybe<Scalars['String']['input']>;
  get_serial_number?: InputMaybe<Scalars['String']['input']>;
  get_system_available_features?: InputMaybe<Array<Scalars['String']['input']>>;
  get_system_name?: InputMaybe<Scalars['String']['input']>;
  get_system_version?: InputMaybe<Scalars['String']['input']>;
  get_tags?: InputMaybe<Scalars['String']['input']>;
  get_total_disk_capacity?: InputMaybe<Scalars['bigint']['input']>;
  get_total_disk_capacity_old?: InputMaybe<Scalars['bigint']['input']>;
  get_total_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_type?: InputMaybe<Scalars['String']['input']>;
  get_unique_id?: InputMaybe<Scalars['String']['input']>;
  get_used_memory?: InputMaybe<Scalars['bigint']['input']>;
  get_user_agent?: InputMaybe<Scalars['String']['input']>;
  get_user_agent_sync?: InputMaybe<Scalars['String']['input']>;
  get_version?: InputMaybe<Scalars['String']['input']>;
  has_dynamic_island?: InputMaybe<Scalars['Boolean']['input']>;
  has_gms?: InputMaybe<Scalars['Boolean']['input']>;
  has_hms?: InputMaybe<Scalars['Boolean']['input']>;
  has_notch?: InputMaybe<Scalars['Boolean']['input']>;
  has_system_feature?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_airplane_mode?: InputMaybe<Scalars['Boolean']['input']>;
  is_battery_charging?: InputMaybe<Scalars['Boolean']['input']>;
  is_camera_present?: InputMaybe<Scalars['Boolean']['input']>;
  is_display_zoomed?: InputMaybe<Scalars['Boolean']['input']>;
  is_emulator?: InputMaybe<Scalars['Boolean']['input']>;
  is_headphones_connected?: InputMaybe<Scalars['Boolean']['input']>;
  is_landscape?: InputMaybe<Scalars['Boolean']['input']>;
  is_location_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_pin_or_fingerprint_set?: InputMaybe<Scalars['Boolean']['input']>;
  is_tablet?: InputMaybe<Scalars['Boolean']['input']>;
  supported_32_bit_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  supported_64_bit_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  supported_abis?: InputMaybe<Array<Scalars['String']['input']>>;
  sync_unique_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Device_Infos_Sum_Fields = {
  __typename?: 'mobile_device_infos_sum_fields';
  device_id?: Maybe<Scalars['bigint']['output']>;
  get_api_level?: Maybe<Scalars['bigint']['output']>;
  get_battery_level?: Maybe<Scalars['float8']['output']>;
  get_brightness?: Maybe<Scalars['float8']['output']>;
  get_first_install_time?: Maybe<Scalars['bigint']['output']>;
  get_font_scale?: Maybe<Scalars['float8']['output']>;
  get_free_disk_storage?: Maybe<Scalars['bigint']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['bigint']['output']>;
  get_last_update_time?: Maybe<Scalars['bigint']['output']>;
  get_max_memory?: Maybe<Scalars['bigint']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['bigint']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['bigint']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['bigint']['output']>;
  get_total_memory?: Maybe<Scalars['bigint']['output']>;
  get_used_memory?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "device_infos" */
export type Mobile_Device_Infos_Sum_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "device_infos" */
export enum Mobile_Device_Infos_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  GetAndroidId = 'get_android_id',
  /** column name */
  GetApiLevel = 'get_api_level',
  /** column name */
  GetApplicationName = 'get_application_name',
  /** column name */
  GetAvailableLocationProviders = 'get_available_location_providers',
  /** column name */
  GetBaseOs = 'get_base_os',
  /** column name */
  GetBatteryLevel = 'get_battery_level',
  /** column name */
  GetBootloader = 'get_bootloader',
  /** column name */
  GetBrand = 'get_brand',
  /** column name */
  GetBrightness = 'get_brightness',
  /** column name */
  GetBuildId = 'get_build_id',
  /** column name */
  GetBuildNumber = 'get_build_number',
  /** column name */
  GetBundleId = 'get_bundle_id',
  /** column name */
  GetCarrier = 'get_carrier',
  /** column name */
  GetCodename = 'get_codename',
  /** column name */
  GetDevice = 'get_device',
  /** column name */
  GetDeviceId = 'get_device_id',
  /** column name */
  GetDeviceName = 'get_device_name',
  /** column name */
  GetDeviceToken = 'get_device_token',
  /** column name */
  GetDeviceType = 'get_device_type',
  /** column name */
  GetDisplay = 'get_display',
  /** column name */
  GetFingerprint = 'get_fingerprint',
  /** column name */
  GetFirstInstallTime = 'get_first_install_time',
  /** column name */
  GetFontScale = 'get_font_scale',
  /** column name */
  GetFreeDiskStorage = 'get_free_disk_storage',
  /** column name */
  GetFreeDiskStorageOld = 'get_free_disk_storage_old',
  /** column name */
  GetHardware = 'get_hardware',
  /** column name */
  GetHost = 'get_host',
  /** column name */
  GetIncremental = 'get_incremental',
  /** column name */
  GetInstallReferrer = 'get_install_referrer',
  /** column name */
  GetInstallerPackageName = 'get_installer_package_name',
  /** column name */
  GetInstanceId = 'get_instance_id',
  /** column name */
  GetIpAddress = 'get_ip_address',
  /** column name */
  GetLastUpdateTime = 'get_last_update_time',
  /** column name */
  GetMacAddress = 'get_mac_address',
  /** column name */
  GetManufacturer = 'get_manufacturer',
  /** column name */
  GetMaxMemory = 'get_max_memory',
  /** column name */
  GetModel = 'get_model',
  /** column name */
  GetPhoneNumber = 'get_phone_number',
  /** column name */
  GetPowerState = 'get_power_state',
  /** column name */
  GetPreviewSdkInt = 'get_preview_sdk_int',
  /** column name */
  GetProduct = 'get_product',
  /** column name */
  GetReadableVersion = 'get_readable_version',
  /** column name */
  GetSecurityPatch = 'get_security_patch',
  /** column name */
  GetSerialNumber = 'get_serial_number',
  /** column name */
  GetSystemAvailableFeatures = 'get_system_available_features',
  /** column name */
  GetSystemName = 'get_system_name',
  /** column name */
  GetSystemVersion = 'get_system_version',
  /** column name */
  GetTags = 'get_tags',
  /** column name */
  GetTotalDiskCapacity = 'get_total_disk_capacity',
  /** column name */
  GetTotalDiskCapacityOld = 'get_total_disk_capacity_old',
  /** column name */
  GetTotalMemory = 'get_total_memory',
  /** column name */
  GetType = 'get_type',
  /** column name */
  GetUniqueId = 'get_unique_id',
  /** column name */
  GetUsedMemory = 'get_used_memory',
  /** column name */
  GetUserAgent = 'get_user_agent',
  /** column name */
  GetUserAgentSync = 'get_user_agent_sync',
  /** column name */
  GetVersion = 'get_version',
  /** column name */
  HasDynamicIsland = 'has_dynamic_island',
  /** column name */
  HasGms = 'has_gms',
  /** column name */
  HasHms = 'has_hms',
  /** column name */
  HasNotch = 'has_notch',
  /** column name */
  HasSystemFeature = 'has_system_feature',
  /** column name */
  Id = 'id',
  /** column name */
  IsAirplaneMode = 'is_airplane_mode',
  /** column name */
  IsBatteryCharging = 'is_battery_charging',
  /** column name */
  IsCameraPresent = 'is_camera_present',
  /** column name */
  IsDisplayZoomed = 'is_display_zoomed',
  /** column name */
  IsEmulator = 'is_emulator',
  /** column name */
  IsHeadphonesConnected = 'is_headphones_connected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsLocationEnabled = 'is_location_enabled',
  /** column name */
  IsPinOrFingerprintSet = 'is_pin_or_fingerprint_set',
  /** column name */
  IsTablet = 'is_tablet',
  /** column name */
  Supported_32BitAbis = 'supported_32_bit_abis',
  /** column name */
  Supported_64BitAbis = 'supported_64_bit_abis',
  /** column name */
  SupportedAbis = 'supported_abis',
  /** column name */
  SyncUniqueId = 'sync_unique_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Device_Infos_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mobile_Device_Infos_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mobile_Device_Infos_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mobile_Device_Infos_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mobile_Device_Infos_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Device_Infos_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mobile_Device_Infos_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Device_Infos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Device_Infos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Device_Infos_Var_Pop_Fields = {
  __typename?: 'mobile_device_infos_var_pop_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  get_api_level?: Maybe<Scalars['Float']['output']>;
  get_battery_level?: Maybe<Scalars['Float']['output']>;
  get_brightness?: Maybe<Scalars['Float']['output']>;
  get_first_install_time?: Maybe<Scalars['Float']['output']>;
  get_font_scale?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['Float']['output']>;
  get_last_update_time?: Maybe<Scalars['Float']['output']>;
  get_max_memory?: Maybe<Scalars['Float']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['Float']['output']>;
  get_total_memory?: Maybe<Scalars['Float']['output']>;
  get_used_memory?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "device_infos" */
export type Mobile_Device_Infos_Var_Pop_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Device_Infos_Var_Samp_Fields = {
  __typename?: 'mobile_device_infos_var_samp_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  get_api_level?: Maybe<Scalars['Float']['output']>;
  get_battery_level?: Maybe<Scalars['Float']['output']>;
  get_brightness?: Maybe<Scalars['Float']['output']>;
  get_first_install_time?: Maybe<Scalars['Float']['output']>;
  get_font_scale?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['Float']['output']>;
  get_last_update_time?: Maybe<Scalars['Float']['output']>;
  get_max_memory?: Maybe<Scalars['Float']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['Float']['output']>;
  get_total_memory?: Maybe<Scalars['Float']['output']>;
  get_used_memory?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "device_infos" */
export type Mobile_Device_Infos_Var_Samp_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Device_Infos_Variance_Fields = {
  __typename?: 'mobile_device_infos_variance_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  get_api_level?: Maybe<Scalars['Float']['output']>;
  get_battery_level?: Maybe<Scalars['Float']['output']>;
  get_brightness?: Maybe<Scalars['Float']['output']>;
  get_first_install_time?: Maybe<Scalars['Float']['output']>;
  get_font_scale?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage?: Maybe<Scalars['Float']['output']>;
  get_free_disk_storage_old?: Maybe<Scalars['Float']['output']>;
  get_last_update_time?: Maybe<Scalars['Float']['output']>;
  get_max_memory?: Maybe<Scalars['Float']['output']>;
  get_preview_sdk_int?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity?: Maybe<Scalars['Float']['output']>;
  get_total_disk_capacity_old?: Maybe<Scalars['Float']['output']>;
  get_total_memory?: Maybe<Scalars['Float']['output']>;
  get_used_memory?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "device_infos" */
export type Mobile_Device_Infos_Variance_Order_By = {
  device_id?: InputMaybe<Order_By>;
  get_api_level?: InputMaybe<Order_By>;
  get_battery_level?: InputMaybe<Order_By>;
  get_brightness?: InputMaybe<Order_By>;
  get_first_install_time?: InputMaybe<Order_By>;
  get_font_scale?: InputMaybe<Order_By>;
  get_free_disk_storage?: InputMaybe<Order_By>;
  get_free_disk_storage_old?: InputMaybe<Order_By>;
  get_last_update_time?: InputMaybe<Order_By>;
  get_max_memory?: InputMaybe<Order_By>;
  get_preview_sdk_int?: InputMaybe<Order_By>;
  get_total_disk_capacity?: InputMaybe<Order_By>;
  get_total_disk_capacity_old?: InputMaybe<Order_By>;
  get_total_memory?: InputMaybe<Order_By>;
  get_used_memory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "device_settings" */
export type Mobile_Device_Settings = {
  __typename?: 'mobile_device_settings';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  device?: Maybe<Mobile_Devices>;
  device_id?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  preview_text_group?: Maybe<Scalars['Boolean']['output']>;
  preview_text_notificate?: Maybe<Scalars['Boolean']['output']>;
  sound_group?: Maybe<Scalars['Boolean']['output']>;
  sound_message?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  vibrate_group?: Maybe<Scalars['Boolean']['output']>;
  vibrate_message?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "device_settings" */
export type Mobile_Device_Settings_Aggregate = {
  __typename?: 'mobile_device_settings_aggregate';
  aggregate?: Maybe<Mobile_Device_Settings_Aggregate_Fields>;
  nodes: Array<Mobile_Device_Settings>;
};

export type Mobile_Device_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Device_Settings_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Device_Settings_Select_Column_Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Device_Settings_Select_Column_Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Device_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "device_settings" */
export type Mobile_Device_Settings_Aggregate_Fields = {
  __typename?: 'mobile_device_settings_aggregate_fields';
  avg?: Maybe<Mobile_Device_Settings_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Device_Settings_Max_Fields>;
  min?: Maybe<Mobile_Device_Settings_Min_Fields>;
  stddev?: Maybe<Mobile_Device_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Device_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Device_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Device_Settings_Sum_Fields>;
  var_pop?: Maybe<Mobile_Device_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Device_Settings_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Device_Settings_Variance_Fields>;
};


/** aggregate fields of "device_settings" */
export type Mobile_Device_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "device_settings" */
export type Mobile_Device_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Device_Settings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Device_Settings_Max_Order_By>;
  min?: InputMaybe<Mobile_Device_Settings_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Device_Settings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Device_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Device_Settings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Device_Settings_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Device_Settings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Device_Settings_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Device_Settings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "device_settings" */
export type Mobile_Device_Settings_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Device_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Device_Settings_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Device_Settings_Avg_Fields = {
  __typename?: 'mobile_device_settings_avg_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "device_settings" */
export type Mobile_Device_Settings_Avg_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "device_settings". All fields are combined with a logical 'AND'. */
export type Mobile_Device_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Device_Settings_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Device_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  device?: InputMaybe<Mobile_Devices_Bool_Exp>;
  device_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  preview_text_group?: InputMaybe<Boolean_Comparison_Exp>;
  preview_text_notificate?: InputMaybe<Boolean_Comparison_Exp>;
  sound_group?: InputMaybe<Boolean_Comparison_Exp>;
  sound_message?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vibrate_group?: InputMaybe<Boolean_Comparison_Exp>;
  vibrate_message?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "device_settings" */
export enum Mobile_Device_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeviceSettingsPkey = 'device_settings_pkey'
}

/** input type for incrementing numeric columns in table "device_settings" */
export type Mobile_Device_Settings_Inc_Input = {
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "device_settings" */
export type Mobile_Device_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device?: InputMaybe<Mobile_Devices_Obj_Rel_Insert_Input>;
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  preview_text_group?: InputMaybe<Scalars['Boolean']['input']>;
  preview_text_notificate?: InputMaybe<Scalars['Boolean']['input']>;
  sound_group?: InputMaybe<Scalars['Boolean']['input']>;
  sound_message?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vibrate_group?: InputMaybe<Scalars['Boolean']['input']>;
  vibrate_message?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Mobile_Device_Settings_Max_Fields = {
  __typename?: 'mobile_device_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  device_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "device_settings" */
export type Mobile_Device_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Device_Settings_Min_Fields = {
  __typename?: 'mobile_device_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  device_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "device_settings" */
export type Mobile_Device_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "device_settings" */
export type Mobile_Device_Settings_Mutation_Response = {
  __typename?: 'mobile_device_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Device_Settings>;
};

/** on_conflict condition type for table "device_settings" */
export type Mobile_Device_Settings_On_Conflict = {
  constraint: Mobile_Device_Settings_Constraint;
  update_columns?: Array<Mobile_Device_Settings_Update_Column>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "device_settings". */
export type Mobile_Device_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device?: InputMaybe<Mobile_Devices_Order_By>;
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preview_text_group?: InputMaybe<Order_By>;
  preview_text_notificate?: InputMaybe<Order_By>;
  sound_group?: InputMaybe<Order_By>;
  sound_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vibrate_group?: InputMaybe<Order_By>;
  vibrate_message?: InputMaybe<Order_By>;
};

/** primary key columns input for table: device_settings */
export type Mobile_Device_Settings_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "device_settings" */
export enum Mobile_Device_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  PreviewTextGroup = 'preview_text_group',
  /** column name */
  PreviewTextNotificate = 'preview_text_notificate',
  /** column name */
  SoundGroup = 'sound_group',
  /** column name */
  SoundMessage = 'sound_message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VibrateGroup = 'vibrate_group',
  /** column name */
  VibrateMessage = 'vibrate_message'
}

/** select "mobile_device_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "device_settings" */
export enum Mobile_Device_Settings_Select_Column_Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PreviewTextGroup = 'preview_text_group',
  /** column name */
  PreviewTextNotificate = 'preview_text_notificate',
  /** column name */
  SoundGroup = 'sound_group',
  /** column name */
  SoundMessage = 'sound_message',
  /** column name */
  VibrateGroup = 'vibrate_group',
  /** column name */
  VibrateMessage = 'vibrate_message'
}

/** select "mobile_device_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "device_settings" */
export enum Mobile_Device_Settings_Select_Column_Mobile_Device_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PreviewTextGroup = 'preview_text_group',
  /** column name */
  PreviewTextNotificate = 'preview_text_notificate',
  /** column name */
  SoundGroup = 'sound_group',
  /** column name */
  SoundMessage = 'sound_message',
  /** column name */
  VibrateGroup = 'vibrate_group',
  /** column name */
  VibrateMessage = 'vibrate_message'
}

/** input type for updating data in table "device_settings" */
export type Mobile_Device_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  preview_text_group?: InputMaybe<Scalars['Boolean']['input']>;
  preview_text_notificate?: InputMaybe<Scalars['Boolean']['input']>;
  sound_group?: InputMaybe<Scalars['Boolean']['input']>;
  sound_message?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vibrate_group?: InputMaybe<Scalars['Boolean']['input']>;
  vibrate_message?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Device_Settings_Stddev_Fields = {
  __typename?: 'mobile_device_settings_stddev_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "device_settings" */
export type Mobile_Device_Settings_Stddev_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Device_Settings_Stddev_Pop_Fields = {
  __typename?: 'mobile_device_settings_stddev_pop_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "device_settings" */
export type Mobile_Device_Settings_Stddev_Pop_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Device_Settings_Stddev_Samp_Fields = {
  __typename?: 'mobile_device_settings_stddev_samp_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "device_settings" */
export type Mobile_Device_Settings_Stddev_Samp_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_device_settings" */
export type Mobile_Device_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Device_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Device_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  preview_text_group?: InputMaybe<Scalars['Boolean']['input']>;
  preview_text_notificate?: InputMaybe<Scalars['Boolean']['input']>;
  sound_group?: InputMaybe<Scalars['Boolean']['input']>;
  sound_message?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vibrate_group?: InputMaybe<Scalars['Boolean']['input']>;
  vibrate_message?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Device_Settings_Sum_Fields = {
  __typename?: 'mobile_device_settings_sum_fields';
  device_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "device_settings" */
export type Mobile_Device_Settings_Sum_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "device_settings" */
export enum Mobile_Device_Settings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  PreviewTextGroup = 'preview_text_group',
  /** column name */
  PreviewTextNotificate = 'preview_text_notificate',
  /** column name */
  SoundGroup = 'sound_group',
  /** column name */
  SoundMessage = 'sound_message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VibrateGroup = 'vibrate_group',
  /** column name */
  VibrateMessage = 'vibrate_message'
}

export type Mobile_Device_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Device_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Device_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Device_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Device_Settings_Var_Pop_Fields = {
  __typename?: 'mobile_device_settings_var_pop_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "device_settings" */
export type Mobile_Device_Settings_Var_Pop_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Device_Settings_Var_Samp_Fields = {
  __typename?: 'mobile_device_settings_var_samp_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "device_settings" */
export type Mobile_Device_Settings_Var_Samp_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Device_Settings_Variance_Fields = {
  __typename?: 'mobile_device_settings_variance_fields';
  device_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "device_settings" */
export type Mobile_Device_Settings_Variance_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "devices" */
export type Mobile_Devices = {
  __typename?: 'mobile_devices';
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  device_infos: Array<Mobile_Device_Infos>;
  /** An aggregate relationship */
  device_infos_aggregate: Mobile_Device_Infos_Aggregate;
  /** An array relationship */
  device_settings: Array<Mobile_Device_Settings>;
  /** An aggregate relationship */
  device_settings_aggregate: Mobile_Device_Settings_Aggregate;
  id: Scalars['bigint']['output'];
  imei?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_device_online" */
  is_online?: Maybe<Scalars['Boolean']['output']>;
  last_online?: Maybe<Scalars['timestamptz']['output']>;
  online?: Maybe<Scalars['Boolean']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  os_version?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  profile: Mobile_Profiles;
  profile_id: Scalars['bigint']['output'];
  signed_in?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "devices" */
export type Mobile_DevicesDevice_InfosArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type Mobile_DevicesDevice_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type Mobile_DevicesDevice_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};


/** columns and relationships of "devices" */
export type Mobile_DevicesDevice_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};

/** aggregated selection of "devices" */
export type Mobile_Devices_Aggregate = {
  __typename?: 'mobile_devices_aggregate';
  aggregate?: Maybe<Mobile_Devices_Aggregate_Fields>;
  nodes: Array<Mobile_Devices>;
};

/** aggregate fields of "devices" */
export type Mobile_Devices_Aggregate_Fields = {
  __typename?: 'mobile_devices_aggregate_fields';
  avg?: Maybe<Mobile_Devices_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Devices_Max_Fields>;
  min?: Maybe<Mobile_Devices_Min_Fields>;
  stddev?: Maybe<Mobile_Devices_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Devices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Devices_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Devices_Sum_Fields>;
  var_pop?: Maybe<Mobile_Devices_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Devices_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Devices_Variance_Fields>;
};


/** aggregate fields of "devices" */
export type Mobile_Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Devices_Avg_Fields = {
  __typename?: 'mobile_devices_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Mobile_Devices_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Devices_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Devices_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Devices_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  device_infos?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
  device_infos_aggregate?: InputMaybe<Mobile_Device_Infos_Aggregate_Bool_Exp>;
  device_settings?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
  device_settings_aggregate?: InputMaybe<Mobile_Device_Settings_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  imei?: InputMaybe<String_Comparison_Exp>;
  is_online?: InputMaybe<Boolean_Comparison_Exp>;
  last_online?: InputMaybe<Timestamptz_Comparison_Exp>;
  online?: InputMaybe<Boolean_Comparison_Exp>;
  os?: InputMaybe<String_Comparison_Exp>;
  os_version?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  signed_in?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "devices" */
export enum Mobile_Devices_Constraint {
  /** unique or primary key constraint on columns "id" */
  DevicesPkey = 'devices_pkey'
}

/** input type for incrementing numeric columns in table "devices" */
export type Mobile_Devices_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "devices" */
export type Mobile_Devices_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_infos?: InputMaybe<Mobile_Device_Infos_Arr_Rel_Insert_Input>;
  device_settings?: InputMaybe<Mobile_Device_Settings_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  last_online?: InputMaybe<Scalars['timestamptz']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  os_version?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  signed_in?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Devices_Max_Fields = {
  __typename?: 'mobile_devices_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  imei?: Maybe<Scalars['String']['output']>;
  last_online?: Maybe<Scalars['timestamptz']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  os_version?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Devices_Min_Fields = {
  __typename?: 'mobile_devices_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  imei?: Maybe<Scalars['String']['output']>;
  last_online?: Maybe<Scalars['timestamptz']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  os_version?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "devices" */
export type Mobile_Devices_Mutation_Response = {
  __typename?: 'mobile_devices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Devices>;
};

/** input type for inserting object relation for remote table "devices" */
export type Mobile_Devices_Obj_Rel_Insert_Input = {
  data: Mobile_Devices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Devices_On_Conflict>;
};

/** on_conflict condition type for table "devices" */
export type Mobile_Devices_On_Conflict = {
  constraint: Mobile_Devices_Constraint;
  update_columns?: Array<Mobile_Devices_Update_Column>;
  where?: InputMaybe<Mobile_Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "devices". */
export type Mobile_Devices_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_infos_aggregate?: InputMaybe<Mobile_Device_Infos_Aggregate_Order_By>;
  device_settings_aggregate?: InputMaybe<Mobile_Device_Settings_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  imei?: InputMaybe<Order_By>;
  is_online?: InputMaybe<Order_By>;
  last_online?: InputMaybe<Order_By>;
  online?: InputMaybe<Order_By>;
  os?: InputMaybe<Order_By>;
  os_version?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  signed_in?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: devices */
export type Mobile_Devices_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "devices" */
export enum Mobile_Devices_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Imei = 'imei',
  /** column name */
  LastOnline = 'last_online',
  /** column name */
  Online = 'online',
  /** column name */
  Os = 'os',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SignedIn = 'signed_in',
  /** column name */
  Title = 'title',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "devices" */
export type Mobile_Devices_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  last_online?: InputMaybe<Scalars['timestamptz']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  os_version?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  signed_in?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Devices_Stddev_Fields = {
  __typename?: 'mobile_devices_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Devices_Stddev_Pop_Fields = {
  __typename?: 'mobile_devices_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Devices_Stddev_Samp_Fields = {
  __typename?: 'mobile_devices_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_devices" */
export type Mobile_Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Devices_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  last_online?: InputMaybe<Scalars['timestamptz']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  os_version?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  signed_in?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Devices_Sum_Fields = {
  __typename?: 'mobile_devices_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "devices" */
export enum Mobile_Devices_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Imei = 'imei',
  /** column name */
  LastOnline = 'last_online',
  /** column name */
  Online = 'online',
  /** column name */
  Os = 'os',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SignedIn = 'signed_in',
  /** column name */
  Title = 'title',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Devices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Devices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Devices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Devices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Devices_Var_Pop_Fields = {
  __typename?: 'mobile_devices_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Devices_Var_Samp_Fields = {
  __typename?: 'mobile_devices_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Devices_Variance_Fields = {
  __typename?: 'mobile_devices_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "document_mediafiles" */
export type Mobile_Document_Mediafiles = {
  __typename?: 'mobile_document_mediafiles';
  created_at: Scalars['timestamp']['output'];
  /** An object relationship */
  document: Mobile_Documents;
  document_id: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mediafile_status: Mobile_Mediafile_Statuses;
  mediafile_status_id: Scalars['bigint']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "document_mediafiles" */
export type Mobile_Document_Mediafiles_Aggregate = {
  __typename?: 'mobile_document_mediafiles_aggregate';
  aggregate?: Maybe<Mobile_Document_Mediafiles_Aggregate_Fields>;
  nodes: Array<Mobile_Document_Mediafiles>;
};

export type Mobile_Document_Mediafiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Document_Mediafiles_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Document_Mediafiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document_mediafiles" */
export type Mobile_Document_Mediafiles_Aggregate_Fields = {
  __typename?: 'mobile_document_mediafiles_aggregate_fields';
  avg?: Maybe<Mobile_Document_Mediafiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Document_Mediafiles_Max_Fields>;
  min?: Maybe<Mobile_Document_Mediafiles_Min_Fields>;
  stddev?: Maybe<Mobile_Document_Mediafiles_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Document_Mediafiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Document_Mediafiles_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Document_Mediafiles_Sum_Fields>;
  var_pop?: Maybe<Mobile_Document_Mediafiles_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Document_Mediafiles_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Document_Mediafiles_Variance_Fields>;
};


/** aggregate fields of "document_mediafiles" */
export type Mobile_Document_Mediafiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Document_Mediafiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Document_Mediafiles_Max_Order_By>;
  min?: InputMaybe<Mobile_Document_Mediafiles_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Document_Mediafiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Document_Mediafiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Document_Mediafiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Document_Mediafiles_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Document_Mediafiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Document_Mediafiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Document_Mediafiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Document_Mediafiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Document_Mediafiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Document_Mediafiles_Avg_Fields = {
  __typename?: 'mobile_document_mediafiles_avg_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Avg_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "document_mediafiles". All fields are combined with a logical 'AND'. */
export type Mobile_Document_Mediafiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Document_Mediafiles_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Document_Mediafiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  document?: InputMaybe<Mobile_Documents_Bool_Exp>;
  document_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mediafile_path?: InputMaybe<String_Comparison_Exp>;
  mediafile_size?: InputMaybe<String_Comparison_Exp>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
  mediafile_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "document_mediafiles" */
export enum Mobile_Document_Mediafiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentMediafilesPkey = 'document_mediafiles_pkey'
}

/** input type for incrementing numeric columns in table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Inc_Input = {
  document_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document?: InputMaybe<Mobile_Documents_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Obj_Rel_Insert_Input>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Document_Mediafiles_Max_Fields = {
  __typename?: 'mobile_document_mediafiles_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Document_Mediafiles_Min_Fields = {
  __typename?: 'mobile_document_mediafiles_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Mutation_Response = {
  __typename?: 'mobile_document_mediafiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Document_Mediafiles>;
};

/** on_conflict condition type for table "document_mediafiles" */
export type Mobile_Document_Mediafiles_On_Conflict = {
  constraint: Mobile_Document_Mediafiles_Constraint;
  update_columns?: Array<Mobile_Document_Mediafiles_Update_Column>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};

/** Ordering options when selecting data from "document_mediafiles". */
export type Mobile_Document_Mediafiles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Mobile_Documents_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_mediafiles */
export type Mobile_Document_Mediafiles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "document_mediafiles" */
export enum Mobile_Document_Mediafiles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Document_Mediafiles_Stddev_Fields = {
  __typename?: 'mobile_document_mediafiles_stddev_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Stddev_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Document_Mediafiles_Stddev_Pop_Fields = {
  __typename?: 'mobile_document_mediafiles_stddev_pop_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Stddev_Pop_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Document_Mediafiles_Stddev_Samp_Fields = {
  __typename?: 'mobile_document_mediafiles_stddev_samp_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Stddev_Samp_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_document_mediafiles" */
export type Mobile_Document_Mediafiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Document_Mediafiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Document_Mediafiles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Document_Mediafiles_Sum_Fields = {
  __typename?: 'mobile_document_mediafiles_sum_fields';
  document_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Sum_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** update columns of table "document_mediafiles" */
export enum Mobile_Document_Mediafiles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Document_Mediafiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Document_Mediafiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Document_Mediafiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Document_Mediafiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Document_Mediafiles_Var_Pop_Fields = {
  __typename?: 'mobile_document_mediafiles_var_pop_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Var_Pop_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Document_Mediafiles_Var_Samp_Fields = {
  __typename?: 'mobile_document_mediafiles_var_samp_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Var_Samp_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Document_Mediafiles_Variance_Fields = {
  __typename?: 'mobile_document_mediafiles_variance_fields';
  document_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "document_mediafiles" */
export type Mobile_Document_Mediafiles_Variance_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "documents" */
export type Mobile_Documents = {
  __typename?: 'mobile_documents';
  created_at: Scalars['timestamp']['output'];
  /** An array relationship */
  document_mediafiles: Array<Mobile_Document_Mediafiles>;
  /** An aggregate relationship */
  document_mediafiles_aggregate: Mobile_Document_Mediafiles_Aggregate;
  document_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  issue_date?: Maybe<Scalars['date']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  pro: Mobile_Pros;
  pro_id: Scalars['bigint']['output'];
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "documents" */
export type Mobile_DocumentsDocument_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "documents" */
export type Mobile_DocumentsDocument_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};

/** aggregated selection of "documents" */
export type Mobile_Documents_Aggregate = {
  __typename?: 'mobile_documents_aggregate';
  aggregate?: Maybe<Mobile_Documents_Aggregate_Fields>;
  nodes: Array<Mobile_Documents>;
};

export type Mobile_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Documents_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "documents" */
export type Mobile_Documents_Aggregate_Fields = {
  __typename?: 'mobile_documents_aggregate_fields';
  avg?: Maybe<Mobile_Documents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Documents_Max_Fields>;
  min?: Maybe<Mobile_Documents_Min_Fields>;
  stddev?: Maybe<Mobile_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Documents_Sum_Fields>;
  var_pop?: Maybe<Mobile_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Documents_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Documents_Variance_Fields>;
};


/** aggregate fields of "documents" */
export type Mobile_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "documents" */
export type Mobile_Documents_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Documents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Documents_Max_Order_By>;
  min?: InputMaybe<Mobile_Documents_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Documents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Documents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Documents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Documents_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Documents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Documents_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Documents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "documents" */
export type Mobile_Documents_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Documents_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Documents_Avg_Fields = {
  __typename?: 'mobile_documents_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "documents" */
export type Mobile_Documents_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "documents". All fields are combined with a logical 'AND'. */
export type Mobile_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Documents_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Documents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  document_mediafiles?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
  document_mediafiles_aggregate?: InputMaybe<Mobile_Document_Mediafiles_Aggregate_Bool_Exp>;
  document_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  issue_date?: InputMaybe<Date_Comparison_Exp>;
  issued_by?: InputMaybe<String_Comparison_Exp>;
  pro?: InputMaybe<Mobile_Pros_Bool_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  registration_address?: InputMaybe<String_Comparison_Exp>;
  series_number?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  subdivision_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "documents" */
export enum Mobile_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = 'documents_pkey'
}

/** input type for incrementing numeric columns in table "documents" */
export type Mobile_Documents_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "documents" */
export type Mobile_Documents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_mediafiles?: InputMaybe<Mobile_Document_Mediafiles_Arr_Rel_Insert_Input>;
  document_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  pro?: InputMaybe<Mobile_Pros_Obj_Rel_Insert_Input>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Documents_Max_Fields = {
  __typename?: 'mobile_documents_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "documents" */
export type Mobile_Documents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  issued_by?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  series_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subdivision_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Documents_Min_Fields = {
  __typename?: 'mobile_documents_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "documents" */
export type Mobile_Documents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  issued_by?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  series_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subdivision_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "documents" */
export type Mobile_Documents_Mutation_Response = {
  __typename?: 'mobile_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Documents>;
};

/** input type for inserting object relation for remote table "documents" */
export type Mobile_Documents_Obj_Rel_Insert_Input = {
  data: Mobile_Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Documents_On_Conflict>;
};

/** on_conflict condition type for table "documents" */
export type Mobile_Documents_On_Conflict = {
  constraint: Mobile_Documents_Constraint;
  update_columns?: Array<Mobile_Documents_Update_Column>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "documents". */
export type Mobile_Documents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_mediafiles_aggregate?: InputMaybe<Mobile_Document_Mediafiles_Aggregate_Order_By>;
  document_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  issued_by?: InputMaybe<Order_By>;
  pro?: InputMaybe<Mobile_Pros_Order_By>;
  pro_id?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  series_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subdivision_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: documents */
export type Mobile_Documents_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "documents" */
export enum Mobile_Documents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  Id = 'id',
  /** column name */
  IssueDate = 'issue_date',
  /** column name */
  IssuedBy = 'issued_by',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  SeriesNumber = 'series_number',
  /** column name */
  Status = 'status',
  /** column name */
  SubdivisionCode = 'subdivision_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "documents" */
export type Mobile_Documents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Documents_Stddev_Fields = {
  __typename?: 'mobile_documents_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "documents" */
export type Mobile_Documents_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Documents_Stddev_Pop_Fields = {
  __typename?: 'mobile_documents_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "documents" */
export type Mobile_Documents_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Documents_Stddev_Samp_Fields = {
  __typename?: 'mobile_documents_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "documents" */
export type Mobile_Documents_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_documents" */
export type Mobile_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Documents_Sum_Fields = {
  __typename?: 'mobile_documents_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "documents" */
export type Mobile_Documents_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** update columns of table "documents" */
export enum Mobile_Documents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  Id = 'id',
  /** column name */
  IssueDate = 'issue_date',
  /** column name */
  IssuedBy = 'issued_by',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  SeriesNumber = 'series_number',
  /** column name */
  Status = 'status',
  /** column name */
  SubdivisionCode = 'subdivision_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Documents_Var_Pop_Fields = {
  __typename?: 'mobile_documents_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "documents" */
export type Mobile_Documents_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Documents_Var_Samp_Fields = {
  __typename?: 'mobile_documents_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "documents" */
export type Mobile_Documents_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Documents_Variance_Fields = {
  __typename?: 'mobile_documents_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "documents" */
export type Mobile_Documents_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** columns and relationships of "education_mediafiles" */
export type Mobile_Education_Mediafiles = {
  __typename?: 'mobile_education_mediafiles';
  created_at: Scalars['timestamp']['output'];
  /** An object relationship */
  education: Mobile_Educations;
  education_id: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mediafile_status: Mobile_Mediafile_Statuses;
  mediafile_status_id: Scalars['bigint']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "education_mediafiles" */
export type Mobile_Education_Mediafiles_Aggregate = {
  __typename?: 'mobile_education_mediafiles_aggregate';
  aggregate?: Maybe<Mobile_Education_Mediafiles_Aggregate_Fields>;
  nodes: Array<Mobile_Education_Mediafiles>;
};

export type Mobile_Education_Mediafiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Education_Mediafiles_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Education_Mediafiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "education_mediafiles" */
export type Mobile_Education_Mediafiles_Aggregate_Fields = {
  __typename?: 'mobile_education_mediafiles_aggregate_fields';
  avg?: Maybe<Mobile_Education_Mediafiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Education_Mediafiles_Max_Fields>;
  min?: Maybe<Mobile_Education_Mediafiles_Min_Fields>;
  stddev?: Maybe<Mobile_Education_Mediafiles_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Education_Mediafiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Education_Mediafiles_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Education_Mediafiles_Sum_Fields>;
  var_pop?: Maybe<Mobile_Education_Mediafiles_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Education_Mediafiles_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Education_Mediafiles_Variance_Fields>;
};


/** aggregate fields of "education_mediafiles" */
export type Mobile_Education_Mediafiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Education_Mediafiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Education_Mediafiles_Max_Order_By>;
  min?: InputMaybe<Mobile_Education_Mediafiles_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Education_Mediafiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Education_Mediafiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Education_Mediafiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Education_Mediafiles_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Education_Mediafiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Education_Mediafiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Education_Mediafiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Education_Mediafiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Education_Mediafiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Education_Mediafiles_Avg_Fields = {
  __typename?: 'mobile_education_mediafiles_avg_fields';
  education_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Avg_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "education_mediafiles". All fields are combined with a logical 'AND'. */
export type Mobile_Education_Mediafiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Education_Mediafiles_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Education_Mediafiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  education?: InputMaybe<Mobile_Educations_Bool_Exp>;
  education_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mediafile_path?: InputMaybe<String_Comparison_Exp>;
  mediafile_size?: InputMaybe<String_Comparison_Exp>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
  mediafile_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "education_mediafiles" */
export enum Mobile_Education_Mediafiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  EducationMediafilesPkey = 'education_mediafiles_pkey'
}

/** input type for incrementing numeric columns in table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Inc_Input = {
  education_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  education?: InputMaybe<Mobile_Educations_Obj_Rel_Insert_Input>;
  education_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Obj_Rel_Insert_Input>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Education_Mediafiles_Max_Fields = {
  __typename?: 'mobile_education_mediafiles_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  education_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Education_Mediafiles_Min_Fields = {
  __typename?: 'mobile_education_mediafiles_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  education_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Mutation_Response = {
  __typename?: 'mobile_education_mediafiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Education_Mediafiles>;
};

/** on_conflict condition type for table "education_mediafiles" */
export type Mobile_Education_Mediafiles_On_Conflict = {
  constraint: Mobile_Education_Mediafiles_Constraint;
  update_columns?: Array<Mobile_Education_Mediafiles_Update_Column>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};

/** Ordering options when selecting data from "education_mediafiles". */
export type Mobile_Education_Mediafiles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  education?: InputMaybe<Mobile_Educations_Order_By>;
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: education_mediafiles */
export type Mobile_Education_Mediafiles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "education_mediafiles" */
export enum Mobile_Education_Mediafiles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EducationId = 'education_id',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  education_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Education_Mediafiles_Stddev_Fields = {
  __typename?: 'mobile_education_mediafiles_stddev_fields';
  education_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Stddev_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Education_Mediafiles_Stddev_Pop_Fields = {
  __typename?: 'mobile_education_mediafiles_stddev_pop_fields';
  education_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Stddev_Pop_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Education_Mediafiles_Stddev_Samp_Fields = {
  __typename?: 'mobile_education_mediafiles_stddev_samp_fields';
  education_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Stddev_Samp_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_education_mediafiles" */
export type Mobile_Education_Mediafiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Education_Mediafiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Education_Mediafiles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  education_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Education_Mediafiles_Sum_Fields = {
  __typename?: 'mobile_education_mediafiles_sum_fields';
  education_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Sum_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** update columns of table "education_mediafiles" */
export enum Mobile_Education_Mediafiles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EducationId = 'education_id',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Education_Mediafiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Education_Mediafiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Education_Mediafiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Education_Mediafiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Education_Mediafiles_Var_Pop_Fields = {
  __typename?: 'mobile_education_mediafiles_var_pop_fields';
  education_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Var_Pop_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Education_Mediafiles_Var_Samp_Fields = {
  __typename?: 'mobile_education_mediafiles_var_samp_fields';
  education_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Var_Samp_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Education_Mediafiles_Variance_Fields = {
  __typename?: 'mobile_education_mediafiles_variance_fields';
  education_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "education_mediafiles" */
export type Mobile_Education_Mediafiles_Variance_Order_By = {
  education_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "educations" */
export type Mobile_Educations = {
  __typename?: 'mobile_educations';
  created_at: Scalars['timestamp']['output'];
  /** An array relationship */
  education_mediafiles: Array<Mobile_Education_Mediafiles>;
  /** An aggregate relationship */
  education_mediafiles_aggregate: Mobile_Education_Mediafiles_Aggregate;
  faculty?: Maybe<Scalars['String']['output']>;
  graduation_year?: Maybe<Scalars['Int']['output']>;
  id: Scalars['bigint']['output'];
  institution_name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  pro: Mobile_Pros;
  pro_id: Scalars['bigint']['output'];
  specialization?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "educations" */
export type Mobile_EducationsEducation_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "educations" */
export type Mobile_EducationsEducation_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};

/** aggregated selection of "educations" */
export type Mobile_Educations_Aggregate = {
  __typename?: 'mobile_educations_aggregate';
  aggregate?: Maybe<Mobile_Educations_Aggregate_Fields>;
  nodes: Array<Mobile_Educations>;
};

export type Mobile_Educations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Educations_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Educations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Educations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "educations" */
export type Mobile_Educations_Aggregate_Fields = {
  __typename?: 'mobile_educations_aggregate_fields';
  avg?: Maybe<Mobile_Educations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Educations_Max_Fields>;
  min?: Maybe<Mobile_Educations_Min_Fields>;
  stddev?: Maybe<Mobile_Educations_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Educations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Educations_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Educations_Sum_Fields>;
  var_pop?: Maybe<Mobile_Educations_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Educations_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Educations_Variance_Fields>;
};


/** aggregate fields of "educations" */
export type Mobile_Educations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "educations" */
export type Mobile_Educations_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Educations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Educations_Max_Order_By>;
  min?: InputMaybe<Mobile_Educations_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Educations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Educations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Educations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Educations_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Educations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Educations_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Educations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "educations" */
export type Mobile_Educations_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Educations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Educations_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Educations_Avg_Fields = {
  __typename?: 'mobile_educations_avg_fields';
  graduation_year?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "educations" */
export type Mobile_Educations_Avg_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "educations". All fields are combined with a logical 'AND'. */
export type Mobile_Educations_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Educations_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Educations_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Educations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  education_mediafiles?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
  education_mediafiles_aggregate?: InputMaybe<Mobile_Education_Mediafiles_Aggregate_Bool_Exp>;
  faculty?: InputMaybe<String_Comparison_Exp>;
  graduation_year?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  institution_name?: InputMaybe<String_Comparison_Exp>;
  pro?: InputMaybe<Mobile_Pros_Bool_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  specialization?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "educations" */
export enum Mobile_Educations_Constraint {
  /** unique or primary key constraint on columns "id" */
  EducationsPkey = 'educations_pkey'
}

/** input type for incrementing numeric columns in table "educations" */
export type Mobile_Educations_Inc_Input = {
  graduation_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "educations" */
export type Mobile_Educations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  education_mediafiles?: InputMaybe<Mobile_Education_Mediafiles_Arr_Rel_Insert_Input>;
  faculty?: InputMaybe<Scalars['String']['input']>;
  graduation_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_name?: InputMaybe<Scalars['String']['input']>;
  pro?: InputMaybe<Mobile_Pros_Obj_Rel_Insert_Input>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  specialization?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Educations_Max_Fields = {
  __typename?: 'mobile_educations_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  faculty?: Maybe<Scalars['String']['output']>;
  graduation_year?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  institution_name?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  specialization?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "educations" */
export type Mobile_Educations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  faculty?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_name?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  specialization?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Educations_Min_Fields = {
  __typename?: 'mobile_educations_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  faculty?: Maybe<Scalars['String']['output']>;
  graduation_year?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  institution_name?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  specialization?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "educations" */
export type Mobile_Educations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  faculty?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_name?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  specialization?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "educations" */
export type Mobile_Educations_Mutation_Response = {
  __typename?: 'mobile_educations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Educations>;
};

/** input type for inserting object relation for remote table "educations" */
export type Mobile_Educations_Obj_Rel_Insert_Input = {
  data: Mobile_Educations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Educations_On_Conflict>;
};

/** on_conflict condition type for table "educations" */
export type Mobile_Educations_On_Conflict = {
  constraint: Mobile_Educations_Constraint;
  update_columns?: Array<Mobile_Educations_Update_Column>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};

/** Ordering options when selecting data from "educations". */
export type Mobile_Educations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  education_mediafiles_aggregate?: InputMaybe<Mobile_Education_Mediafiles_Aggregate_Order_By>;
  faculty?: InputMaybe<Order_By>;
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_name?: InputMaybe<Order_By>;
  pro?: InputMaybe<Mobile_Pros_Order_By>;
  pro_id?: InputMaybe<Order_By>;
  specialization?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: educations */
export type Mobile_Educations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "educations" */
export enum Mobile_Educations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Faculty = 'faculty',
  /** column name */
  GraduationYear = 'graduation_year',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  Specialization = 'specialization',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "educations" */
export type Mobile_Educations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  faculty?: InputMaybe<Scalars['String']['input']>;
  graduation_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_name?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  specialization?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Educations_Stddev_Fields = {
  __typename?: 'mobile_educations_stddev_fields';
  graduation_year?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "educations" */
export type Mobile_Educations_Stddev_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Educations_Stddev_Pop_Fields = {
  __typename?: 'mobile_educations_stddev_pop_fields';
  graduation_year?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "educations" */
export type Mobile_Educations_Stddev_Pop_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Educations_Stddev_Samp_Fields = {
  __typename?: 'mobile_educations_stddev_samp_fields';
  graduation_year?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "educations" */
export type Mobile_Educations_Stddev_Samp_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_educations" */
export type Mobile_Educations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Educations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Educations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  faculty?: InputMaybe<Scalars['String']['input']>;
  graduation_year?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_name?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  specialization?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Educations_Sum_Fields = {
  __typename?: 'mobile_educations_sum_fields';
  graduation_year?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "educations" */
export type Mobile_Educations_Sum_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** update columns of table "educations" */
export enum Mobile_Educations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Faculty = 'faculty',
  /** column name */
  GraduationYear = 'graduation_year',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  Specialization = 'specialization',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Educations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Educations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Educations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Educations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Educations_Var_Pop_Fields = {
  __typename?: 'mobile_educations_var_pop_fields';
  graduation_year?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "educations" */
export type Mobile_Educations_Var_Pop_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Educations_Var_Samp_Fields = {
  __typename?: 'mobile_educations_var_samp_fields';
  graduation_year?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "educations" */
export type Mobile_Educations_Var_Samp_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Educations_Variance_Fields = {
  __typename?: 'mobile_educations_variance_fields';
  graduation_year?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "educations" */
export type Mobile_Educations_Variance_Order_By = {
  graduation_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "emojis" */
export type Mobile_Emojis = {
  __typename?: 'mobile_emojis';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  reactions: Array<Mobile_Reactions>;
  /** An aggregate relationship */
  reactions_aggregate: Mobile_Reactions_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "emojis" */
export type Mobile_EmojisReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};


/** columns and relationships of "emojis" */
export type Mobile_EmojisReactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};

/** aggregated selection of "emojis" */
export type Mobile_Emojis_Aggregate = {
  __typename?: 'mobile_emojis_aggregate';
  aggregate?: Maybe<Mobile_Emojis_Aggregate_Fields>;
  nodes: Array<Mobile_Emojis>;
};

/** aggregate fields of "emojis" */
export type Mobile_Emojis_Aggregate_Fields = {
  __typename?: 'mobile_emojis_aggregate_fields';
  avg?: Maybe<Mobile_Emojis_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Emojis_Max_Fields>;
  min?: Maybe<Mobile_Emojis_Min_Fields>;
  stddev?: Maybe<Mobile_Emojis_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Emojis_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Emojis_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Emojis_Sum_Fields>;
  var_pop?: Maybe<Mobile_Emojis_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Emojis_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Emojis_Variance_Fields>;
};


/** aggregate fields of "emojis" */
export type Mobile_Emojis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Emojis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Emojis_Avg_Fields = {
  __typename?: 'mobile_emojis_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "emojis". All fields are combined with a logical 'AND'. */
export type Mobile_Emojis_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Emojis_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Emojis_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Emojis_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  reactions?: InputMaybe<Mobile_Reactions_Bool_Exp>;
  reactions_aggregate?: InputMaybe<Mobile_Reactions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "emojis" */
export enum Mobile_Emojis_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmojisPkey = 'emojis_pkey'
}

/** input type for incrementing numeric columns in table "emojis" */
export type Mobile_Emojis_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "emojis" */
export type Mobile_Emojis_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reactions?: InputMaybe<Mobile_Reactions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Emojis_Max_Fields = {
  __typename?: 'mobile_emojis_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Emojis_Min_Fields = {
  __typename?: 'mobile_emojis_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "emojis" */
export type Mobile_Emojis_Mutation_Response = {
  __typename?: 'mobile_emojis_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Emojis>;
};

/** input type for inserting object relation for remote table "emojis" */
export type Mobile_Emojis_Obj_Rel_Insert_Input = {
  data: Mobile_Emojis_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Emojis_On_Conflict>;
};

/** on_conflict condition type for table "emojis" */
export type Mobile_Emojis_On_Conflict = {
  constraint: Mobile_Emojis_Constraint;
  update_columns?: Array<Mobile_Emojis_Update_Column>;
  where?: InputMaybe<Mobile_Emojis_Bool_Exp>;
};

/** Ordering options when selecting data from "emojis". */
export type Mobile_Emojis_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reactions_aggregate?: InputMaybe<Mobile_Reactions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emojis */
export type Mobile_Emojis_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "emojis" */
export enum Mobile_Emojis_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "emojis" */
export type Mobile_Emojis_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Emojis_Stddev_Fields = {
  __typename?: 'mobile_emojis_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Emojis_Stddev_Pop_Fields = {
  __typename?: 'mobile_emojis_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Emojis_Stddev_Samp_Fields = {
  __typename?: 'mobile_emojis_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_emojis" */
export type Mobile_Emojis_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Emojis_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Emojis_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Emojis_Sum_Fields = {
  __typename?: 'mobile_emojis_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "emojis" */
export enum Mobile_Emojis_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Mobile_Emojis_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Emojis_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Emojis_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Emojis_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Emojis_Var_Pop_Fields = {
  __typename?: 'mobile_emojis_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Emojis_Var_Samp_Fields = {
  __typename?: 'mobile_emojis_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Emojis_Variance_Fields = {
  __typename?: 'mobile_emojis_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages = {
  __typename?: 'mobile_fixed_chat_messages';
  /** An object relationship */
  chat?: Maybe<Mobile_Chats>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  chat_message?: Maybe<Mobile_Chat_Messages>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Aggregate = {
  __typename?: 'mobile_fixed_chat_messages_aggregate';
  aggregate?: Maybe<Mobile_Fixed_Chat_Messages_Aggregate_Fields>;
  nodes: Array<Mobile_Fixed_Chat_Messages>;
};

/** aggregate fields of "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Aggregate_Fields = {
  __typename?: 'mobile_fixed_chat_messages_aggregate_fields';
  avg?: Maybe<Mobile_Fixed_Chat_Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Fixed_Chat_Messages_Max_Fields>;
  min?: Maybe<Mobile_Fixed_Chat_Messages_Min_Fields>;
  stddev?: Maybe<Mobile_Fixed_Chat_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Fixed_Chat_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Fixed_Chat_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Fixed_Chat_Messages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Fixed_Chat_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Fixed_Chat_Messages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Fixed_Chat_Messages_Variance_Fields>;
};


/** aggregate fields of "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Fixed_Chat_Messages_Avg_Fields = {
  __typename?: 'mobile_fixed_chat_messages_avg_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "fixed_chat_messages". All fields are combined with a logical 'AND'. */
export type Mobile_Fixed_Chat_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Fixed_Chat_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Bool_Exp>>;
  chat?: InputMaybe<Mobile_Chats_Bool_Exp>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "fixed_chat_messages" */
export enum Mobile_Fixed_Chat_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  FixedChatMessagesPkey = 'fixed_chat_messages_pkey'
}

/** input type for incrementing numeric columns in table "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Inc_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Insert_Input = {
  chat?: InputMaybe<Mobile_Chats_Obj_Rel_Insert_Input>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Obj_Rel_Insert_Input>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Fixed_Chat_Messages_Max_Fields = {
  __typename?: 'mobile_fixed_chat_messages_max_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Fixed_Chat_Messages_Min_Fields = {
  __typename?: 'mobile_fixed_chat_messages_min_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Mutation_Response = {
  __typename?: 'mobile_fixed_chat_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Fixed_Chat_Messages>;
};

/** on_conflict condition type for table "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_On_Conflict = {
  constraint: Mobile_Fixed_Chat_Messages_Constraint;
  update_columns?: Array<Mobile_Fixed_Chat_Messages_Update_Column>;
  where?: InputMaybe<Mobile_Fixed_Chat_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "fixed_chat_messages". */
export type Mobile_Fixed_Chat_Messages_Order_By = {
  chat?: InputMaybe<Mobile_Chats_Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fixed_chat_messages */
export type Mobile_Fixed_Chat_Messages_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "fixed_chat_messages" */
export enum Mobile_Fixed_Chat_Messages_Select_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Set_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Fixed_Chat_Messages_Stddev_Fields = {
  __typename?: 'mobile_fixed_chat_messages_stddev_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Fixed_Chat_Messages_Stddev_Pop_Fields = {
  __typename?: 'mobile_fixed_chat_messages_stddev_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Fixed_Chat_Messages_Stddev_Samp_Fields = {
  __typename?: 'mobile_fixed_chat_messages_stddev_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_fixed_chat_messages" */
export type Mobile_Fixed_Chat_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Fixed_Chat_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Fixed_Chat_Messages_Stream_Cursor_Value_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Fixed_Chat_Messages_Sum_Fields = {
  __typename?: 'mobile_fixed_chat_messages_sum_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "fixed_chat_messages" */
export enum Mobile_Fixed_Chat_Messages_Update_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Fixed_Chat_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Fixed_Chat_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Fixed_Chat_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Fixed_Chat_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Fixed_Chat_Messages_Var_Pop_Fields = {
  __typename?: 'mobile_fixed_chat_messages_var_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Fixed_Chat_Messages_Var_Samp_Fields = {
  __typename?: 'mobile_fixed_chat_messages_var_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Fixed_Chat_Messages_Variance_Fields = {
  __typename?: 'mobile_fixed_chat_messages_variance_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "fixed_comments" */
export type Mobile_Fixed_Comments = {
  __typename?: 'mobile_fixed_comments';
  /** An object relationship */
  comment?: Maybe<Mobile_Comments>;
  comment_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  post?: Maybe<Mobile_Posts>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "fixed_comments" */
export type Mobile_Fixed_Comments_Aggregate = {
  __typename?: 'mobile_fixed_comments_aggregate';
  aggregate?: Maybe<Mobile_Fixed_Comments_Aggregate_Fields>;
  nodes: Array<Mobile_Fixed_Comments>;
};

/** aggregate fields of "fixed_comments" */
export type Mobile_Fixed_Comments_Aggregate_Fields = {
  __typename?: 'mobile_fixed_comments_aggregate_fields';
  avg?: Maybe<Mobile_Fixed_Comments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Fixed_Comments_Max_Fields>;
  min?: Maybe<Mobile_Fixed_Comments_Min_Fields>;
  stddev?: Maybe<Mobile_Fixed_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Fixed_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Fixed_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Fixed_Comments_Sum_Fields>;
  var_pop?: Maybe<Mobile_Fixed_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Fixed_Comments_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Fixed_Comments_Variance_Fields>;
};


/** aggregate fields of "fixed_comments" */
export type Mobile_Fixed_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Fixed_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Fixed_Comments_Avg_Fields = {
  __typename?: 'mobile_fixed_comments_avg_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "fixed_comments". All fields are combined with a logical 'AND'. */
export type Mobile_Fixed_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Fixed_Comments_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Fixed_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Fixed_Comments_Bool_Exp>>;
  comment?: InputMaybe<Mobile_Comments_Bool_Exp>;
  comment_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  post?: InputMaybe<Mobile_Posts_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "fixed_comments" */
export enum Mobile_Fixed_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  FixedCommentsPkey = 'fixed_comments_pkey'
}

/** input type for incrementing numeric columns in table "fixed_comments" */
export type Mobile_Fixed_Comments_Inc_Input = {
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "fixed_comments" */
export type Mobile_Fixed_Comments_Insert_Input = {
  comment?: InputMaybe<Mobile_Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post?: InputMaybe<Mobile_Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Fixed_Comments_Max_Fields = {
  __typename?: 'mobile_fixed_comments_max_fields';
  comment_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Fixed_Comments_Min_Fields = {
  __typename?: 'mobile_fixed_comments_min_fields';
  comment_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "fixed_comments" */
export type Mobile_Fixed_Comments_Mutation_Response = {
  __typename?: 'mobile_fixed_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Fixed_Comments>;
};

/** on_conflict condition type for table "fixed_comments" */
export type Mobile_Fixed_Comments_On_Conflict = {
  constraint: Mobile_Fixed_Comments_Constraint;
  update_columns?: Array<Mobile_Fixed_Comments_Update_Column>;
  where?: InputMaybe<Mobile_Fixed_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "fixed_comments". */
export type Mobile_Fixed_Comments_Order_By = {
  comment?: InputMaybe<Mobile_Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Mobile_Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fixed_comments */
export type Mobile_Fixed_Comments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "fixed_comments" */
export enum Mobile_Fixed_Comments_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "fixed_comments" */
export type Mobile_Fixed_Comments_Set_Input = {
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Fixed_Comments_Stddev_Fields = {
  __typename?: 'mobile_fixed_comments_stddev_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Fixed_Comments_Stddev_Pop_Fields = {
  __typename?: 'mobile_fixed_comments_stddev_pop_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Fixed_Comments_Stddev_Samp_Fields = {
  __typename?: 'mobile_fixed_comments_stddev_samp_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_fixed_comments" */
export type Mobile_Fixed_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Fixed_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Fixed_Comments_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Fixed_Comments_Sum_Fields = {
  __typename?: 'mobile_fixed_comments_sum_fields';
  comment_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "fixed_comments" */
export enum Mobile_Fixed_Comments_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Fixed_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Fixed_Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Fixed_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Fixed_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Fixed_Comments_Var_Pop_Fields = {
  __typename?: 'mobile_fixed_comments_var_pop_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Fixed_Comments_Var_Samp_Fields = {
  __typename?: 'mobile_fixed_comments_var_samp_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Fixed_Comments_Variance_Fields = {
  __typename?: 'mobile_fixed_comments_variance_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "founder_mediafiles" */
export type Mobile_Founder_Mediafiles = {
  __typename?: 'mobile_founder_mediafiles';
  created_at: Scalars['timestamp']['output'];
  /** An object relationship */
  founder: Mobile_Founders;
  founder_id: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mediafile_status: Mobile_Mediafile_Statuses;
  mediafile_status_id: Scalars['bigint']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Aggregate = {
  __typename?: 'mobile_founder_mediafiles_aggregate';
  aggregate?: Maybe<Mobile_Founder_Mediafiles_Aggregate_Fields>;
  nodes: Array<Mobile_Founder_Mediafiles>;
};

export type Mobile_Founder_Mediafiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Founder_Mediafiles_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Founder_Mediafiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Aggregate_Fields = {
  __typename?: 'mobile_founder_mediafiles_aggregate_fields';
  avg?: Maybe<Mobile_Founder_Mediafiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Founder_Mediafiles_Max_Fields>;
  min?: Maybe<Mobile_Founder_Mediafiles_Min_Fields>;
  stddev?: Maybe<Mobile_Founder_Mediafiles_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Founder_Mediafiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Founder_Mediafiles_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Founder_Mediafiles_Sum_Fields>;
  var_pop?: Maybe<Mobile_Founder_Mediafiles_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Founder_Mediafiles_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Founder_Mediafiles_Variance_Fields>;
};


/** aggregate fields of "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Founder_Mediafiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Founder_Mediafiles_Max_Order_By>;
  min?: InputMaybe<Mobile_Founder_Mediafiles_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Founder_Mediafiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Founder_Mediafiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Founder_Mediafiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Founder_Mediafiles_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Founder_Mediafiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Founder_Mediafiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Founder_Mediafiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Founder_Mediafiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Founder_Mediafiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Founder_Mediafiles_Avg_Fields = {
  __typename?: 'mobile_founder_mediafiles_avg_fields';
  founder_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Avg_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "founder_mediafiles". All fields are combined with a logical 'AND'. */
export type Mobile_Founder_Mediafiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Founder_Mediafiles_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Founder_Mediafiles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  founder?: InputMaybe<Mobile_Founders_Bool_Exp>;
  founder_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mediafile_path?: InputMaybe<String_Comparison_Exp>;
  mediafile_size?: InputMaybe<String_Comparison_Exp>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
  mediafile_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "founder_mediafiles" */
export enum Mobile_Founder_Mediafiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  FounderMediafilesPkey = 'founder_mediafiles_pkey'
}

/** input type for incrementing numeric columns in table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Inc_Input = {
  founder_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  founder?: InputMaybe<Mobile_Founders_Obj_Rel_Insert_Input>;
  founder_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Obj_Rel_Insert_Input>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Founder_Mediafiles_Max_Fields = {
  __typename?: 'mobile_founder_mediafiles_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  founder_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Founder_Mediafiles_Min_Fields = {
  __typename?: 'mobile_founder_mediafiles_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  founder_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_path?: Maybe<Scalars['String']['output']>;
  mediafile_size?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Mutation_Response = {
  __typename?: 'mobile_founder_mediafiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Founder_Mediafiles>;
};

/** on_conflict condition type for table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_On_Conflict = {
  constraint: Mobile_Founder_Mediafiles_Constraint;
  update_columns?: Array<Mobile_Founder_Mediafiles_Update_Column>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};

/** Ordering options when selecting data from "founder_mediafiles". */
export type Mobile_Founder_Mediafiles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  founder?: InputMaybe<Mobile_Founders_Order_By>;
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_path?: InputMaybe<Order_By>;
  mediafile_size?: InputMaybe<Order_By>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: founder_mediafiles */
export type Mobile_Founder_Mediafiles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "founder_mediafiles" */
export enum Mobile_Founder_Mediafiles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FounderId = 'founder_id',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  founder_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Founder_Mediafiles_Stddev_Fields = {
  __typename?: 'mobile_founder_mediafiles_stddev_fields';
  founder_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Stddev_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Founder_Mediafiles_Stddev_Pop_Fields = {
  __typename?: 'mobile_founder_mediafiles_stddev_pop_fields';
  founder_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Stddev_Pop_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Founder_Mediafiles_Stddev_Samp_Fields = {
  __typename?: 'mobile_founder_mediafiles_stddev_samp_fields';
  founder_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Stddev_Samp_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Founder_Mediafiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Founder_Mediafiles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  founder_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_path?: InputMaybe<Scalars['String']['input']>;
  mediafile_size?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Founder_Mediafiles_Sum_Fields = {
  __typename?: 'mobile_founder_mediafiles_sum_fields';
  founder_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Sum_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** update columns of table "founder_mediafiles" */
export enum Mobile_Founder_Mediafiles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FounderId = 'founder_id',
  /** column name */
  Id = 'id',
  /** column name */
  MediafilePath = 'mediafile_path',
  /** column name */
  MediafileSize = 'mediafile_size',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Founder_Mediafiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Founder_Mediafiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Founder_Mediafiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Founder_Mediafiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Founder_Mediafiles_Var_Pop_Fields = {
  __typename?: 'mobile_founder_mediafiles_var_pop_fields';
  founder_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Var_Pop_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Founder_Mediafiles_Var_Samp_Fields = {
  __typename?: 'mobile_founder_mediafiles_var_samp_fields';
  founder_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Var_Samp_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Founder_Mediafiles_Variance_Fields = {
  __typename?: 'mobile_founder_mediafiles_variance_fields';
  founder_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "founder_mediafiles" */
export type Mobile_Founder_Mediafiles_Variance_Order_By = {
  founder_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "founders" */
export type Mobile_Founders = {
  __typename?: 'mobile_founders';
  birth_date?: Maybe<Scalars['date']['output']>;
  created_at: Scalars['timestamp']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  founder_mediafiles: Array<Mobile_Founder_Mediafiles>;
  /** An aggregate relationship */
  founder_mediafiles_aggregate: Mobile_Founder_Mediafiles_Aggregate;
  id: Scalars['bigint']['output'];
  issue_date?: Maybe<Scalars['date']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  pro: Mobile_Pros;
  pro_id: Scalars['bigint']['output'];
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "founders" */
export type Mobile_FoundersFounder_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "founders" */
export type Mobile_FoundersFounder_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};

/** aggregated selection of "founders" */
export type Mobile_Founders_Aggregate = {
  __typename?: 'mobile_founders_aggregate';
  aggregate?: Maybe<Mobile_Founders_Aggregate_Fields>;
  nodes: Array<Mobile_Founders>;
};

export type Mobile_Founders_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Founders_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Founders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Founders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "founders" */
export type Mobile_Founders_Aggregate_Fields = {
  __typename?: 'mobile_founders_aggregate_fields';
  avg?: Maybe<Mobile_Founders_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Founders_Max_Fields>;
  min?: Maybe<Mobile_Founders_Min_Fields>;
  stddev?: Maybe<Mobile_Founders_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Founders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Founders_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Founders_Sum_Fields>;
  var_pop?: Maybe<Mobile_Founders_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Founders_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Founders_Variance_Fields>;
};


/** aggregate fields of "founders" */
export type Mobile_Founders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "founders" */
export type Mobile_Founders_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Founders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Founders_Max_Order_By>;
  min?: InputMaybe<Mobile_Founders_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Founders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Founders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Founders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Founders_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Founders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Founders_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Founders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "founders" */
export type Mobile_Founders_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Founders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Founders_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Founders_Avg_Fields = {
  __typename?: 'mobile_founders_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "founders" */
export type Mobile_Founders_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "founders". All fields are combined with a logical 'AND'. */
export type Mobile_Founders_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Founders_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Founders_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Founders_Bool_Exp>>;
  birth_date?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  founder_mediafiles?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
  founder_mediafiles_aggregate?: InputMaybe<Mobile_Founder_Mediafiles_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  issue_date?: InputMaybe<Date_Comparison_Exp>;
  issued_by?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  patronymic?: InputMaybe<String_Comparison_Exp>;
  pro?: InputMaybe<Mobile_Pros_Bool_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  registration_address?: InputMaybe<String_Comparison_Exp>;
  series_number?: InputMaybe<String_Comparison_Exp>;
  subdivision_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "founders" */
export enum Mobile_Founders_Constraint {
  /** unique or primary key constraint on columns "id" */
  FoundersPkey = 'founders_pkey'
}

/** input type for incrementing numeric columns in table "founders" */
export type Mobile_Founders_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "founders" */
export type Mobile_Founders_Insert_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  founder_mediafiles?: InputMaybe<Mobile_Founder_Mediafiles_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  pro?: InputMaybe<Mobile_Pros_Obj_Rel_Insert_Input>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Founders_Max_Fields = {
  __typename?: 'mobile_founders_max_fields';
  birth_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "founders" */
export type Mobile_Founders_Max_Order_By = {
  birth_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  issued_by?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patronymic?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  series_number?: InputMaybe<Order_By>;
  subdivision_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Founders_Min_Fields = {
  __typename?: 'mobile_founders_min_fields';
  birth_date?: Maybe<Scalars['date']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  issued_by?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  registration_address?: Maybe<Scalars['String']['output']>;
  series_number?: Maybe<Scalars['String']['output']>;
  subdivision_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "founders" */
export type Mobile_Founders_Min_Order_By = {
  birth_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  issued_by?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patronymic?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  series_number?: InputMaybe<Order_By>;
  subdivision_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "founders" */
export type Mobile_Founders_Mutation_Response = {
  __typename?: 'mobile_founders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Founders>;
};

/** input type for inserting object relation for remote table "founders" */
export type Mobile_Founders_Obj_Rel_Insert_Input = {
  data: Mobile_Founders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Founders_On_Conflict>;
};

/** on_conflict condition type for table "founders" */
export type Mobile_Founders_On_Conflict = {
  constraint: Mobile_Founders_Constraint;
  update_columns?: Array<Mobile_Founders_Update_Column>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};

/** Ordering options when selecting data from "founders". */
export type Mobile_Founders_Order_By = {
  birth_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  founder_mediafiles_aggregate?: InputMaybe<Mobile_Founder_Mediafiles_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  issued_by?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patronymic?: InputMaybe<Order_By>;
  pro?: InputMaybe<Mobile_Pros_Order_By>;
  pro_id?: InputMaybe<Order_By>;
  registration_address?: InputMaybe<Order_By>;
  series_number?: InputMaybe<Order_By>;
  subdivision_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: founders */
export type Mobile_Founders_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "founders" */
export enum Mobile_Founders_Select_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IssueDate = 'issue_date',
  /** column name */
  IssuedBy = 'issued_by',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  SeriesNumber = 'series_number',
  /** column name */
  SubdivisionCode = 'subdivision_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "founders" */
export type Mobile_Founders_Set_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Founders_Stddev_Fields = {
  __typename?: 'mobile_founders_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "founders" */
export type Mobile_Founders_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Founders_Stddev_Pop_Fields = {
  __typename?: 'mobile_founders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "founders" */
export type Mobile_Founders_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Founders_Stddev_Samp_Fields = {
  __typename?: 'mobile_founders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "founders" */
export type Mobile_Founders_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_founders" */
export type Mobile_Founders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Founders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Founders_Stream_Cursor_Value_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  issued_by?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  registration_address?: InputMaybe<Scalars['String']['input']>;
  series_number?: InputMaybe<Scalars['String']['input']>;
  subdivision_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Founders_Sum_Fields = {
  __typename?: 'mobile_founders_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "founders" */
export type Mobile_Founders_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** update columns of table "founders" */
export enum Mobile_Founders_Update_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IssueDate = 'issue_date',
  /** column name */
  IssuedBy = 'issued_by',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  RegistrationAddress = 'registration_address',
  /** column name */
  SeriesNumber = 'series_number',
  /** column name */
  SubdivisionCode = 'subdivision_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Founders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Founders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Founders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Founders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Founders_Var_Pop_Fields = {
  __typename?: 'mobile_founders_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "founders" */
export type Mobile_Founders_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Founders_Var_Samp_Fields = {
  __typename?: 'mobile_founders_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "founders" */
export type Mobile_Founders_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Founders_Variance_Fields = {
  __typename?: 'mobile_founders_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "founders" */
export type Mobile_Founders_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "invite_emails" */
export type Mobile_Invite_Emails = {
  __typename?: 'mobile_invite_emails';
  created_at: Scalars['timestamptz']['output'];
  date_email_sent: Scalars['timestamp']['output'];
  email: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "invite_emails" */
export type Mobile_Invite_Emails_Aggregate = {
  __typename?: 'mobile_invite_emails_aggregate';
  aggregate?: Maybe<Mobile_Invite_Emails_Aggregate_Fields>;
  nodes: Array<Mobile_Invite_Emails>;
};

/** aggregate fields of "invite_emails" */
export type Mobile_Invite_Emails_Aggregate_Fields = {
  __typename?: 'mobile_invite_emails_aggregate_fields';
  avg?: Maybe<Mobile_Invite_Emails_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Invite_Emails_Max_Fields>;
  min?: Maybe<Mobile_Invite_Emails_Min_Fields>;
  stddev?: Maybe<Mobile_Invite_Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Invite_Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Invite_Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Invite_Emails_Sum_Fields>;
  var_pop?: Maybe<Mobile_Invite_Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Invite_Emails_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Invite_Emails_Variance_Fields>;
};


/** aggregate fields of "invite_emails" */
export type Mobile_Invite_Emails_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Invite_Emails_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Invite_Emails_Avg_Fields = {
  __typename?: 'mobile_invite_emails_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "invite_emails". All fields are combined with a logical 'AND'. */
export type Mobile_Invite_Emails_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Invite_Emails_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Invite_Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Invite_Emails_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_email_sent?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "invite_emails" */
export enum Mobile_Invite_Emails_Constraint {
  /** unique or primary key constraint on columns "id" */
  InviteEmailsPkey = 'invite_emails_pkey'
}

/** input type for incrementing numeric columns in table "invite_emails" */
export type Mobile_Invite_Emails_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "invite_emails" */
export type Mobile_Invite_Emails_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_email_sent?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Invite_Emails_Max_Fields = {
  __typename?: 'mobile_invite_emails_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_email_sent?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Invite_Emails_Min_Fields = {
  __typename?: 'mobile_invite_emails_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_email_sent?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "invite_emails" */
export type Mobile_Invite_Emails_Mutation_Response = {
  __typename?: 'mobile_invite_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Invite_Emails>;
};

/** on_conflict condition type for table "invite_emails" */
export type Mobile_Invite_Emails_On_Conflict = {
  constraint: Mobile_Invite_Emails_Constraint;
  update_columns?: Array<Mobile_Invite_Emails_Update_Column>;
  where?: InputMaybe<Mobile_Invite_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "invite_emails". */
export type Mobile_Invite_Emails_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date_email_sent?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invite_emails */
export type Mobile_Invite_Emails_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "invite_emails" */
export enum Mobile_Invite_Emails_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateEmailSent = 'date_email_sent',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "invite_emails" */
export type Mobile_Invite_Emails_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_email_sent?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Invite_Emails_Stddev_Fields = {
  __typename?: 'mobile_invite_emails_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Invite_Emails_Stddev_Pop_Fields = {
  __typename?: 'mobile_invite_emails_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Invite_Emails_Stddev_Samp_Fields = {
  __typename?: 'mobile_invite_emails_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_invite_emails" */
export type Mobile_Invite_Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Invite_Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Invite_Emails_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_email_sent?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Invite_Emails_Sum_Fields = {
  __typename?: 'mobile_invite_emails_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "invite_emails" */
export enum Mobile_Invite_Emails_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateEmailSent = 'date_email_sent',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Invite_Emails_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Invite_Emails_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Invite_Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Invite_Emails_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Invite_Emails_Var_Pop_Fields = {
  __typename?: 'mobile_invite_emails_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Invite_Emails_Var_Samp_Fields = {
  __typename?: 'mobile_invite_emails_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Invite_Emails_Variance_Fields = {
  __typename?: 'mobile_invite_emails_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "like_notifications" */
export type Mobile_Like_Notifications = {
  __typename?: 'mobile_like_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  likes?: Maybe<Mobile_Likes>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notifications?: Maybe<Mobile_Notifications>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "like_notifications" */
export type Mobile_Like_Notifications_Aggregate = {
  __typename?: 'mobile_like_notifications_aggregate';
  aggregate?: Maybe<Mobile_Like_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Like_Notifications>;
};

/** aggregate fields of "like_notifications" */
export type Mobile_Like_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_like_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Like_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Like_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Like_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Like_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Like_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Like_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Like_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Like_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Like_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Like_Notifications_Variance_Fields>;
};


/** aggregate fields of "like_notifications" */
export type Mobile_Like_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Like_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Like_Notifications_Avg_Fields = {
  __typename?: 'mobile_like_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "like_notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Like_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Like_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Like_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Like_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  likes?: InputMaybe<Mobile_Likes_Bool_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "like_notifications" */
export type Mobile_Like_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "like_notifications" */
export type Mobile_Like_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  likes?: InputMaybe<Mobile_Likes_Obj_Rel_Insert_Input>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Mobile_Notifications_Obj_Rel_Insert_Input>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Like_Notifications_Max_Fields = {
  __typename?: 'mobile_like_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Like_Notifications_Min_Fields = {
  __typename?: 'mobile_like_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "like_notifications" */
export type Mobile_Like_Notifications_Mutation_Response = {
  __typename?: 'mobile_like_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Like_Notifications>;
};

/** input type for inserting object relation for remote table "like_notifications" */
export type Mobile_Like_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Like_Notifications_Insert_Input;
};

/** Ordering options when selecting data from "like_notifications". */
export type Mobile_Like_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  likes?: InputMaybe<Mobile_Likes_Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Mobile_Notifications_Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "like_notifications" */
export enum Mobile_Like_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "like_notifications" */
export type Mobile_Like_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Like_Notifications_Stddev_Fields = {
  __typename?: 'mobile_like_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Like_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_like_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Like_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_like_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_like_notifications" */
export type Mobile_Like_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Like_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Like_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Like_Notifications_Sum_Fields = {
  __typename?: 'mobile_like_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Like_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Like_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Like_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Like_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Like_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_like_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Like_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_like_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Like_Notifications_Variance_Fields = {
  __typename?: 'mobile_like_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "likes" */
export type Mobile_Likes = {
  __typename?: 'mobile_likes';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  post?: Maybe<Mobile_Posts>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "likes" */
export type Mobile_Likes_Aggregate = {
  __typename?: 'mobile_likes_aggregate';
  aggregate?: Maybe<Mobile_Likes_Aggregate_Fields>;
  nodes: Array<Mobile_Likes>;
};

export type Mobile_Likes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Likes_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Likes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Likes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "likes" */
export type Mobile_Likes_Aggregate_Fields = {
  __typename?: 'mobile_likes_aggregate_fields';
  avg?: Maybe<Mobile_Likes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Likes_Max_Fields>;
  min?: Maybe<Mobile_Likes_Min_Fields>;
  stddev?: Maybe<Mobile_Likes_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Likes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Likes_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Likes_Sum_Fields>;
  var_pop?: Maybe<Mobile_Likes_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Likes_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Likes_Variance_Fields>;
};


/** aggregate fields of "likes" */
export type Mobile_Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "likes" */
export type Mobile_Likes_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Likes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Likes_Max_Order_By>;
  min?: InputMaybe<Mobile_Likes_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Likes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Likes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Likes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Likes_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Likes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Likes_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Likes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "likes" */
export type Mobile_Likes_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Likes_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Likes_Avg_Fields = {
  __typename?: 'mobile_likes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "likes" */
export type Mobile_Likes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "likes". All fields are combined with a logical 'AND'. */
export type Mobile_Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Likes_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Likes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  post?: InputMaybe<Mobile_Posts_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "likes" */
export enum Mobile_Likes_Constraint {
  /** unique or primary key constraint on columns "id" */
  LikesPkey = 'likes_pkey',
  /** unique or primary key constraint on columns "user_id", "post_id" */
  UniquePostIdUserId = 'unique_post_id_user_id'
}

/** input type for incrementing numeric columns in table "likes" */
export type Mobile_Likes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "likes" */
export type Mobile_Likes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post?: InputMaybe<Mobile_Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Likes_Max_Fields = {
  __typename?: 'mobile_likes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "likes" */
export type Mobile_Likes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Likes_Min_Fields = {
  __typename?: 'mobile_likes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "likes" */
export type Mobile_Likes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "likes" */
export type Mobile_Likes_Mutation_Response = {
  __typename?: 'mobile_likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Likes>;
};

/** input type for inserting object relation for remote table "likes" */
export type Mobile_Likes_Obj_Rel_Insert_Input = {
  data: Mobile_Likes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Likes_On_Conflict>;
};

/** on_conflict condition type for table "likes" */
export type Mobile_Likes_On_Conflict = {
  constraint: Mobile_Likes_Constraint;
  update_columns?: Array<Mobile_Likes_Update_Column>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "likes". */
export type Mobile_Likes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Mobile_Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: likes */
export type Mobile_Likes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "likes" */
export enum Mobile_Likes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "likes" */
export type Mobile_Likes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Likes_Stddev_Fields = {
  __typename?: 'mobile_likes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "likes" */
export type Mobile_Likes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Likes_Stddev_Pop_Fields = {
  __typename?: 'mobile_likes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "likes" */
export type Mobile_Likes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Likes_Stddev_Samp_Fields = {
  __typename?: 'mobile_likes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "likes" */
export type Mobile_Likes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_likes" */
export type Mobile_Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Likes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Likes_Sum_Fields = {
  __typename?: 'mobile_likes_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "likes" */
export type Mobile_Likes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "likes" */
export enum Mobile_Likes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Likes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Likes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Likes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Likes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Likes_Var_Pop_Fields = {
  __typename?: 'mobile_likes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "likes" */
export type Mobile_Likes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Likes_Var_Samp_Fields = {
  __typename?: 'mobile_likes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "likes" */
export type Mobile_Likes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Likes_Variance_Fields = {
  __typename?: 'mobile_likes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "likes" */
export type Mobile_Likes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "link_snippets" */
export type Mobile_Link_Snippets = {
  __typename?: 'mobile_link_snippets';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  image_url: Scalars['String']['output'];
  link: Scalars['String']['output'];
  root_url: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "link_snippets" */
export type Mobile_Link_Snippets_Aggregate = {
  __typename?: 'mobile_link_snippets_aggregate';
  aggregate?: Maybe<Mobile_Link_Snippets_Aggregate_Fields>;
  nodes: Array<Mobile_Link_Snippets>;
};

/** aggregate fields of "link_snippets" */
export type Mobile_Link_Snippets_Aggregate_Fields = {
  __typename?: 'mobile_link_snippets_aggregate_fields';
  avg?: Maybe<Mobile_Link_Snippets_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Link_Snippets_Max_Fields>;
  min?: Maybe<Mobile_Link_Snippets_Min_Fields>;
  stddev?: Maybe<Mobile_Link_Snippets_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Link_Snippets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Link_Snippets_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Link_Snippets_Sum_Fields>;
  var_pop?: Maybe<Mobile_Link_Snippets_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Link_Snippets_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Link_Snippets_Variance_Fields>;
};


/** aggregate fields of "link_snippets" */
export type Mobile_Link_Snippets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Link_Snippets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Link_Snippets_Avg_Fields = {
  __typename?: 'mobile_link_snippets_avg_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "link_snippets". All fields are combined with a logical 'AND'. */
export type Mobile_Link_Snippets_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Link_Snippets_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Link_Snippets_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Link_Snippets_Bool_Exp>>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  root_url?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "link_snippets" */
export enum Mobile_Link_Snippets_Constraint {
  /** unique or primary key constraint on columns "id" */
  LinkSnippetsPkey = 'link_snippets_pkey'
}

/** input type for incrementing numeric columns in table "link_snippets" */
export type Mobile_Link_Snippets_Inc_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "link_snippets" */
export type Mobile_Link_Snippets_Insert_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  root_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Link_Snippets_Max_Fields = {
  __typename?: 'mobile_link_snippets_max_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  root_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Link_Snippets_Min_Fields = {
  __typename?: 'mobile_link_snippets_min_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  root_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "link_snippets" */
export type Mobile_Link_Snippets_Mutation_Response = {
  __typename?: 'mobile_link_snippets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Link_Snippets>;
};

/** on_conflict condition type for table "link_snippets" */
export type Mobile_Link_Snippets_On_Conflict = {
  constraint: Mobile_Link_Snippets_Constraint;
  update_columns?: Array<Mobile_Link_Snippets_Update_Column>;
  where?: InputMaybe<Mobile_Link_Snippets_Bool_Exp>;
};

/** Ordering options when selecting data from "link_snippets". */
export type Mobile_Link_Snippets_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  root_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: link_snippets */
export type Mobile_Link_Snippets_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "link_snippets" */
export enum Mobile_Link_Snippets_Select_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Link = 'link',
  /** column name */
  RootUrl = 'root_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "link_snippets" */
export type Mobile_Link_Snippets_Set_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  root_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Link_Snippets_Stddev_Fields = {
  __typename?: 'mobile_link_snippets_stddev_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Link_Snippets_Stddev_Pop_Fields = {
  __typename?: 'mobile_link_snippets_stddev_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Link_Snippets_Stddev_Samp_Fields = {
  __typename?: 'mobile_link_snippets_stddev_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_link_snippets" */
export type Mobile_Link_Snippets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Link_Snippets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Link_Snippets_Stream_Cursor_Value_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  root_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Link_Snippets_Sum_Fields = {
  __typename?: 'mobile_link_snippets_sum_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "link_snippets" */
export enum Mobile_Link_Snippets_Update_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Link = 'link',
  /** column name */
  RootUrl = 'root_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Link_Snippets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Link_Snippets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Link_Snippets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Link_Snippets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Link_Snippets_Var_Pop_Fields = {
  __typename?: 'mobile_link_snippets_var_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Link_Snippets_Var_Samp_Fields = {
  __typename?: 'mobile_link_snippets_var_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Link_Snippets_Variance_Fields = {
  __typename?: 'mobile_link_snippets_variance_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages = {
  __typename?: 'mobile_locked_deleted_chat_messages';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  deleted_all?: Maybe<Scalars['Boolean']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Aggregate = {
  __typename?: 'mobile_locked_deleted_chat_messages_aggregate';
  aggregate?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Aggregate_Fields>;
  nodes: Array<Mobile_Locked_Deleted_Chat_Messages>;
};

/** aggregate fields of "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Aggregate_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_aggregate_fields';
  avg?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Max_Fields>;
  min?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Min_Fields>;
  stddev?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Variance_Fields>;
};


/** aggregate fields of "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Avg_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_avg_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "locked_deleted_chat_messages". All fields are combined with a logical 'AND'. */
export type Mobile_Locked_Deleted_Chat_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_messages_count?: InputMaybe<Bigint_Comparison_Exp>;
  deleted_all?: InputMaybe<Boolean_Comparison_Exp>;
  deletion_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Inc_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Insert_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  deleted_all?: InputMaybe<Scalars['Boolean']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Max_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_max_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Min_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_min_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Mutation_Response = {
  __typename?: 'mobile_locked_deleted_chat_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Locked_Deleted_Chat_Messages>;
};

/** Ordering options when selecting data from "locked_deleted_chat_messages". */
export type Mobile_Locked_Deleted_Chat_Messages_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_messages_count?: InputMaybe<Order_By>;
  deleted_all?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "locked_deleted_chat_messages" */
export enum Mobile_Locked_Deleted_Chat_Messages_Select_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatMessagesCount = 'chat_messages_count',
  /** column name */
  DeletedAll = 'deleted_all',
  /** column name */
  DeletionDate = 'deletion_date',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Set_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  deleted_all?: InputMaybe<Scalars['Boolean']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Stddev_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_stddev_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Stddev_Pop_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_stddev_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Stddev_Samp_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_stddev_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_locked_deleted_chat_messages" */
export type Mobile_Locked_Deleted_Chat_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Locked_Deleted_Chat_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Locked_Deleted_Chat_Messages_Stream_Cursor_Value_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_messages_count?: InputMaybe<Scalars['bigint']['input']>;
  deleted_all?: InputMaybe<Scalars['Boolean']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Sum_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_sum_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_messages_count?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Locked_Deleted_Chat_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Locked_Deleted_Chat_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Var_Pop_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_var_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Var_Samp_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_var_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Locked_Deleted_Chat_Messages_Variance_Fields = {
  __typename?: 'mobile_locked_deleted_chat_messages_variance_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_messages_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names = {
  __typename?: 'mobile_mediafile_status_names';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mediafile_status: Mobile_Mediafile_Statuses;
  mediafile_status_id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Aggregate = {
  __typename?: 'mobile_mediafile_status_names_aggregate';
  aggregate?: Maybe<Mobile_Mediafile_Status_Names_Aggregate_Fields>;
  nodes: Array<Mobile_Mediafile_Status_Names>;
};

export type Mobile_Mediafile_Status_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Mediafile_Status_Names_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Mediafile_Status_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Aggregate_Fields = {
  __typename?: 'mobile_mediafile_status_names_aggregate_fields';
  avg?: Maybe<Mobile_Mediafile_Status_Names_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Mediafile_Status_Names_Max_Fields>;
  min?: Maybe<Mobile_Mediafile_Status_Names_Min_Fields>;
  stddev?: Maybe<Mobile_Mediafile_Status_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Mediafile_Status_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Mediafile_Status_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Mediafile_Status_Names_Sum_Fields>;
  var_pop?: Maybe<Mobile_Mediafile_Status_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Mediafile_Status_Names_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Mediafile_Status_Names_Variance_Fields>;
};


/** aggregate fields of "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Mediafile_Status_Names_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Mediafile_Status_Names_Max_Order_By>;
  min?: InputMaybe<Mobile_Mediafile_Status_Names_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Mediafile_Status_Names_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Mediafile_Status_Names_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Mediafile_Status_Names_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Mediafile_Status_Names_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Mediafile_Status_Names_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Mediafile_Status_Names_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Mediafile_Status_Names_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Mediafile_Status_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Mediafile_Status_Names_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Mediafile_Status_Names_Avg_Fields = {
  __typename?: 'mobile_mediafile_status_names_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mediafile_status_names". All fields are combined with a logical 'AND'. */
export type Mobile_Mediafile_Status_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
  mediafile_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "mediafile_status_names" */
export enum Mobile_Mediafile_Status_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  MediafileStatusNamesPkey = 'mediafile_status_names_pkey'
}

/** input type for incrementing numeric columns in table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Obj_Rel_Insert_Input>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Mediafile_Status_Names_Max_Fields = {
  __typename?: 'mobile_mediafile_status_names_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Mediafile_Status_Names_Min_Fields = {
  __typename?: 'mobile_mediafile_status_names_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Mutation_Response = {
  __typename?: 'mobile_mediafile_status_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Mediafile_Status_Names>;
};

/** on_conflict condition type for table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_On_Conflict = {
  constraint: Mobile_Mediafile_Status_Names_Constraint;
  update_columns?: Array<Mobile_Mediafile_Status_Names_Update_Column>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "mediafile_status_names". */
export type Mobile_Mediafile_Status_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  mediafile_status?: InputMaybe<Mobile_Mediafile_Statuses_Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mediafile_status_names */
export type Mobile_Mediafile_Status_Names_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "mediafile_status_names" */
export enum Mobile_Mediafile_Status_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Mediafile_Status_Names_Stddev_Fields = {
  __typename?: 'mobile_mediafile_status_names_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Mediafile_Status_Names_Stddev_Pop_Fields = {
  __typename?: 'mobile_mediafile_status_names_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Mediafile_Status_Names_Stddev_Samp_Fields = {
  __typename?: 'mobile_mediafile_status_names_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Mediafile_Status_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Mediafile_Status_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  mediafile_status_id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Mediafile_Status_Names_Sum_Fields = {
  __typename?: 'mobile_mediafile_status_names_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  mediafile_status_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** update columns of table "mediafile_status_names" */
export enum Mobile_Mediafile_Status_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  MediafileStatusId = 'mediafile_status_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Mediafile_Status_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Mediafile_Status_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Mediafile_Status_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Mediafile_Status_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Mediafile_Status_Names_Var_Pop_Fields = {
  __typename?: 'mobile_mediafile_status_names_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Mediafile_Status_Names_Var_Samp_Fields = {
  __typename?: 'mobile_mediafile_status_names_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Mediafile_Status_Names_Variance_Fields = {
  __typename?: 'mobile_mediafile_status_names_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mediafile_status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "mediafile_status_names" */
export type Mobile_Mediafile_Status_Names_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  mediafile_status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_Statuses = {
  __typename?: 'mobile_mediafile_statuses';
  created_at: Scalars['timestamp']['output'];
  /** An array relationship */
  document_mediafiles: Array<Mobile_Document_Mediafiles>;
  /** An aggregate relationship */
  document_mediafiles_aggregate: Mobile_Document_Mediafiles_Aggregate;
  /** An array relationship */
  education_mediafiles: Array<Mobile_Education_Mediafiles>;
  /** An aggregate relationship */
  education_mediafiles_aggregate: Mobile_Education_Mediafiles_Aggregate;
  /** An array relationship */
  founder_mediafiles: Array<Mobile_Founder_Mediafiles>;
  /** An aggregate relationship */
  founder_mediafiles_aggregate: Mobile_Founder_Mediafiles_Aggregate;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  mediafile_status_names: Array<Mobile_Mediafile_Status_Names>;
  /** An aggregate relationship */
  mediafile_status_names_aggregate: Mobile_Mediafile_Status_Names_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesDocument_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesDocument_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesEducation_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesEducation_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesFounder_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesFounder_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesMediafile_Status_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};


/** columns and relationships of "mediafile_statuses" */
export type Mobile_Mediafile_StatusesMediafile_Status_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};

/** aggregated selection of "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Aggregate = {
  __typename?: 'mobile_mediafile_statuses_aggregate';
  aggregate?: Maybe<Mobile_Mediafile_Statuses_Aggregate_Fields>;
  nodes: Array<Mobile_Mediafile_Statuses>;
};

/** aggregate fields of "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Aggregate_Fields = {
  __typename?: 'mobile_mediafile_statuses_aggregate_fields';
  avg?: Maybe<Mobile_Mediafile_Statuses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Mediafile_Statuses_Max_Fields>;
  min?: Maybe<Mobile_Mediafile_Statuses_Min_Fields>;
  stddev?: Maybe<Mobile_Mediafile_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Mediafile_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Mediafile_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Mediafile_Statuses_Sum_Fields>;
  var_pop?: Maybe<Mobile_Mediafile_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Mediafile_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Mediafile_Statuses_Variance_Fields>;
};


/** aggregate fields of "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Mediafile_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Mediafile_Statuses_Avg_Fields = {
  __typename?: 'mobile_mediafile_statuses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "mediafile_statuses". All fields are combined with a logical 'AND'. */
export type Mobile_Mediafile_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Mediafile_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Mediafile_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  document_mediafiles?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
  document_mediafiles_aggregate?: InputMaybe<Mobile_Document_Mediafiles_Aggregate_Bool_Exp>;
  education_mediafiles?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
  education_mediafiles_aggregate?: InputMaybe<Mobile_Education_Mediafiles_Aggregate_Bool_Exp>;
  founder_mediafiles?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
  founder_mediafiles_aggregate?: InputMaybe<Mobile_Founder_Mediafiles_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mediafile_status_names?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
  mediafile_status_names_aggregate?: InputMaybe<Mobile_Mediafile_Status_Names_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "mediafile_statuses" */
export enum Mobile_Mediafile_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  MediafileStatusesPkey = 'mediafile_statuses_pkey'
}

/** input type for incrementing numeric columns in table "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_mediafiles?: InputMaybe<Mobile_Document_Mediafiles_Arr_Rel_Insert_Input>;
  education_mediafiles?: InputMaybe<Mobile_Education_Mediafiles_Arr_Rel_Insert_Input>;
  founder_mediafiles?: InputMaybe<Mobile_Founder_Mediafiles_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mediafile_status_names?: InputMaybe<Mobile_Mediafile_Status_Names_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Mediafile_Statuses_Max_Fields = {
  __typename?: 'mobile_mediafile_statuses_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Mediafile_Statuses_Min_Fields = {
  __typename?: 'mobile_mediafile_statuses_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Mutation_Response = {
  __typename?: 'mobile_mediafile_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Mediafile_Statuses>;
};

/** input type for inserting object relation for remote table "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Obj_Rel_Insert_Input = {
  data: Mobile_Mediafile_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Mediafile_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_On_Conflict = {
  constraint: Mobile_Mediafile_Statuses_Constraint;
  update_columns?: Array<Mobile_Mediafile_Statuses_Update_Column>;
  where?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "mediafile_statuses". */
export type Mobile_Mediafile_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_mediafiles_aggregate?: InputMaybe<Mobile_Document_Mediafiles_Aggregate_Order_By>;
  education_mediafiles_aggregate?: InputMaybe<Mobile_Education_Mediafiles_Aggregate_Order_By>;
  founder_mediafiles_aggregate?: InputMaybe<Mobile_Founder_Mediafiles_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  mediafile_status_names_aggregate?: InputMaybe<Mobile_Mediafile_Status_Names_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mediafile_statuses */
export type Mobile_Mediafile_Statuses_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "mediafile_statuses" */
export enum Mobile_Mediafile_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Mediafile_Statuses_Stddev_Fields = {
  __typename?: 'mobile_mediafile_statuses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Mediafile_Statuses_Stddev_Pop_Fields = {
  __typename?: 'mobile_mediafile_statuses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Mediafile_Statuses_Stddev_Samp_Fields = {
  __typename?: 'mobile_mediafile_statuses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_mediafile_statuses" */
export type Mobile_Mediafile_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Mediafile_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Mediafile_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Mediafile_Statuses_Sum_Fields = {
  __typename?: 'mobile_mediafile_statuses_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "mediafile_statuses" */
export enum Mobile_Mediafile_Statuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Mediafile_Statuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Mediafile_Statuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Mediafile_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Mediafile_Statuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Mediafile_Statuses_Var_Pop_Fields = {
  __typename?: 'mobile_mediafile_statuses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Mediafile_Statuses_Var_Samp_Fields = {
  __typename?: 'mobile_mediafile_statuses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Mediafile_Statuses_Variance_Fields = {
  __typename?: 'mobile_mediafile_statuses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders = {
  __typename?: 'mobile_medical_concierge_orders';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  medical_concierge_order?: Maybe<Mobile_Medical_Concierge_Orders>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  medical_concierge_product?: Maybe<Mobile_Medical_Concierge_Products>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Aggregate = {
  __typename?: 'mobile_medical_concierge_orders_aggregate';
  aggregate?: Maybe<Mobile_Medical_Concierge_Orders_Aggregate_Fields>;
  nodes: Array<Mobile_Medical_Concierge_Orders>;
};

export type Mobile_Medical_Concierge_Orders_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Medical_Concierge_Orders_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Medical_Concierge_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Aggregate_Fields = {
  __typename?: 'mobile_medical_concierge_orders_aggregate_fields';
  avg?: Maybe<Mobile_Medical_Concierge_Orders_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Medical_Concierge_Orders_Max_Fields>;
  min?: Maybe<Mobile_Medical_Concierge_Orders_Min_Fields>;
  stddev?: Maybe<Mobile_Medical_Concierge_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Medical_Concierge_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Medical_Concierge_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Medical_Concierge_Orders_Sum_Fields>;
  var_pop?: Maybe<Mobile_Medical_Concierge_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Medical_Concierge_Orders_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Medical_Concierge_Orders_Variance_Fields>;
};


/** aggregate fields of "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Medical_Concierge_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Medical_Concierge_Orders_Max_Order_By>;
  min?: InputMaybe<Mobile_Medical_Concierge_Orders_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Medical_Concierge_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Medical_Concierge_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Medical_Concierge_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Medical_Concierge_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Medical_Concierge_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Medical_Concierge_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Medical_Concierge_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Medical_Concierge_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Medical_Concierge_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Medical_Concierge_Orders_Avg_Fields = {
  __typename?: 'mobile_medical_concierge_orders_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "medical_concierge_orders". All fields are combined with a logical 'AND'. */
export type Mobile_Medical_Concierge_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  medical_concierge_order?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
  medical_concierge_order_id?: InputMaybe<Bigint_Comparison_Exp>;
  medical_concierge_product?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
  medical_concierge_product_id?: InputMaybe<Bigint_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  payment_status?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_concierge_orders" */
export enum Mobile_Medical_Concierge_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  MedicalConciergeOrdersPkey = 'medical_concierge_orders_pkey'
}

/** input type for incrementing numeric columns in table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_order?: InputMaybe<Mobile_Medical_Concierge_Orders_Obj_Rel_Insert_Input>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product?: InputMaybe<Mobile_Medical_Concierge_Products_Obj_Rel_Insert_Input>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Medical_Concierge_Orders_Max_Fields = {
  __typename?: 'mobile_medical_concierge_orders_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Medical_Concierge_Orders_Min_Fields = {
  __typename?: 'mobile_medical_concierge_orders_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Mutation_Response = {
  __typename?: 'mobile_medical_concierge_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Medical_Concierge_Orders>;
};

/** input type for inserting object relation for remote table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Obj_Rel_Insert_Input = {
  data: Mobile_Medical_Concierge_Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Medical_Concierge_Orders_On_Conflict>;
};

/** on_conflict condition type for table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_On_Conflict = {
  constraint: Mobile_Medical_Concierge_Orders_Constraint;
  update_columns?: Array<Mobile_Medical_Concierge_Orders_Update_Column>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_concierge_orders". */
export type Mobile_Medical_Concierge_Orders_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_concierge_order?: InputMaybe<Mobile_Medical_Concierge_Orders_Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product?: InputMaybe<Mobile_Medical_Concierge_Products_Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medical_concierge_orders */
export type Mobile_Medical_Concierge_Orders_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "medical_concierge_orders" */
export enum Mobile_Medical_Concierge_Orders_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  MedicalConciergeOrderId = 'medical_concierge_order_id',
  /** column name */
  MedicalConciergeProductId = 'medical_concierge_product_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Medical_Concierge_Orders_Stddev_Fields = {
  __typename?: 'mobile_medical_concierge_orders_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Medical_Concierge_Orders_Stddev_Pop_Fields = {
  __typename?: 'mobile_medical_concierge_orders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Medical_Concierge_Orders_Stddev_Samp_Fields = {
  __typename?: 'mobile_medical_concierge_orders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Medical_Concierge_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Medical_Concierge_Orders_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_order_id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_product_id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Medical_Concierge_Orders_Sum_Fields = {
  __typename?: 'mobile_medical_concierge_orders_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['bigint']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** update columns of table "medical_concierge_orders" */
export enum Mobile_Medical_Concierge_Orders_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  MedicalConciergeOrderId = 'medical_concierge_order_id',
  /** column name */
  MedicalConciergeProductId = 'medical_concierge_product_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Medical_Concierge_Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Medical_Concierge_Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Medical_Concierge_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Medical_Concierge_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Medical_Concierge_Orders_Var_Pop_Fields = {
  __typename?: 'mobile_medical_concierge_orders_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Medical_Concierge_Orders_Var_Samp_Fields = {
  __typename?: 'mobile_medical_concierge_orders_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Medical_Concierge_Orders_Variance_Fields = {
  __typename?: 'mobile_medical_concierge_orders_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_order_id?: Maybe<Scalars['Float']['output']>;
  medical_concierge_product_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "medical_concierge_orders" */
export type Mobile_Medical_Concierge_Orders_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  medical_concierge_order_id?: InputMaybe<Order_By>;
  medical_concierge_product_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products = {
  __typename?: 'mobile_medical_concierge_products';
  /** An array relationship */
  amocrm_leads: Array<Mobile_Amocrm_Leads>;
  /** An aggregate relationship */
  amocrm_leads_aggregate: Mobile_Amocrm_Leads_Aggregate;
  catalog_element_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  medical_concierge_orders: Array<Mobile_Medical_Concierge_Orders>;
  /** An aggregate relationship */
  medical_concierge_orders_aggregate: Mobile_Medical_Concierge_Orders_Aggregate;
  period?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_view?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "medical_concierge_products" */
export type Mobile_Medical_Concierge_ProductsAmocrm_LeadsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


/** columns and relationships of "medical_concierge_products" */
export type Mobile_Medical_Concierge_ProductsAmocrm_Leads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


/** columns and relationships of "medical_concierge_products" */
export type Mobile_Medical_Concierge_ProductsDescriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "medical_concierge_products" */
export type Mobile_Medical_Concierge_ProductsMedical_Concierge_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};


/** columns and relationships of "medical_concierge_products" */
export type Mobile_Medical_Concierge_ProductsMedical_Concierge_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};

/** aggregated selection of "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Aggregate = {
  __typename?: 'mobile_medical_concierge_products_aggregate';
  aggregate?: Maybe<Mobile_Medical_Concierge_Products_Aggregate_Fields>;
  nodes: Array<Mobile_Medical_Concierge_Products>;
};

/** aggregate fields of "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Aggregate_Fields = {
  __typename?: 'mobile_medical_concierge_products_aggregate_fields';
  avg?: Maybe<Mobile_Medical_Concierge_Products_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Medical_Concierge_Products_Max_Fields>;
  min?: Maybe<Mobile_Medical_Concierge_Products_Min_Fields>;
  stddev?: Maybe<Mobile_Medical_Concierge_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Medical_Concierge_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Medical_Concierge_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Medical_Concierge_Products_Sum_Fields>;
  var_pop?: Maybe<Mobile_Medical_Concierge_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Medical_Concierge_Products_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Medical_Concierge_Products_Variance_Fields>;
};


/** aggregate fields of "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Medical_Concierge_Products_Append_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Medical_Concierge_Products_Avg_Fields = {
  __typename?: 'mobile_medical_concierge_products_avg_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "medical_concierge_products". All fields are combined with a logical 'AND'. */
export type Mobile_Medical_Concierge_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Bool_Exp>>;
  amocrm_leads?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
  amocrm_leads_aggregate?: InputMaybe<Mobile_Amocrm_Leads_Aggregate_Bool_Exp>;
  catalog_element_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  medical_concierge_orders?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
  medical_concierge_orders_aggregate?: InputMaybe<Mobile_Medical_Concierge_Orders_Aggregate_Bool_Exp>;
  period?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  title_view?: InputMaybe<String_Comparison_Exp>;
  type_product?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_concierge_products" */
export enum Mobile_Medical_Concierge_Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  MedicalConciergeProductsPkey = 'medical_concierge_products_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mobile_Medical_Concierge_Products_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mobile_Medical_Concierge_Products_Delete_Elem_Input = {
  description?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mobile_Medical_Concierge_Products_Delete_Key_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Inc_Input = {
  catalog_element_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Insert_Input = {
  amocrm_leads?: InputMaybe<Mobile_Amocrm_Leads_Arr_Rel_Insert_Input>;
  catalog_element_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  medical_concierge_orders?: InputMaybe<Mobile_Medical_Concierge_Orders_Arr_Rel_Insert_Input>;
  period?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_view?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Medical_Concierge_Products_Max_Fields = {
  __typename?: 'mobile_medical_concierge_products_max_fields';
  catalog_element_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_view?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Medical_Concierge_Products_Min_Fields = {
  __typename?: 'mobile_medical_concierge_products_min_fields';
  catalog_element_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  title_view?: Maybe<Scalars['String']['output']>;
  type_product?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Mutation_Response = {
  __typename?: 'mobile_medical_concierge_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Medical_Concierge_Products>;
};

/** input type for inserting object relation for remote table "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Obj_Rel_Insert_Input = {
  data: Mobile_Medical_Concierge_Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Medical_Concierge_Products_On_Conflict>;
};

/** on_conflict condition type for table "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_On_Conflict = {
  constraint: Mobile_Medical_Concierge_Products_Constraint;
  update_columns?: Array<Mobile_Medical_Concierge_Products_Update_Column>;
  where?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_concierge_products". */
export type Mobile_Medical_Concierge_Products_Order_By = {
  amocrm_leads_aggregate?: InputMaybe<Mobile_Amocrm_Leads_Aggregate_Order_By>;
  catalog_element_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_concierge_orders_aggregate?: InputMaybe<Mobile_Medical_Concierge_Orders_Aggregate_Order_By>;
  period?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  title_view?: InputMaybe<Order_By>;
  type_product?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medical_concierge_products */
export type Mobile_Medical_Concierge_Products_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Medical_Concierge_Products_Prepend_Input = {
  description?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "medical_concierge_products" */
export enum Mobile_Medical_Concierge_Products_Select_Column {
  /** column name */
  CatalogElementId = 'catalog_element_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period',
  /** column name */
  Price = 'price',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleView = 'title_view',
  /** column name */
  TypeProduct = 'type_product',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Set_Input = {
  catalog_element_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_view?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Medical_Concierge_Products_Stddev_Fields = {
  __typename?: 'mobile_medical_concierge_products_stddev_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Medical_Concierge_Products_Stddev_Pop_Fields = {
  __typename?: 'mobile_medical_concierge_products_stddev_pop_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Medical_Concierge_Products_Stddev_Samp_Fields = {
  __typename?: 'mobile_medical_concierge_products_stddev_samp_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_medical_concierge_products" */
export type Mobile_Medical_Concierge_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Medical_Concierge_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Medical_Concierge_Products_Stream_Cursor_Value_Input = {
  catalog_element_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_view?: InputMaybe<Scalars['String']['input']>;
  type_product?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Medical_Concierge_Products_Sum_Fields = {
  __typename?: 'mobile_medical_concierge_products_sum_fields';
  catalog_element_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "medical_concierge_products" */
export enum Mobile_Medical_Concierge_Products_Update_Column {
  /** column name */
  CatalogElementId = 'catalog_element_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period',
  /** column name */
  Price = 'price',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  Title = 'title',
  /** column name */
  TitleView = 'title_view',
  /** column name */
  TypeProduct = 'type_product',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Medical_Concierge_Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mobile_Medical_Concierge_Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Medical_Concierge_Products_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mobile_Medical_Concierge_Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Medical_Concierge_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Medical_Concierge_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Medical_Concierge_Products_Var_Pop_Fields = {
  __typename?: 'mobile_medical_concierge_products_var_pop_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Medical_Concierge_Products_Var_Samp_Fields = {
  __typename?: 'mobile_medical_concierge_products_var_samp_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Medical_Concierge_Products_Variance_Fields = {
  __typename?: 'mobile_medical_concierge_products_variance_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "mention_notifications" */
export type Mobile_Mention_Notifications = {
  __typename?: 'mobile_mention_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  mentions?: Maybe<Mobile_Mentions>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notifications?: Maybe<Mobile_Notifications>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "mention_notifications" */
export type Mobile_Mention_Notifications_Aggregate = {
  __typename?: 'mobile_mention_notifications_aggregate';
  aggregate?: Maybe<Mobile_Mention_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Mention_Notifications>;
};

/** aggregate fields of "mention_notifications" */
export type Mobile_Mention_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_mention_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Mention_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Mention_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Mention_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Mention_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Mention_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Mention_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Mention_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Mention_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Mention_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Mention_Notifications_Variance_Fields>;
};


/** aggregate fields of "mention_notifications" */
export type Mobile_Mention_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Mention_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Mention_Notifications_Avg_Fields = {
  __typename?: 'mobile_mention_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "mention_notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Mention_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Mention_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Mention_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Mention_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  mentions?: InputMaybe<Mobile_Mentions_Bool_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "mention_notifications" */
export type Mobile_Mention_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "mention_notifications" */
export type Mobile_Mention_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  mentions?: InputMaybe<Mobile_Mentions_Obj_Rel_Insert_Input>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Mobile_Notifications_Obj_Rel_Insert_Input>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Mention_Notifications_Max_Fields = {
  __typename?: 'mobile_mention_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Mention_Notifications_Min_Fields = {
  __typename?: 'mobile_mention_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "mention_notifications" */
export type Mobile_Mention_Notifications_Mutation_Response = {
  __typename?: 'mobile_mention_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Mention_Notifications>;
};

/** input type for inserting object relation for remote table "mention_notifications" */
export type Mobile_Mention_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Mention_Notifications_Insert_Input;
};

/** Ordering options when selecting data from "mention_notifications". */
export type Mobile_Mention_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  mentions?: InputMaybe<Mobile_Mentions_Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Mobile_Notifications_Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "mention_notifications" */
export enum Mobile_Mention_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "mention_notifications" */
export type Mobile_Mention_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Mention_Notifications_Stddev_Fields = {
  __typename?: 'mobile_mention_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Mention_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_mention_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Mention_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_mention_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_mention_notifications" */
export type Mobile_Mention_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Mention_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Mention_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Mention_Notifications_Sum_Fields = {
  __typename?: 'mobile_mention_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Mention_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Mention_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Mention_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Mention_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Mention_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_mention_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Mention_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_mention_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Mention_Notifications_Variance_Fields = {
  __typename?: 'mobile_mention_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "mentions" */
export type Mobile_Mentions = {
  __typename?: 'mobile_mentions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  mentionable_id?: Maybe<Scalars['bigint']['output']>;
  mentionable_type?: Maybe<Scalars['String']['output']>;
  notifier_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  notifier_profile?: Maybe<Mobile_Profiles>;
  recipient_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  recipient_profile?: Maybe<Mobile_Profiles>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "mentions" */
export type Mobile_Mentions_Aggregate = {
  __typename?: 'mobile_mentions_aggregate';
  aggregate?: Maybe<Mobile_Mentions_Aggregate_Fields>;
  nodes: Array<Mobile_Mentions>;
};

/** aggregate fields of "mentions" */
export type Mobile_Mentions_Aggregate_Fields = {
  __typename?: 'mobile_mentions_aggregate_fields';
  avg?: Maybe<Mobile_Mentions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Mentions_Max_Fields>;
  min?: Maybe<Mobile_Mentions_Min_Fields>;
  stddev?: Maybe<Mobile_Mentions_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Mentions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Mentions_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Mentions_Sum_Fields>;
  var_pop?: Maybe<Mobile_Mentions_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Mentions_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Mentions_Variance_Fields>;
};


/** aggregate fields of "mentions" */
export type Mobile_Mentions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Mentions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Mentions_Avg_Fields = {
  __typename?: 'mobile_mentions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentionable_id?: Maybe<Scalars['Float']['output']>;
  notifier_id?: Maybe<Scalars['Float']['output']>;
  recipient_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "mentions". All fields are combined with a logical 'AND'. */
export type Mobile_Mentions_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Mentions_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Mentions_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Mentions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mentionable_id?: InputMaybe<Bigint_Comparison_Exp>;
  mentionable_type?: InputMaybe<String_Comparison_Exp>;
  notifier_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifier_profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  recipient_id?: InputMaybe<Bigint_Comparison_Exp>;
  recipient_profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentions" */
export enum Mobile_Mentions_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentionsPkey = 'mentions_pkey'
}

/** input type for incrementing numeric columns in table "mentions" */
export type Mobile_Mentions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentionable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifier_id?: InputMaybe<Scalars['bigint']['input']>;
  recipient_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "mentions" */
export type Mobile_Mentions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentionable_id?: InputMaybe<Scalars['bigint']['input']>;
  mentionable_type?: InputMaybe<Scalars['String']['input']>;
  notifier_id?: InputMaybe<Scalars['bigint']['input']>;
  notifier_profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  recipient_id?: InputMaybe<Scalars['bigint']['input']>;
  recipient_profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Mentions_Max_Fields = {
  __typename?: 'mobile_mentions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentionable_id?: Maybe<Scalars['bigint']['output']>;
  mentionable_type?: Maybe<Scalars['String']['output']>;
  notifier_id?: Maybe<Scalars['bigint']['output']>;
  recipient_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Mentions_Min_Fields = {
  __typename?: 'mobile_mentions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentionable_id?: Maybe<Scalars['bigint']['output']>;
  mentionable_type?: Maybe<Scalars['String']['output']>;
  notifier_id?: Maybe<Scalars['bigint']['output']>;
  recipient_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "mentions" */
export type Mobile_Mentions_Mutation_Response = {
  __typename?: 'mobile_mentions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Mentions>;
};

/** input type for inserting object relation for remote table "mentions" */
export type Mobile_Mentions_Obj_Rel_Insert_Input = {
  data: Mobile_Mentions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Mentions_On_Conflict>;
};

/** on_conflict condition type for table "mentions" */
export type Mobile_Mentions_On_Conflict = {
  constraint: Mobile_Mentions_Constraint;
  update_columns?: Array<Mobile_Mentions_Update_Column>;
  where?: InputMaybe<Mobile_Mentions_Bool_Exp>;
};

/** Ordering options when selecting data from "mentions". */
export type Mobile_Mentions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentionable_id?: InputMaybe<Order_By>;
  mentionable_type?: InputMaybe<Order_By>;
  notifier_id?: InputMaybe<Order_By>;
  notifier_profile?: InputMaybe<Mobile_Profiles_Order_By>;
  recipient_id?: InputMaybe<Order_By>;
  recipient_profile?: InputMaybe<Mobile_Profiles_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentions */
export type Mobile_Mentions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "mentions" */
export enum Mobile_Mentions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentionableId = 'mentionable_id',
  /** column name */
  MentionableType = 'mentionable_type',
  /** column name */
  NotifierId = 'notifier_id',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "mentions" */
export type Mobile_Mentions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentionable_id?: InputMaybe<Scalars['bigint']['input']>;
  mentionable_type?: InputMaybe<Scalars['String']['input']>;
  notifier_id?: InputMaybe<Scalars['bigint']['input']>;
  recipient_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Mentions_Stddev_Fields = {
  __typename?: 'mobile_mentions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentionable_id?: Maybe<Scalars['Float']['output']>;
  notifier_id?: Maybe<Scalars['Float']['output']>;
  recipient_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Mentions_Stddev_Pop_Fields = {
  __typename?: 'mobile_mentions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentionable_id?: Maybe<Scalars['Float']['output']>;
  notifier_id?: Maybe<Scalars['Float']['output']>;
  recipient_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Mentions_Stddev_Samp_Fields = {
  __typename?: 'mobile_mentions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentionable_id?: Maybe<Scalars['Float']['output']>;
  notifier_id?: Maybe<Scalars['Float']['output']>;
  recipient_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_mentions" */
export type Mobile_Mentions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Mentions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Mentions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentionable_id?: InputMaybe<Scalars['bigint']['input']>;
  mentionable_type?: InputMaybe<Scalars['String']['input']>;
  notifier_id?: InputMaybe<Scalars['bigint']['input']>;
  recipient_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Mentions_Sum_Fields = {
  __typename?: 'mobile_mentions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  mentionable_id?: Maybe<Scalars['bigint']['output']>;
  notifier_id?: Maybe<Scalars['bigint']['output']>;
  recipient_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "mentions" */
export enum Mobile_Mentions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentionableId = 'mentionable_id',
  /** column name */
  MentionableType = 'mentionable_type',
  /** column name */
  NotifierId = 'notifier_id',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Mentions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Mentions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Mentions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Mentions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Mentions_Var_Pop_Fields = {
  __typename?: 'mobile_mentions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentionable_id?: Maybe<Scalars['Float']['output']>;
  notifier_id?: Maybe<Scalars['Float']['output']>;
  recipient_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Mentions_Var_Samp_Fields = {
  __typename?: 'mobile_mentions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentionable_id?: Maybe<Scalars['Float']['output']>;
  notifier_id?: Maybe<Scalars['Float']['output']>;
  recipient_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Mentions_Variance_Fields = {
  __typename?: 'mobile_mentions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentionable_id?: Maybe<Scalars['Float']['output']>;
  notifier_id?: Maybe<Scalars['Float']['output']>;
  recipient_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "metric_names" */
export type Mobile_Metric_Names = {
  __typename?: 'mobile_metric_names';
  created_at: Scalars['timestamp']['output'];
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  metric: Mobile_Metrics;
  metric_id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "metric_names" */
export type Mobile_Metric_Names_Aggregate = {
  __typename?: 'mobile_metric_names_aggregate';
  aggregate?: Maybe<Mobile_Metric_Names_Aggregate_Fields>;
  nodes: Array<Mobile_Metric_Names>;
};

export type Mobile_Metric_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Metric_Names_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Metric_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "metric_names" */
export type Mobile_Metric_Names_Aggregate_Fields = {
  __typename?: 'mobile_metric_names_aggregate_fields';
  avg?: Maybe<Mobile_Metric_Names_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Metric_Names_Max_Fields>;
  min?: Maybe<Mobile_Metric_Names_Min_Fields>;
  stddev?: Maybe<Mobile_Metric_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Metric_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Metric_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Metric_Names_Sum_Fields>;
  var_pop?: Maybe<Mobile_Metric_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Metric_Names_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Metric_Names_Variance_Fields>;
};


/** aggregate fields of "metric_names" */
export type Mobile_Metric_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "metric_names" */
export type Mobile_Metric_Names_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Metric_Names_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Metric_Names_Max_Order_By>;
  min?: InputMaybe<Mobile_Metric_Names_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Metric_Names_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Metric_Names_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Metric_Names_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Metric_Names_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Metric_Names_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Metric_Names_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Metric_Names_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "metric_names" */
export type Mobile_Metric_Names_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Metric_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Metric_Names_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Metric_Names_Avg_Fields = {
  __typename?: 'mobile_metric_names_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "metric_names" */
export type Mobile_Metric_Names_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "metric_names". All fields are combined with a logical 'AND'. */
export type Mobile_Metric_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Metric_Names_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Metric_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  metric?: InputMaybe<Mobile_Metrics_Bool_Exp>;
  metric_id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  short_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "metric_names" */
export enum Mobile_Metric_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  MetricNamesPkey = 'metric_names_pkey'
}

/** input type for incrementing numeric columns in table "metric_names" */
export type Mobile_Metric_Names_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "metric_names" */
export type Mobile_Metric_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<Mobile_Metrics_Obj_Rel_Insert_Input>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Metric_Names_Max_Fields = {
  __typename?: 'mobile_metric_names_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "metric_names" */
export type Mobile_Metric_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Metric_Names_Min_Fields = {
  __typename?: 'mobile_metric_names_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "metric_names" */
export type Mobile_Metric_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "metric_names" */
export type Mobile_Metric_Names_Mutation_Response = {
  __typename?: 'mobile_metric_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Metric_Names>;
};

/** on_conflict condition type for table "metric_names" */
export type Mobile_Metric_Names_On_Conflict = {
  constraint: Mobile_Metric_Names_Constraint;
  update_columns?: Array<Mobile_Metric_Names_Update_Column>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "metric_names". */
export type Mobile_Metric_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  metric?: InputMaybe<Mobile_Metrics_Order_By>;
  metric_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metric_names */
export type Mobile_Metric_Names_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "metric_names" */
export enum Mobile_Metric_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "metric_names" */
export type Mobile_Metric_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Metric_Names_Stddev_Fields = {
  __typename?: 'mobile_metric_names_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "metric_names" */
export type Mobile_Metric_Names_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Metric_Names_Stddev_Pop_Fields = {
  __typename?: 'mobile_metric_names_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "metric_names" */
export type Mobile_Metric_Names_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Metric_Names_Stddev_Samp_Fields = {
  __typename?: 'mobile_metric_names_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "metric_names" */
export type Mobile_Metric_Names_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_metric_names" */
export type Mobile_Metric_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Metric_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Metric_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Metric_Names_Sum_Fields = {
  __typename?: 'mobile_metric_names_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "metric_names" */
export type Mobile_Metric_Names_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** update columns of table "metric_names" */
export enum Mobile_Metric_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  Name = 'name',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Metric_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Metric_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Metric_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Metric_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Metric_Names_Var_Pop_Fields = {
  __typename?: 'mobile_metric_names_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "metric_names" */
export type Mobile_Metric_Names_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Metric_Names_Var_Samp_Fields = {
  __typename?: 'mobile_metric_names_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "metric_names" */
export type Mobile_Metric_Names_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Metric_Names_Variance_Fields = {
  __typename?: 'mobile_metric_names_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "metric_names" */
export type Mobile_Metric_Names_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "metric_params" */
export type Mobile_Metric_Params = {
  __typename?: 'mobile_metric_params';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  metric?: Maybe<Mobile_Metrics>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  param?: Maybe<Mobile_Params>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "metric_params" */
export type Mobile_Metric_Params_Aggregate = {
  __typename?: 'mobile_metric_params_aggregate';
  aggregate?: Maybe<Mobile_Metric_Params_Aggregate_Fields>;
  nodes: Array<Mobile_Metric_Params>;
};

export type Mobile_Metric_Params_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Metric_Params_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Metric_Params_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "metric_params" */
export type Mobile_Metric_Params_Aggregate_Fields = {
  __typename?: 'mobile_metric_params_aggregate_fields';
  avg?: Maybe<Mobile_Metric_Params_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Metric_Params_Max_Fields>;
  min?: Maybe<Mobile_Metric_Params_Min_Fields>;
  stddev?: Maybe<Mobile_Metric_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Metric_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Metric_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Metric_Params_Sum_Fields>;
  var_pop?: Maybe<Mobile_Metric_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Metric_Params_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Metric_Params_Variance_Fields>;
};


/** aggregate fields of "metric_params" */
export type Mobile_Metric_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "metric_params" */
export type Mobile_Metric_Params_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Metric_Params_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Metric_Params_Max_Order_By>;
  min?: InputMaybe<Mobile_Metric_Params_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Metric_Params_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Metric_Params_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Metric_Params_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Metric_Params_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Metric_Params_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Metric_Params_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Metric_Params_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "metric_params" */
export type Mobile_Metric_Params_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Metric_Params_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Metric_Params_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Metric_Params_Avg_Fields = {
  __typename?: 'mobile_metric_params_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "metric_params" */
export type Mobile_Metric_Params_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "metric_params". All fields are combined with a logical 'AND'. */
export type Mobile_Metric_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Metric_Params_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Metric_Params_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metric?: InputMaybe<Mobile_Metrics_Bool_Exp>;
  metric_id?: InputMaybe<Bigint_Comparison_Exp>;
  param?: InputMaybe<Mobile_Params_Bool_Exp>;
  param_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "metric_params" */
export enum Mobile_Metric_Params_Constraint {
  /** unique or primary key constraint on columns "id" */
  MetricParamsPkey = 'metric_params_pkey'
}

/** input type for incrementing numeric columns in table "metric_params" */
export type Mobile_Metric_Params_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "metric_params" */
export type Mobile_Metric_Params_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric?: InputMaybe<Mobile_Metrics_Obj_Rel_Insert_Input>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param?: InputMaybe<Mobile_Params_Obj_Rel_Insert_Input>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Metric_Params_Max_Fields = {
  __typename?: 'mobile_metric_params_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "metric_params" */
export type Mobile_Metric_Params_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Metric_Params_Min_Fields = {
  __typename?: 'mobile_metric_params_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "metric_params" */
export type Mobile_Metric_Params_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "metric_params" */
export type Mobile_Metric_Params_Mutation_Response = {
  __typename?: 'mobile_metric_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Metric_Params>;
};

/** on_conflict condition type for table "metric_params" */
export type Mobile_Metric_Params_On_Conflict = {
  constraint: Mobile_Metric_Params_Constraint;
  update_columns?: Array<Mobile_Metric_Params_Update_Column>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "metric_params". */
export type Mobile_Metric_Params_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric?: InputMaybe<Mobile_Metrics_Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param?: InputMaybe<Mobile_Params_Order_By>;
  param_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metric_params */
export type Mobile_Metric_Params_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "metric_params" */
export enum Mobile_Metric_Params_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "metric_params" */
export type Mobile_Metric_Params_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Metric_Params_Stddev_Fields = {
  __typename?: 'mobile_metric_params_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "metric_params" */
export type Mobile_Metric_Params_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Metric_Params_Stddev_Pop_Fields = {
  __typename?: 'mobile_metric_params_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "metric_params" */
export type Mobile_Metric_Params_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Metric_Params_Stddev_Samp_Fields = {
  __typename?: 'mobile_metric_params_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "metric_params" */
export type Mobile_Metric_Params_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_metric_params" */
export type Mobile_Metric_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Metric_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Metric_Params_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Metric_Params_Sum_Fields = {
  __typename?: 'mobile_metric_params_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "metric_params" */
export type Mobile_Metric_Params_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** update columns of table "metric_params" */
export enum Mobile_Metric_Params_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Metric_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Metric_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Metric_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Metric_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Metric_Params_Var_Pop_Fields = {
  __typename?: 'mobile_metric_params_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "metric_params" */
export type Mobile_Metric_Params_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Metric_Params_Var_Samp_Fields = {
  __typename?: 'mobile_metric_params_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "metric_params" */
export type Mobile_Metric_Params_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Metric_Params_Variance_Fields = {
  __typename?: 'mobile_metric_params_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "metric_params" */
export type Mobile_Metric_Params_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "metric_types" */
export type Mobile_Metric_Types = {
  __typename?: 'mobile_metric_types';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  metrics: Array<Mobile_Metrics>;
  /** An aggregate relationship */
  metrics_aggregate: Mobile_Metrics_Aggregate;
  name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "metric_types" */
export type Mobile_Metric_TypesMetricsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metrics_Order_By>>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};


/** columns and relationships of "metric_types" */
export type Mobile_Metric_TypesMetrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metrics_Order_By>>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};

/** aggregated selection of "metric_types" */
export type Mobile_Metric_Types_Aggregate = {
  __typename?: 'mobile_metric_types_aggregate';
  aggregate?: Maybe<Mobile_Metric_Types_Aggregate_Fields>;
  nodes: Array<Mobile_Metric_Types>;
};

/** aggregate fields of "metric_types" */
export type Mobile_Metric_Types_Aggregate_Fields = {
  __typename?: 'mobile_metric_types_aggregate_fields';
  avg?: Maybe<Mobile_Metric_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Metric_Types_Max_Fields>;
  min?: Maybe<Mobile_Metric_Types_Min_Fields>;
  stddev?: Maybe<Mobile_Metric_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Metric_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Metric_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Metric_Types_Sum_Fields>;
  var_pop?: Maybe<Mobile_Metric_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Metric_Types_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Metric_Types_Variance_Fields>;
};


/** aggregate fields of "metric_types" */
export type Mobile_Metric_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Metric_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Metric_Types_Avg_Fields = {
  __typename?: 'mobile_metric_types_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "metric_types". All fields are combined with a logical 'AND'. */
export type Mobile_Metric_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Metric_Types_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Metric_Types_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metrics?: InputMaybe<Mobile_Metrics_Bool_Exp>;
  metrics_aggregate?: InputMaybe<Mobile_Metrics_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "metric_types" */
export enum Mobile_Metric_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  MetricTypesPkey = 'metric_types_pkey'
}

/** input type for incrementing numeric columns in table "metric_types" */
export type Mobile_Metric_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "metric_types" */
export type Mobile_Metric_Types_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metrics?: InputMaybe<Mobile_Metrics_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Metric_Types_Max_Fields = {
  __typename?: 'mobile_metric_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Metric_Types_Min_Fields = {
  __typename?: 'mobile_metric_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "metric_types" */
export type Mobile_Metric_Types_Mutation_Response = {
  __typename?: 'mobile_metric_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Metric_Types>;
};

/** input type for inserting object relation for remote table "metric_types" */
export type Mobile_Metric_Types_Obj_Rel_Insert_Input = {
  data: Mobile_Metric_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Metric_Types_On_Conflict>;
};

/** on_conflict condition type for table "metric_types" */
export type Mobile_Metric_Types_On_Conflict = {
  constraint: Mobile_Metric_Types_Constraint;
  update_columns?: Array<Mobile_Metric_Types_Update_Column>;
  where?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "metric_types". */
export type Mobile_Metric_Types_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metrics_aggregate?: InputMaybe<Mobile_Metrics_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metric_types */
export type Mobile_Metric_Types_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "metric_types" */
export enum Mobile_Metric_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "metric_types" */
export type Mobile_Metric_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Metric_Types_Stddev_Fields = {
  __typename?: 'mobile_metric_types_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Metric_Types_Stddev_Pop_Fields = {
  __typename?: 'mobile_metric_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Metric_Types_Stddev_Samp_Fields = {
  __typename?: 'mobile_metric_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_metric_types" */
export type Mobile_Metric_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Metric_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Metric_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Metric_Types_Sum_Fields = {
  __typename?: 'mobile_metric_types_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "metric_types" */
export enum Mobile_Metric_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Metric_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Metric_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Metric_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Metric_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Metric_Types_Var_Pop_Fields = {
  __typename?: 'mobile_metric_types_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Metric_Types_Var_Samp_Fields = {
  __typename?: 'mobile_metric_types_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Metric_Types_Variance_Fields = {
  __typename?: 'mobile_metric_types_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "metrics" */
export type Mobile_Metrics = {
  __typename?: 'mobile_metrics';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  max?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  metric_names: Array<Mobile_Metric_Names>;
  /** An aggregate relationship */
  metric_names_aggregate: Mobile_Metric_Names_Aggregate;
  /** An array relationship */
  metric_params: Array<Mobile_Metric_Params>;
  /** An aggregate relationship */
  metric_params_aggregate: Mobile_Metric_Params_Aggregate;
  /** An object relationship */
  metric_type?: Maybe<Mobile_Metric_Types>;
  metric_type_id?: Maybe<Scalars['bigint']['output']>;
  min?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  params_services: Array<Mobile_Params_Services>;
  /** An aggregate relationship */
  params_services_aggregate: Mobile_Params_Services_Aggregate;
  /** An array relationship */
  post_params: Array<Mobile_Post_Params>;
  /** An aggregate relationship */
  post_params_aggregate: Mobile_Post_Params_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsMetric_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Names_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsMetric_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Names_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsMetric_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsMetric_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsParams_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsParams_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsPost_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


/** columns and relationships of "metrics" */
export type Mobile_MetricsPost_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};

/** aggregated selection of "metrics" */
export type Mobile_Metrics_Aggregate = {
  __typename?: 'mobile_metrics_aggregate';
  aggregate?: Maybe<Mobile_Metrics_Aggregate_Fields>;
  nodes: Array<Mobile_Metrics>;
};

export type Mobile_Metrics_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Metrics_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Metrics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Metrics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "metrics" */
export type Mobile_Metrics_Aggregate_Fields = {
  __typename?: 'mobile_metrics_aggregate_fields';
  avg?: Maybe<Mobile_Metrics_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Metrics_Max_Fields>;
  min?: Maybe<Mobile_Metrics_Min_Fields>;
  stddev?: Maybe<Mobile_Metrics_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Metrics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Metrics_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Metrics_Sum_Fields>;
  var_pop?: Maybe<Mobile_Metrics_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Metrics_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Metrics_Variance_Fields>;
};


/** aggregate fields of "metrics" */
export type Mobile_Metrics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "metrics" */
export type Mobile_Metrics_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Metrics_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Metrics_Max_Order_By>;
  min?: InputMaybe<Mobile_Metrics_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Metrics_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Metrics_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Metrics_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Metrics_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Metrics_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Metrics_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Metrics_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "metrics" */
export type Mobile_Metrics_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Metrics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Metrics_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Metrics_Avg_Fields = {
  __typename?: 'mobile_metrics_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  metric_type_id?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "metrics" */
export type Mobile_Metrics_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "metrics". All fields are combined with a logical 'AND'. */
export type Mobile_Metrics_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Metrics_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Metrics_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Metrics_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  max?: InputMaybe<Bigint_Comparison_Exp>;
  metric_names?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
  metric_names_aggregate?: InputMaybe<Mobile_Metric_Names_Aggregate_Bool_Exp>;
  metric_params?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
  metric_params_aggregate?: InputMaybe<Mobile_Metric_Params_Aggregate_Bool_Exp>;
  metric_type?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
  metric_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  min?: InputMaybe<Bigint_Comparison_Exp>;
  params_services?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Bool_Exp>;
  post_params?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "metrics" */
export enum Mobile_Metrics_Constraint {
  /** unique or primary key constraint on columns "id" */
  MetricsPkey = 'metrics_pkey'
}

/** input type for incrementing numeric columns in table "metrics" */
export type Mobile_Metrics_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  max?: InputMaybe<Scalars['bigint']['input']>;
  metric_type_id?: InputMaybe<Scalars['bigint']['input']>;
  min?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "metrics" */
export type Mobile_Metrics_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  max?: InputMaybe<Scalars['bigint']['input']>;
  metric_names?: InputMaybe<Mobile_Metric_Names_Arr_Rel_Insert_Input>;
  metric_params?: InputMaybe<Mobile_Metric_Params_Arr_Rel_Insert_Input>;
  metric_type?: InputMaybe<Mobile_Metric_Types_Obj_Rel_Insert_Input>;
  metric_type_id?: InputMaybe<Scalars['bigint']['input']>;
  min?: InputMaybe<Scalars['bigint']['input']>;
  params_services?: InputMaybe<Mobile_Params_Services_Arr_Rel_Insert_Input>;
  post_params?: InputMaybe<Mobile_Post_Params_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Metrics_Max_Fields = {
  __typename?: 'mobile_metrics_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  max?: Maybe<Scalars['bigint']['output']>;
  metric_type_id?: Maybe<Scalars['bigint']['output']>;
  min?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "metrics" */
export type Mobile_Metrics_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Metrics_Min_Fields = {
  __typename?: 'mobile_metrics_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  max?: Maybe<Scalars['bigint']['output']>;
  metric_type_id?: Maybe<Scalars['bigint']['output']>;
  min?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "metrics" */
export type Mobile_Metrics_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "metrics" */
export type Mobile_Metrics_Mutation_Response = {
  __typename?: 'mobile_metrics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Metrics>;
};

/** input type for inserting object relation for remote table "metrics" */
export type Mobile_Metrics_Obj_Rel_Insert_Input = {
  data: Mobile_Metrics_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Metrics_On_Conflict>;
};

/** on_conflict condition type for table "metrics" */
export type Mobile_Metrics_On_Conflict = {
  constraint: Mobile_Metrics_Constraint;
  update_columns?: Array<Mobile_Metrics_Update_Column>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};

/** Ordering options when selecting data from "metrics". */
export type Mobile_Metrics_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_names_aggregate?: InputMaybe<Mobile_Metric_Names_Aggregate_Order_By>;
  metric_params_aggregate?: InputMaybe<Mobile_Metric_Params_Aggregate_Order_By>;
  metric_type?: InputMaybe<Mobile_Metric_Types_Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Order_By>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metrics */
export type Mobile_Metrics_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "metrics" */
export enum Mobile_Metrics_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  MetricTypeId = 'metric_type_id',
  /** column name */
  Min = 'min',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "metrics" */
export type Mobile_Metrics_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  max?: InputMaybe<Scalars['bigint']['input']>;
  metric_type_id?: InputMaybe<Scalars['bigint']['input']>;
  min?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Metrics_Stddev_Fields = {
  __typename?: 'mobile_metrics_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  metric_type_id?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "metrics" */
export type Mobile_Metrics_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Metrics_Stddev_Pop_Fields = {
  __typename?: 'mobile_metrics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  metric_type_id?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "metrics" */
export type Mobile_Metrics_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Metrics_Stddev_Samp_Fields = {
  __typename?: 'mobile_metrics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  metric_type_id?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "metrics" */
export type Mobile_Metrics_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_metrics" */
export type Mobile_Metrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Metrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Metrics_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  max?: InputMaybe<Scalars['bigint']['input']>;
  metric_type_id?: InputMaybe<Scalars['bigint']['input']>;
  min?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Metrics_Sum_Fields = {
  __typename?: 'mobile_metrics_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  max?: Maybe<Scalars['bigint']['output']>;
  metric_type_id?: Maybe<Scalars['bigint']['output']>;
  min?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "metrics" */
export type Mobile_Metrics_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** update columns of table "metrics" */
export enum Mobile_Metrics_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Max = 'max',
  /** column name */
  MetricTypeId = 'metric_type_id',
  /** column name */
  Min = 'min',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Metrics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Metrics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Metrics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Metrics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Metrics_Var_Pop_Fields = {
  __typename?: 'mobile_metrics_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  metric_type_id?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "metrics" */
export type Mobile_Metrics_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Metrics_Var_Samp_Fields = {
  __typename?: 'mobile_metrics_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  metric_type_id?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "metrics" */
export type Mobile_Metrics_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Metrics_Variance_Fields = {
  __typename?: 'mobile_metrics_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  metric_type_id?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "metrics" */
export type Mobile_Metrics_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
  metric_type_id?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** columns and relationships of "notification_messages" */
export type Mobile_Notification_Messages = {
  __typename?: 'mobile_notification_messages';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "notification_messages" */
export type Mobile_Notification_Messages_Aggregate = {
  __typename?: 'mobile_notification_messages_aggregate';
  aggregate?: Maybe<Mobile_Notification_Messages_Aggregate_Fields>;
  nodes: Array<Mobile_Notification_Messages>;
};

/** aggregate fields of "notification_messages" */
export type Mobile_Notification_Messages_Aggregate_Fields = {
  __typename?: 'mobile_notification_messages_aggregate_fields';
  avg?: Maybe<Mobile_Notification_Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Notification_Messages_Max_Fields>;
  min?: Maybe<Mobile_Notification_Messages_Min_Fields>;
  stddev?: Maybe<Mobile_Notification_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Notification_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Notification_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Notification_Messages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Notification_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Notification_Messages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Notification_Messages_Variance_Fields>;
};


/** aggregate fields of "notification_messages" */
export type Mobile_Notification_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Notification_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Notification_Messages_Avg_Fields = {
  __typename?: 'mobile_notification_messages_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "notification_messages". All fields are combined with a logical 'AND'. */
export type Mobile_Notification_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Notification_Messages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Notification_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Notification_Messages_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  notification_type?: InputMaybe<String_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_messages" */
export enum Mobile_Notification_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationMessagesPkey = 'notification_messages_pkey'
}

/** input type for incrementing numeric columns in table "notification_messages" */
export type Mobile_Notification_Messages_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "notification_messages" */
export type Mobile_Notification_Messages_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Notification_Messages_Max_Fields = {
  __typename?: 'mobile_notification_messages_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Notification_Messages_Min_Fields = {
  __typename?: 'mobile_notification_messages_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "notification_messages" */
export type Mobile_Notification_Messages_Mutation_Response = {
  __typename?: 'mobile_notification_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Notification_Messages>;
};

/** on_conflict condition type for table "notification_messages" */
export type Mobile_Notification_Messages_On_Conflict = {
  constraint: Mobile_Notification_Messages_Constraint;
  update_columns?: Array<Mobile_Notification_Messages_Update_Column>;
  where?: InputMaybe<Mobile_Notification_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_messages". */
export type Mobile_Notification_Messages_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  notification_type?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_messages */
export type Mobile_Notification_Messages_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "notification_messages" */
export enum Mobile_Notification_Messages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "notification_messages" */
export type Mobile_Notification_Messages_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Notification_Messages_Stddev_Fields = {
  __typename?: 'mobile_notification_messages_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Notification_Messages_Stddev_Pop_Fields = {
  __typename?: 'mobile_notification_messages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Notification_Messages_Stddev_Samp_Fields = {
  __typename?: 'mobile_notification_messages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_notification_messages" */
export type Mobile_Notification_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Notification_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Notification_Messages_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Notification_Messages_Sum_Fields = {
  __typename?: 'mobile_notification_messages_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "notification_messages" */
export enum Mobile_Notification_Messages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Notification_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Notification_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Notification_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Notification_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Notification_Messages_Var_Pop_Fields = {
  __typename?: 'mobile_notification_messages_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Notification_Messages_Var_Samp_Fields = {
  __typename?: 'mobile_notification_messages_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Notification_Messages_Variance_Fields = {
  __typename?: 'mobile_notification_messages_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "notifications" */
export type Mobile_Notifications = {
  __typename?: 'mobile_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  actor_profile?: Maybe<Mobile_Profiles>;
  /** An object relationship */
  chat_message_notifications?: Maybe<Mobile_Chat_Message_Notifications>;
  /** An object relationship */
  comment_like_notifications?: Maybe<Mobile_Comment_Like_Notifications>;
  /** An object relationship */
  comment_notifications?: Maybe<Mobile_Comment_Notifications>;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  like_notifications?: Maybe<Mobile_Like_Notifications>;
  /** An object relationship */
  mention_notifications?: Maybe<Mobile_Mention_Notifications>;
  notifiable_id: Scalars['bigint']['output'];
  notifiable_type: Scalars['String']['output'];
  on_click_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  order_notifications?: Maybe<Mobile_Order_Notifications>;
  /** An object relationship */
  post?: Maybe<Mobile_Posts>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile: Mobile_Profiles;
  profile_id: Scalars['bigint']['output'];
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Mobile_Notifications_Statuses_Enum>;
  /** An object relationship */
  subscribe_notifications?: Maybe<Mobile_Subscribe_Notifications>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "notifications" */
export type Mobile_Notifications_Aggregate = {
  __typename?: 'mobile_notifications_aggregate';
  aggregate?: Maybe<Mobile_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Notifications>;
};

/** aggregate fields of "notifications" */
export type Mobile_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Notifications_Variance_Fields>;
};


/** aggregate fields of "notifications" */
export type Mobile_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Notifications_Avg_Fields = {
  __typename?: 'mobile_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  actor_profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  chat_message_notifications?: InputMaybe<Mobile_Chat_Message_Notifications_Bool_Exp>;
  comment_like_notifications?: InputMaybe<Mobile_Comment_Like_Notifications_Bool_Exp>;
  comment_notifications?: InputMaybe<Mobile_Comment_Notifications_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  like_notifications?: InputMaybe<Mobile_Like_Notifications_Bool_Exp>;
  mention_notifications?: InputMaybe<Mobile_Mention_Notifications_Bool_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  order_notifications?: InputMaybe<Mobile_Order_Notifications_Bool_Exp>;
  post?: InputMaybe<Mobile_Posts_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Mobile_Notifications_Statuses_Enum_Comparison_Exp>;
  subscribe_notifications?: InputMaybe<Mobile_Subscribe_Notifications_Bool_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Mobile_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** input type for incrementing numeric columns in table "notifications" */
export type Mobile_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "notifications" */
export type Mobile_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  actor_profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  chat_message_notifications?: InputMaybe<Mobile_Chat_Message_Notifications_Obj_Rel_Insert_Input>;
  comment_like_notifications?: InputMaybe<Mobile_Comment_Like_Notifications_Obj_Rel_Insert_Input>;
  comment_notifications?: InputMaybe<Mobile_Comment_Notifications_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  like_notifications?: InputMaybe<Mobile_Like_Notifications_Obj_Rel_Insert_Input>;
  mention_notifications?: InputMaybe<Mobile_Mention_Notifications_Obj_Rel_Insert_Input>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  order_notifications?: InputMaybe<Mobile_Order_Notifications_Obj_Rel_Insert_Input>;
  post?: InputMaybe<Mobile_Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Mobile_Notifications_Statuses_Enum>;
  subscribe_notifications?: InputMaybe<Mobile_Subscribe_Notifications_Obj_Rel_Insert_Input>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Notifications_Max_Fields = {
  __typename?: 'mobile_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Notifications_Min_Fields = {
  __typename?: 'mobile_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "notifications" */
export type Mobile_Notifications_Mutation_Response = {
  __typename?: 'mobile_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Notifications>;
};

/** input type for inserting object relation for remote table "notifications" */
export type Mobile_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Notifications_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Notifications_On_Conflict>;
};

/** on_conflict condition type for table "notifications" */
export type Mobile_Notifications_On_Conflict = {
  constraint: Mobile_Notifications_Constraint;
  update_columns?: Array<Mobile_Notifications_Update_Column>;
  where?: InputMaybe<Mobile_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Mobile_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  actor_profile?: InputMaybe<Mobile_Profiles_Order_By>;
  chat_message_notifications?: InputMaybe<Mobile_Chat_Message_Notifications_Order_By>;
  comment_like_notifications?: InputMaybe<Mobile_Comment_Like_Notifications_Order_By>;
  comment_notifications?: InputMaybe<Mobile_Comment_Notifications_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  like_notifications?: InputMaybe<Mobile_Like_Notifications_Order_By>;
  mention_notifications?: InputMaybe<Mobile_Mention_Notifications_Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  order_notifications?: InputMaybe<Mobile_Order_Notifications_Order_By>;
  post?: InputMaybe<Mobile_Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscribe_notifications?: InputMaybe<Mobile_Subscribe_Notifications_Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Mobile_Notifications_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "notifications" */
export enum Mobile_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "notifications" */
export type Mobile_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Mobile_Notifications_Statuses_Enum>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "notifications_statuses" */
export type Mobile_Notifications_Statuses = {
  __typename?: 'mobile_notifications_statuses';
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** aggregated selection of "notifications_statuses" */
export type Mobile_Notifications_Statuses_Aggregate = {
  __typename?: 'mobile_notifications_statuses_aggregate';
  aggregate?: Maybe<Mobile_Notifications_Statuses_Aggregate_Fields>;
  nodes: Array<Mobile_Notifications_Statuses>;
};

/** aggregate fields of "notifications_statuses" */
export type Mobile_Notifications_Statuses_Aggregate_Fields = {
  __typename?: 'mobile_notifications_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Notifications_Statuses_Max_Fields>;
  min?: Maybe<Mobile_Notifications_Statuses_Min_Fields>;
};


/** aggregate fields of "notifications_statuses" */
export type Mobile_Notifications_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Notifications_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notifications_statuses". All fields are combined with a logical 'AND'. */
export type Mobile_Notifications_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Notifications_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Notifications_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Notifications_Statuses_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications_statuses" */
export enum Mobile_Notifications_Statuses_Constraint {
  /** unique or primary key constraint on columns "title" */
  NotificationsStatusesPkey = 'notifications_statuses_pkey'
}

export enum Mobile_Notifications_Statuses_Enum {
  /** уведомление активно */
  Active = 'active',
  /** уведомление скрыто */
  Hidden = 'hidden'
}

/** Boolean expression to compare columns of type "mobile_notifications_statuses_enum". All fields are combined with logical 'AND'. */
export type Mobile_Notifications_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Mobile_Notifications_Statuses_Enum>;
  _in?: InputMaybe<Array<Mobile_Notifications_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Mobile_Notifications_Statuses_Enum>;
  _nin?: InputMaybe<Array<Mobile_Notifications_Statuses_Enum>>;
};

/** input type for inserting data into table "notifications_statuses" */
export type Mobile_Notifications_Statuses_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Notifications_Statuses_Max_Fields = {
  __typename?: 'mobile_notifications_statuses_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Notifications_Statuses_Min_Fields = {
  __typename?: 'mobile_notifications_statuses_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notifications_statuses" */
export type Mobile_Notifications_Statuses_Mutation_Response = {
  __typename?: 'mobile_notifications_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Notifications_Statuses>;
};

/** on_conflict condition type for table "notifications_statuses" */
export type Mobile_Notifications_Statuses_On_Conflict = {
  constraint: Mobile_Notifications_Statuses_Constraint;
  update_columns?: Array<Mobile_Notifications_Statuses_Update_Column>;
  where?: InputMaybe<Mobile_Notifications_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications_statuses". */
export type Mobile_Notifications_Statuses_Order_By = {
  description?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications_statuses */
export type Mobile_Notifications_Statuses_Pk_Columns_Input = {
  title: Scalars['String']['input'];
};

/** select columns of table "notifications_statuses" */
export enum Mobile_Notifications_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "notifications_statuses" */
export type Mobile_Notifications_Statuses_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "mobile_notifications_statuses" */
export type Mobile_Notifications_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Notifications_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Notifications_Statuses_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notifications_statuses" */
export enum Mobile_Notifications_Statuses_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Title = 'title'
}

export type Mobile_Notifications_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Notifications_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Notifications_Statuses_Bool_Exp;
};

/** aggregate stddev on columns */
export type Mobile_Notifications_Stddev_Fields = {
  __typename?: 'mobile_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_notifications" */
export type Mobile_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Mobile_Notifications_Statuses_Enum>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Notifications_Sum_Fields = {
  __typename?: 'mobile_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "notifications" */
export enum Mobile_Notifications_Update_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Notifications_Variance_Fields = {
  __typename?: 'mobile_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "order_notifications" */
export type Mobile_Order_Notifications = {
  __typename?: 'mobile_order_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notifications?: Maybe<Mobile_Notifications>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  orders?: Maybe<Mobile_Orders>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "order_notifications" */
export type Mobile_Order_Notifications_Aggregate = {
  __typename?: 'mobile_order_notifications_aggregate';
  aggregate?: Maybe<Mobile_Order_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Order_Notifications>;
};

/** aggregate fields of "order_notifications" */
export type Mobile_Order_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_order_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Order_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Order_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Order_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Order_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Order_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Order_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Order_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Order_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Order_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Order_Notifications_Variance_Fields>;
};


/** aggregate fields of "order_notifications" */
export type Mobile_Order_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Order_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Order_Notifications_Avg_Fields = {
  __typename?: 'mobile_order_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "order_notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Order_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Order_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Order_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Order_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  orders?: InputMaybe<Mobile_Orders_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "order_notifications" */
export type Mobile_Order_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "order_notifications" */
export type Mobile_Order_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Mobile_Notifications_Obj_Rel_Insert_Input>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  orders?: InputMaybe<Mobile_Orders_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Order_Notifications_Max_Fields = {
  __typename?: 'mobile_order_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Order_Notifications_Min_Fields = {
  __typename?: 'mobile_order_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "order_notifications" */
export type Mobile_Order_Notifications_Mutation_Response = {
  __typename?: 'mobile_order_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Order_Notifications>;
};

/** input type for inserting object relation for remote table "order_notifications" */
export type Mobile_Order_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Order_Notifications_Insert_Input;
};

/** Ordering options when selecting data from "order_notifications". */
export type Mobile_Order_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Mobile_Notifications_Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  orders?: InputMaybe<Mobile_Orders_Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "order_notifications" */
export enum Mobile_Order_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_notifications" */
export type Mobile_Order_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Order_Notifications_Stddev_Fields = {
  __typename?: 'mobile_order_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Order_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_order_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Order_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_order_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_order_notifications" */
export type Mobile_Order_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Order_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Order_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Order_Notifications_Sum_Fields = {
  __typename?: 'mobile_order_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Order_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Order_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Order_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Order_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Order_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_order_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Order_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_order_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Order_Notifications_Variance_Fields = {
  __typename?: 'mobile_order_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "order_professionals" */
export type Mobile_Order_Professionals = {
  __typename?: 'mobile_order_professionals';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  pro?: Maybe<Mobile_Pros>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "order_professionals" */
export type Mobile_Order_Professionals_Aggregate = {
  __typename?: 'mobile_order_professionals_aggregate';
  aggregate?: Maybe<Mobile_Order_Professionals_Aggregate_Fields>;
  nodes: Array<Mobile_Order_Professionals>;
};

export type Mobile_Order_Professionals_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Order_Professionals_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Order_Professionals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "order_professionals" */
export type Mobile_Order_Professionals_Aggregate_Fields = {
  __typename?: 'mobile_order_professionals_aggregate_fields';
  avg?: Maybe<Mobile_Order_Professionals_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Order_Professionals_Max_Fields>;
  min?: Maybe<Mobile_Order_Professionals_Min_Fields>;
  stddev?: Maybe<Mobile_Order_Professionals_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Order_Professionals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Order_Professionals_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Order_Professionals_Sum_Fields>;
  var_pop?: Maybe<Mobile_Order_Professionals_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Order_Professionals_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Order_Professionals_Variance_Fields>;
};


/** aggregate fields of "order_professionals" */
export type Mobile_Order_Professionals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "order_professionals" */
export type Mobile_Order_Professionals_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Order_Professionals_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Order_Professionals_Max_Order_By>;
  min?: InputMaybe<Mobile_Order_Professionals_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Order_Professionals_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Order_Professionals_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Order_Professionals_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Order_Professionals_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Order_Professionals_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Order_Professionals_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Order_Professionals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_professionals" */
export type Mobile_Order_Professionals_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Order_Professionals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Order_Professionals_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Order_Professionals_Avg_Fields = {
  __typename?: 'mobile_order_professionals_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_professionals". All fields are combined with a logical 'AND'. */
export type Mobile_Order_Professionals_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Order_Professionals_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Order_Professionals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pro?: InputMaybe<Mobile_Pros_Bool_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_professionals" */
export enum Mobile_Order_Professionals_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrderProfessionalsPkey = 'order_professionals_pkey'
}

/** input type for incrementing numeric columns in table "order_professionals" */
export type Mobile_Order_Professionals_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "order_professionals" */
export type Mobile_Order_Professionals_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro?: InputMaybe<Mobile_Pros_Obj_Rel_Insert_Input>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Order_Professionals_Max_Fields = {
  __typename?: 'mobile_order_professionals_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Order_Professionals_Min_Fields = {
  __typename?: 'mobile_order_professionals_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "order_professionals" */
export type Mobile_Order_Professionals_Mutation_Response = {
  __typename?: 'mobile_order_professionals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Order_Professionals>;
};

/** on_conflict condition type for table "order_professionals" */
export type Mobile_Order_Professionals_On_Conflict = {
  constraint: Mobile_Order_Professionals_Constraint;
  update_columns?: Array<Mobile_Order_Professionals_Update_Column>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};

/** Ordering options when selecting data from "order_professionals". */
export type Mobile_Order_Professionals_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro?: InputMaybe<Mobile_Pros_Order_By>;
  pro_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_professionals */
export type Mobile_Order_Professionals_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "order_professionals" */
export enum Mobile_Order_Professionals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "order_professionals" */
export type Mobile_Order_Professionals_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Order_Professionals_Stddev_Fields = {
  __typename?: 'mobile_order_professionals_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Order_Professionals_Stddev_Pop_Fields = {
  __typename?: 'mobile_order_professionals_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Order_Professionals_Stddev_Samp_Fields = {
  __typename?: 'mobile_order_professionals_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_order_professionals" */
export type Mobile_Order_Professionals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Order_Professionals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Order_Professionals_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Order_Professionals_Sum_Fields = {
  __typename?: 'mobile_order_professionals_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "order_professionals" */
export enum Mobile_Order_Professionals_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Order_Professionals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Order_Professionals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Order_Professionals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Order_Professionals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Order_Professionals_Var_Pop_Fields = {
  __typename?: 'mobile_order_professionals_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Order_Professionals_Var_Samp_Fields = {
  __typename?: 'mobile_order_professionals_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Order_Professionals_Variance_Fields = {
  __typename?: 'mobile_order_professionals_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "order_professionals" */
export type Mobile_Order_Professionals_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "orders" */
export type Mobile_Orders = {
  __typename?: 'mobile_orders';
  amount: Scalars['Int']['output'];
  /** An object relationship */
  client?: Maybe<Mobile_Clients>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  currency?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['bigint']['output'];
  paid_at?: Maybe<Scalars['timestamp']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  to_client?: Maybe<Mobile_Clients>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
  to_user_id?: Maybe<Scalars['bigint']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id: Scalars['bigint']['output'];
  vc_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "orders" */
export type Mobile_Orders_Aggregate = {
  __typename?: 'mobile_orders_aggregate';
  aggregate?: Maybe<Mobile_Orders_Aggregate_Fields>;
  nodes: Array<Mobile_Orders>;
};

export type Mobile_Orders_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Orders_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orders" */
export type Mobile_Orders_Aggregate_Fields = {
  __typename?: 'mobile_orders_aggregate_fields';
  avg?: Maybe<Mobile_Orders_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Orders_Max_Fields>;
  min?: Maybe<Mobile_Orders_Min_Fields>;
  stddev?: Maybe<Mobile_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Orders_Sum_Fields>;
  var_pop?: Maybe<Mobile_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Orders_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Orders_Variance_Fields>;
};


/** aggregate fields of "orders" */
export type Mobile_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "orders" */
export type Mobile_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Orders_Max_Order_By>;
  min?: InputMaybe<Mobile_Orders_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Mobile_Orders_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Orders_Avg_Fields = {
  __typename?: 'mobile_orders_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
  to_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "orders" */
export type Mobile_Orders_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Mobile_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Orders_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Orders_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  client?: InputMaybe<Mobile_Clients_Bool_Exp>;
  client_id?: InputMaybe<Bigint_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  currency?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  fee?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  paid_at?: InputMaybe<Timestamp_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  to_client?: InputMaybe<Mobile_Clients_Bool_Exp>;
  to_client_id?: InputMaybe<Bigint_Comparison_Exp>;
  to_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  vc_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Mobile_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** input type for incrementing numeric columns in table "orders" */
export type Mobile_Orders_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
  to_user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "orders" */
export type Mobile_Orders_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  client?: InputMaybe<Mobile_Clients_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  paid_at?: InputMaybe<Scalars['timestamp']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  to_client?: InputMaybe<Mobile_Clients_Obj_Rel_Insert_Input>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
  to_user_id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Orders_Max_Fields = {
  __typename?: 'mobile_orders_max_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  paid_at?: Maybe<Scalars['timestamp']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
  to_user_id?: Maybe<Scalars['bigint']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  vc_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "orders" */
export type Mobile_Orders_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Orders_Min_Fields = {
  __typename?: 'mobile_orders_min_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  paid_at?: Maybe<Scalars['timestamp']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
  to_user_id?: Maybe<Scalars['bigint']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  vc_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "orders" */
export type Mobile_Orders_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Mobile_Orders_Mutation_Response = {
  __typename?: 'mobile_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Mobile_Orders_Obj_Rel_Insert_Input = {
  data: Mobile_Orders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Mobile_Orders_On_Conflict = {
  constraint: Mobile_Orders_Constraint;
  update_columns?: Array<Mobile_Orders_Update_Column>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Mobile_Orders_Order_By = {
  amount?: InputMaybe<Order_By>;
  client?: InputMaybe<Mobile_Clients_Order_By>;
  client_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paid_at?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client?: InputMaybe<Mobile_Clients_Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: orders */
export type Mobile_Orders_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "orders" */
export enum Mobile_Orders_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Fee = 'fee',
  /** column name */
  Id = 'id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  ToClientId = 'to_client_id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VcId = 'vc_id'
}

/** input type for updating data in table "orders" */
export type Mobile_Orders_Set_Input = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  paid_at?: InputMaybe<Scalars['timestamp']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
  to_user_id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Orders_Stddev_Fields = {
  __typename?: 'mobile_orders_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
  to_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "orders" */
export type Mobile_Orders_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Orders_Stddev_Pop_Fields = {
  __typename?: 'mobile_orders_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
  to_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type Mobile_Orders_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Orders_Stddev_Samp_Fields = {
  __typename?: 'mobile_orders_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
  to_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type Mobile_Orders_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_orders" */
export type Mobile_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Orders_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  client_id?: InputMaybe<Scalars['bigint']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  paid_at?: InputMaybe<Scalars['timestamp']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  to_client_id?: InputMaybe<Scalars['bigint']['input']>;
  to_user_id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Orders_Sum_Fields = {
  __typename?: 'mobile_orders_sum_fields';
  amount?: Maybe<Scalars['Int']['output']>;
  client_id?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  to_client_id?: Maybe<Scalars['bigint']['output']>;
  to_user_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  vc_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "orders" */
export type Mobile_Orders_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** update columns of table "orders" */
export enum Mobile_Orders_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Fee = 'fee',
  /** column name */
  Id = 'id',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  ToClientId = 'to_client_id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VcId = 'vc_id'
}

export type Mobile_Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Orders_Var_Pop_Fields = {
  __typename?: 'mobile_orders_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
  to_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "orders" */
export type Mobile_Orders_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Orders_Var_Samp_Fields = {
  __typename?: 'mobile_orders_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
  to_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "orders" */
export type Mobile_Orders_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Orders_Variance_Fields = {
  __typename?: 'mobile_orders_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  client_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  to_client_id?: Maybe<Scalars['Float']['output']>;
  to_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "orders" */
export type Mobile_Orders_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_client_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "orgs" */
export type Mobile_Orgs = {
  __typename?: 'mobile_orgs';
  active?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  category?: Maybe<Mobile_Categories>;
  category_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  describe?: Maybe<Scalars['String']['output']>;
  document_path_file?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "orgs" */
export type Mobile_Orgs_Aggregate = {
  __typename?: 'mobile_orgs_aggregate';
  aggregate?: Maybe<Mobile_Orgs_Aggregate_Fields>;
  nodes: Array<Mobile_Orgs>;
};

export type Mobile_Orgs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Orgs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Orgs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Orgs_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Orgs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Orgs_Select_Column_Mobile_Orgs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Orgs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Orgs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Orgs_Select_Column_Mobile_Orgs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Orgs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Orgs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Orgs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orgs" */
export type Mobile_Orgs_Aggregate_Fields = {
  __typename?: 'mobile_orgs_aggregate_fields';
  avg?: Maybe<Mobile_Orgs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Orgs_Max_Fields>;
  min?: Maybe<Mobile_Orgs_Min_Fields>;
  stddev?: Maybe<Mobile_Orgs_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Orgs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Orgs_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Orgs_Sum_Fields>;
  var_pop?: Maybe<Mobile_Orgs_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Orgs_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Orgs_Variance_Fields>;
};


/** aggregate fields of "orgs" */
export type Mobile_Orgs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "orgs" */
export type Mobile_Orgs_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Orgs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Orgs_Max_Order_By>;
  min?: InputMaybe<Mobile_Orgs_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Orgs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Orgs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Orgs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Orgs_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Orgs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Orgs_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Orgs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "orgs" */
export type Mobile_Orgs_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Orgs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Orgs_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Orgs_Avg_Fields = {
  __typename?: 'mobile_orgs_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "orgs" */
export type Mobile_Orgs_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orgs". All fields are combined with a logical 'AND'. */
export type Mobile_Orgs_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Orgs_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Orgs_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Orgs_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  category?: InputMaybe<Mobile_Categories_Bool_Exp>;
  category_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  describe?: InputMaybe<String_Comparison_Exp>;
  document_path_file?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "orgs" */
export enum Mobile_Orgs_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrgsPkey = 'orgs_pkey'
}

/** input type for incrementing numeric columns in table "orgs" */
export type Mobile_Orgs_Inc_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "orgs" */
export type Mobile_Orgs_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Mobile_Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  describe?: InputMaybe<Scalars['String']['input']>;
  document_path_file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Orgs_Max_Fields = {
  __typename?: 'mobile_orgs_max_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  describe?: Maybe<Scalars['String']['output']>;
  document_path_file?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "orgs" */
export type Mobile_Orgs_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  describe?: InputMaybe<Order_By>;
  document_path_file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Orgs_Min_Fields = {
  __typename?: 'mobile_orgs_min_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  describe?: Maybe<Scalars['String']['output']>;
  document_path_file?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "orgs" */
export type Mobile_Orgs_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  describe?: InputMaybe<Order_By>;
  document_path_file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "orgs" */
export type Mobile_Orgs_Mutation_Response = {
  __typename?: 'mobile_orgs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Orgs>;
};

/** on_conflict condition type for table "orgs" */
export type Mobile_Orgs_On_Conflict = {
  constraint: Mobile_Orgs_Constraint;
  update_columns?: Array<Mobile_Orgs_Update_Column>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};

/** Ordering options when selecting data from "orgs". */
export type Mobile_Orgs_Order_By = {
  active?: InputMaybe<Order_By>;
  category?: InputMaybe<Mobile_Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  describe?: InputMaybe<Order_By>;
  document_path_file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: orgs */
export type Mobile_Orgs_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "orgs" */
export enum Mobile_Orgs_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Describe = 'describe',
  /** column name */
  DocumentPathFile = 'document_path_file',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_orgs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "orgs" */
export enum Mobile_Orgs_Select_Column_Mobile_Orgs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "mobile_orgs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "orgs" */
export enum Mobile_Orgs_Select_Column_Mobile_Orgs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "orgs" */
export type Mobile_Orgs_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  describe?: InputMaybe<Scalars['String']['input']>;
  document_path_file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Orgs_Stddev_Fields = {
  __typename?: 'mobile_orgs_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "orgs" */
export type Mobile_Orgs_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Orgs_Stddev_Pop_Fields = {
  __typename?: 'mobile_orgs_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "orgs" */
export type Mobile_Orgs_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Orgs_Stddev_Samp_Fields = {
  __typename?: 'mobile_orgs_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "orgs" */
export type Mobile_Orgs_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_orgs" */
export type Mobile_Orgs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Orgs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Orgs_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  describe?: InputMaybe<Scalars['String']['input']>;
  document_path_file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Orgs_Sum_Fields = {
  __typename?: 'mobile_orgs_sum_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "orgs" */
export type Mobile_Orgs_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "orgs" */
export enum Mobile_Orgs_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Describe = 'describe',
  /** column name */
  DocumentPathFile = 'document_path_file',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Orgs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Orgs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Orgs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Orgs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Orgs_Var_Pop_Fields = {
  __typename?: 'mobile_orgs_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "orgs" */
export type Mobile_Orgs_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Orgs_Var_Samp_Fields = {
  __typename?: 'mobile_orgs_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "orgs" */
export type Mobile_Orgs_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Orgs_Variance_Fields = {
  __typename?: 'mobile_orgs_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "orgs" */
export type Mobile_Orgs_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "param_categories" */
export type Mobile_Param_Categories = {
  __typename?: 'mobile_param_categories';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  param_categories_params: Array<Mobile_Param_Categories_Params>;
  /** An aggregate relationship */
  param_categories_params_aggregate: Mobile_Param_Categories_Params_Aggregate;
  /** An array relationship */
  param_category_names: Array<Mobile_Param_Category_Names>;
  /** An aggregate relationship */
  param_category_names_aggregate: Mobile_Param_Category_Names_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "param_categories" */
export type Mobile_Param_CategoriesParam_Categories_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


/** columns and relationships of "param_categories" */
export type Mobile_Param_CategoriesParam_Categories_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


/** columns and relationships of "param_categories" */
export type Mobile_Param_CategoriesParam_Category_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};


/** columns and relationships of "param_categories" */
export type Mobile_Param_CategoriesParam_Category_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};

/** aggregated selection of "param_categories" */
export type Mobile_Param_Categories_Aggregate = {
  __typename?: 'mobile_param_categories_aggregate';
  aggregate?: Maybe<Mobile_Param_Categories_Aggregate_Fields>;
  nodes: Array<Mobile_Param_Categories>;
};

/** aggregate fields of "param_categories" */
export type Mobile_Param_Categories_Aggregate_Fields = {
  __typename?: 'mobile_param_categories_aggregate_fields';
  avg?: Maybe<Mobile_Param_Categories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Param_Categories_Max_Fields>;
  min?: Maybe<Mobile_Param_Categories_Min_Fields>;
  stddev?: Maybe<Mobile_Param_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Param_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Param_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Param_Categories_Sum_Fields>;
  var_pop?: Maybe<Mobile_Param_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Param_Categories_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Param_Categories_Variance_Fields>;
};


/** aggregate fields of "param_categories" */
export type Mobile_Param_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Param_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Param_Categories_Avg_Fields = {
  __typename?: 'mobile_param_categories_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "param_categories". All fields are combined with a logical 'AND'. */
export type Mobile_Param_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Param_Categories_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Param_Categories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  param_categories_params?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
  param_categories_params_aggregate?: InputMaybe<Mobile_Param_Categories_Params_Aggregate_Bool_Exp>;
  param_category_names?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
  param_category_names_aggregate?: InputMaybe<Mobile_Param_Category_Names_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "param_categories" */
export enum Mobile_Param_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParamCategoriesPkey = 'param_categories_pkey'
}

/** input type for incrementing numeric columns in table "param_categories" */
export type Mobile_Param_Categories_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "param_categories" */
export type Mobile_Param_Categories_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  param_categories_params?: InputMaybe<Mobile_Param_Categories_Params_Arr_Rel_Insert_Input>;
  param_category_names?: InputMaybe<Mobile_Param_Category_Names_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Param_Categories_Max_Fields = {
  __typename?: 'mobile_param_categories_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Param_Categories_Min_Fields = {
  __typename?: 'mobile_param_categories_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "param_categories" */
export type Mobile_Param_Categories_Mutation_Response = {
  __typename?: 'mobile_param_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Param_Categories>;
};

/** input type for inserting object relation for remote table "param_categories" */
export type Mobile_Param_Categories_Obj_Rel_Insert_Input = {
  data: Mobile_Param_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Param_Categories_On_Conflict>;
};

/** on_conflict condition type for table "param_categories" */
export type Mobile_Param_Categories_On_Conflict = {
  constraint: Mobile_Param_Categories_Constraint;
  update_columns?: Array<Mobile_Param_Categories_Update_Column>;
  where?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "param_categories". */
export type Mobile_Param_Categories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_categories_params_aggregate?: InputMaybe<Mobile_Param_Categories_Params_Aggregate_Order_By>;
  param_category_names_aggregate?: InputMaybe<Mobile_Param_Category_Names_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "param_categories_params" */
export type Mobile_Param_Categories_Params = {
  __typename?: 'mobile_param_categories_params';
  /** An object relationship */
  param?: Maybe<Mobile_Params>;
  /** An object relationship */
  param_category?: Maybe<Mobile_Param_Categories>;
  param_category_id: Scalars['bigint']['output'];
  param_id: Scalars['bigint']['output'];
};

/** aggregated selection of "param_categories_params" */
export type Mobile_Param_Categories_Params_Aggregate = {
  __typename?: 'mobile_param_categories_params_aggregate';
  aggregate?: Maybe<Mobile_Param_Categories_Params_Aggregate_Fields>;
  nodes: Array<Mobile_Param_Categories_Params>;
};

export type Mobile_Param_Categories_Params_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Param_Categories_Params_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Param_Categories_Params_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "param_categories_params" */
export type Mobile_Param_Categories_Params_Aggregate_Fields = {
  __typename?: 'mobile_param_categories_params_aggregate_fields';
  avg?: Maybe<Mobile_Param_Categories_Params_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Param_Categories_Params_Max_Fields>;
  min?: Maybe<Mobile_Param_Categories_Params_Min_Fields>;
  stddev?: Maybe<Mobile_Param_Categories_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Param_Categories_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Param_Categories_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Param_Categories_Params_Sum_Fields>;
  var_pop?: Maybe<Mobile_Param_Categories_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Param_Categories_Params_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Param_Categories_Params_Variance_Fields>;
};


/** aggregate fields of "param_categories_params" */
export type Mobile_Param_Categories_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Param_Categories_Params_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Param_Categories_Params_Max_Order_By>;
  min?: InputMaybe<Mobile_Param_Categories_Params_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Param_Categories_Params_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Param_Categories_Params_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Param_Categories_Params_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Param_Categories_Params_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Param_Categories_Params_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Param_Categories_Params_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Param_Categories_Params_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "param_categories_params" */
export type Mobile_Param_Categories_Params_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Param_Categories_Params_Insert_Input>;
};

/** aggregate avg on columns */
export type Mobile_Param_Categories_Params_Avg_Fields = {
  __typename?: 'mobile_param_categories_params_avg_fields';
  param_category_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Avg_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "param_categories_params". All fields are combined with a logical 'AND'. */
export type Mobile_Param_Categories_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Param_Categories_Params_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Param_Categories_Params_Bool_Exp>>;
  param?: InputMaybe<Mobile_Params_Bool_Exp>;
  param_category?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
  param_category_id?: InputMaybe<Bigint_Comparison_Exp>;
  param_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "param_categories_params" */
export type Mobile_Param_Categories_Params_Inc_Input = {
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "param_categories_params" */
export type Mobile_Param_Categories_Params_Insert_Input = {
  param?: InputMaybe<Mobile_Params_Obj_Rel_Insert_Input>;
  param_category?: InputMaybe<Mobile_Param_Categories_Obj_Rel_Insert_Input>;
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Param_Categories_Params_Max_Fields = {
  __typename?: 'mobile_param_categories_params_max_fields';
  param_category_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Max_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Param_Categories_Params_Min_Fields = {
  __typename?: 'mobile_param_categories_params_min_fields';
  param_category_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Min_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "param_categories_params" */
export type Mobile_Param_Categories_Params_Mutation_Response = {
  __typename?: 'mobile_param_categories_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Param_Categories_Params>;
};

/** Ordering options when selecting data from "param_categories_params". */
export type Mobile_Param_Categories_Params_Order_By = {
  param?: InputMaybe<Mobile_Params_Order_By>;
  param_category?: InputMaybe<Mobile_Param_Categories_Order_By>;
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** select columns of table "param_categories_params" */
export enum Mobile_Param_Categories_Params_Select_Column {
  /** column name */
  ParamCategoryId = 'param_category_id',
  /** column name */
  ParamId = 'param_id'
}

/** input type for updating data in table "param_categories_params" */
export type Mobile_Param_Categories_Params_Set_Input = {
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Param_Categories_Params_Stddev_Fields = {
  __typename?: 'mobile_param_categories_params_stddev_fields';
  param_category_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Stddev_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Param_Categories_Params_Stddev_Pop_Fields = {
  __typename?: 'mobile_param_categories_params_stddev_pop_fields';
  param_category_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Stddev_Pop_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Param_Categories_Params_Stddev_Samp_Fields = {
  __typename?: 'mobile_param_categories_params_stddev_samp_fields';
  param_category_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Stddev_Samp_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_param_categories_params" */
export type Mobile_Param_Categories_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Param_Categories_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Param_Categories_Params_Stream_Cursor_Value_Input = {
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Param_Categories_Params_Sum_Fields = {
  __typename?: 'mobile_param_categories_params_sum_fields';
  param_category_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Sum_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

export type Mobile_Param_Categories_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Param_Categories_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Param_Categories_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Param_Categories_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Param_Categories_Params_Var_Pop_Fields = {
  __typename?: 'mobile_param_categories_params_var_pop_fields';
  param_category_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Var_Pop_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Param_Categories_Params_Var_Samp_Fields = {
  __typename?: 'mobile_param_categories_params_var_samp_fields';
  param_category_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Var_Samp_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Param_Categories_Params_Variance_Fields = {
  __typename?: 'mobile_param_categories_params_variance_fields';
  param_category_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "param_categories_params" */
export type Mobile_Param_Categories_Params_Variance_Order_By = {
  param_category_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: param_categories */
export type Mobile_Param_Categories_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "param_categories" */
export enum Mobile_Param_Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "param_categories" */
export type Mobile_Param_Categories_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Param_Categories_Stddev_Fields = {
  __typename?: 'mobile_param_categories_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Param_Categories_Stddev_Pop_Fields = {
  __typename?: 'mobile_param_categories_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Param_Categories_Stddev_Samp_Fields = {
  __typename?: 'mobile_param_categories_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_param_categories" */
export type Mobile_Param_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Param_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Param_Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Param_Categories_Sum_Fields = {
  __typename?: 'mobile_param_categories_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "param_categories" */
export enum Mobile_Param_Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Param_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Param_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Param_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Param_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Param_Categories_Var_Pop_Fields = {
  __typename?: 'mobile_param_categories_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Param_Categories_Var_Samp_Fields = {
  __typename?: 'mobile_param_categories_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Param_Categories_Variance_Fields = {
  __typename?: 'mobile_param_categories_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "param_category_names" */
export type Mobile_Param_Category_Names = {
  __typename?: 'mobile_param_category_names';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  param_category: Mobile_Param_Categories;
  param_category_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "param_category_names" */
export type Mobile_Param_Category_Names_Aggregate = {
  __typename?: 'mobile_param_category_names_aggregate';
  aggregate?: Maybe<Mobile_Param_Category_Names_Aggregate_Fields>;
  nodes: Array<Mobile_Param_Category_Names>;
};

export type Mobile_Param_Category_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Param_Category_Names_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Param_Category_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "param_category_names" */
export type Mobile_Param_Category_Names_Aggregate_Fields = {
  __typename?: 'mobile_param_category_names_aggregate_fields';
  avg?: Maybe<Mobile_Param_Category_Names_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Param_Category_Names_Max_Fields>;
  min?: Maybe<Mobile_Param_Category_Names_Min_Fields>;
  stddev?: Maybe<Mobile_Param_Category_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Param_Category_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Param_Category_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Param_Category_Names_Sum_Fields>;
  var_pop?: Maybe<Mobile_Param_Category_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Param_Category_Names_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Param_Category_Names_Variance_Fields>;
};


/** aggregate fields of "param_category_names" */
export type Mobile_Param_Category_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "param_category_names" */
export type Mobile_Param_Category_Names_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Param_Category_Names_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Param_Category_Names_Max_Order_By>;
  min?: InputMaybe<Mobile_Param_Category_Names_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Param_Category_Names_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Param_Category_Names_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Param_Category_Names_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Param_Category_Names_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Param_Category_Names_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Param_Category_Names_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Param_Category_Names_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "param_category_names" */
export type Mobile_Param_Category_Names_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Param_Category_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Param_Category_Names_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Param_Category_Names_Avg_Fields = {
  __typename?: 'mobile_param_category_names_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "param_category_names". All fields are combined with a logical 'AND'. */
export type Mobile_Param_Category_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Param_Category_Names_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Param_Category_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  param_category?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
  param_category_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "param_category_names" */
export enum Mobile_Param_Category_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParamCategoryNamesPkey = 'param_category_names_pkey'
}

/** input type for incrementing numeric columns in table "param_category_names" */
export type Mobile_Param_Category_Names_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "param_category_names" */
export type Mobile_Param_Category_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  param_category?: InputMaybe<Mobile_Param_Categories_Obj_Rel_Insert_Input>;
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Param_Category_Names_Max_Fields = {
  __typename?: 'mobile_param_category_names_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  param_category_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Param_Category_Names_Min_Fields = {
  __typename?: 'mobile_param_category_names_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  param_category_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "param_category_names" */
export type Mobile_Param_Category_Names_Mutation_Response = {
  __typename?: 'mobile_param_category_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Param_Category_Names>;
};

/** on_conflict condition type for table "param_category_names" */
export type Mobile_Param_Category_Names_On_Conflict = {
  constraint: Mobile_Param_Category_Names_Constraint;
  update_columns?: Array<Mobile_Param_Category_Names_Update_Column>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "param_category_names". */
export type Mobile_Param_Category_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  param_category?: InputMaybe<Mobile_Param_Categories_Order_By>;
  param_category_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: param_category_names */
export type Mobile_Param_Category_Names_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "param_category_names" */
export enum Mobile_Param_Category_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ParamCategoryId = 'param_category_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "param_category_names" */
export type Mobile_Param_Category_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Param_Category_Names_Stddev_Fields = {
  __typename?: 'mobile_param_category_names_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Param_Category_Names_Stddev_Pop_Fields = {
  __typename?: 'mobile_param_category_names_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Param_Category_Names_Stddev_Samp_Fields = {
  __typename?: 'mobile_param_category_names_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_param_category_names" */
export type Mobile_Param_Category_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Param_Category_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Param_Category_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  param_category_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Param_Category_Names_Sum_Fields = {
  __typename?: 'mobile_param_category_names_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  param_category_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** update columns of table "param_category_names" */
export enum Mobile_Param_Category_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ParamCategoryId = 'param_category_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Param_Category_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Param_Category_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Param_Category_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Param_Category_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Param_Category_Names_Var_Pop_Fields = {
  __typename?: 'mobile_param_category_names_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Param_Category_Names_Var_Samp_Fields = {
  __typename?: 'mobile_param_category_names_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Param_Category_Names_Variance_Fields = {
  __typename?: 'mobile_param_category_names_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "param_category_names" */
export type Mobile_Param_Category_Names_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  param_category_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "param_names" */
export type Mobile_Param_Names = {
  __typename?: 'mobile_param_names';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  param: Mobile_Params;
  param_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "param_names" */
export type Mobile_Param_Names_Aggregate = {
  __typename?: 'mobile_param_names_aggregate';
  aggregate?: Maybe<Mobile_Param_Names_Aggregate_Fields>;
  nodes: Array<Mobile_Param_Names>;
};

export type Mobile_Param_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Param_Names_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Param_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "param_names" */
export type Mobile_Param_Names_Aggregate_Fields = {
  __typename?: 'mobile_param_names_aggregate_fields';
  avg?: Maybe<Mobile_Param_Names_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Param_Names_Max_Fields>;
  min?: Maybe<Mobile_Param_Names_Min_Fields>;
  stddev?: Maybe<Mobile_Param_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Param_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Param_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Param_Names_Sum_Fields>;
  var_pop?: Maybe<Mobile_Param_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Param_Names_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Param_Names_Variance_Fields>;
};


/** aggregate fields of "param_names" */
export type Mobile_Param_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "param_names" */
export type Mobile_Param_Names_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Param_Names_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Param_Names_Max_Order_By>;
  min?: InputMaybe<Mobile_Param_Names_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Param_Names_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Param_Names_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Param_Names_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Param_Names_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Param_Names_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Param_Names_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Param_Names_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "param_names" */
export type Mobile_Param_Names_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Param_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Param_Names_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Param_Names_Avg_Fields = {
  __typename?: 'mobile_param_names_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "param_names" */
export type Mobile_Param_Names_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "param_names". All fields are combined with a logical 'AND'. */
export type Mobile_Param_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Param_Names_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Param_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  param?: InputMaybe<Mobile_Params_Bool_Exp>;
  param_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "param_names" */
export enum Mobile_Param_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParamNamesPkey = 'param_names_pkey'
}

/** input type for incrementing numeric columns in table "param_names" */
export type Mobile_Param_Names_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "param_names" */
export type Mobile_Param_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  param?: InputMaybe<Mobile_Params_Obj_Rel_Insert_Input>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Param_Names_Max_Fields = {
  __typename?: 'mobile_param_names_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "param_names" */
export type Mobile_Param_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Param_Names_Min_Fields = {
  __typename?: 'mobile_param_names_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "param_names" */
export type Mobile_Param_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "param_names" */
export type Mobile_Param_Names_Mutation_Response = {
  __typename?: 'mobile_param_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Param_Names>;
};

/** on_conflict condition type for table "param_names" */
export type Mobile_Param_Names_On_Conflict = {
  constraint: Mobile_Param_Names_Constraint;
  update_columns?: Array<Mobile_Param_Names_Update_Column>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "param_names". */
export type Mobile_Param_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  param?: InputMaybe<Mobile_Params_Order_By>;
  param_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: param_names */
export type Mobile_Param_Names_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "param_names" */
export enum Mobile_Param_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "param_names" */
export type Mobile_Param_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Param_Names_Stddev_Fields = {
  __typename?: 'mobile_param_names_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "param_names" */
export type Mobile_Param_Names_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Param_Names_Stddev_Pop_Fields = {
  __typename?: 'mobile_param_names_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "param_names" */
export type Mobile_Param_Names_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Param_Names_Stddev_Samp_Fields = {
  __typename?: 'mobile_param_names_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "param_names" */
export type Mobile_Param_Names_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_param_names" */
export type Mobile_Param_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Param_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Param_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Param_Names_Sum_Fields = {
  __typename?: 'mobile_param_names_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "param_names" */
export type Mobile_Param_Names_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** update columns of table "param_names" */
export enum Mobile_Param_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Param_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Param_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Param_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Param_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Param_Names_Var_Pop_Fields = {
  __typename?: 'mobile_param_names_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "param_names" */
export type Mobile_Param_Names_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Param_Names_Var_Samp_Fields = {
  __typename?: 'mobile_param_names_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "param_names" */
export type Mobile_Param_Names_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Param_Names_Variance_Fields = {
  __typename?: 'mobile_param_names_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "param_names" */
export type Mobile_Param_Names_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "params" */
export type Mobile_Params = {
  __typename?: 'mobile_params';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  metric_params: Array<Mobile_Metric_Params>;
  /** An aggregate relationship */
  metric_params_aggregate: Mobile_Metric_Params_Aggregate;
  /** An array relationship */
  param_categories_params: Array<Mobile_Param_Categories_Params>;
  /** An aggregate relationship */
  param_categories_params_aggregate: Mobile_Param_Categories_Params_Aggregate;
  /** An array relationship */
  param_names: Array<Mobile_Param_Names>;
  /** An aggregate relationship */
  param_names_aggregate: Mobile_Param_Names_Aggregate;
  /** An array relationship */
  params_services: Array<Mobile_Params_Services>;
  /** An aggregate relationship */
  params_services_aggregate: Mobile_Params_Services_Aggregate;
  /** An array relationship */
  post_params: Array<Mobile_Post_Params>;
  /** An aggregate relationship */
  post_params_aggregate: Mobile_Post_Params_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "params" */
export type Mobile_ParamsMetric_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsMetric_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsParam_Categories_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsParam_Categories_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsParam_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsParam_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsParams_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsParams_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsPost_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


/** columns and relationships of "params" */
export type Mobile_ParamsPost_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};

/** aggregated selection of "params" */
export type Mobile_Params_Aggregate = {
  __typename?: 'mobile_params_aggregate';
  aggregate?: Maybe<Mobile_Params_Aggregate_Fields>;
  nodes: Array<Mobile_Params>;
};

/** aggregate fields of "params" */
export type Mobile_Params_Aggregate_Fields = {
  __typename?: 'mobile_params_aggregate_fields';
  avg?: Maybe<Mobile_Params_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Params_Max_Fields>;
  min?: Maybe<Mobile_Params_Min_Fields>;
  stddev?: Maybe<Mobile_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Params_Sum_Fields>;
  var_pop?: Maybe<Mobile_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Params_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Params_Variance_Fields>;
};


/** aggregate fields of "params" */
export type Mobile_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Params_Avg_Fields = {
  __typename?: 'mobile_params_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "params". All fields are combined with a logical 'AND'. */
export type Mobile_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Params_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Params_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metric_params?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
  metric_params_aggregate?: InputMaybe<Mobile_Metric_Params_Aggregate_Bool_Exp>;
  param_categories_params?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
  param_categories_params_aggregate?: InputMaybe<Mobile_Param_Categories_Params_Aggregate_Bool_Exp>;
  param_names?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
  param_names_aggregate?: InputMaybe<Mobile_Param_Names_Aggregate_Bool_Exp>;
  params_services?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Bool_Exp>;
  post_params?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "params" */
export enum Mobile_Params_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParamsPkey = 'params_pkey'
}

/** input type for incrementing numeric columns in table "params" */
export type Mobile_Params_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "params" */
export type Mobile_Params_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_params?: InputMaybe<Mobile_Metric_Params_Arr_Rel_Insert_Input>;
  param_categories_params?: InputMaybe<Mobile_Param_Categories_Params_Arr_Rel_Insert_Input>;
  param_names?: InputMaybe<Mobile_Param_Names_Arr_Rel_Insert_Input>;
  params_services?: InputMaybe<Mobile_Params_Services_Arr_Rel_Insert_Input>;
  post_params?: InputMaybe<Mobile_Post_Params_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Params_Max_Fields = {
  __typename?: 'mobile_params_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Params_Min_Fields = {
  __typename?: 'mobile_params_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "params" */
export type Mobile_Params_Mutation_Response = {
  __typename?: 'mobile_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Params>;
};

/** input type for inserting object relation for remote table "params" */
export type Mobile_Params_Obj_Rel_Insert_Input = {
  data: Mobile_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Params_On_Conflict>;
};

/** on_conflict condition type for table "params" */
export type Mobile_Params_On_Conflict = {
  constraint: Mobile_Params_Constraint;
  update_columns?: Array<Mobile_Params_Update_Column>;
  where?: InputMaybe<Mobile_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "params". */
export type Mobile_Params_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_params_aggregate?: InputMaybe<Mobile_Metric_Params_Aggregate_Order_By>;
  param_categories_params_aggregate?: InputMaybe<Mobile_Param_Categories_Params_Aggregate_Order_By>;
  param_names_aggregate?: InputMaybe<Mobile_Param_Names_Aggregate_Order_By>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Order_By>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: params */
export type Mobile_Params_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "params" */
export enum Mobile_Params_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "params_services" */
export type Mobile_Params_Services = {
  __typename?: 'mobile_params_services';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  metric?: Maybe<Mobile_Metrics>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  param?: Maybe<Mobile_Params>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "params_services" */
export type Mobile_Params_Services_Aggregate = {
  __typename?: 'mobile_params_services_aggregate';
  aggregate?: Maybe<Mobile_Params_Services_Aggregate_Fields>;
  nodes: Array<Mobile_Params_Services>;
};

export type Mobile_Params_Services_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Params_Services_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Params_Services_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "params_services" */
export type Mobile_Params_Services_Aggregate_Fields = {
  __typename?: 'mobile_params_services_aggregate_fields';
  avg?: Maybe<Mobile_Params_Services_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Params_Services_Max_Fields>;
  min?: Maybe<Mobile_Params_Services_Min_Fields>;
  stddev?: Maybe<Mobile_Params_Services_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Params_Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Params_Services_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Params_Services_Sum_Fields>;
  var_pop?: Maybe<Mobile_Params_Services_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Params_Services_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Params_Services_Variance_Fields>;
};


/** aggregate fields of "params_services" */
export type Mobile_Params_Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "params_services" */
export type Mobile_Params_Services_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Params_Services_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Params_Services_Max_Order_By>;
  min?: InputMaybe<Mobile_Params_Services_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Params_Services_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Params_Services_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Params_Services_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Params_Services_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Params_Services_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Params_Services_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Params_Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "params_services" */
export type Mobile_Params_Services_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Params_Services_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Params_Services_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Params_Services_Avg_Fields = {
  __typename?: 'mobile_params_services_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "params_services" */
export type Mobile_Params_Services_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "params_services". All fields are combined with a logical 'AND'. */
export type Mobile_Params_Services_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Params_Services_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Params_Services_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metric?: InputMaybe<Mobile_Metrics_Bool_Exp>;
  metric_id?: InputMaybe<Bigint_Comparison_Exp>;
  param?: InputMaybe<Mobile_Params_Bool_Exp>;
  param_id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "params_services" */
export enum Mobile_Params_Services_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParamsServicesPkey = 'params_services_pkey'
}

/** input type for incrementing numeric columns in table "params_services" */
export type Mobile_Params_Services_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "params_services" */
export type Mobile_Params_Services_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric?: InputMaybe<Mobile_Metrics_Obj_Rel_Insert_Input>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param?: InputMaybe<Mobile_Params_Obj_Rel_Insert_Input>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Params_Services_Max_Fields = {
  __typename?: 'mobile_params_services_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "params_services" */
export type Mobile_Params_Services_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Params_Services_Min_Fields = {
  __typename?: 'mobile_params_services_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "params_services" */
export type Mobile_Params_Services_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "params_services" */
export type Mobile_Params_Services_Mutation_Response = {
  __typename?: 'mobile_params_services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Params_Services>;
};

/** on_conflict condition type for table "params_services" */
export type Mobile_Params_Services_On_Conflict = {
  constraint: Mobile_Params_Services_Constraint;
  update_columns?: Array<Mobile_Params_Services_Update_Column>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};

/** Ordering options when selecting data from "params_services". */
export type Mobile_Params_Services_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric?: InputMaybe<Mobile_Metrics_Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param?: InputMaybe<Mobile_Params_Order_By>;
  param_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: params_services */
export type Mobile_Params_Services_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "params_services" */
export enum Mobile_Params_Services_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "params_services" */
export type Mobile_Params_Services_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Params_Services_Stddev_Fields = {
  __typename?: 'mobile_params_services_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "params_services" */
export type Mobile_Params_Services_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Params_Services_Stddev_Pop_Fields = {
  __typename?: 'mobile_params_services_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "params_services" */
export type Mobile_Params_Services_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Params_Services_Stddev_Samp_Fields = {
  __typename?: 'mobile_params_services_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "params_services" */
export type Mobile_Params_Services_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_params_services" */
export type Mobile_Params_Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Params_Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Params_Services_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Params_Services_Sum_Fields = {
  __typename?: 'mobile_params_services_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "params_services" */
export type Mobile_Params_Services_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "params_services" */
export enum Mobile_Params_Services_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Params_Services_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Params_Services_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Params_Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Params_Services_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Params_Services_Var_Pop_Fields = {
  __typename?: 'mobile_params_services_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "params_services" */
export type Mobile_Params_Services_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Params_Services_Var_Samp_Fields = {
  __typename?: 'mobile_params_services_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "params_services" */
export type Mobile_Params_Services_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Params_Services_Variance_Fields = {
  __typename?: 'mobile_params_services_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "params_services" */
export type Mobile_Params_Services_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for updating data in table "params" */
export type Mobile_Params_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Params_Stddev_Fields = {
  __typename?: 'mobile_params_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Params_Stddev_Pop_Fields = {
  __typename?: 'mobile_params_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Params_Stddev_Samp_Fields = {
  __typename?: 'mobile_params_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_params" */
export type Mobile_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Params_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Params_Sum_Fields = {
  __typename?: 'mobile_params_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "params" */
export enum Mobile_Params_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Params_Var_Pop_Fields = {
  __typename?: 'mobile_params_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Params_Var_Samp_Fields = {
  __typename?: 'mobile_params_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Params_Variance_Fields = {
  __typename?: 'mobile_params_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_Views = {
  __typename?: 'mobile_personal_info_views';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  personalInfosByBirthdayViewId: Array<Mobile_Personal_Infos>;
  /** An aggregate relationship */
  personalInfosByBirthdayViewId_aggregate: Mobile_Personal_Infos_Aggregate;
  /** An array relationship */
  personalInfosByGenderViewId: Array<Mobile_Personal_Infos>;
  /** An aggregate relationship */
  personalInfosByGenderViewId_aggregate: Mobile_Personal_Infos_Aggregate;
  /** An array relationship */
  personalInfosByHeightViewId: Array<Mobile_Personal_Infos>;
  /** An aggregate relationship */
  personalInfosByHeightViewId_aggregate: Mobile_Personal_Infos_Aggregate;
  /** An array relationship */
  personalInfosByWeightViewId: Array<Mobile_Personal_Infos>;
  /** An aggregate relationship */
  personalInfosByWeightViewId_aggregate: Mobile_Personal_Infos_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByBirthdayViewIdArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByBirthdayViewId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByGenderViewIdArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByGenderViewId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByHeightViewIdArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByHeightViewId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByWeightViewIdArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "personal_info_views" */
export type Mobile_Personal_Info_ViewsPersonalInfosByWeightViewId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};

/** aggregated selection of "personal_info_views" */
export type Mobile_Personal_Info_Views_Aggregate = {
  __typename?: 'mobile_personal_info_views_aggregate';
  aggregate?: Maybe<Mobile_Personal_Info_Views_Aggregate_Fields>;
  nodes: Array<Mobile_Personal_Info_Views>;
};

/** aggregate fields of "personal_info_views" */
export type Mobile_Personal_Info_Views_Aggregate_Fields = {
  __typename?: 'mobile_personal_info_views_aggregate_fields';
  avg?: Maybe<Mobile_Personal_Info_Views_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Personal_Info_Views_Max_Fields>;
  min?: Maybe<Mobile_Personal_Info_Views_Min_Fields>;
  stddev?: Maybe<Mobile_Personal_Info_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Personal_Info_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Personal_Info_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Personal_Info_Views_Sum_Fields>;
  var_pop?: Maybe<Mobile_Personal_Info_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Personal_Info_Views_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Personal_Info_Views_Variance_Fields>;
};


/** aggregate fields of "personal_info_views" */
export type Mobile_Personal_Info_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Personal_Info_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Personal_Info_Views_Avg_Fields = {
  __typename?: 'mobile_personal_info_views_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "personal_info_views". All fields are combined with a logical 'AND'. */
export type Mobile_Personal_Info_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Personal_Info_Views_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Personal_Info_Views_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  personalInfosByBirthdayViewId?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
  personalInfosByBirthdayViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Bool_Exp>;
  personalInfosByGenderViewId?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
  personalInfosByGenderViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Bool_Exp>;
  personalInfosByHeightViewId?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
  personalInfosByHeightViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Bool_Exp>;
  personalInfosByWeightViewId?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
  personalInfosByWeightViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "personal_info_views" */
export enum Mobile_Personal_Info_Views_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonalInfoViewsPkey = 'personal_info_views_pkey'
}

/** input type for incrementing numeric columns in table "personal_info_views" */
export type Mobile_Personal_Info_Views_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "personal_info_views" */
export type Mobile_Personal_Info_Views_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  personalInfosByBirthdayViewId?: InputMaybe<Mobile_Personal_Infos_Arr_Rel_Insert_Input>;
  personalInfosByGenderViewId?: InputMaybe<Mobile_Personal_Infos_Arr_Rel_Insert_Input>;
  personalInfosByHeightViewId?: InputMaybe<Mobile_Personal_Infos_Arr_Rel_Insert_Input>;
  personalInfosByWeightViewId?: InputMaybe<Mobile_Personal_Infos_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Personal_Info_Views_Max_Fields = {
  __typename?: 'mobile_personal_info_views_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Personal_Info_Views_Min_Fields = {
  __typename?: 'mobile_personal_info_views_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "personal_info_views" */
export type Mobile_Personal_Info_Views_Mutation_Response = {
  __typename?: 'mobile_personal_info_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Personal_Info_Views>;
};

/** input type for inserting object relation for remote table "personal_info_views" */
export type Mobile_Personal_Info_Views_Obj_Rel_Insert_Input = {
  data: Mobile_Personal_Info_Views_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Personal_Info_Views_On_Conflict>;
};

/** on_conflict condition type for table "personal_info_views" */
export type Mobile_Personal_Info_Views_On_Conflict = {
  constraint: Mobile_Personal_Info_Views_Constraint;
  update_columns?: Array<Mobile_Personal_Info_Views_Update_Column>;
  where?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "personal_info_views". */
export type Mobile_Personal_Info_Views_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  personalInfosByBirthdayViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Order_By>;
  personalInfosByGenderViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Order_By>;
  personalInfosByHeightViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Order_By>;
  personalInfosByWeightViewId_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: personal_info_views */
export type Mobile_Personal_Info_Views_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "personal_info_views" */
export enum Mobile_Personal_Info_Views_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "personal_info_views" */
export type Mobile_Personal_Info_Views_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Personal_Info_Views_Stddev_Fields = {
  __typename?: 'mobile_personal_info_views_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Personal_Info_Views_Stddev_Pop_Fields = {
  __typename?: 'mobile_personal_info_views_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Personal_Info_Views_Stddev_Samp_Fields = {
  __typename?: 'mobile_personal_info_views_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_personal_info_views" */
export type Mobile_Personal_Info_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Personal_Info_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Personal_Info_Views_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Personal_Info_Views_Sum_Fields = {
  __typename?: 'mobile_personal_info_views_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "personal_info_views" */
export enum Mobile_Personal_Info_Views_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Personal_Info_Views_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Personal_Info_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Personal_Info_Views_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Personal_Info_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Personal_Info_Views_Var_Pop_Fields = {
  __typename?: 'mobile_personal_info_views_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Personal_Info_Views_Var_Samp_Fields = {
  __typename?: 'mobile_personal_info_views_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Personal_Info_Views_Variance_Fields = {
  __typename?: 'mobile_personal_info_views_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "personal_infos" */
export type Mobile_Personal_Infos = {
  __typename?: 'mobile_personal_infos';
  birthday?: Maybe<Scalars['date']['output']>;
  birthday_view_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  gender?: Maybe<Scalars['Int']['output']>;
  gender_view_id?: Maybe<Scalars['bigint']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_view_id?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  personalInfoViewByBirthdayViewId?: Maybe<Mobile_Personal_Info_Views>;
  /** An object relationship */
  personalInfoViewByGenderViewId?: Maybe<Mobile_Personal_Info_Views>;
  /** An object relationship */
  personalInfoViewByHeightViewId?: Maybe<Mobile_Personal_Info_Views>;
  /** An object relationship */
  personalInfoViewByWeightViewId?: Maybe<Mobile_Personal_Info_Views>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
  weight_view_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "personal_infos" */
export type Mobile_Personal_Infos_Aggregate = {
  __typename?: 'mobile_personal_infos_aggregate';
  aggregate?: Maybe<Mobile_Personal_Infos_Aggregate_Fields>;
  nodes: Array<Mobile_Personal_Infos>;
};

export type Mobile_Personal_Infos_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Personal_Infos_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Personal_Infos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "personal_infos" */
export type Mobile_Personal_Infos_Aggregate_Fields = {
  __typename?: 'mobile_personal_infos_aggregate_fields';
  avg?: Maybe<Mobile_Personal_Infos_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Personal_Infos_Max_Fields>;
  min?: Maybe<Mobile_Personal_Infos_Min_Fields>;
  stddev?: Maybe<Mobile_Personal_Infos_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Personal_Infos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Personal_Infos_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Personal_Infos_Sum_Fields>;
  var_pop?: Maybe<Mobile_Personal_Infos_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Personal_Infos_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Personal_Infos_Variance_Fields>;
};


/** aggregate fields of "personal_infos" */
export type Mobile_Personal_Infos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "personal_infos" */
export type Mobile_Personal_Infos_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Personal_Infos_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Personal_Infos_Max_Order_By>;
  min?: InputMaybe<Mobile_Personal_Infos_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Personal_Infos_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Personal_Infos_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Personal_Infos_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Personal_Infos_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Personal_Infos_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Personal_Infos_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Personal_Infos_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "personal_infos" */
export type Mobile_Personal_Infos_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Personal_Infos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Personal_Infos_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Personal_Infos_Avg_Fields = {
  __typename?: 'mobile_personal_infos_avg_fields';
  birthday_view_id?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  gender_view_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_view_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_view_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Avg_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "personal_infos". All fields are combined with a logical 'AND'. */
export type Mobile_Personal_Infos_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Personal_Infos_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Personal_Infos_Bool_Exp>>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  birthday_view_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gender?: InputMaybe<Int_Comparison_Exp>;
  gender_view_id?: InputMaybe<Bigint_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  height_view_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  personalInfoViewByBirthdayViewId?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
  personalInfoViewByGenderViewId?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
  personalInfoViewByHeightViewId?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
  personalInfoViewByWeightViewId?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  weight?: InputMaybe<Int_Comparison_Exp>;
  weight_view_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "personal_infos" */
export enum Mobile_Personal_Infos_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonalInfosPkey = 'personal_infos_pkey'
}

/** input type for incrementing numeric columns in table "personal_infos" */
export type Mobile_Personal_Infos_Inc_Input = {
  birthday_view_id?: InputMaybe<Scalars['bigint']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  gender_view_id?: InputMaybe<Scalars['bigint']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_view_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  weight_view_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "personal_infos" */
export type Mobile_Personal_Infos_Insert_Input = {
  birthday?: InputMaybe<Scalars['date']['input']>;
  birthday_view_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  gender_view_id?: InputMaybe<Scalars['bigint']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_view_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  personalInfoViewByBirthdayViewId?: InputMaybe<Mobile_Personal_Info_Views_Obj_Rel_Insert_Input>;
  personalInfoViewByGenderViewId?: InputMaybe<Mobile_Personal_Info_Views_Obj_Rel_Insert_Input>;
  personalInfoViewByHeightViewId?: InputMaybe<Mobile_Personal_Info_Views_Obj_Rel_Insert_Input>;
  personalInfoViewByWeightViewId?: InputMaybe<Mobile_Personal_Info_Views_Obj_Rel_Insert_Input>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  weight_view_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Personal_Infos_Max_Fields = {
  __typename?: 'mobile_personal_infos_max_fields';
  birthday?: Maybe<Scalars['date']['output']>;
  birthday_view_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  gender_view_id?: Maybe<Scalars['bigint']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_view_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
  weight_view_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Max_Order_By = {
  birthday?: InputMaybe<Order_By>;
  birthday_view_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Personal_Infos_Min_Fields = {
  __typename?: 'mobile_personal_infos_min_fields';
  birthday?: Maybe<Scalars['date']['output']>;
  birthday_view_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  gender_view_id?: Maybe<Scalars['bigint']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_view_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
  weight_view_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Min_Order_By = {
  birthday?: InputMaybe<Order_By>;
  birthday_view_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "personal_infos" */
export type Mobile_Personal_Infos_Mutation_Response = {
  __typename?: 'mobile_personal_infos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Personal_Infos>;
};

/** on_conflict condition type for table "personal_infos" */
export type Mobile_Personal_Infos_On_Conflict = {
  constraint: Mobile_Personal_Infos_Constraint;
  update_columns?: Array<Mobile_Personal_Infos_Update_Column>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};

/** Ordering options when selecting data from "personal_infos". */
export type Mobile_Personal_Infos_Order_By = {
  birthday?: InputMaybe<Order_By>;
  birthday_view_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  personalInfoViewByBirthdayViewId?: InputMaybe<Mobile_Personal_Info_Views_Order_By>;
  personalInfoViewByGenderViewId?: InputMaybe<Mobile_Personal_Info_Views_Order_By>;
  personalInfoViewByHeightViewId?: InputMaybe<Mobile_Personal_Info_Views_Order_By>;
  personalInfoViewByWeightViewId?: InputMaybe<Mobile_Personal_Info_Views_Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: personal_infos */
export type Mobile_Personal_Infos_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "personal_infos" */
export enum Mobile_Personal_Infos_Select_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  BirthdayViewId = 'birthday_view_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gender = 'gender',
  /** column name */
  GenderViewId = 'gender_view_id',
  /** column name */
  Height = 'height',
  /** column name */
  HeightViewId = 'height_view_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Weight = 'weight',
  /** column name */
  WeightViewId = 'weight_view_id'
}

/** input type for updating data in table "personal_infos" */
export type Mobile_Personal_Infos_Set_Input = {
  birthday?: InputMaybe<Scalars['date']['input']>;
  birthday_view_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  gender_view_id?: InputMaybe<Scalars['bigint']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_view_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  weight_view_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Personal_Infos_Stddev_Fields = {
  __typename?: 'mobile_personal_infos_stddev_fields';
  birthday_view_id?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  gender_view_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_view_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_view_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Stddev_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Personal_Infos_Stddev_Pop_Fields = {
  __typename?: 'mobile_personal_infos_stddev_pop_fields';
  birthday_view_id?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  gender_view_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_view_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_view_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Stddev_Pop_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Personal_Infos_Stddev_Samp_Fields = {
  __typename?: 'mobile_personal_infos_stddev_samp_fields';
  birthday_view_id?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  gender_view_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_view_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_view_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Stddev_Samp_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_personal_infos" */
export type Mobile_Personal_Infos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Personal_Infos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Personal_Infos_Stream_Cursor_Value_Input = {
  birthday?: InputMaybe<Scalars['date']['input']>;
  birthday_view_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  gender_view_id?: InputMaybe<Scalars['bigint']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_view_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  weight_view_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Personal_Infos_Sum_Fields = {
  __typename?: 'mobile_personal_infos_sum_fields';
  birthday_view_id?: Maybe<Scalars['bigint']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  gender_view_id?: Maybe<Scalars['bigint']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  height_view_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
  weight_view_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Sum_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** update columns of table "personal_infos" */
export enum Mobile_Personal_Infos_Update_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  BirthdayViewId = 'birthday_view_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Gender = 'gender',
  /** column name */
  GenderViewId = 'gender_view_id',
  /** column name */
  Height = 'height',
  /** column name */
  HeightViewId = 'height_view_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Weight = 'weight',
  /** column name */
  WeightViewId = 'weight_view_id'
}

export type Mobile_Personal_Infos_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Personal_Infos_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Personal_Infos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Personal_Infos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Personal_Infos_Var_Pop_Fields = {
  __typename?: 'mobile_personal_infos_var_pop_fields';
  birthday_view_id?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  gender_view_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_view_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_view_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Var_Pop_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Personal_Infos_Var_Samp_Fields = {
  __typename?: 'mobile_personal_infos_var_samp_fields';
  birthday_view_id?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  gender_view_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_view_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_view_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Var_Samp_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Personal_Infos_Variance_Fields = {
  __typename?: 'mobile_personal_infos_variance_fields';
  birthday_view_id?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  gender_view_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  height_view_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_view_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "personal_infos" */
export type Mobile_Personal_Infos_Variance_Order_By = {
  birthday_view_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  gender_view_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  height_view_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  weight_view_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "phones" */
export type Mobile_Phones = {
  __typename?: 'mobile_phones';
  /** An object relationship */
  contact: Mobile_Contacts;
  contact_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  is_present?: Maybe<Scalars['Boolean']['output']>;
  phone: Scalars['String']['output'];
  /** An object relationship */
  type_phone: Mobile_Type_Phones;
  type_phone_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "phones" */
export type Mobile_Phones_Aggregate = {
  __typename?: 'mobile_phones_aggregate';
  aggregate?: Maybe<Mobile_Phones_Aggregate_Fields>;
  nodes: Array<Mobile_Phones>;
};

export type Mobile_Phones_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Phones_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Phones_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Phones_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Phones_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Phones_Select_Column_Mobile_Phones_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Phones_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Phones_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Phones_Select_Column_Mobile_Phones_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Phones_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Phones_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Phones_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "phones" */
export type Mobile_Phones_Aggregate_Fields = {
  __typename?: 'mobile_phones_aggregate_fields';
  avg?: Maybe<Mobile_Phones_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Phones_Max_Fields>;
  min?: Maybe<Mobile_Phones_Min_Fields>;
  stddev?: Maybe<Mobile_Phones_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Phones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Phones_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Phones_Sum_Fields>;
  var_pop?: Maybe<Mobile_Phones_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Phones_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Phones_Variance_Fields>;
};


/** aggregate fields of "phones" */
export type Mobile_Phones_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "phones" */
export type Mobile_Phones_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Phones_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Phones_Max_Order_By>;
  min?: InputMaybe<Mobile_Phones_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Phones_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Phones_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Phones_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Phones_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Phones_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Phones_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Phones_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "phones" */
export type Mobile_Phones_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Phones_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Phones_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Phones_Avg_Fields = {
  __typename?: 'mobile_phones_avg_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_phone_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "phones" */
export type Mobile_Phones_Avg_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "phones". All fields are combined with a logical 'AND'. */
export type Mobile_Phones_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Phones_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Phones_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Phones_Bool_Exp>>;
  contact?: InputMaybe<Mobile_Contacts_Bool_Exp>;
  contact_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_present?: InputMaybe<Boolean_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  type_phone?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
  type_phone_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "phones" */
export enum Mobile_Phones_Constraint {
  /** unique or primary key constraint on columns "id" */
  PhonesPkey = 'phones_pkey'
}

/** input type for incrementing numeric columns in table "phones" */
export type Mobile_Phones_Inc_Input = {
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_phone_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "phones" */
export type Mobile_Phones_Insert_Input = {
  contact?: InputMaybe<Mobile_Contacts_Obj_Rel_Insert_Input>;
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_present?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type_phone?: InputMaybe<Mobile_Type_Phones_Obj_Rel_Insert_Input>;
  type_phone_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Phones_Max_Fields = {
  __typename?: 'mobile_phones_max_fields';
  contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  type_phone_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "phones" */
export type Mobile_Phones_Max_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Phones_Min_Fields = {
  __typename?: 'mobile_phones_min_fields';
  contact_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  type_phone_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "phones" */
export type Mobile_Phones_Min_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "phones" */
export type Mobile_Phones_Mutation_Response = {
  __typename?: 'mobile_phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Phones>;
};

/** on_conflict condition type for table "phones" */
export type Mobile_Phones_On_Conflict = {
  constraint: Mobile_Phones_Constraint;
  update_columns?: Array<Mobile_Phones_Update_Column>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};

/** Ordering options when selecting data from "phones". */
export type Mobile_Phones_Order_By = {
  contact?: InputMaybe<Mobile_Contacts_Order_By>;
  contact_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_present?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  type_phone?: InputMaybe<Mobile_Type_Phones_Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: phones */
export type Mobile_Phones_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "phones" */
export enum Mobile_Phones_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPresent = 'is_present',
  /** column name */
  Phone = 'phone',
  /** column name */
  TypePhoneId = 'type_phone_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_phones_aggregate_bool_exp_bool_and_arguments_columns" columns of table "phones" */
export enum Mobile_Phones_Select_Column_Mobile_Phones_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPresent = 'is_present'
}

/** select "mobile_phones_aggregate_bool_exp_bool_or_arguments_columns" columns of table "phones" */
export enum Mobile_Phones_Select_Column_Mobile_Phones_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPresent = 'is_present'
}

/** input type for updating data in table "phones" */
export type Mobile_Phones_Set_Input = {
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_present?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type_phone_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Phones_Stddev_Fields = {
  __typename?: 'mobile_phones_stddev_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_phone_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "phones" */
export type Mobile_Phones_Stddev_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Phones_Stddev_Pop_Fields = {
  __typename?: 'mobile_phones_stddev_pop_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_phone_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "phones" */
export type Mobile_Phones_Stddev_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Phones_Stddev_Samp_Fields = {
  __typename?: 'mobile_phones_stddev_samp_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_phone_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "phones" */
export type Mobile_Phones_Stddev_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_phones" */
export type Mobile_Phones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Phones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Phones_Stream_Cursor_Value_Input = {
  contact_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_present?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type_phone_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Phones_Sum_Fields = {
  __typename?: 'mobile_phones_sum_fields';
  contact_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  type_phone_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "phones" */
export type Mobile_Phones_Sum_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "phones" */
export enum Mobile_Phones_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPresent = 'is_present',
  /** column name */
  Phone = 'phone',
  /** column name */
  TypePhoneId = 'type_phone_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Phones_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Phones_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Phones_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Phones_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Phones_Var_Pop_Fields = {
  __typename?: 'mobile_phones_var_pop_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_phone_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "phones" */
export type Mobile_Phones_Var_Pop_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Phones_Var_Samp_Fields = {
  __typename?: 'mobile_phones_var_samp_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_phone_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "phones" */
export type Mobile_Phones_Var_Samp_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Phones_Variance_Fields = {
  __typename?: 'mobile_phones_variance_fields';
  contact_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  type_phone_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "phones" */
export type Mobile_Phones_Variance_Order_By = {
  contact_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_phone_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "post_params" */
export type Mobile_Post_Params = {
  __typename?: 'mobile_post_params';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  metric: Mobile_Metrics;
  metric_id: Scalars['bigint']['output'];
  /** An object relationship */
  param: Mobile_Params;
  param_id: Scalars['bigint']['output'];
  /** An object relationship */
  post: Mobile_Posts;
  post_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "post_params" */
export type Mobile_Post_Params_Aggregate = {
  __typename?: 'mobile_post_params_aggregate';
  aggregate?: Maybe<Mobile_Post_Params_Aggregate_Fields>;
  nodes: Array<Mobile_Post_Params>;
};

export type Mobile_Post_Params_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Post_Params_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Post_Params_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "post_params" */
export type Mobile_Post_Params_Aggregate_Fields = {
  __typename?: 'mobile_post_params_aggregate_fields';
  avg?: Maybe<Mobile_Post_Params_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Post_Params_Max_Fields>;
  min?: Maybe<Mobile_Post_Params_Min_Fields>;
  stddev?: Maybe<Mobile_Post_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Post_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Post_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Post_Params_Sum_Fields>;
  var_pop?: Maybe<Mobile_Post_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Post_Params_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Post_Params_Variance_Fields>;
};


/** aggregate fields of "post_params" */
export type Mobile_Post_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "post_params" */
export type Mobile_Post_Params_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Post_Params_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Post_Params_Max_Order_By>;
  min?: InputMaybe<Mobile_Post_Params_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Post_Params_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Post_Params_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Post_Params_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Post_Params_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Post_Params_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Post_Params_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Post_Params_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "post_params" */
export type Mobile_Post_Params_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Post_Params_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Post_Params_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Post_Params_Avg_Fields = {
  __typename?: 'mobile_post_params_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "post_params" */
export type Mobile_Post_Params_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_params". All fields are combined with a logical 'AND'. */
export type Mobile_Post_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Post_Params_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Post_Params_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  metric?: InputMaybe<Mobile_Metrics_Bool_Exp>;
  metric_id?: InputMaybe<Bigint_Comparison_Exp>;
  param?: InputMaybe<Mobile_Params_Bool_Exp>;
  param_id?: InputMaybe<Bigint_Comparison_Exp>;
  post?: InputMaybe<Mobile_Posts_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_params" */
export enum Mobile_Post_Params_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostParamsPkey = 'post_params_pkey'
}

/** input type for incrementing numeric columns in table "post_params" */
export type Mobile_Post_Params_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "post_params" */
export type Mobile_Post_Params_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric?: InputMaybe<Mobile_Metrics_Obj_Rel_Insert_Input>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param?: InputMaybe<Mobile_Params_Obj_Rel_Insert_Input>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  post?: InputMaybe<Mobile_Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Post_Params_Max_Fields = {
  __typename?: 'mobile_post_params_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "post_params" */
export type Mobile_Post_Params_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Post_Params_Min_Fields = {
  __typename?: 'mobile_post_params_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "post_params" */
export type Mobile_Post_Params_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_params" */
export type Mobile_Post_Params_Mutation_Response = {
  __typename?: 'mobile_post_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Post_Params>;
};

/** on_conflict condition type for table "post_params" */
export type Mobile_Post_Params_On_Conflict = {
  constraint: Mobile_Post_Params_Constraint;
  update_columns?: Array<Mobile_Post_Params_Update_Column>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "post_params". */
export type Mobile_Post_Params_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric?: InputMaybe<Mobile_Metrics_Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param?: InputMaybe<Mobile_Params_Order_By>;
  param_id?: InputMaybe<Order_By>;
  post?: InputMaybe<Mobile_Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_params */
export type Mobile_Post_Params_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "post_params" */
export enum Mobile_Post_Params_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "post_params" */
export type Mobile_Post_Params_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Post_Params_Stddev_Fields = {
  __typename?: 'mobile_post_params_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "post_params" */
export type Mobile_Post_Params_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Post_Params_Stddev_Pop_Fields = {
  __typename?: 'mobile_post_params_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "post_params" */
export type Mobile_Post_Params_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Post_Params_Stddev_Samp_Fields = {
  __typename?: 'mobile_post_params_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "post_params" */
export type Mobile_Post_Params_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_post_params" */
export type Mobile_Post_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Post_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Post_Params_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  metric_id?: InputMaybe<Scalars['bigint']['input']>;
  param_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Post_Params_Sum_Fields = {
  __typename?: 'mobile_post_params_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  metric_id?: Maybe<Scalars['bigint']['output']>;
  param_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "post_params" */
export type Mobile_Post_Params_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "post_params" */
export enum Mobile_Post_Params_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetricId = 'metric_id',
  /** column name */
  ParamId = 'param_id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Post_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Post_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Post_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Post_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Post_Params_Var_Pop_Fields = {
  __typename?: 'mobile_post_params_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "post_params" */
export type Mobile_Post_Params_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Post_Params_Var_Samp_Fields = {
  __typename?: 'mobile_post_params_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "post_params" */
export type Mobile_Post_Params_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Post_Params_Variance_Fields = {
  __typename?: 'mobile_post_params_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  metric_id?: Maybe<Scalars['Float']['output']>;
  param_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "post_params" */
export type Mobile_Post_Params_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_id?: InputMaybe<Order_By>;
  param_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "posts" */
export type Mobile_Posts = {
  __typename?: 'mobile_posts';
  /** An array relationship */
  comments: Array<Mobile_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Mobile_Comments_Aggregate;
  /** An object relationship */
  content?: Maybe<Mobile_Contents>;
  content_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['bigint']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  likes: Array<Mobile_Likes>;
  /** An aggregate relationship */
  likes_aggregate: Mobile_Likes_Aggregate;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  post_params: Array<Mobile_Post_Params>;
  /** An aggregate relationship */
  post_params_aggregate: Mobile_Post_Params_Aggregate;
  /** An array relationship */
  post_profile_lockeds: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  post_profile_lockeds_aggregate: Mobile_Profile_Locks_Aggregate;
  /** An array relationship */
  post_profile_lockings: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  post_profile_lockings_aggregate: Mobile_Profile_Locks_Aggregate;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  share_posts: Array<Mobile_Share_Posts>;
  /** An aggregate relationship */
  share_posts_aggregate: Mobile_Share_Posts_Aggregate;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
  video_sound?: Maybe<Scalars['Boolean']['output']>;
  visible: Scalars['Boolean']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsLikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsPost_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsPost_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsPost_Profile_LockedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsPost_Profile_Lockeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsPost_Profile_LockingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsPost_Profile_Lockings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsShare_PostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type Mobile_PostsShare_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};

/** aggregated selection of "posts" */
export type Mobile_Posts_Aggregate = {
  __typename?: 'mobile_posts_aggregate';
  aggregate?: Maybe<Mobile_Posts_Aggregate_Fields>;
  nodes: Array<Mobile_Posts>;
};

export type Mobile_Posts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Posts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Posts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Posts_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Posts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Posts_Select_Column_Mobile_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Posts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Posts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Posts_Select_Column_Mobile_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Posts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Posts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Posts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "posts" */
export type Mobile_Posts_Aggregate_Fields = {
  __typename?: 'mobile_posts_aggregate_fields';
  avg?: Maybe<Mobile_Posts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Posts_Max_Fields>;
  min?: Maybe<Mobile_Posts_Min_Fields>;
  stddev?: Maybe<Mobile_Posts_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Posts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Posts_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Posts_Sum_Fields>;
  var_pop?: Maybe<Mobile_Posts_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Posts_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Posts_Variance_Fields>;
};


/** aggregate fields of "posts" */
export type Mobile_Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "posts" */
export type Mobile_Posts_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Posts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Posts_Max_Order_By>;
  min?: InputMaybe<Mobile_Posts_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Posts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Posts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Posts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Posts_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Posts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Posts_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Posts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "posts" */
export type Mobile_Posts_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Posts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Posts_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Posts_Avg_Fields = {
  __typename?: 'mobile_posts_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "posts" */
export type Mobile_Posts_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Mobile_Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Posts_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Posts_Bool_Exp>>;
  comments?: InputMaybe<Mobile_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Bool_Exp>;
  content?: InputMaybe<Mobile_Contents_Bool_Exp>;
  content_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  file_preview_path?: InputMaybe<String_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  likes?: InputMaybe<Mobile_Likes_Bool_Exp>;
  likes_aggregate?: InputMaybe<Mobile_Likes_Aggregate_Bool_Exp>;
  locked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  post_params?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Bool_Exp>;
  post_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  post_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  post_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  post_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  share_posts?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
  share_posts_aggregate?: InputMaybe<Mobile_Share_Posts_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  video_duration?: InputMaybe<Int_Comparison_Exp>;
  video_sound?: InputMaybe<Boolean_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
  width?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "posts" */
export enum Mobile_Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPkey = 'posts_pkey'
}

/** input type for incrementing numeric columns in table "posts" */
export type Mobile_Posts_Inc_Input = {
  content_id?: InputMaybe<Scalars['bigint']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "posts" */
export type Mobile_Posts_Insert_Input = {
  comments?: InputMaybe<Mobile_Comments_Arr_Rel_Insert_Input>;
  content?: InputMaybe<Mobile_Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  likes?: InputMaybe<Mobile_Likes_Arr_Rel_Insert_Input>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  post_params?: InputMaybe<Mobile_Post_Params_Arr_Rel_Insert_Input>;
  post_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Arr_Rel_Insert_Input>;
  post_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  share_posts?: InputMaybe<Mobile_Share_Posts_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
  video_sound?: InputMaybe<Scalars['Boolean']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Posts_Max_Fields = {
  __typename?: 'mobile_posts_max_fields';
  content_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "posts" */
export type Mobile_Posts_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Posts_Min_Fields = {
  __typename?: 'mobile_posts_min_fields';
  content_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "posts" */
export type Mobile_Posts_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts" */
export type Mobile_Posts_Mutation_Response = {
  __typename?: 'mobile_posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Posts>;
};

/** input type for inserting object relation for remote table "posts" */
export type Mobile_Posts_Obj_Rel_Insert_Input = {
  data: Mobile_Posts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Posts_On_Conflict>;
};

/** on_conflict condition type for table "posts" */
export type Mobile_Posts_On_Conflict = {
  constraint: Mobile_Posts_Constraint;
  update_columns?: Array<Mobile_Posts_Update_Column>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "posts". */
export type Mobile_Posts_Order_By = {
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Order_By>;
  content?: InputMaybe<Mobile_Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  likes_aggregate?: InputMaybe<Mobile_Likes_Aggregate_Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Order_By>;
  post_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  post_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  share_posts_aggregate?: InputMaybe<Mobile_Share_Posts_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  video_sound?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** primary key columns input for table: posts */
export type Mobile_Posts_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "posts" */
export enum Mobile_Posts_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FilePreviewPath = 'file_preview_path',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VideoDuration = 'video_duration',
  /** column name */
  VideoSound = 'video_sound',
  /** column name */
  Visible = 'visible',
  /** column name */
  Width = 'width'
}

/** select "mobile_posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "posts" */
export enum Mobile_Posts_Select_Column_Mobile_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  VideoSound = 'video_sound',
  /** column name */
  Visible = 'visible'
}

/** select "mobile_posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "posts" */
export enum Mobile_Posts_Select_Column_Mobile_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  VideoSound = 'video_sound',
  /** column name */
  Visible = 'visible'
}

/** columns and relationships of "posts_services" */
export type Mobile_Posts_Services = {
  __typename?: 'mobile_posts_services';
  /** An array relationship */
  auto_replies: Array<Mobile_Auto_Replies>;
  /** An aggregate relationship */
  auto_replies_aggregate: Mobile_Auto_Replies_Aggregate;
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  comments: Array<Mobile_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Mobile_Comments_Aggregate;
  /** An object relationship */
  content?: Maybe<Mobile_Contents>;
  content_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  likes: Array<Mobile_Likes>;
  /** An aggregate relationship */
  likes_aggregate: Mobile_Likes_Aggregate;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  params_services: Array<Mobile_Params_Services>;
  /** An aggregate relationship */
  params_services_aggregate: Mobile_Params_Services_Aggregate;
  /** An array relationship */
  post_params: Array<Mobile_Post_Params>;
  /** An aggregate relationship */
  post_params_aggregate: Mobile_Post_Params_Aggregate;
  /** An array relationship */
  post_profile_lockeds: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  post_profile_lockeds_aggregate: Mobile_Profile_Locks_Aggregate;
  /** An array relationship */
  post_profile_lockings: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  post_profile_lockings_aggregate: Mobile_Profile_Locks_Aggregate;
  price?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  service_documents: Array<Mobile_Service_Documents>;
  /** An aggregate relationship */
  service_documents_aggregate: Mobile_Service_Documents_Aggregate;
  /** An array relationship */
  service_favorites: Array<Mobile_Service_Favorites>;
  /** An aggregate relationship */
  service_favorites_aggregate: Mobile_Service_Favorites_Aggregate;
  /** An array relationship */
  service_images: Array<Mobile_Service_Images>;
  /** An aggregate relationship */
  service_images_aggregate: Mobile_Service_Images_Aggregate;
  /** An array relationship */
  service_likes: Array<Mobile_Service_Likes>;
  /** An aggregate relationship */
  service_likes_aggregate: Mobile_Service_Likes_Aggregate;
  /** An array relationship */
  service_purchases: Array<Mobile_Service_Purchases>;
  /** An aggregate relationship */
  service_purchases_aggregate: Mobile_Service_Purchases_Aggregate;
  /** An array relationship */
  service_questions: Array<Mobile_Service_Questions>;
  /** An aggregate relationship */
  service_questions_aggregate: Mobile_Service_Questions_Aggregate;
  /** An array relationship */
  service_rates: Array<Mobile_Service_Rates>;
  /** An aggregate relationship */
  service_rates_aggregate: Mobile_Service_Rates_Aggregate;
  /** An object relationship */
  service_status?: Maybe<Mobile_Service_Statuses>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  share_posts: Array<Mobile_Share_Posts>;
  /** An aggregate relationship */
  share_posts_aggregate: Mobile_Share_Posts_Aggregate;
  source?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  video_duration?: Maybe<Scalars['Boolean']['output']>;
  video_sound?: Maybe<Scalars['Boolean']['output']>;
  visible?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesAuto_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesAuto_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesCommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesLikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesParams_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesParams_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesPost_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesPost_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesPost_Profile_LockedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesPost_Profile_Lockeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesPost_Profile_LockingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesPost_Profile_Lockings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_FavoritesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_Favorites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_RatesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesService_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesShare_PostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};


/** columns and relationships of "posts_services" */
export type Mobile_Posts_ServicesShare_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};

/** aggregated selection of "posts_services" */
export type Mobile_Posts_Services_Aggregate = {
  __typename?: 'mobile_posts_services_aggregate';
  aggregate?: Maybe<Mobile_Posts_Services_Aggregate_Fields>;
  nodes: Array<Mobile_Posts_Services>;
};

/** aggregate fields of "posts_services" */
export type Mobile_Posts_Services_Aggregate_Fields = {
  __typename?: 'mobile_posts_services_aggregate_fields';
  avg?: Maybe<Mobile_Posts_Services_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Posts_Services_Max_Fields>;
  min?: Maybe<Mobile_Posts_Services_Min_Fields>;
  stddev?: Maybe<Mobile_Posts_Services_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Posts_Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Posts_Services_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Posts_Services_Sum_Fields>;
  var_pop?: Maybe<Mobile_Posts_Services_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Posts_Services_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Posts_Services_Variance_Fields>;
};


/** aggregate fields of "posts_services" */
export type Mobile_Posts_Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Posts_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Posts_Services_Avg_Fields = {
  __typename?: 'mobile_posts_services_avg_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "posts_services". All fields are combined with a logical 'AND'. */
export type Mobile_Posts_Services_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Posts_Services_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Posts_Services_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Posts_Services_Bool_Exp>>;
  auto_replies?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
  auto_replies_aggregate?: InputMaybe<Mobile_Auto_Replies_Aggregate_Bool_Exp>;
  category_profession_id?: InputMaybe<Bigint_Comparison_Exp>;
  comments?: InputMaybe<Mobile_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Bool_Exp>;
  content?: InputMaybe<Mobile_Contents_Bool_Exp>;
  content_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  file_preview_path?: InputMaybe<String_Comparison_Exp>;
  height?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  likes?: InputMaybe<Mobile_Likes_Bool_Exp>;
  likes_aggregate?: InputMaybe<Mobile_Likes_Aggregate_Bool_Exp>;
  locked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  params_services?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Bool_Exp>;
  post_params?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Bool_Exp>;
  post_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  post_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  post_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  post_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  rate?: InputMaybe<Numeric_Comparison_Exp>;
  service_documents?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
  service_documents_aggregate?: InputMaybe<Mobile_Service_Documents_Aggregate_Bool_Exp>;
  service_favorites?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
  service_favorites_aggregate?: InputMaybe<Mobile_Service_Favorites_Aggregate_Bool_Exp>;
  service_images?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
  service_images_aggregate?: InputMaybe<Mobile_Service_Images_Aggregate_Bool_Exp>;
  service_likes?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
  service_likes_aggregate?: InputMaybe<Mobile_Service_Likes_Aggregate_Bool_Exp>;
  service_purchases?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
  service_purchases_aggregate?: InputMaybe<Mobile_Service_Purchases_Aggregate_Bool_Exp>;
  service_questions?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
  service_questions_aggregate?: InputMaybe<Mobile_Service_Questions_Aggregate_Bool_Exp>;
  service_rates?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
  service_rates_aggregate?: InputMaybe<Mobile_Service_Rates_Aggregate_Bool_Exp>;
  service_status?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
  service_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  share_posts?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
  share_posts_aggregate?: InputMaybe<Mobile_Share_Posts_Aggregate_Bool_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  video_duration?: InputMaybe<Boolean_Comparison_Exp>;
  video_sound?: InputMaybe<Boolean_Comparison_Exp>;
  visible?: InputMaybe<Int_Comparison_Exp>;
  width?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Mobile_Posts_Services_Max_Fields = {
  __typename?: 'mobile_posts_services_max_fields';
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  content_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  visible?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mobile_Posts_Services_Min_Fields = {
  __typename?: 'mobile_posts_services_min_fields';
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  content_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  visible?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "posts_services". */
export type Mobile_Posts_Services_Order_By = {
  auto_replies_aggregate?: InputMaybe<Mobile_Auto_Replies_Aggregate_Order_By>;
  category_profession_id?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Order_By>;
  content?: InputMaybe<Mobile_Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  likes_aggregate?: InputMaybe<Mobile_Likes_Aggregate_Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Order_By>;
  post_params_aggregate?: InputMaybe<Mobile_Post_Params_Aggregate_Order_By>;
  post_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  post_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_documents_aggregate?: InputMaybe<Mobile_Service_Documents_Aggregate_Order_By>;
  service_favorites_aggregate?: InputMaybe<Mobile_Service_Favorites_Aggregate_Order_By>;
  service_images_aggregate?: InputMaybe<Mobile_Service_Images_Aggregate_Order_By>;
  service_likes_aggregate?: InputMaybe<Mobile_Service_Likes_Aggregate_Order_By>;
  service_purchases_aggregate?: InputMaybe<Mobile_Service_Purchases_Aggregate_Order_By>;
  service_questions_aggregate?: InputMaybe<Mobile_Service_Questions_Aggregate_Order_By>;
  service_rates_aggregate?: InputMaybe<Mobile_Service_Rates_Aggregate_Order_By>;
  service_status?: InputMaybe<Mobile_Service_Statuses_Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  share_posts_aggregate?: InputMaybe<Mobile_Share_Posts_Aggregate_Order_By>;
  source?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  video_sound?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** select columns of table "posts_services" */
export enum Mobile_Posts_Services_Select_Column {
  /** column name */
  CategoryProfessionId = 'category_profession_id',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FilePreviewPath = 'file_preview_path',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  Price = 'price',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Rate = 'rate',
  /** column name */
  ServiceStatusId = 'service_status_id',
  /** column name */
  ServiceTypeId = 'service_type_id',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VideoDuration = 'video_duration',
  /** column name */
  VideoSound = 'video_sound',
  /** column name */
  Visible = 'visible',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Mobile_Posts_Services_Stddev_Fields = {
  __typename?: 'mobile_posts_services_stddev_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Posts_Services_Stddev_Pop_Fields = {
  __typename?: 'mobile_posts_services_stddev_pop_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Posts_Services_Stddev_Samp_Fields = {
  __typename?: 'mobile_posts_services_stddev_samp_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_posts_services" */
export type Mobile_Posts_Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Posts_Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Posts_Services_Stream_Cursor_Value_Input = {
  category_profession_id?: InputMaybe<Scalars['bigint']['input']>;
  content_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  service_status_id?: InputMaybe<Scalars['bigint']['input']>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  video_duration?: InputMaybe<Scalars['Boolean']['input']>;
  video_sound?: InputMaybe<Scalars['Boolean']['input']>;
  visible?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Posts_Services_Sum_Fields = {
  __typename?: 'mobile_posts_services_sum_fields';
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  content_id?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  visible?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Mobile_Posts_Services_Var_Pop_Fields = {
  __typename?: 'mobile_posts_services_var_pop_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Posts_Services_Var_Samp_Fields = {
  __typename?: 'mobile_posts_services_var_samp_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Posts_Services_Variance_Fields = {
  __typename?: 'mobile_posts_services_variance_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  visible?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** input type for updating data in table "posts" */
export type Mobile_Posts_Set_Input = {
  content_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
  video_sound?: InputMaybe<Scalars['Boolean']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Posts_Stddev_Fields = {
  __typename?: 'mobile_posts_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "posts" */
export type Mobile_Posts_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Posts_Stddev_Pop_Fields = {
  __typename?: 'mobile_posts_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "posts" */
export type Mobile_Posts_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Posts_Stddev_Samp_Fields = {
  __typename?: 'mobile_posts_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "posts" */
export type Mobile_Posts_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_posts" */
export type Mobile_Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Posts_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  video_duration?: InputMaybe<Scalars['Int']['input']>;
  video_sound?: InputMaybe<Scalars['Boolean']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Posts_Sum_Fields = {
  __typename?: 'mobile_posts_sum_fields';
  content_id?: Maybe<Scalars['bigint']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  video_duration?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "posts" */
export type Mobile_Posts_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** update columns of table "posts" */
export enum Mobile_Posts_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FilePreviewPath = 'file_preview_path',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VideoDuration = 'video_duration',
  /** column name */
  VideoSound = 'video_sound',
  /** column name */
  Visible = 'visible',
  /** column name */
  Width = 'width'
}

export type Mobile_Posts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Posts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Posts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Posts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Posts_Var_Pop_Fields = {
  __typename?: 'mobile_posts_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "posts" */
export type Mobile_Posts_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Posts_Var_Samp_Fields = {
  __typename?: 'mobile_posts_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "posts" */
export type Mobile_Posts_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Posts_Variance_Fields = {
  __typename?: 'mobile_posts_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  video_duration?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "posts" */
export type Mobile_Posts_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  height?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_duration?: InputMaybe<Order_By>;
  width?: InputMaybe<Order_By>;
};

/** columns and relationships of "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses = {
  __typename?: 'mobile_pro_document_statuses';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Aggregate = {
  __typename?: 'mobile_pro_document_statuses_aggregate';
  aggregate?: Maybe<Mobile_Pro_Document_Statuses_Aggregate_Fields>;
  nodes: Array<Mobile_Pro_Document_Statuses>;
};

/** aggregate fields of "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Aggregate_Fields = {
  __typename?: 'mobile_pro_document_statuses_aggregate_fields';
  avg?: Maybe<Mobile_Pro_Document_Statuses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Pro_Document_Statuses_Max_Fields>;
  min?: Maybe<Mobile_Pro_Document_Statuses_Min_Fields>;
  stddev?: Maybe<Mobile_Pro_Document_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Pro_Document_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Pro_Document_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Pro_Document_Statuses_Sum_Fields>;
  var_pop?: Maybe<Mobile_Pro_Document_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Pro_Document_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Pro_Document_Statuses_Variance_Fields>;
};


/** aggregate fields of "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Pro_Document_Statuses_Avg_Fields = {
  __typename?: 'mobile_pro_document_statuses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "pro_document_statuses". All fields are combined with a logical 'AND'. */
export type Mobile_Pro_Document_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "pro_document_statuses" */
export enum Mobile_Pro_Document_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProDocumentStatusesPkey = 'pro_document_statuses_pkey'
}

/** input type for incrementing numeric columns in table "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Pro_Document_Statuses_Max_Fields = {
  __typename?: 'mobile_pro_document_statuses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Pro_Document_Statuses_Min_Fields = {
  __typename?: 'mobile_pro_document_statuses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Mutation_Response = {
  __typename?: 'mobile_pro_document_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Pro_Document_Statuses>;
};

/** input type for inserting object relation for remote table "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Obj_Rel_Insert_Input = {
  data: Mobile_Pro_Document_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Pro_Document_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_On_Conflict = {
  constraint: Mobile_Pro_Document_Statuses_Constraint;
  update_columns?: Array<Mobile_Pro_Document_Statuses_Update_Column>;
  where?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "pro_document_statuses". */
export type Mobile_Pro_Document_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pro_document_statuses */
export type Mobile_Pro_Document_Statuses_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "pro_document_statuses" */
export enum Mobile_Pro_Document_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Pro_Document_Statuses_Stddev_Fields = {
  __typename?: 'mobile_pro_document_statuses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Pro_Document_Statuses_Stddev_Pop_Fields = {
  __typename?: 'mobile_pro_document_statuses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Pro_Document_Statuses_Stddev_Samp_Fields = {
  __typename?: 'mobile_pro_document_statuses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_pro_document_statuses" */
export type Mobile_Pro_Document_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Pro_Document_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Pro_Document_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Pro_Document_Statuses_Sum_Fields = {
  __typename?: 'mobile_pro_document_statuses_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "pro_document_statuses" */
export enum Mobile_Pro_Document_Statuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Pro_Document_Statuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Pro_Document_Statuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Pro_Document_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Pro_Document_Statuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Pro_Document_Statuses_Var_Pop_Fields = {
  __typename?: 'mobile_pro_document_statuses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Pro_Document_Statuses_Var_Samp_Fields = {
  __typename?: 'mobile_pro_document_statuses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Pro_Document_Statuses_Variance_Fields = {
  __typename?: 'mobile_pro_document_statuses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "pro_professions" */
export type Mobile_Pro_Professions = {
  __typename?: 'mobile_pro_professions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  pro_id?: Maybe<Scalars['bigint']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "pro_professions" */
export type Mobile_Pro_Professions_Aggregate = {
  __typename?: 'mobile_pro_professions_aggregate';
  aggregate?: Maybe<Mobile_Pro_Professions_Aggregate_Fields>;
  nodes: Array<Mobile_Pro_Professions>;
};

/** aggregate fields of "pro_professions" */
export type Mobile_Pro_Professions_Aggregate_Fields = {
  __typename?: 'mobile_pro_professions_aggregate_fields';
  avg?: Maybe<Mobile_Pro_Professions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Pro_Professions_Max_Fields>;
  min?: Maybe<Mobile_Pro_Professions_Min_Fields>;
  stddev?: Maybe<Mobile_Pro_Professions_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Pro_Professions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Pro_Professions_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Pro_Professions_Sum_Fields>;
  var_pop?: Maybe<Mobile_Pro_Professions_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Pro_Professions_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Pro_Professions_Variance_Fields>;
};


/** aggregate fields of "pro_professions" */
export type Mobile_Pro_Professions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Pro_Professions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Pro_Professions_Avg_Fields = {
  __typename?: 'mobile_pro_professions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "pro_professions". All fields are combined with a logical 'AND'. */
export type Mobile_Pro_Professions_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Pro_Professions_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Pro_Professions_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Pro_Professions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  profession_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "pro_professions" */
export enum Mobile_Pro_Professions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProProfessionsPkey = 'pro_professions_pkey'
}

/** input type for incrementing numeric columns in table "pro_professions" */
export type Mobile_Pro_Professions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "pro_professions" */
export type Mobile_Pro_Professions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Pro_Professions_Max_Fields = {
  __typename?: 'mobile_pro_professions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Pro_Professions_Min_Fields = {
  __typename?: 'mobile_pro_professions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "pro_professions" */
export type Mobile_Pro_Professions_Mutation_Response = {
  __typename?: 'mobile_pro_professions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Pro_Professions>;
};

/** on_conflict condition type for table "pro_professions" */
export type Mobile_Pro_Professions_On_Conflict = {
  constraint: Mobile_Pro_Professions_Constraint;
  update_columns?: Array<Mobile_Pro_Professions_Update_Column>;
  where?: InputMaybe<Mobile_Pro_Professions_Bool_Exp>;
};

/** Ordering options when selecting data from "pro_professions". */
export type Mobile_Pro_Professions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pro_professions */
export type Mobile_Pro_Professions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "pro_professions" */
export enum Mobile_Pro_Professions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  ProfessionId = 'profession_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pro_professions" */
export type Mobile_Pro_Professions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Pro_Professions_Stddev_Fields = {
  __typename?: 'mobile_pro_professions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Pro_Professions_Stddev_Pop_Fields = {
  __typename?: 'mobile_pro_professions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Pro_Professions_Stddev_Samp_Fields = {
  __typename?: 'mobile_pro_professions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_pro_professions" */
export type Mobile_Pro_Professions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Pro_Professions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Pro_Professions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Pro_Professions_Sum_Fields = {
  __typename?: 'mobile_pro_professions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "pro_professions" */
export enum Mobile_Pro_Professions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  ProfessionId = 'profession_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Pro_Professions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Pro_Professions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Pro_Professions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Pro_Professions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Pro_Professions_Var_Pop_Fields = {
  __typename?: 'mobile_pro_professions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Pro_Professions_Var_Samp_Fields = {
  __typename?: 'mobile_pro_professions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Pro_Professions_Variance_Fields = {
  __typename?: 'mobile_pro_professions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "pro_types" */
export type Mobile_Pro_Types = {
  __typename?: 'mobile_pro_types';
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** aggregated selection of "pro_types" */
export type Mobile_Pro_Types_Aggregate = {
  __typename?: 'mobile_pro_types_aggregate';
  aggregate?: Maybe<Mobile_Pro_Types_Aggregate_Fields>;
  nodes: Array<Mobile_Pro_Types>;
};

/** aggregate fields of "pro_types" */
export type Mobile_Pro_Types_Aggregate_Fields = {
  __typename?: 'mobile_pro_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Pro_Types_Max_Fields>;
  min?: Maybe<Mobile_Pro_Types_Min_Fields>;
};


/** aggregate fields of "pro_types" */
export type Mobile_Pro_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Pro_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "pro_types". All fields are combined with a logical 'AND'. */
export type Mobile_Pro_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Pro_Types_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Pro_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Pro_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pro_types" */
export enum Mobile_Pro_Types_Constraint {
  /** unique or primary key constraint on columns "title" */
  ProTypesPkey = 'pro_types_pkey'
}

/** input type for inserting data into table "pro_types" */
export type Mobile_Pro_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Pro_Types_Max_Fields = {
  __typename?: 'mobile_pro_types_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Pro_Types_Min_Fields = {
  __typename?: 'mobile_pro_types_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "pro_types" */
export type Mobile_Pro_Types_Mutation_Response = {
  __typename?: 'mobile_pro_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Pro_Types>;
};

/** on_conflict condition type for table "pro_types" */
export type Mobile_Pro_Types_On_Conflict = {
  constraint: Mobile_Pro_Types_Constraint;
  update_columns?: Array<Mobile_Pro_Types_Update_Column>;
  where?: InputMaybe<Mobile_Pro_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "pro_types". */
export type Mobile_Pro_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pro_types */
export type Mobile_Pro_Types_Pk_Columns_Input = {
  title: Scalars['String']['input'];
};

/** select columns of table "pro_types" */
export enum Mobile_Pro_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "pro_types" */
export type Mobile_Pro_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "mobile_pro_types" */
export type Mobile_Pro_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Pro_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Pro_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "pro_types" */
export enum Mobile_Pro_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Title = 'title'
}

export type Mobile_Pro_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Pro_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Pro_Types_Bool_Exp;
};

/** columns and relationships of "problems" */
export type Mobile_Problems = {
  __typename?: 'mobile_problems';
  /** An array relationship */
  auto_reply_documents: Array<Mobile_Auto_Reply_Documents>;
  /** An aggregate relationship */
  auto_reply_documents_aggregate: Mobile_Auto_Reply_Documents_Aggregate;
  /** An array relationship */
  auto_reply_mediafiles: Array<Mobile_Auto_Reply_Mediafiles>;
  /** An aggregate relationship */
  auto_reply_mediafiles_aggregate: Mobile_Auto_Reply_Mediafiles_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  service_documents: Array<Mobile_Service_Documents>;
  /** An aggregate relationship */
  service_documents_aggregate: Mobile_Service_Documents_Aggregate;
  /** An array relationship */
  service_images: Array<Mobile_Service_Images>;
  /** An aggregate relationship */
  service_images_aggregate: Mobile_Service_Images_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  upload_pro_documents: Array<Mobile_Upload_Pro_Documents>;
  /** An aggregate relationship */
  upload_pro_documents_aggregate: Mobile_Upload_Pro_Documents_Aggregate;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsAuto_Reply_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsAuto_Reply_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsAuto_Reply_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsAuto_Reply_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsService_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsService_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsService_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsService_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsUpload_Pro_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};


/** columns and relationships of "problems" */
export type Mobile_ProblemsUpload_Pro_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};

/** aggregated selection of "problems" */
export type Mobile_Problems_Aggregate = {
  __typename?: 'mobile_problems_aggregate';
  aggregate?: Maybe<Mobile_Problems_Aggregate_Fields>;
  nodes: Array<Mobile_Problems>;
};

/** aggregate fields of "problems" */
export type Mobile_Problems_Aggregate_Fields = {
  __typename?: 'mobile_problems_aggregate_fields';
  avg?: Maybe<Mobile_Problems_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Problems_Max_Fields>;
  min?: Maybe<Mobile_Problems_Min_Fields>;
  stddev?: Maybe<Mobile_Problems_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Problems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Problems_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Problems_Sum_Fields>;
  var_pop?: Maybe<Mobile_Problems_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Problems_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Problems_Variance_Fields>;
};


/** aggregate fields of "problems" */
export type Mobile_Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Problems_Avg_Fields = {
  __typename?: 'mobile_problems_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "problems". All fields are combined with a logical 'AND'. */
export type Mobile_Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Problems_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Problems_Bool_Exp>>;
  auto_reply_documents?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
  auto_reply_documents_aggregate?: InputMaybe<Mobile_Auto_Reply_Documents_Aggregate_Bool_Exp>;
  auto_reply_mediafiles?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
  auto_reply_mediafiles_aggregate?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  service_documents?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
  service_documents_aggregate?: InputMaybe<Mobile_Service_Documents_Aggregate_Bool_Exp>;
  service_images?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
  service_images_aggregate?: InputMaybe<Mobile_Service_Images_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_pro_documents?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
  upload_pro_documents_aggregate?: InputMaybe<Mobile_Upload_Pro_Documents_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "problems" */
export enum Mobile_Problems_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProblemsPkey = 'problems_pkey'
}

/** input type for incrementing numeric columns in table "problems" */
export type Mobile_Problems_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "problems" */
export type Mobile_Problems_Insert_Input = {
  auto_reply_documents?: InputMaybe<Mobile_Auto_Reply_Documents_Arr_Rel_Insert_Input>;
  auto_reply_mediafiles?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  service_documents?: InputMaybe<Mobile_Service_Documents_Arr_Rel_Insert_Input>;
  service_images?: InputMaybe<Mobile_Service_Images_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload_pro_documents?: InputMaybe<Mobile_Upload_Pro_Documents_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mobile_Problems_Max_Fields = {
  __typename?: 'mobile_problems_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Problems_Min_Fields = {
  __typename?: 'mobile_problems_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "problems" */
export type Mobile_Problems_Mutation_Response = {
  __typename?: 'mobile_problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Problems>;
};

/** input type for inserting object relation for remote table "problems" */
export type Mobile_Problems_Obj_Rel_Insert_Input = {
  data: Mobile_Problems_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Problems_On_Conflict>;
};

/** on_conflict condition type for table "problems" */
export type Mobile_Problems_On_Conflict = {
  constraint: Mobile_Problems_Constraint;
  update_columns?: Array<Mobile_Problems_Update_Column>;
  where?: InputMaybe<Mobile_Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "problems". */
export type Mobile_Problems_Order_By = {
  auto_reply_documents_aggregate?: InputMaybe<Mobile_Auto_Reply_Documents_Aggregate_Order_By>;
  auto_reply_mediafiles_aggregate?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  service_documents_aggregate?: InputMaybe<Mobile_Service_Documents_Aggregate_Order_By>;
  service_images_aggregate?: InputMaybe<Mobile_Service_Images_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_pro_documents_aggregate?: InputMaybe<Mobile_Upload_Pro_Documents_Aggregate_Order_By>;
};

/** primary key columns input for table: problems */
export type Mobile_Problems_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "problems" */
export enum Mobile_Problems_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "problems" */
export type Mobile_Problems_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Problems_Stddev_Fields = {
  __typename?: 'mobile_problems_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Problems_Stddev_Pop_Fields = {
  __typename?: 'mobile_problems_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Problems_Stddev_Samp_Fields = {
  __typename?: 'mobile_problems_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_problems" */
export type Mobile_Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Problems_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Problems_Sum_Fields = {
  __typename?: 'mobile_problems_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "problems" */
export enum Mobile_Problems_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Problems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Problems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Problems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Problems_Var_Pop_Fields = {
  __typename?: 'mobile_problems_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Problems_Var_Samp_Fields = {
  __typename?: 'mobile_problems_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Problems_Variance_Fields = {
  __typename?: 'mobile_problems_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "profession_names" */
export type Mobile_Profession_Names = {
  __typename?: 'mobile_profession_names';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  profession: Mobile_Professions;
  profession_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "profession_names" */
export type Mobile_Profession_Names_Aggregate = {
  __typename?: 'mobile_profession_names_aggregate';
  aggregate?: Maybe<Mobile_Profession_Names_Aggregate_Fields>;
  nodes: Array<Mobile_Profession_Names>;
};

export type Mobile_Profession_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Profession_Names_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Profession_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "profession_names" */
export type Mobile_Profession_Names_Aggregate_Fields = {
  __typename?: 'mobile_profession_names_aggregate_fields';
  avg?: Maybe<Mobile_Profession_Names_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Profession_Names_Max_Fields>;
  min?: Maybe<Mobile_Profession_Names_Min_Fields>;
  stddev?: Maybe<Mobile_Profession_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Profession_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Profession_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Profession_Names_Sum_Fields>;
  var_pop?: Maybe<Mobile_Profession_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Profession_Names_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Profession_Names_Variance_Fields>;
};


/** aggregate fields of "profession_names" */
export type Mobile_Profession_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "profession_names" */
export type Mobile_Profession_Names_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Profession_Names_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Profession_Names_Max_Order_By>;
  min?: InputMaybe<Mobile_Profession_Names_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Profession_Names_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Profession_Names_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Profession_Names_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Profession_Names_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Profession_Names_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Profession_Names_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Profession_Names_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profession_names" */
export type Mobile_Profession_Names_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Profession_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Profession_Names_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Profession_Names_Avg_Fields = {
  __typename?: 'mobile_profession_names_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "profession_names" */
export type Mobile_Profession_Names_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profession_names". All fields are combined with a logical 'AND'. */
export type Mobile_Profession_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Profession_Names_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Profession_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profession?: InputMaybe<Mobile_Professions_Bool_Exp>;
  profession_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "profession_names" */
export enum Mobile_Profession_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfessionNamesPkey = 'profession_names_pkey'
}

/** input type for incrementing numeric columns in table "profession_names" */
export type Mobile_Profession_Names_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "profession_names" */
export type Mobile_Profession_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profession?: InputMaybe<Mobile_Professions_Obj_Rel_Insert_Input>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Profession_Names_Max_Fields = {
  __typename?: 'mobile_profession_names_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "profession_names" */
export type Mobile_Profession_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Profession_Names_Min_Fields = {
  __typename?: 'mobile_profession_names_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "profession_names" */
export type Mobile_Profession_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profession_names" */
export type Mobile_Profession_Names_Mutation_Response = {
  __typename?: 'mobile_profession_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Profession_Names>;
};

/** on_conflict condition type for table "profession_names" */
export type Mobile_Profession_Names_On_Conflict = {
  constraint: Mobile_Profession_Names_Constraint;
  update_columns?: Array<Mobile_Profession_Names_Update_Column>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "profession_names". */
export type Mobile_Profession_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profession?: InputMaybe<Mobile_Professions_Order_By>;
  profession_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profession_names */
export type Mobile_Profession_Names_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "profession_names" */
export enum Mobile_Profession_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ProfessionId = 'profession_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profession_names" */
export type Mobile_Profession_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Profession_Names_Stddev_Fields = {
  __typename?: 'mobile_profession_names_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "profession_names" */
export type Mobile_Profession_Names_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Profession_Names_Stddev_Pop_Fields = {
  __typename?: 'mobile_profession_names_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "profession_names" */
export type Mobile_Profession_Names_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Profession_Names_Stddev_Samp_Fields = {
  __typename?: 'mobile_profession_names_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "profession_names" */
export type Mobile_Profession_Names_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_profession_names" */
export type Mobile_Profession_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Profession_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Profession_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Profession_Names_Sum_Fields = {
  __typename?: 'mobile_profession_names_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "profession_names" */
export type Mobile_Profession_Names_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** update columns of table "profession_names" */
export enum Mobile_Profession_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ProfessionId = 'profession_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Profession_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Profession_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Profession_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Profession_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Profession_Names_Var_Pop_Fields = {
  __typename?: 'mobile_profession_names_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "profession_names" */
export type Mobile_Profession_Names_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Profession_Names_Var_Samp_Fields = {
  __typename?: 'mobile_profession_names_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "profession_names" */
export type Mobile_Profession_Names_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Profession_Names_Variance_Fields = {
  __typename?: 'mobile_profession_names_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "profession_names" */
export type Mobile_Profession_Names_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "professions" */
export type Mobile_Professions = {
  __typename?: 'mobile_professions';
  /** An object relationship */
  category?: Maybe<Mobile_Categories>;
  category_id?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  profession_names: Array<Mobile_Profession_Names>;
  /** An aggregate relationship */
  profession_names_aggregate: Mobile_Profession_Names_Aggregate;
  /** An array relationship */
  pros: Array<Mobile_Pros>;
  /** An aggregate relationship */
  pros_aggregate: Mobile_Pros_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "professions" */
export type Mobile_ProfessionsProfession_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profession_Names_Order_By>>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};


/** columns and relationships of "professions" */
export type Mobile_ProfessionsProfession_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profession_Names_Order_By>>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};


/** columns and relationships of "professions" */
export type Mobile_ProfessionsProsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pros_Order_By>>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};


/** columns and relationships of "professions" */
export type Mobile_ProfessionsPros_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pros_Order_By>>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};

/** aggregated selection of "professions" */
export type Mobile_Professions_Aggregate = {
  __typename?: 'mobile_professions_aggregate';
  aggregate?: Maybe<Mobile_Professions_Aggregate_Fields>;
  nodes: Array<Mobile_Professions>;
};

export type Mobile_Professions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Professions_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Professions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Professions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "professions" */
export type Mobile_Professions_Aggregate_Fields = {
  __typename?: 'mobile_professions_aggregate_fields';
  avg?: Maybe<Mobile_Professions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Professions_Max_Fields>;
  min?: Maybe<Mobile_Professions_Min_Fields>;
  stddev?: Maybe<Mobile_Professions_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Professions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Professions_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Professions_Sum_Fields>;
  var_pop?: Maybe<Mobile_Professions_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Professions_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Professions_Variance_Fields>;
};


/** aggregate fields of "professions" */
export type Mobile_Professions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "professions" */
export type Mobile_Professions_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Professions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Professions_Max_Order_By>;
  min?: InputMaybe<Mobile_Professions_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Professions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Professions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Professions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Professions_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Professions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Professions_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Professions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "professions" */
export type Mobile_Professions_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Professions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Professions_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Professions_Avg_Fields = {
  __typename?: 'mobile_professions_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "professions" */
export type Mobile_Professions_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "professions". All fields are combined with a logical 'AND'. */
export type Mobile_Professions_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Professions_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Professions_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Professions_Bool_Exp>>;
  category?: InputMaybe<Mobile_Categories_Bool_Exp>;
  category_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profession_names?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
  profession_names_aggregate?: InputMaybe<Mobile_Profession_Names_Aggregate_Bool_Exp>;
  pros?: InputMaybe<Mobile_Pros_Bool_Exp>;
  pros_aggregate?: InputMaybe<Mobile_Pros_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "professions" */
export enum Mobile_Professions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfessionsPkey = 'professions_pkey'
}

/** input type for incrementing numeric columns in table "professions" */
export type Mobile_Professions_Inc_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "professions" */
export type Mobile_Professions_Insert_Input = {
  category?: InputMaybe<Mobile_Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profession_names?: InputMaybe<Mobile_Profession_Names_Arr_Rel_Insert_Input>;
  pros?: InputMaybe<Mobile_Pros_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Professions_Max_Fields = {
  __typename?: 'mobile_professions_max_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "professions" */
export type Mobile_Professions_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Professions_Min_Fields = {
  __typename?: 'mobile_professions_min_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "professions" */
export type Mobile_Professions_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "professions" */
export type Mobile_Professions_Mutation_Response = {
  __typename?: 'mobile_professions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Professions>;
};

/** input type for inserting object relation for remote table "professions" */
export type Mobile_Professions_Obj_Rel_Insert_Input = {
  data: Mobile_Professions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Professions_On_Conflict>;
};

/** on_conflict condition type for table "professions" */
export type Mobile_Professions_On_Conflict = {
  constraint: Mobile_Professions_Constraint;
  update_columns?: Array<Mobile_Professions_Update_Column>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};

/** Ordering options when selecting data from "professions". */
export type Mobile_Professions_Order_By = {
  category?: InputMaybe<Mobile_Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profession_names_aggregate?: InputMaybe<Mobile_Profession_Names_Aggregate_Order_By>;
  pros_aggregate?: InputMaybe<Mobile_Pros_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: professions */
export type Mobile_Professions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "professions" */
export enum Mobile_Professions_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "professions" */
export type Mobile_Professions_Set_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Professions_Stddev_Fields = {
  __typename?: 'mobile_professions_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "professions" */
export type Mobile_Professions_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Professions_Stddev_Pop_Fields = {
  __typename?: 'mobile_professions_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "professions" */
export type Mobile_Professions_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Professions_Stddev_Samp_Fields = {
  __typename?: 'mobile_professions_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "professions" */
export type Mobile_Professions_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_professions" */
export type Mobile_Professions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Professions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Professions_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Professions_Sum_Fields = {
  __typename?: 'mobile_professions_sum_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "professions" */
export type Mobile_Professions_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** update columns of table "professions" */
export enum Mobile_Professions_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status'
}

export type Mobile_Professions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Professions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Professions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Professions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Professions_Var_Pop_Fields = {
  __typename?: 'mobile_professions_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "professions" */
export type Mobile_Professions_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Professions_Var_Samp_Fields = {
  __typename?: 'mobile_professions_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "professions" */
export type Mobile_Professions_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Professions_Variance_Fields = {
  __typename?: 'mobile_professions_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "professions" */
export type Mobile_Professions_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** columns and relationships of "profile_deleteds" */
export type Mobile_Profile_Deleteds = {
  __typename?: 'mobile_profile_deleteds';
  created_at: Scalars['timestamptz']['output'];
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  deletion_is_hard?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  recovery_date?: Maybe<Scalars['timestamp']['output']>;
  restore_until_date?: Maybe<Scalars['timestamp']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "profile_deleteds" */
export type Mobile_Profile_Deleteds_Aggregate = {
  __typename?: 'mobile_profile_deleteds_aggregate';
  aggregate?: Maybe<Mobile_Profile_Deleteds_Aggregate_Fields>;
  nodes: Array<Mobile_Profile_Deleteds>;
};

/** aggregate fields of "profile_deleteds" */
export type Mobile_Profile_Deleteds_Aggregate_Fields = {
  __typename?: 'mobile_profile_deleteds_aggregate_fields';
  avg?: Maybe<Mobile_Profile_Deleteds_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Profile_Deleteds_Max_Fields>;
  min?: Maybe<Mobile_Profile_Deleteds_Min_Fields>;
  stddev?: Maybe<Mobile_Profile_Deleteds_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Profile_Deleteds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Profile_Deleteds_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Profile_Deleteds_Sum_Fields>;
  var_pop?: Maybe<Mobile_Profile_Deleteds_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Profile_Deleteds_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Profile_Deleteds_Variance_Fields>;
};


/** aggregate fields of "profile_deleteds" */
export type Mobile_Profile_Deleteds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Profile_Deleteds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Profile_Deleteds_Avg_Fields = {
  __typename?: 'mobile_profile_deleteds_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "profile_deleteds". All fields are combined with a logical 'AND'. */
export type Mobile_Profile_Deleteds_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Profile_Deleteds_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Profile_Deleteds_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Profile_Deleteds_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletion_date?: InputMaybe<Timestamp_Comparison_Exp>;
  deletion_is_hard?: InputMaybe<Boolean_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  recovery_date?: InputMaybe<Timestamp_Comparison_Exp>;
  restore_until_date?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_deleteds" */
export enum Mobile_Profile_Deleteds_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileDeletedsPkey = 'profile_deleteds_pkey'
}

/** input type for incrementing numeric columns in table "profile_deleteds" */
export type Mobile_Profile_Deleteds_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "profile_deleteds" */
export type Mobile_Profile_Deleteds_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  deletion_is_hard?: InputMaybe<Scalars['Boolean']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  recovery_date?: InputMaybe<Scalars['timestamp']['input']>;
  restore_until_date?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Profile_Deleteds_Max_Fields = {
  __typename?: 'mobile_profile_deleteds_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  recovery_date?: Maybe<Scalars['timestamp']['output']>;
  restore_until_date?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Profile_Deleteds_Min_Fields = {
  __typename?: 'mobile_profile_deleteds_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  recovery_date?: Maybe<Scalars['timestamp']['output']>;
  restore_until_date?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "profile_deleteds" */
export type Mobile_Profile_Deleteds_Mutation_Response = {
  __typename?: 'mobile_profile_deleteds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Profile_Deleteds>;
};

/** on_conflict condition type for table "profile_deleteds" */
export type Mobile_Profile_Deleteds_On_Conflict = {
  constraint: Mobile_Profile_Deleteds_Constraint;
  update_columns?: Array<Mobile_Profile_Deleteds_Update_Column>;
  where?: InputMaybe<Mobile_Profile_Deleteds_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_deleteds". */
export type Mobile_Profile_Deleteds_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  deletion_is_hard?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  recovery_date?: InputMaybe<Order_By>;
  restore_until_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile_deleteds */
export type Mobile_Profile_Deleteds_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "profile_deleteds" */
export enum Mobile_Profile_Deleteds_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletionDate = 'deletion_date',
  /** column name */
  DeletionIsHard = 'deletion_is_hard',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RecoveryDate = 'recovery_date',
  /** column name */
  RestoreUntilDate = 'restore_until_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "profile_deleteds" */
export type Mobile_Profile_Deleteds_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  deletion_is_hard?: InputMaybe<Scalars['Boolean']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  recovery_date?: InputMaybe<Scalars['timestamp']['input']>;
  restore_until_date?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Profile_Deleteds_Stddev_Fields = {
  __typename?: 'mobile_profile_deleteds_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Profile_Deleteds_Stddev_Pop_Fields = {
  __typename?: 'mobile_profile_deleteds_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Profile_Deleteds_Stddev_Samp_Fields = {
  __typename?: 'mobile_profile_deleteds_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_profile_deleteds" */
export type Mobile_Profile_Deleteds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Profile_Deleteds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Profile_Deleteds_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  deletion_is_hard?: InputMaybe<Scalars['Boolean']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  recovery_date?: InputMaybe<Scalars['timestamp']['input']>;
  restore_until_date?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Profile_Deleteds_Sum_Fields = {
  __typename?: 'mobile_profile_deleteds_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "profile_deleteds" */
export enum Mobile_Profile_Deleteds_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletionDate = 'deletion_date',
  /** column name */
  DeletionIsHard = 'deletion_is_hard',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RecoveryDate = 'recovery_date',
  /** column name */
  RestoreUntilDate = 'restore_until_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

export type Mobile_Profile_Deleteds_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Profile_Deleteds_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Profile_Deleteds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Profile_Deleteds_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Profile_Deleteds_Var_Pop_Fields = {
  __typename?: 'mobile_profile_deleteds_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Profile_Deleteds_Var_Samp_Fields = {
  __typename?: 'mobile_profile_deleteds_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Profile_Deleteds_Variance_Fields = {
  __typename?: 'mobile_profile_deleteds_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "profile_locks" */
export type Mobile_Profile_Locks = {
  __typename?: 'mobile_profile_locks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  locked_user_id: Scalars['bigint']['output'];
  /** An object relationship */
  post_profile_lockeds?: Maybe<Mobile_Profile_Locks>;
  /** An object relationship */
  post_profile_lockings?: Maybe<Mobile_Profile_Locks>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "profile_locks" */
export type Mobile_Profile_Locks_Aggregate = {
  __typename?: 'mobile_profile_locks_aggregate';
  aggregate?: Maybe<Mobile_Profile_Locks_Aggregate_Fields>;
  nodes: Array<Mobile_Profile_Locks>;
};

export type Mobile_Profile_Locks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Profile_Locks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "profile_locks" */
export type Mobile_Profile_Locks_Aggregate_Fields = {
  __typename?: 'mobile_profile_locks_aggregate_fields';
  avg?: Maybe<Mobile_Profile_Locks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Profile_Locks_Max_Fields>;
  min?: Maybe<Mobile_Profile_Locks_Min_Fields>;
  stddev?: Maybe<Mobile_Profile_Locks_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Profile_Locks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Profile_Locks_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Profile_Locks_Sum_Fields>;
  var_pop?: Maybe<Mobile_Profile_Locks_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Profile_Locks_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Profile_Locks_Variance_Fields>;
};


/** aggregate fields of "profile_locks" */
export type Mobile_Profile_Locks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "profile_locks" */
export type Mobile_Profile_Locks_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Profile_Locks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Profile_Locks_Max_Order_By>;
  min?: InputMaybe<Mobile_Profile_Locks_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Profile_Locks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Profile_Locks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Profile_Locks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Profile_Locks_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Profile_Locks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Profile_Locks_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Profile_Locks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profile_locks" */
export type Mobile_Profile_Locks_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Profile_Locks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Profile_Locks_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Profile_Locks_Avg_Fields = {
  __typename?: 'mobile_profile_locks_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  locked_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile_locks". All fields are combined with a logical 'AND'. */
export type Mobile_Profile_Locks_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Profile_Locks_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Profile_Locks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  locked_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  post_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  post_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_locks" */
export enum Mobile_Profile_Locks_Constraint {
  /** unique or primary key constraint on columns "locked_user_id", "user_id" */
  IndexProfileLocksOnUserIdAndLockedUserId = 'index_profile_locks_on_user_id_and_locked_user_id',
  /** unique or primary key constraint on columns "id" */
  ProfileLocksPkey = 'profile_locks_pkey'
}

/** input type for incrementing numeric columns in table "profile_locks" */
export type Mobile_Profile_Locks_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "profile_locks" */
export type Mobile_Profile_Locks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_user_id?: InputMaybe<Scalars['bigint']['input']>;
  post_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Obj_Rel_Insert_Input>;
  post_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Profile_Locks_Max_Fields = {
  __typename?: 'mobile_profile_locks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_user_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Profile_Locks_Min_Fields = {
  __typename?: 'mobile_profile_locks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_user_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profile_locks" */
export type Mobile_Profile_Locks_Mutation_Response = {
  __typename?: 'mobile_profile_locks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Profile_Locks>;
};

/** input type for inserting object relation for remote table "profile_locks" */
export type Mobile_Profile_Locks_Obj_Rel_Insert_Input = {
  data: Mobile_Profile_Locks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Profile_Locks_On_Conflict>;
};

/** on_conflict condition type for table "profile_locks" */
export type Mobile_Profile_Locks_On_Conflict = {
  constraint: Mobile_Profile_Locks_Constraint;
  update_columns?: Array<Mobile_Profile_Locks_Update_Column>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_locks". */
export type Mobile_Profile_Locks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  post_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Order_By>;
  post_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile_locks */
export type Mobile_Profile_Locks_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "profile_locks" */
export enum Mobile_Profile_Locks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedUserId = 'locked_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "profile_locks" */
export type Mobile_Profile_Locks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_user_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Profile_Locks_Stddev_Fields = {
  __typename?: 'mobile_profile_locks_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  locked_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Profile_Locks_Stddev_Pop_Fields = {
  __typename?: 'mobile_profile_locks_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  locked_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Profile_Locks_Stddev_Samp_Fields = {
  __typename?: 'mobile_profile_locks_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  locked_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_profile_locks" */
export type Mobile_Profile_Locks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Profile_Locks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Profile_Locks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_user_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Profile_Locks_Sum_Fields = {
  __typename?: 'mobile_profile_locks_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  locked_user_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "profile_locks" */
export enum Mobile_Profile_Locks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedUserId = 'locked_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Profile_Locks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Profile_Locks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Profile_Locks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Profile_Locks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Profile_Locks_Var_Pop_Fields = {
  __typename?: 'mobile_profile_locks_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  locked_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Profile_Locks_Var_Samp_Fields = {
  __typename?: 'mobile_profile_locks_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  locked_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Profile_Locks_Variance_Fields = {
  __typename?: 'mobile_profile_locks_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  locked_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "profile_locks" */
export type Mobile_Profile_Locks_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  locked_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "profiles" */
export type Mobile_Profiles = {
  __typename?: 'mobile_profiles';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  appointments: Array<Mobile_Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Mobile_Appointments_Aggregate;
  avatar_path?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  chat_messages: Array<Mobile_Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Mobile_Chat_Messages_Aggregate;
  /** An array relationship */
  comment_likes: Array<Mobile_Comment_Likes>;
  /** An aggregate relationship */
  comment_likes_aggregate: Mobile_Comment_Likes_Aggregate;
  /** An array relationship */
  comments: Array<Mobile_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Mobile_Comments_Aggregate;
  /** An array relationship */
  confirmation_codes: Array<Mobile_Confirmation_Codes>;
  /** An aggregate relationship */
  confirmation_codes_aggregate: Mobile_Confirmation_Codes_Aggregate;
  /** An array relationship */
  contacts: Array<Mobile_Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Mobile_Contacts_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  orgs: Array<Mobile_Orgs>;
  /** An aggregate relationship */
  orgs_aggregate: Mobile_Orgs_Aggregate;
  /** An array relationship */
  personal_infos: Array<Mobile_Personal_Infos>;
  /** An aggregate relationship */
  personal_infos_aggregate: Mobile_Personal_Infos_Aggregate;
  /** An array relationship */
  posts: Array<Mobile_Posts>;
  /** An aggregate relationship */
  posts_aggregate: Mobile_Posts_Aggregate;
  /** An object relationship */
  pro?: Maybe<Mobile_Pros>;
  profile_link?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  service_purchases: Array<Mobile_Service_Purchases>;
  /** An aggregate relationship */
  service_purchases_aggregate: Mobile_Service_Purchases_Aggregate;
  /** An array relationship */
  services: Array<Mobile_Services>;
  /** An aggregate relationship */
  services_aggregate: Mobile_Services_Aggregate;
  /** An array relationship */
  settings: Array<Mobile_Settings>;
  /** An aggregate relationship */
  settings_aggregate: Mobile_Settings_Aggregate;
  status?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  subscribers: Array<Mobile_Subscribes>;
  /** An aggregate relationship */
  subscribers_aggregate: Mobile_Subscribes_Aggregate;
  /** An array relationship */
  subscriptions: Array<Mobile_Subscribes>;
  /** An aggregate relationship */
  subscriptions_aggregate: Mobile_Subscribes_Aggregate;
  /** An array relationship */
  support_messages: Array<Mobile_Support_Messages>;
  /** An aggregate relationship */
  support_messages_aggregate: Mobile_Support_Messages_Aggregate;
  /** An object relationship */
  type_profile?: Maybe<Mobile_Type_Profiles>;
  type_profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_chats: Array<Mobile_User_Chats>;
  /** An aggregate relationship */
  user_chats_aggregate: Mobile_User_Chats_Aggregate;
  user_id: Scalars['bigint']['output'];
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['bigint']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesComment_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesComment_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesCommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesConfirmation_CodesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesConfirmation_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesContactsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesOrgsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesOrgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesPersonal_InfosArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesPersonal_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesPostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesService_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesService_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSettingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSubscribersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSubscribers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSupport_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesSupport_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesUser_ChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


/** columns and relationships of "profiles" */
export type Mobile_ProfilesUser_Chats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};

/** aggregated selection of "profiles" */
export type Mobile_Profiles_Aggregate = {
  __typename?: 'mobile_profiles_aggregate';
  aggregate?: Maybe<Mobile_Profiles_Aggregate_Fields>;
  nodes: Array<Mobile_Profiles>;
};

export type Mobile_Profiles_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Profiles_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Profiles_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Profiles_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Profiles_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Profiles_Select_Column_Mobile_Profiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Profiles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Profiles_Select_Column_Mobile_Profiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "profiles" */
export type Mobile_Profiles_Aggregate_Fields = {
  __typename?: 'mobile_profiles_aggregate_fields';
  avg?: Maybe<Mobile_Profiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Profiles_Max_Fields>;
  min?: Maybe<Mobile_Profiles_Min_Fields>;
  stddev?: Maybe<Mobile_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Profiles_Sum_Fields>;
  var_pop?: Maybe<Mobile_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Profiles_Variance_Fields>;
};


/** aggregate fields of "profiles" */
export type Mobile_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "profiles" */
export type Mobile_Profiles_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Profiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Profiles_Max_Order_By>;
  min?: InputMaybe<Mobile_Profiles_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Profiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Profiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Profiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Profiles_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Profiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Profiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Profiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profiles" */
export type Mobile_Profiles_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Profiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Profiles_Avg_Fields = {
  __typename?: 'mobile_profiles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_active?: Maybe<Scalars['Float']['output']>;
  vc_client_id?: Maybe<Scalars['Float']['output']>;
  vc_state?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "profiles" */
export type Mobile_Profiles_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profiles". All fields are combined with a logical 'AND'. */
export type Mobile_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Profiles_Bool_Exp>>;
  about_myself?: InputMaybe<String_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  appointments?: InputMaybe<Mobile_Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Mobile_Appointments_Aggregate_Bool_Exp>;
  avatar_path?: InputMaybe<String_Comparison_Exp>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp>;
  comment_likes?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
  comment_likes_aggregate?: InputMaybe<Mobile_Comment_Likes_Aggregate_Bool_Exp>;
  comments?: InputMaybe<Mobile_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Bool_Exp>;
  confirmation_codes?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
  confirmation_codes_aggregate?: InputMaybe<Mobile_Confirmation_Codes_Aggregate_Bool_Exp>;
  contacts?: InputMaybe<Mobile_Contacts_Bool_Exp>;
  contacts_aggregate?: InputMaybe<Mobile_Contacts_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  orgs?: InputMaybe<Mobile_Orgs_Bool_Exp>;
  orgs_aggregate?: InputMaybe<Mobile_Orgs_Aggregate_Bool_Exp>;
  personal_infos?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
  personal_infos_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Bool_Exp>;
  posts?: InputMaybe<Mobile_Posts_Bool_Exp>;
  posts_aggregate?: InputMaybe<Mobile_Posts_Aggregate_Bool_Exp>;
  pro?: InputMaybe<Mobile_Pros_Bool_Exp>;
  profile_link?: InputMaybe<String_Comparison_Exp>;
  service_purchases?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
  service_purchases_aggregate?: InputMaybe<Mobile_Service_Purchases_Aggregate_Bool_Exp>;
  services?: InputMaybe<Mobile_Services_Bool_Exp>;
  services_aggregate?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp>;
  settings?: InputMaybe<Mobile_Settings_Bool_Exp>;
  settings_aggregate?: InputMaybe<Mobile_Settings_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subscribers?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
  subscribers_aggregate?: InputMaybe<Mobile_Subscribes_Aggregate_Bool_Exp>;
  subscriptions?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Mobile_Subscribes_Aggregate_Bool_Exp>;
  support_messages?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
  support_messages_aggregate?: InputMaybe<Mobile_Support_Messages_Aggregate_Bool_Exp>;
  type_profile?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
  type_profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_chats?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
  user_chats_aggregate?: InputMaybe<Mobile_User_Chats_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  vc_active?: InputMaybe<Int_Comparison_Exp>;
  vc_client_id?: InputMaybe<Bigint_Comparison_Exp>;
  vc_state?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "profiles" */
export enum Mobile_Profiles_Constraint {
  /** unique or primary key constraint on columns "username" */
  IndexProfilesOnUsername = 'index_profiles_on_username',
  /** unique or primary key constraint on columns "id" */
  ProfilesPkey = 'profiles_pkey',
  /** unique or primary key constraint on columns "profile_link" */
  ProfilesProfileLinkKey = 'profiles_profile_link_key',
  /** unique or primary key constraint on columns "user_id" */
  ProfilesUniqueUserId = 'profiles_unique_user_id'
}

/** input type for incrementing numeric columns in table "profiles" */
export type Mobile_Profiles_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_active?: InputMaybe<Scalars['Int']['input']>;
  vc_client_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_state?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "profiles" */
export type Mobile_Profiles_Insert_Input = {
  about_myself?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  appointments?: InputMaybe<Mobile_Appointments_Arr_Rel_Insert_Input>;
  avatar_path?: InputMaybe<Scalars['String']['input']>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Arr_Rel_Insert_Input>;
  comment_likes?: InputMaybe<Mobile_Comment_Likes_Arr_Rel_Insert_Input>;
  comments?: InputMaybe<Mobile_Comments_Arr_Rel_Insert_Input>;
  confirmation_codes?: InputMaybe<Mobile_Confirmation_Codes_Arr_Rel_Insert_Input>;
  contacts?: InputMaybe<Mobile_Contacts_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  orgs?: InputMaybe<Mobile_Orgs_Arr_Rel_Insert_Input>;
  personal_infos?: InputMaybe<Mobile_Personal_Infos_Arr_Rel_Insert_Input>;
  posts?: InputMaybe<Mobile_Posts_Arr_Rel_Insert_Input>;
  pro?: InputMaybe<Mobile_Pros_Obj_Rel_Insert_Input>;
  profile_link?: InputMaybe<Scalars['String']['input']>;
  service_purchases?: InputMaybe<Mobile_Service_Purchases_Arr_Rel_Insert_Input>;
  services?: InputMaybe<Mobile_Services_Arr_Rel_Insert_Input>;
  settings?: InputMaybe<Mobile_Settings_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  subscribers?: InputMaybe<Mobile_Subscribes_Arr_Rel_Insert_Input>;
  subscriptions?: InputMaybe<Mobile_Subscribes_Arr_Rel_Insert_Input>;
  support_messages?: InputMaybe<Mobile_Support_Messages_Arr_Rel_Insert_Input>;
  type_profile?: InputMaybe<Mobile_Type_Profiles_Obj_Rel_Insert_Input>;
  type_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_chats?: InputMaybe<Mobile_User_Chats_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vc_active?: InputMaybe<Scalars['Int']['input']>;
  vc_client_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_state?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Profiles_Max_Fields = {
  __typename?: 'mobile_profiles_max_fields';
  about_myself?: Maybe<Scalars['String']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  profile_link?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['bigint']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "profiles" */
export type Mobile_Profiles_Max_Order_By = {
  about_myself?: InputMaybe<Order_By>;
  avatar_path?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  profile_link?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Profiles_Min_Fields = {
  __typename?: 'mobile_profiles_min_fields';
  about_myself?: Maybe<Scalars['String']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  profile_link?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['bigint']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "profiles" */
export type Mobile_Profiles_Min_Order_By = {
  about_myself?: InputMaybe<Order_By>;
  avatar_path?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  profile_link?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profiles" */
export type Mobile_Profiles_Mutation_Response = {
  __typename?: 'mobile_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Profiles>;
};

/** input type for inserting object relation for remote table "profiles" */
export type Mobile_Profiles_Obj_Rel_Insert_Input = {
  data: Mobile_Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Profiles_On_Conflict>;
};

/** on_conflict condition type for table "profiles" */
export type Mobile_Profiles_On_Conflict = {
  constraint: Mobile_Profiles_Constraint;
  update_columns?: Array<Mobile_Profiles_Update_Column>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "profiles". */
export type Mobile_Profiles_Order_By = {
  about_myself?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  appointments_aggregate?: InputMaybe<Mobile_Appointments_Aggregate_Order_By>;
  avatar_path?: InputMaybe<Order_By>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Order_By>;
  comment_likes_aggregate?: InputMaybe<Mobile_Comment_Likes_Aggregate_Order_By>;
  comments_aggregate?: InputMaybe<Mobile_Comments_Aggregate_Order_By>;
  confirmation_codes_aggregate?: InputMaybe<Mobile_Confirmation_Codes_Aggregate_Order_By>;
  contacts_aggregate?: InputMaybe<Mobile_Contacts_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  orgs_aggregate?: InputMaybe<Mobile_Orgs_Aggregate_Order_By>;
  personal_infos_aggregate?: InputMaybe<Mobile_Personal_Infos_Aggregate_Order_By>;
  posts_aggregate?: InputMaybe<Mobile_Posts_Aggregate_Order_By>;
  pro?: InputMaybe<Mobile_Pros_Order_By>;
  profile_link?: InputMaybe<Order_By>;
  service_purchases_aggregate?: InputMaybe<Mobile_Service_Purchases_Aggregate_Order_By>;
  services_aggregate?: InputMaybe<Mobile_Services_Aggregate_Order_By>;
  settings_aggregate?: InputMaybe<Mobile_Settings_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  subscribers_aggregate?: InputMaybe<Mobile_Subscribes_Aggregate_Order_By>;
  subscriptions_aggregate?: InputMaybe<Mobile_Subscribes_Aggregate_Order_By>;
  support_messages_aggregate?: InputMaybe<Mobile_Support_Messages_Aggregate_Order_By>;
  type_profile?: InputMaybe<Mobile_Type_Profiles_Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_chats_aggregate?: InputMaybe<Mobile_User_Chats_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profiles */
export type Mobile_Profiles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "profiles" */
export enum Mobile_Profiles_Select_Column {
  /** column name */
  AboutMyself = 'about_myself',
  /** column name */
  Active = 'active',
  /** column name */
  AvatarPath = 'avatar_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Link = 'link',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  ProfileLink = 'profile_link',
  /** column name */
  Status = 'status',
  /** column name */
  TypeProfileId = 'type_profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username',
  /** column name */
  VcActive = 'vc_active',
  /** column name */
  VcClientId = 'vc_client_id',
  /** column name */
  VcState = 'vc_state'
}

/** select "mobile_profiles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "profiles" */
export enum Mobile_Profiles_Select_Column_Mobile_Profiles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "mobile_profiles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "profiles" */
export enum Mobile_Profiles_Select_Column_Mobile_Profiles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "profiles" */
export type Mobile_Profiles_Set_Input = {
  about_myself?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar_path?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  profile_link?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vc_active?: InputMaybe<Scalars['Int']['input']>;
  vc_client_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_state?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Profiles_Stddev_Fields = {
  __typename?: 'mobile_profiles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_active?: Maybe<Scalars['Float']['output']>;
  vc_client_id?: Maybe<Scalars['Float']['output']>;
  vc_state?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "profiles" */
export type Mobile_Profiles_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Profiles_Stddev_Pop_Fields = {
  __typename?: 'mobile_profiles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_active?: Maybe<Scalars['Float']['output']>;
  vc_client_id?: Maybe<Scalars['Float']['output']>;
  vc_state?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "profiles" */
export type Mobile_Profiles_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Profiles_Stddev_Samp_Fields = {
  __typename?: 'mobile_profiles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_active?: Maybe<Scalars['Float']['output']>;
  vc_client_id?: Maybe<Scalars['Float']['output']>;
  vc_state?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "profiles" */
export type Mobile_Profiles_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_profiles" */
export type Mobile_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Profiles_Stream_Cursor_Value_Input = {
  about_myself?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatar_path?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  profile_link?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vc_active?: InputMaybe<Scalars['Int']['input']>;
  vc_client_id?: InputMaybe<Scalars['bigint']['input']>;
  vc_state?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Profiles_Sum_Fields = {
  __typename?: 'mobile_profiles_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  type_profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['bigint']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "profiles" */
export type Mobile_Profiles_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** update columns of table "profiles" */
export enum Mobile_Profiles_Update_Column {
  /** column name */
  AboutMyself = 'about_myself',
  /** column name */
  Active = 'active',
  /** column name */
  AvatarPath = 'avatar_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Link = 'link',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  ProfileLink = 'profile_link',
  /** column name */
  Status = 'status',
  /** column name */
  TypeProfileId = 'type_profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username',
  /** column name */
  VcActive = 'vc_active',
  /** column name */
  VcClientId = 'vc_client_id',
  /** column name */
  VcState = 'vc_state'
}

export type Mobile_Profiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Profiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Profiles_Var_Pop_Fields = {
  __typename?: 'mobile_profiles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_active?: Maybe<Scalars['Float']['output']>;
  vc_client_id?: Maybe<Scalars['Float']['output']>;
  vc_state?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "profiles" */
export type Mobile_Profiles_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Profiles_Var_Samp_Fields = {
  __typename?: 'mobile_profiles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_active?: Maybe<Scalars['Float']['output']>;
  vc_client_id?: Maybe<Scalars['Float']['output']>;
  vc_state?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "profiles" */
export type Mobile_Profiles_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Profiles_Variance_Fields = {
  __typename?: 'mobile_profiles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  vc_active?: Maybe<Scalars['Float']['output']>;
  vc_client_id?: Maybe<Scalars['Float']['output']>;
  vc_state?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "profiles" */
export type Mobile_Profiles_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  type_profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vc_active?: InputMaybe<Order_By>;
  vc_client_id?: InputMaybe<Order_By>;
  vc_state?: InputMaybe<Order_By>;
};

/** columns and relationships of "pros" */
export type Mobile_Pros = {
  __typename?: 'mobile_pros';
  active?: Maybe<Scalars['Boolean']['output']>;
  approve?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  bank_accounts: Array<Mobile_Bank_Accounts>;
  /** An aggregate relationship */
  bank_accounts_aggregate: Mobile_Bank_Accounts_Aggregate;
  cancel?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  document_path_file?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<Mobile_Documents>;
  /** An aggregate relationship */
  documents_aggregate: Mobile_Documents_Aggregate;
  documents_path?: Maybe<Array<Scalars['String']['output']>>;
  /** An array relationship */
  educations: Array<Mobile_Educations>;
  /** An aggregate relationship */
  educations_aggregate: Mobile_Educations_Aggregate;
  first_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  founders: Array<Mobile_Founders>;
  /** An aggregate relationship */
  founders_aggregate: Mobile_Founders_Aggregate;
  id: Scalars['bigint']['output'];
  inn?: Maybe<Scalars['String']['output']>;
  is_send_request?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  ogrnip?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  order_professionals: Array<Mobile_Order_Professionals>;
  /** An aggregate relationship */
  order_professionals_aggregate: Mobile_Order_Professionals_Aggregate;
  patronymic?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  profession?: Maybe<Mobile_Professions>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  upload_pro_documents: Array<Mobile_Upload_Pro_Documents>;
  /** An aggregate relationship */
  upload_pro_documents_aggregate: Mobile_Upload_Pro_Documents_Aggregate;
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsBank_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsBank_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsEducationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Educations_Order_By>>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsEducations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Educations_Order_By>>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsFoundersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founders_Order_By>>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsFounders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founders_Order_By>>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsOrder_ProfessionalsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Professionals_Order_By>>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsOrder_Professionals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Professionals_Order_By>>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsUpload_Pro_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};


/** columns and relationships of "pros" */
export type Mobile_ProsUpload_Pro_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};

/** aggregated selection of "pros" */
export type Mobile_Pros_Aggregate = {
  __typename?: 'mobile_pros_aggregate';
  aggregate?: Maybe<Mobile_Pros_Aggregate_Fields>;
  nodes: Array<Mobile_Pros>;
};

export type Mobile_Pros_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Pros_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Pros_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Pros_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Pros_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Pros_Select_Column_Mobile_Pros_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Pros_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Pros_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Pros_Select_Column_Mobile_Pros_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Pros_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Pros_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Pros_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pros" */
export type Mobile_Pros_Aggregate_Fields = {
  __typename?: 'mobile_pros_aggregate_fields';
  avg?: Maybe<Mobile_Pros_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Pros_Max_Fields>;
  min?: Maybe<Mobile_Pros_Min_Fields>;
  stddev?: Maybe<Mobile_Pros_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Pros_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Pros_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Pros_Sum_Fields>;
  var_pop?: Maybe<Mobile_Pros_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Pros_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Pros_Variance_Fields>;
};


/** aggregate fields of "pros" */
export type Mobile_Pros_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "pros" */
export type Mobile_Pros_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Pros_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Pros_Max_Order_By>;
  min?: InputMaybe<Mobile_Pros_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Pros_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Pros_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Pros_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Pros_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Pros_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Pros_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Pros_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "pros" */
export type Mobile_Pros_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Pros_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Pros_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Pros_Avg_Fields = {
  __typename?: 'mobile_pros_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "pros" */
export type Mobile_Pros_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pros". All fields are combined with a logical 'AND'. */
export type Mobile_Pros_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Pros_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Pros_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Pros_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  approve?: InputMaybe<Boolean_Comparison_Exp>;
  bank_accounts?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
  bank_accounts_aggregate?: InputMaybe<Mobile_Bank_Accounts_Aggregate_Bool_Exp>;
  cancel?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  document_path_file?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Mobile_Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Mobile_Documents_Aggregate_Bool_Exp>;
  documents_path?: InputMaybe<String_Array_Comparison_Exp>;
  educations?: InputMaybe<Mobile_Educations_Bool_Exp>;
  educations_aggregate?: InputMaybe<Mobile_Educations_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  founders?: InputMaybe<Mobile_Founders_Bool_Exp>;
  founders_aggregate?: InputMaybe<Mobile_Founders_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  inn?: InputMaybe<String_Comparison_Exp>;
  is_send_request?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  ogrnip?: InputMaybe<String_Comparison_Exp>;
  order_professionals?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
  order_professionals_aggregate?: InputMaybe<Mobile_Order_Professionals_Aggregate_Bool_Exp>;
  patronymic?: InputMaybe<String_Comparison_Exp>;
  profession?: InputMaybe<Mobile_Professions_Bool_Exp>;
  profession_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  snils?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_pro_documents?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
  upload_pro_documents_aggregate?: InputMaybe<Mobile_Upload_Pro_Documents_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "pros" */
export enum Mobile_Pros_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProsPkey = 'pros_pkey'
}

/** input type for incrementing numeric columns in table "pros" */
export type Mobile_Pros_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "pros" */
export type Mobile_Pros_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  approve?: InputMaybe<Scalars['Boolean']['input']>;
  bank_accounts?: InputMaybe<Mobile_Bank_Accounts_Arr_Rel_Insert_Input>;
  cancel?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document_path_file?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Mobile_Documents_Arr_Rel_Insert_Input>;
  documents_path?: InputMaybe<Array<Scalars['String']['input']>>;
  educations?: InputMaybe<Mobile_Educations_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  founders?: InputMaybe<Mobile_Founders_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  is_send_request?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  ogrnip?: InputMaybe<Scalars['String']['input']>;
  order_professionals?: InputMaybe<Mobile_Order_Professionals_Arr_Rel_Insert_Input>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  profession?: InputMaybe<Mobile_Professions_Obj_Rel_Insert_Input>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  upload_pro_documents?: InputMaybe<Mobile_Upload_Pro_Documents_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Pros_Max_Fields = {
  __typename?: 'mobile_pros_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document_path_file?: Maybe<Scalars['String']['output']>;
  documents_path?: Maybe<Array<Scalars['String']['output']>>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  ogrnip?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "pros" */
export type Mobile_Pros_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_path_file?: InputMaybe<Order_By>;
  documents_path?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inn?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  ogrnip?: InputMaybe<Order_By>;
  patronymic?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  snils?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Pros_Min_Fields = {
  __typename?: 'mobile_pros_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document_path_file?: Maybe<Scalars['String']['output']>;
  documents_path?: Maybe<Array<Scalars['String']['output']>>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  ogrnip?: Maybe<Scalars['String']['output']>;
  patronymic?: Maybe<Scalars['String']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  snils?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "pros" */
export type Mobile_Pros_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_path_file?: InputMaybe<Order_By>;
  documents_path?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inn?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  ogrnip?: InputMaybe<Order_By>;
  patronymic?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  snils?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pros" */
export type Mobile_Pros_Mutation_Response = {
  __typename?: 'mobile_pros_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Pros>;
};

/** input type for inserting object relation for remote table "pros" */
export type Mobile_Pros_Obj_Rel_Insert_Input = {
  data: Mobile_Pros_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Pros_On_Conflict>;
};

/** on_conflict condition type for table "pros" */
export type Mobile_Pros_On_Conflict = {
  constraint: Mobile_Pros_Constraint;
  update_columns?: Array<Mobile_Pros_Update_Column>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};

/** Ordering options when selecting data from "pros". */
export type Mobile_Pros_Order_By = {
  active?: InputMaybe<Order_By>;
  approve?: InputMaybe<Order_By>;
  bank_accounts_aggregate?: InputMaybe<Mobile_Bank_Accounts_Aggregate_Order_By>;
  cancel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_path_file?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Mobile_Documents_Aggregate_Order_By>;
  documents_path?: InputMaybe<Order_By>;
  educations_aggregate?: InputMaybe<Mobile_Educations_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  founders_aggregate?: InputMaybe<Mobile_Founders_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  inn?: InputMaybe<Order_By>;
  is_send_request?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  ogrnip?: InputMaybe<Order_By>;
  order_professionals_aggregate?: InputMaybe<Mobile_Order_Professionals_Aggregate_Order_By>;
  patronymic?: InputMaybe<Order_By>;
  profession?: InputMaybe<Mobile_Professions_Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  snils?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_pro_documents_aggregate?: InputMaybe<Mobile_Upload_Pro_Documents_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pros */
export type Mobile_Pros_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "pros" */
export enum Mobile_Pros_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Approve = 'approve',
  /** column name */
  Cancel = 'cancel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentPathFile = 'document_path_file',
  /** column name */
  DocumentsPath = 'documents_path',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Inn = 'inn',
  /** column name */
  IsSendRequest = 'is_send_request',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Ogrnip = 'ogrnip',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  ProfessionId = 'profession_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Snils = 'snils',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_pros_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pros" */
export enum Mobile_Pros_Select_Column_Mobile_Pros_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Approve = 'approve',
  /** column name */
  Cancel = 'cancel',
  /** column name */
  IsSendRequest = 'is_send_request'
}

/** select "mobile_pros_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pros" */
export enum Mobile_Pros_Select_Column_Mobile_Pros_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Approve = 'approve',
  /** column name */
  Cancel = 'cancel',
  /** column name */
  IsSendRequest = 'is_send_request'
}

/** input type for updating data in table "pros" */
export type Mobile_Pros_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  approve?: InputMaybe<Scalars['Boolean']['input']>;
  cancel?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document_path_file?: InputMaybe<Scalars['String']['input']>;
  documents_path?: InputMaybe<Array<Scalars['String']['input']>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  is_send_request?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  ogrnip?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Pros_Stddev_Fields = {
  __typename?: 'mobile_pros_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "pros" */
export type Mobile_Pros_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Pros_Stddev_Pop_Fields = {
  __typename?: 'mobile_pros_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "pros" */
export type Mobile_Pros_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Pros_Stddev_Samp_Fields = {
  __typename?: 'mobile_pros_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "pros" */
export type Mobile_Pros_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_pros" */
export type Mobile_Pros_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Pros_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Pros_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  approve?: InputMaybe<Scalars['Boolean']['input']>;
  cancel?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document_path_file?: InputMaybe<Scalars['String']['input']>;
  documents_path?: InputMaybe<Array<Scalars['String']['input']>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  is_send_request?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  ogrnip?: InputMaybe<Scalars['String']['input']>;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  profession_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  snils?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Pros_Sum_Fields = {
  __typename?: 'mobile_pros_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profession_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "pros" */
export type Mobile_Pros_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "pros" */
export enum Mobile_Pros_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Approve = 'approve',
  /** column name */
  Cancel = 'cancel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentPathFile = 'document_path_file',
  /** column name */
  DocumentsPath = 'documents_path',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Inn = 'inn',
  /** column name */
  IsSendRequest = 'is_send_request',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Ogrnip = 'ogrnip',
  /** column name */
  Patronymic = 'patronymic',
  /** column name */
  ProfessionId = 'profession_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Snils = 'snils',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Pros_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Pros_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Pros_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Pros_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Pros_Var_Pop_Fields = {
  __typename?: 'mobile_pros_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "pros" */
export type Mobile_Pros_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Pros_Var_Samp_Fields = {
  __typename?: 'mobile_pros_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "pros" */
export type Mobile_Pros_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Pros_Variance_Fields = {
  __typename?: 'mobile_pros_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profession_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "pros" */
export type Mobile_Pros_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  profession_id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "pulse_norms" */
export type Mobile_Pulse_Norms = {
  __typename?: 'mobile_pulse_norms';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm: Scalars['float8']['output'];
  /** An object relationship */
  profile: Mobile_Profiles;
  profile_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "pulse_norms" */
export type Mobile_Pulse_Norms_Aggregate = {
  __typename?: 'mobile_pulse_norms_aggregate';
  aggregate?: Maybe<Mobile_Pulse_Norms_Aggregate_Fields>;
  nodes: Array<Mobile_Pulse_Norms>;
};

/** aggregate fields of "pulse_norms" */
export type Mobile_Pulse_Norms_Aggregate_Fields = {
  __typename?: 'mobile_pulse_norms_aggregate_fields';
  avg?: Maybe<Mobile_Pulse_Norms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Pulse_Norms_Max_Fields>;
  min?: Maybe<Mobile_Pulse_Norms_Min_Fields>;
  stddev?: Maybe<Mobile_Pulse_Norms_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Pulse_Norms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Pulse_Norms_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Pulse_Norms_Sum_Fields>;
  var_pop?: Maybe<Mobile_Pulse_Norms_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Pulse_Norms_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Pulse_Norms_Variance_Fields>;
};


/** aggregate fields of "pulse_norms" */
export type Mobile_Pulse_Norms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Pulse_Norms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Pulse_Norms_Avg_Fields = {
  __typename?: 'mobile_pulse_norms_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "pulse_norms". All fields are combined with a logical 'AND'. */
export type Mobile_Pulse_Norms_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Pulse_Norms_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Pulse_Norms_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Pulse_Norms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mentor_norm?: InputMaybe<Float8_Comparison_Exp>;
  mentor_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  norm?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "pulse_norms" */
export enum Mobile_Pulse_Norms_Constraint {
  /** unique or primary key constraint on columns "id" */
  PulseNormsPkey = 'pulse_norms_pkey'
}

/** input type for incrementing numeric columns in table "pulse_norms" */
export type Mobile_Pulse_Norms_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "pulse_norms" */
export type Mobile_Pulse_Norms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Pulse_Norms_Max_Fields = {
  __typename?: 'mobile_pulse_norms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Pulse_Norms_Min_Fields = {
  __typename?: 'mobile_pulse_norms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "pulse_norms" */
export type Mobile_Pulse_Norms_Mutation_Response = {
  __typename?: 'mobile_pulse_norms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Pulse_Norms>;
};

/** on_conflict condition type for table "pulse_norms" */
export type Mobile_Pulse_Norms_On_Conflict = {
  constraint: Mobile_Pulse_Norms_Constraint;
  update_columns?: Array<Mobile_Pulse_Norms_Update_Column>;
  where?: InputMaybe<Mobile_Pulse_Norms_Bool_Exp>;
};

/** Ordering options when selecting data from "pulse_norms". */
export type Mobile_Pulse_Norms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentor_norm?: InputMaybe<Order_By>;
  mentor_user_id?: InputMaybe<Order_By>;
  norm?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pulse_norms */
export type Mobile_Pulse_Norms_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "pulse_norms" */
export enum Mobile_Pulse_Norms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "pulse_norms" */
export type Mobile_Pulse_Norms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Pulse_Norms_Stddev_Fields = {
  __typename?: 'mobile_pulse_norms_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Pulse_Norms_Stddev_Pop_Fields = {
  __typename?: 'mobile_pulse_norms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Pulse_Norms_Stddev_Samp_Fields = {
  __typename?: 'mobile_pulse_norms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_pulse_norms" */
export type Mobile_Pulse_Norms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Pulse_Norms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Pulse_Norms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Pulse_Norms_Sum_Fields = {
  __typename?: 'mobile_pulse_norms_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "pulse_norms" */
export enum Mobile_Pulse_Norms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Pulse_Norms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Pulse_Norms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Pulse_Norms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Pulse_Norms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Pulse_Norms_Var_Pop_Fields = {
  __typename?: 'mobile_pulse_norms_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Pulse_Norms_Var_Samp_Fields = {
  __typename?: 'mobile_pulse_norms_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Pulse_Norms_Variance_Fields = {
  __typename?: 'mobile_pulse_norms_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "pulse_nows" */
export type Mobile_Pulse_Nows = {
  __typename?: 'mobile_pulse_nows';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "pulse_nows" */
export type Mobile_Pulse_Nows_Aggregate = {
  __typename?: 'mobile_pulse_nows_aggregate';
  aggregate?: Maybe<Mobile_Pulse_Nows_Aggregate_Fields>;
  nodes: Array<Mobile_Pulse_Nows>;
};

/** aggregate fields of "pulse_nows" */
export type Mobile_Pulse_Nows_Aggregate_Fields = {
  __typename?: 'mobile_pulse_nows_aggregate_fields';
  avg?: Maybe<Mobile_Pulse_Nows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Pulse_Nows_Max_Fields>;
  min?: Maybe<Mobile_Pulse_Nows_Min_Fields>;
  stddev?: Maybe<Mobile_Pulse_Nows_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Pulse_Nows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Pulse_Nows_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Pulse_Nows_Sum_Fields>;
  var_pop?: Maybe<Mobile_Pulse_Nows_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Pulse_Nows_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Pulse_Nows_Variance_Fields>;
};


/** aggregate fields of "pulse_nows" */
export type Mobile_Pulse_Nows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Pulse_Nows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Pulse_Nows_Avg_Fields = {
  __typename?: 'mobile_pulse_nows_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "pulse_nows". All fields are combined with a logical 'AND'. */
export type Mobile_Pulse_Nows_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Pulse_Nows_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Pulse_Nows_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Pulse_Nows_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "pulse_nows" */
export enum Mobile_Pulse_Nows_Constraint {
  /** unique or primary key constraint on columns "id" */
  PulseNowsPkey = 'pulse_nows_pkey'
}

/** input type for incrementing numeric columns in table "pulse_nows" */
export type Mobile_Pulse_Nows_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "pulse_nows" */
export type Mobile_Pulse_Nows_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Pulse_Nows_Max_Fields = {
  __typename?: 'mobile_pulse_nows_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mobile_Pulse_Nows_Min_Fields = {
  __typename?: 'mobile_pulse_nows_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "pulse_nows" */
export type Mobile_Pulse_Nows_Mutation_Response = {
  __typename?: 'mobile_pulse_nows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Pulse_Nows>;
};

/** on_conflict condition type for table "pulse_nows" */
export type Mobile_Pulse_Nows_On_Conflict = {
  constraint: Mobile_Pulse_Nows_Constraint;
  update_columns?: Array<Mobile_Pulse_Nows_Update_Column>;
  where?: InputMaybe<Mobile_Pulse_Nows_Bool_Exp>;
};

/** Ordering options when selecting data from "pulse_nows". */
export type Mobile_Pulse_Nows_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pulse_nows */
export type Mobile_Pulse_Nows_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "pulse_nows" */
export enum Mobile_Pulse_Nows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "pulse_nows" */
export type Mobile_Pulse_Nows_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Pulse_Nows_Stddev_Fields = {
  __typename?: 'mobile_pulse_nows_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Pulse_Nows_Stddev_Pop_Fields = {
  __typename?: 'mobile_pulse_nows_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Pulse_Nows_Stddev_Samp_Fields = {
  __typename?: 'mobile_pulse_nows_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_pulse_nows" */
export type Mobile_Pulse_Nows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Pulse_Nows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Pulse_Nows_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Pulse_Nows_Sum_Fields = {
  __typename?: 'mobile_pulse_nows_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "pulse_nows" */
export enum Mobile_Pulse_Nows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Pulse_Nows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Pulse_Nows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Pulse_Nows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Pulse_Nows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Pulse_Nows_Var_Pop_Fields = {
  __typename?: 'mobile_pulse_nows_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Pulse_Nows_Var_Samp_Fields = {
  __typename?: 'mobile_pulse_nows_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Pulse_Nows_Variance_Fields = {
  __typename?: 'mobile_pulse_nows_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "pulses" */
export type Mobile_Pulses = {
  __typename?: 'mobile_pulses';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "pulses" */
export type Mobile_Pulses_Aggregate = {
  __typename?: 'mobile_pulses_aggregate';
  aggregate?: Maybe<Mobile_Pulses_Aggregate_Fields>;
  nodes: Array<Mobile_Pulses>;
};

/** aggregate fields of "pulses" */
export type Mobile_Pulses_Aggregate_Fields = {
  __typename?: 'mobile_pulses_aggregate_fields';
  avg?: Maybe<Mobile_Pulses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Pulses_Max_Fields>;
  min?: Maybe<Mobile_Pulses_Min_Fields>;
  stddev?: Maybe<Mobile_Pulses_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Pulses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Pulses_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Pulses_Sum_Fields>;
  var_pop?: Maybe<Mobile_Pulses_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Pulses_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Pulses_Variance_Fields>;
};


/** aggregate fields of "pulses" */
export type Mobile_Pulses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Pulses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Pulses_Avg_Fields = {
  __typename?: 'mobile_pulses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "pulses". All fields are combined with a logical 'AND'. */
export type Mobile_Pulses_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Pulses_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Pulses_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Pulses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "pulses" */
export enum Mobile_Pulses_Constraint {
  /** unique or primary key constraint on columns "id" */
  PulsesPkey = 'pulses_pkey'
}

/** input type for incrementing numeric columns in table "pulses" */
export type Mobile_Pulses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "pulses" */
export type Mobile_Pulses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Pulses_Max_Fields = {
  __typename?: 'mobile_pulses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mobile_Pulses_Min_Fields = {
  __typename?: 'mobile_pulses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "pulses" */
export type Mobile_Pulses_Mutation_Response = {
  __typename?: 'mobile_pulses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Pulses>;
};

/** on_conflict condition type for table "pulses" */
export type Mobile_Pulses_On_Conflict = {
  constraint: Mobile_Pulses_Constraint;
  update_columns?: Array<Mobile_Pulses_Update_Column>;
  where?: InputMaybe<Mobile_Pulses_Bool_Exp>;
};

/** Ordering options when selecting data from "pulses". */
export type Mobile_Pulses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pulses */
export type Mobile_Pulses_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "pulses" */
export enum Mobile_Pulses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "pulses" */
export type Mobile_Pulses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Pulses_Stddev_Fields = {
  __typename?: 'mobile_pulses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Pulses_Stddev_Pop_Fields = {
  __typename?: 'mobile_pulses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Pulses_Stddev_Samp_Fields = {
  __typename?: 'mobile_pulses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_pulses" */
export type Mobile_Pulses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Pulses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Pulses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Pulses_Sum_Fields = {
  __typename?: 'mobile_pulses_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "pulses" */
export enum Mobile_Pulses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Pulses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Pulses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Pulses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Pulses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Pulses_Var_Pop_Fields = {
  __typename?: 'mobile_pulses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Pulses_Var_Samp_Fields = {
  __typename?: 'mobile_pulses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Pulses_Variance_Fields = {
  __typename?: 'mobile_pulses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reactions" */
export type Mobile_Reactions = {
  __typename?: 'mobile_reactions';
  /** An object relationship */
  chat_message?: Maybe<Mobile_Chat_Messages>;
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  emoji?: Maybe<Mobile_Emojis>;
  emoji_id?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "reactions" */
export type Mobile_Reactions_Aggregate = {
  __typename?: 'mobile_reactions_aggregate';
  aggregate?: Maybe<Mobile_Reactions_Aggregate_Fields>;
  nodes: Array<Mobile_Reactions>;
};

export type Mobile_Reactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Reactions_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Reactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Reactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reactions" */
export type Mobile_Reactions_Aggregate_Fields = {
  __typename?: 'mobile_reactions_aggregate_fields';
  avg?: Maybe<Mobile_Reactions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Reactions_Max_Fields>;
  min?: Maybe<Mobile_Reactions_Min_Fields>;
  stddev?: Maybe<Mobile_Reactions_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Reactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Reactions_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Reactions_Sum_Fields>;
  var_pop?: Maybe<Mobile_Reactions_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Reactions_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Reactions_Variance_Fields>;
};


/** aggregate fields of "reactions" */
export type Mobile_Reactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reactions" */
export type Mobile_Reactions_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Reactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Reactions_Max_Order_By>;
  min?: InputMaybe<Mobile_Reactions_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Reactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Reactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Reactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Reactions_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Reactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Reactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Reactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reactions" */
export type Mobile_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Reactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Reactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Reactions_Avg_Fields = {
  __typename?: 'mobile_reactions_avg_fields';
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  emoji_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reactions" */
export type Mobile_Reactions_Avg_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reactions". All fields are combined with a logical 'AND'. */
export type Mobile_Reactions_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Reactions_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Reactions_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Reactions_Bool_Exp>>;
  chat_message?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_message_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  emoji?: InputMaybe<Mobile_Emojis_Bool_Exp>;
  emoji_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "reactions" */
export enum Mobile_Reactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReactionsPkey = 'reactions_pkey',
  /** unique or primary key constraint on columns "emoji_id", "user_id", "chat_message_id" */
  UniqueCombinationUserIdChatMessageIdEmojiId = 'unique_combination_user_id_chat_message_id_emoji_id'
}

/** input type for incrementing numeric columns in table "reactions" */
export type Mobile_Reactions_Inc_Input = {
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  emoji_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "reactions" */
export type Mobile_Reactions_Insert_Input = {
  chat_message?: InputMaybe<Mobile_Chat_Messages_Obj_Rel_Insert_Input>;
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  emoji?: InputMaybe<Mobile_Emojis_Obj_Rel_Insert_Input>;
  emoji_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Reactions_Max_Fields = {
  __typename?: 'mobile_reactions_max_fields';
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  emoji_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "reactions" */
export type Mobile_Reactions_Max_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Reactions_Min_Fields = {
  __typename?: 'mobile_reactions_min_fields';
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  emoji_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "reactions" */
export type Mobile_Reactions_Min_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reactions" */
export type Mobile_Reactions_Mutation_Response = {
  __typename?: 'mobile_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Reactions>;
};

/** on_conflict condition type for table "reactions" */
export type Mobile_Reactions_On_Conflict = {
  constraint: Mobile_Reactions_Constraint;
  update_columns?: Array<Mobile_Reactions_Update_Column>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};

/** Ordering options when selecting data from "reactions". */
export type Mobile_Reactions_Order_By = {
  chat_message?: InputMaybe<Mobile_Chat_Messages_Order_By>;
  chat_message_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Mobile_Emojis_Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reactions */
export type Mobile_Reactions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "reactions" */
export enum Mobile_Reactions_Select_Column {
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmojiId = 'emoji_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "reactions" */
export type Mobile_Reactions_Set_Input = {
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  emoji_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Reactions_Stddev_Fields = {
  __typename?: 'mobile_reactions_stddev_fields';
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  emoji_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reactions" */
export type Mobile_Reactions_Stddev_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Reactions_Stddev_Pop_Fields = {
  __typename?: 'mobile_reactions_stddev_pop_fields';
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  emoji_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reactions" */
export type Mobile_Reactions_Stddev_Pop_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Reactions_Stddev_Samp_Fields = {
  __typename?: 'mobile_reactions_stddev_samp_fields';
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  emoji_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reactions" */
export type Mobile_Reactions_Stddev_Samp_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_reactions" */
export type Mobile_Reactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Reactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Reactions_Stream_Cursor_Value_Input = {
  chat_message_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  emoji_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Reactions_Sum_Fields = {
  __typename?: 'mobile_reactions_sum_fields';
  chat_message_id?: Maybe<Scalars['bigint']['output']>;
  emoji_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "reactions" */
export type Mobile_Reactions_Sum_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "reactions" */
export enum Mobile_Reactions_Update_Column {
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmojiId = 'emoji_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Reactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Reactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Reactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Reactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Reactions_Var_Pop_Fields = {
  __typename?: 'mobile_reactions_var_pop_fields';
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  emoji_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reactions" */
export type Mobile_Reactions_Var_Pop_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Reactions_Var_Samp_Fields = {
  __typename?: 'mobile_reactions_var_samp_fields';
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  emoji_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reactions" */
export type Mobile_Reactions_Var_Samp_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Reactions_Variance_Fields = {
  __typename?: 'mobile_reactions_variance_fields';
  chat_message_id?: Maybe<Scalars['Float']['output']>;
  emoji_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reactions" */
export type Mobile_Reactions_Variance_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  emoji_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "reports" */
export type Mobile_Reports = {
  __typename?: 'mobile_reports';
  approve?: Maybe<Scalars['Boolean']['output']>;
  awaiting_verification?: Maybe<Scalars['Boolean']['output']>;
  cancel?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  reportable_id?: Maybe<Scalars['bigint']['output']>;
  reportable_message?: Maybe<Scalars['String']['output']>;
  reportable_type?: Maybe<Scalars['String']['output']>;
  reported_user_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "reports" */
export type Mobile_Reports_Aggregate = {
  __typename?: 'mobile_reports_aggregate';
  aggregate?: Maybe<Mobile_Reports_Aggregate_Fields>;
  nodes: Array<Mobile_Reports>;
};

/** aggregate fields of "reports" */
export type Mobile_Reports_Aggregate_Fields = {
  __typename?: 'mobile_reports_aggregate_fields';
  avg?: Maybe<Mobile_Reports_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Reports_Max_Fields>;
  min?: Maybe<Mobile_Reports_Min_Fields>;
  stddev?: Maybe<Mobile_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Reports_Sum_Fields>;
  var_pop?: Maybe<Mobile_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Reports_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Reports_Variance_Fields>;
};


/** aggregate fields of "reports" */
export type Mobile_Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Reports_Avg_Fields = {
  __typename?: 'mobile_reports_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reportable_id?: Maybe<Scalars['Float']['output']>;
  reported_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Mobile_Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Reports_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Reports_Bool_Exp>>;
  approve?: InputMaybe<Boolean_Comparison_Exp>;
  awaiting_verification?: InputMaybe<Boolean_Comparison_Exp>;
  cancel?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  reportable_id?: InputMaybe<Bigint_Comparison_Exp>;
  reportable_message?: InputMaybe<String_Comparison_Exp>;
  reportable_type?: InputMaybe<String_Comparison_Exp>;
  reported_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Mobile_Reports_Constraint {
  /** unique or primary key constraint on columns "reportable_id", "user_id", "reportable_type" */
  IndexReportsOnUserIdAndReportableIdAndReportableType = 'index_reports_on_user_id_and_reportable_id_and_reportable_type',
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

/** input type for incrementing numeric columns in table "reports" */
export type Mobile_Reports_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  reportable_id?: InputMaybe<Scalars['bigint']['input']>;
  reported_user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "reports" */
export type Mobile_Reports_Insert_Input = {
  approve?: InputMaybe<Scalars['Boolean']['input']>;
  awaiting_verification?: InputMaybe<Scalars['Boolean']['input']>;
  cancel?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reportable_id?: InputMaybe<Scalars['bigint']['input']>;
  reportable_message?: InputMaybe<Scalars['String']['input']>;
  reportable_type?: InputMaybe<Scalars['String']['input']>;
  reported_user_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Mobile_Reports_Max_Fields = {
  __typename?: 'mobile_reports_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  reportable_id?: Maybe<Scalars['bigint']['output']>;
  reportable_message?: Maybe<Scalars['String']['output']>;
  reportable_type?: Maybe<Scalars['String']['output']>;
  reported_user_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Reports_Min_Fields = {
  __typename?: 'mobile_reports_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  reportable_id?: Maybe<Scalars['bigint']['output']>;
  reportable_message?: Maybe<Scalars['String']['output']>;
  reportable_type?: Maybe<Scalars['String']['output']>;
  reported_user_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "reports" */
export type Mobile_Reports_Mutation_Response = {
  __typename?: 'mobile_reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Reports>;
};

/** on_conflict condition type for table "reports" */
export type Mobile_Reports_On_Conflict = {
  constraint: Mobile_Reports_Constraint;
  update_columns?: Array<Mobile_Reports_Update_Column>;
  where?: InputMaybe<Mobile_Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Mobile_Reports_Order_By = {
  approve?: InputMaybe<Order_By>;
  awaiting_verification?: InputMaybe<Order_By>;
  cancel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportable_id?: InputMaybe<Order_By>;
  reportable_message?: InputMaybe<Order_By>;
  reportable_type?: InputMaybe<Order_By>;
  reported_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reports */
export type Mobile_Reports_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "reports" */
export enum Mobile_Reports_Select_Column {
  /** column name */
  Approve = 'approve',
  /** column name */
  AwaitingVerification = 'awaiting_verification',
  /** column name */
  Cancel = 'cancel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportableId = 'reportable_id',
  /** column name */
  ReportableMessage = 'reportable_message',
  /** column name */
  ReportableType = 'reportable_type',
  /** column name */
  ReportedUserId = 'reported_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "reports" */
export type Mobile_Reports_Set_Input = {
  approve?: InputMaybe<Scalars['Boolean']['input']>;
  awaiting_verification?: InputMaybe<Scalars['Boolean']['input']>;
  cancel?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reportable_id?: InputMaybe<Scalars['bigint']['input']>;
  reportable_message?: InputMaybe<Scalars['String']['input']>;
  reportable_type?: InputMaybe<Scalars['String']['input']>;
  reported_user_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Reports_Stddev_Fields = {
  __typename?: 'mobile_reports_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reportable_id?: Maybe<Scalars['Float']['output']>;
  reported_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Reports_Stddev_Pop_Fields = {
  __typename?: 'mobile_reports_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reportable_id?: Maybe<Scalars['Float']['output']>;
  reported_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Reports_Stddev_Samp_Fields = {
  __typename?: 'mobile_reports_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reportable_id?: Maybe<Scalars['Float']['output']>;
  reported_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_reports" */
export type Mobile_Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Reports_Stream_Cursor_Value_Input = {
  approve?: InputMaybe<Scalars['Boolean']['input']>;
  awaiting_verification?: InputMaybe<Scalars['Boolean']['input']>;
  cancel?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reportable_id?: InputMaybe<Scalars['bigint']['input']>;
  reportable_message?: InputMaybe<Scalars['String']['input']>;
  reportable_type?: InputMaybe<Scalars['String']['input']>;
  reported_user_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Reports_Sum_Fields = {
  __typename?: 'mobile_reports_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  reportable_id?: Maybe<Scalars['bigint']['output']>;
  reported_user_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "reports" */
export enum Mobile_Reports_Update_Column {
  /** column name */
  Approve = 'approve',
  /** column name */
  AwaitingVerification = 'awaiting_verification',
  /** column name */
  Cancel = 'cancel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportableId = 'reportable_id',
  /** column name */
  ReportableMessage = 'reportable_message',
  /** column name */
  ReportableType = 'reportable_type',
  /** column name */
  ReportedUserId = 'reported_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Verified = 'verified'
}

export type Mobile_Reports_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Reports_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Reports_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Reports_Var_Pop_Fields = {
  __typename?: 'mobile_reports_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reportable_id?: Maybe<Scalars['Float']['output']>;
  reported_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Reports_Var_Samp_Fields = {
  __typename?: 'mobile_reports_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reportable_id?: Maybe<Scalars['Float']['output']>;
  reported_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Reports_Variance_Fields = {
  __typename?: 'mobile_reports_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reportable_id?: Maybe<Scalars['Float']['output']>;
  reported_user_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

export type Mobile_Search_Posts_Find_Title_Message_Args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Mobile_Search_Profiles_Find_First_Last_Username_Aboutmyself_Args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "service_agreements" */
export type Mobile_Service_Agreements = {
  __typename?: 'mobile_service_agreements';
  created_at: Scalars['timestamp']['output'];
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "service_agreements" */
export type Mobile_Service_Agreements_Aggregate = {
  __typename?: 'mobile_service_agreements_aggregate';
  aggregate?: Maybe<Mobile_Service_Agreements_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Agreements>;
};

/** aggregate fields of "service_agreements" */
export type Mobile_Service_Agreements_Aggregate_Fields = {
  __typename?: 'mobile_service_agreements_aggregate_fields';
  avg?: Maybe<Mobile_Service_Agreements_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Agreements_Max_Fields>;
  min?: Maybe<Mobile_Service_Agreements_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Agreements_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Agreements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Agreements_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Agreements_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Agreements_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Agreements_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Agreements_Variance_Fields>;
};


/** aggregate fields of "service_agreements" */
export type Mobile_Service_Agreements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Agreements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_Agreements_Avg_Fields = {
  __typename?: 'mobile_service_agreements_avg_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_agreements". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Agreements_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Agreements_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Agreements_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  document_path?: InputMaybe<String_Comparison_Exp>;
  document_size?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_agreements" */
export enum Mobile_Service_Agreements_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceAgreementsPkey = 'service_agreements_pkey',
  /** unique or primary key constraint on columns "service_id" */
  UniqueServiceAgreementServiceId = 'unique_service_agreement_service_id'
}

/** input type for incrementing numeric columns in table "service_agreements" */
export type Mobile_Service_Agreements_Inc_Input = {
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_agreements" */
export type Mobile_Service_Agreements_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Agreements_Max_Fields = {
  __typename?: 'mobile_service_agreements_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_Agreements_Min_Fields = {
  __typename?: 'mobile_service_agreements_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "service_agreements" */
export type Mobile_Service_Agreements_Mutation_Response = {
  __typename?: 'mobile_service_agreements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Agreements>;
};

/** input type for inserting object relation for remote table "service_agreements" */
export type Mobile_Service_Agreements_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Agreements_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Agreements_On_Conflict>;
};

/** on_conflict condition type for table "service_agreements" */
export type Mobile_Service_Agreements_On_Conflict = {
  constraint: Mobile_Service_Agreements_Constraint;
  update_columns?: Array<Mobile_Service_Agreements_Update_Column>;
  where?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
};

/** Ordering options when selecting data from "service_agreements". */
export type Mobile_Service_Agreements_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_agreements */
export type Mobile_Service_Agreements_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_agreements" */
export enum Mobile_Service_Agreements_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_agreements" */
export type Mobile_Service_Agreements_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Agreements_Stddev_Fields = {
  __typename?: 'mobile_service_agreements_stddev_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Agreements_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_agreements_stddev_pop_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Agreements_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_agreements_stddev_samp_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_agreements" */
export type Mobile_Service_Agreements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Agreements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Agreements_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Agreements_Sum_Fields = {
  __typename?: 'mobile_service_agreements_sum_fields';
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_agreements" */
export enum Mobile_Service_Agreements_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Agreements_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Agreements_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Agreements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Agreements_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Agreements_Var_Pop_Fields = {
  __typename?: 'mobile_service_agreements_var_pop_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Agreements_Var_Samp_Fields = {
  __typename?: 'mobile_service_agreements_var_samp_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_Agreements_Variance_Fields = {
  __typename?: 'mobile_service_agreements_variance_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions = {
  __typename?: 'mobile_service_answer_reactions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  is_liked: Scalars['Boolean']['output'];
  /** An object relationship */
  service_answer?: Maybe<Mobile_Service_Answers>;
  service_answer_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Aggregate = {
  __typename?: 'mobile_service_answer_reactions_aggregate';
  aggregate?: Maybe<Mobile_Service_Answer_Reactions_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Answer_Reactions>;
};

export type Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Service_Answer_Reactions_Select_Column_Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Service_Answer_Reactions_Select_Column_Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Aggregate_Fields = {
  __typename?: 'mobile_service_answer_reactions_aggregate_fields';
  avg?: Maybe<Mobile_Service_Answer_Reactions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Answer_Reactions_Max_Fields>;
  min?: Maybe<Mobile_Service_Answer_Reactions_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Answer_Reactions_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Answer_Reactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Answer_Reactions_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Answer_Reactions_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Answer_Reactions_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Answer_Reactions_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Answer_Reactions_Variance_Fields>;
};


/** aggregate fields of "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Answer_Reactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Answer_Reactions_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Answer_Reactions_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Answer_Reactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Answer_Reactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Answer_Reactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Answer_Reactions_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Answer_Reactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Answer_Reactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Answer_Reactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Answer_Reactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Answer_Reactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Answer_Reactions_Avg_Fields = {
  __typename?: 'mobile_service_answer_reactions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_answer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_answer_reactions". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Answer_Reactions_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_liked?: InputMaybe<Boolean_Comparison_Exp>;
  service_answer?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
  service_answer_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_answer_reactions" */
export enum Mobile_Service_Answer_Reactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceAnswerReactionsPkey = 'service_answer_reactions_pkey',
  /** unique or primary key constraint on columns "service_answer_id", "user_id" */
  ServiceAnswerReactionsUniqueUserIdServiceAnswerId = 'service_answer_reactions_unique_user_id_service_answer_id'
}

/** input type for incrementing numeric columns in table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_answer_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_liked?: InputMaybe<Scalars['Boolean']['input']>;
  service_answer?: InputMaybe<Mobile_Service_Answers_Obj_Rel_Insert_Input>;
  service_answer_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Answer_Reactions_Max_Fields = {
  __typename?: 'mobile_service_answer_reactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_answer_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Answer_Reactions_Min_Fields = {
  __typename?: 'mobile_service_answer_reactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_answer_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Mutation_Response = {
  __typename?: 'mobile_service_answer_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Answer_Reactions>;
};

/** on_conflict condition type for table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_On_Conflict = {
  constraint: Mobile_Service_Answer_Reactions_Constraint;
  update_columns?: Array<Mobile_Service_Answer_Reactions_Update_Column>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};

/** Ordering options when selecting data from "service_answer_reactions". */
export type Mobile_Service_Answer_Reactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_liked?: InputMaybe<Order_By>;
  service_answer?: InputMaybe<Mobile_Service_Answers_Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_answer_reactions */
export type Mobile_Service_Answer_Reactions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_answer_reactions" */
export enum Mobile_Service_Answer_Reactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLiked = 'is_liked',
  /** column name */
  ServiceAnswerId = 'service_answer_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_service_answer_reactions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "service_answer_reactions" */
export enum Mobile_Service_Answer_Reactions_Select_Column_Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsLiked = 'is_liked'
}

/** select "mobile_service_answer_reactions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "service_answer_reactions" */
export enum Mobile_Service_Answer_Reactions_Select_Column_Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsLiked = 'is_liked'
}

/** input type for updating data in table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_liked?: InputMaybe<Scalars['Boolean']['input']>;
  service_answer_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Answer_Reactions_Stddev_Fields = {
  __typename?: 'mobile_service_answer_reactions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_answer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Answer_Reactions_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_answer_reactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_answer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Answer_Reactions_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_answer_reactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_answer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Answer_Reactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Answer_Reactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_liked?: InputMaybe<Scalars['Boolean']['input']>;
  service_answer_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Answer_Reactions_Sum_Fields = {
  __typename?: 'mobile_service_answer_reactions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_answer_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_answer_reactions" */
export enum Mobile_Service_Answer_Reactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLiked = 'is_liked',
  /** column name */
  ServiceAnswerId = 'service_answer_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Answer_Reactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Answer_Reactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Answer_Reactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Answer_Reactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Answer_Reactions_Var_Pop_Fields = {
  __typename?: 'mobile_service_answer_reactions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_answer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Answer_Reactions_Var_Samp_Fields = {
  __typename?: 'mobile_service_answer_reactions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_answer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Answer_Reactions_Variance_Fields = {
  __typename?: 'mobile_service_answer_reactions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_answer_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_answer_reactions" */
export type Mobile_Service_Answer_Reactions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_answer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_answers" */
export type Mobile_Service_Answers = {
  __typename?: 'mobile_service_answers';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  message: Scalars['String']['output'];
  /** An array relationship */
  service_answer_reactions: Array<Mobile_Service_Answer_Reactions>;
  /** An aggregate relationship */
  service_answer_reactions_aggregate: Mobile_Service_Answer_Reactions_Aggregate;
  /** An object relationship */
  service_question?: Maybe<Mobile_Service_Questions>;
  service_question_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};


/** columns and relationships of "service_answers" */
export type Mobile_Service_AnswersService_Answer_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};


/** columns and relationships of "service_answers" */
export type Mobile_Service_AnswersService_Answer_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};

/** aggregated selection of "service_answers" */
export type Mobile_Service_Answers_Aggregate = {
  __typename?: 'mobile_service_answers_aggregate';
  aggregate?: Maybe<Mobile_Service_Answers_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Answers>;
};

export type Mobile_Service_Answers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Answers_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_answers" */
export type Mobile_Service_Answers_Aggregate_Fields = {
  __typename?: 'mobile_service_answers_aggregate_fields';
  avg?: Maybe<Mobile_Service_Answers_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Answers_Max_Fields>;
  min?: Maybe<Mobile_Service_Answers_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Answers_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Answers_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Answers_Variance_Fields>;
};


/** aggregate fields of "service_answers" */
export type Mobile_Service_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_answers" */
export type Mobile_Service_Answers_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Answers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Answers_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Answers_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Answers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Answers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Answers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Answers_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Answers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Answers_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Answers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_answers" */
export type Mobile_Service_Answers_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Answers_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Answers_Avg_Fields = {
  __typename?: 'mobile_service_answers_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_question_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_answers" */
export type Mobile_Service_Answers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_answers". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Answers_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Answers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamp_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  service_answer_reactions?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
  service_answer_reactions_aggregate?: InputMaybe<Mobile_Service_Answer_Reactions_Aggregate_Bool_Exp>;
  service_question?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
  service_question_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_answers" */
export enum Mobile_Service_Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceAnswersPkey = 'service_answers_pkey',
  /** unique or primary key constraint on columns "user_id", "service_question_id" */
  ServiceAnswersUniqueUserIdServiceQuestionId = 'service_answers_unique_user_id_service_question_id'
}

/** input type for incrementing numeric columns in table "service_answers" */
export type Mobile_Service_Answers_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_question_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_answers" */
export type Mobile_Service_Answers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  service_answer_reactions?: InputMaybe<Mobile_Service_Answer_Reactions_Arr_Rel_Insert_Input>;
  service_question?: InputMaybe<Mobile_Service_Questions_Obj_Rel_Insert_Input>;
  service_question_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Answers_Max_Fields = {
  __typename?: 'mobile_service_answers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  service_question_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_answers" */
export type Mobile_Service_Answers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Answers_Min_Fields = {
  __typename?: 'mobile_service_answers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  service_question_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_answers" */
export type Mobile_Service_Answers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_answers" */
export type Mobile_Service_Answers_Mutation_Response = {
  __typename?: 'mobile_service_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Answers>;
};

/** input type for inserting object relation for remote table "service_answers" */
export type Mobile_Service_Answers_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Answers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Answers_On_Conflict>;
};

/** on_conflict condition type for table "service_answers" */
export type Mobile_Service_Answers_On_Conflict = {
  constraint: Mobile_Service_Answers_Constraint;
  update_columns?: Array<Mobile_Service_Answers_Update_Column>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "service_answers". */
export type Mobile_Service_Answers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  service_answer_reactions_aggregate?: InputMaybe<Mobile_Service_Answer_Reactions_Aggregate_Order_By>;
  service_question?: InputMaybe<Mobile_Service_Questions_Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_answers */
export type Mobile_Service_Answers_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_answers" */
export enum Mobile_Service_Answers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  ServiceQuestionId = 'service_question_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_answers" */
export type Mobile_Service_Answers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  service_question_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Answers_Stddev_Fields = {
  __typename?: 'mobile_service_answers_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_question_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_answers" */
export type Mobile_Service_Answers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Answers_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_answers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_question_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_answers" */
export type Mobile_Service_Answers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Answers_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_answers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_question_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_answers" */
export type Mobile_Service_Answers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_answers" */
export type Mobile_Service_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Answers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  service_question_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Answers_Sum_Fields = {
  __typename?: 'mobile_service_answers_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_question_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_answers" */
export type Mobile_Service_Answers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_answers" */
export enum Mobile_Service_Answers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  ServiceQuestionId = 'service_question_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Answers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Answers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Answers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Answers_Var_Pop_Fields = {
  __typename?: 'mobile_service_answers_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_question_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_answers" */
export type Mobile_Service_Answers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Answers_Var_Samp_Fields = {
  __typename?: 'mobile_service_answers_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_question_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_answers" */
export type Mobile_Service_Answers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Answers_Variance_Fields = {
  __typename?: 'mobile_service_answers_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_question_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_answers" */
export type Mobile_Service_Answers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_documents" */
export type Mobile_Service_Documents = {
  __typename?: 'mobile_service_documents';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  pro_document_status?: Maybe<Mobile_Pro_Document_Statuses>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  problem?: Maybe<Mobile_Problems>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "service_documents" */
export type Mobile_Service_Documents_Aggregate = {
  __typename?: 'mobile_service_documents_aggregate';
  aggregate?: Maybe<Mobile_Service_Documents_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Documents>;
};

export type Mobile_Service_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Documents_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_documents" */
export type Mobile_Service_Documents_Aggregate_Fields = {
  __typename?: 'mobile_service_documents_aggregate_fields';
  avg?: Maybe<Mobile_Service_Documents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Documents_Max_Fields>;
  min?: Maybe<Mobile_Service_Documents_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Documents_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Documents_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Documents_Variance_Fields>;
};


/** aggregate fields of "service_documents" */
export type Mobile_Service_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_documents" */
export type Mobile_Service_Documents_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Documents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Documents_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Documents_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Documents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Documents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Documents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Documents_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Documents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Documents_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Documents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_documents" */
export type Mobile_Service_Documents_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Documents_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Documents_Avg_Fields = {
  __typename?: 'mobile_service_documents_avg_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_documents" */
export type Mobile_Service_Documents_Avg_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_documents". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Documents_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Documents_Bool_Exp>>;
  comment_on_problem?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_path?: InputMaybe<String_Comparison_Exp>;
  document_size?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
  pro_document_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  problem?: InputMaybe<Mobile_Problems_Bool_Exp>;
  problem_id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_documents" */
export enum Mobile_Service_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceDocumentsPkey = 'service_documents_pkey'
}

/** input type for incrementing numeric columns in table "service_documents" */
export type Mobile_Service_Documents_Inc_Input = {
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_documents" */
export type Mobile_Service_Documents_Insert_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Obj_Rel_Insert_Input>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem?: InputMaybe<Mobile_Problems_Obj_Rel_Insert_Input>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Documents_Max_Fields = {
  __typename?: 'mobile_service_documents_max_fields';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_documents" */
export type Mobile_Service_Documents_Max_Order_By = {
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Documents_Min_Fields = {
  __typename?: 'mobile_service_documents_min_fields';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_documents" */
export type Mobile_Service_Documents_Min_Order_By = {
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_documents" */
export type Mobile_Service_Documents_Mutation_Response = {
  __typename?: 'mobile_service_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Documents>;
};

/** on_conflict condition type for table "service_documents" */
export type Mobile_Service_Documents_On_Conflict = {
  constraint: Mobile_Service_Documents_Constraint;
  update_columns?: Array<Mobile_Service_Documents_Update_Column>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "service_documents". */
export type Mobile_Service_Documents_Order_By = {
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem?: InputMaybe<Mobile_Problems_Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_documents */
export type Mobile_Service_Documents_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_documents" */
export enum Mobile_Service_Documents_Select_Column {
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_documents" */
export type Mobile_Service_Documents_Set_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Documents_Stddev_Fields = {
  __typename?: 'mobile_service_documents_stddev_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_documents" */
export type Mobile_Service_Documents_Stddev_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Documents_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_documents_stddev_pop_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_documents" */
export type Mobile_Service_Documents_Stddev_Pop_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Documents_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_documents_stddev_samp_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_documents" */
export type Mobile_Service_Documents_Stddev_Samp_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_documents" */
export type Mobile_Service_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Documents_Stream_Cursor_Value_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Documents_Sum_Fields = {
  __typename?: 'mobile_service_documents_sum_fields';
  document_size?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_documents" */
export type Mobile_Service_Documents_Sum_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_documents" */
export enum Mobile_Service_Documents_Update_Column {
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Documents_Var_Pop_Fields = {
  __typename?: 'mobile_service_documents_var_pop_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_documents" */
export type Mobile_Service_Documents_Var_Pop_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Documents_Var_Samp_Fields = {
  __typename?: 'mobile_service_documents_var_samp_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_documents" */
export type Mobile_Service_Documents_Var_Samp_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Documents_Variance_Fields = {
  __typename?: 'mobile_service_documents_variance_fields';
  document_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_documents" */
export type Mobile_Service_Documents_Variance_Order_By = {
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_favorites" */
export type Mobile_Service_Favorites = {
  __typename?: 'mobile_service_favorites';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "service_favorites" */
export type Mobile_Service_Favorites_Aggregate = {
  __typename?: 'mobile_service_favorites_aggregate';
  aggregate?: Maybe<Mobile_Service_Favorites_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Favorites>;
};

export type Mobile_Service_Favorites_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Favorites_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Favorites_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_favorites" */
export type Mobile_Service_Favorites_Aggregate_Fields = {
  __typename?: 'mobile_service_favorites_aggregate_fields';
  avg?: Maybe<Mobile_Service_Favorites_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Favorites_Max_Fields>;
  min?: Maybe<Mobile_Service_Favorites_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Favorites_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Favorites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Favorites_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Favorites_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Favorites_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Favorites_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Favorites_Variance_Fields>;
};


/** aggregate fields of "service_favorites" */
export type Mobile_Service_Favorites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_favorites" */
export type Mobile_Service_Favorites_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Favorites_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Favorites_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Favorites_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Favorites_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Favorites_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Favorites_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Favorites_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Favorites_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Favorites_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Favorites_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_favorites" */
export type Mobile_Service_Favorites_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Favorites_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Favorites_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Favorites_Avg_Fields = {
  __typename?: 'mobile_service_favorites_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_favorites". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Favorites_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Favorites_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Favorites_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_favorites" */
export enum Mobile_Service_Favorites_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceFavoritesPkey = 'service_favorites_pkey',
  /** unique or primary key constraint on columns "user_id", "service_id" */
  ServiceFavoritesUniqueUserIdServiceId = 'service_favorites_unique_user_id_service_id'
}

/** input type for incrementing numeric columns in table "service_favorites" */
export type Mobile_Service_Favorites_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_favorites" */
export type Mobile_Service_Favorites_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Favorites_Max_Fields = {
  __typename?: 'mobile_service_favorites_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Favorites_Min_Fields = {
  __typename?: 'mobile_service_favorites_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_favorites" */
export type Mobile_Service_Favorites_Mutation_Response = {
  __typename?: 'mobile_service_favorites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Favorites>;
};

/** on_conflict condition type for table "service_favorites" */
export type Mobile_Service_Favorites_On_Conflict = {
  constraint: Mobile_Service_Favorites_Constraint;
  update_columns?: Array<Mobile_Service_Favorites_Update_Column>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};

/** Ordering options when selecting data from "service_favorites". */
export type Mobile_Service_Favorites_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_favorites */
export type Mobile_Service_Favorites_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_favorites" */
export enum Mobile_Service_Favorites_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_favorites" */
export type Mobile_Service_Favorites_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Favorites_Stddev_Fields = {
  __typename?: 'mobile_service_favorites_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Favorites_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_favorites_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Favorites_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_favorites_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_favorites" */
export type Mobile_Service_Favorites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Favorites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Favorites_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Favorites_Sum_Fields = {
  __typename?: 'mobile_service_favorites_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_favorites" */
export enum Mobile_Service_Favorites_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Favorites_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Favorites_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Favorites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Favorites_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Favorites_Var_Pop_Fields = {
  __typename?: 'mobile_service_favorites_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Favorites_Var_Samp_Fields = {
  __typename?: 'mobile_service_favorites_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Favorites_Variance_Fields = {
  __typename?: 'mobile_service_favorites_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_favorites" */
export type Mobile_Service_Favorites_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_free_purchases" */
export type Mobile_Service_Free_Purchases = {
  __typename?: 'mobile_service_free_purchases';
  created_at: Scalars['timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  program_name?: Maybe<Scalars['String']['output']>;
  service_type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "service_free_purchases" */
export type Mobile_Service_Free_Purchases_Aggregate = {
  __typename?: 'mobile_service_free_purchases_aggregate';
  aggregate?: Maybe<Mobile_Service_Free_Purchases_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Free_Purchases>;
};

/** aggregate fields of "service_free_purchases" */
export type Mobile_Service_Free_Purchases_Aggregate_Fields = {
  __typename?: 'mobile_service_free_purchases_aggregate_fields';
  avg?: Maybe<Mobile_Service_Free_Purchases_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Free_Purchases_Max_Fields>;
  min?: Maybe<Mobile_Service_Free_Purchases_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Free_Purchases_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Free_Purchases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Free_Purchases_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Free_Purchases_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Free_Purchases_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Free_Purchases_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Free_Purchases_Variance_Fields>;
};


/** aggregate fields of "service_free_purchases" */
export type Mobile_Service_Free_Purchases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Free_Purchases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_Free_Purchases_Avg_Fields = {
  __typename?: 'mobile_service_free_purchases_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_free_purchases". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Free_Purchases_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Free_Purchases_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Free_Purchases_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Free_Purchases_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  program_name?: InputMaybe<String_Comparison_Exp>;
  service_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_free_purchases" */
export enum Mobile_Service_Free_Purchases_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceFreePurchasesPkey = 'service_free_purchases_pkey'
}

/** input type for incrementing numeric columns in table "service_free_purchases" */
export type Mobile_Service_Free_Purchases_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_free_purchases" */
export type Mobile_Service_Free_Purchases_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  program_name?: InputMaybe<Scalars['String']['input']>;
  service_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Free_Purchases_Max_Fields = {
  __typename?: 'mobile_service_free_purchases_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  program_name?: Maybe<Scalars['String']['output']>;
  service_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_Free_Purchases_Min_Fields = {
  __typename?: 'mobile_service_free_purchases_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  program_name?: Maybe<Scalars['String']['output']>;
  service_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "service_free_purchases" */
export type Mobile_Service_Free_Purchases_Mutation_Response = {
  __typename?: 'mobile_service_free_purchases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Free_Purchases>;
};

/** on_conflict condition type for table "service_free_purchases" */
export type Mobile_Service_Free_Purchases_On_Conflict = {
  constraint: Mobile_Service_Free_Purchases_Constraint;
  update_columns?: Array<Mobile_Service_Free_Purchases_Update_Column>;
  where?: InputMaybe<Mobile_Service_Free_Purchases_Bool_Exp>;
};

/** Ordering options when selecting data from "service_free_purchases". */
export type Mobile_Service_Free_Purchases_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  program_name?: InputMaybe<Order_By>;
  service_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_free_purchases */
export type Mobile_Service_Free_Purchases_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_free_purchases" */
export enum Mobile_Service_Free_Purchases_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_free_purchases" */
export type Mobile_Service_Free_Purchases_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  program_name?: InputMaybe<Scalars['String']['input']>;
  service_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Free_Purchases_Stddev_Fields = {
  __typename?: 'mobile_service_free_purchases_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Free_Purchases_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_free_purchases_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Free_Purchases_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_free_purchases_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_free_purchases" */
export type Mobile_Service_Free_Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Free_Purchases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Free_Purchases_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  program_name?: InputMaybe<Scalars['String']['input']>;
  service_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Free_Purchases_Sum_Fields = {
  __typename?: 'mobile_service_free_purchases_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_free_purchases" */
export enum Mobile_Service_Free_Purchases_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Free_Purchases_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Free_Purchases_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Free_Purchases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Free_Purchases_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Free_Purchases_Var_Pop_Fields = {
  __typename?: 'mobile_service_free_purchases_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Free_Purchases_Var_Samp_Fields = {
  __typename?: 'mobile_service_free_purchases_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_Free_Purchases_Variance_Fields = {
  __typename?: 'mobile_service_free_purchases_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_images" */
export type Mobile_Service_Images = {
  __typename?: 'mobile_service_images';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  media_file_size?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  pro_document_status?: Maybe<Mobile_Pro_Document_Statuses>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  problem?: Maybe<Mobile_Problems>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "service_images" */
export type Mobile_Service_Images_Aggregate = {
  __typename?: 'mobile_service_images_aggregate';
  aggregate?: Maybe<Mobile_Service_Images_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Images>;
};

export type Mobile_Service_Images_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Images_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Images_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_images" */
export type Mobile_Service_Images_Aggregate_Fields = {
  __typename?: 'mobile_service_images_aggregate_fields';
  avg?: Maybe<Mobile_Service_Images_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Images_Max_Fields>;
  min?: Maybe<Mobile_Service_Images_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Images_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Images_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Images_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Images_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Images_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Images_Variance_Fields>;
};


/** aggregate fields of "service_images" */
export type Mobile_Service_Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_images" */
export type Mobile_Service_Images_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Images_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Images_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Images_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Images_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Images_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Images_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Images_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Images_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Images_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_images" */
export type Mobile_Service_Images_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Images_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Images_Avg_Fields = {
  __typename?: 'mobile_service_images_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  media_file_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_images" */
export type Mobile_Service_Images_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_images". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Images_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Images_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Images_Bool_Exp>>;
  comment_on_problem?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  file_preview_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  media_file_size?: InputMaybe<Bigint_Comparison_Exp>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
  pro_document_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  problem?: InputMaybe<Mobile_Problems_Bool_Exp>;
  problem_id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_images" */
export enum Mobile_Service_Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceImagesPkey = 'service_images_pkey'
}

/** input type for incrementing numeric columns in table "service_images" */
export type Mobile_Service_Images_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  media_file_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_images" */
export type Mobile_Service_Images_Insert_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  media_file_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Obj_Rel_Insert_Input>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem?: InputMaybe<Mobile_Problems_Obj_Rel_Insert_Input>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Images_Max_Fields = {
  __typename?: 'mobile_service_images_max_fields';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  media_file_size?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_images" */
export type Mobile_Service_Images_Max_Order_By = {
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Images_Min_Fields = {
  __typename?: 'mobile_service_images_min_fields';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_preview_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  media_file_size?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_images" */
export type Mobile_Service_Images_Min_Order_By = {
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_images" */
export type Mobile_Service_Images_Mutation_Response = {
  __typename?: 'mobile_service_images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Images>;
};

/** on_conflict condition type for table "service_images" */
export type Mobile_Service_Images_On_Conflict = {
  constraint: Mobile_Service_Images_Constraint;
  update_columns?: Array<Mobile_Service_Images_Update_Column>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};

/** Ordering options when selecting data from "service_images". */
export type Mobile_Service_Images_Order_By = {
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  file_preview_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem?: InputMaybe<Mobile_Problems_Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_images */
export type Mobile_Service_Images_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_images" */
export enum Mobile_Service_Images_Select_Column {
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FilePreviewPath = 'file_preview_path',
  /** column name */
  Id = 'id',
  /** column name */
  MediaFileSize = 'media_file_size',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_images" */
export type Mobile_Service_Images_Set_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  media_file_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Images_Stddev_Fields = {
  __typename?: 'mobile_service_images_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  media_file_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_images" */
export type Mobile_Service_Images_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Images_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_images_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  media_file_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_images" */
export type Mobile_Service_Images_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Images_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_images_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  media_file_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_images" */
export type Mobile_Service_Images_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_images" */
export type Mobile_Service_Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Images_Stream_Cursor_Value_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  file_preview_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  media_file_size?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Images_Sum_Fields = {
  __typename?: 'mobile_service_images_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  media_file_size?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_images" */
export type Mobile_Service_Images_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_images" */
export enum Mobile_Service_Images_Update_Column {
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  FilePreviewPath = 'file_preview_path',
  /** column name */
  Id = 'id',
  /** column name */
  MediaFileSize = 'media_file_size',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Images_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Images_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Images_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Images_Var_Pop_Fields = {
  __typename?: 'mobile_service_images_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  media_file_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_images" */
export type Mobile_Service_Images_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Images_Var_Samp_Fields = {
  __typename?: 'mobile_service_images_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  media_file_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_images" */
export type Mobile_Service_Images_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Images_Variance_Fields = {
  __typename?: 'mobile_service_images_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  media_file_size?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_images" */
export type Mobile_Service_Images_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  media_file_size?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_likes" */
export type Mobile_Service_Likes = {
  __typename?: 'mobile_service_likes';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "service_likes" */
export type Mobile_Service_Likes_Aggregate = {
  __typename?: 'mobile_service_likes_aggregate';
  aggregate?: Maybe<Mobile_Service_Likes_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Likes>;
};

export type Mobile_Service_Likes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Likes_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Likes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_likes" */
export type Mobile_Service_Likes_Aggregate_Fields = {
  __typename?: 'mobile_service_likes_aggregate_fields';
  avg?: Maybe<Mobile_Service_Likes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Likes_Max_Fields>;
  min?: Maybe<Mobile_Service_Likes_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Likes_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Likes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Likes_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Likes_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Likes_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Likes_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Likes_Variance_Fields>;
};


/** aggregate fields of "service_likes" */
export type Mobile_Service_Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_likes" */
export type Mobile_Service_Likes_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Likes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Likes_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Likes_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Likes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Likes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Likes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Likes_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Likes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Likes_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Likes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_likes" */
export type Mobile_Service_Likes_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Likes_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Likes_Avg_Fields = {
  __typename?: 'mobile_service_likes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_likes" */
export type Mobile_Service_Likes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_likes". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Likes_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Likes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_likes" */
export enum Mobile_Service_Likes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceLikesPkey = 'service_likes_pkey',
  /** unique or primary key constraint on columns "user_id", "service_id" */
  UniqueUserIdServiceId = 'unique_user_id_service_id'
}

/** input type for incrementing numeric columns in table "service_likes" */
export type Mobile_Service_Likes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_likes" */
export type Mobile_Service_Likes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Likes_Max_Fields = {
  __typename?: 'mobile_service_likes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_likes" */
export type Mobile_Service_Likes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Likes_Min_Fields = {
  __typename?: 'mobile_service_likes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_likes" */
export type Mobile_Service_Likes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_likes" */
export type Mobile_Service_Likes_Mutation_Response = {
  __typename?: 'mobile_service_likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Likes>;
};

/** on_conflict condition type for table "service_likes" */
export type Mobile_Service_Likes_On_Conflict = {
  constraint: Mobile_Service_Likes_Constraint;
  update_columns?: Array<Mobile_Service_Likes_Update_Column>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "service_likes". */
export type Mobile_Service_Likes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_likes */
export type Mobile_Service_Likes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_likes" */
export enum Mobile_Service_Likes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_likes" */
export type Mobile_Service_Likes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Likes_Stddev_Fields = {
  __typename?: 'mobile_service_likes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_likes" */
export type Mobile_Service_Likes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Likes_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_likes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_likes" */
export type Mobile_Service_Likes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Likes_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_likes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_likes" */
export type Mobile_Service_Likes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_likes" */
export type Mobile_Service_Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Likes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Likes_Sum_Fields = {
  __typename?: 'mobile_service_likes_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_likes" */
export type Mobile_Service_Likes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_likes" */
export enum Mobile_Service_Likes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Likes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Likes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Likes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Likes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Likes_Var_Pop_Fields = {
  __typename?: 'mobile_service_likes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_likes" */
export type Mobile_Service_Likes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Likes_Var_Samp_Fields = {
  __typename?: 'mobile_service_likes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_likes" */
export type Mobile_Service_Likes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Likes_Variance_Fields = {
  __typename?: 'mobile_service_likes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_likes" */
export type Mobile_Service_Likes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_list_documents" */
export type Mobile_Service_List_Documents = {
  __typename?: 'mobile_service_list_documents';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_list_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "service_list_documents" */
export type Mobile_Service_List_Documents_Aggregate = {
  __typename?: 'mobile_service_list_documents_aggregate';
  aggregate?: Maybe<Mobile_Service_List_Documents_Aggregate_Fields>;
  nodes: Array<Mobile_Service_List_Documents>;
};

/** aggregate fields of "service_list_documents" */
export type Mobile_Service_List_Documents_Aggregate_Fields = {
  __typename?: 'mobile_service_list_documents_aggregate_fields';
  avg?: Maybe<Mobile_Service_List_Documents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_List_Documents_Max_Fields>;
  min?: Maybe<Mobile_Service_List_Documents_Min_Fields>;
  stddev?: Maybe<Mobile_Service_List_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_List_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_List_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_List_Documents_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_List_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_List_Documents_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_List_Documents_Variance_Fields>;
};


/** aggregate fields of "service_list_documents" */
export type Mobile_Service_List_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_List_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_List_Documents_Avg_Fields = {
  __typename?: 'mobile_service_list_documents_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_list_documents". All fields are combined with a logical 'AND'. */
export type Mobile_Service_List_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_List_Documents_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_List_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_List_Documents_Bool_Exp>>;
  comment_on_problem?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  document_path?: InputMaybe<String_Comparison_Exp>;
  document_size?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pro_document_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  problem_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_list_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_list_documents" */
export enum Mobile_Service_List_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceListDocumentsPkey = 'service_list_documents_pkey'
}

/** input type for incrementing numeric columns in table "service_list_documents" */
export type Mobile_Service_List_Documents_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_list_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_list_documents" */
export type Mobile_Service_List_Documents_Insert_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_list_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_List_Documents_Max_Fields = {
  __typename?: 'mobile_service_list_documents_max_fields';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_list_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_List_Documents_Min_Fields = {
  __typename?: 'mobile_service_list_documents_min_fields';
  comment_on_problem?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  document_size?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_list_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "service_list_documents" */
export type Mobile_Service_List_Documents_Mutation_Response = {
  __typename?: 'mobile_service_list_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_List_Documents>;
};

/** on_conflict condition type for table "service_list_documents" */
export type Mobile_Service_List_Documents_On_Conflict = {
  constraint: Mobile_Service_List_Documents_Constraint;
  update_columns?: Array<Mobile_Service_List_Documents_Update_Column>;
  where?: InputMaybe<Mobile_Service_List_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "service_list_documents". */
export type Mobile_Service_List_Documents_Order_By = {
  comment_on_problem?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  document_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  service_list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_list_documents */
export type Mobile_Service_List_Documents_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_list_documents" */
export enum Mobile_Service_List_Documents_Select_Column {
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  ServiceListId = 'service_list_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_list_documents" */
export type Mobile_Service_List_Documents_Set_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_list_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_List_Documents_Stddev_Fields = {
  __typename?: 'mobile_service_list_documents_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_List_Documents_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_list_documents_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_List_Documents_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_list_documents_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_list_documents" */
export type Mobile_Service_List_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_List_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_List_Documents_Stream_Cursor_Value_Input = {
  comment_on_problem?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  document_size?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  service_list_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_List_Documents_Sum_Fields = {
  __typename?: 'mobile_service_list_documents_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  service_list_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_list_documents" */
export enum Mobile_Service_List_Documents_Update_Column {
  /** column name */
  CommentOnProblem = 'comment_on_problem',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  DocumentSize = 'document_size',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  ServiceListId = 'service_list_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_List_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_List_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_List_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_List_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_List_Documents_Var_Pop_Fields = {
  __typename?: 'mobile_service_list_documents_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_List_Documents_Var_Samp_Fields = {
  __typename?: 'mobile_service_list_documents_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_List_Documents_Variance_Fields = {
  __typename?: 'mobile_service_list_documents_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  service_list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_list_periodicities" */
export type Mobile_Service_List_Periodicities = {
  __typename?: 'mobile_service_list_periodicities';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  service_lists: Array<Mobile_Service_Lists>;
  /** An aggregate relationship */
  service_lists_aggregate: Mobile_Service_Lists_Aggregate;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "service_list_periodicities" */
export type Mobile_Service_List_PeriodicitiesService_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};


/** columns and relationships of "service_list_periodicities" */
export type Mobile_Service_List_PeriodicitiesService_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};

/** aggregated selection of "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Aggregate = {
  __typename?: 'mobile_service_list_periodicities_aggregate';
  aggregate?: Maybe<Mobile_Service_List_Periodicities_Aggregate_Fields>;
  nodes: Array<Mobile_Service_List_Periodicities>;
};

/** aggregate fields of "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Aggregate_Fields = {
  __typename?: 'mobile_service_list_periodicities_aggregate_fields';
  avg?: Maybe<Mobile_Service_List_Periodicities_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_List_Periodicities_Max_Fields>;
  min?: Maybe<Mobile_Service_List_Periodicities_Min_Fields>;
  stddev?: Maybe<Mobile_Service_List_Periodicities_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_List_Periodicities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_List_Periodicities_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_List_Periodicities_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_List_Periodicities_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_List_Periodicities_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_List_Periodicities_Variance_Fields>;
};


/** aggregate fields of "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_List_Periodicities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_List_Periodicities_Avg_Fields = {
  __typename?: 'mobile_service_list_periodicities_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_list_periodicities". All fields are combined with a logical 'AND'. */
export type Mobile_Service_List_Periodicities_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_List_Periodicities_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_List_Periodicities_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  service_lists?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  service_lists_aggregate?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_list_periodicities" */
export enum Mobile_Service_List_Periodicities_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceListPeriodicitiesPkey = 'service_list_periodicities_pkey'
}

/** input type for incrementing numeric columns in table "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  service_lists?: InputMaybe<Mobile_Service_Lists_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_List_Periodicities_Max_Fields = {
  __typename?: 'mobile_service_list_periodicities_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_List_Periodicities_Min_Fields = {
  __typename?: 'mobile_service_list_periodicities_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Mutation_Response = {
  __typename?: 'mobile_service_list_periodicities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_List_Periodicities>;
};

/** input type for inserting object relation for remote table "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Obj_Rel_Insert_Input = {
  data: Mobile_Service_List_Periodicities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_List_Periodicities_On_Conflict>;
};

/** on_conflict condition type for table "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_On_Conflict = {
  constraint: Mobile_Service_List_Periodicities_Constraint;
  update_columns?: Array<Mobile_Service_List_Periodicities_Update_Column>;
  where?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
};

/** Ordering options when selecting data from "service_list_periodicities". */
export type Mobile_Service_List_Periodicities_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  service_lists_aggregate?: InputMaybe<Mobile_Service_Lists_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_list_periodicities */
export type Mobile_Service_List_Periodicities_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_list_periodicities" */
export enum Mobile_Service_List_Periodicities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_List_Periodicities_Stddev_Fields = {
  __typename?: 'mobile_service_list_periodicities_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_List_Periodicities_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_list_periodicities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_List_Periodicities_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_list_periodicities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_list_periodicities" */
export type Mobile_Service_List_Periodicities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_List_Periodicities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_List_Periodicities_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_List_Periodicities_Sum_Fields = {
  __typename?: 'mobile_service_list_periodicities_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_list_periodicities" */
export enum Mobile_Service_List_Periodicities_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_List_Periodicities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_List_Periodicities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_List_Periodicities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_List_Periodicities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_List_Periodicities_Var_Pop_Fields = {
  __typename?: 'mobile_service_list_periodicities_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_List_Periodicities_Var_Samp_Fields = {
  __typename?: 'mobile_service_list_periodicities_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_List_Periodicities_Variance_Fields = {
  __typename?: 'mobile_service_list_periodicities_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_lists" */
export type Mobile_Service_Lists = {
  __typename?: 'mobile_service_lists';
  as_gift?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  service_list_periodicity?: Maybe<Mobile_Service_List_Periodicities>;
  service_list_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  service_tariff?: Maybe<Mobile_Service_Tariffs>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "service_lists" */
export type Mobile_Service_Lists_Aggregate = {
  __typename?: 'mobile_service_lists_aggregate';
  aggregate?: Maybe<Mobile_Service_Lists_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Lists>;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp_Var_Samp>;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Avg = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Corr = {
  arguments: Mobile_Service_Lists_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Mobile_Service_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Max = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Min = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Sum = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Service_Lists_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "service_lists" */
export type Mobile_Service_Lists_Aggregate_Fields = {
  __typename?: 'mobile_service_lists_aggregate_fields';
  avg?: Maybe<Mobile_Service_Lists_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Lists_Max_Fields>;
  min?: Maybe<Mobile_Service_Lists_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Lists_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Lists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Lists_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Lists_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Lists_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Lists_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Lists_Variance_Fields>;
};


/** aggregate fields of "service_lists" */
export type Mobile_Service_Lists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_lists" */
export type Mobile_Service_Lists_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Lists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Lists_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Lists_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Lists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Lists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Lists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Lists_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Lists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Lists_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Lists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_lists" */
export type Mobile_Service_Lists_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Lists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Lists_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Lists_Avg_Fields = {
  __typename?: 'mobile_service_lists_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_lists" */
export type Mobile_Service_Lists_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_lists". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Lists_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Lists_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Lists_Bool_Exp>>;
  as_gift?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  service_list_periodicity?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
  service_list_periodicity_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_tariff?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
  service_tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_lists" */
export enum Mobile_Service_Lists_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceListsPkey = 'service_lists_pkey'
}

/** input type for incrementing numeric columns in table "service_lists" */
export type Mobile_Service_Lists_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  service_list_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_lists" */
export type Mobile_Service_Lists_Insert_Input = {
  as_gift?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  service_list_periodicity?: InputMaybe<Mobile_Service_List_Periodicities_Obj_Rel_Insert_Input>;
  service_list_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff?: InputMaybe<Mobile_Service_Tariffs_Obj_Rel_Insert_Input>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Lists_Max_Fields = {
  __typename?: 'mobile_service_lists_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_lists" */
export type Mobile_Service_Lists_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Lists_Min_Fields = {
  __typename?: 'mobile_service_lists_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_lists" */
export type Mobile_Service_Lists_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_lists" */
export type Mobile_Service_Lists_Mutation_Response = {
  __typename?: 'mobile_service_lists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Lists>;
};

/** on_conflict condition type for table "service_lists" */
export type Mobile_Service_Lists_On_Conflict = {
  constraint: Mobile_Service_Lists_Constraint;
  update_columns?: Array<Mobile_Service_Lists_Update_Column>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};

/** Ordering options when selecting data from "service_lists". */
export type Mobile_Service_Lists_Order_By = {
  as_gift?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity?: InputMaybe<Mobile_Service_List_Periodicities_Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff?: InputMaybe<Mobile_Service_Tariffs_Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_lists */
export type Mobile_Service_Lists_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column {
  /** column name */
  AsGift = 'as_gift',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceListPeriodicityId = 'service_list_periodicity_id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_service_lists_aggregate_bool_exp_avg_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_service_lists_aggregate_bool_exp_bool_and_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AsGift = 'as_gift'
}

/** select "mobile_service_lists_aggregate_bool_exp_bool_or_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AsGift = 'as_gift'
}

/** select "mobile_service_lists_aggregate_bool_exp_corr_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_service_lists_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_service_lists_aggregate_bool_exp_max_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_service_lists_aggregate_bool_exp_min_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_service_lists_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_service_lists_aggregate_bool_exp_sum_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_service_lists_aggregate_bool_exp_var_samp_arguments_columns" columns of table "service_lists" */
export enum Mobile_Service_Lists_Select_Column_Mobile_Service_Lists_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "service_lists" */
export type Mobile_Service_Lists_Set_Input = {
  as_gift?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  service_list_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Lists_Stddev_Fields = {
  __typename?: 'mobile_service_lists_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_lists" */
export type Mobile_Service_Lists_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Lists_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_lists_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_lists" */
export type Mobile_Service_Lists_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Lists_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_lists_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_lists" */
export type Mobile_Service_Lists_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_lists" */
export type Mobile_Service_Lists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Lists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Lists_Stream_Cursor_Value_Input = {
  as_gift?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  service_list_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Lists_Sum_Fields = {
  __typename?: 'mobile_service_lists_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_lists" */
export type Mobile_Service_Lists_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_lists" */
export enum Mobile_Service_Lists_Update_Column {
  /** column name */
  AsGift = 'as_gift',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServiceListPeriodicityId = 'service_list_periodicity_id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Lists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Lists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Lists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Lists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Lists_Var_Pop_Fields = {
  __typename?: 'mobile_service_lists_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_lists" */
export type Mobile_Service_Lists_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Lists_Var_Samp_Fields = {
  __typename?: 'mobile_service_lists_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_lists" */
export type Mobile_Service_Lists_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Lists_Variance_Fields = {
  __typename?: 'mobile_service_lists_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  service_list_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_lists" */
export type Mobile_Service_Lists_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_list_periodicity_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_orders" */
export type Mobile_Service_Orders = {
  __typename?: 'mobile_service_orders';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  order_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "service_orders" */
export type Mobile_Service_Orders_Aggregate = {
  __typename?: 'mobile_service_orders_aggregate';
  aggregate?: Maybe<Mobile_Service_Orders_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Orders>;
};

/** aggregate fields of "service_orders" */
export type Mobile_Service_Orders_Aggregate_Fields = {
  __typename?: 'mobile_service_orders_aggregate_fields';
  avg?: Maybe<Mobile_Service_Orders_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Orders_Max_Fields>;
  min?: Maybe<Mobile_Service_Orders_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Orders_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Orders_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Orders_Variance_Fields>;
};


/** aggregate fields of "service_orders" */
export type Mobile_Service_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_Orders_Avg_Fields = {
  __typename?: 'mobile_service_orders_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_orders". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Orders_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Orders_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  payment_status?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  service_order_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_orders" */
export enum Mobile_Service_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceOrdersPkey = 'service_orders_pkey'
}

/** input type for incrementing numeric columns in table "service_orders" */
export type Mobile_Service_Orders_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_orders" */
export type Mobile_Service_Orders_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Orders_Max_Fields = {
  __typename?: 'mobile_service_orders_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_Orders_Min_Fields = {
  __typename?: 'mobile_service_orders_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "service_orders" */
export type Mobile_Service_Orders_Mutation_Response = {
  __typename?: 'mobile_service_orders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Orders>;
};

/** on_conflict condition type for table "service_orders" */
export type Mobile_Service_Orders_On_Conflict = {
  constraint: Mobile_Service_Orders_Constraint;
  update_columns?: Array<Mobile_Service_Orders_Update_Column>;
  where?: InputMaybe<Mobile_Service_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "service_orders". */
export type Mobile_Service_Orders_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  service_order_id?: InputMaybe<Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_orders */
export type Mobile_Service_Orders_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_orders" */
export enum Mobile_Service_Orders_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  Phone = 'phone',
  /** column name */
  ServiceOrderId = 'service_order_id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_orders" */
export type Mobile_Service_Orders_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Orders_Stddev_Fields = {
  __typename?: 'mobile_service_orders_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Orders_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_orders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Orders_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_orders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_orders" */
export type Mobile_Service_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Orders_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  service_order_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Orders_Sum_Fields = {
  __typename?: 'mobile_service_orders_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_order_id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_orders" */
export enum Mobile_Service_Orders_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  Phone = 'phone',
  /** column name */
  ServiceOrderId = 'service_order_id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Orders_Var_Pop_Fields = {
  __typename?: 'mobile_service_orders_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Orders_Var_Samp_Fields = {
  __typename?: 'mobile_service_orders_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_Orders_Variance_Fields = {
  __typename?: 'mobile_service_orders_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_order_id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_periodicities" */
export type Mobile_Service_Periodicities = {
  __typename?: 'mobile_service_periodicities';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  services: Array<Mobile_Services>;
  /** An aggregate relationship */
  services_aggregate: Mobile_Services_Aggregate;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "service_periodicities" */
export type Mobile_Service_PeriodicitiesServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


/** columns and relationships of "service_periodicities" */
export type Mobile_Service_PeriodicitiesServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};

/** aggregated selection of "service_periodicities" */
export type Mobile_Service_Periodicities_Aggregate = {
  __typename?: 'mobile_service_periodicities_aggregate';
  aggregate?: Maybe<Mobile_Service_Periodicities_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Periodicities>;
};

/** aggregate fields of "service_periodicities" */
export type Mobile_Service_Periodicities_Aggregate_Fields = {
  __typename?: 'mobile_service_periodicities_aggregate_fields';
  avg?: Maybe<Mobile_Service_Periodicities_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Periodicities_Max_Fields>;
  min?: Maybe<Mobile_Service_Periodicities_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Periodicities_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Periodicities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Periodicities_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Periodicities_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Periodicities_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Periodicities_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Periodicities_Variance_Fields>;
};


/** aggregate fields of "service_periodicities" */
export type Mobile_Service_Periodicities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Periodicities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_Periodicities_Avg_Fields = {
  __typename?: 'mobile_service_periodicities_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_periodicities". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Periodicities_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Periodicities_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Periodicities_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  services?: InputMaybe<Mobile_Services_Bool_Exp>;
  services_aggregate?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_periodicities" */
export enum Mobile_Service_Periodicities_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicePeriodicitiesPkey = 'service_periodicities_pkey'
}

/** input type for incrementing numeric columns in table "service_periodicities" */
export type Mobile_Service_Periodicities_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_periodicities" */
export type Mobile_Service_Periodicities_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Mobile_Services_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Periodicities_Max_Fields = {
  __typename?: 'mobile_service_periodicities_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_Periodicities_Min_Fields = {
  __typename?: 'mobile_service_periodicities_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "service_periodicities" */
export type Mobile_Service_Periodicities_Mutation_Response = {
  __typename?: 'mobile_service_periodicities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Periodicities>;
};

/** input type for inserting object relation for remote table "service_periodicities" */
export type Mobile_Service_Periodicities_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Periodicities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Periodicities_On_Conflict>;
};

/** on_conflict condition type for table "service_periodicities" */
export type Mobile_Service_Periodicities_On_Conflict = {
  constraint: Mobile_Service_Periodicities_Constraint;
  update_columns?: Array<Mobile_Service_Periodicities_Update_Column>;
  where?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
};

/** Ordering options when selecting data from "service_periodicities". */
export type Mobile_Service_Periodicities_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  services_aggregate?: InputMaybe<Mobile_Services_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_periodicities */
export type Mobile_Service_Periodicities_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_periodicities" */
export enum Mobile_Service_Periodicities_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_periodicities" */
export type Mobile_Service_Periodicities_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Periodicities_Stddev_Fields = {
  __typename?: 'mobile_service_periodicities_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Periodicities_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_periodicities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Periodicities_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_periodicities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_periodicities" */
export type Mobile_Service_Periodicities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Periodicities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Periodicities_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Periodicities_Sum_Fields = {
  __typename?: 'mobile_service_periodicities_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_periodicities" */
export enum Mobile_Service_Periodicities_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Periodicities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Periodicities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Periodicities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Periodicities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Periodicities_Var_Pop_Fields = {
  __typename?: 'mobile_service_periodicities_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Periodicities_Var_Samp_Fields = {
  __typename?: 'mobile_service_periodicities_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_Periodicities_Variance_Fields = {
  __typename?: 'mobile_service_periodicities_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_purchases" */
export type Mobile_Service_Purchases = {
  __typename?: 'mobile_service_purchases';
  author_user_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "service_purchases" */
export type Mobile_Service_Purchases_Aggregate = {
  __typename?: 'mobile_service_purchases_aggregate';
  aggregate?: Maybe<Mobile_Service_Purchases_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Purchases>;
};

export type Mobile_Service_Purchases_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Purchases_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Purchases_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_purchases" */
export type Mobile_Service_Purchases_Aggregate_Fields = {
  __typename?: 'mobile_service_purchases_aggregate_fields';
  avg?: Maybe<Mobile_Service_Purchases_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Purchases_Max_Fields>;
  min?: Maybe<Mobile_Service_Purchases_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Purchases_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Purchases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Purchases_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Purchases_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Purchases_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Purchases_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Purchases_Variance_Fields>;
};


/** aggregate fields of "service_purchases" */
export type Mobile_Service_Purchases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_purchases" */
export type Mobile_Service_Purchases_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Purchases_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Purchases_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Purchases_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Purchases_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Purchases_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Purchases_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Purchases_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Purchases_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Purchases_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Purchases_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_purchases" */
export type Mobile_Service_Purchases_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Purchases_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Purchases_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Purchases_Avg_Fields = {
  __typename?: 'mobile_service_purchases_avg_fields';
  author_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Avg_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_purchases". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Purchases_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Purchases_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Purchases_Bool_Exp>>;
  author_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_purchases" */
export enum Mobile_Service_Purchases_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicePurchasesPkey = 'service_purchases_pkey',
  /** unique or primary key constraint on columns "user_id", "service_id" */
  ServicePurchasesUniqueUserIdServiceId = 'service_purchases_unique_user_id_service_id'
}

/** input type for incrementing numeric columns in table "service_purchases" */
export type Mobile_Service_Purchases_Inc_Input = {
  author_user_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_purchases" */
export type Mobile_Service_Purchases_Insert_Input = {
  author_user_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Purchases_Max_Fields = {
  __typename?: 'mobile_service_purchases_max_fields';
  author_user_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Max_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Purchases_Min_Fields = {
  __typename?: 'mobile_service_purchases_min_fields';
  author_user_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Min_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_purchases" */
export type Mobile_Service_Purchases_Mutation_Response = {
  __typename?: 'mobile_service_purchases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Purchases>;
};

/** on_conflict condition type for table "service_purchases" */
export type Mobile_Service_Purchases_On_Conflict = {
  constraint: Mobile_Service_Purchases_Constraint;
  update_columns?: Array<Mobile_Service_Purchases_Update_Column>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};

/** Ordering options when selecting data from "service_purchases". */
export type Mobile_Service_Purchases_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_purchases */
export type Mobile_Service_Purchases_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_purchases" */
export enum Mobile_Service_Purchases_Select_Column {
  /** column name */
  AuthorUserId = 'author_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_purchases" */
export type Mobile_Service_Purchases_Set_Input = {
  author_user_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Purchases_Stddev_Fields = {
  __typename?: 'mobile_service_purchases_stddev_fields';
  author_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Stddev_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Purchases_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_purchases_stddev_pop_fields';
  author_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Stddev_Pop_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Purchases_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_purchases_stddev_samp_fields';
  author_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Stddev_Samp_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_purchases" */
export type Mobile_Service_Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Purchases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Purchases_Stream_Cursor_Value_Input = {
  author_user_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Purchases_Sum_Fields = {
  __typename?: 'mobile_service_purchases_sum_fields';
  author_user_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Sum_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_purchases" */
export enum Mobile_Service_Purchases_Update_Column {
  /** column name */
  AuthorUserId = 'author_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Purchases_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Purchases_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Purchases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Purchases_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Purchases_Var_Pop_Fields = {
  __typename?: 'mobile_service_purchases_var_pop_fields';
  author_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Var_Pop_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Purchases_Var_Samp_Fields = {
  __typename?: 'mobile_service_purchases_var_samp_fields';
  author_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Var_Samp_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Purchases_Variance_Fields = {
  __typename?: 'mobile_service_purchases_variance_fields';
  author_user_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_purchases" */
export type Mobile_Service_Purchases_Variance_Order_By = {
  author_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_questions" */
export type Mobile_Service_Questions = {
  __typename?: 'mobile_service_questions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  message: Scalars['String']['output'];
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  /** An object relationship */
  service_answer?: Maybe<Mobile_Service_Answers>;
  /** An array relationship */
  service_answers: Array<Mobile_Service_Answers>;
  /** An aggregate relationship */
  service_answers_aggregate: Mobile_Service_Answers_Aggregate;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};


/** columns and relationships of "service_questions" */
export type Mobile_Service_QuestionsService_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answers_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};


/** columns and relationships of "service_questions" */
export type Mobile_Service_QuestionsService_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answers_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};

/** aggregated selection of "service_questions" */
export type Mobile_Service_Questions_Aggregate = {
  __typename?: 'mobile_service_questions_aggregate';
  aggregate?: Maybe<Mobile_Service_Questions_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Questions>;
};

export type Mobile_Service_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Questions_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_questions" */
export type Mobile_Service_Questions_Aggregate_Fields = {
  __typename?: 'mobile_service_questions_aggregate_fields';
  avg?: Maybe<Mobile_Service_Questions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Questions_Max_Fields>;
  min?: Maybe<Mobile_Service_Questions_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Questions_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Questions_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Questions_Variance_Fields>;
};


/** aggregate fields of "service_questions" */
export type Mobile_Service_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_questions" */
export type Mobile_Service_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Questions_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Questions_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Questions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_questions" */
export type Mobile_Service_Questions_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Questions_Avg_Fields = {
  __typename?: 'mobile_service_questions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_questions" */
export type Mobile_Service_Questions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_questions". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Questions_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Questions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamp_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_answer?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
  service_answers?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
  service_answers_aggregate?: InputMaybe<Mobile_Service_Answers_Aggregate_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_questions" */
export enum Mobile_Service_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceQuestionsPkey = 'service_questions_pkey'
}

/** input type for incrementing numeric columns in table "service_questions" */
export type Mobile_Service_Questions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_questions" */
export type Mobile_Service_Questions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_answer?: InputMaybe<Mobile_Service_Answers_Obj_Rel_Insert_Input>;
  service_answers?: InputMaybe<Mobile_Service_Answers_Arr_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Questions_Max_Fields = {
  __typename?: 'mobile_service_questions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_questions" */
export type Mobile_Service_Questions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Questions_Min_Fields = {
  __typename?: 'mobile_service_questions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locked_at?: Maybe<Scalars['timestamp']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_questions" */
export type Mobile_Service_Questions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_questions" */
export type Mobile_Service_Questions_Mutation_Response = {
  __typename?: 'mobile_service_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Questions>;
};

/** input type for inserting object relation for remote table "service_questions" */
export type Mobile_Service_Questions_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Questions_On_Conflict>;
};

/** on_conflict condition type for table "service_questions" */
export type Mobile_Service_Questions_On_Conflict = {
  constraint: Mobile_Service_Questions_Constraint;
  update_columns?: Array<Mobile_Service_Questions_Update_Column>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "service_questions". */
export type Mobile_Service_Questions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_answer?: InputMaybe<Mobile_Service_Answers_Order_By>;
  service_answers_aggregate?: InputMaybe<Mobile_Service_Answers_Aggregate_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_questions */
export type Mobile_Service_Questions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_questions" */
export enum Mobile_Service_Questions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_questions" */
export type Mobile_Service_Questions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Questions_Stddev_Fields = {
  __typename?: 'mobile_service_questions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_questions" */
export type Mobile_Service_Questions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Questions_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_questions" */
export type Mobile_Service_Questions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Questions_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_questions" */
export type Mobile_Service_Questions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_questions" */
export type Mobile_Service_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Questions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locked_at?: InputMaybe<Scalars['timestamp']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Questions_Sum_Fields = {
  __typename?: 'mobile_service_questions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_questions" */
export type Mobile_Service_Questions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_questions" */
export enum Mobile_Service_Questions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Questions_Var_Pop_Fields = {
  __typename?: 'mobile_service_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_questions" */
export type Mobile_Service_Questions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Questions_Var_Samp_Fields = {
  __typename?: 'mobile_service_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_questions" */
export type Mobile_Service_Questions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Questions_Variance_Fields = {
  __typename?: 'mobile_service_questions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_questions" */
export type Mobile_Service_Questions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_rate_comments" */
export type Mobile_Service_Rate_Comments = {
  __typename?: 'mobile_service_rate_comments';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "service_rate_comments" */
export type Mobile_Service_Rate_Comments_Aggregate = {
  __typename?: 'mobile_service_rate_comments_aggregate';
  aggregate?: Maybe<Mobile_Service_Rate_Comments_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Rate_Comments>;
};

/** aggregate fields of "service_rate_comments" */
export type Mobile_Service_Rate_Comments_Aggregate_Fields = {
  __typename?: 'mobile_service_rate_comments_aggregate_fields';
  avg?: Maybe<Mobile_Service_Rate_Comments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Rate_Comments_Max_Fields>;
  min?: Maybe<Mobile_Service_Rate_Comments_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Rate_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Rate_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Rate_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Rate_Comments_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Rate_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Rate_Comments_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Rate_Comments_Variance_Fields>;
};


/** aggregate fields of "service_rate_comments" */
export type Mobile_Service_Rate_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Rate_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_Rate_Comments_Avg_Fields = {
  __typename?: 'mobile_service_rate_comments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_rate_comments". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Rate_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Rate_Comments_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Rate_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Rate_Comments_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_rate_comments" */
export enum Mobile_Service_Rate_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceRateCommentsPkey = 'service_rate_comments_pkey'
}

/** input type for incrementing numeric columns in table "service_rate_comments" */
export type Mobile_Service_Rate_Comments_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_rate_comments" */
export type Mobile_Service_Rate_Comments_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Rate_Comments_Max_Fields = {
  __typename?: 'mobile_service_rate_comments_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_Rate_Comments_Min_Fields = {
  __typename?: 'mobile_service_rate_comments_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "service_rate_comments" */
export type Mobile_Service_Rate_Comments_Mutation_Response = {
  __typename?: 'mobile_service_rate_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Rate_Comments>;
};

/** on_conflict condition type for table "service_rate_comments" */
export type Mobile_Service_Rate_Comments_On_Conflict = {
  constraint: Mobile_Service_Rate_Comments_Constraint;
  update_columns?: Array<Mobile_Service_Rate_Comments_Update_Column>;
  where?: InputMaybe<Mobile_Service_Rate_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "service_rate_comments". */
export type Mobile_Service_Rate_Comments_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_rate_comments */
export type Mobile_Service_Rate_Comments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_rate_comments" */
export enum Mobile_Service_Rate_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_rate_comments" */
export type Mobile_Service_Rate_Comments_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Rate_Comments_Stddev_Fields = {
  __typename?: 'mobile_service_rate_comments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Rate_Comments_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_rate_comments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Rate_Comments_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_rate_comments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_rate_comments" */
export type Mobile_Service_Rate_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Rate_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Rate_Comments_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Rate_Comments_Sum_Fields = {
  __typename?: 'mobile_service_rate_comments_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_rate_comments" */
export enum Mobile_Service_Rate_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Rate_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Rate_Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Rate_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Rate_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Rate_Comments_Var_Pop_Fields = {
  __typename?: 'mobile_service_rate_comments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Rate_Comments_Var_Samp_Fields = {
  __typename?: 'mobile_service_rate_comments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_Rate_Comments_Variance_Fields = {
  __typename?: 'mobile_service_rate_comments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_rate_images" */
export type Mobile_Service_Rate_Images = {
  __typename?: 'mobile_service_rate_images';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  image_path?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  service_rate?: Maybe<Mobile_Service_Rates>;
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "service_rate_images" */
export type Mobile_Service_Rate_Images_Aggregate = {
  __typename?: 'mobile_service_rate_images_aggregate';
  aggregate?: Maybe<Mobile_Service_Rate_Images_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Rate_Images>;
};

export type Mobile_Service_Rate_Images_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Rate_Images_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Rate_Images_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_rate_images" */
export type Mobile_Service_Rate_Images_Aggregate_Fields = {
  __typename?: 'mobile_service_rate_images_aggregate_fields';
  avg?: Maybe<Mobile_Service_Rate_Images_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Rate_Images_Max_Fields>;
  min?: Maybe<Mobile_Service_Rate_Images_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Rate_Images_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Rate_Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Rate_Images_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Rate_Images_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Rate_Images_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Rate_Images_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Rate_Images_Variance_Fields>;
};


/** aggregate fields of "service_rate_images" */
export type Mobile_Service_Rate_Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Rate_Images_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Rate_Images_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Rate_Images_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Rate_Images_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Rate_Images_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Rate_Images_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Rate_Images_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Rate_Images_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Rate_Images_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Rate_Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_rate_images" */
export type Mobile_Service_Rate_Images_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Rate_Images_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Rate_Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Rate_Images_Avg_Fields = {
  __typename?: 'mobile_service_rate_images_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_rate_images". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Rate_Images_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Rate_Images_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Rate_Images_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_path?: InputMaybe<String_Comparison_Exp>;
  service_rate?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
  service_rate_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_rate_images" */
export enum Mobile_Service_Rate_Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceRateImagesPkey = 'service_rate_images_pkey'
}

/** input type for incrementing numeric columns in table "service_rate_images" */
export type Mobile_Service_Rate_Images_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_rate_images" */
export type Mobile_Service_Rate_Images_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_path?: InputMaybe<Scalars['String']['input']>;
  service_rate?: InputMaybe<Mobile_Service_Rates_Obj_Rel_Insert_Input>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Rate_Images_Max_Fields = {
  __typename?: 'mobile_service_rate_images_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_path?: Maybe<Scalars['String']['output']>;
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Rate_Images_Min_Fields = {
  __typename?: 'mobile_service_rate_images_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_path?: Maybe<Scalars['String']['output']>;
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_rate_images" */
export type Mobile_Service_Rate_Images_Mutation_Response = {
  __typename?: 'mobile_service_rate_images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Rate_Images>;
};

/** on_conflict condition type for table "service_rate_images" */
export type Mobile_Service_Rate_Images_On_Conflict = {
  constraint: Mobile_Service_Rate_Images_Constraint;
  update_columns?: Array<Mobile_Service_Rate_Images_Update_Column>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};

/** Ordering options when selecting data from "service_rate_images". */
export type Mobile_Service_Rate_Images_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_path?: InputMaybe<Order_By>;
  service_rate?: InputMaybe<Mobile_Service_Rates_Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_rate_images */
export type Mobile_Service_Rate_Images_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_rate_images" */
export enum Mobile_Service_Rate_Images_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  ServiceRateId = 'service_rate_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_rate_images" */
export type Mobile_Service_Rate_Images_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_path?: InputMaybe<Scalars['String']['input']>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Rate_Images_Stddev_Fields = {
  __typename?: 'mobile_service_rate_images_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Rate_Images_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_rate_images_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Rate_Images_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_rate_images_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_rate_images" */
export type Mobile_Service_Rate_Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Rate_Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Rate_Images_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_path?: InputMaybe<Scalars['String']['input']>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Rate_Images_Sum_Fields = {
  __typename?: 'mobile_service_rate_images_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_rate_images" */
export enum Mobile_Service_Rate_Images_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  ServiceRateId = 'service_rate_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Rate_Images_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Rate_Images_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Rate_Images_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Rate_Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Rate_Images_Var_Pop_Fields = {
  __typename?: 'mobile_service_rate_images_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Rate_Images_Var_Samp_Fields = {
  __typename?: 'mobile_service_rate_images_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Rate_Images_Variance_Fields = {
  __typename?: 'mobile_service_rate_images_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_rate_images" */
export type Mobile_Service_Rate_Images_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions = {
  __typename?: 'mobile_service_rate_reactions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  is_like: Scalars['Boolean']['output'];
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Aggregate = {
  __typename?: 'mobile_service_rate_reactions_aggregate';
  aggregate?: Maybe<Mobile_Service_Rate_Reactions_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Rate_Reactions>;
};

export type Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Service_Rate_Reactions_Select_Column_Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Service_Rate_Reactions_Select_Column_Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Aggregate_Fields = {
  __typename?: 'mobile_service_rate_reactions_aggregate_fields';
  avg?: Maybe<Mobile_Service_Rate_Reactions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Rate_Reactions_Max_Fields>;
  min?: Maybe<Mobile_Service_Rate_Reactions_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Rate_Reactions_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Rate_Reactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Rate_Reactions_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Rate_Reactions_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Rate_Reactions_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Rate_Reactions_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Rate_Reactions_Variance_Fields>;
};


/** aggregate fields of "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Rate_Reactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Rate_Reactions_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Rate_Reactions_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Rate_Reactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Rate_Reactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Rate_Reactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Rate_Reactions_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Rate_Reactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Rate_Reactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Rate_Reactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Rate_Reactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Rate_Reactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Rate_Reactions_Avg_Fields = {
  __typename?: 'mobile_service_rate_reactions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_rate_reactions". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Rate_Reactions_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_like?: InputMaybe<Boolean_Comparison_Exp>;
  service_rate_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_rate_reactions" */
export enum Mobile_Service_Rate_Reactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceRateReactionsPkey = 'service_rate_reactions_pkey',
  /** unique or primary key constraint on columns "user_id", "service_rate_id" */
  ServiceRateReactionsUniqueUserIdServiceRateId = 'service_rate_reactions_unique_user_id_service_rate_id'
}

/** input type for incrementing numeric columns in table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_like?: InputMaybe<Scalars['Boolean']['input']>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Rate_Reactions_Max_Fields = {
  __typename?: 'mobile_service_rate_reactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Rate_Reactions_Min_Fields = {
  __typename?: 'mobile_service_rate_reactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Mutation_Response = {
  __typename?: 'mobile_service_rate_reactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Rate_Reactions>;
};

/** on_conflict condition type for table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_On_Conflict = {
  constraint: Mobile_Service_Rate_Reactions_Constraint;
  update_columns?: Array<Mobile_Service_Rate_Reactions_Update_Column>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};

/** Ordering options when selecting data from "service_rate_reactions". */
export type Mobile_Service_Rate_Reactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_like?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_rate_reactions */
export type Mobile_Service_Rate_Reactions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_rate_reactions" */
export enum Mobile_Service_Rate_Reactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLike = 'is_like',
  /** column name */
  ServiceRateId = 'service_rate_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_service_rate_reactions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "service_rate_reactions" */
export enum Mobile_Service_Rate_Reactions_Select_Column_Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsLike = 'is_like'
}

/** select "mobile_service_rate_reactions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "service_rate_reactions" */
export enum Mobile_Service_Rate_Reactions_Select_Column_Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsLike = 'is_like'
}

/** input type for updating data in table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_like?: InputMaybe<Scalars['Boolean']['input']>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Rate_Reactions_Stddev_Fields = {
  __typename?: 'mobile_service_rate_reactions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Rate_Reactions_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_rate_reactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Rate_Reactions_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_rate_reactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Rate_Reactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Rate_Reactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_like?: InputMaybe<Scalars['Boolean']['input']>;
  service_rate_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Rate_Reactions_Sum_Fields = {
  __typename?: 'mobile_service_rate_reactions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_rate_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_rate_reactions" */
export enum Mobile_Service_Rate_Reactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsLike = 'is_like',
  /** column name */
  ServiceRateId = 'service_rate_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Rate_Reactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Rate_Reactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Rate_Reactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Rate_Reactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Rate_Reactions_Var_Pop_Fields = {
  __typename?: 'mobile_service_rate_reactions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Rate_Reactions_Var_Samp_Fields = {
  __typename?: 'mobile_service_rate_reactions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Rate_Reactions_Variance_Fields = {
  __typename?: 'mobile_service_rate_reactions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_rate_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_rate_reactions" */
export type Mobile_Service_Rate_Reactions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_rate_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_rates" */
export type Mobile_Service_Rates = {
  __typename?: 'mobile_service_rates';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  rate?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  service_rate_images: Array<Mobile_Service_Rate_Images>;
  /** An aggregate relationship */
  service_rate_images_aggregate: Mobile_Service_Rate_Images_Aggregate;
  /** An array relationship */
  service_rate_reactions: Array<Mobile_Service_Rate_Reactions>;
  /** An aggregate relationship */
  service_rate_reactions_aggregate: Mobile_Service_Rate_Reactions_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};


/** columns and relationships of "service_rates" */
export type Mobile_Service_RatesService_Rate_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};


/** columns and relationships of "service_rates" */
export type Mobile_Service_RatesService_Rate_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};


/** columns and relationships of "service_rates" */
export type Mobile_Service_RatesService_Rate_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};


/** columns and relationships of "service_rates" */
export type Mobile_Service_RatesService_Rate_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};

/** aggregated selection of "service_rates" */
export type Mobile_Service_Rates_Aggregate = {
  __typename?: 'mobile_service_rates_aggregate';
  aggregate?: Maybe<Mobile_Service_Rates_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Rates>;
};

export type Mobile_Service_Rates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Rates_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Rates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_rates" */
export type Mobile_Service_Rates_Aggregate_Fields = {
  __typename?: 'mobile_service_rates_aggregate_fields';
  avg?: Maybe<Mobile_Service_Rates_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Rates_Max_Fields>;
  min?: Maybe<Mobile_Service_Rates_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Rates_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Rates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Rates_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Rates_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Rates_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Rates_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Rates_Variance_Fields>;
};


/** aggregate fields of "service_rates" */
export type Mobile_Service_Rates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_rates" */
export type Mobile_Service_Rates_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Rates_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Rates_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Rates_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Rates_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Rates_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Rates_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Rates_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Rates_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Rates_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Rates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_rates" */
export type Mobile_Service_Rates_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Rates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Rates_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Rates_Avg_Fields = {
  __typename?: 'mobile_service_rates_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_rates" */
export type Mobile_Service_Rates_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_rates". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Rates_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Rates_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Rates_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  rate?: InputMaybe<Int_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_rate_images?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
  service_rate_images_aggregate?: InputMaybe<Mobile_Service_Rate_Images_Aggregate_Bool_Exp>;
  service_rate_reactions?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
  service_rate_reactions_aggregate?: InputMaybe<Mobile_Service_Rate_Reactions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_rates" */
export enum Mobile_Service_Rates_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceRatesPkey = 'service_rates_pkey',
  /** unique or primary key constraint on columns "user_id", "service_id" */
  ServiceRatesUniqueUserIdServiceId = 'service_rates_unique_user_id_service_id'
}

/** input type for incrementing numeric columns in table "service_rates" */
export type Mobile_Service_Rates_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_rates" */
export type Mobile_Service_Rates_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['Int']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  service_rate_images?: InputMaybe<Mobile_Service_Rate_Images_Arr_Rel_Insert_Input>;
  service_rate_reactions?: InputMaybe<Mobile_Service_Rate_Reactions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Rates_Max_Fields = {
  __typename?: 'mobile_service_rates_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_rates" */
export type Mobile_Service_Rates_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Rates_Min_Fields = {
  __typename?: 'mobile_service_rates_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_rates" */
export type Mobile_Service_Rates_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_rates" */
export type Mobile_Service_Rates_Mutation_Response = {
  __typename?: 'mobile_service_rates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Rates>;
};

/** input type for inserting object relation for remote table "service_rates" */
export type Mobile_Service_Rates_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Rates_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Rates_On_Conflict>;
};

/** on_conflict condition type for table "service_rates" */
export type Mobile_Service_Rates_On_Conflict = {
  constraint: Mobile_Service_Rates_Constraint;
  update_columns?: Array<Mobile_Service_Rates_Update_Column>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};

/** Ordering options when selecting data from "service_rates". */
export type Mobile_Service_Rates_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  service_rate_images_aggregate?: InputMaybe<Mobile_Service_Rate_Images_Aggregate_Order_By>;
  service_rate_reactions_aggregate?: InputMaybe<Mobile_Service_Rate_Reactions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_rates */
export type Mobile_Service_Rates_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_rates" */
export enum Mobile_Service_Rates_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rate = 'rate',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_rates" */
export type Mobile_Service_Rates_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Rates_Stddev_Fields = {
  __typename?: 'mobile_service_rates_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_rates" */
export type Mobile_Service_Rates_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Rates_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_rates_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_rates" */
export type Mobile_Service_Rates_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Rates_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_rates_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_rates" */
export type Mobile_Service_Rates_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_rates" */
export type Mobile_Service_Rates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Rates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Rates_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['Int']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Rates_Sum_Fields = {
  __typename?: 'mobile_service_rates_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['Int']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_rates" */
export type Mobile_Service_Rates_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_rates" */
export enum Mobile_Service_Rates_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Rate = 'rate',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Rates_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Rates_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Rates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Rates_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Rates_Var_Pop_Fields = {
  __typename?: 'mobile_service_rates_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_rates" */
export type Mobile_Service_Rates_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Rates_Var_Samp_Fields = {
  __typename?: 'mobile_service_rates_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_rates" */
export type Mobile_Service_Rates_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Rates_Variance_Fields = {
  __typename?: 'mobile_service_rates_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_rates" */
export type Mobile_Service_Rates_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_statuses" */
export type Mobile_Service_Statuses = {
  __typename?: 'mobile_service_statuses';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  services: Array<Mobile_Services>;
  /** An aggregate relationship */
  services_aggregate: Mobile_Services_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "service_statuses" */
export type Mobile_Service_StatusesServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


/** columns and relationships of "service_statuses" */
export type Mobile_Service_StatusesServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};

/** aggregated selection of "service_statuses" */
export type Mobile_Service_Statuses_Aggregate = {
  __typename?: 'mobile_service_statuses_aggregate';
  aggregate?: Maybe<Mobile_Service_Statuses_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Statuses>;
};

/** aggregate fields of "service_statuses" */
export type Mobile_Service_Statuses_Aggregate_Fields = {
  __typename?: 'mobile_service_statuses_aggregate_fields';
  avg?: Maybe<Mobile_Service_Statuses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Statuses_Max_Fields>;
  min?: Maybe<Mobile_Service_Statuses_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Statuses_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Statuses_Variance_Fields>;
};


/** aggregate fields of "service_statuses" */
export type Mobile_Service_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_Statuses_Avg_Fields = {
  __typename?: 'mobile_service_statuses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_statuses". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  services?: InputMaybe<Mobile_Services_Bool_Exp>;
  services_aggregate?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_statuses" */
export enum Mobile_Service_Statuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceStatusesPkey = 'service_statuses_pkey'
}

/** input type for incrementing numeric columns in table "service_statuses" */
export type Mobile_Service_Statuses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_statuses" */
export type Mobile_Service_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Mobile_Services_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Statuses_Max_Fields = {
  __typename?: 'mobile_service_statuses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_Statuses_Min_Fields = {
  __typename?: 'mobile_service_statuses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "service_statuses" */
export type Mobile_Service_Statuses_Mutation_Response = {
  __typename?: 'mobile_service_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Statuses>;
};

/** input type for inserting object relation for remote table "service_statuses" */
export type Mobile_Service_Statuses_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "service_statuses" */
export type Mobile_Service_Statuses_On_Conflict = {
  constraint: Mobile_Service_Statuses_Constraint;
  update_columns?: Array<Mobile_Service_Statuses_Update_Column>;
  where?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "service_statuses". */
export type Mobile_Service_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  services_aggregate?: InputMaybe<Mobile_Services_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_statuses */
export type Mobile_Service_Statuses_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_statuses" */
export enum Mobile_Service_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_statuses" */
export type Mobile_Service_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Statuses_Stddev_Fields = {
  __typename?: 'mobile_service_statuses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Statuses_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_statuses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Statuses_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_statuses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_statuses" */
export type Mobile_Service_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Statuses_Sum_Fields = {
  __typename?: 'mobile_service_statuses_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_statuses" */
export enum Mobile_Service_Statuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Statuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Statuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Statuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Statuses_Var_Pop_Fields = {
  __typename?: 'mobile_service_statuses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Statuses_Var_Samp_Fields = {
  __typename?: 'mobile_service_statuses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_Statuses_Variance_Fields = {
  __typename?: 'mobile_service_statuses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_tag_ages" */
export type Mobile_Service_Tag_Ages = {
  __typename?: 'mobile_service_tag_ages';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  tag_age?: Maybe<Mobile_Tag_Ages>;
  tag_age_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Aggregate = {
  __typename?: 'mobile_service_tag_ages_aggregate';
  aggregate?: Maybe<Mobile_Service_Tag_Ages_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Tag_Ages>;
};

export type Mobile_Service_Tag_Ages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Tag_Ages_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Tag_Ages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Aggregate_Fields = {
  __typename?: 'mobile_service_tag_ages_aggregate_fields';
  avg?: Maybe<Mobile_Service_Tag_Ages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Tag_Ages_Max_Fields>;
  min?: Maybe<Mobile_Service_Tag_Ages_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Tag_Ages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Tag_Ages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Tag_Ages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Tag_Ages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Tag_Ages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Tag_Ages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Tag_Ages_Variance_Fields>;
};


/** aggregate fields of "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Tag_Ages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Tag_Ages_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Tag_Ages_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Tag_Ages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Tag_Ages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Tag_Ages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Tag_Ages_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Tag_Ages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Tag_Ages_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Tag_Ages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Tag_Ages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Tag_Ages_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Tag_Ages_Avg_Fields = {
  __typename?: 'mobile_service_tag_ages_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_age_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_tag_ages". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Tag_Ages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Tag_Ages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Tag_Ages_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  tag_age?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
  tag_age_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_tag_ages" */
export enum Mobile_Service_Tag_Ages_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTagAgesPkey = 'service_tag_ages_pkey'
}

/** input type for incrementing numeric columns in table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_age_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_age?: InputMaybe<Mobile_Tag_Ages_Obj_Rel_Insert_Input>;
  tag_age_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Tag_Ages_Max_Fields = {
  __typename?: 'mobile_service_tag_ages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_age_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Tag_Ages_Min_Fields = {
  __typename?: 'mobile_service_tag_ages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_age_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Mutation_Response = {
  __typename?: 'mobile_service_tag_ages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Tag_Ages>;
};

/** on_conflict condition type for table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_On_Conflict = {
  constraint: Mobile_Service_Tag_Ages_Constraint;
  update_columns?: Array<Mobile_Service_Tag_Ages_Update_Column>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};

/** Ordering options when selecting data from "service_tag_ages". */
export type Mobile_Service_Tag_Ages_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age?: InputMaybe<Mobile_Tag_Ages_Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_tag_ages */
export type Mobile_Service_Tag_Ages_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_tag_ages" */
export enum Mobile_Service_Tag_Ages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  TagAgeId = 'tag_age_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_age_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Tag_Ages_Stddev_Fields = {
  __typename?: 'mobile_service_tag_ages_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_age_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Tag_Ages_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_tag_ages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_age_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Tag_Ages_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_tag_ages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_age_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_tag_ages" */
export type Mobile_Service_Tag_Ages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Tag_Ages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Tag_Ages_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_age_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Tag_Ages_Sum_Fields = {
  __typename?: 'mobile_service_tag_ages_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_age_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_tag_ages" */
export enum Mobile_Service_Tag_Ages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  TagAgeId = 'tag_age_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Tag_Ages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Tag_Ages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Tag_Ages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Tag_Ages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Tag_Ages_Var_Pop_Fields = {
  __typename?: 'mobile_service_tag_ages_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_age_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Tag_Ages_Var_Samp_Fields = {
  __typename?: 'mobile_service_tag_ages_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_age_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Tag_Ages_Variance_Fields = {
  __typename?: 'mobile_service_tag_ages_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_age_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_tag_ages" */
export type Mobile_Service_Tag_Ages_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_age_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups = {
  __typename?: 'mobile_service_tag_target_groups';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  tag_target_group?: Maybe<Mobile_Tag_Target_Groups>;
  tag_target_group_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Aggregate = {
  __typename?: 'mobile_service_tag_target_groups_aggregate';
  aggregate?: Maybe<Mobile_Service_Tag_Target_Groups_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Tag_Target_Groups>;
};

export type Mobile_Service_Tag_Target_Groups_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Tag_Target_Groups_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Tag_Target_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Aggregate_Fields = {
  __typename?: 'mobile_service_tag_target_groups_aggregate_fields';
  avg?: Maybe<Mobile_Service_Tag_Target_Groups_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Tag_Target_Groups_Max_Fields>;
  min?: Maybe<Mobile_Service_Tag_Target_Groups_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Tag_Target_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Tag_Target_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Tag_Target_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Tag_Target_Groups_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Tag_Target_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Tag_Target_Groups_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Tag_Target_Groups_Variance_Fields>;
};


/** aggregate fields of "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Tag_Target_Groups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Tag_Target_Groups_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Tag_Target_Groups_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Tag_Target_Groups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Tag_Target_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Tag_Target_Groups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Tag_Target_Groups_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Tag_Target_Groups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Tag_Target_Groups_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Tag_Target_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Tag_Target_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Tag_Target_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Tag_Target_Groups_Avg_Fields = {
  __typename?: 'mobile_service_tag_target_groups_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_tag_target_groups". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Tag_Target_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  tag_target_group?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
  tag_target_group_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_tag_target_groups" */
export enum Mobile_Service_Tag_Target_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTagTargetGroupsPkey = 'service_tag_target_groups_pkey'
}

/** input type for incrementing numeric columns in table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target_group_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target_group?: InputMaybe<Mobile_Tag_Target_Groups_Obj_Rel_Insert_Input>;
  tag_target_group_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Tag_Target_Groups_Max_Fields = {
  __typename?: 'mobile_service_tag_target_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_target_group_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Tag_Target_Groups_Min_Fields = {
  __typename?: 'mobile_service_tag_target_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_target_group_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Mutation_Response = {
  __typename?: 'mobile_service_tag_target_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Tag_Target_Groups>;
};

/** on_conflict condition type for table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_On_Conflict = {
  constraint: Mobile_Service_Tag_Target_Groups_Constraint;
  update_columns?: Array<Mobile_Service_Tag_Target_Groups_Update_Column>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "service_tag_target_groups". */
export type Mobile_Service_Tag_Target_Groups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group?: InputMaybe<Mobile_Tag_Target_Groups_Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_tag_target_groups */
export type Mobile_Service_Tag_Target_Groups_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_tag_target_groups" */
export enum Mobile_Service_Tag_Target_Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  TagTargetGroupId = 'tag_target_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target_group_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Tag_Target_Groups_Stddev_Fields = {
  __typename?: 'mobile_service_tag_target_groups_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Tag_Target_Groups_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_tag_target_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Tag_Target_Groups_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_tag_target_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Tag_Target_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Tag_Target_Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target_group_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Tag_Target_Groups_Sum_Fields = {
  __typename?: 'mobile_service_tag_target_groups_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_target_group_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_tag_target_groups" */
export enum Mobile_Service_Tag_Target_Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  TagTargetGroupId = 'tag_target_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Tag_Target_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Tag_Target_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Tag_Target_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Tag_Target_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Tag_Target_Groups_Var_Pop_Fields = {
  __typename?: 'mobile_service_tag_target_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Tag_Target_Groups_Var_Samp_Fields = {
  __typename?: 'mobile_service_tag_target_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Tag_Target_Groups_Variance_Fields = {
  __typename?: 'mobile_service_tag_target_groups_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_tag_target_groups" */
export type Mobile_Service_Tag_Target_Groups_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_group_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_tag_targets" */
export type Mobile_Service_Tag_Targets = {
  __typename?: 'mobile_service_tag_targets';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  tag_target?: Maybe<Mobile_Tag_Targets>;
  tag_target_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Aggregate = {
  __typename?: 'mobile_service_tag_targets_aggregate';
  aggregate?: Maybe<Mobile_Service_Tag_Targets_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Tag_Targets>;
};

export type Mobile_Service_Tag_Targets_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Tag_Targets_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Tag_Targets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Aggregate_Fields = {
  __typename?: 'mobile_service_tag_targets_aggregate_fields';
  avg?: Maybe<Mobile_Service_Tag_Targets_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Tag_Targets_Max_Fields>;
  min?: Maybe<Mobile_Service_Tag_Targets_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Tag_Targets_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Tag_Targets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Tag_Targets_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Tag_Targets_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Tag_Targets_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Tag_Targets_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Tag_Targets_Variance_Fields>;
};


/** aggregate fields of "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Tag_Targets_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Tag_Targets_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Tag_Targets_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Tag_Targets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Tag_Targets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Tag_Targets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Tag_Targets_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Tag_Targets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Tag_Targets_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Tag_Targets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Tag_Targets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Tag_Targets_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Tag_Targets_Avg_Fields = {
  __typename?: 'mobile_service_tag_targets_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_tag_targets". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Tag_Targets_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Tag_Targets_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Tag_Targets_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  tag_target?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
  tag_target_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_tag_targets" */
export enum Mobile_Service_Tag_Targets_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTagTargetsPkey = 'service_tag_targets_pkey'
}

/** input type for incrementing numeric columns in table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target?: InputMaybe<Mobile_Tag_Targets_Obj_Rel_Insert_Input>;
  tag_target_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Tag_Targets_Max_Fields = {
  __typename?: 'mobile_service_tag_targets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_target_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Tag_Targets_Min_Fields = {
  __typename?: 'mobile_service_tag_targets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_target_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Mutation_Response = {
  __typename?: 'mobile_service_tag_targets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Tag_Targets>;
};

/** on_conflict condition type for table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_On_Conflict = {
  constraint: Mobile_Service_Tag_Targets_Constraint;
  update_columns?: Array<Mobile_Service_Tag_Targets_Update_Column>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};

/** Ordering options when selecting data from "service_tag_targets". */
export type Mobile_Service_Tag_Targets_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target?: InputMaybe<Mobile_Tag_Targets_Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_tag_targets */
export type Mobile_Service_Tag_Targets_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_tag_targets" */
export enum Mobile_Service_Tag_Targets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  TagTargetId = 'tag_target_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Tag_Targets_Stddev_Fields = {
  __typename?: 'mobile_service_tag_targets_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Tag_Targets_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_tag_targets_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Tag_Targets_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_tag_targets_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_tag_targets" */
export type Mobile_Service_Tag_Targets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Tag_Targets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Tag_Targets_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  tag_target_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Tag_Targets_Sum_Fields = {
  __typename?: 'mobile_service_tag_targets_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  tag_target_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_tag_targets" */
export enum Mobile_Service_Tag_Targets_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  TagTargetId = 'tag_target_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Tag_Targets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Tag_Targets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Tag_Targets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Tag_Targets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Tag_Targets_Var_Pop_Fields = {
  __typename?: 'mobile_service_tag_targets_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Tag_Targets_Var_Samp_Fields = {
  __typename?: 'mobile_service_tag_targets_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Tag_Targets_Variance_Fields = {
  __typename?: 'mobile_service_tag_targets_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  tag_target_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_tag_targets" */
export type Mobile_Service_Tag_Targets_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  tag_target_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents = {
  __typename?: 'mobile_service_tariff_documents';
  created_at: Scalars['timestamp']['output'];
  document_path?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  service_tariff?: Maybe<Mobile_Service_Tariffs>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Aggregate = {
  __typename?: 'mobile_service_tariff_documents_aggregate';
  aggregate?: Maybe<Mobile_Service_Tariff_Documents_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Tariff_Documents>;
};

/** aggregate fields of "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Aggregate_Fields = {
  __typename?: 'mobile_service_tariff_documents_aggregate_fields';
  avg?: Maybe<Mobile_Service_Tariff_Documents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Tariff_Documents_Max_Fields>;
  min?: Maybe<Mobile_Service_Tariff_Documents_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Tariff_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Tariff_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Tariff_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Tariff_Documents_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Tariff_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Tariff_Documents_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Tariff_Documents_Variance_Fields>;
};


/** aggregate fields of "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Service_Tariff_Documents_Avg_Fields = {
  __typename?: 'mobile_service_tariff_documents_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "service_tariff_documents". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Tariff_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Tariff_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  document_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service_tariff?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
  service_tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_tariff_documents" */
export enum Mobile_Service_Tariff_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTariffDocumentsPkey = 'service_tariff_documents_pkey'
}

/** input type for incrementing numeric columns in table "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff?: InputMaybe<Mobile_Service_Tariffs_Obj_Rel_Insert_Input>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Tariff_Documents_Max_Fields = {
  __typename?: 'mobile_service_tariff_documents_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Service_Tariff_Documents_Min_Fields = {
  __typename?: 'mobile_service_tariff_documents_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  document_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Mutation_Response = {
  __typename?: 'mobile_service_tariff_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Tariff_Documents>;
};

/** on_conflict condition type for table "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_On_Conflict = {
  constraint: Mobile_Service_Tariff_Documents_Constraint;
  update_columns?: Array<Mobile_Service_Tariff_Documents_Update_Column>;
  where?: InputMaybe<Mobile_Service_Tariff_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "service_tariff_documents". */
export type Mobile_Service_Tariff_Documents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_tariff?: InputMaybe<Mobile_Service_Tariffs_Order_By>;
  service_tariff_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_tariff_documents */
export type Mobile_Service_Tariff_Documents_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_tariff_documents" */
export enum Mobile_Service_Tariff_Documents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Tariff_Documents_Stddev_Fields = {
  __typename?: 'mobile_service_tariff_documents_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Tariff_Documents_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_tariff_documents_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Tariff_Documents_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_tariff_documents_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_service_tariff_documents" */
export type Mobile_Service_Tariff_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Tariff_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Tariff_Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  document_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_tariff_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Tariff_Documents_Sum_Fields = {
  __typename?: 'mobile_service_tariff_documents_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_tariff_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "service_tariff_documents" */
export enum Mobile_Service_Tariff_Documents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentPath = 'document_path',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceTariffId = 'service_tariff_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Tariff_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Tariff_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Tariff_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Tariff_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Tariff_Documents_Var_Pop_Fields = {
  __typename?: 'mobile_service_tariff_documents_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Tariff_Documents_Var_Samp_Fields = {
  __typename?: 'mobile_service_tariff_documents_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Service_Tariff_Documents_Variance_Fields = {
  __typename?: 'mobile_service_tariff_documents_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_tariff_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "service_tariffs" */
export type Mobile_Service_Tariffs = {
  __typename?: 'mobile_service_tariffs';
  catalog_element_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  service?: Maybe<Mobile_Services>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  service_lists: Array<Mobile_Service_Lists>;
  /** An aggregate relationship */
  service_lists_aggregate: Mobile_Service_Lists_Aggregate;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "service_tariffs" */
export type Mobile_Service_TariffsService_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};


/** columns and relationships of "service_tariffs" */
export type Mobile_Service_TariffsService_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};

/** aggregated selection of "service_tariffs" */
export type Mobile_Service_Tariffs_Aggregate = {
  __typename?: 'mobile_service_tariffs_aggregate';
  aggregate?: Maybe<Mobile_Service_Tariffs_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Tariffs>;
};

export type Mobile_Service_Tariffs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Tariffs_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Tariffs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_tariffs" */
export type Mobile_Service_Tariffs_Aggregate_Fields = {
  __typename?: 'mobile_service_tariffs_aggregate_fields';
  avg?: Maybe<Mobile_Service_Tariffs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Tariffs_Max_Fields>;
  min?: Maybe<Mobile_Service_Tariffs_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Tariffs_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Tariffs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Tariffs_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Tariffs_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Tariffs_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Tariffs_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Tariffs_Variance_Fields>;
};


/** aggregate fields of "service_tariffs" */
export type Mobile_Service_Tariffs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_tariffs" */
export type Mobile_Service_Tariffs_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Tariffs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Tariffs_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Tariffs_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Tariffs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Tariffs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Tariffs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Tariffs_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Tariffs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Tariffs_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Tariffs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_tariffs" */
export type Mobile_Service_Tariffs_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Tariffs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Tariffs_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Tariffs_Avg_Fields = {
  __typename?: 'mobile_service_tariffs_avg_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Avg_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_tariffs". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Tariffs_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Tariffs_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Tariffs_Bool_Exp>>;
  catalog_element_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  service?: InputMaybe<Mobile_Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_lists?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
  service_lists_aggregate?: InputMaybe<Mobile_Service_Lists_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_tariffs" */
export enum Mobile_Service_Tariffs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTariffsPkey = 'service_tariffs_pkey'
}

/** input type for incrementing numeric columns in table "service_tariffs" */
export type Mobile_Service_Tariffs_Inc_Input = {
  catalog_element_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_tariffs" */
export type Mobile_Service_Tariffs_Insert_Input = {
  catalog_element_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  service?: InputMaybe<Mobile_Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  service_lists?: InputMaybe<Mobile_Service_Lists_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Tariffs_Max_Fields = {
  __typename?: 'mobile_service_tariffs_max_fields';
  catalog_element_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Max_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Tariffs_Min_Fields = {
  __typename?: 'mobile_service_tariffs_min_fields';
  catalog_element_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Min_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_tariffs" */
export type Mobile_Service_Tariffs_Mutation_Response = {
  __typename?: 'mobile_service_tariffs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Tariffs>;
};

/** input type for inserting object relation for remote table "service_tariffs" */
export type Mobile_Service_Tariffs_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Tariffs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Tariffs_On_Conflict>;
};

/** on_conflict condition type for table "service_tariffs" */
export type Mobile_Service_Tariffs_On_Conflict = {
  constraint: Mobile_Service_Tariffs_Constraint;
  update_columns?: Array<Mobile_Service_Tariffs_Update_Column>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};

/** Ordering options when selecting data from "service_tariffs". */
export type Mobile_Service_Tariffs_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service?: InputMaybe<Mobile_Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  service_lists_aggregate?: InputMaybe<Mobile_Service_Lists_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_tariffs */
export type Mobile_Service_Tariffs_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_tariffs" */
export enum Mobile_Service_Tariffs_Select_Column {
  /** column name */
  CatalogElementId = 'catalog_element_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "service_tariffs" */
export type Mobile_Service_Tariffs_Set_Input = {
  catalog_element_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Tariffs_Stddev_Fields = {
  __typename?: 'mobile_service_tariffs_stddev_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Stddev_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Tariffs_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_tariffs_stddev_pop_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Stddev_Pop_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Tariffs_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_tariffs_stddev_samp_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Stddev_Samp_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_tariffs" */
export type Mobile_Service_Tariffs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Tariffs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Tariffs_Stream_Cursor_Value_Input = {
  catalog_element_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  service_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Tariffs_Sum_Fields = {
  __typename?: 'mobile_service_tariffs_sum_fields';
  catalog_element_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  service_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Sum_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_tariffs" */
export enum Mobile_Service_Tariffs_Update_Column {
  /** column name */
  CatalogElementId = 'catalog_element_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Service_Tariffs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Tariffs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Tariffs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Tariffs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Tariffs_Var_Pop_Fields = {
  __typename?: 'mobile_service_tariffs_var_pop_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Var_Pop_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Tariffs_Var_Samp_Fields = {
  __typename?: 'mobile_service_tariffs_var_samp_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Var_Samp_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Tariffs_Variance_Fields = {
  __typename?: 'mobile_service_tariffs_variance_fields';
  catalog_element_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  service_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_tariffs" */
export type Mobile_Service_Tariffs_Variance_Order_By = {
  catalog_element_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_type_names" */
export type Mobile_Service_Type_Names = {
  __typename?: 'mobile_service_type_names';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  service_type: Mobile_Service_Types;
  service_type_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "service_type_names" */
export type Mobile_Service_Type_Names_Aggregate = {
  __typename?: 'mobile_service_type_names_aggregate';
  aggregate?: Maybe<Mobile_Service_Type_Names_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Type_Names>;
};

export type Mobile_Service_Type_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Type_Names_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Type_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_type_names" */
export type Mobile_Service_Type_Names_Aggregate_Fields = {
  __typename?: 'mobile_service_type_names_aggregate_fields';
  avg?: Maybe<Mobile_Service_Type_Names_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Type_Names_Max_Fields>;
  min?: Maybe<Mobile_Service_Type_Names_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Type_Names_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Type_Names_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Type_Names_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Type_Names_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Type_Names_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Type_Names_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Type_Names_Variance_Fields>;
};


/** aggregate fields of "service_type_names" */
export type Mobile_Service_Type_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_type_names" */
export type Mobile_Service_Type_Names_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Type_Names_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Type_Names_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Type_Names_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Type_Names_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Type_Names_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Type_Names_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Type_Names_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Type_Names_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Type_Names_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Type_Names_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_type_names" */
export type Mobile_Service_Type_Names_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Type_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Type_Names_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Type_Names_Avg_Fields = {
  __typename?: 'mobile_service_type_names_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_type_names". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Type_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Type_Names_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Type_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  service_type?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
  service_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_type_names" */
export enum Mobile_Service_Type_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTypeNamesPkey = 'service_type_names_pkey'
}

/** input type for incrementing numeric columns in table "service_type_names" */
export type Mobile_Service_Type_Names_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "service_type_names" */
export type Mobile_Service_Type_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  service_type?: InputMaybe<Mobile_Service_Types_Obj_Rel_Insert_Input>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Type_Names_Max_Fields = {
  __typename?: 'mobile_service_type_names_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Type_Names_Min_Fields = {
  __typename?: 'mobile_service_type_names_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_type_names" */
export type Mobile_Service_Type_Names_Mutation_Response = {
  __typename?: 'mobile_service_type_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Type_Names>;
};

/** on_conflict condition type for table "service_type_names" */
export type Mobile_Service_Type_Names_On_Conflict = {
  constraint: Mobile_Service_Type_Names_Constraint;
  update_columns?: Array<Mobile_Service_Type_Names_Update_Column>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "service_type_names". */
export type Mobile_Service_Type_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  service_type?: InputMaybe<Mobile_Service_Types_Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_type_names */
export type Mobile_Service_Type_Names_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_type_names" */
export enum Mobile_Service_Type_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ServiceTypeId = 'service_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_type_names" */
export type Mobile_Service_Type_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Type_Names_Stddev_Fields = {
  __typename?: 'mobile_service_type_names_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Type_Names_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_type_names_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Type_Names_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_type_names_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_type_names" */
export type Mobile_Service_Type_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Type_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Type_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Type_Names_Sum_Fields = {
  __typename?: 'mobile_service_type_names_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_type_names" */
export enum Mobile_Service_Type_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  Name = 'name',
  /** column name */
  ServiceTypeId = 'service_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Type_Names_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Type_Names_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Type_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Type_Names_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Type_Names_Var_Pop_Fields = {
  __typename?: 'mobile_service_type_names_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Type_Names_Var_Samp_Fields = {
  __typename?: 'mobile_service_type_names_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Type_Names_Variance_Fields = {
  __typename?: 'mobile_service_type_names_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_type_names" */
export type Mobile_Service_Type_Names_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_types" */
export type Mobile_Service_Types = {
  __typename?: 'mobile_service_types';
  /** An object relationship */
  category: Mobile_Categories;
  category_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  service_type_names: Array<Mobile_Service_Type_Names>;
  /** An aggregate relationship */
  service_type_names_aggregate: Mobile_Service_Type_Names_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "service_types" */
export type Mobile_Service_TypesService_Type_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Type_Names_Order_By>>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};


/** columns and relationships of "service_types" */
export type Mobile_Service_TypesService_Type_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Type_Names_Order_By>>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};

/** aggregated selection of "service_types" */
export type Mobile_Service_Types_Aggregate = {
  __typename?: 'mobile_service_types_aggregate';
  aggregate?: Maybe<Mobile_Service_Types_Aggregate_Fields>;
  nodes: Array<Mobile_Service_Types>;
};

export type Mobile_Service_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Service_Types_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Service_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_types" */
export type Mobile_Service_Types_Aggregate_Fields = {
  __typename?: 'mobile_service_types_aggregate_fields';
  avg?: Maybe<Mobile_Service_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Service_Types_Max_Fields>;
  min?: Maybe<Mobile_Service_Types_Min_Fields>;
  stddev?: Maybe<Mobile_Service_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Service_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Service_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Service_Types_Sum_Fields>;
  var_pop?: Maybe<Mobile_Service_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Service_Types_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Service_Types_Variance_Fields>;
};


/** aggregate fields of "service_types" */
export type Mobile_Service_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_types" */
export type Mobile_Service_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Service_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Service_Types_Max_Order_By>;
  min?: InputMaybe<Mobile_Service_Types_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Service_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Service_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Service_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Service_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Service_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Service_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Service_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_types" */
export type Mobile_Service_Types_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Service_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Service_Types_Avg_Fields = {
  __typename?: 'mobile_service_types_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_types" */
export type Mobile_Service_Types_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_types". All fields are combined with a logical 'AND'. */
export type Mobile_Service_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Service_Types_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Service_Types_Bool_Exp>>;
  category?: InputMaybe<Mobile_Categories_Bool_Exp>;
  category_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service_type_names?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
  service_type_names_aggregate?: InputMaybe<Mobile_Service_Type_Names_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_types" */
export enum Mobile_Service_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceTypesPkey = 'service_types_pkey'
}

/** input type for incrementing numeric columns in table "service_types" */
export type Mobile_Service_Types_Inc_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "service_types" */
export type Mobile_Service_Types_Insert_Input = {
  category?: InputMaybe<Mobile_Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  service_type_names?: InputMaybe<Mobile_Service_Type_Names_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Service_Types_Max_Fields = {
  __typename?: 'mobile_service_types_max_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "service_types" */
export type Mobile_Service_Types_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Service_Types_Min_Fields = {
  __typename?: 'mobile_service_types_min_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "service_types" */
export type Mobile_Service_Types_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_types" */
export type Mobile_Service_Types_Mutation_Response = {
  __typename?: 'mobile_service_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Service_Types>;
};

/** input type for inserting object relation for remote table "service_types" */
export type Mobile_Service_Types_Obj_Rel_Insert_Input = {
  data: Mobile_Service_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Service_Types_On_Conflict>;
};

/** on_conflict condition type for table "service_types" */
export type Mobile_Service_Types_On_Conflict = {
  constraint: Mobile_Service_Types_Constraint;
  update_columns?: Array<Mobile_Service_Types_Update_Column>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "service_types". */
export type Mobile_Service_Types_Order_By = {
  category?: InputMaybe<Mobile_Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_type_names_aggregate?: InputMaybe<Mobile_Service_Type_Names_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_types */
export type Mobile_Service_Types_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "service_types" */
export enum Mobile_Service_Types_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_types" */
export type Mobile_Service_Types_Set_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Service_Types_Stddev_Fields = {
  __typename?: 'mobile_service_types_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_types" */
export type Mobile_Service_Types_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Service_Types_Stddev_Pop_Fields = {
  __typename?: 'mobile_service_types_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_types" */
export type Mobile_Service_Types_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Service_Types_Stddev_Samp_Fields = {
  __typename?: 'mobile_service_types_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_types" */
export type Mobile_Service_Types_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_service_types" */
export type Mobile_Service_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Service_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Service_Types_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Service_Types_Sum_Fields = {
  __typename?: 'mobile_service_types_sum_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "service_types" */
export type Mobile_Service_Types_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** update columns of table "service_types" */
export enum Mobile_Service_Types_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Service_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Service_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Service_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Service_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Service_Types_Var_Pop_Fields = {
  __typename?: 'mobile_service_types_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_types" */
export type Mobile_Service_Types_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Service_Types_Var_Samp_Fields = {
  __typename?: 'mobile_service_types_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_types" */
export type Mobile_Service_Types_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Service_Types_Variance_Fields = {
  __typename?: 'mobile_service_types_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_types" */
export type Mobile_Service_Types_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** columns and relationships of "services" */
export type Mobile_Services = {
  __typename?: 'mobile_services';
  /** An array relationship */
  auto_replies: Array<Mobile_Auto_Replies>;
  /** An aggregate relationship */
  auto_replies_aggregate: Mobile_Auto_Replies_Aggregate;
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  detailed_message?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  instruction?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  params_services: Array<Mobile_Params_Services>;
  /** An aggregate relationship */
  params_services_aggregate: Mobile_Params_Services_Aggregate;
  price?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  service_agreement?: Maybe<Mobile_Service_Agreements>;
  /** An array relationship */
  service_documents: Array<Mobile_Service_Documents>;
  /** An aggregate relationship */
  service_documents_aggregate: Mobile_Service_Documents_Aggregate;
  /** An array relationship */
  service_favorites: Array<Mobile_Service_Favorites>;
  /** An aggregate relationship */
  service_favorites_aggregate: Mobile_Service_Favorites_Aggregate;
  /** An array relationship */
  service_images: Array<Mobile_Service_Images>;
  /** An aggregate relationship */
  service_images_aggregate: Mobile_Service_Images_Aggregate;
  /** An array relationship */
  service_likes: Array<Mobile_Service_Likes>;
  /** An aggregate relationship */
  service_likes_aggregate: Mobile_Service_Likes_Aggregate;
  /** An object relationship */
  service_periodicity?: Maybe<Mobile_Service_Periodicities>;
  service_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  service_purchases: Array<Mobile_Service_Purchases>;
  /** An aggregate relationship */
  service_purchases_aggregate: Mobile_Service_Purchases_Aggregate;
  /** An array relationship */
  service_questions: Array<Mobile_Service_Questions>;
  /** An aggregate relationship */
  service_questions_aggregate: Mobile_Service_Questions_Aggregate;
  /** An array relationship */
  service_rates: Array<Mobile_Service_Rates>;
  /** An aggregate relationship */
  service_rates_aggregate: Mobile_Service_Rates_Aggregate;
  /** An object relationship */
  service_status?: Maybe<Mobile_Service_Statuses>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  service_tag_ages: Array<Mobile_Service_Tag_Ages>;
  /** An aggregate relationship */
  service_tag_ages_aggregate: Mobile_Service_Tag_Ages_Aggregate;
  /** An array relationship */
  service_tag_target_groups: Array<Mobile_Service_Tag_Target_Groups>;
  /** An aggregate relationship */
  service_tag_target_groups_aggregate: Mobile_Service_Tag_Target_Groups_Aggregate;
  /** An array relationship */
  service_tag_targets: Array<Mobile_Service_Tag_Targets>;
  /** An aggregate relationship */
  service_tag_targets_aggregate: Mobile_Service_Tag_Targets_Aggregate;
  /** An array relationship */
  service_tariffs: Array<Mobile_Service_Tariffs>;
  /** An aggregate relationship */
  service_tariffs_aggregate: Mobile_Service_Tariffs_Aggregate;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesAuto_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesAuto_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesParams_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesParams_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_FavoritesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Favorites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_RatesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Tag_AgesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Tag_Ages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Tag_Target_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Tag_Target_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Tag_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Tag_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_TariffsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariffs_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};


/** columns and relationships of "services" */
export type Mobile_ServicesService_Tariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariffs_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};

/** aggregated selection of "services" */
export type Mobile_Services_Aggregate = {
  __typename?: 'mobile_services_aggregate';
  aggregate?: Maybe<Mobile_Services_Aggregate_Fields>;
  nodes: Array<Mobile_Services>;
};

export type Mobile_Services_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Mobile_Services_Aggregate_Bool_Exp_Var_Samp>;
};

export type Mobile_Services_Aggregate_Bool_Exp_Avg = {
  arguments: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Corr = {
  arguments: Mobile_Services_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Mobile_Services_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Mobile_Services_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Mobile_Services_Aggregate_Bool_Exp_Max = {
  arguments: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Min = {
  arguments: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Sum = {
  arguments: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_Services_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Services_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "services" */
export type Mobile_Services_Aggregate_Fields = {
  __typename?: 'mobile_services_aggregate_fields';
  avg?: Maybe<Mobile_Services_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Services_Max_Fields>;
  min?: Maybe<Mobile_Services_Min_Fields>;
  stddev?: Maybe<Mobile_Services_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Services_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Services_Sum_Fields>;
  var_pop?: Maybe<Mobile_Services_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Services_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Services_Variance_Fields>;
};


/** aggregate fields of "services" */
export type Mobile_Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "services" */
export type Mobile_Services_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Services_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Services_Max_Order_By>;
  min?: InputMaybe<Mobile_Services_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Services_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Services_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Services_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Services_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Services_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Services_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "services" */
export type Mobile_Services_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Services_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Services_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Services_Avg_Fields = {
  __typename?: 'mobile_services_avg_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "services" */
export type Mobile_Services_Avg_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "services". All fields are combined with a logical 'AND'. */
export type Mobile_Services_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Services_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Services_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Services_Bool_Exp>>;
  auto_replies?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
  auto_replies_aggregate?: InputMaybe<Mobile_Auto_Replies_Aggregate_Bool_Exp>;
  category_profession_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  detailed_message?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  instruction?: InputMaybe<String_Comparison_Exp>;
  locked_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  params_services?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Bool_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  quantity?: InputMaybe<Float8_Comparison_Exp>;
  rate?: InputMaybe<Numeric_Comparison_Exp>;
  service_agreement?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
  service_documents?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
  service_documents_aggregate?: InputMaybe<Mobile_Service_Documents_Aggregate_Bool_Exp>;
  service_favorites?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
  service_favorites_aggregate?: InputMaybe<Mobile_Service_Favorites_Aggregate_Bool_Exp>;
  service_images?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
  service_images_aggregate?: InputMaybe<Mobile_Service_Images_Aggregate_Bool_Exp>;
  service_likes?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
  service_likes_aggregate?: InputMaybe<Mobile_Service_Likes_Aggregate_Bool_Exp>;
  service_periodicity?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
  service_periodicity_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_purchases?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
  service_purchases_aggregate?: InputMaybe<Mobile_Service_Purchases_Aggregate_Bool_Exp>;
  service_questions?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
  service_questions_aggregate?: InputMaybe<Mobile_Service_Questions_Aggregate_Bool_Exp>;
  service_rates?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
  service_rates_aggregate?: InputMaybe<Mobile_Service_Rates_Aggregate_Bool_Exp>;
  service_status?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
  service_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_tag_ages?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
  service_tag_ages_aggregate?: InputMaybe<Mobile_Service_Tag_Ages_Aggregate_Bool_Exp>;
  service_tag_target_groups?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
  service_tag_target_groups_aggregate?: InputMaybe<Mobile_Service_Tag_Target_Groups_Aggregate_Bool_Exp>;
  service_tag_targets?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
  service_tag_targets_aggregate?: InputMaybe<Mobile_Service_Tag_Targets_Aggregate_Bool_Exp>;
  service_tariffs?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
  service_tariffs_aggregate?: InputMaybe<Mobile_Service_Tariffs_Aggregate_Bool_Exp>;
  service_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "services" */
export enum Mobile_Services_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicesPkey = 'services_pkey'
}

/** input type for incrementing numeric columns in table "services" */
export type Mobile_Services_Inc_Input = {
  category_profession_id?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  service_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_status_id?: InputMaybe<Scalars['bigint']['input']>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "services" */
export type Mobile_Services_Insert_Input = {
  auto_replies?: InputMaybe<Mobile_Auto_Replies_Arr_Rel_Insert_Input>;
  category_profession_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  detailed_message?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  instruction?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  params_services?: InputMaybe<Mobile_Params_Services_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  service_agreement?: InputMaybe<Mobile_Service_Agreements_Obj_Rel_Insert_Input>;
  service_documents?: InputMaybe<Mobile_Service_Documents_Arr_Rel_Insert_Input>;
  service_favorites?: InputMaybe<Mobile_Service_Favorites_Arr_Rel_Insert_Input>;
  service_images?: InputMaybe<Mobile_Service_Images_Arr_Rel_Insert_Input>;
  service_likes?: InputMaybe<Mobile_Service_Likes_Arr_Rel_Insert_Input>;
  service_periodicity?: InputMaybe<Mobile_Service_Periodicities_Obj_Rel_Insert_Input>;
  service_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_purchases?: InputMaybe<Mobile_Service_Purchases_Arr_Rel_Insert_Input>;
  service_questions?: InputMaybe<Mobile_Service_Questions_Arr_Rel_Insert_Input>;
  service_rates?: InputMaybe<Mobile_Service_Rates_Arr_Rel_Insert_Input>;
  service_status?: InputMaybe<Mobile_Service_Statuses_Obj_Rel_Insert_Input>;
  service_status_id?: InputMaybe<Scalars['bigint']['input']>;
  service_tag_ages?: InputMaybe<Mobile_Service_Tag_Ages_Arr_Rel_Insert_Input>;
  service_tag_target_groups?: InputMaybe<Mobile_Service_Tag_Target_Groups_Arr_Rel_Insert_Input>;
  service_tag_targets?: InputMaybe<Mobile_Service_Tag_Targets_Arr_Rel_Insert_Input>;
  service_tariffs?: InputMaybe<Mobile_Service_Tariffs_Arr_Rel_Insert_Input>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Services_Max_Fields = {
  __typename?: 'mobile_services_max_fields';
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  detailed_message?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  instruction?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  service_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "services" */
export type Mobile_Services_Max_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  detailed_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instruction?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Services_Min_Fields = {
  __typename?: 'mobile_services_min_fields';
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  detailed_message?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  instruction?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  service_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "services" */
export type Mobile_Services_Min_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  detailed_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instruction?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "services" */
export type Mobile_Services_Mutation_Response = {
  __typename?: 'mobile_services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Services>;
};

/** input type for inserting object relation for remote table "services" */
export type Mobile_Services_Obj_Rel_Insert_Input = {
  data: Mobile_Services_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Services_On_Conflict>;
};

/** on_conflict condition type for table "services" */
export type Mobile_Services_On_Conflict = {
  constraint: Mobile_Services_Constraint;
  update_columns?: Array<Mobile_Services_Update_Column>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};

/** Ordering options when selecting data from "services". */
export type Mobile_Services_Order_By = {
  auto_replies_aggregate?: InputMaybe<Mobile_Auto_Replies_Aggregate_Order_By>;
  category_profession_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  detailed_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instruction?: InputMaybe<Order_By>;
  locked_at?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  params_services_aggregate?: InputMaybe<Mobile_Params_Services_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_agreement?: InputMaybe<Mobile_Service_Agreements_Order_By>;
  service_documents_aggregate?: InputMaybe<Mobile_Service_Documents_Aggregate_Order_By>;
  service_favorites_aggregate?: InputMaybe<Mobile_Service_Favorites_Aggregate_Order_By>;
  service_images_aggregate?: InputMaybe<Mobile_Service_Images_Aggregate_Order_By>;
  service_likes_aggregate?: InputMaybe<Mobile_Service_Likes_Aggregate_Order_By>;
  service_periodicity?: InputMaybe<Mobile_Service_Periodicities_Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_purchases_aggregate?: InputMaybe<Mobile_Service_Purchases_Aggregate_Order_By>;
  service_questions_aggregate?: InputMaybe<Mobile_Service_Questions_Aggregate_Order_By>;
  service_rates_aggregate?: InputMaybe<Mobile_Service_Rates_Aggregate_Order_By>;
  service_status?: InputMaybe<Mobile_Service_Statuses_Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_tag_ages_aggregate?: InputMaybe<Mobile_Service_Tag_Ages_Aggregate_Order_By>;
  service_tag_target_groups_aggregate?: InputMaybe<Mobile_Service_Tag_Target_Groups_Aggregate_Order_By>;
  service_tag_targets_aggregate?: InputMaybe<Mobile_Service_Tag_Targets_Aggregate_Order_By>;
  service_tariffs_aggregate?: InputMaybe<Mobile_Service_Tariffs_Aggregate_Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: services */
export type Mobile_Services_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "services" */
export enum Mobile_Services_Select_Column {
  /** column name */
  CategoryProfessionId = 'category_profession_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DetailedMessage = 'detailed_message',
  /** column name */
  Id = 'id',
  /** column name */
  Instruction = 'instruction',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  Price = 'price',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Rate = 'rate',
  /** column name */
  ServicePeriodicityId = 'service_periodicity_id',
  /** column name */
  ServiceStatusId = 'service_status_id',
  /** column name */
  ServiceTypeId = 'service_type_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_services_aggregate_bool_exp_avg_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_services_aggregate_bool_exp_corr_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_services_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_services_aggregate_bool_exp_max_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_services_aggregate_bool_exp_min_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_services_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_services_aggregate_bool_exp_sum_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** select "mobile_services_aggregate_bool_exp_var_samp_arguments_columns" columns of table "services" */
export enum Mobile_Services_Select_Column_Mobile_Services_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "services" */
export type Mobile_Services_Set_Input = {
  category_profession_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  detailed_message?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  instruction?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  service_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_status_id?: InputMaybe<Scalars['bigint']['input']>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Services_Stddev_Fields = {
  __typename?: 'mobile_services_stddev_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "services" */
export type Mobile_Services_Stddev_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Services_Stddev_Pop_Fields = {
  __typename?: 'mobile_services_stddev_pop_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "services" */
export type Mobile_Services_Stddev_Pop_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Services_Stddev_Samp_Fields = {
  __typename?: 'mobile_services_stddev_samp_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "services" */
export type Mobile_Services_Stddev_Samp_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_services" */
export type Mobile_Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Services_Stream_Cursor_Value_Input = {
  category_profession_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  detailed_message?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  instruction?: InputMaybe<Scalars['String']['input']>;
  locked_at?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  quantity?: InputMaybe<Scalars['float8']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  service_periodicity_id?: InputMaybe<Scalars['bigint']['input']>;
  service_status_id?: InputMaybe<Scalars['bigint']['input']>;
  service_type_id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Services_Sum_Fields = {
  __typename?: 'mobile_services_sum_fields';
  category_profession_id?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  quantity?: Maybe<Scalars['float8']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  service_periodicity_id?: Maybe<Scalars['bigint']['output']>;
  service_status_id?: Maybe<Scalars['bigint']['output']>;
  service_type_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "services" */
export type Mobile_Services_Sum_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "services" */
export enum Mobile_Services_Update_Column {
  /** column name */
  CategoryProfessionId = 'category_profession_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DetailedMessage = 'detailed_message',
  /** column name */
  Id = 'id',
  /** column name */
  Instruction = 'instruction',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Message = 'message',
  /** column name */
  Price = 'price',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Rate = 'rate',
  /** column name */
  ServicePeriodicityId = 'service_periodicity_id',
  /** column name */
  ServiceStatusId = 'service_status_id',
  /** column name */
  ServiceTypeId = 'service_type_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Services_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Services_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Services_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Services_Var_Pop_Fields = {
  __typename?: 'mobile_services_var_pop_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "services" */
export type Mobile_Services_Var_Pop_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Services_Var_Samp_Fields = {
  __typename?: 'mobile_services_var_samp_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "services" */
export type Mobile_Services_Var_Samp_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Services_Variance_Fields = {
  __typename?: 'mobile_services_variance_fields';
  category_profession_id?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  service_periodicity_id?: Maybe<Scalars['Float']['output']>;
  service_status_id?: Maybe<Scalars['Float']['output']>;
  service_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "services" */
export type Mobile_Services_Variance_Order_By = {
  category_profession_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  service_periodicity_id?: InputMaybe<Order_By>;
  service_status_id?: InputMaybe<Order_By>;
  service_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "settings" */
export type Mobile_Settings = {
  __typename?: 'mobile_settings';
  chat_notification?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  confirmation_codes: Array<Mobile_Confirmation_Codes>;
  /** An aggregate relationship */
  confirmation_codes_aggregate: Mobile_Confirmation_Codes_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  notification?: Maybe<Scalars['Boolean']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profiles?: Maybe<Mobile_Profiles>;
  telegram_push?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "settings" */
export type Mobile_SettingsConfirmation_CodesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


/** columns and relationships of "settings" */
export type Mobile_SettingsConfirmation_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};

/** aggregated selection of "settings" */
export type Mobile_Settings_Aggregate = {
  __typename?: 'mobile_settings_aggregate';
  aggregate?: Maybe<Mobile_Settings_Aggregate_Fields>;
  nodes: Array<Mobile_Settings>;
};

export type Mobile_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_Settings_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_Settings_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_Settings_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Settings_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_Settings_Select_Column_Mobile_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_Settings_Select_Column_Mobile_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Settings_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "settings" */
export type Mobile_Settings_Aggregate_Fields = {
  __typename?: 'mobile_settings_aggregate_fields';
  avg?: Maybe<Mobile_Settings_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Settings_Max_Fields>;
  min?: Maybe<Mobile_Settings_Min_Fields>;
  stddev?: Maybe<Mobile_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Settings_Sum_Fields>;
  var_pop?: Maybe<Mobile_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Settings_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Settings_Variance_Fields>;
};


/** aggregate fields of "settings" */
export type Mobile_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "settings" */
export type Mobile_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Settings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Settings_Max_Order_By>;
  min?: InputMaybe<Mobile_Settings_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Settings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Settings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Settings_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Settings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Settings_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Settings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "settings" */
export type Mobile_Settings_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Settings_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Settings_Avg_Fields = {
  __typename?: 'mobile_settings_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "settings" */
export type Mobile_Settings_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "settings". All fields are combined with a logical 'AND'. */
export type Mobile_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Settings_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Settings_Bool_Exp>>;
  chat_notification?: InputMaybe<Boolean_Comparison_Exp>;
  confirmation_codes?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
  confirmation_codes_aggregate?: InputMaybe<Mobile_Confirmation_Codes_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  notification?: InputMaybe<Boolean_Comparison_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  profiles?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  telegram_push?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "settings" */
export enum Mobile_Settings_Constraint {
  /** unique or primary key constraint on columns "profile_id" */
  IndexSettingsOnProfileId = 'index_settings_on_profile_id',
  /** unique or primary key constraint on columns "id" */
  SettingsPkey = 'settings_pkey'
}

/** input type for incrementing numeric columns in table "settings" */
export type Mobile_Settings_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "settings" */
export type Mobile_Settings_Insert_Input = {
  chat_notification?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_codes?: InputMaybe<Mobile_Confirmation_Codes_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notification?: InputMaybe<Scalars['Boolean']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  profiles?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  telegram_push?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Settings_Max_Fields = {
  __typename?: 'mobile_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "settings" */
export type Mobile_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Settings_Min_Fields = {
  __typename?: 'mobile_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "settings" */
export type Mobile_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "settings" */
export type Mobile_Settings_Mutation_Response = {
  __typename?: 'mobile_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Settings>;
};

/** input type for inserting object relation for remote table "settings" */
export type Mobile_Settings_Obj_Rel_Insert_Input = {
  data: Mobile_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Settings_On_Conflict>;
};

/** on_conflict condition type for table "settings" */
export type Mobile_Settings_On_Conflict = {
  constraint: Mobile_Settings_Constraint;
  update_columns?: Array<Mobile_Settings_Update_Column>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "settings". */
export type Mobile_Settings_Order_By = {
  chat_notification?: InputMaybe<Order_By>;
  confirmation_codes_aggregate?: InputMaybe<Mobile_Confirmation_Codes_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  profiles?: InputMaybe<Mobile_Profiles_Order_By>;
  telegram_push?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: settings */
export type Mobile_Settings_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "settings" */
export enum Mobile_Settings_Select_Column {
  /** column name */
  ChatNotification = 'chat_notification',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notification = 'notification',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TelegramPush = 'telegram_push',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "settings" */
export enum Mobile_Settings_Select_Column_Mobile_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ChatNotification = 'chat_notification',
  /** column name */
  Notification = 'notification',
  /** column name */
  TelegramPush = 'telegram_push'
}

/** select "mobile_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "settings" */
export enum Mobile_Settings_Select_Column_Mobile_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ChatNotification = 'chat_notification',
  /** column name */
  Notification = 'notification',
  /** column name */
  TelegramPush = 'telegram_push'
}

/** input type for updating data in table "settings" */
export type Mobile_Settings_Set_Input = {
  chat_notification?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notification?: InputMaybe<Scalars['Boolean']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  telegram_push?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Settings_Stddev_Fields = {
  __typename?: 'mobile_settings_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "settings" */
export type Mobile_Settings_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Settings_Stddev_Pop_Fields = {
  __typename?: 'mobile_settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "settings" */
export type Mobile_Settings_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Settings_Stddev_Samp_Fields = {
  __typename?: 'mobile_settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "settings" */
export type Mobile_Settings_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_settings" */
export type Mobile_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Settings_Stream_Cursor_Value_Input = {
  chat_notification?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notification?: InputMaybe<Scalars['Boolean']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  telegram_push?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Settings_Sum_Fields = {
  __typename?: 'mobile_settings_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "settings" */
export type Mobile_Settings_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "settings" */
export enum Mobile_Settings_Update_Column {
  /** column name */
  ChatNotification = 'chat_notification',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notification = 'notification',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  TelegramPush = 'telegram_push',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Settings_Var_Pop_Fields = {
  __typename?: 'mobile_settings_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "settings" */
export type Mobile_Settings_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Settings_Var_Samp_Fields = {
  __typename?: 'mobile_settings_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "settings" */
export type Mobile_Settings_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Settings_Variance_Fields = {
  __typename?: 'mobile_settings_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "settings" */
export type Mobile_Settings_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "share_posts" */
export type Mobile_Share_Posts = {
  __typename?: 'mobile_share_posts';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  post?: Maybe<Mobile_Posts>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "share_posts" */
export type Mobile_Share_Posts_Aggregate = {
  __typename?: 'mobile_share_posts_aggregate';
  aggregate?: Maybe<Mobile_Share_Posts_Aggregate_Fields>;
  nodes: Array<Mobile_Share_Posts>;
};

export type Mobile_Share_Posts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Share_Posts_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Share_Posts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "share_posts" */
export type Mobile_Share_Posts_Aggregate_Fields = {
  __typename?: 'mobile_share_posts_aggregate_fields';
  avg?: Maybe<Mobile_Share_Posts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Share_Posts_Max_Fields>;
  min?: Maybe<Mobile_Share_Posts_Min_Fields>;
  stddev?: Maybe<Mobile_Share_Posts_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Share_Posts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Share_Posts_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Share_Posts_Sum_Fields>;
  var_pop?: Maybe<Mobile_Share_Posts_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Share_Posts_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Share_Posts_Variance_Fields>;
};


/** aggregate fields of "share_posts" */
export type Mobile_Share_Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "share_posts" */
export type Mobile_Share_Posts_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Share_Posts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Share_Posts_Max_Order_By>;
  min?: InputMaybe<Mobile_Share_Posts_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Share_Posts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Share_Posts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Share_Posts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Share_Posts_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Share_Posts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Share_Posts_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Share_Posts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "share_posts" */
export type Mobile_Share_Posts_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Share_Posts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Share_Posts_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Share_Posts_Avg_Fields = {
  __typename?: 'mobile_share_posts_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "share_posts" */
export type Mobile_Share_Posts_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "share_posts". All fields are combined with a logical 'AND'. */
export type Mobile_Share_Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Share_Posts_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Share_Posts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  post?: InputMaybe<Mobile_Posts_Bool_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "share_posts" */
export enum Mobile_Share_Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  SharePostsPkey = 'share_posts_pkey'
}

/** input type for incrementing numeric columns in table "share_posts" */
export type Mobile_Share_Posts_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "share_posts" */
export type Mobile_Share_Posts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post?: InputMaybe<Mobile_Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Share_Posts_Max_Fields = {
  __typename?: 'mobile_share_posts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "share_posts" */
export type Mobile_Share_Posts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Share_Posts_Min_Fields = {
  __typename?: 'mobile_share_posts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "share_posts" */
export type Mobile_Share_Posts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "share_posts" */
export type Mobile_Share_Posts_Mutation_Response = {
  __typename?: 'mobile_share_posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Share_Posts>;
};

/** on_conflict condition type for table "share_posts" */
export type Mobile_Share_Posts_On_Conflict = {
  constraint: Mobile_Share_Posts_Constraint;
  update_columns?: Array<Mobile_Share_Posts_Update_Column>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "share_posts". */
export type Mobile_Share_Posts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Mobile_Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: share_posts */
export type Mobile_Share_Posts_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "share_posts" */
export enum Mobile_Share_Posts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "share_posts" */
export type Mobile_Share_Posts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Share_Posts_Stddev_Fields = {
  __typename?: 'mobile_share_posts_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "share_posts" */
export type Mobile_Share_Posts_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Share_Posts_Stddev_Pop_Fields = {
  __typename?: 'mobile_share_posts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "share_posts" */
export type Mobile_Share_Posts_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Share_Posts_Stddev_Samp_Fields = {
  __typename?: 'mobile_share_posts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "share_posts" */
export type Mobile_Share_Posts_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_share_posts" */
export type Mobile_Share_Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Share_Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Share_Posts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Share_Posts_Sum_Fields = {
  __typename?: 'mobile_share_posts_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "share_posts" */
export type Mobile_Share_Posts_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "share_posts" */
export enum Mobile_Share_Posts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Share_Posts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Share_Posts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Share_Posts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Share_Posts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Share_Posts_Var_Pop_Fields = {
  __typename?: 'mobile_share_posts_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "share_posts" */
export type Mobile_Share_Posts_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Share_Posts_Var_Samp_Fields = {
  __typename?: 'mobile_share_posts_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "share_posts" */
export type Mobile_Share_Posts_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Share_Posts_Variance_Fields = {
  __typename?: 'mobile_share_posts_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "share_posts" */
export type Mobile_Share_Posts_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "sleep_norms" */
export type Mobile_Sleep_Norms = {
  __typename?: 'mobile_sleep_norms';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm: Scalars['float8']['output'];
  /** An object relationship */
  profile: Mobile_Profiles;
  profile_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "sleep_norms" */
export type Mobile_Sleep_Norms_Aggregate = {
  __typename?: 'mobile_sleep_norms_aggregate';
  aggregate?: Maybe<Mobile_Sleep_Norms_Aggregate_Fields>;
  nodes: Array<Mobile_Sleep_Norms>;
};

/** aggregate fields of "sleep_norms" */
export type Mobile_Sleep_Norms_Aggregate_Fields = {
  __typename?: 'mobile_sleep_norms_aggregate_fields';
  avg?: Maybe<Mobile_Sleep_Norms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Sleep_Norms_Max_Fields>;
  min?: Maybe<Mobile_Sleep_Norms_Min_Fields>;
  stddev?: Maybe<Mobile_Sleep_Norms_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Sleep_Norms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Sleep_Norms_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Sleep_Norms_Sum_Fields>;
  var_pop?: Maybe<Mobile_Sleep_Norms_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Sleep_Norms_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Sleep_Norms_Variance_Fields>;
};


/** aggregate fields of "sleep_norms" */
export type Mobile_Sleep_Norms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Sleep_Norms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Sleep_Norms_Avg_Fields = {
  __typename?: 'mobile_sleep_norms_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sleep_norms". All fields are combined with a logical 'AND'. */
export type Mobile_Sleep_Norms_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Sleep_Norms_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Sleep_Norms_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Sleep_Norms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mentor_norm?: InputMaybe<Float8_Comparison_Exp>;
  mentor_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  norm?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "sleep_norms" */
export enum Mobile_Sleep_Norms_Constraint {
  /** unique or primary key constraint on columns "id" */
  SleepNormsPkey = 'sleep_norms_pkey'
}

/** input type for incrementing numeric columns in table "sleep_norms" */
export type Mobile_Sleep_Norms_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "sleep_norms" */
export type Mobile_Sleep_Norms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Sleep_Norms_Max_Fields = {
  __typename?: 'mobile_sleep_norms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Sleep_Norms_Min_Fields = {
  __typename?: 'mobile_sleep_norms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "sleep_norms" */
export type Mobile_Sleep_Norms_Mutation_Response = {
  __typename?: 'mobile_sleep_norms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Sleep_Norms>;
};

/** on_conflict condition type for table "sleep_norms" */
export type Mobile_Sleep_Norms_On_Conflict = {
  constraint: Mobile_Sleep_Norms_Constraint;
  update_columns?: Array<Mobile_Sleep_Norms_Update_Column>;
  where?: InputMaybe<Mobile_Sleep_Norms_Bool_Exp>;
};

/** Ordering options when selecting data from "sleep_norms". */
export type Mobile_Sleep_Norms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentor_norm?: InputMaybe<Order_By>;
  mentor_user_id?: InputMaybe<Order_By>;
  norm?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sleep_norms */
export type Mobile_Sleep_Norms_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "sleep_norms" */
export enum Mobile_Sleep_Norms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "sleep_norms" */
export type Mobile_Sleep_Norms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Sleep_Norms_Stddev_Fields = {
  __typename?: 'mobile_sleep_norms_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Sleep_Norms_Stddev_Pop_Fields = {
  __typename?: 'mobile_sleep_norms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Sleep_Norms_Stddev_Samp_Fields = {
  __typename?: 'mobile_sleep_norms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_sleep_norms" */
export type Mobile_Sleep_Norms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Sleep_Norms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Sleep_Norms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Sleep_Norms_Sum_Fields = {
  __typename?: 'mobile_sleep_norms_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "sleep_norms" */
export enum Mobile_Sleep_Norms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Sleep_Norms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Sleep_Norms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Sleep_Norms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Sleep_Norms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Sleep_Norms_Var_Pop_Fields = {
  __typename?: 'mobile_sleep_norms_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Sleep_Norms_Var_Samp_Fields = {
  __typename?: 'mobile_sleep_norms_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Sleep_Norms_Variance_Fields = {
  __typename?: 'mobile_sleep_norms_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "sleep_nows" */
export type Mobile_Sleep_Nows = {
  __typename?: 'mobile_sleep_nows';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "sleep_nows" */
export type Mobile_Sleep_Nows_Aggregate = {
  __typename?: 'mobile_sleep_nows_aggregate';
  aggregate?: Maybe<Mobile_Sleep_Nows_Aggregate_Fields>;
  nodes: Array<Mobile_Sleep_Nows>;
};

/** aggregate fields of "sleep_nows" */
export type Mobile_Sleep_Nows_Aggregate_Fields = {
  __typename?: 'mobile_sleep_nows_aggregate_fields';
  avg?: Maybe<Mobile_Sleep_Nows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Sleep_Nows_Max_Fields>;
  min?: Maybe<Mobile_Sleep_Nows_Min_Fields>;
  stddev?: Maybe<Mobile_Sleep_Nows_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Sleep_Nows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Sleep_Nows_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Sleep_Nows_Sum_Fields>;
  var_pop?: Maybe<Mobile_Sleep_Nows_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Sleep_Nows_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Sleep_Nows_Variance_Fields>;
};


/** aggregate fields of "sleep_nows" */
export type Mobile_Sleep_Nows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Sleep_Nows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Sleep_Nows_Avg_Fields = {
  __typename?: 'mobile_sleep_nows_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sleep_nows". All fields are combined with a logical 'AND'. */
export type Mobile_Sleep_Nows_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Sleep_Nows_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Sleep_Nows_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Sleep_Nows_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "sleep_nows" */
export enum Mobile_Sleep_Nows_Constraint {
  /** unique or primary key constraint on columns "id" */
  SleepNowsPkey = 'sleep_nows_pkey'
}

/** input type for incrementing numeric columns in table "sleep_nows" */
export type Mobile_Sleep_Nows_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "sleep_nows" */
export type Mobile_Sleep_Nows_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Mobile_Sleep_Nows_Max_Fields = {
  __typename?: 'mobile_sleep_nows_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type Mobile_Sleep_Nows_Min_Fields = {
  __typename?: 'mobile_sleep_nows_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** response of any mutation on the table "sleep_nows" */
export type Mobile_Sleep_Nows_Mutation_Response = {
  __typename?: 'mobile_sleep_nows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Sleep_Nows>;
};

/** on_conflict condition type for table "sleep_nows" */
export type Mobile_Sleep_Nows_On_Conflict = {
  constraint: Mobile_Sleep_Nows_Constraint;
  update_columns?: Array<Mobile_Sleep_Nows_Update_Column>;
  where?: InputMaybe<Mobile_Sleep_Nows_Bool_Exp>;
};

/** Ordering options when selecting data from "sleep_nows". */
export type Mobile_Sleep_Nows_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sleep_nows */
export type Mobile_Sleep_Nows_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "sleep_nows" */
export enum Mobile_Sleep_Nows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "sleep_nows" */
export type Mobile_Sleep_Nows_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Sleep_Nows_Stddev_Fields = {
  __typename?: 'mobile_sleep_nows_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Sleep_Nows_Stddev_Pop_Fields = {
  __typename?: 'mobile_sleep_nows_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Sleep_Nows_Stddev_Samp_Fields = {
  __typename?: 'mobile_sleep_nows_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_sleep_nows" */
export type Mobile_Sleep_Nows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Sleep_Nows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Sleep_Nows_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Sleep_Nows_Sum_Fields = {
  __typename?: 'mobile_sleep_nows_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "sleep_nows" */
export enum Mobile_Sleep_Nows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Sleep_Nows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Sleep_Nows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Sleep_Nows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Sleep_Nows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Sleep_Nows_Var_Pop_Fields = {
  __typename?: 'mobile_sleep_nows_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Sleep_Nows_Var_Samp_Fields = {
  __typename?: 'mobile_sleep_nows_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Sleep_Nows_Variance_Fields = {
  __typename?: 'mobile_sleep_nows_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "sleeps" */
export type Mobile_Sleeps = {
  __typename?: 'mobile_sleeps';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "sleeps" */
export type Mobile_Sleeps_Aggregate = {
  __typename?: 'mobile_sleeps_aggregate';
  aggregate?: Maybe<Mobile_Sleeps_Aggregate_Fields>;
  nodes: Array<Mobile_Sleeps>;
};

/** aggregate fields of "sleeps" */
export type Mobile_Sleeps_Aggregate_Fields = {
  __typename?: 'mobile_sleeps_aggregate_fields';
  avg?: Maybe<Mobile_Sleeps_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Sleeps_Max_Fields>;
  min?: Maybe<Mobile_Sleeps_Min_Fields>;
  stddev?: Maybe<Mobile_Sleeps_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Sleeps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Sleeps_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Sleeps_Sum_Fields>;
  var_pop?: Maybe<Mobile_Sleeps_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Sleeps_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Sleeps_Variance_Fields>;
};


/** aggregate fields of "sleeps" */
export type Mobile_Sleeps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Sleeps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Sleeps_Avg_Fields = {
  __typename?: 'mobile_sleeps_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sleeps". All fields are combined with a logical 'AND'. */
export type Mobile_Sleeps_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Sleeps_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Sleeps_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Sleeps_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "sleeps" */
export enum Mobile_Sleeps_Constraint {
  /** unique or primary key constraint on columns "created_at" */
  SleepsCreatedAtKey = 'sleeps_created_at_key',
  /** unique or primary key constraint on columns "id" */
  SleepsPkey = 'sleeps_pkey'
}

/** input type for incrementing numeric columns in table "sleeps" */
export type Mobile_Sleeps_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "sleeps" */
export type Mobile_Sleeps_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Mobile_Sleeps_Max_Fields = {
  __typename?: 'mobile_sleeps_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type Mobile_Sleeps_Min_Fields = {
  __typename?: 'mobile_sleeps_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** response of any mutation on the table "sleeps" */
export type Mobile_Sleeps_Mutation_Response = {
  __typename?: 'mobile_sleeps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Sleeps>;
};

/** on_conflict condition type for table "sleeps" */
export type Mobile_Sleeps_On_Conflict = {
  constraint: Mobile_Sleeps_Constraint;
  update_columns?: Array<Mobile_Sleeps_Update_Column>;
  where?: InputMaybe<Mobile_Sleeps_Bool_Exp>;
};

/** Ordering options when selecting data from "sleeps". */
export type Mobile_Sleeps_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sleeps */
export type Mobile_Sleeps_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "sleeps" */
export enum Mobile_Sleeps_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "sleeps" */
export type Mobile_Sleeps_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Sleeps_Stddev_Fields = {
  __typename?: 'mobile_sleeps_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Sleeps_Stddev_Pop_Fields = {
  __typename?: 'mobile_sleeps_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Sleeps_Stddev_Samp_Fields = {
  __typename?: 'mobile_sleeps_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_sleeps" */
export type Mobile_Sleeps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Sleeps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Sleeps_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Sleeps_Sum_Fields = {
  __typename?: 'mobile_sleeps_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "sleeps" */
export enum Mobile_Sleeps_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Sleeps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Sleeps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Sleeps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Sleeps_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Sleeps_Var_Pop_Fields = {
  __typename?: 'mobile_sleeps_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Sleeps_Var_Samp_Fields = {
  __typename?: 'mobile_sleeps_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Sleeps_Variance_Fields = {
  __typename?: 'mobile_sleeps_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "slots" */
export type Mobile_Slots = {
  __typename?: 'mobile_slots';
  /** An array relationship */
  appointments: Array<Mobile_Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Mobile_Appointments_Aggregate;
  busy?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamptz']['output'];
  date_slot?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "slots" */
export type Mobile_SlotsAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


/** columns and relationships of "slots" */
export type Mobile_SlotsAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};

/** aggregated selection of "slots" */
export type Mobile_Slots_Aggregate = {
  __typename?: 'mobile_slots_aggregate';
  aggregate?: Maybe<Mobile_Slots_Aggregate_Fields>;
  nodes: Array<Mobile_Slots>;
};

/** aggregate fields of "slots" */
export type Mobile_Slots_Aggregate_Fields = {
  __typename?: 'mobile_slots_aggregate_fields';
  avg?: Maybe<Mobile_Slots_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Slots_Max_Fields>;
  min?: Maybe<Mobile_Slots_Min_Fields>;
  stddev?: Maybe<Mobile_Slots_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Slots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Slots_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Slots_Sum_Fields>;
  var_pop?: Maybe<Mobile_Slots_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Slots_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Slots_Variance_Fields>;
};


/** aggregate fields of "slots" */
export type Mobile_Slots_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Slots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Slots_Avg_Fields = {
  __typename?: 'mobile_slots_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "slots". All fields are combined with a logical 'AND'. */
export type Mobile_Slots_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Slots_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Slots_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Slots_Bool_Exp>>;
  appointments?: InputMaybe<Mobile_Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Mobile_Appointments_Aggregate_Bool_Exp>;
  busy?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_slot?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "slots" */
export enum Mobile_Slots_Constraint {
  /** unique or primary key constraint on columns "id" */
  SlotsPkey = 'slots_pkey'
}

/** input type for incrementing numeric columns in table "slots" */
export type Mobile_Slots_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "slots" */
export type Mobile_Slots_Insert_Input = {
  appointments?: InputMaybe<Mobile_Appointments_Arr_Rel_Insert_Input>;
  busy?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_slot?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Slots_Max_Fields = {
  __typename?: 'mobile_slots_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_slot?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Slots_Min_Fields = {
  __typename?: 'mobile_slots_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_slot?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "slots" */
export type Mobile_Slots_Mutation_Response = {
  __typename?: 'mobile_slots_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Slots>;
};

/** input type for inserting object relation for remote table "slots" */
export type Mobile_Slots_Obj_Rel_Insert_Input = {
  data: Mobile_Slots_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Slots_On_Conflict>;
};

/** on_conflict condition type for table "slots" */
export type Mobile_Slots_On_Conflict = {
  constraint: Mobile_Slots_Constraint;
  update_columns?: Array<Mobile_Slots_Update_Column>;
  where?: InputMaybe<Mobile_Slots_Bool_Exp>;
};

/** Ordering options when selecting data from "slots". */
export type Mobile_Slots_Order_By = {
  appointments_aggregate?: InputMaybe<Mobile_Appointments_Aggregate_Order_By>;
  busy?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_slot?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: slots */
export type Mobile_Slots_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "slots" */
export enum Mobile_Slots_Select_Column {
  /** column name */
  Busy = 'busy',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSlot = 'date_slot',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "slots" */
export type Mobile_Slots_Set_Input = {
  busy?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_slot?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Slots_Stddev_Fields = {
  __typename?: 'mobile_slots_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Slots_Stddev_Pop_Fields = {
  __typename?: 'mobile_slots_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Slots_Stddev_Samp_Fields = {
  __typename?: 'mobile_slots_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_slots" */
export type Mobile_Slots_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Slots_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Slots_Stream_Cursor_Value_Input = {
  busy?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_slot?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Slots_Sum_Fields = {
  __typename?: 'mobile_slots_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "slots" */
export enum Mobile_Slots_Update_Column {
  /** column name */
  Busy = 'busy',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSlot = 'date_slot',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Slots_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Slots_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Slots_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Slots_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Slots_Var_Pop_Fields = {
  __typename?: 'mobile_slots_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Slots_Var_Samp_Fields = {
  __typename?: 'mobile_slots_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Slots_Variance_Fields = {
  __typename?: 'mobile_slots_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "status_messages" */
export type Mobile_Status_Messages = {
  __typename?: 'mobile_status_messages';
  /** An array relationship */
  chat_messages: Array<Mobile_Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Mobile_Chat_Messages_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "status_messages" */
export type Mobile_Status_MessagesChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


/** columns and relationships of "status_messages" */
export type Mobile_Status_MessagesChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};

/** aggregated selection of "status_messages" */
export type Mobile_Status_Messages_Aggregate = {
  __typename?: 'mobile_status_messages_aggregate';
  aggregate?: Maybe<Mobile_Status_Messages_Aggregate_Fields>;
  nodes: Array<Mobile_Status_Messages>;
};

/** aggregate fields of "status_messages" */
export type Mobile_Status_Messages_Aggregate_Fields = {
  __typename?: 'mobile_status_messages_aggregate_fields';
  avg?: Maybe<Mobile_Status_Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Status_Messages_Max_Fields>;
  min?: Maybe<Mobile_Status_Messages_Min_Fields>;
  stddev?: Maybe<Mobile_Status_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Status_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Status_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Status_Messages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Status_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Status_Messages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Status_Messages_Variance_Fields>;
};


/** aggregate fields of "status_messages" */
export type Mobile_Status_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Status_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Status_Messages_Avg_Fields = {
  __typename?: 'mobile_status_messages_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "status_messages". All fields are combined with a logical 'AND'. */
export type Mobile_Status_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Status_Messages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Status_Messages_Bool_Exp>>;
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "status_messages" */
export enum Mobile_Status_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatusMessagesPkey = 'status_messages_pkey'
}

/** input type for incrementing numeric columns in table "status_messages" */
export type Mobile_Status_Messages_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "status_messages" */
export type Mobile_Status_Messages_Insert_Input = {
  chat_messages?: InputMaybe<Mobile_Chat_Messages_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Status_Messages_Max_Fields = {
  __typename?: 'mobile_status_messages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Status_Messages_Min_Fields = {
  __typename?: 'mobile_status_messages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "status_messages" */
export type Mobile_Status_Messages_Mutation_Response = {
  __typename?: 'mobile_status_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Status_Messages>;
};

/** input type for inserting object relation for remote table "status_messages" */
export type Mobile_Status_Messages_Obj_Rel_Insert_Input = {
  data: Mobile_Status_Messages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Status_Messages_On_Conflict>;
};

/** on_conflict condition type for table "status_messages" */
export type Mobile_Status_Messages_On_Conflict = {
  constraint: Mobile_Status_Messages_Constraint;
  update_columns?: Array<Mobile_Status_Messages_Update_Column>;
  where?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "status_messages". */
export type Mobile_Status_Messages_Order_By = {
  chat_messages_aggregate?: InputMaybe<Mobile_Chat_Messages_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: status_messages */
export type Mobile_Status_Messages_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "status_messages" */
export enum Mobile_Status_Messages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "status_messages" */
export type Mobile_Status_Messages_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Status_Messages_Stddev_Fields = {
  __typename?: 'mobile_status_messages_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Status_Messages_Stddev_Pop_Fields = {
  __typename?: 'mobile_status_messages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Status_Messages_Stddev_Samp_Fields = {
  __typename?: 'mobile_status_messages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_status_messages" */
export type Mobile_Status_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Status_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Status_Messages_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Status_Messages_Sum_Fields = {
  __typename?: 'mobile_status_messages_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "status_messages" */
export enum Mobile_Status_Messages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Status_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Status_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Status_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Status_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Status_Messages_Var_Pop_Fields = {
  __typename?: 'mobile_status_messages_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Status_Messages_Var_Samp_Fields = {
  __typename?: 'mobile_status_messages_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Status_Messages_Variance_Fields = {
  __typename?: 'mobile_status_messages_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "step_norms" */
export type Mobile_Step_Norms = {
  __typename?: 'mobile_step_norms';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm: Scalars['float8']['output'];
  /** An object relationship */
  profile: Mobile_Profiles;
  profile_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "step_norms" */
export type Mobile_Step_Norms_Aggregate = {
  __typename?: 'mobile_step_norms_aggregate';
  aggregate?: Maybe<Mobile_Step_Norms_Aggregate_Fields>;
  nodes: Array<Mobile_Step_Norms>;
};

/** aggregate fields of "step_norms" */
export type Mobile_Step_Norms_Aggregate_Fields = {
  __typename?: 'mobile_step_norms_aggregate_fields';
  avg?: Maybe<Mobile_Step_Norms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Step_Norms_Max_Fields>;
  min?: Maybe<Mobile_Step_Norms_Min_Fields>;
  stddev?: Maybe<Mobile_Step_Norms_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Step_Norms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Step_Norms_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Step_Norms_Sum_Fields>;
  var_pop?: Maybe<Mobile_Step_Norms_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Step_Norms_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Step_Norms_Variance_Fields>;
};


/** aggregate fields of "step_norms" */
export type Mobile_Step_Norms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Step_Norms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Step_Norms_Avg_Fields = {
  __typename?: 'mobile_step_norms_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "step_norms". All fields are combined with a logical 'AND'. */
export type Mobile_Step_Norms_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Step_Norms_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Step_Norms_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Step_Norms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mentor_norm?: InputMaybe<Float8_Comparison_Exp>;
  mentor_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  norm?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "step_norms" */
export enum Mobile_Step_Norms_Constraint {
  /** unique or primary key constraint on columns "id" */
  StepNormsPkey = 'step_norms_pkey'
}

/** input type for incrementing numeric columns in table "step_norms" */
export type Mobile_Step_Norms_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "step_norms" */
export type Mobile_Step_Norms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Step_Norms_Max_Fields = {
  __typename?: 'mobile_step_norms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Step_Norms_Min_Fields = {
  __typename?: 'mobile_step_norms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "step_norms" */
export type Mobile_Step_Norms_Mutation_Response = {
  __typename?: 'mobile_step_norms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Step_Norms>;
};

/** on_conflict condition type for table "step_norms" */
export type Mobile_Step_Norms_On_Conflict = {
  constraint: Mobile_Step_Norms_Constraint;
  update_columns?: Array<Mobile_Step_Norms_Update_Column>;
  where?: InputMaybe<Mobile_Step_Norms_Bool_Exp>;
};

/** Ordering options when selecting data from "step_norms". */
export type Mobile_Step_Norms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentor_norm?: InputMaybe<Order_By>;
  mentor_user_id?: InputMaybe<Order_By>;
  norm?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: step_norms */
export type Mobile_Step_Norms_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "step_norms" */
export enum Mobile_Step_Norms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "step_norms" */
export type Mobile_Step_Norms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Step_Norms_Stddev_Fields = {
  __typename?: 'mobile_step_norms_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Step_Norms_Stddev_Pop_Fields = {
  __typename?: 'mobile_step_norms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Step_Norms_Stddev_Samp_Fields = {
  __typename?: 'mobile_step_norms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_step_norms" */
export type Mobile_Step_Norms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Step_Norms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Step_Norms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Step_Norms_Sum_Fields = {
  __typename?: 'mobile_step_norms_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "step_norms" */
export enum Mobile_Step_Norms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Step_Norms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Step_Norms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Step_Norms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Step_Norms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Step_Norms_Var_Pop_Fields = {
  __typename?: 'mobile_step_norms_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Step_Norms_Var_Samp_Fields = {
  __typename?: 'mobile_step_norms_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Step_Norms_Variance_Fields = {
  __typename?: 'mobile_step_norms_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "step_nows" */
export type Mobile_Step_Nows = {
  __typename?: 'mobile_step_nows';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "step_nows" */
export type Mobile_Step_Nows_Aggregate = {
  __typename?: 'mobile_step_nows_aggregate';
  aggregate?: Maybe<Mobile_Step_Nows_Aggregate_Fields>;
  nodes: Array<Mobile_Step_Nows>;
};

/** aggregate fields of "step_nows" */
export type Mobile_Step_Nows_Aggregate_Fields = {
  __typename?: 'mobile_step_nows_aggregate_fields';
  avg?: Maybe<Mobile_Step_Nows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Step_Nows_Max_Fields>;
  min?: Maybe<Mobile_Step_Nows_Min_Fields>;
  stddev?: Maybe<Mobile_Step_Nows_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Step_Nows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Step_Nows_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Step_Nows_Sum_Fields>;
  var_pop?: Maybe<Mobile_Step_Nows_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Step_Nows_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Step_Nows_Variance_Fields>;
};


/** aggregate fields of "step_nows" */
export type Mobile_Step_Nows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Step_Nows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Step_Nows_Avg_Fields = {
  __typename?: 'mobile_step_nows_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "step_nows". All fields are combined with a logical 'AND'. */
export type Mobile_Step_Nows_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Step_Nows_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Step_Nows_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Step_Nows_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "step_nows" */
export enum Mobile_Step_Nows_Constraint {
  /** unique or primary key constraint on columns "id" */
  StepNowsPkey = 'step_nows_pkey'
}

/** input type for incrementing numeric columns in table "step_nows" */
export type Mobile_Step_Nows_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "step_nows" */
export type Mobile_Step_Nows_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Step_Nows_Max_Fields = {
  __typename?: 'mobile_step_nows_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mobile_Step_Nows_Min_Fields = {
  __typename?: 'mobile_step_nows_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "step_nows" */
export type Mobile_Step_Nows_Mutation_Response = {
  __typename?: 'mobile_step_nows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Step_Nows>;
};

/** on_conflict condition type for table "step_nows" */
export type Mobile_Step_Nows_On_Conflict = {
  constraint: Mobile_Step_Nows_Constraint;
  update_columns?: Array<Mobile_Step_Nows_Update_Column>;
  where?: InputMaybe<Mobile_Step_Nows_Bool_Exp>;
};

/** Ordering options when selecting data from "step_nows". */
export type Mobile_Step_Nows_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: step_nows */
export type Mobile_Step_Nows_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "step_nows" */
export enum Mobile_Step_Nows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "step_nows" */
export type Mobile_Step_Nows_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Step_Nows_Stddev_Fields = {
  __typename?: 'mobile_step_nows_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Step_Nows_Stddev_Pop_Fields = {
  __typename?: 'mobile_step_nows_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Step_Nows_Stddev_Samp_Fields = {
  __typename?: 'mobile_step_nows_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_step_nows" */
export type Mobile_Step_Nows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Step_Nows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Step_Nows_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Step_Nows_Sum_Fields = {
  __typename?: 'mobile_step_nows_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "step_nows" */
export enum Mobile_Step_Nows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Step_Nows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Step_Nows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Step_Nows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Step_Nows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Step_Nows_Var_Pop_Fields = {
  __typename?: 'mobile_step_nows_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Step_Nows_Var_Samp_Fields = {
  __typename?: 'mobile_step_nows_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Step_Nows_Variance_Fields = {
  __typename?: 'mobile_step_nows_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "steps" */
export type Mobile_Steps = {
  __typename?: 'mobile_steps';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "steps" */
export type Mobile_Steps_Aggregate = {
  __typename?: 'mobile_steps_aggregate';
  aggregate?: Maybe<Mobile_Steps_Aggregate_Fields>;
  nodes: Array<Mobile_Steps>;
};

/** aggregate fields of "steps" */
export type Mobile_Steps_Aggregate_Fields = {
  __typename?: 'mobile_steps_aggregate_fields';
  avg?: Maybe<Mobile_Steps_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Steps_Max_Fields>;
  min?: Maybe<Mobile_Steps_Min_Fields>;
  stddev?: Maybe<Mobile_Steps_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Steps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Steps_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Steps_Sum_Fields>;
  var_pop?: Maybe<Mobile_Steps_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Steps_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Steps_Variance_Fields>;
};


/** aggregate fields of "steps" */
export type Mobile_Steps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Steps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Steps_Avg_Fields = {
  __typename?: 'mobile_steps_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "steps". All fields are combined with a logical 'AND'. */
export type Mobile_Steps_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Steps_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Steps_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Steps_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "steps" */
export enum Mobile_Steps_Constraint {
  /** unique or primary key constraint on columns "id" */
  StepsPkey = 'steps_pkey'
}

/** input type for incrementing numeric columns in table "steps" */
export type Mobile_Steps_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "steps" */
export type Mobile_Steps_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Mobile_Steps_Max_Fields = {
  __typename?: 'mobile_steps_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Mobile_Steps_Min_Fields = {
  __typename?: 'mobile_steps_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "steps" */
export type Mobile_Steps_Mutation_Response = {
  __typename?: 'mobile_steps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Steps>;
};

/** on_conflict condition type for table "steps" */
export type Mobile_Steps_On_Conflict = {
  constraint: Mobile_Steps_Constraint;
  update_columns?: Array<Mobile_Steps_Update_Column>;
  where?: InputMaybe<Mobile_Steps_Bool_Exp>;
};

/** Ordering options when selecting data from "steps". */
export type Mobile_Steps_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: steps */
export type Mobile_Steps_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "steps" */
export enum Mobile_Steps_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "steps" */
export type Mobile_Steps_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Steps_Stddev_Fields = {
  __typename?: 'mobile_steps_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Steps_Stddev_Pop_Fields = {
  __typename?: 'mobile_steps_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Steps_Stddev_Samp_Fields = {
  __typename?: 'mobile_steps_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_steps" */
export type Mobile_Steps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Steps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Steps_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Steps_Sum_Fields = {
  __typename?: 'mobile_steps_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "steps" */
export enum Mobile_Steps_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Steps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Steps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Steps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Steps_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Steps_Var_Pop_Fields = {
  __typename?: 'mobile_steps_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Steps_Var_Samp_Fields = {
  __typename?: 'mobile_steps_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Steps_Variance_Fields = {
  __typename?: 'mobile_steps_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscribe_notifications" */
export type Mobile_Subscribe_Notifications = {
  __typename?: 'mobile_subscribe_notifications';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notifications?: Maybe<Mobile_Notifications>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  subscribes?: Maybe<Mobile_Subscribes>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Aggregate = {
  __typename?: 'mobile_subscribe_notifications_aggregate';
  aggregate?: Maybe<Mobile_Subscribe_Notifications_Aggregate_Fields>;
  nodes: Array<Mobile_Subscribe_Notifications>;
};

/** aggregate fields of "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Aggregate_Fields = {
  __typename?: 'mobile_subscribe_notifications_aggregate_fields';
  avg?: Maybe<Mobile_Subscribe_Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Subscribe_Notifications_Max_Fields>;
  min?: Maybe<Mobile_Subscribe_Notifications_Min_Fields>;
  stddev?: Maybe<Mobile_Subscribe_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Subscribe_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Subscribe_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Subscribe_Notifications_Sum_Fields>;
  var_pop?: Maybe<Mobile_Subscribe_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Subscribe_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Subscribe_Notifications_Variance_Fields>;
};


/** aggregate fields of "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Subscribe_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Subscribe_Notifications_Avg_Fields = {
  __typename?: 'mobile_subscribe_notifications_avg_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscribe_notifications". All fields are combined with a logical 'AND'. */
export type Mobile_Subscribe_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Subscribe_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Subscribe_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Subscribe_Notifications_Bool_Exp>>;
  actor_avatar_url?: InputMaybe<String_Comparison_Exp>;
  actor_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  notifiable_id?: InputMaybe<Bigint_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Mobile_Notifications_Bool_Exp>;
  on_click_url?: InputMaybe<String_Comparison_Exp>;
  post_id?: InputMaybe<Bigint_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  subscribes?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Inc_Input = {
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Insert_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Mobile_Notifications_Obj_Rel_Insert_Input>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscribes?: InputMaybe<Mobile_Subscribes_Obj_Rel_Insert_Input>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Mobile_Subscribe_Notifications_Max_Fields = {
  __typename?: 'mobile_subscribe_notifications_max_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Mobile_Subscribe_Notifications_Min_Fields = {
  __typename?: 'mobile_subscribe_notifications_min_fields';
  actor_avatar_url?: Maybe<Scalars['String']['output']>;
  actor_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  on_click_url?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Mutation_Response = {
  __typename?: 'mobile_subscribe_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Subscribe_Notifications>;
};

/** input type for inserting object relation for remote table "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Obj_Rel_Insert_Input = {
  data: Mobile_Subscribe_Notifications_Insert_Input;
};

/** Ordering options when selecting data from "subscribe_notifications". */
export type Mobile_Subscribe_Notifications_Order_By = {
  actor_avatar_url?: InputMaybe<Order_By>;
  actor_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Mobile_Notifications_Order_By>;
  on_click_url?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  subscribes?: InputMaybe<Mobile_Subscribes_Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "subscribe_notifications" */
export enum Mobile_Subscribe_Notifications_Select_Column {
  /** column name */
  ActorAvatarUrl = 'actor_avatar_url',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  OnClickUrl = 'on_click_url',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Set_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Subscribe_Notifications_Stddev_Fields = {
  __typename?: 'mobile_subscribe_notifications_stddev_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Subscribe_Notifications_Stddev_Pop_Fields = {
  __typename?: 'mobile_subscribe_notifications_stddev_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Subscribe_Notifications_Stddev_Samp_Fields = {
  __typename?: 'mobile_subscribe_notifications_stddev_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_subscribe_notifications" */
export type Mobile_Subscribe_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Subscribe_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Subscribe_Notifications_Stream_Cursor_Value_Input = {
  actor_avatar_url?: InputMaybe<Scalars['String']['input']>;
  actor_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  notifiable_id?: InputMaybe<Scalars['bigint']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  on_click_url?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Subscribe_Notifications_Sum_Fields = {
  __typename?: 'mobile_subscribe_notifications_sum_fields';
  actor_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  notifiable_id?: Maybe<Scalars['bigint']['output']>;
  post_id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_Subscribe_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Subscribe_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Subscribe_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Subscribe_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Subscribe_Notifications_Var_Pop_Fields = {
  __typename?: 'mobile_subscribe_notifications_var_pop_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Subscribe_Notifications_Var_Samp_Fields = {
  __typename?: 'mobile_subscribe_notifications_var_samp_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Subscribe_Notifications_Variance_Fields = {
  __typename?: 'mobile_subscribe_notifications_variance_fields';
  actor_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  notifiable_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
};

/** Подписчики */
export type Mobile_Subscribes = {
  __typename?: 'mobile_subscribes';
  created_at: Scalars['timestamptz']['output'];
  /** A computed field, executes function "mobile_subscribed_to_subscriber" */
  did_subscribe_to_subscriber?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  profiles_subscribers?: Maybe<Mobile_Profiles>;
  /** An object relationship */
  profiles_subscriptions?: Maybe<Mobile_Profiles>;
  /** An array relationship */
  subscriber_profile_lockeds: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  subscriber_profile_lockeds_aggregate: Mobile_Profile_Locks_Aggregate;
  /** An array relationship */
  subscriber_profile_lockings: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  subscriber_profile_lockings_aggregate: Mobile_Profile_Locks_Aggregate;
  /** An array relationship */
  subscription_profile_lockeds: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  subscription_profile_lockeds_aggregate: Mobile_Profile_Locks_Aggregate;
  /** An array relationship */
  subscription_profile_lockings: Array<Mobile_Profile_Locks>;
  /** An aggregate relationship */
  subscription_profile_lockings_aggregate: Mobile_Profile_Locks_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  user_id_subscribe?: Maybe<Scalars['bigint']['output']>;
};


/** Подписчики */
export type Mobile_SubscribesSubscriber_Profile_LockedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** Подписчики */
export type Mobile_SubscribesSubscriber_Profile_Lockeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** Подписчики */
export type Mobile_SubscribesSubscriber_Profile_LockingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** Подписчики */
export type Mobile_SubscribesSubscriber_Profile_Lockings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** Подписчики */
export type Mobile_SubscribesSubscription_Profile_LockedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** Подписчики */
export type Mobile_SubscribesSubscription_Profile_Lockeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** Подписчики */
export type Mobile_SubscribesSubscription_Profile_LockingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


/** Подписчики */
export type Mobile_SubscribesSubscription_Profile_Lockings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};

/** aggregated selection of "subscribes" */
export type Mobile_Subscribes_Aggregate = {
  __typename?: 'mobile_subscribes_aggregate';
  aggregate?: Maybe<Mobile_Subscribes_Aggregate_Fields>;
  nodes: Array<Mobile_Subscribes>;
};

export type Mobile_Subscribes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Subscribes_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Subscribes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscribes" */
export type Mobile_Subscribes_Aggregate_Fields = {
  __typename?: 'mobile_subscribes_aggregate_fields';
  avg?: Maybe<Mobile_Subscribes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Subscribes_Max_Fields>;
  min?: Maybe<Mobile_Subscribes_Min_Fields>;
  stddev?: Maybe<Mobile_Subscribes_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Subscribes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Subscribes_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Subscribes_Sum_Fields>;
  var_pop?: Maybe<Mobile_Subscribes_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Subscribes_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Subscribes_Variance_Fields>;
};


/** aggregate fields of "subscribes" */
export type Mobile_Subscribes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscribes" */
export type Mobile_Subscribes_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Subscribes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Subscribes_Max_Order_By>;
  min?: InputMaybe<Mobile_Subscribes_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Subscribes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Subscribes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Subscribes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Subscribes_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Subscribes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Subscribes_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Subscribes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subscribes" */
export type Mobile_Subscribes_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Subscribes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Subscribes_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Subscribes_Avg_Fields = {
  __typename?: 'mobile_subscribes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_subscribe?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscribes" */
export type Mobile_Subscribes_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscribes". All fields are combined with a logical 'AND'. */
export type Mobile_Subscribes_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Subscribes_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Subscribes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  did_subscribe_to_subscriber?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  profiles_subscribers?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profiles_subscriptions?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  subscriber_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  subscriber_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  subscriber_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  subscriber_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  subscription_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  subscription_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  subscription_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
  subscription_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id_subscribe?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscribes" */
export enum Mobile_Subscribes_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscribesPkey = 'subscribes_pkey',
  /** unique or primary key constraint on columns "user_id", "user_id_subscribe" */
  SubscribesUniqueUserIdUserIdSubscribe = 'subscribes_unique_user_id_user_id_subscribe'
}

/** input type for incrementing numeric columns in table "subscribes" */
export type Mobile_Subscribes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_subscribe?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "subscribes" */
export type Mobile_Subscribes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profiles_subscribers?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profiles_subscriptions?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  subscriber_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Arr_Rel_Insert_Input>;
  subscriber_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Arr_Rel_Insert_Input>;
  subscription_profile_lockeds?: InputMaybe<Mobile_Profile_Locks_Arr_Rel_Insert_Input>;
  subscription_profile_lockings?: InputMaybe<Mobile_Profile_Locks_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_subscribe?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Subscribes_Max_Fields = {
  __typename?: 'mobile_subscribes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  user_id_subscribe?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "subscribes" */
export type Mobile_Subscribes_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Subscribes_Min_Fields = {
  __typename?: 'mobile_subscribes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  user_id_subscribe?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "subscribes" */
export type Mobile_Subscribes_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscribes" */
export type Mobile_Subscribes_Mutation_Response = {
  __typename?: 'mobile_subscribes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Subscribes>;
};

/** input type for inserting object relation for remote table "subscribes" */
export type Mobile_Subscribes_Obj_Rel_Insert_Input = {
  data: Mobile_Subscribes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Subscribes_On_Conflict>;
};

/** on_conflict condition type for table "subscribes" */
export type Mobile_Subscribes_On_Conflict = {
  constraint: Mobile_Subscribes_Constraint;
  update_columns?: Array<Mobile_Subscribes_Update_Column>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};

/** Ordering options when selecting data from "subscribes". */
export type Mobile_Subscribes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  did_subscribe_to_subscriber?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profiles_subscribers?: InputMaybe<Mobile_Profiles_Order_By>;
  profiles_subscriptions?: InputMaybe<Mobile_Profiles_Order_By>;
  subscriber_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  subscriber_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  subscription_profile_lockeds_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  subscription_profile_lockings_aggregate?: InputMaybe<Mobile_Profile_Locks_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscribes */
export type Mobile_Subscribes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "subscribes" */
export enum Mobile_Subscribes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIdSubscribe = 'user_id_subscribe'
}

/** input type for updating data in table "subscribes" */
export type Mobile_Subscribes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_subscribe?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Subscribes_Stddev_Fields = {
  __typename?: 'mobile_subscribes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_subscribe?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscribes" */
export type Mobile_Subscribes_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Subscribes_Stddev_Pop_Fields = {
  __typename?: 'mobile_subscribes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_subscribe?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscribes" */
export type Mobile_Subscribes_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Subscribes_Stddev_Samp_Fields = {
  __typename?: 'mobile_subscribes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_subscribe?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscribes" */
export type Mobile_Subscribes_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_subscribes" */
export type Mobile_Subscribes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Subscribes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Subscribes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id_subscribe?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Subscribes_Sum_Fields = {
  __typename?: 'mobile_subscribes_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  user_id_subscribe?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "subscribes" */
export type Mobile_Subscribes_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** update columns of table "subscribes" */
export enum Mobile_Subscribes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIdSubscribe = 'user_id_subscribe'
}

export type Mobile_Subscribes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Subscribes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Subscribes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Subscribes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Subscribes_Var_Pop_Fields = {
  __typename?: 'mobile_subscribes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_subscribe?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscribes" */
export type Mobile_Subscribes_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Subscribes_Var_Samp_Fields = {
  __typename?: 'mobile_subscribes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_subscribe?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscribes" */
export type Mobile_Subscribes_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Subscribes_Variance_Fields = {
  __typename?: 'mobile_subscribes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  user_id_subscribe?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscribes" */
export type Mobile_Subscribes_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_id_subscribe?: InputMaybe<Order_By>;
};

/** Подписчики */
export type Mobile_Support_Messages = {
  __typename?: 'mobile_support_messages';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  message: Scalars['String']['output'];
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  /** An object relationship */
  support_topic?: Maybe<Mobile_Support_Topics>;
  support_topic_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "support_messages" */
export type Mobile_Support_Messages_Aggregate = {
  __typename?: 'mobile_support_messages_aggregate';
  aggregate?: Maybe<Mobile_Support_Messages_Aggregate_Fields>;
  nodes: Array<Mobile_Support_Messages>;
};

export type Mobile_Support_Messages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Support_Messages_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Support_Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "support_messages" */
export type Mobile_Support_Messages_Aggregate_Fields = {
  __typename?: 'mobile_support_messages_aggregate_fields';
  avg?: Maybe<Mobile_Support_Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Support_Messages_Max_Fields>;
  min?: Maybe<Mobile_Support_Messages_Min_Fields>;
  stddev?: Maybe<Mobile_Support_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Support_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Support_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Support_Messages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Support_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Support_Messages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Support_Messages_Variance_Fields>;
};


/** aggregate fields of "support_messages" */
export type Mobile_Support_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "support_messages" */
export type Mobile_Support_Messages_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Support_Messages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Support_Messages_Max_Order_By>;
  min?: InputMaybe<Mobile_Support_Messages_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Support_Messages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Support_Messages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Support_Messages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Support_Messages_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Support_Messages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Support_Messages_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Support_Messages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "support_messages" */
export type Mobile_Support_Messages_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Support_Messages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Support_Messages_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Support_Messages_Avg_Fields = {
  __typename?: 'mobile_support_messages_avg_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  support_topic_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "support_messages" */
export type Mobile_Support_Messages_Avg_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "support_messages". All fields are combined with a logical 'AND'. */
export type Mobile_Support_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Support_Messages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Support_Messages_Bool_Exp>>;
  abuse_profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  support_topic?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
  support_topic_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "support_messages" */
export enum Mobile_Support_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupportMessagesPkey = 'support_messages_pkey'
}

/** input type for incrementing numeric columns in table "support_messages" */
export type Mobile_Support_Messages_Inc_Input = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  support_topic_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "support_messages" */
export type Mobile_Support_Messages_Insert_Input = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  support_topic?: InputMaybe<Mobile_Support_Topics_Obj_Rel_Insert_Input>;
  support_topic_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Support_Messages_Max_Fields = {
  __typename?: 'mobile_support_messages_max_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  support_topic_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "support_messages" */
export type Mobile_Support_Messages_Max_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Support_Messages_Min_Fields = {
  __typename?: 'mobile_support_messages_min_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  support_topic_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "support_messages" */
export type Mobile_Support_Messages_Min_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "support_messages" */
export type Mobile_Support_Messages_Mutation_Response = {
  __typename?: 'mobile_support_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Support_Messages>;
};

/** on_conflict condition type for table "support_messages" */
export type Mobile_Support_Messages_On_Conflict = {
  constraint: Mobile_Support_Messages_Constraint;
  update_columns?: Array<Mobile_Support_Messages_Update_Column>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "support_messages". */
export type Mobile_Support_Messages_Order_By = {
  abuse_profile_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  support_topic?: InputMaybe<Mobile_Support_Topics_Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: support_messages */
export type Mobile_Support_Messages_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "support_messages" */
export enum Mobile_Support_Messages_Select_Column {
  /** column name */
  AbuseProfileId = 'abuse_profile_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  SupportTopicId = 'support_topic_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "support_messages" */
export type Mobile_Support_Messages_Set_Input = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  support_topic_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Support_Messages_Stddev_Fields = {
  __typename?: 'mobile_support_messages_stddev_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  support_topic_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "support_messages" */
export type Mobile_Support_Messages_Stddev_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Support_Messages_Stddev_Pop_Fields = {
  __typename?: 'mobile_support_messages_stddev_pop_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  support_topic_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "support_messages" */
export type Mobile_Support_Messages_Stddev_Pop_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Support_Messages_Stddev_Samp_Fields = {
  __typename?: 'mobile_support_messages_stddev_samp_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  support_topic_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "support_messages" */
export type Mobile_Support_Messages_Stddev_Samp_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_support_messages" */
export type Mobile_Support_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Support_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Support_Messages_Stream_Cursor_Value_Input = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  support_topic_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Support_Messages_Sum_Fields = {
  __typename?: 'mobile_support_messages_sum_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  support_topic_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "support_messages" */
export type Mobile_Support_Messages_Sum_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "support_messages" */
export enum Mobile_Support_Messages_Update_Column {
  /** column name */
  AbuseProfileId = 'abuse_profile_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  SupportTopicId = 'support_topic_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Support_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Support_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Support_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Support_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Support_Messages_Var_Pop_Fields = {
  __typename?: 'mobile_support_messages_var_pop_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  support_topic_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "support_messages" */
export type Mobile_Support_Messages_Var_Pop_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Support_Messages_Var_Samp_Fields = {
  __typename?: 'mobile_support_messages_var_samp_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  support_topic_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "support_messages" */
export type Mobile_Support_Messages_Var_Samp_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Support_Messages_Variance_Fields = {
  __typename?: 'mobile_support_messages_variance_fields';
  /** профиль на кого жалоба */
  abuse_profile_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  support_topic_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "support_messages" */
export type Mobile_Support_Messages_Variance_Order_By = {
  /** профиль на кого жалоба */
  abuse_profile_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_topic_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "support_topics" */
export type Mobile_Support_Topics = {
  __typename?: 'mobile_support_topics';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  support_messages: Array<Mobile_Support_Messages>;
  /** An aggregate relationship */
  support_messages_aggregate: Mobile_Support_Messages_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['String']['output'];
};


/** columns and relationships of "support_topics" */
export type Mobile_Support_TopicsSupport_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


/** columns and relationships of "support_topics" */
export type Mobile_Support_TopicsSupport_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};

/** aggregated selection of "support_topics" */
export type Mobile_Support_Topics_Aggregate = {
  __typename?: 'mobile_support_topics_aggregate';
  aggregate?: Maybe<Mobile_Support_Topics_Aggregate_Fields>;
  nodes: Array<Mobile_Support_Topics>;
};

/** aggregate fields of "support_topics" */
export type Mobile_Support_Topics_Aggregate_Fields = {
  __typename?: 'mobile_support_topics_aggregate_fields';
  avg?: Maybe<Mobile_Support_Topics_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Support_Topics_Max_Fields>;
  min?: Maybe<Mobile_Support_Topics_Min_Fields>;
  stddev?: Maybe<Mobile_Support_Topics_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Support_Topics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Support_Topics_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Support_Topics_Sum_Fields>;
  var_pop?: Maybe<Mobile_Support_Topics_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Support_Topics_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Support_Topics_Variance_Fields>;
};


/** aggregate fields of "support_topics" */
export type Mobile_Support_Topics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Support_Topics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Support_Topics_Avg_Fields = {
  __typename?: 'mobile_support_topics_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "support_topics". All fields are combined with a logical 'AND'. */
export type Mobile_Support_Topics_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Support_Topics_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Support_Topics_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  support_messages?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
  support_messages_aggregate?: InputMaybe<Mobile_Support_Messages_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "support_topics" */
export enum Mobile_Support_Topics_Constraint {
  /** unique or primary key constraint on columns "id" */
  SupportTopicsPkey = 'support_topics_pkey'
}

/** input type for incrementing numeric columns in table "support_topics" */
export type Mobile_Support_Topics_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "support_topics" */
export type Mobile_Support_Topics_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  support_messages?: InputMaybe<Mobile_Support_Messages_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Mobile_Support_Topics_Max_Fields = {
  __typename?: 'mobile_support_topics_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Support_Topics_Min_Fields = {
  __typename?: 'mobile_support_topics_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "support_topics" */
export type Mobile_Support_Topics_Mutation_Response = {
  __typename?: 'mobile_support_topics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Support_Topics>;
};

/** input type for inserting object relation for remote table "support_topics" */
export type Mobile_Support_Topics_Obj_Rel_Insert_Input = {
  data: Mobile_Support_Topics_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Support_Topics_On_Conflict>;
};

/** on_conflict condition type for table "support_topics" */
export type Mobile_Support_Topics_On_Conflict = {
  constraint: Mobile_Support_Topics_Constraint;
  update_columns?: Array<Mobile_Support_Topics_Update_Column>;
  where?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
};

/** Ordering options when selecting data from "support_topics". */
export type Mobile_Support_Topics_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  support_messages_aggregate?: InputMaybe<Mobile_Support_Messages_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: support_topics */
export type Mobile_Support_Topics_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "support_topics" */
export enum Mobile_Support_Topics_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "support_topics" */
export type Mobile_Support_Topics_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Support_Topics_Stddev_Fields = {
  __typename?: 'mobile_support_topics_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Support_Topics_Stddev_Pop_Fields = {
  __typename?: 'mobile_support_topics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Support_Topics_Stddev_Samp_Fields = {
  __typename?: 'mobile_support_topics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_support_topics" */
export type Mobile_Support_Topics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Support_Topics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Support_Topics_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Support_Topics_Sum_Fields = {
  __typename?: 'mobile_support_topics_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "support_topics" */
export enum Mobile_Support_Topics_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Mobile_Support_Topics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Support_Topics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Support_Topics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Support_Topics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Support_Topics_Var_Pop_Fields = {
  __typename?: 'mobile_support_topics_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Support_Topics_Var_Samp_Fields = {
  __typename?: 'mobile_support_topics_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Support_Topics_Variance_Fields = {
  __typename?: 'mobile_support_topics_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "tag_ages" */
export type Mobile_Tag_Ages = {
  __typename?: 'mobile_tag_ages';
  color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  service_tag_ages: Array<Mobile_Service_Tag_Ages>;
  /** An aggregate relationship */
  service_tag_ages_aggregate: Mobile_Service_Tag_Ages_Aggregate;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "tag_ages" */
export type Mobile_Tag_AgesService_Tag_AgesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


/** columns and relationships of "tag_ages" */
export type Mobile_Tag_AgesService_Tag_Ages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};

/** aggregated selection of "tag_ages" */
export type Mobile_Tag_Ages_Aggregate = {
  __typename?: 'mobile_tag_ages_aggregate';
  aggregate?: Maybe<Mobile_Tag_Ages_Aggregate_Fields>;
  nodes: Array<Mobile_Tag_Ages>;
};

/** aggregate fields of "tag_ages" */
export type Mobile_Tag_Ages_Aggregate_Fields = {
  __typename?: 'mobile_tag_ages_aggregate_fields';
  avg?: Maybe<Mobile_Tag_Ages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Tag_Ages_Max_Fields>;
  min?: Maybe<Mobile_Tag_Ages_Min_Fields>;
  stddev?: Maybe<Mobile_Tag_Ages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Tag_Ages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Tag_Ages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Tag_Ages_Sum_Fields>;
  var_pop?: Maybe<Mobile_Tag_Ages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Tag_Ages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Tag_Ages_Variance_Fields>;
};


/** aggregate fields of "tag_ages" */
export type Mobile_Tag_Ages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Tag_Ages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Tag_Ages_Avg_Fields = {
  __typename?: 'mobile_tag_ages_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tag_ages". All fields are combined with a logical 'AND'. */
export type Mobile_Tag_Ages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Tag_Ages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Tag_Ages_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  output_order?: InputMaybe<Int_Comparison_Exp>;
  service_tag_ages?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
  service_tag_ages_aggregate?: InputMaybe<Mobile_Service_Tag_Ages_Aggregate_Bool_Exp>;
  tag_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag_ages" */
export enum Mobile_Tag_Ages_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagAgesPkey = 'tag_ages_pkey'
}

/** input type for incrementing numeric columns in table "tag_ages" */
export type Mobile_Tag_Ages_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "tag_ages" */
export type Mobile_Tag_Ages_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  service_tag_ages?: InputMaybe<Mobile_Service_Tag_Ages_Arr_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Tag_Ages_Max_Fields = {
  __typename?: 'mobile_tag_ages_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Tag_Ages_Min_Fields = {
  __typename?: 'mobile_tag_ages_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "tag_ages" */
export type Mobile_Tag_Ages_Mutation_Response = {
  __typename?: 'mobile_tag_ages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Tag_Ages>;
};

/** input type for inserting object relation for remote table "tag_ages" */
export type Mobile_Tag_Ages_Obj_Rel_Insert_Input = {
  data: Mobile_Tag_Ages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Tag_Ages_On_Conflict>;
};

/** on_conflict condition type for table "tag_ages" */
export type Mobile_Tag_Ages_On_Conflict = {
  constraint: Mobile_Tag_Ages_Constraint;
  update_columns?: Array<Mobile_Tag_Ages_Update_Column>;
  where?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
};

/** Ordering options when selecting data from "tag_ages". */
export type Mobile_Tag_Ages_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  output_order?: InputMaybe<Order_By>;
  service_tag_ages_aggregate?: InputMaybe<Mobile_Service_Tag_Ages_Aggregate_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tag_ages */
export type Mobile_Tag_Ages_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "tag_ages" */
export enum Mobile_Tag_Ages_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OutputOrder = 'output_order',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tag_ages" */
export type Mobile_Tag_Ages_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Tag_Ages_Stddev_Fields = {
  __typename?: 'mobile_tag_ages_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Tag_Ages_Stddev_Pop_Fields = {
  __typename?: 'mobile_tag_ages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Tag_Ages_Stddev_Samp_Fields = {
  __typename?: 'mobile_tag_ages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_tag_ages" */
export type Mobile_Tag_Ages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Tag_Ages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Tag_Ages_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Tag_Ages_Sum_Fields = {
  __typename?: 'mobile_tag_ages_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "tag_ages" */
export enum Mobile_Tag_Ages_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OutputOrder = 'output_order',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Tag_Ages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Tag_Ages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Tag_Ages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Tag_Ages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Tag_Ages_Var_Pop_Fields = {
  __typename?: 'mobile_tag_ages_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Tag_Ages_Var_Samp_Fields = {
  __typename?: 'mobile_tag_ages_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Tag_Ages_Variance_Fields = {
  __typename?: 'mobile_tag_ages_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "tag_target_groups" */
export type Mobile_Tag_Target_Groups = {
  __typename?: 'mobile_tag_target_groups';
  color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  service_tag_target_groups: Array<Mobile_Service_Tag_Target_Groups>;
  /** An aggregate relationship */
  service_tag_target_groups_aggregate: Mobile_Service_Tag_Target_Groups_Aggregate;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "tag_target_groups" */
export type Mobile_Tag_Target_GroupsService_Tag_Target_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


/** columns and relationships of "tag_target_groups" */
export type Mobile_Tag_Target_GroupsService_Tag_Target_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};

/** aggregated selection of "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Aggregate = {
  __typename?: 'mobile_tag_target_groups_aggregate';
  aggregate?: Maybe<Mobile_Tag_Target_Groups_Aggregate_Fields>;
  nodes: Array<Mobile_Tag_Target_Groups>;
};

/** aggregate fields of "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Aggregate_Fields = {
  __typename?: 'mobile_tag_target_groups_aggregate_fields';
  avg?: Maybe<Mobile_Tag_Target_Groups_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Tag_Target_Groups_Max_Fields>;
  min?: Maybe<Mobile_Tag_Target_Groups_Min_Fields>;
  stddev?: Maybe<Mobile_Tag_Target_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Tag_Target_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Tag_Target_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Tag_Target_Groups_Sum_Fields>;
  var_pop?: Maybe<Mobile_Tag_Target_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Tag_Target_Groups_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Tag_Target_Groups_Variance_Fields>;
};


/** aggregate fields of "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Tag_Target_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Tag_Target_Groups_Avg_Fields = {
  __typename?: 'mobile_tag_target_groups_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tag_target_groups". All fields are combined with a logical 'AND'. */
export type Mobile_Tag_Target_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Tag_Target_Groups_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Tag_Target_Groups_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  output_order?: InputMaybe<Int_Comparison_Exp>;
  picture_path?: InputMaybe<String_Comparison_Exp>;
  service_tag_target_groups?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
  service_tag_target_groups_aggregate?: InputMaybe<Mobile_Service_Tag_Target_Groups_Aggregate_Bool_Exp>;
  tag_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag_target_groups" */
export enum Mobile_Tag_Target_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagTargetGroupsPkey = 'tag_target_groups_pkey'
}

/** input type for incrementing numeric columns in table "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  picture_path?: InputMaybe<Scalars['String']['input']>;
  service_tag_target_groups?: InputMaybe<Mobile_Service_Tag_Target_Groups_Arr_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Tag_Target_Groups_Max_Fields = {
  __typename?: 'mobile_tag_target_groups_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Tag_Target_Groups_Min_Fields = {
  __typename?: 'mobile_tag_target_groups_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Mutation_Response = {
  __typename?: 'mobile_tag_target_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Tag_Target_Groups>;
};

/** input type for inserting object relation for remote table "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Obj_Rel_Insert_Input = {
  data: Mobile_Tag_Target_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Tag_Target_Groups_On_Conflict>;
};

/** on_conflict condition type for table "tag_target_groups" */
export type Mobile_Tag_Target_Groups_On_Conflict = {
  constraint: Mobile_Tag_Target_Groups_Constraint;
  update_columns?: Array<Mobile_Tag_Target_Groups_Update_Column>;
  where?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "tag_target_groups". */
export type Mobile_Tag_Target_Groups_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  output_order?: InputMaybe<Order_By>;
  picture_path?: InputMaybe<Order_By>;
  service_tag_target_groups_aggregate?: InputMaybe<Mobile_Service_Tag_Target_Groups_Aggregate_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tag_target_groups */
export type Mobile_Tag_Target_Groups_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "tag_target_groups" */
export enum Mobile_Tag_Target_Groups_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OutputOrder = 'output_order',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tag_target_groups" */
export type Mobile_Tag_Target_Groups_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  picture_path?: InputMaybe<Scalars['String']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Tag_Target_Groups_Stddev_Fields = {
  __typename?: 'mobile_tag_target_groups_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Tag_Target_Groups_Stddev_Pop_Fields = {
  __typename?: 'mobile_tag_target_groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Tag_Target_Groups_Stddev_Samp_Fields = {
  __typename?: 'mobile_tag_target_groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_tag_target_groups" */
export type Mobile_Tag_Target_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Tag_Target_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Tag_Target_Groups_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  picture_path?: InputMaybe<Scalars['String']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Tag_Target_Groups_Sum_Fields = {
  __typename?: 'mobile_tag_target_groups_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "tag_target_groups" */
export enum Mobile_Tag_Target_Groups_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OutputOrder = 'output_order',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Tag_Target_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Tag_Target_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Tag_Target_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Tag_Target_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Tag_Target_Groups_Var_Pop_Fields = {
  __typename?: 'mobile_tag_target_groups_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Tag_Target_Groups_Var_Samp_Fields = {
  __typename?: 'mobile_tag_target_groups_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Tag_Target_Groups_Variance_Fields = {
  __typename?: 'mobile_tag_target_groups_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "tag_targets" */
export type Mobile_Tag_Targets = {
  __typename?: 'mobile_tag_targets';
  color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  service_tag_targets: Array<Mobile_Service_Tag_Targets>;
  /** An aggregate relationship */
  service_tag_targets_aggregate: Mobile_Service_Tag_Targets_Aggregate;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "tag_targets" */
export type Mobile_Tag_TargetsService_Tag_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


/** columns and relationships of "tag_targets" */
export type Mobile_Tag_TargetsService_Tag_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};

/** aggregated selection of "tag_targets" */
export type Mobile_Tag_Targets_Aggregate = {
  __typename?: 'mobile_tag_targets_aggregate';
  aggregate?: Maybe<Mobile_Tag_Targets_Aggregate_Fields>;
  nodes: Array<Mobile_Tag_Targets>;
};

/** aggregate fields of "tag_targets" */
export type Mobile_Tag_Targets_Aggregate_Fields = {
  __typename?: 'mobile_tag_targets_aggregate_fields';
  avg?: Maybe<Mobile_Tag_Targets_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Tag_Targets_Max_Fields>;
  min?: Maybe<Mobile_Tag_Targets_Min_Fields>;
  stddev?: Maybe<Mobile_Tag_Targets_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Tag_Targets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Tag_Targets_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Tag_Targets_Sum_Fields>;
  var_pop?: Maybe<Mobile_Tag_Targets_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Tag_Targets_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Tag_Targets_Variance_Fields>;
};


/** aggregate fields of "tag_targets" */
export type Mobile_Tag_Targets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Tag_Targets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Tag_Targets_Avg_Fields = {
  __typename?: 'mobile_tag_targets_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tag_targets". All fields are combined with a logical 'AND'. */
export type Mobile_Tag_Targets_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Tag_Targets_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Tag_Targets_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  output_order?: InputMaybe<Int_Comparison_Exp>;
  picture_path?: InputMaybe<String_Comparison_Exp>;
  service_tag_targets?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
  service_tag_targets_aggregate?: InputMaybe<Mobile_Service_Tag_Targets_Aggregate_Bool_Exp>;
  tag_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag_targets" */
export enum Mobile_Tag_Targets_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagTargetsPkey = 'tag_targets_pkey'
}

/** input type for incrementing numeric columns in table "tag_targets" */
export type Mobile_Tag_Targets_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "tag_targets" */
export type Mobile_Tag_Targets_Insert_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  picture_path?: InputMaybe<Scalars['String']['input']>;
  service_tag_targets?: InputMaybe<Mobile_Service_Tag_Targets_Arr_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Mobile_Tag_Targets_Max_Fields = {
  __typename?: 'mobile_tag_targets_max_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Mobile_Tag_Targets_Min_Fields = {
  __typename?: 'mobile_tag_targets_min_fields';
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  picture_path?: Maybe<Scalars['String']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "tag_targets" */
export type Mobile_Tag_Targets_Mutation_Response = {
  __typename?: 'mobile_tag_targets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Tag_Targets>;
};

/** input type for inserting object relation for remote table "tag_targets" */
export type Mobile_Tag_Targets_Obj_Rel_Insert_Input = {
  data: Mobile_Tag_Targets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Tag_Targets_On_Conflict>;
};

/** on_conflict condition type for table "tag_targets" */
export type Mobile_Tag_Targets_On_Conflict = {
  constraint: Mobile_Tag_Targets_Constraint;
  update_columns?: Array<Mobile_Tag_Targets_Update_Column>;
  where?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
};

/** Ordering options when selecting data from "tag_targets". */
export type Mobile_Tag_Targets_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  output_order?: InputMaybe<Order_By>;
  picture_path?: InputMaybe<Order_By>;
  service_tag_targets_aggregate?: InputMaybe<Mobile_Service_Tag_Targets_Aggregate_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tag_targets */
export type Mobile_Tag_Targets_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "tag_targets" */
export enum Mobile_Tag_Targets_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OutputOrder = 'output_order',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tag_targets" */
export type Mobile_Tag_Targets_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  picture_path?: InputMaybe<Scalars['String']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Tag_Targets_Stddev_Fields = {
  __typename?: 'mobile_tag_targets_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Tag_Targets_Stddev_Pop_Fields = {
  __typename?: 'mobile_tag_targets_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Tag_Targets_Stddev_Samp_Fields = {
  __typename?: 'mobile_tag_targets_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_tag_targets" */
export type Mobile_Tag_Targets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Tag_Targets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Tag_Targets_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output_order?: InputMaybe<Scalars['Int']['input']>;
  picture_path?: InputMaybe<Scalars['String']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Tag_Targets_Sum_Fields = {
  __typename?: 'mobile_tag_targets_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  output_order?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "tag_targets" */
export enum Mobile_Tag_Targets_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OutputOrder = 'output_order',
  /** column name */
  PicturePath = 'picture_path',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Mobile_Tag_Targets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Tag_Targets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Tag_Targets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Tag_Targets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Tag_Targets_Var_Pop_Fields = {
  __typename?: 'mobile_tag_targets_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Tag_Targets_Var_Samp_Fields = {
  __typename?: 'mobile_tag_targets_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Tag_Targets_Variance_Fields = {
  __typename?: 'mobile_tag_targets_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  output_order?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "type_phones" */
export type Mobile_Type_Phones = {
  __typename?: 'mobile_type_phones';
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  phones: Array<Mobile_Phones>;
  /** An aggregate relationship */
  phones_aggregate: Mobile_Phones_Aggregate;
};


/** columns and relationships of "type_phones" */
export type Mobile_Type_PhonesPhonesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};


/** columns and relationships of "type_phones" */
export type Mobile_Type_PhonesPhones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};

/** aggregated selection of "type_phones" */
export type Mobile_Type_Phones_Aggregate = {
  __typename?: 'mobile_type_phones_aggregate';
  aggregate?: Maybe<Mobile_Type_Phones_Aggregate_Fields>;
  nodes: Array<Mobile_Type_Phones>;
};

/** aggregate fields of "type_phones" */
export type Mobile_Type_Phones_Aggregate_Fields = {
  __typename?: 'mobile_type_phones_aggregate_fields';
  avg?: Maybe<Mobile_Type_Phones_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Type_Phones_Max_Fields>;
  min?: Maybe<Mobile_Type_Phones_Min_Fields>;
  stddev?: Maybe<Mobile_Type_Phones_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Type_Phones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Type_Phones_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Type_Phones_Sum_Fields>;
  var_pop?: Maybe<Mobile_Type_Phones_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Type_Phones_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Type_Phones_Variance_Fields>;
};


/** aggregate fields of "type_phones" */
export type Mobile_Type_Phones_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Type_Phones_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Type_Phones_Avg_Fields = {
  __typename?: 'mobile_type_phones_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "type_phones". All fields are combined with a logical 'AND'. */
export type Mobile_Type_Phones_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Type_Phones_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Type_Phones_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phones?: InputMaybe<Mobile_Phones_Bool_Exp>;
  phones_aggregate?: InputMaybe<Mobile_Phones_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "type_phones" */
export enum Mobile_Type_Phones_Constraint {
  /** unique or primary key constraint on columns "name" */
  IndexTypePhonesOnName = 'index_type_phones_on_name',
  /** unique or primary key constraint on columns "id" */
  TypePhonesPkey = 'type_phones_pkey'
}

/** input type for incrementing numeric columns in table "type_phones" */
export type Mobile_Type_Phones_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "type_phones" */
export type Mobile_Type_Phones_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Mobile_Phones_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mobile_Type_Phones_Max_Fields = {
  __typename?: 'mobile_type_phones_max_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Type_Phones_Min_Fields = {
  __typename?: 'mobile_type_phones_min_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "type_phones" */
export type Mobile_Type_Phones_Mutation_Response = {
  __typename?: 'mobile_type_phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Type_Phones>;
};

/** input type for inserting object relation for remote table "type_phones" */
export type Mobile_Type_Phones_Obj_Rel_Insert_Input = {
  data: Mobile_Type_Phones_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Type_Phones_On_Conflict>;
};

/** on_conflict condition type for table "type_phones" */
export type Mobile_Type_Phones_On_Conflict = {
  constraint: Mobile_Type_Phones_Constraint;
  update_columns?: Array<Mobile_Type_Phones_Update_Column>;
  where?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
};

/** Ordering options when selecting data from "type_phones". */
export type Mobile_Type_Phones_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phones_aggregate?: InputMaybe<Mobile_Phones_Aggregate_Order_By>;
};

/** primary key columns input for table: type_phones */
export type Mobile_Type_Phones_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "type_phones" */
export enum Mobile_Type_Phones_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "type_phones" */
export type Mobile_Type_Phones_Set_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Type_Phones_Stddev_Fields = {
  __typename?: 'mobile_type_phones_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Type_Phones_Stddev_Pop_Fields = {
  __typename?: 'mobile_type_phones_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Type_Phones_Stddev_Samp_Fields = {
  __typename?: 'mobile_type_phones_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_type_phones" */
export type Mobile_Type_Phones_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Type_Phones_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Type_Phones_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Type_Phones_Sum_Fields = {
  __typename?: 'mobile_type_phones_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "type_phones" */
export enum Mobile_Type_Phones_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Mobile_Type_Phones_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Type_Phones_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Type_Phones_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Type_Phones_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Type_Phones_Var_Pop_Fields = {
  __typename?: 'mobile_type_phones_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Type_Phones_Var_Samp_Fields = {
  __typename?: 'mobile_type_phones_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Type_Phones_Variance_Fields = {
  __typename?: 'mobile_type_phones_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "type_profiles" */
export type Mobile_Type_Profiles = {
  __typename?: 'mobile_type_profiles';
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  profiles: Array<Mobile_Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Mobile_Profiles_Aggregate;
};


/** columns and relationships of "type_profiles" */
export type Mobile_Type_ProfilesProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


/** columns and relationships of "type_profiles" */
export type Mobile_Type_ProfilesProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};

/** aggregated selection of "type_profiles" */
export type Mobile_Type_Profiles_Aggregate = {
  __typename?: 'mobile_type_profiles_aggregate';
  aggregate?: Maybe<Mobile_Type_Profiles_Aggregate_Fields>;
  nodes: Array<Mobile_Type_Profiles>;
};

/** aggregate fields of "type_profiles" */
export type Mobile_Type_Profiles_Aggregate_Fields = {
  __typename?: 'mobile_type_profiles_aggregate_fields';
  avg?: Maybe<Mobile_Type_Profiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Type_Profiles_Max_Fields>;
  min?: Maybe<Mobile_Type_Profiles_Min_Fields>;
  stddev?: Maybe<Mobile_Type_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Type_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Type_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Type_Profiles_Sum_Fields>;
  var_pop?: Maybe<Mobile_Type_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Type_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Type_Profiles_Variance_Fields>;
};


/** aggregate fields of "type_profiles" */
export type Mobile_Type_Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Type_Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Type_Profiles_Avg_Fields = {
  __typename?: 'mobile_type_profiles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "type_profiles". All fields are combined with a logical 'AND'. */
export type Mobile_Type_Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Type_Profiles_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Type_Profiles_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profiles?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Mobile_Profiles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "type_profiles" */
export enum Mobile_Type_Profiles_Constraint {
  /** unique or primary key constraint on columns "id" */
  TypeProfilesPkey = 'type_profiles_pkey'
}

/** input type for incrementing numeric columns in table "type_profiles" */
export type Mobile_Type_Profiles_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "type_profiles" */
export type Mobile_Type_Profiles_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profiles?: InputMaybe<Mobile_Profiles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mobile_Type_Profiles_Max_Fields = {
  __typename?: 'mobile_type_profiles_max_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Mobile_Type_Profiles_Min_Fields = {
  __typename?: 'mobile_type_profiles_min_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "type_profiles" */
export type Mobile_Type_Profiles_Mutation_Response = {
  __typename?: 'mobile_type_profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Type_Profiles>;
};

/** input type for inserting object relation for remote table "type_profiles" */
export type Mobile_Type_Profiles_Obj_Rel_Insert_Input = {
  data: Mobile_Type_Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Type_Profiles_On_Conflict>;
};

/** on_conflict condition type for table "type_profiles" */
export type Mobile_Type_Profiles_On_Conflict = {
  constraint: Mobile_Type_Profiles_Constraint;
  update_columns?: Array<Mobile_Type_Profiles_Update_Column>;
  where?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "type_profiles". */
export type Mobile_Type_Profiles_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Mobile_Profiles_Aggregate_Order_By>;
};

/** primary key columns input for table: type_profiles */
export type Mobile_Type_Profiles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "type_profiles" */
export enum Mobile_Type_Profiles_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "type_profiles" */
export type Mobile_Type_Profiles_Set_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Type_Profiles_Stddev_Fields = {
  __typename?: 'mobile_type_profiles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Type_Profiles_Stddev_Pop_Fields = {
  __typename?: 'mobile_type_profiles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Type_Profiles_Stddev_Samp_Fields = {
  __typename?: 'mobile_type_profiles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_type_profiles" */
export type Mobile_Type_Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Type_Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Type_Profiles_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Type_Profiles_Sum_Fields = {
  __typename?: 'mobile_type_profiles_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "type_profiles" */
export enum Mobile_Type_Profiles_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Mobile_Type_Profiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Type_Profiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Type_Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Type_Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Type_Profiles_Var_Pop_Fields = {
  __typename?: 'mobile_type_profiles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Type_Profiles_Var_Samp_Fields = {
  __typename?: 'mobile_type_profiles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Type_Profiles_Variance_Fields = {
  __typename?: 'mobile_type_profiles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents = {
  __typename?: 'mobile_upload_pro_documents';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  file_path?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  pro_document_status?: Maybe<Mobile_Pro_Document_Statuses>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Aggregate = {
  __typename?: 'mobile_upload_pro_documents_aggregate';
  aggregate?: Maybe<Mobile_Upload_Pro_Documents_Aggregate_Fields>;
  nodes: Array<Mobile_Upload_Pro_Documents>;
};

export type Mobile_Upload_Pro_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mobile_Upload_Pro_Documents_Aggregate_Bool_Exp_Count>;
};

export type Mobile_Upload_Pro_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Aggregate_Fields = {
  __typename?: 'mobile_upload_pro_documents_aggregate_fields';
  avg?: Maybe<Mobile_Upload_Pro_Documents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Upload_Pro_Documents_Max_Fields>;
  min?: Maybe<Mobile_Upload_Pro_Documents_Min_Fields>;
  stddev?: Maybe<Mobile_Upload_Pro_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Upload_Pro_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Upload_Pro_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Upload_Pro_Documents_Sum_Fields>;
  var_pop?: Maybe<Mobile_Upload_Pro_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Upload_Pro_Documents_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Upload_Pro_Documents_Variance_Fields>;
};


/** aggregate fields of "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_Upload_Pro_Documents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_Upload_Pro_Documents_Max_Order_By>;
  min?: InputMaybe<Mobile_Upload_Pro_Documents_Min_Order_By>;
  stddev?: InputMaybe<Mobile_Upload_Pro_Documents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_Upload_Pro_Documents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_Upload_Pro_Documents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_Upload_Pro_Documents_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_Upload_Pro_Documents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_Upload_Pro_Documents_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_Upload_Pro_Documents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Arr_Rel_Insert_Input = {
  data: Array<Mobile_Upload_Pro_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_Upload_Pro_Documents_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_Upload_Pro_Documents_Avg_Fields = {
  __typename?: 'mobile_upload_pro_documents_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "upload_pro_documents". All fields are combined with a logical 'AND'. */
export type Mobile_Upload_Pro_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
  pro_document_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  pro_id?: InputMaybe<Bigint_Comparison_Exp>;
  problem_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "upload_pro_documents" */
export enum Mobile_Upload_Pro_Documents_Constraint {
  /** unique or primary key constraint on columns "id" */
  UploadProDocumentsPkey = 'upload_pro_documents_pkey'
}

/** input type for incrementing numeric columns in table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Obj_Rel_Insert_Input>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Upload_Pro_Documents_Max_Fields = {
  __typename?: 'mobile_upload_pro_documents_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_Upload_Pro_Documents_Min_Fields = {
  __typename?: 'mobile_upload_pro_documents_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Mutation_Response = {
  __typename?: 'mobile_upload_pro_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Upload_Pro_Documents>;
};

/** on_conflict condition type for table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_On_Conflict = {
  constraint: Mobile_Upload_Pro_Documents_Constraint;
  update_columns?: Array<Mobile_Upload_Pro_Documents_Update_Column>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "upload_pro_documents". */
export type Mobile_Upload_Pro_Documents_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pro_document_status?: InputMaybe<Mobile_Pro_Document_Statuses_Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: upload_pro_documents */
export type Mobile_Upload_Pro_Documents_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "upload_pro_documents" */
export enum Mobile_Upload_Pro_Documents_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Upload_Pro_Documents_Stddev_Fields = {
  __typename?: 'mobile_upload_pro_documents_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Upload_Pro_Documents_Stddev_Pop_Fields = {
  __typename?: 'mobile_upload_pro_documents_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Upload_Pro_Documents_Stddev_Samp_Fields = {
  __typename?: 'mobile_upload_pro_documents_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Upload_Pro_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Upload_Pro_Documents_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pro_document_status_id?: InputMaybe<Scalars['bigint']['input']>;
  pro_id?: InputMaybe<Scalars['bigint']['input']>;
  problem_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Upload_Pro_Documents_Sum_Fields = {
  __typename?: 'mobile_upload_pro_documents_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  pro_document_status_id?: Maybe<Scalars['bigint']['output']>;
  pro_id?: Maybe<Scalars['bigint']['output']>;
  problem_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "upload_pro_documents" */
export enum Mobile_Upload_Pro_Documents_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Id = 'id',
  /** column name */
  ProDocumentStatusId = 'pro_document_status_id',
  /** column name */
  ProId = 'pro_id',
  /** column name */
  ProblemId = 'problem_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Upload_Pro_Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Upload_Pro_Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Upload_Pro_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Upload_Pro_Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Upload_Pro_Documents_Var_Pop_Fields = {
  __typename?: 'mobile_upload_pro_documents_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_Upload_Pro_Documents_Var_Samp_Fields = {
  __typename?: 'mobile_upload_pro_documents_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_Upload_Pro_Documents_Variance_Fields = {
  __typename?: 'mobile_upload_pro_documents_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  pro_document_status_id?: Maybe<Scalars['Float']['output']>;
  pro_id?: Maybe<Scalars['Float']['output']>;
  problem_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "upload_pro_documents" */
export type Mobile_Upload_Pro_Documents_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  pro_document_status_id?: InputMaybe<Order_By>;
  pro_id?: InputMaybe<Order_By>;
  problem_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_chat_rights" */
export type Mobile_User_Chat_Rights = {
  __typename?: 'mobile_user_chat_rights';
  blocked_user?: Maybe<Scalars['Boolean']['output']>;
  create_admin_user?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['timestamptz']['output'];
  delete_message?: Maybe<Scalars['Boolean']['output']>;
  edit_profile_group?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['bigint']['output'];
  pin_message?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "user_chat_rights" */
export type Mobile_User_Chat_Rights_Aggregate = {
  __typename?: 'mobile_user_chat_rights_aggregate';
  aggregate?: Maybe<Mobile_User_Chat_Rights_Aggregate_Fields>;
  nodes: Array<Mobile_User_Chat_Rights>;
};

/** aggregate fields of "user_chat_rights" */
export type Mobile_User_Chat_Rights_Aggregate_Fields = {
  __typename?: 'mobile_user_chat_rights_aggregate_fields';
  avg?: Maybe<Mobile_User_Chat_Rights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_User_Chat_Rights_Max_Fields>;
  min?: Maybe<Mobile_User_Chat_Rights_Min_Fields>;
  stddev?: Maybe<Mobile_User_Chat_Rights_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_User_Chat_Rights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_User_Chat_Rights_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_User_Chat_Rights_Sum_Fields>;
  var_pop?: Maybe<Mobile_User_Chat_Rights_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_User_Chat_Rights_Var_Samp_Fields>;
  variance?: Maybe<Mobile_User_Chat_Rights_Variance_Fields>;
};


/** aggregate fields of "user_chat_rights" */
export type Mobile_User_Chat_Rights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_User_Chat_Rights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_User_Chat_Rights_Avg_Fields = {
  __typename?: 'mobile_user_chat_rights_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_chat_rights". All fields are combined with a logical 'AND'. */
export type Mobile_User_Chat_Rights_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_User_Chat_Rights_Bool_Exp>>;
  _not?: InputMaybe<Mobile_User_Chat_Rights_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_User_Chat_Rights_Bool_Exp>>;
  blocked_user?: InputMaybe<Boolean_Comparison_Exp>;
  create_admin_user?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delete_message?: InputMaybe<Boolean_Comparison_Exp>;
  edit_profile_group?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  pin_message?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_chat_rights" */
export enum Mobile_User_Chat_Rights_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserChatRightsPkey = 'user_chat_rights_pkey'
}

/** input type for incrementing numeric columns in table "user_chat_rights" */
export type Mobile_User_Chat_Rights_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "user_chat_rights" */
export type Mobile_User_Chat_Rights_Insert_Input = {
  blocked_user?: InputMaybe<Scalars['Boolean']['input']>;
  create_admin_user?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delete_message?: InputMaybe<Scalars['Boolean']['input']>;
  edit_profile_group?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pin_message?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_User_Chat_Rights_Max_Fields = {
  __typename?: 'mobile_user_chat_rights_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_User_Chat_Rights_Min_Fields = {
  __typename?: 'mobile_user_chat_rights_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "user_chat_rights" */
export type Mobile_User_Chat_Rights_Mutation_Response = {
  __typename?: 'mobile_user_chat_rights_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_User_Chat_Rights>;
};

/** on_conflict condition type for table "user_chat_rights" */
export type Mobile_User_Chat_Rights_On_Conflict = {
  constraint: Mobile_User_Chat_Rights_Constraint;
  update_columns?: Array<Mobile_User_Chat_Rights_Update_Column>;
  where?: InputMaybe<Mobile_User_Chat_Rights_Bool_Exp>;
};

/** Ordering options when selecting data from "user_chat_rights". */
export type Mobile_User_Chat_Rights_Order_By = {
  blocked_user?: InputMaybe<Order_By>;
  create_admin_user?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delete_message?: InputMaybe<Order_By>;
  edit_profile_group?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pin_message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_chat_rights */
export type Mobile_User_Chat_Rights_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "user_chat_rights" */
export enum Mobile_User_Chat_Rights_Select_Column {
  /** column name */
  BlockedUser = 'blocked_user',
  /** column name */
  CreateAdminUser = 'create_admin_user',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeleteMessage = 'delete_message',
  /** column name */
  EditProfileGroup = 'edit_profile_group',
  /** column name */
  Id = 'id',
  /** column name */
  PinMessage = 'pin_message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_chat_rights" */
export type Mobile_User_Chat_Rights_Set_Input = {
  blocked_user?: InputMaybe<Scalars['Boolean']['input']>;
  create_admin_user?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delete_message?: InputMaybe<Scalars['Boolean']['input']>;
  edit_profile_group?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pin_message?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_User_Chat_Rights_Stddev_Fields = {
  __typename?: 'mobile_user_chat_rights_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_User_Chat_Rights_Stddev_Pop_Fields = {
  __typename?: 'mobile_user_chat_rights_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_User_Chat_Rights_Stddev_Samp_Fields = {
  __typename?: 'mobile_user_chat_rights_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_user_chat_rights" */
export type Mobile_User_Chat_Rights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_User_Chat_Rights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_User_Chat_Rights_Stream_Cursor_Value_Input = {
  blocked_user?: InputMaybe<Scalars['Boolean']['input']>;
  create_admin_user?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delete_message?: InputMaybe<Scalars['Boolean']['input']>;
  edit_profile_group?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  pin_message?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_User_Chat_Rights_Sum_Fields = {
  __typename?: 'mobile_user_chat_rights_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "user_chat_rights" */
export enum Mobile_User_Chat_Rights_Update_Column {
  /** column name */
  BlockedUser = 'blocked_user',
  /** column name */
  CreateAdminUser = 'create_admin_user',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeleteMessage = 'delete_message',
  /** column name */
  EditProfileGroup = 'edit_profile_group',
  /** column name */
  Id = 'id',
  /** column name */
  PinMessage = 'pin_message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_User_Chat_Rights_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_User_Chat_Rights_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_User_Chat_Rights_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_User_Chat_Rights_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_User_Chat_Rights_Var_Pop_Fields = {
  __typename?: 'mobile_user_chat_rights_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_User_Chat_Rights_Var_Samp_Fields = {
  __typename?: 'mobile_user_chat_rights_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_User_Chat_Rights_Variance_Fields = {
  __typename?: 'mobile_user_chat_rights_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "user_chats" */
export type Mobile_User_Chats = {
  __typename?: 'mobile_user_chats';
  /** An object relationship */
  chat?: Maybe<Mobile_Chats>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  chat_role?: Maybe<Mobile_Chat_Roles>;
  chat_role_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  is_ban?: Maybe<Scalars['Boolean']['output']>;
  is_blocked?: Maybe<Scalars['Boolean']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  is_mute?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  profile?: Maybe<Mobile_Profiles>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  profiles?: Maybe<Mobile_Profiles>;
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "user_chats" */
export type Mobile_User_Chats_Aggregate = {
  __typename?: 'mobile_user_chats_aggregate';
  aggregate?: Maybe<Mobile_User_Chats_Aggregate_Fields>;
  nodes: Array<Mobile_User_Chats>;
};

export type Mobile_User_Chats_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mobile_User_Chats_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_User_Chats_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mobile_User_Chats_Aggregate_Bool_Exp_Count>;
};

export type Mobile_User_Chats_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_User_Chats_Select_Column_Mobile_User_Chats_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_User_Chats_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_User_Chats_Select_Column_Mobile_User_Chats_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_User_Chats_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_chats" */
export type Mobile_User_Chats_Aggregate_Fields = {
  __typename?: 'mobile_user_chats_aggregate_fields';
  avg?: Maybe<Mobile_User_Chats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_User_Chats_Max_Fields>;
  min?: Maybe<Mobile_User_Chats_Min_Fields>;
  stddev?: Maybe<Mobile_User_Chats_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_User_Chats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_User_Chats_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_User_Chats_Sum_Fields>;
  var_pop?: Maybe<Mobile_User_Chats_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_User_Chats_Var_Samp_Fields>;
  variance?: Maybe<Mobile_User_Chats_Variance_Fields>;
};


/** aggregate fields of "user_chats" */
export type Mobile_User_Chats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_chats" */
export type Mobile_User_Chats_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_User_Chats_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_User_Chats_Max_Order_By>;
  min?: InputMaybe<Mobile_User_Chats_Min_Order_By>;
  stddev?: InputMaybe<Mobile_User_Chats_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_User_Chats_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_User_Chats_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_User_Chats_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_User_Chats_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_User_Chats_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_User_Chats_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_chats" */
export type Mobile_User_Chats_Arr_Rel_Insert_Input = {
  data: Array<Mobile_User_Chats_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mobile_User_Chats_On_Conflict>;
};

/** aggregate avg on columns */
export type Mobile_User_Chats_Avg_Fields = {
  __typename?: 'mobile_user_chats_avg_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_role_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_chats" */
export type Mobile_User_Chats_Avg_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_chats". All fields are combined with a logical 'AND'. */
export type Mobile_User_Chats_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_User_Chats_Bool_Exp>>;
  _not?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_User_Chats_Bool_Exp>>;
  chat?: InputMaybe<Mobile_Chats_Bool_Exp>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  chat_role?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
  chat_role_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletion_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_ban?: InputMaybe<Boolean_Comparison_Exp>;
  is_blocked?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  is_mute?: InputMaybe<Boolean_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  profiles?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_chats" */
export enum Mobile_User_Chats_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserChatsPkey = 'user_chats_pkey'
}

/** input type for incrementing numeric columns in table "user_chats" */
export type Mobile_User_Chats_Inc_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_role_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "user_chats" */
export type Mobile_User_Chats_Insert_Input = {
  chat?: InputMaybe<Mobile_Chats_Obj_Rel_Insert_Input>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_role?: InputMaybe<Mobile_Chat_Roles_Obj_Rel_Insert_Input>;
  chat_role_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_ban?: InputMaybe<Scalars['Boolean']['input']>;
  is_blocked?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  is_mute?: InputMaybe<Scalars['Boolean']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  profiles?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_User_Chats_Max_Fields = {
  __typename?: 'mobile_user_chats_max_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_role_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "user_chats" */
export type Mobile_User_Chats_Max_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_User_Chats_Min_Fields = {
  __typename?: 'mobile_user_chats_min_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_role_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deletion_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "user_chats" */
export type Mobile_User_Chats_Min_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_chats" */
export type Mobile_User_Chats_Mutation_Response = {
  __typename?: 'mobile_user_chats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_User_Chats>;
};

/** on_conflict condition type for table "user_chats" */
export type Mobile_User_Chats_On_Conflict = {
  constraint: Mobile_User_Chats_Constraint;
  update_columns?: Array<Mobile_User_Chats_Update_Column>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};

/** Ordering options when selecting data from "user_chats". */
export type Mobile_User_Chats_Order_By = {
  chat?: InputMaybe<Mobile_Chats_Order_By>;
  chat_id?: InputMaybe<Order_By>;
  chat_role?: InputMaybe<Mobile_Chat_Roles_Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deletion_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_ban?: InputMaybe<Order_By>;
  is_blocked?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  is_mute?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  profiles?: InputMaybe<Mobile_Profiles_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_chats */
export type Mobile_User_Chats_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "user_chats" */
export enum Mobile_User_Chats_Select_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatRoleId = 'chat_role_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletionDate = 'deletion_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsBan = 'is_ban',
  /** column name */
  IsBlocked = 'is_blocked',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsMute = 'is_mute',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mobile_user_chats_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_chats" */
export enum Mobile_User_Chats_Select_Column_Mobile_User_Chats_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsBan = 'is_ban',
  /** column name */
  IsBlocked = 'is_blocked',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsMute = 'is_mute'
}

/** select "mobile_user_chats_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_chats" */
export enum Mobile_User_Chats_Select_Column_Mobile_User_Chats_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsBan = 'is_ban',
  /** column name */
  IsBlocked = 'is_blocked',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsMute = 'is_mute'
}

/** input type for updating data in table "user_chats" */
export type Mobile_User_Chats_Set_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_role_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_ban?: InputMaybe<Scalars['Boolean']['input']>;
  is_blocked?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  is_mute?: InputMaybe<Scalars['Boolean']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_User_Chats_Stddev_Fields = {
  __typename?: 'mobile_user_chats_stddev_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_role_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_chats" */
export type Mobile_User_Chats_Stddev_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_User_Chats_Stddev_Pop_Fields = {
  __typename?: 'mobile_user_chats_stddev_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_role_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_chats" */
export type Mobile_User_Chats_Stddev_Pop_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_User_Chats_Stddev_Samp_Fields = {
  __typename?: 'mobile_user_chats_stddev_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_role_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_chats" */
export type Mobile_User_Chats_Stddev_Samp_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_user_chats" */
export type Mobile_User_Chats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_User_Chats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_User_Chats_Stream_Cursor_Value_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  chat_role_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deletion_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_ban?: InputMaybe<Scalars['Boolean']['input']>;
  is_blocked?: InputMaybe<Scalars['Boolean']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  is_mute?: InputMaybe<Scalars['Boolean']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_User_Chats_Sum_Fields = {
  __typename?: 'mobile_user_chats_sum_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  chat_role_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "user_chats" */
export type Mobile_User_Chats_Sum_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_chats" */
export enum Mobile_User_Chats_Update_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  ChatRoleId = 'chat_role_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletionDate = 'deletion_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsBan = 'is_ban',
  /** column name */
  IsBlocked = 'is_blocked',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsMute = 'is_mute',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_User_Chats_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_User_Chats_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_User_Chats_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_User_Chats_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_User_Chats_Var_Pop_Fields = {
  __typename?: 'mobile_user_chats_var_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_role_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_chats" */
export type Mobile_User_Chats_Var_Pop_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_User_Chats_Var_Samp_Fields = {
  __typename?: 'mobile_user_chats_var_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_role_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_chats" */
export type Mobile_User_Chats_Var_Samp_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_User_Chats_Variance_Fields = {
  __typename?: 'mobile_user_chats_variance_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  chat_role_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_chats" */
export type Mobile_User_Chats_Variance_Order_By = {
  chat_id?: InputMaybe<Order_By>;
  chat_role_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "view_chat_messages" */
export type Mobile_View_Chat_Messages = {
  __typename?: 'mobile_view_chat_messages';
  /** An object relationship */
  chat?: Maybe<Mobile_Chats>;
  chat_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "view_chat_messages" */
export type Mobile_View_Chat_Messages_Aggregate = {
  __typename?: 'mobile_view_chat_messages_aggregate';
  aggregate?: Maybe<Mobile_View_Chat_Messages_Aggregate_Fields>;
  nodes: Array<Mobile_View_Chat_Messages>;
};

/** aggregate fields of "view_chat_messages" */
export type Mobile_View_Chat_Messages_Aggregate_Fields = {
  __typename?: 'mobile_view_chat_messages_aggregate_fields';
  avg?: Maybe<Mobile_View_Chat_Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_View_Chat_Messages_Max_Fields>;
  min?: Maybe<Mobile_View_Chat_Messages_Min_Fields>;
  stddev?: Maybe<Mobile_View_Chat_Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_View_Chat_Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_View_Chat_Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_View_Chat_Messages_Sum_Fields>;
  var_pop?: Maybe<Mobile_View_Chat_Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_View_Chat_Messages_Var_Samp_Fields>;
  variance?: Maybe<Mobile_View_Chat_Messages_Variance_Fields>;
};


/** aggregate fields of "view_chat_messages" */
export type Mobile_View_Chat_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_View_Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_View_Chat_Messages_Avg_Fields = {
  __typename?: 'mobile_view_chat_messages_avg_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "view_chat_messages". All fields are combined with a logical 'AND'. */
export type Mobile_View_Chat_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_View_Chat_Messages_Bool_Exp>>;
  _not?: InputMaybe<Mobile_View_Chat_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_View_Chat_Messages_Bool_Exp>>;
  chat?: InputMaybe<Mobile_Chats_Bool_Exp>;
  chat_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "view_chat_messages" */
export type Mobile_View_Chat_Messages_Inc_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "view_chat_messages" */
export type Mobile_View_Chat_Messages_Insert_Input = {
  chat?: InputMaybe<Mobile_Chats_Obj_Rel_Insert_Input>;
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_View_Chat_Messages_Max_Fields = {
  __typename?: 'mobile_view_chat_messages_max_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_View_Chat_Messages_Min_Fields = {
  __typename?: 'mobile_view_chat_messages_min_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "view_chat_messages" */
export type Mobile_View_Chat_Messages_Mutation_Response = {
  __typename?: 'mobile_view_chat_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_View_Chat_Messages>;
};

/** Ordering options when selecting data from "view_chat_messages". */
export type Mobile_View_Chat_Messages_Order_By = {
  chat?: InputMaybe<Mobile_Chats_Order_By>;
  chat_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_chat_messages" */
export enum Mobile_View_Chat_Messages_Select_Column {
  /** column name */
  ChatId = 'chat_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "view_chat_messages" */
export type Mobile_View_Chat_Messages_Set_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_View_Chat_Messages_Stddev_Fields = {
  __typename?: 'mobile_view_chat_messages_stddev_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_View_Chat_Messages_Stddev_Pop_Fields = {
  __typename?: 'mobile_view_chat_messages_stddev_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_View_Chat_Messages_Stddev_Samp_Fields = {
  __typename?: 'mobile_view_chat_messages_stddev_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_view_chat_messages" */
export type Mobile_View_Chat_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_View_Chat_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_View_Chat_Messages_Stream_Cursor_Value_Input = {
  chat_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_View_Chat_Messages_Sum_Fields = {
  __typename?: 'mobile_view_chat_messages_sum_fields';
  chat_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

export type Mobile_View_Chat_Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_View_Chat_Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_View_Chat_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_View_Chat_Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_View_Chat_Messages_Var_Pop_Fields = {
  __typename?: 'mobile_view_chat_messages_var_pop_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_View_Chat_Messages_Var_Samp_Fields = {
  __typename?: 'mobile_view_chat_messages_var_samp_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_View_Chat_Messages_Variance_Fields = {
  __typename?: 'mobile_view_chat_messages_variance_fields';
  chat_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "water_norms" */
export type Mobile_Water_Norms = {
  __typename?: 'mobile_water_norms';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm: Scalars['float8']['output'];
  /** An object relationship */
  profile: Mobile_Profiles;
  profile_id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['bigint']['output'];
};

/** aggregated selection of "water_norms" */
export type Mobile_Water_Norms_Aggregate = {
  __typename?: 'mobile_water_norms_aggregate';
  aggregate?: Maybe<Mobile_Water_Norms_Aggregate_Fields>;
  nodes: Array<Mobile_Water_Norms>;
};

/** aggregate fields of "water_norms" */
export type Mobile_Water_Norms_Aggregate_Fields = {
  __typename?: 'mobile_water_norms_aggregate_fields';
  avg?: Maybe<Mobile_Water_Norms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Water_Norms_Max_Fields>;
  min?: Maybe<Mobile_Water_Norms_Min_Fields>;
  stddev?: Maybe<Mobile_Water_Norms_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Water_Norms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Water_Norms_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Water_Norms_Sum_Fields>;
  var_pop?: Maybe<Mobile_Water_Norms_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Water_Norms_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Water_Norms_Variance_Fields>;
};


/** aggregate fields of "water_norms" */
export type Mobile_Water_Norms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Water_Norms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Water_Norms_Avg_Fields = {
  __typename?: 'mobile_water_norms_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "water_norms". All fields are combined with a logical 'AND'. */
export type Mobile_Water_Norms_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Water_Norms_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Water_Norms_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Water_Norms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  mentor_norm?: InputMaybe<Float8_Comparison_Exp>;
  mentor_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  norm?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Mobile_Profiles_Bool_Exp>;
  profile_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "water_norms" */
export enum Mobile_Water_Norms_Constraint {
  /** unique or primary key constraint on columns "id" */
  WaterNormsPkey = 'water_norms_pkey'
}

/** input type for incrementing numeric columns in table "water_norms" */
export type Mobile_Water_Norms_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "water_norms" */
export type Mobile_Water_Norms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile?: InputMaybe<Mobile_Profiles_Obj_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Mobile_Water_Norms_Max_Fields = {
  __typename?: 'mobile_water_norms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Mobile_Water_Norms_Min_Fields = {
  __typename?: 'mobile_water_norms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "water_norms" */
export type Mobile_Water_Norms_Mutation_Response = {
  __typename?: 'mobile_water_norms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Water_Norms>;
};

/** on_conflict condition type for table "water_norms" */
export type Mobile_Water_Norms_On_Conflict = {
  constraint: Mobile_Water_Norms_Constraint;
  update_columns?: Array<Mobile_Water_Norms_Update_Column>;
  where?: InputMaybe<Mobile_Water_Norms_Bool_Exp>;
};

/** Ordering options when selecting data from "water_norms". */
export type Mobile_Water_Norms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentor_norm?: InputMaybe<Order_By>;
  mentor_user_id?: InputMaybe<Order_By>;
  norm?: InputMaybe<Order_By>;
  profile?: InputMaybe<Mobile_Profiles_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: water_norms */
export type Mobile_Water_Norms_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "water_norms" */
export enum Mobile_Water_Norms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "water_norms" */
export type Mobile_Water_Norms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Water_Norms_Stddev_Fields = {
  __typename?: 'mobile_water_norms_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Water_Norms_Stddev_Pop_Fields = {
  __typename?: 'mobile_water_norms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Water_Norms_Stddev_Samp_Fields = {
  __typename?: 'mobile_water_norms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_water_norms" */
export type Mobile_Water_Norms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Water_Norms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Water_Norms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  mentor_norm?: InputMaybe<Scalars['float8']['input']>;
  mentor_user_id?: InputMaybe<Scalars['bigint']['input']>;
  norm?: InputMaybe<Scalars['float8']['input']>;
  profile_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Water_Norms_Sum_Fields = {
  __typename?: 'mobile_water_norms_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  mentor_norm?: Maybe<Scalars['float8']['output']>;
  mentor_user_id?: Maybe<Scalars['bigint']['output']>;
  norm?: Maybe<Scalars['float8']['output']>;
  profile_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "water_norms" */
export enum Mobile_Water_Norms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentorNorm = 'mentor_norm',
  /** column name */
  MentorUserId = 'mentor_user_id',
  /** column name */
  Norm = 'norm',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mobile_Water_Norms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Water_Norms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Water_Norms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Water_Norms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Water_Norms_Var_Pop_Fields = {
  __typename?: 'mobile_water_norms_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Water_Norms_Var_Samp_Fields = {
  __typename?: 'mobile_water_norms_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Water_Norms_Variance_Fields = {
  __typename?: 'mobile_water_norms_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mentor_norm?: Maybe<Scalars['Float']['output']>;
  mentor_user_id?: Maybe<Scalars['Float']['output']>;
  norm?: Maybe<Scalars['Float']['output']>;
  profile_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "water_nows" */
export type Mobile_Water_Nows = {
  __typename?: 'mobile_water_nows';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "water_nows" */
export type Mobile_Water_Nows_Aggregate = {
  __typename?: 'mobile_water_nows_aggregate';
  aggregate?: Maybe<Mobile_Water_Nows_Aggregate_Fields>;
  nodes: Array<Mobile_Water_Nows>;
};

/** aggregate fields of "water_nows" */
export type Mobile_Water_Nows_Aggregate_Fields = {
  __typename?: 'mobile_water_nows_aggregate_fields';
  avg?: Maybe<Mobile_Water_Nows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Water_Nows_Max_Fields>;
  min?: Maybe<Mobile_Water_Nows_Min_Fields>;
  stddev?: Maybe<Mobile_Water_Nows_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Water_Nows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Water_Nows_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Water_Nows_Sum_Fields>;
  var_pop?: Maybe<Mobile_Water_Nows_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Water_Nows_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Water_Nows_Variance_Fields>;
};


/** aggregate fields of "water_nows" */
export type Mobile_Water_Nows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Water_Nows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Water_Nows_Avg_Fields = {
  __typename?: 'mobile_water_nows_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "water_nows". All fields are combined with a logical 'AND'. */
export type Mobile_Water_Nows_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Water_Nows_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Water_Nows_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Water_Nows_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "water_nows" */
export enum Mobile_Water_Nows_Constraint {
  /** unique or primary key constraint on columns "id" */
  WaterNowsPkey = 'water_nows_pkey'
}

/** input type for incrementing numeric columns in table "water_nows" */
export type Mobile_Water_Nows_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "water_nows" */
export type Mobile_Water_Nows_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Mobile_Water_Nows_Max_Fields = {
  __typename?: 'mobile_water_nows_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type Mobile_Water_Nows_Min_Fields = {
  __typename?: 'mobile_water_nows_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** response of any mutation on the table "water_nows" */
export type Mobile_Water_Nows_Mutation_Response = {
  __typename?: 'mobile_water_nows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Water_Nows>;
};

/** on_conflict condition type for table "water_nows" */
export type Mobile_Water_Nows_On_Conflict = {
  constraint: Mobile_Water_Nows_Constraint;
  update_columns?: Array<Mobile_Water_Nows_Update_Column>;
  where?: InputMaybe<Mobile_Water_Nows_Bool_Exp>;
};

/** Ordering options when selecting data from "water_nows". */
export type Mobile_Water_Nows_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: water_nows */
export type Mobile_Water_Nows_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "water_nows" */
export enum Mobile_Water_Nows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "water_nows" */
export type Mobile_Water_Nows_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Water_Nows_Stddev_Fields = {
  __typename?: 'mobile_water_nows_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Water_Nows_Stddev_Pop_Fields = {
  __typename?: 'mobile_water_nows_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Water_Nows_Stddev_Samp_Fields = {
  __typename?: 'mobile_water_nows_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_water_nows" */
export type Mobile_Water_Nows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Water_Nows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Water_Nows_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Water_Nows_Sum_Fields = {
  __typename?: 'mobile_water_nows_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "water_nows" */
export enum Mobile_Water_Nows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Water_Nows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Water_Nows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Water_Nows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Water_Nows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Water_Nows_Var_Pop_Fields = {
  __typename?: 'mobile_water_nows_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Water_Nows_Var_Samp_Fields = {
  __typename?: 'mobile_water_nows_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Water_Nows_Variance_Fields = {
  __typename?: 'mobile_water_nows_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "waters" */
export type Mobile_Waters = {
  __typename?: 'mobile_waters';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "waters" */
export type Mobile_Waters_Aggregate = {
  __typename?: 'mobile_waters_aggregate';
  aggregate?: Maybe<Mobile_Waters_Aggregate_Fields>;
  nodes: Array<Mobile_Waters>;
};

/** aggregate fields of "waters" */
export type Mobile_Waters_Aggregate_Fields = {
  __typename?: 'mobile_waters_aggregate_fields';
  avg?: Maybe<Mobile_Waters_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Waters_Max_Fields>;
  min?: Maybe<Mobile_Waters_Min_Fields>;
  stddev?: Maybe<Mobile_Waters_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Waters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Waters_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Waters_Sum_Fields>;
  var_pop?: Maybe<Mobile_Waters_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Waters_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Waters_Variance_Fields>;
};


/** aggregate fields of "waters" */
export type Mobile_Waters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Waters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Waters_Avg_Fields = {
  __typename?: 'mobile_waters_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "waters". All fields are combined with a logical 'AND'. */
export type Mobile_Waters_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Waters_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Waters_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Waters_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "waters" */
export enum Mobile_Waters_Constraint {
  /** unique or primary key constraint on columns "id" */
  WatersPkey = 'waters_pkey'
}

/** input type for incrementing numeric columns in table "waters" */
export type Mobile_Waters_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "waters" */
export type Mobile_Waters_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Mobile_Waters_Max_Fields = {
  __typename?: 'mobile_waters_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type Mobile_Waters_Min_Fields = {
  __typename?: 'mobile_waters_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** response of any mutation on the table "waters" */
export type Mobile_Waters_Mutation_Response = {
  __typename?: 'mobile_waters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Waters>;
};

/** on_conflict condition type for table "waters" */
export type Mobile_Waters_On_Conflict = {
  constraint: Mobile_Waters_Constraint;
  update_columns?: Array<Mobile_Waters_Update_Column>;
  where?: InputMaybe<Mobile_Waters_Bool_Exp>;
};

/** Ordering options when selecting data from "waters". */
export type Mobile_Waters_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: waters */
export type Mobile_Waters_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "waters" */
export enum Mobile_Waters_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "waters" */
export type Mobile_Waters_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Waters_Stddev_Fields = {
  __typename?: 'mobile_waters_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Waters_Stddev_Pop_Fields = {
  __typename?: 'mobile_waters_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Waters_Stddev_Samp_Fields = {
  __typename?: 'mobile_waters_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_waters" */
export type Mobile_Waters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Waters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Waters_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Waters_Sum_Fields = {
  __typename?: 'mobile_waters_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "waters" */
export enum Mobile_Waters_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Waters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Waters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Waters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Waters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Waters_Var_Pop_Fields = {
  __typename?: 'mobile_waters_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Waters_Var_Samp_Fields = {
  __typename?: 'mobile_waters_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Waters_Variance_Fields = {
  __typename?: 'mobile_waters_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "weight_nows" */
export type Mobile_Weight_Nows = {
  __typename?: 'mobile_weight_nows';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "weight_nows" */
export type Mobile_Weight_Nows_Aggregate = {
  __typename?: 'mobile_weight_nows_aggregate';
  aggregate?: Maybe<Mobile_Weight_Nows_Aggregate_Fields>;
  nodes: Array<Mobile_Weight_Nows>;
};

/** aggregate fields of "weight_nows" */
export type Mobile_Weight_Nows_Aggregate_Fields = {
  __typename?: 'mobile_weight_nows_aggregate_fields';
  avg?: Maybe<Mobile_Weight_Nows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Weight_Nows_Max_Fields>;
  min?: Maybe<Mobile_Weight_Nows_Min_Fields>;
  stddev?: Maybe<Mobile_Weight_Nows_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Weight_Nows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Weight_Nows_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Weight_Nows_Sum_Fields>;
  var_pop?: Maybe<Mobile_Weight_Nows_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Weight_Nows_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Weight_Nows_Variance_Fields>;
};


/** aggregate fields of "weight_nows" */
export type Mobile_Weight_Nows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Weight_Nows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Weight_Nows_Avg_Fields = {
  __typename?: 'mobile_weight_nows_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "weight_nows". All fields are combined with a logical 'AND'. */
export type Mobile_Weight_Nows_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Weight_Nows_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Weight_Nows_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Weight_Nows_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "weight_nows" */
export enum Mobile_Weight_Nows_Constraint {
  /** unique or primary key constraint on columns "id" */
  WeightNowsPkey = 'weight_nows_pkey'
}

/** input type for incrementing numeric columns in table "weight_nows" */
export type Mobile_Weight_Nows_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "weight_nows" */
export type Mobile_Weight_Nows_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Mobile_Weight_Nows_Max_Fields = {
  __typename?: 'mobile_weight_nows_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type Mobile_Weight_Nows_Min_Fields = {
  __typename?: 'mobile_weight_nows_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** response of any mutation on the table "weight_nows" */
export type Mobile_Weight_Nows_Mutation_Response = {
  __typename?: 'mobile_weight_nows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Weight_Nows>;
};

/** on_conflict condition type for table "weight_nows" */
export type Mobile_Weight_Nows_On_Conflict = {
  constraint: Mobile_Weight_Nows_Constraint;
  update_columns?: Array<Mobile_Weight_Nows_Update_Column>;
  where?: InputMaybe<Mobile_Weight_Nows_Bool_Exp>;
};

/** Ordering options when selecting data from "weight_nows". */
export type Mobile_Weight_Nows_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: weight_nows */
export type Mobile_Weight_Nows_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "weight_nows" */
export enum Mobile_Weight_Nows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "weight_nows" */
export type Mobile_Weight_Nows_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Weight_Nows_Stddev_Fields = {
  __typename?: 'mobile_weight_nows_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Weight_Nows_Stddev_Pop_Fields = {
  __typename?: 'mobile_weight_nows_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Weight_Nows_Stddev_Samp_Fields = {
  __typename?: 'mobile_weight_nows_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_weight_nows" */
export type Mobile_Weight_Nows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Weight_Nows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Weight_Nows_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Weight_Nows_Sum_Fields = {
  __typename?: 'mobile_weight_nows_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "weight_nows" */
export enum Mobile_Weight_Nows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Weight_Nows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Weight_Nows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Weight_Nows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Weight_Nows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Weight_Nows_Var_Pop_Fields = {
  __typename?: 'mobile_weight_nows_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Weight_Nows_Var_Samp_Fields = {
  __typename?: 'mobile_weight_nows_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Weight_Nows_Variance_Fields = {
  __typename?: 'mobile_weight_nows_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "weights" */
export type Mobile_Weights = {
  __typename?: 'mobile_weights';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "weights" */
export type Mobile_Weights_Aggregate = {
  __typename?: 'mobile_weights_aggregate';
  aggregate?: Maybe<Mobile_Weights_Aggregate_Fields>;
  nodes: Array<Mobile_Weights>;
};

/** aggregate fields of "weights" */
export type Mobile_Weights_Aggregate_Fields = {
  __typename?: 'mobile_weights_aggregate_fields';
  avg?: Maybe<Mobile_Weights_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Mobile_Weights_Max_Fields>;
  min?: Maybe<Mobile_Weights_Min_Fields>;
  stddev?: Maybe<Mobile_Weights_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_Weights_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_Weights_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_Weights_Sum_Fields>;
  var_pop?: Maybe<Mobile_Weights_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_Weights_Var_Samp_Fields>;
  variance?: Maybe<Mobile_Weights_Variance_Fields>;
};


/** aggregate fields of "weights" */
export type Mobile_Weights_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_Weights_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Mobile_Weights_Avg_Fields = {
  __typename?: 'mobile_weights_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "weights". All fields are combined with a logical 'AND'. */
export type Mobile_Weights_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_Weights_Bool_Exp>>;
  _not?: InputMaybe<Mobile_Weights_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_Weights_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "weights" */
export enum Mobile_Weights_Constraint {
  /** unique or primary key constraint on columns "id" */
  WeightsPkey = 'weights_pkey'
}

/** input type for incrementing numeric columns in table "weights" */
export type Mobile_Weights_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "weights" */
export type Mobile_Weights_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Mobile_Weights_Max_Fields = {
  __typename?: 'mobile_weights_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type Mobile_Weights_Min_Fields = {
  __typename?: 'mobile_weights_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** response of any mutation on the table "weights" */
export type Mobile_Weights_Mutation_Response = {
  __typename?: 'mobile_weights_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Weights>;
};

/** on_conflict condition type for table "weights" */
export type Mobile_Weights_On_Conflict = {
  constraint: Mobile_Weights_Constraint;
  update_columns?: Array<Mobile_Weights_Update_Column>;
  where?: InputMaybe<Mobile_Weights_Bool_Exp>;
};

/** Ordering options when selecting data from "weights". */
export type Mobile_Weights_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: weights */
export type Mobile_Weights_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "weights" */
export enum Mobile_Weights_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "weights" */
export type Mobile_Weights_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Mobile_Weights_Stddev_Fields = {
  __typename?: 'mobile_weights_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Mobile_Weights_Stddev_Pop_Fields = {
  __typename?: 'mobile_weights_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Mobile_Weights_Stddev_Samp_Fields = {
  __typename?: 'mobile_weights_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "mobile_weights" */
export type Mobile_Weights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Weights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_Weights_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
  value?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type Mobile_Weights_Sum_Fields = {
  __typename?: 'mobile_weights_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
  value?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "weights" */
export enum Mobile_Weights_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Mobile_Weights_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mobile_Weights_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mobile_Weights_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mobile_Weights_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mobile_Weights_Var_Pop_Fields = {
  __typename?: 'mobile_weights_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Mobile_Weights_Var_Samp_Fields = {
  __typename?: 'mobile_weights_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Mobile_Weights_Variance_Fields = {
  __typename?: 'mobile_weights_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type MobiledMedconciergeTimerButtonOutput = {
  __typename?: 'mobiledMedconciergeTimerButtonOutput';
  chat_id: Scalars['Int']['output'];
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  authChangeEmail?: Maybe<AuthChangeEmailOutput>;
  authChangePassword?: Maybe<AuthChangePasswordOutput>;
  authChangePhone?: Maybe<AuthChangePhoneOutput>;
  authCheckOtpCodeEmail?: Maybe<AuthCheckOtpCodeEmailOutput>;
  authCheckOtpCodePhone?: Maybe<AuthCheckOtpCodePhoneOutput>;
  authFindOrCreateMedconciergeBot?: Maybe<AuthFindOrCreateMedconciergeBotOutput>;
  authHardDeleteUser?: Maybe<AuthHardDeleteUserOutput>;
  authSendAccountDeletionCode?: Maybe<AuthSendAccountDeletionCodeOutput>;
  authSendOtpCodeCheckEmail?: Maybe<AuthSendOtpCodeCheckEmailOutput>;
  authSendOtpCodeCheckPhone?: Maybe<AuthSendOtpCodeCheckPhoneOutput>;
  authSendingMailConfirmNewEmail?: Maybe<AuthSendingMailConfirmNewEmailOutput>;
  authSendingSmsConfirmNewPhone?: Maybe<AuthSendingSmsConfirmNewPhoneOutput>;
  authSoftDeleteUser?: Maybe<AuthSoftDeleteUserOutput>;
  billingDmsPaytureGetStatus?: Maybe<BillingDmsPaytureGetStatusOutput>;
  billingOrderPoliceCreate?: Maybe<BillingOrderPoliceCreateOutput>;
  create_direct_upload?: Maybe<CreateDirectUploadOutput>;
  custom_update_users_by_pk?: Maybe<UpdateUserOutput>;
  custom_update_users_by_pk_test?: Maybe<UpdateUserOutput>;
  /** delete data from the table: "oauth_access_tokens" */
  delete_auth_oauth_access_tokens?: Maybe<Auth_Oauth_Access_Tokens_Mutation_Response>;
  /** delete single row from the table: "oauth_access_tokens" */
  delete_auth_oauth_access_tokens_by_pk?: Maybe<Auth_Oauth_Access_Tokens>;
  /** delete data from the table: "test_users" */
  delete_auth_test_users?: Maybe<Auth_Test_Users_Mutation_Response>;
  /** delete single row from the table: "test_users" */
  delete_auth_test_users_by_pk?: Maybe<Auth_Test_Users>;
  /** delete data from the table: "users" */
  delete_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_auth_users_by_pk?: Maybe<Auth_Users>;
  /** delete data from the table: "verification_emails" */
  delete_auth_verification_emails?: Maybe<Auth_Verification_Emails_Mutation_Response>;
  /** delete single row from the table: "verification_emails" */
  delete_auth_verification_emails_by_pk?: Maybe<Auth_Verification_Emails>;
  /** delete data from the table: "verification_phones" */
  delete_auth_verification_phones?: Maybe<Auth_Verification_Phones_Mutation_Response>;
  /** delete single row from the table: "verification_phones" */
  delete_auth_verification_phones_by_pk?: Maybe<Auth_Verification_Phones>;
  /** delete data from the table: "order_polices" */
  delete_billing_order_polices?: Maybe<Billing_Order_Polices_Mutation_Response>;
  /** delete single row from the table: "order_polices" */
  delete_billing_order_polices_by_pk?: Maybe<Billing_Order_Polices>;
  /** delete data from the table: "billing_order_statuses" */
  delete_billing_order_statuses?: Maybe<Billing_Order_Statuses_Mutation_Response>;
  /** delete single row from the table: "billing_order_statuses" */
  delete_billing_order_statuses_by_pk?: Maybe<Billing_Order_Statuses>;
  /** delete data from the table: "promo_codes" */
  delete_billing_promo_codes?: Maybe<Billing_Promo_Codes_Mutation_Response>;
  /** delete single row from the table: "promo_codes" */
  delete_billing_promo_codes_by_pk?: Maybe<Billing_Promo_Codes>;
  /** delete data from the table: "cities" */
  delete_dms_cities?: Maybe<Dms_Cities_Mutation_Response>;
  /** delete single row from the table: "cities" */
  delete_dms_cities_by_pk?: Maybe<Dms_Cities>;
  /** delete data from the table: "group_insureds" */
  delete_dms_group_insureds?: Maybe<Dms_Group_Insureds_Mutation_Response>;
  /** delete single row from the table: "group_insureds" */
  delete_dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** delete data from the table: "groups" */
  delete_dms_groups?: Maybe<Dms_Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** delete data from the table: "insurants" */
  delete_dms_insurants?: Maybe<Dms_Insurants_Mutation_Response>;
  /** delete single row from the table: "insurants" */
  delete_dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** delete data from the table: "insureds" */
  delete_dms_insureds?: Maybe<Dms_Insureds_Mutation_Response>;
  /** delete single row from the table: "insureds" */
  delete_dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** delete data from the table: "order_links" */
  delete_dms_order_links?: Maybe<Dms_Order_Links_Mutation_Response>;
  /** delete single row from the table: "order_links" */
  delete_dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** delete data from the table: "order_numbers" */
  delete_dms_order_numbers?: Maybe<Dms_Order_Numbers_Mutation_Response>;
  /** delete single row from the table: "order_numbers" */
  delete_dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** delete data from the table: "orders" */
  delete_dms_orders?: Maybe<Dms_Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** delete data from the table: "products" */
  delete_dms_products?: Maybe<Dms_Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_dms_products_by_pk?: Maybe<Dms_Products>;
  /** delete data from the table: "promo_codes" */
  delete_dms_promo_codes?: Maybe<Dms_Promo_Codes_Mutation_Response>;
  /** delete single row from the table: "promo_codes" */
  delete_dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** delete data from the table: "statuses" */
  delete_dms_statuses?: Maybe<Dms_Statuses_Mutation_Response>;
  /** delete single row from the table: "statuses" */
  delete_dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** delete data from the table: "users" */
  delete_dms_users?: Maybe<Dms_Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_dms_users_by_pk?: Maybe<Dms_Users>;
  /** delete data from the table: "cards" */
  delete_fintech_cards?: Maybe<Fintech_Cards_Mutation_Response>;
  /** delete single row from the table: "cards" */
  delete_fintech_cards_by_pk?: Maybe<Fintech_Cards>;
  /** delete data from the table: "clients" */
  delete_fintech_clients?: Maybe<Fintech_Clients_Mutation_Response>;
  /** delete single row from the table: "clients" */
  delete_fintech_clients_by_pk?: Maybe<Fintech_Clients>;
  /** delete data from the table: "orders" */
  delete_fintech_orders?: Maybe<Fintech_Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_fintech_orders_by_pk?: Maybe<Fintech_Orders>;
  /** delete data from the table: "amocrm_contacts" */
  delete_mobile_amocrm_contacts?: Maybe<Mobile_Amocrm_Contacts_Mutation_Response>;
  /** delete single row from the table: "amocrm_contacts" */
  delete_mobile_amocrm_contacts_by_pk?: Maybe<Mobile_Amocrm_Contacts>;
  /** delete data from the table: "amocrm_leads" */
  delete_mobile_amocrm_leads?: Maybe<Mobile_Amocrm_Leads_Mutation_Response>;
  /** delete single row from the table: "amocrm_leads" */
  delete_mobile_amocrm_leads_by_pk?: Maybe<Mobile_Amocrm_Leads>;
  /** delete data from the table: "amocrm_tags" */
  delete_mobile_amocrm_tags?: Maybe<Mobile_Amocrm_Tags_Mutation_Response>;
  /** delete single row from the table: "amocrm_tags" */
  delete_mobile_amocrm_tags_by_pk?: Maybe<Mobile_Amocrm_Tags>;
  /** delete data from the table: "appointments" */
  delete_mobile_appointments?: Maybe<Mobile_Appointments_Mutation_Response>;
  /** delete single row from the table: "appointments" */
  delete_mobile_appointments_by_pk?: Maybe<Mobile_Appointments>;
  /** delete data from the table: "auto_replies" */
  delete_mobile_auto_replies?: Maybe<Mobile_Auto_Replies_Mutation_Response>;
  /** delete single row from the table: "auto_replies" */
  delete_mobile_auto_replies_by_pk?: Maybe<Mobile_Auto_Replies>;
  /** delete data from the table: "auto_reply_documents" */
  delete_mobile_auto_reply_documents?: Maybe<Mobile_Auto_Reply_Documents_Mutation_Response>;
  /** delete single row from the table: "auto_reply_documents" */
  delete_mobile_auto_reply_documents_by_pk?: Maybe<Mobile_Auto_Reply_Documents>;
  /** delete data from the table: "auto_reply_mediafiles" */
  delete_mobile_auto_reply_mediafiles?: Maybe<Mobile_Auto_Reply_Mediafiles_Mutation_Response>;
  /** delete single row from the table: "auto_reply_mediafiles" */
  delete_mobile_auto_reply_mediafiles_by_pk?: Maybe<Mobile_Auto_Reply_Mediafiles>;
  /** delete data from the table: "bank_accounts" */
  delete_mobile_bank_accounts?: Maybe<Mobile_Bank_Accounts_Mutation_Response>;
  /** delete single row from the table: "bank_accounts" */
  delete_mobile_bank_accounts_by_pk?: Maybe<Mobile_Bank_Accounts>;
  /** delete data from the table: "blocked_sites" */
  delete_mobile_blocked_sites?: Maybe<Mobile_Blocked_Sites_Mutation_Response>;
  /** delete single row from the table: "blocked_sites" */
  delete_mobile_blocked_sites_by_pk?: Maybe<Mobile_Blocked_Sites>;
  /** delete data from the table: "blockeds" */
  delete_mobile_blockeds?: Maybe<Mobile_Blockeds_Mutation_Response>;
  /** delete single row from the table: "blockeds" */
  delete_mobile_blockeds_by_pk?: Maybe<Mobile_Blockeds>;
  /** delete data from the table: "calorie_norms" */
  delete_mobile_calorie_norms?: Maybe<Mobile_Calorie_Norms_Mutation_Response>;
  /** delete single row from the table: "calorie_norms" */
  delete_mobile_calorie_norms_by_pk?: Maybe<Mobile_Calorie_Norms>;
  /** delete data from the table: "calorie_nows" */
  delete_mobile_calorie_nows?: Maybe<Mobile_Calorie_Nows_Mutation_Response>;
  /** delete single row from the table: "calorie_nows" */
  delete_mobile_calorie_nows_by_pk?: Maybe<Mobile_Calorie_Nows>;
  /** delete data from the table: "calories" */
  delete_mobile_calories?: Maybe<Mobile_Calories_Mutation_Response>;
  /** delete single row from the table: "calories" */
  delete_mobile_calories_by_pk?: Maybe<Mobile_Calories>;
  /** delete data from the table: "categories" */
  delete_mobile_categories?: Maybe<Mobile_Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_mobile_categories_by_pk?: Maybe<Mobile_Categories>;
  /** delete data from the table: "category_names" */
  delete_mobile_category_names?: Maybe<Mobile_Category_Names_Mutation_Response>;
  /** delete single row from the table: "category_names" */
  delete_mobile_category_names_by_pk?: Maybe<Mobile_Category_Names>;
  /** delete data from the table: "chat_black_lists" */
  delete_mobile_chat_black_lists?: Maybe<Mobile_Chat_Black_Lists_Mutation_Response>;
  /** delete single row from the table: "chat_black_lists" */
  delete_mobile_chat_black_lists_by_pk?: Maybe<Mobile_Chat_Black_Lists>;
  /** delete data from the table: "chat_defaults" */
  delete_mobile_chat_defaults?: Maybe<Mobile_Chat_Defaults_Mutation_Response>;
  /** delete single row from the table: "chat_defaults" */
  delete_mobile_chat_defaults_by_pk?: Maybe<Mobile_Chat_Defaults>;
  /** delete data from the table: "chat_file_types" */
  delete_mobile_chat_file_types?: Maybe<Mobile_Chat_File_Types_Mutation_Response>;
  /** delete single row from the table: "chat_file_types" */
  delete_mobile_chat_file_types_by_pk?: Maybe<Mobile_Chat_File_Types>;
  /** delete data from the table: "chat_message_comments" */
  delete_mobile_chat_message_comments?: Maybe<Mobile_Chat_Message_Comments_Mutation_Response>;
  /** delete single row from the table: "chat_message_comments" */
  delete_mobile_chat_message_comments_by_pk?: Maybe<Mobile_Chat_Message_Comments>;
  /** delete data from the table: "chat_message_files" */
  delete_mobile_chat_message_files?: Maybe<Mobile_Chat_Message_Files_Mutation_Response>;
  /** delete single row from the table: "chat_message_files" */
  delete_mobile_chat_message_files_by_pk?: Maybe<Mobile_Chat_Message_Files>;
  /** delete data from the table: "chat_message_notifications" */
  delete_mobile_chat_message_notifications?: Maybe<Mobile_Chat_Message_Notifications_Mutation_Response>;
  /** delete data from the table: "chat_message_types" */
  delete_mobile_chat_message_types?: Maybe<Mobile_Chat_Message_Types_Mutation_Response>;
  /** delete single row from the table: "chat_message_types" */
  delete_mobile_chat_message_types_by_pk?: Maybe<Mobile_Chat_Message_Types>;
  /** delete data from the table: "chat_messages" */
  delete_mobile_chat_messages?: Maybe<Mobile_Chat_Messages_Mutation_Response>;
  /** delete single row from the table: "chat_messages" */
  delete_mobile_chat_messages_by_pk?: Maybe<Mobile_Chat_Messages>;
  /** delete data from the table: "chat_roles" */
  delete_mobile_chat_roles?: Maybe<Mobile_Chat_Roles_Mutation_Response>;
  /** delete single row from the table: "chat_roles" */
  delete_mobile_chat_roles_by_pk?: Maybe<Mobile_Chat_Roles>;
  /** delete data from the table: "chat_types" */
  delete_mobile_chat_types?: Maybe<Mobile_Chat_Types_Mutation_Response>;
  /** delete single row from the table: "chat_types" */
  delete_mobile_chat_types_by_pk?: Maybe<Mobile_Chat_Types>;
  /** delete data from the table: "chats" */
  delete_mobile_chats?: Maybe<Mobile_Chats_Mutation_Response>;
  /** delete single row from the table: "chats" */
  delete_mobile_chats_by_pk?: Maybe<Mobile_Chats>;
  /** delete data from the table: "clients" */
  delete_mobile_clients?: Maybe<Mobile_Clients_Mutation_Response>;
  /** delete single row from the table: "clients" */
  delete_mobile_clients_by_pk?: Maybe<Mobile_Clients>;
  /** delete data from the table: "comment_like_notifications" */
  delete_mobile_comment_like_notifications?: Maybe<Mobile_Comment_Like_Notifications_Mutation_Response>;
  /** delete data from the table: "comment_likes" */
  delete_mobile_comment_likes?: Maybe<Mobile_Comment_Likes_Mutation_Response>;
  /** delete single row from the table: "comment_likes" */
  delete_mobile_comment_likes_by_pk?: Maybe<Mobile_Comment_Likes>;
  /** delete data from the table: "comment_notifications" */
  delete_mobile_comment_notifications?: Maybe<Mobile_Comment_Notifications_Mutation_Response>;
  /** delete data from the table: "comments" */
  delete_mobile_comments?: Maybe<Mobile_Comments_Mutation_Response>;
  /** delete single row from the table: "comments" */
  delete_mobile_comments_by_pk?: Maybe<Mobile_Comments>;
  /** delete data from the table: "confirmation_codes" */
  delete_mobile_confirmation_codes?: Maybe<Mobile_Confirmation_Codes_Mutation_Response>;
  /** delete single row from the table: "confirmation_codes" */
  delete_mobile_confirmation_codes_by_pk?: Maybe<Mobile_Confirmation_Codes>;
  /** delete data from the table: "contacts" */
  delete_mobile_contacts?: Maybe<Mobile_Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_mobile_contacts_by_pk?: Maybe<Mobile_Contacts>;
  /** delete data from the table: "contents" */
  delete_mobile_contents?: Maybe<Mobile_Contents_Mutation_Response>;
  /** delete single row from the table: "contents" */
  delete_mobile_contents_by_pk?: Maybe<Mobile_Contents>;
  /** delete data from the table: "device_infos" */
  delete_mobile_device_infos?: Maybe<Mobile_Device_Infos_Mutation_Response>;
  /** delete single row from the table: "device_infos" */
  delete_mobile_device_infos_by_pk?: Maybe<Mobile_Device_Infos>;
  /** delete data from the table: "device_settings" */
  delete_mobile_device_settings?: Maybe<Mobile_Device_Settings_Mutation_Response>;
  /** delete single row from the table: "device_settings" */
  delete_mobile_device_settings_by_pk?: Maybe<Mobile_Device_Settings>;
  /** delete data from the table: "devices" */
  delete_mobile_devices?: Maybe<Mobile_Devices_Mutation_Response>;
  /** delete single row from the table: "devices" */
  delete_mobile_devices_by_pk?: Maybe<Mobile_Devices>;
  /** delete data from the table: "document_mediafiles" */
  delete_mobile_document_mediafiles?: Maybe<Mobile_Document_Mediafiles_Mutation_Response>;
  /** delete single row from the table: "document_mediafiles" */
  delete_mobile_document_mediafiles_by_pk?: Maybe<Mobile_Document_Mediafiles>;
  /** delete data from the table: "documents" */
  delete_mobile_documents?: Maybe<Mobile_Documents_Mutation_Response>;
  /** delete single row from the table: "documents" */
  delete_mobile_documents_by_pk?: Maybe<Mobile_Documents>;
  /** delete data from the table: "education_mediafiles" */
  delete_mobile_education_mediafiles?: Maybe<Mobile_Education_Mediafiles_Mutation_Response>;
  /** delete single row from the table: "education_mediafiles" */
  delete_mobile_education_mediafiles_by_pk?: Maybe<Mobile_Education_Mediafiles>;
  /** delete data from the table: "educations" */
  delete_mobile_educations?: Maybe<Mobile_Educations_Mutation_Response>;
  /** delete single row from the table: "educations" */
  delete_mobile_educations_by_pk?: Maybe<Mobile_Educations>;
  /** delete data from the table: "emojis" */
  delete_mobile_emojis?: Maybe<Mobile_Emojis_Mutation_Response>;
  /** delete single row from the table: "emojis" */
  delete_mobile_emojis_by_pk?: Maybe<Mobile_Emojis>;
  /** delete data from the table: "fixed_chat_messages" */
  delete_mobile_fixed_chat_messages?: Maybe<Mobile_Fixed_Chat_Messages_Mutation_Response>;
  /** delete single row from the table: "fixed_chat_messages" */
  delete_mobile_fixed_chat_messages_by_pk?: Maybe<Mobile_Fixed_Chat_Messages>;
  /** delete data from the table: "fixed_comments" */
  delete_mobile_fixed_comments?: Maybe<Mobile_Fixed_Comments_Mutation_Response>;
  /** delete single row from the table: "fixed_comments" */
  delete_mobile_fixed_comments_by_pk?: Maybe<Mobile_Fixed_Comments>;
  /** delete data from the table: "founder_mediafiles" */
  delete_mobile_founder_mediafiles?: Maybe<Mobile_Founder_Mediafiles_Mutation_Response>;
  /** delete single row from the table: "founder_mediafiles" */
  delete_mobile_founder_mediafiles_by_pk?: Maybe<Mobile_Founder_Mediafiles>;
  /** delete data from the table: "founders" */
  delete_mobile_founders?: Maybe<Mobile_Founders_Mutation_Response>;
  /** delete single row from the table: "founders" */
  delete_mobile_founders_by_pk?: Maybe<Mobile_Founders>;
  /** delete data from the table: "invite_emails" */
  delete_mobile_invite_emails?: Maybe<Mobile_Invite_Emails_Mutation_Response>;
  /** delete single row from the table: "invite_emails" */
  delete_mobile_invite_emails_by_pk?: Maybe<Mobile_Invite_Emails>;
  /** delete data from the table: "like_notifications" */
  delete_mobile_like_notifications?: Maybe<Mobile_Like_Notifications_Mutation_Response>;
  /** delete data from the table: "likes" */
  delete_mobile_likes?: Maybe<Mobile_Likes_Mutation_Response>;
  /** delete single row from the table: "likes" */
  delete_mobile_likes_by_pk?: Maybe<Mobile_Likes>;
  /** delete data from the table: "link_snippets" */
  delete_mobile_link_snippets?: Maybe<Mobile_Link_Snippets_Mutation_Response>;
  /** delete single row from the table: "link_snippets" */
  delete_mobile_link_snippets_by_pk?: Maybe<Mobile_Link_Snippets>;
  /** delete data from the table: "locked_deleted_chat_messages" */
  delete_mobile_locked_deleted_chat_messages?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Mutation_Response>;
  /** delete data from the table: "mediafile_status_names" */
  delete_mobile_mediafile_status_names?: Maybe<Mobile_Mediafile_Status_Names_Mutation_Response>;
  /** delete single row from the table: "mediafile_status_names" */
  delete_mobile_mediafile_status_names_by_pk?: Maybe<Mobile_Mediafile_Status_Names>;
  /** delete data from the table: "mediafile_statuses" */
  delete_mobile_mediafile_statuses?: Maybe<Mobile_Mediafile_Statuses_Mutation_Response>;
  /** delete single row from the table: "mediafile_statuses" */
  delete_mobile_mediafile_statuses_by_pk?: Maybe<Mobile_Mediafile_Statuses>;
  /** delete data from the table: "medical_concierge_orders" */
  delete_mobile_medical_concierge_orders?: Maybe<Mobile_Medical_Concierge_Orders_Mutation_Response>;
  /** delete single row from the table: "medical_concierge_orders" */
  delete_mobile_medical_concierge_orders_by_pk?: Maybe<Mobile_Medical_Concierge_Orders>;
  /** delete data from the table: "medical_concierge_products" */
  delete_mobile_medical_concierge_products?: Maybe<Mobile_Medical_Concierge_Products_Mutation_Response>;
  /** delete single row from the table: "medical_concierge_products" */
  delete_mobile_medical_concierge_products_by_pk?: Maybe<Mobile_Medical_Concierge_Products>;
  /** delete data from the table: "mention_notifications" */
  delete_mobile_mention_notifications?: Maybe<Mobile_Mention_Notifications_Mutation_Response>;
  /** delete data from the table: "mentions" */
  delete_mobile_mentions?: Maybe<Mobile_Mentions_Mutation_Response>;
  /** delete single row from the table: "mentions" */
  delete_mobile_mentions_by_pk?: Maybe<Mobile_Mentions>;
  /** delete data from the table: "metric_names" */
  delete_mobile_metric_names?: Maybe<Mobile_Metric_Names_Mutation_Response>;
  /** delete single row from the table: "metric_names" */
  delete_mobile_metric_names_by_pk?: Maybe<Mobile_Metric_Names>;
  /** delete data from the table: "metric_params" */
  delete_mobile_metric_params?: Maybe<Mobile_Metric_Params_Mutation_Response>;
  /** delete single row from the table: "metric_params" */
  delete_mobile_metric_params_by_pk?: Maybe<Mobile_Metric_Params>;
  /** delete data from the table: "metric_types" */
  delete_mobile_metric_types?: Maybe<Mobile_Metric_Types_Mutation_Response>;
  /** delete single row from the table: "metric_types" */
  delete_mobile_metric_types_by_pk?: Maybe<Mobile_Metric_Types>;
  /** delete data from the table: "metrics" */
  delete_mobile_metrics?: Maybe<Mobile_Metrics_Mutation_Response>;
  /** delete single row from the table: "metrics" */
  delete_mobile_metrics_by_pk?: Maybe<Mobile_Metrics>;
  /** delete data from the table: "notification_messages" */
  delete_mobile_notification_messages?: Maybe<Mobile_Notification_Messages_Mutation_Response>;
  /** delete single row from the table: "notification_messages" */
  delete_mobile_notification_messages_by_pk?: Maybe<Mobile_Notification_Messages>;
  /** delete data from the table: "notifications" */
  delete_mobile_notifications?: Maybe<Mobile_Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_mobile_notifications_by_pk?: Maybe<Mobile_Notifications>;
  /** delete data from the table: "notifications_statuses" */
  delete_mobile_notifications_statuses?: Maybe<Mobile_Notifications_Statuses_Mutation_Response>;
  /** delete single row from the table: "notifications_statuses" */
  delete_mobile_notifications_statuses_by_pk?: Maybe<Mobile_Notifications_Statuses>;
  /** delete data from the table: "order_notifications" */
  delete_mobile_order_notifications?: Maybe<Mobile_Order_Notifications_Mutation_Response>;
  /** delete data from the table: "order_professionals" */
  delete_mobile_order_professionals?: Maybe<Mobile_Order_Professionals_Mutation_Response>;
  /** delete single row from the table: "order_professionals" */
  delete_mobile_order_professionals_by_pk?: Maybe<Mobile_Order_Professionals>;
  /** delete data from the table: "orders" */
  delete_mobile_orders?: Maybe<Mobile_Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_mobile_orders_by_pk?: Maybe<Mobile_Orders>;
  /** delete data from the table: "orgs" */
  delete_mobile_orgs?: Maybe<Mobile_Orgs_Mutation_Response>;
  /** delete single row from the table: "orgs" */
  delete_mobile_orgs_by_pk?: Maybe<Mobile_Orgs>;
  /** delete data from the table: "param_categories" */
  delete_mobile_param_categories?: Maybe<Mobile_Param_Categories_Mutation_Response>;
  /** delete single row from the table: "param_categories" */
  delete_mobile_param_categories_by_pk?: Maybe<Mobile_Param_Categories>;
  /** delete data from the table: "param_categories_params" */
  delete_mobile_param_categories_params?: Maybe<Mobile_Param_Categories_Params_Mutation_Response>;
  /** delete data from the table: "param_category_names" */
  delete_mobile_param_category_names?: Maybe<Mobile_Param_Category_Names_Mutation_Response>;
  /** delete single row from the table: "param_category_names" */
  delete_mobile_param_category_names_by_pk?: Maybe<Mobile_Param_Category_Names>;
  /** delete data from the table: "param_names" */
  delete_mobile_param_names?: Maybe<Mobile_Param_Names_Mutation_Response>;
  /** delete single row from the table: "param_names" */
  delete_mobile_param_names_by_pk?: Maybe<Mobile_Param_Names>;
  /** delete data from the table: "params" */
  delete_mobile_params?: Maybe<Mobile_Params_Mutation_Response>;
  /** delete single row from the table: "params" */
  delete_mobile_params_by_pk?: Maybe<Mobile_Params>;
  /** delete data from the table: "params_services" */
  delete_mobile_params_services?: Maybe<Mobile_Params_Services_Mutation_Response>;
  /** delete single row from the table: "params_services" */
  delete_mobile_params_services_by_pk?: Maybe<Mobile_Params_Services>;
  /** delete data from the table: "personal_info_views" */
  delete_mobile_personal_info_views?: Maybe<Mobile_Personal_Info_Views_Mutation_Response>;
  /** delete single row from the table: "personal_info_views" */
  delete_mobile_personal_info_views_by_pk?: Maybe<Mobile_Personal_Info_Views>;
  /** delete data from the table: "personal_infos" */
  delete_mobile_personal_infos?: Maybe<Mobile_Personal_Infos_Mutation_Response>;
  /** delete single row from the table: "personal_infos" */
  delete_mobile_personal_infos_by_pk?: Maybe<Mobile_Personal_Infos>;
  /** delete data from the table: "phones" */
  delete_mobile_phones?: Maybe<Mobile_Phones_Mutation_Response>;
  /** delete single row from the table: "phones" */
  delete_mobile_phones_by_pk?: Maybe<Mobile_Phones>;
  /** delete data from the table: "post_params" */
  delete_mobile_post_params?: Maybe<Mobile_Post_Params_Mutation_Response>;
  /** delete single row from the table: "post_params" */
  delete_mobile_post_params_by_pk?: Maybe<Mobile_Post_Params>;
  /** delete data from the table: "posts" */
  delete_mobile_posts?: Maybe<Mobile_Posts_Mutation_Response>;
  /** delete single row from the table: "posts" */
  delete_mobile_posts_by_pk?: Maybe<Mobile_Posts>;
  /** delete data from the table: "pro_document_statuses" */
  delete_mobile_pro_document_statuses?: Maybe<Mobile_Pro_Document_Statuses_Mutation_Response>;
  /** delete single row from the table: "pro_document_statuses" */
  delete_mobile_pro_document_statuses_by_pk?: Maybe<Mobile_Pro_Document_Statuses>;
  /** delete data from the table: "pro_professions" */
  delete_mobile_pro_professions?: Maybe<Mobile_Pro_Professions_Mutation_Response>;
  /** delete single row from the table: "pro_professions" */
  delete_mobile_pro_professions_by_pk?: Maybe<Mobile_Pro_Professions>;
  /** delete data from the table: "pro_types" */
  delete_mobile_pro_types?: Maybe<Mobile_Pro_Types_Mutation_Response>;
  /** delete single row from the table: "pro_types" */
  delete_mobile_pro_types_by_pk?: Maybe<Mobile_Pro_Types>;
  /** delete data from the table: "problems" */
  delete_mobile_problems?: Maybe<Mobile_Problems_Mutation_Response>;
  /** delete single row from the table: "problems" */
  delete_mobile_problems_by_pk?: Maybe<Mobile_Problems>;
  /** delete data from the table: "profession_names" */
  delete_mobile_profession_names?: Maybe<Mobile_Profession_Names_Mutation_Response>;
  /** delete single row from the table: "profession_names" */
  delete_mobile_profession_names_by_pk?: Maybe<Mobile_Profession_Names>;
  /** delete data from the table: "professions" */
  delete_mobile_professions?: Maybe<Mobile_Professions_Mutation_Response>;
  /** delete single row from the table: "professions" */
  delete_mobile_professions_by_pk?: Maybe<Mobile_Professions>;
  /** delete data from the table: "profile_deleteds" */
  delete_mobile_profile_deleteds?: Maybe<Mobile_Profile_Deleteds_Mutation_Response>;
  /** delete single row from the table: "profile_deleteds" */
  delete_mobile_profile_deleteds_by_pk?: Maybe<Mobile_Profile_Deleteds>;
  /** delete data from the table: "profile_locks" */
  delete_mobile_profile_locks?: Maybe<Mobile_Profile_Locks_Mutation_Response>;
  /** delete single row from the table: "profile_locks" */
  delete_mobile_profile_locks_by_pk?: Maybe<Mobile_Profile_Locks>;
  /** delete data from the table: "profiles" */
  delete_mobile_profiles?: Maybe<Mobile_Profiles_Mutation_Response>;
  /** delete single row from the table: "profiles" */
  delete_mobile_profiles_by_pk?: Maybe<Mobile_Profiles>;
  /** delete data from the table: "pros" */
  delete_mobile_pros?: Maybe<Mobile_Pros_Mutation_Response>;
  /** delete single row from the table: "pros" */
  delete_mobile_pros_by_pk?: Maybe<Mobile_Pros>;
  /** delete data from the table: "pulse_norms" */
  delete_mobile_pulse_norms?: Maybe<Mobile_Pulse_Norms_Mutation_Response>;
  /** delete single row from the table: "pulse_norms" */
  delete_mobile_pulse_norms_by_pk?: Maybe<Mobile_Pulse_Norms>;
  /** delete data from the table: "pulse_nows" */
  delete_mobile_pulse_nows?: Maybe<Mobile_Pulse_Nows_Mutation_Response>;
  /** delete single row from the table: "pulse_nows" */
  delete_mobile_pulse_nows_by_pk?: Maybe<Mobile_Pulse_Nows>;
  /** delete data from the table: "pulses" */
  delete_mobile_pulses?: Maybe<Mobile_Pulses_Mutation_Response>;
  /** delete single row from the table: "pulses" */
  delete_mobile_pulses_by_pk?: Maybe<Mobile_Pulses>;
  /** delete data from the table: "reactions" */
  delete_mobile_reactions?: Maybe<Mobile_Reactions_Mutation_Response>;
  /** delete single row from the table: "reactions" */
  delete_mobile_reactions_by_pk?: Maybe<Mobile_Reactions>;
  /** delete data from the table: "reports" */
  delete_mobile_reports?: Maybe<Mobile_Reports_Mutation_Response>;
  /** delete single row from the table: "reports" */
  delete_mobile_reports_by_pk?: Maybe<Mobile_Reports>;
  /** delete data from the table: "service_agreements" */
  delete_mobile_service_agreements?: Maybe<Mobile_Service_Agreements_Mutation_Response>;
  /** delete single row from the table: "service_agreements" */
  delete_mobile_service_agreements_by_pk?: Maybe<Mobile_Service_Agreements>;
  /** delete data from the table: "service_answer_reactions" */
  delete_mobile_service_answer_reactions?: Maybe<Mobile_Service_Answer_Reactions_Mutation_Response>;
  /** delete single row from the table: "service_answer_reactions" */
  delete_mobile_service_answer_reactions_by_pk?: Maybe<Mobile_Service_Answer_Reactions>;
  /** delete data from the table: "service_answers" */
  delete_mobile_service_answers?: Maybe<Mobile_Service_Answers_Mutation_Response>;
  /** delete single row from the table: "service_answers" */
  delete_mobile_service_answers_by_pk?: Maybe<Mobile_Service_Answers>;
  /** delete data from the table: "service_documents" */
  delete_mobile_service_documents?: Maybe<Mobile_Service_Documents_Mutation_Response>;
  /** delete single row from the table: "service_documents" */
  delete_mobile_service_documents_by_pk?: Maybe<Mobile_Service_Documents>;
  /** delete data from the table: "service_favorites" */
  delete_mobile_service_favorites?: Maybe<Mobile_Service_Favorites_Mutation_Response>;
  /** delete single row from the table: "service_favorites" */
  delete_mobile_service_favorites_by_pk?: Maybe<Mobile_Service_Favorites>;
  /** delete data from the table: "service_free_purchases" */
  delete_mobile_service_free_purchases?: Maybe<Mobile_Service_Free_Purchases_Mutation_Response>;
  /** delete single row from the table: "service_free_purchases" */
  delete_mobile_service_free_purchases_by_pk?: Maybe<Mobile_Service_Free_Purchases>;
  /** delete data from the table: "service_images" */
  delete_mobile_service_images?: Maybe<Mobile_Service_Images_Mutation_Response>;
  /** delete single row from the table: "service_images" */
  delete_mobile_service_images_by_pk?: Maybe<Mobile_Service_Images>;
  /** delete data from the table: "service_likes" */
  delete_mobile_service_likes?: Maybe<Mobile_Service_Likes_Mutation_Response>;
  /** delete single row from the table: "service_likes" */
  delete_mobile_service_likes_by_pk?: Maybe<Mobile_Service_Likes>;
  /** delete data from the table: "service_list_documents" */
  delete_mobile_service_list_documents?: Maybe<Mobile_Service_List_Documents_Mutation_Response>;
  /** delete single row from the table: "service_list_documents" */
  delete_mobile_service_list_documents_by_pk?: Maybe<Mobile_Service_List_Documents>;
  /** delete data from the table: "service_list_periodicities" */
  delete_mobile_service_list_periodicities?: Maybe<Mobile_Service_List_Periodicities_Mutation_Response>;
  /** delete single row from the table: "service_list_periodicities" */
  delete_mobile_service_list_periodicities_by_pk?: Maybe<Mobile_Service_List_Periodicities>;
  /** delete data from the table: "service_lists" */
  delete_mobile_service_lists?: Maybe<Mobile_Service_Lists_Mutation_Response>;
  /** delete single row from the table: "service_lists" */
  delete_mobile_service_lists_by_pk?: Maybe<Mobile_Service_Lists>;
  /** delete data from the table: "service_orders" */
  delete_mobile_service_orders?: Maybe<Mobile_Service_Orders_Mutation_Response>;
  /** delete single row from the table: "service_orders" */
  delete_mobile_service_orders_by_pk?: Maybe<Mobile_Service_Orders>;
  /** delete data from the table: "service_periodicities" */
  delete_mobile_service_periodicities?: Maybe<Mobile_Service_Periodicities_Mutation_Response>;
  /** delete single row from the table: "service_periodicities" */
  delete_mobile_service_periodicities_by_pk?: Maybe<Mobile_Service_Periodicities>;
  /** delete data from the table: "service_purchases" */
  delete_mobile_service_purchases?: Maybe<Mobile_Service_Purchases_Mutation_Response>;
  /** delete single row from the table: "service_purchases" */
  delete_mobile_service_purchases_by_pk?: Maybe<Mobile_Service_Purchases>;
  /** delete data from the table: "service_questions" */
  delete_mobile_service_questions?: Maybe<Mobile_Service_Questions_Mutation_Response>;
  /** delete single row from the table: "service_questions" */
  delete_mobile_service_questions_by_pk?: Maybe<Mobile_Service_Questions>;
  /** delete data from the table: "service_rate_comments" */
  delete_mobile_service_rate_comments?: Maybe<Mobile_Service_Rate_Comments_Mutation_Response>;
  /** delete single row from the table: "service_rate_comments" */
  delete_mobile_service_rate_comments_by_pk?: Maybe<Mobile_Service_Rate_Comments>;
  /** delete data from the table: "service_rate_images" */
  delete_mobile_service_rate_images?: Maybe<Mobile_Service_Rate_Images_Mutation_Response>;
  /** delete single row from the table: "service_rate_images" */
  delete_mobile_service_rate_images_by_pk?: Maybe<Mobile_Service_Rate_Images>;
  /** delete data from the table: "service_rate_reactions" */
  delete_mobile_service_rate_reactions?: Maybe<Mobile_Service_Rate_Reactions_Mutation_Response>;
  /** delete single row from the table: "service_rate_reactions" */
  delete_mobile_service_rate_reactions_by_pk?: Maybe<Mobile_Service_Rate_Reactions>;
  /** delete data from the table: "service_rates" */
  delete_mobile_service_rates?: Maybe<Mobile_Service_Rates_Mutation_Response>;
  /** delete single row from the table: "service_rates" */
  delete_mobile_service_rates_by_pk?: Maybe<Mobile_Service_Rates>;
  /** delete data from the table: "service_statuses" */
  delete_mobile_service_statuses?: Maybe<Mobile_Service_Statuses_Mutation_Response>;
  /** delete single row from the table: "service_statuses" */
  delete_mobile_service_statuses_by_pk?: Maybe<Mobile_Service_Statuses>;
  /** delete data from the table: "service_tag_ages" */
  delete_mobile_service_tag_ages?: Maybe<Mobile_Service_Tag_Ages_Mutation_Response>;
  /** delete single row from the table: "service_tag_ages" */
  delete_mobile_service_tag_ages_by_pk?: Maybe<Mobile_Service_Tag_Ages>;
  /** delete data from the table: "service_tag_target_groups" */
  delete_mobile_service_tag_target_groups?: Maybe<Mobile_Service_Tag_Target_Groups_Mutation_Response>;
  /** delete single row from the table: "service_tag_target_groups" */
  delete_mobile_service_tag_target_groups_by_pk?: Maybe<Mobile_Service_Tag_Target_Groups>;
  /** delete data from the table: "service_tag_targets" */
  delete_mobile_service_tag_targets?: Maybe<Mobile_Service_Tag_Targets_Mutation_Response>;
  /** delete single row from the table: "service_tag_targets" */
  delete_mobile_service_tag_targets_by_pk?: Maybe<Mobile_Service_Tag_Targets>;
  /** delete data from the table: "service_tariff_documents" */
  delete_mobile_service_tariff_documents?: Maybe<Mobile_Service_Tariff_Documents_Mutation_Response>;
  /** delete single row from the table: "service_tariff_documents" */
  delete_mobile_service_tariff_documents_by_pk?: Maybe<Mobile_Service_Tariff_Documents>;
  /** delete data from the table: "service_tariffs" */
  delete_mobile_service_tariffs?: Maybe<Mobile_Service_Tariffs_Mutation_Response>;
  /** delete single row from the table: "service_tariffs" */
  delete_mobile_service_tariffs_by_pk?: Maybe<Mobile_Service_Tariffs>;
  /** delete data from the table: "service_type_names" */
  delete_mobile_service_type_names?: Maybe<Mobile_Service_Type_Names_Mutation_Response>;
  /** delete single row from the table: "service_type_names" */
  delete_mobile_service_type_names_by_pk?: Maybe<Mobile_Service_Type_Names>;
  /** delete data from the table: "service_types" */
  delete_mobile_service_types?: Maybe<Mobile_Service_Types_Mutation_Response>;
  /** delete single row from the table: "service_types" */
  delete_mobile_service_types_by_pk?: Maybe<Mobile_Service_Types>;
  /** delete data from the table: "services" */
  delete_mobile_services?: Maybe<Mobile_Services_Mutation_Response>;
  /** delete single row from the table: "services" */
  delete_mobile_services_by_pk?: Maybe<Mobile_Services>;
  /** delete data from the table: "settings" */
  delete_mobile_settings?: Maybe<Mobile_Settings_Mutation_Response>;
  /** delete single row from the table: "settings" */
  delete_mobile_settings_by_pk?: Maybe<Mobile_Settings>;
  /** delete data from the table: "share_posts" */
  delete_mobile_share_posts?: Maybe<Mobile_Share_Posts_Mutation_Response>;
  /** delete single row from the table: "share_posts" */
  delete_mobile_share_posts_by_pk?: Maybe<Mobile_Share_Posts>;
  /** delete data from the table: "sleep_norms" */
  delete_mobile_sleep_norms?: Maybe<Mobile_Sleep_Norms_Mutation_Response>;
  /** delete single row from the table: "sleep_norms" */
  delete_mobile_sleep_norms_by_pk?: Maybe<Mobile_Sleep_Norms>;
  /** delete data from the table: "sleep_nows" */
  delete_mobile_sleep_nows?: Maybe<Mobile_Sleep_Nows_Mutation_Response>;
  /** delete single row from the table: "sleep_nows" */
  delete_mobile_sleep_nows_by_pk?: Maybe<Mobile_Sleep_Nows>;
  /** delete data from the table: "sleeps" */
  delete_mobile_sleeps?: Maybe<Mobile_Sleeps_Mutation_Response>;
  /** delete single row from the table: "sleeps" */
  delete_mobile_sleeps_by_pk?: Maybe<Mobile_Sleeps>;
  /** delete data from the table: "slots" */
  delete_mobile_slots?: Maybe<Mobile_Slots_Mutation_Response>;
  /** delete single row from the table: "slots" */
  delete_mobile_slots_by_pk?: Maybe<Mobile_Slots>;
  /** delete data from the table: "status_messages" */
  delete_mobile_status_messages?: Maybe<Mobile_Status_Messages_Mutation_Response>;
  /** delete single row from the table: "status_messages" */
  delete_mobile_status_messages_by_pk?: Maybe<Mobile_Status_Messages>;
  /** delete data from the table: "step_norms" */
  delete_mobile_step_norms?: Maybe<Mobile_Step_Norms_Mutation_Response>;
  /** delete single row from the table: "step_norms" */
  delete_mobile_step_norms_by_pk?: Maybe<Mobile_Step_Norms>;
  /** delete data from the table: "step_nows" */
  delete_mobile_step_nows?: Maybe<Mobile_Step_Nows_Mutation_Response>;
  /** delete single row from the table: "step_nows" */
  delete_mobile_step_nows_by_pk?: Maybe<Mobile_Step_Nows>;
  /** delete data from the table: "steps" */
  delete_mobile_steps?: Maybe<Mobile_Steps_Mutation_Response>;
  /** delete single row from the table: "steps" */
  delete_mobile_steps_by_pk?: Maybe<Mobile_Steps>;
  /** delete data from the table: "subscribe_notifications" */
  delete_mobile_subscribe_notifications?: Maybe<Mobile_Subscribe_Notifications_Mutation_Response>;
  /** delete data from the table: "subscribes" */
  delete_mobile_subscribes?: Maybe<Mobile_Subscribes_Mutation_Response>;
  /** delete single row from the table: "subscribes" */
  delete_mobile_subscribes_by_pk?: Maybe<Mobile_Subscribes>;
  /** delete data from the table: "support_messages" */
  delete_mobile_support_messages?: Maybe<Mobile_Support_Messages_Mutation_Response>;
  /** delete single row from the table: "support_messages" */
  delete_mobile_support_messages_by_pk?: Maybe<Mobile_Support_Messages>;
  /** delete data from the table: "support_topics" */
  delete_mobile_support_topics?: Maybe<Mobile_Support_Topics_Mutation_Response>;
  /** delete single row from the table: "support_topics" */
  delete_mobile_support_topics_by_pk?: Maybe<Mobile_Support_Topics>;
  /** delete data from the table: "tag_ages" */
  delete_mobile_tag_ages?: Maybe<Mobile_Tag_Ages_Mutation_Response>;
  /** delete single row from the table: "tag_ages" */
  delete_mobile_tag_ages_by_pk?: Maybe<Mobile_Tag_Ages>;
  /** delete data from the table: "tag_target_groups" */
  delete_mobile_tag_target_groups?: Maybe<Mobile_Tag_Target_Groups_Mutation_Response>;
  /** delete single row from the table: "tag_target_groups" */
  delete_mobile_tag_target_groups_by_pk?: Maybe<Mobile_Tag_Target_Groups>;
  /** delete data from the table: "tag_targets" */
  delete_mobile_tag_targets?: Maybe<Mobile_Tag_Targets_Mutation_Response>;
  /** delete single row from the table: "tag_targets" */
  delete_mobile_tag_targets_by_pk?: Maybe<Mobile_Tag_Targets>;
  /** delete data from the table: "type_phones" */
  delete_mobile_type_phones?: Maybe<Mobile_Type_Phones_Mutation_Response>;
  /** delete single row from the table: "type_phones" */
  delete_mobile_type_phones_by_pk?: Maybe<Mobile_Type_Phones>;
  /** delete data from the table: "type_profiles" */
  delete_mobile_type_profiles?: Maybe<Mobile_Type_Profiles_Mutation_Response>;
  /** delete single row from the table: "type_profiles" */
  delete_mobile_type_profiles_by_pk?: Maybe<Mobile_Type_Profiles>;
  /** delete data from the table: "upload_pro_documents" */
  delete_mobile_upload_pro_documents?: Maybe<Mobile_Upload_Pro_Documents_Mutation_Response>;
  /** delete single row from the table: "upload_pro_documents" */
  delete_mobile_upload_pro_documents_by_pk?: Maybe<Mobile_Upload_Pro_Documents>;
  /** delete data from the table: "user_chat_rights" */
  delete_mobile_user_chat_rights?: Maybe<Mobile_User_Chat_Rights_Mutation_Response>;
  /** delete single row from the table: "user_chat_rights" */
  delete_mobile_user_chat_rights_by_pk?: Maybe<Mobile_User_Chat_Rights>;
  /** delete data from the table: "user_chats" */
  delete_mobile_user_chats?: Maybe<Mobile_User_Chats_Mutation_Response>;
  /** delete single row from the table: "user_chats" */
  delete_mobile_user_chats_by_pk?: Maybe<Mobile_User_Chats>;
  /** delete data from the table: "view_chat_messages" */
  delete_mobile_view_chat_messages?: Maybe<Mobile_View_Chat_Messages_Mutation_Response>;
  /** delete data from the table: "water_norms" */
  delete_mobile_water_norms?: Maybe<Mobile_Water_Norms_Mutation_Response>;
  /** delete single row from the table: "water_norms" */
  delete_mobile_water_norms_by_pk?: Maybe<Mobile_Water_Norms>;
  /** delete data from the table: "water_nows" */
  delete_mobile_water_nows?: Maybe<Mobile_Water_Nows_Mutation_Response>;
  /** delete single row from the table: "water_nows" */
  delete_mobile_water_nows_by_pk?: Maybe<Mobile_Water_Nows>;
  /** delete data from the table: "waters" */
  delete_mobile_waters?: Maybe<Mobile_Waters_Mutation_Response>;
  /** delete single row from the table: "waters" */
  delete_mobile_waters_by_pk?: Maybe<Mobile_Waters>;
  /** delete data from the table: "weight_nows" */
  delete_mobile_weight_nows?: Maybe<Mobile_Weight_Nows_Mutation_Response>;
  /** delete single row from the table: "weight_nows" */
  delete_mobile_weight_nows_by_pk?: Maybe<Mobile_Weight_Nows>;
  /** delete data from the table: "weights" */
  delete_mobile_weights?: Maybe<Mobile_Weights_Mutation_Response>;
  /** delete single row from the table: "weights" */
  delete_mobile_weights_by_pk?: Maybe<Mobile_Weights>;
  /** delete data from the table: "balances" */
  delete_partner_balances?: Maybe<Partner_Balances_Mutation_Response>;
  /** delete single row from the table: "balances" */
  delete_partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** delete data from the table: "log_vzr_sales" */
  delete_partner_log_vzr_sales?: Maybe<Partner_Log_Vzr_Sales_Mutation_Response>;
  /** delete single row from the table: "log_vzr_sales" */
  delete_partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** delete data from the table: "log_vzr_searches" */
  delete_partner_log_vzr_searches?: Maybe<Partner_Log_Vzr_Searches_Mutation_Response>;
  /** delete single row from the table: "log_vzr_searches" */
  delete_partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** delete data from the table: "log_vzr_views" */
  delete_partner_log_vzr_views?: Maybe<Partner_Log_Vzr_Views_Mutation_Response>;
  /** delete single row from the table: "log_vzr_views" */
  delete_partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** delete data from the table: "place_type_integrations" */
  delete_partner_place_type_integrations?: Maybe<Partner_Place_Type_Integrations_Mutation_Response>;
  /** delete single row from the table: "place_type_integrations" */
  delete_partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** delete data from the table: "places" */
  delete_partner_places?: Maybe<Partner_Places_Mutation_Response>;
  /** delete single row from the table: "places" */
  delete_partner_places_by_pk?: Maybe<Partner_Places>;
  /** delete data from the table: "sale_types" */
  delete_partner_sale_types?: Maybe<Partner_Sale_Types_Mutation_Response>;
  /** delete single row from the table: "sale_types" */
  delete_partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** delete data from the table: "status_integrations" */
  delete_partner_status_integrations?: Maybe<Partner_Status_Integrations_Mutation_Response>;
  /** delete single row from the table: "status_integrations" */
  delete_partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** delete data from the table: "status_transactions" */
  delete_partner_status_transactions?: Maybe<Partner_Status_Transactions_Mutation_Response>;
  /** delete single row from the table: "status_transactions" */
  delete_partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** delete data from the table: "themes" */
  delete_partner_themes?: Maybe<Partner_Themes_Mutation_Response>;
  /** delete single row from the table: "themes" */
  delete_partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** delete data from the table: "type_integrations" */
  delete_partner_type_integrations?: Maybe<Partner_Type_Integrations_Mutation_Response>;
  /** delete single row from the table: "type_integrations" */
  delete_partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** delete data from the table: "type_transactions" */
  delete_partner_type_transactions?: Maybe<Partner_Type_Transactions_Mutation_Response>;
  /** delete single row from the table: "type_transactions" */
  delete_partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** delete data from the table: "user_types" */
  delete_partner_user_types?: Maybe<Partner_User_Types_Mutation_Response>;
  /** delete single row from the table: "user_types" */
  delete_partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** delete data from the table: "users" */
  delete_partner_users?: Maybe<Partner_Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_partner_users_by_pk?: Maybe<Partner_Users>;
  /** delete data from the table: "users" */
  delete_service_users?: Maybe<Service_Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_service_users_by_pk?: Maybe<Service_Users>;
  dmsApiDenumCreateForm?: Maybe<DmsApiDenumCreateFormOutput>;
  dmsCreateInsurant?: Maybe<DmsCreateInsurantOutput>;
  dmsCreateOrder?: Maybe<DmsCreateOrderOutput>;
  dmsCreateOrderNumber?: Maybe<DmsCreateOrderNumberOutput>;
  dmsPrintOrder?: Maybe<DmsPrintOrderOutput>;
  fintechCancelOrderJob?: Maybe<FintechCancelOrderOutput>;
  fintechCancelTransfer?: Maybe<FintechCancelOrderOutput>;
  fintechCheckSms?: Maybe<FintechCheckSmsClientInfoOutput>;
  fintechCreateInvoice?: Maybe<FintechOrderOutput>;
  fintechModifyClient?: Maybe<FintechClientInfoOutput>;
  fintechPayInDebit?: Maybe<FintechPaymentUrlOutput>;
  fintechPayInDebitComplete?: Maybe<FintechOperationOutput>;
  fintechPayInvoice?: Maybe<FintechOrderOutput>;
  fintechRegisterClient?: Maybe<FintechClientInfoOutput>;
  fintechResendSms?: Maybe<FintechResendSmsOutput>;
  fintechTransfer?: Maybe<FintechOrderOutput>;
  insertMobilePostsOneAction?: Maybe<InsertMobilePostsOneOutput>;
  insertMobileProfilesOneAction?: Maybe<InsertMobileProfilesOneOutput>;
  /** insert data into the table: "oauth_access_tokens" */
  insert_auth_oauth_access_tokens?: Maybe<Auth_Oauth_Access_Tokens_Mutation_Response>;
  /** insert a single row into the table: "oauth_access_tokens" */
  insert_auth_oauth_access_tokens_one?: Maybe<Auth_Oauth_Access_Tokens>;
  /** insert data into the table: "test_users" */
  insert_auth_test_users?: Maybe<Auth_Test_Users_Mutation_Response>;
  /** insert a single row into the table: "test_users" */
  insert_auth_test_users_one?: Maybe<Auth_Test_Users>;
  /** insert data into the table: "users" */
  insert_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_auth_users_one?: Maybe<Auth_Users>;
  /** insert data into the table: "verification_emails" */
  insert_auth_verification_emails?: Maybe<Auth_Verification_Emails_Mutation_Response>;
  /** insert a single row into the table: "verification_emails" */
  insert_auth_verification_emails_one?: Maybe<Auth_Verification_Emails>;
  /** insert data into the table: "verification_phones" */
  insert_auth_verification_phones?: Maybe<Auth_Verification_Phones_Mutation_Response>;
  /** insert a single row into the table: "verification_phones" */
  insert_auth_verification_phones_one?: Maybe<Auth_Verification_Phones>;
  /** insert data into the table: "order_polices" */
  insert_billing_order_polices?: Maybe<Billing_Order_Polices_Mutation_Response>;
  /** insert a single row into the table: "order_polices" */
  insert_billing_order_polices_one?: Maybe<Billing_Order_Polices>;
  /** insert data into the table: "billing_order_statuses" */
  insert_billing_order_statuses?: Maybe<Billing_Order_Statuses_Mutation_Response>;
  /** insert a single row into the table: "billing_order_statuses" */
  insert_billing_order_statuses_one?: Maybe<Billing_Order_Statuses>;
  /** insert data into the table: "promo_codes" */
  insert_billing_promo_codes?: Maybe<Billing_Promo_Codes_Mutation_Response>;
  /** insert a single row into the table: "promo_codes" */
  insert_billing_promo_codes_one?: Maybe<Billing_Promo_Codes>;
  /** insert data into the table: "cities" */
  insert_dms_cities?: Maybe<Dms_Cities_Mutation_Response>;
  /** insert a single row into the table: "cities" */
  insert_dms_cities_one?: Maybe<Dms_Cities>;
  /** insert data into the table: "group_insureds" */
  insert_dms_group_insureds?: Maybe<Dms_Group_Insureds_Mutation_Response>;
  /** insert a single row into the table: "group_insureds" */
  insert_dms_group_insureds_one?: Maybe<Dms_Group_Insureds>;
  /** insert data into the table: "groups" */
  insert_dms_groups?: Maybe<Dms_Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_dms_groups_one?: Maybe<Dms_Groups>;
  /** insert data into the table: "insurants" */
  insert_dms_insurants?: Maybe<Dms_Insurants_Mutation_Response>;
  /** insert a single row into the table: "insurants" */
  insert_dms_insurants_one?: Maybe<Dms_Insurants>;
  /** insert data into the table: "insureds" */
  insert_dms_insureds?: Maybe<Dms_Insureds_Mutation_Response>;
  /** insert a single row into the table: "insureds" */
  insert_dms_insureds_one?: Maybe<Dms_Insureds>;
  /** insert data into the table: "order_links" */
  insert_dms_order_links?: Maybe<Dms_Order_Links_Mutation_Response>;
  /** insert a single row into the table: "order_links" */
  insert_dms_order_links_one?: Maybe<Dms_Order_Links>;
  /** insert data into the table: "order_numbers" */
  insert_dms_order_numbers?: Maybe<Dms_Order_Numbers_Mutation_Response>;
  /** insert a single row into the table: "order_numbers" */
  insert_dms_order_numbers_one?: Maybe<Dms_Order_Numbers>;
  /** insert data into the table: "orders" */
  insert_dms_orders?: Maybe<Dms_Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_dms_orders_one?: Maybe<Dms_Orders>;
  /** insert data into the table: "products" */
  insert_dms_products?: Maybe<Dms_Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_dms_products_one?: Maybe<Dms_Products>;
  /** insert data into the table: "promo_codes" */
  insert_dms_promo_codes?: Maybe<Dms_Promo_Codes_Mutation_Response>;
  /** insert a single row into the table: "promo_codes" */
  insert_dms_promo_codes_one?: Maybe<Dms_Promo_Codes>;
  /** insert data into the table: "statuses" */
  insert_dms_statuses?: Maybe<Dms_Statuses_Mutation_Response>;
  /** insert a single row into the table: "statuses" */
  insert_dms_statuses_one?: Maybe<Dms_Statuses>;
  /** insert data into the table: "users" */
  insert_dms_users?: Maybe<Dms_Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_dms_users_one?: Maybe<Dms_Users>;
  /** insert data into the table: "cards" */
  insert_fintech_cards?: Maybe<Fintech_Cards_Mutation_Response>;
  /** insert a single row into the table: "cards" */
  insert_fintech_cards_one?: Maybe<Fintech_Cards>;
  /** insert data into the table: "clients" */
  insert_fintech_clients?: Maybe<Fintech_Clients_Mutation_Response>;
  /** insert a single row into the table: "clients" */
  insert_fintech_clients_one?: Maybe<Fintech_Clients>;
  /** insert data into the table: "orders" */
  insert_fintech_orders?: Maybe<Fintech_Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_fintech_orders_one?: Maybe<Fintech_Orders>;
  /** insert data into the table: "amocrm_contacts" */
  insert_mobile_amocrm_contacts?: Maybe<Mobile_Amocrm_Contacts_Mutation_Response>;
  /** insert a single row into the table: "amocrm_contacts" */
  insert_mobile_amocrm_contacts_one?: Maybe<Mobile_Amocrm_Contacts>;
  /** insert data into the table: "amocrm_leads" */
  insert_mobile_amocrm_leads?: Maybe<Mobile_Amocrm_Leads_Mutation_Response>;
  /** insert a single row into the table: "amocrm_leads" */
  insert_mobile_amocrm_leads_one?: Maybe<Mobile_Amocrm_Leads>;
  /** insert data into the table: "amocrm_tags" */
  insert_mobile_amocrm_tags?: Maybe<Mobile_Amocrm_Tags_Mutation_Response>;
  /** insert a single row into the table: "amocrm_tags" */
  insert_mobile_amocrm_tags_one?: Maybe<Mobile_Amocrm_Tags>;
  /** insert data into the table: "appointments" */
  insert_mobile_appointments?: Maybe<Mobile_Appointments_Mutation_Response>;
  /** insert a single row into the table: "appointments" */
  insert_mobile_appointments_one?: Maybe<Mobile_Appointments>;
  /** insert data into the table: "auto_replies" */
  insert_mobile_auto_replies?: Maybe<Mobile_Auto_Replies_Mutation_Response>;
  /** insert a single row into the table: "auto_replies" */
  insert_mobile_auto_replies_one?: Maybe<Mobile_Auto_Replies>;
  /** insert data into the table: "auto_reply_documents" */
  insert_mobile_auto_reply_documents?: Maybe<Mobile_Auto_Reply_Documents_Mutation_Response>;
  /** insert a single row into the table: "auto_reply_documents" */
  insert_mobile_auto_reply_documents_one?: Maybe<Mobile_Auto_Reply_Documents>;
  /** insert data into the table: "auto_reply_mediafiles" */
  insert_mobile_auto_reply_mediafiles?: Maybe<Mobile_Auto_Reply_Mediafiles_Mutation_Response>;
  /** insert a single row into the table: "auto_reply_mediafiles" */
  insert_mobile_auto_reply_mediafiles_one?: Maybe<Mobile_Auto_Reply_Mediafiles>;
  /** insert data into the table: "bank_accounts" */
  insert_mobile_bank_accounts?: Maybe<Mobile_Bank_Accounts_Mutation_Response>;
  /** insert a single row into the table: "bank_accounts" */
  insert_mobile_bank_accounts_one?: Maybe<Mobile_Bank_Accounts>;
  /** insert data into the table: "blocked_sites" */
  insert_mobile_blocked_sites?: Maybe<Mobile_Blocked_Sites_Mutation_Response>;
  /** insert a single row into the table: "blocked_sites" */
  insert_mobile_blocked_sites_one?: Maybe<Mobile_Blocked_Sites>;
  /** insert data into the table: "blockeds" */
  insert_mobile_blockeds?: Maybe<Mobile_Blockeds_Mutation_Response>;
  /** insert a single row into the table: "blockeds" */
  insert_mobile_blockeds_one?: Maybe<Mobile_Blockeds>;
  /** insert data into the table: "calorie_norms" */
  insert_mobile_calorie_norms?: Maybe<Mobile_Calorie_Norms_Mutation_Response>;
  /** insert a single row into the table: "calorie_norms" */
  insert_mobile_calorie_norms_one?: Maybe<Mobile_Calorie_Norms>;
  /** insert data into the table: "calorie_nows" */
  insert_mobile_calorie_nows?: Maybe<Mobile_Calorie_Nows_Mutation_Response>;
  /** insert a single row into the table: "calorie_nows" */
  insert_mobile_calorie_nows_one?: Maybe<Mobile_Calorie_Nows>;
  /** insert data into the table: "calories" */
  insert_mobile_calories?: Maybe<Mobile_Calories_Mutation_Response>;
  /** insert a single row into the table: "calories" */
  insert_mobile_calories_one?: Maybe<Mobile_Calories>;
  /** insert data into the table: "categories" */
  insert_mobile_categories?: Maybe<Mobile_Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_mobile_categories_one?: Maybe<Mobile_Categories>;
  /** insert data into the table: "category_names" */
  insert_mobile_category_names?: Maybe<Mobile_Category_Names_Mutation_Response>;
  /** insert a single row into the table: "category_names" */
  insert_mobile_category_names_one?: Maybe<Mobile_Category_Names>;
  /** insert data into the table: "chat_black_lists" */
  insert_mobile_chat_black_lists?: Maybe<Mobile_Chat_Black_Lists_Mutation_Response>;
  /** insert a single row into the table: "chat_black_lists" */
  insert_mobile_chat_black_lists_one?: Maybe<Mobile_Chat_Black_Lists>;
  /** insert data into the table: "chat_defaults" */
  insert_mobile_chat_defaults?: Maybe<Mobile_Chat_Defaults_Mutation_Response>;
  /** insert a single row into the table: "chat_defaults" */
  insert_mobile_chat_defaults_one?: Maybe<Mobile_Chat_Defaults>;
  /** insert data into the table: "chat_file_types" */
  insert_mobile_chat_file_types?: Maybe<Mobile_Chat_File_Types_Mutation_Response>;
  /** insert a single row into the table: "chat_file_types" */
  insert_mobile_chat_file_types_one?: Maybe<Mobile_Chat_File_Types>;
  /** insert data into the table: "chat_message_comments" */
  insert_mobile_chat_message_comments?: Maybe<Mobile_Chat_Message_Comments_Mutation_Response>;
  /** insert a single row into the table: "chat_message_comments" */
  insert_mobile_chat_message_comments_one?: Maybe<Mobile_Chat_Message_Comments>;
  /** insert data into the table: "chat_message_files" */
  insert_mobile_chat_message_files?: Maybe<Mobile_Chat_Message_Files_Mutation_Response>;
  /** insert a single row into the table: "chat_message_files" */
  insert_mobile_chat_message_files_one?: Maybe<Mobile_Chat_Message_Files>;
  /** insert data into the table: "chat_message_notifications" */
  insert_mobile_chat_message_notifications?: Maybe<Mobile_Chat_Message_Notifications_Mutation_Response>;
  /** insert a single row into the table: "chat_message_notifications" */
  insert_mobile_chat_message_notifications_one?: Maybe<Mobile_Chat_Message_Notifications>;
  /** insert data into the table: "chat_message_types" */
  insert_mobile_chat_message_types?: Maybe<Mobile_Chat_Message_Types_Mutation_Response>;
  /** insert a single row into the table: "chat_message_types" */
  insert_mobile_chat_message_types_one?: Maybe<Mobile_Chat_Message_Types>;
  /** insert data into the table: "chat_messages" */
  insert_mobile_chat_messages?: Maybe<Mobile_Chat_Messages_Mutation_Response>;
  /** insert a single row into the table: "chat_messages" */
  insert_mobile_chat_messages_one?: Maybe<Mobile_Chat_Messages>;
  /** insert data into the table: "chat_roles" */
  insert_mobile_chat_roles?: Maybe<Mobile_Chat_Roles_Mutation_Response>;
  /** insert a single row into the table: "chat_roles" */
  insert_mobile_chat_roles_one?: Maybe<Mobile_Chat_Roles>;
  /** insert data into the table: "chat_types" */
  insert_mobile_chat_types?: Maybe<Mobile_Chat_Types_Mutation_Response>;
  /** insert a single row into the table: "chat_types" */
  insert_mobile_chat_types_one?: Maybe<Mobile_Chat_Types>;
  /** insert data into the table: "chats" */
  insert_mobile_chats?: Maybe<Mobile_Chats_Mutation_Response>;
  /** insert a single row into the table: "chats" */
  insert_mobile_chats_one?: Maybe<Mobile_Chats>;
  /** insert data into the table: "clients" */
  insert_mobile_clients?: Maybe<Mobile_Clients_Mutation_Response>;
  /** insert a single row into the table: "clients" */
  insert_mobile_clients_one?: Maybe<Mobile_Clients>;
  /** insert data into the table: "comment_like_notifications" */
  insert_mobile_comment_like_notifications?: Maybe<Mobile_Comment_Like_Notifications_Mutation_Response>;
  /** insert a single row into the table: "comment_like_notifications" */
  insert_mobile_comment_like_notifications_one?: Maybe<Mobile_Comment_Like_Notifications>;
  /** insert data into the table: "comment_likes" */
  insert_mobile_comment_likes?: Maybe<Mobile_Comment_Likes_Mutation_Response>;
  /** insert a single row into the table: "comment_likes" */
  insert_mobile_comment_likes_one?: Maybe<Mobile_Comment_Likes>;
  /** insert data into the table: "comment_notifications" */
  insert_mobile_comment_notifications?: Maybe<Mobile_Comment_Notifications_Mutation_Response>;
  /** insert a single row into the table: "comment_notifications" */
  insert_mobile_comment_notifications_one?: Maybe<Mobile_Comment_Notifications>;
  /** insert data into the table: "comments" */
  insert_mobile_comments?: Maybe<Mobile_Comments_Mutation_Response>;
  /** insert a single row into the table: "comments" */
  insert_mobile_comments_one?: Maybe<Mobile_Comments>;
  /** insert data into the table: "confirmation_codes" */
  insert_mobile_confirmation_codes?: Maybe<Mobile_Confirmation_Codes_Mutation_Response>;
  /** insert a single row into the table: "confirmation_codes" */
  insert_mobile_confirmation_codes_one?: Maybe<Mobile_Confirmation_Codes>;
  /** insert data into the table: "contacts" */
  insert_mobile_contacts?: Maybe<Mobile_Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_mobile_contacts_one?: Maybe<Mobile_Contacts>;
  /** insert data into the table: "contents" */
  insert_mobile_contents?: Maybe<Mobile_Contents_Mutation_Response>;
  /** insert a single row into the table: "contents" */
  insert_mobile_contents_one?: Maybe<Mobile_Contents>;
  /** insert data into the table: "device_infos" */
  insert_mobile_device_infos?: Maybe<Mobile_Device_Infos_Mutation_Response>;
  /** insert a single row into the table: "device_infos" */
  insert_mobile_device_infos_one?: Maybe<Mobile_Device_Infos>;
  /** insert data into the table: "device_settings" */
  insert_mobile_device_settings?: Maybe<Mobile_Device_Settings_Mutation_Response>;
  /** insert a single row into the table: "device_settings" */
  insert_mobile_device_settings_one?: Maybe<Mobile_Device_Settings>;
  /** insert data into the table: "devices" */
  insert_mobile_devices?: Maybe<Mobile_Devices_Mutation_Response>;
  /** insert a single row into the table: "devices" */
  insert_mobile_devices_one?: Maybe<Mobile_Devices>;
  /** insert data into the table: "document_mediafiles" */
  insert_mobile_document_mediafiles?: Maybe<Mobile_Document_Mediafiles_Mutation_Response>;
  /** insert a single row into the table: "document_mediafiles" */
  insert_mobile_document_mediafiles_one?: Maybe<Mobile_Document_Mediafiles>;
  /** insert data into the table: "documents" */
  insert_mobile_documents?: Maybe<Mobile_Documents_Mutation_Response>;
  /** insert a single row into the table: "documents" */
  insert_mobile_documents_one?: Maybe<Mobile_Documents>;
  /** insert data into the table: "education_mediafiles" */
  insert_mobile_education_mediafiles?: Maybe<Mobile_Education_Mediafiles_Mutation_Response>;
  /** insert a single row into the table: "education_mediafiles" */
  insert_mobile_education_mediafiles_one?: Maybe<Mobile_Education_Mediafiles>;
  /** insert data into the table: "educations" */
  insert_mobile_educations?: Maybe<Mobile_Educations_Mutation_Response>;
  /** insert a single row into the table: "educations" */
  insert_mobile_educations_one?: Maybe<Mobile_Educations>;
  /** insert data into the table: "emojis" */
  insert_mobile_emojis?: Maybe<Mobile_Emojis_Mutation_Response>;
  /** insert a single row into the table: "emojis" */
  insert_mobile_emojis_one?: Maybe<Mobile_Emojis>;
  /** insert data into the table: "fixed_chat_messages" */
  insert_mobile_fixed_chat_messages?: Maybe<Mobile_Fixed_Chat_Messages_Mutation_Response>;
  /** insert a single row into the table: "fixed_chat_messages" */
  insert_mobile_fixed_chat_messages_one?: Maybe<Mobile_Fixed_Chat_Messages>;
  /** insert data into the table: "fixed_comments" */
  insert_mobile_fixed_comments?: Maybe<Mobile_Fixed_Comments_Mutation_Response>;
  /** insert a single row into the table: "fixed_comments" */
  insert_mobile_fixed_comments_one?: Maybe<Mobile_Fixed_Comments>;
  /** insert data into the table: "founder_mediafiles" */
  insert_mobile_founder_mediafiles?: Maybe<Mobile_Founder_Mediafiles_Mutation_Response>;
  /** insert a single row into the table: "founder_mediafiles" */
  insert_mobile_founder_mediafiles_one?: Maybe<Mobile_Founder_Mediafiles>;
  /** insert data into the table: "founders" */
  insert_mobile_founders?: Maybe<Mobile_Founders_Mutation_Response>;
  /** insert a single row into the table: "founders" */
  insert_mobile_founders_one?: Maybe<Mobile_Founders>;
  /** insert data into the table: "invite_emails" */
  insert_mobile_invite_emails?: Maybe<Mobile_Invite_Emails_Mutation_Response>;
  /** insert a single row into the table: "invite_emails" */
  insert_mobile_invite_emails_one?: Maybe<Mobile_Invite_Emails>;
  /** insert data into the table: "like_notifications" */
  insert_mobile_like_notifications?: Maybe<Mobile_Like_Notifications_Mutation_Response>;
  /** insert a single row into the table: "like_notifications" */
  insert_mobile_like_notifications_one?: Maybe<Mobile_Like_Notifications>;
  /** insert data into the table: "likes" */
  insert_mobile_likes?: Maybe<Mobile_Likes_Mutation_Response>;
  /** insert a single row into the table: "likes" */
  insert_mobile_likes_one?: Maybe<Mobile_Likes>;
  /** insert data into the table: "link_snippets" */
  insert_mobile_link_snippets?: Maybe<Mobile_Link_Snippets_Mutation_Response>;
  /** insert a single row into the table: "link_snippets" */
  insert_mobile_link_snippets_one?: Maybe<Mobile_Link_Snippets>;
  /** insert data into the table: "locked_deleted_chat_messages" */
  insert_mobile_locked_deleted_chat_messages?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Mutation_Response>;
  /** insert a single row into the table: "locked_deleted_chat_messages" */
  insert_mobile_locked_deleted_chat_messages_one?: Maybe<Mobile_Locked_Deleted_Chat_Messages>;
  /** insert data into the table: "mediafile_status_names" */
  insert_mobile_mediafile_status_names?: Maybe<Mobile_Mediafile_Status_Names_Mutation_Response>;
  /** insert a single row into the table: "mediafile_status_names" */
  insert_mobile_mediafile_status_names_one?: Maybe<Mobile_Mediafile_Status_Names>;
  /** insert data into the table: "mediafile_statuses" */
  insert_mobile_mediafile_statuses?: Maybe<Mobile_Mediafile_Statuses_Mutation_Response>;
  /** insert a single row into the table: "mediafile_statuses" */
  insert_mobile_mediafile_statuses_one?: Maybe<Mobile_Mediafile_Statuses>;
  /** insert data into the table: "medical_concierge_orders" */
  insert_mobile_medical_concierge_orders?: Maybe<Mobile_Medical_Concierge_Orders_Mutation_Response>;
  /** insert a single row into the table: "medical_concierge_orders" */
  insert_mobile_medical_concierge_orders_one?: Maybe<Mobile_Medical_Concierge_Orders>;
  /** insert data into the table: "medical_concierge_products" */
  insert_mobile_medical_concierge_products?: Maybe<Mobile_Medical_Concierge_Products_Mutation_Response>;
  /** insert a single row into the table: "medical_concierge_products" */
  insert_mobile_medical_concierge_products_one?: Maybe<Mobile_Medical_Concierge_Products>;
  /** insert data into the table: "mention_notifications" */
  insert_mobile_mention_notifications?: Maybe<Mobile_Mention_Notifications_Mutation_Response>;
  /** insert a single row into the table: "mention_notifications" */
  insert_mobile_mention_notifications_one?: Maybe<Mobile_Mention_Notifications>;
  /** insert data into the table: "mentions" */
  insert_mobile_mentions?: Maybe<Mobile_Mentions_Mutation_Response>;
  /** insert a single row into the table: "mentions" */
  insert_mobile_mentions_one?: Maybe<Mobile_Mentions>;
  /** insert data into the table: "metric_names" */
  insert_mobile_metric_names?: Maybe<Mobile_Metric_Names_Mutation_Response>;
  /** insert a single row into the table: "metric_names" */
  insert_mobile_metric_names_one?: Maybe<Mobile_Metric_Names>;
  /** insert data into the table: "metric_params" */
  insert_mobile_metric_params?: Maybe<Mobile_Metric_Params_Mutation_Response>;
  /** insert a single row into the table: "metric_params" */
  insert_mobile_metric_params_one?: Maybe<Mobile_Metric_Params>;
  /** insert data into the table: "metric_types" */
  insert_mobile_metric_types?: Maybe<Mobile_Metric_Types_Mutation_Response>;
  /** insert a single row into the table: "metric_types" */
  insert_mobile_metric_types_one?: Maybe<Mobile_Metric_Types>;
  /** insert data into the table: "metrics" */
  insert_mobile_metrics?: Maybe<Mobile_Metrics_Mutation_Response>;
  /** insert a single row into the table: "metrics" */
  insert_mobile_metrics_one?: Maybe<Mobile_Metrics>;
  /** insert data into the table: "notification_messages" */
  insert_mobile_notification_messages?: Maybe<Mobile_Notification_Messages_Mutation_Response>;
  /** insert a single row into the table: "notification_messages" */
  insert_mobile_notification_messages_one?: Maybe<Mobile_Notification_Messages>;
  /** insert data into the table: "notifications" */
  insert_mobile_notifications?: Maybe<Mobile_Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_mobile_notifications_one?: Maybe<Mobile_Notifications>;
  /** insert data into the table: "notifications_statuses" */
  insert_mobile_notifications_statuses?: Maybe<Mobile_Notifications_Statuses_Mutation_Response>;
  /** insert a single row into the table: "notifications_statuses" */
  insert_mobile_notifications_statuses_one?: Maybe<Mobile_Notifications_Statuses>;
  /** insert data into the table: "order_notifications" */
  insert_mobile_order_notifications?: Maybe<Mobile_Order_Notifications_Mutation_Response>;
  /** insert a single row into the table: "order_notifications" */
  insert_mobile_order_notifications_one?: Maybe<Mobile_Order_Notifications>;
  /** insert data into the table: "order_professionals" */
  insert_mobile_order_professionals?: Maybe<Mobile_Order_Professionals_Mutation_Response>;
  /** insert a single row into the table: "order_professionals" */
  insert_mobile_order_professionals_one?: Maybe<Mobile_Order_Professionals>;
  /** insert data into the table: "orders" */
  insert_mobile_orders?: Maybe<Mobile_Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_mobile_orders_one?: Maybe<Mobile_Orders>;
  /** insert data into the table: "orgs" */
  insert_mobile_orgs?: Maybe<Mobile_Orgs_Mutation_Response>;
  /** insert a single row into the table: "orgs" */
  insert_mobile_orgs_one?: Maybe<Mobile_Orgs>;
  /** insert data into the table: "param_categories" */
  insert_mobile_param_categories?: Maybe<Mobile_Param_Categories_Mutation_Response>;
  /** insert a single row into the table: "param_categories" */
  insert_mobile_param_categories_one?: Maybe<Mobile_Param_Categories>;
  /** insert data into the table: "param_categories_params" */
  insert_mobile_param_categories_params?: Maybe<Mobile_Param_Categories_Params_Mutation_Response>;
  /** insert a single row into the table: "param_categories_params" */
  insert_mobile_param_categories_params_one?: Maybe<Mobile_Param_Categories_Params>;
  /** insert data into the table: "param_category_names" */
  insert_mobile_param_category_names?: Maybe<Mobile_Param_Category_Names_Mutation_Response>;
  /** insert a single row into the table: "param_category_names" */
  insert_mobile_param_category_names_one?: Maybe<Mobile_Param_Category_Names>;
  /** insert data into the table: "param_names" */
  insert_mobile_param_names?: Maybe<Mobile_Param_Names_Mutation_Response>;
  /** insert a single row into the table: "param_names" */
  insert_mobile_param_names_one?: Maybe<Mobile_Param_Names>;
  /** insert data into the table: "params" */
  insert_mobile_params?: Maybe<Mobile_Params_Mutation_Response>;
  /** insert a single row into the table: "params" */
  insert_mobile_params_one?: Maybe<Mobile_Params>;
  /** insert data into the table: "params_services" */
  insert_mobile_params_services?: Maybe<Mobile_Params_Services_Mutation_Response>;
  /** insert a single row into the table: "params_services" */
  insert_mobile_params_services_one?: Maybe<Mobile_Params_Services>;
  /** insert data into the table: "personal_info_views" */
  insert_mobile_personal_info_views?: Maybe<Mobile_Personal_Info_Views_Mutation_Response>;
  /** insert a single row into the table: "personal_info_views" */
  insert_mobile_personal_info_views_one?: Maybe<Mobile_Personal_Info_Views>;
  /** insert data into the table: "personal_infos" */
  insert_mobile_personal_infos?: Maybe<Mobile_Personal_Infos_Mutation_Response>;
  /** insert a single row into the table: "personal_infos" */
  insert_mobile_personal_infos_one?: Maybe<Mobile_Personal_Infos>;
  /** insert data into the table: "phones" */
  insert_mobile_phones?: Maybe<Mobile_Phones_Mutation_Response>;
  /** insert a single row into the table: "phones" */
  insert_mobile_phones_one?: Maybe<Mobile_Phones>;
  /** insert data into the table: "post_params" */
  insert_mobile_post_params?: Maybe<Mobile_Post_Params_Mutation_Response>;
  /** insert a single row into the table: "post_params" */
  insert_mobile_post_params_one?: Maybe<Mobile_Post_Params>;
  /** insert data into the table: "posts" */
  insert_mobile_posts?: Maybe<Mobile_Posts_Mutation_Response>;
  /** insert a single row into the table: "posts" */
  insert_mobile_posts_one?: Maybe<Mobile_Posts>;
  /** insert data into the table: "pro_document_statuses" */
  insert_mobile_pro_document_statuses?: Maybe<Mobile_Pro_Document_Statuses_Mutation_Response>;
  /** insert a single row into the table: "pro_document_statuses" */
  insert_mobile_pro_document_statuses_one?: Maybe<Mobile_Pro_Document_Statuses>;
  /** insert data into the table: "pro_professions" */
  insert_mobile_pro_professions?: Maybe<Mobile_Pro_Professions_Mutation_Response>;
  /** insert a single row into the table: "pro_professions" */
  insert_mobile_pro_professions_one?: Maybe<Mobile_Pro_Professions>;
  /** insert data into the table: "pro_types" */
  insert_mobile_pro_types?: Maybe<Mobile_Pro_Types_Mutation_Response>;
  /** insert a single row into the table: "pro_types" */
  insert_mobile_pro_types_one?: Maybe<Mobile_Pro_Types>;
  /** insert data into the table: "problems" */
  insert_mobile_problems?: Maybe<Mobile_Problems_Mutation_Response>;
  /** insert a single row into the table: "problems" */
  insert_mobile_problems_one?: Maybe<Mobile_Problems>;
  /** insert data into the table: "profession_names" */
  insert_mobile_profession_names?: Maybe<Mobile_Profession_Names_Mutation_Response>;
  /** insert a single row into the table: "profession_names" */
  insert_mobile_profession_names_one?: Maybe<Mobile_Profession_Names>;
  /** insert data into the table: "professions" */
  insert_mobile_professions?: Maybe<Mobile_Professions_Mutation_Response>;
  /** insert a single row into the table: "professions" */
  insert_mobile_professions_one?: Maybe<Mobile_Professions>;
  /** insert data into the table: "profile_deleteds" */
  insert_mobile_profile_deleteds?: Maybe<Mobile_Profile_Deleteds_Mutation_Response>;
  /** insert a single row into the table: "profile_deleteds" */
  insert_mobile_profile_deleteds_one?: Maybe<Mobile_Profile_Deleteds>;
  /** insert data into the table: "profile_locks" */
  insert_mobile_profile_locks?: Maybe<Mobile_Profile_Locks_Mutation_Response>;
  /** insert a single row into the table: "profile_locks" */
  insert_mobile_profile_locks_one?: Maybe<Mobile_Profile_Locks>;
  /** insert data into the table: "profiles" */
  insert_mobile_profiles?: Maybe<Mobile_Profiles_Mutation_Response>;
  /** insert a single row into the table: "profiles" */
  insert_mobile_profiles_one?: Maybe<Mobile_Profiles>;
  /** insert data into the table: "pros" */
  insert_mobile_pros?: Maybe<Mobile_Pros_Mutation_Response>;
  /** insert a single row into the table: "pros" */
  insert_mobile_pros_one?: Maybe<Mobile_Pros>;
  /** insert data into the table: "pulse_norms" */
  insert_mobile_pulse_norms?: Maybe<Mobile_Pulse_Norms_Mutation_Response>;
  /** insert a single row into the table: "pulse_norms" */
  insert_mobile_pulse_norms_one?: Maybe<Mobile_Pulse_Norms>;
  /** insert data into the table: "pulse_nows" */
  insert_mobile_pulse_nows?: Maybe<Mobile_Pulse_Nows_Mutation_Response>;
  /** insert a single row into the table: "pulse_nows" */
  insert_mobile_pulse_nows_one?: Maybe<Mobile_Pulse_Nows>;
  /** insert data into the table: "pulses" */
  insert_mobile_pulses?: Maybe<Mobile_Pulses_Mutation_Response>;
  /** insert a single row into the table: "pulses" */
  insert_mobile_pulses_one?: Maybe<Mobile_Pulses>;
  /** insert data into the table: "reactions" */
  insert_mobile_reactions?: Maybe<Mobile_Reactions_Mutation_Response>;
  /** insert a single row into the table: "reactions" */
  insert_mobile_reactions_one?: Maybe<Mobile_Reactions>;
  /** insert data into the table: "reports" */
  insert_mobile_reports?: Maybe<Mobile_Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  insert_mobile_reports_one?: Maybe<Mobile_Reports>;
  /** insert data into the table: "service_agreements" */
  insert_mobile_service_agreements?: Maybe<Mobile_Service_Agreements_Mutation_Response>;
  /** insert a single row into the table: "service_agreements" */
  insert_mobile_service_agreements_one?: Maybe<Mobile_Service_Agreements>;
  /** insert data into the table: "service_answer_reactions" */
  insert_mobile_service_answer_reactions?: Maybe<Mobile_Service_Answer_Reactions_Mutation_Response>;
  /** insert a single row into the table: "service_answer_reactions" */
  insert_mobile_service_answer_reactions_one?: Maybe<Mobile_Service_Answer_Reactions>;
  /** insert data into the table: "service_answers" */
  insert_mobile_service_answers?: Maybe<Mobile_Service_Answers_Mutation_Response>;
  /** insert a single row into the table: "service_answers" */
  insert_mobile_service_answers_one?: Maybe<Mobile_Service_Answers>;
  /** insert data into the table: "service_documents" */
  insert_mobile_service_documents?: Maybe<Mobile_Service_Documents_Mutation_Response>;
  /** insert a single row into the table: "service_documents" */
  insert_mobile_service_documents_one?: Maybe<Mobile_Service_Documents>;
  /** insert data into the table: "service_favorites" */
  insert_mobile_service_favorites?: Maybe<Mobile_Service_Favorites_Mutation_Response>;
  /** insert a single row into the table: "service_favorites" */
  insert_mobile_service_favorites_one?: Maybe<Mobile_Service_Favorites>;
  /** insert data into the table: "service_free_purchases" */
  insert_mobile_service_free_purchases?: Maybe<Mobile_Service_Free_Purchases_Mutation_Response>;
  /** insert a single row into the table: "service_free_purchases" */
  insert_mobile_service_free_purchases_one?: Maybe<Mobile_Service_Free_Purchases>;
  /** insert data into the table: "service_images" */
  insert_mobile_service_images?: Maybe<Mobile_Service_Images_Mutation_Response>;
  /** insert a single row into the table: "service_images" */
  insert_mobile_service_images_one?: Maybe<Mobile_Service_Images>;
  /** insert data into the table: "service_likes" */
  insert_mobile_service_likes?: Maybe<Mobile_Service_Likes_Mutation_Response>;
  /** insert a single row into the table: "service_likes" */
  insert_mobile_service_likes_one?: Maybe<Mobile_Service_Likes>;
  /** insert data into the table: "service_list_documents" */
  insert_mobile_service_list_documents?: Maybe<Mobile_Service_List_Documents_Mutation_Response>;
  /** insert a single row into the table: "service_list_documents" */
  insert_mobile_service_list_documents_one?: Maybe<Mobile_Service_List_Documents>;
  /** insert data into the table: "service_list_periodicities" */
  insert_mobile_service_list_periodicities?: Maybe<Mobile_Service_List_Periodicities_Mutation_Response>;
  /** insert a single row into the table: "service_list_periodicities" */
  insert_mobile_service_list_periodicities_one?: Maybe<Mobile_Service_List_Periodicities>;
  /** insert data into the table: "service_lists" */
  insert_mobile_service_lists?: Maybe<Mobile_Service_Lists_Mutation_Response>;
  /** insert a single row into the table: "service_lists" */
  insert_mobile_service_lists_one?: Maybe<Mobile_Service_Lists>;
  /** insert data into the table: "service_orders" */
  insert_mobile_service_orders?: Maybe<Mobile_Service_Orders_Mutation_Response>;
  /** insert a single row into the table: "service_orders" */
  insert_mobile_service_orders_one?: Maybe<Mobile_Service_Orders>;
  /** insert data into the table: "service_periodicities" */
  insert_mobile_service_periodicities?: Maybe<Mobile_Service_Periodicities_Mutation_Response>;
  /** insert a single row into the table: "service_periodicities" */
  insert_mobile_service_periodicities_one?: Maybe<Mobile_Service_Periodicities>;
  /** insert data into the table: "service_purchases" */
  insert_mobile_service_purchases?: Maybe<Mobile_Service_Purchases_Mutation_Response>;
  /** insert a single row into the table: "service_purchases" */
  insert_mobile_service_purchases_one?: Maybe<Mobile_Service_Purchases>;
  /** insert data into the table: "service_questions" */
  insert_mobile_service_questions?: Maybe<Mobile_Service_Questions_Mutation_Response>;
  /** insert a single row into the table: "service_questions" */
  insert_mobile_service_questions_one?: Maybe<Mobile_Service_Questions>;
  /** insert data into the table: "service_rate_comments" */
  insert_mobile_service_rate_comments?: Maybe<Mobile_Service_Rate_Comments_Mutation_Response>;
  /** insert a single row into the table: "service_rate_comments" */
  insert_mobile_service_rate_comments_one?: Maybe<Mobile_Service_Rate_Comments>;
  /** insert data into the table: "service_rate_images" */
  insert_mobile_service_rate_images?: Maybe<Mobile_Service_Rate_Images_Mutation_Response>;
  /** insert a single row into the table: "service_rate_images" */
  insert_mobile_service_rate_images_one?: Maybe<Mobile_Service_Rate_Images>;
  /** insert data into the table: "service_rate_reactions" */
  insert_mobile_service_rate_reactions?: Maybe<Mobile_Service_Rate_Reactions_Mutation_Response>;
  /** insert a single row into the table: "service_rate_reactions" */
  insert_mobile_service_rate_reactions_one?: Maybe<Mobile_Service_Rate_Reactions>;
  /** insert data into the table: "service_rates" */
  insert_mobile_service_rates?: Maybe<Mobile_Service_Rates_Mutation_Response>;
  /** insert a single row into the table: "service_rates" */
  insert_mobile_service_rates_one?: Maybe<Mobile_Service_Rates>;
  /** insert data into the table: "service_statuses" */
  insert_mobile_service_statuses?: Maybe<Mobile_Service_Statuses_Mutation_Response>;
  /** insert a single row into the table: "service_statuses" */
  insert_mobile_service_statuses_one?: Maybe<Mobile_Service_Statuses>;
  /** insert data into the table: "service_tag_ages" */
  insert_mobile_service_tag_ages?: Maybe<Mobile_Service_Tag_Ages_Mutation_Response>;
  /** insert a single row into the table: "service_tag_ages" */
  insert_mobile_service_tag_ages_one?: Maybe<Mobile_Service_Tag_Ages>;
  /** insert data into the table: "service_tag_target_groups" */
  insert_mobile_service_tag_target_groups?: Maybe<Mobile_Service_Tag_Target_Groups_Mutation_Response>;
  /** insert a single row into the table: "service_tag_target_groups" */
  insert_mobile_service_tag_target_groups_one?: Maybe<Mobile_Service_Tag_Target_Groups>;
  /** insert data into the table: "service_tag_targets" */
  insert_mobile_service_tag_targets?: Maybe<Mobile_Service_Tag_Targets_Mutation_Response>;
  /** insert a single row into the table: "service_tag_targets" */
  insert_mobile_service_tag_targets_one?: Maybe<Mobile_Service_Tag_Targets>;
  /** insert data into the table: "service_tariff_documents" */
  insert_mobile_service_tariff_documents?: Maybe<Mobile_Service_Tariff_Documents_Mutation_Response>;
  /** insert a single row into the table: "service_tariff_documents" */
  insert_mobile_service_tariff_documents_one?: Maybe<Mobile_Service_Tariff_Documents>;
  /** insert data into the table: "service_tariffs" */
  insert_mobile_service_tariffs?: Maybe<Mobile_Service_Tariffs_Mutation_Response>;
  /** insert a single row into the table: "service_tariffs" */
  insert_mobile_service_tariffs_one?: Maybe<Mobile_Service_Tariffs>;
  /** insert data into the table: "service_type_names" */
  insert_mobile_service_type_names?: Maybe<Mobile_Service_Type_Names_Mutation_Response>;
  /** insert a single row into the table: "service_type_names" */
  insert_mobile_service_type_names_one?: Maybe<Mobile_Service_Type_Names>;
  /** insert data into the table: "service_types" */
  insert_mobile_service_types?: Maybe<Mobile_Service_Types_Mutation_Response>;
  /** insert a single row into the table: "service_types" */
  insert_mobile_service_types_one?: Maybe<Mobile_Service_Types>;
  /** insert data into the table: "services" */
  insert_mobile_services?: Maybe<Mobile_Services_Mutation_Response>;
  /** insert a single row into the table: "services" */
  insert_mobile_services_one?: Maybe<Mobile_Services>;
  /** insert data into the table: "settings" */
  insert_mobile_settings?: Maybe<Mobile_Settings_Mutation_Response>;
  /** insert a single row into the table: "settings" */
  insert_mobile_settings_one?: Maybe<Mobile_Settings>;
  /** insert data into the table: "share_posts" */
  insert_mobile_share_posts?: Maybe<Mobile_Share_Posts_Mutation_Response>;
  /** insert a single row into the table: "share_posts" */
  insert_mobile_share_posts_one?: Maybe<Mobile_Share_Posts>;
  /** insert data into the table: "sleep_norms" */
  insert_mobile_sleep_norms?: Maybe<Mobile_Sleep_Norms_Mutation_Response>;
  /** insert a single row into the table: "sleep_norms" */
  insert_mobile_sleep_norms_one?: Maybe<Mobile_Sleep_Norms>;
  /** insert data into the table: "sleep_nows" */
  insert_mobile_sleep_nows?: Maybe<Mobile_Sleep_Nows_Mutation_Response>;
  /** insert a single row into the table: "sleep_nows" */
  insert_mobile_sleep_nows_one?: Maybe<Mobile_Sleep_Nows>;
  /** insert data into the table: "sleeps" */
  insert_mobile_sleeps?: Maybe<Mobile_Sleeps_Mutation_Response>;
  /** insert a single row into the table: "sleeps" */
  insert_mobile_sleeps_one?: Maybe<Mobile_Sleeps>;
  /** insert data into the table: "slots" */
  insert_mobile_slots?: Maybe<Mobile_Slots_Mutation_Response>;
  /** insert a single row into the table: "slots" */
  insert_mobile_slots_one?: Maybe<Mobile_Slots>;
  /** insert data into the table: "status_messages" */
  insert_mobile_status_messages?: Maybe<Mobile_Status_Messages_Mutation_Response>;
  /** insert a single row into the table: "status_messages" */
  insert_mobile_status_messages_one?: Maybe<Mobile_Status_Messages>;
  /** insert data into the table: "step_norms" */
  insert_mobile_step_norms?: Maybe<Mobile_Step_Norms_Mutation_Response>;
  /** insert a single row into the table: "step_norms" */
  insert_mobile_step_norms_one?: Maybe<Mobile_Step_Norms>;
  /** insert data into the table: "step_nows" */
  insert_mobile_step_nows?: Maybe<Mobile_Step_Nows_Mutation_Response>;
  /** insert a single row into the table: "step_nows" */
  insert_mobile_step_nows_one?: Maybe<Mobile_Step_Nows>;
  /** insert data into the table: "steps" */
  insert_mobile_steps?: Maybe<Mobile_Steps_Mutation_Response>;
  /** insert a single row into the table: "steps" */
  insert_mobile_steps_one?: Maybe<Mobile_Steps>;
  /** insert data into the table: "subscribe_notifications" */
  insert_mobile_subscribe_notifications?: Maybe<Mobile_Subscribe_Notifications_Mutation_Response>;
  /** insert a single row into the table: "subscribe_notifications" */
  insert_mobile_subscribe_notifications_one?: Maybe<Mobile_Subscribe_Notifications>;
  /** insert data into the table: "subscribes" */
  insert_mobile_subscribes?: Maybe<Mobile_Subscribes_Mutation_Response>;
  /** insert a single row into the table: "subscribes" */
  insert_mobile_subscribes_one?: Maybe<Mobile_Subscribes>;
  /** insert data into the table: "support_messages" */
  insert_mobile_support_messages?: Maybe<Mobile_Support_Messages_Mutation_Response>;
  /** insert a single row into the table: "support_messages" */
  insert_mobile_support_messages_one?: Maybe<Mobile_Support_Messages>;
  /** insert data into the table: "support_topics" */
  insert_mobile_support_topics?: Maybe<Mobile_Support_Topics_Mutation_Response>;
  /** insert a single row into the table: "support_topics" */
  insert_mobile_support_topics_one?: Maybe<Mobile_Support_Topics>;
  /** insert data into the table: "tag_ages" */
  insert_mobile_tag_ages?: Maybe<Mobile_Tag_Ages_Mutation_Response>;
  /** insert a single row into the table: "tag_ages" */
  insert_mobile_tag_ages_one?: Maybe<Mobile_Tag_Ages>;
  /** insert data into the table: "tag_target_groups" */
  insert_mobile_tag_target_groups?: Maybe<Mobile_Tag_Target_Groups_Mutation_Response>;
  /** insert a single row into the table: "tag_target_groups" */
  insert_mobile_tag_target_groups_one?: Maybe<Mobile_Tag_Target_Groups>;
  /** insert data into the table: "tag_targets" */
  insert_mobile_tag_targets?: Maybe<Mobile_Tag_Targets_Mutation_Response>;
  /** insert a single row into the table: "tag_targets" */
  insert_mobile_tag_targets_one?: Maybe<Mobile_Tag_Targets>;
  /** insert data into the table: "type_phones" */
  insert_mobile_type_phones?: Maybe<Mobile_Type_Phones_Mutation_Response>;
  /** insert a single row into the table: "type_phones" */
  insert_mobile_type_phones_one?: Maybe<Mobile_Type_Phones>;
  /** insert data into the table: "type_profiles" */
  insert_mobile_type_profiles?: Maybe<Mobile_Type_Profiles_Mutation_Response>;
  /** insert a single row into the table: "type_profiles" */
  insert_mobile_type_profiles_one?: Maybe<Mobile_Type_Profiles>;
  /** insert data into the table: "upload_pro_documents" */
  insert_mobile_upload_pro_documents?: Maybe<Mobile_Upload_Pro_Documents_Mutation_Response>;
  /** insert a single row into the table: "upload_pro_documents" */
  insert_mobile_upload_pro_documents_one?: Maybe<Mobile_Upload_Pro_Documents>;
  /** insert data into the table: "user_chat_rights" */
  insert_mobile_user_chat_rights?: Maybe<Mobile_User_Chat_Rights_Mutation_Response>;
  /** insert a single row into the table: "user_chat_rights" */
  insert_mobile_user_chat_rights_one?: Maybe<Mobile_User_Chat_Rights>;
  /** insert data into the table: "user_chats" */
  insert_mobile_user_chats?: Maybe<Mobile_User_Chats_Mutation_Response>;
  /** insert a single row into the table: "user_chats" */
  insert_mobile_user_chats_one?: Maybe<Mobile_User_Chats>;
  /** insert data into the table: "view_chat_messages" */
  insert_mobile_view_chat_messages?: Maybe<Mobile_View_Chat_Messages_Mutation_Response>;
  /** insert a single row into the table: "view_chat_messages" */
  insert_mobile_view_chat_messages_one?: Maybe<Mobile_View_Chat_Messages>;
  /** insert data into the table: "water_norms" */
  insert_mobile_water_norms?: Maybe<Mobile_Water_Norms_Mutation_Response>;
  /** insert a single row into the table: "water_norms" */
  insert_mobile_water_norms_one?: Maybe<Mobile_Water_Norms>;
  /** insert data into the table: "water_nows" */
  insert_mobile_water_nows?: Maybe<Mobile_Water_Nows_Mutation_Response>;
  /** insert a single row into the table: "water_nows" */
  insert_mobile_water_nows_one?: Maybe<Mobile_Water_Nows>;
  /** insert data into the table: "waters" */
  insert_mobile_waters?: Maybe<Mobile_Waters_Mutation_Response>;
  /** insert a single row into the table: "waters" */
  insert_mobile_waters_one?: Maybe<Mobile_Waters>;
  /** insert data into the table: "weight_nows" */
  insert_mobile_weight_nows?: Maybe<Mobile_Weight_Nows_Mutation_Response>;
  /** insert a single row into the table: "weight_nows" */
  insert_mobile_weight_nows_one?: Maybe<Mobile_Weight_Nows>;
  /** insert data into the table: "weights" */
  insert_mobile_weights?: Maybe<Mobile_Weights_Mutation_Response>;
  /** insert a single row into the table: "weights" */
  insert_mobile_weights_one?: Maybe<Mobile_Weights>;
  /** insert data into the table: "balances" */
  insert_partner_balances?: Maybe<Partner_Balances_Mutation_Response>;
  /** insert a single row into the table: "balances" */
  insert_partner_balances_one?: Maybe<Partner_Balances>;
  /** insert data into the table: "log_vzr_sales" */
  insert_partner_log_vzr_sales?: Maybe<Partner_Log_Vzr_Sales_Mutation_Response>;
  /** insert a single row into the table: "log_vzr_sales" */
  insert_partner_log_vzr_sales_one?: Maybe<Partner_Log_Vzr_Sales>;
  /** insert data into the table: "log_vzr_searches" */
  insert_partner_log_vzr_searches?: Maybe<Partner_Log_Vzr_Searches_Mutation_Response>;
  /** insert a single row into the table: "log_vzr_searches" */
  insert_partner_log_vzr_searches_one?: Maybe<Partner_Log_Vzr_Searches>;
  /** insert data into the table: "log_vzr_views" */
  insert_partner_log_vzr_views?: Maybe<Partner_Log_Vzr_Views_Mutation_Response>;
  /** insert a single row into the table: "log_vzr_views" */
  insert_partner_log_vzr_views_one?: Maybe<Partner_Log_Vzr_Views>;
  /** insert data into the table: "place_type_integrations" */
  insert_partner_place_type_integrations?: Maybe<Partner_Place_Type_Integrations_Mutation_Response>;
  /** insert a single row into the table: "place_type_integrations" */
  insert_partner_place_type_integrations_one?: Maybe<Partner_Place_Type_Integrations>;
  /** insert data into the table: "places" */
  insert_partner_places?: Maybe<Partner_Places_Mutation_Response>;
  /** insert a single row into the table: "places" */
  insert_partner_places_one?: Maybe<Partner_Places>;
  /** insert data into the table: "sale_types" */
  insert_partner_sale_types?: Maybe<Partner_Sale_Types_Mutation_Response>;
  /** insert a single row into the table: "sale_types" */
  insert_partner_sale_types_one?: Maybe<Partner_Sale_Types>;
  /** insert data into the table: "status_integrations" */
  insert_partner_status_integrations?: Maybe<Partner_Status_Integrations_Mutation_Response>;
  /** insert a single row into the table: "status_integrations" */
  insert_partner_status_integrations_one?: Maybe<Partner_Status_Integrations>;
  /** insert data into the table: "status_transactions" */
  insert_partner_status_transactions?: Maybe<Partner_Status_Transactions_Mutation_Response>;
  /** insert a single row into the table: "status_transactions" */
  insert_partner_status_transactions_one?: Maybe<Partner_Status_Transactions>;
  /** insert data into the table: "themes" */
  insert_partner_themes?: Maybe<Partner_Themes_Mutation_Response>;
  /** insert a single row into the table: "themes" */
  insert_partner_themes_one?: Maybe<Partner_Themes>;
  /** insert data into the table: "type_integrations" */
  insert_partner_type_integrations?: Maybe<Partner_Type_Integrations_Mutation_Response>;
  /** insert a single row into the table: "type_integrations" */
  insert_partner_type_integrations_one?: Maybe<Partner_Type_Integrations>;
  /** insert data into the table: "type_transactions" */
  insert_partner_type_transactions?: Maybe<Partner_Type_Transactions_Mutation_Response>;
  /** insert a single row into the table: "type_transactions" */
  insert_partner_type_transactions_one?: Maybe<Partner_Type_Transactions>;
  /** insert data into the table: "user_types" */
  insert_partner_user_types?: Maybe<Partner_User_Types_Mutation_Response>;
  /** insert a single row into the table: "user_types" */
  insert_partner_user_types_one?: Maybe<Partner_User_Types>;
  /** insert data into the table: "users" */
  insert_partner_users?: Maybe<Partner_Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_partner_users_one?: Maybe<Partner_Users>;
  /** insert data into the table: "users" */
  insert_service_users?: Maybe<Service_Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_service_users_one?: Maybe<Service_Users>;
  mobileAmocrmCreateLead?: Maybe<MobileAmocrmCreateLeadOutput>;
  mobileCreateLinkSnippet?: Maybe<MobileCreateLinkSnippetOutput>;
  mobileCustomInsertChatMessageComment?: Maybe<MobileCustomInsertChatMessageCommentOutput>;
  mobileCustomInsertComment?: Maybe<MobileCustomInsertCommentOutput>;
  mobileDeleteAutoreply?: Maybe<MobileDeleteAutoreplyOutput>;
  mobileDeleteAvatar?: Maybe<MobileDeleteAvatarOutput>;
  mobileDeleteCommentsByPk?: Maybe<MobileDeleteCommentsByPkOutput>;
  mobileDeleteDocumentToPro?: Maybe<MobileDeleteDocumentToProOutput>;
  mobileDeletePersonalUserChats?: Maybe<MobileDeletePersonalUserChatsOutput>;
  mobileDeleteService?: Maybe<ServiceOutput>;
  mobileGetFreeGuide?: Maybe<MobileGetFreeGuideOutput>;
  mobileGetProfile?: Maybe<MobileGetProfileOutput>;
  mobileHardDeleteUser?: Maybe<MobileHardDeleteUserOutput>;
  mobileHardDeleteUserForTest?: Maybe<MobileHardDeleteUserForTestOutput>;
  mobileInsertButtonCommandMedconcierge?: Maybe<MobileInsertButtonCommandMedconciergeOutput>;
  mobileInsertChatMessagesOne?: Maybe<MobileInsertChatMessagesOneOutput>;
  mobileInsertChatsOne?: Maybe<MobileInsertChatsOneOutput>;
  mobileInsertMobileCommentsOne?: Maybe<MobileInsertMobileCommentsOneOutput>;
  mobileInsertParamsServices?: Maybe<MobileInsertParamsServicesOutput>;
  mobileInsertPostParams?: Maybe<MobileInsertPostParamsOutput>;
  mobileInsertPro?: Maybe<MobileInsertProOutput>;
  mobileInsertProfileLocksOne?: Maybe<MobileInsertProfileLocksOneOutput>;
  mobileInsertReports?: Maybe<MobileInsertReportsOutput>;
  mobileInsertServiceLists?: Maybe<MobileInsertServiceListsOutput>;
  mobileInsertServiceRatesOne?: Maybe<MobileInsertServiceRatesOneOutput>;
  mobileInsertServicesOne?: Maybe<MobileInsertServicesOneOutput>;
  mobileInsertSubscribesOne?: Maybe<MobileInsertSubscribesOneOutput>;
  mobileMedconciergeTimerButton?: Maybe<MobiledMedconciergeTimerButtonOutput>;
  mobileProfileDataRecovery?: Maybe<MobileProfileDataRecoveryOutput>;
  mobilePurchasingMedicalConcierge?: Maybe<MobilePurchasingMedicalConciergeOutput>;
  mobilePurchasingMedicalConciergeRetry?: Maybe<MobilePurchasingMedicalConciergeRetryOutput>;
  mobilePurchasingPersonalProgram?: Maybe<MobilePurchasingPersonalProgramOutput>;
  mobilePurchasingService?: Maybe<MobilePurchasingServiceOutput>;
  mobilePurchasingServiceRetry?: Maybe<MobilePurchasingServiceRetryOutput>;
  mobileSearchByServiceLikes?: Maybe<MobileSearchByServiceLikesOutput>;
  mobileServiceChecking?: Maybe<ServiceOutput>;
  mobileServiceHidden?: Maybe<ServiceOutput>;
  mobileSetOperatorProfile?: Maybe<MobileSetOperatorProfileOutput>;
  mobileSetProfile?: Maybe<MobileSetProfileOutput>;
  mobileSetProfileMedBot?: Maybe<MobileSetProfileMedBotOutput>;
  mobileSoftDeleteComment?: Maybe<MobileSoftDeleteCommentOutput>;
  mobileSoftDeleteUser?: Maybe<MobileSoftDeleteUserOutput>;
  mobileSoftDeleteUserFotTest?: Maybe<MobileSoftDeleteUserFotTestOutput>;
  mobileUpdateChatMessagesStatusMessage?: Maybe<MobileUpdateChatMessagesStatusMessageOutput>;
  mobileUpdateDocumentToAutoreply?: Maybe<AutoReplyDocumentOutput>;
  mobileUpdateDocumentToService?: Maybe<ServiceDocumentOutput>;
  mobileUpdateMediaFileToService?: Maybe<ServiceImageOutput>;
  mobileUpdateMediafileToAutoreply?: Maybe<AutoReplyMediafileOutput>;
  mobileUpdateMobileProfileAvatar?: Maybe<ProfileOutput>;
  mobileUpdateService?: Maybe<ServiceOutput>;
  mobileUploadAgreementToService?: Maybe<MobileUploadAgreementToServiceOutput>;
  mobileUploadDocumentToAutoreply?: Maybe<MobileUploadDocumentToAutoreplyOutput>;
  mobileUploadDocumentToPro?: Maybe<MobileUploadDocumentToProOutput>;
  mobileUploadDocumentToService?: Maybe<MobileUploadDocumentToServiceOutput>;
  mobileUploadDocumentToServiceList?: Maybe<MobileUploadDocumentToServiceListOutput>;
  mobileUploadMediaFileToService?: Maybe<MobileUploadMediaFileToServiceOutput>;
  mobileUploadMediafileToAutoreply?: Maybe<MobileUploadMediafileToAutoreplyOutput>;
  partnerConfirmFileUploading?: Maybe<PartnerConfirmFileUploadingOutput>;
  partnerCreateFileAccess?: Maybe<PartnerCreateFileAccessOutput>;
  partnerCreatePlace?: Maybe<PartnerCreatePlaceOutput>;
  partnerCreatePlaceTypeIntegrations?: Maybe<PartnerCreatePlaceTypeIntegrationsOutput>;
  partnerInsertBalances?: Maybe<PartnerInsertBalancesOutput>;
  updateMobilePostsAction?: Maybe<UpdateMobilePostsOutput>;
  updateMobileProfilesByPkAction?: Maybe<UpdateMobileProfilesByPkOutput>;
  /** update data of the table: "oauth_access_tokens" */
  update_auth_oauth_access_tokens?: Maybe<Auth_Oauth_Access_Tokens_Mutation_Response>;
  /** update single row of the table: "oauth_access_tokens" */
  update_auth_oauth_access_tokens_by_pk?: Maybe<Auth_Oauth_Access_Tokens>;
  /** update multiples rows of table: "oauth_access_tokens" */
  update_auth_oauth_access_tokens_many?: Maybe<Array<Maybe<Auth_Oauth_Access_Tokens_Mutation_Response>>>;
  /** update data of the table: "test_users" */
  update_auth_test_users?: Maybe<Auth_Test_Users_Mutation_Response>;
  /** update single row of the table: "test_users" */
  update_auth_test_users_by_pk?: Maybe<Auth_Test_Users>;
  /** update multiples rows of table: "test_users" */
  update_auth_test_users_many?: Maybe<Array<Maybe<Auth_Test_Users_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_auth_users?: Maybe<Auth_Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_auth_users_by_pk?: Maybe<Auth_Users>;
  /** update multiples rows of table: "users" */
  update_auth_users_many?: Maybe<Array<Maybe<Auth_Users_Mutation_Response>>>;
  /** update data of the table: "verification_emails" */
  update_auth_verification_emails?: Maybe<Auth_Verification_Emails_Mutation_Response>;
  /** update single row of the table: "verification_emails" */
  update_auth_verification_emails_by_pk?: Maybe<Auth_Verification_Emails>;
  /** update multiples rows of table: "verification_emails" */
  update_auth_verification_emails_many?: Maybe<Array<Maybe<Auth_Verification_Emails_Mutation_Response>>>;
  /** update data of the table: "verification_phones" */
  update_auth_verification_phones?: Maybe<Auth_Verification_Phones_Mutation_Response>;
  /** update single row of the table: "verification_phones" */
  update_auth_verification_phones_by_pk?: Maybe<Auth_Verification_Phones>;
  /** update multiples rows of table: "verification_phones" */
  update_auth_verification_phones_many?: Maybe<Array<Maybe<Auth_Verification_Phones_Mutation_Response>>>;
  /** update data of the table: "order_polices" */
  update_billing_order_polices?: Maybe<Billing_Order_Polices_Mutation_Response>;
  /** update single row of the table: "order_polices" */
  update_billing_order_polices_by_pk?: Maybe<Billing_Order_Polices>;
  /** update multiples rows of table: "order_polices" */
  update_billing_order_polices_many?: Maybe<Array<Maybe<Billing_Order_Polices_Mutation_Response>>>;
  /** update data of the table: "billing_order_statuses" */
  update_billing_order_statuses?: Maybe<Billing_Order_Statuses_Mutation_Response>;
  /** update single row of the table: "billing_order_statuses" */
  update_billing_order_statuses_by_pk?: Maybe<Billing_Order_Statuses>;
  /** update multiples rows of table: "billing_order_statuses" */
  update_billing_order_statuses_many?: Maybe<Array<Maybe<Billing_Order_Statuses_Mutation_Response>>>;
  /** update data of the table: "promo_codes" */
  update_billing_promo_codes?: Maybe<Billing_Promo_Codes_Mutation_Response>;
  /** update single row of the table: "promo_codes" */
  update_billing_promo_codes_by_pk?: Maybe<Billing_Promo_Codes>;
  /** update multiples rows of table: "promo_codes" */
  update_billing_promo_codes_many?: Maybe<Array<Maybe<Billing_Promo_Codes_Mutation_Response>>>;
  /** update data of the table: "cities" */
  update_dms_cities?: Maybe<Dms_Cities_Mutation_Response>;
  /** update single row of the table: "cities" */
  update_dms_cities_by_pk?: Maybe<Dms_Cities>;
  /** update multiples rows of table: "cities" */
  update_dms_cities_many?: Maybe<Array<Maybe<Dms_Cities_Mutation_Response>>>;
  /** update data of the table: "group_insureds" */
  update_dms_group_insureds?: Maybe<Dms_Group_Insureds_Mutation_Response>;
  /** update single row of the table: "group_insureds" */
  update_dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** update multiples rows of table: "group_insureds" */
  update_dms_group_insureds_many?: Maybe<Array<Maybe<Dms_Group_Insureds_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_dms_groups?: Maybe<Dms_Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** update multiples rows of table: "groups" */
  update_dms_groups_many?: Maybe<Array<Maybe<Dms_Groups_Mutation_Response>>>;
  /** update data of the table: "insurants" */
  update_dms_insurants?: Maybe<Dms_Insurants_Mutation_Response>;
  /** update single row of the table: "insurants" */
  update_dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** update multiples rows of table: "insurants" */
  update_dms_insurants_many?: Maybe<Array<Maybe<Dms_Insurants_Mutation_Response>>>;
  /** update data of the table: "insureds" */
  update_dms_insureds?: Maybe<Dms_Insureds_Mutation_Response>;
  /** update single row of the table: "insureds" */
  update_dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** update multiples rows of table: "insureds" */
  update_dms_insureds_many?: Maybe<Array<Maybe<Dms_Insureds_Mutation_Response>>>;
  /** update data of the table: "order_links" */
  update_dms_order_links?: Maybe<Dms_Order_Links_Mutation_Response>;
  /** update single row of the table: "order_links" */
  update_dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** update multiples rows of table: "order_links" */
  update_dms_order_links_many?: Maybe<Array<Maybe<Dms_Order_Links_Mutation_Response>>>;
  /** update data of the table: "order_numbers" */
  update_dms_order_numbers?: Maybe<Dms_Order_Numbers_Mutation_Response>;
  /** update single row of the table: "order_numbers" */
  update_dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** update multiples rows of table: "order_numbers" */
  update_dms_order_numbers_many?: Maybe<Array<Maybe<Dms_Order_Numbers_Mutation_Response>>>;
  /** update data of the table: "orders" */
  update_dms_orders?: Maybe<Dms_Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** update multiples rows of table: "orders" */
  update_dms_orders_many?: Maybe<Array<Maybe<Dms_Orders_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_dms_products?: Maybe<Dms_Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_dms_products_by_pk?: Maybe<Dms_Products>;
  /** update multiples rows of table: "products" */
  update_dms_products_many?: Maybe<Array<Maybe<Dms_Products_Mutation_Response>>>;
  /** update data of the table: "promo_codes" */
  update_dms_promo_codes?: Maybe<Dms_Promo_Codes_Mutation_Response>;
  /** update single row of the table: "promo_codes" */
  update_dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** update multiples rows of table: "promo_codes" */
  update_dms_promo_codes_many?: Maybe<Array<Maybe<Dms_Promo_Codes_Mutation_Response>>>;
  /** update data of the table: "statuses" */
  update_dms_statuses?: Maybe<Dms_Statuses_Mutation_Response>;
  /** update single row of the table: "statuses" */
  update_dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** update multiples rows of table: "statuses" */
  update_dms_statuses_many?: Maybe<Array<Maybe<Dms_Statuses_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_dms_users?: Maybe<Dms_Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_dms_users_by_pk?: Maybe<Dms_Users>;
  /** update multiples rows of table: "users" */
  update_dms_users_many?: Maybe<Array<Maybe<Dms_Users_Mutation_Response>>>;
  /** update data of the table: "cards" */
  update_fintech_cards?: Maybe<Fintech_Cards_Mutation_Response>;
  /** update single row of the table: "cards" */
  update_fintech_cards_by_pk?: Maybe<Fintech_Cards>;
  /** update multiples rows of table: "cards" */
  update_fintech_cards_many?: Maybe<Array<Maybe<Fintech_Cards_Mutation_Response>>>;
  /** update data of the table: "clients" */
  update_fintech_clients?: Maybe<Fintech_Clients_Mutation_Response>;
  /** update single row of the table: "clients" */
  update_fintech_clients_by_pk?: Maybe<Fintech_Clients>;
  /** update multiples rows of table: "clients" */
  update_fintech_clients_many?: Maybe<Array<Maybe<Fintech_Clients_Mutation_Response>>>;
  /** update data of the table: "orders" */
  update_fintech_orders?: Maybe<Fintech_Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_fintech_orders_by_pk?: Maybe<Fintech_Orders>;
  /** update multiples rows of table: "orders" */
  update_fintech_orders_many?: Maybe<Array<Maybe<Fintech_Orders_Mutation_Response>>>;
  /** update data of the table: "amocrm_contacts" */
  update_mobile_amocrm_contacts?: Maybe<Mobile_Amocrm_Contacts_Mutation_Response>;
  /** update single row of the table: "amocrm_contacts" */
  update_mobile_amocrm_contacts_by_pk?: Maybe<Mobile_Amocrm_Contacts>;
  /** update multiples rows of table: "amocrm_contacts" */
  update_mobile_amocrm_contacts_many?: Maybe<Array<Maybe<Mobile_Amocrm_Contacts_Mutation_Response>>>;
  /** update data of the table: "amocrm_leads" */
  update_mobile_amocrm_leads?: Maybe<Mobile_Amocrm_Leads_Mutation_Response>;
  /** update single row of the table: "amocrm_leads" */
  update_mobile_amocrm_leads_by_pk?: Maybe<Mobile_Amocrm_Leads>;
  /** update multiples rows of table: "amocrm_leads" */
  update_mobile_amocrm_leads_many?: Maybe<Array<Maybe<Mobile_Amocrm_Leads_Mutation_Response>>>;
  /** update data of the table: "amocrm_tags" */
  update_mobile_amocrm_tags?: Maybe<Mobile_Amocrm_Tags_Mutation_Response>;
  /** update single row of the table: "amocrm_tags" */
  update_mobile_amocrm_tags_by_pk?: Maybe<Mobile_Amocrm_Tags>;
  /** update multiples rows of table: "amocrm_tags" */
  update_mobile_amocrm_tags_many?: Maybe<Array<Maybe<Mobile_Amocrm_Tags_Mutation_Response>>>;
  /** update data of the table: "appointments" */
  update_mobile_appointments?: Maybe<Mobile_Appointments_Mutation_Response>;
  /** update single row of the table: "appointments" */
  update_mobile_appointments_by_pk?: Maybe<Mobile_Appointments>;
  /** update multiples rows of table: "appointments" */
  update_mobile_appointments_many?: Maybe<Array<Maybe<Mobile_Appointments_Mutation_Response>>>;
  /** update data of the table: "auto_replies" */
  update_mobile_auto_replies?: Maybe<Mobile_Auto_Replies_Mutation_Response>;
  /** update single row of the table: "auto_replies" */
  update_mobile_auto_replies_by_pk?: Maybe<Mobile_Auto_Replies>;
  /** update multiples rows of table: "auto_replies" */
  update_mobile_auto_replies_many?: Maybe<Array<Maybe<Mobile_Auto_Replies_Mutation_Response>>>;
  /** update data of the table: "auto_reply_documents" */
  update_mobile_auto_reply_documents?: Maybe<Mobile_Auto_Reply_Documents_Mutation_Response>;
  /** update single row of the table: "auto_reply_documents" */
  update_mobile_auto_reply_documents_by_pk?: Maybe<Mobile_Auto_Reply_Documents>;
  /** update multiples rows of table: "auto_reply_documents" */
  update_mobile_auto_reply_documents_many?: Maybe<Array<Maybe<Mobile_Auto_Reply_Documents_Mutation_Response>>>;
  /** update data of the table: "auto_reply_mediafiles" */
  update_mobile_auto_reply_mediafiles?: Maybe<Mobile_Auto_Reply_Mediafiles_Mutation_Response>;
  /** update single row of the table: "auto_reply_mediafiles" */
  update_mobile_auto_reply_mediafiles_by_pk?: Maybe<Mobile_Auto_Reply_Mediafiles>;
  /** update multiples rows of table: "auto_reply_mediafiles" */
  update_mobile_auto_reply_mediafiles_many?: Maybe<Array<Maybe<Mobile_Auto_Reply_Mediafiles_Mutation_Response>>>;
  /** update data of the table: "bank_accounts" */
  update_mobile_bank_accounts?: Maybe<Mobile_Bank_Accounts_Mutation_Response>;
  /** update single row of the table: "bank_accounts" */
  update_mobile_bank_accounts_by_pk?: Maybe<Mobile_Bank_Accounts>;
  /** update multiples rows of table: "bank_accounts" */
  update_mobile_bank_accounts_many?: Maybe<Array<Maybe<Mobile_Bank_Accounts_Mutation_Response>>>;
  /** update data of the table: "blocked_sites" */
  update_mobile_blocked_sites?: Maybe<Mobile_Blocked_Sites_Mutation_Response>;
  /** update single row of the table: "blocked_sites" */
  update_mobile_blocked_sites_by_pk?: Maybe<Mobile_Blocked_Sites>;
  /** update multiples rows of table: "blocked_sites" */
  update_mobile_blocked_sites_many?: Maybe<Array<Maybe<Mobile_Blocked_Sites_Mutation_Response>>>;
  /** update data of the table: "blockeds" */
  update_mobile_blockeds?: Maybe<Mobile_Blockeds_Mutation_Response>;
  /** update single row of the table: "blockeds" */
  update_mobile_blockeds_by_pk?: Maybe<Mobile_Blockeds>;
  /** update multiples rows of table: "blockeds" */
  update_mobile_blockeds_many?: Maybe<Array<Maybe<Mobile_Blockeds_Mutation_Response>>>;
  /** update data of the table: "calorie_norms" */
  update_mobile_calorie_norms?: Maybe<Mobile_Calorie_Norms_Mutation_Response>;
  /** update single row of the table: "calorie_norms" */
  update_mobile_calorie_norms_by_pk?: Maybe<Mobile_Calorie_Norms>;
  /** update multiples rows of table: "calorie_norms" */
  update_mobile_calorie_norms_many?: Maybe<Array<Maybe<Mobile_Calorie_Norms_Mutation_Response>>>;
  /** update data of the table: "calorie_nows" */
  update_mobile_calorie_nows?: Maybe<Mobile_Calorie_Nows_Mutation_Response>;
  /** update single row of the table: "calorie_nows" */
  update_mobile_calorie_nows_by_pk?: Maybe<Mobile_Calorie_Nows>;
  /** update multiples rows of table: "calorie_nows" */
  update_mobile_calorie_nows_many?: Maybe<Array<Maybe<Mobile_Calorie_Nows_Mutation_Response>>>;
  /** update data of the table: "calories" */
  update_mobile_calories?: Maybe<Mobile_Calories_Mutation_Response>;
  /** update single row of the table: "calories" */
  update_mobile_calories_by_pk?: Maybe<Mobile_Calories>;
  /** update multiples rows of table: "calories" */
  update_mobile_calories_many?: Maybe<Array<Maybe<Mobile_Calories_Mutation_Response>>>;
  /** update data of the table: "categories" */
  update_mobile_categories?: Maybe<Mobile_Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_mobile_categories_by_pk?: Maybe<Mobile_Categories>;
  /** update multiples rows of table: "categories" */
  update_mobile_categories_many?: Maybe<Array<Maybe<Mobile_Categories_Mutation_Response>>>;
  /** update data of the table: "category_names" */
  update_mobile_category_names?: Maybe<Mobile_Category_Names_Mutation_Response>;
  /** update single row of the table: "category_names" */
  update_mobile_category_names_by_pk?: Maybe<Mobile_Category_Names>;
  /** update multiples rows of table: "category_names" */
  update_mobile_category_names_many?: Maybe<Array<Maybe<Mobile_Category_Names_Mutation_Response>>>;
  /** update data of the table: "chat_black_lists" */
  update_mobile_chat_black_lists?: Maybe<Mobile_Chat_Black_Lists_Mutation_Response>;
  /** update single row of the table: "chat_black_lists" */
  update_mobile_chat_black_lists_by_pk?: Maybe<Mobile_Chat_Black_Lists>;
  /** update multiples rows of table: "chat_black_lists" */
  update_mobile_chat_black_lists_many?: Maybe<Array<Maybe<Mobile_Chat_Black_Lists_Mutation_Response>>>;
  /** update data of the table: "chat_defaults" */
  update_mobile_chat_defaults?: Maybe<Mobile_Chat_Defaults_Mutation_Response>;
  /** update single row of the table: "chat_defaults" */
  update_mobile_chat_defaults_by_pk?: Maybe<Mobile_Chat_Defaults>;
  /** update multiples rows of table: "chat_defaults" */
  update_mobile_chat_defaults_many?: Maybe<Array<Maybe<Mobile_Chat_Defaults_Mutation_Response>>>;
  /** update data of the table: "chat_file_types" */
  update_mobile_chat_file_types?: Maybe<Mobile_Chat_File_Types_Mutation_Response>;
  /** update single row of the table: "chat_file_types" */
  update_mobile_chat_file_types_by_pk?: Maybe<Mobile_Chat_File_Types>;
  /** update multiples rows of table: "chat_file_types" */
  update_mobile_chat_file_types_many?: Maybe<Array<Maybe<Mobile_Chat_File_Types_Mutation_Response>>>;
  /** update data of the table: "chat_message_comments" */
  update_mobile_chat_message_comments?: Maybe<Mobile_Chat_Message_Comments_Mutation_Response>;
  /** update single row of the table: "chat_message_comments" */
  update_mobile_chat_message_comments_by_pk?: Maybe<Mobile_Chat_Message_Comments>;
  /** update multiples rows of table: "chat_message_comments" */
  update_mobile_chat_message_comments_many?: Maybe<Array<Maybe<Mobile_Chat_Message_Comments_Mutation_Response>>>;
  /** update data of the table: "chat_message_files" */
  update_mobile_chat_message_files?: Maybe<Mobile_Chat_Message_Files_Mutation_Response>;
  /** update single row of the table: "chat_message_files" */
  update_mobile_chat_message_files_by_pk?: Maybe<Mobile_Chat_Message_Files>;
  /** update multiples rows of table: "chat_message_files" */
  update_mobile_chat_message_files_many?: Maybe<Array<Maybe<Mobile_Chat_Message_Files_Mutation_Response>>>;
  /** update data of the table: "chat_message_notifications" */
  update_mobile_chat_message_notifications?: Maybe<Mobile_Chat_Message_Notifications_Mutation_Response>;
  /** update multiples rows of table: "chat_message_notifications" */
  update_mobile_chat_message_notifications_many?: Maybe<Array<Maybe<Mobile_Chat_Message_Notifications_Mutation_Response>>>;
  /** update data of the table: "chat_message_types" */
  update_mobile_chat_message_types?: Maybe<Mobile_Chat_Message_Types_Mutation_Response>;
  /** update single row of the table: "chat_message_types" */
  update_mobile_chat_message_types_by_pk?: Maybe<Mobile_Chat_Message_Types>;
  /** update multiples rows of table: "chat_message_types" */
  update_mobile_chat_message_types_many?: Maybe<Array<Maybe<Mobile_Chat_Message_Types_Mutation_Response>>>;
  /** update data of the table: "chat_messages" */
  update_mobile_chat_messages?: Maybe<Mobile_Chat_Messages_Mutation_Response>;
  /** update single row of the table: "chat_messages" */
  update_mobile_chat_messages_by_pk?: Maybe<Mobile_Chat_Messages>;
  /** update multiples rows of table: "chat_messages" */
  update_mobile_chat_messages_many?: Maybe<Array<Maybe<Mobile_Chat_Messages_Mutation_Response>>>;
  /** update data of the table: "chat_roles" */
  update_mobile_chat_roles?: Maybe<Mobile_Chat_Roles_Mutation_Response>;
  /** update single row of the table: "chat_roles" */
  update_mobile_chat_roles_by_pk?: Maybe<Mobile_Chat_Roles>;
  /** update multiples rows of table: "chat_roles" */
  update_mobile_chat_roles_many?: Maybe<Array<Maybe<Mobile_Chat_Roles_Mutation_Response>>>;
  /** update data of the table: "chat_types" */
  update_mobile_chat_types?: Maybe<Mobile_Chat_Types_Mutation_Response>;
  /** update single row of the table: "chat_types" */
  update_mobile_chat_types_by_pk?: Maybe<Mobile_Chat_Types>;
  /** update multiples rows of table: "chat_types" */
  update_mobile_chat_types_many?: Maybe<Array<Maybe<Mobile_Chat_Types_Mutation_Response>>>;
  /** update data of the table: "chats" */
  update_mobile_chats?: Maybe<Mobile_Chats_Mutation_Response>;
  /** update single row of the table: "chats" */
  update_mobile_chats_by_pk?: Maybe<Mobile_Chats>;
  /** update multiples rows of table: "chats" */
  update_mobile_chats_many?: Maybe<Array<Maybe<Mobile_Chats_Mutation_Response>>>;
  /** update data of the table: "clients" */
  update_mobile_clients?: Maybe<Mobile_Clients_Mutation_Response>;
  /** update single row of the table: "clients" */
  update_mobile_clients_by_pk?: Maybe<Mobile_Clients>;
  /** update multiples rows of table: "clients" */
  update_mobile_clients_many?: Maybe<Array<Maybe<Mobile_Clients_Mutation_Response>>>;
  /** update data of the table: "comment_like_notifications" */
  update_mobile_comment_like_notifications?: Maybe<Mobile_Comment_Like_Notifications_Mutation_Response>;
  /** update multiples rows of table: "comment_like_notifications" */
  update_mobile_comment_like_notifications_many?: Maybe<Array<Maybe<Mobile_Comment_Like_Notifications_Mutation_Response>>>;
  /** update data of the table: "comment_likes" */
  update_mobile_comment_likes?: Maybe<Mobile_Comment_Likes_Mutation_Response>;
  /** update single row of the table: "comment_likes" */
  update_mobile_comment_likes_by_pk?: Maybe<Mobile_Comment_Likes>;
  /** update multiples rows of table: "comment_likes" */
  update_mobile_comment_likes_many?: Maybe<Array<Maybe<Mobile_Comment_Likes_Mutation_Response>>>;
  /** update data of the table: "comment_notifications" */
  update_mobile_comment_notifications?: Maybe<Mobile_Comment_Notifications_Mutation_Response>;
  /** update multiples rows of table: "comment_notifications" */
  update_mobile_comment_notifications_many?: Maybe<Array<Maybe<Mobile_Comment_Notifications_Mutation_Response>>>;
  /** update data of the table: "comments" */
  update_mobile_comments?: Maybe<Mobile_Comments_Mutation_Response>;
  /** update single row of the table: "comments" */
  update_mobile_comments_by_pk?: Maybe<Mobile_Comments>;
  /** update multiples rows of table: "comments" */
  update_mobile_comments_many?: Maybe<Array<Maybe<Mobile_Comments_Mutation_Response>>>;
  /** update data of the table: "confirmation_codes" */
  update_mobile_confirmation_codes?: Maybe<Mobile_Confirmation_Codes_Mutation_Response>;
  /** update single row of the table: "confirmation_codes" */
  update_mobile_confirmation_codes_by_pk?: Maybe<Mobile_Confirmation_Codes>;
  /** update multiples rows of table: "confirmation_codes" */
  update_mobile_confirmation_codes_many?: Maybe<Array<Maybe<Mobile_Confirmation_Codes_Mutation_Response>>>;
  /** update data of the table: "contacts" */
  update_mobile_contacts?: Maybe<Mobile_Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_mobile_contacts_by_pk?: Maybe<Mobile_Contacts>;
  /** update multiples rows of table: "contacts" */
  update_mobile_contacts_many?: Maybe<Array<Maybe<Mobile_Contacts_Mutation_Response>>>;
  /** update data of the table: "contents" */
  update_mobile_contents?: Maybe<Mobile_Contents_Mutation_Response>;
  /** update single row of the table: "contents" */
  update_mobile_contents_by_pk?: Maybe<Mobile_Contents>;
  /** update multiples rows of table: "contents" */
  update_mobile_contents_many?: Maybe<Array<Maybe<Mobile_Contents_Mutation_Response>>>;
  /** update data of the table: "device_infos" */
  update_mobile_device_infos?: Maybe<Mobile_Device_Infos_Mutation_Response>;
  /** update single row of the table: "device_infos" */
  update_mobile_device_infos_by_pk?: Maybe<Mobile_Device_Infos>;
  /** update multiples rows of table: "device_infos" */
  update_mobile_device_infos_many?: Maybe<Array<Maybe<Mobile_Device_Infos_Mutation_Response>>>;
  /** update data of the table: "device_settings" */
  update_mobile_device_settings?: Maybe<Mobile_Device_Settings_Mutation_Response>;
  /** update single row of the table: "device_settings" */
  update_mobile_device_settings_by_pk?: Maybe<Mobile_Device_Settings>;
  /** update multiples rows of table: "device_settings" */
  update_mobile_device_settings_many?: Maybe<Array<Maybe<Mobile_Device_Settings_Mutation_Response>>>;
  /** update data of the table: "devices" */
  update_mobile_devices?: Maybe<Mobile_Devices_Mutation_Response>;
  /** update single row of the table: "devices" */
  update_mobile_devices_by_pk?: Maybe<Mobile_Devices>;
  /** update multiples rows of table: "devices" */
  update_mobile_devices_many?: Maybe<Array<Maybe<Mobile_Devices_Mutation_Response>>>;
  /** update data of the table: "document_mediafiles" */
  update_mobile_document_mediafiles?: Maybe<Mobile_Document_Mediafiles_Mutation_Response>;
  /** update single row of the table: "document_mediafiles" */
  update_mobile_document_mediafiles_by_pk?: Maybe<Mobile_Document_Mediafiles>;
  /** update multiples rows of table: "document_mediafiles" */
  update_mobile_document_mediafiles_many?: Maybe<Array<Maybe<Mobile_Document_Mediafiles_Mutation_Response>>>;
  /** update data of the table: "documents" */
  update_mobile_documents?: Maybe<Mobile_Documents_Mutation_Response>;
  /** update single row of the table: "documents" */
  update_mobile_documents_by_pk?: Maybe<Mobile_Documents>;
  /** update multiples rows of table: "documents" */
  update_mobile_documents_many?: Maybe<Array<Maybe<Mobile_Documents_Mutation_Response>>>;
  /** update data of the table: "education_mediafiles" */
  update_mobile_education_mediafiles?: Maybe<Mobile_Education_Mediafiles_Mutation_Response>;
  /** update single row of the table: "education_mediafiles" */
  update_mobile_education_mediafiles_by_pk?: Maybe<Mobile_Education_Mediafiles>;
  /** update multiples rows of table: "education_mediafiles" */
  update_mobile_education_mediafiles_many?: Maybe<Array<Maybe<Mobile_Education_Mediafiles_Mutation_Response>>>;
  /** update data of the table: "educations" */
  update_mobile_educations?: Maybe<Mobile_Educations_Mutation_Response>;
  /** update single row of the table: "educations" */
  update_mobile_educations_by_pk?: Maybe<Mobile_Educations>;
  /** update multiples rows of table: "educations" */
  update_mobile_educations_many?: Maybe<Array<Maybe<Mobile_Educations_Mutation_Response>>>;
  /** update data of the table: "emojis" */
  update_mobile_emojis?: Maybe<Mobile_Emojis_Mutation_Response>;
  /** update single row of the table: "emojis" */
  update_mobile_emojis_by_pk?: Maybe<Mobile_Emojis>;
  /** update multiples rows of table: "emojis" */
  update_mobile_emojis_many?: Maybe<Array<Maybe<Mobile_Emojis_Mutation_Response>>>;
  /** update data of the table: "fixed_chat_messages" */
  update_mobile_fixed_chat_messages?: Maybe<Mobile_Fixed_Chat_Messages_Mutation_Response>;
  /** update single row of the table: "fixed_chat_messages" */
  update_mobile_fixed_chat_messages_by_pk?: Maybe<Mobile_Fixed_Chat_Messages>;
  /** update multiples rows of table: "fixed_chat_messages" */
  update_mobile_fixed_chat_messages_many?: Maybe<Array<Maybe<Mobile_Fixed_Chat_Messages_Mutation_Response>>>;
  /** update data of the table: "fixed_comments" */
  update_mobile_fixed_comments?: Maybe<Mobile_Fixed_Comments_Mutation_Response>;
  /** update single row of the table: "fixed_comments" */
  update_mobile_fixed_comments_by_pk?: Maybe<Mobile_Fixed_Comments>;
  /** update multiples rows of table: "fixed_comments" */
  update_mobile_fixed_comments_many?: Maybe<Array<Maybe<Mobile_Fixed_Comments_Mutation_Response>>>;
  /** update data of the table: "founder_mediafiles" */
  update_mobile_founder_mediafiles?: Maybe<Mobile_Founder_Mediafiles_Mutation_Response>;
  /** update single row of the table: "founder_mediafiles" */
  update_mobile_founder_mediafiles_by_pk?: Maybe<Mobile_Founder_Mediafiles>;
  /** update multiples rows of table: "founder_mediafiles" */
  update_mobile_founder_mediafiles_many?: Maybe<Array<Maybe<Mobile_Founder_Mediafiles_Mutation_Response>>>;
  /** update data of the table: "founders" */
  update_mobile_founders?: Maybe<Mobile_Founders_Mutation_Response>;
  /** update single row of the table: "founders" */
  update_mobile_founders_by_pk?: Maybe<Mobile_Founders>;
  /** update multiples rows of table: "founders" */
  update_mobile_founders_many?: Maybe<Array<Maybe<Mobile_Founders_Mutation_Response>>>;
  /** update data of the table: "invite_emails" */
  update_mobile_invite_emails?: Maybe<Mobile_Invite_Emails_Mutation_Response>;
  /** update single row of the table: "invite_emails" */
  update_mobile_invite_emails_by_pk?: Maybe<Mobile_Invite_Emails>;
  /** update multiples rows of table: "invite_emails" */
  update_mobile_invite_emails_many?: Maybe<Array<Maybe<Mobile_Invite_Emails_Mutation_Response>>>;
  /** update data of the table: "like_notifications" */
  update_mobile_like_notifications?: Maybe<Mobile_Like_Notifications_Mutation_Response>;
  /** update multiples rows of table: "like_notifications" */
  update_mobile_like_notifications_many?: Maybe<Array<Maybe<Mobile_Like_Notifications_Mutation_Response>>>;
  /** update data of the table: "likes" */
  update_mobile_likes?: Maybe<Mobile_Likes_Mutation_Response>;
  /** update single row of the table: "likes" */
  update_mobile_likes_by_pk?: Maybe<Mobile_Likes>;
  /** update multiples rows of table: "likes" */
  update_mobile_likes_many?: Maybe<Array<Maybe<Mobile_Likes_Mutation_Response>>>;
  /** update data of the table: "link_snippets" */
  update_mobile_link_snippets?: Maybe<Mobile_Link_Snippets_Mutation_Response>;
  /** update single row of the table: "link_snippets" */
  update_mobile_link_snippets_by_pk?: Maybe<Mobile_Link_Snippets>;
  /** update multiples rows of table: "link_snippets" */
  update_mobile_link_snippets_many?: Maybe<Array<Maybe<Mobile_Link_Snippets_Mutation_Response>>>;
  /** update data of the table: "locked_deleted_chat_messages" */
  update_mobile_locked_deleted_chat_messages?: Maybe<Mobile_Locked_Deleted_Chat_Messages_Mutation_Response>;
  /** update multiples rows of table: "locked_deleted_chat_messages" */
  update_mobile_locked_deleted_chat_messages_many?: Maybe<Array<Maybe<Mobile_Locked_Deleted_Chat_Messages_Mutation_Response>>>;
  /** update data of the table: "mediafile_status_names" */
  update_mobile_mediafile_status_names?: Maybe<Mobile_Mediafile_Status_Names_Mutation_Response>;
  /** update single row of the table: "mediafile_status_names" */
  update_mobile_mediafile_status_names_by_pk?: Maybe<Mobile_Mediafile_Status_Names>;
  /** update multiples rows of table: "mediafile_status_names" */
  update_mobile_mediafile_status_names_many?: Maybe<Array<Maybe<Mobile_Mediafile_Status_Names_Mutation_Response>>>;
  /** update data of the table: "mediafile_statuses" */
  update_mobile_mediafile_statuses?: Maybe<Mobile_Mediafile_Statuses_Mutation_Response>;
  /** update single row of the table: "mediafile_statuses" */
  update_mobile_mediafile_statuses_by_pk?: Maybe<Mobile_Mediafile_Statuses>;
  /** update multiples rows of table: "mediafile_statuses" */
  update_mobile_mediafile_statuses_many?: Maybe<Array<Maybe<Mobile_Mediafile_Statuses_Mutation_Response>>>;
  /** update data of the table: "medical_concierge_orders" */
  update_mobile_medical_concierge_orders?: Maybe<Mobile_Medical_Concierge_Orders_Mutation_Response>;
  /** update single row of the table: "medical_concierge_orders" */
  update_mobile_medical_concierge_orders_by_pk?: Maybe<Mobile_Medical_Concierge_Orders>;
  /** update multiples rows of table: "medical_concierge_orders" */
  update_mobile_medical_concierge_orders_many?: Maybe<Array<Maybe<Mobile_Medical_Concierge_Orders_Mutation_Response>>>;
  /** update data of the table: "medical_concierge_products" */
  update_mobile_medical_concierge_products?: Maybe<Mobile_Medical_Concierge_Products_Mutation_Response>;
  /** update single row of the table: "medical_concierge_products" */
  update_mobile_medical_concierge_products_by_pk?: Maybe<Mobile_Medical_Concierge_Products>;
  /** update multiples rows of table: "medical_concierge_products" */
  update_mobile_medical_concierge_products_many?: Maybe<Array<Maybe<Mobile_Medical_Concierge_Products_Mutation_Response>>>;
  /** update data of the table: "mention_notifications" */
  update_mobile_mention_notifications?: Maybe<Mobile_Mention_Notifications_Mutation_Response>;
  /** update multiples rows of table: "mention_notifications" */
  update_mobile_mention_notifications_many?: Maybe<Array<Maybe<Mobile_Mention_Notifications_Mutation_Response>>>;
  /** update data of the table: "mentions" */
  update_mobile_mentions?: Maybe<Mobile_Mentions_Mutation_Response>;
  /** update single row of the table: "mentions" */
  update_mobile_mentions_by_pk?: Maybe<Mobile_Mentions>;
  /** update multiples rows of table: "mentions" */
  update_mobile_mentions_many?: Maybe<Array<Maybe<Mobile_Mentions_Mutation_Response>>>;
  /** update data of the table: "metric_names" */
  update_mobile_metric_names?: Maybe<Mobile_Metric_Names_Mutation_Response>;
  /** update single row of the table: "metric_names" */
  update_mobile_metric_names_by_pk?: Maybe<Mobile_Metric_Names>;
  /** update multiples rows of table: "metric_names" */
  update_mobile_metric_names_many?: Maybe<Array<Maybe<Mobile_Metric_Names_Mutation_Response>>>;
  /** update data of the table: "metric_params" */
  update_mobile_metric_params?: Maybe<Mobile_Metric_Params_Mutation_Response>;
  /** update single row of the table: "metric_params" */
  update_mobile_metric_params_by_pk?: Maybe<Mobile_Metric_Params>;
  /** update multiples rows of table: "metric_params" */
  update_mobile_metric_params_many?: Maybe<Array<Maybe<Mobile_Metric_Params_Mutation_Response>>>;
  /** update data of the table: "metric_types" */
  update_mobile_metric_types?: Maybe<Mobile_Metric_Types_Mutation_Response>;
  /** update single row of the table: "metric_types" */
  update_mobile_metric_types_by_pk?: Maybe<Mobile_Metric_Types>;
  /** update multiples rows of table: "metric_types" */
  update_mobile_metric_types_many?: Maybe<Array<Maybe<Mobile_Metric_Types_Mutation_Response>>>;
  /** update data of the table: "metrics" */
  update_mobile_metrics?: Maybe<Mobile_Metrics_Mutation_Response>;
  /** update single row of the table: "metrics" */
  update_mobile_metrics_by_pk?: Maybe<Mobile_Metrics>;
  /** update multiples rows of table: "metrics" */
  update_mobile_metrics_many?: Maybe<Array<Maybe<Mobile_Metrics_Mutation_Response>>>;
  /** update data of the table: "notification_messages" */
  update_mobile_notification_messages?: Maybe<Mobile_Notification_Messages_Mutation_Response>;
  /** update single row of the table: "notification_messages" */
  update_mobile_notification_messages_by_pk?: Maybe<Mobile_Notification_Messages>;
  /** update multiples rows of table: "notification_messages" */
  update_mobile_notification_messages_many?: Maybe<Array<Maybe<Mobile_Notification_Messages_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_mobile_notifications?: Maybe<Mobile_Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_mobile_notifications_by_pk?: Maybe<Mobile_Notifications>;
  /** update multiples rows of table: "notifications" */
  update_mobile_notifications_many?: Maybe<Array<Maybe<Mobile_Notifications_Mutation_Response>>>;
  /** update data of the table: "notifications_statuses" */
  update_mobile_notifications_statuses?: Maybe<Mobile_Notifications_Statuses_Mutation_Response>;
  /** update single row of the table: "notifications_statuses" */
  update_mobile_notifications_statuses_by_pk?: Maybe<Mobile_Notifications_Statuses>;
  /** update multiples rows of table: "notifications_statuses" */
  update_mobile_notifications_statuses_many?: Maybe<Array<Maybe<Mobile_Notifications_Statuses_Mutation_Response>>>;
  /** update data of the table: "order_notifications" */
  update_mobile_order_notifications?: Maybe<Mobile_Order_Notifications_Mutation_Response>;
  /** update multiples rows of table: "order_notifications" */
  update_mobile_order_notifications_many?: Maybe<Array<Maybe<Mobile_Order_Notifications_Mutation_Response>>>;
  /** update data of the table: "order_professionals" */
  update_mobile_order_professionals?: Maybe<Mobile_Order_Professionals_Mutation_Response>;
  /** update single row of the table: "order_professionals" */
  update_mobile_order_professionals_by_pk?: Maybe<Mobile_Order_Professionals>;
  /** update multiples rows of table: "order_professionals" */
  update_mobile_order_professionals_many?: Maybe<Array<Maybe<Mobile_Order_Professionals_Mutation_Response>>>;
  /** update data of the table: "orders" */
  update_mobile_orders?: Maybe<Mobile_Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_mobile_orders_by_pk?: Maybe<Mobile_Orders>;
  /** update multiples rows of table: "orders" */
  update_mobile_orders_many?: Maybe<Array<Maybe<Mobile_Orders_Mutation_Response>>>;
  /** update data of the table: "orgs" */
  update_mobile_orgs?: Maybe<Mobile_Orgs_Mutation_Response>;
  /** update single row of the table: "orgs" */
  update_mobile_orgs_by_pk?: Maybe<Mobile_Orgs>;
  /** update multiples rows of table: "orgs" */
  update_mobile_orgs_many?: Maybe<Array<Maybe<Mobile_Orgs_Mutation_Response>>>;
  /** update data of the table: "param_categories" */
  update_mobile_param_categories?: Maybe<Mobile_Param_Categories_Mutation_Response>;
  /** update single row of the table: "param_categories" */
  update_mobile_param_categories_by_pk?: Maybe<Mobile_Param_Categories>;
  /** update multiples rows of table: "param_categories" */
  update_mobile_param_categories_many?: Maybe<Array<Maybe<Mobile_Param_Categories_Mutation_Response>>>;
  /** update data of the table: "param_categories_params" */
  update_mobile_param_categories_params?: Maybe<Mobile_Param_Categories_Params_Mutation_Response>;
  /** update multiples rows of table: "param_categories_params" */
  update_mobile_param_categories_params_many?: Maybe<Array<Maybe<Mobile_Param_Categories_Params_Mutation_Response>>>;
  /** update data of the table: "param_category_names" */
  update_mobile_param_category_names?: Maybe<Mobile_Param_Category_Names_Mutation_Response>;
  /** update single row of the table: "param_category_names" */
  update_mobile_param_category_names_by_pk?: Maybe<Mobile_Param_Category_Names>;
  /** update multiples rows of table: "param_category_names" */
  update_mobile_param_category_names_many?: Maybe<Array<Maybe<Mobile_Param_Category_Names_Mutation_Response>>>;
  /** update data of the table: "param_names" */
  update_mobile_param_names?: Maybe<Mobile_Param_Names_Mutation_Response>;
  /** update single row of the table: "param_names" */
  update_mobile_param_names_by_pk?: Maybe<Mobile_Param_Names>;
  /** update multiples rows of table: "param_names" */
  update_mobile_param_names_many?: Maybe<Array<Maybe<Mobile_Param_Names_Mutation_Response>>>;
  /** update data of the table: "params" */
  update_mobile_params?: Maybe<Mobile_Params_Mutation_Response>;
  /** update single row of the table: "params" */
  update_mobile_params_by_pk?: Maybe<Mobile_Params>;
  /** update multiples rows of table: "params" */
  update_mobile_params_many?: Maybe<Array<Maybe<Mobile_Params_Mutation_Response>>>;
  /** update data of the table: "params_services" */
  update_mobile_params_services?: Maybe<Mobile_Params_Services_Mutation_Response>;
  /** update single row of the table: "params_services" */
  update_mobile_params_services_by_pk?: Maybe<Mobile_Params_Services>;
  /** update multiples rows of table: "params_services" */
  update_mobile_params_services_many?: Maybe<Array<Maybe<Mobile_Params_Services_Mutation_Response>>>;
  /** update data of the table: "personal_info_views" */
  update_mobile_personal_info_views?: Maybe<Mobile_Personal_Info_Views_Mutation_Response>;
  /** update single row of the table: "personal_info_views" */
  update_mobile_personal_info_views_by_pk?: Maybe<Mobile_Personal_Info_Views>;
  /** update multiples rows of table: "personal_info_views" */
  update_mobile_personal_info_views_many?: Maybe<Array<Maybe<Mobile_Personal_Info_Views_Mutation_Response>>>;
  /** update data of the table: "personal_infos" */
  update_mobile_personal_infos?: Maybe<Mobile_Personal_Infos_Mutation_Response>;
  /** update single row of the table: "personal_infos" */
  update_mobile_personal_infos_by_pk?: Maybe<Mobile_Personal_Infos>;
  /** update multiples rows of table: "personal_infos" */
  update_mobile_personal_infos_many?: Maybe<Array<Maybe<Mobile_Personal_Infos_Mutation_Response>>>;
  /** update data of the table: "phones" */
  update_mobile_phones?: Maybe<Mobile_Phones_Mutation_Response>;
  /** update single row of the table: "phones" */
  update_mobile_phones_by_pk?: Maybe<Mobile_Phones>;
  /** update multiples rows of table: "phones" */
  update_mobile_phones_many?: Maybe<Array<Maybe<Mobile_Phones_Mutation_Response>>>;
  /** update data of the table: "post_params" */
  update_mobile_post_params?: Maybe<Mobile_Post_Params_Mutation_Response>;
  /** update single row of the table: "post_params" */
  update_mobile_post_params_by_pk?: Maybe<Mobile_Post_Params>;
  /** update multiples rows of table: "post_params" */
  update_mobile_post_params_many?: Maybe<Array<Maybe<Mobile_Post_Params_Mutation_Response>>>;
  /** update data of the table: "posts" */
  update_mobile_posts?: Maybe<Mobile_Posts_Mutation_Response>;
  /** update single row of the table: "posts" */
  update_mobile_posts_by_pk?: Maybe<Mobile_Posts>;
  /** update multiples rows of table: "posts" */
  update_mobile_posts_many?: Maybe<Array<Maybe<Mobile_Posts_Mutation_Response>>>;
  /** update data of the table: "pro_document_statuses" */
  update_mobile_pro_document_statuses?: Maybe<Mobile_Pro_Document_Statuses_Mutation_Response>;
  /** update single row of the table: "pro_document_statuses" */
  update_mobile_pro_document_statuses_by_pk?: Maybe<Mobile_Pro_Document_Statuses>;
  /** update multiples rows of table: "pro_document_statuses" */
  update_mobile_pro_document_statuses_many?: Maybe<Array<Maybe<Mobile_Pro_Document_Statuses_Mutation_Response>>>;
  /** update data of the table: "pro_professions" */
  update_mobile_pro_professions?: Maybe<Mobile_Pro_Professions_Mutation_Response>;
  /** update single row of the table: "pro_professions" */
  update_mobile_pro_professions_by_pk?: Maybe<Mobile_Pro_Professions>;
  /** update multiples rows of table: "pro_professions" */
  update_mobile_pro_professions_many?: Maybe<Array<Maybe<Mobile_Pro_Professions_Mutation_Response>>>;
  /** update data of the table: "pro_types" */
  update_mobile_pro_types?: Maybe<Mobile_Pro_Types_Mutation_Response>;
  /** update single row of the table: "pro_types" */
  update_mobile_pro_types_by_pk?: Maybe<Mobile_Pro_Types>;
  /** update multiples rows of table: "pro_types" */
  update_mobile_pro_types_many?: Maybe<Array<Maybe<Mobile_Pro_Types_Mutation_Response>>>;
  /** update data of the table: "problems" */
  update_mobile_problems?: Maybe<Mobile_Problems_Mutation_Response>;
  /** update single row of the table: "problems" */
  update_mobile_problems_by_pk?: Maybe<Mobile_Problems>;
  /** update multiples rows of table: "problems" */
  update_mobile_problems_many?: Maybe<Array<Maybe<Mobile_Problems_Mutation_Response>>>;
  /** update data of the table: "profession_names" */
  update_mobile_profession_names?: Maybe<Mobile_Profession_Names_Mutation_Response>;
  /** update single row of the table: "profession_names" */
  update_mobile_profession_names_by_pk?: Maybe<Mobile_Profession_Names>;
  /** update multiples rows of table: "profession_names" */
  update_mobile_profession_names_many?: Maybe<Array<Maybe<Mobile_Profession_Names_Mutation_Response>>>;
  /** update data of the table: "professions" */
  update_mobile_professions?: Maybe<Mobile_Professions_Mutation_Response>;
  /** update single row of the table: "professions" */
  update_mobile_professions_by_pk?: Maybe<Mobile_Professions>;
  /** update multiples rows of table: "professions" */
  update_mobile_professions_many?: Maybe<Array<Maybe<Mobile_Professions_Mutation_Response>>>;
  /** update data of the table: "profile_deleteds" */
  update_mobile_profile_deleteds?: Maybe<Mobile_Profile_Deleteds_Mutation_Response>;
  /** update single row of the table: "profile_deleteds" */
  update_mobile_profile_deleteds_by_pk?: Maybe<Mobile_Profile_Deleteds>;
  /** update multiples rows of table: "profile_deleteds" */
  update_mobile_profile_deleteds_many?: Maybe<Array<Maybe<Mobile_Profile_Deleteds_Mutation_Response>>>;
  /** update data of the table: "profile_locks" */
  update_mobile_profile_locks?: Maybe<Mobile_Profile_Locks_Mutation_Response>;
  /** update single row of the table: "profile_locks" */
  update_mobile_profile_locks_by_pk?: Maybe<Mobile_Profile_Locks>;
  /** update multiples rows of table: "profile_locks" */
  update_mobile_profile_locks_many?: Maybe<Array<Maybe<Mobile_Profile_Locks_Mutation_Response>>>;
  /** update data of the table: "profiles" */
  update_mobile_profiles?: Maybe<Mobile_Profiles_Mutation_Response>;
  /** update single row of the table: "profiles" */
  update_mobile_profiles_by_pk?: Maybe<Mobile_Profiles>;
  /** update multiples rows of table: "profiles" */
  update_mobile_profiles_many?: Maybe<Array<Maybe<Mobile_Profiles_Mutation_Response>>>;
  /** update data of the table: "pros" */
  update_mobile_pros?: Maybe<Mobile_Pros_Mutation_Response>;
  /** update single row of the table: "pros" */
  update_mobile_pros_by_pk?: Maybe<Mobile_Pros>;
  /** update multiples rows of table: "pros" */
  update_mobile_pros_many?: Maybe<Array<Maybe<Mobile_Pros_Mutation_Response>>>;
  /** update data of the table: "pulse_norms" */
  update_mobile_pulse_norms?: Maybe<Mobile_Pulse_Norms_Mutation_Response>;
  /** update single row of the table: "pulse_norms" */
  update_mobile_pulse_norms_by_pk?: Maybe<Mobile_Pulse_Norms>;
  /** update multiples rows of table: "pulse_norms" */
  update_mobile_pulse_norms_many?: Maybe<Array<Maybe<Mobile_Pulse_Norms_Mutation_Response>>>;
  /** update data of the table: "pulse_nows" */
  update_mobile_pulse_nows?: Maybe<Mobile_Pulse_Nows_Mutation_Response>;
  /** update single row of the table: "pulse_nows" */
  update_mobile_pulse_nows_by_pk?: Maybe<Mobile_Pulse_Nows>;
  /** update multiples rows of table: "pulse_nows" */
  update_mobile_pulse_nows_many?: Maybe<Array<Maybe<Mobile_Pulse_Nows_Mutation_Response>>>;
  /** update data of the table: "pulses" */
  update_mobile_pulses?: Maybe<Mobile_Pulses_Mutation_Response>;
  /** update single row of the table: "pulses" */
  update_mobile_pulses_by_pk?: Maybe<Mobile_Pulses>;
  /** update multiples rows of table: "pulses" */
  update_mobile_pulses_many?: Maybe<Array<Maybe<Mobile_Pulses_Mutation_Response>>>;
  /** update data of the table: "reactions" */
  update_mobile_reactions?: Maybe<Mobile_Reactions_Mutation_Response>;
  /** update single row of the table: "reactions" */
  update_mobile_reactions_by_pk?: Maybe<Mobile_Reactions>;
  /** update multiples rows of table: "reactions" */
  update_mobile_reactions_many?: Maybe<Array<Maybe<Mobile_Reactions_Mutation_Response>>>;
  /** update data of the table: "reports" */
  update_mobile_reports?: Maybe<Mobile_Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  update_mobile_reports_by_pk?: Maybe<Mobile_Reports>;
  /** update multiples rows of table: "reports" */
  update_mobile_reports_many?: Maybe<Array<Maybe<Mobile_Reports_Mutation_Response>>>;
  /** update data of the table: "service_agreements" */
  update_mobile_service_agreements?: Maybe<Mobile_Service_Agreements_Mutation_Response>;
  /** update single row of the table: "service_agreements" */
  update_mobile_service_agreements_by_pk?: Maybe<Mobile_Service_Agreements>;
  /** update multiples rows of table: "service_agreements" */
  update_mobile_service_agreements_many?: Maybe<Array<Maybe<Mobile_Service_Agreements_Mutation_Response>>>;
  /** update data of the table: "service_answer_reactions" */
  update_mobile_service_answer_reactions?: Maybe<Mobile_Service_Answer_Reactions_Mutation_Response>;
  /** update single row of the table: "service_answer_reactions" */
  update_mobile_service_answer_reactions_by_pk?: Maybe<Mobile_Service_Answer_Reactions>;
  /** update multiples rows of table: "service_answer_reactions" */
  update_mobile_service_answer_reactions_many?: Maybe<Array<Maybe<Mobile_Service_Answer_Reactions_Mutation_Response>>>;
  /** update data of the table: "service_answers" */
  update_mobile_service_answers?: Maybe<Mobile_Service_Answers_Mutation_Response>;
  /** update single row of the table: "service_answers" */
  update_mobile_service_answers_by_pk?: Maybe<Mobile_Service_Answers>;
  /** update multiples rows of table: "service_answers" */
  update_mobile_service_answers_many?: Maybe<Array<Maybe<Mobile_Service_Answers_Mutation_Response>>>;
  /** update data of the table: "service_documents" */
  update_mobile_service_documents?: Maybe<Mobile_Service_Documents_Mutation_Response>;
  /** update single row of the table: "service_documents" */
  update_mobile_service_documents_by_pk?: Maybe<Mobile_Service_Documents>;
  /** update multiples rows of table: "service_documents" */
  update_mobile_service_documents_many?: Maybe<Array<Maybe<Mobile_Service_Documents_Mutation_Response>>>;
  /** update data of the table: "service_favorites" */
  update_mobile_service_favorites?: Maybe<Mobile_Service_Favorites_Mutation_Response>;
  /** update single row of the table: "service_favorites" */
  update_mobile_service_favorites_by_pk?: Maybe<Mobile_Service_Favorites>;
  /** update multiples rows of table: "service_favorites" */
  update_mobile_service_favorites_many?: Maybe<Array<Maybe<Mobile_Service_Favorites_Mutation_Response>>>;
  /** update data of the table: "service_free_purchases" */
  update_mobile_service_free_purchases?: Maybe<Mobile_Service_Free_Purchases_Mutation_Response>;
  /** update single row of the table: "service_free_purchases" */
  update_mobile_service_free_purchases_by_pk?: Maybe<Mobile_Service_Free_Purchases>;
  /** update multiples rows of table: "service_free_purchases" */
  update_mobile_service_free_purchases_many?: Maybe<Array<Maybe<Mobile_Service_Free_Purchases_Mutation_Response>>>;
  /** update data of the table: "service_images" */
  update_mobile_service_images?: Maybe<Mobile_Service_Images_Mutation_Response>;
  /** update single row of the table: "service_images" */
  update_mobile_service_images_by_pk?: Maybe<Mobile_Service_Images>;
  /** update multiples rows of table: "service_images" */
  update_mobile_service_images_many?: Maybe<Array<Maybe<Mobile_Service_Images_Mutation_Response>>>;
  /** update data of the table: "service_likes" */
  update_mobile_service_likes?: Maybe<Mobile_Service_Likes_Mutation_Response>;
  /** update single row of the table: "service_likes" */
  update_mobile_service_likes_by_pk?: Maybe<Mobile_Service_Likes>;
  /** update multiples rows of table: "service_likes" */
  update_mobile_service_likes_many?: Maybe<Array<Maybe<Mobile_Service_Likes_Mutation_Response>>>;
  /** update data of the table: "service_list_documents" */
  update_mobile_service_list_documents?: Maybe<Mobile_Service_List_Documents_Mutation_Response>;
  /** update single row of the table: "service_list_documents" */
  update_mobile_service_list_documents_by_pk?: Maybe<Mobile_Service_List_Documents>;
  /** update multiples rows of table: "service_list_documents" */
  update_mobile_service_list_documents_many?: Maybe<Array<Maybe<Mobile_Service_List_Documents_Mutation_Response>>>;
  /** update data of the table: "service_list_periodicities" */
  update_mobile_service_list_periodicities?: Maybe<Mobile_Service_List_Periodicities_Mutation_Response>;
  /** update single row of the table: "service_list_periodicities" */
  update_mobile_service_list_periodicities_by_pk?: Maybe<Mobile_Service_List_Periodicities>;
  /** update multiples rows of table: "service_list_periodicities" */
  update_mobile_service_list_periodicities_many?: Maybe<Array<Maybe<Mobile_Service_List_Periodicities_Mutation_Response>>>;
  /** update data of the table: "service_lists" */
  update_mobile_service_lists?: Maybe<Mobile_Service_Lists_Mutation_Response>;
  /** update single row of the table: "service_lists" */
  update_mobile_service_lists_by_pk?: Maybe<Mobile_Service_Lists>;
  /** update multiples rows of table: "service_lists" */
  update_mobile_service_lists_many?: Maybe<Array<Maybe<Mobile_Service_Lists_Mutation_Response>>>;
  /** update data of the table: "service_orders" */
  update_mobile_service_orders?: Maybe<Mobile_Service_Orders_Mutation_Response>;
  /** update single row of the table: "service_orders" */
  update_mobile_service_orders_by_pk?: Maybe<Mobile_Service_Orders>;
  /** update multiples rows of table: "service_orders" */
  update_mobile_service_orders_many?: Maybe<Array<Maybe<Mobile_Service_Orders_Mutation_Response>>>;
  /** update data of the table: "service_periodicities" */
  update_mobile_service_periodicities?: Maybe<Mobile_Service_Periodicities_Mutation_Response>;
  /** update single row of the table: "service_periodicities" */
  update_mobile_service_periodicities_by_pk?: Maybe<Mobile_Service_Periodicities>;
  /** update multiples rows of table: "service_periodicities" */
  update_mobile_service_periodicities_many?: Maybe<Array<Maybe<Mobile_Service_Periodicities_Mutation_Response>>>;
  /** update data of the table: "service_purchases" */
  update_mobile_service_purchases?: Maybe<Mobile_Service_Purchases_Mutation_Response>;
  /** update single row of the table: "service_purchases" */
  update_mobile_service_purchases_by_pk?: Maybe<Mobile_Service_Purchases>;
  /** update multiples rows of table: "service_purchases" */
  update_mobile_service_purchases_many?: Maybe<Array<Maybe<Mobile_Service_Purchases_Mutation_Response>>>;
  /** update data of the table: "service_questions" */
  update_mobile_service_questions?: Maybe<Mobile_Service_Questions_Mutation_Response>;
  /** update single row of the table: "service_questions" */
  update_mobile_service_questions_by_pk?: Maybe<Mobile_Service_Questions>;
  /** update multiples rows of table: "service_questions" */
  update_mobile_service_questions_many?: Maybe<Array<Maybe<Mobile_Service_Questions_Mutation_Response>>>;
  /** update data of the table: "service_rate_comments" */
  update_mobile_service_rate_comments?: Maybe<Mobile_Service_Rate_Comments_Mutation_Response>;
  /** update single row of the table: "service_rate_comments" */
  update_mobile_service_rate_comments_by_pk?: Maybe<Mobile_Service_Rate_Comments>;
  /** update multiples rows of table: "service_rate_comments" */
  update_mobile_service_rate_comments_many?: Maybe<Array<Maybe<Mobile_Service_Rate_Comments_Mutation_Response>>>;
  /** update data of the table: "service_rate_images" */
  update_mobile_service_rate_images?: Maybe<Mobile_Service_Rate_Images_Mutation_Response>;
  /** update single row of the table: "service_rate_images" */
  update_mobile_service_rate_images_by_pk?: Maybe<Mobile_Service_Rate_Images>;
  /** update multiples rows of table: "service_rate_images" */
  update_mobile_service_rate_images_many?: Maybe<Array<Maybe<Mobile_Service_Rate_Images_Mutation_Response>>>;
  /** update data of the table: "service_rate_reactions" */
  update_mobile_service_rate_reactions?: Maybe<Mobile_Service_Rate_Reactions_Mutation_Response>;
  /** update single row of the table: "service_rate_reactions" */
  update_mobile_service_rate_reactions_by_pk?: Maybe<Mobile_Service_Rate_Reactions>;
  /** update multiples rows of table: "service_rate_reactions" */
  update_mobile_service_rate_reactions_many?: Maybe<Array<Maybe<Mobile_Service_Rate_Reactions_Mutation_Response>>>;
  /** update data of the table: "service_rates" */
  update_mobile_service_rates?: Maybe<Mobile_Service_Rates_Mutation_Response>;
  /** update single row of the table: "service_rates" */
  update_mobile_service_rates_by_pk?: Maybe<Mobile_Service_Rates>;
  /** update multiples rows of table: "service_rates" */
  update_mobile_service_rates_many?: Maybe<Array<Maybe<Mobile_Service_Rates_Mutation_Response>>>;
  /** update data of the table: "service_statuses" */
  update_mobile_service_statuses?: Maybe<Mobile_Service_Statuses_Mutation_Response>;
  /** update single row of the table: "service_statuses" */
  update_mobile_service_statuses_by_pk?: Maybe<Mobile_Service_Statuses>;
  /** update multiples rows of table: "service_statuses" */
  update_mobile_service_statuses_many?: Maybe<Array<Maybe<Mobile_Service_Statuses_Mutation_Response>>>;
  /** update data of the table: "service_tag_ages" */
  update_mobile_service_tag_ages?: Maybe<Mobile_Service_Tag_Ages_Mutation_Response>;
  /** update single row of the table: "service_tag_ages" */
  update_mobile_service_tag_ages_by_pk?: Maybe<Mobile_Service_Tag_Ages>;
  /** update multiples rows of table: "service_tag_ages" */
  update_mobile_service_tag_ages_many?: Maybe<Array<Maybe<Mobile_Service_Tag_Ages_Mutation_Response>>>;
  /** update data of the table: "service_tag_target_groups" */
  update_mobile_service_tag_target_groups?: Maybe<Mobile_Service_Tag_Target_Groups_Mutation_Response>;
  /** update single row of the table: "service_tag_target_groups" */
  update_mobile_service_tag_target_groups_by_pk?: Maybe<Mobile_Service_Tag_Target_Groups>;
  /** update multiples rows of table: "service_tag_target_groups" */
  update_mobile_service_tag_target_groups_many?: Maybe<Array<Maybe<Mobile_Service_Tag_Target_Groups_Mutation_Response>>>;
  /** update data of the table: "service_tag_targets" */
  update_mobile_service_tag_targets?: Maybe<Mobile_Service_Tag_Targets_Mutation_Response>;
  /** update single row of the table: "service_tag_targets" */
  update_mobile_service_tag_targets_by_pk?: Maybe<Mobile_Service_Tag_Targets>;
  /** update multiples rows of table: "service_tag_targets" */
  update_mobile_service_tag_targets_many?: Maybe<Array<Maybe<Mobile_Service_Tag_Targets_Mutation_Response>>>;
  /** update data of the table: "service_tariff_documents" */
  update_mobile_service_tariff_documents?: Maybe<Mobile_Service_Tariff_Documents_Mutation_Response>;
  /** update single row of the table: "service_tariff_documents" */
  update_mobile_service_tariff_documents_by_pk?: Maybe<Mobile_Service_Tariff_Documents>;
  /** update multiples rows of table: "service_tariff_documents" */
  update_mobile_service_tariff_documents_many?: Maybe<Array<Maybe<Mobile_Service_Tariff_Documents_Mutation_Response>>>;
  /** update data of the table: "service_tariffs" */
  update_mobile_service_tariffs?: Maybe<Mobile_Service_Tariffs_Mutation_Response>;
  /** update single row of the table: "service_tariffs" */
  update_mobile_service_tariffs_by_pk?: Maybe<Mobile_Service_Tariffs>;
  /** update multiples rows of table: "service_tariffs" */
  update_mobile_service_tariffs_many?: Maybe<Array<Maybe<Mobile_Service_Tariffs_Mutation_Response>>>;
  /** update data of the table: "service_type_names" */
  update_mobile_service_type_names?: Maybe<Mobile_Service_Type_Names_Mutation_Response>;
  /** update single row of the table: "service_type_names" */
  update_mobile_service_type_names_by_pk?: Maybe<Mobile_Service_Type_Names>;
  /** update multiples rows of table: "service_type_names" */
  update_mobile_service_type_names_many?: Maybe<Array<Maybe<Mobile_Service_Type_Names_Mutation_Response>>>;
  /** update data of the table: "service_types" */
  update_mobile_service_types?: Maybe<Mobile_Service_Types_Mutation_Response>;
  /** update single row of the table: "service_types" */
  update_mobile_service_types_by_pk?: Maybe<Mobile_Service_Types>;
  /** update multiples rows of table: "service_types" */
  update_mobile_service_types_many?: Maybe<Array<Maybe<Mobile_Service_Types_Mutation_Response>>>;
  /** update data of the table: "services" */
  update_mobile_services?: Maybe<Mobile_Services_Mutation_Response>;
  /** update single row of the table: "services" */
  update_mobile_services_by_pk?: Maybe<Mobile_Services>;
  /** update multiples rows of table: "services" */
  update_mobile_services_many?: Maybe<Array<Maybe<Mobile_Services_Mutation_Response>>>;
  /** update data of the table: "settings" */
  update_mobile_settings?: Maybe<Mobile_Settings_Mutation_Response>;
  /** update single row of the table: "settings" */
  update_mobile_settings_by_pk?: Maybe<Mobile_Settings>;
  /** update multiples rows of table: "settings" */
  update_mobile_settings_many?: Maybe<Array<Maybe<Mobile_Settings_Mutation_Response>>>;
  /** update data of the table: "share_posts" */
  update_mobile_share_posts?: Maybe<Mobile_Share_Posts_Mutation_Response>;
  /** update single row of the table: "share_posts" */
  update_mobile_share_posts_by_pk?: Maybe<Mobile_Share_Posts>;
  /** update multiples rows of table: "share_posts" */
  update_mobile_share_posts_many?: Maybe<Array<Maybe<Mobile_Share_Posts_Mutation_Response>>>;
  /** update data of the table: "sleep_norms" */
  update_mobile_sleep_norms?: Maybe<Mobile_Sleep_Norms_Mutation_Response>;
  /** update single row of the table: "sleep_norms" */
  update_mobile_sleep_norms_by_pk?: Maybe<Mobile_Sleep_Norms>;
  /** update multiples rows of table: "sleep_norms" */
  update_mobile_sleep_norms_many?: Maybe<Array<Maybe<Mobile_Sleep_Norms_Mutation_Response>>>;
  /** update data of the table: "sleep_nows" */
  update_mobile_sleep_nows?: Maybe<Mobile_Sleep_Nows_Mutation_Response>;
  /** update single row of the table: "sleep_nows" */
  update_mobile_sleep_nows_by_pk?: Maybe<Mobile_Sleep_Nows>;
  /** update multiples rows of table: "sleep_nows" */
  update_mobile_sleep_nows_many?: Maybe<Array<Maybe<Mobile_Sleep_Nows_Mutation_Response>>>;
  /** update data of the table: "sleeps" */
  update_mobile_sleeps?: Maybe<Mobile_Sleeps_Mutation_Response>;
  /** update single row of the table: "sleeps" */
  update_mobile_sleeps_by_pk?: Maybe<Mobile_Sleeps>;
  /** update multiples rows of table: "sleeps" */
  update_mobile_sleeps_many?: Maybe<Array<Maybe<Mobile_Sleeps_Mutation_Response>>>;
  /** update data of the table: "slots" */
  update_mobile_slots?: Maybe<Mobile_Slots_Mutation_Response>;
  /** update single row of the table: "slots" */
  update_mobile_slots_by_pk?: Maybe<Mobile_Slots>;
  /** update multiples rows of table: "slots" */
  update_mobile_slots_many?: Maybe<Array<Maybe<Mobile_Slots_Mutation_Response>>>;
  /** update data of the table: "status_messages" */
  update_mobile_status_messages?: Maybe<Mobile_Status_Messages_Mutation_Response>;
  /** update single row of the table: "status_messages" */
  update_mobile_status_messages_by_pk?: Maybe<Mobile_Status_Messages>;
  /** update multiples rows of table: "status_messages" */
  update_mobile_status_messages_many?: Maybe<Array<Maybe<Mobile_Status_Messages_Mutation_Response>>>;
  /** update data of the table: "step_norms" */
  update_mobile_step_norms?: Maybe<Mobile_Step_Norms_Mutation_Response>;
  /** update single row of the table: "step_norms" */
  update_mobile_step_norms_by_pk?: Maybe<Mobile_Step_Norms>;
  /** update multiples rows of table: "step_norms" */
  update_mobile_step_norms_many?: Maybe<Array<Maybe<Mobile_Step_Norms_Mutation_Response>>>;
  /** update data of the table: "step_nows" */
  update_mobile_step_nows?: Maybe<Mobile_Step_Nows_Mutation_Response>;
  /** update single row of the table: "step_nows" */
  update_mobile_step_nows_by_pk?: Maybe<Mobile_Step_Nows>;
  /** update multiples rows of table: "step_nows" */
  update_mobile_step_nows_many?: Maybe<Array<Maybe<Mobile_Step_Nows_Mutation_Response>>>;
  /** update data of the table: "steps" */
  update_mobile_steps?: Maybe<Mobile_Steps_Mutation_Response>;
  /** update single row of the table: "steps" */
  update_mobile_steps_by_pk?: Maybe<Mobile_Steps>;
  /** update multiples rows of table: "steps" */
  update_mobile_steps_many?: Maybe<Array<Maybe<Mobile_Steps_Mutation_Response>>>;
  /** update data of the table: "subscribe_notifications" */
  update_mobile_subscribe_notifications?: Maybe<Mobile_Subscribe_Notifications_Mutation_Response>;
  /** update multiples rows of table: "subscribe_notifications" */
  update_mobile_subscribe_notifications_many?: Maybe<Array<Maybe<Mobile_Subscribe_Notifications_Mutation_Response>>>;
  /** update data of the table: "subscribes" */
  update_mobile_subscribes?: Maybe<Mobile_Subscribes_Mutation_Response>;
  /** update single row of the table: "subscribes" */
  update_mobile_subscribes_by_pk?: Maybe<Mobile_Subscribes>;
  /** update multiples rows of table: "subscribes" */
  update_mobile_subscribes_many?: Maybe<Array<Maybe<Mobile_Subscribes_Mutation_Response>>>;
  /** update data of the table: "support_messages" */
  update_mobile_support_messages?: Maybe<Mobile_Support_Messages_Mutation_Response>;
  /** update single row of the table: "support_messages" */
  update_mobile_support_messages_by_pk?: Maybe<Mobile_Support_Messages>;
  /** update multiples rows of table: "support_messages" */
  update_mobile_support_messages_many?: Maybe<Array<Maybe<Mobile_Support_Messages_Mutation_Response>>>;
  /** update data of the table: "support_topics" */
  update_mobile_support_topics?: Maybe<Mobile_Support_Topics_Mutation_Response>;
  /** update single row of the table: "support_topics" */
  update_mobile_support_topics_by_pk?: Maybe<Mobile_Support_Topics>;
  /** update multiples rows of table: "support_topics" */
  update_mobile_support_topics_many?: Maybe<Array<Maybe<Mobile_Support_Topics_Mutation_Response>>>;
  /** update data of the table: "tag_ages" */
  update_mobile_tag_ages?: Maybe<Mobile_Tag_Ages_Mutation_Response>;
  /** update single row of the table: "tag_ages" */
  update_mobile_tag_ages_by_pk?: Maybe<Mobile_Tag_Ages>;
  /** update multiples rows of table: "tag_ages" */
  update_mobile_tag_ages_many?: Maybe<Array<Maybe<Mobile_Tag_Ages_Mutation_Response>>>;
  /** update data of the table: "tag_target_groups" */
  update_mobile_tag_target_groups?: Maybe<Mobile_Tag_Target_Groups_Mutation_Response>;
  /** update single row of the table: "tag_target_groups" */
  update_mobile_tag_target_groups_by_pk?: Maybe<Mobile_Tag_Target_Groups>;
  /** update multiples rows of table: "tag_target_groups" */
  update_mobile_tag_target_groups_many?: Maybe<Array<Maybe<Mobile_Tag_Target_Groups_Mutation_Response>>>;
  /** update data of the table: "tag_targets" */
  update_mobile_tag_targets?: Maybe<Mobile_Tag_Targets_Mutation_Response>;
  /** update single row of the table: "tag_targets" */
  update_mobile_tag_targets_by_pk?: Maybe<Mobile_Tag_Targets>;
  /** update multiples rows of table: "tag_targets" */
  update_mobile_tag_targets_many?: Maybe<Array<Maybe<Mobile_Tag_Targets_Mutation_Response>>>;
  /** update data of the table: "type_phones" */
  update_mobile_type_phones?: Maybe<Mobile_Type_Phones_Mutation_Response>;
  /** update single row of the table: "type_phones" */
  update_mobile_type_phones_by_pk?: Maybe<Mobile_Type_Phones>;
  /** update multiples rows of table: "type_phones" */
  update_mobile_type_phones_many?: Maybe<Array<Maybe<Mobile_Type_Phones_Mutation_Response>>>;
  /** update data of the table: "type_profiles" */
  update_mobile_type_profiles?: Maybe<Mobile_Type_Profiles_Mutation_Response>;
  /** update single row of the table: "type_profiles" */
  update_mobile_type_profiles_by_pk?: Maybe<Mobile_Type_Profiles>;
  /** update multiples rows of table: "type_profiles" */
  update_mobile_type_profiles_many?: Maybe<Array<Maybe<Mobile_Type_Profiles_Mutation_Response>>>;
  /** update data of the table: "upload_pro_documents" */
  update_mobile_upload_pro_documents?: Maybe<Mobile_Upload_Pro_Documents_Mutation_Response>;
  /** update single row of the table: "upload_pro_documents" */
  update_mobile_upload_pro_documents_by_pk?: Maybe<Mobile_Upload_Pro_Documents>;
  /** update multiples rows of table: "upload_pro_documents" */
  update_mobile_upload_pro_documents_many?: Maybe<Array<Maybe<Mobile_Upload_Pro_Documents_Mutation_Response>>>;
  /** update data of the table: "user_chat_rights" */
  update_mobile_user_chat_rights?: Maybe<Mobile_User_Chat_Rights_Mutation_Response>;
  /** update single row of the table: "user_chat_rights" */
  update_mobile_user_chat_rights_by_pk?: Maybe<Mobile_User_Chat_Rights>;
  /** update multiples rows of table: "user_chat_rights" */
  update_mobile_user_chat_rights_many?: Maybe<Array<Maybe<Mobile_User_Chat_Rights_Mutation_Response>>>;
  /** update data of the table: "user_chats" */
  update_mobile_user_chats?: Maybe<Mobile_User_Chats_Mutation_Response>;
  /** update single row of the table: "user_chats" */
  update_mobile_user_chats_by_pk?: Maybe<Mobile_User_Chats>;
  /** update multiples rows of table: "user_chats" */
  update_mobile_user_chats_many?: Maybe<Array<Maybe<Mobile_User_Chats_Mutation_Response>>>;
  /** update data of the table: "view_chat_messages" */
  update_mobile_view_chat_messages?: Maybe<Mobile_View_Chat_Messages_Mutation_Response>;
  /** update multiples rows of table: "view_chat_messages" */
  update_mobile_view_chat_messages_many?: Maybe<Array<Maybe<Mobile_View_Chat_Messages_Mutation_Response>>>;
  /** update data of the table: "water_norms" */
  update_mobile_water_norms?: Maybe<Mobile_Water_Norms_Mutation_Response>;
  /** update single row of the table: "water_norms" */
  update_mobile_water_norms_by_pk?: Maybe<Mobile_Water_Norms>;
  /** update multiples rows of table: "water_norms" */
  update_mobile_water_norms_many?: Maybe<Array<Maybe<Mobile_Water_Norms_Mutation_Response>>>;
  /** update data of the table: "water_nows" */
  update_mobile_water_nows?: Maybe<Mobile_Water_Nows_Mutation_Response>;
  /** update single row of the table: "water_nows" */
  update_mobile_water_nows_by_pk?: Maybe<Mobile_Water_Nows>;
  /** update multiples rows of table: "water_nows" */
  update_mobile_water_nows_many?: Maybe<Array<Maybe<Mobile_Water_Nows_Mutation_Response>>>;
  /** update data of the table: "waters" */
  update_mobile_waters?: Maybe<Mobile_Waters_Mutation_Response>;
  /** update single row of the table: "waters" */
  update_mobile_waters_by_pk?: Maybe<Mobile_Waters>;
  /** update multiples rows of table: "waters" */
  update_mobile_waters_many?: Maybe<Array<Maybe<Mobile_Waters_Mutation_Response>>>;
  /** update data of the table: "weight_nows" */
  update_mobile_weight_nows?: Maybe<Mobile_Weight_Nows_Mutation_Response>;
  /** update single row of the table: "weight_nows" */
  update_mobile_weight_nows_by_pk?: Maybe<Mobile_Weight_Nows>;
  /** update multiples rows of table: "weight_nows" */
  update_mobile_weight_nows_many?: Maybe<Array<Maybe<Mobile_Weight_Nows_Mutation_Response>>>;
  /** update data of the table: "weights" */
  update_mobile_weights?: Maybe<Mobile_Weights_Mutation_Response>;
  /** update single row of the table: "weights" */
  update_mobile_weights_by_pk?: Maybe<Mobile_Weights>;
  /** update multiples rows of table: "weights" */
  update_mobile_weights_many?: Maybe<Array<Maybe<Mobile_Weights_Mutation_Response>>>;
  /** update data of the table: "balances" */
  update_partner_balances?: Maybe<Partner_Balances_Mutation_Response>;
  /** update single row of the table: "balances" */
  update_partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** update multiples rows of table: "balances" */
  update_partner_balances_many?: Maybe<Array<Maybe<Partner_Balances_Mutation_Response>>>;
  /** update data of the table: "log_vzr_sales" */
  update_partner_log_vzr_sales?: Maybe<Partner_Log_Vzr_Sales_Mutation_Response>;
  /** update single row of the table: "log_vzr_sales" */
  update_partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** update multiples rows of table: "log_vzr_sales" */
  update_partner_log_vzr_sales_many?: Maybe<Array<Maybe<Partner_Log_Vzr_Sales_Mutation_Response>>>;
  /** update data of the table: "log_vzr_searches" */
  update_partner_log_vzr_searches?: Maybe<Partner_Log_Vzr_Searches_Mutation_Response>;
  /** update single row of the table: "log_vzr_searches" */
  update_partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** update multiples rows of table: "log_vzr_searches" */
  update_partner_log_vzr_searches_many?: Maybe<Array<Maybe<Partner_Log_Vzr_Searches_Mutation_Response>>>;
  /** update data of the table: "log_vzr_views" */
  update_partner_log_vzr_views?: Maybe<Partner_Log_Vzr_Views_Mutation_Response>;
  /** update single row of the table: "log_vzr_views" */
  update_partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** update multiples rows of table: "log_vzr_views" */
  update_partner_log_vzr_views_many?: Maybe<Array<Maybe<Partner_Log_Vzr_Views_Mutation_Response>>>;
  /** update data of the table: "place_type_integrations" */
  update_partner_place_type_integrations?: Maybe<Partner_Place_Type_Integrations_Mutation_Response>;
  /** update single row of the table: "place_type_integrations" */
  update_partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** update multiples rows of table: "place_type_integrations" */
  update_partner_place_type_integrations_many?: Maybe<Array<Maybe<Partner_Place_Type_Integrations_Mutation_Response>>>;
  /** update data of the table: "places" */
  update_partner_places?: Maybe<Partner_Places_Mutation_Response>;
  /** update single row of the table: "places" */
  update_partner_places_by_pk?: Maybe<Partner_Places>;
  /** update multiples rows of table: "places" */
  update_partner_places_many?: Maybe<Array<Maybe<Partner_Places_Mutation_Response>>>;
  /** update data of the table: "sale_types" */
  update_partner_sale_types?: Maybe<Partner_Sale_Types_Mutation_Response>;
  /** update single row of the table: "sale_types" */
  update_partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** update multiples rows of table: "sale_types" */
  update_partner_sale_types_many?: Maybe<Array<Maybe<Partner_Sale_Types_Mutation_Response>>>;
  /** update data of the table: "status_integrations" */
  update_partner_status_integrations?: Maybe<Partner_Status_Integrations_Mutation_Response>;
  /** update single row of the table: "status_integrations" */
  update_partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** update multiples rows of table: "status_integrations" */
  update_partner_status_integrations_many?: Maybe<Array<Maybe<Partner_Status_Integrations_Mutation_Response>>>;
  /** update data of the table: "status_transactions" */
  update_partner_status_transactions?: Maybe<Partner_Status_Transactions_Mutation_Response>;
  /** update single row of the table: "status_transactions" */
  update_partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** update multiples rows of table: "status_transactions" */
  update_partner_status_transactions_many?: Maybe<Array<Maybe<Partner_Status_Transactions_Mutation_Response>>>;
  /** update data of the table: "themes" */
  update_partner_themes?: Maybe<Partner_Themes_Mutation_Response>;
  /** update single row of the table: "themes" */
  update_partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** update multiples rows of table: "themes" */
  update_partner_themes_many?: Maybe<Array<Maybe<Partner_Themes_Mutation_Response>>>;
  /** update data of the table: "type_integrations" */
  update_partner_type_integrations?: Maybe<Partner_Type_Integrations_Mutation_Response>;
  /** update single row of the table: "type_integrations" */
  update_partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** update multiples rows of table: "type_integrations" */
  update_partner_type_integrations_many?: Maybe<Array<Maybe<Partner_Type_Integrations_Mutation_Response>>>;
  /** update data of the table: "type_transactions" */
  update_partner_type_transactions?: Maybe<Partner_Type_Transactions_Mutation_Response>;
  /** update single row of the table: "type_transactions" */
  update_partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** update multiples rows of table: "type_transactions" */
  update_partner_type_transactions_many?: Maybe<Array<Maybe<Partner_Type_Transactions_Mutation_Response>>>;
  /** update data of the table: "user_types" */
  update_partner_user_types?: Maybe<Partner_User_Types_Mutation_Response>;
  /** update single row of the table: "user_types" */
  update_partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** update multiples rows of table: "user_types" */
  update_partner_user_types_many?: Maybe<Array<Maybe<Partner_User_Types_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_partner_users?: Maybe<Partner_Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_partner_users_by_pk?: Maybe<Partner_Users>;
  /** update multiples rows of table: "users" */
  update_partner_users_many?: Maybe<Array<Maybe<Partner_Users_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_service_users?: Maybe<Service_Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_service_users_by_pk?: Maybe<Service_Users>;
  /** update multiples rows of table: "users" */
  update_service_users_many?: Maybe<Array<Maybe<Service_Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAuthChangeEmailArgs = {
  arg: AuthChangeEmailInput;
};


/** mutation root */
export type Mutation_RootAuthChangePasswordArgs = {
  params: AuthChangePasswordInput;
};


/** mutation root */
export type Mutation_RootAuthChangePhoneArgs = {
  arg: AuthChangePhoneInput;
};


/** mutation root */
export type Mutation_RootAuthCheckOtpCodeEmailArgs = {
  arg: AuthCheckOtpCodeEmailInput;
};


/** mutation root */
export type Mutation_RootAuthCheckOtpCodePhoneArgs = {
  arg: AuthCheckOtpCodePhoneInput;
};


/** mutation root */
export type Mutation_RootAuthHardDeleteUserArgs = {
  arg: AuthHardDeleteUserInput;
};


/** mutation root */
export type Mutation_RootAuthSendOtpCodeCheckEmailArgs = {
  arg: AuthSendOtpCodeCheckEmailInput;
};


/** mutation root */
export type Mutation_RootAuthSendOtpCodeCheckPhoneArgs = {
  arg: AuthSendOtpCodeCheckPhoneInput;
};


/** mutation root */
export type Mutation_RootAuthSendingMailConfirmNewEmailArgs = {
  arg: AuthSendingMailConfirmNewEmailInput;
};


/** mutation root */
export type Mutation_RootAuthSendingSmsConfirmNewPhoneArgs = {
  arg: AuthSendingSmsConfirmNewPhoneInput;
};


/** mutation root */
export type Mutation_RootBillingDmsPaytureGetStatusArgs = {
  arg: BillingDmsPaytureGetStatusInput;
};


/** mutation root */
export type Mutation_RootBillingOrderPoliceCreateArgs = {
  arg: BillingOrderPoliceCreateInput;
};


/** mutation root */
export type Mutation_RootCreate_Direct_UploadArgs = {
  object: CreateDirectUploadInput;
};


/** mutation root */
export type Mutation_RootCustom_Update_Users_By_PkArgs = {
  _set?: InputMaybe<UpdateUserUsersSetInput>;
  pk_columns: UpdateUserUsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootCustom_Update_Users_By_Pk_TestArgs = {
  _set?: InputMaybe<UpdateUserUsersSetInput>;
  pk_columns: UpdateUserUsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Oauth_Access_TokensArgs = {
  where: Auth_Oauth_Access_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Oauth_Access_Tokens_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Test_UsersArgs = {
  where: Auth_Test_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Test_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_UsersArgs = {
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Verification_EmailsArgs = {
  where: Auth_Verification_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Verification_Emails_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Verification_PhonesArgs = {
  where: Auth_Verification_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Verification_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Order_PolicesArgs = {
  where: Billing_Order_Polices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Order_Polices_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Order_StatusesArgs = {
  where: Billing_Order_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Order_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Promo_CodesArgs = {
  where: Billing_Promo_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_CitiesArgs = {
  where: Dms_Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Cities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Group_InsuredsArgs = {
  where: Dms_Group_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Group_Insureds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_GroupsArgs = {
  where: Dms_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_InsurantsArgs = {
  where: Dms_Insurants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Insurants_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_InsuredsArgs = {
  where: Dms_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Insureds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_LinksArgs = {
  where: Dms_Order_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_Links_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_NumbersArgs = {
  where: Dms_Order_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Order_Numbers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_OrdersArgs = {
  where: Dms_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_ProductsArgs = {
  where: Dms_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Products_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_Promo_CodesArgs = {
  where: Dms_Promo_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_StatusesArgs = {
  where: Dms_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Dms_UsersArgs = {
  where: Dms_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dms_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Fintech_CardsArgs = {
  where: Fintech_Cards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fintech_Cards_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Fintech_ClientsArgs = {
  where: Fintech_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fintech_Clients_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Fintech_OrdersArgs = {
  where: Fintech_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fintech_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Amocrm_ContactsArgs = {
  where: Mobile_Amocrm_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Amocrm_Contacts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Amocrm_LeadsArgs = {
  where: Mobile_Amocrm_Leads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Amocrm_Leads_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Amocrm_TagsArgs = {
  where: Mobile_Amocrm_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Amocrm_Tags_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_AppointmentsArgs = {
  where: Mobile_Appointments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Appointments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Auto_RepliesArgs = {
  where: Mobile_Auto_Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Auto_Replies_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Auto_Reply_DocumentsArgs = {
  where: Mobile_Auto_Reply_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Auto_Reply_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Auto_Reply_MediafilesArgs = {
  where: Mobile_Auto_Reply_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Auto_Reply_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Bank_AccountsArgs = {
  where: Mobile_Bank_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Bank_Accounts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Blocked_SitesArgs = {
  where: Mobile_Blocked_Sites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Blocked_Sites_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_BlockedsArgs = {
  where: Mobile_Blockeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Blockeds_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Calorie_NormsArgs = {
  where: Mobile_Calorie_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Calorie_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Calorie_NowsArgs = {
  where: Mobile_Calorie_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Calorie_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_CaloriesArgs = {
  where: Mobile_Calories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Calories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_CategoriesArgs = {
  where: Mobile_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Categories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Category_NamesArgs = {
  where: Mobile_Category_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Category_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Black_ListsArgs = {
  where: Mobile_Chat_Black_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Black_Lists_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_DefaultsArgs = {
  where: Mobile_Chat_Defaults_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Defaults_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_File_TypesArgs = {
  where: Mobile_Chat_File_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_File_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Message_CommentsArgs = {
  where: Mobile_Chat_Message_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Message_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Message_FilesArgs = {
  where: Mobile_Chat_Message_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Message_Files_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Message_NotificationsArgs = {
  where: Mobile_Chat_Message_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Message_TypesArgs = {
  where: Mobile_Chat_Message_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Message_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_MessagesArgs = {
  where: Mobile_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_RolesArgs = {
  where: Mobile_Chat_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Roles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_TypesArgs = {
  where: Mobile_Chat_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chat_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ChatsArgs = {
  where: Mobile_Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Chats_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ClientsArgs = {
  where: Mobile_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Clients_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Comment_Like_NotificationsArgs = {
  where: Mobile_Comment_Like_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Comment_LikesArgs = {
  where: Mobile_Comment_Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Comment_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Comment_NotificationsArgs = {
  where: Mobile_Comment_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_CommentsArgs = {
  where: Mobile_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Confirmation_CodesArgs = {
  where: Mobile_Confirmation_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Confirmation_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ContactsArgs = {
  where: Mobile_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Contacts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ContentsArgs = {
  where: Mobile_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Contents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Device_InfosArgs = {
  where: Mobile_Device_Infos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Device_Infos_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Device_SettingsArgs = {
  where: Mobile_Device_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Device_Settings_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_DevicesArgs = {
  where: Mobile_Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Devices_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Document_MediafilesArgs = {
  where: Mobile_Document_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Document_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_DocumentsArgs = {
  where: Mobile_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Education_MediafilesArgs = {
  where: Mobile_Education_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Education_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_EducationsArgs = {
  where: Mobile_Educations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Educations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_EmojisArgs = {
  where: Mobile_Emojis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Emojis_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Fixed_Chat_MessagesArgs = {
  where: Mobile_Fixed_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Fixed_Chat_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Fixed_CommentsArgs = {
  where: Mobile_Fixed_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Fixed_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Founder_MediafilesArgs = {
  where: Mobile_Founder_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Founder_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_FoundersArgs = {
  where: Mobile_Founders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Founders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Invite_EmailsArgs = {
  where: Mobile_Invite_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Invite_Emails_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Like_NotificationsArgs = {
  where: Mobile_Like_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_LikesArgs = {
  where: Mobile_Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Link_SnippetsArgs = {
  where: Mobile_Link_Snippets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Link_Snippets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Locked_Deleted_Chat_MessagesArgs = {
  where: Mobile_Locked_Deleted_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Mediafile_Status_NamesArgs = {
  where: Mobile_Mediafile_Status_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Mediafile_Status_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Mediafile_StatusesArgs = {
  where: Mobile_Mediafile_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Mediafile_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Medical_Concierge_OrdersArgs = {
  where: Mobile_Medical_Concierge_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Medical_Concierge_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Medical_Concierge_ProductsArgs = {
  where: Mobile_Medical_Concierge_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Medical_Concierge_Products_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Mention_NotificationsArgs = {
  where: Mobile_Mention_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_MentionsArgs = {
  where: Mobile_Mentions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Mentions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Metric_NamesArgs = {
  where: Mobile_Metric_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Metric_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Metric_ParamsArgs = {
  where: Mobile_Metric_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Metric_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Metric_TypesArgs = {
  where: Mobile_Metric_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Metric_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_MetricsArgs = {
  where: Mobile_Metrics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Metrics_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Notification_MessagesArgs = {
  where: Mobile_Notification_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Notification_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_NotificationsArgs = {
  where: Mobile_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Notifications_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Notifications_StatusesArgs = {
  where: Mobile_Notifications_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Notifications_Statuses_By_PkArgs = {
  title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Order_NotificationsArgs = {
  where: Mobile_Order_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Order_ProfessionalsArgs = {
  where: Mobile_Order_Professionals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Order_Professionals_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_OrdersArgs = {
  where: Mobile_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_OrgsArgs = {
  where: Mobile_Orgs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Orgs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Param_CategoriesArgs = {
  where: Mobile_Param_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Param_Categories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Param_Categories_ParamsArgs = {
  where: Mobile_Param_Categories_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Param_Category_NamesArgs = {
  where: Mobile_Param_Category_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Param_Category_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Param_NamesArgs = {
  where: Mobile_Param_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Param_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ParamsArgs = {
  where: Mobile_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Params_ServicesArgs = {
  where: Mobile_Params_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Params_Services_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Personal_Info_ViewsArgs = {
  where: Mobile_Personal_Info_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Personal_Info_Views_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Personal_InfosArgs = {
  where: Mobile_Personal_Infos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Personal_Infos_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_PhonesArgs = {
  where: Mobile_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Post_ParamsArgs = {
  where: Mobile_Post_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Post_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_PostsArgs = {
  where: Mobile_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Posts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pro_Document_StatusesArgs = {
  where: Mobile_Pro_Document_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pro_Document_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pro_ProfessionsArgs = {
  where: Mobile_Pro_Professions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pro_Professions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pro_TypesArgs = {
  where: Mobile_Pro_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pro_Types_By_PkArgs = {
  title: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ProblemsArgs = {
  where: Mobile_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Problems_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Profession_NamesArgs = {
  where: Mobile_Profession_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Profession_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ProfessionsArgs = {
  where: Mobile_Professions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Professions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Profile_DeletedsArgs = {
  where: Mobile_Profile_Deleteds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Profile_Deleteds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Profile_LocksArgs = {
  where: Mobile_Profile_Locks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Profile_Locks_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ProfilesArgs = {
  where: Mobile_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Profiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ProsArgs = {
  where: Mobile_Pros_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pros_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pulse_NormsArgs = {
  where: Mobile_Pulse_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pulse_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pulse_NowsArgs = {
  where: Mobile_Pulse_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pulse_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_PulsesArgs = {
  where: Mobile_Pulses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Pulses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ReactionsArgs = {
  where: Mobile_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ReportsArgs = {
  where: Mobile_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Reports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_AgreementsArgs = {
  where: Mobile_Service_Agreements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Agreements_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Answer_ReactionsArgs = {
  where: Mobile_Service_Answer_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Answer_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_AnswersArgs = {
  where: Mobile_Service_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Answers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_DocumentsArgs = {
  where: Mobile_Service_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_FavoritesArgs = {
  where: Mobile_Service_Favorites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Favorites_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Free_PurchasesArgs = {
  where: Mobile_Service_Free_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Free_Purchases_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_ImagesArgs = {
  where: Mobile_Service_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Images_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_LikesArgs = {
  where: Mobile_Service_Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_List_DocumentsArgs = {
  where: Mobile_Service_List_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_List_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_List_PeriodicitiesArgs = {
  where: Mobile_Service_List_Periodicities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_List_Periodicities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_ListsArgs = {
  where: Mobile_Service_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Lists_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_OrdersArgs = {
  where: Mobile_Service_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_PeriodicitiesArgs = {
  where: Mobile_Service_Periodicities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Periodicities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_PurchasesArgs = {
  where: Mobile_Service_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Purchases_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_QuestionsArgs = {
  where: Mobile_Service_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Questions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Rate_CommentsArgs = {
  where: Mobile_Service_Rate_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Rate_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Rate_ImagesArgs = {
  where: Mobile_Service_Rate_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Rate_Images_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Rate_ReactionsArgs = {
  where: Mobile_Service_Rate_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Rate_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_RatesArgs = {
  where: Mobile_Service_Rates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Rates_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_StatusesArgs = {
  where: Mobile_Service_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tag_AgesArgs = {
  where: Mobile_Service_Tag_Ages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tag_Ages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tag_Target_GroupsArgs = {
  where: Mobile_Service_Tag_Target_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tag_Target_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tag_TargetsArgs = {
  where: Mobile_Service_Tag_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tag_Targets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tariff_DocumentsArgs = {
  where: Mobile_Service_Tariff_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tariff_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_TariffsArgs = {
  where: Mobile_Service_Tariffs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Tariffs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Type_NamesArgs = {
  where: Mobile_Service_Type_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Type_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_TypesArgs = {
  where: Mobile_Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Service_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_ServicesArgs = {
  where: Mobile_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Services_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_SettingsArgs = {
  where: Mobile_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Settings_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Share_PostsArgs = {
  where: Mobile_Share_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Share_Posts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Sleep_NormsArgs = {
  where: Mobile_Sleep_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Sleep_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Sleep_NowsArgs = {
  where: Mobile_Sleep_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Sleep_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_SleepsArgs = {
  where: Mobile_Sleeps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Sleeps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_SlotsArgs = {
  where: Mobile_Slots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Slots_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Status_MessagesArgs = {
  where: Mobile_Status_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Status_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Step_NormsArgs = {
  where: Mobile_Step_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Step_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Step_NowsArgs = {
  where: Mobile_Step_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Step_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_StepsArgs = {
  where: Mobile_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Steps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Subscribe_NotificationsArgs = {
  where: Mobile_Subscribe_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_SubscribesArgs = {
  where: Mobile_Subscribes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Subscribes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Support_MessagesArgs = {
  where: Mobile_Support_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Support_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Support_TopicsArgs = {
  where: Mobile_Support_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Support_Topics_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Tag_AgesArgs = {
  where: Mobile_Tag_Ages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Tag_Ages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Tag_Target_GroupsArgs = {
  where: Mobile_Tag_Target_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Tag_Target_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Tag_TargetsArgs = {
  where: Mobile_Tag_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Tag_Targets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Type_PhonesArgs = {
  where: Mobile_Type_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Type_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Type_ProfilesArgs = {
  where: Mobile_Type_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Type_Profiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Upload_Pro_DocumentsArgs = {
  where: Mobile_Upload_Pro_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Upload_Pro_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_User_Chat_RightsArgs = {
  where: Mobile_User_Chat_Rights_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_User_Chat_Rights_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_User_ChatsArgs = {
  where: Mobile_User_Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_User_Chats_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_View_Chat_MessagesArgs = {
  where: Mobile_View_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Water_NormsArgs = {
  where: Mobile_Water_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Water_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Water_NowsArgs = {
  where: Mobile_Water_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Water_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_WatersArgs = {
  where: Mobile_Waters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Waters_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Weight_NowsArgs = {
  where: Mobile_Weight_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Weight_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Mobile_WeightsArgs = {
  where: Mobile_Weights_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mobile_Weights_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_BalancesArgs = {
  where: Partner_Balances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Balances_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_SalesArgs = {
  where: Partner_Log_Vzr_Sales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_Sales_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_SearchesArgs = {
  where: Partner_Log_Vzr_Searches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_Searches_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_ViewsArgs = {
  where: Partner_Log_Vzr_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Log_Vzr_Views_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Place_Type_IntegrationsArgs = {
  where: Partner_Place_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Place_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_PlacesArgs = {
  where: Partner_Places_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Places_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Sale_TypesArgs = {
  where: Partner_Sale_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Sale_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_IntegrationsArgs = {
  where: Partner_Status_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_TransactionsArgs = {
  where: Partner_Status_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Status_Transactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_ThemesArgs = {
  where: Partner_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Themes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_IntegrationsArgs = {
  where: Partner_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_TransactionsArgs = {
  where: Partner_Type_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Type_Transactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_User_TypesArgs = {
  where: Partner_User_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_User_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_UsersArgs = {
  where: Partner_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Service_UsersArgs = {
  where: Service_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDmsApiDenumCreateFormArgs = {
  arg: DmsApiDenumCreateFormInput;
};


/** mutation root */
export type Mutation_RootDmsCreateInsurantArgs = {
  arg: DmsCreateInsurantInput;
};


/** mutation root */
export type Mutation_RootDmsCreateOrderArgs = {
  arg: DmsCreateOrderInput;
};


/** mutation root */
export type Mutation_RootDmsPrintOrderArgs = {
  arg: DmsPrintOrderInput;
};


/** mutation root */
export type Mutation_RootFintechCancelOrderJobArgs = {
  params: FintechCancelOrderJobInput;
};


/** mutation root */
export type Mutation_RootFintechCancelTransferArgs = {
  params: FintechCancelOrderInput;
};


/** mutation root */
export type Mutation_RootFintechCheckSmsArgs = {
  params: FintechClientSmsInput;
};


/** mutation root */
export type Mutation_RootFintechCreateInvoiceArgs = {
  params: CreateInvoiceInput;
};


/** mutation root */
export type Mutation_RootFintechModifyClientArgs = {
  params: FintechClientModifInput;
};


/** mutation root */
export type Mutation_RootFintechPayInDebitArgs = {
  params: FintechPayInDebitParamsInput;
};


/** mutation root */
export type Mutation_RootFintechPayInDebitCompleteArgs = {
  params: FintechOperationInput;
};


/** mutation root */
export type Mutation_RootFintechPayInvoiceArgs = {
  params: PayInvoiceInput;
};


/** mutation root */
export type Mutation_RootFintechRegisterClientArgs = {
  params: FintechClientParamsInput;
};


/** mutation root */
export type Mutation_RootFintechTransferArgs = {
  params: FintechTransferParamsInput;
};


/** mutation root */
export type Mutation_RootInsertMobilePostsOneActionArgs = {
  arg: InsertMobilePostsOneInput;
};


/** mutation root */
export type Mutation_RootInsertMobileProfilesOneActionArgs = {
  arg: InsertMobileProfilesOneInput;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Oauth_Access_TokensArgs = {
  objects: Array<Auth_Oauth_Access_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Oauth_Access_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Oauth_Access_Tokens_OneArgs = {
  object: Auth_Oauth_Access_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Auth_Oauth_Access_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Test_UsersArgs = {
  objects: Array<Auth_Test_Users_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Test_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Test_Users_OneArgs = {
  object: Auth_Test_Users_Insert_Input;
  on_conflict?: InputMaybe<Auth_Test_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_UsersArgs = {
  objects: Array<Auth_Users_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Users_OneArgs = {
  object: Auth_Users_Insert_Input;
  on_conflict?: InputMaybe<Auth_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Verification_EmailsArgs = {
  objects: Array<Auth_Verification_Emails_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Verification_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Verification_Emails_OneArgs = {
  object: Auth_Verification_Emails_Insert_Input;
  on_conflict?: InputMaybe<Auth_Verification_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Verification_PhonesArgs = {
  objects: Array<Auth_Verification_Phones_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Verification_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Verification_Phones_OneArgs = {
  object: Auth_Verification_Phones_Insert_Input;
  on_conflict?: InputMaybe<Auth_Verification_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Order_PolicesArgs = {
  objects: Array<Billing_Order_Polices_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Order_Polices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Order_Polices_OneArgs = {
  object: Billing_Order_Polices_Insert_Input;
  on_conflict?: InputMaybe<Billing_Order_Polices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Order_StatusesArgs = {
  objects: Array<Billing_Order_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Order_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Order_Statuses_OneArgs = {
  object: Billing_Order_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Billing_Order_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Promo_CodesArgs = {
  objects: Array<Billing_Promo_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Promo_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Promo_Codes_OneArgs = {
  object: Billing_Promo_Codes_Insert_Input;
  on_conflict?: InputMaybe<Billing_Promo_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_CitiesArgs = {
  objects: Array<Dms_Cities_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Cities_OneArgs = {
  object: Dms_Cities_Insert_Input;
  on_conflict?: InputMaybe<Dms_Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Group_InsuredsArgs = {
  objects: Array<Dms_Group_Insureds_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Group_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Group_Insureds_OneArgs = {
  object: Dms_Group_Insureds_Insert_Input;
  on_conflict?: InputMaybe<Dms_Group_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_GroupsArgs = {
  objects: Array<Dms_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Groups_OneArgs = {
  object: Dms_Groups_Insert_Input;
  on_conflict?: InputMaybe<Dms_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_InsurantsArgs = {
  objects: Array<Dms_Insurants_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Insurants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Insurants_OneArgs = {
  object: Dms_Insurants_Insert_Input;
  on_conflict?: InputMaybe<Dms_Insurants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_InsuredsArgs = {
  objects: Array<Dms_Insureds_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Insureds_OneArgs = {
  object: Dms_Insureds_Insert_Input;
  on_conflict?: InputMaybe<Dms_Insureds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_LinksArgs = {
  objects: Array<Dms_Order_Links_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Order_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_Links_OneArgs = {
  object: Dms_Order_Links_Insert_Input;
  on_conflict?: InputMaybe<Dms_Order_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_NumbersArgs = {
  objects: Array<Dms_Order_Numbers_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Order_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Order_Numbers_OneArgs = {
  object: Dms_Order_Numbers_Insert_Input;
  on_conflict?: InputMaybe<Dms_Order_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_OrdersArgs = {
  objects: Array<Dms_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Orders_OneArgs = {
  object: Dms_Orders_Insert_Input;
  on_conflict?: InputMaybe<Dms_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_ProductsArgs = {
  objects: Array<Dms_Products_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Products_OneArgs = {
  object: Dms_Products_Insert_Input;
  on_conflict?: InputMaybe<Dms_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Promo_CodesArgs = {
  objects: Array<Dms_Promo_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Promo_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Promo_Codes_OneArgs = {
  object: Dms_Promo_Codes_Insert_Input;
  on_conflict?: InputMaybe<Dms_Promo_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_StatusesArgs = {
  objects: Array<Dms_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Statuses_OneArgs = {
  object: Dms_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Dms_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_UsersArgs = {
  objects: Array<Dms_Users_Insert_Input>;
  on_conflict?: InputMaybe<Dms_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dms_Users_OneArgs = {
  object: Dms_Users_Insert_Input;
  on_conflict?: InputMaybe<Dms_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fintech_CardsArgs = {
  objects: Array<Fintech_Cards_Insert_Input>;
  on_conflict?: InputMaybe<Fintech_Cards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fintech_Cards_OneArgs = {
  object: Fintech_Cards_Insert_Input;
  on_conflict?: InputMaybe<Fintech_Cards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fintech_ClientsArgs = {
  objects: Array<Fintech_Clients_Insert_Input>;
  on_conflict?: InputMaybe<Fintech_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fintech_Clients_OneArgs = {
  object: Fintech_Clients_Insert_Input;
  on_conflict?: InputMaybe<Fintech_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fintech_OrdersArgs = {
  objects: Array<Fintech_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Fintech_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fintech_Orders_OneArgs = {
  object: Fintech_Orders_Insert_Input;
  on_conflict?: InputMaybe<Fintech_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Amocrm_ContactsArgs = {
  objects: Array<Mobile_Amocrm_Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Amocrm_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Amocrm_Contacts_OneArgs = {
  object: Mobile_Amocrm_Contacts_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Amocrm_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Amocrm_LeadsArgs = {
  objects: Array<Mobile_Amocrm_Leads_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Amocrm_Leads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Amocrm_Leads_OneArgs = {
  object: Mobile_Amocrm_Leads_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Amocrm_Leads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Amocrm_TagsArgs = {
  objects: Array<Mobile_Amocrm_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Amocrm_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Amocrm_Tags_OneArgs = {
  object: Mobile_Amocrm_Tags_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Amocrm_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_AppointmentsArgs = {
  objects: Array<Mobile_Appointments_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Appointments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Appointments_OneArgs = {
  object: Mobile_Appointments_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Appointments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Auto_RepliesArgs = {
  objects: Array<Mobile_Auto_Replies_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Auto_Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Auto_Replies_OneArgs = {
  object: Mobile_Auto_Replies_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Auto_Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Auto_Reply_DocumentsArgs = {
  objects: Array<Mobile_Auto_Reply_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Auto_Reply_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Auto_Reply_Documents_OneArgs = {
  object: Mobile_Auto_Reply_Documents_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Auto_Reply_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Auto_Reply_MediafilesArgs = {
  objects: Array<Mobile_Auto_Reply_Mediafiles_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Auto_Reply_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Auto_Reply_Mediafiles_OneArgs = {
  object: Mobile_Auto_Reply_Mediafiles_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Auto_Reply_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Bank_AccountsArgs = {
  objects: Array<Mobile_Bank_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Bank_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Bank_Accounts_OneArgs = {
  object: Mobile_Bank_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Bank_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Blocked_SitesArgs = {
  objects: Array<Mobile_Blocked_Sites_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Blocked_Sites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Blocked_Sites_OneArgs = {
  object: Mobile_Blocked_Sites_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Blocked_Sites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_BlockedsArgs = {
  objects: Array<Mobile_Blockeds_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Blockeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Blockeds_OneArgs = {
  object: Mobile_Blockeds_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Blockeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Calorie_NormsArgs = {
  objects: Array<Mobile_Calorie_Norms_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Calorie_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Calorie_Norms_OneArgs = {
  object: Mobile_Calorie_Norms_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Calorie_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Calorie_NowsArgs = {
  objects: Array<Mobile_Calorie_Nows_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Calorie_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Calorie_Nows_OneArgs = {
  object: Mobile_Calorie_Nows_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Calorie_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_CaloriesArgs = {
  objects: Array<Mobile_Calories_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Calories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Calories_OneArgs = {
  object: Mobile_Calories_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Calories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_CategoriesArgs = {
  objects: Array<Mobile_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Categories_OneArgs = {
  object: Mobile_Categories_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Category_NamesArgs = {
  objects: Array<Mobile_Category_Names_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Category_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Category_Names_OneArgs = {
  object: Mobile_Category_Names_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Category_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Black_ListsArgs = {
  objects: Array<Mobile_Chat_Black_Lists_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Black_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Black_Lists_OneArgs = {
  object: Mobile_Chat_Black_Lists_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Black_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_DefaultsArgs = {
  objects: Array<Mobile_Chat_Defaults_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Defaults_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Defaults_OneArgs = {
  object: Mobile_Chat_Defaults_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Defaults_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_File_TypesArgs = {
  objects: Array<Mobile_Chat_File_Types_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_File_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_File_Types_OneArgs = {
  object: Mobile_Chat_File_Types_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_File_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_CommentsArgs = {
  objects: Array<Mobile_Chat_Message_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Message_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_Comments_OneArgs = {
  object: Mobile_Chat_Message_Comments_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Message_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_FilesArgs = {
  objects: Array<Mobile_Chat_Message_Files_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Message_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_Files_OneArgs = {
  object: Mobile_Chat_Message_Files_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Message_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_NotificationsArgs = {
  objects: Array<Mobile_Chat_Message_Notifications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_Notifications_OneArgs = {
  object: Mobile_Chat_Message_Notifications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_TypesArgs = {
  objects: Array<Mobile_Chat_Message_Types_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Message_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Message_Types_OneArgs = {
  object: Mobile_Chat_Message_Types_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Message_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_MessagesArgs = {
  objects: Array<Mobile_Chat_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Messages_OneArgs = {
  object: Mobile_Chat_Messages_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_RolesArgs = {
  objects: Array<Mobile_Chat_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Roles_OneArgs = {
  object: Mobile_Chat_Roles_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_TypesArgs = {
  objects: Array<Mobile_Chat_Types_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chat_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chat_Types_OneArgs = {
  object: Mobile_Chat_Types_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chat_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ChatsArgs = {
  objects: Array<Mobile_Chats_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Chats_OneArgs = {
  object: Mobile_Chats_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ClientsArgs = {
  objects: Array<Mobile_Clients_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Clients_OneArgs = {
  object: Mobile_Clients_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Comment_Like_NotificationsArgs = {
  objects: Array<Mobile_Comment_Like_Notifications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Comment_Like_Notifications_OneArgs = {
  object: Mobile_Comment_Like_Notifications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Comment_LikesArgs = {
  objects: Array<Mobile_Comment_Likes_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Comment_Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Comment_Likes_OneArgs = {
  object: Mobile_Comment_Likes_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Comment_Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Comment_NotificationsArgs = {
  objects: Array<Mobile_Comment_Notifications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Comment_Notifications_OneArgs = {
  object: Mobile_Comment_Notifications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_CommentsArgs = {
  objects: Array<Mobile_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Comments_OneArgs = {
  object: Mobile_Comments_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Confirmation_CodesArgs = {
  objects: Array<Mobile_Confirmation_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Confirmation_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Confirmation_Codes_OneArgs = {
  object: Mobile_Confirmation_Codes_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Confirmation_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ContactsArgs = {
  objects: Array<Mobile_Contacts_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Contacts_OneArgs = {
  object: Mobile_Contacts_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ContentsArgs = {
  objects: Array<Mobile_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Contents_OneArgs = {
  object: Mobile_Contents_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Device_InfosArgs = {
  objects: Array<Mobile_Device_Infos_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Device_Infos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Device_Infos_OneArgs = {
  object: Mobile_Device_Infos_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Device_Infos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Device_SettingsArgs = {
  objects: Array<Mobile_Device_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Device_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Device_Settings_OneArgs = {
  object: Mobile_Device_Settings_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Device_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_DevicesArgs = {
  objects: Array<Mobile_Devices_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Devices_OneArgs = {
  object: Mobile_Devices_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Document_MediafilesArgs = {
  objects: Array<Mobile_Document_Mediafiles_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Document_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Document_Mediafiles_OneArgs = {
  object: Mobile_Document_Mediafiles_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Document_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_DocumentsArgs = {
  objects: Array<Mobile_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Documents_OneArgs = {
  object: Mobile_Documents_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Education_MediafilesArgs = {
  objects: Array<Mobile_Education_Mediafiles_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Education_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Education_Mediafiles_OneArgs = {
  object: Mobile_Education_Mediafiles_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Education_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_EducationsArgs = {
  objects: Array<Mobile_Educations_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Educations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Educations_OneArgs = {
  object: Mobile_Educations_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Educations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_EmojisArgs = {
  objects: Array<Mobile_Emojis_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Emojis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Emojis_OneArgs = {
  object: Mobile_Emojis_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Emojis_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Fixed_Chat_MessagesArgs = {
  objects: Array<Mobile_Fixed_Chat_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Fixed_Chat_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Fixed_Chat_Messages_OneArgs = {
  object: Mobile_Fixed_Chat_Messages_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Fixed_Chat_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Fixed_CommentsArgs = {
  objects: Array<Mobile_Fixed_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Fixed_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Fixed_Comments_OneArgs = {
  object: Mobile_Fixed_Comments_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Fixed_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Founder_MediafilesArgs = {
  objects: Array<Mobile_Founder_Mediafiles_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Founder_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Founder_Mediafiles_OneArgs = {
  object: Mobile_Founder_Mediafiles_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Founder_Mediafiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_FoundersArgs = {
  objects: Array<Mobile_Founders_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Founders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Founders_OneArgs = {
  object: Mobile_Founders_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Founders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Invite_EmailsArgs = {
  objects: Array<Mobile_Invite_Emails_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Invite_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Invite_Emails_OneArgs = {
  object: Mobile_Invite_Emails_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Invite_Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Like_NotificationsArgs = {
  objects: Array<Mobile_Like_Notifications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Like_Notifications_OneArgs = {
  object: Mobile_Like_Notifications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_LikesArgs = {
  objects: Array<Mobile_Likes_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Likes_OneArgs = {
  object: Mobile_Likes_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Link_SnippetsArgs = {
  objects: Array<Mobile_Link_Snippets_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Link_Snippets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Link_Snippets_OneArgs = {
  object: Mobile_Link_Snippets_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Link_Snippets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Locked_Deleted_Chat_MessagesArgs = {
  objects: Array<Mobile_Locked_Deleted_Chat_Messages_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Locked_Deleted_Chat_Messages_OneArgs = {
  object: Mobile_Locked_Deleted_Chat_Messages_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Mediafile_Status_NamesArgs = {
  objects: Array<Mobile_Mediafile_Status_Names_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Mediafile_Status_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Mediafile_Status_Names_OneArgs = {
  object: Mobile_Mediafile_Status_Names_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Mediafile_Status_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Mediafile_StatusesArgs = {
  objects: Array<Mobile_Mediafile_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Mediafile_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Mediafile_Statuses_OneArgs = {
  object: Mobile_Mediafile_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Mediafile_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Medical_Concierge_OrdersArgs = {
  objects: Array<Mobile_Medical_Concierge_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Medical_Concierge_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Medical_Concierge_Orders_OneArgs = {
  object: Mobile_Medical_Concierge_Orders_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Medical_Concierge_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Medical_Concierge_ProductsArgs = {
  objects: Array<Mobile_Medical_Concierge_Products_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Medical_Concierge_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Medical_Concierge_Products_OneArgs = {
  object: Mobile_Medical_Concierge_Products_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Medical_Concierge_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Mention_NotificationsArgs = {
  objects: Array<Mobile_Mention_Notifications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Mention_Notifications_OneArgs = {
  object: Mobile_Mention_Notifications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_MentionsArgs = {
  objects: Array<Mobile_Mentions_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Mentions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Mentions_OneArgs = {
  object: Mobile_Mentions_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Mentions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Metric_NamesArgs = {
  objects: Array<Mobile_Metric_Names_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Metric_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Metric_Names_OneArgs = {
  object: Mobile_Metric_Names_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Metric_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Metric_ParamsArgs = {
  objects: Array<Mobile_Metric_Params_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Metric_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Metric_Params_OneArgs = {
  object: Mobile_Metric_Params_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Metric_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Metric_TypesArgs = {
  objects: Array<Mobile_Metric_Types_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Metric_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Metric_Types_OneArgs = {
  object: Mobile_Metric_Types_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Metric_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_MetricsArgs = {
  objects: Array<Mobile_Metrics_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Metrics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Metrics_OneArgs = {
  object: Mobile_Metrics_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Metrics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Notification_MessagesArgs = {
  objects: Array<Mobile_Notification_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Notification_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Notification_Messages_OneArgs = {
  object: Mobile_Notification_Messages_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Notification_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_NotificationsArgs = {
  objects: Array<Mobile_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Notifications_OneArgs = {
  object: Mobile_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Notifications_StatusesArgs = {
  objects: Array<Mobile_Notifications_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Notifications_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Notifications_Statuses_OneArgs = {
  object: Mobile_Notifications_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Notifications_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Order_NotificationsArgs = {
  objects: Array<Mobile_Order_Notifications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Order_Notifications_OneArgs = {
  object: Mobile_Order_Notifications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Order_ProfessionalsArgs = {
  objects: Array<Mobile_Order_Professionals_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Order_Professionals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Order_Professionals_OneArgs = {
  object: Mobile_Order_Professionals_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Order_Professionals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_OrdersArgs = {
  objects: Array<Mobile_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Orders_OneArgs = {
  object: Mobile_Orders_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_OrgsArgs = {
  objects: Array<Mobile_Orgs_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Orgs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Orgs_OneArgs = {
  object: Mobile_Orgs_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Orgs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_CategoriesArgs = {
  objects: Array<Mobile_Param_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Param_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_Categories_OneArgs = {
  object: Mobile_Param_Categories_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Param_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_Categories_ParamsArgs = {
  objects: Array<Mobile_Param_Categories_Params_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_Categories_Params_OneArgs = {
  object: Mobile_Param_Categories_Params_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_Category_NamesArgs = {
  objects: Array<Mobile_Param_Category_Names_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Param_Category_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_Category_Names_OneArgs = {
  object: Mobile_Param_Category_Names_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Param_Category_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_NamesArgs = {
  objects: Array<Mobile_Param_Names_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Param_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Param_Names_OneArgs = {
  object: Mobile_Param_Names_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Param_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ParamsArgs = {
  objects: Array<Mobile_Params_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Params_OneArgs = {
  object: Mobile_Params_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Params_ServicesArgs = {
  objects: Array<Mobile_Params_Services_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Params_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Params_Services_OneArgs = {
  object: Mobile_Params_Services_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Params_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Personal_Info_ViewsArgs = {
  objects: Array<Mobile_Personal_Info_Views_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Personal_Info_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Personal_Info_Views_OneArgs = {
  object: Mobile_Personal_Info_Views_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Personal_Info_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Personal_InfosArgs = {
  objects: Array<Mobile_Personal_Infos_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Personal_Infos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Personal_Infos_OneArgs = {
  object: Mobile_Personal_Infos_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Personal_Infos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_PhonesArgs = {
  objects: Array<Mobile_Phones_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Phones_OneArgs = {
  object: Mobile_Phones_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Post_ParamsArgs = {
  objects: Array<Mobile_Post_Params_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Post_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Post_Params_OneArgs = {
  object: Mobile_Post_Params_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Post_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_PostsArgs = {
  objects: Array<Mobile_Posts_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Posts_OneArgs = {
  object: Mobile_Posts_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pro_Document_StatusesArgs = {
  objects: Array<Mobile_Pro_Document_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Pro_Document_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pro_Document_Statuses_OneArgs = {
  object: Mobile_Pro_Document_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Pro_Document_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pro_ProfessionsArgs = {
  objects: Array<Mobile_Pro_Professions_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Pro_Professions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pro_Professions_OneArgs = {
  object: Mobile_Pro_Professions_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Pro_Professions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pro_TypesArgs = {
  objects: Array<Mobile_Pro_Types_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Pro_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pro_Types_OneArgs = {
  object: Mobile_Pro_Types_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Pro_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ProblemsArgs = {
  objects: Array<Mobile_Problems_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Problems_OneArgs = {
  object: Mobile_Problems_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Problems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Profession_NamesArgs = {
  objects: Array<Mobile_Profession_Names_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Profession_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Profession_Names_OneArgs = {
  object: Mobile_Profession_Names_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Profession_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ProfessionsArgs = {
  objects: Array<Mobile_Professions_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Professions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Professions_OneArgs = {
  object: Mobile_Professions_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Professions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Profile_DeletedsArgs = {
  objects: Array<Mobile_Profile_Deleteds_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Profile_Deleteds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Profile_Deleteds_OneArgs = {
  object: Mobile_Profile_Deleteds_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Profile_Deleteds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Profile_LocksArgs = {
  objects: Array<Mobile_Profile_Locks_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Profile_Locks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Profile_Locks_OneArgs = {
  object: Mobile_Profile_Locks_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Profile_Locks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ProfilesArgs = {
  objects: Array<Mobile_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Profiles_OneArgs = {
  object: Mobile_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ProsArgs = {
  objects: Array<Mobile_Pros_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Pros_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pros_OneArgs = {
  object: Mobile_Pros_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Pros_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pulse_NormsArgs = {
  objects: Array<Mobile_Pulse_Norms_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Pulse_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pulse_Norms_OneArgs = {
  object: Mobile_Pulse_Norms_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Pulse_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pulse_NowsArgs = {
  objects: Array<Mobile_Pulse_Nows_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Pulse_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pulse_Nows_OneArgs = {
  object: Mobile_Pulse_Nows_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Pulse_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_PulsesArgs = {
  objects: Array<Mobile_Pulses_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Pulses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Pulses_OneArgs = {
  object: Mobile_Pulses_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Pulses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ReactionsArgs = {
  objects: Array<Mobile_Reactions_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Reactions_OneArgs = {
  object: Mobile_Reactions_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ReportsArgs = {
  objects: Array<Mobile_Reports_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Reports_OneArgs = {
  object: Mobile_Reports_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_AgreementsArgs = {
  objects: Array<Mobile_Service_Agreements_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Agreements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Agreements_OneArgs = {
  object: Mobile_Service_Agreements_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Agreements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Answer_ReactionsArgs = {
  objects: Array<Mobile_Service_Answer_Reactions_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Answer_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Answer_Reactions_OneArgs = {
  object: Mobile_Service_Answer_Reactions_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Answer_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_AnswersArgs = {
  objects: Array<Mobile_Service_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Answers_OneArgs = {
  object: Mobile_Service_Answers_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_DocumentsArgs = {
  objects: Array<Mobile_Service_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Documents_OneArgs = {
  object: Mobile_Service_Documents_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_FavoritesArgs = {
  objects: Array<Mobile_Service_Favorites_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Favorites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Favorites_OneArgs = {
  object: Mobile_Service_Favorites_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Favorites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Free_PurchasesArgs = {
  objects: Array<Mobile_Service_Free_Purchases_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Free_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Free_Purchases_OneArgs = {
  object: Mobile_Service_Free_Purchases_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Free_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_ImagesArgs = {
  objects: Array<Mobile_Service_Images_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Images_OneArgs = {
  object: Mobile_Service_Images_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_LikesArgs = {
  objects: Array<Mobile_Service_Likes_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Likes_OneArgs = {
  object: Mobile_Service_Likes_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_List_DocumentsArgs = {
  objects: Array<Mobile_Service_List_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_List_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_List_Documents_OneArgs = {
  object: Mobile_Service_List_Documents_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_List_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_List_PeriodicitiesArgs = {
  objects: Array<Mobile_Service_List_Periodicities_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_List_Periodicities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_List_Periodicities_OneArgs = {
  object: Mobile_Service_List_Periodicities_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_List_Periodicities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_ListsArgs = {
  objects: Array<Mobile_Service_Lists_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Lists_OneArgs = {
  object: Mobile_Service_Lists_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_OrdersArgs = {
  objects: Array<Mobile_Service_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Orders_OneArgs = {
  object: Mobile_Service_Orders_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_PeriodicitiesArgs = {
  objects: Array<Mobile_Service_Periodicities_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Periodicities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Periodicities_OneArgs = {
  object: Mobile_Service_Periodicities_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Periodicities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_PurchasesArgs = {
  objects: Array<Mobile_Service_Purchases_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Purchases_OneArgs = {
  object: Mobile_Service_Purchases_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_QuestionsArgs = {
  objects: Array<Mobile_Service_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Questions_OneArgs = {
  object: Mobile_Service_Questions_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Rate_CommentsArgs = {
  objects: Array<Mobile_Service_Rate_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Rate_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Rate_Comments_OneArgs = {
  object: Mobile_Service_Rate_Comments_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Rate_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Rate_ImagesArgs = {
  objects: Array<Mobile_Service_Rate_Images_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Rate_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Rate_Images_OneArgs = {
  object: Mobile_Service_Rate_Images_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Rate_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Rate_ReactionsArgs = {
  objects: Array<Mobile_Service_Rate_Reactions_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Rate_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Rate_Reactions_OneArgs = {
  object: Mobile_Service_Rate_Reactions_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Rate_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_RatesArgs = {
  objects: Array<Mobile_Service_Rates_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Rates_OneArgs = {
  object: Mobile_Service_Rates_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Rates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_StatusesArgs = {
  objects: Array<Mobile_Service_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Statuses_OneArgs = {
  object: Mobile_Service_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tag_AgesArgs = {
  objects: Array<Mobile_Service_Tag_Ages_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Tag_Ages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tag_Ages_OneArgs = {
  object: Mobile_Service_Tag_Ages_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Tag_Ages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tag_Target_GroupsArgs = {
  objects: Array<Mobile_Service_Tag_Target_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Tag_Target_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tag_Target_Groups_OneArgs = {
  object: Mobile_Service_Tag_Target_Groups_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Tag_Target_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tag_TargetsArgs = {
  objects: Array<Mobile_Service_Tag_Targets_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Tag_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tag_Targets_OneArgs = {
  object: Mobile_Service_Tag_Targets_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Tag_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tariff_DocumentsArgs = {
  objects: Array<Mobile_Service_Tariff_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Tariff_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tariff_Documents_OneArgs = {
  object: Mobile_Service_Tariff_Documents_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Tariff_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_TariffsArgs = {
  objects: Array<Mobile_Service_Tariffs_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Tariffs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Tariffs_OneArgs = {
  object: Mobile_Service_Tariffs_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Tariffs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Type_NamesArgs = {
  objects: Array<Mobile_Service_Type_Names_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Type_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Type_Names_OneArgs = {
  object: Mobile_Service_Type_Names_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Type_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_TypesArgs = {
  objects: Array<Mobile_Service_Types_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Service_Types_OneArgs = {
  object: Mobile_Service_Types_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Service_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_ServicesArgs = {
  objects: Array<Mobile_Services_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Services_OneArgs = {
  object: Mobile_Services_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_SettingsArgs = {
  objects: Array<Mobile_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Settings_OneArgs = {
  object: Mobile_Settings_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Share_PostsArgs = {
  objects: Array<Mobile_Share_Posts_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Share_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Share_Posts_OneArgs = {
  object: Mobile_Share_Posts_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Share_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Sleep_NormsArgs = {
  objects: Array<Mobile_Sleep_Norms_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Sleep_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Sleep_Norms_OneArgs = {
  object: Mobile_Sleep_Norms_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Sleep_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Sleep_NowsArgs = {
  objects: Array<Mobile_Sleep_Nows_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Sleep_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Sleep_Nows_OneArgs = {
  object: Mobile_Sleep_Nows_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Sleep_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_SleepsArgs = {
  objects: Array<Mobile_Sleeps_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Sleeps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Sleeps_OneArgs = {
  object: Mobile_Sleeps_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Sleeps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_SlotsArgs = {
  objects: Array<Mobile_Slots_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Slots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Slots_OneArgs = {
  object: Mobile_Slots_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Slots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Status_MessagesArgs = {
  objects: Array<Mobile_Status_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Status_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Status_Messages_OneArgs = {
  object: Mobile_Status_Messages_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Status_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Step_NormsArgs = {
  objects: Array<Mobile_Step_Norms_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Step_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Step_Norms_OneArgs = {
  object: Mobile_Step_Norms_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Step_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Step_NowsArgs = {
  objects: Array<Mobile_Step_Nows_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Step_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Step_Nows_OneArgs = {
  object: Mobile_Step_Nows_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Step_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_StepsArgs = {
  objects: Array<Mobile_Steps_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Steps_OneArgs = {
  object: Mobile_Steps_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Subscribe_NotificationsArgs = {
  objects: Array<Mobile_Subscribe_Notifications_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Subscribe_Notifications_OneArgs = {
  object: Mobile_Subscribe_Notifications_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_SubscribesArgs = {
  objects: Array<Mobile_Subscribes_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Subscribes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Subscribes_OneArgs = {
  object: Mobile_Subscribes_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Subscribes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Support_MessagesArgs = {
  objects: Array<Mobile_Support_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Support_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Support_Messages_OneArgs = {
  object: Mobile_Support_Messages_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Support_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Support_TopicsArgs = {
  objects: Array<Mobile_Support_Topics_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Support_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Support_Topics_OneArgs = {
  object: Mobile_Support_Topics_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Support_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Tag_AgesArgs = {
  objects: Array<Mobile_Tag_Ages_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Tag_Ages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Tag_Ages_OneArgs = {
  object: Mobile_Tag_Ages_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Tag_Ages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Tag_Target_GroupsArgs = {
  objects: Array<Mobile_Tag_Target_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Tag_Target_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Tag_Target_Groups_OneArgs = {
  object: Mobile_Tag_Target_Groups_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Tag_Target_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Tag_TargetsArgs = {
  objects: Array<Mobile_Tag_Targets_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Tag_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Tag_Targets_OneArgs = {
  object: Mobile_Tag_Targets_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Tag_Targets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Type_PhonesArgs = {
  objects: Array<Mobile_Type_Phones_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Type_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Type_Phones_OneArgs = {
  object: Mobile_Type_Phones_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Type_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Type_ProfilesArgs = {
  objects: Array<Mobile_Type_Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Type_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Type_Profiles_OneArgs = {
  object: Mobile_Type_Profiles_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Type_Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Upload_Pro_DocumentsArgs = {
  objects: Array<Mobile_Upload_Pro_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Upload_Pro_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Upload_Pro_Documents_OneArgs = {
  object: Mobile_Upload_Pro_Documents_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Upload_Pro_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_User_Chat_RightsArgs = {
  objects: Array<Mobile_User_Chat_Rights_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_User_Chat_Rights_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_User_Chat_Rights_OneArgs = {
  object: Mobile_User_Chat_Rights_Insert_Input;
  on_conflict?: InputMaybe<Mobile_User_Chat_Rights_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_User_ChatsArgs = {
  objects: Array<Mobile_User_Chats_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_User_Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_User_Chats_OneArgs = {
  object: Mobile_User_Chats_Insert_Input;
  on_conflict?: InputMaybe<Mobile_User_Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_View_Chat_MessagesArgs = {
  objects: Array<Mobile_View_Chat_Messages_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_View_Chat_Messages_OneArgs = {
  object: Mobile_View_Chat_Messages_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Water_NormsArgs = {
  objects: Array<Mobile_Water_Norms_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Water_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Water_Norms_OneArgs = {
  object: Mobile_Water_Norms_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Water_Norms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Water_NowsArgs = {
  objects: Array<Mobile_Water_Nows_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Water_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Water_Nows_OneArgs = {
  object: Mobile_Water_Nows_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Water_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_WatersArgs = {
  objects: Array<Mobile_Waters_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Waters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Waters_OneArgs = {
  object: Mobile_Waters_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Waters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Weight_NowsArgs = {
  objects: Array<Mobile_Weight_Nows_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Weight_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Weight_Nows_OneArgs = {
  object: Mobile_Weight_Nows_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Weight_Nows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_WeightsArgs = {
  objects: Array<Mobile_Weights_Insert_Input>;
  on_conflict?: InputMaybe<Mobile_Weights_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mobile_Weights_OneArgs = {
  object: Mobile_Weights_Insert_Input;
  on_conflict?: InputMaybe<Mobile_Weights_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_BalancesArgs = {
  objects: Array<Partner_Balances_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Balances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Balances_OneArgs = {
  object: Partner_Balances_Insert_Input;
  on_conflict?: InputMaybe<Partner_Balances_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_SalesArgs = {
  objects: Array<Partner_Log_Vzr_Sales_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Sales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_Sales_OneArgs = {
  object: Partner_Log_Vzr_Sales_Insert_Input;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Sales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_SearchesArgs = {
  objects: Array<Partner_Log_Vzr_Searches_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Searches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_Searches_OneArgs = {
  object: Partner_Log_Vzr_Searches_Insert_Input;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Searches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_ViewsArgs = {
  objects: Array<Partner_Log_Vzr_Views_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Log_Vzr_Views_OneArgs = {
  object: Partner_Log_Vzr_Views_Insert_Input;
  on_conflict?: InputMaybe<Partner_Log_Vzr_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Place_Type_IntegrationsArgs = {
  objects: Array<Partner_Place_Type_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Place_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Place_Type_Integrations_OneArgs = {
  object: Partner_Place_Type_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Partner_Place_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_PlacesArgs = {
  objects: Array<Partner_Places_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Places_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Places_OneArgs = {
  object: Partner_Places_Insert_Input;
  on_conflict?: InputMaybe<Partner_Places_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Sale_TypesArgs = {
  objects: Array<Partner_Sale_Types_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Sale_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Sale_Types_OneArgs = {
  object: Partner_Sale_Types_Insert_Input;
  on_conflict?: InputMaybe<Partner_Sale_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_IntegrationsArgs = {
  objects: Array<Partner_Status_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Status_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_Integrations_OneArgs = {
  object: Partner_Status_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Partner_Status_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_TransactionsArgs = {
  objects: Array<Partner_Status_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Status_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Status_Transactions_OneArgs = {
  object: Partner_Status_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Partner_Status_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_ThemesArgs = {
  objects: Array<Partner_Themes_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Themes_OneArgs = {
  object: Partner_Themes_Insert_Input;
  on_conflict?: InputMaybe<Partner_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_IntegrationsArgs = {
  objects: Array<Partner_Type_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_Integrations_OneArgs = {
  object: Partner_Type_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Partner_Type_Integrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_TransactionsArgs = {
  objects: Array<Partner_Type_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Type_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Type_Transactions_OneArgs = {
  object: Partner_Type_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Partner_Type_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_User_TypesArgs = {
  objects: Array<Partner_User_Types_Insert_Input>;
  on_conflict?: InputMaybe<Partner_User_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_User_Types_OneArgs = {
  object: Partner_User_Types_Insert_Input;
  on_conflict?: InputMaybe<Partner_User_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_UsersArgs = {
  objects: Array<Partner_Users_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Users_OneArgs = {
  object: Partner_Users_Insert_Input;
  on_conflict?: InputMaybe<Partner_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_UsersArgs = {
  objects: Array<Service_Users_Insert_Input>;
  on_conflict?: InputMaybe<Service_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Users_OneArgs = {
  object: Service_Users_Insert_Input;
  on_conflict?: InputMaybe<Service_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootMobileAmocrmCreateLeadArgs = {
  arg: MobileAmocrmCreateLeadInput;
};


/** mutation root */
export type Mutation_RootMobileCreateLinkSnippetArgs = {
  arg: MobileCreateLinkSnippetInput;
};


/** mutation root */
export type Mutation_RootMobileCustomInsertChatMessageCommentArgs = {
  arg: MobileCustomInsertChatMessageCommentInput;
};


/** mutation root */
export type Mutation_RootMobileCustomInsertCommentArgs = {
  arg: MobileCustomInsertCommentInput;
};


/** mutation root */
export type Mutation_RootMobileDeleteAutoreplyArgs = {
  arg: MobileDeleteAutoreplyInput;
};


/** mutation root */
export type Mutation_RootMobileDeleteCommentsByPkArgs = {
  arg: MobileDeleteCommentsByPkInput;
};


/** mutation root */
export type Mutation_RootMobileDeleteDocumentToProArgs = {
  arg: MobileDeleteDocumentToProInput;
};


/** mutation root */
export type Mutation_RootMobileDeletePersonalUserChatsArgs = {
  arg: MobileDeletePersonalUserChatsInput;
};


/** mutation root */
export type Mutation_RootMobileDeleteServiceArgs = {
  arg: MobileDeleteServiceInput;
};


/** mutation root */
export type Mutation_RootMobileGetFreeGuideArgs = {
  arg: MobileGetFreeGuideInput;
};


/** mutation root */
export type Mutation_RootMobileHardDeleteUserArgs = {
  arg: MobileHardDeleteUserInput;
};


/** mutation root */
export type Mutation_RootMobileHardDeleteUserForTestArgs = {
  arg: MobileHardDeleteUserForTestInput;
};


/** mutation root */
export type Mutation_RootMobileInsertButtonCommandMedconciergeArgs = {
  arg: MobileInsertButtonCommandMedconciergeInput;
};


/** mutation root */
export type Mutation_RootMobileInsertChatMessagesOneArgs = {
  arg: MobileInsertChatMessagesOneInput;
};


/** mutation root */
export type Mutation_RootMobileInsertChatsOneArgs = {
  arg: MobileInsertChatsOneInput;
};


/** mutation root */
export type Mutation_RootMobileInsertMobileCommentsOneArgs = {
  arg: MobileInsertMobileCommentsOneInput;
};


/** mutation root */
export type Mutation_RootMobileInsertParamsServicesArgs = {
  arg: MobileInsertParamsServicesInput;
};


/** mutation root */
export type Mutation_RootMobileInsertPostParamsArgs = {
  arg: MobileInsertPostParamsInput;
};


/** mutation root */
export type Mutation_RootMobileInsertProArgs = {
  params: MobileInsertProInput;
};


/** mutation root */
export type Mutation_RootMobileInsertProfileLocksOneArgs = {
  arg: MobileInsertProfileLocksOneInput;
};


/** mutation root */
export type Mutation_RootMobileInsertReportsArgs = {
  arg: MobileInsertReportsInput;
};


/** mutation root */
export type Mutation_RootMobileInsertServiceListsArgs = {
  arg: MobileInsertServiceListsInput;
};


/** mutation root */
export type Mutation_RootMobileInsertServiceRatesOneArgs = {
  arg: MobileInsertServiceRatesOneInput;
};


/** mutation root */
export type Mutation_RootMobileInsertServicesOneArgs = {
  arg: MobileInsertServicesOneInput;
};


/** mutation root */
export type Mutation_RootMobileInsertSubscribesOneArgs = {
  arg: MobileInsertSubscribesOneInput;
};


/** mutation root */
export type Mutation_RootMobileMedconciergeTimerButtonArgs = {
  arg: MobileMedconciergeTimerButtonInput;
};


/** mutation root */
export type Mutation_RootMobilePurchasingMedicalConciergeArgs = {
  arg: MobilePurchasingMedicalConciergeInput;
};


/** mutation root */
export type Mutation_RootMobilePurchasingMedicalConciergeRetryArgs = {
  arg: MobilePurchasingMedicalConciergeRetryInput;
};


/** mutation root */
export type Mutation_RootMobilePurchasingPersonalProgramArgs = {
  arg: MobilePurchasingPersonalProgramInput;
};


/** mutation root */
export type Mutation_RootMobilePurchasingServiceArgs = {
  arg: MobilePurchasingServiceInput;
};


/** mutation root */
export type Mutation_RootMobilePurchasingServiceRetryArgs = {
  arg: MobilePurchasingServiceRetryInput;
};


/** mutation root */
export type Mutation_RootMobileSearchByServiceLikesArgs = {
  arg: MobileSearchByServiceLikesInput;
};


/** mutation root */
export type Mutation_RootMobileServiceCheckingArgs = {
  arg: MobileServiceCheckingInput;
};


/** mutation root */
export type Mutation_RootMobileServiceHiddenArgs = {
  arg: MobileServiceHiddenInput;
};


/** mutation root */
export type Mutation_RootMobileSetOperatorProfileArgs = {
  arg: MobileSetOperatorProfileInput;
};


/** mutation root */
export type Mutation_RootMobileSetProfileArgs = {
  arg: MobileSetProfileInput;
};


/** mutation root */
export type Mutation_RootMobileSetProfileMedBotArgs = {
  arg: MobileSetProfileMedBotInput;
};


/** mutation root */
export type Mutation_RootMobileSoftDeleteCommentArgs = {
  arg: MobileSoftDeleteCommentInput;
};


/** mutation root */
export type Mutation_RootMobileSoftDeleteUserArgs = {
  arg: MobileSoftDeleteUserInput;
};


/** mutation root */
export type Mutation_RootMobileSoftDeleteUserFotTestArgs = {
  arg: MobileSoftDeleteUserFotTestInput;
};


/** mutation root */
export type Mutation_RootMobileUpdateChatMessagesStatusMessageArgs = {
  arg: MobileUpdateChatMessagesStatusMessageInput;
};


/** mutation root */
export type Mutation_RootMobileUpdateDocumentToAutoreplyArgs = {
  arg: MobileUpdateDocumentToAutoreplyInput;
};


/** mutation root */
export type Mutation_RootMobileUpdateDocumentToServiceArgs = {
  arg: MobileUpdateDocumentToServiceInput;
};


/** mutation root */
export type Mutation_RootMobileUpdateMediaFileToServiceArgs = {
  arg: MobileUpdateMediaFileToServiceInput;
};


/** mutation root */
export type Mutation_RootMobileUpdateMediafileToAutoreplyArgs = {
  arg: MobileUpdateMediafileToAutoreplyInput;
};


/** mutation root */
export type Mutation_RootMobileUpdateMobileProfileAvatarArgs = {
  arg: MobileUpdateMobileProfileAvatarInput;
};


/** mutation root */
export type Mutation_RootMobileUpdateServiceArgs = {
  arg: MobileUpdateServiceInput;
};


/** mutation root */
export type Mutation_RootMobileUploadAgreementToServiceArgs = {
  arg: MobileUploadAgreementToServiceInput;
};


/** mutation root */
export type Mutation_RootMobileUploadDocumentToAutoreplyArgs = {
  arg: MobileUploadDocumentToAutoreplyInput;
};


/** mutation root */
export type Mutation_RootMobileUploadDocumentToProArgs = {
  arg: MobileUploadDocumentToProInput;
};


/** mutation root */
export type Mutation_RootMobileUploadDocumentToServiceArgs = {
  arg: MobileUploadDocumentToServiceInput;
};


/** mutation root */
export type Mutation_RootMobileUploadDocumentToServiceListArgs = {
  arg: MobileUploadDocumentToServiceListInput;
};


/** mutation root */
export type Mutation_RootMobileUploadMediaFileToServiceArgs = {
  arg: MobileUploadMediaFileToServiceInput;
};


/** mutation root */
export type Mutation_RootMobileUploadMediafileToAutoreplyArgs = {
  arg: MobileUploadMediafileToAutoreplyInput;
};


/** mutation root */
export type Mutation_RootPartnerConfirmFileUploadingArgs = {
  arg: PartnerConfirmFileUploadingInput;
};


/** mutation root */
export type Mutation_RootPartnerCreateFileAccessArgs = {
  arg: PartnerFileAccessInput;
};


/** mutation root */
export type Mutation_RootPartnerCreatePlaceArgs = {
  arg: PartnerCreatePlaceInput;
};


/** mutation root */
export type Mutation_RootPartnerCreatePlaceTypeIntegrationsArgs = {
  arg: PartnerCreatePlaceTypeIntegrationsInput;
};


/** mutation root */
export type Mutation_RootPartnerInsertBalancesArgs = {
  arg: PartnerInsertBalancesInput;
};


/** mutation root */
export type Mutation_RootUpdateMobilePostsActionArgs = {
  arg: UpdateMobilePostsInput;
};


/** mutation root */
export type Mutation_RootUpdateMobileProfilesByPkActionArgs = {
  arg: UpdateMobileProfilesByPkInput;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Oauth_Access_TokensArgs = {
  _inc?: InputMaybe<Auth_Oauth_Access_Tokens_Inc_Input>;
  _set?: InputMaybe<Auth_Oauth_Access_Tokens_Set_Input>;
  where: Auth_Oauth_Access_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Oauth_Access_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Auth_Oauth_Access_Tokens_Inc_Input>;
  _set?: InputMaybe<Auth_Oauth_Access_Tokens_Set_Input>;
  pk_columns: Auth_Oauth_Access_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Oauth_Access_Tokens_ManyArgs = {
  updates: Array<Auth_Oauth_Access_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Test_UsersArgs = {
  _inc?: InputMaybe<Auth_Test_Users_Inc_Input>;
  _set?: InputMaybe<Auth_Test_Users_Set_Input>;
  where: Auth_Test_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Test_Users_By_PkArgs = {
  _inc?: InputMaybe<Auth_Test_Users_Inc_Input>;
  _set?: InputMaybe<Auth_Test_Users_Set_Input>;
  pk_columns: Auth_Test_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Test_Users_ManyArgs = {
  updates: Array<Auth_Test_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_UsersArgs = {
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  _set?: InputMaybe<Auth_Users_Set_Input>;
  where: Auth_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_By_PkArgs = {
  _inc?: InputMaybe<Auth_Users_Inc_Input>;
  _set?: InputMaybe<Auth_Users_Set_Input>;
  pk_columns: Auth_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Users_ManyArgs = {
  updates: Array<Auth_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Verification_EmailsArgs = {
  _inc?: InputMaybe<Auth_Verification_Emails_Inc_Input>;
  _set?: InputMaybe<Auth_Verification_Emails_Set_Input>;
  where: Auth_Verification_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Verification_Emails_By_PkArgs = {
  _inc?: InputMaybe<Auth_Verification_Emails_Inc_Input>;
  _set?: InputMaybe<Auth_Verification_Emails_Set_Input>;
  pk_columns: Auth_Verification_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Verification_Emails_ManyArgs = {
  updates: Array<Auth_Verification_Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Verification_PhonesArgs = {
  _inc?: InputMaybe<Auth_Verification_Phones_Inc_Input>;
  _set?: InputMaybe<Auth_Verification_Phones_Set_Input>;
  where: Auth_Verification_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Verification_Phones_By_PkArgs = {
  _inc?: InputMaybe<Auth_Verification_Phones_Inc_Input>;
  _set?: InputMaybe<Auth_Verification_Phones_Set_Input>;
  pk_columns: Auth_Verification_Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Verification_Phones_ManyArgs = {
  updates: Array<Auth_Verification_Phones_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Order_PolicesArgs = {
  _append?: InputMaybe<Billing_Order_Polices_Append_Input>;
  _delete_at_path?: InputMaybe<Billing_Order_Polices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Billing_Order_Polices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Billing_Order_Polices_Delete_Key_Input>;
  _inc?: InputMaybe<Billing_Order_Polices_Inc_Input>;
  _prepend?: InputMaybe<Billing_Order_Polices_Prepend_Input>;
  _set?: InputMaybe<Billing_Order_Polices_Set_Input>;
  where: Billing_Order_Polices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Order_Polices_By_PkArgs = {
  _append?: InputMaybe<Billing_Order_Polices_Append_Input>;
  _delete_at_path?: InputMaybe<Billing_Order_Polices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Billing_Order_Polices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Billing_Order_Polices_Delete_Key_Input>;
  _inc?: InputMaybe<Billing_Order_Polices_Inc_Input>;
  _prepend?: InputMaybe<Billing_Order_Polices_Prepend_Input>;
  _set?: InputMaybe<Billing_Order_Polices_Set_Input>;
  pk_columns: Billing_Order_Polices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Order_Polices_ManyArgs = {
  updates: Array<Billing_Order_Polices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Order_StatusesArgs = {
  _inc?: InputMaybe<Billing_Order_Statuses_Inc_Input>;
  _set?: InputMaybe<Billing_Order_Statuses_Set_Input>;
  where: Billing_Order_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Order_Statuses_By_PkArgs = {
  _inc?: InputMaybe<Billing_Order_Statuses_Inc_Input>;
  _set?: InputMaybe<Billing_Order_Statuses_Set_Input>;
  pk_columns: Billing_Order_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Order_Statuses_ManyArgs = {
  updates: Array<Billing_Order_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Promo_CodesArgs = {
  _inc?: InputMaybe<Billing_Promo_Codes_Inc_Input>;
  _set?: InputMaybe<Billing_Promo_Codes_Set_Input>;
  where: Billing_Promo_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Promo_Codes_By_PkArgs = {
  _inc?: InputMaybe<Billing_Promo_Codes_Inc_Input>;
  _set?: InputMaybe<Billing_Promo_Codes_Set_Input>;
  pk_columns: Billing_Promo_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Promo_Codes_ManyArgs = {
  updates: Array<Billing_Promo_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_CitiesArgs = {
  _inc?: InputMaybe<Dms_Cities_Inc_Input>;
  _set?: InputMaybe<Dms_Cities_Set_Input>;
  where: Dms_Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Cities_By_PkArgs = {
  _inc?: InputMaybe<Dms_Cities_Inc_Input>;
  _set?: InputMaybe<Dms_Cities_Set_Input>;
  pk_columns: Dms_Cities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Cities_ManyArgs = {
  updates: Array<Dms_Cities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Group_InsuredsArgs = {
  _inc?: InputMaybe<Dms_Group_Insureds_Inc_Input>;
  _set?: InputMaybe<Dms_Group_Insureds_Set_Input>;
  where: Dms_Group_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Group_Insureds_By_PkArgs = {
  _inc?: InputMaybe<Dms_Group_Insureds_Inc_Input>;
  _set?: InputMaybe<Dms_Group_Insureds_Set_Input>;
  pk_columns: Dms_Group_Insureds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Group_Insureds_ManyArgs = {
  updates: Array<Dms_Group_Insureds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_GroupsArgs = {
  _inc?: InputMaybe<Dms_Groups_Inc_Input>;
  _set?: InputMaybe<Dms_Groups_Set_Input>;
  where: Dms_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Groups_By_PkArgs = {
  _inc?: InputMaybe<Dms_Groups_Inc_Input>;
  _set?: InputMaybe<Dms_Groups_Set_Input>;
  pk_columns: Dms_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Groups_ManyArgs = {
  updates: Array<Dms_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_InsurantsArgs = {
  _inc?: InputMaybe<Dms_Insurants_Inc_Input>;
  _set?: InputMaybe<Dms_Insurants_Set_Input>;
  where: Dms_Insurants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insurants_By_PkArgs = {
  _inc?: InputMaybe<Dms_Insurants_Inc_Input>;
  _set?: InputMaybe<Dms_Insurants_Set_Input>;
  pk_columns: Dms_Insurants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insurants_ManyArgs = {
  updates: Array<Dms_Insurants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_InsuredsArgs = {
  _append?: InputMaybe<Dms_Insureds_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Insureds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Insureds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Insureds_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Insureds_Inc_Input>;
  _prepend?: InputMaybe<Dms_Insureds_Prepend_Input>;
  _set?: InputMaybe<Dms_Insureds_Set_Input>;
  where: Dms_Insureds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insureds_By_PkArgs = {
  _append?: InputMaybe<Dms_Insureds_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Insureds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Insureds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Insureds_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Insureds_Inc_Input>;
  _prepend?: InputMaybe<Dms_Insureds_Prepend_Input>;
  _set?: InputMaybe<Dms_Insureds_Set_Input>;
  pk_columns: Dms_Insureds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Insureds_ManyArgs = {
  updates: Array<Dms_Insureds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_LinksArgs = {
  _inc?: InputMaybe<Dms_Order_Links_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Links_Set_Input>;
  where: Dms_Order_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Links_By_PkArgs = {
  _inc?: InputMaybe<Dms_Order_Links_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Links_Set_Input>;
  pk_columns: Dms_Order_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Links_ManyArgs = {
  updates: Array<Dms_Order_Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_NumbersArgs = {
  _inc?: InputMaybe<Dms_Order_Numbers_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Numbers_Set_Input>;
  where: Dms_Order_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Numbers_By_PkArgs = {
  _inc?: InputMaybe<Dms_Order_Numbers_Inc_Input>;
  _set?: InputMaybe<Dms_Order_Numbers_Set_Input>;
  pk_columns: Dms_Order_Numbers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Order_Numbers_ManyArgs = {
  updates: Array<Dms_Order_Numbers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_OrdersArgs = {
  _append?: InputMaybe<Dms_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Orders_Inc_Input>;
  _prepend?: InputMaybe<Dms_Orders_Prepend_Input>;
  _set?: InputMaybe<Dms_Orders_Set_Input>;
  where: Dms_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Orders_By_PkArgs = {
  _append?: InputMaybe<Dms_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Orders_Inc_Input>;
  _prepend?: InputMaybe<Dms_Orders_Prepend_Input>;
  _set?: InputMaybe<Dms_Orders_Set_Input>;
  pk_columns: Dms_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Orders_ManyArgs = {
  updates: Array<Dms_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_ProductsArgs = {
  _append?: InputMaybe<Dms_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Products_Inc_Input>;
  _prepend?: InputMaybe<Dms_Products_Prepend_Input>;
  _set?: InputMaybe<Dms_Products_Set_Input>;
  where: Dms_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Products_By_PkArgs = {
  _append?: InputMaybe<Dms_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Dms_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dms_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dms_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Dms_Products_Inc_Input>;
  _prepend?: InputMaybe<Dms_Products_Prepend_Input>;
  _set?: InputMaybe<Dms_Products_Set_Input>;
  pk_columns: Dms_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Products_ManyArgs = {
  updates: Array<Dms_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Promo_CodesArgs = {
  _inc?: InputMaybe<Dms_Promo_Codes_Inc_Input>;
  _set?: InputMaybe<Dms_Promo_Codes_Set_Input>;
  where: Dms_Promo_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Promo_Codes_By_PkArgs = {
  _inc?: InputMaybe<Dms_Promo_Codes_Inc_Input>;
  _set?: InputMaybe<Dms_Promo_Codes_Set_Input>;
  pk_columns: Dms_Promo_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Promo_Codes_ManyArgs = {
  updates: Array<Dms_Promo_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_StatusesArgs = {
  _inc?: InputMaybe<Dms_Statuses_Inc_Input>;
  _set?: InputMaybe<Dms_Statuses_Set_Input>;
  where: Dms_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Statuses_By_PkArgs = {
  _inc?: InputMaybe<Dms_Statuses_Inc_Input>;
  _set?: InputMaybe<Dms_Statuses_Set_Input>;
  pk_columns: Dms_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Statuses_ManyArgs = {
  updates: Array<Dms_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_UsersArgs = {
  _inc?: InputMaybe<Dms_Users_Inc_Input>;
  _set?: InputMaybe<Dms_Users_Set_Input>;
  where: Dms_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Users_By_PkArgs = {
  _inc?: InputMaybe<Dms_Users_Inc_Input>;
  _set?: InputMaybe<Dms_Users_Set_Input>;
  pk_columns: Dms_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dms_Users_ManyArgs = {
  updates: Array<Dms_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_CardsArgs = {
  _inc?: InputMaybe<Fintech_Cards_Inc_Input>;
  _set?: InputMaybe<Fintech_Cards_Set_Input>;
  where: Fintech_Cards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_Cards_By_PkArgs = {
  _inc?: InputMaybe<Fintech_Cards_Inc_Input>;
  _set?: InputMaybe<Fintech_Cards_Set_Input>;
  pk_columns: Fintech_Cards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_Cards_ManyArgs = {
  updates: Array<Fintech_Cards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_ClientsArgs = {
  _inc?: InputMaybe<Fintech_Clients_Inc_Input>;
  _set?: InputMaybe<Fintech_Clients_Set_Input>;
  where: Fintech_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_Clients_By_PkArgs = {
  _inc?: InputMaybe<Fintech_Clients_Inc_Input>;
  _set?: InputMaybe<Fintech_Clients_Set_Input>;
  pk_columns: Fintech_Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_Clients_ManyArgs = {
  updates: Array<Fintech_Clients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_OrdersArgs = {
  _append?: InputMaybe<Fintech_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Fintech_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Fintech_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Fintech_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Fintech_Orders_Inc_Input>;
  _prepend?: InputMaybe<Fintech_Orders_Prepend_Input>;
  _set?: InputMaybe<Fintech_Orders_Set_Input>;
  where: Fintech_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_Orders_By_PkArgs = {
  _append?: InputMaybe<Fintech_Orders_Append_Input>;
  _delete_at_path?: InputMaybe<Fintech_Orders_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Fintech_Orders_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Fintech_Orders_Delete_Key_Input>;
  _inc?: InputMaybe<Fintech_Orders_Inc_Input>;
  _prepend?: InputMaybe<Fintech_Orders_Prepend_Input>;
  _set?: InputMaybe<Fintech_Orders_Set_Input>;
  pk_columns: Fintech_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fintech_Orders_ManyArgs = {
  updates: Array<Fintech_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_ContactsArgs = {
  _inc?: InputMaybe<Mobile_Amocrm_Contacts_Inc_Input>;
  _set?: InputMaybe<Mobile_Amocrm_Contacts_Set_Input>;
  where: Mobile_Amocrm_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_Contacts_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Amocrm_Contacts_Inc_Input>;
  _set?: InputMaybe<Mobile_Amocrm_Contacts_Set_Input>;
  pk_columns: Mobile_Amocrm_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_Contacts_ManyArgs = {
  updates: Array<Mobile_Amocrm_Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_LeadsArgs = {
  _inc?: InputMaybe<Mobile_Amocrm_Leads_Inc_Input>;
  _set?: InputMaybe<Mobile_Amocrm_Leads_Set_Input>;
  where: Mobile_Amocrm_Leads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_Leads_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Amocrm_Leads_Inc_Input>;
  _set?: InputMaybe<Mobile_Amocrm_Leads_Set_Input>;
  pk_columns: Mobile_Amocrm_Leads_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_Leads_ManyArgs = {
  updates: Array<Mobile_Amocrm_Leads_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_TagsArgs = {
  _inc?: InputMaybe<Mobile_Amocrm_Tags_Inc_Input>;
  _set?: InputMaybe<Mobile_Amocrm_Tags_Set_Input>;
  where: Mobile_Amocrm_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_Tags_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Amocrm_Tags_Inc_Input>;
  _set?: InputMaybe<Mobile_Amocrm_Tags_Set_Input>;
  pk_columns: Mobile_Amocrm_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Amocrm_Tags_ManyArgs = {
  updates: Array<Mobile_Amocrm_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_AppointmentsArgs = {
  _inc?: InputMaybe<Mobile_Appointments_Inc_Input>;
  _set?: InputMaybe<Mobile_Appointments_Set_Input>;
  where: Mobile_Appointments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Appointments_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Appointments_Inc_Input>;
  _set?: InputMaybe<Mobile_Appointments_Set_Input>;
  pk_columns: Mobile_Appointments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Appointments_ManyArgs = {
  updates: Array<Mobile_Appointments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_RepliesArgs = {
  _inc?: InputMaybe<Mobile_Auto_Replies_Inc_Input>;
  _set?: InputMaybe<Mobile_Auto_Replies_Set_Input>;
  where: Mobile_Auto_Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Replies_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Auto_Replies_Inc_Input>;
  _set?: InputMaybe<Mobile_Auto_Replies_Set_Input>;
  pk_columns: Mobile_Auto_Replies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Replies_ManyArgs = {
  updates: Array<Mobile_Auto_Replies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Reply_DocumentsArgs = {
  _inc?: InputMaybe<Mobile_Auto_Reply_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Auto_Reply_Documents_Set_Input>;
  where: Mobile_Auto_Reply_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Reply_Documents_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Auto_Reply_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Auto_Reply_Documents_Set_Input>;
  pk_columns: Mobile_Auto_Reply_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Reply_Documents_ManyArgs = {
  updates: Array<Mobile_Auto_Reply_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Reply_MediafilesArgs = {
  _inc?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Set_Input>;
  where: Mobile_Auto_Reply_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Reply_Mediafiles_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Set_Input>;
  pk_columns: Mobile_Auto_Reply_Mediafiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Auto_Reply_Mediafiles_ManyArgs = {
  updates: Array<Mobile_Auto_Reply_Mediafiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Bank_AccountsArgs = {
  _inc?: InputMaybe<Mobile_Bank_Accounts_Inc_Input>;
  _set?: InputMaybe<Mobile_Bank_Accounts_Set_Input>;
  where: Mobile_Bank_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Bank_Accounts_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Bank_Accounts_Inc_Input>;
  _set?: InputMaybe<Mobile_Bank_Accounts_Set_Input>;
  pk_columns: Mobile_Bank_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Bank_Accounts_ManyArgs = {
  updates: Array<Mobile_Bank_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Blocked_SitesArgs = {
  _inc?: InputMaybe<Mobile_Blocked_Sites_Inc_Input>;
  _set?: InputMaybe<Mobile_Blocked_Sites_Set_Input>;
  where: Mobile_Blocked_Sites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Blocked_Sites_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Blocked_Sites_Inc_Input>;
  _set?: InputMaybe<Mobile_Blocked_Sites_Set_Input>;
  pk_columns: Mobile_Blocked_Sites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Blocked_Sites_ManyArgs = {
  updates: Array<Mobile_Blocked_Sites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_BlockedsArgs = {
  _inc?: InputMaybe<Mobile_Blockeds_Inc_Input>;
  _set?: InputMaybe<Mobile_Blockeds_Set_Input>;
  where: Mobile_Blockeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Blockeds_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Blockeds_Inc_Input>;
  _set?: InputMaybe<Mobile_Blockeds_Set_Input>;
  pk_columns: Mobile_Blockeds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Blockeds_ManyArgs = {
  updates: Array<Mobile_Blockeds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calorie_NormsArgs = {
  _inc?: InputMaybe<Mobile_Calorie_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Calorie_Norms_Set_Input>;
  where: Mobile_Calorie_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calorie_Norms_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Calorie_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Calorie_Norms_Set_Input>;
  pk_columns: Mobile_Calorie_Norms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calorie_Norms_ManyArgs = {
  updates: Array<Mobile_Calorie_Norms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calorie_NowsArgs = {
  _inc?: InputMaybe<Mobile_Calorie_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Calorie_Nows_Set_Input>;
  where: Mobile_Calorie_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calorie_Nows_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Calorie_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Calorie_Nows_Set_Input>;
  pk_columns: Mobile_Calorie_Nows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calorie_Nows_ManyArgs = {
  updates: Array<Mobile_Calorie_Nows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_CaloriesArgs = {
  _inc?: InputMaybe<Mobile_Calories_Inc_Input>;
  _set?: InputMaybe<Mobile_Calories_Set_Input>;
  where: Mobile_Calories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calories_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Calories_Inc_Input>;
  _set?: InputMaybe<Mobile_Calories_Set_Input>;
  pk_columns: Mobile_Calories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Calories_ManyArgs = {
  updates: Array<Mobile_Calories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_CategoriesArgs = {
  _inc?: InputMaybe<Mobile_Categories_Inc_Input>;
  _set?: InputMaybe<Mobile_Categories_Set_Input>;
  where: Mobile_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Categories_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Categories_Inc_Input>;
  _set?: InputMaybe<Mobile_Categories_Set_Input>;
  pk_columns: Mobile_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Categories_ManyArgs = {
  updates: Array<Mobile_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Category_NamesArgs = {
  _inc?: InputMaybe<Mobile_Category_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Category_Names_Set_Input>;
  where: Mobile_Category_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Category_Names_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Category_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Category_Names_Set_Input>;
  pk_columns: Mobile_Category_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Category_Names_ManyArgs = {
  updates: Array<Mobile_Category_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Black_ListsArgs = {
  _inc?: InputMaybe<Mobile_Chat_Black_Lists_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Black_Lists_Set_Input>;
  where: Mobile_Chat_Black_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Black_Lists_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Black_Lists_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Black_Lists_Set_Input>;
  pk_columns: Mobile_Chat_Black_Lists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Black_Lists_ManyArgs = {
  updates: Array<Mobile_Chat_Black_Lists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_DefaultsArgs = {
  _inc?: InputMaybe<Mobile_Chat_Defaults_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Defaults_Set_Input>;
  where: Mobile_Chat_Defaults_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Defaults_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Defaults_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Defaults_Set_Input>;
  pk_columns: Mobile_Chat_Defaults_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Defaults_ManyArgs = {
  updates: Array<Mobile_Chat_Defaults_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_File_TypesArgs = {
  _inc?: InputMaybe<Mobile_Chat_File_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_File_Types_Set_Input>;
  where: Mobile_Chat_File_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_File_Types_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_File_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_File_Types_Set_Input>;
  pk_columns: Mobile_Chat_File_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_File_Types_ManyArgs = {
  updates: Array<Mobile_Chat_File_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_CommentsArgs = {
  _inc?: InputMaybe<Mobile_Chat_Message_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Message_Comments_Set_Input>;
  where: Mobile_Chat_Message_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_Comments_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Message_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Message_Comments_Set_Input>;
  pk_columns: Mobile_Chat_Message_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_Comments_ManyArgs = {
  updates: Array<Mobile_Chat_Message_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_FilesArgs = {
  _inc?: InputMaybe<Mobile_Chat_Message_Files_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Message_Files_Set_Input>;
  where: Mobile_Chat_Message_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_Files_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Message_Files_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Message_Files_Set_Input>;
  pk_columns: Mobile_Chat_Message_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_Files_ManyArgs = {
  updates: Array<Mobile_Chat_Message_Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Chat_Message_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Message_Notifications_Set_Input>;
  where: Mobile_Chat_Message_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_Notifications_ManyArgs = {
  updates: Array<Mobile_Chat_Message_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_TypesArgs = {
  _inc?: InputMaybe<Mobile_Chat_Message_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Message_Types_Set_Input>;
  where: Mobile_Chat_Message_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_Types_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Message_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Message_Types_Set_Input>;
  pk_columns: Mobile_Chat_Message_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Message_Types_ManyArgs = {
  updates: Array<Mobile_Chat_Message_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_MessagesArgs = {
  _inc?: InputMaybe<Mobile_Chat_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Messages_Set_Input>;
  where: Mobile_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Messages_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Messages_Set_Input>;
  pk_columns: Mobile_Chat_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Messages_ManyArgs = {
  updates: Array<Mobile_Chat_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_RolesArgs = {
  _inc?: InputMaybe<Mobile_Chat_Roles_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Roles_Set_Input>;
  where: Mobile_Chat_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Roles_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Roles_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Roles_Set_Input>;
  pk_columns: Mobile_Chat_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Roles_ManyArgs = {
  updates: Array<Mobile_Chat_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_TypesArgs = {
  _inc?: InputMaybe<Mobile_Chat_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Types_Set_Input>;
  where: Mobile_Chat_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Types_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chat_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Chat_Types_Set_Input>;
  pk_columns: Mobile_Chat_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chat_Types_ManyArgs = {
  updates: Array<Mobile_Chat_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ChatsArgs = {
  _inc?: InputMaybe<Mobile_Chats_Inc_Input>;
  _set?: InputMaybe<Mobile_Chats_Set_Input>;
  where: Mobile_Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chats_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Chats_Inc_Input>;
  _set?: InputMaybe<Mobile_Chats_Set_Input>;
  pk_columns: Mobile_Chats_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Chats_ManyArgs = {
  updates: Array<Mobile_Chats_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ClientsArgs = {
  _inc?: InputMaybe<Mobile_Clients_Inc_Input>;
  _set?: InputMaybe<Mobile_Clients_Set_Input>;
  where: Mobile_Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Clients_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Clients_Inc_Input>;
  _set?: InputMaybe<Mobile_Clients_Set_Input>;
  pk_columns: Mobile_Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Clients_ManyArgs = {
  updates: Array<Mobile_Clients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comment_Like_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Comment_Like_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Comment_Like_Notifications_Set_Input>;
  where: Mobile_Comment_Like_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comment_Like_Notifications_ManyArgs = {
  updates: Array<Mobile_Comment_Like_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comment_LikesArgs = {
  _inc?: InputMaybe<Mobile_Comment_Likes_Inc_Input>;
  _set?: InputMaybe<Mobile_Comment_Likes_Set_Input>;
  where: Mobile_Comment_Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comment_Likes_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Comment_Likes_Inc_Input>;
  _set?: InputMaybe<Mobile_Comment_Likes_Set_Input>;
  pk_columns: Mobile_Comment_Likes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comment_Likes_ManyArgs = {
  updates: Array<Mobile_Comment_Likes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comment_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Comment_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Comment_Notifications_Set_Input>;
  where: Mobile_Comment_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comment_Notifications_ManyArgs = {
  updates: Array<Mobile_Comment_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_CommentsArgs = {
  _inc?: InputMaybe<Mobile_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Comments_Set_Input>;
  where: Mobile_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comments_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Comments_Set_Input>;
  pk_columns: Mobile_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Comments_ManyArgs = {
  updates: Array<Mobile_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Confirmation_CodesArgs = {
  _inc?: InputMaybe<Mobile_Confirmation_Codes_Inc_Input>;
  _set?: InputMaybe<Mobile_Confirmation_Codes_Set_Input>;
  where: Mobile_Confirmation_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Confirmation_Codes_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Confirmation_Codes_Inc_Input>;
  _set?: InputMaybe<Mobile_Confirmation_Codes_Set_Input>;
  pk_columns: Mobile_Confirmation_Codes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Confirmation_Codes_ManyArgs = {
  updates: Array<Mobile_Confirmation_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ContactsArgs = {
  _inc?: InputMaybe<Mobile_Contacts_Inc_Input>;
  _set?: InputMaybe<Mobile_Contacts_Set_Input>;
  where: Mobile_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Contacts_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Contacts_Inc_Input>;
  _set?: InputMaybe<Mobile_Contacts_Set_Input>;
  pk_columns: Mobile_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Contacts_ManyArgs = {
  updates: Array<Mobile_Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ContentsArgs = {
  _inc?: InputMaybe<Mobile_Contents_Inc_Input>;
  _set?: InputMaybe<Mobile_Contents_Set_Input>;
  where: Mobile_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Contents_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Contents_Inc_Input>;
  _set?: InputMaybe<Mobile_Contents_Set_Input>;
  pk_columns: Mobile_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Contents_ManyArgs = {
  updates: Array<Mobile_Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Device_InfosArgs = {
  _append?: InputMaybe<Mobile_Device_Infos_Append_Input>;
  _delete_at_path?: InputMaybe<Mobile_Device_Infos_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mobile_Device_Infos_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mobile_Device_Infos_Delete_Key_Input>;
  _inc?: InputMaybe<Mobile_Device_Infos_Inc_Input>;
  _prepend?: InputMaybe<Mobile_Device_Infos_Prepend_Input>;
  _set?: InputMaybe<Mobile_Device_Infos_Set_Input>;
  where: Mobile_Device_Infos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Device_Infos_By_PkArgs = {
  _append?: InputMaybe<Mobile_Device_Infos_Append_Input>;
  _delete_at_path?: InputMaybe<Mobile_Device_Infos_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mobile_Device_Infos_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mobile_Device_Infos_Delete_Key_Input>;
  _inc?: InputMaybe<Mobile_Device_Infos_Inc_Input>;
  _prepend?: InputMaybe<Mobile_Device_Infos_Prepend_Input>;
  _set?: InputMaybe<Mobile_Device_Infos_Set_Input>;
  pk_columns: Mobile_Device_Infos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Device_Infos_ManyArgs = {
  updates: Array<Mobile_Device_Infos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Device_SettingsArgs = {
  _inc?: InputMaybe<Mobile_Device_Settings_Inc_Input>;
  _set?: InputMaybe<Mobile_Device_Settings_Set_Input>;
  where: Mobile_Device_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Device_Settings_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Device_Settings_Inc_Input>;
  _set?: InputMaybe<Mobile_Device_Settings_Set_Input>;
  pk_columns: Mobile_Device_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Device_Settings_ManyArgs = {
  updates: Array<Mobile_Device_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_DevicesArgs = {
  _inc?: InputMaybe<Mobile_Devices_Inc_Input>;
  _set?: InputMaybe<Mobile_Devices_Set_Input>;
  where: Mobile_Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Devices_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Devices_Inc_Input>;
  _set?: InputMaybe<Mobile_Devices_Set_Input>;
  pk_columns: Mobile_Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Devices_ManyArgs = {
  updates: Array<Mobile_Devices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Document_MediafilesArgs = {
  _inc?: InputMaybe<Mobile_Document_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Document_Mediafiles_Set_Input>;
  where: Mobile_Document_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Document_Mediafiles_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Document_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Document_Mediafiles_Set_Input>;
  pk_columns: Mobile_Document_Mediafiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Document_Mediafiles_ManyArgs = {
  updates: Array<Mobile_Document_Mediafiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_DocumentsArgs = {
  _inc?: InputMaybe<Mobile_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Documents_Set_Input>;
  where: Mobile_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Documents_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Documents_Set_Input>;
  pk_columns: Mobile_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Documents_ManyArgs = {
  updates: Array<Mobile_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Education_MediafilesArgs = {
  _inc?: InputMaybe<Mobile_Education_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Education_Mediafiles_Set_Input>;
  where: Mobile_Education_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Education_Mediafiles_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Education_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Education_Mediafiles_Set_Input>;
  pk_columns: Mobile_Education_Mediafiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Education_Mediafiles_ManyArgs = {
  updates: Array<Mobile_Education_Mediafiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_EducationsArgs = {
  _inc?: InputMaybe<Mobile_Educations_Inc_Input>;
  _set?: InputMaybe<Mobile_Educations_Set_Input>;
  where: Mobile_Educations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Educations_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Educations_Inc_Input>;
  _set?: InputMaybe<Mobile_Educations_Set_Input>;
  pk_columns: Mobile_Educations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Educations_ManyArgs = {
  updates: Array<Mobile_Educations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_EmojisArgs = {
  _inc?: InputMaybe<Mobile_Emojis_Inc_Input>;
  _set?: InputMaybe<Mobile_Emojis_Set_Input>;
  where: Mobile_Emojis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Emojis_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Emojis_Inc_Input>;
  _set?: InputMaybe<Mobile_Emojis_Set_Input>;
  pk_columns: Mobile_Emojis_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Emojis_ManyArgs = {
  updates: Array<Mobile_Emojis_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Fixed_Chat_MessagesArgs = {
  _inc?: InputMaybe<Mobile_Fixed_Chat_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Fixed_Chat_Messages_Set_Input>;
  where: Mobile_Fixed_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Fixed_Chat_Messages_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Fixed_Chat_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Fixed_Chat_Messages_Set_Input>;
  pk_columns: Mobile_Fixed_Chat_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Fixed_Chat_Messages_ManyArgs = {
  updates: Array<Mobile_Fixed_Chat_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Fixed_CommentsArgs = {
  _inc?: InputMaybe<Mobile_Fixed_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Fixed_Comments_Set_Input>;
  where: Mobile_Fixed_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Fixed_Comments_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Fixed_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Fixed_Comments_Set_Input>;
  pk_columns: Mobile_Fixed_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Fixed_Comments_ManyArgs = {
  updates: Array<Mobile_Fixed_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Founder_MediafilesArgs = {
  _inc?: InputMaybe<Mobile_Founder_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Founder_Mediafiles_Set_Input>;
  where: Mobile_Founder_Mediafiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Founder_Mediafiles_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Founder_Mediafiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Founder_Mediafiles_Set_Input>;
  pk_columns: Mobile_Founder_Mediafiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Founder_Mediafiles_ManyArgs = {
  updates: Array<Mobile_Founder_Mediafiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_FoundersArgs = {
  _inc?: InputMaybe<Mobile_Founders_Inc_Input>;
  _set?: InputMaybe<Mobile_Founders_Set_Input>;
  where: Mobile_Founders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Founders_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Founders_Inc_Input>;
  _set?: InputMaybe<Mobile_Founders_Set_Input>;
  pk_columns: Mobile_Founders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Founders_ManyArgs = {
  updates: Array<Mobile_Founders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Invite_EmailsArgs = {
  _inc?: InputMaybe<Mobile_Invite_Emails_Inc_Input>;
  _set?: InputMaybe<Mobile_Invite_Emails_Set_Input>;
  where: Mobile_Invite_Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Invite_Emails_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Invite_Emails_Inc_Input>;
  _set?: InputMaybe<Mobile_Invite_Emails_Set_Input>;
  pk_columns: Mobile_Invite_Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Invite_Emails_ManyArgs = {
  updates: Array<Mobile_Invite_Emails_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Like_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Like_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Like_Notifications_Set_Input>;
  where: Mobile_Like_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Like_Notifications_ManyArgs = {
  updates: Array<Mobile_Like_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_LikesArgs = {
  _inc?: InputMaybe<Mobile_Likes_Inc_Input>;
  _set?: InputMaybe<Mobile_Likes_Set_Input>;
  where: Mobile_Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Likes_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Likes_Inc_Input>;
  _set?: InputMaybe<Mobile_Likes_Set_Input>;
  pk_columns: Mobile_Likes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Likes_ManyArgs = {
  updates: Array<Mobile_Likes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Link_SnippetsArgs = {
  _inc?: InputMaybe<Mobile_Link_Snippets_Inc_Input>;
  _set?: InputMaybe<Mobile_Link_Snippets_Set_Input>;
  where: Mobile_Link_Snippets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Link_Snippets_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Link_Snippets_Inc_Input>;
  _set?: InputMaybe<Mobile_Link_Snippets_Set_Input>;
  pk_columns: Mobile_Link_Snippets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Link_Snippets_ManyArgs = {
  updates: Array<Mobile_Link_Snippets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Locked_Deleted_Chat_MessagesArgs = {
  _inc?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Set_Input>;
  where: Mobile_Locked_Deleted_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Locked_Deleted_Chat_Messages_ManyArgs = {
  updates: Array<Mobile_Locked_Deleted_Chat_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mediafile_Status_NamesArgs = {
  _inc?: InputMaybe<Mobile_Mediafile_Status_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Mediafile_Status_Names_Set_Input>;
  where: Mobile_Mediafile_Status_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mediafile_Status_Names_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Mediafile_Status_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Mediafile_Status_Names_Set_Input>;
  pk_columns: Mobile_Mediafile_Status_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mediafile_Status_Names_ManyArgs = {
  updates: Array<Mobile_Mediafile_Status_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mediafile_StatusesArgs = {
  _inc?: InputMaybe<Mobile_Mediafile_Statuses_Inc_Input>;
  _set?: InputMaybe<Mobile_Mediafile_Statuses_Set_Input>;
  where: Mobile_Mediafile_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mediafile_Statuses_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Mediafile_Statuses_Inc_Input>;
  _set?: InputMaybe<Mobile_Mediafile_Statuses_Set_Input>;
  pk_columns: Mobile_Mediafile_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mediafile_Statuses_ManyArgs = {
  updates: Array<Mobile_Mediafile_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Medical_Concierge_OrdersArgs = {
  _inc?: InputMaybe<Mobile_Medical_Concierge_Orders_Inc_Input>;
  _set?: InputMaybe<Mobile_Medical_Concierge_Orders_Set_Input>;
  where: Mobile_Medical_Concierge_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Medical_Concierge_Orders_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Medical_Concierge_Orders_Inc_Input>;
  _set?: InputMaybe<Mobile_Medical_Concierge_Orders_Set_Input>;
  pk_columns: Mobile_Medical_Concierge_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Medical_Concierge_Orders_ManyArgs = {
  updates: Array<Mobile_Medical_Concierge_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Medical_Concierge_ProductsArgs = {
  _append?: InputMaybe<Mobile_Medical_Concierge_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Mobile_Medical_Concierge_Products_Inc_Input>;
  _prepend?: InputMaybe<Mobile_Medical_Concierge_Products_Prepend_Input>;
  _set?: InputMaybe<Mobile_Medical_Concierge_Products_Set_Input>;
  where: Mobile_Medical_Concierge_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Medical_Concierge_Products_By_PkArgs = {
  _append?: InputMaybe<Mobile_Medical_Concierge_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mobile_Medical_Concierge_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Mobile_Medical_Concierge_Products_Inc_Input>;
  _prepend?: InputMaybe<Mobile_Medical_Concierge_Products_Prepend_Input>;
  _set?: InputMaybe<Mobile_Medical_Concierge_Products_Set_Input>;
  pk_columns: Mobile_Medical_Concierge_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Medical_Concierge_Products_ManyArgs = {
  updates: Array<Mobile_Medical_Concierge_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mention_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Mention_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Mention_Notifications_Set_Input>;
  where: Mobile_Mention_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mention_Notifications_ManyArgs = {
  updates: Array<Mobile_Mention_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_MentionsArgs = {
  _inc?: InputMaybe<Mobile_Mentions_Inc_Input>;
  _set?: InputMaybe<Mobile_Mentions_Set_Input>;
  where: Mobile_Mentions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mentions_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Mentions_Inc_Input>;
  _set?: InputMaybe<Mobile_Mentions_Set_Input>;
  pk_columns: Mobile_Mentions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Mentions_ManyArgs = {
  updates: Array<Mobile_Mentions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_NamesArgs = {
  _inc?: InputMaybe<Mobile_Metric_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Metric_Names_Set_Input>;
  where: Mobile_Metric_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_Names_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Metric_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Metric_Names_Set_Input>;
  pk_columns: Mobile_Metric_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_Names_ManyArgs = {
  updates: Array<Mobile_Metric_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_ParamsArgs = {
  _inc?: InputMaybe<Mobile_Metric_Params_Inc_Input>;
  _set?: InputMaybe<Mobile_Metric_Params_Set_Input>;
  where: Mobile_Metric_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_Params_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Metric_Params_Inc_Input>;
  _set?: InputMaybe<Mobile_Metric_Params_Set_Input>;
  pk_columns: Mobile_Metric_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_Params_ManyArgs = {
  updates: Array<Mobile_Metric_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_TypesArgs = {
  _inc?: InputMaybe<Mobile_Metric_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Metric_Types_Set_Input>;
  where: Mobile_Metric_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_Types_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Metric_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Metric_Types_Set_Input>;
  pk_columns: Mobile_Metric_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metric_Types_ManyArgs = {
  updates: Array<Mobile_Metric_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_MetricsArgs = {
  _inc?: InputMaybe<Mobile_Metrics_Inc_Input>;
  _set?: InputMaybe<Mobile_Metrics_Set_Input>;
  where: Mobile_Metrics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metrics_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Metrics_Inc_Input>;
  _set?: InputMaybe<Mobile_Metrics_Set_Input>;
  pk_columns: Mobile_Metrics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Metrics_ManyArgs = {
  updates: Array<Mobile_Metrics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notification_MessagesArgs = {
  _inc?: InputMaybe<Mobile_Notification_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Notification_Messages_Set_Input>;
  where: Mobile_Notification_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notification_Messages_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Notification_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Notification_Messages_Set_Input>;
  pk_columns: Mobile_Notification_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notification_Messages_ManyArgs = {
  updates: Array<Mobile_Notification_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Notifications_Set_Input>;
  where: Mobile_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notifications_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Notifications_Set_Input>;
  pk_columns: Mobile_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notifications_ManyArgs = {
  updates: Array<Mobile_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notifications_StatusesArgs = {
  _set?: InputMaybe<Mobile_Notifications_Statuses_Set_Input>;
  where: Mobile_Notifications_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notifications_Statuses_By_PkArgs = {
  _set?: InputMaybe<Mobile_Notifications_Statuses_Set_Input>;
  pk_columns: Mobile_Notifications_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Notifications_Statuses_ManyArgs = {
  updates: Array<Mobile_Notifications_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Order_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Order_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Order_Notifications_Set_Input>;
  where: Mobile_Order_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Order_Notifications_ManyArgs = {
  updates: Array<Mobile_Order_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Order_ProfessionalsArgs = {
  _inc?: InputMaybe<Mobile_Order_Professionals_Inc_Input>;
  _set?: InputMaybe<Mobile_Order_Professionals_Set_Input>;
  where: Mobile_Order_Professionals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Order_Professionals_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Order_Professionals_Inc_Input>;
  _set?: InputMaybe<Mobile_Order_Professionals_Set_Input>;
  pk_columns: Mobile_Order_Professionals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Order_Professionals_ManyArgs = {
  updates: Array<Mobile_Order_Professionals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_OrdersArgs = {
  _inc?: InputMaybe<Mobile_Orders_Inc_Input>;
  _set?: InputMaybe<Mobile_Orders_Set_Input>;
  where: Mobile_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Orders_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Orders_Inc_Input>;
  _set?: InputMaybe<Mobile_Orders_Set_Input>;
  pk_columns: Mobile_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Orders_ManyArgs = {
  updates: Array<Mobile_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_OrgsArgs = {
  _inc?: InputMaybe<Mobile_Orgs_Inc_Input>;
  _set?: InputMaybe<Mobile_Orgs_Set_Input>;
  where: Mobile_Orgs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Orgs_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Orgs_Inc_Input>;
  _set?: InputMaybe<Mobile_Orgs_Set_Input>;
  pk_columns: Mobile_Orgs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Orgs_ManyArgs = {
  updates: Array<Mobile_Orgs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_CategoriesArgs = {
  _inc?: InputMaybe<Mobile_Param_Categories_Inc_Input>;
  _set?: InputMaybe<Mobile_Param_Categories_Set_Input>;
  where: Mobile_Param_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Categories_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Param_Categories_Inc_Input>;
  _set?: InputMaybe<Mobile_Param_Categories_Set_Input>;
  pk_columns: Mobile_Param_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Categories_ManyArgs = {
  updates: Array<Mobile_Param_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Categories_ParamsArgs = {
  _inc?: InputMaybe<Mobile_Param_Categories_Params_Inc_Input>;
  _set?: InputMaybe<Mobile_Param_Categories_Params_Set_Input>;
  where: Mobile_Param_Categories_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Categories_Params_ManyArgs = {
  updates: Array<Mobile_Param_Categories_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Category_NamesArgs = {
  _inc?: InputMaybe<Mobile_Param_Category_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Param_Category_Names_Set_Input>;
  where: Mobile_Param_Category_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Category_Names_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Param_Category_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Param_Category_Names_Set_Input>;
  pk_columns: Mobile_Param_Category_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Category_Names_ManyArgs = {
  updates: Array<Mobile_Param_Category_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_NamesArgs = {
  _inc?: InputMaybe<Mobile_Param_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Param_Names_Set_Input>;
  where: Mobile_Param_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Names_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Param_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Param_Names_Set_Input>;
  pk_columns: Mobile_Param_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Param_Names_ManyArgs = {
  updates: Array<Mobile_Param_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ParamsArgs = {
  _inc?: InputMaybe<Mobile_Params_Inc_Input>;
  _set?: InputMaybe<Mobile_Params_Set_Input>;
  where: Mobile_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Params_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Params_Inc_Input>;
  _set?: InputMaybe<Mobile_Params_Set_Input>;
  pk_columns: Mobile_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Params_ManyArgs = {
  updates: Array<Mobile_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Params_ServicesArgs = {
  _inc?: InputMaybe<Mobile_Params_Services_Inc_Input>;
  _set?: InputMaybe<Mobile_Params_Services_Set_Input>;
  where: Mobile_Params_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Params_Services_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Params_Services_Inc_Input>;
  _set?: InputMaybe<Mobile_Params_Services_Set_Input>;
  pk_columns: Mobile_Params_Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Params_Services_ManyArgs = {
  updates: Array<Mobile_Params_Services_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Personal_Info_ViewsArgs = {
  _inc?: InputMaybe<Mobile_Personal_Info_Views_Inc_Input>;
  _set?: InputMaybe<Mobile_Personal_Info_Views_Set_Input>;
  where: Mobile_Personal_Info_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Personal_Info_Views_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Personal_Info_Views_Inc_Input>;
  _set?: InputMaybe<Mobile_Personal_Info_Views_Set_Input>;
  pk_columns: Mobile_Personal_Info_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Personal_Info_Views_ManyArgs = {
  updates: Array<Mobile_Personal_Info_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Personal_InfosArgs = {
  _inc?: InputMaybe<Mobile_Personal_Infos_Inc_Input>;
  _set?: InputMaybe<Mobile_Personal_Infos_Set_Input>;
  where: Mobile_Personal_Infos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Personal_Infos_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Personal_Infos_Inc_Input>;
  _set?: InputMaybe<Mobile_Personal_Infos_Set_Input>;
  pk_columns: Mobile_Personal_Infos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Personal_Infos_ManyArgs = {
  updates: Array<Mobile_Personal_Infos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_PhonesArgs = {
  _inc?: InputMaybe<Mobile_Phones_Inc_Input>;
  _set?: InputMaybe<Mobile_Phones_Set_Input>;
  where: Mobile_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Phones_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Phones_Inc_Input>;
  _set?: InputMaybe<Mobile_Phones_Set_Input>;
  pk_columns: Mobile_Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Phones_ManyArgs = {
  updates: Array<Mobile_Phones_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Post_ParamsArgs = {
  _inc?: InputMaybe<Mobile_Post_Params_Inc_Input>;
  _set?: InputMaybe<Mobile_Post_Params_Set_Input>;
  where: Mobile_Post_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Post_Params_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Post_Params_Inc_Input>;
  _set?: InputMaybe<Mobile_Post_Params_Set_Input>;
  pk_columns: Mobile_Post_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Post_Params_ManyArgs = {
  updates: Array<Mobile_Post_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_PostsArgs = {
  _inc?: InputMaybe<Mobile_Posts_Inc_Input>;
  _set?: InputMaybe<Mobile_Posts_Set_Input>;
  where: Mobile_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Posts_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Posts_Inc_Input>;
  _set?: InputMaybe<Mobile_Posts_Set_Input>;
  pk_columns: Mobile_Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Posts_ManyArgs = {
  updates: Array<Mobile_Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_Document_StatusesArgs = {
  _inc?: InputMaybe<Mobile_Pro_Document_Statuses_Inc_Input>;
  _set?: InputMaybe<Mobile_Pro_Document_Statuses_Set_Input>;
  where: Mobile_Pro_Document_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_Document_Statuses_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Pro_Document_Statuses_Inc_Input>;
  _set?: InputMaybe<Mobile_Pro_Document_Statuses_Set_Input>;
  pk_columns: Mobile_Pro_Document_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_Document_Statuses_ManyArgs = {
  updates: Array<Mobile_Pro_Document_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_ProfessionsArgs = {
  _inc?: InputMaybe<Mobile_Pro_Professions_Inc_Input>;
  _set?: InputMaybe<Mobile_Pro_Professions_Set_Input>;
  where: Mobile_Pro_Professions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_Professions_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Pro_Professions_Inc_Input>;
  _set?: InputMaybe<Mobile_Pro_Professions_Set_Input>;
  pk_columns: Mobile_Pro_Professions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_Professions_ManyArgs = {
  updates: Array<Mobile_Pro_Professions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_TypesArgs = {
  _set?: InputMaybe<Mobile_Pro_Types_Set_Input>;
  where: Mobile_Pro_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_Types_By_PkArgs = {
  _set?: InputMaybe<Mobile_Pro_Types_Set_Input>;
  pk_columns: Mobile_Pro_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pro_Types_ManyArgs = {
  updates: Array<Mobile_Pro_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ProblemsArgs = {
  _inc?: InputMaybe<Mobile_Problems_Inc_Input>;
  _set?: InputMaybe<Mobile_Problems_Set_Input>;
  where: Mobile_Problems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Problems_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Problems_Inc_Input>;
  _set?: InputMaybe<Mobile_Problems_Set_Input>;
  pk_columns: Mobile_Problems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Problems_ManyArgs = {
  updates: Array<Mobile_Problems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profession_NamesArgs = {
  _inc?: InputMaybe<Mobile_Profession_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Profession_Names_Set_Input>;
  where: Mobile_Profession_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profession_Names_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Profession_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Profession_Names_Set_Input>;
  pk_columns: Mobile_Profession_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profession_Names_ManyArgs = {
  updates: Array<Mobile_Profession_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ProfessionsArgs = {
  _inc?: InputMaybe<Mobile_Professions_Inc_Input>;
  _set?: InputMaybe<Mobile_Professions_Set_Input>;
  where: Mobile_Professions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Professions_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Professions_Inc_Input>;
  _set?: InputMaybe<Mobile_Professions_Set_Input>;
  pk_columns: Mobile_Professions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Professions_ManyArgs = {
  updates: Array<Mobile_Professions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profile_DeletedsArgs = {
  _inc?: InputMaybe<Mobile_Profile_Deleteds_Inc_Input>;
  _set?: InputMaybe<Mobile_Profile_Deleteds_Set_Input>;
  where: Mobile_Profile_Deleteds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profile_Deleteds_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Profile_Deleteds_Inc_Input>;
  _set?: InputMaybe<Mobile_Profile_Deleteds_Set_Input>;
  pk_columns: Mobile_Profile_Deleteds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profile_Deleteds_ManyArgs = {
  updates: Array<Mobile_Profile_Deleteds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profile_LocksArgs = {
  _inc?: InputMaybe<Mobile_Profile_Locks_Inc_Input>;
  _set?: InputMaybe<Mobile_Profile_Locks_Set_Input>;
  where: Mobile_Profile_Locks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profile_Locks_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Profile_Locks_Inc_Input>;
  _set?: InputMaybe<Mobile_Profile_Locks_Set_Input>;
  pk_columns: Mobile_Profile_Locks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profile_Locks_ManyArgs = {
  updates: Array<Mobile_Profile_Locks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ProfilesArgs = {
  _inc?: InputMaybe<Mobile_Profiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Profiles_Set_Input>;
  where: Mobile_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profiles_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Profiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Profiles_Set_Input>;
  pk_columns: Mobile_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Profiles_ManyArgs = {
  updates: Array<Mobile_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ProsArgs = {
  _inc?: InputMaybe<Mobile_Pros_Inc_Input>;
  _set?: InputMaybe<Mobile_Pros_Set_Input>;
  where: Mobile_Pros_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pros_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Pros_Inc_Input>;
  _set?: InputMaybe<Mobile_Pros_Set_Input>;
  pk_columns: Mobile_Pros_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pros_ManyArgs = {
  updates: Array<Mobile_Pros_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulse_NormsArgs = {
  _inc?: InputMaybe<Mobile_Pulse_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Pulse_Norms_Set_Input>;
  where: Mobile_Pulse_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulse_Norms_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Pulse_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Pulse_Norms_Set_Input>;
  pk_columns: Mobile_Pulse_Norms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulse_Norms_ManyArgs = {
  updates: Array<Mobile_Pulse_Norms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulse_NowsArgs = {
  _inc?: InputMaybe<Mobile_Pulse_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Pulse_Nows_Set_Input>;
  where: Mobile_Pulse_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulse_Nows_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Pulse_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Pulse_Nows_Set_Input>;
  pk_columns: Mobile_Pulse_Nows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulse_Nows_ManyArgs = {
  updates: Array<Mobile_Pulse_Nows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_PulsesArgs = {
  _inc?: InputMaybe<Mobile_Pulses_Inc_Input>;
  _set?: InputMaybe<Mobile_Pulses_Set_Input>;
  where: Mobile_Pulses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulses_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Pulses_Inc_Input>;
  _set?: InputMaybe<Mobile_Pulses_Set_Input>;
  pk_columns: Mobile_Pulses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Pulses_ManyArgs = {
  updates: Array<Mobile_Pulses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ReactionsArgs = {
  _inc?: InputMaybe<Mobile_Reactions_Inc_Input>;
  _set?: InputMaybe<Mobile_Reactions_Set_Input>;
  where: Mobile_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Reactions_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Reactions_Inc_Input>;
  _set?: InputMaybe<Mobile_Reactions_Set_Input>;
  pk_columns: Mobile_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Reactions_ManyArgs = {
  updates: Array<Mobile_Reactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ReportsArgs = {
  _inc?: InputMaybe<Mobile_Reports_Inc_Input>;
  _set?: InputMaybe<Mobile_Reports_Set_Input>;
  where: Mobile_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Reports_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Reports_Inc_Input>;
  _set?: InputMaybe<Mobile_Reports_Set_Input>;
  pk_columns: Mobile_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Reports_ManyArgs = {
  updates: Array<Mobile_Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_AgreementsArgs = {
  _inc?: InputMaybe<Mobile_Service_Agreements_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Agreements_Set_Input>;
  where: Mobile_Service_Agreements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Agreements_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Agreements_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Agreements_Set_Input>;
  pk_columns: Mobile_Service_Agreements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Agreements_ManyArgs = {
  updates: Array<Mobile_Service_Agreements_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Answer_ReactionsArgs = {
  _inc?: InputMaybe<Mobile_Service_Answer_Reactions_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Answer_Reactions_Set_Input>;
  where: Mobile_Service_Answer_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Answer_Reactions_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Answer_Reactions_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Answer_Reactions_Set_Input>;
  pk_columns: Mobile_Service_Answer_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Answer_Reactions_ManyArgs = {
  updates: Array<Mobile_Service_Answer_Reactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_AnswersArgs = {
  _inc?: InputMaybe<Mobile_Service_Answers_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Answers_Set_Input>;
  where: Mobile_Service_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Answers_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Answers_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Answers_Set_Input>;
  pk_columns: Mobile_Service_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Answers_ManyArgs = {
  updates: Array<Mobile_Service_Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_DocumentsArgs = {
  _inc?: InputMaybe<Mobile_Service_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Documents_Set_Input>;
  where: Mobile_Service_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Documents_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Documents_Set_Input>;
  pk_columns: Mobile_Service_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Documents_ManyArgs = {
  updates: Array<Mobile_Service_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_FavoritesArgs = {
  _inc?: InputMaybe<Mobile_Service_Favorites_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Favorites_Set_Input>;
  where: Mobile_Service_Favorites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Favorites_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Favorites_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Favorites_Set_Input>;
  pk_columns: Mobile_Service_Favorites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Favorites_ManyArgs = {
  updates: Array<Mobile_Service_Favorites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Free_PurchasesArgs = {
  _inc?: InputMaybe<Mobile_Service_Free_Purchases_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Free_Purchases_Set_Input>;
  where: Mobile_Service_Free_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Free_Purchases_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Free_Purchases_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Free_Purchases_Set_Input>;
  pk_columns: Mobile_Service_Free_Purchases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Free_Purchases_ManyArgs = {
  updates: Array<Mobile_Service_Free_Purchases_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_ImagesArgs = {
  _inc?: InputMaybe<Mobile_Service_Images_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Images_Set_Input>;
  where: Mobile_Service_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Images_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Images_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Images_Set_Input>;
  pk_columns: Mobile_Service_Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Images_ManyArgs = {
  updates: Array<Mobile_Service_Images_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_LikesArgs = {
  _inc?: InputMaybe<Mobile_Service_Likes_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Likes_Set_Input>;
  where: Mobile_Service_Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Likes_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Likes_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Likes_Set_Input>;
  pk_columns: Mobile_Service_Likes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Likes_ManyArgs = {
  updates: Array<Mobile_Service_Likes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_List_DocumentsArgs = {
  _inc?: InputMaybe<Mobile_Service_List_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_List_Documents_Set_Input>;
  where: Mobile_Service_List_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_List_Documents_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_List_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_List_Documents_Set_Input>;
  pk_columns: Mobile_Service_List_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_List_Documents_ManyArgs = {
  updates: Array<Mobile_Service_List_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_List_PeriodicitiesArgs = {
  _inc?: InputMaybe<Mobile_Service_List_Periodicities_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_List_Periodicities_Set_Input>;
  where: Mobile_Service_List_Periodicities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_List_Periodicities_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_List_Periodicities_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_List_Periodicities_Set_Input>;
  pk_columns: Mobile_Service_List_Periodicities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_List_Periodicities_ManyArgs = {
  updates: Array<Mobile_Service_List_Periodicities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_ListsArgs = {
  _inc?: InputMaybe<Mobile_Service_Lists_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Lists_Set_Input>;
  where: Mobile_Service_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Lists_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Lists_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Lists_Set_Input>;
  pk_columns: Mobile_Service_Lists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Lists_ManyArgs = {
  updates: Array<Mobile_Service_Lists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_OrdersArgs = {
  _inc?: InputMaybe<Mobile_Service_Orders_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Orders_Set_Input>;
  where: Mobile_Service_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Orders_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Orders_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Orders_Set_Input>;
  pk_columns: Mobile_Service_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Orders_ManyArgs = {
  updates: Array<Mobile_Service_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_PeriodicitiesArgs = {
  _inc?: InputMaybe<Mobile_Service_Periodicities_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Periodicities_Set_Input>;
  where: Mobile_Service_Periodicities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Periodicities_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Periodicities_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Periodicities_Set_Input>;
  pk_columns: Mobile_Service_Periodicities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Periodicities_ManyArgs = {
  updates: Array<Mobile_Service_Periodicities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_PurchasesArgs = {
  _inc?: InputMaybe<Mobile_Service_Purchases_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Purchases_Set_Input>;
  where: Mobile_Service_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Purchases_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Purchases_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Purchases_Set_Input>;
  pk_columns: Mobile_Service_Purchases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Purchases_ManyArgs = {
  updates: Array<Mobile_Service_Purchases_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_QuestionsArgs = {
  _inc?: InputMaybe<Mobile_Service_Questions_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Questions_Set_Input>;
  where: Mobile_Service_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Questions_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Questions_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Questions_Set_Input>;
  pk_columns: Mobile_Service_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Questions_ManyArgs = {
  updates: Array<Mobile_Service_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_CommentsArgs = {
  _inc?: InputMaybe<Mobile_Service_Rate_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rate_Comments_Set_Input>;
  where: Mobile_Service_Rate_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_Comments_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Rate_Comments_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rate_Comments_Set_Input>;
  pk_columns: Mobile_Service_Rate_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_Comments_ManyArgs = {
  updates: Array<Mobile_Service_Rate_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_ImagesArgs = {
  _inc?: InputMaybe<Mobile_Service_Rate_Images_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rate_Images_Set_Input>;
  where: Mobile_Service_Rate_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_Images_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Rate_Images_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rate_Images_Set_Input>;
  pk_columns: Mobile_Service_Rate_Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_Images_ManyArgs = {
  updates: Array<Mobile_Service_Rate_Images_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_ReactionsArgs = {
  _inc?: InputMaybe<Mobile_Service_Rate_Reactions_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rate_Reactions_Set_Input>;
  where: Mobile_Service_Rate_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_Reactions_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Rate_Reactions_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rate_Reactions_Set_Input>;
  pk_columns: Mobile_Service_Rate_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rate_Reactions_ManyArgs = {
  updates: Array<Mobile_Service_Rate_Reactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_RatesArgs = {
  _inc?: InputMaybe<Mobile_Service_Rates_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rates_Set_Input>;
  where: Mobile_Service_Rates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rates_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Rates_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Rates_Set_Input>;
  pk_columns: Mobile_Service_Rates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Rates_ManyArgs = {
  updates: Array<Mobile_Service_Rates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_StatusesArgs = {
  _inc?: InputMaybe<Mobile_Service_Statuses_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Statuses_Set_Input>;
  where: Mobile_Service_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Statuses_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Statuses_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Statuses_Set_Input>;
  pk_columns: Mobile_Service_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Statuses_ManyArgs = {
  updates: Array<Mobile_Service_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_AgesArgs = {
  _inc?: InputMaybe<Mobile_Service_Tag_Ages_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tag_Ages_Set_Input>;
  where: Mobile_Service_Tag_Ages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_Ages_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Tag_Ages_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tag_Ages_Set_Input>;
  pk_columns: Mobile_Service_Tag_Ages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_Ages_ManyArgs = {
  updates: Array<Mobile_Service_Tag_Ages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_Target_GroupsArgs = {
  _inc?: InputMaybe<Mobile_Service_Tag_Target_Groups_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tag_Target_Groups_Set_Input>;
  where: Mobile_Service_Tag_Target_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_Target_Groups_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Tag_Target_Groups_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tag_Target_Groups_Set_Input>;
  pk_columns: Mobile_Service_Tag_Target_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_Target_Groups_ManyArgs = {
  updates: Array<Mobile_Service_Tag_Target_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_TargetsArgs = {
  _inc?: InputMaybe<Mobile_Service_Tag_Targets_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tag_Targets_Set_Input>;
  where: Mobile_Service_Tag_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_Targets_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Tag_Targets_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tag_Targets_Set_Input>;
  pk_columns: Mobile_Service_Tag_Targets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tag_Targets_ManyArgs = {
  updates: Array<Mobile_Service_Tag_Targets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tariff_DocumentsArgs = {
  _inc?: InputMaybe<Mobile_Service_Tariff_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tariff_Documents_Set_Input>;
  where: Mobile_Service_Tariff_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tariff_Documents_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Tariff_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tariff_Documents_Set_Input>;
  pk_columns: Mobile_Service_Tariff_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tariff_Documents_ManyArgs = {
  updates: Array<Mobile_Service_Tariff_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_TariffsArgs = {
  _inc?: InputMaybe<Mobile_Service_Tariffs_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tariffs_Set_Input>;
  where: Mobile_Service_Tariffs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tariffs_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Tariffs_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Tariffs_Set_Input>;
  pk_columns: Mobile_Service_Tariffs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Tariffs_ManyArgs = {
  updates: Array<Mobile_Service_Tariffs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Type_NamesArgs = {
  _inc?: InputMaybe<Mobile_Service_Type_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Type_Names_Set_Input>;
  where: Mobile_Service_Type_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Type_Names_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Type_Names_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Type_Names_Set_Input>;
  pk_columns: Mobile_Service_Type_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Type_Names_ManyArgs = {
  updates: Array<Mobile_Service_Type_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_TypesArgs = {
  _inc?: InputMaybe<Mobile_Service_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Types_Set_Input>;
  where: Mobile_Service_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Types_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Service_Types_Inc_Input>;
  _set?: InputMaybe<Mobile_Service_Types_Set_Input>;
  pk_columns: Mobile_Service_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Service_Types_ManyArgs = {
  updates: Array<Mobile_Service_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_ServicesArgs = {
  _inc?: InputMaybe<Mobile_Services_Inc_Input>;
  _set?: InputMaybe<Mobile_Services_Set_Input>;
  where: Mobile_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Services_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Services_Inc_Input>;
  _set?: InputMaybe<Mobile_Services_Set_Input>;
  pk_columns: Mobile_Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Services_ManyArgs = {
  updates: Array<Mobile_Services_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_SettingsArgs = {
  _inc?: InputMaybe<Mobile_Settings_Inc_Input>;
  _set?: InputMaybe<Mobile_Settings_Set_Input>;
  where: Mobile_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Settings_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Settings_Inc_Input>;
  _set?: InputMaybe<Mobile_Settings_Set_Input>;
  pk_columns: Mobile_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Settings_ManyArgs = {
  updates: Array<Mobile_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Share_PostsArgs = {
  _inc?: InputMaybe<Mobile_Share_Posts_Inc_Input>;
  _set?: InputMaybe<Mobile_Share_Posts_Set_Input>;
  where: Mobile_Share_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Share_Posts_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Share_Posts_Inc_Input>;
  _set?: InputMaybe<Mobile_Share_Posts_Set_Input>;
  pk_columns: Mobile_Share_Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Share_Posts_ManyArgs = {
  updates: Array<Mobile_Share_Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleep_NormsArgs = {
  _inc?: InputMaybe<Mobile_Sleep_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Sleep_Norms_Set_Input>;
  where: Mobile_Sleep_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleep_Norms_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Sleep_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Sleep_Norms_Set_Input>;
  pk_columns: Mobile_Sleep_Norms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleep_Norms_ManyArgs = {
  updates: Array<Mobile_Sleep_Norms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleep_NowsArgs = {
  _inc?: InputMaybe<Mobile_Sleep_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Sleep_Nows_Set_Input>;
  where: Mobile_Sleep_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleep_Nows_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Sleep_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Sleep_Nows_Set_Input>;
  pk_columns: Mobile_Sleep_Nows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleep_Nows_ManyArgs = {
  updates: Array<Mobile_Sleep_Nows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_SleepsArgs = {
  _inc?: InputMaybe<Mobile_Sleeps_Inc_Input>;
  _set?: InputMaybe<Mobile_Sleeps_Set_Input>;
  where: Mobile_Sleeps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleeps_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Sleeps_Inc_Input>;
  _set?: InputMaybe<Mobile_Sleeps_Set_Input>;
  pk_columns: Mobile_Sleeps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Sleeps_ManyArgs = {
  updates: Array<Mobile_Sleeps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_SlotsArgs = {
  _inc?: InputMaybe<Mobile_Slots_Inc_Input>;
  _set?: InputMaybe<Mobile_Slots_Set_Input>;
  where: Mobile_Slots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Slots_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Slots_Inc_Input>;
  _set?: InputMaybe<Mobile_Slots_Set_Input>;
  pk_columns: Mobile_Slots_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Slots_ManyArgs = {
  updates: Array<Mobile_Slots_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Status_MessagesArgs = {
  _inc?: InputMaybe<Mobile_Status_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Status_Messages_Set_Input>;
  where: Mobile_Status_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Status_Messages_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Status_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Status_Messages_Set_Input>;
  pk_columns: Mobile_Status_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Status_Messages_ManyArgs = {
  updates: Array<Mobile_Status_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Step_NormsArgs = {
  _inc?: InputMaybe<Mobile_Step_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Step_Norms_Set_Input>;
  where: Mobile_Step_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Step_Norms_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Step_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Step_Norms_Set_Input>;
  pk_columns: Mobile_Step_Norms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Step_Norms_ManyArgs = {
  updates: Array<Mobile_Step_Norms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Step_NowsArgs = {
  _inc?: InputMaybe<Mobile_Step_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Step_Nows_Set_Input>;
  where: Mobile_Step_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Step_Nows_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Step_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Step_Nows_Set_Input>;
  pk_columns: Mobile_Step_Nows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Step_Nows_ManyArgs = {
  updates: Array<Mobile_Step_Nows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_StepsArgs = {
  _inc?: InputMaybe<Mobile_Steps_Inc_Input>;
  _set?: InputMaybe<Mobile_Steps_Set_Input>;
  where: Mobile_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Steps_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Steps_Inc_Input>;
  _set?: InputMaybe<Mobile_Steps_Set_Input>;
  pk_columns: Mobile_Steps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Steps_ManyArgs = {
  updates: Array<Mobile_Steps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Subscribe_NotificationsArgs = {
  _inc?: InputMaybe<Mobile_Subscribe_Notifications_Inc_Input>;
  _set?: InputMaybe<Mobile_Subscribe_Notifications_Set_Input>;
  where: Mobile_Subscribe_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Subscribe_Notifications_ManyArgs = {
  updates: Array<Mobile_Subscribe_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_SubscribesArgs = {
  _inc?: InputMaybe<Mobile_Subscribes_Inc_Input>;
  _set?: InputMaybe<Mobile_Subscribes_Set_Input>;
  where: Mobile_Subscribes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Subscribes_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Subscribes_Inc_Input>;
  _set?: InputMaybe<Mobile_Subscribes_Set_Input>;
  pk_columns: Mobile_Subscribes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Subscribes_ManyArgs = {
  updates: Array<Mobile_Subscribes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Support_MessagesArgs = {
  _inc?: InputMaybe<Mobile_Support_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Support_Messages_Set_Input>;
  where: Mobile_Support_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Support_Messages_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Support_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_Support_Messages_Set_Input>;
  pk_columns: Mobile_Support_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Support_Messages_ManyArgs = {
  updates: Array<Mobile_Support_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Support_TopicsArgs = {
  _inc?: InputMaybe<Mobile_Support_Topics_Inc_Input>;
  _set?: InputMaybe<Mobile_Support_Topics_Set_Input>;
  where: Mobile_Support_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Support_Topics_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Support_Topics_Inc_Input>;
  _set?: InputMaybe<Mobile_Support_Topics_Set_Input>;
  pk_columns: Mobile_Support_Topics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Support_Topics_ManyArgs = {
  updates: Array<Mobile_Support_Topics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_AgesArgs = {
  _inc?: InputMaybe<Mobile_Tag_Ages_Inc_Input>;
  _set?: InputMaybe<Mobile_Tag_Ages_Set_Input>;
  where: Mobile_Tag_Ages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_Ages_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Tag_Ages_Inc_Input>;
  _set?: InputMaybe<Mobile_Tag_Ages_Set_Input>;
  pk_columns: Mobile_Tag_Ages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_Ages_ManyArgs = {
  updates: Array<Mobile_Tag_Ages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_Target_GroupsArgs = {
  _inc?: InputMaybe<Mobile_Tag_Target_Groups_Inc_Input>;
  _set?: InputMaybe<Mobile_Tag_Target_Groups_Set_Input>;
  where: Mobile_Tag_Target_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_Target_Groups_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Tag_Target_Groups_Inc_Input>;
  _set?: InputMaybe<Mobile_Tag_Target_Groups_Set_Input>;
  pk_columns: Mobile_Tag_Target_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_Target_Groups_ManyArgs = {
  updates: Array<Mobile_Tag_Target_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_TargetsArgs = {
  _inc?: InputMaybe<Mobile_Tag_Targets_Inc_Input>;
  _set?: InputMaybe<Mobile_Tag_Targets_Set_Input>;
  where: Mobile_Tag_Targets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_Targets_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Tag_Targets_Inc_Input>;
  _set?: InputMaybe<Mobile_Tag_Targets_Set_Input>;
  pk_columns: Mobile_Tag_Targets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Tag_Targets_ManyArgs = {
  updates: Array<Mobile_Tag_Targets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Type_PhonesArgs = {
  _inc?: InputMaybe<Mobile_Type_Phones_Inc_Input>;
  _set?: InputMaybe<Mobile_Type_Phones_Set_Input>;
  where: Mobile_Type_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Type_Phones_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Type_Phones_Inc_Input>;
  _set?: InputMaybe<Mobile_Type_Phones_Set_Input>;
  pk_columns: Mobile_Type_Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Type_Phones_ManyArgs = {
  updates: Array<Mobile_Type_Phones_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Type_ProfilesArgs = {
  _inc?: InputMaybe<Mobile_Type_Profiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Type_Profiles_Set_Input>;
  where: Mobile_Type_Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Type_Profiles_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Type_Profiles_Inc_Input>;
  _set?: InputMaybe<Mobile_Type_Profiles_Set_Input>;
  pk_columns: Mobile_Type_Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Type_Profiles_ManyArgs = {
  updates: Array<Mobile_Type_Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Upload_Pro_DocumentsArgs = {
  _inc?: InputMaybe<Mobile_Upload_Pro_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Upload_Pro_Documents_Set_Input>;
  where: Mobile_Upload_Pro_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Upload_Pro_Documents_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Upload_Pro_Documents_Inc_Input>;
  _set?: InputMaybe<Mobile_Upload_Pro_Documents_Set_Input>;
  pk_columns: Mobile_Upload_Pro_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Upload_Pro_Documents_ManyArgs = {
  updates: Array<Mobile_Upload_Pro_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_User_Chat_RightsArgs = {
  _inc?: InputMaybe<Mobile_User_Chat_Rights_Inc_Input>;
  _set?: InputMaybe<Mobile_User_Chat_Rights_Set_Input>;
  where: Mobile_User_Chat_Rights_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_User_Chat_Rights_By_PkArgs = {
  _inc?: InputMaybe<Mobile_User_Chat_Rights_Inc_Input>;
  _set?: InputMaybe<Mobile_User_Chat_Rights_Set_Input>;
  pk_columns: Mobile_User_Chat_Rights_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_User_Chat_Rights_ManyArgs = {
  updates: Array<Mobile_User_Chat_Rights_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_User_ChatsArgs = {
  _inc?: InputMaybe<Mobile_User_Chats_Inc_Input>;
  _set?: InputMaybe<Mobile_User_Chats_Set_Input>;
  where: Mobile_User_Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_User_Chats_By_PkArgs = {
  _inc?: InputMaybe<Mobile_User_Chats_Inc_Input>;
  _set?: InputMaybe<Mobile_User_Chats_Set_Input>;
  pk_columns: Mobile_User_Chats_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_User_Chats_ManyArgs = {
  updates: Array<Mobile_User_Chats_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_View_Chat_MessagesArgs = {
  _inc?: InputMaybe<Mobile_View_Chat_Messages_Inc_Input>;
  _set?: InputMaybe<Mobile_View_Chat_Messages_Set_Input>;
  where: Mobile_View_Chat_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_View_Chat_Messages_ManyArgs = {
  updates: Array<Mobile_View_Chat_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Water_NormsArgs = {
  _inc?: InputMaybe<Mobile_Water_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Water_Norms_Set_Input>;
  where: Mobile_Water_Norms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Water_Norms_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Water_Norms_Inc_Input>;
  _set?: InputMaybe<Mobile_Water_Norms_Set_Input>;
  pk_columns: Mobile_Water_Norms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Water_Norms_ManyArgs = {
  updates: Array<Mobile_Water_Norms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Water_NowsArgs = {
  _inc?: InputMaybe<Mobile_Water_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Water_Nows_Set_Input>;
  where: Mobile_Water_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Water_Nows_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Water_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Water_Nows_Set_Input>;
  pk_columns: Mobile_Water_Nows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Water_Nows_ManyArgs = {
  updates: Array<Mobile_Water_Nows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_WatersArgs = {
  _inc?: InputMaybe<Mobile_Waters_Inc_Input>;
  _set?: InputMaybe<Mobile_Waters_Set_Input>;
  where: Mobile_Waters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Waters_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Waters_Inc_Input>;
  _set?: InputMaybe<Mobile_Waters_Set_Input>;
  pk_columns: Mobile_Waters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Waters_ManyArgs = {
  updates: Array<Mobile_Waters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Weight_NowsArgs = {
  _inc?: InputMaybe<Mobile_Weight_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Weight_Nows_Set_Input>;
  where: Mobile_Weight_Nows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Weight_Nows_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Weight_Nows_Inc_Input>;
  _set?: InputMaybe<Mobile_Weight_Nows_Set_Input>;
  pk_columns: Mobile_Weight_Nows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Weight_Nows_ManyArgs = {
  updates: Array<Mobile_Weight_Nows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_WeightsArgs = {
  _inc?: InputMaybe<Mobile_Weights_Inc_Input>;
  _set?: InputMaybe<Mobile_Weights_Set_Input>;
  where: Mobile_Weights_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Weights_By_PkArgs = {
  _inc?: InputMaybe<Mobile_Weights_Inc_Input>;
  _set?: InputMaybe<Mobile_Weights_Set_Input>;
  pk_columns: Mobile_Weights_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mobile_Weights_ManyArgs = {
  updates: Array<Mobile_Weights_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_BalancesArgs = {
  _inc?: InputMaybe<Partner_Balances_Inc_Input>;
  _set?: InputMaybe<Partner_Balances_Set_Input>;
  where: Partner_Balances_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Balances_By_PkArgs = {
  _inc?: InputMaybe<Partner_Balances_Inc_Input>;
  _set?: InputMaybe<Partner_Balances_Set_Input>;
  pk_columns: Partner_Balances_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Balances_ManyArgs = {
  updates: Array<Partner_Balances_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_SalesArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Sales_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Sales_Set_Input>;
  where: Partner_Log_Vzr_Sales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Sales_By_PkArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Sales_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Sales_Set_Input>;
  pk_columns: Partner_Log_Vzr_Sales_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Sales_ManyArgs = {
  updates: Array<Partner_Log_Vzr_Sales_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_SearchesArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Searches_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Searches_Set_Input>;
  where: Partner_Log_Vzr_Searches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Searches_By_PkArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Searches_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Searches_Set_Input>;
  pk_columns: Partner_Log_Vzr_Searches_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Searches_ManyArgs = {
  updates: Array<Partner_Log_Vzr_Searches_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_ViewsArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Views_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Views_Set_Input>;
  where: Partner_Log_Vzr_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Views_By_PkArgs = {
  _inc?: InputMaybe<Partner_Log_Vzr_Views_Inc_Input>;
  _set?: InputMaybe<Partner_Log_Vzr_Views_Set_Input>;
  pk_columns: Partner_Log_Vzr_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Log_Vzr_Views_ManyArgs = {
  updates: Array<Partner_Log_Vzr_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Place_Type_IntegrationsArgs = {
  _inc?: InputMaybe<Partner_Place_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Place_Type_Integrations_Set_Input>;
  where: Partner_Place_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Place_Type_Integrations_By_PkArgs = {
  _inc?: InputMaybe<Partner_Place_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Place_Type_Integrations_Set_Input>;
  pk_columns: Partner_Place_Type_Integrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Place_Type_Integrations_ManyArgs = {
  updates: Array<Partner_Place_Type_Integrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_PlacesArgs = {
  _inc?: InputMaybe<Partner_Places_Inc_Input>;
  _set?: InputMaybe<Partner_Places_Set_Input>;
  where: Partner_Places_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Places_By_PkArgs = {
  _inc?: InputMaybe<Partner_Places_Inc_Input>;
  _set?: InputMaybe<Partner_Places_Set_Input>;
  pk_columns: Partner_Places_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Places_ManyArgs = {
  updates: Array<Partner_Places_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Sale_TypesArgs = {
  _inc?: InputMaybe<Partner_Sale_Types_Inc_Input>;
  _set?: InputMaybe<Partner_Sale_Types_Set_Input>;
  where: Partner_Sale_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Sale_Types_By_PkArgs = {
  _inc?: InputMaybe<Partner_Sale_Types_Inc_Input>;
  _set?: InputMaybe<Partner_Sale_Types_Set_Input>;
  pk_columns: Partner_Sale_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Sale_Types_ManyArgs = {
  updates: Array<Partner_Sale_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_IntegrationsArgs = {
  _inc?: InputMaybe<Partner_Status_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Integrations_Set_Input>;
  where: Partner_Status_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Integrations_By_PkArgs = {
  _inc?: InputMaybe<Partner_Status_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Integrations_Set_Input>;
  pk_columns: Partner_Status_Integrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Integrations_ManyArgs = {
  updates: Array<Partner_Status_Integrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_TransactionsArgs = {
  _inc?: InputMaybe<Partner_Status_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Transactions_Set_Input>;
  where: Partner_Status_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Partner_Status_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Status_Transactions_Set_Input>;
  pk_columns: Partner_Status_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Status_Transactions_ManyArgs = {
  updates: Array<Partner_Status_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_ThemesArgs = {
  _inc?: InputMaybe<Partner_Themes_Inc_Input>;
  _set?: InputMaybe<Partner_Themes_Set_Input>;
  where: Partner_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Themes_By_PkArgs = {
  _inc?: InputMaybe<Partner_Themes_Inc_Input>;
  _set?: InputMaybe<Partner_Themes_Set_Input>;
  pk_columns: Partner_Themes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Themes_ManyArgs = {
  updates: Array<Partner_Themes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_IntegrationsArgs = {
  _inc?: InputMaybe<Partner_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Integrations_Set_Input>;
  where: Partner_Type_Integrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Integrations_By_PkArgs = {
  _inc?: InputMaybe<Partner_Type_Integrations_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Integrations_Set_Input>;
  pk_columns: Partner_Type_Integrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Integrations_ManyArgs = {
  updates: Array<Partner_Type_Integrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_TransactionsArgs = {
  _inc?: InputMaybe<Partner_Type_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Transactions_Set_Input>;
  where: Partner_Type_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Partner_Type_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Type_Transactions_Set_Input>;
  pk_columns: Partner_Type_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Type_Transactions_ManyArgs = {
  updates: Array<Partner_Type_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_User_TypesArgs = {
  _inc?: InputMaybe<Partner_User_Types_Inc_Input>;
  _set?: InputMaybe<Partner_User_Types_Set_Input>;
  where: Partner_User_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_User_Types_By_PkArgs = {
  _inc?: InputMaybe<Partner_User_Types_Inc_Input>;
  _set?: InputMaybe<Partner_User_Types_Set_Input>;
  pk_columns: Partner_User_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_User_Types_ManyArgs = {
  updates: Array<Partner_User_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_UsersArgs = {
  _inc?: InputMaybe<Partner_Users_Inc_Input>;
  _set?: InputMaybe<Partner_Users_Set_Input>;
  where: Partner_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Users_By_PkArgs = {
  _inc?: InputMaybe<Partner_Users_Inc_Input>;
  _set?: InputMaybe<Partner_Users_Set_Input>;
  pk_columns: Partner_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Users_ManyArgs = {
  updates: Array<Partner_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_UsersArgs = {
  _inc?: InputMaybe<Service_Users_Inc_Input>;
  _set?: InputMaybe<Service_Users_Set_Input>;
  where: Service_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Users_By_PkArgs = {
  _inc?: InputMaybe<Service_Users_Inc_Input>;
  _set?: InputMaybe<Service_Users_Set_Input>;
  pk_columns: Service_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Users_ManyArgs = {
  updates: Array<Service_Users_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type PartnerConfirmFileUploadingInput = {
  link: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};

export type PartnerConfirmFileUploadingOutput = {
  __typename?: 'partnerConfirmFileUploadingOutput';
  error: Scalars['String']['output'];
  status_right: Scalars['String']['output'];
};

export type PartnerCreateFileAccessOutput = {
  __typename?: 'partnerCreateFileAccessOutput';
  error: Scalars['String']['output'];
  file_name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PartnerCreatePlaceInput = {
  address_url: Scalars['String']['input'];
  name: Scalars['String']['input'];
  theme_id: Scalars['Int']['input'];
};

export type PartnerCreatePlaceOutput = {
  __typename?: 'partnerCreatePlaceOutput';
  address_url: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  theme_id: Scalars['Int']['output'];
  uid: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
};

export type PartnerCreatePlaceTypeIntegrationsInput = {
  place_id: Scalars['Int']['input'];
  type_integration_id: Scalars['Int']['input'];
};

export type PartnerCreatePlaceTypeIntegrationsOutput = {
  __typename?: 'partnerCreatePlaceTypeIntegrationsOutput';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  place_id: Scalars['Int']['output'];
  status_integration_id: Scalars['Int']['output'];
  type_integration_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['Int']['output'];
};

export type PartnerCurrentBalanceOutput = {
  __typename?: 'partnerCurrentBalanceOutput';
  balance: Scalars['String']['output'];
};

export type PartnerFileAccessInput = {
  id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

export type PartnerInsertBalancesInput = {
  amount: Scalars['Float']['input'];
  id_sale?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sale_type_id?: InputMaybe<Scalars['Int']['input']>;
  type_transaction_id: Scalars['Int']['input'];
};

export type PartnerInsertBalancesOutput = {
  __typename?: 'partnerInsertBalancesOutput';
  amount: Scalars['Float']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  id_sale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sale_type_id?: Maybe<Scalars['String']['output']>;
  status_transaction_id: Scalars['Int']['output'];
  type_transaction_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['Int']['output'];
};

/** columns and relationships of "balances" */
export type Partner_Balances = {
  __typename?: 'partner_balances';
  amount?: Maybe<Scalars['float8']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  id_sale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sale_type_id?: Maybe<Scalars['bigint']['output']>;
  status_transaction_id?: Maybe<Scalars['bigint']['output']>;
  type_transaction_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "balances" */
export type Partner_Balances_Aggregate = {
  __typename?: 'partner_balances_aggregate';
  aggregate?: Maybe<Partner_Balances_Aggregate_Fields>;
  nodes: Array<Partner_Balances>;
};

/** aggregate fields of "balances" */
export type Partner_Balances_Aggregate_Fields = {
  __typename?: 'partner_balances_aggregate_fields';
  avg?: Maybe<Partner_Balances_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Balances_Max_Fields>;
  min?: Maybe<Partner_Balances_Min_Fields>;
  stddev?: Maybe<Partner_Balances_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Balances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Balances_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Balances_Sum_Fields>;
  var_pop?: Maybe<Partner_Balances_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Balances_Var_Samp_Fields>;
  variance?: Maybe<Partner_Balances_Variance_Fields>;
};


/** aggregate fields of "balances" */
export type Partner_Balances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Balances_Avg_Fields = {
  __typename?: 'partner_balances_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sale_type_id?: Maybe<Scalars['Float']['output']>;
  status_transaction_id?: Maybe<Scalars['Float']['output']>;
  type_transaction_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "balances". All fields are combined with a logical 'AND'. */
export type Partner_Balances_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Balances_Bool_Exp>>;
  _not?: InputMaybe<Partner_Balances_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Balances_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  id_sale?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sale_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  status_transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
  type_transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "balances" */
export enum Partner_Balances_Constraint {
  /** unique or primary key constraint on columns "id" */
  BalancesPkey = 'balances_pkey'
}

/** input type for incrementing numeric columns in table "balances" */
export type Partner_Balances_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  sale_type_id?: InputMaybe<Scalars['bigint']['input']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "balances" */
export type Partner_Balances_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  id_sale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sale_type_id?: InputMaybe<Scalars['bigint']['input']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Partner_Balances_Max_Fields = {
  __typename?: 'partner_balances_max_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  id_sale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sale_type_id?: Maybe<Scalars['bigint']['output']>;
  status_transaction_id?: Maybe<Scalars['bigint']['output']>;
  type_transaction_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Partner_Balances_Min_Fields = {
  __typename?: 'partner_balances_min_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  id_sale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sale_type_id?: Maybe<Scalars['bigint']['output']>;
  status_transaction_id?: Maybe<Scalars['bigint']['output']>;
  type_transaction_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "balances" */
export type Partner_Balances_Mutation_Response = {
  __typename?: 'partner_balances_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Balances>;
};

/** on_conflict condition type for table "balances" */
export type Partner_Balances_On_Conflict = {
  constraint: Partner_Balances_Constraint;
  update_columns?: Array<Partner_Balances_Update_Column>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};

/** Ordering options when selecting data from "balances". */
export type Partner_Balances_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_sale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sale_type_id?: InputMaybe<Order_By>;
  status_transaction_id?: InputMaybe<Order_By>;
  type_transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: balances */
export type Partner_Balances_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "balances" */
export enum Partner_Balances_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdSale = 'id_sale',
  /** column name */
  Name = 'name',
  /** column name */
  SaleTypeId = 'sale_type_id',
  /** column name */
  StatusTransactionId = 'status_transaction_id',
  /** column name */
  TypeTransactionId = 'type_transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "balances" */
export type Partner_Balances_Set_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  id_sale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sale_type_id?: InputMaybe<Scalars['bigint']['input']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Balances_Stddev_Fields = {
  __typename?: 'partner_balances_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sale_type_id?: Maybe<Scalars['Float']['output']>;
  status_transaction_id?: Maybe<Scalars['Float']['output']>;
  type_transaction_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Balances_Stddev_Pop_Fields = {
  __typename?: 'partner_balances_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sale_type_id?: Maybe<Scalars['Float']['output']>;
  status_transaction_id?: Maybe<Scalars['Float']['output']>;
  type_transaction_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Balances_Stddev_Samp_Fields = {
  __typename?: 'partner_balances_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sale_type_id?: Maybe<Scalars['Float']['output']>;
  status_transaction_id?: Maybe<Scalars['Float']['output']>;
  type_transaction_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_balances" */
export type Partner_Balances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Balances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Balances_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  id_sale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sale_type_id?: InputMaybe<Scalars['bigint']['input']>;
  status_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  type_transaction_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Partner_Balances_Sum_Fields = {
  __typename?: 'partner_balances_sum_fields';
  amount?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  sale_type_id?: Maybe<Scalars['bigint']['output']>;
  status_transaction_id?: Maybe<Scalars['bigint']['output']>;
  type_transaction_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "balances" */
export enum Partner_Balances_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdSale = 'id_sale',
  /** column name */
  Name = 'name',
  /** column name */
  SaleTypeId = 'sale_type_id',
  /** column name */
  StatusTransactionId = 'status_transaction_id',
  /** column name */
  TypeTransactionId = 'type_transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Balances_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Balances_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Balances_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Balances_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Balances_Var_Pop_Fields = {
  __typename?: 'partner_balances_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sale_type_id?: Maybe<Scalars['Float']['output']>;
  status_transaction_id?: Maybe<Scalars['Float']['output']>;
  type_transaction_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Balances_Var_Samp_Fields = {
  __typename?: 'partner_balances_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sale_type_id?: Maybe<Scalars['Float']['output']>;
  status_transaction_id?: Maybe<Scalars['Float']['output']>;
  type_transaction_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Balances_Variance_Fields = {
  __typename?: 'partner_balances_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  sale_type_id?: Maybe<Scalars['Float']['output']>;
  status_transaction_id?: Maybe<Scalars['Float']['output']>;
  type_transaction_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales = {
  __typename?: 'partner_log_vzr_sales';
  country?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  date_find?: Maybe<Scalars['date']['output']>;
  flight_end_date?: Maybe<Scalars['date']['output']>;
  flight_start_date?: Maybe<Scalars['date']['output']>;
  holiday_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  succefull?: Maybe<Scalars['Boolean']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Aggregate = {
  __typename?: 'partner_log_vzr_sales_aggregate';
  aggregate?: Maybe<Partner_Log_Vzr_Sales_Aggregate_Fields>;
  nodes: Array<Partner_Log_Vzr_Sales>;
};

/** aggregate fields of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Aggregate_Fields = {
  __typename?: 'partner_log_vzr_sales_aggregate_fields';
  avg?: Maybe<Partner_Log_Vzr_Sales_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Log_Vzr_Sales_Max_Fields>;
  min?: Maybe<Partner_Log_Vzr_Sales_Min_Fields>;
  stddev?: Maybe<Partner_Log_Vzr_Sales_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Log_Vzr_Sales_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Log_Vzr_Sales_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Log_Vzr_Sales_Sum_Fields>;
  var_pop?: Maybe<Partner_Log_Vzr_Sales_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Log_Vzr_Sales_Var_Samp_Fields>;
  variance?: Maybe<Partner_Log_Vzr_Sales_Variance_Fields>;
};


/** aggregate fields of "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Log_Vzr_Sales_Avg_Fields = {
  __typename?: 'partner_log_vzr_sales_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "log_vzr_sales". All fields are combined with a logical 'AND'. */
export type Partner_Log_Vzr_Sales_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Log_Vzr_Sales_Bool_Exp>>;
  _not?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Log_Vzr_Sales_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_find?: InputMaybe<Date_Comparison_Exp>;
  flight_end_date?: InputMaybe<Date_Comparison_Exp>;
  flight_start_date?: InputMaybe<Date_Comparison_Exp>;
  holiday_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  succefull?: InputMaybe<Boolean_Comparison_Exp>;
  tourist_number?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "log_vzr_sales" */
export enum Partner_Log_Vzr_Sales_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogVzrSalesPkey = 'log_vzr_sales_pkey'
}

/** input type for incrementing numeric columns in table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Insert_Input = {
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_find?: InputMaybe<Scalars['date']['input']>;
  flight_end_date?: InputMaybe<Scalars['date']['input']>;
  flight_start_date?: InputMaybe<Scalars['date']['input']>;
  holiday_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  succefull?: InputMaybe<Scalars['Boolean']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Partner_Log_Vzr_Sales_Max_Fields = {
  __typename?: 'partner_log_vzr_sales_max_fields';
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_find?: Maybe<Scalars['date']['output']>;
  flight_end_date?: Maybe<Scalars['date']['output']>;
  flight_start_date?: Maybe<Scalars['date']['output']>;
  holiday_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Partner_Log_Vzr_Sales_Min_Fields = {
  __typename?: 'partner_log_vzr_sales_min_fields';
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_find?: Maybe<Scalars['date']['output']>;
  flight_end_date?: Maybe<Scalars['date']['output']>;
  flight_start_date?: Maybe<Scalars['date']['output']>;
  holiday_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Mutation_Response = {
  __typename?: 'partner_log_vzr_sales_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Log_Vzr_Sales>;
};

/** on_conflict condition type for table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_On_Conflict = {
  constraint: Partner_Log_Vzr_Sales_Constraint;
  update_columns?: Array<Partner_Log_Vzr_Sales_Update_Column>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};

/** Ordering options when selecting data from "log_vzr_sales". */
export type Partner_Log_Vzr_Sales_Order_By = {
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_find?: InputMaybe<Order_By>;
  flight_end_date?: InputMaybe<Order_By>;
  flight_start_date?: InputMaybe<Order_By>;
  holiday_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  succefull?: InputMaybe<Order_By>;
  tourist_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: log_vzr_sales */
export type Partner_Log_Vzr_Sales_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "log_vzr_sales" */
export enum Partner_Log_Vzr_Sales_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  Succefull = 'succefull',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Set_Input = {
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_find?: InputMaybe<Scalars['date']['input']>;
  flight_end_date?: InputMaybe<Scalars['date']['input']>;
  flight_start_date?: InputMaybe<Scalars['date']['input']>;
  holiday_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  succefull?: InputMaybe<Scalars['Boolean']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Log_Vzr_Sales_Stddev_Fields = {
  __typename?: 'partner_log_vzr_sales_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Log_Vzr_Sales_Stddev_Pop_Fields = {
  __typename?: 'partner_log_vzr_sales_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Log_Vzr_Sales_Stddev_Samp_Fields = {
  __typename?: 'partner_log_vzr_sales_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_log_vzr_sales" */
export type Partner_Log_Vzr_Sales_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Log_Vzr_Sales_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Log_Vzr_Sales_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_find?: InputMaybe<Scalars['date']['input']>;
  flight_end_date?: InputMaybe<Scalars['date']['input']>;
  flight_start_date?: InputMaybe<Scalars['date']['input']>;
  holiday_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  succefull?: InputMaybe<Scalars['Boolean']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Partner_Log_Vzr_Sales_Sum_Fields = {
  __typename?: 'partner_log_vzr_sales_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "log_vzr_sales" */
export enum Partner_Log_Vzr_Sales_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  Succefull = 'succefull',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Log_Vzr_Sales_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Log_Vzr_Sales_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Log_Vzr_Sales_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Log_Vzr_Sales_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Log_Vzr_Sales_Var_Pop_Fields = {
  __typename?: 'partner_log_vzr_sales_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Log_Vzr_Sales_Var_Samp_Fields = {
  __typename?: 'partner_log_vzr_sales_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Log_Vzr_Sales_Variance_Fields = {
  __typename?: 'partner_log_vzr_sales_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches = {
  __typename?: 'partner_log_vzr_searches';
  country?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  date_find?: Maybe<Scalars['date']['output']>;
  flight_end_date?: Maybe<Scalars['date']['output']>;
  flight_start_date?: Maybe<Scalars['date']['output']>;
  holiday_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Aggregate = {
  __typename?: 'partner_log_vzr_searches_aggregate';
  aggregate?: Maybe<Partner_Log_Vzr_Searches_Aggregate_Fields>;
  nodes: Array<Partner_Log_Vzr_Searches>;
};

/** aggregate fields of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Aggregate_Fields = {
  __typename?: 'partner_log_vzr_searches_aggregate_fields';
  avg?: Maybe<Partner_Log_Vzr_Searches_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Log_Vzr_Searches_Max_Fields>;
  min?: Maybe<Partner_Log_Vzr_Searches_Min_Fields>;
  stddev?: Maybe<Partner_Log_Vzr_Searches_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Log_Vzr_Searches_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Log_Vzr_Searches_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Log_Vzr_Searches_Sum_Fields>;
  var_pop?: Maybe<Partner_Log_Vzr_Searches_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Log_Vzr_Searches_Var_Samp_Fields>;
  variance?: Maybe<Partner_Log_Vzr_Searches_Variance_Fields>;
};


/** aggregate fields of "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Log_Vzr_Searches_Avg_Fields = {
  __typename?: 'partner_log_vzr_searches_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "log_vzr_searches". All fields are combined with a logical 'AND'. */
export type Partner_Log_Vzr_Searches_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Log_Vzr_Searches_Bool_Exp>>;
  _not?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Log_Vzr_Searches_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_find?: InputMaybe<Date_Comparison_Exp>;
  flight_end_date?: InputMaybe<Date_Comparison_Exp>;
  flight_start_date?: InputMaybe<Date_Comparison_Exp>;
  holiday_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  tourist_number?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "log_vzr_searches" */
export enum Partner_Log_Vzr_Searches_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogVzrSearchesPkey = 'log_vzr_searches_pkey'
}

/** input type for incrementing numeric columns in table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Insert_Input = {
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_find?: InputMaybe<Scalars['date']['input']>;
  flight_end_date?: InputMaybe<Scalars['date']['input']>;
  flight_start_date?: InputMaybe<Scalars['date']['input']>;
  holiday_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Partner_Log_Vzr_Searches_Max_Fields = {
  __typename?: 'partner_log_vzr_searches_max_fields';
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_find?: Maybe<Scalars['date']['output']>;
  flight_end_date?: Maybe<Scalars['date']['output']>;
  flight_start_date?: Maybe<Scalars['date']['output']>;
  holiday_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Partner_Log_Vzr_Searches_Min_Fields = {
  __typename?: 'partner_log_vzr_searches_min_fields';
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_find?: Maybe<Scalars['date']['output']>;
  flight_end_date?: Maybe<Scalars['date']['output']>;
  flight_start_date?: Maybe<Scalars['date']['output']>;
  holiday_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Mutation_Response = {
  __typename?: 'partner_log_vzr_searches_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Log_Vzr_Searches>;
};

/** on_conflict condition type for table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_On_Conflict = {
  constraint: Partner_Log_Vzr_Searches_Constraint;
  update_columns?: Array<Partner_Log_Vzr_Searches_Update_Column>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};

/** Ordering options when selecting data from "log_vzr_searches". */
export type Partner_Log_Vzr_Searches_Order_By = {
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_find?: InputMaybe<Order_By>;
  flight_end_date?: InputMaybe<Order_By>;
  flight_start_date?: InputMaybe<Order_By>;
  holiday_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  tourist_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: log_vzr_searches */
export type Partner_Log_Vzr_Searches_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "log_vzr_searches" */
export enum Partner_Log_Vzr_Searches_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Set_Input = {
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_find?: InputMaybe<Scalars['date']['input']>;
  flight_end_date?: InputMaybe<Scalars['date']['input']>;
  flight_start_date?: InputMaybe<Scalars['date']['input']>;
  holiday_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Log_Vzr_Searches_Stddev_Fields = {
  __typename?: 'partner_log_vzr_searches_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Log_Vzr_Searches_Stddev_Pop_Fields = {
  __typename?: 'partner_log_vzr_searches_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Log_Vzr_Searches_Stddev_Samp_Fields = {
  __typename?: 'partner_log_vzr_searches_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_log_vzr_searches" */
export type Partner_Log_Vzr_Searches_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Log_Vzr_Searches_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Log_Vzr_Searches_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_find?: InputMaybe<Scalars['date']['input']>;
  flight_end_date?: InputMaybe<Scalars['date']['input']>;
  flight_start_date?: InputMaybe<Scalars['date']['input']>;
  holiday_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['float8']['input']>;
  tourist_number?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Partner_Log_Vzr_Searches_Sum_Fields = {
  __typename?: 'partner_log_vzr_searches_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['float8']['output']>;
  tourist_number?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "log_vzr_searches" */
export enum Partner_Log_Vzr_Searches_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateFind = 'date_find',
  /** column name */
  FlightEndDate = 'flight_end_date',
  /** column name */
  FlightStartDate = 'flight_start_date',
  /** column name */
  HolidayType = 'holiday_type',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Price = 'price',
  /** column name */
  TouristNumber = 'tourist_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Log_Vzr_Searches_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Log_Vzr_Searches_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Log_Vzr_Searches_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Log_Vzr_Searches_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Log_Vzr_Searches_Var_Pop_Fields = {
  __typename?: 'partner_log_vzr_searches_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Log_Vzr_Searches_Var_Samp_Fields = {
  __typename?: 'partner_log_vzr_searches_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Log_Vzr_Searches_Variance_Fields = {
  __typename?: 'partner_log_vzr_searches_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  tourist_number?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "log_vzr_views" */
export type Partner_Log_Vzr_Views = {
  __typename?: 'partner_log_vzr_views';
  browser?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "log_vzr_views" */
export type Partner_Log_Vzr_Views_Aggregate = {
  __typename?: 'partner_log_vzr_views_aggregate';
  aggregate?: Maybe<Partner_Log_Vzr_Views_Aggregate_Fields>;
  nodes: Array<Partner_Log_Vzr_Views>;
};

/** aggregate fields of "log_vzr_views" */
export type Partner_Log_Vzr_Views_Aggregate_Fields = {
  __typename?: 'partner_log_vzr_views_aggregate_fields';
  avg?: Maybe<Partner_Log_Vzr_Views_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Log_Vzr_Views_Max_Fields>;
  min?: Maybe<Partner_Log_Vzr_Views_Min_Fields>;
  stddev?: Maybe<Partner_Log_Vzr_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Log_Vzr_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Log_Vzr_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Log_Vzr_Views_Sum_Fields>;
  var_pop?: Maybe<Partner_Log_Vzr_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Log_Vzr_Views_Var_Samp_Fields>;
  variance?: Maybe<Partner_Log_Vzr_Views_Variance_Fields>;
};


/** aggregate fields of "log_vzr_views" */
export type Partner_Log_Vzr_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Log_Vzr_Views_Avg_Fields = {
  __typename?: 'partner_log_vzr_views_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "log_vzr_views". All fields are combined with a logical 'AND'. */
export type Partner_Log_Vzr_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Log_Vzr_Views_Bool_Exp>>;
  _not?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Log_Vzr_Views_Bool_Exp>>;
  browser?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  os?: InputMaybe<String_Comparison_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "log_vzr_views" */
export enum Partner_Log_Vzr_Views_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogVzrViewsPkey = 'log_vzr_views_pkey'
}

/** input type for incrementing numeric columns in table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Insert_Input = {
  browser?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Partner_Log_Vzr_Views_Max_Fields = {
  __typename?: 'partner_log_vzr_views_max_fields';
  browser?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Partner_Log_Vzr_Views_Min_Fields = {
  __typename?: 'partner_log_vzr_views_min_fields';
  browser?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Mutation_Response = {
  __typename?: 'partner_log_vzr_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Log_Vzr_Views>;
};

/** on_conflict condition type for table "log_vzr_views" */
export type Partner_Log_Vzr_Views_On_Conflict = {
  constraint: Partner_Log_Vzr_Views_Constraint;
  update_columns?: Array<Partner_Log_Vzr_Views_Update_Column>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "log_vzr_views". */
export type Partner_Log_Vzr_Views_Order_By = {
  browser?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  os?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: log_vzr_views */
export type Partner_Log_Vzr_Views_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "log_vzr_views" */
export enum Partner_Log_Vzr_Views_Select_Column {
  /** column name */
  Browser = 'browser',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Os = 'os',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "log_vzr_views" */
export type Partner_Log_Vzr_Views_Set_Input = {
  browser?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Log_Vzr_Views_Stddev_Fields = {
  __typename?: 'partner_log_vzr_views_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Log_Vzr_Views_Stddev_Pop_Fields = {
  __typename?: 'partner_log_vzr_views_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Log_Vzr_Views_Stddev_Samp_Fields = {
  __typename?: 'partner_log_vzr_views_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_log_vzr_views" */
export type Partner_Log_Vzr_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Log_Vzr_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Log_Vzr_Views_Stream_Cursor_Value_Input = {
  browser?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Partner_Log_Vzr_Views_Sum_Fields = {
  __typename?: 'partner_log_vzr_views_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "log_vzr_views" */
export enum Partner_Log_Vzr_Views_Update_Column {
  /** column name */
  Browser = 'browser',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Os = 'os',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Log_Vzr_Views_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Log_Vzr_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Log_Vzr_Views_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Log_Vzr_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Log_Vzr_Views_Var_Pop_Fields = {
  __typename?: 'partner_log_vzr_views_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Log_Vzr_Views_Var_Samp_Fields = {
  __typename?: 'partner_log_vzr_views_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Log_Vzr_Views_Variance_Fields = {
  __typename?: 'partner_log_vzr_views_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "place_type_integrations" */
export type Partner_Place_Type_Integrations = {
  __typename?: 'partner_place_type_integrations';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  place?: Maybe<Partner_Places>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  status_integration?: Maybe<Partner_Status_Integrations>;
  status_integration_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  type_integration?: Maybe<Partner_Type_Integrations>;
  type_integration_id?: Maybe<Scalars['bigint']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "place_type_integrations" */
export type Partner_Place_Type_Integrations_Aggregate = {
  __typename?: 'partner_place_type_integrations_aggregate';
  aggregate?: Maybe<Partner_Place_Type_Integrations_Aggregate_Fields>;
  nodes: Array<Partner_Place_Type_Integrations>;
};

/** aggregate fields of "place_type_integrations" */
export type Partner_Place_Type_Integrations_Aggregate_Fields = {
  __typename?: 'partner_place_type_integrations_aggregate_fields';
  avg?: Maybe<Partner_Place_Type_Integrations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Place_Type_Integrations_Max_Fields>;
  min?: Maybe<Partner_Place_Type_Integrations_Min_Fields>;
  stddev?: Maybe<Partner_Place_Type_Integrations_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Place_Type_Integrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Place_Type_Integrations_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Place_Type_Integrations_Sum_Fields>;
  var_pop?: Maybe<Partner_Place_Type_Integrations_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Place_Type_Integrations_Var_Samp_Fields>;
  variance?: Maybe<Partner_Place_Type_Integrations_Variance_Fields>;
};


/** aggregate fields of "place_type_integrations" */
export type Partner_Place_Type_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Place_Type_Integrations_Avg_Fields = {
  __typename?: 'partner_place_type_integrations_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  status_integration_id?: Maybe<Scalars['Float']['output']>;
  type_integration_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "place_type_integrations". All fields are combined with a logical 'AND'. */
export type Partner_Place_Type_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Place_Type_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Place_Type_Integrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  place?: InputMaybe<Partner_Places_Bool_Exp>;
  place_id?: InputMaybe<Bigint_Comparison_Exp>;
  status_integration?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
  status_integration_id?: InputMaybe<Bigint_Comparison_Exp>;
  type_integration?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
  type_integration_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "place_type_integrations" */
export enum Partner_Place_Type_Integrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaceTypeIntegrationsPkey = 'place_type_integrations_pkey'
}

/** input type for incrementing numeric columns in table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  status_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  type_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place?: InputMaybe<Partner_Places_Obj_Rel_Insert_Input>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  status_integration?: InputMaybe<Partner_Status_Integrations_Obj_Rel_Insert_Input>;
  status_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  type_integration?: InputMaybe<Partner_Type_Integrations_Obj_Rel_Insert_Input>;
  type_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Partner_Place_Type_Integrations_Max_Fields = {
  __typename?: 'partner_place_type_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  status_integration_id?: Maybe<Scalars['bigint']['output']>;
  type_integration_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Partner_Place_Type_Integrations_Min_Fields = {
  __typename?: 'partner_place_type_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  status_integration_id?: Maybe<Scalars['bigint']['output']>;
  type_integration_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Mutation_Response = {
  __typename?: 'partner_place_type_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Place_Type_Integrations>;
};

/** on_conflict condition type for table "place_type_integrations" */
export type Partner_Place_Type_Integrations_On_Conflict = {
  constraint: Partner_Place_Type_Integrations_Constraint;
  update_columns?: Array<Partner_Place_Type_Integrations_Update_Column>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "place_type_integrations". */
export type Partner_Place_Type_Integrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place?: InputMaybe<Partner_Places_Order_By>;
  place_id?: InputMaybe<Order_By>;
  status_integration?: InputMaybe<Partner_Status_Integrations_Order_By>;
  status_integration_id?: InputMaybe<Order_By>;
  type_integration?: InputMaybe<Partner_Type_Integrations_Order_By>;
  type_integration_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: place_type_integrations */
export type Partner_Place_Type_Integrations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "place_type_integrations" */
export enum Partner_Place_Type_Integrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  StatusIntegrationId = 'status_integration_id',
  /** column name */
  TypeIntegrationId = 'type_integration_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "place_type_integrations" */
export type Partner_Place_Type_Integrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  status_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  type_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Place_Type_Integrations_Stddev_Fields = {
  __typename?: 'partner_place_type_integrations_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  status_integration_id?: Maybe<Scalars['Float']['output']>;
  type_integration_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Place_Type_Integrations_Stddev_Pop_Fields = {
  __typename?: 'partner_place_type_integrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  status_integration_id?: Maybe<Scalars['Float']['output']>;
  type_integration_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Place_Type_Integrations_Stddev_Samp_Fields = {
  __typename?: 'partner_place_type_integrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  status_integration_id?: Maybe<Scalars['Float']['output']>;
  type_integration_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_place_type_integrations" */
export type Partner_Place_Type_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Place_Type_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Place_Type_Integrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place_id?: InputMaybe<Scalars['bigint']['input']>;
  status_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  type_integration_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Partner_Place_Type_Integrations_Sum_Fields = {
  __typename?: 'partner_place_type_integrations_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  place_id?: Maybe<Scalars['bigint']['output']>;
  status_integration_id?: Maybe<Scalars['bigint']['output']>;
  type_integration_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "place_type_integrations" */
export enum Partner_Place_Type_Integrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  StatusIntegrationId = 'status_integration_id',
  /** column name */
  TypeIntegrationId = 'type_integration_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Place_Type_Integrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Place_Type_Integrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Place_Type_Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Place_Type_Integrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Place_Type_Integrations_Var_Pop_Fields = {
  __typename?: 'partner_place_type_integrations_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  status_integration_id?: Maybe<Scalars['Float']['output']>;
  type_integration_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Place_Type_Integrations_Var_Samp_Fields = {
  __typename?: 'partner_place_type_integrations_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  status_integration_id?: Maybe<Scalars['Float']['output']>;
  type_integration_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Place_Type_Integrations_Variance_Fields = {
  __typename?: 'partner_place_type_integrations_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  status_integration_id?: Maybe<Scalars['Float']['output']>;
  type_integration_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "places" */
export type Partner_Places = {
  __typename?: 'partner_places';
  address_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  moderation?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status_right?: Maybe<Scalars['String']['output']>;
  theme_id?: Maybe<Scalars['bigint']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "places" */
export type Partner_Places_Aggregate = {
  __typename?: 'partner_places_aggregate';
  aggregate?: Maybe<Partner_Places_Aggregate_Fields>;
  nodes: Array<Partner_Places>;
};

/** aggregate fields of "places" */
export type Partner_Places_Aggregate_Fields = {
  __typename?: 'partner_places_aggregate_fields';
  avg?: Maybe<Partner_Places_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Places_Max_Fields>;
  min?: Maybe<Partner_Places_Min_Fields>;
  stddev?: Maybe<Partner_Places_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Places_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Places_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Places_Sum_Fields>;
  var_pop?: Maybe<Partner_Places_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Places_Var_Samp_Fields>;
  variance?: Maybe<Partner_Places_Variance_Fields>;
};


/** aggregate fields of "places" */
export type Partner_Places_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Places_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Places_Avg_Fields = {
  __typename?: 'partner_places_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  theme_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "places". All fields are combined with a logical 'AND'. */
export type Partner_Places_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Places_Bool_Exp>>;
  _not?: InputMaybe<Partner_Places_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Places_Bool_Exp>>;
  address_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  moderation?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status_right?: InputMaybe<String_Comparison_Exp>;
  theme_id?: InputMaybe<Bigint_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "places" */
export enum Partner_Places_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlacesPkey = 'places_pkey'
}

/** input type for incrementing numeric columns in table "places" */
export type Partner_Places_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  theme_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "places" */
export type Partner_Places_Insert_Input = {
  address_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  moderation?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status_right?: InputMaybe<Scalars['String']['input']>;
  theme_id?: InputMaybe<Scalars['bigint']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Partner_Places_Max_Fields = {
  __typename?: 'partner_places_max_fields';
  address_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status_right?: Maybe<Scalars['String']['output']>;
  theme_id?: Maybe<Scalars['bigint']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Partner_Places_Min_Fields = {
  __typename?: 'partner_places_min_fields';
  address_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status_right?: Maybe<Scalars['String']['output']>;
  theme_id?: Maybe<Scalars['bigint']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "places" */
export type Partner_Places_Mutation_Response = {
  __typename?: 'partner_places_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Places>;
};

/** input type for inserting object relation for remote table "places" */
export type Partner_Places_Obj_Rel_Insert_Input = {
  data: Partner_Places_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Places_On_Conflict>;
};

/** on_conflict condition type for table "places" */
export type Partner_Places_On_Conflict = {
  constraint: Partner_Places_Constraint;
  update_columns?: Array<Partner_Places_Update_Column>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};

/** Ordering options when selecting data from "places". */
export type Partner_Places_Order_By = {
  address_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  moderation?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status_right?: InputMaybe<Order_By>;
  theme_id?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: places */
export type Partner_Places_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "places" */
export enum Partner_Places_Select_Column {
  /** column name */
  AddressUrl = 'address_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Moderation = 'moderation',
  /** column name */
  Name = 'name',
  /** column name */
  StatusRight = 'status_right',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "places" */
export type Partner_Places_Set_Input = {
  address_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  moderation?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status_right?: InputMaybe<Scalars['String']['input']>;
  theme_id?: InputMaybe<Scalars['bigint']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Places_Stddev_Fields = {
  __typename?: 'partner_places_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  theme_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Places_Stddev_Pop_Fields = {
  __typename?: 'partner_places_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  theme_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Places_Stddev_Samp_Fields = {
  __typename?: 'partner_places_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  theme_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_places" */
export type Partner_Places_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Places_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Places_Stream_Cursor_Value_Input = {
  address_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  moderation?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status_right?: InputMaybe<Scalars['String']['input']>;
  theme_id?: InputMaybe<Scalars['bigint']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Partner_Places_Sum_Fields = {
  __typename?: 'partner_places_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  theme_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "places" */
export enum Partner_Places_Update_Column {
  /** column name */
  AddressUrl = 'address_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Moderation = 'moderation',
  /** column name */
  Name = 'name',
  /** column name */
  StatusRight = 'status_right',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Places_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Places_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Places_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Places_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Places_Var_Pop_Fields = {
  __typename?: 'partner_places_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  theme_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Places_Var_Samp_Fields = {
  __typename?: 'partner_places_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  theme_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Places_Variance_Fields = {
  __typename?: 'partner_places_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  theme_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "sale_types" */
export type Partner_Sale_Types = {
  __typename?: 'partner_sale_types';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  label?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "sale_types" */
export type Partner_Sale_Types_Aggregate = {
  __typename?: 'partner_sale_types_aggregate';
  aggregate?: Maybe<Partner_Sale_Types_Aggregate_Fields>;
  nodes: Array<Partner_Sale_Types>;
};

/** aggregate fields of "sale_types" */
export type Partner_Sale_Types_Aggregate_Fields = {
  __typename?: 'partner_sale_types_aggregate_fields';
  avg?: Maybe<Partner_Sale_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Sale_Types_Max_Fields>;
  min?: Maybe<Partner_Sale_Types_Min_Fields>;
  stddev?: Maybe<Partner_Sale_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Sale_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Sale_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Sale_Types_Sum_Fields>;
  var_pop?: Maybe<Partner_Sale_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Sale_Types_Var_Samp_Fields>;
  variance?: Maybe<Partner_Sale_Types_Variance_Fields>;
};


/** aggregate fields of "sale_types" */
export type Partner_Sale_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Sale_Types_Avg_Fields = {
  __typename?: 'partner_sale_types_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sale_types". All fields are combined with a logical 'AND'. */
export type Partner_Sale_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Sale_Types_Bool_Exp>>;
  _not?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Sale_Types_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sale_types" */
export enum Partner_Sale_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  SaleTypesPkey = 'sale_types_pkey'
}

/** input type for incrementing numeric columns in table "sale_types" */
export type Partner_Sale_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "sale_types" */
export type Partner_Sale_Types_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Partner_Sale_Types_Max_Fields = {
  __typename?: 'partner_sale_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Partner_Sale_Types_Min_Fields = {
  __typename?: 'partner_sale_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "sale_types" */
export type Partner_Sale_Types_Mutation_Response = {
  __typename?: 'partner_sale_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Sale_Types>;
};

/** on_conflict condition type for table "sale_types" */
export type Partner_Sale_Types_On_Conflict = {
  constraint: Partner_Sale_Types_Constraint;
  update_columns?: Array<Partner_Sale_Types_Update_Column>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "sale_types". */
export type Partner_Sale_Types_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sale_types */
export type Partner_Sale_Types_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "sale_types" */
export enum Partner_Sale_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "sale_types" */
export type Partner_Sale_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Sale_Types_Stddev_Fields = {
  __typename?: 'partner_sale_types_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Sale_Types_Stddev_Pop_Fields = {
  __typename?: 'partner_sale_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Sale_Types_Stddev_Samp_Fields = {
  __typename?: 'partner_sale_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_sale_types" */
export type Partner_Sale_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Sale_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Sale_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Partner_Sale_Types_Sum_Fields = {
  __typename?: 'partner_sale_types_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "sale_types" */
export enum Partner_Sale_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Partner_Sale_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Sale_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Sale_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Sale_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Sale_Types_Var_Pop_Fields = {
  __typename?: 'partner_sale_types_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Sale_Types_Var_Samp_Fields = {
  __typename?: 'partner_sale_types_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Sale_Types_Variance_Fields = {
  __typename?: 'partner_sale_types_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "status_integrations" */
export type Partner_Status_Integrations = {
  __typename?: 'partner_status_integrations';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  label?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "status_integrations" */
export type Partner_Status_Integrations_Aggregate = {
  __typename?: 'partner_status_integrations_aggregate';
  aggregate?: Maybe<Partner_Status_Integrations_Aggregate_Fields>;
  nodes: Array<Partner_Status_Integrations>;
};

/** aggregate fields of "status_integrations" */
export type Partner_Status_Integrations_Aggregate_Fields = {
  __typename?: 'partner_status_integrations_aggregate_fields';
  avg?: Maybe<Partner_Status_Integrations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Status_Integrations_Max_Fields>;
  min?: Maybe<Partner_Status_Integrations_Min_Fields>;
  stddev?: Maybe<Partner_Status_Integrations_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Status_Integrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Status_Integrations_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Status_Integrations_Sum_Fields>;
  var_pop?: Maybe<Partner_Status_Integrations_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Status_Integrations_Var_Samp_Fields>;
  variance?: Maybe<Partner_Status_Integrations_Variance_Fields>;
};


/** aggregate fields of "status_integrations" */
export type Partner_Status_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Status_Integrations_Avg_Fields = {
  __typename?: 'partner_status_integrations_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "status_integrations". All fields are combined with a logical 'AND'. */
export type Partner_Status_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Status_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Status_Integrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "status_integrations" */
export enum Partner_Status_Integrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatusIntegrationsPkey = 'status_integrations_pkey'
}

/** input type for incrementing numeric columns in table "status_integrations" */
export type Partner_Status_Integrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "status_integrations" */
export type Partner_Status_Integrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Partner_Status_Integrations_Max_Fields = {
  __typename?: 'partner_status_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Partner_Status_Integrations_Min_Fields = {
  __typename?: 'partner_status_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "status_integrations" */
export type Partner_Status_Integrations_Mutation_Response = {
  __typename?: 'partner_status_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Status_Integrations>;
};

/** input type for inserting object relation for remote table "status_integrations" */
export type Partner_Status_Integrations_Obj_Rel_Insert_Input = {
  data: Partner_Status_Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Status_Integrations_On_Conflict>;
};

/** on_conflict condition type for table "status_integrations" */
export type Partner_Status_Integrations_On_Conflict = {
  constraint: Partner_Status_Integrations_Constraint;
  update_columns?: Array<Partner_Status_Integrations_Update_Column>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "status_integrations". */
export type Partner_Status_Integrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: status_integrations */
export type Partner_Status_Integrations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "status_integrations" */
export enum Partner_Status_Integrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "status_integrations" */
export type Partner_Status_Integrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Status_Integrations_Stddev_Fields = {
  __typename?: 'partner_status_integrations_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Status_Integrations_Stddev_Pop_Fields = {
  __typename?: 'partner_status_integrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Status_Integrations_Stddev_Samp_Fields = {
  __typename?: 'partner_status_integrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_status_integrations" */
export type Partner_Status_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Status_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Status_Integrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Partner_Status_Integrations_Sum_Fields = {
  __typename?: 'partner_status_integrations_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "status_integrations" */
export enum Partner_Status_Integrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Partner_Status_Integrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Status_Integrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Status_Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Status_Integrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Status_Integrations_Var_Pop_Fields = {
  __typename?: 'partner_status_integrations_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Status_Integrations_Var_Samp_Fields = {
  __typename?: 'partner_status_integrations_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Status_Integrations_Variance_Fields = {
  __typename?: 'partner_status_integrations_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "status_transactions" */
export type Partner_Status_Transactions = {
  __typename?: 'partner_status_transactions';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "status_transactions" */
export type Partner_Status_Transactions_Aggregate = {
  __typename?: 'partner_status_transactions_aggregate';
  aggregate?: Maybe<Partner_Status_Transactions_Aggregate_Fields>;
  nodes: Array<Partner_Status_Transactions>;
};

/** aggregate fields of "status_transactions" */
export type Partner_Status_Transactions_Aggregate_Fields = {
  __typename?: 'partner_status_transactions_aggregate_fields';
  avg?: Maybe<Partner_Status_Transactions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Status_Transactions_Max_Fields>;
  min?: Maybe<Partner_Status_Transactions_Min_Fields>;
  stddev?: Maybe<Partner_Status_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Status_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Status_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Status_Transactions_Sum_Fields>;
  var_pop?: Maybe<Partner_Status_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Status_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Partner_Status_Transactions_Variance_Fields>;
};


/** aggregate fields of "status_transactions" */
export type Partner_Status_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Status_Transactions_Avg_Fields = {
  __typename?: 'partner_status_transactions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "status_transactions". All fields are combined with a logical 'AND'. */
export type Partner_Status_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Status_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Status_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "status_transactions" */
export enum Partner_Status_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatusTransactionsPkey = 'status_transactions_pkey'
}

/** input type for incrementing numeric columns in table "status_transactions" */
export type Partner_Status_Transactions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "status_transactions" */
export type Partner_Status_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Partner_Status_Transactions_Max_Fields = {
  __typename?: 'partner_status_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Partner_Status_Transactions_Min_Fields = {
  __typename?: 'partner_status_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "status_transactions" */
export type Partner_Status_Transactions_Mutation_Response = {
  __typename?: 'partner_status_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Status_Transactions>;
};

/** on_conflict condition type for table "status_transactions" */
export type Partner_Status_Transactions_On_Conflict = {
  constraint: Partner_Status_Transactions_Constraint;
  update_columns?: Array<Partner_Status_Transactions_Update_Column>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "status_transactions". */
export type Partner_Status_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: status_transactions */
export type Partner_Status_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "status_transactions" */
export enum Partner_Status_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "status_transactions" */
export type Partner_Status_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Status_Transactions_Stddev_Fields = {
  __typename?: 'partner_status_transactions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Status_Transactions_Stddev_Pop_Fields = {
  __typename?: 'partner_status_transactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Status_Transactions_Stddev_Samp_Fields = {
  __typename?: 'partner_status_transactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_status_transactions" */
export type Partner_Status_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Status_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Status_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Partner_Status_Transactions_Sum_Fields = {
  __typename?: 'partner_status_transactions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "status_transactions" */
export enum Partner_Status_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_Status_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Status_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Status_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Status_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Status_Transactions_Var_Pop_Fields = {
  __typename?: 'partner_status_transactions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Status_Transactions_Var_Samp_Fields = {
  __typename?: 'partner_status_transactions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Status_Transactions_Variance_Fields = {
  __typename?: 'partner_status_transactions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "themes" */
export type Partner_Themes = {
  __typename?: 'partner_themes';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "themes" */
export type Partner_Themes_Aggregate = {
  __typename?: 'partner_themes_aggregate';
  aggregate?: Maybe<Partner_Themes_Aggregate_Fields>;
  nodes: Array<Partner_Themes>;
};

/** aggregate fields of "themes" */
export type Partner_Themes_Aggregate_Fields = {
  __typename?: 'partner_themes_aggregate_fields';
  avg?: Maybe<Partner_Themes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Themes_Max_Fields>;
  min?: Maybe<Partner_Themes_Min_Fields>;
  stddev?: Maybe<Partner_Themes_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Themes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Themes_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Themes_Sum_Fields>;
  var_pop?: Maybe<Partner_Themes_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Themes_Var_Samp_Fields>;
  variance?: Maybe<Partner_Themes_Variance_Fields>;
};


/** aggregate fields of "themes" */
export type Partner_Themes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Themes_Avg_Fields = {
  __typename?: 'partner_themes_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "themes". All fields are combined with a logical 'AND'. */
export type Partner_Themes_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Themes_Bool_Exp>>;
  _not?: InputMaybe<Partner_Themes_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Themes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "themes" */
export enum Partner_Themes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ThemesPkey = 'themes_pkey'
}

/** input type for incrementing numeric columns in table "themes" */
export type Partner_Themes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "themes" */
export type Partner_Themes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Partner_Themes_Max_Fields = {
  __typename?: 'partner_themes_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Partner_Themes_Min_Fields = {
  __typename?: 'partner_themes_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "themes" */
export type Partner_Themes_Mutation_Response = {
  __typename?: 'partner_themes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Themes>;
};

/** on_conflict condition type for table "themes" */
export type Partner_Themes_On_Conflict = {
  constraint: Partner_Themes_Constraint;
  update_columns?: Array<Partner_Themes_Update_Column>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};

/** Ordering options when selecting data from "themes". */
export type Partner_Themes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: themes */
export type Partner_Themes_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "themes" */
export enum Partner_Themes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "themes" */
export type Partner_Themes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Themes_Stddev_Fields = {
  __typename?: 'partner_themes_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Themes_Stddev_Pop_Fields = {
  __typename?: 'partner_themes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Themes_Stddev_Samp_Fields = {
  __typename?: 'partner_themes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_themes" */
export type Partner_Themes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Themes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Themes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Partner_Themes_Sum_Fields = {
  __typename?: 'partner_themes_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "themes" */
export enum Partner_Themes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_Themes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Themes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Themes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Themes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Themes_Var_Pop_Fields = {
  __typename?: 'partner_themes_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Themes_Var_Samp_Fields = {
  __typename?: 'partner_themes_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Themes_Variance_Fields = {
  __typename?: 'partner_themes_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "type_integrations" */
export type Partner_Type_Integrations = {
  __typename?: 'partner_type_integrations';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  label?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "type_integrations" */
export type Partner_Type_Integrations_Aggregate = {
  __typename?: 'partner_type_integrations_aggregate';
  aggregate?: Maybe<Partner_Type_Integrations_Aggregate_Fields>;
  nodes: Array<Partner_Type_Integrations>;
};

/** aggregate fields of "type_integrations" */
export type Partner_Type_Integrations_Aggregate_Fields = {
  __typename?: 'partner_type_integrations_aggregate_fields';
  avg?: Maybe<Partner_Type_Integrations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Type_Integrations_Max_Fields>;
  min?: Maybe<Partner_Type_Integrations_Min_Fields>;
  stddev?: Maybe<Partner_Type_Integrations_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Type_Integrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Type_Integrations_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Type_Integrations_Sum_Fields>;
  var_pop?: Maybe<Partner_Type_Integrations_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Type_Integrations_Var_Samp_Fields>;
  variance?: Maybe<Partner_Type_Integrations_Variance_Fields>;
};


/** aggregate fields of "type_integrations" */
export type Partner_Type_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Type_Integrations_Avg_Fields = {
  __typename?: 'partner_type_integrations_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "type_integrations". All fields are combined with a logical 'AND'. */
export type Partner_Type_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Type_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Type_Integrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "type_integrations" */
export enum Partner_Type_Integrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TypeIntegrationsPkey = 'type_integrations_pkey'
}

/** input type for incrementing numeric columns in table "type_integrations" */
export type Partner_Type_Integrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "type_integrations" */
export type Partner_Type_Integrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Partner_Type_Integrations_Max_Fields = {
  __typename?: 'partner_type_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Partner_Type_Integrations_Min_Fields = {
  __typename?: 'partner_type_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "type_integrations" */
export type Partner_Type_Integrations_Mutation_Response = {
  __typename?: 'partner_type_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Type_Integrations>;
};

/** input type for inserting object relation for remote table "type_integrations" */
export type Partner_Type_Integrations_Obj_Rel_Insert_Input = {
  data: Partner_Type_Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Type_Integrations_On_Conflict>;
};

/** on_conflict condition type for table "type_integrations" */
export type Partner_Type_Integrations_On_Conflict = {
  constraint: Partner_Type_Integrations_Constraint;
  update_columns?: Array<Partner_Type_Integrations_Update_Column>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "type_integrations". */
export type Partner_Type_Integrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: type_integrations */
export type Partner_Type_Integrations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "type_integrations" */
export enum Partner_Type_Integrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "type_integrations" */
export type Partner_Type_Integrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Type_Integrations_Stddev_Fields = {
  __typename?: 'partner_type_integrations_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Type_Integrations_Stddev_Pop_Fields = {
  __typename?: 'partner_type_integrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Type_Integrations_Stddev_Samp_Fields = {
  __typename?: 'partner_type_integrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_type_integrations" */
export type Partner_Type_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Type_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Type_Integrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Partner_Type_Integrations_Sum_Fields = {
  __typename?: 'partner_type_integrations_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "type_integrations" */
export enum Partner_Type_Integrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Partner_Type_Integrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Type_Integrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Type_Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Type_Integrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Type_Integrations_Var_Pop_Fields = {
  __typename?: 'partner_type_integrations_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Type_Integrations_Var_Samp_Fields = {
  __typename?: 'partner_type_integrations_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Type_Integrations_Variance_Fields = {
  __typename?: 'partner_type_integrations_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "type_transactions" */
export type Partner_Type_Transactions = {
  __typename?: 'partner_type_transactions';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "type_transactions" */
export type Partner_Type_Transactions_Aggregate = {
  __typename?: 'partner_type_transactions_aggregate';
  aggregate?: Maybe<Partner_Type_Transactions_Aggregate_Fields>;
  nodes: Array<Partner_Type_Transactions>;
};

/** aggregate fields of "type_transactions" */
export type Partner_Type_Transactions_Aggregate_Fields = {
  __typename?: 'partner_type_transactions_aggregate_fields';
  avg?: Maybe<Partner_Type_Transactions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Type_Transactions_Max_Fields>;
  min?: Maybe<Partner_Type_Transactions_Min_Fields>;
  stddev?: Maybe<Partner_Type_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Type_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Type_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Type_Transactions_Sum_Fields>;
  var_pop?: Maybe<Partner_Type_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Type_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Partner_Type_Transactions_Variance_Fields>;
};


/** aggregate fields of "type_transactions" */
export type Partner_Type_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Type_Transactions_Avg_Fields = {
  __typename?: 'partner_type_transactions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "type_transactions". All fields are combined with a logical 'AND'. */
export type Partner_Type_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Type_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Type_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "type_transactions" */
export enum Partner_Type_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TypeTransactionsPkey = 'type_transactions_pkey'
}

/** input type for incrementing numeric columns in table "type_transactions" */
export type Partner_Type_Transactions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "type_transactions" */
export type Partner_Type_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Partner_Type_Transactions_Max_Fields = {
  __typename?: 'partner_type_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Partner_Type_Transactions_Min_Fields = {
  __typename?: 'partner_type_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "type_transactions" */
export type Partner_Type_Transactions_Mutation_Response = {
  __typename?: 'partner_type_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Type_Transactions>;
};

/** on_conflict condition type for table "type_transactions" */
export type Partner_Type_Transactions_On_Conflict = {
  constraint: Partner_Type_Transactions_Constraint;
  update_columns?: Array<Partner_Type_Transactions_Update_Column>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "type_transactions". */
export type Partner_Type_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: type_transactions */
export type Partner_Type_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "type_transactions" */
export enum Partner_Type_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "type_transactions" */
export type Partner_Type_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Type_Transactions_Stddev_Fields = {
  __typename?: 'partner_type_transactions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Type_Transactions_Stddev_Pop_Fields = {
  __typename?: 'partner_type_transactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Type_Transactions_Stddev_Samp_Fields = {
  __typename?: 'partner_type_transactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_type_transactions" */
export type Partner_Type_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Type_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Type_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Partner_Type_Transactions_Sum_Fields = {
  __typename?: 'partner_type_transactions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "type_transactions" */
export enum Partner_Type_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_Type_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Type_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Type_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Type_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Type_Transactions_Var_Pop_Fields = {
  __typename?: 'partner_type_transactions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Type_Transactions_Var_Samp_Fields = {
  __typename?: 'partner_type_transactions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Type_Transactions_Variance_Fields = {
  __typename?: 'partner_type_transactions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "user_types" */
export type Partner_User_Types = {
  __typename?: 'partner_user_types';
  created_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  type_title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "user_types" */
export type Partner_User_Types_Aggregate = {
  __typename?: 'partner_user_types_aggregate';
  aggregate?: Maybe<Partner_User_Types_Aggregate_Fields>;
  nodes: Array<Partner_User_Types>;
};

/** aggregate fields of "user_types" */
export type Partner_User_Types_Aggregate_Fields = {
  __typename?: 'partner_user_types_aggregate_fields';
  avg?: Maybe<Partner_User_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_User_Types_Max_Fields>;
  min?: Maybe<Partner_User_Types_Min_Fields>;
  stddev?: Maybe<Partner_User_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_User_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_User_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_User_Types_Sum_Fields>;
  var_pop?: Maybe<Partner_User_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_User_Types_Var_Samp_Fields>;
  variance?: Maybe<Partner_User_Types_Variance_Fields>;
};


/** aggregate fields of "user_types" */
export type Partner_User_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_User_Types_Avg_Fields = {
  __typename?: 'partner_user_types_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_types". All fields are combined with a logical 'AND'. */
export type Partner_User_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_User_Types_Bool_Exp>>;
  _not?: InputMaybe<Partner_User_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_User_Types_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type_title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_types" */
export enum Partner_User_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserTypesPkey = 'user_types_pkey'
}

/** input type for incrementing numeric columns in table "user_types" */
export type Partner_User_Types_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "user_types" */
export type Partner_User_Types_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Partner_User_Types_Max_Fields = {
  __typename?: 'partner_user_types_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  type_title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Partner_User_Types_Min_Fields = {
  __typename?: 'partner_user_types_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  type_title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "user_types" */
export type Partner_User_Types_Mutation_Response = {
  __typename?: 'partner_user_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_User_Types>;
};

/** on_conflict condition type for table "user_types" */
export type Partner_User_Types_On_Conflict = {
  constraint: Partner_User_Types_Constraint;
  update_columns?: Array<Partner_User_Types_Update_Column>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "user_types". */
export type Partner_User_Types_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type_title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_types */
export type Partner_User_Types_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "user_types" */
export enum Partner_User_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TypeTitle = 'type_title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_types" */
export type Partner_User_Types_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Partner_User_Types_Stddev_Fields = {
  __typename?: 'partner_user_types_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_User_Types_Stddev_Pop_Fields = {
  __typename?: 'partner_user_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_User_Types_Stddev_Samp_Fields = {
  __typename?: 'partner_user_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_user_types" */
export type Partner_User_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_User_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_User_Types_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type_title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Partner_User_Types_Sum_Fields = {
  __typename?: 'partner_user_types_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "user_types" */
export enum Partner_User_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TypeTitle = 'type_title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Partner_User_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_User_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_User_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_User_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_User_Types_Var_Pop_Fields = {
  __typename?: 'partner_user_types_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_User_Types_Var_Samp_Fields = {
  __typename?: 'partner_user_types_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_User_Types_Variance_Fields = {
  __typename?: 'partner_user_types_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users" */
export type Partner_Users = {
  __typename?: 'partner_users';
  allow_password_change?: Maybe<Scalars['Boolean']['output']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_inn?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_name?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_number_account?: Maybe<Scalars['String']['output']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  date_send_code?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password: Scalars['String']['output'];
  /** Имя */
  first_name?: Maybe<Scalars['String']['output']>;
  full_name: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']['output']>;
  /** Кем выдан */
  passport_author?: Maybe<Scalars['String']['output']>;
  /** Дата выдачи */
  passport_issue_date?: Maybe<Scalars['date']['output']>;
  /** Код подразделения */
  passport_registration?: Maybe<Scalars['String']['output']>;
  /** Код подразделения */
  passport_unit_code?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  user_type_id?: Maybe<Scalars['bigint']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "users" */
export type Partner_Users_Aggregate = {
  __typename?: 'partner_users_aggregate';
  aggregate?: Maybe<Partner_Users_Aggregate_Fields>;
  nodes: Array<Partner_Users>;
};

/** aggregate fields of "users" */
export type Partner_Users_Aggregate_Fields = {
  __typename?: 'partner_users_aggregate_fields';
  avg?: Maybe<Partner_Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Partner_Users_Max_Fields>;
  min?: Maybe<Partner_Users_Min_Fields>;
  stddev?: Maybe<Partner_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Users_Sum_Fields>;
  var_pop?: Maybe<Partner_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Users_Var_Samp_Fields>;
  variance?: Maybe<Partner_Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Partner_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Partner_Users_Avg_Fields = {
  __typename?: 'partner_users_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Partner_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Users_Bool_Exp>>;
  _not?: InputMaybe<Partner_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Users_Bool_Exp>>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  bank_correspondent_account?: InputMaybe<String_Comparison_Exp>;
  bank_inn?: InputMaybe<String_Comparison_Exp>;
  bank_name?: InputMaybe<String_Comparison_Exp>;
  bank_number_account?: InputMaybe<String_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date_send_code?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  passport?: InputMaybe<String_Comparison_Exp>;
  passport_author?: InputMaybe<String_Comparison_Exp>;
  passport_issue_date?: InputMaybe<Date_Comparison_Exp>;
  passport_registration?: InputMaybe<String_Comparison_Exp>;
  passport_unit_code?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  place_birth?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  second_name?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Partner_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Partner_Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  user_type_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users" */
export type Partner_Users_Insert_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_inn?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_name?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_number_account?: InputMaybe<Scalars['String']['input']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_send_code?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан */
  passport_author?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи */
  passport_issue_date?: InputMaybe<Scalars['date']['input']>;
  /** Код подразделения */
  passport_registration?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения */
  passport_unit_code?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_type_id?: InputMaybe<Scalars['bigint']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Partner_Users_Max_Fields = {
  __typename?: 'partner_users_max_fields';
  /** Корреспондентский счёт */
  bank_correspondent_account?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_inn?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_name?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_number_account?: Maybe<Scalars['String']['output']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_send_code?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']['output']>;
  /** Кем выдан */
  passport_author?: Maybe<Scalars['String']['output']>;
  /** Дата выдачи */
  passport_issue_date?: Maybe<Scalars['date']['output']>;
  /** Код подразделения */
  passport_registration?: Maybe<Scalars['String']['output']>;
  /** Код подразделения */
  passport_unit_code?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_type_id?: Maybe<Scalars['bigint']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Partner_Users_Min_Fields = {
  __typename?: 'partner_users_min_fields';
  /** Корреспондентский счёт */
  bank_correspondent_account?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_inn?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_name?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bank_number_account?: Maybe<Scalars['String']['output']>;
  /** Дата рождения */
  birthday?: Maybe<Scalars['date']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date_send_code?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  /** Имя */
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  /** Фамилия */
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  /** Паспорт */
  passport?: Maybe<Scalars['String']['output']>;
  /** Кем выдан */
  passport_author?: Maybe<Scalars['String']['output']>;
  /** Дата выдачи */
  passport_issue_date?: Maybe<Scalars['date']['output']>;
  /** Код подразделения */
  passport_registration?: Maybe<Scalars['String']['output']>;
  /** Код подразделения */
  passport_unit_code?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  /** Место рождения */
  place_birth?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** Отчество */
  second_name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_type_id?: Maybe<Scalars['bigint']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users" */
export type Partner_Users_Mutation_Response = {
  __typename?: 'partner_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Users>;
};

/** on_conflict condition type for table "users" */
export type Partner_Users_On_Conflict = {
  constraint: Partner_Users_Constraint;
  update_columns?: Array<Partner_Users_Update_Column>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Partner_Users_Order_By = {
  allow_password_change?: InputMaybe<Order_By>;
  bank_correspondent_account?: InputMaybe<Order_By>;
  bank_inn?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bank_number_account?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_send_code?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  passport?: InputMaybe<Order_By>;
  passport_author?: InputMaybe<Order_By>;
  passport_issue_date?: InputMaybe<Order_By>;
  passport_registration?: InputMaybe<Order_By>;
  passport_unit_code?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  place_birth?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  second_name?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_type_id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Partner_Users_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users" */
export enum Partner_Users_Select_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  BankCorrespondentAccount = 'bank_correspondent_account',
  /** column name */
  BankInn = 'bank_inn',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BankNumberAccount = 'bank_number_account',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PassportAuthor = 'passport_author',
  /** column name */
  PassportIssueDate = 'passport_issue_date',
  /** column name */
  PassportRegistration = 'passport_registration',
  /** column name */
  PassportUnitCode = 'passport_unit_code',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserTypeId = 'user_type_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Partner_Users_Set_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_inn?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_name?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_number_account?: InputMaybe<Scalars['String']['input']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_send_code?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан */
  passport_author?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи */
  passport_issue_date?: InputMaybe<Scalars['date']['input']>;
  /** Код подразделения */
  passport_registration?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения */
  passport_unit_code?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_type_id?: InputMaybe<Scalars['bigint']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Partner_Users_Stddev_Fields = {
  __typename?: 'partner_users_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Users_Stddev_Pop_Fields = {
  __typename?: 'partner_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Users_Stddev_Samp_Fields = {
  __typename?: 'partner_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "partner_users" */
export type Partner_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Users_Stream_Cursor_Value_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  /** Корреспондентский счёт */
  bank_correspondent_account?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_inn?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_name?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bank_number_account?: InputMaybe<Scalars['String']['input']>;
  /** Дата рождения */
  birthday?: InputMaybe<Scalars['date']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date_send_code?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  first_name?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фамилия */
  last_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  /** Паспорт */
  passport?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан */
  passport_author?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи */
  passport_issue_date?: InputMaybe<Scalars['date']['input']>;
  /** Код подразделения */
  passport_registration?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения */
  passport_unit_code?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  /** Место рождения */
  place_birth?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  second_name?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_type_id?: InputMaybe<Scalars['bigint']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Partner_Users_Sum_Fields = {
  __typename?: 'partner_users_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  user_type_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users" */
export enum Partner_Users_Update_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  BankCorrespondentAccount = 'bank_correspondent_account',
  /** column name */
  BankInn = 'bank_inn',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BankNumberAccount = 'bank_number_account',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSendCode = 'date_send_code',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  Passport = 'passport',
  /** column name */
  PassportAuthor = 'passport_author',
  /** column name */
  PassportIssueDate = 'passport_issue_date',
  /** column name */
  PassportRegistration = 'passport_registration',
  /** column name */
  PassportUnitCode = 'passport_unit_code',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlaceBirth = 'place_birth',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Uid = 'uid',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserTypeId = 'user_type_id',
  /** column name */
  Username = 'username'
}

export type Partner_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Users_Var_Pop_Fields = {
  __typename?: 'partner_users_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Partner_Users_Var_Samp_Fields = {
  __typename?: 'partner_users_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Partner_Users_Variance_Fields = {
  __typename?: 'partner_users_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_type_id?: Maybe<Scalars['Float']['output']>;
};

export type PersonalInfoViewActionInput = {
  profile_user_id: Scalars['Int']['input'];
};

export type PersonalInfoViewActionOutput = {
  __typename?: 'personalInfoViewActionOutput';
  date_of_birth?: Maybe<Scalars['date']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type ProfileOutput = {
  __typename?: 'profileOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['Int']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

export type Profiles = {
  __typename?: 'profiles';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ProfilesOutput = {
  __typename?: 'profilesOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  locked_at?: Maybe<Scalars['timestamptz']['output']>;
  pro?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  vc_active?: Maybe<Scalars['Int']['output']>;
  vc_client_id?: Maybe<Scalars['Int']['output']>;
  vc_state?: Maybe<Scalars['Int']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  authCheckUsername?: Maybe<AuthCheckUsernameOutput>;
  authCheckUsernameUniqueness?: Maybe<AuthCheckUsernameUniquenessOutput>;
  authFindUser?: Maybe<AuthFindUserOutput>;
  authVerifyTokenBeforeResettingPassword?: Maybe<AuthVerifyTokenBeforeResettingPasswordOutput>;
  /** fetch data from the table: "oauth_access_tokens" */
  auth_oauth_access_tokens: Array<Auth_Oauth_Access_Tokens>;
  /** fetch aggregated fields from the table: "oauth_access_tokens" */
  auth_oauth_access_tokens_aggregate: Auth_Oauth_Access_Tokens_Aggregate;
  /** fetch data from the table: "oauth_access_tokens" using primary key columns */
  auth_oauth_access_tokens_by_pk?: Maybe<Auth_Oauth_Access_Tokens>;
  /** fetch data from the table: "test_users" */
  auth_test_users: Array<Auth_Test_Users>;
  /** fetch aggregated fields from the table: "test_users" */
  auth_test_users_aggregate: Auth_Test_Users_Aggregate;
  /** fetch data from the table: "test_users" using primary key columns */
  auth_test_users_by_pk?: Maybe<Auth_Test_Users>;
  /** fetch data from the table: "users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  /** fetch data from the table: "verification_emails" */
  auth_verification_emails: Array<Auth_Verification_Emails>;
  /** fetch aggregated fields from the table: "verification_emails" */
  auth_verification_emails_aggregate: Auth_Verification_Emails_Aggregate;
  /** fetch data from the table: "verification_emails" using primary key columns */
  auth_verification_emails_by_pk?: Maybe<Auth_Verification_Emails>;
  /** fetch data from the table: "verification_phones" */
  auth_verification_phones: Array<Auth_Verification_Phones>;
  /** fetch aggregated fields from the table: "verification_phones" */
  auth_verification_phones_aggregate: Auth_Verification_Phones_Aggregate;
  /** fetch data from the table: "verification_phones" using primary key columns */
  auth_verification_phones_by_pk?: Maybe<Auth_Verification_Phones>;
  billingCheckPromoCode?: Maybe<BillingCheckPromoCodeOutput>;
  billingGetPaytureOrder?: Maybe<BillingGetPaytureOrderOutput>;
  /** fetch data from the table: "order_polices" */
  billing_order_polices: Array<Billing_Order_Polices>;
  /** fetch aggregated fields from the table: "order_polices" */
  billing_order_polices_aggregate: Billing_Order_Polices_Aggregate;
  /** fetch data from the table: "order_polices" using primary key columns */
  billing_order_polices_by_pk?: Maybe<Billing_Order_Polices>;
  /** fetch data from the table: "billing_order_statuses" */
  billing_order_statuses: Array<Billing_Order_Statuses>;
  /** fetch aggregated fields from the table: "billing_order_statuses" */
  billing_order_statuses_aggregate: Billing_Order_Statuses_Aggregate;
  /** fetch data from the table: "billing_order_statuses" using primary key columns */
  billing_order_statuses_by_pk?: Maybe<Billing_Order_Statuses>;
  /** fetch data from the table: "promo_codes" */
  billing_promo_codes: Array<Billing_Promo_Codes>;
  /** fetch aggregated fields from the table: "promo_codes" */
  billing_promo_codes_aggregate: Billing_Promo_Codes_Aggregate;
  /** fetch data from the table: "promo_codes" using primary key columns */
  billing_promo_codes_by_pk?: Maybe<Billing_Promo_Codes>;
  dmsApiDenumCheckSmsCode?: Maybe<DmsApiDenumCheckSmsCodeOutput>;
  dmsApiDenumGetContract?: Maybe<DmsApiDenumGetContractOutput>;
  dmsApiDenumGetSigningStatus?: Maybe<DmsApiDenumGetSigningStatusOutput>;
  dmsApiDenumResendSmsCode?: Maybe<DmsApiDenumResendSmsCodeOutput>;
  dmsCheckPromoCode?: Maybe<DmsCheckPromoCodeOutput>;
  dmsGetInfoClinicForMap?: Maybe<GetInfoClinicForMapOutput>;
  dmsGetOrderDiscount?: Maybe<DmsGetOrderDiscountOutput>;
  dmsGetPolicyCard?: Maybe<DmsGetPolicyCardOutput>;
  dmsOrderLinksByUid?: Maybe<DmsOrderLinksByUidOutput>;
  /** fetch data from the table: "cities" */
  dms_cities: Array<Dms_Cities>;
  /** fetch aggregated fields from the table: "cities" */
  dms_cities_aggregate: Dms_Cities_Aggregate;
  /** fetch data from the table: "cities" using primary key columns */
  dms_cities_by_pk?: Maybe<Dms_Cities>;
  /** fetch data from the table: "group_insureds" */
  dms_group_insureds: Array<Dms_Group_Insureds>;
  /** fetch aggregated fields from the table: "group_insureds" */
  dms_group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  /** fetch data from the table: "group_insureds" using primary key columns */
  dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** fetch data from the table: "groups" */
  dms_groups: Array<Dms_Groups>;
  /** fetch aggregated fields from the table: "groups" */
  dms_groups_aggregate: Dms_Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** fetch data from the table: "insurants" */
  dms_insurants: Array<Dms_Insurants>;
  /** fetch aggregated fields from the table: "insurants" */
  dms_insurants_aggregate: Dms_Insurants_Aggregate;
  /** fetch data from the table: "insurants" using primary key columns */
  dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** fetch data from the table: "insureds" */
  dms_insureds: Array<Dms_Insureds>;
  /** fetch aggregated fields from the table: "insureds" */
  dms_insureds_aggregate: Dms_Insureds_Aggregate;
  /** fetch data from the table: "insureds" using primary key columns */
  dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** fetch data from the table: "order_links" */
  dms_order_links: Array<Dms_Order_Links>;
  /** fetch aggregated fields from the table: "order_links" */
  dms_order_links_aggregate: Dms_Order_Links_Aggregate;
  /** fetch data from the table: "order_links" using primary key columns */
  dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** fetch data from the table: "order_numbers" */
  dms_order_numbers: Array<Dms_Order_Numbers>;
  /** fetch aggregated fields from the table: "order_numbers" */
  dms_order_numbers_aggregate: Dms_Order_Numbers_Aggregate;
  /** fetch data from the table: "order_numbers" using primary key columns */
  dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** fetch data from the table: "orders" */
  dms_orders: Array<Dms_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  dms_orders_aggregate: Dms_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** fetch data from the table: "products" */
  dms_products: Array<Dms_Products>;
  /** fetch aggregated fields from the table: "products" */
  dms_products_aggregate: Dms_Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  dms_products_by_pk?: Maybe<Dms_Products>;
  /** fetch data from the table: "promo_codes" */
  dms_promo_codes: Array<Dms_Promo_Codes>;
  /** fetch aggregated fields from the table: "promo_codes" */
  dms_promo_codes_aggregate: Dms_Promo_Codes_Aggregate;
  /** fetch data from the table: "promo_codes" using primary key columns */
  dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** fetch data from the table: "statuses" */
  dms_statuses: Array<Dms_Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  dms_statuses_aggregate: Dms_Statuses_Aggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** fetch data from the table: "users" */
  dms_users: Array<Dms_Users>;
  /** fetch aggregated fields from the table: "users" */
  dms_users_aggregate: Dms_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  dms_users_by_pk?: Maybe<Dms_Users>;
  fintechCardInfo?: Maybe<FintechCardInfoOutput>;
  fintechGetClientInfo?: Maybe<FintechClientInfoOutput>;
  fintechGetClientOrders?: Maybe<FintechGetOrdersOutput>;
  /** fetch data from the table: "cards" */
  fintech_cards: Array<Fintech_Cards>;
  /** fetch aggregated fields from the table: "cards" */
  fintech_cards_aggregate: Fintech_Cards_Aggregate;
  /** fetch data from the table: "cards" using primary key columns */
  fintech_cards_by_pk?: Maybe<Fintech_Cards>;
  /** fetch data from the table: "clients" */
  fintech_clients: Array<Fintech_Clients>;
  /** fetch aggregated fields from the table: "clients" */
  fintech_clients_aggregate: Fintech_Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  fintech_clients_by_pk?: Maybe<Fintech_Clients>;
  /** fetch data from the table: "orders" */
  fintech_orders: Array<Fintech_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  fintech_orders_aggregate: Fintech_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  fintech_orders_by_pk?: Maybe<Fintech_Orders>;
  me?: Maybe<MeOutput>;
  medconciergeSendGreetingToProfile?: Maybe<MedconciergeSendGreetingToProfileOutput>;
  mobileBotGreetingMessage?: Maybe<MobileBotGreetingMessageOutput>;
  mobileCheckUsersOnline?: Maybe<MobileCheckUsersOnlineOutput>;
  mobileConciergePurchaseMailerSendClient?: Maybe<MobileConciergePurchaseMailerSendClientOutput>;
  mobileFindByChats?: Maybe<MobileFindByChatsOutput>;
  mobileFindByMyChats?: Maybe<MobileFindByMyChatsOutput>;
  mobileGetChatById?: Maybe<MobileGetChatByIdOutput>;
  mobileGetChatIdFindByProfileUserId?: Maybe<MobileGetChatIdFindByProfileUserIdOutput>;
  mobileGetChats?: Maybe<MobileGetChatsOutput>;
  mobileGetChatsV2?: Maybe<MobileGetChatsV2Output>;
  mobileGetContacts?: Maybe<MobileGetContactsOutput>;
  mobileGetCountNotReadMessage?: Maybe<MobileGetCountNotReadMessageOutput>;
  mobileGetNotifications?: Maybe<MobileGetNotificationsOutput>;
  mobileGetPostMediaFile?: Maybe<MobileGetPostMediaFileOutput>;
  mobileGetProfileAvatar?: Maybe<MobileGetProfileAvatarOutput>;
  mobileGetProfileMediafiles?: Maybe<MobileGetProfileMediafilesOutput>;
  mobileGetServiceTariffs?: Maybe<MobileGetServiceTariffsOutput>;
  mobileGetStatusMedicalConciergeBilling?: Maybe<MobileGetStatusMedicalConciergeBillingOutput>;
  mobileGetStatusServiceBilling?: Maybe<MobileGetStatusServiceBillingOutput>;
  mobilePersonalInfoViewAction?: Maybe<PersonalInfoViewActionOutput>;
  mobilePostFindByTitleMessage?: Maybe<MobilePostFindByTitleMessageOutput>;
  mobilePostsAction?: Maybe<MobilePostsActionOutput>;
  mobileProfileFindByFio?: Maybe<MobileProfileFindByFioOutput>;
  mobileProfileFindByUsernFirstnLastnAboutGeneralSubscriber?: Maybe<MobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberOutput>;
  mobileProfileFindByUsernameFirstnameLastnameAboutLike?: Maybe<MobileProfileFindByUsernameFirstnameLastnameAboutLikeOutput>;
  mobileProfileFindByUsernameFirstnameLastnameAboutSubscriber?: Maybe<MobileProfileFindByUsernameFirstnameLastnameAboutSubscriberOutput>;
  mobileProfileFindByUsernameFirstnameLastnameAboutSubscription?: Maybe<MobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionOutput>;
  mobileProfileGeneralSubscriber?: Maybe<MobileProfileGeneralSubscriberOutput>;
  mobileSearchByMessagesInFiles?: Maybe<MobileSearchByMessagesInFilesOutput>;
  mobileSearchProfilesFindFirstLastUsernameAboutmyself?: Maybe<MobileSearchProfilesFindFirstLastUsernameAboutmyselfOutput>;
  mobileSendInvite?: Maybe<MobileSendInviteOutput>;
  /** fetch data from the table: "amocrm_contacts" */
  mobile_amocrm_contacts: Array<Mobile_Amocrm_Contacts>;
  /** fetch aggregated fields from the table: "amocrm_contacts" */
  mobile_amocrm_contacts_aggregate: Mobile_Amocrm_Contacts_Aggregate;
  /** fetch data from the table: "amocrm_contacts" using primary key columns */
  mobile_amocrm_contacts_by_pk?: Maybe<Mobile_Amocrm_Contacts>;
  /** fetch data from the table: "amocrm_leads" */
  mobile_amocrm_leads: Array<Mobile_Amocrm_Leads>;
  /** fetch aggregated fields from the table: "amocrm_leads" */
  mobile_amocrm_leads_aggregate: Mobile_Amocrm_Leads_Aggregate;
  /** fetch data from the table: "amocrm_leads" using primary key columns */
  mobile_amocrm_leads_by_pk?: Maybe<Mobile_Amocrm_Leads>;
  /** fetch data from the table: "amocrm_tags" */
  mobile_amocrm_tags: Array<Mobile_Amocrm_Tags>;
  /** fetch aggregated fields from the table: "amocrm_tags" */
  mobile_amocrm_tags_aggregate: Mobile_Amocrm_Tags_Aggregate;
  /** fetch data from the table: "amocrm_tags" using primary key columns */
  mobile_amocrm_tags_by_pk?: Maybe<Mobile_Amocrm_Tags>;
  /** fetch data from the table: "appointments" */
  mobile_appointments: Array<Mobile_Appointments>;
  /** fetch aggregated fields from the table: "appointments" */
  mobile_appointments_aggregate: Mobile_Appointments_Aggregate;
  /** fetch data from the table: "appointments" using primary key columns */
  mobile_appointments_by_pk?: Maybe<Mobile_Appointments>;
  /** fetch data from the table: "auto_replies" */
  mobile_auto_replies: Array<Mobile_Auto_Replies>;
  /** fetch aggregated fields from the table: "auto_replies" */
  mobile_auto_replies_aggregate: Mobile_Auto_Replies_Aggregate;
  /** fetch data from the table: "auto_replies" using primary key columns */
  mobile_auto_replies_by_pk?: Maybe<Mobile_Auto_Replies>;
  /** fetch data from the table: "auto_reply_documents" */
  mobile_auto_reply_documents: Array<Mobile_Auto_Reply_Documents>;
  /** fetch aggregated fields from the table: "auto_reply_documents" */
  mobile_auto_reply_documents_aggregate: Mobile_Auto_Reply_Documents_Aggregate;
  /** fetch data from the table: "auto_reply_documents" using primary key columns */
  mobile_auto_reply_documents_by_pk?: Maybe<Mobile_Auto_Reply_Documents>;
  /** fetch data from the table: "auto_reply_mediafiles" */
  mobile_auto_reply_mediafiles: Array<Mobile_Auto_Reply_Mediafiles>;
  /** fetch aggregated fields from the table: "auto_reply_mediafiles" */
  mobile_auto_reply_mediafiles_aggregate: Mobile_Auto_Reply_Mediafiles_Aggregate;
  /** fetch data from the table: "auto_reply_mediafiles" using primary key columns */
  mobile_auto_reply_mediafiles_by_pk?: Maybe<Mobile_Auto_Reply_Mediafiles>;
  /** fetch data from the table: "bank_accounts" */
  mobile_bank_accounts: Array<Mobile_Bank_Accounts>;
  /** fetch aggregated fields from the table: "bank_accounts" */
  mobile_bank_accounts_aggregate: Mobile_Bank_Accounts_Aggregate;
  /** fetch data from the table: "bank_accounts" using primary key columns */
  mobile_bank_accounts_by_pk?: Maybe<Mobile_Bank_Accounts>;
  /** fetch data from the table: "blocked_sites" */
  mobile_blocked_sites: Array<Mobile_Blocked_Sites>;
  /** fetch aggregated fields from the table: "blocked_sites" */
  mobile_blocked_sites_aggregate: Mobile_Blocked_Sites_Aggregate;
  /** fetch data from the table: "blocked_sites" using primary key columns */
  mobile_blocked_sites_by_pk?: Maybe<Mobile_Blocked_Sites>;
  /** fetch data from the table: "blockeds" */
  mobile_blockeds: Array<Mobile_Blockeds>;
  /** fetch aggregated fields from the table: "blockeds" */
  mobile_blockeds_aggregate: Mobile_Blockeds_Aggregate;
  /** fetch data from the table: "blockeds" using primary key columns */
  mobile_blockeds_by_pk?: Maybe<Mobile_Blockeds>;
  /** fetch data from the table: "calorie_norms" */
  mobile_calorie_norms: Array<Mobile_Calorie_Norms>;
  /** fetch aggregated fields from the table: "calorie_norms" */
  mobile_calorie_norms_aggregate: Mobile_Calorie_Norms_Aggregate;
  /** fetch data from the table: "calorie_norms" using primary key columns */
  mobile_calorie_norms_by_pk?: Maybe<Mobile_Calorie_Norms>;
  /** fetch data from the table: "calorie_nows" */
  mobile_calorie_nows: Array<Mobile_Calorie_Nows>;
  /** fetch aggregated fields from the table: "calorie_nows" */
  mobile_calorie_nows_aggregate: Mobile_Calorie_Nows_Aggregate;
  /** fetch data from the table: "calorie_nows" using primary key columns */
  mobile_calorie_nows_by_pk?: Maybe<Mobile_Calorie_Nows>;
  /** fetch data from the table: "calories" */
  mobile_calories: Array<Mobile_Calories>;
  /** fetch aggregated fields from the table: "calories" */
  mobile_calories_aggregate: Mobile_Calories_Aggregate;
  /** fetch data from the table: "calories" using primary key columns */
  mobile_calories_by_pk?: Maybe<Mobile_Calories>;
  /** fetch data from the table: "categories" */
  mobile_categories: Array<Mobile_Categories>;
  /** fetch aggregated fields from the table: "categories" */
  mobile_categories_aggregate: Mobile_Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  mobile_categories_by_pk?: Maybe<Mobile_Categories>;
  /** fetch data from the table: "category_names" */
  mobile_category_names: Array<Mobile_Category_Names>;
  /** fetch aggregated fields from the table: "category_names" */
  mobile_category_names_aggregate: Mobile_Category_Names_Aggregate;
  /** fetch data from the table: "category_names" using primary key columns */
  mobile_category_names_by_pk?: Maybe<Mobile_Category_Names>;
  /** fetch data from the table: "chat_black_lists" */
  mobile_chat_black_lists: Array<Mobile_Chat_Black_Lists>;
  /** fetch aggregated fields from the table: "chat_black_lists" */
  mobile_chat_black_lists_aggregate: Mobile_Chat_Black_Lists_Aggregate;
  /** fetch data from the table: "chat_black_lists" using primary key columns */
  mobile_chat_black_lists_by_pk?: Maybe<Mobile_Chat_Black_Lists>;
  /** fetch data from the table: "chat_defaults" */
  mobile_chat_defaults: Array<Mobile_Chat_Defaults>;
  /** fetch aggregated fields from the table: "chat_defaults" */
  mobile_chat_defaults_aggregate: Mobile_Chat_Defaults_Aggregate;
  /** fetch data from the table: "chat_defaults" using primary key columns */
  mobile_chat_defaults_by_pk?: Maybe<Mobile_Chat_Defaults>;
  /** fetch data from the table: "chat_file_types" */
  mobile_chat_file_types: Array<Mobile_Chat_File_Types>;
  /** fetch aggregated fields from the table: "chat_file_types" */
  mobile_chat_file_types_aggregate: Mobile_Chat_File_Types_Aggregate;
  /** fetch data from the table: "chat_file_types" using primary key columns */
  mobile_chat_file_types_by_pk?: Maybe<Mobile_Chat_File_Types>;
  /** fetch data from the table: "chat_message_comments" */
  mobile_chat_message_comments: Array<Mobile_Chat_Message_Comments>;
  /** fetch aggregated fields from the table: "chat_message_comments" */
  mobile_chat_message_comments_aggregate: Mobile_Chat_Message_Comments_Aggregate;
  /** fetch data from the table: "chat_message_comments" using primary key columns */
  mobile_chat_message_comments_by_pk?: Maybe<Mobile_Chat_Message_Comments>;
  /** fetch data from the table: "chat_message_files" */
  mobile_chat_message_files: Array<Mobile_Chat_Message_Files>;
  /** fetch aggregated fields from the table: "chat_message_files" */
  mobile_chat_message_files_aggregate: Mobile_Chat_Message_Files_Aggregate;
  /** fetch data from the table: "chat_message_files" using primary key columns */
  mobile_chat_message_files_by_pk?: Maybe<Mobile_Chat_Message_Files>;
  /** fetch data from the table: "chat_message_notifications" */
  mobile_chat_message_notifications: Array<Mobile_Chat_Message_Notifications>;
  /** fetch aggregated fields from the table: "chat_message_notifications" */
  mobile_chat_message_notifications_aggregate: Mobile_Chat_Message_Notifications_Aggregate;
  /** fetch data from the table: "chat_message_types" */
  mobile_chat_message_types: Array<Mobile_Chat_Message_Types>;
  /** fetch aggregated fields from the table: "chat_message_types" */
  mobile_chat_message_types_aggregate: Mobile_Chat_Message_Types_Aggregate;
  /** fetch data from the table: "chat_message_types" using primary key columns */
  mobile_chat_message_types_by_pk?: Maybe<Mobile_Chat_Message_Types>;
  /** fetch data from the table: "chat_messages" */
  mobile_chat_messages: Array<Mobile_Chat_Messages>;
  /** fetch aggregated fields from the table: "chat_messages" */
  mobile_chat_messages_aggregate: Mobile_Chat_Messages_Aggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  mobile_chat_messages_by_pk?: Maybe<Mobile_Chat_Messages>;
  /** fetch data from the table: "chat_roles" */
  mobile_chat_roles: Array<Mobile_Chat_Roles>;
  /** fetch aggregated fields from the table: "chat_roles" */
  mobile_chat_roles_aggregate: Mobile_Chat_Roles_Aggregate;
  /** fetch data from the table: "chat_roles" using primary key columns */
  mobile_chat_roles_by_pk?: Maybe<Mobile_Chat_Roles>;
  /** fetch data from the table: "chat_types" */
  mobile_chat_types: Array<Mobile_Chat_Types>;
  /** fetch aggregated fields from the table: "chat_types" */
  mobile_chat_types_aggregate: Mobile_Chat_Types_Aggregate;
  /** fetch data from the table: "chat_types" using primary key columns */
  mobile_chat_types_by_pk?: Maybe<Mobile_Chat_Types>;
  /** fetch data from the table: "chats" */
  mobile_chats: Array<Mobile_Chats>;
  /** fetch aggregated fields from the table: "chats" */
  mobile_chats_aggregate: Mobile_Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  mobile_chats_by_pk?: Maybe<Mobile_Chats>;
  /** fetch data from the table: "clients" */
  mobile_clients: Array<Mobile_Clients>;
  /** fetch aggregated fields from the table: "clients" */
  mobile_clients_aggregate: Mobile_Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  mobile_clients_by_pk?: Maybe<Mobile_Clients>;
  /** fetch data from the table: "comment_like_notifications" */
  mobile_comment_like_notifications: Array<Mobile_Comment_Like_Notifications>;
  /** fetch aggregated fields from the table: "comment_like_notifications" */
  mobile_comment_like_notifications_aggregate: Mobile_Comment_Like_Notifications_Aggregate;
  /** fetch data from the table: "comment_likes" */
  mobile_comment_likes: Array<Mobile_Comment_Likes>;
  /** fetch aggregated fields from the table: "comment_likes" */
  mobile_comment_likes_aggregate: Mobile_Comment_Likes_Aggregate;
  /** fetch data from the table: "comment_likes" using primary key columns */
  mobile_comment_likes_by_pk?: Maybe<Mobile_Comment_Likes>;
  /** fetch data from the table: "comment_notifications" */
  mobile_comment_notifications: Array<Mobile_Comment_Notifications>;
  /** fetch aggregated fields from the table: "comment_notifications" */
  mobile_comment_notifications_aggregate: Mobile_Comment_Notifications_Aggregate;
  /** fetch data from the table: "comments" */
  mobile_comments: Array<Mobile_Comments>;
  /** fetch aggregated fields from the table: "comments" */
  mobile_comments_aggregate: Mobile_Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  mobile_comments_by_pk?: Maybe<Mobile_Comments>;
  /** fetch data from the table: "confirmation_codes" */
  mobile_confirmation_codes: Array<Mobile_Confirmation_Codes>;
  /** fetch aggregated fields from the table: "confirmation_codes" */
  mobile_confirmation_codes_aggregate: Mobile_Confirmation_Codes_Aggregate;
  /** fetch data from the table: "confirmation_codes" using primary key columns */
  mobile_confirmation_codes_by_pk?: Maybe<Mobile_Confirmation_Codes>;
  /** fetch data from the table: "contacts" */
  mobile_contacts: Array<Mobile_Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  mobile_contacts_aggregate: Mobile_Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  mobile_contacts_by_pk?: Maybe<Mobile_Contacts>;
  /** fetch data from the table: "contents" */
  mobile_contents: Array<Mobile_Contents>;
  /** fetch aggregated fields from the table: "contents" */
  mobile_contents_aggregate: Mobile_Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  mobile_contents_by_pk?: Maybe<Mobile_Contents>;
  /** fetch data from the table: "device_infos" */
  mobile_device_infos: Array<Mobile_Device_Infos>;
  /** fetch aggregated fields from the table: "device_infos" */
  mobile_device_infos_aggregate: Mobile_Device_Infos_Aggregate;
  /** fetch data from the table: "device_infos" using primary key columns */
  mobile_device_infos_by_pk?: Maybe<Mobile_Device_Infos>;
  /** fetch data from the table: "device_settings" */
  mobile_device_settings: Array<Mobile_Device_Settings>;
  /** fetch aggregated fields from the table: "device_settings" */
  mobile_device_settings_aggregate: Mobile_Device_Settings_Aggregate;
  /** fetch data from the table: "device_settings" using primary key columns */
  mobile_device_settings_by_pk?: Maybe<Mobile_Device_Settings>;
  /** fetch data from the table: "devices" */
  mobile_devices: Array<Mobile_Devices>;
  /** fetch aggregated fields from the table: "devices" */
  mobile_devices_aggregate: Mobile_Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  mobile_devices_by_pk?: Maybe<Mobile_Devices>;
  /** fetch data from the table: "document_mediafiles" */
  mobile_document_mediafiles: Array<Mobile_Document_Mediafiles>;
  /** fetch aggregated fields from the table: "document_mediafiles" */
  mobile_document_mediafiles_aggregate: Mobile_Document_Mediafiles_Aggregate;
  /** fetch data from the table: "document_mediafiles" using primary key columns */
  mobile_document_mediafiles_by_pk?: Maybe<Mobile_Document_Mediafiles>;
  /** fetch data from the table: "documents" */
  mobile_documents: Array<Mobile_Documents>;
  /** fetch aggregated fields from the table: "documents" */
  mobile_documents_aggregate: Mobile_Documents_Aggregate;
  /** fetch data from the table: "documents" using primary key columns */
  mobile_documents_by_pk?: Maybe<Mobile_Documents>;
  /** fetch data from the table: "education_mediafiles" */
  mobile_education_mediafiles: Array<Mobile_Education_Mediafiles>;
  /** fetch aggregated fields from the table: "education_mediafiles" */
  mobile_education_mediafiles_aggregate: Mobile_Education_Mediafiles_Aggregate;
  /** fetch data from the table: "education_mediafiles" using primary key columns */
  mobile_education_mediafiles_by_pk?: Maybe<Mobile_Education_Mediafiles>;
  /** fetch data from the table: "educations" */
  mobile_educations: Array<Mobile_Educations>;
  /** fetch aggregated fields from the table: "educations" */
  mobile_educations_aggregate: Mobile_Educations_Aggregate;
  /** fetch data from the table: "educations" using primary key columns */
  mobile_educations_by_pk?: Maybe<Mobile_Educations>;
  /** fetch data from the table: "emojis" */
  mobile_emojis: Array<Mobile_Emojis>;
  /** fetch aggregated fields from the table: "emojis" */
  mobile_emojis_aggregate: Mobile_Emojis_Aggregate;
  /** fetch data from the table: "emojis" using primary key columns */
  mobile_emojis_by_pk?: Maybe<Mobile_Emojis>;
  /** fetch data from the table: "fixed_chat_messages" */
  mobile_fixed_chat_messages: Array<Mobile_Fixed_Chat_Messages>;
  /** fetch aggregated fields from the table: "fixed_chat_messages" */
  mobile_fixed_chat_messages_aggregate: Mobile_Fixed_Chat_Messages_Aggregate;
  /** fetch data from the table: "fixed_chat_messages" using primary key columns */
  mobile_fixed_chat_messages_by_pk?: Maybe<Mobile_Fixed_Chat_Messages>;
  /** fetch data from the table: "fixed_comments" */
  mobile_fixed_comments: Array<Mobile_Fixed_Comments>;
  /** fetch aggregated fields from the table: "fixed_comments" */
  mobile_fixed_comments_aggregate: Mobile_Fixed_Comments_Aggregate;
  /** fetch data from the table: "fixed_comments" using primary key columns */
  mobile_fixed_comments_by_pk?: Maybe<Mobile_Fixed_Comments>;
  /** fetch data from the table: "founder_mediafiles" */
  mobile_founder_mediafiles: Array<Mobile_Founder_Mediafiles>;
  /** fetch aggregated fields from the table: "founder_mediafiles" */
  mobile_founder_mediafiles_aggregate: Mobile_Founder_Mediafiles_Aggregate;
  /** fetch data from the table: "founder_mediafiles" using primary key columns */
  mobile_founder_mediafiles_by_pk?: Maybe<Mobile_Founder_Mediafiles>;
  /** fetch data from the table: "founders" */
  mobile_founders: Array<Mobile_Founders>;
  /** fetch aggregated fields from the table: "founders" */
  mobile_founders_aggregate: Mobile_Founders_Aggregate;
  /** fetch data from the table: "founders" using primary key columns */
  mobile_founders_by_pk?: Maybe<Mobile_Founders>;
  /** fetch data from the table: "invite_emails" */
  mobile_invite_emails: Array<Mobile_Invite_Emails>;
  /** fetch aggregated fields from the table: "invite_emails" */
  mobile_invite_emails_aggregate: Mobile_Invite_Emails_Aggregate;
  /** fetch data from the table: "invite_emails" using primary key columns */
  mobile_invite_emails_by_pk?: Maybe<Mobile_Invite_Emails>;
  /** fetch data from the table: "like_notifications" */
  mobile_like_notifications: Array<Mobile_Like_Notifications>;
  /** fetch aggregated fields from the table: "like_notifications" */
  mobile_like_notifications_aggregate: Mobile_Like_Notifications_Aggregate;
  /** fetch data from the table: "likes" */
  mobile_likes: Array<Mobile_Likes>;
  /** fetch aggregated fields from the table: "likes" */
  mobile_likes_aggregate: Mobile_Likes_Aggregate;
  /** fetch data from the table: "likes" using primary key columns */
  mobile_likes_by_pk?: Maybe<Mobile_Likes>;
  /** fetch data from the table: "link_snippets" */
  mobile_link_snippets: Array<Mobile_Link_Snippets>;
  /** fetch aggregated fields from the table: "link_snippets" */
  mobile_link_snippets_aggregate: Mobile_Link_Snippets_Aggregate;
  /** fetch data from the table: "link_snippets" using primary key columns */
  mobile_link_snippets_by_pk?: Maybe<Mobile_Link_Snippets>;
  /** fetch data from the table: "locked_deleted_chat_messages" */
  mobile_locked_deleted_chat_messages: Array<Mobile_Locked_Deleted_Chat_Messages>;
  /** fetch aggregated fields from the table: "locked_deleted_chat_messages" */
  mobile_locked_deleted_chat_messages_aggregate: Mobile_Locked_Deleted_Chat_Messages_Aggregate;
  /** fetch data from the table: "mediafile_status_names" */
  mobile_mediafile_status_names: Array<Mobile_Mediafile_Status_Names>;
  /** fetch aggregated fields from the table: "mediafile_status_names" */
  mobile_mediafile_status_names_aggregate: Mobile_Mediafile_Status_Names_Aggregate;
  /** fetch data from the table: "mediafile_status_names" using primary key columns */
  mobile_mediafile_status_names_by_pk?: Maybe<Mobile_Mediafile_Status_Names>;
  /** fetch data from the table: "mediafile_statuses" */
  mobile_mediafile_statuses: Array<Mobile_Mediafile_Statuses>;
  /** fetch aggregated fields from the table: "mediafile_statuses" */
  mobile_mediafile_statuses_aggregate: Mobile_Mediafile_Statuses_Aggregate;
  /** fetch data from the table: "mediafile_statuses" using primary key columns */
  mobile_mediafile_statuses_by_pk?: Maybe<Mobile_Mediafile_Statuses>;
  /** fetch data from the table: "medical_concierge_orders" */
  mobile_medical_concierge_orders: Array<Mobile_Medical_Concierge_Orders>;
  /** fetch aggregated fields from the table: "medical_concierge_orders" */
  mobile_medical_concierge_orders_aggregate: Mobile_Medical_Concierge_Orders_Aggregate;
  /** fetch data from the table: "medical_concierge_orders" using primary key columns */
  mobile_medical_concierge_orders_by_pk?: Maybe<Mobile_Medical_Concierge_Orders>;
  /** fetch data from the table: "medical_concierge_products" */
  mobile_medical_concierge_products: Array<Mobile_Medical_Concierge_Products>;
  /** fetch aggregated fields from the table: "medical_concierge_products" */
  mobile_medical_concierge_products_aggregate: Mobile_Medical_Concierge_Products_Aggregate;
  /** fetch data from the table: "medical_concierge_products" using primary key columns */
  mobile_medical_concierge_products_by_pk?: Maybe<Mobile_Medical_Concierge_Products>;
  /** fetch data from the table: "mention_notifications" */
  mobile_mention_notifications: Array<Mobile_Mention_Notifications>;
  /** fetch aggregated fields from the table: "mention_notifications" */
  mobile_mention_notifications_aggregate: Mobile_Mention_Notifications_Aggregate;
  /** fetch data from the table: "mentions" */
  mobile_mentions: Array<Mobile_Mentions>;
  /** fetch aggregated fields from the table: "mentions" */
  mobile_mentions_aggregate: Mobile_Mentions_Aggregate;
  /** fetch data from the table: "mentions" using primary key columns */
  mobile_mentions_by_pk?: Maybe<Mobile_Mentions>;
  /** fetch data from the table: "metric_names" */
  mobile_metric_names: Array<Mobile_Metric_Names>;
  /** fetch aggregated fields from the table: "metric_names" */
  mobile_metric_names_aggregate: Mobile_Metric_Names_Aggregate;
  /** fetch data from the table: "metric_names" using primary key columns */
  mobile_metric_names_by_pk?: Maybe<Mobile_Metric_Names>;
  /** fetch data from the table: "metric_params" */
  mobile_metric_params: Array<Mobile_Metric_Params>;
  /** fetch aggregated fields from the table: "metric_params" */
  mobile_metric_params_aggregate: Mobile_Metric_Params_Aggregate;
  /** fetch data from the table: "metric_params" using primary key columns */
  mobile_metric_params_by_pk?: Maybe<Mobile_Metric_Params>;
  /** fetch data from the table: "metric_types" */
  mobile_metric_types: Array<Mobile_Metric_Types>;
  /** fetch aggregated fields from the table: "metric_types" */
  mobile_metric_types_aggregate: Mobile_Metric_Types_Aggregate;
  /** fetch data from the table: "metric_types" using primary key columns */
  mobile_metric_types_by_pk?: Maybe<Mobile_Metric_Types>;
  /** fetch data from the table: "metrics" */
  mobile_metrics: Array<Mobile_Metrics>;
  /** fetch aggregated fields from the table: "metrics" */
  mobile_metrics_aggregate: Mobile_Metrics_Aggregate;
  /** fetch data from the table: "metrics" using primary key columns */
  mobile_metrics_by_pk?: Maybe<Mobile_Metrics>;
  /** fetch data from the table: "notification_messages" */
  mobile_notification_messages: Array<Mobile_Notification_Messages>;
  /** fetch aggregated fields from the table: "notification_messages" */
  mobile_notification_messages_aggregate: Mobile_Notification_Messages_Aggregate;
  /** fetch data from the table: "notification_messages" using primary key columns */
  mobile_notification_messages_by_pk?: Maybe<Mobile_Notification_Messages>;
  /** fetch data from the table: "notifications" */
  mobile_notifications: Array<Mobile_Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  mobile_notifications_aggregate: Mobile_Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  mobile_notifications_by_pk?: Maybe<Mobile_Notifications>;
  /** fetch data from the table: "notifications_statuses" */
  mobile_notifications_statuses: Array<Mobile_Notifications_Statuses>;
  /** fetch aggregated fields from the table: "notifications_statuses" */
  mobile_notifications_statuses_aggregate: Mobile_Notifications_Statuses_Aggregate;
  /** fetch data from the table: "notifications_statuses" using primary key columns */
  mobile_notifications_statuses_by_pk?: Maybe<Mobile_Notifications_Statuses>;
  /** fetch data from the table: "order_notifications" */
  mobile_order_notifications: Array<Mobile_Order_Notifications>;
  /** fetch aggregated fields from the table: "order_notifications" */
  mobile_order_notifications_aggregate: Mobile_Order_Notifications_Aggregate;
  /** fetch data from the table: "order_professionals" */
  mobile_order_professionals: Array<Mobile_Order_Professionals>;
  /** fetch aggregated fields from the table: "order_professionals" */
  mobile_order_professionals_aggregate: Mobile_Order_Professionals_Aggregate;
  /** fetch data from the table: "order_professionals" using primary key columns */
  mobile_order_professionals_by_pk?: Maybe<Mobile_Order_Professionals>;
  /** fetch data from the table: "orders" */
  mobile_orders: Array<Mobile_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  mobile_orders_aggregate: Mobile_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  mobile_orders_by_pk?: Maybe<Mobile_Orders>;
  /** fetch data from the table: "orgs" */
  mobile_orgs: Array<Mobile_Orgs>;
  /** fetch aggregated fields from the table: "orgs" */
  mobile_orgs_aggregate: Mobile_Orgs_Aggregate;
  /** fetch data from the table: "orgs" using primary key columns */
  mobile_orgs_by_pk?: Maybe<Mobile_Orgs>;
  /** fetch data from the table: "param_categories" */
  mobile_param_categories: Array<Mobile_Param_Categories>;
  /** fetch aggregated fields from the table: "param_categories" */
  mobile_param_categories_aggregate: Mobile_Param_Categories_Aggregate;
  /** fetch data from the table: "param_categories" using primary key columns */
  mobile_param_categories_by_pk?: Maybe<Mobile_Param_Categories>;
  /** fetch data from the table: "param_categories_params" */
  mobile_param_categories_params: Array<Mobile_Param_Categories_Params>;
  /** fetch aggregated fields from the table: "param_categories_params" */
  mobile_param_categories_params_aggregate: Mobile_Param_Categories_Params_Aggregate;
  /** fetch data from the table: "param_category_names" */
  mobile_param_category_names: Array<Mobile_Param_Category_Names>;
  /** fetch aggregated fields from the table: "param_category_names" */
  mobile_param_category_names_aggregate: Mobile_Param_Category_Names_Aggregate;
  /** fetch data from the table: "param_category_names" using primary key columns */
  mobile_param_category_names_by_pk?: Maybe<Mobile_Param_Category_Names>;
  /** fetch data from the table: "param_names" */
  mobile_param_names: Array<Mobile_Param_Names>;
  /** fetch aggregated fields from the table: "param_names" */
  mobile_param_names_aggregate: Mobile_Param_Names_Aggregate;
  /** fetch data from the table: "param_names" using primary key columns */
  mobile_param_names_by_pk?: Maybe<Mobile_Param_Names>;
  /** fetch data from the table: "params" */
  mobile_params: Array<Mobile_Params>;
  /** fetch aggregated fields from the table: "params" */
  mobile_params_aggregate: Mobile_Params_Aggregate;
  /** fetch data from the table: "params" using primary key columns */
  mobile_params_by_pk?: Maybe<Mobile_Params>;
  /** fetch data from the table: "params_services" */
  mobile_params_services: Array<Mobile_Params_Services>;
  /** fetch aggregated fields from the table: "params_services" */
  mobile_params_services_aggregate: Mobile_Params_Services_Aggregate;
  /** fetch data from the table: "params_services" using primary key columns */
  mobile_params_services_by_pk?: Maybe<Mobile_Params_Services>;
  /** fetch data from the table: "personal_info_views" */
  mobile_personal_info_views: Array<Mobile_Personal_Info_Views>;
  /** fetch aggregated fields from the table: "personal_info_views" */
  mobile_personal_info_views_aggregate: Mobile_Personal_Info_Views_Aggregate;
  /** fetch data from the table: "personal_info_views" using primary key columns */
  mobile_personal_info_views_by_pk?: Maybe<Mobile_Personal_Info_Views>;
  /** fetch data from the table: "personal_infos" */
  mobile_personal_infos: Array<Mobile_Personal_Infos>;
  /** fetch aggregated fields from the table: "personal_infos" */
  mobile_personal_infos_aggregate: Mobile_Personal_Infos_Aggregate;
  /** fetch data from the table: "personal_infos" using primary key columns */
  mobile_personal_infos_by_pk?: Maybe<Mobile_Personal_Infos>;
  /** fetch data from the table: "phones" */
  mobile_phones: Array<Mobile_Phones>;
  /** fetch aggregated fields from the table: "phones" */
  mobile_phones_aggregate: Mobile_Phones_Aggregate;
  /** fetch data from the table: "phones" using primary key columns */
  mobile_phones_by_pk?: Maybe<Mobile_Phones>;
  /** fetch data from the table: "post_params" */
  mobile_post_params: Array<Mobile_Post_Params>;
  /** fetch aggregated fields from the table: "post_params" */
  mobile_post_params_aggregate: Mobile_Post_Params_Aggregate;
  /** fetch data from the table: "post_params" using primary key columns */
  mobile_post_params_by_pk?: Maybe<Mobile_Post_Params>;
  /** fetch data from the table: "posts" */
  mobile_posts: Array<Mobile_Posts>;
  /** fetch aggregated fields from the table: "posts" */
  mobile_posts_aggregate: Mobile_Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  mobile_posts_by_pk?: Maybe<Mobile_Posts>;
  /** fetch data from the table: "posts_services" */
  mobile_posts_services: Array<Mobile_Posts_Services>;
  /** fetch aggregated fields from the table: "posts_services" */
  mobile_posts_services_aggregate: Mobile_Posts_Services_Aggregate;
  /** fetch data from the table: "pro_document_statuses" */
  mobile_pro_document_statuses: Array<Mobile_Pro_Document_Statuses>;
  /** fetch aggregated fields from the table: "pro_document_statuses" */
  mobile_pro_document_statuses_aggregate: Mobile_Pro_Document_Statuses_Aggregate;
  /** fetch data from the table: "pro_document_statuses" using primary key columns */
  mobile_pro_document_statuses_by_pk?: Maybe<Mobile_Pro_Document_Statuses>;
  /** fetch data from the table: "pro_professions" */
  mobile_pro_professions: Array<Mobile_Pro_Professions>;
  /** fetch aggregated fields from the table: "pro_professions" */
  mobile_pro_professions_aggregate: Mobile_Pro_Professions_Aggregate;
  /** fetch data from the table: "pro_professions" using primary key columns */
  mobile_pro_professions_by_pk?: Maybe<Mobile_Pro_Professions>;
  /** fetch data from the table: "pro_types" */
  mobile_pro_types: Array<Mobile_Pro_Types>;
  /** fetch aggregated fields from the table: "pro_types" */
  mobile_pro_types_aggregate: Mobile_Pro_Types_Aggregate;
  /** fetch data from the table: "pro_types" using primary key columns */
  mobile_pro_types_by_pk?: Maybe<Mobile_Pro_Types>;
  /** fetch data from the table: "problems" */
  mobile_problems: Array<Mobile_Problems>;
  /** fetch aggregated fields from the table: "problems" */
  mobile_problems_aggregate: Mobile_Problems_Aggregate;
  /** fetch data from the table: "problems" using primary key columns */
  mobile_problems_by_pk?: Maybe<Mobile_Problems>;
  /** fetch data from the table: "profession_names" */
  mobile_profession_names: Array<Mobile_Profession_Names>;
  /** fetch aggregated fields from the table: "profession_names" */
  mobile_profession_names_aggregate: Mobile_Profession_Names_Aggregate;
  /** fetch data from the table: "profession_names" using primary key columns */
  mobile_profession_names_by_pk?: Maybe<Mobile_Profession_Names>;
  /** fetch data from the table: "professions" */
  mobile_professions: Array<Mobile_Professions>;
  /** fetch aggregated fields from the table: "professions" */
  mobile_professions_aggregate: Mobile_Professions_Aggregate;
  /** fetch data from the table: "professions" using primary key columns */
  mobile_professions_by_pk?: Maybe<Mobile_Professions>;
  /** fetch data from the table: "profile_deleteds" */
  mobile_profile_deleteds: Array<Mobile_Profile_Deleteds>;
  /** fetch aggregated fields from the table: "profile_deleteds" */
  mobile_profile_deleteds_aggregate: Mobile_Profile_Deleteds_Aggregate;
  /** fetch data from the table: "profile_deleteds" using primary key columns */
  mobile_profile_deleteds_by_pk?: Maybe<Mobile_Profile_Deleteds>;
  /** fetch data from the table: "profile_locks" */
  mobile_profile_locks: Array<Mobile_Profile_Locks>;
  /** fetch aggregated fields from the table: "profile_locks" */
  mobile_profile_locks_aggregate: Mobile_Profile_Locks_Aggregate;
  /** fetch data from the table: "profile_locks" using primary key columns */
  mobile_profile_locks_by_pk?: Maybe<Mobile_Profile_Locks>;
  /** fetch data from the table: "profiles" */
  mobile_profiles: Array<Mobile_Profiles>;
  /** fetch aggregated fields from the table: "profiles" */
  mobile_profiles_aggregate: Mobile_Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  mobile_profiles_by_pk?: Maybe<Mobile_Profiles>;
  /** fetch data from the table: "pros" */
  mobile_pros: Array<Mobile_Pros>;
  /** fetch aggregated fields from the table: "pros" */
  mobile_pros_aggregate: Mobile_Pros_Aggregate;
  /** fetch data from the table: "pros" using primary key columns */
  mobile_pros_by_pk?: Maybe<Mobile_Pros>;
  /** fetch data from the table: "pulse_norms" */
  mobile_pulse_norms: Array<Mobile_Pulse_Norms>;
  /** fetch aggregated fields from the table: "pulse_norms" */
  mobile_pulse_norms_aggregate: Mobile_Pulse_Norms_Aggregate;
  /** fetch data from the table: "pulse_norms" using primary key columns */
  mobile_pulse_norms_by_pk?: Maybe<Mobile_Pulse_Norms>;
  /** fetch data from the table: "pulse_nows" */
  mobile_pulse_nows: Array<Mobile_Pulse_Nows>;
  /** fetch aggregated fields from the table: "pulse_nows" */
  mobile_pulse_nows_aggregate: Mobile_Pulse_Nows_Aggregate;
  /** fetch data from the table: "pulse_nows" using primary key columns */
  mobile_pulse_nows_by_pk?: Maybe<Mobile_Pulse_Nows>;
  /** fetch data from the table: "pulses" */
  mobile_pulses: Array<Mobile_Pulses>;
  /** fetch aggregated fields from the table: "pulses" */
  mobile_pulses_aggregate: Mobile_Pulses_Aggregate;
  /** fetch data from the table: "pulses" using primary key columns */
  mobile_pulses_by_pk?: Maybe<Mobile_Pulses>;
  /** fetch data from the table: "reactions" */
  mobile_reactions: Array<Mobile_Reactions>;
  /** fetch aggregated fields from the table: "reactions" */
  mobile_reactions_aggregate: Mobile_Reactions_Aggregate;
  /** fetch data from the table: "reactions" using primary key columns */
  mobile_reactions_by_pk?: Maybe<Mobile_Reactions>;
  /** fetch data from the table: "reports" */
  mobile_reports: Array<Mobile_Reports>;
  /** fetch aggregated fields from the table: "reports" */
  mobile_reports_aggregate: Mobile_Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  mobile_reports_by_pk?: Maybe<Mobile_Reports>;
  /** execute function "mobile_search_posts_find_title_message" which returns "posts" */
  mobile_search_posts_find_title_message: Array<Mobile_Posts>;
  /** execute function "mobile_search_posts_find_title_message" and query aggregates on result of table type "posts" */
  mobile_search_posts_find_title_message_aggregate: Mobile_Posts_Aggregate;
  /** execute function "mobile_search_profiles_find_first_last_username_aboutmyself" which returns "profiles" */
  mobile_search_profiles_find_first_last_username_aboutmyself: Array<Mobile_Profiles>;
  /** execute function "mobile_search_profiles_find_first_last_username_aboutmyself" and query aggregates on result of table type "profiles" */
  mobile_search_profiles_find_first_last_username_aboutmyself_aggregate: Mobile_Profiles_Aggregate;
  /** fetch data from the table: "service_agreements" */
  mobile_service_agreements: Array<Mobile_Service_Agreements>;
  /** fetch aggregated fields from the table: "service_agreements" */
  mobile_service_agreements_aggregate: Mobile_Service_Agreements_Aggregate;
  /** fetch data from the table: "service_agreements" using primary key columns */
  mobile_service_agreements_by_pk?: Maybe<Mobile_Service_Agreements>;
  /** fetch data from the table: "service_answer_reactions" */
  mobile_service_answer_reactions: Array<Mobile_Service_Answer_Reactions>;
  /** fetch aggregated fields from the table: "service_answer_reactions" */
  mobile_service_answer_reactions_aggregate: Mobile_Service_Answer_Reactions_Aggregate;
  /** fetch data from the table: "service_answer_reactions" using primary key columns */
  mobile_service_answer_reactions_by_pk?: Maybe<Mobile_Service_Answer_Reactions>;
  /** fetch data from the table: "service_answers" */
  mobile_service_answers: Array<Mobile_Service_Answers>;
  /** fetch aggregated fields from the table: "service_answers" */
  mobile_service_answers_aggregate: Mobile_Service_Answers_Aggregate;
  /** fetch data from the table: "service_answers" using primary key columns */
  mobile_service_answers_by_pk?: Maybe<Mobile_Service_Answers>;
  /** fetch data from the table: "service_documents" */
  mobile_service_documents: Array<Mobile_Service_Documents>;
  /** fetch aggregated fields from the table: "service_documents" */
  mobile_service_documents_aggregate: Mobile_Service_Documents_Aggregate;
  /** fetch data from the table: "service_documents" using primary key columns */
  mobile_service_documents_by_pk?: Maybe<Mobile_Service_Documents>;
  /** fetch data from the table: "service_favorites" */
  mobile_service_favorites: Array<Mobile_Service_Favorites>;
  /** fetch aggregated fields from the table: "service_favorites" */
  mobile_service_favorites_aggregate: Mobile_Service_Favorites_Aggregate;
  /** fetch data from the table: "service_favorites" using primary key columns */
  mobile_service_favorites_by_pk?: Maybe<Mobile_Service_Favorites>;
  /** fetch data from the table: "service_free_purchases" */
  mobile_service_free_purchases: Array<Mobile_Service_Free_Purchases>;
  /** fetch aggregated fields from the table: "service_free_purchases" */
  mobile_service_free_purchases_aggregate: Mobile_Service_Free_Purchases_Aggregate;
  /** fetch data from the table: "service_free_purchases" using primary key columns */
  mobile_service_free_purchases_by_pk?: Maybe<Mobile_Service_Free_Purchases>;
  /** fetch data from the table: "service_images" */
  mobile_service_images: Array<Mobile_Service_Images>;
  /** fetch aggregated fields from the table: "service_images" */
  mobile_service_images_aggregate: Mobile_Service_Images_Aggregate;
  /** fetch data from the table: "service_images" using primary key columns */
  mobile_service_images_by_pk?: Maybe<Mobile_Service_Images>;
  /** fetch data from the table: "service_likes" */
  mobile_service_likes: Array<Mobile_Service_Likes>;
  /** fetch aggregated fields from the table: "service_likes" */
  mobile_service_likes_aggregate: Mobile_Service_Likes_Aggregate;
  /** fetch data from the table: "service_likes" using primary key columns */
  mobile_service_likes_by_pk?: Maybe<Mobile_Service_Likes>;
  /** fetch data from the table: "service_list_documents" */
  mobile_service_list_documents: Array<Mobile_Service_List_Documents>;
  /** fetch aggregated fields from the table: "service_list_documents" */
  mobile_service_list_documents_aggregate: Mobile_Service_List_Documents_Aggregate;
  /** fetch data from the table: "service_list_documents" using primary key columns */
  mobile_service_list_documents_by_pk?: Maybe<Mobile_Service_List_Documents>;
  /** fetch data from the table: "service_list_periodicities" */
  mobile_service_list_periodicities: Array<Mobile_Service_List_Periodicities>;
  /** fetch aggregated fields from the table: "service_list_periodicities" */
  mobile_service_list_periodicities_aggregate: Mobile_Service_List_Periodicities_Aggregate;
  /** fetch data from the table: "service_list_periodicities" using primary key columns */
  mobile_service_list_periodicities_by_pk?: Maybe<Mobile_Service_List_Periodicities>;
  /** fetch data from the table: "service_lists" */
  mobile_service_lists: Array<Mobile_Service_Lists>;
  /** fetch aggregated fields from the table: "service_lists" */
  mobile_service_lists_aggregate: Mobile_Service_Lists_Aggregate;
  /** fetch data from the table: "service_lists" using primary key columns */
  mobile_service_lists_by_pk?: Maybe<Mobile_Service_Lists>;
  /** fetch data from the table: "service_orders" */
  mobile_service_orders: Array<Mobile_Service_Orders>;
  /** fetch aggregated fields from the table: "service_orders" */
  mobile_service_orders_aggregate: Mobile_Service_Orders_Aggregate;
  /** fetch data from the table: "service_orders" using primary key columns */
  mobile_service_orders_by_pk?: Maybe<Mobile_Service_Orders>;
  /** fetch data from the table: "service_periodicities" */
  mobile_service_periodicities: Array<Mobile_Service_Periodicities>;
  /** fetch aggregated fields from the table: "service_periodicities" */
  mobile_service_periodicities_aggregate: Mobile_Service_Periodicities_Aggregate;
  /** fetch data from the table: "service_periodicities" using primary key columns */
  mobile_service_periodicities_by_pk?: Maybe<Mobile_Service_Periodicities>;
  /** fetch data from the table: "service_purchases" */
  mobile_service_purchases: Array<Mobile_Service_Purchases>;
  /** fetch aggregated fields from the table: "service_purchases" */
  mobile_service_purchases_aggregate: Mobile_Service_Purchases_Aggregate;
  /** fetch data from the table: "service_purchases" using primary key columns */
  mobile_service_purchases_by_pk?: Maybe<Mobile_Service_Purchases>;
  /** fetch data from the table: "service_questions" */
  mobile_service_questions: Array<Mobile_Service_Questions>;
  /** fetch aggregated fields from the table: "service_questions" */
  mobile_service_questions_aggregate: Mobile_Service_Questions_Aggregate;
  /** fetch data from the table: "service_questions" using primary key columns */
  mobile_service_questions_by_pk?: Maybe<Mobile_Service_Questions>;
  /** fetch data from the table: "service_rate_comments" */
  mobile_service_rate_comments: Array<Mobile_Service_Rate_Comments>;
  /** fetch aggregated fields from the table: "service_rate_comments" */
  mobile_service_rate_comments_aggregate: Mobile_Service_Rate_Comments_Aggregate;
  /** fetch data from the table: "service_rate_comments" using primary key columns */
  mobile_service_rate_comments_by_pk?: Maybe<Mobile_Service_Rate_Comments>;
  /** fetch data from the table: "service_rate_images" */
  mobile_service_rate_images: Array<Mobile_Service_Rate_Images>;
  /** fetch aggregated fields from the table: "service_rate_images" */
  mobile_service_rate_images_aggregate: Mobile_Service_Rate_Images_Aggregate;
  /** fetch data from the table: "service_rate_images" using primary key columns */
  mobile_service_rate_images_by_pk?: Maybe<Mobile_Service_Rate_Images>;
  /** fetch data from the table: "service_rate_reactions" */
  mobile_service_rate_reactions: Array<Mobile_Service_Rate_Reactions>;
  /** fetch aggregated fields from the table: "service_rate_reactions" */
  mobile_service_rate_reactions_aggregate: Mobile_Service_Rate_Reactions_Aggregate;
  /** fetch data from the table: "service_rate_reactions" using primary key columns */
  mobile_service_rate_reactions_by_pk?: Maybe<Mobile_Service_Rate_Reactions>;
  /** fetch data from the table: "service_rates" */
  mobile_service_rates: Array<Mobile_Service_Rates>;
  /** fetch aggregated fields from the table: "service_rates" */
  mobile_service_rates_aggregate: Mobile_Service_Rates_Aggregate;
  /** fetch data from the table: "service_rates" using primary key columns */
  mobile_service_rates_by_pk?: Maybe<Mobile_Service_Rates>;
  /** fetch data from the table: "service_statuses" */
  mobile_service_statuses: Array<Mobile_Service_Statuses>;
  /** fetch aggregated fields from the table: "service_statuses" */
  mobile_service_statuses_aggregate: Mobile_Service_Statuses_Aggregate;
  /** fetch data from the table: "service_statuses" using primary key columns */
  mobile_service_statuses_by_pk?: Maybe<Mobile_Service_Statuses>;
  /** fetch data from the table: "service_tag_ages" */
  mobile_service_tag_ages: Array<Mobile_Service_Tag_Ages>;
  /** fetch aggregated fields from the table: "service_tag_ages" */
  mobile_service_tag_ages_aggregate: Mobile_Service_Tag_Ages_Aggregate;
  /** fetch data from the table: "service_tag_ages" using primary key columns */
  mobile_service_tag_ages_by_pk?: Maybe<Mobile_Service_Tag_Ages>;
  /** fetch data from the table: "service_tag_target_groups" */
  mobile_service_tag_target_groups: Array<Mobile_Service_Tag_Target_Groups>;
  /** fetch aggregated fields from the table: "service_tag_target_groups" */
  mobile_service_tag_target_groups_aggregate: Mobile_Service_Tag_Target_Groups_Aggregate;
  /** fetch data from the table: "service_tag_target_groups" using primary key columns */
  mobile_service_tag_target_groups_by_pk?: Maybe<Mobile_Service_Tag_Target_Groups>;
  /** fetch data from the table: "service_tag_targets" */
  mobile_service_tag_targets: Array<Mobile_Service_Tag_Targets>;
  /** fetch aggregated fields from the table: "service_tag_targets" */
  mobile_service_tag_targets_aggregate: Mobile_Service_Tag_Targets_Aggregate;
  /** fetch data from the table: "service_tag_targets" using primary key columns */
  mobile_service_tag_targets_by_pk?: Maybe<Mobile_Service_Tag_Targets>;
  /** fetch data from the table: "service_tariff_documents" */
  mobile_service_tariff_documents: Array<Mobile_Service_Tariff_Documents>;
  /** fetch aggregated fields from the table: "service_tariff_documents" */
  mobile_service_tariff_documents_aggregate: Mobile_Service_Tariff_Documents_Aggregate;
  /** fetch data from the table: "service_tariff_documents" using primary key columns */
  mobile_service_tariff_documents_by_pk?: Maybe<Mobile_Service_Tariff_Documents>;
  /** fetch data from the table: "service_tariffs" */
  mobile_service_tariffs: Array<Mobile_Service_Tariffs>;
  /** fetch aggregated fields from the table: "service_tariffs" */
  mobile_service_tariffs_aggregate: Mobile_Service_Tariffs_Aggregate;
  /** fetch data from the table: "service_tariffs" using primary key columns */
  mobile_service_tariffs_by_pk?: Maybe<Mobile_Service_Tariffs>;
  /** fetch data from the table: "service_type_names" */
  mobile_service_type_names: Array<Mobile_Service_Type_Names>;
  /** fetch aggregated fields from the table: "service_type_names" */
  mobile_service_type_names_aggregate: Mobile_Service_Type_Names_Aggregate;
  /** fetch data from the table: "service_type_names" using primary key columns */
  mobile_service_type_names_by_pk?: Maybe<Mobile_Service_Type_Names>;
  /** fetch data from the table: "service_types" */
  mobile_service_types: Array<Mobile_Service_Types>;
  /** fetch aggregated fields from the table: "service_types" */
  mobile_service_types_aggregate: Mobile_Service_Types_Aggregate;
  /** fetch data from the table: "service_types" using primary key columns */
  mobile_service_types_by_pk?: Maybe<Mobile_Service_Types>;
  /** fetch data from the table: "services" */
  mobile_services: Array<Mobile_Services>;
  /** fetch aggregated fields from the table: "services" */
  mobile_services_aggregate: Mobile_Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  mobile_services_by_pk?: Maybe<Mobile_Services>;
  /** fetch data from the table: "settings" */
  mobile_settings: Array<Mobile_Settings>;
  /** fetch aggregated fields from the table: "settings" */
  mobile_settings_aggregate: Mobile_Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  mobile_settings_by_pk?: Maybe<Mobile_Settings>;
  /** fetch data from the table: "share_posts" */
  mobile_share_posts: Array<Mobile_Share_Posts>;
  /** fetch aggregated fields from the table: "share_posts" */
  mobile_share_posts_aggregate: Mobile_Share_Posts_Aggregate;
  /** fetch data from the table: "share_posts" using primary key columns */
  mobile_share_posts_by_pk?: Maybe<Mobile_Share_Posts>;
  /** fetch data from the table: "sleep_norms" */
  mobile_sleep_norms: Array<Mobile_Sleep_Norms>;
  /** fetch aggregated fields from the table: "sleep_norms" */
  mobile_sleep_norms_aggregate: Mobile_Sleep_Norms_Aggregate;
  /** fetch data from the table: "sleep_norms" using primary key columns */
  mobile_sleep_norms_by_pk?: Maybe<Mobile_Sleep_Norms>;
  /** fetch data from the table: "sleep_nows" */
  mobile_sleep_nows: Array<Mobile_Sleep_Nows>;
  /** fetch aggregated fields from the table: "sleep_nows" */
  mobile_sleep_nows_aggregate: Mobile_Sleep_Nows_Aggregate;
  /** fetch data from the table: "sleep_nows" using primary key columns */
  mobile_sleep_nows_by_pk?: Maybe<Mobile_Sleep_Nows>;
  /** fetch data from the table: "sleeps" */
  mobile_sleeps: Array<Mobile_Sleeps>;
  /** fetch aggregated fields from the table: "sleeps" */
  mobile_sleeps_aggregate: Mobile_Sleeps_Aggregate;
  /** fetch data from the table: "sleeps" using primary key columns */
  mobile_sleeps_by_pk?: Maybe<Mobile_Sleeps>;
  /** fetch data from the table: "slots" */
  mobile_slots: Array<Mobile_Slots>;
  /** fetch aggregated fields from the table: "slots" */
  mobile_slots_aggregate: Mobile_Slots_Aggregate;
  /** fetch data from the table: "slots" using primary key columns */
  mobile_slots_by_pk?: Maybe<Mobile_Slots>;
  /** fetch data from the table: "status_messages" */
  mobile_status_messages: Array<Mobile_Status_Messages>;
  /** fetch aggregated fields from the table: "status_messages" */
  mobile_status_messages_aggregate: Mobile_Status_Messages_Aggregate;
  /** fetch data from the table: "status_messages" using primary key columns */
  mobile_status_messages_by_pk?: Maybe<Mobile_Status_Messages>;
  /** fetch data from the table: "step_norms" */
  mobile_step_norms: Array<Mobile_Step_Norms>;
  /** fetch aggregated fields from the table: "step_norms" */
  mobile_step_norms_aggregate: Mobile_Step_Norms_Aggregate;
  /** fetch data from the table: "step_norms" using primary key columns */
  mobile_step_norms_by_pk?: Maybe<Mobile_Step_Norms>;
  /** fetch data from the table: "step_nows" */
  mobile_step_nows: Array<Mobile_Step_Nows>;
  /** fetch aggregated fields from the table: "step_nows" */
  mobile_step_nows_aggregate: Mobile_Step_Nows_Aggregate;
  /** fetch data from the table: "step_nows" using primary key columns */
  mobile_step_nows_by_pk?: Maybe<Mobile_Step_Nows>;
  /** fetch data from the table: "steps" */
  mobile_steps: Array<Mobile_Steps>;
  /** fetch aggregated fields from the table: "steps" */
  mobile_steps_aggregate: Mobile_Steps_Aggregate;
  /** fetch data from the table: "steps" using primary key columns */
  mobile_steps_by_pk?: Maybe<Mobile_Steps>;
  /** fetch data from the table: "subscribe_notifications" */
  mobile_subscribe_notifications: Array<Mobile_Subscribe_Notifications>;
  /** fetch aggregated fields from the table: "subscribe_notifications" */
  mobile_subscribe_notifications_aggregate: Mobile_Subscribe_Notifications_Aggregate;
  /** fetch data from the table: "subscribes" */
  mobile_subscribes: Array<Mobile_Subscribes>;
  /** fetch aggregated fields from the table: "subscribes" */
  mobile_subscribes_aggregate: Mobile_Subscribes_Aggregate;
  /** fetch data from the table: "subscribes" using primary key columns */
  mobile_subscribes_by_pk?: Maybe<Mobile_Subscribes>;
  /** fetch data from the table: "support_messages" */
  mobile_support_messages: Array<Mobile_Support_Messages>;
  /** fetch aggregated fields from the table: "support_messages" */
  mobile_support_messages_aggregate: Mobile_Support_Messages_Aggregate;
  /** fetch data from the table: "support_messages" using primary key columns */
  mobile_support_messages_by_pk?: Maybe<Mobile_Support_Messages>;
  /** fetch data from the table: "support_topics" */
  mobile_support_topics: Array<Mobile_Support_Topics>;
  /** fetch aggregated fields from the table: "support_topics" */
  mobile_support_topics_aggregate: Mobile_Support_Topics_Aggregate;
  /** fetch data from the table: "support_topics" using primary key columns */
  mobile_support_topics_by_pk?: Maybe<Mobile_Support_Topics>;
  /** fetch data from the table: "tag_ages" */
  mobile_tag_ages: Array<Mobile_Tag_Ages>;
  /** fetch aggregated fields from the table: "tag_ages" */
  mobile_tag_ages_aggregate: Mobile_Tag_Ages_Aggregate;
  /** fetch data from the table: "tag_ages" using primary key columns */
  mobile_tag_ages_by_pk?: Maybe<Mobile_Tag_Ages>;
  /** fetch data from the table: "tag_target_groups" */
  mobile_tag_target_groups: Array<Mobile_Tag_Target_Groups>;
  /** fetch aggregated fields from the table: "tag_target_groups" */
  mobile_tag_target_groups_aggregate: Mobile_Tag_Target_Groups_Aggregate;
  /** fetch data from the table: "tag_target_groups" using primary key columns */
  mobile_tag_target_groups_by_pk?: Maybe<Mobile_Tag_Target_Groups>;
  /** fetch data from the table: "tag_targets" */
  mobile_tag_targets: Array<Mobile_Tag_Targets>;
  /** fetch aggregated fields from the table: "tag_targets" */
  mobile_tag_targets_aggregate: Mobile_Tag_Targets_Aggregate;
  /** fetch data from the table: "tag_targets" using primary key columns */
  mobile_tag_targets_by_pk?: Maybe<Mobile_Tag_Targets>;
  /** fetch data from the table: "type_phones" */
  mobile_type_phones: Array<Mobile_Type_Phones>;
  /** fetch aggregated fields from the table: "type_phones" */
  mobile_type_phones_aggregate: Mobile_Type_Phones_Aggregate;
  /** fetch data from the table: "type_phones" using primary key columns */
  mobile_type_phones_by_pk?: Maybe<Mobile_Type_Phones>;
  /** fetch data from the table: "type_profiles" */
  mobile_type_profiles: Array<Mobile_Type_Profiles>;
  /** fetch aggregated fields from the table: "type_profiles" */
  mobile_type_profiles_aggregate: Mobile_Type_Profiles_Aggregate;
  /** fetch data from the table: "type_profiles" using primary key columns */
  mobile_type_profiles_by_pk?: Maybe<Mobile_Type_Profiles>;
  /** fetch data from the table: "upload_pro_documents" */
  mobile_upload_pro_documents: Array<Mobile_Upload_Pro_Documents>;
  /** fetch aggregated fields from the table: "upload_pro_documents" */
  mobile_upload_pro_documents_aggregate: Mobile_Upload_Pro_Documents_Aggregate;
  /** fetch data from the table: "upload_pro_documents" using primary key columns */
  mobile_upload_pro_documents_by_pk?: Maybe<Mobile_Upload_Pro_Documents>;
  /** fetch data from the table: "user_chat_rights" */
  mobile_user_chat_rights: Array<Mobile_User_Chat_Rights>;
  /** fetch aggregated fields from the table: "user_chat_rights" */
  mobile_user_chat_rights_aggregate: Mobile_User_Chat_Rights_Aggregate;
  /** fetch data from the table: "user_chat_rights" using primary key columns */
  mobile_user_chat_rights_by_pk?: Maybe<Mobile_User_Chat_Rights>;
  /** fetch data from the table: "user_chats" */
  mobile_user_chats: Array<Mobile_User_Chats>;
  /** fetch aggregated fields from the table: "user_chats" */
  mobile_user_chats_aggregate: Mobile_User_Chats_Aggregate;
  /** fetch data from the table: "user_chats" using primary key columns */
  mobile_user_chats_by_pk?: Maybe<Mobile_User_Chats>;
  /** fetch data from the table: "view_chat_messages" */
  mobile_view_chat_messages: Array<Mobile_View_Chat_Messages>;
  /** fetch aggregated fields from the table: "view_chat_messages" */
  mobile_view_chat_messages_aggregate: Mobile_View_Chat_Messages_Aggregate;
  /** fetch data from the table: "water_norms" */
  mobile_water_norms: Array<Mobile_Water_Norms>;
  /** fetch aggregated fields from the table: "water_norms" */
  mobile_water_norms_aggregate: Mobile_Water_Norms_Aggregate;
  /** fetch data from the table: "water_norms" using primary key columns */
  mobile_water_norms_by_pk?: Maybe<Mobile_Water_Norms>;
  /** fetch data from the table: "water_nows" */
  mobile_water_nows: Array<Mobile_Water_Nows>;
  /** fetch aggregated fields from the table: "water_nows" */
  mobile_water_nows_aggregate: Mobile_Water_Nows_Aggregate;
  /** fetch data from the table: "water_nows" using primary key columns */
  mobile_water_nows_by_pk?: Maybe<Mobile_Water_Nows>;
  /** fetch data from the table: "waters" */
  mobile_waters: Array<Mobile_Waters>;
  /** fetch aggregated fields from the table: "waters" */
  mobile_waters_aggregate: Mobile_Waters_Aggregate;
  /** fetch data from the table: "waters" using primary key columns */
  mobile_waters_by_pk?: Maybe<Mobile_Waters>;
  /** fetch data from the table: "weight_nows" */
  mobile_weight_nows: Array<Mobile_Weight_Nows>;
  /** fetch aggregated fields from the table: "weight_nows" */
  mobile_weight_nows_aggregate: Mobile_Weight_Nows_Aggregate;
  /** fetch data from the table: "weight_nows" using primary key columns */
  mobile_weight_nows_by_pk?: Maybe<Mobile_Weight_Nows>;
  /** fetch data from the table: "weights" */
  mobile_weights: Array<Mobile_Weights>;
  /** fetch aggregated fields from the table: "weights" */
  mobile_weights_aggregate: Mobile_Weights_Aggregate;
  /** fetch data from the table: "weights" using primary key columns */
  mobile_weights_by_pk?: Maybe<Mobile_Weights>;
  partnerCurrentBalance?: Maybe<PartnerCurrentBalanceOutput>;
  /** fetch data from the table: "balances" */
  partner_balances: Array<Partner_Balances>;
  /** fetch aggregated fields from the table: "balances" */
  partner_balances_aggregate: Partner_Balances_Aggregate;
  /** fetch data from the table: "balances" using primary key columns */
  partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** fetch data from the table: "log_vzr_sales" */
  partner_log_vzr_sales: Array<Partner_Log_Vzr_Sales>;
  /** fetch aggregated fields from the table: "log_vzr_sales" */
  partner_log_vzr_sales_aggregate: Partner_Log_Vzr_Sales_Aggregate;
  /** fetch data from the table: "log_vzr_sales" using primary key columns */
  partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** fetch data from the table: "log_vzr_searches" */
  partner_log_vzr_searches: Array<Partner_Log_Vzr_Searches>;
  /** fetch aggregated fields from the table: "log_vzr_searches" */
  partner_log_vzr_searches_aggregate: Partner_Log_Vzr_Searches_Aggregate;
  /** fetch data from the table: "log_vzr_searches" using primary key columns */
  partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** fetch data from the table: "log_vzr_views" */
  partner_log_vzr_views: Array<Partner_Log_Vzr_Views>;
  /** fetch aggregated fields from the table: "log_vzr_views" */
  partner_log_vzr_views_aggregate: Partner_Log_Vzr_Views_Aggregate;
  /** fetch data from the table: "log_vzr_views" using primary key columns */
  partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** fetch data from the table: "place_type_integrations" */
  partner_place_type_integrations: Array<Partner_Place_Type_Integrations>;
  /** fetch aggregated fields from the table: "place_type_integrations" */
  partner_place_type_integrations_aggregate: Partner_Place_Type_Integrations_Aggregate;
  /** fetch data from the table: "place_type_integrations" using primary key columns */
  partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** fetch data from the table: "places" */
  partner_places: Array<Partner_Places>;
  /** fetch aggregated fields from the table: "places" */
  partner_places_aggregate: Partner_Places_Aggregate;
  /** fetch data from the table: "places" using primary key columns */
  partner_places_by_pk?: Maybe<Partner_Places>;
  /** fetch data from the table: "sale_types" */
  partner_sale_types: Array<Partner_Sale_Types>;
  /** fetch aggregated fields from the table: "sale_types" */
  partner_sale_types_aggregate: Partner_Sale_Types_Aggregate;
  /** fetch data from the table: "sale_types" using primary key columns */
  partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** fetch data from the table: "status_integrations" */
  partner_status_integrations: Array<Partner_Status_Integrations>;
  /** fetch aggregated fields from the table: "status_integrations" */
  partner_status_integrations_aggregate: Partner_Status_Integrations_Aggregate;
  /** fetch data from the table: "status_integrations" using primary key columns */
  partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** fetch data from the table: "status_transactions" */
  partner_status_transactions: Array<Partner_Status_Transactions>;
  /** fetch aggregated fields from the table: "status_transactions" */
  partner_status_transactions_aggregate: Partner_Status_Transactions_Aggregate;
  /** fetch data from the table: "status_transactions" using primary key columns */
  partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** fetch data from the table: "themes" */
  partner_themes: Array<Partner_Themes>;
  /** fetch aggregated fields from the table: "themes" */
  partner_themes_aggregate: Partner_Themes_Aggregate;
  /** fetch data from the table: "themes" using primary key columns */
  partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** fetch data from the table: "type_integrations" */
  partner_type_integrations: Array<Partner_Type_Integrations>;
  /** fetch aggregated fields from the table: "type_integrations" */
  partner_type_integrations_aggregate: Partner_Type_Integrations_Aggregate;
  /** fetch data from the table: "type_integrations" using primary key columns */
  partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** fetch data from the table: "type_transactions" */
  partner_type_transactions: Array<Partner_Type_Transactions>;
  /** fetch aggregated fields from the table: "type_transactions" */
  partner_type_transactions_aggregate: Partner_Type_Transactions_Aggregate;
  /** fetch data from the table: "type_transactions" using primary key columns */
  partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** fetch data from the table: "user_types" */
  partner_user_types: Array<Partner_User_Types>;
  /** fetch aggregated fields from the table: "user_types" */
  partner_user_types_aggregate: Partner_User_Types_Aggregate;
  /** fetch data from the table: "user_types" using primary key columns */
  partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** fetch data from the table: "users" */
  partner_users: Array<Partner_Users>;
  /** fetch aggregated fields from the table: "users" */
  partner_users_aggregate: Partner_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  partner_users_by_pk?: Maybe<Partner_Users>;
  serviceBotSendTelegramCodeToUser?: Maybe<ServiceBotSendTelegramCodeToUserOutput>;
  /** fetch data from the table: "users" */
  service_users: Array<Service_Users>;
  /** fetch aggregated fields from the table: "users" */
  service_users_aggregate: Service_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  service_users_by_pk?: Maybe<Service_Users>;
};


export type Query_RootAuthCheckUsernameArgs = {
  arg: AuthCheckUsernameInput;
};


export type Query_RootAuthCheckUsernameUniquenessArgs = {
  arg: AuthCheckUsernameUniquenessInput;
};


export type Query_RootAuthFindUserArgs = {
  arg: AuthFindUserInput;
};


export type Query_RootAuthVerifyTokenBeforeResettingPasswordArgs = {
  arg: AuthVerifyTokenBeforeResettingPasswordInput;
};


export type Query_RootAuth_Oauth_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};


export type Query_RootAuth_Oauth_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};


export type Query_RootAuth_Oauth_Access_Tokens_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootAuth_Test_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Test_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Test_Users_Order_By>>;
  where?: InputMaybe<Auth_Test_Users_Bool_Exp>;
};


export type Query_RootAuth_Test_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Test_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Test_Users_Order_By>>;
  where?: InputMaybe<Auth_Test_Users_Bool_Exp>;
};


export type Query_RootAuth_Test_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootAuth_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Query_RootAuth_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootAuth_Verification_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Emails_Order_By>>;
  where?: InputMaybe<Auth_Verification_Emails_Bool_Exp>;
};


export type Query_RootAuth_Verification_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Emails_Order_By>>;
  where?: InputMaybe<Auth_Verification_Emails_Bool_Exp>;
};


export type Query_RootAuth_Verification_Emails_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootAuth_Verification_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Phones_Order_By>>;
  where?: InputMaybe<Auth_Verification_Phones_Bool_Exp>;
};


export type Query_RootAuth_Verification_Phones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Phones_Order_By>>;
  where?: InputMaybe<Auth_Verification_Phones_Bool_Exp>;
};


export type Query_RootAuth_Verification_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootBillingCheckPromoCodeArgs = {
  arg: BillingCheckPromoCodeInput;
};


export type Query_RootBillingGetPaytureOrderArgs = {
  arg: BillingGetPaytureOrderInput;
};


export type Query_RootBilling_Order_PolicesArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Polices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Polices_Order_By>>;
  where?: InputMaybe<Billing_Order_Polices_Bool_Exp>;
};


export type Query_RootBilling_Order_Polices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Polices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Polices_Order_By>>;
  where?: InputMaybe<Billing_Order_Polices_Bool_Exp>;
};


export type Query_RootBilling_Order_Polices_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootBilling_Order_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Statuses_Order_By>>;
  where?: InputMaybe<Billing_Order_Statuses_Bool_Exp>;
};


export type Query_RootBilling_Order_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Statuses_Order_By>>;
  where?: InputMaybe<Billing_Order_Statuses_Bool_Exp>;
};


export type Query_RootBilling_Order_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootBilling_Promo_CodesArgs = {
  distinct_on?: InputMaybe<Array<Billing_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Promo_Codes_Order_By>>;
  where?: InputMaybe<Billing_Promo_Codes_Bool_Exp>;
};


export type Query_RootBilling_Promo_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Promo_Codes_Order_By>>;
  where?: InputMaybe<Billing_Promo_Codes_Bool_Exp>;
};


export type Query_RootBilling_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDmsApiDenumCheckSmsCodeArgs = {
  arg: DmsApiDenumCheckSmsCodeInput;
};


export type Query_RootDmsApiDenumGetContractArgs = {
  arg: DmsApiDenumGetContractInput;
};


export type Query_RootDmsApiDenumGetSigningStatusArgs = {
  arg: DmsApiDenumGetSigningStatusInput;
};


export type Query_RootDmsApiDenumResendSmsCodeArgs = {
  arg: DmsApiDenumResendSmsCodeInput;
};


export type Query_RootDmsCheckPromoCodeArgs = {
  arg: DmsCheckPromoCodeInput;
};


export type Query_RootDmsGetInfoClinicForMapArgs = {
  arg: GetInfoClinicForMapInput;
};


export type Query_RootDmsGetOrderDiscountArgs = {
  arg: DmsGetOrderDiscountInput;
};


export type Query_RootDmsGetPolicyCardArgs = {
  arg: DmsGetPolicyCardInput;
};


export type Query_RootDmsOrderLinksByUidArgs = {
  arg: DmsOrderLinksByUidInput;
};


export type Query_RootDms_CitiesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Cities_Order_By>>;
  where?: InputMaybe<Dms_Cities_Bool_Exp>;
};


export type Query_RootDms_Cities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Cities_Order_By>>;
  where?: InputMaybe<Dms_Cities_Bool_Exp>;
};


export type Query_RootDms_Cities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_Group_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Query_RootDms_Group_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Query_RootDms_Group_Insureds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Query_RootDms_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Query_RootDms_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_InsurantsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Query_RootDms_Insurants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Query_RootDms_Insurants_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Query_RootDms_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Query_RootDms_Insureds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_Order_LinksArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Query_RootDms_Order_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Query_RootDms_Order_Links_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_Order_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Query_RootDms_Order_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Query_RootDms_Order_Numbers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Query_RootDms_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Query_RootDms_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Query_RootDms_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Query_RootDms_Products_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_Promo_CodesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Query_RootDms_Promo_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Query_RootDms_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Query_RootDms_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Query_RootDms_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootDms_UsersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Query_RootDms_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Query_RootDms_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootFintechCardInfoArgs = {
  params?: InputMaybe<FintechCardInfoInput>;
};


export type Query_RootFintechGetClientInfoArgs = {
  params: FintechClientInput;
};


export type Query_RootFintech_CardsArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Cards_Order_By>>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};


export type Query_RootFintech_Cards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Cards_Order_By>>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};


export type Query_RootFintech_Cards_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootFintech_ClientsArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Clients_Order_By>>;
  where?: InputMaybe<Fintech_Clients_Bool_Exp>;
};


export type Query_RootFintech_Clients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Clients_Order_By>>;
  where?: InputMaybe<Fintech_Clients_Bool_Exp>;
};


export type Query_RootFintech_Clients_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootFintech_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Orders_Order_By>>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};


export type Query_RootFintech_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Orders_Order_By>>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};


export type Query_RootFintech_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMedconciergeSendGreetingToProfileArgs = {
  arg: MedconciergeSendGreetingToProfileInput;
};


export type Query_RootMobileBotGreetingMessageArgs = {
  arg: MobileBotGreetingMessageInput;
};


export type Query_RootMobileCheckUsersOnlineArgs = {
  arg: MobileCheckUsersOnlineInput;
};


export type Query_RootMobileConciergePurchaseMailerSendClientArgs = {
  arg: MobileConciergePurchaseMailerSendClientInput;
};


export type Query_RootMobileFindByChatsArgs = {
  arg: MobileFindByChatsInput;
};


export type Query_RootMobileFindByMyChatsArgs = {
  arg: MobileFindByMyChatsInput;
};


export type Query_RootMobileGetChatByIdArgs = {
  arg: MobileGetChatByIdInput;
};


export type Query_RootMobileGetChatIdFindByProfileUserIdArgs = {
  arg: MobileGetChatIdFindByProfileUserIdInput;
};


export type Query_RootMobileGetContactsArgs = {
  arg: MobileGetContactsInput;
};


export type Query_RootMobileGetPostMediaFileArgs = {
  arg: MobileGetPostMediaFileInput;
};


export type Query_RootMobileGetProfileAvatarArgs = {
  arg: MobileGetProfileAvatarInput;
};


export type Query_RootMobileGetServiceTariffsArgs = {
  arg: MobileGetServiceTariffsInput;
};


export type Query_RootMobileGetStatusMedicalConciergeBillingArgs = {
  arg: MobileGetStatusMedicalConciergeBillingInput;
};


export type Query_RootMobileGetStatusServiceBillingArgs = {
  arg: MobileGetStatusServiceBillingInput;
};


export type Query_RootMobilePersonalInfoViewActionArgs = {
  arg: PersonalInfoViewActionInput;
};


export type Query_RootMobilePostFindByTitleMessageArgs = {
  arg: MobilePostFindByTitleMessageInput;
};


export type Query_RootMobilePostsActionArgs = {
  arg: MobilePostsActionInput;
};


export type Query_RootMobileProfileFindByFioArgs = {
  arg: MobileProfileFindByFioInput;
};


export type Query_RootMobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberArgs = {
  arg: MobileProfileFindByUsernFirstnLastnAboutGeneralSubscriberInput;
};


export type Query_RootMobileProfileFindByUsernameFirstnameLastnameAboutLikeArgs = {
  arg: MobileProfileFindByUsernameFirstnameLastnameAboutLikeInput;
};


export type Query_RootMobileProfileFindByUsernameFirstnameLastnameAboutSubscriberArgs = {
  arg: MobileProfileFindByUsernameFirstnameLastnameAboutSubscriberInput;
};


export type Query_RootMobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionArgs = {
  arg: MobileProfileFindByUsernameFirstnameLastnameAboutSubscriptionInput;
};


export type Query_RootMobileProfileGeneralSubscriberArgs = {
  arg: MobileProfileGeneralSubscriberInput;
};


export type Query_RootMobileSearchByMessagesInFilesArgs = {
  arg: MobileSearchByMessagesInFilesInput;
};


export type Query_RootMobileSearchProfilesFindFirstLastUsernameAboutmyselfArgs = {
  arg: MobileSearchProfilesFindFirstLastUsernameAboutmyselfInput;
};


export type Query_RootMobileSendInviteArgs = {
  arg: MobileSendInviteInput;
};


export type Query_RootMobile_Amocrm_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
};


export type Query_RootMobile_Amocrm_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
};


export type Query_RootMobile_Amocrm_Contacts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Amocrm_LeadsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


export type Query_RootMobile_Amocrm_Leads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


export type Query_RootMobile_Amocrm_Leads_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Amocrm_TagsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Tags_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Tags_Bool_Exp>;
};


export type Query_RootMobile_Amocrm_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Tags_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Tags_Bool_Exp>;
};


export type Query_RootMobile_Amocrm_Tags_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_AppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


export type Query_RootMobile_Appointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


export type Query_RootMobile_Appointments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Auto_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


export type Query_RootMobile_Auto_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


export type Query_RootMobile_Auto_Replies_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Auto_Reply_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


export type Query_RootMobile_Auto_Reply_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


export type Query_RootMobile_Auto_Reply_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Auto_Reply_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Auto_Reply_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Auto_Reply_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Bank_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};


export type Query_RootMobile_Bank_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};


export type Query_RootMobile_Bank_Accounts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Blocked_SitesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blocked_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blocked_Sites_Order_By>>;
  where?: InputMaybe<Mobile_Blocked_Sites_Bool_Exp>;
};


export type Query_RootMobile_Blocked_Sites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blocked_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blocked_Sites_Order_By>>;
  where?: InputMaybe<Mobile_Blocked_Sites_Bool_Exp>;
};


export type Query_RootMobile_Blocked_Sites_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_BlockedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blockeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blockeds_Order_By>>;
  where?: InputMaybe<Mobile_Blockeds_Bool_Exp>;
};


export type Query_RootMobile_Blockeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blockeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blockeds_Order_By>>;
  where?: InputMaybe<Mobile_Blockeds_Bool_Exp>;
};


export type Query_RootMobile_Blockeds_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootMobile_Calorie_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Norms_Bool_Exp>;
};


export type Query_RootMobile_Calorie_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Norms_Bool_Exp>;
};


export type Query_RootMobile_Calorie_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Calorie_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Nows_Bool_Exp>;
};


export type Query_RootMobile_Calorie_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Nows_Bool_Exp>;
};


export type Query_RootMobile_Calorie_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_CaloriesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calories_Order_By>>;
  where?: InputMaybe<Mobile_Calories_Bool_Exp>;
};


export type Query_RootMobile_Calories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calories_Order_By>>;
  where?: InputMaybe<Mobile_Calories_Bool_Exp>;
};


export type Query_RootMobile_Calories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Categories_Bool_Exp>;
};


export type Query_RootMobile_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Categories_Bool_Exp>;
};


export type Query_RootMobile_Categories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Category_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};


export type Query_RootMobile_Category_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};


export type Query_RootMobile_Category_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_Black_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Black_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Black_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Black_Lists_Bool_Exp>;
};


export type Query_RootMobile_Chat_Black_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Black_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Black_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Black_Lists_Bool_Exp>;
};


export type Query_RootMobile_Chat_Black_Lists_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_DefaultsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Defaults_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};


export type Query_RootMobile_Chat_Defaults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Defaults_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};


export type Query_RootMobile_Chat_Defaults_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_File_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_File_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
};


export type Query_RootMobile_Chat_File_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_File_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
};


export type Query_RootMobile_Chat_File_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_Message_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_Message_FilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_Files_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_Message_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
};


export type Query_RootMobile_Chat_Message_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_Chat_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_RolesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Roles_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
};


export type Query_RootMobile_Chat_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Roles_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
};


export type Query_RootMobile_Chat_Roles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Chat_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
};


export type Query_RootMobile_Chat_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
};


export type Query_RootMobile_Chat_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chats_Order_By>>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};


export type Query_RootMobile_Chats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chats_Order_By>>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};


export type Query_RootMobile_Chats_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ClientsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Clients_Order_By>>;
  where?: InputMaybe<Mobile_Clients_Bool_Exp>;
};


export type Query_RootMobile_Clients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Clients_Order_By>>;
  where?: InputMaybe<Mobile_Clients_Bool_Exp>;
};


export type Query_RootMobile_Clients_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Comment_Like_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Like_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Comment_Like_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Like_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Comment_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


export type Query_RootMobile_Comment_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


export type Query_RootMobile_Comment_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Comment_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Comment_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Notifications_Bool_Exp>;
};


export type Query_RootMobile_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


export type Query_RootMobile_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


export type Query_RootMobile_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Confirmation_CodesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


export type Query_RootMobile_Confirmation_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


export type Query_RootMobile_Confirmation_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};


export type Query_RootMobile_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};


export type Query_RootMobile_Contacts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contents_Order_By>>;
  where?: InputMaybe<Mobile_Contents_Bool_Exp>;
};


export type Query_RootMobile_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contents_Order_By>>;
  where?: InputMaybe<Mobile_Contents_Bool_Exp>;
};


export type Query_RootMobile_Contents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Device_InfosArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};


export type Query_RootMobile_Device_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};


export type Query_RootMobile_Device_Infos_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Device_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};


export type Query_RootMobile_Device_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};


export type Query_RootMobile_Device_Settings_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_DevicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Devices_Order_By>>;
  where?: InputMaybe<Mobile_Devices_Bool_Exp>;
};


export type Query_RootMobile_Devices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Devices_Order_By>>;
  where?: InputMaybe<Mobile_Devices_Bool_Exp>;
};


export type Query_RootMobile_Devices_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Document_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Document_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Document_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};


export type Query_RootMobile_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};


export type Query_RootMobile_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Education_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Education_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Education_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_EducationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Educations_Order_By>>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};


export type Query_RootMobile_Educations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Educations_Order_By>>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};


export type Query_RootMobile_Educations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_EmojisArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Emojis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Emojis_Order_By>>;
  where?: InputMaybe<Mobile_Emojis_Bool_Exp>;
};


export type Query_RootMobile_Emojis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Emojis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Emojis_Order_By>>;
  where?: InputMaybe<Mobile_Emojis_Bool_Exp>;
};


export type Query_RootMobile_Emojis_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Fixed_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_Fixed_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_Fixed_Chat_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Fixed_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Comments_Bool_Exp>;
};


export type Query_RootMobile_Fixed_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Comments_Bool_Exp>;
};


export type Query_RootMobile_Fixed_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Founder_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Founder_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


export type Query_RootMobile_Founder_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_FoundersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founders_Order_By>>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};


export type Query_RootMobile_Founders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founders_Order_By>>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};


export type Query_RootMobile_Founders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Invite_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Invite_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Invite_Emails_Order_By>>;
  where?: InputMaybe<Mobile_Invite_Emails_Bool_Exp>;
};


export type Query_RootMobile_Invite_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Invite_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Invite_Emails_Order_By>>;
  where?: InputMaybe<Mobile_Invite_Emails_Bool_Exp>;
};


export type Query_RootMobile_Invite_Emails_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Like_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Like_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Like_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Like_Notifications_Bool_Exp>;
};


export type Query_RootMobile_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


export type Query_RootMobile_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


export type Query_RootMobile_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Link_SnippetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Link_Snippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Link_Snippets_Order_By>>;
  where?: InputMaybe<Mobile_Link_Snippets_Bool_Exp>;
};


export type Query_RootMobile_Link_Snippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Link_Snippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Link_Snippets_Order_By>>;
  where?: InputMaybe<Mobile_Link_Snippets_Bool_Exp>;
};


export type Query_RootMobile_Link_Snippets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Locked_Deleted_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_Locked_Deleted_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_Mediafile_Status_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};


export type Query_RootMobile_Mediafile_Status_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};


export type Query_RootMobile_Mediafile_Status_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Mediafile_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Mediafile_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Mediafile_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Medical_Concierge_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};


export type Query_RootMobile_Medical_Concierge_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};


export type Query_RootMobile_Medical_Concierge_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Medical_Concierge_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
};


export type Query_RootMobile_Medical_Concierge_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
};


export type Query_RootMobile_Medical_Concierge_Products_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Mention_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mention_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mention_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Mention_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Mention_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mention_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mention_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Mention_Notifications_Bool_Exp>;
};


export type Query_RootMobile_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mentions_Order_By>>;
  where?: InputMaybe<Mobile_Mentions_Bool_Exp>;
};


export type Query_RootMobile_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mentions_Order_By>>;
  where?: InputMaybe<Mobile_Mentions_Bool_Exp>;
};


export type Query_RootMobile_Mentions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Metric_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Names_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};


export type Query_RootMobile_Metric_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Names_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};


export type Query_RootMobile_Metric_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Metric_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


export type Query_RootMobile_Metric_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


export type Query_RootMobile_Metric_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Metric_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Types_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
};


export type Query_RootMobile_Metric_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Types_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
};


export type Query_RootMobile_Metric_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metrics_Order_By>>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};


export type Query_RootMobile_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metrics_Order_By>>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};


export type Query_RootMobile_Metrics_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Notification_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notification_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notification_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Notification_Messages_Bool_Exp>;
};


export type Query_RootMobile_Notification_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notification_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notification_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Notification_Messages_Bool_Exp>;
};


export type Query_RootMobile_Notification_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Notifications_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Notifications_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Notifications_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Notifications_Statuses_By_PkArgs = {
  title: Scalars['String']['input'];
};


export type Query_RootMobile_Order_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Order_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Order_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Order_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Order_ProfessionalsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Professionals_Order_By>>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};


export type Query_RootMobile_Order_Professionals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Professionals_Order_By>>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};


export type Query_RootMobile_Order_Professionals_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


export type Query_RootMobile_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


export type Query_RootMobile_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_OrgsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


export type Query_RootMobile_Orgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


export type Query_RootMobile_Orgs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Param_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
};


export type Query_RootMobile_Param_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
};


export type Query_RootMobile_Param_Categories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Param_Categories_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


export type Query_RootMobile_Param_Categories_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


export type Query_RootMobile_Param_Category_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};


export type Query_RootMobile_Param_Category_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};


export type Query_RootMobile_Param_Category_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Param_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};


export type Query_RootMobile_Param_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};


export type Query_RootMobile_Param_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Order_By>>;
  where?: InputMaybe<Mobile_Params_Bool_Exp>;
};


export type Query_RootMobile_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Order_By>>;
  where?: InputMaybe<Mobile_Params_Bool_Exp>;
};


export type Query_RootMobile_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Params_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


export type Query_RootMobile_Params_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


export type Query_RootMobile_Params_Services_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Personal_Info_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Info_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Info_Views_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
};


export type Query_RootMobile_Personal_Info_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Info_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Info_Views_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
};


export type Query_RootMobile_Personal_Info_Views_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Personal_InfosArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


export type Query_RootMobile_Personal_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


export type Query_RootMobile_Personal_Infos_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};


export type Query_RootMobile_Phones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};


export type Query_RootMobile_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Post_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


export type Query_RootMobile_Post_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


export type Query_RootMobile_Post_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_PostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Query_RootMobile_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Query_RootMobile_Posts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Posts_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Services_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Services_Bool_Exp>;
};


export type Query_RootMobile_Posts_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Services_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Services_Bool_Exp>;
};


export type Query_RootMobile_Pro_Document_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Pro_Document_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Pro_Document_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Pro_ProfessionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Professions_Bool_Exp>;
};


export type Query_RootMobile_Pro_Professions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Professions_Bool_Exp>;
};


export type Query_RootMobile_Pro_Professions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Pro_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Types_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Types_Bool_Exp>;
};


export type Query_RootMobile_Pro_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Types_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Types_Bool_Exp>;
};


export type Query_RootMobile_Pro_Types_By_PkArgs = {
  title: Scalars['String']['input'];
};


export type Query_RootMobile_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Problems_Order_By>>;
  where?: InputMaybe<Mobile_Problems_Bool_Exp>;
};


export type Query_RootMobile_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Problems_Order_By>>;
  where?: InputMaybe<Mobile_Problems_Bool_Exp>;
};


export type Query_RootMobile_Problems_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Profession_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profession_Names_Order_By>>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};


export type Query_RootMobile_Profession_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profession_Names_Order_By>>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};


export type Query_RootMobile_Profession_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ProfessionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};


export type Query_RootMobile_Professions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};


export type Query_RootMobile_Professions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Profile_DeletedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Deleteds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Deleteds_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Deleteds_Bool_Exp>;
};


export type Query_RootMobile_Profile_Deleteds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Deleteds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Deleteds_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Deleteds_Bool_Exp>;
};


export type Query_RootMobile_Profile_Deleteds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Profile_LocksArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


export type Query_RootMobile_Profile_Locks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


export type Query_RootMobile_Profile_Locks_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Query_RootMobile_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Query_RootMobile_Profiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ProsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pros_Order_By>>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};


export type Query_RootMobile_Pros_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pros_Order_By>>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};


export type Query_RootMobile_Pros_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Pulse_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Norms_Bool_Exp>;
};


export type Query_RootMobile_Pulse_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Norms_Bool_Exp>;
};


export type Query_RootMobile_Pulse_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Pulse_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Nows_Bool_Exp>;
};


export type Query_RootMobile_Pulse_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Nows_Bool_Exp>;
};


export type Query_RootMobile_Pulse_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_PulsesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulses_Order_By>>;
  where?: InputMaybe<Mobile_Pulses_Bool_Exp>;
};


export type Query_RootMobile_Pulses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulses_Order_By>>;
  where?: InputMaybe<Mobile_Pulses_Bool_Exp>;
};


export type Query_RootMobile_Pulses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};


export type Query_RootMobile_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};


export type Query_RootMobile_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reports_Order_By>>;
  where?: InputMaybe<Mobile_Reports_Bool_Exp>;
};


export type Query_RootMobile_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reports_Order_By>>;
  where?: InputMaybe<Mobile_Reports_Bool_Exp>;
};


export type Query_RootMobile_Reports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Search_Posts_Find_Title_MessageArgs = {
  args: Mobile_Search_Posts_Find_Title_Message_Args;
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Query_RootMobile_Search_Posts_Find_Title_Message_AggregateArgs = {
  args: Mobile_Search_Posts_Find_Title_Message_Args;
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Query_RootMobile_Search_Profiles_Find_First_Last_Username_AboutmyselfArgs = {
  args: Mobile_Search_Profiles_Find_First_Last_Username_Aboutmyself_Args;
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Query_RootMobile_Search_Profiles_Find_First_Last_Username_Aboutmyself_AggregateArgs = {
  args: Mobile_Search_Profiles_Find_First_Last_Username_Aboutmyself_Args;
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Query_RootMobile_Service_AgreementsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Agreements_Order_By>>;
  where?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
};


export type Query_RootMobile_Service_Agreements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Agreements_Order_By>>;
  where?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
};


export type Query_RootMobile_Service_Agreements_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Answer_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};


export type Query_RootMobile_Service_Answer_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};


export type Query_RootMobile_Service_Answer_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answers_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};


export type Query_RootMobile_Service_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answers_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};


export type Query_RootMobile_Service_Answers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


export type Query_RootMobile_Service_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


export type Query_RootMobile_Service_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_FavoritesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


export type Query_RootMobile_Service_Favorites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


export type Query_RootMobile_Service_Favorites_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Free_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Free_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Free_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Free_Purchases_Bool_Exp>;
};


export type Query_RootMobile_Service_Free_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Free_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Free_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Free_Purchases_Bool_Exp>;
};


export type Query_RootMobile_Service_Free_Purchases_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


export type Query_RootMobile_Service_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


export type Query_RootMobile_Service_Images_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


export type Query_RootMobile_Service_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


export type Query_RootMobile_Service_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_List_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Documents_Bool_Exp>;
};


export type Query_RootMobile_Service_List_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Documents_Bool_Exp>;
};


export type Query_RootMobile_Service_List_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_List_PeriodicitiesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
};


export type Query_RootMobile_Service_List_Periodicities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
};


export type Query_RootMobile_Service_List_Periodicities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};


export type Query_RootMobile_Service_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};


export type Query_RootMobile_Service_Lists_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Service_Orders_Bool_Exp>;
};


export type Query_RootMobile_Service_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Service_Orders_Bool_Exp>;
};


export type Query_RootMobile_Service_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_PeriodicitiesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
};


export type Query_RootMobile_Service_Periodicities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
};


export type Query_RootMobile_Service_Periodicities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


export type Query_RootMobile_Service_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


export type Query_RootMobile_Service_Purchases_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


export type Query_RootMobile_Service_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


export type Query_RootMobile_Service_Questions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Rate_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Comments_Bool_Exp>;
};


export type Query_RootMobile_Service_Rate_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Comments_Bool_Exp>;
};


export type Query_RootMobile_Service_Rate_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Rate_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};


export type Query_RootMobile_Service_Rate_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};


export type Query_RootMobile_Service_Rate_Images_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Rate_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};


export type Query_RootMobile_Service_Rate_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};


export type Query_RootMobile_Service_Rate_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_RatesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


export type Query_RootMobile_Service_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


export type Query_RootMobile_Service_Rates_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Service_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
};


export type Query_RootMobile_Service_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Tag_AgesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


export type Query_RootMobile_Service_Tag_Ages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


export type Query_RootMobile_Service_Tag_Ages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Tag_Target_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


export type Query_RootMobile_Service_Tag_Target_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


export type Query_RootMobile_Service_Tag_Target_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Tag_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


export type Query_RootMobile_Service_Tag_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


export type Query_RootMobile_Service_Tag_Targets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Tariff_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariff_Documents_Bool_Exp>;
};


export type Query_RootMobile_Service_Tariff_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariff_Documents_Bool_Exp>;
};


export type Query_RootMobile_Service_Tariff_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_TariffsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariffs_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};


export type Query_RootMobile_Service_Tariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariffs_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};


export type Query_RootMobile_Service_Tariffs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_Type_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Type_Names_Order_By>>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};


export type Query_RootMobile_Service_Type_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Type_Names_Order_By>>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};


export type Query_RootMobile_Service_Type_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Service_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Types_Order_By>>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};


export type Query_RootMobile_Service_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Types_Order_By>>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};


export type Query_RootMobile_Service_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


export type Query_RootMobile_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


export type Query_RootMobile_Services_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};


export type Query_RootMobile_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};


export type Query_RootMobile_Settings_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Share_PostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};


export type Query_RootMobile_Share_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};


export type Query_RootMobile_Share_Posts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Sleep_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Norms_Bool_Exp>;
};


export type Query_RootMobile_Sleep_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Norms_Bool_Exp>;
};


export type Query_RootMobile_Sleep_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Sleep_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Nows_Bool_Exp>;
};


export type Query_RootMobile_Sleep_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Nows_Bool_Exp>;
};


export type Query_RootMobile_Sleep_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_SleepsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleeps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleeps_Order_By>>;
  where?: InputMaybe<Mobile_Sleeps_Bool_Exp>;
};


export type Query_RootMobile_Sleeps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleeps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleeps_Order_By>>;
  where?: InputMaybe<Mobile_Sleeps_Bool_Exp>;
};


export type Query_RootMobile_Sleeps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Slots_Order_By>>;
  where?: InputMaybe<Mobile_Slots_Bool_Exp>;
};


export type Query_RootMobile_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Slots_Order_By>>;
  where?: InputMaybe<Mobile_Slots_Bool_Exp>;
};


export type Query_RootMobile_Slots_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Status_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Status_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Status_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
};


export type Query_RootMobile_Status_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Status_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Status_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
};


export type Query_RootMobile_Status_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Step_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Step_Norms_Bool_Exp>;
};


export type Query_RootMobile_Step_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Step_Norms_Bool_Exp>;
};


export type Query_RootMobile_Step_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Step_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Step_Nows_Bool_Exp>;
};


export type Query_RootMobile_Step_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Step_Nows_Bool_Exp>;
};


export type Query_RootMobile_Step_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_StepsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Steps_Order_By>>;
  where?: InputMaybe<Mobile_Steps_Bool_Exp>;
};


export type Query_RootMobile_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Steps_Order_By>>;
  where?: InputMaybe<Mobile_Steps_Bool_Exp>;
};


export type Query_RootMobile_Steps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Subscribe_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribe_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribe_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Subscribe_Notifications_Bool_Exp>;
};


export type Query_RootMobile_Subscribe_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribe_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribe_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Subscribe_Notifications_Bool_Exp>;
};


export type Query_RootMobile_SubscribesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


export type Query_RootMobile_Subscribes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


export type Query_RootMobile_Subscribes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Support_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


export type Query_RootMobile_Support_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


export type Query_RootMobile_Support_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Support_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Topics_Order_By>>;
  where?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
};


export type Query_RootMobile_Support_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Topics_Order_By>>;
  where?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
};


export type Query_RootMobile_Support_Topics_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Tag_AgesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
};


export type Query_RootMobile_Tag_Ages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
};


export type Query_RootMobile_Tag_Ages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Tag_Target_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
};


export type Query_RootMobile_Tag_Target_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
};


export type Query_RootMobile_Tag_Target_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Tag_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
};


export type Query_RootMobile_Tag_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
};


export type Query_RootMobile_Tag_Targets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Type_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
};


export type Query_RootMobile_Type_Phones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
};


export type Query_RootMobile_Type_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Type_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
};


export type Query_RootMobile_Type_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
};


export type Query_RootMobile_Type_Profiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Upload_Pro_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};


export type Query_RootMobile_Upload_Pro_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};


export type Query_RootMobile_Upload_Pro_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_User_Chat_RightsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chat_Rights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chat_Rights_Order_By>>;
  where?: InputMaybe<Mobile_User_Chat_Rights_Bool_Exp>;
};


export type Query_RootMobile_User_Chat_Rights_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chat_Rights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chat_Rights_Order_By>>;
  where?: InputMaybe<Mobile_User_Chat_Rights_Bool_Exp>;
};


export type Query_RootMobile_User_Chat_Rights_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_User_ChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


export type Query_RootMobile_User_Chats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


export type Query_RootMobile_User_Chats_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_View_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_View_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_View_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_View_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_View_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_View_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_View_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_View_Chat_Messages_Bool_Exp>;
};


export type Query_RootMobile_Water_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Water_Norms_Bool_Exp>;
};


export type Query_RootMobile_Water_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Water_Norms_Bool_Exp>;
};


export type Query_RootMobile_Water_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Water_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Water_Nows_Bool_Exp>;
};


export type Query_RootMobile_Water_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Water_Nows_Bool_Exp>;
};


export type Query_RootMobile_Water_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_WatersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Waters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Waters_Order_By>>;
  where?: InputMaybe<Mobile_Waters_Bool_Exp>;
};


export type Query_RootMobile_Waters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Waters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Waters_Order_By>>;
  where?: InputMaybe<Mobile_Waters_Bool_Exp>;
};


export type Query_RootMobile_Waters_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_Weight_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weight_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weight_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Weight_Nows_Bool_Exp>;
};


export type Query_RootMobile_Weight_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weight_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weight_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Weight_Nows_Bool_Exp>;
};


export type Query_RootMobile_Weight_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootMobile_WeightsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weights_Order_By>>;
  where?: InputMaybe<Mobile_Weights_Bool_Exp>;
};


export type Query_RootMobile_Weights_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weights_Order_By>>;
  where?: InputMaybe<Mobile_Weights_Bool_Exp>;
};


export type Query_RootMobile_Weights_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Query_RootPartner_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Query_RootPartner_Balances_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Log_Vzr_SalesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Sales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Sales_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Log_Vzr_SearchesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Searches_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Log_Vzr_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Query_RootPartner_Log_Vzr_Views_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Place_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Place_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Place_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_PlacesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Query_RootPartner_Places_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Query_RootPartner_Places_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Sale_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Query_RootPartner_Sale_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Query_RootPartner_Sale_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Status_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Status_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Status_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Status_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Status_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Status_Transactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_ThemesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Query_RootPartner_Themes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Query_RootPartner_Themes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Query_RootPartner_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_Type_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Type_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Type_Transactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_User_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Query_RootPartner_User_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Query_RootPartner_User_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPartner_UsersArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Query_RootPartner_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Query_RootPartner_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootServiceBotSendTelegramCodeToUserArgs = {
  arg: ServiceBotSendTelegramCodeToUserInput;
};


export type Query_RootService_UsersArgs = {
  distinct_on?: InputMaybe<Array<Service_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Users_Order_By>>;
  where?: InputMaybe<Service_Users_Bool_Exp>;
};


export type Query_RootService_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Users_Order_By>>;
  where?: InputMaybe<Service_Users_Bool_Exp>;
};


export type Query_RootService_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};

export type ServiceBotSendTelegramCodeToUserInput = {
  bot_user_id: Scalars['Int']['input'];
  chat_id: Scalars['Int']['input'];
  chat_message_type_id: Scalars['Int']['input'];
  profile_id: Scalars['Int']['input'];
  status_message_id: Scalars['Int']['input'];
  telegram_code: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};

export type ServiceBotSendTelegramCodeToUserOutput = {
  __typename?: 'serviceBotSendTelegramCodeToUserOutput';
  result?: Maybe<Array<Maybe<ServiceBotSendTelegramCodeToUserOutput_Service_Message>>>;
};

export type ServiceBotSendTelegramCodeToUserOutput_Service_Message = {
  __typename?: 'serviceBotSendTelegramCodeToUserOutput_service_message';
  bot_user_id: Scalars['Int']['output'];
  chat_id: Scalars['Int']['output'];
  chat_message_type_id: Scalars['Int']['output'];
  profile_id: Scalars['Int']['output'];
  status_message_id: Scalars['Int']['output'];
  telegram_code: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
};

/** columns and relationships of "users" */
export type Service_Users = {
  __typename?: 'service_users';
  allow_password_change?: Maybe<Scalars['Boolean']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "users" */
export type Service_Users_Aggregate = {
  __typename?: 'service_users_aggregate';
  aggregate?: Maybe<Service_Users_Aggregate_Fields>;
  nodes: Array<Service_Users>;
};

/** aggregate fields of "users" */
export type Service_Users_Aggregate_Fields = {
  __typename?: 'service_users_aggregate_fields';
  avg?: Maybe<Service_Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Service_Users_Max_Fields>;
  min?: Maybe<Service_Users_Min_Fields>;
  stddev?: Maybe<Service_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Users_Sum_Fields>;
  var_pop?: Maybe<Service_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Users_Var_Samp_Fields>;
  variance?: Maybe<Service_Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Service_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Service_Users_Avg_Fields = {
  __typename?: 'service_users_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Service_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Users_Bool_Exp>>;
  _not?: InputMaybe<Service_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Users_Bool_Exp>>;
  allow_password_change?: InputMaybe<Boolean_Comparison_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  otp_secret_key?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Service_Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "email" */
  IndexUsersOnEmail = 'index_users_on_email',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Service_Users_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users" */
export type Service_Users_Insert_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Service_Users_Max_Fields = {
  __typename?: 'service_users_max_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Service_Users_Min_Fields = {
  __typename?: 'service_users_min_fields';
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otp_secret_key?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users" */
export type Service_Users_Mutation_Response = {
  __typename?: 'service_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Users>;
};

/** on_conflict condition type for table "users" */
export type Service_Users_On_Conflict = {
  constraint: Service_Users_Constraint;
  update_columns?: Array<Service_Users_Update_Column>;
  where?: InputMaybe<Service_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Service_Users_Order_By = {
  allow_password_change?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  otp_secret_key?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Service_Users_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users" */
export enum Service_Users_Select_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Service_Users_Set_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Service_Users_Stddev_Fields = {
  __typename?: 'service_users_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Service_Users_Stddev_Pop_Fields = {
  __typename?: 'service_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Service_Users_Stddev_Samp_Fields = {
  __typename?: 'service_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "service_users" */
export type Service_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Users_Stream_Cursor_Value_Input = {
  allow_password_change?: InputMaybe<Scalars['Boolean']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  otp_secret_key?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Service_Users_Sum_Fields = {
  __typename?: 'service_users_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users" */
export enum Service_Users_Update_Column {
  /** column name */
  AllowPasswordChange = 'allow_password_change',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  OtpSecretKey = 'otp_secret_key',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  Role = 'role',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Service_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Users_Var_Pop_Fields = {
  __typename?: 'service_users_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Service_Users_Var_Samp_Fields = {
  __typename?: 'service_users_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Service_Users_Variance_Fields = {
  __typename?: 'service_users_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "oauth_access_tokens" */
  auth_oauth_access_tokens: Array<Auth_Oauth_Access_Tokens>;
  /** fetch aggregated fields from the table: "oauth_access_tokens" */
  auth_oauth_access_tokens_aggregate: Auth_Oauth_Access_Tokens_Aggregate;
  /** fetch data from the table: "oauth_access_tokens" using primary key columns */
  auth_oauth_access_tokens_by_pk?: Maybe<Auth_Oauth_Access_Tokens>;
  /** fetch data from the table in a streaming manner: "oauth_access_tokens" */
  auth_oauth_access_tokens_stream: Array<Auth_Oauth_Access_Tokens>;
  /** fetch data from the table: "test_users" */
  auth_test_users: Array<Auth_Test_Users>;
  /** fetch aggregated fields from the table: "test_users" */
  auth_test_users_aggregate: Auth_Test_Users_Aggregate;
  /** fetch data from the table: "test_users" using primary key columns */
  auth_test_users_by_pk?: Maybe<Auth_Test_Users>;
  /** fetch data from the table in a streaming manner: "test_users" */
  auth_test_users_stream: Array<Auth_Test_Users>;
  /** fetch data from the table: "users" */
  auth_users: Array<Auth_Users>;
  /** fetch aggregated fields from the table: "users" */
  auth_users_aggregate: Auth_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  auth_users_by_pk?: Maybe<Auth_Users>;
  /** fetch data from the table in a streaming manner: "users" */
  auth_users_stream: Array<Auth_Users>;
  /** fetch data from the table: "verification_emails" */
  auth_verification_emails: Array<Auth_Verification_Emails>;
  /** fetch aggregated fields from the table: "verification_emails" */
  auth_verification_emails_aggregate: Auth_Verification_Emails_Aggregate;
  /** fetch data from the table: "verification_emails" using primary key columns */
  auth_verification_emails_by_pk?: Maybe<Auth_Verification_Emails>;
  /** fetch data from the table in a streaming manner: "verification_emails" */
  auth_verification_emails_stream: Array<Auth_Verification_Emails>;
  /** fetch data from the table: "verification_phones" */
  auth_verification_phones: Array<Auth_Verification_Phones>;
  /** fetch aggregated fields from the table: "verification_phones" */
  auth_verification_phones_aggregate: Auth_Verification_Phones_Aggregate;
  /** fetch data from the table: "verification_phones" using primary key columns */
  auth_verification_phones_by_pk?: Maybe<Auth_Verification_Phones>;
  /** fetch data from the table in a streaming manner: "verification_phones" */
  auth_verification_phones_stream: Array<Auth_Verification_Phones>;
  /** fetch data from the table: "order_polices" */
  billing_order_polices: Array<Billing_Order_Polices>;
  /** fetch aggregated fields from the table: "order_polices" */
  billing_order_polices_aggregate: Billing_Order_Polices_Aggregate;
  /** fetch data from the table: "order_polices" using primary key columns */
  billing_order_polices_by_pk?: Maybe<Billing_Order_Polices>;
  /** fetch data from the table in a streaming manner: "order_polices" */
  billing_order_polices_stream: Array<Billing_Order_Polices>;
  /** fetch data from the table: "billing_order_statuses" */
  billing_order_statuses: Array<Billing_Order_Statuses>;
  /** fetch aggregated fields from the table: "billing_order_statuses" */
  billing_order_statuses_aggregate: Billing_Order_Statuses_Aggregate;
  /** fetch data from the table: "billing_order_statuses" using primary key columns */
  billing_order_statuses_by_pk?: Maybe<Billing_Order_Statuses>;
  /** fetch data from the table in a streaming manner: "billing_order_statuses" */
  billing_order_statuses_stream: Array<Billing_Order_Statuses>;
  /** fetch data from the table: "promo_codes" */
  billing_promo_codes: Array<Billing_Promo_Codes>;
  /** fetch aggregated fields from the table: "promo_codes" */
  billing_promo_codes_aggregate: Billing_Promo_Codes_Aggregate;
  /** fetch data from the table: "promo_codes" using primary key columns */
  billing_promo_codes_by_pk?: Maybe<Billing_Promo_Codes>;
  /** fetch data from the table in a streaming manner: "promo_codes" */
  billing_promo_codes_stream: Array<Billing_Promo_Codes>;
  /** fetch data from the table: "cities" */
  dms_cities: Array<Dms_Cities>;
  /** fetch aggregated fields from the table: "cities" */
  dms_cities_aggregate: Dms_Cities_Aggregate;
  /** fetch data from the table: "cities" using primary key columns */
  dms_cities_by_pk?: Maybe<Dms_Cities>;
  /** fetch data from the table in a streaming manner: "cities" */
  dms_cities_stream: Array<Dms_Cities>;
  /** fetch data from the table: "group_insureds" */
  dms_group_insureds: Array<Dms_Group_Insureds>;
  /** fetch aggregated fields from the table: "group_insureds" */
  dms_group_insureds_aggregate: Dms_Group_Insureds_Aggregate;
  /** fetch data from the table: "group_insureds" using primary key columns */
  dms_group_insureds_by_pk?: Maybe<Dms_Group_Insureds>;
  /** fetch data from the table in a streaming manner: "group_insureds" */
  dms_group_insureds_stream: Array<Dms_Group_Insureds>;
  /** fetch data from the table: "groups" */
  dms_groups: Array<Dms_Groups>;
  /** fetch aggregated fields from the table: "groups" */
  dms_groups_aggregate: Dms_Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  dms_groups_by_pk?: Maybe<Dms_Groups>;
  /** fetch data from the table in a streaming manner: "groups" */
  dms_groups_stream: Array<Dms_Groups>;
  /** fetch data from the table: "insurants" */
  dms_insurants: Array<Dms_Insurants>;
  /** fetch aggregated fields from the table: "insurants" */
  dms_insurants_aggregate: Dms_Insurants_Aggregate;
  /** fetch data from the table: "insurants" using primary key columns */
  dms_insurants_by_pk?: Maybe<Dms_Insurants>;
  /** fetch data from the table in a streaming manner: "insurants" */
  dms_insurants_stream: Array<Dms_Insurants>;
  /** fetch data from the table: "insureds" */
  dms_insureds: Array<Dms_Insureds>;
  /** fetch aggregated fields from the table: "insureds" */
  dms_insureds_aggregate: Dms_Insureds_Aggregate;
  /** fetch data from the table: "insureds" using primary key columns */
  dms_insureds_by_pk?: Maybe<Dms_Insureds>;
  /** fetch data from the table in a streaming manner: "insureds" */
  dms_insureds_stream: Array<Dms_Insureds>;
  /** fetch data from the table: "order_links" */
  dms_order_links: Array<Dms_Order_Links>;
  /** fetch aggregated fields from the table: "order_links" */
  dms_order_links_aggregate: Dms_Order_Links_Aggregate;
  /** fetch data from the table: "order_links" using primary key columns */
  dms_order_links_by_pk?: Maybe<Dms_Order_Links>;
  /** fetch data from the table in a streaming manner: "order_links" */
  dms_order_links_stream: Array<Dms_Order_Links>;
  /** fetch data from the table: "order_numbers" */
  dms_order_numbers: Array<Dms_Order_Numbers>;
  /** fetch aggregated fields from the table: "order_numbers" */
  dms_order_numbers_aggregate: Dms_Order_Numbers_Aggregate;
  /** fetch data from the table: "order_numbers" using primary key columns */
  dms_order_numbers_by_pk?: Maybe<Dms_Order_Numbers>;
  /** fetch data from the table in a streaming manner: "order_numbers" */
  dms_order_numbers_stream: Array<Dms_Order_Numbers>;
  /** fetch data from the table: "orders" */
  dms_orders: Array<Dms_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  dms_orders_aggregate: Dms_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  dms_orders_by_pk?: Maybe<Dms_Orders>;
  /** fetch data from the table in a streaming manner: "orders" */
  dms_orders_stream: Array<Dms_Orders>;
  /** fetch data from the table: "products" */
  dms_products: Array<Dms_Products>;
  /** fetch aggregated fields from the table: "products" */
  dms_products_aggregate: Dms_Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  dms_products_by_pk?: Maybe<Dms_Products>;
  /** fetch data from the table in a streaming manner: "products" */
  dms_products_stream: Array<Dms_Products>;
  /** fetch data from the table: "promo_codes" */
  dms_promo_codes: Array<Dms_Promo_Codes>;
  /** fetch aggregated fields from the table: "promo_codes" */
  dms_promo_codes_aggregate: Dms_Promo_Codes_Aggregate;
  /** fetch data from the table: "promo_codes" using primary key columns */
  dms_promo_codes_by_pk?: Maybe<Dms_Promo_Codes>;
  /** fetch data from the table in a streaming manner: "promo_codes" */
  dms_promo_codes_stream: Array<Dms_Promo_Codes>;
  /** fetch data from the table: "statuses" */
  dms_statuses: Array<Dms_Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  dms_statuses_aggregate: Dms_Statuses_Aggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  dms_statuses_by_pk?: Maybe<Dms_Statuses>;
  /** fetch data from the table in a streaming manner: "statuses" */
  dms_statuses_stream: Array<Dms_Statuses>;
  /** fetch data from the table: "users" */
  dms_users: Array<Dms_Users>;
  /** fetch aggregated fields from the table: "users" */
  dms_users_aggregate: Dms_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  dms_users_by_pk?: Maybe<Dms_Users>;
  /** fetch data from the table in a streaming manner: "users" */
  dms_users_stream: Array<Dms_Users>;
  /** fetch data from the table: "cards" */
  fintech_cards: Array<Fintech_Cards>;
  /** fetch aggregated fields from the table: "cards" */
  fintech_cards_aggregate: Fintech_Cards_Aggregate;
  /** fetch data from the table: "cards" using primary key columns */
  fintech_cards_by_pk?: Maybe<Fintech_Cards>;
  /** fetch data from the table in a streaming manner: "cards" */
  fintech_cards_stream: Array<Fintech_Cards>;
  /** fetch data from the table: "clients" */
  fintech_clients: Array<Fintech_Clients>;
  /** fetch aggregated fields from the table: "clients" */
  fintech_clients_aggregate: Fintech_Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  fintech_clients_by_pk?: Maybe<Fintech_Clients>;
  /** fetch data from the table in a streaming manner: "clients" */
  fintech_clients_stream: Array<Fintech_Clients>;
  /** fetch data from the table: "orders" */
  fintech_orders: Array<Fintech_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  fintech_orders_aggregate: Fintech_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  fintech_orders_by_pk?: Maybe<Fintech_Orders>;
  /** fetch data from the table in a streaming manner: "orders" */
  fintech_orders_stream: Array<Fintech_Orders>;
  /** fetch data from the table: "amocrm_contacts" */
  mobile_amocrm_contacts: Array<Mobile_Amocrm_Contacts>;
  /** fetch aggregated fields from the table: "amocrm_contacts" */
  mobile_amocrm_contacts_aggregate: Mobile_Amocrm_Contacts_Aggregate;
  /** fetch data from the table: "amocrm_contacts" using primary key columns */
  mobile_amocrm_contacts_by_pk?: Maybe<Mobile_Amocrm_Contacts>;
  /** fetch data from the table in a streaming manner: "amocrm_contacts" */
  mobile_amocrm_contacts_stream: Array<Mobile_Amocrm_Contacts>;
  /** fetch data from the table: "amocrm_leads" */
  mobile_amocrm_leads: Array<Mobile_Amocrm_Leads>;
  /** fetch aggregated fields from the table: "amocrm_leads" */
  mobile_amocrm_leads_aggregate: Mobile_Amocrm_Leads_Aggregate;
  /** fetch data from the table: "amocrm_leads" using primary key columns */
  mobile_amocrm_leads_by_pk?: Maybe<Mobile_Amocrm_Leads>;
  /** fetch data from the table in a streaming manner: "amocrm_leads" */
  mobile_amocrm_leads_stream: Array<Mobile_Amocrm_Leads>;
  /** fetch data from the table: "amocrm_tags" */
  mobile_amocrm_tags: Array<Mobile_Amocrm_Tags>;
  /** fetch aggregated fields from the table: "amocrm_tags" */
  mobile_amocrm_tags_aggregate: Mobile_Amocrm_Tags_Aggregate;
  /** fetch data from the table: "amocrm_tags" using primary key columns */
  mobile_amocrm_tags_by_pk?: Maybe<Mobile_Amocrm_Tags>;
  /** fetch data from the table in a streaming manner: "amocrm_tags" */
  mobile_amocrm_tags_stream: Array<Mobile_Amocrm_Tags>;
  /** fetch data from the table: "appointments" */
  mobile_appointments: Array<Mobile_Appointments>;
  /** fetch aggregated fields from the table: "appointments" */
  mobile_appointments_aggregate: Mobile_Appointments_Aggregate;
  /** fetch data from the table: "appointments" using primary key columns */
  mobile_appointments_by_pk?: Maybe<Mobile_Appointments>;
  /** fetch data from the table in a streaming manner: "appointments" */
  mobile_appointments_stream: Array<Mobile_Appointments>;
  /** fetch data from the table: "auto_replies" */
  mobile_auto_replies: Array<Mobile_Auto_Replies>;
  /** fetch aggregated fields from the table: "auto_replies" */
  mobile_auto_replies_aggregate: Mobile_Auto_Replies_Aggregate;
  /** fetch data from the table: "auto_replies" using primary key columns */
  mobile_auto_replies_by_pk?: Maybe<Mobile_Auto_Replies>;
  /** fetch data from the table in a streaming manner: "auto_replies" */
  mobile_auto_replies_stream: Array<Mobile_Auto_Replies>;
  /** fetch data from the table: "auto_reply_documents" */
  mobile_auto_reply_documents: Array<Mobile_Auto_Reply_Documents>;
  /** fetch aggregated fields from the table: "auto_reply_documents" */
  mobile_auto_reply_documents_aggregate: Mobile_Auto_Reply_Documents_Aggregate;
  /** fetch data from the table: "auto_reply_documents" using primary key columns */
  mobile_auto_reply_documents_by_pk?: Maybe<Mobile_Auto_Reply_Documents>;
  /** fetch data from the table in a streaming manner: "auto_reply_documents" */
  mobile_auto_reply_documents_stream: Array<Mobile_Auto_Reply_Documents>;
  /** fetch data from the table: "auto_reply_mediafiles" */
  mobile_auto_reply_mediafiles: Array<Mobile_Auto_Reply_Mediafiles>;
  /** fetch aggregated fields from the table: "auto_reply_mediafiles" */
  mobile_auto_reply_mediafiles_aggregate: Mobile_Auto_Reply_Mediafiles_Aggregate;
  /** fetch data from the table: "auto_reply_mediafiles" using primary key columns */
  mobile_auto_reply_mediafiles_by_pk?: Maybe<Mobile_Auto_Reply_Mediafiles>;
  /** fetch data from the table in a streaming manner: "auto_reply_mediafiles" */
  mobile_auto_reply_mediafiles_stream: Array<Mobile_Auto_Reply_Mediafiles>;
  /** fetch data from the table: "bank_accounts" */
  mobile_bank_accounts: Array<Mobile_Bank_Accounts>;
  /** fetch aggregated fields from the table: "bank_accounts" */
  mobile_bank_accounts_aggregate: Mobile_Bank_Accounts_Aggregate;
  /** fetch data from the table: "bank_accounts" using primary key columns */
  mobile_bank_accounts_by_pk?: Maybe<Mobile_Bank_Accounts>;
  /** fetch data from the table in a streaming manner: "bank_accounts" */
  mobile_bank_accounts_stream: Array<Mobile_Bank_Accounts>;
  /** fetch data from the table: "blocked_sites" */
  mobile_blocked_sites: Array<Mobile_Blocked_Sites>;
  /** fetch aggregated fields from the table: "blocked_sites" */
  mobile_blocked_sites_aggregate: Mobile_Blocked_Sites_Aggregate;
  /** fetch data from the table: "blocked_sites" using primary key columns */
  mobile_blocked_sites_by_pk?: Maybe<Mobile_Blocked_Sites>;
  /** fetch data from the table in a streaming manner: "blocked_sites" */
  mobile_blocked_sites_stream: Array<Mobile_Blocked_Sites>;
  /** fetch data from the table: "blockeds" */
  mobile_blockeds: Array<Mobile_Blockeds>;
  /** fetch aggregated fields from the table: "blockeds" */
  mobile_blockeds_aggregate: Mobile_Blockeds_Aggregate;
  /** fetch data from the table: "blockeds" using primary key columns */
  mobile_blockeds_by_pk?: Maybe<Mobile_Blockeds>;
  /** fetch data from the table in a streaming manner: "blockeds" */
  mobile_blockeds_stream: Array<Mobile_Blockeds>;
  /** fetch data from the table: "calorie_norms" */
  mobile_calorie_norms: Array<Mobile_Calorie_Norms>;
  /** fetch aggregated fields from the table: "calorie_norms" */
  mobile_calorie_norms_aggregate: Mobile_Calorie_Norms_Aggregate;
  /** fetch data from the table: "calorie_norms" using primary key columns */
  mobile_calorie_norms_by_pk?: Maybe<Mobile_Calorie_Norms>;
  /** fetch data from the table in a streaming manner: "calorie_norms" */
  mobile_calorie_norms_stream: Array<Mobile_Calorie_Norms>;
  /** fetch data from the table: "calorie_nows" */
  mobile_calorie_nows: Array<Mobile_Calorie_Nows>;
  /** fetch aggregated fields from the table: "calorie_nows" */
  mobile_calorie_nows_aggregate: Mobile_Calorie_Nows_Aggregate;
  /** fetch data from the table: "calorie_nows" using primary key columns */
  mobile_calorie_nows_by_pk?: Maybe<Mobile_Calorie_Nows>;
  /** fetch data from the table in a streaming manner: "calorie_nows" */
  mobile_calorie_nows_stream: Array<Mobile_Calorie_Nows>;
  /** fetch data from the table: "calories" */
  mobile_calories: Array<Mobile_Calories>;
  /** fetch aggregated fields from the table: "calories" */
  mobile_calories_aggregate: Mobile_Calories_Aggregate;
  /** fetch data from the table: "calories" using primary key columns */
  mobile_calories_by_pk?: Maybe<Mobile_Calories>;
  /** fetch data from the table in a streaming manner: "calories" */
  mobile_calories_stream: Array<Mobile_Calories>;
  /** fetch data from the table: "categories" */
  mobile_categories: Array<Mobile_Categories>;
  /** fetch aggregated fields from the table: "categories" */
  mobile_categories_aggregate: Mobile_Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  mobile_categories_by_pk?: Maybe<Mobile_Categories>;
  /** fetch data from the table in a streaming manner: "categories" */
  mobile_categories_stream: Array<Mobile_Categories>;
  /** fetch data from the table: "category_names" */
  mobile_category_names: Array<Mobile_Category_Names>;
  /** fetch aggregated fields from the table: "category_names" */
  mobile_category_names_aggregate: Mobile_Category_Names_Aggregate;
  /** fetch data from the table: "category_names" using primary key columns */
  mobile_category_names_by_pk?: Maybe<Mobile_Category_Names>;
  /** fetch data from the table in a streaming manner: "category_names" */
  mobile_category_names_stream: Array<Mobile_Category_Names>;
  /** fetch data from the table: "chat_black_lists" */
  mobile_chat_black_lists: Array<Mobile_Chat_Black_Lists>;
  /** fetch aggregated fields from the table: "chat_black_lists" */
  mobile_chat_black_lists_aggregate: Mobile_Chat_Black_Lists_Aggregate;
  /** fetch data from the table: "chat_black_lists" using primary key columns */
  mobile_chat_black_lists_by_pk?: Maybe<Mobile_Chat_Black_Lists>;
  /** fetch data from the table in a streaming manner: "chat_black_lists" */
  mobile_chat_black_lists_stream: Array<Mobile_Chat_Black_Lists>;
  /** fetch data from the table: "chat_defaults" */
  mobile_chat_defaults: Array<Mobile_Chat_Defaults>;
  /** fetch aggregated fields from the table: "chat_defaults" */
  mobile_chat_defaults_aggregate: Mobile_Chat_Defaults_Aggregate;
  /** fetch data from the table: "chat_defaults" using primary key columns */
  mobile_chat_defaults_by_pk?: Maybe<Mobile_Chat_Defaults>;
  /** fetch data from the table in a streaming manner: "chat_defaults" */
  mobile_chat_defaults_stream: Array<Mobile_Chat_Defaults>;
  /** fetch data from the table: "chat_file_types" */
  mobile_chat_file_types: Array<Mobile_Chat_File_Types>;
  /** fetch aggregated fields from the table: "chat_file_types" */
  mobile_chat_file_types_aggregate: Mobile_Chat_File_Types_Aggregate;
  /** fetch data from the table: "chat_file_types" using primary key columns */
  mobile_chat_file_types_by_pk?: Maybe<Mobile_Chat_File_Types>;
  /** fetch data from the table in a streaming manner: "chat_file_types" */
  mobile_chat_file_types_stream: Array<Mobile_Chat_File_Types>;
  /** fetch data from the table: "chat_message_comments" */
  mobile_chat_message_comments: Array<Mobile_Chat_Message_Comments>;
  /** fetch aggregated fields from the table: "chat_message_comments" */
  mobile_chat_message_comments_aggregate: Mobile_Chat_Message_Comments_Aggregate;
  /** fetch data from the table: "chat_message_comments" using primary key columns */
  mobile_chat_message_comments_by_pk?: Maybe<Mobile_Chat_Message_Comments>;
  /** fetch data from the table in a streaming manner: "chat_message_comments" */
  mobile_chat_message_comments_stream: Array<Mobile_Chat_Message_Comments>;
  /** fetch data from the table: "chat_message_files" */
  mobile_chat_message_files: Array<Mobile_Chat_Message_Files>;
  /** fetch aggregated fields from the table: "chat_message_files" */
  mobile_chat_message_files_aggregate: Mobile_Chat_Message_Files_Aggregate;
  /** fetch data from the table: "chat_message_files" using primary key columns */
  mobile_chat_message_files_by_pk?: Maybe<Mobile_Chat_Message_Files>;
  /** fetch data from the table in a streaming manner: "chat_message_files" */
  mobile_chat_message_files_stream: Array<Mobile_Chat_Message_Files>;
  /** fetch data from the table: "chat_message_notifications" */
  mobile_chat_message_notifications: Array<Mobile_Chat_Message_Notifications>;
  /** fetch aggregated fields from the table: "chat_message_notifications" */
  mobile_chat_message_notifications_aggregate: Mobile_Chat_Message_Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "chat_message_notifications" */
  mobile_chat_message_notifications_stream: Array<Mobile_Chat_Message_Notifications>;
  /** fetch data from the table: "chat_message_types" */
  mobile_chat_message_types: Array<Mobile_Chat_Message_Types>;
  /** fetch aggregated fields from the table: "chat_message_types" */
  mobile_chat_message_types_aggregate: Mobile_Chat_Message_Types_Aggregate;
  /** fetch data from the table: "chat_message_types" using primary key columns */
  mobile_chat_message_types_by_pk?: Maybe<Mobile_Chat_Message_Types>;
  /** fetch data from the table in a streaming manner: "chat_message_types" */
  mobile_chat_message_types_stream: Array<Mobile_Chat_Message_Types>;
  /** fetch data from the table: "chat_messages" */
  mobile_chat_messages: Array<Mobile_Chat_Messages>;
  /** fetch aggregated fields from the table: "chat_messages" */
  mobile_chat_messages_aggregate: Mobile_Chat_Messages_Aggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  mobile_chat_messages_by_pk?: Maybe<Mobile_Chat_Messages>;
  /** fetch data from the table in a streaming manner: "chat_messages" */
  mobile_chat_messages_stream: Array<Mobile_Chat_Messages>;
  /** fetch data from the table: "chat_roles" */
  mobile_chat_roles: Array<Mobile_Chat_Roles>;
  /** fetch aggregated fields from the table: "chat_roles" */
  mobile_chat_roles_aggregate: Mobile_Chat_Roles_Aggregate;
  /** fetch data from the table: "chat_roles" using primary key columns */
  mobile_chat_roles_by_pk?: Maybe<Mobile_Chat_Roles>;
  /** fetch data from the table in a streaming manner: "chat_roles" */
  mobile_chat_roles_stream: Array<Mobile_Chat_Roles>;
  /** fetch data from the table: "chat_types" */
  mobile_chat_types: Array<Mobile_Chat_Types>;
  /** fetch aggregated fields from the table: "chat_types" */
  mobile_chat_types_aggregate: Mobile_Chat_Types_Aggregate;
  /** fetch data from the table: "chat_types" using primary key columns */
  mobile_chat_types_by_pk?: Maybe<Mobile_Chat_Types>;
  /** fetch data from the table in a streaming manner: "chat_types" */
  mobile_chat_types_stream: Array<Mobile_Chat_Types>;
  /** fetch data from the table: "chats" */
  mobile_chats: Array<Mobile_Chats>;
  /** fetch aggregated fields from the table: "chats" */
  mobile_chats_aggregate: Mobile_Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  mobile_chats_by_pk?: Maybe<Mobile_Chats>;
  /** fetch data from the table in a streaming manner: "chats" */
  mobile_chats_stream: Array<Mobile_Chats>;
  /** fetch data from the table: "clients" */
  mobile_clients: Array<Mobile_Clients>;
  /** fetch aggregated fields from the table: "clients" */
  mobile_clients_aggregate: Mobile_Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  mobile_clients_by_pk?: Maybe<Mobile_Clients>;
  /** fetch data from the table in a streaming manner: "clients" */
  mobile_clients_stream: Array<Mobile_Clients>;
  /** fetch data from the table: "comment_like_notifications" */
  mobile_comment_like_notifications: Array<Mobile_Comment_Like_Notifications>;
  /** fetch aggregated fields from the table: "comment_like_notifications" */
  mobile_comment_like_notifications_aggregate: Mobile_Comment_Like_Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "comment_like_notifications" */
  mobile_comment_like_notifications_stream: Array<Mobile_Comment_Like_Notifications>;
  /** fetch data from the table: "comment_likes" */
  mobile_comment_likes: Array<Mobile_Comment_Likes>;
  /** fetch aggregated fields from the table: "comment_likes" */
  mobile_comment_likes_aggregate: Mobile_Comment_Likes_Aggregate;
  /** fetch data from the table: "comment_likes" using primary key columns */
  mobile_comment_likes_by_pk?: Maybe<Mobile_Comment_Likes>;
  /** fetch data from the table in a streaming manner: "comment_likes" */
  mobile_comment_likes_stream: Array<Mobile_Comment_Likes>;
  /** fetch data from the table: "comment_notifications" */
  mobile_comment_notifications: Array<Mobile_Comment_Notifications>;
  /** fetch aggregated fields from the table: "comment_notifications" */
  mobile_comment_notifications_aggregate: Mobile_Comment_Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "comment_notifications" */
  mobile_comment_notifications_stream: Array<Mobile_Comment_Notifications>;
  /** fetch data from the table: "comments" */
  mobile_comments: Array<Mobile_Comments>;
  /** fetch aggregated fields from the table: "comments" */
  mobile_comments_aggregate: Mobile_Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  mobile_comments_by_pk?: Maybe<Mobile_Comments>;
  /** fetch data from the table in a streaming manner: "comments" */
  mobile_comments_stream: Array<Mobile_Comments>;
  /** fetch data from the table: "confirmation_codes" */
  mobile_confirmation_codes: Array<Mobile_Confirmation_Codes>;
  /** fetch aggregated fields from the table: "confirmation_codes" */
  mobile_confirmation_codes_aggregate: Mobile_Confirmation_Codes_Aggregate;
  /** fetch data from the table: "confirmation_codes" using primary key columns */
  mobile_confirmation_codes_by_pk?: Maybe<Mobile_Confirmation_Codes>;
  /** fetch data from the table in a streaming manner: "confirmation_codes" */
  mobile_confirmation_codes_stream: Array<Mobile_Confirmation_Codes>;
  /** fetch data from the table: "contacts" */
  mobile_contacts: Array<Mobile_Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  mobile_contacts_aggregate: Mobile_Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  mobile_contacts_by_pk?: Maybe<Mobile_Contacts>;
  /** fetch data from the table in a streaming manner: "contacts" */
  mobile_contacts_stream: Array<Mobile_Contacts>;
  /** fetch data from the table: "contents" */
  mobile_contents: Array<Mobile_Contents>;
  /** fetch aggregated fields from the table: "contents" */
  mobile_contents_aggregate: Mobile_Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  mobile_contents_by_pk?: Maybe<Mobile_Contents>;
  /** fetch data from the table in a streaming manner: "contents" */
  mobile_contents_stream: Array<Mobile_Contents>;
  /** fetch data from the table: "device_infos" */
  mobile_device_infos: Array<Mobile_Device_Infos>;
  /** fetch aggregated fields from the table: "device_infos" */
  mobile_device_infos_aggregate: Mobile_Device_Infos_Aggregate;
  /** fetch data from the table: "device_infos" using primary key columns */
  mobile_device_infos_by_pk?: Maybe<Mobile_Device_Infos>;
  /** fetch data from the table in a streaming manner: "device_infos" */
  mobile_device_infos_stream: Array<Mobile_Device_Infos>;
  /** fetch data from the table: "device_settings" */
  mobile_device_settings: Array<Mobile_Device_Settings>;
  /** fetch aggregated fields from the table: "device_settings" */
  mobile_device_settings_aggregate: Mobile_Device_Settings_Aggregate;
  /** fetch data from the table: "device_settings" using primary key columns */
  mobile_device_settings_by_pk?: Maybe<Mobile_Device_Settings>;
  /** fetch data from the table in a streaming manner: "device_settings" */
  mobile_device_settings_stream: Array<Mobile_Device_Settings>;
  /** fetch data from the table: "devices" */
  mobile_devices: Array<Mobile_Devices>;
  /** fetch aggregated fields from the table: "devices" */
  mobile_devices_aggregate: Mobile_Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  mobile_devices_by_pk?: Maybe<Mobile_Devices>;
  /** fetch data from the table in a streaming manner: "devices" */
  mobile_devices_stream: Array<Mobile_Devices>;
  /** fetch data from the table: "document_mediafiles" */
  mobile_document_mediafiles: Array<Mobile_Document_Mediafiles>;
  /** fetch aggregated fields from the table: "document_mediafiles" */
  mobile_document_mediafiles_aggregate: Mobile_Document_Mediafiles_Aggregate;
  /** fetch data from the table: "document_mediafiles" using primary key columns */
  mobile_document_mediafiles_by_pk?: Maybe<Mobile_Document_Mediafiles>;
  /** fetch data from the table in a streaming manner: "document_mediafiles" */
  mobile_document_mediafiles_stream: Array<Mobile_Document_Mediafiles>;
  /** fetch data from the table: "documents" */
  mobile_documents: Array<Mobile_Documents>;
  /** fetch aggregated fields from the table: "documents" */
  mobile_documents_aggregate: Mobile_Documents_Aggregate;
  /** fetch data from the table: "documents" using primary key columns */
  mobile_documents_by_pk?: Maybe<Mobile_Documents>;
  /** fetch data from the table in a streaming manner: "documents" */
  mobile_documents_stream: Array<Mobile_Documents>;
  /** fetch data from the table: "education_mediafiles" */
  mobile_education_mediafiles: Array<Mobile_Education_Mediafiles>;
  /** fetch aggregated fields from the table: "education_mediafiles" */
  mobile_education_mediafiles_aggregate: Mobile_Education_Mediafiles_Aggregate;
  /** fetch data from the table: "education_mediafiles" using primary key columns */
  mobile_education_mediafiles_by_pk?: Maybe<Mobile_Education_Mediafiles>;
  /** fetch data from the table in a streaming manner: "education_mediafiles" */
  mobile_education_mediafiles_stream: Array<Mobile_Education_Mediafiles>;
  /** fetch data from the table: "educations" */
  mobile_educations: Array<Mobile_Educations>;
  /** fetch aggregated fields from the table: "educations" */
  mobile_educations_aggregate: Mobile_Educations_Aggregate;
  /** fetch data from the table: "educations" using primary key columns */
  mobile_educations_by_pk?: Maybe<Mobile_Educations>;
  /** fetch data from the table in a streaming manner: "educations" */
  mobile_educations_stream: Array<Mobile_Educations>;
  /** fetch data from the table: "emojis" */
  mobile_emojis: Array<Mobile_Emojis>;
  /** fetch aggregated fields from the table: "emojis" */
  mobile_emojis_aggregate: Mobile_Emojis_Aggregate;
  /** fetch data from the table: "emojis" using primary key columns */
  mobile_emojis_by_pk?: Maybe<Mobile_Emojis>;
  /** fetch data from the table in a streaming manner: "emojis" */
  mobile_emojis_stream: Array<Mobile_Emojis>;
  /** fetch data from the table: "fixed_chat_messages" */
  mobile_fixed_chat_messages: Array<Mobile_Fixed_Chat_Messages>;
  /** fetch aggregated fields from the table: "fixed_chat_messages" */
  mobile_fixed_chat_messages_aggregate: Mobile_Fixed_Chat_Messages_Aggregate;
  /** fetch data from the table: "fixed_chat_messages" using primary key columns */
  mobile_fixed_chat_messages_by_pk?: Maybe<Mobile_Fixed_Chat_Messages>;
  /** fetch data from the table in a streaming manner: "fixed_chat_messages" */
  mobile_fixed_chat_messages_stream: Array<Mobile_Fixed_Chat_Messages>;
  /** fetch data from the table: "fixed_comments" */
  mobile_fixed_comments: Array<Mobile_Fixed_Comments>;
  /** fetch aggregated fields from the table: "fixed_comments" */
  mobile_fixed_comments_aggregate: Mobile_Fixed_Comments_Aggregate;
  /** fetch data from the table: "fixed_comments" using primary key columns */
  mobile_fixed_comments_by_pk?: Maybe<Mobile_Fixed_Comments>;
  /** fetch data from the table in a streaming manner: "fixed_comments" */
  mobile_fixed_comments_stream: Array<Mobile_Fixed_Comments>;
  /** fetch data from the table: "founder_mediafiles" */
  mobile_founder_mediafiles: Array<Mobile_Founder_Mediafiles>;
  /** fetch aggregated fields from the table: "founder_mediafiles" */
  mobile_founder_mediafiles_aggregate: Mobile_Founder_Mediafiles_Aggregate;
  /** fetch data from the table: "founder_mediafiles" using primary key columns */
  mobile_founder_mediafiles_by_pk?: Maybe<Mobile_Founder_Mediafiles>;
  /** fetch data from the table in a streaming manner: "founder_mediafiles" */
  mobile_founder_mediafiles_stream: Array<Mobile_Founder_Mediafiles>;
  /** fetch data from the table: "founders" */
  mobile_founders: Array<Mobile_Founders>;
  /** fetch aggregated fields from the table: "founders" */
  mobile_founders_aggregate: Mobile_Founders_Aggregate;
  /** fetch data from the table: "founders" using primary key columns */
  mobile_founders_by_pk?: Maybe<Mobile_Founders>;
  /** fetch data from the table in a streaming manner: "founders" */
  mobile_founders_stream: Array<Mobile_Founders>;
  /** fetch data from the table: "invite_emails" */
  mobile_invite_emails: Array<Mobile_Invite_Emails>;
  /** fetch aggregated fields from the table: "invite_emails" */
  mobile_invite_emails_aggregate: Mobile_Invite_Emails_Aggregate;
  /** fetch data from the table: "invite_emails" using primary key columns */
  mobile_invite_emails_by_pk?: Maybe<Mobile_Invite_Emails>;
  /** fetch data from the table in a streaming manner: "invite_emails" */
  mobile_invite_emails_stream: Array<Mobile_Invite_Emails>;
  /** fetch data from the table: "like_notifications" */
  mobile_like_notifications: Array<Mobile_Like_Notifications>;
  /** fetch aggregated fields from the table: "like_notifications" */
  mobile_like_notifications_aggregate: Mobile_Like_Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "like_notifications" */
  mobile_like_notifications_stream: Array<Mobile_Like_Notifications>;
  /** fetch data from the table: "likes" */
  mobile_likes: Array<Mobile_Likes>;
  /** fetch aggregated fields from the table: "likes" */
  mobile_likes_aggregate: Mobile_Likes_Aggregate;
  /** fetch data from the table: "likes" using primary key columns */
  mobile_likes_by_pk?: Maybe<Mobile_Likes>;
  /** fetch data from the table in a streaming manner: "likes" */
  mobile_likes_stream: Array<Mobile_Likes>;
  /** fetch data from the table: "link_snippets" */
  mobile_link_snippets: Array<Mobile_Link_Snippets>;
  /** fetch aggregated fields from the table: "link_snippets" */
  mobile_link_snippets_aggregate: Mobile_Link_Snippets_Aggregate;
  /** fetch data from the table: "link_snippets" using primary key columns */
  mobile_link_snippets_by_pk?: Maybe<Mobile_Link_Snippets>;
  /** fetch data from the table in a streaming manner: "link_snippets" */
  mobile_link_snippets_stream: Array<Mobile_Link_Snippets>;
  /** fetch data from the table: "locked_deleted_chat_messages" */
  mobile_locked_deleted_chat_messages: Array<Mobile_Locked_Deleted_Chat_Messages>;
  /** fetch aggregated fields from the table: "locked_deleted_chat_messages" */
  mobile_locked_deleted_chat_messages_aggregate: Mobile_Locked_Deleted_Chat_Messages_Aggregate;
  /** fetch data from the table in a streaming manner: "locked_deleted_chat_messages" */
  mobile_locked_deleted_chat_messages_stream: Array<Mobile_Locked_Deleted_Chat_Messages>;
  /** fetch data from the table: "mediafile_status_names" */
  mobile_mediafile_status_names: Array<Mobile_Mediafile_Status_Names>;
  /** fetch aggregated fields from the table: "mediafile_status_names" */
  mobile_mediafile_status_names_aggregate: Mobile_Mediafile_Status_Names_Aggregate;
  /** fetch data from the table: "mediafile_status_names" using primary key columns */
  mobile_mediafile_status_names_by_pk?: Maybe<Mobile_Mediafile_Status_Names>;
  /** fetch data from the table in a streaming manner: "mediafile_status_names" */
  mobile_mediafile_status_names_stream: Array<Mobile_Mediafile_Status_Names>;
  /** fetch data from the table: "mediafile_statuses" */
  mobile_mediafile_statuses: Array<Mobile_Mediafile_Statuses>;
  /** fetch aggregated fields from the table: "mediafile_statuses" */
  mobile_mediafile_statuses_aggregate: Mobile_Mediafile_Statuses_Aggregate;
  /** fetch data from the table: "mediafile_statuses" using primary key columns */
  mobile_mediafile_statuses_by_pk?: Maybe<Mobile_Mediafile_Statuses>;
  /** fetch data from the table in a streaming manner: "mediafile_statuses" */
  mobile_mediafile_statuses_stream: Array<Mobile_Mediafile_Statuses>;
  /** fetch data from the table: "medical_concierge_orders" */
  mobile_medical_concierge_orders: Array<Mobile_Medical_Concierge_Orders>;
  /** fetch aggregated fields from the table: "medical_concierge_orders" */
  mobile_medical_concierge_orders_aggregate: Mobile_Medical_Concierge_Orders_Aggregate;
  /** fetch data from the table: "medical_concierge_orders" using primary key columns */
  mobile_medical_concierge_orders_by_pk?: Maybe<Mobile_Medical_Concierge_Orders>;
  /** fetch data from the table in a streaming manner: "medical_concierge_orders" */
  mobile_medical_concierge_orders_stream: Array<Mobile_Medical_Concierge_Orders>;
  /** fetch data from the table: "medical_concierge_products" */
  mobile_medical_concierge_products: Array<Mobile_Medical_Concierge_Products>;
  /** fetch aggregated fields from the table: "medical_concierge_products" */
  mobile_medical_concierge_products_aggregate: Mobile_Medical_Concierge_Products_Aggregate;
  /** fetch data from the table: "medical_concierge_products" using primary key columns */
  mobile_medical_concierge_products_by_pk?: Maybe<Mobile_Medical_Concierge_Products>;
  /** fetch data from the table in a streaming manner: "medical_concierge_products" */
  mobile_medical_concierge_products_stream: Array<Mobile_Medical_Concierge_Products>;
  /** fetch data from the table: "mention_notifications" */
  mobile_mention_notifications: Array<Mobile_Mention_Notifications>;
  /** fetch aggregated fields from the table: "mention_notifications" */
  mobile_mention_notifications_aggregate: Mobile_Mention_Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "mention_notifications" */
  mobile_mention_notifications_stream: Array<Mobile_Mention_Notifications>;
  /** fetch data from the table: "mentions" */
  mobile_mentions: Array<Mobile_Mentions>;
  /** fetch aggregated fields from the table: "mentions" */
  mobile_mentions_aggregate: Mobile_Mentions_Aggregate;
  /** fetch data from the table: "mentions" using primary key columns */
  mobile_mentions_by_pk?: Maybe<Mobile_Mentions>;
  /** fetch data from the table in a streaming manner: "mentions" */
  mobile_mentions_stream: Array<Mobile_Mentions>;
  /** fetch data from the table: "metric_names" */
  mobile_metric_names: Array<Mobile_Metric_Names>;
  /** fetch aggregated fields from the table: "metric_names" */
  mobile_metric_names_aggregate: Mobile_Metric_Names_Aggregate;
  /** fetch data from the table: "metric_names" using primary key columns */
  mobile_metric_names_by_pk?: Maybe<Mobile_Metric_Names>;
  /** fetch data from the table in a streaming manner: "metric_names" */
  mobile_metric_names_stream: Array<Mobile_Metric_Names>;
  /** fetch data from the table: "metric_params" */
  mobile_metric_params: Array<Mobile_Metric_Params>;
  /** fetch aggregated fields from the table: "metric_params" */
  mobile_metric_params_aggregate: Mobile_Metric_Params_Aggregate;
  /** fetch data from the table: "metric_params" using primary key columns */
  mobile_metric_params_by_pk?: Maybe<Mobile_Metric_Params>;
  /** fetch data from the table in a streaming manner: "metric_params" */
  mobile_metric_params_stream: Array<Mobile_Metric_Params>;
  /** fetch data from the table: "metric_types" */
  mobile_metric_types: Array<Mobile_Metric_Types>;
  /** fetch aggregated fields from the table: "metric_types" */
  mobile_metric_types_aggregate: Mobile_Metric_Types_Aggregate;
  /** fetch data from the table: "metric_types" using primary key columns */
  mobile_metric_types_by_pk?: Maybe<Mobile_Metric_Types>;
  /** fetch data from the table in a streaming manner: "metric_types" */
  mobile_metric_types_stream: Array<Mobile_Metric_Types>;
  /** fetch data from the table: "metrics" */
  mobile_metrics: Array<Mobile_Metrics>;
  /** fetch aggregated fields from the table: "metrics" */
  mobile_metrics_aggregate: Mobile_Metrics_Aggregate;
  /** fetch data from the table: "metrics" using primary key columns */
  mobile_metrics_by_pk?: Maybe<Mobile_Metrics>;
  /** fetch data from the table in a streaming manner: "metrics" */
  mobile_metrics_stream: Array<Mobile_Metrics>;
  /** fetch data from the table: "notification_messages" */
  mobile_notification_messages: Array<Mobile_Notification_Messages>;
  /** fetch aggregated fields from the table: "notification_messages" */
  mobile_notification_messages_aggregate: Mobile_Notification_Messages_Aggregate;
  /** fetch data from the table: "notification_messages" using primary key columns */
  mobile_notification_messages_by_pk?: Maybe<Mobile_Notification_Messages>;
  /** fetch data from the table in a streaming manner: "notification_messages" */
  mobile_notification_messages_stream: Array<Mobile_Notification_Messages>;
  /** fetch data from the table: "notifications" */
  mobile_notifications: Array<Mobile_Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  mobile_notifications_aggregate: Mobile_Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  mobile_notifications_by_pk?: Maybe<Mobile_Notifications>;
  /** fetch data from the table: "notifications_statuses" */
  mobile_notifications_statuses: Array<Mobile_Notifications_Statuses>;
  /** fetch aggregated fields from the table: "notifications_statuses" */
  mobile_notifications_statuses_aggregate: Mobile_Notifications_Statuses_Aggregate;
  /** fetch data from the table: "notifications_statuses" using primary key columns */
  mobile_notifications_statuses_by_pk?: Maybe<Mobile_Notifications_Statuses>;
  /** fetch data from the table in a streaming manner: "notifications_statuses" */
  mobile_notifications_statuses_stream: Array<Mobile_Notifications_Statuses>;
  /** fetch data from the table in a streaming manner: "notifications" */
  mobile_notifications_stream: Array<Mobile_Notifications>;
  /** fetch data from the table: "order_notifications" */
  mobile_order_notifications: Array<Mobile_Order_Notifications>;
  /** fetch aggregated fields from the table: "order_notifications" */
  mobile_order_notifications_aggregate: Mobile_Order_Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "order_notifications" */
  mobile_order_notifications_stream: Array<Mobile_Order_Notifications>;
  /** fetch data from the table: "order_professionals" */
  mobile_order_professionals: Array<Mobile_Order_Professionals>;
  /** fetch aggregated fields from the table: "order_professionals" */
  mobile_order_professionals_aggregate: Mobile_Order_Professionals_Aggregate;
  /** fetch data from the table: "order_professionals" using primary key columns */
  mobile_order_professionals_by_pk?: Maybe<Mobile_Order_Professionals>;
  /** fetch data from the table in a streaming manner: "order_professionals" */
  mobile_order_professionals_stream: Array<Mobile_Order_Professionals>;
  /** fetch data from the table: "orders" */
  mobile_orders: Array<Mobile_Orders>;
  /** fetch aggregated fields from the table: "orders" */
  mobile_orders_aggregate: Mobile_Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  mobile_orders_by_pk?: Maybe<Mobile_Orders>;
  /** fetch data from the table in a streaming manner: "orders" */
  mobile_orders_stream: Array<Mobile_Orders>;
  /** fetch data from the table: "orgs" */
  mobile_orgs: Array<Mobile_Orgs>;
  /** fetch aggregated fields from the table: "orgs" */
  mobile_orgs_aggregate: Mobile_Orgs_Aggregate;
  /** fetch data from the table: "orgs" using primary key columns */
  mobile_orgs_by_pk?: Maybe<Mobile_Orgs>;
  /** fetch data from the table in a streaming manner: "orgs" */
  mobile_orgs_stream: Array<Mobile_Orgs>;
  /** fetch data from the table: "param_categories" */
  mobile_param_categories: Array<Mobile_Param_Categories>;
  /** fetch aggregated fields from the table: "param_categories" */
  mobile_param_categories_aggregate: Mobile_Param_Categories_Aggregate;
  /** fetch data from the table: "param_categories" using primary key columns */
  mobile_param_categories_by_pk?: Maybe<Mobile_Param_Categories>;
  /** fetch data from the table: "param_categories_params" */
  mobile_param_categories_params: Array<Mobile_Param_Categories_Params>;
  /** fetch aggregated fields from the table: "param_categories_params" */
  mobile_param_categories_params_aggregate: Mobile_Param_Categories_Params_Aggregate;
  /** fetch data from the table in a streaming manner: "param_categories_params" */
  mobile_param_categories_params_stream: Array<Mobile_Param_Categories_Params>;
  /** fetch data from the table in a streaming manner: "param_categories" */
  mobile_param_categories_stream: Array<Mobile_Param_Categories>;
  /** fetch data from the table: "param_category_names" */
  mobile_param_category_names: Array<Mobile_Param_Category_Names>;
  /** fetch aggregated fields from the table: "param_category_names" */
  mobile_param_category_names_aggregate: Mobile_Param_Category_Names_Aggregate;
  /** fetch data from the table: "param_category_names" using primary key columns */
  mobile_param_category_names_by_pk?: Maybe<Mobile_Param_Category_Names>;
  /** fetch data from the table in a streaming manner: "param_category_names" */
  mobile_param_category_names_stream: Array<Mobile_Param_Category_Names>;
  /** fetch data from the table: "param_names" */
  mobile_param_names: Array<Mobile_Param_Names>;
  /** fetch aggregated fields from the table: "param_names" */
  mobile_param_names_aggregate: Mobile_Param_Names_Aggregate;
  /** fetch data from the table: "param_names" using primary key columns */
  mobile_param_names_by_pk?: Maybe<Mobile_Param_Names>;
  /** fetch data from the table in a streaming manner: "param_names" */
  mobile_param_names_stream: Array<Mobile_Param_Names>;
  /** fetch data from the table: "params" */
  mobile_params: Array<Mobile_Params>;
  /** fetch aggregated fields from the table: "params" */
  mobile_params_aggregate: Mobile_Params_Aggregate;
  /** fetch data from the table: "params" using primary key columns */
  mobile_params_by_pk?: Maybe<Mobile_Params>;
  /** fetch data from the table: "params_services" */
  mobile_params_services: Array<Mobile_Params_Services>;
  /** fetch aggregated fields from the table: "params_services" */
  mobile_params_services_aggregate: Mobile_Params_Services_Aggregate;
  /** fetch data from the table: "params_services" using primary key columns */
  mobile_params_services_by_pk?: Maybe<Mobile_Params_Services>;
  /** fetch data from the table in a streaming manner: "params_services" */
  mobile_params_services_stream: Array<Mobile_Params_Services>;
  /** fetch data from the table in a streaming manner: "params" */
  mobile_params_stream: Array<Mobile_Params>;
  /** fetch data from the table: "personal_info_views" */
  mobile_personal_info_views: Array<Mobile_Personal_Info_Views>;
  /** fetch aggregated fields from the table: "personal_info_views" */
  mobile_personal_info_views_aggregate: Mobile_Personal_Info_Views_Aggregate;
  /** fetch data from the table: "personal_info_views" using primary key columns */
  mobile_personal_info_views_by_pk?: Maybe<Mobile_Personal_Info_Views>;
  /** fetch data from the table in a streaming manner: "personal_info_views" */
  mobile_personal_info_views_stream: Array<Mobile_Personal_Info_Views>;
  /** fetch data from the table: "personal_infos" */
  mobile_personal_infos: Array<Mobile_Personal_Infos>;
  /** fetch aggregated fields from the table: "personal_infos" */
  mobile_personal_infos_aggregate: Mobile_Personal_Infos_Aggregate;
  /** fetch data from the table: "personal_infos" using primary key columns */
  mobile_personal_infos_by_pk?: Maybe<Mobile_Personal_Infos>;
  /** fetch data from the table in a streaming manner: "personal_infos" */
  mobile_personal_infos_stream: Array<Mobile_Personal_Infos>;
  /** fetch data from the table: "phones" */
  mobile_phones: Array<Mobile_Phones>;
  /** fetch aggregated fields from the table: "phones" */
  mobile_phones_aggregate: Mobile_Phones_Aggregate;
  /** fetch data from the table: "phones" using primary key columns */
  mobile_phones_by_pk?: Maybe<Mobile_Phones>;
  /** fetch data from the table in a streaming manner: "phones" */
  mobile_phones_stream: Array<Mobile_Phones>;
  /** fetch data from the table: "post_params" */
  mobile_post_params: Array<Mobile_Post_Params>;
  /** fetch aggregated fields from the table: "post_params" */
  mobile_post_params_aggregate: Mobile_Post_Params_Aggregate;
  /** fetch data from the table: "post_params" using primary key columns */
  mobile_post_params_by_pk?: Maybe<Mobile_Post_Params>;
  /** fetch data from the table in a streaming manner: "post_params" */
  mobile_post_params_stream: Array<Mobile_Post_Params>;
  /** fetch data from the table: "posts" */
  mobile_posts: Array<Mobile_Posts>;
  /** fetch aggregated fields from the table: "posts" */
  mobile_posts_aggregate: Mobile_Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  mobile_posts_by_pk?: Maybe<Mobile_Posts>;
  /** fetch data from the table: "posts_services" */
  mobile_posts_services: Array<Mobile_Posts_Services>;
  /** fetch aggregated fields from the table: "posts_services" */
  mobile_posts_services_aggregate: Mobile_Posts_Services_Aggregate;
  /** fetch data from the table in a streaming manner: "posts_services" */
  mobile_posts_services_stream: Array<Mobile_Posts_Services>;
  /** fetch data from the table in a streaming manner: "posts" */
  mobile_posts_stream: Array<Mobile_Posts>;
  /** fetch data from the table: "pro_document_statuses" */
  mobile_pro_document_statuses: Array<Mobile_Pro_Document_Statuses>;
  /** fetch aggregated fields from the table: "pro_document_statuses" */
  mobile_pro_document_statuses_aggregate: Mobile_Pro_Document_Statuses_Aggregate;
  /** fetch data from the table: "pro_document_statuses" using primary key columns */
  mobile_pro_document_statuses_by_pk?: Maybe<Mobile_Pro_Document_Statuses>;
  /** fetch data from the table in a streaming manner: "pro_document_statuses" */
  mobile_pro_document_statuses_stream: Array<Mobile_Pro_Document_Statuses>;
  /** fetch data from the table: "pro_professions" */
  mobile_pro_professions: Array<Mobile_Pro_Professions>;
  /** fetch aggregated fields from the table: "pro_professions" */
  mobile_pro_professions_aggregate: Mobile_Pro_Professions_Aggregate;
  /** fetch data from the table: "pro_professions" using primary key columns */
  mobile_pro_professions_by_pk?: Maybe<Mobile_Pro_Professions>;
  /** fetch data from the table in a streaming manner: "pro_professions" */
  mobile_pro_professions_stream: Array<Mobile_Pro_Professions>;
  /** fetch data from the table: "pro_types" */
  mobile_pro_types: Array<Mobile_Pro_Types>;
  /** fetch aggregated fields from the table: "pro_types" */
  mobile_pro_types_aggregate: Mobile_Pro_Types_Aggregate;
  /** fetch data from the table: "pro_types" using primary key columns */
  mobile_pro_types_by_pk?: Maybe<Mobile_Pro_Types>;
  /** fetch data from the table in a streaming manner: "pro_types" */
  mobile_pro_types_stream: Array<Mobile_Pro_Types>;
  /** fetch data from the table: "problems" */
  mobile_problems: Array<Mobile_Problems>;
  /** fetch aggregated fields from the table: "problems" */
  mobile_problems_aggregate: Mobile_Problems_Aggregate;
  /** fetch data from the table: "problems" using primary key columns */
  mobile_problems_by_pk?: Maybe<Mobile_Problems>;
  /** fetch data from the table in a streaming manner: "problems" */
  mobile_problems_stream: Array<Mobile_Problems>;
  /** fetch data from the table: "profession_names" */
  mobile_profession_names: Array<Mobile_Profession_Names>;
  /** fetch aggregated fields from the table: "profession_names" */
  mobile_profession_names_aggregate: Mobile_Profession_Names_Aggregate;
  /** fetch data from the table: "profession_names" using primary key columns */
  mobile_profession_names_by_pk?: Maybe<Mobile_Profession_Names>;
  /** fetch data from the table in a streaming manner: "profession_names" */
  mobile_profession_names_stream: Array<Mobile_Profession_Names>;
  /** fetch data from the table: "professions" */
  mobile_professions: Array<Mobile_Professions>;
  /** fetch aggregated fields from the table: "professions" */
  mobile_professions_aggregate: Mobile_Professions_Aggregate;
  /** fetch data from the table: "professions" using primary key columns */
  mobile_professions_by_pk?: Maybe<Mobile_Professions>;
  /** fetch data from the table in a streaming manner: "professions" */
  mobile_professions_stream: Array<Mobile_Professions>;
  /** fetch data from the table: "profile_deleteds" */
  mobile_profile_deleteds: Array<Mobile_Profile_Deleteds>;
  /** fetch aggregated fields from the table: "profile_deleteds" */
  mobile_profile_deleteds_aggregate: Mobile_Profile_Deleteds_Aggregate;
  /** fetch data from the table: "profile_deleteds" using primary key columns */
  mobile_profile_deleteds_by_pk?: Maybe<Mobile_Profile_Deleteds>;
  /** fetch data from the table in a streaming manner: "profile_deleteds" */
  mobile_profile_deleteds_stream: Array<Mobile_Profile_Deleteds>;
  /** fetch data from the table: "profile_locks" */
  mobile_profile_locks: Array<Mobile_Profile_Locks>;
  /** fetch aggregated fields from the table: "profile_locks" */
  mobile_profile_locks_aggregate: Mobile_Profile_Locks_Aggregate;
  /** fetch data from the table: "profile_locks" using primary key columns */
  mobile_profile_locks_by_pk?: Maybe<Mobile_Profile_Locks>;
  /** fetch data from the table in a streaming manner: "profile_locks" */
  mobile_profile_locks_stream: Array<Mobile_Profile_Locks>;
  /** fetch data from the table: "profiles" */
  mobile_profiles: Array<Mobile_Profiles>;
  /** fetch aggregated fields from the table: "profiles" */
  mobile_profiles_aggregate: Mobile_Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  mobile_profiles_by_pk?: Maybe<Mobile_Profiles>;
  /** fetch data from the table in a streaming manner: "profiles" */
  mobile_profiles_stream: Array<Mobile_Profiles>;
  /** fetch data from the table: "pros" */
  mobile_pros: Array<Mobile_Pros>;
  /** fetch aggregated fields from the table: "pros" */
  mobile_pros_aggregate: Mobile_Pros_Aggregate;
  /** fetch data from the table: "pros" using primary key columns */
  mobile_pros_by_pk?: Maybe<Mobile_Pros>;
  /** fetch data from the table in a streaming manner: "pros" */
  mobile_pros_stream: Array<Mobile_Pros>;
  /** fetch data from the table: "pulse_norms" */
  mobile_pulse_norms: Array<Mobile_Pulse_Norms>;
  /** fetch aggregated fields from the table: "pulse_norms" */
  mobile_pulse_norms_aggregate: Mobile_Pulse_Norms_Aggregate;
  /** fetch data from the table: "pulse_norms" using primary key columns */
  mobile_pulse_norms_by_pk?: Maybe<Mobile_Pulse_Norms>;
  /** fetch data from the table in a streaming manner: "pulse_norms" */
  mobile_pulse_norms_stream: Array<Mobile_Pulse_Norms>;
  /** fetch data from the table: "pulse_nows" */
  mobile_pulse_nows: Array<Mobile_Pulse_Nows>;
  /** fetch aggregated fields from the table: "pulse_nows" */
  mobile_pulse_nows_aggregate: Mobile_Pulse_Nows_Aggregate;
  /** fetch data from the table: "pulse_nows" using primary key columns */
  mobile_pulse_nows_by_pk?: Maybe<Mobile_Pulse_Nows>;
  /** fetch data from the table in a streaming manner: "pulse_nows" */
  mobile_pulse_nows_stream: Array<Mobile_Pulse_Nows>;
  /** fetch data from the table: "pulses" */
  mobile_pulses: Array<Mobile_Pulses>;
  /** fetch aggregated fields from the table: "pulses" */
  mobile_pulses_aggregate: Mobile_Pulses_Aggregate;
  /** fetch data from the table: "pulses" using primary key columns */
  mobile_pulses_by_pk?: Maybe<Mobile_Pulses>;
  /** fetch data from the table in a streaming manner: "pulses" */
  mobile_pulses_stream: Array<Mobile_Pulses>;
  /** fetch data from the table: "reactions" */
  mobile_reactions: Array<Mobile_Reactions>;
  /** fetch aggregated fields from the table: "reactions" */
  mobile_reactions_aggregate: Mobile_Reactions_Aggregate;
  /** fetch data from the table: "reactions" using primary key columns */
  mobile_reactions_by_pk?: Maybe<Mobile_Reactions>;
  /** fetch data from the table in a streaming manner: "reactions" */
  mobile_reactions_stream: Array<Mobile_Reactions>;
  /** fetch data from the table: "reports" */
  mobile_reports: Array<Mobile_Reports>;
  /** fetch aggregated fields from the table: "reports" */
  mobile_reports_aggregate: Mobile_Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  mobile_reports_by_pk?: Maybe<Mobile_Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  mobile_reports_stream: Array<Mobile_Reports>;
  /** execute function "mobile_search_posts_find_title_message" which returns "posts" */
  mobile_search_posts_find_title_message: Array<Mobile_Posts>;
  /** execute function "mobile_search_posts_find_title_message" and query aggregates on result of table type "posts" */
  mobile_search_posts_find_title_message_aggregate: Mobile_Posts_Aggregate;
  /** execute function "mobile_search_profiles_find_first_last_username_aboutmyself" which returns "profiles" */
  mobile_search_profiles_find_first_last_username_aboutmyself: Array<Mobile_Profiles>;
  /** execute function "mobile_search_profiles_find_first_last_username_aboutmyself" and query aggregates on result of table type "profiles" */
  mobile_search_profiles_find_first_last_username_aboutmyself_aggregate: Mobile_Profiles_Aggregate;
  /** fetch data from the table: "service_agreements" */
  mobile_service_agreements: Array<Mobile_Service_Agreements>;
  /** fetch aggregated fields from the table: "service_agreements" */
  mobile_service_agreements_aggregate: Mobile_Service_Agreements_Aggregate;
  /** fetch data from the table: "service_agreements" using primary key columns */
  mobile_service_agreements_by_pk?: Maybe<Mobile_Service_Agreements>;
  /** fetch data from the table in a streaming manner: "service_agreements" */
  mobile_service_agreements_stream: Array<Mobile_Service_Agreements>;
  /** fetch data from the table: "service_answer_reactions" */
  mobile_service_answer_reactions: Array<Mobile_Service_Answer_Reactions>;
  /** fetch aggregated fields from the table: "service_answer_reactions" */
  mobile_service_answer_reactions_aggregate: Mobile_Service_Answer_Reactions_Aggregate;
  /** fetch data from the table: "service_answer_reactions" using primary key columns */
  mobile_service_answer_reactions_by_pk?: Maybe<Mobile_Service_Answer_Reactions>;
  /** fetch data from the table in a streaming manner: "service_answer_reactions" */
  mobile_service_answer_reactions_stream: Array<Mobile_Service_Answer_Reactions>;
  /** fetch data from the table: "service_answers" */
  mobile_service_answers: Array<Mobile_Service_Answers>;
  /** fetch aggregated fields from the table: "service_answers" */
  mobile_service_answers_aggregate: Mobile_Service_Answers_Aggregate;
  /** fetch data from the table: "service_answers" using primary key columns */
  mobile_service_answers_by_pk?: Maybe<Mobile_Service_Answers>;
  /** fetch data from the table in a streaming manner: "service_answers" */
  mobile_service_answers_stream: Array<Mobile_Service_Answers>;
  /** fetch data from the table: "service_documents" */
  mobile_service_documents: Array<Mobile_Service_Documents>;
  /** fetch aggregated fields from the table: "service_documents" */
  mobile_service_documents_aggregate: Mobile_Service_Documents_Aggregate;
  /** fetch data from the table: "service_documents" using primary key columns */
  mobile_service_documents_by_pk?: Maybe<Mobile_Service_Documents>;
  /** fetch data from the table in a streaming manner: "service_documents" */
  mobile_service_documents_stream: Array<Mobile_Service_Documents>;
  /** fetch data from the table: "service_favorites" */
  mobile_service_favorites: Array<Mobile_Service_Favorites>;
  /** fetch aggregated fields from the table: "service_favorites" */
  mobile_service_favorites_aggregate: Mobile_Service_Favorites_Aggregate;
  /** fetch data from the table: "service_favorites" using primary key columns */
  mobile_service_favorites_by_pk?: Maybe<Mobile_Service_Favorites>;
  /** fetch data from the table in a streaming manner: "service_favorites" */
  mobile_service_favorites_stream: Array<Mobile_Service_Favorites>;
  /** fetch data from the table: "service_free_purchases" */
  mobile_service_free_purchases: Array<Mobile_Service_Free_Purchases>;
  /** fetch aggregated fields from the table: "service_free_purchases" */
  mobile_service_free_purchases_aggregate: Mobile_Service_Free_Purchases_Aggregate;
  /** fetch data from the table: "service_free_purchases" using primary key columns */
  mobile_service_free_purchases_by_pk?: Maybe<Mobile_Service_Free_Purchases>;
  /** fetch data from the table in a streaming manner: "service_free_purchases" */
  mobile_service_free_purchases_stream: Array<Mobile_Service_Free_Purchases>;
  /** fetch data from the table: "service_images" */
  mobile_service_images: Array<Mobile_Service_Images>;
  /** fetch aggregated fields from the table: "service_images" */
  mobile_service_images_aggregate: Mobile_Service_Images_Aggregate;
  /** fetch data from the table: "service_images" using primary key columns */
  mobile_service_images_by_pk?: Maybe<Mobile_Service_Images>;
  /** fetch data from the table in a streaming manner: "service_images" */
  mobile_service_images_stream: Array<Mobile_Service_Images>;
  /** fetch data from the table: "service_likes" */
  mobile_service_likes: Array<Mobile_Service_Likes>;
  /** fetch aggregated fields from the table: "service_likes" */
  mobile_service_likes_aggregate: Mobile_Service_Likes_Aggregate;
  /** fetch data from the table: "service_likes" using primary key columns */
  mobile_service_likes_by_pk?: Maybe<Mobile_Service_Likes>;
  /** fetch data from the table in a streaming manner: "service_likes" */
  mobile_service_likes_stream: Array<Mobile_Service_Likes>;
  /** fetch data from the table: "service_list_documents" */
  mobile_service_list_documents: Array<Mobile_Service_List_Documents>;
  /** fetch aggregated fields from the table: "service_list_documents" */
  mobile_service_list_documents_aggregate: Mobile_Service_List_Documents_Aggregate;
  /** fetch data from the table: "service_list_documents" using primary key columns */
  mobile_service_list_documents_by_pk?: Maybe<Mobile_Service_List_Documents>;
  /** fetch data from the table in a streaming manner: "service_list_documents" */
  mobile_service_list_documents_stream: Array<Mobile_Service_List_Documents>;
  /** fetch data from the table: "service_list_periodicities" */
  mobile_service_list_periodicities: Array<Mobile_Service_List_Periodicities>;
  /** fetch aggregated fields from the table: "service_list_periodicities" */
  mobile_service_list_periodicities_aggregate: Mobile_Service_List_Periodicities_Aggregate;
  /** fetch data from the table: "service_list_periodicities" using primary key columns */
  mobile_service_list_periodicities_by_pk?: Maybe<Mobile_Service_List_Periodicities>;
  /** fetch data from the table in a streaming manner: "service_list_periodicities" */
  mobile_service_list_periodicities_stream: Array<Mobile_Service_List_Periodicities>;
  /** fetch data from the table: "service_lists" */
  mobile_service_lists: Array<Mobile_Service_Lists>;
  /** fetch aggregated fields from the table: "service_lists" */
  mobile_service_lists_aggregate: Mobile_Service_Lists_Aggregate;
  /** fetch data from the table: "service_lists" using primary key columns */
  mobile_service_lists_by_pk?: Maybe<Mobile_Service_Lists>;
  /** fetch data from the table in a streaming manner: "service_lists" */
  mobile_service_lists_stream: Array<Mobile_Service_Lists>;
  /** fetch data from the table: "service_orders" */
  mobile_service_orders: Array<Mobile_Service_Orders>;
  /** fetch aggregated fields from the table: "service_orders" */
  mobile_service_orders_aggregate: Mobile_Service_Orders_Aggregate;
  /** fetch data from the table: "service_orders" using primary key columns */
  mobile_service_orders_by_pk?: Maybe<Mobile_Service_Orders>;
  /** fetch data from the table in a streaming manner: "service_orders" */
  mobile_service_orders_stream: Array<Mobile_Service_Orders>;
  /** fetch data from the table: "service_periodicities" */
  mobile_service_periodicities: Array<Mobile_Service_Periodicities>;
  /** fetch aggregated fields from the table: "service_periodicities" */
  mobile_service_periodicities_aggregate: Mobile_Service_Periodicities_Aggregate;
  /** fetch data from the table: "service_periodicities" using primary key columns */
  mobile_service_periodicities_by_pk?: Maybe<Mobile_Service_Periodicities>;
  /** fetch data from the table in a streaming manner: "service_periodicities" */
  mobile_service_periodicities_stream: Array<Mobile_Service_Periodicities>;
  /** fetch data from the table: "service_purchases" */
  mobile_service_purchases: Array<Mobile_Service_Purchases>;
  /** fetch aggregated fields from the table: "service_purchases" */
  mobile_service_purchases_aggregate: Mobile_Service_Purchases_Aggregate;
  /** fetch data from the table: "service_purchases" using primary key columns */
  mobile_service_purchases_by_pk?: Maybe<Mobile_Service_Purchases>;
  /** fetch data from the table in a streaming manner: "service_purchases" */
  mobile_service_purchases_stream: Array<Mobile_Service_Purchases>;
  /** fetch data from the table: "service_questions" */
  mobile_service_questions: Array<Mobile_Service_Questions>;
  /** fetch aggregated fields from the table: "service_questions" */
  mobile_service_questions_aggregate: Mobile_Service_Questions_Aggregate;
  /** fetch data from the table: "service_questions" using primary key columns */
  mobile_service_questions_by_pk?: Maybe<Mobile_Service_Questions>;
  /** fetch data from the table in a streaming manner: "service_questions" */
  mobile_service_questions_stream: Array<Mobile_Service_Questions>;
  /** fetch data from the table: "service_rate_comments" */
  mobile_service_rate_comments: Array<Mobile_Service_Rate_Comments>;
  /** fetch aggregated fields from the table: "service_rate_comments" */
  mobile_service_rate_comments_aggregate: Mobile_Service_Rate_Comments_Aggregate;
  /** fetch data from the table: "service_rate_comments" using primary key columns */
  mobile_service_rate_comments_by_pk?: Maybe<Mobile_Service_Rate_Comments>;
  /** fetch data from the table in a streaming manner: "service_rate_comments" */
  mobile_service_rate_comments_stream: Array<Mobile_Service_Rate_Comments>;
  /** fetch data from the table: "service_rate_images" */
  mobile_service_rate_images: Array<Mobile_Service_Rate_Images>;
  /** fetch aggregated fields from the table: "service_rate_images" */
  mobile_service_rate_images_aggregate: Mobile_Service_Rate_Images_Aggregate;
  /** fetch data from the table: "service_rate_images" using primary key columns */
  mobile_service_rate_images_by_pk?: Maybe<Mobile_Service_Rate_Images>;
  /** fetch data from the table in a streaming manner: "service_rate_images" */
  mobile_service_rate_images_stream: Array<Mobile_Service_Rate_Images>;
  /** fetch data from the table: "service_rate_reactions" */
  mobile_service_rate_reactions: Array<Mobile_Service_Rate_Reactions>;
  /** fetch aggregated fields from the table: "service_rate_reactions" */
  mobile_service_rate_reactions_aggregate: Mobile_Service_Rate_Reactions_Aggregate;
  /** fetch data from the table: "service_rate_reactions" using primary key columns */
  mobile_service_rate_reactions_by_pk?: Maybe<Mobile_Service_Rate_Reactions>;
  /** fetch data from the table in a streaming manner: "service_rate_reactions" */
  mobile_service_rate_reactions_stream: Array<Mobile_Service_Rate_Reactions>;
  /** fetch data from the table: "service_rates" */
  mobile_service_rates: Array<Mobile_Service_Rates>;
  /** fetch aggregated fields from the table: "service_rates" */
  mobile_service_rates_aggregate: Mobile_Service_Rates_Aggregate;
  /** fetch data from the table: "service_rates" using primary key columns */
  mobile_service_rates_by_pk?: Maybe<Mobile_Service_Rates>;
  /** fetch data from the table in a streaming manner: "service_rates" */
  mobile_service_rates_stream: Array<Mobile_Service_Rates>;
  /** fetch data from the table: "service_statuses" */
  mobile_service_statuses: Array<Mobile_Service_Statuses>;
  /** fetch aggregated fields from the table: "service_statuses" */
  mobile_service_statuses_aggregate: Mobile_Service_Statuses_Aggregate;
  /** fetch data from the table: "service_statuses" using primary key columns */
  mobile_service_statuses_by_pk?: Maybe<Mobile_Service_Statuses>;
  /** fetch data from the table in a streaming manner: "service_statuses" */
  mobile_service_statuses_stream: Array<Mobile_Service_Statuses>;
  /** fetch data from the table: "service_tag_ages" */
  mobile_service_tag_ages: Array<Mobile_Service_Tag_Ages>;
  /** fetch aggregated fields from the table: "service_tag_ages" */
  mobile_service_tag_ages_aggregate: Mobile_Service_Tag_Ages_Aggregate;
  /** fetch data from the table: "service_tag_ages" using primary key columns */
  mobile_service_tag_ages_by_pk?: Maybe<Mobile_Service_Tag_Ages>;
  /** fetch data from the table in a streaming manner: "service_tag_ages" */
  mobile_service_tag_ages_stream: Array<Mobile_Service_Tag_Ages>;
  /** fetch data from the table: "service_tag_target_groups" */
  mobile_service_tag_target_groups: Array<Mobile_Service_Tag_Target_Groups>;
  /** fetch aggregated fields from the table: "service_tag_target_groups" */
  mobile_service_tag_target_groups_aggregate: Mobile_Service_Tag_Target_Groups_Aggregate;
  /** fetch data from the table: "service_tag_target_groups" using primary key columns */
  mobile_service_tag_target_groups_by_pk?: Maybe<Mobile_Service_Tag_Target_Groups>;
  /** fetch data from the table in a streaming manner: "service_tag_target_groups" */
  mobile_service_tag_target_groups_stream: Array<Mobile_Service_Tag_Target_Groups>;
  /** fetch data from the table: "service_tag_targets" */
  mobile_service_tag_targets: Array<Mobile_Service_Tag_Targets>;
  /** fetch aggregated fields from the table: "service_tag_targets" */
  mobile_service_tag_targets_aggregate: Mobile_Service_Tag_Targets_Aggregate;
  /** fetch data from the table: "service_tag_targets" using primary key columns */
  mobile_service_tag_targets_by_pk?: Maybe<Mobile_Service_Tag_Targets>;
  /** fetch data from the table in a streaming manner: "service_tag_targets" */
  mobile_service_tag_targets_stream: Array<Mobile_Service_Tag_Targets>;
  /** fetch data from the table: "service_tariff_documents" */
  mobile_service_tariff_documents: Array<Mobile_Service_Tariff_Documents>;
  /** fetch aggregated fields from the table: "service_tariff_documents" */
  mobile_service_tariff_documents_aggregate: Mobile_Service_Tariff_Documents_Aggregate;
  /** fetch data from the table: "service_tariff_documents" using primary key columns */
  mobile_service_tariff_documents_by_pk?: Maybe<Mobile_Service_Tariff_Documents>;
  /** fetch data from the table in a streaming manner: "service_tariff_documents" */
  mobile_service_tariff_documents_stream: Array<Mobile_Service_Tariff_Documents>;
  /** fetch data from the table: "service_tariffs" */
  mobile_service_tariffs: Array<Mobile_Service_Tariffs>;
  /** fetch aggregated fields from the table: "service_tariffs" */
  mobile_service_tariffs_aggregate: Mobile_Service_Tariffs_Aggregate;
  /** fetch data from the table: "service_tariffs" using primary key columns */
  mobile_service_tariffs_by_pk?: Maybe<Mobile_Service_Tariffs>;
  /** fetch data from the table in a streaming manner: "service_tariffs" */
  mobile_service_tariffs_stream: Array<Mobile_Service_Tariffs>;
  /** fetch data from the table: "service_type_names" */
  mobile_service_type_names: Array<Mobile_Service_Type_Names>;
  /** fetch aggregated fields from the table: "service_type_names" */
  mobile_service_type_names_aggregate: Mobile_Service_Type_Names_Aggregate;
  /** fetch data from the table: "service_type_names" using primary key columns */
  mobile_service_type_names_by_pk?: Maybe<Mobile_Service_Type_Names>;
  /** fetch data from the table in a streaming manner: "service_type_names" */
  mobile_service_type_names_stream: Array<Mobile_Service_Type_Names>;
  /** fetch data from the table: "service_types" */
  mobile_service_types: Array<Mobile_Service_Types>;
  /** fetch aggregated fields from the table: "service_types" */
  mobile_service_types_aggregate: Mobile_Service_Types_Aggregate;
  /** fetch data from the table: "service_types" using primary key columns */
  mobile_service_types_by_pk?: Maybe<Mobile_Service_Types>;
  /** fetch data from the table in a streaming manner: "service_types" */
  mobile_service_types_stream: Array<Mobile_Service_Types>;
  /** fetch data from the table: "services" */
  mobile_services: Array<Mobile_Services>;
  /** fetch aggregated fields from the table: "services" */
  mobile_services_aggregate: Mobile_Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  mobile_services_by_pk?: Maybe<Mobile_Services>;
  /** fetch data from the table in a streaming manner: "services" */
  mobile_services_stream: Array<Mobile_Services>;
  /** fetch data from the table: "settings" */
  mobile_settings: Array<Mobile_Settings>;
  /** fetch aggregated fields from the table: "settings" */
  mobile_settings_aggregate: Mobile_Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  mobile_settings_by_pk?: Maybe<Mobile_Settings>;
  /** fetch data from the table in a streaming manner: "settings" */
  mobile_settings_stream: Array<Mobile_Settings>;
  /** fetch data from the table: "share_posts" */
  mobile_share_posts: Array<Mobile_Share_Posts>;
  /** fetch aggregated fields from the table: "share_posts" */
  mobile_share_posts_aggregate: Mobile_Share_Posts_Aggregate;
  /** fetch data from the table: "share_posts" using primary key columns */
  mobile_share_posts_by_pk?: Maybe<Mobile_Share_Posts>;
  /** fetch data from the table in a streaming manner: "share_posts" */
  mobile_share_posts_stream: Array<Mobile_Share_Posts>;
  /** fetch data from the table: "sleep_norms" */
  mobile_sleep_norms: Array<Mobile_Sleep_Norms>;
  /** fetch aggregated fields from the table: "sleep_norms" */
  mobile_sleep_norms_aggregate: Mobile_Sleep_Norms_Aggregate;
  /** fetch data from the table: "sleep_norms" using primary key columns */
  mobile_sleep_norms_by_pk?: Maybe<Mobile_Sleep_Norms>;
  /** fetch data from the table in a streaming manner: "sleep_norms" */
  mobile_sleep_norms_stream: Array<Mobile_Sleep_Norms>;
  /** fetch data from the table: "sleep_nows" */
  mobile_sleep_nows: Array<Mobile_Sleep_Nows>;
  /** fetch aggregated fields from the table: "sleep_nows" */
  mobile_sleep_nows_aggregate: Mobile_Sleep_Nows_Aggregate;
  /** fetch data from the table: "sleep_nows" using primary key columns */
  mobile_sleep_nows_by_pk?: Maybe<Mobile_Sleep_Nows>;
  /** fetch data from the table in a streaming manner: "sleep_nows" */
  mobile_sleep_nows_stream: Array<Mobile_Sleep_Nows>;
  /** fetch data from the table: "sleeps" */
  mobile_sleeps: Array<Mobile_Sleeps>;
  /** fetch aggregated fields from the table: "sleeps" */
  mobile_sleeps_aggregate: Mobile_Sleeps_Aggregate;
  /** fetch data from the table: "sleeps" using primary key columns */
  mobile_sleeps_by_pk?: Maybe<Mobile_Sleeps>;
  /** fetch data from the table in a streaming manner: "sleeps" */
  mobile_sleeps_stream: Array<Mobile_Sleeps>;
  /** fetch data from the table: "slots" */
  mobile_slots: Array<Mobile_Slots>;
  /** fetch aggregated fields from the table: "slots" */
  mobile_slots_aggregate: Mobile_Slots_Aggregate;
  /** fetch data from the table: "slots" using primary key columns */
  mobile_slots_by_pk?: Maybe<Mobile_Slots>;
  /** fetch data from the table in a streaming manner: "slots" */
  mobile_slots_stream: Array<Mobile_Slots>;
  /** fetch data from the table: "status_messages" */
  mobile_status_messages: Array<Mobile_Status_Messages>;
  /** fetch aggregated fields from the table: "status_messages" */
  mobile_status_messages_aggregate: Mobile_Status_Messages_Aggregate;
  /** fetch data from the table: "status_messages" using primary key columns */
  mobile_status_messages_by_pk?: Maybe<Mobile_Status_Messages>;
  /** fetch data from the table in a streaming manner: "status_messages" */
  mobile_status_messages_stream: Array<Mobile_Status_Messages>;
  /** fetch data from the table: "step_norms" */
  mobile_step_norms: Array<Mobile_Step_Norms>;
  /** fetch aggregated fields from the table: "step_norms" */
  mobile_step_norms_aggregate: Mobile_Step_Norms_Aggregate;
  /** fetch data from the table: "step_norms" using primary key columns */
  mobile_step_norms_by_pk?: Maybe<Mobile_Step_Norms>;
  /** fetch data from the table in a streaming manner: "step_norms" */
  mobile_step_norms_stream: Array<Mobile_Step_Norms>;
  /** fetch data from the table: "step_nows" */
  mobile_step_nows: Array<Mobile_Step_Nows>;
  /** fetch aggregated fields from the table: "step_nows" */
  mobile_step_nows_aggregate: Mobile_Step_Nows_Aggregate;
  /** fetch data from the table: "step_nows" using primary key columns */
  mobile_step_nows_by_pk?: Maybe<Mobile_Step_Nows>;
  /** fetch data from the table in a streaming manner: "step_nows" */
  mobile_step_nows_stream: Array<Mobile_Step_Nows>;
  /** fetch data from the table: "steps" */
  mobile_steps: Array<Mobile_Steps>;
  /** fetch aggregated fields from the table: "steps" */
  mobile_steps_aggregate: Mobile_Steps_Aggregate;
  /** fetch data from the table: "steps" using primary key columns */
  mobile_steps_by_pk?: Maybe<Mobile_Steps>;
  /** fetch data from the table in a streaming manner: "steps" */
  mobile_steps_stream: Array<Mobile_Steps>;
  /** fetch data from the table: "subscribe_notifications" */
  mobile_subscribe_notifications: Array<Mobile_Subscribe_Notifications>;
  /** fetch aggregated fields from the table: "subscribe_notifications" */
  mobile_subscribe_notifications_aggregate: Mobile_Subscribe_Notifications_Aggregate;
  /** fetch data from the table in a streaming manner: "subscribe_notifications" */
  mobile_subscribe_notifications_stream: Array<Mobile_Subscribe_Notifications>;
  /** fetch data from the table: "subscribes" */
  mobile_subscribes: Array<Mobile_Subscribes>;
  /** fetch aggregated fields from the table: "subscribes" */
  mobile_subscribes_aggregate: Mobile_Subscribes_Aggregate;
  /** fetch data from the table: "subscribes" using primary key columns */
  mobile_subscribes_by_pk?: Maybe<Mobile_Subscribes>;
  /** fetch data from the table in a streaming manner: "subscribes" */
  mobile_subscribes_stream: Array<Mobile_Subscribes>;
  /** fetch data from the table: "support_messages" */
  mobile_support_messages: Array<Mobile_Support_Messages>;
  /** fetch aggregated fields from the table: "support_messages" */
  mobile_support_messages_aggregate: Mobile_Support_Messages_Aggregate;
  /** fetch data from the table: "support_messages" using primary key columns */
  mobile_support_messages_by_pk?: Maybe<Mobile_Support_Messages>;
  /** fetch data from the table in a streaming manner: "support_messages" */
  mobile_support_messages_stream: Array<Mobile_Support_Messages>;
  /** fetch data from the table: "support_topics" */
  mobile_support_topics: Array<Mobile_Support_Topics>;
  /** fetch aggregated fields from the table: "support_topics" */
  mobile_support_topics_aggregate: Mobile_Support_Topics_Aggregate;
  /** fetch data from the table: "support_topics" using primary key columns */
  mobile_support_topics_by_pk?: Maybe<Mobile_Support_Topics>;
  /** fetch data from the table in a streaming manner: "support_topics" */
  mobile_support_topics_stream: Array<Mobile_Support_Topics>;
  /** fetch data from the table: "tag_ages" */
  mobile_tag_ages: Array<Mobile_Tag_Ages>;
  /** fetch aggregated fields from the table: "tag_ages" */
  mobile_tag_ages_aggregate: Mobile_Tag_Ages_Aggregate;
  /** fetch data from the table: "tag_ages" using primary key columns */
  mobile_tag_ages_by_pk?: Maybe<Mobile_Tag_Ages>;
  /** fetch data from the table in a streaming manner: "tag_ages" */
  mobile_tag_ages_stream: Array<Mobile_Tag_Ages>;
  /** fetch data from the table: "tag_target_groups" */
  mobile_tag_target_groups: Array<Mobile_Tag_Target_Groups>;
  /** fetch aggregated fields from the table: "tag_target_groups" */
  mobile_tag_target_groups_aggregate: Mobile_Tag_Target_Groups_Aggregate;
  /** fetch data from the table: "tag_target_groups" using primary key columns */
  mobile_tag_target_groups_by_pk?: Maybe<Mobile_Tag_Target_Groups>;
  /** fetch data from the table in a streaming manner: "tag_target_groups" */
  mobile_tag_target_groups_stream: Array<Mobile_Tag_Target_Groups>;
  /** fetch data from the table: "tag_targets" */
  mobile_tag_targets: Array<Mobile_Tag_Targets>;
  /** fetch aggregated fields from the table: "tag_targets" */
  mobile_tag_targets_aggregate: Mobile_Tag_Targets_Aggregate;
  /** fetch data from the table: "tag_targets" using primary key columns */
  mobile_tag_targets_by_pk?: Maybe<Mobile_Tag_Targets>;
  /** fetch data from the table in a streaming manner: "tag_targets" */
  mobile_tag_targets_stream: Array<Mobile_Tag_Targets>;
  /** fetch data from the table: "type_phones" */
  mobile_type_phones: Array<Mobile_Type_Phones>;
  /** fetch aggregated fields from the table: "type_phones" */
  mobile_type_phones_aggregate: Mobile_Type_Phones_Aggregate;
  /** fetch data from the table: "type_phones" using primary key columns */
  mobile_type_phones_by_pk?: Maybe<Mobile_Type_Phones>;
  /** fetch data from the table in a streaming manner: "type_phones" */
  mobile_type_phones_stream: Array<Mobile_Type_Phones>;
  /** fetch data from the table: "type_profiles" */
  mobile_type_profiles: Array<Mobile_Type_Profiles>;
  /** fetch aggregated fields from the table: "type_profiles" */
  mobile_type_profiles_aggregate: Mobile_Type_Profiles_Aggregate;
  /** fetch data from the table: "type_profiles" using primary key columns */
  mobile_type_profiles_by_pk?: Maybe<Mobile_Type_Profiles>;
  /** fetch data from the table in a streaming manner: "type_profiles" */
  mobile_type_profiles_stream: Array<Mobile_Type_Profiles>;
  /** fetch data from the table: "upload_pro_documents" */
  mobile_upload_pro_documents: Array<Mobile_Upload_Pro_Documents>;
  /** fetch aggregated fields from the table: "upload_pro_documents" */
  mobile_upload_pro_documents_aggregate: Mobile_Upload_Pro_Documents_Aggregate;
  /** fetch data from the table: "upload_pro_documents" using primary key columns */
  mobile_upload_pro_documents_by_pk?: Maybe<Mobile_Upload_Pro_Documents>;
  /** fetch data from the table in a streaming manner: "upload_pro_documents" */
  mobile_upload_pro_documents_stream: Array<Mobile_Upload_Pro_Documents>;
  /** fetch data from the table: "user_chat_rights" */
  mobile_user_chat_rights: Array<Mobile_User_Chat_Rights>;
  /** fetch aggregated fields from the table: "user_chat_rights" */
  mobile_user_chat_rights_aggregate: Mobile_User_Chat_Rights_Aggregate;
  /** fetch data from the table: "user_chat_rights" using primary key columns */
  mobile_user_chat_rights_by_pk?: Maybe<Mobile_User_Chat_Rights>;
  /** fetch data from the table in a streaming manner: "user_chat_rights" */
  mobile_user_chat_rights_stream: Array<Mobile_User_Chat_Rights>;
  /** fetch data from the table: "user_chats" */
  mobile_user_chats: Array<Mobile_User_Chats>;
  /** fetch aggregated fields from the table: "user_chats" */
  mobile_user_chats_aggregate: Mobile_User_Chats_Aggregate;
  /** fetch data from the table: "user_chats" using primary key columns */
  mobile_user_chats_by_pk?: Maybe<Mobile_User_Chats>;
  /** fetch data from the table in a streaming manner: "user_chats" */
  mobile_user_chats_stream: Array<Mobile_User_Chats>;
  /** fetch data from the table: "view_chat_messages" */
  mobile_view_chat_messages: Array<Mobile_View_Chat_Messages>;
  /** fetch aggregated fields from the table: "view_chat_messages" */
  mobile_view_chat_messages_aggregate: Mobile_View_Chat_Messages_Aggregate;
  /** fetch data from the table in a streaming manner: "view_chat_messages" */
  mobile_view_chat_messages_stream: Array<Mobile_View_Chat_Messages>;
  /** fetch data from the table: "water_norms" */
  mobile_water_norms: Array<Mobile_Water_Norms>;
  /** fetch aggregated fields from the table: "water_norms" */
  mobile_water_norms_aggregate: Mobile_Water_Norms_Aggregate;
  /** fetch data from the table: "water_norms" using primary key columns */
  mobile_water_norms_by_pk?: Maybe<Mobile_Water_Norms>;
  /** fetch data from the table in a streaming manner: "water_norms" */
  mobile_water_norms_stream: Array<Mobile_Water_Norms>;
  /** fetch data from the table: "water_nows" */
  mobile_water_nows: Array<Mobile_Water_Nows>;
  /** fetch aggregated fields from the table: "water_nows" */
  mobile_water_nows_aggregate: Mobile_Water_Nows_Aggregate;
  /** fetch data from the table: "water_nows" using primary key columns */
  mobile_water_nows_by_pk?: Maybe<Mobile_Water_Nows>;
  /** fetch data from the table in a streaming manner: "water_nows" */
  mobile_water_nows_stream: Array<Mobile_Water_Nows>;
  /** fetch data from the table: "waters" */
  mobile_waters: Array<Mobile_Waters>;
  /** fetch aggregated fields from the table: "waters" */
  mobile_waters_aggregate: Mobile_Waters_Aggregate;
  /** fetch data from the table: "waters" using primary key columns */
  mobile_waters_by_pk?: Maybe<Mobile_Waters>;
  /** fetch data from the table in a streaming manner: "waters" */
  mobile_waters_stream: Array<Mobile_Waters>;
  /** fetch data from the table: "weight_nows" */
  mobile_weight_nows: Array<Mobile_Weight_Nows>;
  /** fetch aggregated fields from the table: "weight_nows" */
  mobile_weight_nows_aggregate: Mobile_Weight_Nows_Aggregate;
  /** fetch data from the table: "weight_nows" using primary key columns */
  mobile_weight_nows_by_pk?: Maybe<Mobile_Weight_Nows>;
  /** fetch data from the table in a streaming manner: "weight_nows" */
  mobile_weight_nows_stream: Array<Mobile_Weight_Nows>;
  /** fetch data from the table: "weights" */
  mobile_weights: Array<Mobile_Weights>;
  /** fetch aggregated fields from the table: "weights" */
  mobile_weights_aggregate: Mobile_Weights_Aggregate;
  /** fetch data from the table: "weights" using primary key columns */
  mobile_weights_by_pk?: Maybe<Mobile_Weights>;
  /** fetch data from the table in a streaming manner: "weights" */
  mobile_weights_stream: Array<Mobile_Weights>;
  /** fetch data from the table: "balances" */
  partner_balances: Array<Partner_Balances>;
  /** fetch aggregated fields from the table: "balances" */
  partner_balances_aggregate: Partner_Balances_Aggregate;
  /** fetch data from the table: "balances" using primary key columns */
  partner_balances_by_pk?: Maybe<Partner_Balances>;
  /** fetch data from the table in a streaming manner: "balances" */
  partner_balances_stream: Array<Partner_Balances>;
  /** fetch data from the table: "log_vzr_sales" */
  partner_log_vzr_sales: Array<Partner_Log_Vzr_Sales>;
  /** fetch aggregated fields from the table: "log_vzr_sales" */
  partner_log_vzr_sales_aggregate: Partner_Log_Vzr_Sales_Aggregate;
  /** fetch data from the table: "log_vzr_sales" using primary key columns */
  partner_log_vzr_sales_by_pk?: Maybe<Partner_Log_Vzr_Sales>;
  /** fetch data from the table in a streaming manner: "log_vzr_sales" */
  partner_log_vzr_sales_stream: Array<Partner_Log_Vzr_Sales>;
  /** fetch data from the table: "log_vzr_searches" */
  partner_log_vzr_searches: Array<Partner_Log_Vzr_Searches>;
  /** fetch aggregated fields from the table: "log_vzr_searches" */
  partner_log_vzr_searches_aggregate: Partner_Log_Vzr_Searches_Aggregate;
  /** fetch data from the table: "log_vzr_searches" using primary key columns */
  partner_log_vzr_searches_by_pk?: Maybe<Partner_Log_Vzr_Searches>;
  /** fetch data from the table in a streaming manner: "log_vzr_searches" */
  partner_log_vzr_searches_stream: Array<Partner_Log_Vzr_Searches>;
  /** fetch data from the table: "log_vzr_views" */
  partner_log_vzr_views: Array<Partner_Log_Vzr_Views>;
  /** fetch aggregated fields from the table: "log_vzr_views" */
  partner_log_vzr_views_aggregate: Partner_Log_Vzr_Views_Aggregate;
  /** fetch data from the table: "log_vzr_views" using primary key columns */
  partner_log_vzr_views_by_pk?: Maybe<Partner_Log_Vzr_Views>;
  /** fetch data from the table in a streaming manner: "log_vzr_views" */
  partner_log_vzr_views_stream: Array<Partner_Log_Vzr_Views>;
  /** fetch data from the table: "place_type_integrations" */
  partner_place_type_integrations: Array<Partner_Place_Type_Integrations>;
  /** fetch aggregated fields from the table: "place_type_integrations" */
  partner_place_type_integrations_aggregate: Partner_Place_Type_Integrations_Aggregate;
  /** fetch data from the table: "place_type_integrations" using primary key columns */
  partner_place_type_integrations_by_pk?: Maybe<Partner_Place_Type_Integrations>;
  /** fetch data from the table in a streaming manner: "place_type_integrations" */
  partner_place_type_integrations_stream: Array<Partner_Place_Type_Integrations>;
  /** fetch data from the table: "places" */
  partner_places: Array<Partner_Places>;
  /** fetch aggregated fields from the table: "places" */
  partner_places_aggregate: Partner_Places_Aggregate;
  /** fetch data from the table: "places" using primary key columns */
  partner_places_by_pk?: Maybe<Partner_Places>;
  /** fetch data from the table in a streaming manner: "places" */
  partner_places_stream: Array<Partner_Places>;
  /** fetch data from the table: "sale_types" */
  partner_sale_types: Array<Partner_Sale_Types>;
  /** fetch aggregated fields from the table: "sale_types" */
  partner_sale_types_aggregate: Partner_Sale_Types_Aggregate;
  /** fetch data from the table: "sale_types" using primary key columns */
  partner_sale_types_by_pk?: Maybe<Partner_Sale_Types>;
  /** fetch data from the table in a streaming manner: "sale_types" */
  partner_sale_types_stream: Array<Partner_Sale_Types>;
  /** fetch data from the table: "status_integrations" */
  partner_status_integrations: Array<Partner_Status_Integrations>;
  /** fetch aggregated fields from the table: "status_integrations" */
  partner_status_integrations_aggregate: Partner_Status_Integrations_Aggregate;
  /** fetch data from the table: "status_integrations" using primary key columns */
  partner_status_integrations_by_pk?: Maybe<Partner_Status_Integrations>;
  /** fetch data from the table in a streaming manner: "status_integrations" */
  partner_status_integrations_stream: Array<Partner_Status_Integrations>;
  /** fetch data from the table: "status_transactions" */
  partner_status_transactions: Array<Partner_Status_Transactions>;
  /** fetch aggregated fields from the table: "status_transactions" */
  partner_status_transactions_aggregate: Partner_Status_Transactions_Aggregate;
  /** fetch data from the table: "status_transactions" using primary key columns */
  partner_status_transactions_by_pk?: Maybe<Partner_Status_Transactions>;
  /** fetch data from the table in a streaming manner: "status_transactions" */
  partner_status_transactions_stream: Array<Partner_Status_Transactions>;
  /** fetch data from the table: "themes" */
  partner_themes: Array<Partner_Themes>;
  /** fetch aggregated fields from the table: "themes" */
  partner_themes_aggregate: Partner_Themes_Aggregate;
  /** fetch data from the table: "themes" using primary key columns */
  partner_themes_by_pk?: Maybe<Partner_Themes>;
  /** fetch data from the table in a streaming manner: "themes" */
  partner_themes_stream: Array<Partner_Themes>;
  /** fetch data from the table: "type_integrations" */
  partner_type_integrations: Array<Partner_Type_Integrations>;
  /** fetch aggregated fields from the table: "type_integrations" */
  partner_type_integrations_aggregate: Partner_Type_Integrations_Aggregate;
  /** fetch data from the table: "type_integrations" using primary key columns */
  partner_type_integrations_by_pk?: Maybe<Partner_Type_Integrations>;
  /** fetch data from the table in a streaming manner: "type_integrations" */
  partner_type_integrations_stream: Array<Partner_Type_Integrations>;
  /** fetch data from the table: "type_transactions" */
  partner_type_transactions: Array<Partner_Type_Transactions>;
  /** fetch aggregated fields from the table: "type_transactions" */
  partner_type_transactions_aggregate: Partner_Type_Transactions_Aggregate;
  /** fetch data from the table: "type_transactions" using primary key columns */
  partner_type_transactions_by_pk?: Maybe<Partner_Type_Transactions>;
  /** fetch data from the table in a streaming manner: "type_transactions" */
  partner_type_transactions_stream: Array<Partner_Type_Transactions>;
  /** fetch data from the table: "user_types" */
  partner_user_types: Array<Partner_User_Types>;
  /** fetch aggregated fields from the table: "user_types" */
  partner_user_types_aggregate: Partner_User_Types_Aggregate;
  /** fetch data from the table: "user_types" using primary key columns */
  partner_user_types_by_pk?: Maybe<Partner_User_Types>;
  /** fetch data from the table in a streaming manner: "user_types" */
  partner_user_types_stream: Array<Partner_User_Types>;
  /** fetch data from the table: "users" */
  partner_users: Array<Partner_Users>;
  /** fetch aggregated fields from the table: "users" */
  partner_users_aggregate: Partner_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  partner_users_by_pk?: Maybe<Partner_Users>;
  /** fetch data from the table in a streaming manner: "users" */
  partner_users_stream: Array<Partner_Users>;
  /** fetch data from the table: "users" */
  service_users: Array<Service_Users>;
  /** fetch aggregated fields from the table: "users" */
  service_users_aggregate: Service_Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  service_users_by_pk?: Maybe<Service_Users>;
  /** fetch data from the table in a streaming manner: "users" */
  service_users_stream: Array<Service_Users>;
};


export type Subscription_RootAuth_Oauth_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Oauth_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Oauth_Access_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Oauth_Access_Tokens_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAuth_Oauth_Access_Tokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Oauth_Access_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Oauth_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Test_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Test_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Test_Users_Order_By>>;
  where?: InputMaybe<Auth_Test_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Test_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Test_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Test_Users_Order_By>>;
  where?: InputMaybe<Auth_Test_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Test_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAuth_Test_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Test_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Test_Users_Bool_Exp>;
};


export type Subscription_RootAuth_UsersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Users_Order_By>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAuth_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Users_Bool_Exp>;
};


export type Subscription_RootAuth_Verification_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Emails_Order_By>>;
  where?: InputMaybe<Auth_Verification_Emails_Bool_Exp>;
};


export type Subscription_RootAuth_Verification_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Emails_Order_By>>;
  where?: InputMaybe<Auth_Verification_Emails_Bool_Exp>;
};


export type Subscription_RootAuth_Verification_Emails_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAuth_Verification_Emails_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Verification_Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Verification_Emails_Bool_Exp>;
};


export type Subscription_RootAuth_Verification_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Phones_Order_By>>;
  where?: InputMaybe<Auth_Verification_Phones_Bool_Exp>;
};


export type Subscription_RootAuth_Verification_Phones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Verification_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Verification_Phones_Order_By>>;
  where?: InputMaybe<Auth_Verification_Phones_Bool_Exp>;
};


export type Subscription_RootAuth_Verification_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAuth_Verification_Phones_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Verification_Phones_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Verification_Phones_Bool_Exp>;
};


export type Subscription_RootBilling_Order_PolicesArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Polices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Polices_Order_By>>;
  where?: InputMaybe<Billing_Order_Polices_Bool_Exp>;
};


export type Subscription_RootBilling_Order_Polices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Polices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Polices_Order_By>>;
  where?: InputMaybe<Billing_Order_Polices_Bool_Exp>;
};


export type Subscription_RootBilling_Order_Polices_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootBilling_Order_Polices_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Billing_Order_Polices_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Order_Polices_Bool_Exp>;
};


export type Subscription_RootBilling_Order_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Statuses_Order_By>>;
  where?: InputMaybe<Billing_Order_Statuses_Bool_Exp>;
};


export type Subscription_RootBilling_Order_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Order_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Order_Statuses_Order_By>>;
  where?: InputMaybe<Billing_Order_Statuses_Bool_Exp>;
};


export type Subscription_RootBilling_Order_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootBilling_Order_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Billing_Order_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Order_Statuses_Bool_Exp>;
};


export type Subscription_RootBilling_Promo_CodesArgs = {
  distinct_on?: InputMaybe<Array<Billing_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Promo_Codes_Order_By>>;
  where?: InputMaybe<Billing_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootBilling_Promo_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Promo_Codes_Order_By>>;
  where?: InputMaybe<Billing_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootBilling_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootBilling_Promo_Codes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Billing_Promo_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootDms_CitiesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Cities_Order_By>>;
  where?: InputMaybe<Dms_Cities_Bool_Exp>;
};


export type Subscription_RootDms_Cities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Cities_Order_By>>;
  where?: InputMaybe<Dms_Cities_Bool_Exp>;
};


export type Subscription_RootDms_Cities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Cities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Cities_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Cities_Bool_Exp>;
};


export type Subscription_RootDms_Group_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Group_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Group_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Group_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Group_Insureds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Group_Insureds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Group_Insureds_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Group_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Subscription_RootDms_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Groups_Order_By>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Subscription_RootDms_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Groups_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Groups_Bool_Exp>;
};


export type Subscription_RootDms_InsurantsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Subscription_RootDms_Insurants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insurants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insurants_Order_By>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Subscription_RootDms_Insurants_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Insurants_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Insurants_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Insurants_Bool_Exp>;
};


export type Subscription_RootDms_InsuredsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Insureds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Insureds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Insureds_Order_By>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Insureds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Insureds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Insureds_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Insureds_Bool_Exp>;
};


export type Subscription_RootDms_Order_LinksArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Subscription_RootDms_Order_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Links_Order_By>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Subscription_RootDms_Order_Links_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Order_Links_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Order_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Order_Links_Bool_Exp>;
};


export type Subscription_RootDms_Order_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Subscription_RootDms_Order_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Order_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Order_Numbers_Order_By>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Subscription_RootDms_Order_Numbers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Order_Numbers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Order_Numbers_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Order_Numbers_Bool_Exp>;
};


export type Subscription_RootDms_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Subscription_RootDms_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Orders_Order_By>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Subscription_RootDms_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Orders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Orders_Bool_Exp>;
};


export type Subscription_RootDms_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Subscription_RootDms_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Products_Order_By>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Subscription_RootDms_Products_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Products_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Products_Bool_Exp>;
};


export type Subscription_RootDms_Promo_CodesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootDms_Promo_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Promo_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Promo_Codes_Order_By>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootDms_Promo_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Promo_Codes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Promo_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Promo_Codes_Bool_Exp>;
};


export type Subscription_RootDms_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Subscription_RootDms_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Statuses_Order_By>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Subscription_RootDms_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Statuses_Bool_Exp>;
};


export type Subscription_RootDms_UsersArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Subscription_RootDms_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dms_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Dms_Users_Order_By>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Subscription_RootDms_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootDms_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Dms_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Dms_Users_Bool_Exp>;
};


export type Subscription_RootFintech_CardsArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Cards_Order_By>>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};


export type Subscription_RootFintech_Cards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Cards_Order_By>>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};


export type Subscription_RootFintech_Cards_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootFintech_Cards_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Fintech_Cards_Stream_Cursor_Input>>;
  where?: InputMaybe<Fintech_Cards_Bool_Exp>;
};


export type Subscription_RootFintech_ClientsArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Clients_Order_By>>;
  where?: InputMaybe<Fintech_Clients_Bool_Exp>;
};


export type Subscription_RootFintech_Clients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Clients_Order_By>>;
  where?: InputMaybe<Fintech_Clients_Bool_Exp>;
};


export type Subscription_RootFintech_Clients_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootFintech_Clients_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Fintech_Clients_Stream_Cursor_Input>>;
  where?: InputMaybe<Fintech_Clients_Bool_Exp>;
};


export type Subscription_RootFintech_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Orders_Order_By>>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};


export type Subscription_RootFintech_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fintech_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Fintech_Orders_Order_By>>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};


export type Subscription_RootFintech_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootFintech_Orders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Fintech_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Fintech_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_Contacts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Amocrm_Contacts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Amocrm_Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Amocrm_Contacts_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_LeadsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_Leads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Leads_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_Leads_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Amocrm_Leads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Amocrm_Leads_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Amocrm_Leads_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_TagsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Tags_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Tags_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Amocrm_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Amocrm_Tags_Order_By>>;
  where?: InputMaybe<Mobile_Amocrm_Tags_Bool_Exp>;
};


export type Subscription_RootMobile_Amocrm_Tags_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Amocrm_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Amocrm_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Amocrm_Tags_Bool_Exp>;
};


export type Subscription_RootMobile_AppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


export type Subscription_RootMobile_Appointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Appointments_Order_By>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


export type Subscription_RootMobile_Appointments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Appointments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Appointments_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Appointments_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_RepliesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Replies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Replies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Replies_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Replies_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Auto_Replies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Auto_Replies_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Auto_Replies_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Reply_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Reply_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Reply_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Auto_Reply_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Auto_Reply_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Auto_Reply_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Reply_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Reply_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Auto_Reply_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Auto_Reply_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Auto_Reply_Mediafiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Auto_Reply_Mediafiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Auto_Reply_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Bank_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};


export type Subscription_RootMobile_Bank_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Bank_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Bank_Accounts_Order_By>>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};


export type Subscription_RootMobile_Bank_Accounts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Bank_Accounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Bank_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Bank_Accounts_Bool_Exp>;
};


export type Subscription_RootMobile_Blocked_SitesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blocked_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blocked_Sites_Order_By>>;
  where?: InputMaybe<Mobile_Blocked_Sites_Bool_Exp>;
};


export type Subscription_RootMobile_Blocked_Sites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blocked_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blocked_Sites_Order_By>>;
  where?: InputMaybe<Mobile_Blocked_Sites_Bool_Exp>;
};


export type Subscription_RootMobile_Blocked_Sites_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Blocked_Sites_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Blocked_Sites_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Blocked_Sites_Bool_Exp>;
};


export type Subscription_RootMobile_BlockedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blockeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blockeds_Order_By>>;
  where?: InputMaybe<Mobile_Blockeds_Bool_Exp>;
};


export type Subscription_RootMobile_Blockeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Blockeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Blockeds_Order_By>>;
  where?: InputMaybe<Mobile_Blockeds_Bool_Exp>;
};


export type Subscription_RootMobile_Blockeds_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMobile_Blockeds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Blockeds_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Blockeds_Bool_Exp>;
};


export type Subscription_RootMobile_Calorie_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Calorie_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Calorie_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Calorie_Norms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Calorie_Norms_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Calorie_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Calorie_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Calorie_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calorie_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calorie_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Calorie_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Calorie_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Calorie_Nows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Calorie_Nows_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Calorie_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_CaloriesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calories_Order_By>>;
  where?: InputMaybe<Mobile_Calories_Bool_Exp>;
};


export type Subscription_RootMobile_Calories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Calories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Calories_Order_By>>;
  where?: InputMaybe<Mobile_Calories_Bool_Exp>;
};


export type Subscription_RootMobile_Calories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Calories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Calories_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Calories_Bool_Exp>;
};


export type Subscription_RootMobile_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Categories_Bool_Exp>;
};


export type Subscription_RootMobile_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Categories_Bool_Exp>;
};


export type Subscription_RootMobile_Categories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Categories_Bool_Exp>;
};


export type Subscription_RootMobile_Category_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Category_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Category_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Category_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Category_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Category_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Black_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Black_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Black_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Black_Lists_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Black_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Black_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Black_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Black_Lists_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Black_Lists_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Black_Lists_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Black_Lists_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Black_Lists_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_DefaultsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Defaults_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Defaults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Defaults_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Defaults_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Defaults_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Defaults_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Defaults_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Defaults_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_File_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_File_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_File_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_File_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_File_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_File_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_File_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_File_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_File_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Message_Comments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Message_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Message_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_FilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Files_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Files_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Message_Files_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Message_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Message_Files_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Message_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Message_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Message_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Message_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Message_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Message_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Message_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Message_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_RolesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Roles_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Roles_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Roles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Roles_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chat_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chat_Types_Order_By>>;
  where?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Chat_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chat_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chat_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chat_Types_Bool_Exp>;
};


export type Subscription_RootMobile_ChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chats_Order_By>>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};


export type Subscription_RootMobile_Chats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Chats_Order_By>>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};


export type Subscription_RootMobile_Chats_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Chats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Chats_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Chats_Bool_Exp>;
};


export type Subscription_RootMobile_ClientsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Clients_Order_By>>;
  where?: InputMaybe<Mobile_Clients_Bool_Exp>;
};


export type Subscription_RootMobile_Clients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Clients_Order_By>>;
  where?: InputMaybe<Mobile_Clients_Bool_Exp>;
};


export type Subscription_RootMobile_Clients_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Clients_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Clients_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Clients_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_Like_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Like_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_Like_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Like_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_Like_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Comment_Like_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Comment_Like_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Comment_Likes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Comment_Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Comment_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comment_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comment_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Comment_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Comment_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Comment_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Comment_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Comments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Confirmation_CodesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


export type Subscription_RootMobile_Confirmation_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Confirmation_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Confirmation_Codes_Order_By>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


export type Subscription_RootMobile_Confirmation_Codes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Confirmation_Codes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Confirmation_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Confirmation_Codes_Bool_Exp>;
};


export type Subscription_RootMobile_ContactsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};


export type Subscription_RootMobile_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contacts_Order_By>>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};


export type Subscription_RootMobile_Contacts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Contacts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Contacts_Bool_Exp>;
};


export type Subscription_RootMobile_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contents_Order_By>>;
  where?: InputMaybe<Mobile_Contents_Bool_Exp>;
};


export type Subscription_RootMobile_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Contents_Order_By>>;
  where?: InputMaybe<Mobile_Contents_Bool_Exp>;
};


export type Subscription_RootMobile_Contents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Contents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Contents_Bool_Exp>;
};


export type Subscription_RootMobile_Device_InfosArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};


export type Subscription_RootMobile_Device_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};


export type Subscription_RootMobile_Device_Infos_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Device_Infos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Device_Infos_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Device_Infos_Bool_Exp>;
};


export type Subscription_RootMobile_Device_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};


export type Subscription_RootMobile_Device_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Device_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Device_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};


export type Subscription_RootMobile_Device_Settings_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Device_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Device_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Device_Settings_Bool_Exp>;
};


export type Subscription_RootMobile_DevicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Devices_Order_By>>;
  where?: InputMaybe<Mobile_Devices_Bool_Exp>;
};


export type Subscription_RootMobile_Devices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Devices_Order_By>>;
  where?: InputMaybe<Mobile_Devices_Bool_Exp>;
};


export type Subscription_RootMobile_Devices_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Devices_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Devices_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Devices_Bool_Exp>;
};


export type Subscription_RootMobile_Document_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Document_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Document_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Document_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Document_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Document_Mediafiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Document_Mediafiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Document_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Education_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Education_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Education_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Education_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Education_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Education_Mediafiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Education_Mediafiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Education_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_EducationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Educations_Order_By>>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};


export type Subscription_RootMobile_Educations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Educations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Educations_Order_By>>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};


export type Subscription_RootMobile_Educations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Educations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Educations_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Educations_Bool_Exp>;
};


export type Subscription_RootMobile_EmojisArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Emojis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Emojis_Order_By>>;
  where?: InputMaybe<Mobile_Emojis_Bool_Exp>;
};


export type Subscription_RootMobile_Emojis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Emojis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Emojis_Order_By>>;
  where?: InputMaybe<Mobile_Emojis_Bool_Exp>;
};


export type Subscription_RootMobile_Emojis_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Emojis_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Emojis_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Emojis_Bool_Exp>;
};


export type Subscription_RootMobile_Fixed_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Fixed_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Fixed_Chat_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Fixed_Chat_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Fixed_Chat_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Fixed_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Fixed_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Fixed_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Fixed_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Fixed_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Fixed_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Fixed_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Fixed_Comments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Fixed_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Fixed_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Founder_MediafilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Founder_Mediafiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founder_Mediafiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founder_Mediafiles_Order_By>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_Founder_Mediafiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Founder_Mediafiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Founder_Mediafiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Founder_Mediafiles_Bool_Exp>;
};


export type Subscription_RootMobile_FoundersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founders_Order_By>>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};


export type Subscription_RootMobile_Founders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Founders_Order_By>>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};


export type Subscription_RootMobile_Founders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Founders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Founders_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Founders_Bool_Exp>;
};


export type Subscription_RootMobile_Invite_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Invite_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Invite_Emails_Order_By>>;
  where?: InputMaybe<Mobile_Invite_Emails_Bool_Exp>;
};


export type Subscription_RootMobile_Invite_Emails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Invite_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Invite_Emails_Order_By>>;
  where?: InputMaybe<Mobile_Invite_Emails_Bool_Exp>;
};


export type Subscription_RootMobile_Invite_Emails_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Invite_Emails_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Invite_Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Invite_Emails_Bool_Exp>;
};


export type Subscription_RootMobile_Like_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Like_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Like_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Like_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Like_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Like_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Like_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Like_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Like_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Likes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Link_SnippetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Link_Snippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Link_Snippets_Order_By>>;
  where?: InputMaybe<Mobile_Link_Snippets_Bool_Exp>;
};


export type Subscription_RootMobile_Link_Snippets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Link_Snippets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Link_Snippets_Order_By>>;
  where?: InputMaybe<Mobile_Link_Snippets_Bool_Exp>;
};


export type Subscription_RootMobile_Link_Snippets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Link_Snippets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Link_Snippets_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Link_Snippets_Bool_Exp>;
};


export type Subscription_RootMobile_Locked_Deleted_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Locked_Deleted_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Locked_Deleted_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Locked_Deleted_Chat_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Locked_Deleted_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Mediafile_Status_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Mediafile_Status_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Status_Names_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Mediafile_Status_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Mediafile_Status_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Mediafile_Status_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Mediafile_Status_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Mediafile_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Mediafile_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mediafile_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mediafile_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Mediafile_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Mediafile_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Mediafile_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Mediafile_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Medical_Concierge_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Medical_Concierge_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Medical_Concierge_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Medical_Concierge_Orders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Medical_Concierge_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Medical_Concierge_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
};


export type Subscription_RootMobile_Medical_Concierge_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Medical_Concierge_Products_Order_By>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
};


export type Subscription_RootMobile_Medical_Concierge_Products_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Medical_Concierge_Products_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Medical_Concierge_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Medical_Concierge_Products_Bool_Exp>;
};


export type Subscription_RootMobile_Mention_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mention_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mention_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Mention_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Mention_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mention_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mention_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Mention_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Mention_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Mention_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Mention_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mentions_Order_By>>;
  where?: InputMaybe<Mobile_Mentions_Bool_Exp>;
};


export type Subscription_RootMobile_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Mentions_Order_By>>;
  where?: InputMaybe<Mobile_Mentions_Bool_Exp>;
};


export type Subscription_RootMobile_Mentions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Mentions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Mentions_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Mentions_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Names_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Names_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Metric_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Metric_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Metric_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Params_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Metric_Params_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Metric_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Metric_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Types_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metric_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metric_Types_Order_By>>;
  where?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Metric_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Metric_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Metric_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Metric_Types_Bool_Exp>;
};


export type Subscription_RootMobile_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metrics_Order_By>>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};


export type Subscription_RootMobile_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Metrics_Order_By>>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};


export type Subscription_RootMobile_Metrics_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Metrics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Metrics_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Metrics_Bool_Exp>;
};


export type Subscription_RootMobile_Notification_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notification_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notification_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Notification_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Notification_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notification_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notification_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Notification_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Notification_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Notification_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Notification_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Notification_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Notifications_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Notifications_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Notifications_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Notifications_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Notifications_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Notifications_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Notifications_Statuses_By_PkArgs = {
  title: Scalars['String']['input'];
};


export type Subscription_RootMobile_Notifications_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Notifications_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Notifications_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Order_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Order_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Order_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Order_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Order_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Order_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Order_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Order_ProfessionalsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Professionals_Order_By>>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};


export type Subscription_RootMobile_Order_Professionals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Order_Professionals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Order_Professionals_Order_By>>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};


export type Subscription_RootMobile_Order_Professionals_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Order_Professionals_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Order_Professionals_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Order_Professionals_Bool_Exp>;
};


export type Subscription_RootMobile_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Orders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_OrgsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


export type Subscription_RootMobile_Orgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Orgs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Orgs_Order_By>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


export type Subscription_RootMobile_Orgs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Orgs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Orgs_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Orgs_Bool_Exp>;
};


export type Subscription_RootMobile_Param_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Categories_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Param_Categories_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Categories_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Categories_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Categories_Params_Order_By>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Categories_Params_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Param_Categories_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Param_Categories_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Param_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Param_Categories_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Category_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Category_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Category_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Category_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Category_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Param_Category_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Param_Category_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Param_Category_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Param_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Param_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Param_Names_Order_By>>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Param_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Param_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Param_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Param_Names_Bool_Exp>;
};


export type Subscription_RootMobile_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Order_By>>;
  where?: InputMaybe<Mobile_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Order_By>>;
  where?: InputMaybe<Mobile_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Params_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Params_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Params_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Params_Services_Order_By>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Params_Services_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Params_Services_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Params_Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Params_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Params_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Personal_Info_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Info_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Info_Views_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
};


export type Subscription_RootMobile_Personal_Info_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Info_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Info_Views_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
};


export type Subscription_RootMobile_Personal_Info_Views_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Personal_Info_Views_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Personal_Info_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Personal_Info_Views_Bool_Exp>;
};


export type Subscription_RootMobile_Personal_InfosArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


export type Subscription_RootMobile_Personal_Infos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Personal_Infos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Personal_Infos_Order_By>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


export type Subscription_RootMobile_Personal_Infos_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Personal_Infos_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Personal_Infos_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Personal_Infos_Bool_Exp>;
};


export type Subscription_RootMobile_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};


export type Subscription_RootMobile_Phones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};


export type Subscription_RootMobile_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Phones_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Phones_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Phones_Bool_Exp>;
};


export type Subscription_RootMobile_Post_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Post_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Post_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Post_Params_Order_By>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


export type Subscription_RootMobile_Post_Params_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Post_Params_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Post_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Post_Params_Bool_Exp>;
};


export type Subscription_RootMobile_PostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Posts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Posts_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Services_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Posts_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Posts_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Services_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Posts_Services_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Posts_Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Posts_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Posts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_Document_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_Document_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Document_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_Document_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Pro_Document_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Pro_Document_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Pro_Document_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_ProfessionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Professions_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_Professions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Professions_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_Professions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Pro_Professions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Pro_Professions_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Pro_Professions_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Types_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pro_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pro_Types_Order_By>>;
  where?: InputMaybe<Mobile_Pro_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Pro_Types_By_PkArgs = {
  title: Scalars['String']['input'];
};


export type Subscription_RootMobile_Pro_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Pro_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Pro_Types_Bool_Exp>;
};


export type Subscription_RootMobile_ProblemsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Problems_Order_By>>;
  where?: InputMaybe<Mobile_Problems_Bool_Exp>;
};


export type Subscription_RootMobile_Problems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Problems_Order_By>>;
  where?: InputMaybe<Mobile_Problems_Bool_Exp>;
};


export type Subscription_RootMobile_Problems_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Problems_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Problems_Bool_Exp>;
};


export type Subscription_RootMobile_Profession_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profession_Names_Order_By>>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Profession_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profession_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profession_Names_Order_By>>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Profession_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Profession_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Profession_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Profession_Names_Bool_Exp>;
};


export type Subscription_RootMobile_ProfessionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};


export type Subscription_RootMobile_Professions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Professions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Professions_Order_By>>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};


export type Subscription_RootMobile_Professions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Professions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Professions_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Professions_Bool_Exp>;
};


export type Subscription_RootMobile_Profile_DeletedsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Deleteds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Deleteds_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Deleteds_Bool_Exp>;
};


export type Subscription_RootMobile_Profile_Deleteds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Deleteds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Deleteds_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Deleteds_Bool_Exp>;
};


export type Subscription_RootMobile_Profile_Deleteds_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Profile_Deleteds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Profile_Deleteds_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Profile_Deleteds_Bool_Exp>;
};


export type Subscription_RootMobile_Profile_LocksArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


export type Subscription_RootMobile_Profile_Locks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profile_Locks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profile_Locks_Order_By>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


export type Subscription_RootMobile_Profile_Locks_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Profile_Locks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Profile_Locks_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Profile_Locks_Bool_Exp>;
};


export type Subscription_RootMobile_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_Profiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Profiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_ProsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pros_Order_By>>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};


export type Subscription_RootMobile_Pros_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pros_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pros_Order_By>>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};


export type Subscription_RootMobile_Pros_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Pros_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Pros_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Pros_Bool_Exp>;
};


export type Subscription_RootMobile_Pulse_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Pulse_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Pulse_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Pulse_Norms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Pulse_Norms_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Pulse_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Pulse_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Pulse_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulse_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulse_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Pulse_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Pulse_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Pulse_Nows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Pulse_Nows_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Pulse_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_PulsesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulses_Order_By>>;
  where?: InputMaybe<Mobile_Pulses_Bool_Exp>;
};


export type Subscription_RootMobile_Pulses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Pulses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Pulses_Order_By>>;
  where?: InputMaybe<Mobile_Pulses_Bool_Exp>;
};


export type Subscription_RootMobile_Pulses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Pulses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Pulses_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Pulses_Bool_Exp>;
};


export type Subscription_RootMobile_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Reactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Reactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reports_Order_By>>;
  where?: InputMaybe<Mobile_Reports_Bool_Exp>;
};


export type Subscription_RootMobile_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Reports_Order_By>>;
  where?: InputMaybe<Mobile_Reports_Bool_Exp>;
};


export type Subscription_RootMobile_Reports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Reports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Reports_Bool_Exp>;
};


export type Subscription_RootMobile_Search_Posts_Find_Title_MessageArgs = {
  args: Mobile_Search_Posts_Find_Title_Message_Args;
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Search_Posts_Find_Title_Message_AggregateArgs = {
  args: Mobile_Search_Posts_Find_Title_Message_Args;
  distinct_on?: InputMaybe<Array<Mobile_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Search_Profiles_Find_First_Last_Username_AboutmyselfArgs = {
  args: Mobile_Search_Profiles_Find_First_Last_Username_Aboutmyself_Args;
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_Search_Profiles_Find_First_Last_Username_Aboutmyself_AggregateArgs = {
  args: Mobile_Search_Profiles_Find_First_Last_Username_Aboutmyself_Args;
  distinct_on?: InputMaybe<Array<Mobile_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_Service_AgreementsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Agreements_Order_By>>;
  where?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Agreements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Agreements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Agreements_Order_By>>;
  where?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Agreements_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Agreements_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Agreements_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Agreements_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Answer_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Answer_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answer_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Answer_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Answer_Reactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Answer_Reactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Answer_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answers_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Answers_Order_By>>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Answers_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Answers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Answers_Bool_Exp>;
};


export type Subscription_RootMobile_Service_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_FavoritesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Favorites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Favorites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Favorites_Order_By>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Favorites_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Favorites_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Favorites_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Favorites_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Free_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Free_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Free_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Free_Purchases_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Free_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Free_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Free_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Free_Purchases_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Free_Purchases_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Free_Purchases_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Free_Purchases_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Free_Purchases_Bool_Exp>;
};


export type Subscription_RootMobile_Service_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Images_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Images_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Images_Bool_Exp>;
};


export type Subscription_RootMobile_Service_LikesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Likes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Likes_Order_By>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Likes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Likes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Likes_Bool_Exp>;
};


export type Subscription_RootMobile_Service_List_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_List_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_List_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_List_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_List_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_List_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_List_PeriodicitiesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
};


export type Subscription_RootMobile_Service_List_Periodicities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_List_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_List_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
};


export type Subscription_RootMobile_Service_List_Periodicities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_List_Periodicities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_List_Periodicities_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_List_Periodicities_Bool_Exp>;
};


export type Subscription_RootMobile_Service_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Lists_Order_By>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Lists_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Lists_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Lists_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Lists_Bool_Exp>;
};


export type Subscription_RootMobile_Service_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Service_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Orders_Order_By>>;
  where?: InputMaybe<Mobile_Service_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Orders_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Orders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Orders_Bool_Exp>;
};


export type Subscription_RootMobile_Service_PeriodicitiesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Periodicities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Periodicities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Periodicities_Order_By>>;
  where?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Periodicities_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Periodicities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Periodicities_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Periodicities_Bool_Exp>;
};


export type Subscription_RootMobile_Service_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Purchases_Order_By>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Purchases_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Purchases_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Purchases_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Purchases_Bool_Exp>;
};


export type Subscription_RootMobile_Service_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Questions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Questions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Questions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Questions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Comments_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Rate_Comments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Rate_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Rate_Comments_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_ImagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_Images_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Images_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_Images_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Rate_Images_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Rate_Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Rate_Images_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_ReactionsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_Reactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rate_Reactions_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rate_Reactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Rate_Reactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Rate_Reactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Rate_Reactions_Bool_Exp>;
};


export type Subscription_RootMobile_Service_RatesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Rates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Rates_Order_By>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Rates_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Rates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Rates_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Rates_Bool_Exp>;
};


export type Subscription_RootMobile_Service_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Statuses_Order_By>>;
  where?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Statuses_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Statuses_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_AgesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_Ages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_Ages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Tag_Ages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Tag_Ages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Tag_Ages_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_Target_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_Target_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_Target_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Tag_Target_Groups_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Tag_Target_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Tag_Target_Groups_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tag_Targets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Tag_Targets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Tag_Targets_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Tag_Targets_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tariff_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariff_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tariff_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariff_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariff_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tariff_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Tariff_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Tariff_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Tariff_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Service_TariffsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariffs_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Tariffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Tariffs_Order_By>>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Tariffs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Tariffs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Tariffs_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Tariffs_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Type_NamesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Type_Names_Order_By>>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Type_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Type_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Type_Names_Order_By>>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Type_Names_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Type_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Type_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Type_Names_Bool_Exp>;
};


export type Subscription_RootMobile_Service_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Types_Order_By>>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Service_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Service_Types_Order_By>>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};


export type Subscription_RootMobile_Service_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Service_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Service_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Service_Types_Bool_Exp>;
};


export type Subscription_RootMobile_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Services_Order_By>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


export type Subscription_RootMobile_Services_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Services_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Services_Bool_Exp>;
};


export type Subscription_RootMobile_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};


export type Subscription_RootMobile_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Settings_Order_By>>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};


export type Subscription_RootMobile_Settings_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Settings_Bool_Exp>;
};


export type Subscription_RootMobile_Share_PostsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Share_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Share_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Share_Posts_Order_By>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Share_Posts_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Share_Posts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Share_Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Share_Posts_Bool_Exp>;
};


export type Subscription_RootMobile_Sleep_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Sleep_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Sleep_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Sleep_Norms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Sleep_Norms_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Sleep_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Sleep_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Sleep_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleep_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleep_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Sleep_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Sleep_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Sleep_Nows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Sleep_Nows_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Sleep_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_SleepsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleeps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleeps_Order_By>>;
  where?: InputMaybe<Mobile_Sleeps_Bool_Exp>;
};


export type Subscription_RootMobile_Sleeps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Sleeps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Sleeps_Order_By>>;
  where?: InputMaybe<Mobile_Sleeps_Bool_Exp>;
};


export type Subscription_RootMobile_Sleeps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Sleeps_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Sleeps_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Sleeps_Bool_Exp>;
};


export type Subscription_RootMobile_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Slots_Order_By>>;
  where?: InputMaybe<Mobile_Slots_Bool_Exp>;
};


export type Subscription_RootMobile_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Slots_Order_By>>;
  where?: InputMaybe<Mobile_Slots_Bool_Exp>;
};


export type Subscription_RootMobile_Slots_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Slots_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Slots_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Slots_Bool_Exp>;
};


export type Subscription_RootMobile_Status_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Status_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Status_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Status_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Status_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Status_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Status_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Status_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Status_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Status_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Step_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Step_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Step_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Step_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Step_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Step_Norms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Step_Norms_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Step_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Step_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Step_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Step_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Step_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Step_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Step_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Step_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Step_Nows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Step_Nows_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Step_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_StepsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Steps_Order_By>>;
  where?: InputMaybe<Mobile_Steps_Bool_Exp>;
};


export type Subscription_RootMobile_Steps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Steps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Steps_Order_By>>;
  where?: InputMaybe<Mobile_Steps_Bool_Exp>;
};


export type Subscription_RootMobile_Steps_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Steps_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Steps_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Steps_Bool_Exp>;
};


export type Subscription_RootMobile_Subscribe_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribe_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribe_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Subscribe_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Subscribe_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribe_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribe_Notifications_Order_By>>;
  where?: InputMaybe<Mobile_Subscribe_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_Subscribe_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Subscribe_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Subscribe_Notifications_Bool_Exp>;
};


export type Subscription_RootMobile_SubscribesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


export type Subscription_RootMobile_Subscribes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Subscribes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Subscribes_Order_By>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


export type Subscription_RootMobile_Subscribes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Subscribes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Subscribes_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Subscribes_Bool_Exp>;
};


export type Subscription_RootMobile_Support_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Support_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Messages_Order_By>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Support_Messages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Support_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Support_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Support_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Support_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Topics_Order_By>>;
  where?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
};


export type Subscription_RootMobile_Support_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Support_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Support_Topics_Order_By>>;
  where?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
};


export type Subscription_RootMobile_Support_Topics_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Support_Topics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Support_Topics_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Support_Topics_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_AgesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_Ages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Ages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Ages_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_Ages_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Tag_Ages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Tag_Ages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Tag_Ages_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_Target_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_Target_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Target_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Target_Groups_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_Target_Groups_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Tag_Target_Groups_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Tag_Target_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Tag_Target_Groups_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_TargetsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_Targets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Tag_Targets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Tag_Targets_Order_By>>;
  where?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
};


export type Subscription_RootMobile_Tag_Targets_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Tag_Targets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Tag_Targets_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Tag_Targets_Bool_Exp>;
};


export type Subscription_RootMobile_Type_PhonesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
};


export type Subscription_RootMobile_Type_Phones_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Phones_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Phones_Order_By>>;
  where?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
};


export type Subscription_RootMobile_Type_Phones_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Type_Phones_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Type_Phones_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Type_Phones_Bool_Exp>;
};


export type Subscription_RootMobile_Type_ProfilesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_Type_Profiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Type_Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Type_Profiles_Order_By>>;
  where?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_Type_Profiles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Type_Profiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Type_Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Type_Profiles_Bool_Exp>;
};


export type Subscription_RootMobile_Upload_Pro_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Upload_Pro_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Upload_Pro_Documents_Order_By>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_Upload_Pro_Documents_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Upload_Pro_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Upload_Pro_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Upload_Pro_Documents_Bool_Exp>;
};


export type Subscription_RootMobile_User_Chat_RightsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chat_Rights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chat_Rights_Order_By>>;
  where?: InputMaybe<Mobile_User_Chat_Rights_Bool_Exp>;
};


export type Subscription_RootMobile_User_Chat_Rights_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chat_Rights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chat_Rights_Order_By>>;
  where?: InputMaybe<Mobile_User_Chat_Rights_Bool_Exp>;
};


export type Subscription_RootMobile_User_Chat_Rights_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_User_Chat_Rights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_User_Chat_Rights_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_User_Chat_Rights_Bool_Exp>;
};


export type Subscription_RootMobile_User_ChatsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


export type Subscription_RootMobile_User_Chats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_User_Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_User_Chats_Order_By>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


export type Subscription_RootMobile_User_Chats_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_User_Chats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_User_Chats_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_User_Chats_Bool_Exp>;
};


export type Subscription_RootMobile_View_Chat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Mobile_View_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_View_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_View_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_View_Chat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_View_Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_View_Chat_Messages_Order_By>>;
  where?: InputMaybe<Mobile_View_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_View_Chat_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_View_Chat_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_View_Chat_Messages_Bool_Exp>;
};


export type Subscription_RootMobile_Water_NormsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Water_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Water_Norms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Norms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Norms_Order_By>>;
  where?: InputMaybe<Mobile_Water_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Water_Norms_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Water_Norms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Water_Norms_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Water_Norms_Bool_Exp>;
};


export type Subscription_RootMobile_Water_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Water_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Water_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Water_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Water_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Water_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Water_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Water_Nows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Water_Nows_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Water_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_WatersArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Waters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Waters_Order_By>>;
  where?: InputMaybe<Mobile_Waters_Bool_Exp>;
};


export type Subscription_RootMobile_Waters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Waters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Waters_Order_By>>;
  where?: InputMaybe<Mobile_Waters_Bool_Exp>;
};


export type Subscription_RootMobile_Waters_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Waters_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Waters_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Waters_Bool_Exp>;
};


export type Subscription_RootMobile_Weight_NowsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weight_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weight_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Weight_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Weight_Nows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weight_Nows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weight_Nows_Order_By>>;
  where?: InputMaybe<Mobile_Weight_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_Weight_Nows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Weight_Nows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Weight_Nows_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Weight_Nows_Bool_Exp>;
};


export type Subscription_RootMobile_WeightsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weights_Order_By>>;
  where?: InputMaybe<Mobile_Weights_Bool_Exp>;
};


export type Subscription_RootMobile_Weights_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_Weights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Mobile_Weights_Order_By>>;
  where?: InputMaybe<Mobile_Weights_Bool_Exp>;
};


export type Subscription_RootMobile_Weights_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootMobile_Weights_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Mobile_Weights_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_Weights_Bool_Exp>;
};


export type Subscription_RootPartner_BalancesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Subscription_RootPartner_Balances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balances_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Balances_Order_By>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Subscription_RootPartner_Balances_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Balances_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Balances_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Balances_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_SalesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Sales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Sales_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Sales_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Log_Vzr_Sales_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Log_Vzr_Sales_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Log_Vzr_Sales_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_SearchesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Searches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Searches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Searches_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Searches_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Log_Vzr_Searches_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Log_Vzr_Searches_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Log_Vzr_Searches_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Log_Vzr_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Log_Vzr_Views_Order_By>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Subscription_RootPartner_Log_Vzr_Views_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Log_Vzr_Views_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Log_Vzr_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Log_Vzr_Views_Bool_Exp>;
};


export type Subscription_RootPartner_Place_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Place_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Place_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Place_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Place_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Place_Type_Integrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Place_Type_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Place_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_PlacesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Subscription_RootPartner_Places_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Places_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Places_Order_By>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Subscription_RootPartner_Places_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Places_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Places_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Places_Bool_Exp>;
};


export type Subscription_RootPartner_Sale_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Sale_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Sale_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Sale_Types_Order_By>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Sale_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Sale_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Sale_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Sale_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Status_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Status_Integrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Status_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Status_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Status_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Status_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Status_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Status_Transactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Status_Transactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Status_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Status_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_ThemesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Subscription_RootPartner_Themes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Themes_Order_By>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Subscription_RootPartner_Themes_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Themes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Themes_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Themes_Bool_Exp>;
};


export type Subscription_RootPartner_Type_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Integrations_Order_By>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Integrations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Type_Integrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Type_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Type_Integrations_Bool_Exp>;
};


export type Subscription_RootPartner_Type_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Type_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Type_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Type_Transactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Type_Transactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Type_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Type_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_User_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Subscription_RootPartner_User_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_User_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_User_Types_Order_By>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Subscription_RootPartner_User_Types_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_User_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_User_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_User_Types_Bool_Exp>;
};


export type Subscription_RootPartner_UsersArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Subscription_RootPartner_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Partner_Users_Order_By>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Subscription_RootPartner_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPartner_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Partner_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Users_Bool_Exp>;
};


export type Subscription_RootService_UsersArgs = {
  distinct_on?: InputMaybe<Array<Service_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Users_Order_By>>;
  where?: InputMaybe<Service_Users_Bool_Exp>;
};


export type Subscription_RootService_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Users_Order_By>>;
  where?: InputMaybe<Service_Users_Bool_Exp>;
};


export type Subscription_RootService_Users_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootService_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Service_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Users_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type UpdateMobilePostsInput = {
  content_id: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  media_file?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMobilePostsOutput = {
  __typename?: 'updateMobilePostsOutput';
  content_id?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  media_file_url?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UpdateMobileProfilesByPkInput = {
  about_myself?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type UpdateMobileProfilesByPkOutput = {
  __typename?: 'updateMobileProfilesByPkOutput';
  about_myself?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  avatar_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type_profile_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AmocrmCreateLeadMutationVariables = Exact<{
  email_or_phone: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  program_name: Scalars['String']['input'];
  user_name?: InputMaybe<Scalars['String']['input']>;
  tags: Array<InputMaybe<MobileAmocrmCreateLeadTagsInput>> | InputMaybe<MobileAmocrmCreateLeadTagsInput>;
}>;


export type AmocrmCreateLeadMutation = { __typename?: 'mutation_root', mobileAmocrmCreateLead?: { __typename?: 'mobileAmocrmCreateLeadOutput', error?: string | null, status?: boolean | null } | null };

export type PurchaseRequestMutationVariables = Exact<{
  email: Scalars['String']['input'];
  fio: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  service_tariff_id: Scalars['Int']['input'];
}>;


export type PurchaseRequestMutation = { __typename?: 'mutation_root', mobilePurchasingService?: { __typename?: 'mobilePurchasingServiceOutput', status: boolean, payment_link?: string | null, error?: string | null } | null };

export type PurchaseRequestRetryMutationVariables = Exact<{
  order_id: Scalars['String']['input'];
}>;


export type PurchaseRequestRetryMutation = { __typename?: 'mutation_root', mobilePurchasingServiceRetry?: { __typename?: 'mobilePurchasingServiceRetryOutput', status: boolean, payment_link?: string | null, error?: string | null } | null };

export type SendFreeGuideMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  email: Scalars['String']['input'];
  full_name: Scalars['String']['input'];
  program_name: Scalars['String']['input'];
}>;


export type SendFreeGuideMutation = { __typename?: 'mutation_root', mobileGetFreeGuide?: { __typename?: 'mobileGetFreeGuideOutput', error?: string | null, status?: string | null } | null };

export type GetProductsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['bigint']['input']> | Scalars['bigint']['input'];
}>;


export type GetProductsByIdsQuery = { __typename?: 'query_root', mobile_services: Array<{ __typename?: 'mobile_services', id: any, service_type_id?: any | null, user_id?: any | null, title?: string | null, message?: string | null, detailed_message?: string | null, price?: any | null, quantity?: any | null, service_periodicity?: { __typename?: 'mobile_service_periodicities', name?: string | null } | null, service_images: Array<{ __typename?: 'mobile_service_images', file_path?: string | null }>, service_documents: Array<{ __typename?: 'mobile_service_documents', id: any, document_size?: any | null, document_path?: string | null, created_at: any }>, service_tariffs: Array<{ __typename?: 'mobile_service_tariffs', created_at: any, id: any, name?: string | null, price?: any | null, service_lists: Array<{ __typename?: 'mobile_service_lists', id: any, title?: string | null, description?: string | null, as_gift?: boolean | null, price?: any | null, quantity?: any | null, service_list_periodicity?: { __typename?: 'mobile_service_list_periodicities', name?: string | null } | null }> }> }> };

export type GetProductsByTagsQueryVariables = Exact<{
  tag_target_groups: Array<Scalars['bigint']['input']> | Scalars['bigint']['input'];
  tag_ages: Array<Scalars['bigint']['input']> | Scalars['bigint']['input'];
  tag_targets: Array<Scalars['bigint']['input']> | Scalars['bigint']['input'];
}>;


export type GetProductsByTagsQuery = { __typename?: 'query_root', mobile_services: Array<{ __typename?: 'mobile_services', id: any, service_type_id?: any | null, user_id?: any | null, title?: string | null, message?: string | null, detailed_message?: string | null, price?: any | null, quantity?: any | null, service_periodicity?: { __typename?: 'mobile_service_periodicities', name?: string | null } | null, service_images: Array<{ __typename?: 'mobile_service_images', file_path?: string | null }>, service_documents: Array<{ __typename?: 'mobile_service_documents', id: any, document_size?: any | null, document_path?: string | null, created_at: any }>, service_tariffs: Array<{ __typename?: 'mobile_service_tariffs', created_at: any, id: any, name?: string | null, price?: any | null, service_lists: Array<{ __typename?: 'mobile_service_lists', id: any, title?: string | null, description?: string | null, price?: any | null, as_gift?: boolean | null, quantity?: any | null, service_list_periodicity?: { __typename?: 'mobile_service_list_periodicities', name?: string | null } | null }>, service?: { __typename?: 'mobile_services', id: any, instruction?: string | null, message?: string | null, service_periodicity?: { __typename?: 'mobile_service_periodicities', name?: string | null } | null, service_tag_ages: Array<{ __typename?: 'mobile_service_tag_ages', service_id?: any | null, tag_age_id?: any | null, tag_age?: { __typename?: 'mobile_tag_ages', id: any, name?: string | null } | null }>, service_tag_targets: Array<{ __typename?: 'mobile_service_tag_targets', tag_target?: { __typename?: 'mobile_tag_targets', id: any, name?: string | null } | null }>, service_tag_target_groups: Array<{ __typename?: 'mobile_service_tag_target_groups', id: any, tag_target_group?: { __typename?: 'mobile_tag_target_groups', name?: string | null } | null }>, service_images: Array<{ __typename?: 'mobile_service_images', file_path?: string | null }> } | null }> }> };

export type GetProductsByTagsIncompleteQueryVariables = Exact<{
  tag_ages: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
  tag_target_groups: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
  tag_targets: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
}>;


export type GetProductsByTagsIncompleteQuery = { __typename?: 'query_root', mobileGetServiceTariffs?: { __typename?: 'mobileGetServiceTariffsOutput', service_tariffs?: Array<{ __typename?: 'mobileGetServiceTariffsTariffOutput', created_at?: any | null, id?: number | null, name?: string | null, service_lists?: Array<{ __typename?: 'mobileGetServiceTariffsServiceListsOutput', as_gift?: boolean | null, price?: number | null, service_list_periodicity?: { __typename?: 'ServiceListPeriodicityOutput', id?: number | null, name?: string | null } | null } | null> | null, service?: Array<{ __typename?: 'mobileGetServiceTariffsTariffService', id?: number | null, service_type_id?: number | null, user_id?: number | null, title?: string | null, price?: number | null, description?: string | null, service_periodicity?: { __typename?: 'ServicePeriodicity', name?: string | null } | null, service_tag_ages?: Array<{ __typename?: 'ServiceTagAges', service_id?: number | null, tag_age_id?: number | null, tag_age?: { __typename?: 'TagAge', id?: number | null, name?: string | null } | null } | null> | null, service_tag_targets?: Array<{ __typename?: 'ServciceTagTargets', tag_target?: { __typename?: 'TagTarget', id?: number | null, name?: string | null } | null } | null> | null, service_tag_target_groups?: Array<{ __typename?: 'ServiceTagTargetGroups', id?: number | null, tag_target_group?: { __typename?: 'TagTargetGroup', name?: string | null } | null } | null> | null, service_images?: Array<{ __typename?: 'ServiceImages', file_path?: string | null } | null> | null } | null> | null } | null> | null } | null };

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { __typename?: 'query_root', mobile_tag_target_groups: Array<{ __typename?: 'mobile_tag_target_groups', id: any, tag_id?: any | null, name?: string | null, color?: string | null, picture_path?: string | null, output_order?: number | null }>, mobile_tag_ages: Array<{ __typename?: 'mobile_tag_ages', id: any, tag_id?: any | null, name?: string | null, color?: string | null, output_order?: number | null }>, mobile_tag_targets: Array<{ __typename?: 'mobile_tag_targets', id: any, tag_id?: any | null, name?: string | null, color?: string | null, output_order?: number | null, picture_path?: string | null }> };

export type GetTariffsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['bigint']['input']> | Scalars['bigint']['input'];
}>;


export type GetTariffsByIdsQuery = { __typename?: 'query_root', mobile_service_tariffs: Array<{ __typename?: 'mobile_service_tariffs', created_at: any, id: any, name?: string | null, price?: any | null, service_id?: any | null, user_id?: any | null, service?: { __typename?: 'mobile_services', id: any, service_type_id?: any | null, user_id?: any | null, title?: string | null, message?: string | null, detailed_message?: string | null, price?: any | null, quantity?: any | null, service_periodicity?: { __typename?: 'mobile_service_periodicities', name?: string | null } | null, service_images: Array<{ __typename?: 'mobile_service_images', file_path?: string | null }>, service_documents: Array<{ __typename?: 'mobile_service_documents', id: any, document_size?: any | null, document_path?: string | null, created_at: any }> } | null, service_lists: Array<{ __typename?: 'mobile_service_lists', id: any, title?: string | null, description?: string | null, as_gift?: boolean | null, price?: any | null, quantity?: any | null, service_list_periodicity?: { __typename?: 'mobile_service_list_periodicities', name?: string | null } | null }> }> };

export type PaymentStatusQueryVariables = Exact<{
  order_id: Scalars['String']['input'];
}>;


export type PaymentStatusQuery = { __typename?: 'query_root', mobileGetStatusMedicalConciergeBilling?: { __typename?: 'mobileGetStatusMedicalConciergeBillingOutput', status?: string | null, email?: string | null, program_name?: string | null, period?: number | null, error?: string | null } | null };


export const AmocrmCreateLeadDocument = gql`
    mutation AmocrmCreateLead($email_or_phone: String!, $period: Int!, $program_name: String!, $user_name: String, $tags: [mobileAmocrmCreateLeadTagsInput]!) {
  mobileAmocrmCreateLead(
    arg: {email_or_phone: $email_or_phone, period: $period, program_name: $program_name, user_name: $user_name, tags: $tags}
  ) {
    error
    status
  }
}
    `;
export type AmocrmCreateLeadMutationFn = Apollo.MutationFunction<AmocrmCreateLeadMutation, AmocrmCreateLeadMutationVariables>;

/**
 * __useAmocrmCreateLeadMutation__
 *
 * To run a mutation, you first call `useAmocrmCreateLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmocrmCreateLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amocrmCreateLeadMutation, { data, loading, error }] = useAmocrmCreateLeadMutation({
 *   variables: {
 *      email_or_phone: // value for 'email_or_phone'
 *      period: // value for 'period'
 *      program_name: // value for 'program_name'
 *      user_name: // value for 'user_name'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useAmocrmCreateLeadMutation(baseOptions?: Apollo.MutationHookOptions<AmocrmCreateLeadMutation, AmocrmCreateLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AmocrmCreateLeadMutation, AmocrmCreateLeadMutationVariables>(AmocrmCreateLeadDocument, options);
      }
export type AmocrmCreateLeadMutationHookResult = ReturnType<typeof useAmocrmCreateLeadMutation>;
export type AmocrmCreateLeadMutationResult = Apollo.MutationResult<AmocrmCreateLeadMutation>;
export type AmocrmCreateLeadMutationOptions = Apollo.BaseMutationOptions<AmocrmCreateLeadMutation, AmocrmCreateLeadMutationVariables>;
export const PurchaseRequestDocument = gql`
    mutation PurchaseRequest($email: String!, $fio: String!, $phone: String!, $comment: String, $service_tariff_id: Int!) {
  mobilePurchasingService(
    arg: {source: "landing", email: $email, fio: $fio, phone: $phone, comment: $comment, service_tariff_id: $service_tariff_id}
  ) {
    status
    payment_link
    error
  }
}
    `;
export type PurchaseRequestMutationFn = Apollo.MutationFunction<PurchaseRequestMutation, PurchaseRequestMutationVariables>;

/**
 * __usePurchaseRequestMutation__
 *
 * To run a mutation, you first call `usePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseRequestMutation, { data, loading, error }] = usePurchaseRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *      fio: // value for 'fio'
 *      phone: // value for 'phone'
 *      comment: // value for 'comment'
 *      service_tariff_id: // value for 'service_tariff_id'
 *   },
 * });
 */
export function usePurchaseRequestMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseRequestMutation, PurchaseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseRequestMutation, PurchaseRequestMutationVariables>(PurchaseRequestDocument, options);
      }
export type PurchaseRequestMutationHookResult = ReturnType<typeof usePurchaseRequestMutation>;
export type PurchaseRequestMutationResult = Apollo.MutationResult<PurchaseRequestMutation>;
export type PurchaseRequestMutationOptions = Apollo.BaseMutationOptions<PurchaseRequestMutation, PurchaseRequestMutationVariables>;
export const PurchaseRequestRetryDocument = gql`
    mutation PurchaseRequestRetry($order_id: String!) {
  mobilePurchasingServiceRetry(arg: {order_id: $order_id}) {
    status
    payment_link
    error
  }
}
    `;
export type PurchaseRequestRetryMutationFn = Apollo.MutationFunction<PurchaseRequestRetryMutation, PurchaseRequestRetryMutationVariables>;

/**
 * __usePurchaseRequestRetryMutation__
 *
 * To run a mutation, you first call `usePurchaseRequestRetryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseRequestRetryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseRequestRetryMutation, { data, loading, error }] = usePurchaseRequestRetryMutation({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function usePurchaseRequestRetryMutation(baseOptions?: Apollo.MutationHookOptions<PurchaseRequestRetryMutation, PurchaseRequestRetryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PurchaseRequestRetryMutation, PurchaseRequestRetryMutationVariables>(PurchaseRequestRetryDocument, options);
      }
export type PurchaseRequestRetryMutationHookResult = ReturnType<typeof usePurchaseRequestRetryMutation>;
export type PurchaseRequestRetryMutationResult = Apollo.MutationResult<PurchaseRequestRetryMutation>;
export type PurchaseRequestRetryMutationOptions = Apollo.BaseMutationOptions<PurchaseRequestRetryMutation, PurchaseRequestRetryMutationVariables>;
export const SendFreeGuideDocument = gql`
    mutation SendFreeGuide($phone: String!, $email: String!, $full_name: String!, $program_name: String!) {
  mobileGetFreeGuide(
    arg: {phone: $phone, email: $email, full_name: $full_name, program_name: $program_name}
  ) {
    error
    status
  }
}
    `;
export type SendFreeGuideMutationFn = Apollo.MutationFunction<SendFreeGuideMutation, SendFreeGuideMutationVariables>;

/**
 * __useSendFreeGuideMutation__
 *
 * To run a mutation, you first call `useSendFreeGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFreeGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFreeGuideMutation, { data, loading, error }] = useSendFreeGuideMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      full_name: // value for 'full_name'
 *      program_name: // value for 'program_name'
 *   },
 * });
 */
export function useSendFreeGuideMutation(baseOptions?: Apollo.MutationHookOptions<SendFreeGuideMutation, SendFreeGuideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFreeGuideMutation, SendFreeGuideMutationVariables>(SendFreeGuideDocument, options);
      }
export type SendFreeGuideMutationHookResult = ReturnType<typeof useSendFreeGuideMutation>;
export type SendFreeGuideMutationResult = Apollo.MutationResult<SendFreeGuideMutation>;
export type SendFreeGuideMutationOptions = Apollo.BaseMutationOptions<SendFreeGuideMutation, SendFreeGuideMutationVariables>;
export const GetProductsByIdsDocument = gql`
    query GetProductsByIds($ids: [bigint!]!) {
  mobile_services(where: {id: {_in: $ids}}) {
    id
    service_type_id
    user_id
    title
    message
    detailed_message
    price
    service_periodicity {
      name
    }
    quantity
    service_images {
      file_path
    }
    service_documents {
      id
      document_size
      document_path
      created_at
    }
    service_tariffs {
      created_at
      id
      name
      price
      service_lists {
        id
        title
        description
        as_gift
        price
        service_list_periodicity {
          name
        }
        quantity
      }
    }
  }
}
    `;

/**
 * __useGetProductsByIdsQuery__
 *
 * To run a query within a React component, call `useGetProductsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetProductsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetProductsByIdsQuery, GetProductsByIdsQueryVariables> & ({ variables: GetProductsByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsByIdsQuery, GetProductsByIdsQueryVariables>(GetProductsByIdsDocument, options);
      }
export function useGetProductsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsByIdsQuery, GetProductsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsByIdsQuery, GetProductsByIdsQueryVariables>(GetProductsByIdsDocument, options);
        }
export function useGetProductsByIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductsByIdsQuery, GetProductsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsByIdsQuery, GetProductsByIdsQueryVariables>(GetProductsByIdsDocument, options);
        }
export type GetProductsByIdsQueryHookResult = ReturnType<typeof useGetProductsByIdsQuery>;
export type GetProductsByIdsLazyQueryHookResult = ReturnType<typeof useGetProductsByIdsLazyQuery>;
export type GetProductsByIdsSuspenseQueryHookResult = ReturnType<typeof useGetProductsByIdsSuspenseQuery>;
export type GetProductsByIdsQueryResult = Apollo.QueryResult<GetProductsByIdsQuery, GetProductsByIdsQueryVariables>;
export const GetProductsByTagsDocument = gql`
    query GetProductsByTags($tag_target_groups: [bigint!]!, $tag_ages: [bigint!]!, $tag_targets: [bigint!]!) {
  mobile_services(
    where: {service_tag_target_groups: {tag_target_group_id: {_in: $tag_target_groups}}, service_tag_ages: {tag_age_id: {_in: $tag_ages}}, service_tag_targets: {tag_target_id: {_in: $tag_targets}}, service_images: {file_path: {}}, service_status_id: {_eq: "3"}}
  ) {
    id
    service_type_id
    user_id
    title
    message
    detailed_message
    price
    service_periodicity {
      name
    }
    quantity
    service_images {
      file_path
    }
    service_documents {
      id
      document_size
      document_path
      created_at
    }
    service_tariffs {
      created_at
      id
      name
      price
      service_lists {
        id
        title
        description
        price
        as_gift
        quantity
        service_list_periodicity {
          name
        }
      }
      service {
        id
        instruction
        message
        service_periodicity {
          name
        }
        service_tag_ages {
          service_id
          tag_age_id
          tag_age {
            id
            name
          }
        }
        service_tag_targets {
          tag_target {
            id
            name
          }
        }
        service_tag_target_groups {
          id
          tag_target_group {
            name
          }
        }
        service_images {
          file_path
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductsByTagsQuery__
 *
 * To run a query within a React component, call `useGetProductsByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByTagsQuery({
 *   variables: {
 *      tag_target_groups: // value for 'tag_target_groups'
 *      tag_ages: // value for 'tag_ages'
 *      tag_targets: // value for 'tag_targets'
 *   },
 * });
 */
export function useGetProductsByTagsQuery(baseOptions: Apollo.QueryHookOptions<GetProductsByTagsQuery, GetProductsByTagsQueryVariables> & ({ variables: GetProductsByTagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsByTagsQuery, GetProductsByTagsQueryVariables>(GetProductsByTagsDocument, options);
      }
export function useGetProductsByTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsByTagsQuery, GetProductsByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsByTagsQuery, GetProductsByTagsQueryVariables>(GetProductsByTagsDocument, options);
        }
export function useGetProductsByTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductsByTagsQuery, GetProductsByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsByTagsQuery, GetProductsByTagsQueryVariables>(GetProductsByTagsDocument, options);
        }
export type GetProductsByTagsQueryHookResult = ReturnType<typeof useGetProductsByTagsQuery>;
export type GetProductsByTagsLazyQueryHookResult = ReturnType<typeof useGetProductsByTagsLazyQuery>;
export type GetProductsByTagsSuspenseQueryHookResult = ReturnType<typeof useGetProductsByTagsSuspenseQuery>;
export type GetProductsByTagsQueryResult = Apollo.QueryResult<GetProductsByTagsQuery, GetProductsByTagsQueryVariables>;
export const GetProductsByTagsIncompleteDocument = gql`
    query GetProductsByTagsIncomplete($tag_ages: [Int]!, $tag_target_groups: [Int]!, $tag_targets: [Int]!) {
  mobileGetServiceTariffs(
    arg: {tag_ages: $tag_ages, tag_target_groups: $tag_target_groups, tag_targets: $tag_targets}
  ) {
    service_tariffs {
      created_at
      id
      name
      service_lists {
        as_gift
        price
        service_list_periodicity {
          id
          name
        }
      }
      service {
        id
        service_type_id
        user_id
        title
        price
        description
        service_periodicity {
          name
        }
        service_tag_ages {
          service_id
          tag_age_id
          tag_age {
            id
            name
          }
        }
        service_tag_targets {
          tag_target {
            id
            name
          }
        }
        service_tag_target_groups {
          id
          tag_target_group {
            name
          }
        }
        service_images {
          file_path
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductsByTagsIncompleteQuery__
 *
 * To run a query within a React component, call `useGetProductsByTagsIncompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByTagsIncompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByTagsIncompleteQuery({
 *   variables: {
 *      tag_ages: // value for 'tag_ages'
 *      tag_target_groups: // value for 'tag_target_groups'
 *      tag_targets: // value for 'tag_targets'
 *   },
 * });
 */
export function useGetProductsByTagsIncompleteQuery(baseOptions: Apollo.QueryHookOptions<GetProductsByTagsIncompleteQuery, GetProductsByTagsIncompleteQueryVariables> & ({ variables: GetProductsByTagsIncompleteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsByTagsIncompleteQuery, GetProductsByTagsIncompleteQueryVariables>(GetProductsByTagsIncompleteDocument, options);
      }
export function useGetProductsByTagsIncompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsByTagsIncompleteQuery, GetProductsByTagsIncompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsByTagsIncompleteQuery, GetProductsByTagsIncompleteQueryVariables>(GetProductsByTagsIncompleteDocument, options);
        }
export function useGetProductsByTagsIncompleteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductsByTagsIncompleteQuery, GetProductsByTagsIncompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsByTagsIncompleteQuery, GetProductsByTagsIncompleteQueryVariables>(GetProductsByTagsIncompleteDocument, options);
        }
export type GetProductsByTagsIncompleteQueryHookResult = ReturnType<typeof useGetProductsByTagsIncompleteQuery>;
export type GetProductsByTagsIncompleteLazyQueryHookResult = ReturnType<typeof useGetProductsByTagsIncompleteLazyQuery>;
export type GetProductsByTagsIncompleteSuspenseQueryHookResult = ReturnType<typeof useGetProductsByTagsIncompleteSuspenseQuery>;
export type GetProductsByTagsIncompleteQueryResult = Apollo.QueryResult<GetProductsByTagsIncompleteQuery, GetProductsByTagsIncompleteQueryVariables>;
export const GetTagsDocument = gql`
    query GetTags {
  mobile_tag_target_groups {
    id
    tag_id
    name
    color
    picture_path
    output_order
  }
  mobile_tag_ages {
    id
    tag_id
    name
    color
    output_order
  }
  mobile_tag_targets {
    id
    tag_id
    name
    color
    output_order
    picture_path
  }
}
    `;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export function useGetTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsSuspenseQueryHookResult = ReturnType<typeof useGetTagsSuspenseQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetTariffsByIdsDocument = gql`
    query GetTariffsByIds($ids: [bigint!]!) {
  mobile_service_tariffs(where: {id: {_in: $ids}}) {
    created_at
    id
    name
    price
    service_id
    user_id
    service {
      id
      service_type_id
      user_id
      title
      message
      detailed_message
      price
      service_periodicity {
        name
      }
      quantity
      service_images {
        file_path
      }
      service_documents {
        id
        document_size
        document_path
        created_at
      }
    }
    service_lists {
      id
      title
      description
      as_gift
      price
      service_list_periodicity {
        name
      }
      quantity
    }
  }
}
    `;

/**
 * __useGetTariffsByIdsQuery__
 *
 * To run a query within a React component, call `useGetTariffsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetTariffsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetTariffsByIdsQuery, GetTariffsByIdsQueryVariables> & ({ variables: GetTariffsByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffsByIdsQuery, GetTariffsByIdsQueryVariables>(GetTariffsByIdsDocument, options);
      }
export function useGetTariffsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffsByIdsQuery, GetTariffsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffsByIdsQuery, GetTariffsByIdsQueryVariables>(GetTariffsByIdsDocument, options);
        }
export function useGetTariffsByIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTariffsByIdsQuery, GetTariffsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTariffsByIdsQuery, GetTariffsByIdsQueryVariables>(GetTariffsByIdsDocument, options);
        }
export type GetTariffsByIdsQueryHookResult = ReturnType<typeof useGetTariffsByIdsQuery>;
export type GetTariffsByIdsLazyQueryHookResult = ReturnType<typeof useGetTariffsByIdsLazyQuery>;
export type GetTariffsByIdsSuspenseQueryHookResult = ReturnType<typeof useGetTariffsByIdsSuspenseQuery>;
export type GetTariffsByIdsQueryResult = Apollo.QueryResult<GetTariffsByIdsQuery, GetTariffsByIdsQueryVariables>;
export const PaymentStatusDocument = gql`
    query PaymentStatus($order_id: String!) {
  mobileGetStatusMedicalConciergeBilling(arg: {order_id: $order_id}) {
    status
    email
    program_name
    period
    error
  }
}
    `;

/**
 * __usePaymentStatusQuery__
 *
 * To run a query within a React component, call `usePaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentStatusQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function usePaymentStatusQuery(baseOptions: Apollo.QueryHookOptions<PaymentStatusQuery, PaymentStatusQueryVariables> & ({ variables: PaymentStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(PaymentStatusDocument, options);
      }
export function usePaymentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentStatusQuery, PaymentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(PaymentStatusDocument, options);
        }
export function usePaymentStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentStatusQuery, PaymentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(PaymentStatusDocument, options);
        }
export type PaymentStatusQueryHookResult = ReturnType<typeof usePaymentStatusQuery>;
export type PaymentStatusLazyQueryHookResult = ReturnType<typeof usePaymentStatusLazyQuery>;
export type PaymentStatusSuspenseQueryHookResult = ReturnType<typeof usePaymentStatusSuspenseQuery>;
export type PaymentStatusQueryResult = Apollo.QueryResult<PaymentStatusQuery, PaymentStatusQueryVariables>;