import images from "../../../../utils/images";

export const aggregatorButtons = [
  { iconImage: images.salad,    title: 'Питание',      tagId: 3 },
  { iconImage: images.run,      title: 'Спорт',        tagId: 2 },
  { iconImage: images.hospital, title: 'Медицина',     tagId: 8 },
  { iconImage: images.pregnant, title: 'Беременность', tagId: 1 },
  { iconImage: images.stop,     title: 'Зависимости',  tagId: 6 },
  { iconImage: images.meditate, title: 'Психология',   tagId: 5 },
];

// 3 Правильное Питание 355603
// 2 Спорт и фитнес 355601
// 8 Лечение 355613
// 1 Беременность 355599
// 6 Вредные привычки 355609
// 5 Психологическая поддержка 355607

// 4 Эмоциональное выгорание 355605
// 7 Бросить курить 355611
// 9 Медицина за рубежом 355615
// 10 Сложные случаи 355617
// 11 Хронические заболевания 355621
// 12 Депрессия 355623
// 13 Апатия 355625
// 14 Отказаться от алкоголя 355627
// 15 Проблемы со сном 355629
// 16 Гнев 355631
// 17 Профилактика 355633