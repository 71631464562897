import { NetworkStatus } from "@apollo/client";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { GetTagsQuery, useGetTagsLazyQuery } from "../../gql/graphqlWithHooks";
import { structureAgeRanges, structureCategories, structureTagTargets } from "../../pages/MainPage/components/1.Aggregator/aggregatorUtils";
import { setFetching, setStructuredTagsData, setTagImageUrlsForCaching } from "../../redux/aggregatorReducer";
import { useAppSelector } from "../../redux/store";
import { networkErrorMessage } from "../appConstants";
import { cacheImages } from "../functions/cacheImages";
import { getFileUrl } from "../requestApi";

const getTagIconSources = (tagsData?: GetTagsQuery) => {
  const imageSources: string[] = [];

  tagsData?.mobile_tag_target_groups.forEach((item) => {
    if (item.picture_path) {
      imageSources.push(getFileUrl(item.picture_path));
    }
  });
  
  tagsData?.mobile_tag_targets.forEach((item) => {
    if (item.picture_path) {
      imageSources.push(getFileUrl(item.picture_path));
    }
  });

  return imageSources;
};

export const useGetAndStoreTags = () => {
  const dispatch = useDispatch();
  const tagImageUrlsForCaching = useAppSelector((state) => state.aggregator.tagImageUrlsForCaching);
  const [getTagsLazyQuery] = useGetTagsLazyQuery();

  const { categoryIds, ageRangeIds, tagTargetIds } = useAppSelector(
    (state) => state.aggregator.structuredTagsData
  );

  const getAndStoreTags = async () => {
    dispatch(setFetching(true));

    const allTagArraysArePopulated = !isEmpty(categoryIds) && !isEmpty(ageRangeIds) && !isEmpty(tagTargetIds);
    if (allTagArraysArePopulated) {
      await cacheImages(tagImageUrlsForCaching);
      dispatch(setFetching(false));
      return {
        fetchedCategoryIds: categoryIds,
        fetchedAgeRangeIds: ageRangeIds,
        fetchedTagTargetIds: tagTargetIds,
      };
    }


    const { data: tagsData, networkStatus } = await getTagsLazyQuery({ fetchPolicy: 'cache-and-network' });
    if (networkStatus === NetworkStatus.error) alert(networkErrorMessage);

    const {
     mobile_tag_target_groups: categories,
     mobile_tag_ages: ageRanges,
     mobile_tag_targets: tagTargets,
    } = { ...tagsData };
  
    const fetchedCategoryIds = categories?.map(item => item.id);
    const fetchedAgeRangeIds = ageRanges?.map(item => item.id);
    const fetchedTagTargetIds = tagTargets?.map(item => item.id);

    dispatch(setStructuredTagsData({
      categories: structureCategories(categories || []),
      ageRanges: structureAgeRanges(ageRanges || []),
      tagTargets: structureTagTargets(tagTargets || []),
      categoryIds: fetchedCategoryIds!,
      ageRangeIds: fetchedAgeRangeIds!,
      tagTargetIds: fetchedTagTargetIds!,
      // categoryIds: categories!.map(item => item.tag_id),
      // ageRangeIds: ageRanges!.map(item => item.tag_id),
      // tagTargetIds: tagTargets!.map(item => item.tag_id),
    }))
  
    const imageSources = getTagIconSources(tagsData);
    dispatch(setTagImageUrlsForCaching(imageSources));
    await cacheImages(imageSources);
  
    dispatch(setFetching(false));

    return { fetchedCategoryIds, fetchedAgeRangeIds, fetchedTagTargetIds };
  };

  return { getAndStoreTags };
}

