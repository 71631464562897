import { ConsultCallData } from "./types";

export const isProd = process.env.REACT_APP_ENV === 'prod';
export const baseUrl = isProd ? 'https://api.etnamed.ru' : 'https://dev.api.etnamed.ru';

export const getFileUrl = (fetchedRelativePath: string) => {
  const baseFilesUrl = isProd ? 'https://app.etnamed.ru' : 'https://dev.app.etnamed.ru';
  return baseFilesUrl.concat(fetchedRelativePath);
};

const consultProductDevName = 'Консультация';
export const defaultConsultCallData: ConsultCallData = {
  programDevName: consultProductDevName,
  durationInMonths: 0,
  tags: [],
}
