import AppSpinner from '../../../components/AppSpinner/AppSpinner';
import { colors } from '../../../utils/themeConstants';
import './SearchPageLoader.scss';

const SearchPageLoader = () => {
  return (
    <div className="searchPageLoaderContainer">
      <AppSpinner/>
      <div className="searchPageLoaderText">{'Подбираем персональную программу'}</div>
    </div>
  );
};

export default SearchPageLoader;