import { CSSProperties } from 'react';
import { colors } from '../../../../../utils/themeConstants';
import './AggregatorQuickSearchButtonVisual.scss';

const selectedStyle: CSSProperties = {
  backgroundColor: 'white',
  outline: `1px solid ${colors.accent}`,
}

const AggregatorQuickSearchButtonVisual = ({
  iconImage,
  title,
  usingMobileStyles = false,
  isSelected,
}: {
  iconImage: string;
  title: string;
  usingMobileStyles?: boolean;
  isSelected?: boolean,
}) => {
  const containerClass = usingMobileStyles
    ? 'aggregatorQuickSearchButtonVisualOuterContainerMobile'
    : 'aggregatorQuickSearchButtonVisualOuterContainer';

  const iconContainerClass = usingMobileStyles
  ? 'aggregatorQuickSearchButtonVisualIconContainerMobile'
  : 'aggregatorQuickSearchButtonVisualIconContainer';

  const titleClass = usingMobileStyles
  ? 'aggregatorQuickSearchButtonVisualTitleMobile'
  : 'aggregatorQuickSearchButtonVisualTitle';

  return (
    <div className={containerClass}>
      <div className={iconContainerClass} style={isSelected ? selectedStyle : {}}>
        <img className="aggregatorQuickSearchButtonVisualIcon" src={iconImage} />
      </div>
      <div className={titleClass}>{title}</div>
    </div>
  );
};

export default AggregatorQuickSearchButtonVisual;
