import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import AppSpinner from '../../../../../../components/AppSpinner/AppSpinner';
import { useAppSelector } from '../../../../../../redux/store';
import { PagePath } from '../../../../../../utils/enums';
import { useAggregatorMenuModal } from '../../../../../../utils/hooks/useAggregatorMenuModal';
import { getGoalsCountText } from '../../../../../../utils/stringHelper';
import AggregatorTagTargetsModal from '../modals/AggregatorTagTargetsModal';
import AggregatorMenuArrow from './AggregatorMenuArrow';
import './AggregatorSearchBlockMenus.scss';

const AggregatorTagTargetsMenu = ({
  flex = 1,
  usingFullScreenModal = false,
  menuAsModal = true,
  usingAltDesign,
  setTagTargetsMenuIsOpen,
}: {
  flex?: number;
  usingFullScreenModal?: boolean;
  menuAsModal?: boolean;
  usingAltDesign?: boolean;
  setTagTargetsMenuIsOpen?: (b: boolean) => void;
}) => {
  const location = useLocation();
  const showTagTargetsCounter = useMemo(() => location.pathname === PagePath.MainPage, []);

  const { modalIsOpen, closeModal, toggleModal } = useAggregatorMenuModal({ usingFullScreenModal });
  const { fetching, structuredTagsData, selectedTagTargetIds } = useAppSelector((state) => state.aggregator);

  const selectedTagTargets = structuredTagsData.tagTargets.filter((item) =>
    selectedTagTargetIds.includes(item.id)
  );

  const selectedTagsCount = selectedTagTargetIds.length;

  const emptyGoalsFieldText = usingAltDesign ? 'Ваши цели' : 'Выберите цели';
  const goalsFieldText = selectedTagsCount ? getGoalsCountText(selectedTagsCount) : emptyGoalsFieldText;

  const onMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setTagTargetsMenuIsOpen?.(!modalIsOpen);
    toggleModal();
  }

  const closeModalInner = () => {
    setTagTargetsMenuIsOpen?.(false);
    closeModal();
  }

  return (
    <div className="aggregatorSearchBlockFieldGoalsContainerOuter" style={{ flex }}>
      <button
        className="aggregatorSearchBlockFieldGoalsContainer"
        style={{ ...(!menuAsModal && { overflowX: 'visible' }) }}
        onClick={onMenuButtonClick}
        disabled={fetching}
      >
        <div className="aggregatorSearchBlockFieldTitle">
          {`Цели`}
          {!usingAltDesign && selectedTagsCount && showTagTargetsCounter ? (
            <span className="highlighted">{` (${selectedTagsCount})`}</span>
          ) : null}
        </div>

        <div
          className="aggregatorSearchBlockGoalsFieldContainerInner"
          style={{ borderColor: modalIsOpen ? '#0EC1C9' : undefined }}
        >
          <div className="aggregatorSearchBlockGoalsFieldContent">
            {fetching ? (
              <AppSpinner/>
            ) : selectedTagsCount && !usingAltDesign ? (
              selectedTagTargets.map((tagTarget) => (
                <div key={tagTarget.name} className="aggregatorSearchBlockGoalFieldItem">
                  <img className="aggregatorSearchBlockFieldSelectedItemIcon" src={tagTarget.iconSource} />
                  {tagTarget.name}
                </div>
              ))
            ) : (
              <div
                className="aggregatorSearchBlockFieldSelectedItemName"
                style={{ color: selectedTagsCount ? '#0EC1C9' : undefined }}
              >
                {goalsFieldText}
              </div>
            )}
          </div>

          <AggregatorMenuArrow modalIsOpen={modalIsOpen} />
        </div>
      </button>
      
      {!usingFullScreenModal && (
        <AggregatorTagTargetsModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModalInner}
          asModal={menuAsModal}
        />
      )}
    </div>
  );
};

export default AggregatorTagTargetsMenu;