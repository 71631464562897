import React from "react";
import { GetProductsByIdsQuery, GetTariffsByIdsQuery } from "../gql/graphqlWithHooks";

type CSSProperties = {
  [key:string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

export interface SocialMediaButtonData {
  icon: string;
  hoverIcon: string;
  href: string;
}

export interface MenuItem {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface DimensionsData {
  screenSize: { width: number; height: number; }
  isVerticallyOriented: boolean;
}

export type FetchedGraphqlProductData = GetProductsByIdsQuery['mobile_services'][0];
export type GetProductsQueryTariff = FetchedGraphqlProductData['service_tariffs'][0];
export type GetProductsQueryTariffService = FetchedGraphqlProductData['service_tariffs'][0]['service_lists'][0];

export type FetchedGraphqlTariffData = GetTariffsByIdsQuery['mobile_service_tariffs'][0];

interface ProductDocumentData {
  documentId: number;
  documentCreationDate: string;
  documentName: string;
  documentDisplayedName: string;
  documentExtension: string;
  documentUrl: string;
  documentSize: number;
  documentSizeText: string;
}

export interface GraphqlProductData {
  productId: number;
  serviceTypeId: number;
  productImages: string[];
  productDocuments: ProductDocumentData[];

  userId: string;

  title: string;
  descriptionShort: string;
  descriptionLong: string;
  price: string;

  periodicityName?: string | null;
  quantity?: number | null;
  
  tariffs: GraphqlTariffData[];
}

export interface GraphqlTariffData {
  serviceTypeId: number;
  userId: string;
  productId: number;
  productTitle: string;
  productPrice: number;
  productImages: string[];
  productDocuments: ProductDocumentData[];

  periodicityName?: string | null;
  quantity?: number | null;
  durationText: string;
  descriptionShort: string;
  descriptionLong: string;
  
  tariffId: number;
  tariffName: string;
  tariffPrice: number;
  tariffPriceText: string;
  tariffPurchaseButtonText: string;
  
  tariffDataRaw: GetProductsQueryTariff;
}

export enum ProductsSortType {
  PopularFirst = 'PopularFirst',
  PriceAscending = 'PriceAscending',
  PriceDescending = 'PriceDescending',
}

export interface ConsultCallData {
  programDevName: string;
  durationInMonths: number;
  tags: string[];
}

// request data types
export enum OrderStatus {
  InProgress = 'wait',
  Success = 'paid',
  Error = 'error',
}

export interface PaymentProcessingOrderData {
  orderStatus: OrderStatus;
  productTitle?: string;
  productQuantity?: number;
  userEmail?: string;
}