import images from '../../../../../../utils/images';
import './AggregatorMenuArrow.scss';

const AggregatorMenuArrow = ({ modalIsOpen }: { modalIsOpen: boolean }) => {
  return (
    <div
      className="aggregatorMenuArrowFadeOutContainer"
      style={{ borderColor: modalIsOpen ? '#0EC1C9' : undefined }}
    >
      <img
        className="aggregatorMenuArrowArrow"
        src={images.aggregatorMenuArrowDown}
        style={{ transform: modalIsOpen ? 'rotate(-90deg)' : undefined }}
      />
    </div>
  );
};

export default AggregatorMenuArrow;
