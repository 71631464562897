import { useRef, useState } from 'react';
import { getImageUrlForCss } from '../../../utils/stringHelper';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ItemDescriptionPageMainBannerCards.scss';

const ItemDescriptionPageMainBannerCards = ({ productImages }: { productImages: string[] }) => {
  const sliderRef = useRef<Slider | null>(null);
  const [selectedCardId, setSelectedCardId] = useState(0);

  const onCardClick = (id: number) => {
    sliderRef.current?.slickGoTo(id);
    setSelectedCardId(id)
  }

  return (
    <div className="productDescriptionMainBannerCards">
      <Slider
        ref={sliderRef}
        className="productDescriptionMainBannerImageSlider"
        fade={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        waitForAnimate={false}
        arrows={false}
        autoplay={true}
        autoplaySpeed={2700}
        beforeChange={(oldIndex, newIndex) => {
          setSelectedCardId(newIndex);
        }}
      >
        {productImages.map((productImage) => (
          <div key={productImage} className="productDescriptionMainBannerImageContainer">
            <img className="productDescriptionMainBannerImage" src={productImage} />
          </div>
        ))}
      </Slider>

      <div className="productDescriptionMainBannerCardPreviewsContainer">
        {productImages.map((productImage, index) => {
          const isSelected = index === selectedCardId;

          const className = isSelected
            ? 'productDescriptionMainBannerCardPreviewSelected'
            : 'productDescriptionMainBannerCardPreview';

          return (
            <button
              key={index}
              className={className}
              style={{ backgroundImage: getImageUrlForCss(productImage) }}
              onClick={() => onCardClick(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ItemDescriptionPageMainBannerCards;
