import { useDispatch } from 'react-redux';
import { setSelectedCategoryId } from '../../../../../../redux/aggregatorReducer';
import { useAppSelector } from '../../../../../../redux/store';
import './AggregatorCategoriesModal.scss';
import { useCloseModalOnClickOutside } from '../../../../../../utils/hooks/useCloseModalOnClickOutside';
import { nonModalMenuStyles } from './nonModalMenuStyles';
import images from '../../../../../../utils/images';

const highlightColour = '#EAF3F4';

const AggregatorCategoriesModal = ({
  modalIsOpen,
  closeModal,
  asModal = true,
  withMobileStyles = false,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  asModal: boolean;
  withMobileStyles?: boolean;
}) => {
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });

  const { structuredTagsData: { categories },  selectedCategoryId } = useAppSelector(state => state.aggregator);

  const dispatch = useDispatch();
  const onSelectCategoryIdInner = (id: number) => {
    dispatch(setSelectedCategoryId(id));
    closeModal();
  }

  const containerStyle = withMobileStyles ? 'aggregatorCategoriesModalContainerMobile' : 'aggregatorCategoriesModalContainer';
  const itemContainerStyle = withMobileStyles ? 'aggregatorCategoriesModalItemMobile' : 'aggregatorCategoriesModalItem';
  const itemSubContainerStyle = withMobileStyles ? 'aggregatorCategoriesModalItemSubContainerMobile' : 'aggregatorCategoriesModalItemSubContainer';
  
  if (!modalIsOpen) return null;

  return (
    <div
      className={containerStyle}
      ref={modalRef}
      style={{ ...(!asModal && nonModalMenuStyles) }}
    >
      {categories.map((categoryItem) => {
        const isSelected = categoryItem.id === selectedCategoryId;

        return (
          <button
            key={categoryItem.id}
            className={itemContainerStyle}
            style={{ backgroundColor: isSelected && !withMobileStyles ? highlightColour : 'none' }}
            onClick={() => onSelectCategoryIdInner(categoryItem.id)}
          >
            {withMobileStyles && (
              <img
                className="aggregatorCategoriesModalItemCheckmarkMobile"
                src={isSelected ? images.aggregatorMenuChecked : images.aggregatorMenuUnchecked}
              />
            )}

            <div className={itemSubContainerStyle}>
              <div className="aggregatorCategoriesModalItemText">{categoryItem.name}</div>
              <img className="aggregatorCategoriesModalItemIcon" src={categoryItem.iconSource} />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default AggregatorCategoriesModal;
