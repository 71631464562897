import { useDispatch } from 'react-redux';
import { setSelectedAgeRangeId } from '../../../../../../redux/aggregatorReducer';
import { useAppSelector } from '../../../../../../redux/store';
import './AggregatorAgeRangesModal.scss';
import { useCloseModalOnClickOutside } from '../../../../../../utils/hooks/useCloseModalOnClickOutside';
import { nonModalMenuStyles } from './nonModalMenuStyles';

const highlightColour = '#3ACFD5';
const textHighlightColour = 'white';

const AggregatorAgeRangesModal = ({
  modalIsOpen,
  closeModal,
  asModal = true,
  usingDesktopVersion = false,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  asModal: boolean;
  usingDesktopVersion?: boolean;
}) => {
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });
  const { structuredTagsData: { ageRanges }, selectedAgeRangeId } = useAppSelector(state => state.aggregator);

  const dispatch = useDispatch();
  const onSelectAgeRangeInner = (id: number) => {
    dispatch(setSelectedAgeRangeId(id));
    closeModal();
  }
  
  const containerStyle = {
    ...usingDesktopVersion ? { right: -110 } : {},
    ...asModal ? { gridTemplateColumns: '1fr 1fr'} : nonModalMenuStyles,
  }

  if (!modalIsOpen) return null;

  return (
    <div
      className="aggregatorAgeRangesModalContainer"
      ref={modalRef}
      style={containerStyle}
    >
      {ageRanges.map((ageRange, index) => {
        const isSelected = ageRange.id === selectedAgeRangeId;
        const shortName = ageRange.id === 0 ? ageRange.name : ageRange.name.split(' ')?.[0];

        const isLast = index === ageRanges.length - 1;

        return (
          <button
            key={ageRange.id}
            className="aggregatorAgeRangesModalItem"
            style={{
              backgroundColor: isSelected ? highlightColour : 'none',
              gridColumn: isLast ? '1 / -1' : undefined,
            }}
            onClick={() => onSelectAgeRangeInner(ageRange.id)}
          >
            <div
              className="aggregatorAgeRangesModalItemText"
              style={{ color: isSelected ? textHighlightColour : undefined }}
            >
              {shortName}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default AggregatorAgeRangesModal;