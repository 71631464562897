import images from "./images";
import { SocialMediaButtonData } from "./types";

export const companyMail = 'sales@EtnaApp.global';
export const companyMailHref = `mailto:${companyMail}`;

export const companyPhone1 = '8 (800) 100-26-91';
export const companyPhone1Href = 'tel:88001002691';

export const companyPhone2 = '+7 (495) 181-09-04';
export const companyPhone2Href = 'tel:+74951810904';

export const privacyPolicyHref = 'https://etna.global/ru/privacy_policy.html';
// export const termsOfServiceHref = 'https://etna.global/ru/terms.html';
export const invalidPhoneNumberText = 'Некорректный номер';
export const invalidEmailText = 'Некорректный e-mail';

export const appStoreHref = 'https://apps.apple.com/ru/app/etna-app/id6465174352';
export const googlePlayHref = 'https://play.google.com/store/apps/details?id=com.etnamedmobileappclient';

export const telegramBotName = 'etna_support_bot';
export const telegramBotHref = `https://t.me/etnasupport_bot/`; // different

export const servicesAgreementHref = 'documents/oferta_medlabel.pdf';
export const agreementTerminationHref = 'documents/zayavlenie-na-rastorzhenie.docx';

const telegramCommunityHref = `https://t.me/etna_global`;
const vkGroupHref = `https://vk.com/etna_app`;

export const socialMediaButtons: SocialMediaButtonData[] = [
  {
    icon: images.telegramIcon,
    hoverIcon: images.telegramIconHover,
    href: telegramCommunityHref,
  },
  {
    icon: images.vkIcon,
    hoverIcon: images.vkIconHover,
    href: vkGroupHref,
  },
];

export const networkErrorMessage = 'Ошибка сети';