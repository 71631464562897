import { useMemo } from "react";
import { useAppSelector } from "../../redux/store";

export const useGetSelectedTagsFromStore = () => {
  const {
    structuredTagsData: {
      categories,
      ageRanges,
      tagTargets,
    },

    selectedCategoryId,
    selectedAgeRangeId,
    selectedTagTargetIds,
  } = useAppSelector(state => state.aggregator);

  const selectedTags = useMemo(
    () => {
      const selectedCategory   = categories.find(item => item.id === selectedCategoryId)?.name;
      const selectedAgeRange   =  ageRanges.find(item => item.id === selectedAgeRangeId)?.name;
      const selectedTagTargets = tagTargets
        .filter((item) => selectedTagTargetIds.includes(item.id))
        .map((item) => item.name);
    
      const result: string[] = [];
    
      if (selectedCategory) result.push(selectedCategory);
      if (selectedAgeRange) result.push(selectedAgeRange);
      if (selectedTagTargets.length) result.push(...selectedTagTargets);
      return result;
    },
    [selectedCategoryId, selectedAgeRangeId, selectedTagTargetIds, selectedTagTargetIds.length]
  );

  return selectedTags;
}