import { apolloClient } from "../..";
import {
  AmocrmCreateLeadDocument,
  AmocrmCreateLeadMutation,
  AmocrmCreateLeadMutationVariables,
} from '../../gql/graphqlWithHooks';

export const handleCallRequest = async ({
  userName,
  userPhone,
  programDevName,
  durationInMonths,
  tags,
}: {
  userName: string;
  userPhone: string;
  programDevName: string;
  durationInMonths: number;
  tags: string[];
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');
  const formattedTags: { tag: string }[] = tags.map(tag => ({ tag: tag }));

  const operationVariables: AmocrmCreateLeadMutationVariables = {
    user_name: userName || undefined,
    email_or_phone: `+${userPhoneFormatted}`,
    program_name: programDevName,
    period: durationInMonths,
    tags: formattedTags,
  };

  try {
    const response = await apolloClient.mutate<AmocrmCreateLeadMutation>({
      mutation: AmocrmCreateLeadDocument,
      variables: operationVariables,
    });

    // request resolved
    const responseData = response?.data?.mobileAmocrmCreateLead;
    const requestSuccessful = !!responseData?.status;
    return requestSuccessful;
  } catch (error) {
    console.error('handleCallRequest Error:', error);
    return false;
  }
};