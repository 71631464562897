import React from 'react';
import { baseColors } from '../../utils/themeConstants';
import AppSpinner from '../AppSpinner/AppSpinner';

const ButtonWithSpinner = ({
  title,
  fetching,
  fetchingTitle,
  onClick,
  className,
  spinnerSize = 10,
  spinnerColor = baseColors.white,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  fetchingTitle?: string;
  fetching: boolean;
  className: string;
  spinnerSize: number;
  spinnerColor?: string;
}) => {
  return (
    <button className={`${className} app-button`} disabled={fetching} onClick={onClick} {...rest}>
      {fetching && <AppSpinner color={'white'} size={spinnerSize} />}
      {fetching ? fetchingTitle && <>{fetchingTitle}</> : <>{title}</>}
    </button>
  );
};

export default ButtonWithSpinner;
