import PersonalProgramModal from '../../../components/PurchaseProductModal/PersonalProgramModal';
import { useModalStateHooks } from '../../../utils/hooks/useModalStateHooks';
import images from '../../../utils/images';
import './SearchPageResultItem.scss';

const description = 'Мы гарантируем подобрать для вас лучшую персональную программу. Даже если ваш запрос сложный и касается лечения, медицины за рубежом, беременности или зависимости, мы справимся с этим. С более чем 10-летним опытом в области фитнеса и диетологии, 3000 успешных кейсов коррекции фигуры и 15-летним опытом в организации медицинского обслуживания, мы гарантируем вам лучший сервис.';

const SearchPagePersonalProgramItem = () => {
  const { modalIsOpen, openModal, closeModal } = useModalStateHooks();

  return (
    <div className="searchPageResultItemContainer">
      <PersonalProgramModal modalIsOpen={modalIsOpen} closeModal={closeModal} />

      <img className="searchPageResultItemImage" src={images.aggregatorPersonalProgramImage} />

      <div className="searchPageResultItemInformationContainer">
        <div className="searchPageResultItemInformationContainerInner">
          <div className="searchPageResultItemTitle">{'Персональная программа'}</div>
          <div
            style={{
              color: '#1A3233',
              fontSize: '18px',
              fontWeight: 800,
              lineHeight: '29px',
            }}
          >
            {'Цена по запросу'}
          </div>

          <div className="searchPageResultItemDescription">{description}</div>
        </div>

        <button
          className="searchPageResultItemBuyButton app-button"
          onClick={openModal}
          style={{ maxWidth: '180px ' }}
        >
          {'Отправить заявку'}
        </button>
      </div>
    </div>
  );
};

export default SearchPagePersonalProgramItem;