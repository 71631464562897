import { useDispatch } from 'react-redux';
import { setFullScreenFiltersModalIsOpen } from '../../redux/aggregatorReducer';
import { useAppSelector } from '../../redux/store';

export const useFullScreenFiltersModal = () => {
  const dispatch = useDispatch();
  const fullScreenFiltersModalIsOpen = useAppSelector((state) => state.aggregator.fullScreenFiltersModalIsOpen);

  const openModal = () => dispatch(setFullScreenFiltersModalIsOpen(true));
  const closeModal = () => dispatch(setFullScreenFiltersModalIsOpen(false));
  const toggleModal = () => dispatch(setFullScreenFiltersModalIsOpen(!fullScreenFiltersModalIsOpen));

  return { fullScreenFiltersModalIsOpen, openModal, closeModal, toggleModal };
};
