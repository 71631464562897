import { GetTagsQuery } from '../../../../gql/graphqlWithHooks';
import images from '../../../../utils/images';
import { getFileUrl } from '../../../../utils/requestApi';
import { getDisplayedFilename, getDurationText, getFileSizeText, getPriceText } from '../../../../utils/stringHelper';
import { FetchedGraphqlProductData, FetchedGraphqlTariffData, GraphqlProductData, GraphqlTariffData } from '../../../../utils/types';
import { AggregatorTagData, AggregatorTagDataWithPicture } from '../../../../utils/typesSearch';

export const structureCategories = (fetchedCategories: GetTagsQuery['mobile_tag_target_groups']) => {
  const structuredSearchCategories = fetchedCategories?.reduce((arr: AggregatorTagDataWithPicture[], item) => {
    if (!item.output_order) return arr;

    const structuredAgeRange: AggregatorTagDataWithPicture = {
      id: item.id,
      tagId: item.tag_id,
      outputOrder: item.output_order!,
      name: item.name!,
      iconSource: getFileUrl(item.picture_path!),
    };
    return [...arr, structuredAgeRange];
  }, []);

  // add "include all category"
  const includeAllSearchCategory: AggregatorTagDataWithPicture = {
    id: 0,
    tagId: undefined!,
    outputOrder: undefined!,
    name: 'Все категории',
    iconSource: images.allCategoriesIcon,
  }

  const structuredAgeRangesPlusAll = [
    includeAllSearchCategory,
    ...structuredSearchCategories.sort((a, b) => a.outputOrder - b.outputOrder),
  ];

  return structuredAgeRangesPlusAll;
}


export const structureAgeRanges = (fetchedAgeRanges: GetTagsQuery['mobile_tag_ages']) => {
  const structuredAgeRanges = fetchedAgeRanges?.reduce((arr: AggregatorTagData[], item) => {
    if (item.output_order === null) return arr;

    const structuredAgeRange: AggregatorTagData = {
      id: item.id,
      tagId: item.tag_id,
      outputOrder: item.output_order!,
      name: item.name!,
    };
    return [...arr, structuredAgeRange];
  }, []);

  // add "include all category"
  const includeAllAgeRangesTag: AggregatorTagData = {
    id: 0,
    tagId: undefined!,
    outputOrder: undefined!,
    name: 'Все периоды',
    nameAlt: 'Все',
  }

  const structuredAgeRangesPlusAll = [
    ...structuredAgeRanges.sort((a, b) => a.outputOrder - b.outputOrder),
    includeAllAgeRangesTag,
  ];

  return structuredAgeRangesPlusAll;
}

export const structureTagTargets = (fetchedTagTargets: GetTagsQuery['mobile_tag_targets']) => {
  const structuredTagTargets = fetchedTagTargets?.reduce((arr: AggregatorTagDataWithPicture[], item) => {
    if (item.output_order === null) return arr;

    const structuredAgeRange: AggregatorTagDataWithPicture = {
      id: item.id,
      tagId: item.tag_id,
      outputOrder: item.output_order!,
      name: item.name!,
      iconSource: getFileUrl(item.picture_path!),
    };
    return [...arr, structuredAgeRange];
  }, []);

  structuredTagTargets.sort((a, b) => a.outputOrder - b.outputOrder)

  return structuredTagTargets;
}

const structureDocumentData = (fetchedDocuments: FetchedGraphqlProductData['service_documents']) => {
  const structuredDocuments = fetchedDocuments.map((item) => {
    const filename = decodeURIComponent(item.document_path!.split('/').pop()!);

    return {
      documentId: item.id,
      documentCreationDate: item.created_at,
      documentName: filename,
      documentDisplayedName: getDisplayedFilename(filename),
      documentExtension: filename.split('.').pop()!.toUpperCase(),
      documentUrl: getFileUrl(item.document_path!),
      documentSize: item.document_size,
      documentSizeText: getFileSizeText(item.document_size),
    };
  });
  return structuredDocuments;
};

export const structureGraphqlProductData = (
  fetchedProductData: FetchedGraphqlProductData,
) => {
  const graphqlProductData: GraphqlProductData = {
    productId: fetchedProductData.id,
    serviceTypeId: fetchedProductData.service_type_id,
    productImages: fetchedProductData.service_images.map((item) => getFileUrl(item.file_path!)),
    productDocuments: structureDocumentData(fetchedProductData.service_documents),
    userId: fetchedProductData.user_id,

    title: fetchedProductData.title!,
    descriptionShort: fetchedProductData.message!,
    descriptionLong: fetchedProductData.detailed_message!,
    price: fetchedProductData.price
      ? getPriceText(fetchedProductData.price)
      : `Missing price for product #${fetchedProductData.id}`,

    periodicityName: fetchedProductData.service_periodicity?.name!,
    quantity: fetchedProductData.quantity,

    // tariffs must be sorted by "as gift" (or show them filtered)
    tariffs: fetchedProductData.service_tariffs.map((fetchedTariffData) =>
      structureGraphqlTariffData(fetchedTariffData, fetchedProductData)
    ),
  };

  return graphqlProductData;
};


export const structureGraphqlTariffData = (
  fetchedTariffData: FetchedGraphqlTariffData,
  fetchedProductData?: FetchedGraphqlProductData,
) => {
  // TODO - (possibly) refactor this later when it's more clear what we need
  // const productData = structureGraphqlProductData(fetchedTariffData.service!);
  const fetchedProductDataInner = fetchedProductData || fetchedTariffData.service!;

  const graphqlTariffData: GraphqlTariffData = {
    userId: fetchedTariffData.user_id,
    productId: fetchedProductDataInner.id,
    productTitle: fetchedProductDataInner.title!,
    productPrice: fetchedProductDataInner.price,
    productImages: fetchedProductDataInner.service_images.map((item) => getFileUrl(item.file_path!)),
    productDocuments: structureDocumentData(fetchedProductDataInner.service_documents),

    serviceTypeId: fetchedProductDataInner.service_type_id,
    periodicityName: fetchedProductDataInner.service_periodicity?.name!,
    quantity: fetchedProductDataInner.quantity,
    durationText: getDurationText(fetchedProductDataInner),
    descriptionShort: fetchedProductDataInner.message!,
    descriptionLong: fetchedProductDataInner.detailed_message!,

    tariffId: fetchedTariffData.id,
    tariffName: fetchedTariffData.name!,
    tariffPrice: fetchedTariffData.price,
    tariffPriceText: fetchedTariffData.price ? getPriceText(fetchedTariffData.price) : 'Бесплатно',
    tariffPurchaseButtonText: fetchedTariffData.price ? 'Купить' : 'Получить',
    
    tariffDataRaw: fetchedTariffData,
  };

  return graphqlTariffData;
};
