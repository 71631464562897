import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PurchaseProductModal from '../../components/PurchaseProductModal/PurchaseProductModal';
import { useGetTariffsByIdsLazyQuery } from '../../gql/graphqlWithHooks';
import { RootState } from '../../redux/store';
import { useOnReturnToMainPageButtonClick } from '../../utils/hooks/useOnReturnToMainPageButtonClick';
import { useScrollToTop } from '../../utils/hooks/useScrollToTop';
import images from '../../utils/images';
import { getCensoredEmailString } from '../../utils/stringHelper';
import { GraphqlTariffData } from '../../utils/types';
import BaseLayout from '../BaseLayout';
import { structureGraphqlTariffData } from '../MainPage/components/1.Aggregator/aggregatorUtils';
import ProductItem from '../MainPage/components/5.Products/components/ProductItem';
import '../OrderStatusPage/components/PaymentProcessingBlock.scss';

const SentFreeGuidePage = () => {
  const onReturnButtonClick = useOnReturnToMainPageButtonClick();
  useScrollToTop({ smooth: false });

  // ####################################################################################
  const emailString = useSelector((state: RootState) => state.main.freeGuideEmail);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openPurchaseProductModal = () => setModalIsOpen(true);
  const closePurchaseProductModal = () => setModalIsOpen(false);

  const [selectedTariffData, setSelectedTariffData] = useState<GraphqlTariffData>();
  const onPurchaseClick = (tariffData: GraphqlTariffData) => {
    setSelectedTariffData(tariffData);
    openPurchaseProductModal();
  }

  // ####################################################################################
  // get suggested products
  
  const suggestedTariffPlanIds: number[] = [247, 248];
  const [getTariffsByIdsLazyQuery] = useGetTariffsByIdsLazyQuery();
  
  const [suggestedTariffs, setSuggestedTariffs] = useState<GraphqlTariffData[]>([]);
  useEffect(() => {
    getTariffsByIdsLazyQuery({ variables: { ids: suggestedTariffPlanIds }}).then(response => {
      const fetchedTariffsData = response.data?.mobile_service_tariffs;
      const structuredTariffs = fetchedTariffsData?.map(item => structureGraphqlTariffData(item)) || [];

      setSuggestedTariffs(structuredTariffs);
    });
  }, []);

  // ####################################################################################
  const displayData = {
    statusImage: images.modalSuccess,
    statusImageClass: 'paymentProcessingStatusIconSuccess',
    firstColumnTitle: 'Мы отправили вам бесплатный гайд: «Хочу в ПП. С чего начать?»',
    firstColumnText: `Мы отправили письмо с гайдом на почту ${getCensoredEmailString(emailString)}`,
    buttonTitle: 'Вернуться на главную',
    onButtonClick: onReturnButtonClick,
    secondColumnTitle: 'Может быть интересно',
  }

  return (
    <BaseLayout
      hideSectionLinks={false}
      content={
        <div className="paymentProcessingContainer">
          <PurchaseProductModal
            tariffData={selectedTariffData!}
            selectedTags={[]}
            modalIsOpen={modalIsOpen}
            closeModal={closePurchaseProductModal}
          />
          <div className="paymentProcessingMainSectionContainer">
            <div className="paymentProcessingMainSectionContainerInner" style={{ maxWidth: 500 }}>
              <img src={displayData.statusImage} alt="" className={displayData.statusImageClass} />
              <div className="paymentProcessingMainSectionTitle">{displayData.firstColumnTitle}</div>
              <div className="paymentProcessingMainSectionText">{displayData.firstColumnText}</div>
              <button
                onClick={displayData.onButtonClick}
                className="app-button paymentProcessingButton"
              >
                {'Вернуться на главную'}
              </button>
            </div>
          </div>
          <div
            className="paymentProcessingSuggestedSectionContainer"
            style={{ backgroundImage: images.successfulPaymentSecondColumnBackground }}
          >
            <div className="paymentProcessingSuggestedSectionTitle">{displayData.secondColumnTitle}</div>
            <div className="paymentProcessingSuggestedSectionContainerInner">
            {suggestedTariffs.map((tariffData) => (
              <ProductItem
                key={`${tariffData.productId}${tariffData.tariffId}`}
                tariffData={tariffData}
                onPurchaseClick={onPurchaseClick}
                onMoveUserToConsult={() => {}}
                useMobileDesign={false}
                usePaymentProcessingVariant
              />
            ))}
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SentFreeGuidePage;
