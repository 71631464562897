import images from '../../../../../utils/images';
import { getImageUrlForCss } from '../../../../../utils/stringHelper';
import './ProductItemModal.scss';
import './PersonalProgramItemModal.scss';

const PersonalProgramItemModal = () => {
  const titlePrefix = 'Ваша программа:';
  const title = 'Персональная программа';
  const description = 'Мы гарантируем подобрать для вас лучшую персональную программу. Даже если ваш запрос сложный и касается лечения, медицины за рубежом, беременности или зависимости, мы справимся с этим. С более чем 10-летним опытом в области фитнеса и диетологии, 3000 успешных кейсов коррекции фигуры и 15-летним опытом в организации медицинского обслуживания, мы гарантируем вам лучший сервис.';

  const backgroundImage = getImageUrlForCss(images.servicesLine1);

  return (
    <div className="modalProductItemContainer">
      <div>
        <div className="personalProgramItemModalPrefix">{titlePrefix}</div>

        <div className="modalProductItemTitle" style={{ backgroundImage }}>
          {title}
        </div>

        {<div className="modalProductItemTextDescription">{description}</div>}
      </div>
    </div>
  );
};

export default PersonalProgramItemModal;
