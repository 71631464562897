import { CSSProperties, useCallback, useEffect, useState } from 'react';
import ButtonWithSpinner from '../../../../../../components/ButtonWithSpinner/ButtonWithSpinner';
import { useAppSelector } from '../../../../../../redux/store';
import images from '../../../../../../utils/images';
import AggregatorAgeRangesMenu from './AggregatorAgeRangesMenu';
import AggregatorCategoryMenu from './AggregatorCategoryMenu';
import AggregatorTagTargetsMenu from './AggregatorTagTargetsMenu';
import './AggregatorMenus.scss';
import './AggregatorSearchBlockMenus.scss';

enum DV {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

const backgroundImage = `url(${images.aggregatorMenuBackground})`;

const AggregatorMenus = ({
  fetchingInitialProducts,
  onSearchButtonClick,
}: {
  fetchingInitialProducts: boolean;
  onSearchButtonClick: () => void;
}) => {
  const screenWidth = useAppSelector((state) => state.main.dimensionsData.screenSize.width);

  const [displayVariant, setDisplayVariant] = useState<DV>(DV.desktop);
  useEffect(() => {
    if (screenWidth >= 1440) setDisplayVariant(DV.desktop);
    else if (screenWidth >= 1080) setDisplayVariant(DV.tablet);
    else setDisplayVariant(DV.mobile);
  }, [screenWidth]);

  const fetching = useAppSelector((state) => state.aggregator.fetching);

  const SearchButton = useCallback(
    ({ style }: { style?: CSSProperties }) => (
      <ButtonWithSpinner
        title={'Найти'}
        fetching={fetchingInitialProducts || fetching}
        onClick={onSearchButtonClick}
        className="aggregatorSearchBlockSearchButton app-button"
        spinnerSize={30}
        style={style}
      />
    ),
    [fetchingInitialProducts, fetching]
  );

  switch (displayVariant) {
    default:
    case DV.desktop:
      return (
        <div className="aggregatorSearchBlockSearchFieldsContainer" style={{ backgroundImage }}>
          <AggregatorCategoryMenu flex={2} />
          <AggregatorAgeRangesMenu flex={0.5} usingDesktopVersion />
          <AggregatorTagTargetsMenu flex={4} />
          <SearchButton />
        </div>
      );

    case DV.tablet:
      return (
        <div className="aggregatorSearchBlockSearchFieldsContainerTablet" style={{ backgroundImage }}>
          <div className="aggregatorSearchMenusSectionTablet">
            <AggregatorCategoryMenu flex={1} usingFullScreenModal />
            <AggregatorAgeRangesMenu flex={1} usingFullScreenModal />
          </div>
          <div className="aggregatorSearchMenusSectionTablet">
            <AggregatorTagTargetsMenu flex={1} usingFullScreenModal />
            <SearchButton style={{ width: 250 }} />
          </div>
        </div>
      );

    case DV.mobile:
      return (
        <div className="aggregatorSearchBlockSearchFieldsContainerMobile">
          <div className="aggregatorSearchBlockSearchFieldsBlockTitle">{'Поиск персональной программы'}</div>
          <div className="aggregatorSearchMenusSectionMobile">
            <AggregatorCategoryMenu flex={2} usingFullScreenModal />
            <AggregatorAgeRangesMenu flex={1} usingFullScreenModal />
          </div>
          <AggregatorTagTargetsMenu flex={1} usingFullScreenModal usingAltDesign />
          <SearchButton style={{ alignSelf: 'center', width: '100%', maxWidth: 400 }} />
        </div>
      );
  }
};

export default AggregatorMenus;