import { SectionName } from '../../../../../utils/enums';
import images from '../../../../../utils/images';
import { getImageUrlForCss, getServiceDurationText } from '../../../../../utils/stringHelper';
import { GraphqlTariffData } from '../../../../../utils/types';
import './ProductItem.scss';

const ProductItem = ({
  tariffData,
  onMoveUserToConsult,
  onPurchaseClick,
  useMobileDesign,
  usePaymentProcessingVariant,
}: {
  tariffData: GraphqlTariffData;
  onMoveUserToConsult: (sectionName: SectionName) => void;
  onPurchaseClick: (tariffData: GraphqlTariffData) => void;
  useMobileDesign: boolean;
  usePaymentProcessingVariant?: boolean;
}) => {
  // const tariffsWithSameName = useMemo(
  //   () => productData.tariffs.filter((item) => item.name === selectedTariff.name),
  //   [productData]
  // );

  const { productTitle, durationText, tariffName, tariffPriceText, tariffPurchaseButtonText } = tariffData;
  const servicesList = tariffData.tariffDataRaw.service_lists;
  
  const mobileStyles: React.CSSProperties = { maxWidth: 'calc(100% - 32px)' };
  
  const containerStyle: React.CSSProperties = {
    ...useMobileDesign && mobileStyles,
    ...usePaymentProcessingVariant && { minHeight: 400 },
  }

  // TODO - custom logic for personal program (?) (not needed as of 22.07.24)
  // const isPersonalProgram = productData.devName === ProductDevName.personalProgram;
  const isPersonalProgram = false;
  const onPurchaseButtonClick = () => {
    if (isPersonalProgram) {
      onMoveUserToConsult(SectionName.ConsultSection2);
    } else {
      onPurchaseClick(tariffData);
    }
  }
  
  // TODO suggest different approaches, maybe client selects id for background or it depends on type
  const backgroundImage = images.servicesLine1;

  return (
    <div className="productItemContainer" style={containerStyle}>
      <div>
        <div className="productItemTitlePrefix">{tariffName}</div>
        <div className="productItemTitle" style={{ backgroundImage: getImageUrlForCss(backgroundImage) }}>
          {productTitle}
        </div>
        <div className="productItemDurationButtonsBlock">
          <div
            className="productItemDurationButtonSelected"
            style={{ ...(!durationText && { display: 'none' }) }}
          >
            {durationText}
          </div>
          {/* {tariffsWithSameName.map((tariff) => (
            <button
              key={tariff.id}
              onClick={tariffsWithSameName.length > 1 ? () => setSelectedTariffId(tariff.id) : undefined}
              className={
                tariff.id === selectedTariffId ? 'productItemDurationButtonSelected' : 'productItemDurationButton'
              }
              style={{ ...(!getDurationText(productData) && { display: 'none' }) }}
            >
              {getDurationText(productData)}
            </button>
          ))} */}
        </div>
        {/* TODO - show short description for guides (?) */}
        {/* <div className="productItemTextDescription">
          {productData.descriptionShort}
        </div> */}
        <div className="productItemIncludedBlock">
          {servicesList.filter(it => !it.as_gift).map((subService) => (
            <div className="productItemIncludedItem" key={subService.id}>
              <div className="productItemIncludedItemText">{subService.title}</div>
              {subService.service_list_periodicity && 
                <div className="productItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                  {getServiceDurationText(subService)}
                </div>
              }
            </div>
          ))}
        </div>
        <div className="productItemIncludedBonusesBlock">
          {servicesList.filter(it => it.as_gift).map((bonusItem) => (
            <div className="productItemIncludedBonusItem" key={bonusItem.id}>
              <img className="productItemIncludedBonusItemIcon" src={images.servicesGiftIcon} />
              <div className="productItemIncludedItemText">{bonusItem.title}</div>
              {bonusItem.service_list_periodicity && 
                <div className="productItemIncludedItemText" style={{ whiteSpace: 'nowrap' }}>
                  {getServiceDurationText(bonusItem)}
                </div>
              }
            </div>
          ))}
        </div>
      </div>

      <div className="productItemColumn">
        <div className="productItemPrice">{tariffPriceText}</div>

        <a onClick={onPurchaseButtonClick} className="productItemPurchaseButton">
          {tariffPurchaseButtonText}
        </a>
      </div>
    </div>
  );
};

export default ProductItem;
