import { useFullScreenFiltersModal } from "./useFullScreenFiltersModal";
import { useModalStateHooks } from "./useModalStateHooks";

export const useAggregatorMenuModal = ({
  usingFullScreenModal = false,
}: {
  usingFullScreenModal: boolean;
}) => {
  const {
    modalIsOpen: menuModalIsOpen,
    openModal: openMenuModal,
    closeModal: closeMenuModal,
    toggleModal: toggleMenuModal,
  } = useModalStateHooks();

  const {
    fullScreenFiltersModalIsOpen,
    openModal: openFullScreenModal,
    closeModal: closeFullScreenModal,
    toggleModal: toggleFullScreenModal,
  } = useFullScreenFiltersModal();

  const modalIsOpen = usingFullScreenModal ? fullScreenFiltersModalIsOpen : menuModalIsOpen;
  const openModal = usingFullScreenModal ? openFullScreenModal : openMenuModal;
  const closeModal = usingFullScreenModal ? closeFullScreenModal : closeMenuModal;
  const toggleModal = usingFullScreenModal ? toggleFullScreenModal : toggleMenuModal;

  return { modalIsOpen, openModal, closeModal, toggleModal };
};