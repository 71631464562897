import AppLink from '../../../components/AppLink/AppLink';
import { getProductDescriptionPageNavLink } from '../../../utils/helper';
import { getDurationText, getImageUrlForCss } from '../../../utils/stringHelper';
import { GraphqlProductData, GraphqlTariffData } from '../../../utils/types';
import { yandexMetrics } from '../../../utils/yandexMetrics';
import './SearchPageResultItem.scss';

const SearchPageResultItem = ({
  productData,
  tariffData,
  tariffId,
  onPurchaseButtonClick,
}: {
  productData: GraphqlProductData;
  tariffData: GraphqlTariffData;
  tariffId: number;
  onPurchaseButtonClick: () => void;
}) => {
  const {
    productId,
    productImages: [productImage],
    title,
    periodicityName,
    quantity,
    descriptionShort,
  } = productData;

  const { tariffName, tariffPriceText, tariffPurchaseButtonText } = tariffData;

  const durationText = `на ${getDurationText({ periodicityName, quantity })}`;

  const itemDescriptionNavLink = getProductDescriptionPageNavLink(productId, tariffId);

  const onProductDetailsClick = () => {
    yandexMetrics.productDetailsClick();
  }

  return (
    <div className="searchPageResultItemContainer">
      <div
        className="searchPageResultItemImage"
        style={{ backgroundImage: getImageUrlForCss(productImage) }}
      />

      <div className="searchPageResultItemInformationContainer">
        <div className="searchPageResultItemInformationContainerInner">
          <div className="searchPageResultItemTitle">{title}</div>
          {tariffName && (
            <div className="searchPageResultItemTariffName">
              <b>{`Тариф: `}</b>
              {tariffName}
            </div>
          )}
          <div className="searchPageResultItemPriceDurationContainer">
            <div className="searchPageResultItemPrice">{tariffPriceText}</div>
            <div className="searchPageResultItemDuration">{durationText}</div>
          </div>
          <div className="searchPageResultItemDescription">{descriptionShort}</div>
        </div>

        <div className="searchPageResultItemButtons">
          <button className="searchPageResultItemBuyButton app-button a" onClick={onPurchaseButtonClick}>
            {tariffPurchaseButtonText}
          </button>
          <AppLink
            className="searchPageResultItemDetailsButton app-button-alt"
            to={itemDescriptionNavLink}
            onClick={onProductDetailsClick}
          >
            {'Подробнее'}
          </AppLink>
        </div>
      </div>
    </div>
  );
};

export default SearchPageResultItem;
