import { apolloClient } from "../..";
import {
  PurchaseRequestRetryDocument,
  PurchaseRequestRetryMutation,
  PurchaseRequestRetryMutationVariables,
} from '../../gql/graphqlWithHooks';

export const handlePurchaseRetryRequest = async ({
  orderId,
  setBlockInterface,
}: {
  orderId: string;
  setBlockInterface: (b: boolean) => void;
}) => {
  const operationVariables: PurchaseRequestRetryMutationVariables = {
    order_id: orderId,
  };

  try {
    const response = await apolloClient.mutate<PurchaseRequestRetryMutation>({
      mutation: PurchaseRequestRetryDocument,
      variables: operationVariables,
    });

    // request resolved
    const responseData = response?.data?.mobilePurchasingServiceRetry;

    // process errors
    if (!responseData || responseData.status === false) {
      console.warn(`handlePurchaseRetryRequest failed: ${JSON.stringify(responseData)}`);
      setBlockInterface(false);
      return;
    }

    if (responseData?.status === true) {
      const paymentUrl = responseData.payment_link!;
      window.location.href = paymentUrl;
      return;

      //after payment is complete user gets redirected back to:
      // https://dev.wellness.etna.global/order?id=...
    }
  } catch (error) {
    console.error('handlePurchaseRetryRequest Error:', error);
  }
};