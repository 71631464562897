import { apolloClient } from "../..";
import {
  SendFreeGuideDocument,
  SendFreeGuideMutation,
  SendFreeGuideMutationVariables,
} from '../../gql/graphqlWithHooks';

export const getFreeGuideRequest = async ({
  userCredentials,
  userPhone,
  userEmail,
}: {
  userCredentials: string;
  userPhone: string;
  userEmail: string;
}) => {
  const userPhoneFormatted = `+${userPhone.replaceAll(/\D/g, '')}`;

  const operationVariables: SendFreeGuideMutationVariables = {
    full_name: userCredentials,
    phone: userPhoneFormatted,
    email: userEmail,
    program_name: "Бесплатный гайд",
  };

  try {
    const response = await apolloClient.mutate<SendFreeGuideMutation>({
      mutation: SendFreeGuideDocument,
      variables: operationVariables,
    });

    // request resolved
    const responseData = response?.data?.mobileGetFreeGuide;
    const requestSuccessful = responseData?.status === 'ok';
    return requestSuccessful;
  } catch (error) {
    console.error('getFreeGuideRequest Error:', error);
    return false;
  }
};
