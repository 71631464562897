import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductItemModal from '../../pages/MainPage/components/5.Products/components/ProductItemModal';
import { setBlockInterface } from '../../redux/mainReducer';
import { RootState } from '../../redux/store';
import { invalidEmailText, invalidPhoneNumberText, servicesAgreementHref } from '../../utils/appConstants';
import { validateEmail, validatePhoneNumber } from '../../utils/helper';
import { useDisableScroll } from '../../utils/hooks/useDisableScroll';
import { useGetSelectedTagsFromStore } from '../../utils/hooks/useGetSelectedTagsFromStore';
import images from '../../utils/images';
import { handlePurchaseRequest } from '../../utils/requests/purchaseRequest';
import { GraphqlTariffData } from '../../utils/types';
import { yandexMetrics } from '../../utils/yandexMetrics';
import AppCheckbox from '../AppCheckbox/AppCheckbox';
import AppMaskedPhoneInput from '../AppMaskedPhoneInput/AppMaskedPhoneInput';
import CommonInlineStyles from '../CommonInlineStyles';
import PrivacyPolicyTextBlock from '../PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import ButtonWithSpinner from '../ButtonWithSpinner/ButtonWithSpinner';
import './PurchaseProductModal.scss';

// this specifically purchases tariff
const PurchaseProductModal = ({
  tariffData,
  selectedTags,
  modalIsOpen,
  closeModal,
}: {
  tariffData: GraphqlTariffData;
  selectedTags?: string[];
  modalIsOpen: boolean;
  closeModal: () => void;
}) => {
  useDisableScroll(modalIsOpen);
  const selectedTagsFromStore = useGetSelectedTagsFromStore();

  const titleText = 'Покупка продукта:'
  const inputsTitle = 'Введите данные:';
  const productSectionTitle = 'Ваш продукт:';

  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const servicesAgreementCheckmarkText = 'Вы выражаете свое согласие на заключение ';
  const servicesAgreementCheckmarkLinkText = 'договора оферты об оказании услуг';
  const [servicesAgreementChecked, setServicesAgreementChecked] = useState(false);

  const [userCredentials, setUserCredentials] = useState('');
  const [userComment, setUserComment] = useState('');

  const [fieldReceivedInput, setFieldReceivedInput] = useState(false);

  const [userEmail, setUserEmail] = useState('');
  const [userEmailValid, setUserEmailValid] = useState(true);
  const onUserEmailFocus = () => {
    if (!userEmailValid) {
      setUserEmailValid(true);
      setFieldReceivedInput(false);
    }
  };
  const onUserEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setUserEmail(input);
    setFieldReceivedInput(true);
  };
  const onUserEmailCheck = () => {
    const isValid = validateEmail(userEmail);
    setUserEmailValid(isValid);
    return isValid;
  };


  const [userPhone, setUserPhone] = useState('');
  const [userPhoneValid, setUserPhoneValid] = useState(true);
  const onUserPhoneCheck = () => {
    const isValid = validatePhoneNumber(userPhone);
    setUserPhoneValid(isValid);
    return isValid;
  };

  const userAgreedToTerms = privacyPolicyChecked && servicesAgreementChecked;
  const buyButtonEnabled =
    fieldReceivedInput &&
    userAgreedToTerms &&
    !!userPhone &&
    userPhone !== '7' &&
    userPhoneValid &&
    !!userEmail &&
    userEmailValid &&
    !!userCredentials;

  const dispatch = useDispatch();
  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);
  const [requestReturnedError, setRequestReturnedError] = useState(false);
  const onPurchaseClickModal = () => {
    const userEmailIsValid = onUserEmailCheck();
    const userPhoneIsValid = onUserPhoneCheck();
    if (!userEmailIsValid || !userPhoneIsValid) return;

    // start spinner and disable interface
    dispatch(setBlockInterface(true));

    yandexMetrics.productPurchase();

    // setBlockInterface is passed inside,
    //  because if we initiate redirect (to payment) interface should stay blocked
    handlePurchaseRequest({
      userCredentials,
      userPhone,
      userEmail,
      userComment,
      selectedTariffId: tariffData.tariffId,
      tags: selectedTags || selectedTagsFromStore,
      setBlockInterface: (b: boolean) => dispatch(setBlockInterface(b)),
      setRequestReturnedError,
    });
  };

  if (!modalIsOpen) return null;

  return (
    <div className="purchaseModalOverlay">
      <div className="purchaseModalContainer">
        <div className="purchaseModalContainerInner">
          <button disabled={blockInterface} className="purchaseModalCloseButton" onClick={closeModal}>
            <img src={images.modalCross} className="purchaseModalCrossIcon" alt="Закрыть" />
          </button>

          <div className="purchaseModalInputsColumn">
            <div className="purchaseInputsTitle">{titleText}</div>
            <div className="purchaseModalInputsInnerColumn">
              <div className="purchaseModalInputsInnerColumnContainer">
                <div className="purchaseModalInputsInnerColumnTitle">{inputsTitle}</div>
                <input
                  type={'text'}
                  placeholder={'ФИО полностью'}
                  autoComplete={'name'}
                  maxLength={100}
                  value={userCredentials}
                  onChange={(e) => setUserCredentials(e.target.value)}
                  className="input-field-dynamic-outline purchaseModalInputsInnerColumnInput"
                />
                <input
                  type={'text'}
                  placeholder={'E-mail'}
                  autoComplete={'email'}
                  maxLength={100}
                  autoCapitalize={'none'}
                  autoCorrect={''}
                  value={userEmail}
                  onFocus={onUserEmailFocus}
                  onChange={onUserEmailChange}
                  className="input-field-dynamic-outline purchaseModalInputsInnerColumnInput"
                  style={!userEmailValid ? CommonInlineStyles.inputFieldErrorBorder : {}}
                />
                {!userEmailValid && <div className="purchaseModalInputsErrorText">{invalidEmailText}</div>}
                <AppMaskedPhoneInput
                  userPhone={userPhone}
                  setUserPhone={setUserPhone}
                  userPhoneValid={userPhoneValid}
                  setUserPhoneValid={setUserPhoneValid}
                  setFieldReceivedInput={setFieldReceivedInput}
                  className="input-field-dynamic-outline purchaseModalInputsInnerColumnInput"
                  style={!userPhoneValid ? CommonInlineStyles.inputFieldErrorBorder : {}}
                />
                {!userPhoneValid && (
                  <div className="purchaseModalInputsErrorText">{invalidPhoneNumberText}</div>
                )}
                <textarea
                  placeholder={'Комментарий'}
                  maxLength={100}
                  value={userComment}
                  draggable={false}
                  onChange={(e) => setUserComment(e.target.value)}
                  className="input-field-dynamic-outline purchaseModalInputsInnerColumnInputComment"
                />
              </div>

              
              <div className="purchaseModalInputsInnerColumnSecondSection">
                <div className="purchaseModalInputsInnerColumnCheckboxesContainer">
                  <div className="purchaseModalInputsInnerColumnCheckboxItemContainer">
                    <AppCheckbox isChecked={privacyPolicyChecked} setCheckBoxValue={setPrivacyPolicyChecked} />
                    <PrivacyPolicyTextBlock className="purchaseModalInputsInnerColumnCheckboxItemText" />
                  </div>
                  <div className="purchaseModalInputsInnerColumnCheckboxItemContainer">
                    <AppCheckbox
                      isChecked={servicesAgreementChecked}
                      setCheckBoxValue={setServicesAgreementChecked}
                    />
                    <div className="purchaseModalInputsInnerColumnCheckboxItemText">
                      {servicesAgreementCheckmarkText}
                      <a
                        href={servicesAgreementHref}
                        className="purchaseModalInputsInnerColumnCheckboxItemTextLink link opacity-on-hover"
                        target="_blank"
                      >
                        {servicesAgreementCheckmarkLinkText}
                      </a>
                    </div>
                  </div>
                </div>

                <ButtonWithSpinner
                  onClick={onPurchaseClickModal}
                  title={tariffData.tariffPurchaseButtonText}
                  fetchingTitle={'Обработка запроса'}
                  fetching={blockInterface}
                  disabled={blockInterface || !buyButtonEnabled}
                  className="purchaseModalInputsInnerColumnPurchaseButton"
                  style={blockInterface || !buyButtonEnabled ? CommonInlineStyles.buttonDisabled : undefined}
                  spinnerSize={25}
                />
                {requestReturnedError ? (
                  <div className="requestErrorText">{'Ошибка сервера. Повторите попытку позже'}</div>
                ) : (
                  <img
                    className="purchaseModalInputsInnerColumnPaymentOptionsImage"
                    src={images.purchaseModalCreditCards}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="purchaseModalProductColumn">
            <div className="purchaseModalProductSectionTitle">{productSectionTitle}</div>
            <ProductItemModal tariffData={tariffData} />
          </div>

          <div className="purchaseModalTitleMobile">{titleText}</div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseProductModal;
