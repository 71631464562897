import { SearchQueryParams } from "../../utils/enums";
import { cacheImages } from "../../utils/functions/cacheImages";
import { GraphqlProductData } from "../../utils/types";

export const cacheImagesForProducts = async ({
  structuredProducts,
  page = 1,
  itemsPerPage = 4,
}: {
  structuredProducts: GraphqlProductData[];
  page?: number;
  itemsPerPage?: number;
}) => {
  const fromId = (page - 1) * itemsPerPage;
  const toId = Math.min(page * itemsPerPage, structuredProducts.length);
  // console.log(`cacheImagesForProducts ${fromId}..${toId}`);

  const imageSources = structuredProducts
    .slice(fromId, toId)
    .reduce((arr: string[], item) => [...arr, item.productImages[0]], []);

  await cacheImages(imageSources);
};

export const getTagsFromSearchQueryParams = (searchParams: URLSearchParams) => {
  const categoryIdString = searchParams.get(SearchQueryParams.categoryId);
  const ageRangeIdString = searchParams.get(SearchQueryParams.ageRangeId);
  const tagTargetIdsString = searchParams.get(SearchQueryParams.tagTargetIds);

  const categoryIdFromQuery = categoryIdString ? parseInt(categoryIdString) : undefined;
  const ageRangeIdFromQuery = ageRangeIdString ? parseInt(ageRangeIdString) : undefined;
  const tagTargetIdsFromQuery = tagTargetIdsString?.split(',').map((str) => parseInt(str)) || [];

  return { categoryIdFromQuery, ageRangeIdFromQuery, tagTargetIdsFromQuery };
}