import { useSelector } from 'react-redux';
import PersonalProgramItemModal from '../../pages/MainPage/components/5.Products/components/PersonalProgramItemModal';
import { RootState } from '../../redux/store';
import { useDisableScroll } from '../../utils/hooks/useDisableScroll';
import { useGetSelectedTagsFromStore } from '../../utils/hooks/useGetSelectedTagsFromStore';
import images from '../../utils/images';
import { defaultConsultCallData } from '../../utils/requestApi';
import { colors } from '../../utils/themeConstants';
import RequestCallForm from '../RequestCallForm/RequestCallForm';
import './PersonalProgramModal.scss';
import './PurchaseProductModal.scss';

// this specifically purchases tariff
const PersonalProgramModal = ({
  modalIsOpen,
  closeModal,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
}) => {
  useDisableScroll(modalIsOpen);
  const selectedTagsFromStore = useGetSelectedTagsFromStore();

  const titleText = 'Подбор персональной программы:'
  const inputsTitle = 'Введите данные:';
  const productSectionTitle = 'Наши специалисты свяжутся с вами в ближайшее время';

  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);

  if (!modalIsOpen) return null;

  return (
    <div className="purchaseModalOverlay">
      <div className="purchaseModalContainer">
        <div className="purchaseModalContainerInner">
          <button disabled={blockInterface} className="purchaseModalCloseButton" onClick={closeModal}>
            <img src={images.modalCross} className="purchaseModalCrossIcon" alt="Закрыть"/>
          </button>

          <div className="purchaseModalInputsColumn">
            <div className="purchaseInputsTitle">{titleText}</div>
            <div className="purchaseModalInputsInnerColumn">
              <div className="purchaseModalInputsInnerColumnContainer">
                <div className="purchaseModalInputsInnerColumnTitle">{inputsTitle}</div>

                <RequestCallForm
                  consultData={{ ...defaultConsultCallData, tags: selectedTagsFromStore }}
                  callMeButtonText={'Отправить заявку'}
                  containerStyle={{ marginTop: 8 }}
                  containerClass={"personalProgramModalCallFormContainer"}
                  inputsContainerClass={"personalProgramModalCallFormInputs"}
                  inputFieldClass={"personalProgramModalCallFormInputField"}
                  phoneNumberBlockClass={"personalProgramModalCallFormInputFieldPhoneNumberBlock"}
                  phoneNumberErrorTextClass={"personalProgramModalCallFormErrorText"}
                  callMeButtonClass={"personalProgramModalCallFormCallMeButton"}
                  callMeButtonStyle={{ backgroundColor: colors.accent, color: 'white', }}
                  personalDataNoteClass={"personalProgramModalCallFormPersonalDataNote"}
                  
                  closeOuterModal={closeModal}
                />
              </div>

              <img
                className="purchaseModalInputsInnerColumnPaymentOptionsImage"
                src={images.purchaseModalCreditCards}
              />
            </div>
          </div>

          <div className="purchaseModalProductColumn">
            <div className="purchaseModalProductSectionTitle">{productSectionTitle}</div>
            <PersonalProgramItemModal />
          </div>

          <div className="purchaseModalTitleMobile">{titleText}</div>
        </div>
      </div>
    </div>
  );
};

export default PersonalProgramModal;
