import { useState } from 'react';
import ButtonWithSpinner from '../../../../../../components/ButtonWithSpinner/ButtonWithSpinner';
import ClearSelectedTagTargetsButton from '../../../../../../components/ClearSelectedTagTargetsButton/ClearSelectedTagTargetsButton';
import { useDisableScroll } from '../../../../../../utils/hooks/useDisableScroll';
import { useFullScreenFiltersModal } from '../../../../../../utils/hooks/useFullScreenFiltersModal';
import images from '../../../../../../utils/images';
import { SearchInput } from '../../../../../SearchPage/SearchPageContent';
import { aggregatorButtons } from '../../aggregatorConstants';
import AggregatorQuickSearchButton from '../AggregatorQuickSearchButton';
import AggregatorAgeRangesMenu from '../menus/AggregatorAgeRangesMenu';
import AggregatorCategoryMenu from '../menus/AggregatorCategoryMenu';
import AggregatorTagTargetsMenu from '../menus/AggregatorTagTargetsMenu';
import './AggregatorFullScreenFiltersModal.scss';

const AggregatorFullScreenFiltersModal = ({
  onSearchButtonClick,
  isSearchPageFilter,
}: {
  onSearchButtonClick: (input?: SearchInput) => void;
  isSearchPageFilter?: boolean;
}) => {
  const [tagTargetsMenuIsOpen, setTagTargetsMenuIsOpen] = useState(false);
  const { fullScreenFiltersModalIsOpen, closeModal } = useFullScreenFiltersModal();

  // disable scroll outside the modal, to not have two scroll bars
  useDisableScroll(fullScreenFiltersModalIsOpen);

  const onSearchClick = (input?: SearchInput) => {
    onSearchButtonClick(input);
    closeModal();
  };

  if (!fullScreenFiltersModalIsOpen) return null;

  return (
    <div className="aggregatorFullScreenFiltersModalContainer">
      <div className="aggregatorFullScreenFiltersModalContainerInner">
        <div className="aggregatorFullScreenFiltersModalTitleContainer">
          <div className="aggregatorFullScreenFiltersModalTitle">{'Фильтры'}</div>

          <button className="aggregatorFullScreenFiltersModalCloseButton" onClick={closeModal}>
            <img
              src={images.aggregatorModalCross}
              className="aggregatorFullScreenFiltersModalCloseButtonImage"
            />
          </button>
        </div>

        <div className="aggregatorFullScreenFiltersModalFiltersSection">
          <div className="aggregatorFullScreenSectionTitle">{'Поиск персональной программы'}</div>

          <AggregatorCategoryMenu menuAsModal={false} withMobileStyles />
          <AggregatorAgeRangesMenu menuAsModal={false} />
          <AggregatorTagTargetsMenu
            menuAsModal={false}
            setTagTargetsMenuIsOpen={setTagTargetsMenuIsOpen}
            usingAltDesign
          />
          {!tagTargetsMenuIsOpen && <ClearSelectedTagTargetsButton />}

          <ButtonWithSpinner
            className="aggregatorFullScreenSearchButton"
            title={'Поиск'}
            fetching={false}
            onClick={() => onSearchClick()}
            spinnerSize={10}
          />
        </div>

        <div className="aggregatorFullScreenFiltersModalQuickSearchSection">
          <div className="aggregatorFullScreenSectionTitle">{'Или ищите по тегам'}</div>

          <div className="aggregatorFullScreenFiltersModalQuickSearchSectionList">
            {aggregatorButtons.map((buttonValues) => (
              <AggregatorQuickSearchButton
                key={buttonValues.tagId}
                iconImage={buttonValues.iconImage}
                title={buttonValues.title}
                tagId={buttonValues.tagId}
                onSearch={onSearchClick}
                usingMobileStyles
                isSearchPageFilter={isSearchPageFilter}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AggregatorFullScreenFiltersModal;