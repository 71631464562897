// import reportWebVitals from './reportWebVitals';

import './components/CommonStyles.scss';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import MainPage from './pages/MainPage/MainPage';
import OrderStatusPage from './pages/OrderStatusPage/OrderStatusPage';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import ItemDescriptionPage from './pages/ItemDescriptionPage/ItemDescriptionPage';
import { PagePath } from './utils/enums';
import ValuesPage from './pages/ValuesPage/ValuesPage';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { baseUrl } from './utils/requestApi';

import SentFreeGuidePage from './pages/SentFreeGuidePage/SentFreeGuidePage';
import SearchPage from './pages/SearchPage/SearchPage';

// apollo
export const apolloClient = new ApolloClient({
  uri: `${baseUrl}/v1/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

// routing
const router = createBrowserRouter([
  {
    path: PagePath.MainPage,
    element: <MainPage />,
  },
  {
    path: PagePath.SearchPage,
    element: <SearchPage />,
  },
  {
    path: PagePath.ItemDescriptionPage,
    element: <ItemDescriptionPage />,
  },
  {
    path: PagePath.OrderStatusPage, // this is where backend navigates us, and should remain the same
    element: <OrderStatusPage />,
  },
  {
    path: PagePath.SentFreeGuidePage,
    element: <SentFreeGuidePage />,
  },
  {
    path: PagePath.ValuesPage,
    element: <ValuesPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // in react 18, StrictMode causes all useEffect-s to trigger twice on freshly rendered components
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
