import { PagePath } from "./enums";
import images from "./images";

export const sleep = (ms?: number) => new Promise(r => setTimeout(r, ms || 0));

export const scrollToTop = (smooth = true) => {
  sleep().then(() => window.scrollTo({ top: 0, behavior: smooth ? 'smooth' : undefined }))
}

export const scrollToBlockRef = (blockRef: React.RefObject<HTMLDivElement>, offset = -20) => {
  sleep().then(() => {
    const elementPosition = blockRef.current?.getBoundingClientRect().top!;
    const offsetPosition = elementPosition + window.scrollY + offset;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  })
};

export const getProductDescriptionPageNavLink = (productId: number, tariffId: number) => {
  return `${PagePath.ItemDescriptionPage}?id=${productId}&variant=${tariffId}`;
}

export const validateEmail = (input: string) => {
  const emailValidationRegExp = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+\.)+[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/;
  const isValid = emailValidationRegExp.test(input);
  return isValid;
};

export const validatePhoneNumber = (input: string) => {
  const onlyNumbers = input.replaceAll(/\D/g, '');
  const isValid = onlyNumbers.length === 11;
  return isValid;
};

export const withOpacity = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)?.map(x => parseInt(x, 16))!;
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getFileExtensionIcon = (extension: string) => {
  switch (extension.toLowerCase()) {
    case 'zip':  return images.extensionIcon_zip;
    case '3gp':  return images.extensionIcon_3gp;
    case 'acc':  return images.extensionIcon_acc;
    case 'avi':  return images.extensionIcon_avi;
    case 'bmp':  return images.extensionIcon_bmp;
    case 'doc':  return images.extensionIcon_doc;
    case 'docx': return images.extensionIcon_docx;
    case 'flac': return images.extensionIcon_flac;
    case 'gif':  return images.extensionIcon_gif;
    case 'jpeg': return images.extensionIcon_jpeg;
    case 'jpg':  return images.extensionIcon_jpg;
    case 'mkv':  return images.extensionIcon_mkv;
    case 'mov':  return images.extensionIcon_mov;
    case 'mp3':  return images.extensionIcon_mp3;
    case 'mp4':  return images.extensionIcon_mp4;
    case 'ods':  return images.extensionIcon_ods;
    case 'odt':  return images.extensionIcon_odt;
    case 'pdf':  return images.extensionIcon_pdf;
    case 'png':  return images.extensionIcon_png;
    case 'ppt':  return images.extensionIcon_ppt;
    case 'pptx': return images.extensionIcon_pptx;
    case 'rar':  return images.extensionIcon_rar;
    case 'rtf':  return images.extensionIcon_rtf;
    case 'svg':  return images.extensionIcon_svg;
    case 'tif':  return images.extensionIcon_tif;
    case 'txt':  return images.extensionIcon_txt;
    case 'wav':  return images.extensionIcon_wav;
    case 'xls':  return images.extensionIcon_xls;
    case 'xlsx': return images.extensionIcon_xlsx;
    default: return images.documentIcon;
  }
}