import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setSectionToScrollTo } from "../../redux/mainReducer";
import { PagePath, SectionName } from "../enums";
import { MenuItem } from "../types";
import { useAppNavigate } from "./useAppNavigate";

export const useHeaderFooterMenuItems = ({
  setHeaderMenuIsOpen,
}: {
  setHeaderMenuIsOpen?: (b: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { appNavigate, location } = useAppNavigate();

  const moveToSection = useCallback(async (sectionName: SectionName) => {
    dispatch(setSectionToScrollTo(sectionName));
    setHeaderMenuIsOpen?.(false);

    // item description page has it's own faq and consult section, do not navigate back to main page
    if (
      location.pathname === PagePath.ItemDescriptionPage &&
      (sectionName === SectionName.FAQ || sectionName === SectionName.ConsultSection2)
    ) {
      return;
    }

    if (location.pathname !== PagePath.MainPage) appNavigate(PagePath.MainPage);
  }, [setHeaderMenuIsOpen, appNavigate]);

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        text: 'Программы и услуги',
        onClick: () => appNavigate(PagePath.SearchPage),
        disabled: location.pathname === PagePath.SearchPage,
      },
      {
        text: 'Частые вопросы',
        onClick: () => moveToSection(SectionName.FAQ),
      },
    ];

    return items;
  }, [location.pathname, moveToSection, appNavigate]);

  return { menuItems, moveToSection };
};