import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AppSpinner from '../../../components/AppSpinner/AppSpinner';
import ButtonWithSpinner from '../../../components/ButtonWithSpinner/ButtonWithSpinner';
import CommonInlineStyles from '../../../components/CommonInlineStyles';
import PurchaseProductModal from '../../../components/PurchaseProductModal/PurchaseProductModal';
import { useGetTariffsByIdsLazyQuery, usePaymentStatusQuery } from '../../../gql/graphqlWithHooks';
import { setBlockInterface } from '../../../redux/mainReducer';
import { RootState } from '../../../redux/store';
import { useOnReturnToMainPageButtonClick } from '../../../utils/hooks/useOnReturnToMainPageButtonClick';
import images from '../../../utils/images';
import { handlePurchaseRetryRequest } from '../../../utils/requests/purchaseRequestRetry';
import { baseColors } from '../../../utils/themeConstants';
import { GraphqlTariffData, OrderStatus, PaymentProcessingOrderData } from '../../../utils/types';
import { structureGraphqlTariffData } from '../../MainPage/components/1.Aggregator/aggregatorUtils';
import ProductItem from '../../MainPage/components/5.Products/components/ProductItem';
import { getBlockData } from '../orderStatusPageUtils';
import './PaymentProcessingBlock.scss';

const PaymentProcessingBlock = () => {
  const dispatch = useDispatch();
  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);

  const onReturnButtonClick = useOnReturnToMainPageButtonClick();

  const onRetryButtonClick = () => {
    // start spinner and disable interface
    dispatch(setBlockInterface(true));

    handlePurchaseRetryRequest({
      orderId: orderId!,
      setBlockInterface: (b: boolean) => dispatch(setBlockInterface(b)),
    });
  }

  // ####################################################################################
  // get query parameters (order id)
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('id');

  // ####################################################################################
  // use orderId to request order status
  const [orderData, setOrderData] = useState<PaymentProcessingOrderData>({
    orderStatus: OrderStatus.InProgress,
  });

  const { loading, data, stopPolling } = usePaymentStatusQuery({
    variables: {
      order_id: orderId!,
    },
    pollInterval: 5000,
  });

  useEffect(() => {
    const fetchedData = data?.mobileGetStatusMedicalConciergeBilling;
    const updatedOrderData: PaymentProcessingOrderData = {
      orderStatus: fetchedData?.status as OrderStatus,
      productTitle: fetchedData?.program_name || undefined,
      productQuantity: fetchedData?.period || undefined,
      userEmail: fetchedData?.email || undefined,
    };

    setOrderData(updatedOrderData);
  }, [data]);

  // ####################################################################################
  // stop requests if status changes from "in progress" (to either success or error)
  useEffect(() => {
    if (orderData.orderStatus !== OrderStatus.InProgress) {
      stopPolling();
    }
  }, [orderData.orderStatus])

  // ####################################################################################
  // get suggested products
  
  const suggestedTariffPlanIds: number[] = [247, 248, 249, 250];
  const [getTariffsByIdsLazyQuery] = useGetTariffsByIdsLazyQuery();
  
  const [suggestedTariffs, setSuggestedTariffs] = useState<GraphqlTariffData[]>([]);
  useEffect(() => {
    getTariffsByIdsLazyQuery({ variables: { ids: suggestedTariffPlanIds }}).then(response => {
      const fetchedTariffsData = response.data?.mobile_service_tariffs;
      const structuredTariffs = fetchedTariffsData?.map(item => structureGraphqlTariffData(item)) || [];

      const { productTitle, productQuantity } = orderData;
      
      const structuredTariffsFiltered = structuredTariffs.filter(
        (item) => !(item.productTitle === productTitle && item.quantity === productQuantity)
      );

      setSuggestedTariffs(structuredTariffsFiltered.slice(0, 2));
    });
  }, []);

  // ####################################################################################
  // right now backend gives us program_name (title?) and period (quantity?)
  // it is probably not enough to fetch actual product & tariff data

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openPurchaseProductModal = () => setModalIsOpen(true);
  const closePurchaseProductModal = () => setModalIsOpen(false);

  const [selectedTariffData, setSelectedTariffData] = useState<GraphqlTariffData>();
  const onPurchaseClick = (tariffData: GraphqlTariffData) => {
    setSelectedTariffData(tariffData);
    openPurchaseProductModal();
  }

  // ####################################################################################
  const displayData = useMemo(() => getBlockData(orderData), [orderData]);
  
  if (loading || orderData.orderStatus === OrderStatus.InProgress || !displayData) {
    const inProgressTitle = 'Ожидание подтверждения оплаты';
    const inProgressText = 'Ваша оплата обрабатывается, это может занять несколько минут';

    return (
      <div className="paymentProcessingInProgressContainer">
        <div className="paymentProcessingInProgressContainerInner">
          <AppSpinner/>
          <div className="paymentProcessingInProgressTitle">{inProgressTitle}</div>
          <div className="paymentProcessingInProgressText">{inProgressText}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="paymentProcessingContainer">
      <PurchaseProductModal
        tariffData={selectedTariffData!}
        selectedTags={[]}
        modalIsOpen={modalIsOpen}
        closeModal={closePurchaseProductModal}
      />
      <div className="paymentProcessingMainSectionContainer">
        <div className="paymentProcessingMainSectionContainerInner">
          <img src={displayData.statusImage} alt="" className={displayData.statusImageClass} />
          <div className="paymentProcessingMainSectionTitle">{displayData.firstColumnTitle}</div>
          <div className="paymentProcessingMainSectionText">{displayData.firstColumnText}</div>
          <ButtonWithSpinner
            onClick={orderData.orderStatus === OrderStatus.Success ? onReturnButtonClick : onRetryButtonClick}
            title={displayData.buttonTitle}
            fetchingTitle={'Обработка запроса'}
            fetching={blockInterface}
            className="app-button paymentProcessingButton"
            style={blockInterface ? CommonInlineStyles.buttonDisabled : undefined}
            spinnerColor={baseColors.white}
            spinnerSize={18}
          />
          {displayData.showReturnUrl && (
            <div className="paymentProcessingUnderButtonLink link opacity-on-hover" onClick={onReturnButtonClick}>
              {'Вернуться на главную'}
            </div>
          )}
        </div>
      </div>
      <div className="paymentProcessingSuggestedSectionContainer" style={{ backgroundImage: images.successfulPaymentSecondColumnBackground }}>
        <div className="paymentProcessingSuggestedSectionTitle">{displayData.secondColumnTitle}</div>
        <div className="paymentProcessingSuggestedSectionContainerInner">
          {suggestedTariffs.map((tariffData) => (
            <ProductItem
              key={`${tariffData.productId}${tariffData.tariffId}`}
              tariffData={tariffData}
              onPurchaseClick={onPurchaseClick}
              onMoveUserToConsult={() => {}}
              useMobileDesign={false}
              usePaymentProcessingVariant
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentProcessingBlock;
