import { useScrollToTop } from '../../utils/hooks/useScrollToTop';
import BaseLayout from '../BaseLayout';
import SearchPageContent from './SearchPageContent';

const SearchPage = () => {
  useScrollToTop({ smooth: true });

  return (
    <BaseLayout
      hideSectionLinks={false}
      style={{ backgroundColor: '#EBF4F5' }}
      content={
        <SearchPageContent />
      }
    />
  );
};

export default SearchPage;