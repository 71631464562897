import { useAppSelector } from '../../../redux/store';
import SearchPageSelectedTagTargetItem from './SearchPageSelectedTagTargetItem';
import './SearchPageSelectedTagTargetsBlock.scss';

const SearchPageSelectedTagTargetsBlock = () => {
  const {
    structuredTagsData: { tagTargets },
    selectedTagTargetIds,
  } = useAppSelector((state) => state.aggregator);

  return (
    <div className="searchPageSelectedTagTargetsBlockContainer">
      {!!tagTargets.length && <div className="searchPageSelectedTagTargetsBlockList">
        {selectedTagTargetIds.map((tagTargetId) => {
          const tagTarget = tagTargets.find((item) => item.id === tagTargetId)!;
          return <SearchPageSelectedTagTargetItem key={tagTargetId} tagTarget={tagTarget} />;
        })}
      </div>}
    </div>
  );
};

export default SearchPageSelectedTagTargetsBlock;
