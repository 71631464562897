import { isNumber } from 'lodash';
import { useMemo } from 'react';
import AppSpinner from '../../../../../../components/AppSpinner/AppSpinner';
import { useAppSelector } from '../../../../../../redux/store';
import { useAggregatorMenuModal } from '../../../../../../utils/hooks/useAggregatorMenuModal';
import AggregatorAgeRangesModal from '../modals/AggregatorAgeRangesModal';
import AggregatorMenuArrow from './AggregatorMenuArrow';
import './AggregatorSearchBlockMenus.scss';

const AggregatorAgeRangesMenu = ({
  flex = 1,
  usingFullScreenModal = false,
  menuAsModal = true,
  usingDesktopVersion = false,
}: {
  flex?: number;
  usingFullScreenModal?: boolean;
  menuAsModal?: boolean;
  usingDesktopVersion?: boolean;
}) => {
  const { modalIsOpen, closeModal, toggleModal } = useAggregatorMenuModal({ usingFullScreenModal });

  const { fetching, structuredTagsData, selectedAgeRangeId } = useAppSelector((state) => state.aggregator);

  const selectedAgeRangeNameShort = useMemo(() => {
    const selectedAgeRange = structuredTagsData.ageRanges.find((item) => item.id === selectedAgeRangeId);
    if (selectedAgeRangeId === 0) return selectedAgeRange?.name;
    
    const shortenedName = selectedAgeRange?.name.split(' ')?.[0];
    return shortenedName;
  }, [structuredTagsData, selectedAgeRangeId])

  const onMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleModal();
  }

  return (
    <div className="aggregatorSearchBlockFieldContainerOuter" style={{ flex, minWidth: 150 }}>
      <button
        className="aggregatorSearchBlockFieldMenuButton"
        onClick={onMenuButtonClick}
        disabled={fetching}
      >
        <div className="aggregatorSearchBlockFieldTitle">{'Возраст'}</div>

        <div
          className="aggregatorSearchBlockFieldContainerInner"
          style={{ borderColor: modalIsOpen ? '#0EC1C9' : undefined }}
        >
          {fetching ? (
            <AppSpinner/>
          ) : isNumber(selectedAgeRangeId) ? (
            <div className="aggregatorSearchBlockFieldContent">{selectedAgeRangeNameShort}</div>
          ) : (
            <div className="aggregatorSearchBlockFieldSelectedItemName">{'Выберите'}</div>
          )}
          
          <AggregatorMenuArrow modalIsOpen={modalIsOpen} />
        </div>
      </button>

      {!usingFullScreenModal && (
        <AggregatorAgeRangesModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          asModal={menuAsModal}
          usingDesktopVersion={usingDesktopVersion}
        />
      )}
    </div>
  );
};

export default AggregatorAgeRangesMenu;