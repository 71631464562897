import './SearchPageResultTemplate.scss';

const SearchPageResultTemplate = () => {
  return (
    <div className="searchPageResultTemplateContainer">
      <div className="searchPageResultTemplateImage animatedGradient" />

      <div className="searchPageResultTemplateInformationContainer">
        <div className="searchPageResultTemplateInformationContainerInner">
          <div className="searchPageResultTemplateTitle animatedGradient" />
          <div className="searchPageResultTemplateSubTitle animatedGradient" />
          <div className="searchPageResultTemplateDescription animatedGradient" />
        </div>

        <div className="searchPageResultTemplateButtons">
          <div className="searchPageResultTemplateButton animatedGradient" />
          <div className="searchPageResultTemplateButton animatedGradient" />
        </div>
      </div>
    </div>
  );
};

export default SearchPageResultTemplate;