import images from "../../utils/images";
import { getCensoredEmailString } from "../../utils/stringHelper";
import { OrderStatus, PaymentProcessingOrderData } from "../../utils/types";

export const getBlockData = (orderData: PaymentProcessingOrderData) => {
  const { orderStatus, productTitle, userEmail } = orderData;
  const emailString = getCensoredEmailString(userEmail);

  const { firstColumnTitleSuccess, firstColumnTextSuccess, firstColumnTitleError } =
    getOrderStatusDescriptions(productTitle, emailString);

  switch (orderStatus) {
    case OrderStatus.Success:
      return {
        statusImage: images.modalSuccess,
        statusImageClass: 'paymentProcessingStatusIconSuccess',
        firstColumnTitle: firstColumnTitleSuccess,
        firstColumnText: firstColumnTextSuccess,
        buttonTitle: 'Вернуться на главную',
        secondColumnTitle: 'Также вам подходит',
      }

    case OrderStatus.InProgress:
      return null;
  
    // not success/waiting = error
    default:
      return {
        statusImage: images.modalError,
        statusImageClass: 'paymentProcessingStatusIconFailure',
        firstColumnTitle: firstColumnTitleError,
        firstColumnText: `Пожалуйста, обратитесь в свой банк или попробуйте выбрать другой способ оплаты.`,
        buttonTitle: 'Попробовать еще раз',
        showReturnUrl: true,
        secondColumnTitle: 'Может быть интересно',
      }
  }
}

const getOrderStatusDescriptions = (productTitle = '', emailString: string) => {
  if (productTitle.includes('Программа')) return {
    firstColumnTitleSuccess: 'Вы оплатили программу',
    firstColumnTextSuccess: `Мы отправили письмо на почту ${emailString} со всей информацией. Наши специалисты свяжутся с вами в ближайшее время.`,
    firstColumnTitleError: 'Программа не оплачена',
  };
  
  if (productTitle.includes('Гайд')) return {
    firstColumnTitleSuccess: 'Вы оплатили гайд',
    firstColumnTextSuccess: `Мы отправили письмо на почту ${emailString} со всей информацией.`,
    firstColumnTitleError: 'Гайд не оплачен',
  };
  
  return {
    firstColumnTitleSuccess: 'Вы оплатили продукт',
    firstColumnTextSuccess: `Мы отправили письмо на почту ${emailString} со всей информацией.`,
    firstColumnTitleError: 'Продукт не оплачен',
  };
}