import { useState } from 'react';
import { useAppSelector } from '../../redux/store';
import {
  companyMail,
  companyMailHref,
  companyPhone1,
  companyPhone1Href,
  socialMediaButtons,
} from '../../utils/appConstants';
import { SectionName } from '../../utils/enums';
import { useHeaderFooterMenuItems } from '../../utils/hooks/useHeaderFooterMenuItems';
import images from '../../utils/images';
import LogoBlock from '../LogoBlock/LogoBlock';
import SocialMediaButton from '../SocialMediaButton/SocialMediaButton';
import HeaderMobileMenuModal from './HeaderMobileMenuModal';
import './Header.scss';

const Header = ({ hideSectionLinks }: { hideSectionLinks: boolean }) => {
  const dimensionsData = useAppSelector((state) => state.main.dimensionsData);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { menuItems, moveToSection } = useHeaderFooterMenuItems({ setHeaderMenuIsOpen: setMenuIsOpen });

  const companyPhoneComment = 'работаем по всей России';

  const { isVerticallyOriented: useMobileDesign } = dimensionsData;

  if (menuIsOpen) {
    return (
      <HeaderMobileMenuModal
        closeModal={() => setMenuIsOpen(false)}
        scrollToConsultSection={() => moveToSection(SectionName.ConsultSection2)}
        companyMail={companyMail}
        companyMailHref={companyMailHref}
        companyPhone={companyPhone1}
        companyPhoneHref={companyPhone1Href}
        menuItems={menuItems}
      />
    );
  }

  return (
    <div className="headerContainer">
      <LogoBlock
        size={useMobileDesign ? 40 : undefined}
        isClickable={true}
      />

      {!hideSectionLinks && (
        <div className="headerMenuContainer headerHideOnMobile">
          {menuItems.map((item) => (
            <button
              key={item.text}
              className={`headerMenuItem opacity-on-hover ${item.disabled ? 'headerMenuItemDisabled' : ''}`}
              onClick={item.onClick}
              disabled={item.disabled}
              // style={{}}
            >
              {item.text}
            </button>
          ))}
        </div>
      )}

      {!hideSectionLinks && (
        <div className="headerSocialMediaButtonsContainer headerHideOnMobile">
          {socialMediaButtons.map((item) => (
            <SocialMediaButton item={item} key={item.href} />
          ))}
        </div>
      )}

      {!hideSectionLinks && (
        <button
          onClick={() => moveToSection(SectionName.ConsultSection2)}
          className="app-button headerConsultButton headerHideOnMobile"
        >
          <span className="headerConsultButtonText">{'Перезвоните мне'}</span>
        </button>
      )}

      <div className="headerCompanyPhoneBlock">
        <a className="headerCompanyPhone link opacity-on-hover" href={companyPhone1Href}>
          {companyPhone1}
        </a>
        <div className="headerCompanyPhoneComment">{companyPhoneComment}</div>
      </div>

      {!hideSectionLinks && (
        <div className="headerMenuButton opacity-on-hover" onClick={() => setMenuIsOpen(true)}>
          <img src={images.headerMobileMenuIcon} className="headerMenuButtonIcon" />
        </div>
      )}
    </div>
  );
};

export default Header;
