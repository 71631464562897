import { useDispatch } from 'react-redux';
import { setSelectedSortType } from '../../../redux/aggregatorReducer';
import { useAppSelector } from '../../../redux/store';
import { useModalStateHooks } from '../../../utils/hooks/useModalStateHooks';
import images from '../../../utils/images';
import SearchPageSortSelectionModal from './SearchPageSortSelectionModal';
import './SearchPageSortButton.scss';
import { ProductsSortType } from '../../../utils/types';

export interface SortOption {
  title: string;
  sortType: ProductsSortType;
}

const sortOptions: SortOption[] = [
  {
    title: 'Самые популярные',
    sortType: ProductsSortType.PopularFirst,
  },
  {
    title: 'Высокая цена',
    sortType: ProductsSortType.PriceDescending,
  },
  {
    title: 'Низкая цена',
    sortType: ProductsSortType.PriceAscending,
  },
]

const SearchPageSortButton = () => {
  
  const { modalIsOpen, openModal, closeModal } = useModalStateHooks();
  
  const selectedSortType = useAppSelector(state => state.aggregator.selectedSortType);
  const selectedSortOption = sortOptions.find(item => item.sortType === selectedSortType)!;

  const dispatch = useDispatch();
  const onSelect = (sortType: ProductsSortType) => {
    dispatch(setSelectedSortType(sortType));
  }

  return (
    <div className="SearchPageSortButtonContainerOuter">
      <SearchPageSortSelectionModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        sortOptions={sortOptions}
        selectedSortType={selectedSortType}
        setSelectedSortType={onSelect}
      />

      <button className="SearchPageSortButtonContainer" onClick={openModal}>
        <div className="SearchPageSortButtonTitle">{selectedSortOption.title}</div>
        <img className="SearchPageSortButtonImage" src={images.aggregatorSortIcon} />
      </button>
    </div>
  );
};

export default SearchPageSortButton;