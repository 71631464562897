import { useDispatch } from 'react-redux';
import { setSelectedTagTargetIds } from '../../redux/aggregatorReducer';
import { useAppSelector } from '../../redux/store';
import './ClearSelectedTagTargetsButton.scss';

const ClearSelectedTagTargetsButton = () => {
  const dispatch = useDispatch();
  const onClearTargetsClick = () => {
    dispatch(setSelectedTagTargetIds([]));
  };

  const selectedTagTargetIds = useAppSelector((state) => state.aggregator.selectedTagTargetIds);

  if (!selectedTagTargetIds.length) return null;

  return (
    <button
      className="clearSelectedTagTargetsButton opacity-on-hover"
      onClick={onClearTargetsClick}
    >
      {'Очистить все цели'}
    </button>
  );
};

export default ClearSelectedTagTargetsButton;
